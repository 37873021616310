import 'flag-icons/css/flag-icons.min.css';
import { Box, GridFlex, HighlightText, Icon, InformationTooltip, LongText, Text } from '@stigg-components';
import countryList from 'country-list';
import { Currency } from '@stigg-types/apiTypes';
import { getCurrencyText } from './currencyUtils';

export function CountryFlag({ countryCode = '' }: { countryCode?: string }) {
  return <span style={{ minWidth: 24, height: 18 }} className={`fi fi-${countryCode}`} />;
}

export function DefaultFlag() {
  return <Icon type="materialIcons" icon="Language" size={20} color="default" />;
}

export function CountryWithFlagByCode({ countryCode = '', currency }: { countryCode?: string; currency?: Currency }) {
  return (
    <GridFlex.RowCenter $fullWidth>
      <CountryFlag countryCode={countryCode} />
      <LongText tooltipPlacement="left" wordBreak sx={{ ml: 2, width: '90%' }} leftMargin>
        {`${countryList.getName(countryCode) || ''}${currency ? ` (${getCurrencyText(currency)})` : ''}`}
      </LongText>
    </GridFlex.RowCenter>
  );
}

export function CountryFlagWithCurrencyTooltip({ countryCode, currency }: { countryCode: string; currency: Currency }) {
  return (
    <InformationTooltip
      title={
        <GridFlex.RowCenter gap={2}>
          <Text.B2>{countryList.getName(countryCode)}</Text.B2>
          <Text.B2 color="secondary">({getCurrencyText(currency)})</Text.B2>
        </GridFlex.RowCenter>
      }
      arrow
      placement="top"
      $padding={2}>
      <Box>
        <CountryFlag countryCode={countryCode} />
      </Box>
    </InformationTooltip>
  );
}

export function CountryFlagWithCurrency({
  countryCode,
  currency,
  suffix,
  highlight,
  $bold,
}: {
  countryCode: string;
  currency?: Currency;
  suffix?: string;
  highlight?: string;
  $bold?: boolean;
}) {
  return (
    <GridFlex.RowCenter gap={2}>
      <CountryFlag countryCode={countryCode} />
      <Box>
        <Text.B2 $bold={$bold} display="inline">
          <HighlightText text={countryList.getName(countryCode)} highlight={highlight} />
        </Text.B2>
        {currency && (
          <Text.B2 color="secondary" display="inline">
            {' '}
            ({getCurrencyText(currency)})
          </Text.B2>
        )}
        {suffix && (
          <Text.B2 color="secondary" display="inline">
            {suffix}
          </Text.B2>
        )}
      </Box>
    </GridFlex.RowCenter>
  );
}
