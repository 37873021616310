import moment from 'moment';
import { camelCase, startCase } from 'lodash';
import {
  BillingModel,
  BillingPeriod,
  BillingVendorIdentifier,
  Currency,
  PricingType,
  TiersMode,
  CheckoutStatePlan,
  Customer,
  GetCheckoutState,
  GetCheckoutStateResults,
  Price,
  Product,
  PromotionalEntitlement,
  Subscription,
  Addon,
} from '@stigg/react-sdk';
import {
  BASE_FEE_MONTHLY,
  TIERS_PRICE_MONTHLY,
  PER_UNIT_PRICE_MONTHLY,
  PER_UNIT_PRICE_YEARLY,
  STRIPE_MOCK_ACCOUNT_ID,
  STRIPE_MOCK_ACCOUNT_PK,
  TIERS,
  BASE_FEE_YEARLY,
  TIERS_PRICE_YEARLY,
  ADDON_PRICE_MONTHLY,
  ADDON_PRICE_YEARLY,
} from './consts';

const mockCustomer: Customer = {
  id: '462d5d8a-22c4-4f22-9306-38d1a3047675',
  name: 'John Doe',
  email: 'john.doe@example.com',
  createdAt: moment().subtract(1, 'year').toDate(),
  updatedAt: moment().subtract(1, 'month').toDate(),
  hasPaymentMethod: false,
  promotionalEntitlements: [],
  subscriptions: [],
  getActivePromotionalEntitlements(): PromotionalEntitlement[] {
    return [];
  },
  getActiveSubscriptions(): Subscription[] {
    return [];
  },
  getActiveTrials(): Subscription[] {
    return [];
  },
};

const mockProduct: Product = {
  id: 'product-1',
  displayName: 'Product 1',
  description: 'Product 1 description',
};

const additionalStorageAddons: Addon = {
  id: 'addon-additional-storage',
  description: 'Additional storage',
  displayName: 'Additional storage',
  entitlements: [],
  pricePoints: [
    {
      pricingModel: BillingModel.FlatFee,
      billingPeriod: BillingPeriod.Monthly,
      amount: ADDON_PRICE_MONTHLY,
      currency: Currency.Usd,
      tiersMode: undefined,
      isTieredPrice: false,
    },
    {
      pricingModel: BillingModel.FlatFee,
      billingPeriod: BillingPeriod.Annually,
      amount: ADDON_PRICE_YEARLY,
      currency: Currency.Usd,
      tiersMode: undefined,
      isTieredPrice: false,
    },
  ],
};

const flatFeePricing: Price[] = [
  {
    pricingModel: BillingModel.FlatFee,
    billingPeriod: BillingPeriod.Monthly,
    amount: BASE_FEE_MONTHLY,
    currency: Currency.Usd,
    tiersMode: undefined,
    isTieredPrice: false,
  },
  {
    pricingModel: BillingModel.FlatFee,
    billingPeriod: BillingPeriod.Annually,
    amount: BASE_FEE_YEARLY,
    currency: Currency.Usd,
    tiersMode: undefined,
    isTieredPrice: false,
  },
];

function tieredPricing(): Price[] {
  const feature = {
    featureId: 'feature-campaigns',
    displayName: 'Campaigns',
    units: 'campaign',
    unitsPlural: 'campaigns',
  };

  return [
    {
      pricingModel: BillingModel.PerUnit,
      billingPeriod: BillingPeriod.Monthly,
      tiersMode: TiersMode.Volume,
      tiers: TIERS.map((tier, index) => ({
        upTo: tier,
        unitPrice: {
          amount: TIERS_PRICE_MONTHLY[index] / tier,
          currency: Currency.Usd,
        },
      })),
      feature,
      currency: Currency.Usd,
      isTieredPrice: true,
    },
    {
      pricingModel: BillingModel.PerUnit,
      billingPeriod: BillingPeriod.Annually,
      tiersMode: TiersMode.Volume,
      tiers: TIERS.map((tier, index) => ({
        upTo: tier,
        unitPrice: {
          amount: TIERS_PRICE_YEARLY[index] / tier,
          currency: Currency.Usd,
        },
      })),
      feature,
      currency: Currency.Usd,
      isTieredPrice: true,
    },
  ];
}

function perUnitPricing(): Price[] {
  const feature = {
    featureId: 'feature-seats',
    displayName: 'Seats',
    units: 'seat',
    unitsPlural: 'seats',
  };

  return [
    {
      pricingModel: BillingModel.PerUnit,
      billingPeriod: BillingPeriod.Monthly,
      amount: PER_UNIT_PRICE_MONTHLY,
      currency: Currency.Usd,
      feature,
      tiersMode: undefined,
      isTieredPrice: false,
    },
    {
      pricingModel: BillingModel.PerUnit,
      billingPeriod: BillingPeriod.Annually,
      amount: PER_UNIT_PRICE_YEARLY,
      currency: Currency.Usd,
      feature,
      tiersMode: undefined,
      isTieredPrice: false,
    },
  ];
}

function mockPlan(planRefId: string): CheckoutStatePlan {
  return {
    id: '4b6c639e-1100-4ae1-86a9-31324994f992',
    displayName: startCase(camelCase(planRefId)),
    pricingType: PricingType.Paid,
    pricePoints: [...flatFeePricing, ...perUnitPricing(), ...tieredPricing()],
    product: mockProduct,
    order: 0,
    compatibleAddons: [additionalStorageAddons],
    entitlements: [],
    inheritedEntitlements: [],
    compatiblePackageGroups: [],
  };
}

export function mockCheckoutState(params: GetCheckoutState): GetCheckoutStateResults {
  const { planId } = params;

  return {
    setupSecret: '',
    customer: mockCustomer,
    plan: mockPlan(planId),
    billingIntegration: {
      billingIdentifier: BillingVendorIdentifier.Stripe,
      credentials: {
        accountId: STRIPE_MOCK_ACCOUNT_ID,
        publicKey: STRIPE_MOCK_ACCOUNT_PK,
      },
    },
    resource: null,
  };
}
