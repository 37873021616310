import { CouponFragment } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { ActiveIntegrationIcons, Box, Button, Grid, GridFlex, TextField } from '@stigg-components';
import { t } from 'i18next';
import { RootState } from '../../../../redux/store';
import Loader from '../../../../components/Loader';

export type UpdateCouponFormFields = {
  name: string;
  description: string;
};

export type UpdateCouponFormProps = {
  coupon: CouponFragment;
  onCancel: () => void;
  onUpdate: (values: UpdateCouponFormFields) => void;
};

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
  });

export function UpdateCouponForm({ onUpdate, coupon, onCancel }: UpdateCouponFormProps) {
  const isLoading = useSelector((state: RootState) => state.couponsReducer.isLoading);

  const initialValues: UpdateCouponFormFields = {
    name: coupon.name,
    description: coupon.description || '',
  };

  const handleSubmit = (values: UpdateCouponFormFields, actions: FormikHelpers<UpdateCouponFormFields>) => {
    onUpdate(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema()}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur }) => (
        <Box>
          {isLoading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={3}>
                <GridFlex.Column item>
                  <TextField
                    name="name"
                    label={t('coupons.nameForm')}
                    value={values.name}
                    touched={!!touched.name}
                    error={!!errors.name}
                    fullWidth
                    errorText={errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    captionText={t('coupons.createCouponHelperText')}
                  />
                </GridFlex.Column>

                <Grid item />
                <Grid item>
                  <TextField
                    maxRows={4}
                    name="description"
                    label={t('coupons.descriptionForm')}
                    value={values.description}
                    touched={Boolean(touched.description)}
                    error={Boolean(errors.description)}
                    fullWidth
                    errorText={errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item container mt={4} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ActiveIntegrationIcons />
                  </Grid>
                  <Grid item alignSelf="end" mt={4}>
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('coupons.cancelButton')}
                    </Button>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.editSaveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </GridFlex.Column>
            </form>
          )}
        </Box>
      )}
    </Formik>
  );
}
