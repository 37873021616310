import { push, replace } from 'connected-react-router';
import { encode } from 'querystring';
import { AppDispatch, RootState } from '../../redux/store';
import { getEnvPathFunc } from './selectors';
import { NavigateToOptions } from './useNavigation';

export function composeUrl(
  path: string,
  getEnvPath: (path: string) => string,
  { isGlobal, search, anchor }: NavigateToOptions = {},
) {
  return `${isGlobal ? path : getEnvPath(path)}${search ? `?${encode(search)}` : ''}${anchor ? `#${anchor}` : ''}`;
}

export function navigateTo(path: string, options: NavigateToOptions = {}) {
  const { replace: shouldReplace } = options;

  return (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      accountReducer: { environments, currentEnvironmentId },
    } = getState();
    const getEnvPath = getEnvPathFunc({ currentEnvironmentId, environments });
    const url = composeUrl(path, getEnvPath, options);
    dispatch(shouldReplace ? replace(url) : push(url));
  };
}
