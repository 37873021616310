import React, { ReactElement, useState } from 'react';
import isNil from 'lodash/isNil';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import {
  LoadingButton,
  Button,
  Text,
  Grid,
  GridFlex,
  Dialog as MuiDialog,
  DialogActions,
  IconButton,
  Slide,
} from '@stigg-components';
import { Save } from 'react-feather';
import { ButtonColor, TextColor } from '@stigg-theme';
import { TransitionProps } from '@mui/material/transitions';
import { SxProps } from '@mui/system';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogHeader = ({
  title,
  icon,
  onClose,
  titleColor,
  withPadding,
  ...other
}: {
  id: string;
  onClose?: () => void;
  title?: string;
  icon?: ReactElement;
  withPadding: boolean;
  titleColor?: TextColor;
}) => {
  if (!title) {
    return onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: withPadding ? 0 : 30,
          top: withPadding ? 0 : 20,
          p: 0,
        }}>
        <CloseIcon sx={{ color: (theme) => theme.itamar.palette.action.active }} />
      </IconButton>
    ) : null;
  }

  return (
    <GridFlex.RowCenter container columnGap={2} mb={4}>
      {icon}
      {title && (
        <Text.H3 color={titleColor} flex={1} {...other}>
          {title}
        </Text.H3>
      )}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon sx={{ color: (theme) => theme.itamar.palette.action.active }} />
        </IconButton>
      ) : null}
    </GridFlex.RowCenter>
  );
};

export function Dialog({
  onCancel,
  content,
  titleText,
  titleIcon,
  titleColor,
  isOpen,
  height,
  width,
  fullScreen,
  TransitionComponent,
  padding,
  disableCloseOnEscapeKey,
}: {
  onCancel?: () => void;
  titleText?: string;
  titleIcon?: ReactElement;
  titleColor?: TextColor;
  content: ReactElement | string | null;
  isOpen: boolean;
  height?: number;
  width?: number;
  fullScreen?: boolean;
  TransitionComponent?: React.JSXElementConstructor<TransitionProps & { children: React.ReactElement<any, any> }>;
  padding?: number;
  disableCloseOnEscapeKey?: boolean;
}) {
  const transitionComponent = TransitionComponent || fullScreen ? Transition : undefined;

  return (
    <MuiDialog
      disableEscapeKeyDown={disableCloseOnEscapeKey}
      fullScreen={fullScreen}
      fullWidth
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          p: isNil(padding) ? '40px' : 0,
          m: 0,
          minHeight: height || 0,
          maxHeight: fullScreen ? '100vh' : height || '80vh',
          minWidth: width,
          backgroundColor: (theme) => theme.itamar.palette.background.paper,
          backgroundImage: 'none',
        },
      }}
      TransitionComponent={transitionComponent}>
      <GridFlex.Column
        position="relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{ height: '100%' }}>
        <DialogHeader
          id="dialog-title"
          onClose={onCancel}
          title={titleText}
          icon={titleIcon}
          titleColor={titleColor || 'primary'}
          withPadding={isNil(padding)}
        />
        {content}
      </GridFlex.Column>
    </MuiDialog>
  );
}

export function DialogActionsButtons({
  onSave,
  isSaveLoading,
  saveStartIcon,
  saveEndIcon,
  saveDisabled,
  saveText,
  onCancel,
  cancelText = t('sharedComponents.cancel'),
  disableButtonAsSubmit = false,
  saveButtonColor = 'primary',
  cancelButtonColor = 'primary',
  saveButtonSx,
  cancelButtonSx,
  isSaveButtonHidden,
}: {
  onSave?: () => void | Promise<void>;
  saveText: string;
  cancelText?: string;
  saveDisabled?: boolean;
  onCancel?: () => void;
  // as we use the dialog mostly with formik, button type will be default set to 'submit'
  disableButtonAsSubmit?: boolean;
  saveStartIcon?: React.ReactNode;
  saveEndIcon?: React.ReactNode;
  isSaveLoading?: boolean;
  saveButtonColor?: ButtonColor;
  cancelButtonColor?: ButtonColor;
  saveButtonSx?: SxProps;
  cancelButtonSx?: SxProps;
  isSaveButtonHidden?: boolean;
}) {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    if (onSave) {
      await onSave();
    }
    setIsSaving(false);
  };
  return (
    <DialogActions sx={{ px: 0 }}>
      <Grid item alignSelf="end">
        {onCancel && (
          <Button
            onClick={onCancel}
            sx={{ mr: 3, ...cancelButtonSx }}
            $outlined
            color={cancelButtonColor}
            disabled={isSaveLoading}>
            {cancelText}
          </Button>
        )}
        {!isSaveButtonHidden && (
          <LoadingButton
            data-testid="dialog-submit-button"
            loadingPosition="start"
            loading={isSaveLoading || isSaving}
            // HACK: we need to show some icon here when button is on loading
            // state so the position of the loading spinner will be correct :/
            startIcon={isSaveLoading ? <Save /> : saveStartIcon}
            endIcon={saveEndIcon}
            onClick={handleSave}
            disabled={saveDisabled}
            type={disableButtonAsSubmit ? 'button' : 'submit'}
            variant="contained"
            sx={saveButtonSx}
            color={saveButtonColor}>
            {saveText}
          </LoadingButton>
        )}
      </Grid>
    </DialogActions>
  );
}
