import { WidgetIdentifier } from '../../useWidgetsData';

type MonacoSuggestions = {
  label: string;
  detail: string;
  documentation: string;
};

export const PAYWALL_MONACO_SUGGESTIONS: MonacoSuggestions[] = [
  {
    label: '.stigg-paywall-layout',
    detail: 'Paywall layout',
    documentation: 'Styles applied to the entire paywall page',
  },
  {
    label: '.stigg-paywall-plans-layout',
    detail: 'Plans layout',
    documentation: 'Styles applied to the container of all plans',
  },
  {
    label: '.stigg-header-wrapper',
    detail: 'Plan header section',
    documentation: 'Styles applied to the plan header section',
  },
  {
    label: '.stigg-plan-offering-container',
    detail: 'Plan offering container',
    documentation: 'Styles applied to the plan container',
  },
  {
    label: '.stigg-plan-header',
    detail: 'Plan title',
    documentation: 'Styles applied to the title of the plan',
  },
  {
    label: '.stigg-starting-at-text',
    detail: 'Plan starting at text',
    documentation: 'Styles applied to the plan starting at text',
  },
  {
    label: '.stigg-price-text',
    detail: 'Plan price',
    documentation: 'Styles applied to the price of the plan',
  },
  {
    label: '.stigg-plan-description',
    detail: 'Plan description',
    documentation: 'Styles applied to the description of the plan',
  },
  {
    label: '.stigg-price-billing-period-text',
    detail: 'Billing period text',
    documentation: 'Styles applied to the text stating the billing period of the plan',
  },
  {
    label: '.stigg-plan-header-divider',
    detail: 'Header divider',
    documentation: 'Styles applied to the divider between the header section and entitlements section',
  },
  {
    label: '.stigg-paywall-plan-button-layout',
    detail: 'Plan button section',
    documentation: 'Styles applied to the button section',
  },
  {
    label: '.stigg-paywall-plan-button',
    detail: 'Plan button',
    documentation: 'Styles applied to the button of the plan',
  },
  {
    label: '.stigg-paywall-plan-button-text',
    detail: 'Plan button text',
    documentation: 'Styles applied to the text of the button',
  },
  {
    label: '.stigg-highlight-badge',
    detail: 'Highligh badge',
    documentation: 'Styles applied to the recommended plan badge',
  },
  {
    label: '.stigg-highlight-badge-text',
    detail: 'Badge text',
    documentation: 'Styles applied to the recommended plan badge text',
  },
  {
    label: '.stigg-trial-days-left-text',
    detail: 'Trial days left text',
    documentation: "Styles applied to the text describing how many days are left of the plan's free trial",
  },
  {
    label: '.stigg-plan-entitlements-container',
    detail: 'Entitlements container',
    documentation: 'Styles applied to the entire section',
  },
  {
    label: '.stigg-plan-entitlements-title',
    detail: 'Entitlements title',
    documentation: 'Styles applied to the title of the section',
  },
  {
    label: '.stigg-entitlement-row-container',
    detail: 'Entitlement row container',
    documentation: 'Styles applied to the area of each entitlement',
  },
  {
    label: '.stigg-entitlement-name',
    detail: 'Entitlement name',
    documentation: 'Styles applied to the text of each entitlement',
  },
  {
    label: '.stigg-entitlement-row-icon',
    detail: 'Entitlement row icon',
    documentation: 'Styles applied to the icon of each entitlement',
  },
  {
    label: '.stigg-period-picker-container',
    detail: 'Period picker section',
    documentation: 'Styles applied to the period toggle section',
  },
  {
    label: '.stigg-period-switch',
    detail: 'Period switch button',
    documentation: 'Styles applied to the period switch button',
  },
  {
    label: '.stigg-monthly-period-text',
    detail: 'Monthly period text',
    documentation: 'Styles applied to the monthly period text',
  },
  {
    label: '.stigg-annual-period-text',
    detail: 'Annually period text',
    documentation: 'Styles applied to the annual period text',
  },
  {
    label: '.stigg-discount-rate-text',
    detail: 'Discount rate text',
    documentation: 'Styles applied to the discount rate given to annual billing',
  },
  {
    label: '.stigg-watermark',
    detail: 'Stigg watermark',
    documentation: 'Styles applied to stigg watermark',
  },
];

export const CUSTOMER_PORTAL_MONACO_SUGGESTIONS: MonacoSuggestions[] = [
  {
    label: '.stigg-customer-portal-layout',
    detail: 'Customer portal layout',
    documentation: 'Styles applied to the entire customer portal page',
  },
  {
    label: '.stigg-customer-portal-sections',
    detail: 'Customer portal sections',
    documentation: 'Styles applied to the customer portal sections layout',
  },
  {
    label: '.stigg-customer-portal-header-layout',
    detail: 'Customer header layout',
    documentation: 'Styles applied to the customer portal header layout',
  },
  {
    label: '.stigg-watermark',
    detail: 'Stigg watermark',
    documentation: 'Styles applied to stigg watermark',
  },
  {
    label: '.stigg-customer-portal-subscriptions-overview',
    detail: 'Subscriptions overview',
    documentation: 'Styles applied to subscriptions overview section',
  },
  {
    label: '.stigg-overview-layout',
    detail: 'Subscriptions overview content layout',
    documentation: 'Styles applied to subscriptions overview content layout',
  },
  {
    label: '.stigg-charge-list',
    detail: 'Subscriptions charge list',
    documentation: 'Styles applied to subscriptions charge list',
  },
  {
    label: '.stigg-charge-list-item',
    detail: 'Subscriptions charge list item',
    documentation: 'Styles applied to subscriptions charge list item',
  },
  {
    label: '.stigg-subscription-view-layout',
    detail: 'Subscriptions overview layout',
    documentation: 'Styles applied to subscriptions overview layout',
  },
  {
    label: '.stigg-subscription-plan-name',
    detail: 'Subscription view plan title',
    documentation: 'Styles applied to subscription view plan title',
  },
  {
    label: '.stigg-subscription-total-price-layout',
    detail: 'Subscription view price',
    documentation: 'Styles applied to subscription view total price layout',
  },
  {
    label: '.stigg-customer-portal-paywall-section',
    detail: 'Paywall section',
    documentation: 'Styles applied to paywall section',
  },
  {
    label: '.stigg-customer-portal-paywall-section-title',
    detail: 'Paywall section title',
    documentation: 'Styles applied to paywall section title',
  },
  {
    label: '.stigg-payment-details-section-layout',
    detail: 'Payment details layout',
    documentation: 'Styles applied to billing information title',
  },
  {
    label: '.stigg-payment-details-section-header',
    detail: 'Payment details title layout',
    documentation: 'Styles applied to payment details title layout',
  },
  {
    label: '.stigg-payment-details-section-title',
    detail: 'Payment details title',
    documentation: 'Styles applied to payment details title',
  },
  {
    label: '.stigg-customer-name',
    detail: 'Payment details customer name',
    documentation: 'Styles applied to payment details customer name',
  },
  {
    label: '.stigg-customer-email',
    detail: 'Payment details customer email',
    documentation: 'Styles applied to payment details customer email',
  },
  {
    label: '.stigg-credit-card',
    detail: 'Payment details credit card',
    documentation: 'Styles applied to payment details credit card',
  },
  {
    label: '.stigg-credit-card-expiration',
    detail: 'Payment details credit card expiration date',
    documentation: 'Styles applied to payment details credit card expiration date',
  },
  {
    label: '.stigg-invoices-section-layout',
    detail: 'Invoice section layout',
    documentation: 'Styles applied to invoice section layout',
  },
  {
    label: '.stigg-invoices-section-header',
    detail: 'Invoice section title layout',
    documentation: 'Styles applied to invoice section title layout',
  },
  {
    label: '.stigg-invoices-section-title',
    detail: 'Invoice section title',
    documentation: 'Styles applied to invoice section title',
  },
  {
    label: '.stigg-view-invoice-history-button',
    detail: 'Invoice history button',
    documentation: 'Styles applied to invoice history button',
  },
  {
    label: '.stigg-edit-payment-details-button',
    detail: 'Update Payment details button',
    documentation: 'Styles applied to update payment details button',
  },
  {
    label: '.stigg-user-information-layout',
    detail: 'User information',
    documentation: 'Styles applied to user information',
  },
  {
    label: '.stigg-tooltip',
    detail: 'Tooltips',
    documentation: 'Styles applied to the tooltips',
  },
  {
    label: '.stigg-tooltip-arrow',
    detail: 'Tooltips arrow',
    documentation: 'Styles applied to the tooltips arrow',
  },
];

export const CHECKOUT_MONACO_SUGGESTIONS: MonacoSuggestions[] = [
  {
    label: '.stigg-checkout-change-plan-button',
    detail: 'Change Plan Button',
    documentation: 'Styles applied to the Change Plan Button',
  },
  {
    label: '.stigg-checkout-change-plan-button-text',
    detail: 'Change Plan Button Text',
    documentation: 'Styles applied to the text inside the Change Plan Button',
  },
  {
    label: '.stigg-checkout-layout',
    detail: 'Checkout Layout',
    documentation: 'Styles applied to the overall layout of the checkout page',
  },
  {
    label: '.stigg-checkout-plan-header-divider',
    detail: 'Plan Header Divider',
    documentation: 'Styles applied to the divider under the plan header',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-container',
    detail: 'Downgrade to Free Plan Container',
    documentation: 'Styles applied to the container for downgrading to a free plan',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-text-header',
    detail: 'Downgrade to Free Text Header',
    documentation: 'Styles applied to the text header for downgrading to a free plan',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-plan-name',
    detail: 'Downgrade to Free Plan Name',
    documentation: 'Styles applied to the name of the free plan in the downgrade section',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-price-text',
    detail: 'Downgrade to Free Price Text',
    documentation: 'Styles applied to the price text in the downgrade section',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-alert',
    detail: 'Downgrade to Free Alert',
    documentation: 'Styles applied to the alert message in the downgrade section',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-plans-container',
    detail: 'Downgrade to Free Plans Container',
    documentation: 'Styles applied to the container for the list of free plans in the downgrade section',
  },
  {
    label: '.stigg-checkout-downgrade-to-free-arrow',
    detail: 'Downgrade to Free Arrow',
    documentation: 'Styles applied to the arrow icon in the downgrade section',
  },
  {
    label: '.stigg-checkout-summary-add-coupon-code-button',
    detail: 'Add Coupon Code Button',
    documentation: 'Styles applied to the Add Coupon Code button in the summary',
  },
  {
    label: '.stigg-checkout-summary-add-coupon-code-button-icon',
    detail: 'Add Coupon Code Button Icon',
    documentation: 'Styles applied to the icon inside the Add Coupon Code button',
  },
  {
    label: '.stigg-checkout-success-container',
    detail: 'Checkout Success Container',
    documentation: 'Styles applied to the container showing the checkout success message',
  },
  {
    label: '.stigg-checkout-summary-divider',
    detail: 'Checkout Summary Divider',
    documentation: 'Styles applied to the divider in the checkout summary',
  },
  {
    label: '.stigg-checkout-summary-cta-button',
    detail: 'Checkout Summary CTA Button',
    documentation: 'Styles applied to the Call To Action button in the checkout summary',
  },
  {
    label: '.stigg-checkout-summary-cta-button-text',
    detail: 'Checkout Summary CTA Button Text',
    documentation: 'Styles applied to the text inside the Call To Action button in the checkout summary',
  },
];

export const getWidgetMonacoSuggestions = (widgetIdentifier: WidgetIdentifier): MonacoSuggestions[] => {
  switch (widgetIdentifier) {
    case WidgetIdentifier.Paywall:
      return PAYWALL_MONACO_SUGGESTIONS;
    case WidgetIdentifier.CustomerPortal:
      return CUSTOMER_PORTAL_MONACO_SUGGESTIONS;
    case WidgetIdentifier.Checkout:
      return CHECKOUT_MONACO_SUGGESTIONS;
    default:
      return [];
  }
};
