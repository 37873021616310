import { gql } from '@apollo/client';
import { t } from 'i18next';
import { MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables } from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchSubscriptionByRefIdAction } from '../customersSlice';

const MARK_INVOICE_AS_PAID = gql`
  mutation MarkInvoiceAsPaid($input: MarkInvoiceAsPaidInput!) {
    markInvoiceAsPaid(input: $input)
  }
`;

export type MarkInvoiceAsPaidProps = {
  subscriptionId: string;
};

async function markInvoiceAsPaid(
  { subscriptionId }: MarkInvoiceAsPaidProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      await apolloClient.mutate<MarkInvoiceAsPaidMutation, MarkInvoiceAsPaidMutationVariables>({
        mutation: MARK_INVOICE_AS_PAID,
        variables: { input: { environmentId: accountReducer.currentEnvironmentId, subscriptionId } },
      });
      await dispatch(fetchSubscriptionByRefIdAction({ refId: subscriptionId, isSilentLoading: true }));
    },
    {
      successMessage: t('subscriptions.api.markInvoiceAsPaidSuccess'),
      failureMessageHandler: () => t('subscriptions.api.markInvoiceAsPaidFailed'),
    },
    dispatch,
  );
}

export { markInvoiceAsPaid };
