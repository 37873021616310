import React, { FC } from 'react';
import { PricingType, SubscriptionStatus } from '@stigg-types/apiTypes';
import { GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { CustomerFilterChip } from './CustomerFilterChip';
import { ExtraSubscriptionStatuses } from './customerPage/customerSubscriptions/SubscriptionStatusChip';
import { CUSTOMER_PLAN_FILTER_OPTIONS, SUBSCRIPTION_STATUS_FILTER_OPTIONS } from './CustomerFiltersDefinitions';

interface CustomerFiltersProps {
  planPriceFilter: PricingType[];
  setPlanPriceFilter: (value: PricingType[]) => void;
  subscriptionStatusFilter: (SubscriptionStatus | ExtraSubscriptionStatuses)[];
  setSubscriptionStatusFilter: (value: (SubscriptionStatus | ExtraSubscriptionStatuses)[]) => void;
}

export const CustomerFilters: FC<CustomerFiltersProps> = ({
  planPriceFilter,
  setPlanPriceFilter,
  subscriptionStatusFilter,
  setSubscriptionStatusFilter,
}) => {
  const options = SUBSCRIPTION_STATUS_FILTER_OPTIONS.filter(({ value }) => value !== SubscriptionStatus.PaymentPending);
  return (
    <GridFlex.Row
      ml={2}
      columnGap={2}
      rowGap={3}
      container
      wrap="wrap"
      flex={1}
      justifyContent="flex-end"
      alignItems="center">
      <CustomerFilterChip
        label={t('customers.filters.plan.label')}
        options={CUSTOMER_PLAN_FILTER_OPTIONS}
        value={planPriceFilter}
        onChange={setPlanPriceFilter}
      />
      <CustomerFilterChip
        label={t('customers.filters.status.label')}
        options={options}
        value={subscriptionStatusFilter}
        onChange={setSubscriptionStatusFilter}
      />
    </GridFlex.Row>
  );
};
