import { useScrollableElement, StepAccordionWithConfirm } from '@stigg-components';
import React, { ReactElement } from 'react';
import { get, omit } from 'lodash';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { ChargeTitle } from '../../components/ChargeTitle';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { ChargeType } from '../SetPriceWizardForm.types';
import { UsageBasedPrice } from './usageBasedPrice/UsageBasedPrice';
import { getChargeGroupId } from '../../../../utils/priceGroups';
import { BasePrice } from './basePrice/BasePrice';

export type ChargeProps = {
  index: number;
  isFocused: boolean;
  onRemove: () => void;
  onConfirm: () => void;
  onToggle: () => void;
  permanent: boolean;
};

export function ChargeAccordion({ index, isFocused, onRemove, onConfirm, onToggle, permanent }: ChargeProps) {
  const { aPackage, formRenderProps, initialFocusedSection } = useSetPriceWizardFormContext();
  const { values, setFieldValue } = formRenderProps;
  const { charges } = values;
  const currentCharge = charges[index];
  const isAddon = aPackage.type === 'Addon';

  const currentChargeErrors = get(formRenderProps.errors, `charges[${index}]`);
  const hasErrors = !isEmpty(currentChargeErrors);
  const hasErrorsWithoutIsConfirmed = !isEmpty(omit(currentChargeErrors, 'isConfirmed'));

  let dataTestId = `charge-${currentCharge.type}`;
  if (currentCharge.type === ChargeType.UsageBased && currentCharge.feature?.refId) {
    dataTestId += `-${currentCharge.feature.refId}`;
  }

  const { elementRef } = useScrollableElement({
    shouldAutoScroll: () =>
      initialFocusedSection?.stepId === 'charges' &&
      initialFocusedSection.priceGroupId === getChargeGroupId(currentCharge),
  });

  const onConfirmButton = () => {
    setFieldValue(`charges[${index}].isConfirmed`, true);
    onConfirm();
  };

  let content: ReactElement;

  switch (currentCharge.type) {
    case ChargeType.BasePrice: {
      content = <BasePrice index={index} />;
      break;
    }
    case ChargeType.UsageBased: {
      content = <UsageBasedPrice index={index} />;
      break;
    }
    default:
      return null;
  }

  return isAddon ? (
    content
  ) : (
    <StepAccordionWithConfirm
      ref={elementRef}
      dataTestId={dataTestId}
      minHeaderHeight={71}
      isFocused={isFocused}
      onToggle={onToggle}
      hasErrors={hasErrors}
      onRemove={onRemove}
      confirmRemoveDialogTitle={t(`pricing.deleteChargeDialogTitle`)}
      confirmRemoveDialogContent={t(`pricing.deleteChargeDialogContent`)}
      permanent={permanent}
      header={<ChargeTitle charge={currentCharge} bold fallbackTitle={t('pricing.charges.usageBased.title')} />}
      isConfirmed={currentCharge.isConfirmed}
      isConfirmDisabled={hasErrorsWithoutIsConfirmed}
      onConfirm={onConfirmButton}>
      {content}
    </StepAccordionWithConfirm>
  );
}
