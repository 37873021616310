import { OptionsDropdownType } from '@stigg-components/types';
import { t } from 'i18next';
import { Text, Icon } from '@stigg-components';

const EditIcon = () => <Icon type="reactFeather" icon="Edit2" color="active" size={20} />;
const ArchiveIcon = () => <Icon type="reactFeather" icon="Archive" color="active" size={20} />;
const DuplicateIcon = () => <Icon type="custom" icon="Duplicate" color="active" size={20} overrideStroke />;

export function getProductMenuOptions({
  setEditDialogOpen,
  setDuplicateDialogOpen,
  setDeleteDialogOpen,
  isAWSMarketplaceProduct,
  duplicateProductEnabled,
}: {
  setEditDialogOpen: (open: boolean) => void;
  setDuplicateDialogOpen: (open: boolean) => void;
  setDeleteDialogOpen: (open: boolean) => void;
  isAWSMarketplaceProduct: boolean;
  duplicateProductEnabled: boolean;
}) {
  let productCardOptions: OptionsDropdownType;

  if (duplicateProductEnabled) {
    productCardOptions = [
      {
        icon: EditIcon,
        text: t('products.options.edit'),
        onClick: () => setEditDialogOpen(true),
      },
      {
        icon: DuplicateIcon,
        text: t('products.options.duplicate'),
        onClick: () => setDuplicateDialogOpen(true),
        disabled: isAWSMarketplaceProduct,
        tooltip: isAWSMarketplaceProduct
          ? {
              placement: 'left',
              title: <Text.B2 color="primary">{t('products.cantDuplicateAwsMarkteplaceProductTooltipText')}</Text.B2>,
            }
          : undefined,
      },
      {
        icon: ArchiveIcon,
        text: t('products.options.delete'),
        onClick: () => setDeleteDialogOpen(true),
      },
    ];
  } else {
    productCardOptions = [
      {
        icon: EditIcon,
        text: t('products.options.edit'),
        onClick: () => setEditDialogOpen(true),
      },
      {
        icon: ArchiveIcon,
        text: t('products.options.delete'),
        onClick: () => setDeleteDialogOpen(true),
      },
    ];
  }

  return productCardOptions;
}
