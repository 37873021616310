import { drawFormFields, Field, GridFlex, FormRenderProps, Text } from '@stigg-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import React, { useMemo } from 'react';
import { SubscriptionUpdateUsageCutoffBehavior } from '@stigg-types/apiTypes';
import * as S from './CustomerJourney.style';
import { TooltipFields } from '../../../../../components/InformationTooltip';

export type UsageResetCutoffFormFields = {
  usageResetCutoffBehavior: SubscriptionUpdateUsageCutoffBehavior;
};

export const UsageResetCutoffFormFieldsNames: (keyof UsageResetCutoffFormFields)[] = ['usageResetCutoffBehavior'];

type UsageResetCutoffConfigurationProps = {
  formRenderProps: FormRenderProps<UsageResetCutoffFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
};

function computeFields(
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<UsageResetCutoffFormFields>[] {
  return [
    {
      type: 'inline',
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              <Trans
                i18nKey="products.customerJourney.subscriptionUpdate.usageResetCutoff"
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
          ),
        },
        {
          id: 'usageResetCutoffBehavior',
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: [
            {
              value: SubscriptionUpdateUsageCutoffBehavior.AlwaysReset,
              displayValue: t('products.customerJourney.usageResetCutoff.alwaysReset'),
            },
            {
              value: SubscriptionUpdateUsageCutoffBehavior.NeverReset,
              displayValue: t('products.customerJourney.usageResetCutoff.neverReset'),
            },
            {
              value: SubscriptionUpdateUsageCutoffBehavior.BillingPeriodChange,
              displayValue: t('products.customerJourney.usageResetCutoff.billingPeriodChanged'),
            },
          ],
        },
      ],
    },
  ];
}

function useFormFields(readonly: boolean, readonlyHideDottedText: boolean, readonlyTooltip: TooltipFields | undefined) {
  return useMemo<Field<UsageResetCutoffFormFields>[]>(
    () => computeFields(readonly, readonlyHideDottedText, readonlyTooltip),
    [readonly, readonlyHideDottedText, readonlyTooltip],
  );
}

export function UsageResetCutoffConfiguration({
  formRenderProps,
  readonly = false,
  readonlyHideDottedText = false,
  readonlyTooltip,
  highlight,
}: UsageResetCutoffConfigurationProps) {
  const fields = useFormFields(readonly, readonlyHideDottedText, readonlyTooltip);
  const content = (
    <GridFlex.Column container rowSpacing={3}>
      {drawFormFields(fields, formRenderProps)}
    </GridFlex.Column>
  );

  return highlight ? <S.StyledBox $isHighlight={highlight}>{content}</S.StyledBox> : content;
}
