import { BillingPeriod, PriceFragment, ProductPlanFragment } from '@stigg-types/apiTypes';
import { countBy } from 'lodash';

export function computeDefaultBillingPeriodFromList(billingPeriods: BillingPeriod[]): BillingPeriod {
  const counts = countBy(billingPeriods);
  return (counts[BillingPeriod.Monthly] || 0) > (counts[BillingPeriod.Annually] || 0)
    ? BillingPeriod.Monthly
    : BillingPeriod.Annually;
}

export function computeDefaultBillingPeriod(plans: ProductPlanFragment[]): BillingPeriod {
  const billingPeriods = plans
    .flatMap((x) => x.prices)
    .filter((x): x is PriceFragment => x !== null && x !== undefined)
    .map((x) => x.billingPeriod);

  return computeDefaultBillingPeriodFromList(billingPeriods);
}
