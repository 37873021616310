import styled from 'styled-components/macro';
import { PageCard } from '../PageCard';

export const LabelCard = styled(PageCard)`
  padding: 0 8px;
  background: ${({ theme }) => theme.itamar.palette.grey[50]};
  border: 1px solid ${({ theme }) => theme.itamar.palette.grey[100]};

  > div:last-child {
    padding: 0;
  }
`;
