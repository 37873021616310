import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import {
  UsageEventsWithCustomerQuery,
  UsageEventsWithCustomerQueryVariables,
  UsageEventWithCustomerFragment,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

export const FETCH_USAGE_EVENTS = gql`
  query UsageEventsWithCustomer($input: UsageEventsInput!) {
    usageEvents(input: $input) {
      events {
        ...UsageEventWithCustomerFragment
      }
    }
  }
  fragment UsageEventWithCustomerFragment on UsageEvent {
    id
    customerId
    resourceId
    eventName
    timestamp
    dimensions
    idempotencyKey
    customer {
      name
      email
      createdAt
    }
  }
`;

type FetchEventsListProps = {
  environmentId: string;
  customerId?: string;
  // This is being used in the slice action handler to determine
  // if the data is being fetched for the first time
  isInitialLoad?: boolean;
};

async function fetchUsageEventsWithCustomer(
  { environmentId, customerId }: FetchEventsListProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<UsageEventWithCustomerFragment[]> {
  return executeOperationSafely(
    async () => {
      const res = await apolloClient.query<UsageEventsWithCustomerQuery, UsageEventsWithCustomerQueryVariables>({
        query: FETCH_USAGE_EVENTS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId,
            customerId,
          },
        },
      });

      return res.data.usageEvents.events;
    },
    {
      failureMessageHandler: () => t('features.api.failFetchUsageEvents'),
    },
    dispatch,
  );
}

export { fetchUsageEventsWithCustomer };
