import { GridFlex, Icon, Text, CheckboxItem } from '@stigg-components';
import React from 'react';
import { BillingPeriod } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getPricePeriodUnit } from '../utils/priceConversions';
import {
  updatePricePeriods,
  getDisabledTooltip,
  getPricePeriodsToUpdate,
  updateOveragePricePeriods,
} from './BillingPeriodStep.utils';

export function BillingPeriodStep() {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { setValues, setTouched, errors } = formRenderProps;
  const { billingPeriods, overageCharges, charges, priceLocalization } = formRenderProps.values;

  const onBillingPeriodChange = (billingPeriod: BillingPeriod, checked: boolean) => {
    const newBillingPeriods = checked
      ? [...billingPeriods, billingPeriod]
      : billingPeriods.filter((period) => period !== billingPeriod);

    const oldPricePeriodUnit = getPricePeriodUnit(billingPeriods);
    const newPricePeriodUnit = getPricePeriodUnit(newBillingPeriods);

    const newTouched = cloneDeep(formRenderProps.touched);
    const newValues = cloneDeep(formRenderProps.values);

    newValues.billingPeriods = newBillingPeriods;

    // when billing periods list is change, update charges prices values accordingly
    const pricePeriodsToUpdate = getPricePeriodsToUpdate(charges, priceLocalization);
    updatePricePeriods(
      pricePeriodsToUpdate,
      billingPeriod,
      checked,
      oldPricePeriodUnit,
      newPricePeriodUnit,
      newValues,
      newTouched,
    );

    updateOveragePricePeriods(overageCharges.charges, newBillingPeriods, newValues, newTouched);

    // setting both values and touched in one
    // action to prevent redundant rendering
    setTouched(newTouched, false);
    setValues(newValues);
  };

  return (
    <>
      <Text.B2 color="secondary" mb={6}>
        {t('pricing.selectBillingPeriodLabel')}
      </Text.B2>

      <GridFlex.Row gap={4}>
        {[BillingPeriod.Monthly, BillingPeriod.Annually].map((billingPeriod) => {
          const checked = billingPeriods.includes(billingPeriod);
          const disabledTooltip = getDisabledTooltip(billingPeriod, charges);

          return (
            <CheckboxItem
              key={billingPeriod}
              value={billingPeriod}
              checked={checked}
              disabled={!!disabledTooltip}
              tooltip={disabledTooltip}
              dataTestId={`billing-period-${billingPeriod}`}
              label={
                <GridFlex.RowCenter gap={2}>
                  <Icon icon="Calendar" type="reactFeather" color={checked ? 'primary.main' : 'active'} />
                  <Text.B2 color={checked ? 'primary.main' : 'secondary'}>
                    {t(`pricing.billingPeriodDescriptionsNew.${billingPeriod}`)}
                  </Text.B2>
                </GridFlex.RowCenter>
              }
              onChange={(_, checked) => onBillingPeriodChange(billingPeriod, checked)}
            />
          );
        })}
      </GridFlex.Row>

      <Text.B2 color="error" mt={2} height={21}>
        {errors.billingPeriods}
      </Text.B2>
    </>
  );
}
