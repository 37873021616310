import React from 'react';
import { Field, FormRenderProps, useDummyFormRenderProps } from '@stigg-components';
import { t } from 'i18next';
import { ProductFragment } from '@stigg-types/apiTypes';
import { ExperimentPreset } from '../ExperimentWizard';
import { CustomerJourneyExperimentDiff } from '../../commom/diff/customerJourney/CustomerJourneyExperimentDiff';
import { EditProductSettingsFormFields } from '../../../../products/components/productPage/customerJourney/types';

export function ProductSettingsVariationEditor({
  initialValues,
  formRenderProps,
  product,
}: {
  initialValues: EditProductSettingsFormFields;
  formRenderProps: FormRenderProps<ExperimentPreset>;
  product: ProductFragment;
}) {
  const readOnlyFormRenderProps = useDummyFormRenderProps(initialValues);
  const productPlans = product?.plans || [];

  const controlField: Field<ExperimentPreset>[] = [
    {
      label: t('experiments.wizard.controlGroupNameLabel'),
      type: 'text',
      id: 'controlGroupName',
    },
  ];
  const variantField: Field<ExperimentPreset>[] = [
    {
      label: t('experiments.wizard.variantGroupNameLabel'),
      type: 'text',
      id: 'variantGroupName',
    },
  ];

  return (
    <CustomerJourneyExperimentDiff
      productId={product.id}
      plans={productPlans}
      controlFormRenderProps={readOnlyFormRenderProps}
      variantRenderProps={formRenderProps as unknown as FormRenderProps<EditProductSettingsFormFields>}
      prefixFields={{
        formRenderProps,
        controlField,
        variantField,
      }}
    />
  );
}
