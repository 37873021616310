import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { Button, FormRenderProps, Grid, GridFlex, Icon, PaymentDetails, Text } from '@stigg-components';
import React from 'react';
import { useModal } from '@stigg-common';
import { t } from 'i18next';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { AddPaymentMethod } from '../../../AddPaymentMethod';

type ChargeCollectionMethodProps = {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
};

function DefaultPaymentMethodDetails({ customer }: Pick<ChargeCollectionMethodProps, 'customer'>) {
  const {
    defaultPaymentMethodType,
    defaultPaymentMethodLast4Digits,
    defaultPaymentExpirationMonth,
    defaultPaymentExpirationYear,
  } = customer;
  return (
    <>
      <GridFlex.RowCenter
        sx={{
          border: (theme) => theme.itamar.border.border,
          borderColor: (theme) => theme.itamar.border.borderColor,
          borderRadius: (theme) => theme.itamar.border.radius,
          paddingX: 4,
          paddingY: 2,
        }}>
        <PaymentDetails
          paymentMethodType={defaultPaymentMethodType}
          last4Digits={defaultPaymentMethodLast4Digits}
          expirationMonth={defaultPaymentExpirationMonth}
          expirationYear={defaultPaymentExpirationYear}
          iconSize={24}
          iconWrapperProps={{
            sx: {
              height: 36,
              width: 36,
              backgroundColor: (theme) => theme.itamar.palette.background.emptyState,
              borderRadius: (theme) => theme.itamar.border.radius,
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        />
      </GridFlex.RowCenter>
    </>
  );
}

function AddPaymentMethodDetails({ form, customer }: ChargeCollectionMethodProps) {
  const { errors, validateForm } = form;
  const [AddPaymentMethodModal, setIsAddPaymentMethodModalOpen] = useModal();

  return (
    <>
      <GridFlex.Row gap={2} container justifyContent="flex-start" alignItems="flex-start">
        <Grid>
          <Button
            startIcon={<Icon icon="Plus" type="reactFeather" color="primary.main" />}
            variant="outlined"
            onClick={() => setIsAddPaymentMethodModalOpen(true)}>
            {t('subscriptionForm.paymentMethod.addPayment')}
          </Button>
        </Grid>
        {errors.paymentMethod && (
          <GridFlex.RowCenter item mt={2}>
            <Icon icon="Error" size={18} color="error" />
            <Text.B2 color="error.main" ml={2}>
              {t('subscriptionForm.paymentMethod.addPaymentRequired')}
            </Text.B2>
          </GridFlex.RowCenter>
        )}
      </GridFlex.Row>
      <AddPaymentMethodModal>
        <AddPaymentMethod
          customer={customer}
          onSuccess={async () => {
            setIsAddPaymentMethodModalOpen(false);
            await validateForm();
          }}
          onCancel={() => setIsAddPaymentMethodModalOpen(false)}
        />
      </AddPaymentMethodModal>
    </>
  );
}

export function ChargeCollectionMethod({ customer, form }: ChargeCollectionMethodProps) {
  const { defaultPaymentMethodId } = customer;
  return defaultPaymentMethodId ? (
    <DefaultPaymentMethodDetails customer={customer} />
  ) : (
    <AddPaymentMethodDetails customer={customer} form={form} />
  );
}
