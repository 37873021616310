import { t } from 'i18next';
import { ReactNode, useEffect } from 'react';
import { drawFormFields, Field, Text } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { ChargeType, SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { MinimumSpendForm } from './minimumSpendForm';

export type MinimumSpendStepProps = {
  renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode;
};

export function MinimumSpendStep({ renderStepActionButtons }: MinimumSpendStepProps) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { values, setFieldValue } = formRenderProps;

  const isPayGoChargeExists = values.charges.some((charge) => charge.type === ChargeType.UsageBased);

  useEffect(() => {
    if (!isPayGoChargeExists) {
      setFieldValue('minimumSpend.enabled', false);
    }
  }, [isPayGoChargeExists, setFieldValue]);

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'switch',
      id: 'minimumSpend.enabled',
      label: t('pricing.minimumSpend.description'),
      disabled: () => !isPayGoChargeExists,
      disabledTooltipProps: {
        title: t('pricing.minimumSpend.disabledTooltip'),
      },
    },
    {
      type: 'idle',
      component: <Text.B2 color="disabled">{t('pricing.minimumSpend.note')}</Text.B2>,
      gridProps: {
        ml: 12,
      },
    },
    {
      type: 'custom',
      gridProps: {
        flexDirection: 'column',
        mt: 4,
      },
      render: () => {
        return <MinimumSpendForm />;
      },
    },
  ];

  return (
    <>
      {drawFormFields(fields, formRenderProps)}
      {renderStepActionButtons(null)}
    </>
  );
}
