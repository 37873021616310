import { FC } from 'react';
import { IconProps, Text, Link, Box } from '@stigg-components';
import { BaseCoupon, formatCoupon } from './utils';
import { useNavigation } from '../../navigation/useNavigation';

export const CouponSummary: FC<{ coupon?: BaseCoupon | null; withLink?: boolean } & Partial<IconProps>> = ({
  coupon,
  withLink,
}) => {
  const { navigateTo } = useNavigation();

  if (!coupon) {
    return null;
  }

  return (
    <Box mx={2}>
      {withLink ? (
        <Link onClick={() => navigateTo(`/coupons/${coupon?.refId}`)} display="flex">
          {formatCoupon(coupon)}
        </Link>
      ) : (
        <Text.B2 color="primary">{formatCoupon(coupon)}</Text.B2>
      )}
    </Box>
  );
};
