import { gql } from '@apollo/client';
import { t } from 'i18next';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { appRoutes } from '../../../navigation/useNavigation';
import { ArchiveAddonGroupMutation, ArchiveAddonGroupMutationVariables } from '../../../../types/apiTypes';

const ARCHIVE_ADDON_GROUP = gql`
  mutation ArchiveAddonGroup($input: ArchivePackageGroup!) {
    archivePackageGroup(input: $input) {
      packageGroupId
    }
  }
`;

async function archiveAddonGroup(
  addonGroupId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<{ id: string }> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<ArchiveAddonGroupMutation, ArchiveAddonGroupMutationVariables>({
        mutation: ARCHIVE_ADDON_GROUP,
        variables: { input: { packageGroupId: addonGroupId, environmentId: accountReducer.currentEnvironmentId } },
      });
      dispatch(navigateTo(appRoutes.addonGroupsPage()));
      return { id: addonGroupId };
    },
    {
      successMessage: t('addons.api.successAddonGroupDelete'),
      failureMessageHandler: () => t('addons.api.failAddonGroupDelete'),
    },
    dispatch,
  );
}

export { archiveAddonGroup };
