import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '../components.style';
import { GridFlex } from '../Grids';
import { Icon } from '../icon';
import { Button } from '../buttons';
import { StyledArticleCard, BoldText, StyledTitle } from './ArticleCard.style';

export type ArticleCardProps = {
  articleLink?: string;
  titleBoldText?: string;
  titleAfterBoldText: string;
  svg: React.ReactNode;
  buttonText?: string;
};

export function ArticleCard({ articleLink, titleBoldText, titleAfterBoldText, svg, buttonText }: ArticleCardProps) {
  const theme = useTheme();
  const clickable = !!articleLink;

  const handleClick = () => {
    if (articleLink) {
      window.open(articleLink, '_blank');
    }
  };

  return (
    <StyledArticleCard p={6} sx={{ minWidth: 262, maxWidth: 288 }} $clickable={clickable}>
      <GridFlex.Column container height="100%">
        <GridFlex.ColumnMiddle container justifyContent="flex-start" flex={1} pb={6}>
          <Box mb={6}>{svg}</Box>
          <StyledTitle color="primary">
            <BoldText color="primary">{titleBoldText}</BoldText> {titleAfterBoldText}
          </StyledTitle>
        </GridFlex.ColumnMiddle>

        <GridFlex.Column container alignItems="flex-start" position="relative">
          <Button
            $outlined
            color={theme.isLightTheme ? 'secondary' : 'outlineBorder'}
            textColor={theme.isLightTheme ? undefined : 'primary'}
            startIcon={<Icon icon="FileText" />}
            onClick={handleClick}
            fullWidth>
            {buttonText || 'Read More'}
          </Button>
        </GridFlex.Column>
      </GridFlex.Column>
    </StyledArticleCard>
  );
}
