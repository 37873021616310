import { gql } from '@apollo/client';
import { RESET_PERIOD_CONFIGURATION } from './resetPeriodConfigurationFragment';

export const PACKAGE_ENTITLEMENT_FRAGMENT = gql`
  fragment PackageEntitlementFragment on PackageEntitlement {
    id
    packageId
    featureId
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    resetPeriod
    createdAt
    order
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
    environmentId
    feature {
      id
      displayName
      refId
      description
      featureStatus
      updatedAt
      environmentId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
      hasMeter
    }
  }

  ${RESET_PERIOD_CONFIGURATION}
`;
