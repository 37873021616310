import { ConfirmationDialog, Text } from '@stigg-components';
import { t } from 'i18next';
import { Action, Location } from 'history';
import { browserHistory } from '../../browserHistory';

export const NOOP = () => {};

export function replayNavigationAction({ location, action }: { location: Location; action: Action }) {
  switch (action) {
    case 'PUSH':
      browserHistory.push(location);
      break;
    case 'POP':
      browserHistory.goBack();
      break;
    case 'REPLACE':
      browserHistory.replace(location);
      break;
    default:
  }
}

export function ExitConfirmationDialog({
  open,
  onDialogClose,
}: {
  open: boolean;
  onDialogClose: (isConfirmed: boolean) => void;
}) {
  return (
    <ConfirmationDialog
      width={574}
      open={open}
      handleClose={onDialogClose}
      title={t('sharedComponents.closeWithoutSavingTitle')}
      content={<Text.B2 color="primary">{t('sharedComponents.closeWithoutSavingConfirmation')}</Text.B2>}
      confirmButtonColor="primary"
      confirmationButtonText={t('sharedComponents.closeWithoutSavingCTA')}
      cancelButtonText={t('sharedComponents.keepEditing')}
      reverseButtonsOrder
    />
  );
}
