import { AddonDependencyChangeFragment, ChangeType } from '@stigg-types/apiTypes';
import { BaseAddonChangeRow, BaseAddonChangeEntity } from './BaseAddonChangeRow';

const mapToAddonChangeType = (entity?: AddonDependencyChangeFragment | null): BaseAddonChangeEntity | null => {
  if (!entity) {
    return null;
  }

  return {
    displayName: entity.displayName,
    refId: entity.refId,
  };
};

export function AddonDependencyChangeRow({
  before,
  after,
  changeType,
}: {
  before?: AddonDependencyChangeFragment | null;
  after?: AddonDependencyChangeFragment | null;
  changeType?: ChangeType | null;
}) {
  return (
    <BaseAddonChangeRow
      before={mapToAddonChangeType(before)}
      after={mapToAddonChangeType(after)}
      changeType={changeType}
    />
  );
}
