import { createContext, useContext } from 'react';

type StiggIntercomContextType = {
  isVisible: boolean;
  setHideIntercom: (id: string, shouldHideIntercom: boolean) => void;
};

export const StiggIntercomContext = createContext<StiggIntercomContextType | null>(null);

export function useStiggIntercomContext() {
  const ctx = useContext(StiggIntercomContext);
  if (!ctx) {
    throw new Error('Could not find StiggIntercomContext');
  }
  return ctx;
}
