import { gql } from '@apollo/client';
import {
  CouponFragment,
  UpdateCouponInput,
  UpdateOneCouponMutation,
  UpdateOneCouponMutationVariables,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { fetchCouponByRefIdAction } from '../couponsSlice';
import { COUPON_FRAGMENT } from '../queries/fetchCoupons';

const UPDATE_COUPON = gql`
  mutation UpdateOneCouponMutation($input: UpdateCouponInput!) {
    updateOneCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

async function updateCoupon(
  couponData: Omit<UpdateCouponInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<CouponFragment> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const coupon: UpdateCouponInput = {
        ...couponData,
        environmentId: accountReducer.currentEnvironmentId,
      };

      const response = await apolloClient.mutate<UpdateOneCouponMutation, UpdateOneCouponMutationVariables>({
        mutation: UPDATE_COUPON,
        variables: { input: coupon },
      });

      const updatedCoupon = response.data?.updateOneCoupon;
      await dispatch(
        fetchCouponByRefIdAction({ environmentId: accountReducer.currentEnvironmentId, refId: coupon.refId }),
      );
      return updatedCoupon;
    },
    {
      successMessage: t('coupons.api.successUpdate'),
      failureMessageHandler: () => t('coupons.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateCoupon };
