import { ApiKey, ApiKeyType, Feature, FeatureType } from '@stigg-types/apiTypes';
import { isMetered } from '../../utils/isMetered';
import { SDK_TO_SNIPPET_DATA, SdkType } from './sdkToSnippetData';

export enum CodeSnippetType {
  INIT_SDK = 'INIT_SDK',
  CHECK_ENTITLEMENT = 'CHECK_ENTITLEMENT',
  REPORT_USAGE = 'REPORT_USAGE',
  REPORT_EVENT = 'REPORT_EVENT',
}

export function getFeatureCodeSnippet(
  feature: Pick<Feature, 'featureType' | 'refId'>,
  apiKeys: ApiKey[],
  codeSnippetType: CodeSnippetType,
  sdkType: SdkType,
): string {
  const serverApiKey = apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Server)?.token as string;
  const clientApiKey = apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Client)?.token as string;
  const isMeteredFeature = isMetered(feature);

  const snippetData = SDK_TO_SNIPPET_DATA[sdkType];
  const featureSnippetCode = snippetData?.featureSnippetCode;

  if (!snippetData || !featureSnippetCode) {
    return '';
  }

  switch (codeSnippetType) {
    case CodeSnippetType.INIT_SDK:
      return featureSnippetCode.initializeSDK(snippetData.apiKeyType === 'CLIENT' ? clientApiKey : serverApiKey);
    case CodeSnippetType.CHECK_ENTITLEMENT:
      switch (feature.featureType) {
        case FeatureType.Boolean:
          return featureSnippetCode.checkBooleanEntitlement(feature.refId);
        case FeatureType.Number:
          return isMeteredFeature
            ? featureSnippetCode.checkMeteredEntitlement(feature.refId)
            : featureSnippetCode.checkNumericEntitlement(feature.refId);
        default:
          return '';
      }
    case CodeSnippetType.REPORT_USAGE:
      return featureSnippetCode.reportUsageForMeteredFeature?.(feature.refId) ?? '';
    case CodeSnippetType.REPORT_EVENT:
      return featureSnippetCode.reportEventForMeteredFeature?.() ?? '';
    default:
      return '';
  }
}
