import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParamsArray = <T extends string>(
  param: string,
  optionalValues: T[],
): [T[], (newValue: T[]) => void] => {
  const location = useLocation();
  const history = useHistory();

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const qsParam = searchParams.getAll(param);

    return qsParam.filter((value) => optionalValues.includes(value as T)) as T[];
  }, [location.search, param, optionalValues]);

  const setValue = useCallback(
    (newValue: T[]) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(param);
      newValue.forEach((planPriceToAppend) => searchParams.append(param, planPriceToAppend));

      history.replace({ search: searchParams.toString() });
    },
    [location.search, param, history],
  );

  return useMemo(() => [value, setValue], [value, setValue]);
};
