import { t } from 'i18next';
import { Icon, GridFlex, Text, IconColor } from '@stigg-components';
import { TextColor } from '../../../../../theme';

export function FallbackTierIcon({ iconColor, textColor }: { iconColor: IconColor; textColor: TextColor }) {
  return (
    <GridFlex.RowCenter columnGap={2}>
      <Icon type="custom" icon="InfinityIcon" overrideStroke color={iconColor} size={22} />
      <Text.B2 color={textColor}>{t('pricing.fallbackTierText')}</Text.B2>
    </GridFlex.RowCenter>
  );
}
