import styled from 'styled-components/macro';
import { Card } from '../components.style';
import { Text } from '../Typography';

export const BoldText = styled.span<{ $fontWeight?: number }>`
  ${({ $fontWeight = 700 }) => `font-weight: ${$fontWeight};`}
`;

export const StyledTitle = styled(Text.H6)`
  font-size: 17px;
  font-weight: 300;
  line-height: 24px;
`;

export const StyledArticleCard = styled(Card)<{ $clickable?: boolean }>`
  transition-duration: 150ms;

  &:hover {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }
`;
