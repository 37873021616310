import { gql } from '@apollo/client';

const OVERAGE_PRICE_FEATURE_FRAGMENT = gql`
  fragment PriceFeatureFragment on Feature {
    id
    displayName
    refId
    featureUnits
    featureUnitsPlural
    meterType
    featureType
    environmentId
    updatedAt
    featureStatus
    hasMeter
  }
`;

export const OVERAGE_PRICE_FRAGMENT = gql`
  fragment OveragePriceFragment on Price {
    id
    billingModel
    billingPeriod
    billingCadence
    billingCountryCode
    price {
      amount
      currency
    }
    blockSize
    tiersMode
    tiers {
      ...PriceTierFragment
    }
    featureId
    packageId
    crmId
    crmLinkUrl
    feature {
      ...PriceFeatureFragment
    }
    isOverridePrice
  }
  ${OVERAGE_PRICE_FEATURE_FRAGMENT}
`;
