import { getStiggTheme } from '@stigg-theme';

export type ChipColor =
  | 'primary'
  | 'primary.outlinedRestingBackground'
  | 'primary.outlinedHoverBackground'
  | 'expired'
  | 'secondary'
  | 'warning'
  | 'success'
  | 'trial'
  | 'draft'
  | 'grey.400'
  | 'empty'
  | 'disabled'
  | 'other.outlineBorder'
  | 'action.disabledBackground'
  | 'action.disabled'
  | 'action.active'
  | 'error'
  | 'background.lightBackground2';

export const getChipColor = (color: ChipColor) => {
  const { theme } = getStiggTheme();
  switch (color) {
    case 'primary':
      return theme.itamar.palette.primary.main;
    case 'primary.outlinedRestingBackground':
      return theme.itamar.palette.primary.outlinedRestingBackground;
    case 'primary.outlinedHoverBackground':
      return theme.itamar.palette.primary.outlinedHoverBackground;
    case 'secondary':
      return theme.itamar.palette.secondary.main;
    case 'warning':
      return theme.itamar.palette.warning.main;
    case 'error':
      return theme.itamar.palette.error.main;
    case 'expired':
      return theme.itamar.palette.expiredColor;
    case 'trial':
      return theme.itamar.palette.info.light;
    case 'draft':
      return theme.itamar.palette.grey[100];
    case 'grey.400':
      return theme.itamar.palette.grey[400];
    case 'empty':
      return theme.itamar.palette.background.lightBackground2;
    case 'disabled':
      return theme.itamar.palette.text.disabled;
    case 'other.outlineBorder':
      return theme.itamar.palette.other.outlineBorder;
    case 'action.disabledBackground':
      return theme.itamar.palette.action.disabledBackground;
    case 'action.disabled':
      return theme.itamar.palette.action.disabled;
    case 'action.active':
      return theme.itamar.palette.action.active;
    case 'background.lightBackground2':
      return theme.itamar.palette.background.lightBackground2;

    default:
      return color;
  }
};
