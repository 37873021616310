import { CouponFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { UpdateCouponForm, UpdateCouponFormFields } from './UpdateCouponForm';
import { Dialog } from '../../../../components/Dialog';

export type UpdateCouponDialogProps = {
  coupon: CouponFragment;
  onUpdate: (values: UpdateCouponFormFields) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};
export function UpdateCouponDialog({ coupon, onUpdate, open, setOpen }: UpdateCouponDialogProps) {
  return (
    <Dialog
      content={<UpdateCouponForm onUpdate={onUpdate} onCancel={() => setOpen(false)} coupon={coupon} />}
      titleText={t('coupons.editDialogTitle')}
      isOpen={open}
      onCancel={() => setOpen(false)}
      aria-labelledby="edit-coupon-dialog"
    />
  );
}
