import React from 'react';
import { t } from 'i18next';
import { Icon, Text, InformationTooltip, Box, GridFlex } from '@stigg-components';
import { InformationTooltipProps } from '../../../../../components/InformationTooltip';

export function HiddenIcon({
  isHidden,
  tooltip,
  tooltipPlacement = 'top',
  content,
  size,
}: {
  isHidden: boolean;
  tooltip?: React.ReactNode;
  tooltipPlacement?: InformationTooltipProps['placement'];
  content?: React.ReactNode;
  size?: number;
}) {
  let icon = <Icon icon="Eye" type="reactFeather" color="default" size={size} />;
  let defaultTooltip = <Text.B2>{t('widgets.visibility.visibleInPricingTables')}</Text.B2>;
  if (isHidden) {
    icon = <Icon icon="EyeOff" type="reactFeather" color="disabled" size={size} />;
    defaultTooltip = <Text.B2>{t('widgets.visibility.notVisibleInPricingTables')}</Text.B2>;
  }

  return (
    <InformationTooltip $padding={2} arrow placement={tooltipPlacement} title={tooltip || defaultTooltip}>
      <GridFlex.RowMiddle>
        <Box display="flex">{icon}</Box>
        {content}
      </GridFlex.RowMiddle>
    </InformationTooltip>
  );
}
