import { t } from 'i18next';
import moment from 'moment';
import { compact, isEmpty, keyBy, map, omit, partition, uniqBy } from 'lodash';
import { AwsChip, generateRandomSlug } from '@stigg-common';
import { GridFlex, Icon, InformationTooltip, Text } from '@stigg-components';
import {
  BillableFeatureInput,
  BillingCadence,
  BillingPeriod,
  Currency,
  CustomerSubscriptionDataFragment,
  PackageStatus,
  PaymentCollectionMethod,
  PlanEntitlementFragment,
  PlanFragment,
  PlanListEntitlementFragment,
  PlanListFragment,
  PriceFragment,
  PriceOverrideInput,
  PricingType,
  ProductListItemFragment,
  SubscriptionMinimumSpendValueInput,
} from '@stigg-types/apiTypes';
import { EntitlementFields } from '../../../../../entitlements/components/entitlementSettings/types';
import {
  AddonsPriceOverride,
  BillableFeaturesPriceOverride,
  PlanPricePoints,
  SubscriptionAddon,
  SubscriptionFormFields,
} from './SubscriptionForm.types';
import { priceIconByPriceType } from '../../../../../packages/pricing/utils/priceTypeIcons';
import { mapSubscriptionToBillableFeatures } from '../priceBreakdown';
import { DEFAULT_CURRENCY } from '../../../../../packages/pricing/components/currency/currencyUtils';
import { getPrice } from '../../../../../packages/pricing/utils/getPrice';

const DEFAULT_INVOICE_DAYS_UNTIL_DUE = 30;

export const generateSubscriptionRefId = (planId: string) => `subscription-${planId}-${generateRandomSlug()}`;

export const getPlanSelectOptions = (plans: (PlanListFragment | PlanFragment)[]) => {
  const isPlanDisabled = (plan: PlanListFragment | PlanFragment) => plan.status === PackageStatus.Draft;

  return plans.map((plan) => {
    const isAwsMarketplaceManagedPlan = !!plan.awsMarketplacePlanDimension;
    const isDisabled = isAwsMarketplaceManagedPlan || isPlanDisabled(plan);
    const pricingType = plan.pricingType!;

    const planItem = {
      disabled: isDisabled,
      key: plan.refId,
      value: plan.refId,
      displayValue: (
        <GridFlex.RowCenter gap={2}>
          <InformationTooltip
            arrow
            placement="left"
            $padding={2}
            title={<Text.B2>{t(`pricing.pricingType.${pricingType}`)}</Text.B2>}>
            <GridFlex.RowCenter>
              <Icon icon={priceIconByPriceType[plan.pricingType!]} type="custom" size={18} />
            </GridFlex.RowCenter>
          </InformationTooltip>
          {plan.displayName}
          {isAwsMarketplaceManagedPlan ? <AwsChip logoOnly /> : null}
        </GridFlex.RowCenter>
      ),
      disabledText: !isAwsMarketplaceManagedPlan ? `(${t('subscriptions.draftPlan')})` : undefined,
    };

    return planItem;
  });
};

type PlanPriceWithFilters = {
  plan: Pick<PlanFragment, 'prices'> | undefined;
  billingCountryCode?: string | null;
  customerBillingCurrency?: Currency | null;
};

export const getPlanPrices = ({
  plan,
  billingCountryCode,
  customerBillingCurrency,
  billingPeriod,
  billableFeaturesPriceOverride,
}: PlanPriceWithFilters & {
  billingPeriod?: BillingPeriod;
  billableFeaturesPriceOverride?: BillableFeaturesPriceOverride;
}) => {
  let planPrices = plan?.prices;

  if (billingPeriod) {
    planPrices = planPrices?.filter((price) => price.billingPeriod === billingPeriod);
  }

  if (billingCountryCode || customerBillingCurrency) {
    const isDefaultCurrency = !!customerBillingCurrency && customerBillingCurrency === DEFAULT_CURRENCY;

    const countryCodeCondition = (price: PriceFragment) =>
      billingCountryCode
        ? price?.billingCountryCode === billingCountryCode
        : !isDefaultCurrency || !price.billingCountryCode;
    const currencyCondition = (price: PriceFragment) =>
      customerBillingCurrency && price ? getPrice(price).currency === customerBillingCurrency : true;

    planPrices = planPrices?.filter((price) => countryCodeCondition(price) && currencyCondition(price));
  } else {
    planPrices = planPrices?.filter((price) => !price.billingCountryCode);
  }

  if (billableFeaturesPriceOverride) {
    planPrices = planPrices?.map((price) => {
      const featureRefId = price.feature?.refId;
      const overriddenPrice = featureRefId
        ? billableFeaturesPriceOverride[featureRefId]
        : billableFeaturesPriceOverride.baseCharge;
      return overriddenPrice || price;
    });
  }

  return planPrices;
};

export const getPlanPricePoints = ({
  plan,
  billingCountryCode,
  customerBillingCurrency,
}: PlanPriceWithFilters): PlanPricePoints => {
  const planPrices = getPlanPrices({ plan, billingCountryCode, customerBillingCurrency });

  const [monthlyPrices, annualPrices] = partition(planPrices, (price) => price.billingPeriod === BillingPeriod.Monthly);

  return {
    ...(monthlyPrices?.length ? { [BillingPeriod.Monthly]: monthlyPrices } : {}),
    ...(annualPrices?.length ? { [BillingPeriod.Annually]: annualPrices } : {}),
  };
};

export const getPlanDefaultBillingData = ({
  plan,
  customerBillingCurrency,
}: Exclude<PlanPriceWithFilters, 'billingCountryCode'>) => {
  const planPricePoints = getPlanPricePoints({ plan, customerBillingCurrency });
  const billingPeriod = Object.keys(planPricePoints)[0];
  const billingCountryCode = planPricePoints?.[billingPeriod]?.[0].billingCountryCode || null;

  return { planPricePoints, billingPeriod, billingCountryCode };
};

export const mapPackageEntitlementsToEntitlementFields = (
  entitlements: Array<PlanEntitlementFragment | PlanListEntitlementFragment>,
): EntitlementFields[] => {
  return (
    entitlements
      ?.filter(({ isCustom }) => isCustom)
      .map((entitlement) => {
        const newEntitlement: EntitlementFields = {
          uuid: generateRandomSlug(),
          id: entitlement.id,
          feature: entitlement.feature,
          isConfirmed: true,
          usageLimit: 1,
          hasUnlimitedUsage: false,
          isCustom: false,
          hasSoftLimit: entitlement.hasSoftLimit,
          resetPeriod: entitlement.resetPeriod,
        };

        if (entitlement.resetPeriodConfiguration?.__typename === 'YearlyResetPeriodConfig') {
          newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.yearlyAccordingTo;
        } else if (entitlement.resetPeriodConfiguration?.__typename === 'MonthlyResetPeriodConfig') {
          newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.monthlyAccordingTo;
        } else if (entitlement.resetPeriodConfiguration?.__typename === 'WeeklyResetPeriodConfig') {
          newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.weeklyAccordingTo;
        }

        return newEntitlement;
      }) || []
  );
};

export const mapSubscriptionEntitlementsToEntitlementFields = (
  entitlements: CustomerSubscriptionDataFragment['subscriptionEntitlements'],
): EntitlementFields[] => {
  return (
    entitlements?.map((entitlement) => {
      const newEntitlement: EntitlementFields = {
        uuid: generateRandomSlug(),
        id: entitlement.id,
        feature: entitlement.feature,
        isConfirmed: true,
        usageLimit: entitlement.usageLimit,
        hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
        hasSoftLimit: entitlement.hasSoftLimit,
        isCustom: false,
        resetPeriod: entitlement.resetPeriod,
      };

      if (entitlement.resetPeriodConfiguration?.__typename === 'YearlyResetPeriodConfig') {
        newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.yearlyAccordingTo;
      } else if (entitlement.resetPeriodConfiguration?.__typename === 'MonthlyResetPeriodConfig') {
        newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.monthlyAccordingTo;
      } else if (entitlement.resetPeriodConfiguration?.__typename === 'WeeklyResetPeriodConfig') {
        newEntitlement.resetPeriodConfiguration = entitlement.resetPeriodConfiguration.weeklyAccordingTo;
      }

      return newEntitlement;
    }) || []
  );
};

const getMissingSubscriptionCustomEntitlements = (
  subscriptionEntitlements: EntitlementFields[],
  currentPlan: PlanFragment | undefined,
) => {
  if (!currentPlan || currentPlan?.pricingType !== PricingType.Custom) {
    return subscriptionEntitlements;
  }
  const subscriptionEntitlementKeys = keyBy(subscriptionEntitlements, 'feature.refId');
  const planEntitlements = uniqBy(
    [...(currentPlan.entitlements || []), ...(currentPlan.inheritedEntitlements || [])],
    'feature.refId',
  );
  const missingPlanCustomEntitlements = planEntitlements.filter(
    (entitlement) => entitlement.isCustom && !subscriptionEntitlementKeys[entitlement.feature.refId],
  );

  return mapPackageEntitlementsToEntitlementFields(missingPlanCustomEntitlements || []);
};

const getSubscriptionCustomEntitlements = (
  subscription: CustomerSubscriptionDataFragment,
  currentPlan: PlanFragment | undefined,
) => {
  const subscriptionEntitlements = mapSubscriptionEntitlementsToEntitlementFields(
    subscription.subscriptionEntitlements,
  );

  const mappedPlanMissingEntitlements: EntitlementFields[] = getMissingSubscriptionCustomEntitlements(
    subscriptionEntitlements,
    currentPlan,
  );

  const planCustomEntitlements = [...(currentPlan?.entitlements || []), ...(currentPlan?.inheritedEntitlements || [])]
    ?.filter((entitlement) => entitlement.isCustom)
    .map((entitlement) => entitlement.feature.refId);

  const onlyExistingEntitlements = subscriptionEntitlements.filter(
    (entitlement) => entitlement.feature?.refId && planCustomEntitlements?.includes(entitlement.feature?.refId),
  );

  return [...onlyExistingEntitlements, ...mappedPlanMissingEntitlements];
};

export const getInitialFormValues = (
  subscription: CustomerSubscriptionDataFragment | null | undefined,
  existingProducts: ProductListItemFragment[],
  currentPlan: PlanFragment | undefined,
  paidInvoicesEnabled?: boolean,
): SubscriptionFormFields => {
  const isInvoicePaid =
    paidInvoicesEnabled && currentPlan
      ? currentPlan?.pricingType === PricingType.Paid &&
        subscription?.paymentCollectionMethod !== PaymentCollectionMethod.None
      : undefined;

  if (!subscription) {
    return {
      productId: existingProducts.length === 1 ? existingProducts[0].refId : '',
      planId: '',
      billingCountryCode: null,
      resourceId: '',
      startDate: moment.utc().toLocaleString(),
      entitlements: [],
      addons: [],
      isTrial: false,
      paymentCollectionMethod: PaymentCollectionMethod.Charge,
      invoiceDaysUntilDue: DEFAULT_INVOICE_DAYS_UNTIL_DUE,
    };
  }

  return {
    productId: subscription.plan.product.refId,
    planId: subscription.plan.refId,
    resourceId: subscription.resource?.resourceId || '',
    startDate: subscription.startDate || moment.utc().toLocaleString(),
    addons: subscription.addons?.map((addon) => ({ ...addon, ...addon.addon } as SubscriptionAddon)) || [],
    paidOneOffAddons:
      subscription.addons
        ?.filter((addon) => addon.price?.billingCadence === BillingCadence.OneOff)
        .map((addon) => ({ ...addon, ...addon.addon } as SubscriptionAddon)) || [],
    entitlements: getSubscriptionCustomEntitlements(subscription, currentPlan),
    billingCountryCode: subscription?.prices?.[0]?.price?.billingCountryCode || null,
    billingPeriod:
      subscription.pricingType !== PricingType.Custom ? subscription.prices?.[0]?.price?.billingPeriod : undefined,
    billableFeatures: mapSubscriptionToBillableFeatures(subscription, currentPlan),
    ...(subscription.trialEndDate ? { isTrial: true, endDate: subscription.trialEndDate } : { isTrial: false }),
    isInvoicePaid,
    budget: subscription.budget
      ? {
          limit: subscription.budget.limit,
          hasSoftLimit: !!subscription.budget.hasSoftLimit,
        }
      : undefined,
    paymentCollectionMethod: subscription.paymentCollectionMethod || undefined,
    minimumSpend: subscription.minimumSpend || undefined,
    invoiceDaysUntilDue: DEFAULT_INVOICE_DAYS_UNTIL_DUE,
    coupon: subscription.coupon || undefined,
    couponRefId: subscription.coupon?.refId,
  };
};

export const getPlanCharges = (
  currentPlan: PlanListFragment | PlanFragment,
  billingPeriod: BillingPeriod,
  billingCountryCode?: string | null,
) => {
  return currentPlan && billingPeriod
    ? currentPlan?.prices?.filter(
        (price) =>
          price.billingPeriod === billingPeriod &&
          (billingCountryCode ? price.billingCountryCode === billingCountryCode : !price.billingCountryCode),
      )
    : [];
};

export const mapBillableFeaturesToBillableFeaturesInput = (
  billableFeatures: SubscriptionFormFields['billableFeatures'],
) => {
  if (isEmpty(billableFeatures)) {
    return undefined;
  }

  return compact(
    map(billableFeatures, (value, key): BillableFeatureInput | null => {
      if (!value) {
        return null;
      }
      return { featureId: key, quantity: value };
    }),
  );
};

export const convertPriceOverrideToInput = (
  billableFeaturesPriceOverride?: BillableFeaturesPriceOverride,
): PriceOverrideInput[] | undefined => {
  return billableFeaturesPriceOverride
    ? compact(Object.values(billableFeaturesPriceOverride)).map((override) => ({
        featureId: override.feature?.refId,
        baseCharge: !override.feature,
        price: omit(override.price, '__typename'),
        blockSize: override.blockSize,
      }))
    : undefined;
};

export const convertAddonPriceOverrideToInput = (
  addonPriceOverride?: AddonsPriceOverride,
): PriceOverrideInput[] | undefined => {
  return addonPriceOverride
    ? compact(
        Object.entries(addonPriceOverride).map(([addonId, override]) =>
          override
            ? {
                addonId,
                price: omit(override.price, '__typename'),
                blockSize: override.blockSize,
              }
            : null,
        ),
      )
    : undefined;
};

export const mapMinimumSpendInput = (
  minimumSpendInput?: Partial<SubscriptionMinimumSpendValueInput>,
  billingPeriod?: BillingPeriod,
  plan?: PlanFragment | PlanListFragment,
): SubscriptionMinimumSpendValueInput | undefined => {
  const planMinimum = plan?.minimumSpend?.find((minimumSpend) => minimumSpend.billingPeriod === billingPeriod);
  const inputMinimumAmount = minimumSpendInput?.minimum?.amount;

  if (!planMinimum) {
    return inputMinimumAmount ? { minimum: { amount: inputMinimumAmount } } : undefined;
  }

  if (planMinimum.minimum?.amount === inputMinimumAmount) {
    return undefined;
  }

  return inputMinimumAmount ? { minimum: { amount: inputMinimumAmount } } : { minimum: null };
};
