import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchHooksQuery,
  FetchHooksQueryVariables,
  SortDirection,
  HookFilter,
  HookSortFields,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { HOOK_DATA_FRAGMENT } from './hookDataFragment';

const FETCH_WEBHOOKS = gql`
  ${HOOK_DATA_FRAGMENT}
  query FetchHooks($filter: HookFilter, $sorting: [HookSort!]) {
    hooks(filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...HookData
        }
      }
    }
  }
`;

export type FetchHooksProps = {
  environmentId: string;
};

async function fetchHooks(props: FetchHooksProps, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const filter: HookFilter = {
        environmentId: { eq: props.environmentId },
      };

      const response = await apolloClient.query<FetchHooksQuery, FetchHooksQueryVariables>({
        query: FETCH_WEBHOOKS,
        fetchPolicy: 'network-only',
        variables: {
          sorting: [
            {
              field: HookSortFields.Status,
              direction: SortDirection.Desc,
            },
            { field: HookSortFields.CreatedAt, direction: SortDirection.Desc },
          ],
          filter,
        },
      });

      return response.data.hooks.edges.map((x) => x.node);
    },
    {
      failureMessageHandler: () => t('hooks.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchHooks };
