import { curveStepAfter } from '@visx/curve';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { useSelector } from 'react-redux';
import { formatDate } from '@stigg-common';
import { useTheme } from '@mui/material/styles';
import { Grid, Skeleton } from '@stigg-components';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import { EntitlementResetPeriod, UsageHistoryFragment, UsageMeasurementPoint } from '@stigg-types/apiTypes';
import { ChartOptions } from 'react-charts/types/types';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { fetchUsageHistoryAction } from '../../../customersSlice';
import { getMaxDataPointValue, getResetPointDates, getChartData } from './FluctuatingUsageGraphWrapper.utils';

function TooltipTitle({ date, isResetPoint }: { date: Date; isResetPoint: boolean }) {
  return (
    <Grid pl={1} container flexDirection="column" alignItems="start">
      <Grid item>{formatDate(date, { formatType: 'longDate', withTime: true })}</Grid>
      {isResetPoint && <Grid item>(usage reset)</Grid>}
    </Grid>
  );
}

const FluctuatingUsageGraph = ({
  usageHistory,
  startDate,
  endDate,
  usageLimit,
}: {
  usageHistory: UsageHistoryFragment;
  startDate: Date;
  endDate: Date;
  usageLimit?: number | null;
}) => {
  const theme = useTheme();
  const chartData = getChartData(usageHistory);
  const resetPoints = getResetPointDates(chartData);
  const maxValue = getMaxDataPointValue(chartData, usageLimit);

  const primaryAxis: AxisOptions<UsageMeasurementPoint> = useMemo(
    (): AxisOptions<UsageMeasurementPoint> => ({
      getValue: (datum) => datum.date,
      scaleType: 'time',
      min: startDate,
      max: endDate,
      showGrid: false,
      formatters: {
        tooltip: (value: Date) => {
          if (!value) {
            return null;
          }
          const isResetDate = resetPoints.has(value);
          return <TooltipTitle date={value} isResetPoint={isResetDate} />;
        },
        scale: (value: Date) =>
          value && moment.utc(value).isSame(moment.utc(value).startOf('day'))
            ? formatDate(value, { formatType: 'shortDate' })
            : '',
      },
    }),
    [startDate, endDate, resetPoints],
  );

  const secondaryAxes: AxisOptions<UsageMeasurementPoint>[] = useMemo(
    (): AxisOptions<UsageMeasurementPoint>[] => [
      {
        getValue: (datum) => datum.value,
        min: 0,
        max: maxValue,
        scaleType: 'linear',
        elementType: 'area',
        curve: curveStepAfter,
      },
    ],
    [maxValue],
  );

  const getSeriesStyle = useMemo((): ChartOptions<any>['getSeriesStyle'] => {
    if (chartData.length > 1) {
      return undefined;
    }
    return () => ({
      area: { fill: theme.itamar.palette.primary.containedHoverBackground, stroke: 'none' },
      line: { stroke: theme.itamar.palette.primary.main, strokeWidth: '3px' },
    });
  }, [chartData.length, theme]);

  return (
    <Chart
      options={{
        data: chartData,
        primaryAxis,
        secondaryAxes,
        primaryCursor: {
          showLine: false,
          showLabel: false,
        },
        secondaryCursor: {
          showLine: false,
          showLabel: false,
        },
        getSeriesStyle,
        dark: !theme.isLightTheme,
      }}
    />
  );
};

export type FluctuatingUsageGraphWrapperProps = {
  featureRefId: string;
  customerRefId: string;
  resourceRefId: string | undefined;
  usageLimit?: number | null;
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
  groupBy?: string[];
};

function getStartDateByPeriod(resetPeriod?: EntitlementResetPeriod | null) {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return moment.utc().subtract(1, 'year').startOf('day').toDate();
    case EntitlementResetPeriod.Month:
    case EntitlementResetPeriod.Week:
      return moment.utc().subtract(1, 'month').startOf('day').toDate();
    case EntitlementResetPeriod.Day:
    case EntitlementResetPeriod.Hour:
      return moment.utc().subtract(1, 'week').startOf('day').toDate();
    default:
      return moment.utc().subtract(1, 'month').startOf('day').toDate();
  }
}

export function FluctuatingUsageGraphWrapper({
  featureRefId,
  customerRefId,
  resourceRefId,
  usageLimit,
  resetPeriod,
  groupBy,
}: FluctuatingUsageGraphWrapperProps) {
  const dispatch = useAppDispatch();
  const featureMeasurements = useSelector((root: RootState) => root.customersReducer.usageHistory[featureRefId]);
  const lastUsageReported = useSelector((root: RootState) => root.customersReducer.lastReportedUsage[featureRefId]);
  const today = useMemo(() => new Date(), []);
  const startDate = useMemo(() => getStartDateByPeriod(resetPeriod), [resetPeriod]);

  useEffect(() => {
    void dispatch(
      fetchUsageHistoryAction({
        featureRefId,
        customerRefId,
        resourceRefId,
        startDate,
        groupBy,
      }),
    );
  }, [featureRefId, customerRefId, resourceRefId, dispatch, resetPeriod, startDate, lastUsageReported, groupBy]);

  if (!featureMeasurements || featureMeasurements.isLoading || !featureMeasurements.usageHistory) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={150}
        sx={{
          borderRadius: '10px',
          bgcolor: 'transparent',
        }}
      />
    );
  }

  return (
    <FluctuatingUsageGraph
      usageHistory={featureMeasurements.usageHistory}
      startDate={startDate}
      endDate={today}
      usageLimit={usageLimit}
    />
  );
}
