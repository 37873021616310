import { Grid } from '@stigg-components';
import { GridProps } from '@stigg-components/types';
import { getTextColor, styled, TextColor } from '@stigg-theme';
import { Children, ReactNode } from 'react';

const Ul = styled('ul')<{ $color: TextColor }>`
  color: ${({ $color }) => $color && getTextColor($color)};
  margin: 0;
  padding: 0;
`;

const Li = styled('li')`
  color: inherit;
  margin: 0;
  padding: 0;
`;

export function ItemList({
  children,
  color,
  ...props
}: { children: ReactNode; color: TextColor } & Omit<GridProps, 'color'>) {
  return children ? (
    <Grid {...props}>
      <Ul $color={color}>
        {Children.map(children, (child, cIndex) => (
          <Li key={cIndex}>{child}</Li>
        ))}
      </Ul>
    </Grid>
  ) : (
    <></>
  );
}
