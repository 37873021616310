import { t } from 'i18next';
import { SubscriptionDataFragment, SubscriptionEndSetup } from '@stigg-types/apiTypes';
import { DetailsLayout } from '@stigg-components';
import { formatDate, getDateRemainingDays } from '@stigg-common';
import { DetailsLayoutType } from '@stigg-components/types';
import * as S from './InTrialStatus.style';

export function InTrialStatus({ subscription }: { subscription: SubscriptionDataFragment }) {
  const { remainingDays, isLessThenOneDay } = getDateRemainingDays(subscription.trialEndDate);
  const details: DetailsLayoutType = [
    {
      title: t('subscriptions.trialEnds'),
      content: isLessThenOneDay
        ? t('subscriptions.trialEndDateZero', { formatDate: formatDate(subscription.trialEndDate) })
        : t('subscriptions.trialEndDate', {
            count: remainingDays,
            formatDate: formatDate(subscription.trialEndDate),
          }),
    },
    {
      title: t('customers.trials.whenTrialEnds'),
      content:
        subscription.plan.product.productSettings.subscriptionEndSetup === SubscriptionEndSetup.CancelSubscription
          ? t('customers.trials.downgradeTo')
          : t('customers.trials.downgradeToFree', {
              freePlan: subscription.plan.product.productSettings.downgradePlan?.displayName,
            }),
    },
  ];

  return (
    <S.Box p={4} width="100%">
      <DetailsLayout details={details} />
    </S.Box>
  );
}
