import { useMemo } from 'react';
import { compact, isEmpty } from 'lodash';
import {
  BillingPeriod,
  SubscriptionDataFragment,
  PriceFragment,
  CouponFragment,
  SubscriptionCouponDataFragment,
} from '@stigg-types/apiTypes';
import { useNavigation, Navigation } from '../../../../navigation/useNavigation';
import {
  getPriceBreakdown,
  PriceBreakdown,
  mapSubscriptionToBillableFeatures,
} from '../../customerPage/customerSubscriptions/priceBreakdown';
import { useChargesSort } from '../../customerPage/customerSubscriptions/useChargeSort';

export type SubscriptionDataType = {
  navigation: Navigation;
  showMonthlyPriceVariation?: boolean;
  priceBreakdown?: PriceBreakdown;
  subscriptionPrices?: { price?: PriceFragment | null }[];
  coupon?: CouponFragment | SubscriptionCouponDataFragment | null;
};

export const useSubscriptionData = (subscription: SubscriptionDataFragment): SubscriptionDataType => {
  const navigation = useNavigation();

  const subscriptionPrices = compact(subscription?.prices);
  const sortedCharges = useChargesSort(compact(subscriptionPrices?.map((sp) => sp.price)));

  const coupon = subscription?.customer?.coupon || subscription?.coupon;

  const subscriptionAddons = (subscription.addons || []).map((addon) => ({
    quantity: addon.quantity,
    displayName: addon.addon.displayName,
    price: addon.price,
    addonId: addon.addon.refId,
  }));

  const priceBreakdown = useMemo(
    () =>
      !isEmpty(sortedCharges)
        ? getPriceBreakdown({
            planPrices: sortedCharges,
            addonsPrices: subscriptionAddons,
            billableFeatures: mapSubscriptionToBillableFeatures(subscription),
            coupon,
            withZeroQuantityPrices: undefined,
            paidOneOffAddons: undefined,
            freeItems: subscription.freeItems,
            minimumSpend: subscription.minimumSpend?.minimum?.amount,
          })
        : null,
    [sortedCharges, subscriptionAddons, subscription, coupon],
  );

  const planPrices = subscription?.plan?.prices;
  const hasMonthlyPrice = planPrices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
  const showMonthlyPriceVariation = hasMonthlyPrice && sortedCharges?.[0]?.billingPeriod === BillingPeriod.Annually;

  return {
    navigation,
    showMonthlyPriceVariation,
    priceBreakdown: priceBreakdown as SubscriptionDataType['priceBreakdown'],
    subscriptionPrices,
    coupon,
  };
};
