import { t } from 'i18next';
import { CardHeaderLayout, DetailsLayout, GridFlex, Link, PageCard, EMPTY_CHAR } from '@stigg-components';
import { CustomerResponseFragment, ExperimentStatus, VendorIdentifier } from '@stigg-types/apiTypes';
import { DetailsLayoutType } from '@stigg-components/types';
import { useIntegrations, useModal } from '@stigg-common';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IntegrationIdLink, IntegrationIdWithIcon } from '../../../../../components/IntegrationIdLink';
import { usePollCustomerSyncStates } from '../usePollCustomerSyncStates';
import { AddPaymentMethod } from '../customerSubscriptions/AddPaymentMethod';
import { CustomerPaymentMethod } from './CustomerPaymentMethod';
import { CustomerCoupon } from './CustomerCoupon';
import { useNavigation } from '../../../../navigation/useNavigation';
import { DetailsSectionHeader } from './DetailsSectionHeader';
import { useSalesforceUrl } from '../../../../integrations/components/salesforce/useSalesforceUrl';

type CustomerDetailsProps = {
  customer: CustomerResponseFragment;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onApplyCouponClick: () => void;
  onRemoveCouponClick: () => void;
};

export function CustomerDetails({
  customer,
  onEditClick,
  onDeleteClick,
  onApplyCouponClick,
  onRemoveCouponClick,
}: CustomerDetailsProps) {
  const navigation = useNavigation();
  const [AddPaymentMethodModal, setIsAddPaymentMethodModalOpen] = useModal();
  const { hasCRMIntegration, hasBillingIntegration, isSyncingCustomerAndSubscriptions } = useIntegrations();
  const { stripeSecretBilling: stripeSecretBillingEnabled } = useFlags<FeatureFlags>();
  const { salesforceUrl } = useSalesforceUrl(customer.salesforceId, 'ACCOUNT');

  usePollCustomerSyncStates(customer);

  const details: DetailsLayoutType = [
    {
      title: t('customers.customerId'),
      content: customer.customerId,
      copyableChip: true,
      withLabel: true,
    },
    {
      title: t('customers.name'),
      content: customer.name || EMPTY_CHAR,
      mask: true,
    },
    {
      title: t('customers.email'),
      content: customer.email ? <GridFlex.Row gap={2}>{customer.email}</GridFlex.Row> : EMPTY_CHAR,
      mask: true,
    },
    {
      title: t('customers.billingCurrency'),
      content: customer.billingCurrency,
      shouldPrintLine: !!customer.billingCurrency,
    },
    {
      title: t('customers.billingId'),
      content: (
        <IntegrationIdLink
          vendorCategory="BILLING"
          entityType="CUSTOMER"
          refId={customer.customerId}
          syncStates={customer.syncStates}
          to={{ integrationId: customer.billingId, integrationLinkUrl: customer.billingLinkUrl }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasBillingIntegration,
    },
    {
      title: t('customers.awsId'),
      content: (
        <IntegrationIdWithIcon
          tooltipTitle={customer.awsMarketplaceCustomerId ? t('customers.awsIdTooltip') : undefined}
          integrationId={customer.awsMarketplaceCustomerId}
          integrationType={VendorIdentifier.AwsMarketplace}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: !!customer.awsMarketplaceCustomerId,
    },
    {
      title: t('customers.salesforceId'),
      content: (
        <IntegrationIdWithIcon
          integrationId={customer.salesforceId}
          integrationType={VendorIdentifier.Salesforce}
          integrationLinkUrl={salesforceUrl}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: !!customer.salesforceId,
    },
    {
      title: t('customers.crmId'),
      content: (
        <IntegrationIdLink
          vendorCategory="CRM"
          entityType="CUSTOMER"
          refId={customer.customerId}
          syncStates={customer.syncStates}
          to={{
            integrationId: customer.crmHubspotCompanyId,
            integrationLinkUrl: customer.crmHubspotCompanyUrl,
          }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasCRMIntegration,
    },
  ];

  const paymentDetails: DetailsLayoutType = [
    {
      title: t('customers.paymentMethod.title'),
      content: (
        <CustomerPaymentMethod
          customer={customer}
          onAddPaymentMethodClick={() => setIsAddPaymentMethodModalOpen(true)}
        />
      ),
      alignItems: 'center',
      shouldPrintLine:
        !stripeSecretBillingEnabled &&
        (!!customer.billingId || (hasBillingIntegration && isSyncingCustomerAndSubscriptions)),
    },
    {
      title: t('customers.coupon.title'),
      content: (
        <CustomerCoupon
          customer={customer}
          onAddCouponClick={onApplyCouponClick}
          onRemoveCouponClick={onRemoveCouponClick}
        />
      ),
      alignItems: 'center',
    },
  ];

  const experimentDetails: DetailsLayoutType = [];

  if (customer.experimentInfo) {
    const experimentRefId = customer.experimentInfo.id;
    experimentDetails.push({
      title:
        customer.experimentInfo.status === ExperimentStatus.InProgress
          ? t('experiments.runningExperiment')
          : t('experiments.endedExperiment'),
      content: (
        <>
          <Link onClick={() => navigation.navigateTo(navigation.appRoutes.experimentPage(experimentRefId))}>
            {customer.experimentInfo.name}
          </Link>
          {' / '}
          {customer.experimentInfo.groupName}
        </>
      ),
      alignItems: 'center',
    });
  }

  return (
    <PageCard>
      <CardHeaderLayout
        displayName={t('customers.details')}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        deleteText={t('customers.archive')}
      />

      <DetailsLayout details={details} titleWidth={160} mt={4} />

      {paymentDetails.filter((x) => x.shouldPrintLine === undefined || x.shouldPrintLine).length > 0 && (
        <>
          <DetailsSectionHeader icon="PaymentOutlined" title={t('customers.paymentDetailsSectionTitle')} />
          <DetailsLayout details={paymentDetails} titleWidth={160} />
        </>
      )}

      {experimentDetails.length > 0 && (
        <>
          <DetailsSectionHeader icon="ScienceOutlined" title={t('customers.experimentParticipationSectionTitle')} />
          <DetailsLayout details={experimentDetails} titleWidth={160} mt={3} />
        </>
      )}

      <AddPaymentMethodModal>
        <AddPaymentMethod
          customer={customer}
          onSuccess={() => setIsAddPaymentMethodModalOpen(false)}
          onCancel={() => setIsAddPaymentMethodModalOpen(false)}
        />
      </AddPaymentMethodModal>
    </PageCard>
  );
}
