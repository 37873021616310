import { generatePath, useRouteMatch } from 'react-router-dom';
import { useCallback } from 'react';
import { push, replace } from 'connected-react-router';
import { match } from 'react-router';
import { selectEnvironment } from '../accounts/accountsSlice';
import { AppDispatch, RootState, useAppDispatch } from '../../redux/store';

function switchEnvironment(environmentId: string, { params, path }: match<any>) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { environments } = getState().accountReducer;

    const environmentSlug = environments.find((x) => x.id === environmentId)?.slug;
    if (!environmentSlug) {
      return;
    }

    if (params.refId) {
      // When we have refId, lets navigate to the list screen. It should be the same path but without the refId param.
      dispatch(push(generatePath(path.replace('/:refId', ''), { ...params, environmentSlug })));
    } else {
      dispatch(replace(generatePath(path, { ...params, environmentSlug })));
    }

    dispatch(selectEnvironment(environmentId));
  };
}

export function useSwitchEnvironment() {
  const dispatch = useAppDispatch();
  const match = useRouteMatch<{ environmentId?: string; refId?: string } & Record<string, string>>();

  return useCallback(
    (environmentId: string) => {
      dispatch(switchEnvironment(environmentId, match));
    },
    [dispatch, match],
  );
}
