import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { Stepper } from '@stigg-components';
import { useSelector } from 'react-redux';
import { CustomerResponseFragment, PromotionalEntitlementPeriod } from '@stigg-types/apiTypes';
import { SelectEntitlements } from './SelectEntitlements';
import {
  EntitlementFields,
  getConfirmedEntitlements,
} from '../../../../../entitlements/components/entitlementSettings/types';
import { getPeriodDate, PromotionalEntitlementsConfiguration } from './PromotionalEntitlementsConfiguration';
import { entitlementsValidationSchema } from '../../../../../packages/plans/components/EntitlementsForm';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { getResetPeriodData } from '../../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';
import { createPromotionalEntitlementsAction } from '../../../../customersSlice';
import { SelectedEntitlementsStatus } from './SelectedEntitlementsStatus';

export type AddPromotionalEntitlementFormProps = {
  customer: CustomerResponseFragment;
  onCancel: () => void;
  hasActiveSubscription: boolean;
};

export type AddPromotionalEntitlementFormFields = {
  entitlements: EntitlementFields[];
  endDate?: string | undefined;
  isVisible: boolean;
  period: PromotionalEntitlementPeriod;
};

const validationSchema = () =>
  Yup.object()
    .shape({
      period: Yup.string().required(t('fieldValidationMessages.required')),
      endDate: Yup.date().when(['period'], {
        is: (period: PromotionalEntitlementPeriod) => period !== PromotionalEntitlementPeriod.Lifetime,
        then: (schema: any) => schema.required(t('fieldValidationMessages.required')),
        otherwise: (schema: any) => schema.nullable(true),
      }),
      isVisible: Yup.boolean().required(t('fieldValidationMessages.required')),
    })
    .concat(entitlementsValidationSchema);

export function AddPromotionalEntitlementForm({
  onCancel,
  customer,
  hasActiveSubscription,
}: AddPromotionalEntitlementFormProps) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isSavingPromotionalEntitlements = useSelector(
    (state: RootState) => state.customersReducer.isSavingPromotionalEntitlements,
  );
  const existingFeatureIds = customer.promotionalEntitlements.map((x) => x.featureId);

  const handleSubmit = async (values: AddPromotionalEntitlementFormFields) => {
    await dispatch(
      createPromotionalEntitlementsAction({
        customerId: customer.customerId,
        promotionalEntitlements: getConfirmedEntitlements(values.entitlements).map((ent) => ({
          customerId: customer.id,
          isVisible: values.isVisible,
          period: values.period,
          endDate: values.endDate,
          featureId: ent.feature.id,
          description: ent.description?.slice(0, 256),
          environmentId: currentEnvironmentId,
          usageLimit: ent.usageLimit,
          hasUnlimitedUsage: ent.hasUnlimitedUsage,
          hasSoftLimit: ent.hasSoftLimit,
          ...getResetPeriodData(ent.resetPeriod, ent.resetPeriodConfiguration),
        })),
      }),
    );
  };

  const initialValues = {
    entitlements: [],
    endDate: getPeriodDate(PromotionalEntitlementPeriod.OneMonth, new Date())?.toLocaleString(),
    isVisible: true,
    period: PromotionalEntitlementPeriod.OneMonth,
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        errors,
        submitForm,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
      }) => (
        <Form>
          <Stepper
            steps={[
              {
                content: () => (
                  <SelectEntitlements
                    excludedFeaturesIds={existingFeatureIds}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                ),
              },
              {
                content: () => (
                  <PromotionalEntitlementsConfiguration
                    hasActiveSubscription={hasActiveSubscription}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    isAdd
                  />
                ),
              },
            ]}
            onCancel={onCancel}
            onDone={submitForm}
            isNextValid={isValid && dirty}
            isNextLoading={isSavingPromotionalEntitlements}
            renderAdditionalControls={() => <SelectedEntitlementsStatus entitlements={values.entitlements} />}
            lastStepCTAText={t('promotionalEntitlements.createFormCTA')}
          />
        </Form>
      )}
    </Formik>
  );
}
