import moment from 'moment';
import { DateText, LongText, HeadCell } from '@stigg-components';
import { UsageEventWithCustomerFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';

export const headCells = (): Array<HeadCell<UsageEventWithCustomerFragment, any>> => [
  {
    id: 'timestamp',
    alignment: 'left',
    label: t('usageEvents.tableColumns.timestamp'),
    render: (event) => (
      <DateText color="secondary" date={moment.utc(event.timestamp).toDate()} withTime formatType="longDate" />
    ),
  },
  {
    id: 'eventName',
    alignment: 'left',
    label: t('usageEvents.tableColumns.eventName'),
    render: (event) => (
      <LongText variant="body2" color="primary" wordBreak>
        {event.eventName}
      </LongText>
    ),
  },
  {
    id: 'idempotencyKey',
    alignment: 'left',
    label: t('usageEvents.tableColumns.idempotencyKey'),
    render: (event) => <LongText variant="body2">{event.idempotencyKey}</LongText>,
  },
  {
    id: 'customerId',
    alignment: 'left',
    label: t('usageEvents.tableColumns.customerId'),
    render: (event) => <LongText variant="body2">{event.customerId}</LongText>,
  },
  {
    id: 'properties',
    alignment: 'left',
    label: t('usageEvents.tableColumns.dimensions'),
    render: (event) => (
      <LongText variant="body2">{event.dimensions ? JSON.stringify(event.dimensions, null, 2) : '-'}</LongText>
    ),
  },
];
