import { useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, Flex, GridFlex, Icon, MenuItem, Select, Text } from '@stigg-components';
import React from 'react';
import { styled } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { setProductFilter } from '../subscriptionsOverviewSlice';
import { TIME_RANGES, TimeRangeLabel } from './constants';

const TimeRangesButtonGroup = styled(ButtonGroup)`
  padding: 2px;
  background-color: ${({ theme }) => theme.itamar.palette.grey[10]};
`;

const StyledButton = styled(Button)<{ $isSelected: boolean }>`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected
      ? `${
          theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.lightBackground
        } !important`
      : `${theme.itamar.palette.grey[10]} !important`};
`;

export function SubscriptionsOverviewFilters({
  onSelectTimeRange,
}: {
  onSelectTimeRange: (label: TimeRangeLabel) => void;
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { productFilterItems, productFilter, timeRangeFilter } = useSelector(
    (state: RootState) => state.dashboards.subscriptionsOverview,
  );

  return (
    <Flex.Row>
      {productFilterItems.length > 1 && (
        <Box mr={4}>
          <Select
            withoutBorder
            backgroundColor={theme.itamar.palette.grey[10]}
            value={productFilter}
            onChange={(e) => {
              return dispatch(setProductFilter(e.target.value as string));
            }}
            renderValue={(x: any) => {
              const productFilterItem = productFilterItems.find((item) => item.id === x);
              return (
                <GridFlex.RowCenter>
                  <Icon icon="Grid" color="active" />
                  <Text.B2 mx={2}>
                    Product: {x === 'ALL_PRODUCTS' ? 'All' : productFilterItem?.displayName || '-'}
                  </Text.B2>
                </GridFlex.RowCenter>
              );
            }}>
            <MenuItem value="ALL_PRODUCTS">
              <Text.B2 color="primary">All</Text.B2>
            </MenuItem>
            {productFilterItems.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                <Text.B2 color="primary">{product.displayName}</Text.B2>
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}

      <TimeRangesButtonGroup>
        {TIME_RANGES.map(({ label, durationMs }) => {
          const isSelected = durationMs === timeRangeFilter?.durationMs;

          return (
            <StyledButton
              key={label}
              onClick={() => onSelectTimeRange(label)}
              $isSelected={isSelected}
              variant="contained">
              <Text.B1 color={theme.isLightTheme ? 'primary' : 'white'}>{label}</Text.B1>
            </StyledButton>
          );
        })}
      </TimeRangesButtonGroup>
    </Flex.Row>
  );
}
