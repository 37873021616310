import { Text, Icon, InformationTooltip, Box, Button } from '@stigg-components';
import styled from 'styled-components/macro';
import { PriceFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';

const getDisabledTooltipTitle = (charge: PriceFragment) => {
  const billingModelCaption: string = charge.tiersMode
    ? 'tiered'
    : t(`pricing.newBillingModelDescriptions.${charge.billingModel}`).toLowerCase();

  const tooltipText = charge.feature
    ? t('pricing.override.disabledTooltip', { billingModelCaption })
    : t('pricing.override.disabledBaseChargeTooltip');

  return <Text.B2>{tooltipText}</Text.B2>;
};

const HiddenButton = styled(Button)<{ $forceVisibility?: boolean }>`
  opacity: ${({ $forceVisibility }) => ($forceVisibility ? 1 : 0)};
  transition: 0.2s ease-in-out;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
`;

export function ChargeEditButton({
  charge,
  disabled,
  hovering,
  onClick,
}: {
  charge: PriceFragment;
  disabled?: boolean;
  hovering?: boolean;
  onClick: () => void;
}) {
  return (
    <InformationTooltip
      disableInteractive
      title={!disabled ? '' : getDisabledTooltipTitle(charge)}
      arrow
      placement="top"
      $maxWidth={230}>
      <Box>
        <HiddenButton
          variant="text"
          textColor="primary"
          hoverColor="disabled"
          $forceVisibility={hovering}
          disabled={disabled}
          onClick={onClick}
          startIcon={
            <Icon
              type="custom"
              icon="Pencil"
              size={14}
              color={!disabled ? 'primary.main' : 'disabled'}
              overrideStroke
            />
          }>
          {t('pricing.override.label')}
        </HiddenButton>
      </Box>
    </InformationTooltip>
  );
}
