import { gql } from '@apollo/client';

export const PACKAGE_CHANGES_FRAGMENT = gql`
  fragment PackageChangesFragment on PackageChanges {
    description {
      before
      after
      changeType
    }
    displayName {
      before
      after
      changeType
    }
    pricingType {
      before
      after
      changeType
    }
    hiddenFromWidgets {
      before
      after
      changeType
    }
    entitlements {
      before {
        ...PackageEntitlementChangeFragment
      }
      after {
        ...PackageEntitlementChangeFragment
      }
      changeType
    }
    prices {
      before {
        ...PriceFragment
      }
      after {
        ...PriceFragment
      }
      changeType
    }
    overagePrices {
      before {
        ...PriceFragment
      }
      after {
        ...PriceFragment
      }
      changeType
    }
    minimumSpend {
      changeType
      before {
        ...MinimumSpendFragment
      }
      after {
        ...MinimumSpendFragment
      }
    }
    compatibleAddons {
      before {
        ...CompatibleAddonChangeFragment
      }
      after {
        ...CompatibleAddonChangeFragment
      }
      changeType
    }
    compatiblePackageGroups {
      before {
        ...CompatiblePackageGroupChangeFragment
      }
      after {
        ...CompatiblePackageGroupChangeFragment
      }
      changeType
    }
    basePlan {
      before {
        displayName
        product {
          displayName
        }
      }
      after {
        displayName
        product {
          displayName
        }
      }
      changeType
    }
    defaultTrialConfig {
      before {
        ...DefaultTrialConfigChangeFragment
      }
      after {
        ...DefaultTrialConfigChangeFragment
      }
      changeType
    }
    additionalMetaData {
      before
      after
      changeType
    }
    overageBillingPeriod {
      before
      after
      changeType
    }
    maxQuantity {
      before
      after
      changeType
    }
    dependencies {
      before {
        ...AddonDependencyChangeFragment
      }
      after {
        ...AddonDependencyChangeFragment
      }
      changeType
    }
    totalChanges
  }
  fragment PackageEntitlementChangeFragment on PackageEntitlement {
    feature {
      displayName
      refId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
    }
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    resetPeriod
    hiddenFromWidgets
    displayNameOverride
    isCustom
    behavior
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
  }
  fragment CompatibleAddonChangeFragment on Addon {
    displayName
    refId
    description
    maxQuantity
  }

  fragment CompatiblePackageGroupChangeFragment on PackageGroup {
    displayName
    packageGroupId
    description
  }

  fragment AddonDependencyChangeFragment on Addon {
    displayName
    refId
  }

  fragment DefaultTrialConfigChangeFragment on DefaultTrialConfig {
    units
    duration
    budget {
      limit
    }
  }
`;
