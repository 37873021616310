import { styled } from '@stigg-theme';
import { GridFlex, Box } from '@stigg-components';
import { Close, ErrorOutline as BaseErrorOutline } from '@mui/icons-material';
import { CheckCircle as BaseCheckCircle } from 'react-feather';

export const TooltipClose = styled(Close)`
  margin-top: 6px;
  color: ${({ theme }) => theme.itamar.palette.text.secondary};
  cursor: pointer;
`;

export const ErrorOutline = styled(BaseErrorOutline)`
  color: ${({ theme }) => theme.itamar.palette.error.main};
`;

export const CheckCircle = styled(BaseCheckCircle)`
  margin-right: 8px;
`;

export const TaskBox = styled(GridFlex.Column)<{ $status: 'success' | 'inProgress' | 'pending' | 'error' }>`
  padding: 16px;
  min-height: 98px;
  background: ${({ theme, $status }) => {
    switch ($status) {
      case 'pending':
        return theme.isLightTheme ? theme.itamar.palette.grey[100] : theme.itamar.palette.background.darkBackground;
      case 'success':
      case 'inProgress':
        return theme.itamar.palette.background.lightBackground2;
      case 'error':
        return theme.itamar.palette.error.background;
      default:
        return theme.isLightTheme ? theme.itamar.palette.grey[100] : theme.itamar.palette.background.paper;
    }
  }};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

export const TaskTitleBox = styled(Box)`
  &,
  & > * {
    display: inline;
  }
`;
