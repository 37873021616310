import {
  Button,
  drawFormFields,
  Field,
  Icon,
  StepAccordionWithConfirm,
  Text,
  useScrollableElement,
} from '@stigg-components';
import React from 'react';
import { t } from 'i18next';
import { get, omit } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';
import { CountryFlagWithCurrency } from '../../../../currency/CountryWithFlagByCode';
import { getLocalizedChargesFields } from './getChargesTableFields';
import { getCountryAndCurrencySelectionFields } from './getCountryAndCurrencySelectionFields';

type CountryPriceLocalizationProps = {
  index: number;
  isFocused: boolean;
  onToggle: () => void;
  onRemove: () => void;
  onConfirm: () => void;
  permanent: boolean;
};

export function LocalizedCountry({
  index,
  isFocused,
  onToggle,
  onRemove,
  onConfirm,
  permanent,
}: CountryPriceLocalizationProps) {
  const { formRenderProps, initialFocusedSection } = useSetPriceWizardFormContext();
  const { values, errors, setFieldValue } = formRenderProps;
  const { priceLocalization, charges, billingPeriods } = values;
  const { billingCountryCode, currency, countryAndCurrencyConfirmed, isConfirmed, usedInSubscriptions } =
    priceLocalization.countries[index];
  const hasSingleCountry = priceLocalization.countries.length === 1;
  const currentCountryErrors = get(errors, `priceLocalization.countries[${index}]`);
  const hasErrors = !isEmpty(currentCountryErrors);
  const hasErrorsWithoutIsConfirmed = !isEmpty(omit(currentCountryErrors, 'isConfirmed'));
  const showChargesSection = !!billingCountryCode && !!currency && countryAndCurrencyConfirmed;

  const { elementRef } = useScrollableElement({
    shouldAutoScroll: () => !!billingCountryCode && initialFocusedSection?.billingCountryCode === billingCountryCode,
  });

  const onConfirmCountryAndCurrency = () =>
    setFieldValue(`priceLocalization.countries[${index}].countryAndCurrencyConfirmed`, true);

  const onBack = () => setFieldValue(`priceLocalization.countries[${index}].countryAndCurrencyConfirmed`, false);

  const onConfirmButton = () => {
    setFieldValue(`priceLocalization.countries[${index}].isConfirmed`, true);
    onConfirm();
  };

  // TODO: the performance of this might be bad, can be improved by using a Set when needed.
  const isCountryInUse = (countryCode: string) =>
    priceLocalization.countries.some(
      (country, optionIndex) => country.billingCountryCode === countryCode && optionIndex !== index,
    );

  let fields: Field<SetPriceWizardFormFields>[];
  let header: React.ReactNode;

  if (showChargesSection) {
    header = <CountryFlagWithCurrency countryCode={billingCountryCode} currency={currency} $bold />;
    fields = getLocalizedChargesFields(index, charges, billingPeriods, currency, priceLocalization, formRenderProps);
  } else {
    header = <Text.B2 $bold>{t('pricing.priceLocalization.localizedPrice', { number: index + 1 })}</Text.B2>;
    fields = getCountryAndCurrencySelectionFields(
      index,
      billingCountryCode,
      currency,
      onConfirmCountryAndCurrency,
      onRemove,
      hasSingleCountry,
      isCountryInUse,
    );
  }

  const backButton = (
    <Button onClick={onBack} startIcon={<Icon icon="ArrowBack" />}>
      {t('sharedComponents.backButton')}
    </Button>
  );

  return (
    <StepAccordionWithConfirm
      ref={elementRef}
      permanent={permanent}
      onRemove={onRemove}
      isRemoveDisabled={usedInSubscriptions}
      removeDisabledTooltip={t('pricing.priceLocalization.priceUsedInSubscriptionsTooltip')}
      confirmRemoveDialogTitle={t(`pricing.priceLocalization.removeTitle`)}
      confirmRemoveDialogContent={t(`pricing.priceLocalization.removeContent`)}
      hasErrors={hasErrors}
      isFocused={isFocused}
      onToggle={onToggle}
      header={header}
      isConfirmed={isConfirmed}
      isConfirmDisabled={hasErrorsWithoutIsConfirmed}
      onConfirm={onConfirmButton}
      hideConfirm={!showChargesSection}
      hideCancel={hasSingleCountry}
      additionalActionButtons={backButton}>
      <Text.B2 color="secondary" mb={6}>
        {showChargesSection
          ? t(`pricing.priceLocalization.setChargesForLocalizedPrice`)
          : t('pricing.priceLocalization.selectCountryAndCurrency')}
      </Text.B2>
      {drawFormFields(fields, formRenderProps)}
    </StepAccordionWithConfirm>
  );
}
