import { gql } from '@apollo/client';
import { t } from 'i18next';
import { Addon, CreateAddonDraftMutation, CreateAddonDraftMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { appRoutes } from '../../../navigation/useNavigation';

const CREATE_ADDON_DRAFT = gql`
  mutation CreateAddonDraft($input: String!) {
    createAddonDraft(id: $input) {
      id
      refId
      versionNumber
    }
  }
`;

async function createAddonDraft(
  addonId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Addon> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<CreateAddonDraftMutation, CreateAddonDraftMutationVariables>({
        mutation: CREATE_ADDON_DRAFT,
        variables: {
          input: addonId,
        },
      });
      const newAddon = response.data?.createAddonDraft;
      if (newAddon?.refId) {
        dispatch(navigateTo(appRoutes.addonPage(newAddon.refId, newAddon.versionNumber)));
      }
      return newAddon;
    },
    {
      failureMessageHandler: () => t('addons.api.failCreateDraft'),
    },
    dispatch,
  );
}

export { createAddonDraft };
