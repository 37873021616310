import { gql } from '@apollo/client';
import { t } from 'i18next';
import { HookQuery, HookQueryVariables, HookDataFragment } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { HOOK_DATA_FRAGMENT } from './hookDataFragment';

const FETCH_HOOK = gql`
  ${HOOK_DATA_FRAGMENT}
  query Hook($hookId: String!) {
    hook(id: $hookId) {
      ...HookData
    }
  }
`;

async function fetchHookById(
  id: string,
  { dispatch }: { dispatch: AppDispatch },
): Promise<HookDataFragment | null | undefined> {
  return executeOperationSafely(
    async () => {
      const res = await apolloClient.query<HookQuery, HookQueryVariables>({
        query: FETCH_HOOK,
        fetchPolicy: 'network-only',
        variables: {
          hookId: id,
        },
      });
      return res.data.hook;
    },
    {
      failureMessageHandler: () => t('hooks.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchHookById };
