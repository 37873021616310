import { TextColor } from '@stigg-theme';
import { Divider, GridFlex, Icon, IconProps, Text } from '@stigg-components';
import React from 'react';

export function CustomerJourneySectionTitle({
  title,
  titleColor = 'primary',
  icon,
  iconType,
  iconSize,
  readonly,
  children,
  lastChild = false,
  minHeight,
  mt = 0,
}: {
  title: string;
  titleColor?: TextColor;
  icon: IconProps['icon'];
  iconType?: IconProps['type'];
  iconSize?: IconProps['size'];
  readonly?: boolean;
  lastChild?: boolean;
  children?: React.ReactNode;
  minHeight?: number;
  mt?: number;
}) {
  return (
    <GridFlex.Row minHeight={minHeight} mt={mt}>
      <GridFlex.ColumnMiddle justifyContent="flex-start" rowGap={4}>
        <Icon icon={icon} type={iconType} size={iconSize} color="active" overrideStroke />
        {!lastChild && (
          <Divider
            orientation="vertical"
            style={{
              borderWidth: '1px',
              flex: 1,
              borderStyle: 'dashed',
              borderColor: 'rgba(148, 161, 193, 0.4)',
            }}
          />
        )}
      </GridFlex.ColumnMiddle>
      <GridFlex.Column pl={4} rowGap={4} pb={!lastChild ? 6 : 0}>
        {readonly ? (
          <Text.H6 pt={1} color={titleColor}>
            {title}
          </Text.H6>
        ) : (
          <Text.H6 pt={1}>{title}</Text.H6>
        )}
        <GridFlex.Item flex={1}>{children}</GridFlex.Item>
      </GridFlex.Column>
    </GridFlex.Row>
  );
}
