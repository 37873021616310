import { PriceFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';

export function calculateUnitQuantity(monthlyPrice?: PriceFragment, annuallyPrice?: PriceFragment) {
  const minUnitQuantity = monthlyPrice?.minUnitQuantity || annuallyPrice?.minUnitQuantity;
  const maxUnitQuantity = monthlyPrice?.maxUnitQuantity || annuallyPrice?.maxUnitQuantity;
  return [minUnitQuantity, maxUnitQuantity];
}

export function calculateUnitQuantityText(
  minUnitQuantity?: number | null,
  maxUnitQuantity?: number | null,
  unitsPlural?: string | null,
  featureName?: string,
) {
  // copy for only maxunit
  if ((!minUnitQuantity || minUnitQuantity === 1) && maxUnitQuantity) {
    return t('unitQuantity.masUnitQuantity', { maxUnitQuantity, unitsPlural });
  }
  // both limits
  if (minUnitQuantity && minUnitQuantity > 1 && maxUnitQuantity) {
    return t('unitQuantity.bothUnitQuantity', { minUnitQuantity, maxUnitQuantity, unitsPlural });
  }
  if (minUnitQuantity && minUnitQuantity > 1) {
    return t('unitQuantity.minUnitQuantity', { minUnitQuantity, unitsPlural });
  }
  if (featureName) {
    return t('unitQuantity.customVaries', { featureName });
  }
  return t('unitQuantity.varies');
}

export function calculateUnitQuantityTextFromPriceFragments(
  monthlyPrice?: PriceFragment,
  annuallyPrice?: PriceFragment,
  unitsPlural?: string | null,
  featureName?: string,
) {
  const [minUnitQuantity, maxUnitQuantity] = calculateUnitQuantity(monthlyPrice, annuallyPrice);
  return calculateUnitQuantityText(minUnitQuantity, maxUnitQuantity, unitsPlural, featureName);
}
