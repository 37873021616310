import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  DeleteOnePackageEntitlementMutation,
  DeleteOnePackageEntitlementMutationVariables,
  ErrorCode,
  PackageEntitlementDeleteResponse,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const DELETE_PACKAGE_ENTITLEMENT = gql`
  mutation deleteOnePackageEntitlement($input: DeleteOnePackageEntitlementInput!) {
    deleteOnePackageEntitlement(input: $input) {
      id
    }
  }
`;

async function deleteEntitlementByPackageId(
  deleteData: { entitlementId: string },
  { dispatch }: { dispatch: AppDispatch },
): Promise<PackageEntitlementDeleteResponse | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        DeleteOnePackageEntitlementMutation,
        DeleteOnePackageEntitlementMutationVariables
      >({
        mutation: DELETE_PACKAGE_ENTITLEMENT,
        variables: { input: { id: deleteData.entitlementId } },
      });

      return response.data?.deleteOnePackageEntitlement;
    },
    {
      failureMessageHandler: () => t('entitlements.api.failRevoke'),
      expectedErrorsMessage: {
        [ErrorCode.CannotEditPackageInNonDraftMode]: t('entitlements.api.cannotEditNonDraftMode'),
      },
    },
    dispatch,
  );
}

export { deleteEntitlementByPackageId };
