import { Components } from '@mui/material/styles/components';

import typography from './typography';
import { VariantType } from './variants.types';

export const getComponents = (variant: VariantType, isLightTheme: boolean): Components => ({
  ...(isLightTheme
    ? {}
    : {
        MuiAlert: {
          styleOverrides: {
            filledInfo: {
              color: variant.itamar.palette.info.content,
              backgroundColor: variant.itamar.palette.info.background,
              borderColor: variant.itamar.palette.info.main,
            },
            filledSuccess: {
              color: variant.itamar.palette.success.content,
              backgroundColor: variant.itamar.palette.success.background,
              borderColor: variant.itamar.palette.success.main,
            },
            filledWarning: {
              color: variant.itamar.palette.warning.content,
              backgroundColor: variant.itamar.palette.warning.background,
              borderColor: variant.itamar.palette.warning.main,
            },
            filledError: {
              color: variant.itamar.palette.error.content,
              backgroundColor: variant.itamar.palette.error.background,
              borderColor: variant.itamar.palette.error.main,
            },
            outlinedInfo: {
              color: variant.itamar.palette.info.content,
              backgroundColor: variant.itamar.palette.info.background,
              borderColor: variant.itamar.palette.info.main,
            },
            outlinedSuccess: {
              color: variant.itamar.palette.success.content,
              backgroundColor: variant.itamar.palette.success.background,
              borderColor: variant.itamar.palette.success.main,
            },
            outlinedWarning: {
              color: variant.itamar.palette.warning.content,
              backgroundColor: variant.itamar.palette.warning.background,
              borderColor: variant.itamar.palette.warning.main,
            },
            outlinedError: {
              color: variant.itamar.palette.error.content,
              backgroundColor: variant.itamar.palette.error.background,
              borderColor: variant.itamar.palette.error.main,
            },
            standardInfo: {
              color: variant.itamar.palette.info.content,
              backgroundColor: variant.itamar.palette.info.background,
              borderColor: variant.itamar.palette.info.main,
            },
            standardSuccess: {
              color: variant.itamar.palette.success.content,
              backgroundColor: variant.itamar.palette.success.background,
              borderColor: variant.itamar.palette.success.main,
            },
            standardWarning: {
              color: variant.itamar.palette.warning.content,
              backgroundColor: variant.itamar.palette.warning.background,
              borderColor: variant.itamar.palette.warning.main,
            },
            standardError: {
              color: variant.itamar.palette.error.content,
              backgroundColor: variant.itamar.palette.error.background,
              borderColor: variant.itamar.palette.error.main,
            },
          },
        },
      }),
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-input.Mui-disabled': {
          color: variant.itamar.palette.text.disabled,
          '-webkit-text-fill-color': variant.itamar.palette.text.disabled,
        },
        radius: '10px',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.other.outlineBorder}`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.primary.main}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${variant.itamar.palette.primary.main}`,
        },
        '&.Mui-disabled': {
          color: 'red',
          backgroundColor: variant.itamar.palette.grey[50],
        },
        color: 'palette.other.outlineBorder',
        transition: '0.1s',
        ...typography.body2,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      thumb: {
        // todo:fix this #colors
        // boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
        boxShadow: '0 0 10px #001E6C1f',
      },
    },
  },
});
