import { styled } from '@stigg-theme';
import { t } from 'i18next';
import { Text, GridFlex, Icon } from '@stigg-components';
import { PlanFragment, AddonFragment } from '../../../../../types/apiTypes';

const PriceViewBox = styled(GridFlex.RowCenter)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export function CustomPricingView({ aPackage }: { aPackage: PlanFragment | AddonFragment }) {
  return (
    <PriceViewBox p={4}>
      <Icon icon="CustomPricingType" />
      <Text.H6 ml={4}>{t('pricing.customPricingType')}</Text.H6>
      <Text.B2 ml={4}>
        {t('pricing.customPricingViewSubtitle', { packageType: aPackage.type === 'Plan' ? 'plan' : 'add-on' })}
      </Text.B2>
    </PriceViewBox>
  );
}
