import { RootState } from '../../redux/store';

export const getEnvPathFunc = ({
  currentEnvironmentId,
  environments,
}: Pick<RootState['accountReducer'], 'currentEnvironmentId' | 'environments'>) => {
  return (path: string) => {
    const environment = environments?.find((x) => x.id === currentEnvironmentId);
    if (!environment) {
      return path;
    }

    const pathSuffix = path.startsWith('/') ? path.slice(1) : path;
    return `/${environment.slug}/${pathSuffix}`;
  };
};
