import { PriceFragment } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';

export function hasTierWithUnitPrice(tiers: PriceFragment['tiers']) {
  return tiers?.some(({ unitPrice, upTo }) => unitPrice && !isNil(upTo));
}

export function hasTierWithFlatPrice(tiers: PriceFragment['tiers']) {
  return tiers?.some(({ flatPrice }) => flatPrice);
}

export function isBulkTiers(tiers: PriceFragment['tiers']) {
  return tiers?.every(({ unitPrice, upTo }) => !unitPrice || isNil(upTo));
}

export function isQuantityInFirstTier(tiers: PriceFragment['tiers'], quantity: number) {
  return tiers?.[0].upTo && quantity <= tiers[0].upTo;
}
