import { t } from 'i18next';
import toLower from 'lodash/toLower';
import { ChangeType, PlanCompatibleAddonFragment } from '@stigg-types/apiTypes';
import { ChangeGroupBox, ChangeRow, getChange } from './DiffBaseComponents';
import { isCompatibleAddonSingleInstance } from '../../packageUtils';

export type BaseAddonChangeEntity = {
  displayName: string;
  refId: string;
  description?: string | null;
  maxQuantity?: number | null;
};

const getAddonType = (entity?: BaseAddonChangeEntity | null, isAddonGroup?: boolean) => {
  if (!entity) {
    return null;
  }

  if (isAddonGroup) {
    return t('packages.addonGroupType');
  }

  return isCompatibleAddonSingleInstance(entity as PlanCompatibleAddonFragment)
    ? `${t('packages.singleAddonType')} ${toLower(t('packages.addon'))}`
    : `${t('packages.multiAddonType')} ${toLower(t('packages.addon'))}`;
};

export function BaseAddonChangeRow({
  before,
  after,
  changeType,
  isAddonGroup,
}: {
  before?: BaseAddonChangeEntity | null;
  after?: BaseAddonChangeEntity | null;
  changeType?: ChangeType | null;
  isAddonGroup?: boolean;
}) {
  const addonTypeBefore = getAddonType(before, isAddonGroup);
  const addonTypeAfter = getAddonType(after, isAddonGroup);

  return (
    <ChangeGroupBox changeType={changeType}>
      <ChangeRow
        before={before?.displayName}
        after={after?.displayName}
        label="Add-on name"
        showAlways
        {...getChange(before?.displayName, after?.displayName)}
      />
      {before?.description || after?.description ? (
        <ChangeRow
          before={before?.description}
          after={after?.description}
          label="Add-on description"
          showAlways
          {...getChange(before?.description, after?.description)}
        />
      ) : null}
      <ChangeRow
        before={before?.refId}
        after={after?.refId}
        label="Add-on ID"
        showAlways
        {...getChange(before?.refId, after?.refId)}
      />
      <ChangeRow
        before={addonTypeBefore}
        after={addonTypeAfter}
        label="Type"
        showAlways
        {...getChange(addonTypeBefore, addonTypeAfter)}
      />
    </ChangeGroupBox>
  );
}
