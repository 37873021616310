import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { GridFlex, Step, StepLabel, StepperPoints, Text } from '@stigg-components';

const IMPORT_PRODUCTS_STEPS = () => [
  {
    label: 'Select product',
  },
  {
    label: 'Configure plans',
  },
];

const StyledStepperPoints = styled(StepperPoints)`
  .MuiSvgIcon-root:not(.Mui-active) {
    color: ${({ theme }) => theme.itamar.palette.text.disabled};
  }
`;

export function AWSImportWizardSideNav({ activeStep }: { activeStep: number }) {
  return (
    <GridFlex.Column
      item
      xl={3}
      p={14}
      sx={{
        height: '100%',
        borderRight: (theme) => `1px solid ${theme.itamar.palette.action.disabledBackground}`,
        zIndex: 2,
        paddingTop: '50px',
      }}>
      <Text.H6 mb={6}>{t('integrations.awsMarketplace.import.sideNavTitle')}</Text.H6>
      <StyledStepperPoints activeStep={activeStep} orientation="vertical">
        {IMPORT_PRODUCTS_STEPS().map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </StyledStepperPoints>
    </GridFlex.Column>
  );
}
