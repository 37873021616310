import React, { ReactNode } from 'react';
import { Text, OptionsDropdown, Chip, Grid, GridFlex } from '@stigg-components';
import { OptionsDropdownType } from '@stigg-components/types';
import { ChipColor } from '@stigg-theme';
import { Edit2, Archive } from 'react-feather';

export function CardHeaderLayout({
  title,
  displayName,
  chip,
  onEditClick,
  onDeleteClick,
  options,
  hideOptions,
  headerIcon,
  deleteText,
}: {
  title?: string;
  displayName?: string | null;
  chip?: {
    component?: ReactNode; // if you want to have a custom Chip component
    label?: ReactNode;
    color?: ChipColor;
  };
  onEditClick?: (status: boolean) => void;
  onDeleteClick?: (status: boolean) => void;
  options?: {
    component?: ReactNode; // if we want to have a custom OptionsDropdown component
    options?: OptionsDropdownType; // if we want to have a custom menu options
  };
  hideOptions?: boolean;
  headerIcon?: React.ReactNode;
  deleteText?: string;
}) {
  const ChipComp = chip?.component;
  const OptionsComp = options?.component;

  return (
    <>
      <GridFlex.RowSpaceBetweenTop container wrap="nowrap">
        <GridFlex.RowCenter item>
          {headerIcon}
          <GridFlex.Column item ml={headerIcon ? 4 : 0}>
            {title && <Text.Sub2 mb={1}>{title}</Text.Sub2>}
            <GridFlex.RowCenter>
              <Text.H3 mr={4}>{displayName}</Text.H3>
              {chip && (ChipComp ?? (chip.color && <Chip size="small" label={chip.label} color={chip.color} />))}
            </GridFlex.RowCenter>
          </GridFlex.Column>
        </GridFlex.RowCenter>

        {!hideOptions && (
          <Grid alignSelf="flex-start" item>
            {OptionsComp ?? (
              <OptionsDropdown
                options={
                  options?.options ?? [
                    {
                      icon: Edit2,
                      text: 'Edit',
                      onClick: () => (onEditClick !== undefined ? onEditClick(true) : null),
                    },
                    {
                      icon: Archive,
                      text: deleteText || 'Delete',
                      onClick: () => (onDeleteClick !== undefined ? onDeleteClick(true) : null),
                    },
                  ]
                }
              />
            )}
          </Grid>
        )}
      </GridFlex.RowSpaceBetweenTop>
    </>
  );
}
