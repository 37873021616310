import { Feature } from '@stigg-types/apiTypes';

export enum FeatureOptionType {
  FEATURE = 'FEATURE',
  ADD_FEATURE = 'ADD_FEATURE',
}

export type NewEntitlementsOption = Feature & {
  isAlreadyAdded: boolean;
  optionType?: FeatureOptionType;
};
