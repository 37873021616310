/* eslint-disable no-restricted-imports */
import { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { spacing } from '@mui/system';
import { ButtonColor } from '@stigg-theme';
import { buttonStyle } from './Buttons.style';

// due to the face color is a props coming from MuiIconButton when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiIconButton['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it :)

type StyledIconButtonType = {
  color?: ButtonColor;
  $typesafeColor?: ButtonColor; // look at the first comment
  $outlined?: boolean;
  $disabled?: boolean;
  $withDisabledBackground?: boolean;
  hoverColor?: ButtonColor | 'disabled';
  dataTestId?: string;
  display?: 'flex' | 'inline';
};

type IconButtonProps = Omit<MuiIconButtonProps, 'color'> & Omit<StyledIconButtonType, '$typesafeColor' | '$disabled'>;

const SpacedIconButton = styled(MuiIconButton)(spacing);
const StyledIconButton = styled(SpacedIconButton)<StyledIconButtonType>`
  ${({ $typesafeColor, hoverColor, $outlined = true, $disabled, $withDisabledBackground, display }) =>
    buttonStyle($typesafeColor, $outlined, $disabled, undefined, hoverColor, $withDisabledBackground, display)}
`;

export const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<IconButtonProps>>(
  ({ color, hoverColor, disabled, dataTestId, display, ...props }, ref) => (
    <StyledIconButton
      $typesafeColor={color}
      hoverColor={hoverColor}
      $disabled={disabled}
      disabled={disabled}
      data-testid={dataTestId}
      display={display}
      {...props}
      ref={ref}
    />
  ),
);
