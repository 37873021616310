import { AwsChip, refIdValidation, useIntegrations } from '@stigg-common';
import {
  ActiveIntegrationIcons,
  ExternalLink,
  Form,
  FormProps,
  FormRenderProps,
  FullScreenDialogLayout,
  Grid,
  GridFlex,
  Icon,
  LongText,
  OutlinedFormFieldLabel,
  Text,
} from '@stigg-components';
import {
  BillingModel,
  BillingPeriod,
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PlanFragment,
  PlanListFragment,
  PricingType,
  SubscriptionDataFragment,
  SubscriptionStatus,
  PaymentCollectionMethod,
} from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SchemaLike } from 'yup/lib/types';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import { first } from 'lodash';
import Loader from '../../../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { fetchAddonsAction } from '../../../../../packages/addons/addonSlice';
import { entitlementValidationSchema } from '../../../../../packages/plans/components/EntitlementsForm';
import { EntitlementsTable } from '../../../../../packages/plans/components/EntitlementsTable';
import {
  extractCountryWithCurrencyMap,
  DEFAULT_CURRENCY,
} from '../../../../../packages/pricing/components/currency/currencyUtils';
import { SubscriptionPreview } from '../SubscriptionPreview';
import { TrialSubscriptionConfig } from '../trialSubscriptionConfig/TrialSubscriptionConfig';
import {
  BillingCycle,
  ChargesTable,
  LocalizationSelect,
  SubscriptionAddons,
  SubscriptionPayment,
  SubscriptionUpdateEstimationsAlert,
  SubscriptionScheduleUpdates,
  CustomerDetails,
  SubscriptionStart,
  useAddedAddonsMap,
  noAvailablePlansTooltip,
  noAvailableProductsTooltip,
  BudgetSpendingConfiguration,
  switchToCreateModeTooltip,
} from './components';
import { SubscriptionFormFields } from './SubscriptionForm.types';
import {
  getInitialFormValues,
  getPlanCharges,
  getPlanDefaultBillingData,
  getPlanPricePoints,
  getPlanPrices,
  getPlanSelectOptions,
  mapPackageEntitlementsToEntitlementFields,
} from './SubscriptionForm.utils';
import { useSubscriptionFormResources } from './useSubscriptionFormResources';
import { SUBSCRIPTION_PREVIEW_MIN_WIDTH } from '../SubscriptionPriceBreakdown';
import { SubscriptionDiscountConfiguration } from './components/SubscriptionDiscountConfiguration';

type CreateSubscriptionFormProps = {
  handleSubmit: (
    values: SubscriptionFormFields,
    plan?: PlanListFragment | PlanFragment,
    isAllowEmptyResourceIdEnabled?: boolean,
  ) => Promise<any>;
  onCancel: () => void;
  customer: CustomerResponseFragment;
  subscription: CustomerSubscriptionDataFragment | null | undefined;
  switchToCreateMode: () => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
};

export function SubscriptionForm({
  handleSubmit,
  onCancel,
  customer,
  subscription,
  switchToCreateMode,
  setHasUnsavedChanges,
}: CreateSubscriptionFormProps) {
  const {
    multipleSubscriptionsUi: multipleSubscriptionsUiEnabled,
    budgetCap: isBudgetCapEnabled,
    paidInvoices: paidInvoicesEnabled,
    paymentLink: isPaymentLinkEnabled,
    minimumSpend: isMinimumSpendEnabled,
    allowEmptyResourceId: isAllowEmptyResourceIdEnabled,
  } = useFlags<FeatureFlags>();
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isUpdatingSubscription = !!subscription;
  const [hasSubscriptionUpdatesSkipped, setHasSubscriptionUpdatesSkipped] = useState(false);
  const hasSubscriptionFutureUpdates = (subscription?.futureUpdates?.length || 0) > 0;
  const shouldBlockUpdating = !hasSubscriptionUpdatesSkipped && hasSubscriptionFutureUpdates;
  const products = useSelector((state: RootState) => state.productReducer.products);

  const {
    existingProducts,
    isLoading,
    hasMultipleProducts,
    currentPlan,
    getProduct,
    getPlan,
    getProductPlans,
    isProductSupportMultipleSubscriptions,
    isCustomPricePlan,
    isFreePlan,
    isPerUnitPricingModal,
  } = useSubscriptionFormResources({
    subscription,
  });

  const initialValues: SubscriptionFormFields = getInitialFormValues(
    subscription,
    existingProducts,
    currentPlan,
    paidInvoicesEnabled,
  );

  const { onPlanIdChange, addedAddonsMap } = useAddedAddonsMap(initialValues.addons);

  // Payment method
  const { hasBillingIntegration, isSyncingCustomerAndSubscriptions } = useIntegrations();
  const hasPaymentMethod = !!customer.defaultPaymentMethodId;

  const getValidationSchema = () => {
    const billableFeaturesValidation = (
      planId: string,
      billingPeriod: BillingPeriod,
      billingCountryCode: string | null,
    ): SchemaLike => {
      if (!isPerUnitPricingModal(planId)) {
        return Yup.object().optional();
      }

      const currentPlan = getPlan(planId);
      const currentPlanCharges = getPlanCharges(currentPlan, billingPeriod, billingCountryCode) || [];
      const perUnitCharges = currentPlanCharges.filter((charge) => charge.billingModel === BillingModel.PerUnit);

      return Yup.object().shape(
        Object.fromEntries(
          perUnitCharges.map((charge) => [
            charge.feature?.refId,
            Yup.number().required(t('fieldValidationMessages.required')),
          ]),
        ),
      );
    };

    const budgetValidation = (minimumSpend: SubscriptionFormFields['minimumSpend']): SchemaLike => {
      const hasMinimumSpend = !isNil(minimumSpend?.minimum?.amount);
      const value = minimumSpend?.minimum?.amount || 0;
      return Yup.object()
        .shape({
          limit: Yup.number()
            .min(
              value,
              t('fieldValidationMessages.mustBeGreaterThan', {
                value: hasMinimumSpend
                  ? toLower(t('subscriptionForm.budgetSpendingConfiguration.minimumSpend.label'))
                  : value,
              }),
            )
            .required(t('fieldValidationMessages.required')),
          hasSoftLimit: Yup.boolean().required(),
        })
        .default(undefined);
    };

    return Yup.object().shape({
      billingPeriod: Yup.mixed().when(['planId', 'paymentCollectionMethod'], {
        is: (planId: string, paymentCollectionMethod: PaymentCollectionMethod) =>
          paymentCollectionMethod === PaymentCollectionMethod.None || isCustomPricePlan(planId) || isFreePlan(planId),
        then: Yup.mixed().optional(),
        otherwise: Yup.mixed<BillingPeriod | string>()
          .oneOf(Object.values(BillingPeriod))
          .required(t('fieldValidationMessages.required')),
      }),
      billableFeatures: Yup.object().when(
        ['planId', 'billingPeriod', 'billingCountryCode'],
        // @ts-ignore
        billableFeaturesValidation,
      ),
      resourceId: Yup.string().when(['productId'], {
        is: (productId: string) => isProductSupportMultipleSubscriptions(productId) && !isAllowEmptyResourceIdEnabled,
        then: refIdValidation(),
        otherwise: Yup.string().optional(),
      }),
      planId: Yup.string(),
      startDate: Yup.date().required(t('fieldValidationMessages.required')),
      addons: Yup.array().of(
        Yup.object().shape({
          quantity: Yup.number()
            .min(1, t('fieldValidationMessages.mustBePositiveNumber'))
            .required(t('fieldValidationMessages.required')),
        }),
      ),
      entitlements: Yup.array().of(entitlementValidationSchema).required(),
      minimumSpend: Yup.object()
        .shape({
          minimum: Yup.object().shape({
            amount: Yup.number()
              .min(0, t('fieldValidationMessages.mustBePositiveNumber'))
              .required(t('fieldValidationMessages.required')),
          }),
        })
        .default(undefined),
      budget: Yup.object().when(['minimumSpend'], budgetValidation),
      paymentCollectionMethod: Yup.string().oneOf(Object.values(PaymentCollectionMethod)).optional(),
      isInvoicePaid: Yup.boolean().optional(),
      invoiceDaysUntilDue: Yup.mixed().when(['paymentCollectionMethod', 'isInvoicePaid'], {
        is: (paymentCollectionMethod: PaymentCollectionMethod, isInvoicePaid: boolean) =>
          paymentCollectionMethod === PaymentCollectionMethod.Invoice && !isInvoicePaid,
        then: Yup.number()
          .min(0, t('fieldValidationMessages.mustBePositiveNumber'))
          .required(t('fieldValidationMessages.required')),
        otherwise: Yup.number().optional(),
      }),
    });
  };

  const validateForm: FormProps<SubscriptionFormFields>['validate'] = (values) => {
    const errors: any = {};

    if (values?.planId) {
      const currentPlan = getPlan(values.planId);
      const isCustomPrice =
        isCustomPricePlan(values.planId) || values.paymentCollectionMethod === PaymentCollectionMethod.None;

      const isPaidPlan = !values.isTrial && !isCustomPrice && currentPlan?.pricingType === PricingType.Paid;
      const isPriceInformationFilled = !!values.billingPeriod;
      const isPaymentMethodRequired =
        !paidInvoicesEnabled &&
        isSyncingCustomerAndSubscriptions &&
        hasBillingIntegration &&
        isPaidPlan &&
        isPriceInformationFilled &&
        values.paymentCollectionMethod === PaymentCollectionMethod.Charge;

      if (isPaymentMethodRequired && !hasPaymentMethod) {
        errors.paymentMethod = 'Required';
      }
    }

    return errors;
  };

  const handleProductChange = ({ resetForm }: FormRenderProps<SubscriptionFormFields>) => {
    return (e: any) => {
      const newProductId = e?.target?.value;
      resetForm({ values: { ...initialValues, ...(newProductId ? { productId: newProductId } : {}) } });
    };
  };

  const handlePlanChange = ({
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
  }: FormRenderProps<SubscriptionFormFields>) => {
    return (e: any) => {
      if (!e?.target?.value) {
        return;
      }

      const planId = e.target.value;
      onPlanIdChange(planId);

      const newSelectedPlan = getPlan(planId);
      const { billingPeriod, billingCountryCode } = getPlanDefaultBillingData({
        plan: newSelectedPlan,
        customerBillingCurrency: customer.billingCurrency,
      });

      if (values.paymentCollectionMethod === PaymentCollectionMethod.None) {
        setFieldValue('billingCountryCode', null);
      } else {
        setFieldValue('billingCountryCode', billingCountryCode);
      }

      const isNewPlanPaid =
        newSelectedPlan?.pricingType !== PricingType.Paid ||
        values.paymentCollectionMethod === PaymentCollectionMethod.None;

      if (newSelectedPlan.defaultTrialConfig?.budget) {
        setFieldValue('budget', {
          limit: newSelectedPlan.defaultTrialConfig.budget.limit,
          hasSoftLimit: false,
        });
      } else {
        setFieldValue('budget', undefined);
      }

      if (newSelectedPlan.minimumSpend) {
        const minimumSpend = newSelectedPlan.minimumSpend.find(
          (minimumSpend) => minimumSpend.billingPeriod === billingPeriod,
        );
        if (minimumSpend) {
          setFieldValue('minimumSpend', {
            minimum: { amount: minimumSpend.minimum?.amount },
          });
        }
      } else {
        setFieldValue('minimumSpend', undefined);
      }

      if (paidInvoicesEnabled) {
        setFieldValue('isInvoicePaid', !isNewPlanPaid);
      }

      setFieldValue('planId', planId);
      setFieldValue('billingPeriod', billingPeriod);
      setFieldValue('billableFeatures', {});
      setFieldTouched('billableFeatures', false);
      setFieldValue('isTrial', !!newSelectedPlan?.defaultTrialConfig); // turn of switch by default and allow switching on if plan allows it
      setFieldValue('addons', []);
      setFieldValue(
        'entitlements',
        mapPackageEntitlementsToEntitlementFields([
          ...(newSelectedPlan.inheritedEntitlements ?? []),
          ...(newSelectedPlan.entitlements ?? []),
        ]),
      );
      setFieldValue('coupon', undefined);
      setFieldValue('couponRefId', undefined);

      // the validation schema runs every time a field is changed, but on a timed interval,
      // so what happens when switching plan and updating multiple fields
      // at the same time, the validation schema missing to check some of the values.
      // therefore, we need to time the last field update - to cause the validation schema to run one last check
      setTimeout(() => {
        handleChange(e);
        const supportedAddonIds: string[] = newSelectedPlan?.compatibleAddons?.map((x) => x.refId) || [];
        const supportedAddonViaPackageGroup: string[] =
          newSelectedPlan?.compatiblePackageGroups?.flatMap((x) => x.addons.map((addon) => addon.refId)) || [];

        const addonIds = uniq([...supportedAddonIds, ...supportedAddonViaPackageGroup]);
        void dispatch(
          fetchAddonsAction({
            environmentId: currentEnvironmentId,
            addonIds,
          }),
        );
      }, 0);
    };
  };

  const dialogTitleText = isUpdatingSubscription
    ? t('subscriptionForm.updateSubscriptionTitle')
    : t('subscriptionForm.createSubscriptionTitle');

  return (
    <FullScreenDialogLayout
      title={`${t('subscriptionForm.customer')} / ${dialogTitleText}`}
      gridProps={{ overflow: 'hidden' }}>
      {isLoading && <Loader />}
      {hasSubscriptionFutureUpdates && isUpdatingSubscription && (
        <Grid
          mt={12}
          sx={{
            width: '100%',
            maxWidth: 1200,
            alignSelf: 'center',
          }}>
          <SubscriptionScheduleUpdates
            subscription={subscription as unknown as SubscriptionDataFragment}
            setHasSubscriptionUpdatesSkipped={setHasSubscriptionUpdatesSkipped}
          />
        </Grid>
      )}
      {!isLoading && (
        <GridFlex.Row container alignSelf="center" justifyContent="center" maxWidth={1250} overflow="hidden" flex={1}>
          <Form
            rowSpacing={6}
            paddingRight={12}
            withStickyFooter
            actionDialogRightSpacing={12}
            validate={validateForm}
            validationSchema={getValidationSchema()}
            initialValues={initialValues}
            onSubmit={(values: SubscriptionFormFields) =>
              handleSubmit(values, getPlan(values.planId), isAllowEmptyResourceIdEnabled)
            }
            onCancel={onCancel}
            footerComponent={isSyncingCustomerAndSubscriptions ? <ActiveIntegrationIcons /> : null}
            withFooterSpacing
            disableSaveOnNoChanges
            submitButtonText={isUpdatingSubscription ? 'Update' : undefined}
            setHasUnsavedChanges={setHasUnsavedChanges}
            contentRenderer={({ formContent, formProps }) => {
              const { values, isValid, errors } = formProps;
              const { planId } = values;
              const currentPlan = getPlan(planId);
              const numberOfErrors = Object.keys(errors).length;
              const onlyErrorIsMissingPM = numberOfErrors === 1 && errors.paymentMethod === 'Required';

              return (
                <GridFlex.Row
                  container
                  item
                  alignSelf="center"
                  justifyContent="center"
                  flexWrap="nowrap"
                  sx={{
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    opacity: shouldBlockUpdating ? '0.5' : undefined,
                    cursor: shouldBlockUpdating ? 'disabled' : undefined,
                    pointerEvents: shouldBlockUpdating ? 'none' : undefined,
                  }}>
                  <GridFlex.Column flex={2} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    {formContent}
                  </GridFlex.Column>
                  <GridFlex.Column
                    flex={1}
                    minWidth={SUBSCRIPTION_PREVIEW_MIN_WIDTH}
                    pt={27}
                    pb={12}
                    px={2}
                    sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    {currentPlan && <SubscriptionPreview customer={customer} values={values} plan={currentPlan} />}
                    {(isValid || onlyErrorIsMissingPM) &&
                      !values.minimumSpend &&
                      isEmpty(values.billableFeaturesPriceOverride) &&
                      isEmpty(values.addonsPriceOverride) && (
                        <SubscriptionUpdateEstimationsAlert
                          values={values}
                          subscription={subscription}
                          customer={customer}
                          plan={currentPlan}
                          isNewSubscription={!isUpdatingSubscription}
                        />
                      )}
                  </GridFlex.Column>
                </GridFlex.Row>
              );
            }}
            fields={(formProps) => {
              const { values, handleChange, setFieldValue } = formProps;
              const currentProduct = values.productId ? getProduct(values.productId) : undefined;
              const currentPlan = values.planId ? getPlan(values.planId) : undefined;
              const planPrices =
                currentPlan && (values.billingPeriod || values.paymentCollectionMethod === PaymentCollectionMethod.None)
                  ? getPlanPrices({
                      plan: currentPlan,
                      billingPeriod:
                        values.paymentCollectionMethod === PaymentCollectionMethod.None
                          ? first(currentPlan.prices)?.billingPeriod
                          : (values.billingPeriod as BillingPeriod),
                      billingCountryCode: values.billingCountryCode,
                      customerBillingCurrency:
                        values.paymentCollectionMethod !== PaymentCollectionMethod.None
                          ? customer.billingCurrency
                          : undefined,
                    })
                  : [];

              const currentCurrency = planPrices?.[0]?.price?.currency || customer.billingCurrency || DEFAULT_CURRENCY;

              const existingProductPlans = getProductPlans(values.productId, {
                billingCurrency: customer.billingCurrency || undefined,
              });

              const currentPlanCountryWithCurrencyMap =
                currentPlan && currentPlan.pricingType === PricingType.Paid
                  ? extractCountryWithCurrencyMap([currentPlan], {
                      currencyFilter: customer.billingCurrency || undefined,
                      withDefaultCountry: !customer.billingCurrency || customer.billingCurrency === DEFAULT_CURRENCY,
                    })
                  : new Map();

              const planSelectOptions = getPlanSelectOptions(existingProductPlans);
              const planPricePoints = getPlanPricePoints({
                plan: currentPlan,
                billingCountryCode: values.billingCountryCode,
                customerBillingCurrency: customer.billingCurrency,
              });

              const showPriceByMonth = !!(
                currentPlan?.prices?.length &&
                values.billingPeriod &&
                values.billingPeriod === BillingPeriod.Annually &&
                currentPlan?.prices?.some(({ billingPeriod }) => billingPeriod === BillingPeriod.Monthly)
              );

              const isCustomSubscription =
                currentPlan?.pricingType === PricingType.Custom ||
                values.paymentCollectionMethod === PaymentCollectionMethod.None;
              const isFreePlan = currentPlan?.pricingType === PricingType.Free;
              const isPaidPlan = currentPlan?.pricingType === PricingType.Paid;

              const doesProductSupportMultipleSubscriptions = currentProduct?.multipleSubscriptions;
              const showResourceIdField = multipleSubscriptionsUiEnabled && doesProductSupportMultipleSubscriptions;

              // we filter out products with no published plans, so if
              // no products then no available plans to select
              const hasAvailablePlan = existingProducts.length > 0;
              const hasAvailableProducts = products.length > 0;
              const hasCoupon = customer?.coupon || subscription?.coupon;
              const shouldHideSubscriptionSchedule = !values.planId;
              const shouldHidePaymentSection =
                (paidInvoicesEnabled && !isPaymentLinkEnabled) ||
                !values.planId ||
                isFreePlan ||
                (values.isTrial && !hasCoupon) ||
                (!customer.billingId && (!hasBillingIntegration || !isSyncingCustomerAndSubscriptions));

              const isInTrial =
                subscription?.status === SubscriptionStatus.InTrial && Boolean(subscription.trialEndDate);

              const isNotStartedWithTrial =
                subscription?.status === SubscriptionStatus.NotStarted && !!subscription.trialEndDate;
              const isNotInTrial = subscription?.status !== SubscriptionStatus.InTrial && !isNotStartedWithTrial;

              const shouldHideTrialConfiguration = !values.planId || isFreePlan;

              const shouldHideInvoiceSection =
                !paidInvoicesEnabled ||
                isPaymentLinkEnabled ||
                !values.planId ||
                currentPlan?.pricingType !== PricingType.Paid ||
                isCustomSubscription;

              const shouldHideBudgetSpendingConfiguration =
                !isBudgetCapEnabled ||
                !isMinimumSpendEnabled ||
                !values.planId ||
                currentPlan?.pricingType !== PricingType.Paid ||
                values.isTrial ||
                isCustomSubscription ||
                currentPlan?.prices?.every((price) => price.billingModel !== BillingModel.UsageBased);

              const shouldHideDiscountConfiguration =
                !values.planId || !isPaidPlan || values.paymentCollectionMethod === PaymentCollectionMethod.None;

              return [
                {
                  type: 'custom',
                  gridProps: { paddingTop: '48px !important', paddingBottom: '32px !important' },
                  render: () => <Text.H3>{dialogTitleText}</Text.H3>,
                },
                {
                  type: 'custom',
                  gridProps: {
                    paddingTop: '16px !important',
                    borderRadius: (theme) => theme.itamar.border.radius,
                    border: (theme) => theme.itamar.border.border,
                    borderColor: (theme) => theme.itamar.border.borderColor,
                    ml: 3,
                  },
                  render: () => <CustomerDetails customer={customer} />,
                },
                {
                  type: 'custom',
                  gridProps: { paddingTop: '32px !important' },
                  render: () => <Text.H6>{t('subscriptionForm.subscriptionContentTitle')}</Text.H6>,
                },
                {
                  type: 'layout',
                  gridProps: { alignItems: 'flex-start', flexWrap: 'nowrap', paddingTop: '16px !important' },
                  hide: () => !showResourceIdField && !hasMultipleProducts,
                  fields: [
                    {
                      type: 'select',
                      id: 'productId',
                      label: t('subscriptions.subscriptionProduct'),
                      disabled: isUpdatingSubscription,
                      tooltip: isUpdatingSubscription
                        ? switchToCreateModeTooltip(
                            t('subscriptionForm.switchToCreateModeForProduct'),
                            switchToCreateMode,
                          )
                        : undefined,
                      handleChange: handleProductChange,
                      gridProps: showResourceIdField ? { width: '50%' } : undefined,
                      hide: () => !hasMultipleProducts,
                      restProps: {
                        placeholder: t('subscriptions.subscriptionProductPlaceholder'),
                      },
                      values: existingProducts.map((product) => ({
                        value: product.refId,
                        disabled: !!product.awsMarketplaceProductId,
                        displayValue: (
                          <Grid gap={2}>
                            <Grid item>
                              <LongText>{product.displayName}</LongText>
                            </Grid>
                            {product.awsMarketplaceProductId ? <AwsChip logoOnly /> : null}
                          </Grid>
                        ),
                        overrideDisplayValue: (
                          <GridFlex.RowCenter gap={2}>
                            <Icon icon="Grid" color="active" /> <LongText>{product.displayName}</LongText>
                          </GridFlex.RowCenter>
                        ),
                        subtitlePosition: 'right',
                      })),
                    },
                    {
                      type: 'text',
                      id: 'resourceId',
                      label: t('sharedComponents.resourceId'),
                      placeholder: isAllowEmptyResourceIdEnabled ? 'Default' : t('subscriptions.resourceIdPlaceholder'),
                      hide: () => !showResourceIdField,
                      disabled: isUpdatingSubscription,
                      startAdornment: <Icon icon="Layers" type="reactFeather" color="active" size={16} />,
                      helpTooltipMaxWidth: 235,
                      helpTooltipText: (
                        <GridFlex.Column gap={2}>
                          <Text.B2>{t('sharedComponents.resourceIdExplanation')}</Text.B2>
                          <ExternalLink
                            label={t('sharedComponents.learnMore')}
                            url="https://docs.stigg.io/docs/multiple-subscriptions"
                          />
                        </GridFlex.Column>
                      ),
                    },
                  ],
                },
                {
                  type: 'select',
                  id: 'planId',
                  label: t('subscriptions.subscriptionPlan'),
                  hide: () => hasAvailablePlan && hasAvailableProducts && !values.productId,
                  disabled: isUpdatingSubscription || !hasAvailablePlan,
                  dataTestId: 'select-plan',
                  handleChange: handlePlanChange,
                  disabledCursorNotAllowed: true,
                  tooltip: isUpdatingSubscription
                    ? switchToCreateModeTooltip(t('subscriptionForm.switchToCreateModeForPlan'), switchToCreateMode)
                    : !hasAvailableProducts
                    ? noAvailableProductsTooltip
                    : !hasAvailablePlan
                    ? noAvailablePlansTooltip(products[0].refId)
                    : undefined,
                  restProps: {
                    menuMaxHeight: 350,
                    value: values.planId,
                    placeholder: t('subscriptions.subscriptionPlanPlaceholder'),
                  },
                  values: planSelectOptions,
                },
                {
                  type: 'layout',
                  contentGridProps: { justifyContent: 'space-between' },
                  hide: () => !values.planId || currentPlan?.pricingType !== PricingType.Paid || isCustomSubscription,
                  fields: [
                    {
                      type: 'custom',
                      gridProps: { flex: 'none' },
                      render: () => (
                        <BillingCycle
                          formProps={formProps}
                          planPricePoints={planPricePoints}
                          billingPeriod={values.billingPeriod}
                          isUpdatingSubscription={isUpdatingSubscription}
                          switchToCreateMode={switchToCreateMode}
                        />
                      ),
                    },
                    {
                      type: 'custom',
                      hide: () =>
                        !values.planId ||
                        currentPlanCountryWithCurrencyMap.size === 0 ||
                        (!customer.billingCurrency &&
                          !values.billingCountryCode &&
                          currentPlanCountryWithCurrencyMap.size === 1),
                      gridProps: { flex: 'none' },
                      render: () => {
                        const noOtherCountryOptions =
                          !!customer.billingCurrency && currentPlanCountryWithCurrencyMap.size === 1;

                        return (
                          <LocalizationSelect
                            customer={customer}
                            plansCountryList={Array.from(currentPlanCountryWithCurrencyMap.keys())}
                            countryCodeToCurrencyMap={currentPlanCountryWithCurrencyMap}
                            selectedCountryCode={values.billingCountryCode}
                            disabled={isUpdatingSubscription || noOtherCountryOptions}
                            setFieldValue={setFieldValue}
                            handleChange={() => {
                              // reset coupon selection to prevent selecting wrong currency coupon
                              setFieldValue('coupon', undefined);
                              setFieldValue('couponRefId', undefined);
                              setFieldValue('billableFeaturesPriceOverride', {});
                              setFieldValue('addonsPriceOverride', {});
                            }}
                          />
                        );
                      },
                    },
                  ],
                },
                {
                  type: 'custom',
                  hide: () => !planPrices?.length,
                  render: () => (
                    <GridFlex.Column flex="1">
                      <OutlinedFormFieldLabel label={t('subscriptionForm.planConfiguration')} />
                      <ChargesTable
                        formProps={formProps}
                        charges={planPrices || []}
                        showPriceByMonth={showPriceByMonth}
                      />
                    </GridFlex.Column>
                  ),
                },
                {
                  type: 'custom',
                  hide: () => !values.entitlements?.length,
                  render: () => (
                    <GridFlex.Column flex="1">
                      <OutlinedFormFieldLabel label={t('subscriptionForm.planConfiguration')} />
                      <EntitlementsTable
                        errors={formProps.errors}
                        touched={formProps.touched}
                        handleBlur={formProps.handleBlur}
                        entitlements={values.entitlements}
                        handleChange={(event: any) => {
                          handleChange(event);
                        }}
                        setFieldValue={(field: string, value: any) => {
                          setFieldValue(field, value);
                        }}
                        setFieldTouched={formProps.setFieldTouched}
                      />
                    </GridFlex.Column>
                  ),
                },
                {
                  type: 'custom',
                  hide: () => !values.planId || currentPlan?.pricingType === PricingType.Free,
                  render: () => {
                    return (
                      <SubscriptionAddons
                        addonsMap={addedAddonsMap}
                        currentPlan={currentPlan}
                        formProps={formProps}
                        subscription={subscription}
                        isCustomSubscription={isCustomSubscription}
                      />
                    );
                  },
                },
                {
                  type: 'custom',
                  gridProps: { paddingTop: '40px !important' },
                  render: () => {
                    return <Text.H6>{t('subscriptionForm.invoiceOptions')}</Text.H6>;
                  },
                  hide: () => shouldHideInvoiceSection,
                },
                {
                  type: 'switch',
                  id: 'isInvoicePaid',
                  label: t('subscriptionForm.isInvoicePaid'),
                  hide: () => shouldHideInvoiceSection,
                  disabled: paidInvoicesEnabled,
                },
                {
                  type: 'custom',
                  hide: () => shouldHideSubscriptionSchedule,
                  render: () => (
                    <SubscriptionStart isUpdatingSubscription={isUpdatingSubscription} formRenderProps={formProps} />
                  ),
                },
                {
                  type: 'custom',
                  hide: () => shouldHideTrialConfiguration,
                  gridProps: { paddingTop: '0px !important' },
                  render: () => {
                    return (
                      <TrialSubscriptionConfig
                        currentCurrency={currentCurrency}
                        formRenderProps={formProps}
                        currentPlan={currentPlan}
                        trialEndDate={subscription ? subscription.trialEndDate : undefined}
                        isInTrial={isInTrial}
                        disabled={isUpdatingSubscription && isNotInTrial}
                        isBudgetConfigurationEnabled={isBudgetCapEnabled}
                      />
                    );
                  },
                },
                {
                  type: 'custom',
                  hide: () => !!shouldHideBudgetSpendingConfiguration,
                  gridProps: { paddingTop: '0px !important' },
                  render: () => (
                    <BudgetSpendingConfiguration currentCurrency={currentCurrency} formRenderProps={formProps} />
                  ),
                },
                {
                  type: 'custom',
                  hide: () => shouldHideDiscountConfiguration,
                  render: () => (
                    <SubscriptionDiscountConfiguration
                      customer={customer}
                      currency={currentCurrency}
                      formRenderProps={formProps}
                    />
                  ),
                },
                {
                  type: 'custom',
                  gridProps: { paddingTop: '40px !important' },
                  render: () => {
                    return <Text.H6>{t('subscriptionForm.paymentSectionTitle')}</Text.H6>;
                  },
                  hide: () => shouldHidePaymentSection,
                },
                {
                  type: 'custom',
                  gridProps: {
                    paddingTop: '16 !important',
                    ...(!hasCoupon ? { paddingBottom: 4 } : {}),
                  },
                  render: () => (
                    <SubscriptionPayment
                      isUpdatingSubscription={isUpdatingSubscription}
                      currentPlan={currentPlan}
                      customer={customer}
                      formProps={formProps}
                    />
                  ),
                  hide: () => shouldHidePaymentSection,
                },
              ];
            }}
          />
        </GridFlex.Row>
      )}
    </FullScreenDialogLayout>
  );
}
