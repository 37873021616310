import React, { PropsWithChildren } from 'react';
import isString from 'lodash/isString';
import { Text } from '.';
import { GridProps, GridFlex } from './Grids';
import {
  FullScreenDialogContentTitleContainer,
  FullScreenDialogContentContainer,
} from './FullScreenDialogLayout.style';

export type FullScreenDialogLayoutProps = PropsWithChildren<{
  title: string | React.ReactNode;
  gridProps?: Partial<GridProps>;
  disableContentScroll?: boolean;
  variant?: 'white' | 'grey';
}>;

export function FullScreenDialogLayout({
  children,
  title,
  disableContentScroll,
  gridProps = {},
  variant = 'white',
}: FullScreenDialogLayoutProps) {
  return (
    <GridFlex.Column height="100%" {...gridProps}>
      <FullScreenDialogContentTitleContainer justifyContent="space-between" $withBackground={variant !== 'grey'}>
        {isString(title) ? <Text.B2>{title}</Text.B2> : title}
      </FullScreenDialogContentTitleContainer>
      <FullScreenDialogContentContainer
        $disableContentScroll={disableContentScroll}
        $withBackground={variant === 'grey'}>
        {children}
      </FullScreenDialogContentContainer>
    </GridFlex.Column>
  );
}
