import React from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarItemsType } from '../../../types/sidebar';
import reduceChildRoutes from './reduceChildRoutes';
import { useNavigation } from '../../../modules/navigation/useNavigation';

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
  isSidenavOpen: boolean;
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const navigation = useNavigation();
  const { pages, depth, isSidenavOpen } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth, navigation, isSidenavOpen }),
    [] as JSX.Element[],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childRoutes}</>;
};

export default SidebarNavList;
