import { useMemo, useEffect, useCallback, useState } from 'react';
import { Link, Skeleton, Box, GridFlex, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { formatDate } from '@stigg-common';
import { IntegrationFragment, Environment, StripeCredentialsFragment, StripeCustomer } from '@stigg-types/apiTypes';
import { FileText, AlertTriangle, Users } from 'react-feather';
import Table, { HeadCell } from '../../../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { getIconColor } from '../../../../../../theme';
import { searchStripeCustomersAction, searchStripeSubscriptionsAction } from '../../../../integrationsSlice';
import { useImportContext } from '../../hooks/useStripeWizardImportContext';
import Search from '../../../../../../components/Search';
import { getCustomerBillingLinkUrl } from '../../stripeBillingUrl';

export type StripeCustomerListItem = {
  id: string;
  name: string;
  email: string;
  subscriptionsCount: number;
  subscriptionPlanName?: string;
  createdAt: Date;
  isSelected: boolean;
  isSynced: boolean;
  environmentId?: string | null;
  isInvalidForImport: boolean;
};

const AlertTriangleIcon = styled(AlertTriangle)`
  position: absolute;
  left: -40px;
`;

export const headCells = (
  environments: Environment[],
  isTestMode: boolean,
): Array<HeadCell<StripeCustomerListItem, any>> => [
  {
    id: 'name',
    alignment: 'left',
    label: t('integrations.stripeCustomerNameColumn'),
    render: (stripeCustomer) => (
      <InformationTooltip
        arrow
        $padding={2}
        placement="top"
        title={<Text.B2>{t('integrations.openLinkInStripe')}</Text.B2>}>
        <Box width="fit-content">
          <Link onClick={() => window.open(getCustomerBillingLinkUrl(isTestMode, stripeCustomer.id), '_blank')}>
            <Text.B2 className="fs-mask" color={stripeCustomer.isInvalidForImport ? 'disabled' : 'primary'}>
              {stripeCustomer.name}
            </Text.B2>
          </Link>
        </Box>
      </InformationTooltip>
    ),
  },
  {
    id: 'email',
    alignment: 'left',
    label: t('integrations.stripeCustomerEmailColumn'),
    render: (stripeCustomer) => (
      <Text.B2
        className="fs-mask"
        color={stripeCustomer.isInvalidForImport ? 'disabled' : 'primary'}
        sx={{ wordBreak: 'break-all' }}>
        {stripeCustomer.email}
      </Text.B2>
    ),
  },
  {
    id: 'subscription',
    alignment: 'left',
    label: t('integrations.stripeCustomerSubscriptionColumn'),
    render: (stripeCustomer) => {
      let alreadyImportToEnvironment: Environment | undefined;
      if (stripeCustomer.isSynced && stripeCustomer.environmentId) {
        alreadyImportToEnvironment = environments.find(
          (environment) => environment.id === stripeCustomer.environmentId,
        );
      }
      return (
        <GridFlex.RowCenter sx={{ position: 'relative' }}>
          {stripeCustomer.isSynced ? (
            <InformationTooltip
              arrow
              placement="top"
              title={
                <>
                  <Text.B2 mb={1}>
                    {t('integrations.alreadyImportedToAnotherStiggAccount', {
                      environmentName: alreadyImportToEnvironment?.displayName
                        ? `"${alreadyImportToEnvironment?.displayName}"`
                        : '',
                    })}
                  </Text.B2>
                </>
              }>
              <AlertTriangleIcon color={getIconColor('warning')} />
            </InformationTooltip>
          ) : null}

          <Text.B2
            color={stripeCustomer.isInvalidForImport || !stripeCustomer.subscriptionsCount ? 'disabled' : 'primary'}
            sx={{ wordBreak: 'break-all' }}>
            {stripeCustomer.subscriptionsCount > 1
              ? `${stripeCustomer.subscriptionsCount} products`
              : stripeCustomer.subscriptionPlanName || '—'}
          </Text.B2>
        </GridFlex.RowCenter>
      );
    },
  },
  {
    id: 'createdAt',
    alignment: 'left',
    label: t('integrations.stripeCustomerCreatedColumn'),
    render: (stripeCustomer) => (
      <Text.B2 color={stripeCustomer.isInvalidForImport ? 'disabled' : 'primary'}>
        {formatDate(stripeCustomer.createdAt, { formatType: 'longDate' })}
      </Text.B2>
    ),
  },
];

export function mapStripeCustomer(stripeCustomer: StripeCustomer, isSelected: boolean): StripeCustomerListItem {
  const isInvalidForImport = stripeCustomer.isSynced;
  return {
    ...stripeCustomer,
    subscriptionPlanName: stripeCustomer.subscriptionPlanName || undefined,
    isSelected: isInvalidForImport ? false : isSelected,
    isInvalidForImport: stripeCustomer.isSynced,
  };
}

export function SelectCustomersStep({ integration }: { integration: IntegrationFragment }) {
  const credentials = integration.credentials as StripeCredentialsFragment;
  const { isTestMode } = credentials;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const importContext = useImportContext();
  const [search, setSearch] = useState('');
  const customersData = useMemo(() => importContext?.customersData || [], [importContext]);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const { customers, headerSelectionChecked, totalCount, nextPage, searchCustomersResults } = customersData;
  const { activeSubscriptionsCount } = importContext;

  const fetchStripeCustomers = useCallback(
    async (nextPage?: string) => {
      setIsLoadingCustomers(true);
      const result = await dispatch(searchStripeCustomersAction({ nextPage })).unwrap();
      importContext.setCustomersData((prevState) => ({
        ...prevState,
        customers: [
          ...prevState.customers,
          ...result.customers.map((stripeCustomer) =>
            mapStripeCustomer(
              stripeCustomer,
              prevState.searchBlacklistCustomers.some((blackCustomer) => blackCustomer.id === stripeCustomer.id)
                ? false
                : prevState.searchWhitelistCustomers.some((blackCustomer) => blackCustomer.id === stripeCustomer.id)
                ? true
                : headerSelectionChecked,
            ),
          ),
        ],
        totalCount: result.totalCount,
        nextPage: result.nextPage,
      }));
      setIsLoadingCustomers(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, headerSelectionChecked],
  );

  const fetchStripeSubscriptionsCount = useCallback(async () => {
    const subscriptionsSearchResult = await dispatch(searchStripeSubscriptionsAction({})).unwrap();
    importContext.setActiveSubscriptionsCount(subscriptionsSearchResult.totalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSelectionChanged = () => {
    importContext.setCustomersData((prevState) => {
      const newHeaderSelection = !prevState.headerSelectionChecked;
      return {
        ...prevState,
        customers: customers.map((customer) => ({
          ...customer,
          isSelected: customer.isInvalidForImport ? false : newHeaderSelection,
        })),
        searchBlacklistCustomers: [],
        searchWhitelistCustomers: [],
        headerSelectionChecked: newHeaderSelection,
      };
    });
  };
  const onSelectItemChanged = (itemIndex: number, checked: boolean) => {
    if (search) {
      importContext.setCustomersData((prevState) => {
        const itemChanged = prevState.searchCustomersResults[itemIndex];
        const customers = prevState.customers.map((customer) => ({
          ...customer,
          isSelected: customer.id === itemChanged.id ? checked : customer.isSelected,
        }));
        const searchCustomersResults = prevState.searchCustomersResults.map((customer, index) => ({
          ...customer,
          isSelected: index === itemIndex ? checked : customer.isSelected,
        }));
        if (prevState.headerSelectionChecked) {
          return {
            ...prevState,
            customers,
            searchCustomersResults,
            searchBlacklistCustomers: checked
              ? prevState.searchBlacklistCustomers.filter((customer) => customer.id !== itemChanged.id)
              : [...prevState.searchBlacklistCustomers, itemChanged],
          };
        }
        return {
          ...prevState,
          customers,
          searchCustomersResults,
          searchWhitelistCustomers: checked
            ? [...prevState.searchWhitelistCustomers, itemChanged]
            : prevState.searchWhitelistCustomers.filter((customer) => customer.id !== itemChanged.id),
        };
      });
    } else {
      importContext.setCustomersData((prevState) => {
        let isAllUnselected = true;
        const customers = prevState.customers.map((customer, index) => {
          const isSelected = index === itemIndex ? checked : customer.isSelected;
          if (isSelected) {
            isAllUnselected = false;
          }
          return {
            ...customer,
            isSelected,
          };
        });

        return {
          ...prevState,
          customers,
          // if the single selection changed was the last checked and
          // it's unselected now we need to update headerSelectionChecked
          headerSelectionChecked: isAllUnselected ? false : prevState.headerSelectionChecked,
        };
      });
    }
  };

  useEffect(() => {
    if (isEmpty(customers)) {
      void fetchStripeCustomers();
      void fetchStripeSubscriptionsCount();
    }
  }, [customers, fetchStripeCustomers, fetchStripeSubscriptionsCount]);

  const searchCustomers = useCallback(
    async (searchTerm: string) => {
      importContext.setCustomersData((prevState) => ({
        ...prevState,
        searchCustomersResults: [],
      }));
      if (searchTerm) {
        setIsLoadingCustomers(true);
        const result = await dispatch(searchStripeCustomersAction({ searchTerm })).unwrap();
        importContext.setCustomersData((prevState) => ({
          ...prevState,
          searchCustomersResults: result.customers.map((stripeCustomer) => {
            // In case we already fetch the customer we should use the existing customer selection
            const fetchedCustomer = customers.find((customer) => customer.id === stripeCustomer.id);
            return mapStripeCustomer(
              stripeCustomer,
              fetchedCustomer ? fetchedCustomer.isSelected : headerSelectionChecked,
            );
          }),
        }));
        setIsLoadingCustomers(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, customers, headerSelectionChecked],
  );

  const fetchMoreCustomers = useCallback(async () => {
    if (nextPage) {
      await fetchStripeCustomers(nextPage);
    }
  }, [fetchStripeCustomers, nextPage]);

  return (
    <GridFlex.Column>
      <Text.H3 mb={2}>{t('integrations.importCustomersHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={4}>
        {t('integrations.importCustomersSelectCustomersSubtitle')}
      </Text.B2>
      <GridFlex.RowCenter justifyContent="space-between" alignItems="flex-end" mb={4}>
        {totalCount ? (
          <GridFlex.RowCenter>
            <Users color={getIconColor('active')} strokeWidth={1.5} />
            <Text.B2 color="secondary" ml={2} mr={4}>
              {headerSelectionChecked
                ? `${totalCount - importContext.unSelectedCustomers.length} / ${totalCount} ${t(
                    'integrations.selectedCustomers',
                  )}`
                : `${importContext.selectedCustomers.length} / ${totalCount} ${t('integrations.selectedCustomers')}`}
            </Text.B2>
            <FileText color={getIconColor('active')} strokeWidth={1.5} />
            <Text.B2 ml={2} color="secondary">
              {activeSubscriptionsCount} active subscriptions
            </Text.B2>
          </GridFlex.RowCenter>
        ) : (
          <Skeleton animation="wave" width={200} height={15} />
        )}
        <Box width={300}>
          <Search
            searchOnChange
            variant="outlined"
            placeholder="Search by customer name"
            handleSearchFunc={(searchTerm) => {
              setSearch(searchTerm);
              void searchCustomers(searchTerm);
            }}
            searchedStr={search}
            shouldMaskFromHjAndFs
          />
        </Box>
      </GridFlex.RowCenter>
      <Table
        isLoading={isLoadingCustomers}
        headCells={headCells(environments, isTestMode)}
        label={t('customers.table')}
        headerRowColor={theme.isLightTheme ? '#F2F4F8' : theme.itamar.palette.background.darkBackground2}
        data={isEmpty(search) ? customers : searchCustomersResults}
        enableRowSelection
        hideHeaderSelection={!isEmpty(search)}
        onSelectAllToggle={onSelectionChanged}
        onItemSelectionToggle={onSelectItemChanged}
        maxHeight={500}
        infiniteScrollOptions={{ hasMore: !!nextPage, loadMore: fetchMoreCustomers }}
        stickyHeader
        isRowDisabled={(stripeCustomer: StripeCustomerListItem) => stripeCustomer.isInvalidForImport}
      />
    </GridFlex.Column>
  );
}
