import { DialogActionsButtons, Grid, GridFlex, IconButton, Link, TextField } from '@stigg-components';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import { Trash2 } from 'react-feather';
import { styled } from '@stigg-theme';
import { Add as AddIcon } from '@mui/icons-material';
import includes from 'lodash/includes';
import { KeyboardEvent, useCallback, useState } from 'react';
import { kVArrayToMetadata, MetadataKV, metadataToKVArray } from './EntityMetadataCard';
import { Dialog } from '../Dialog';

const TAB_KEY = 9;
const ENTER_KEY = 13;

const DeleteIcon = styled(Trash2)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
type AddEditMetadataDialogProps = {
  open: boolean;
  setDialogOpen: any;
  onSubmit: (metaData: Record<string, string>) => Promise<void>;
  metadata: MetadataKV[];
};

export type MetaData = {
  metadata: MetadataKV[];
};

export function SetMetadataDialog({ metadata, onSubmit, open, setDialogOpen }: AddEditMetadataDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onCancel = () => setDialogOpen(false);
  const handleSubmit = useCallback(
    async (values: MetaData) => {
      values.metadata = values.metadata.filter((x) => !isEmpty(x.key) && !isEmpty(x.value));
      setIsSubmitting(true);

      await onSubmit(kVArrayToMetadata(values.metadata) || {});
      setDialogOpen(false);
      setIsSubmitting(false);
    },
    [onSubmit, setDialogOpen],
  );
  // show one empty line to allow adding first kv
  const initialValues: MetaData = {
    metadata: metadata.length ? metadata : [{ key: '', value: '' }],
  };
  const validationSchema = Yup.object().shape({
    metadata: Yup.array().required().min(0),
  });

  const setKey = (replace: any, index: number, e: any, currentKVpair: MetadataKV) => {
    const newKey = e.target.value;
    replace(index, { key: newKey || '', value: currentKVpair.value });
  };

  const setValue = (replace: any, index: number, e: any, currentKVpair: MetadataKV) => {
    const newValue = e.target.value;

    replace(index, { key: currentKVpair.key, value: newValue || '' });
  };

  const addNewPair = (push: any) => {
    push({ key: '', value: '' });
  };

  const onPaste = (e: any, values: any, setFieldValue: any) => {
    // when we have an empty data
    if (!values.metadata.length || values.metadata[0].key === '') {
      const kvStr = e.clipboardData.getData('text');
      try {
        const json = JSON.parse(kvStr);
        // if we actually successfully parsed the pasted string, lets prevent default and try to fill it in
        e.preventDefault();
        setFieldValue('metadata', metadataToKVArray(json));
      } catch (e) {
        // no need do anything. just keep on
      }
    }
  };

  // support custom behaviour when pressing on Tab or Enter, but still allow normal funcionality when pressing shift+tab
  const pressedKeyMap = {};
  const onKeyDown = (keyEvent: KeyboardEvent<HTMLDivElement>, push: any, keysToOverride: number[]) => {
    const key = keyEvent.charCode || keyEvent.keyCode;
    // add pressed key to the pressedkeysmap. if more than one pressed, don't prevent def behaviour
    // @ts-ignore
    pressedKeyMap[key] = 'pushed';
    // on enter or tab
    if (Object.keys(pressedKeyMap).length === 1 && includes(keysToOverride, keyEvent.charCode || keyEvent.keyCode)) {
      keyEvent.preventDefault();
      push({ key: '', value: '' });
    }
  };
  const onKeyUp = (keyEvent: KeyboardEvent<HTMLDivElement>) => {
    const key = keyEvent.charCode || keyEvent.keyCode;
    // @ts-ignore
    delete pressedKeyMap[key];
  };

  const dialogContent = () => (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ isValid, values, setFieldValue }: FormikProps<MetaData>) => (
        <Form onPaste={(e) => onPaste(e, values, setFieldValue)}>
          <FieldArray name="metadata">
            {({ replace, remove, push }) => (
              <GridFlex.Column $fullWidth>
                {values.metadata.map((metadataPair, index) => (
                  <GridFlex.RowSpaceBetween container $fullWidth mt={4} wrap="nowrap" key={`metadatarow-${index}`}>
                    <Grid item flex={1} mr={2}>
                      <TextField
                        autoFocus
                        onKeyUp={(keyEvent: any) => onKeyUp(keyEvent)}
                        onKeyDown={(keyEvent: any) => onKeyDown(keyEvent, push, [ENTER_KEY])}
                        type="text"
                        name="key"
                        error={false}
                        touched={false}
                        placeholder={t('metaData.key')}
                        fullWidth
                        value={metadataPair.key}
                        onChange={(e) => setKey(replace, index, e, metadataPair)}
                      />
                    </Grid>
                    <Grid item flex={1} mr={2}>
                      <TextField
                        onKeyUp={(keyEvent: any) => onKeyUp(keyEvent)}
                        onKeyDown={(keyEvent: any) => onKeyDown(keyEvent, push, [TAB_KEY, ENTER_KEY])}
                        error={false}
                        touched={false}
                        type="text"
                        name="value"
                        placeholder={t('metaData.value')}
                        value={metadataPair.value}
                        fullWidth
                        onChange={(e) => setValue(replace, index, e, metadataPair)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </GridFlex.RowSpaceBetween>
                ))}
                <Grid item mt={4}>
                  <AddIcon color="primary" />
                  <Link variant="body2" ml={1} onClick={() => addNewPair(push)}>
                    {t('metaData.addItem')}
                  </Link>
                </Grid>
                <Grid item alignSelf="end">
                  <DialogActionsButtons
                    isSaveLoading={isSubmitting}
                    saveDisabled={!isValid}
                    saveText={
                      metadata.length ? t('sharedComponents.editSaveButton') : t('sharedComponents.addSaveButton')
                    }
                    onCancel={onCancel}
                  />
                </Grid>
              </GridFlex.Column>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );

  return (
    <Dialog
      onCancel={onCancel}
      titleText={metadata.length ? t('metaData.editMetadataDialogTitle') : t('metaData.addMetadataDialogTitle')}
      content={dialogContent()}
      isOpen={open}
      width={750}
    />
  );
}
