import { Divider, GridFlex, RadioGroup, RadioItem, Text } from '@stigg-components';
import React from 'react';
import { BillingModel, EntitlementResetPeriod, MonthlyAccordingTo } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { getDisabledTooltip } from './UsageBasedBillingTypeSelection.utils';
import { TiersSchema, UsageBasedChargeType } from '../../SetPriceWizardForm.types';

const USAGE_BASED_MODELS = [BillingModel.PerUnit, BillingModel.UsageBased];

export type UsageBasedBillingTypeProps = {
  index: number;
};

export function UsageBasedBillingTypeSelection({ index }: UsageBasedBillingTypeProps) {
  const {
    formRenderProps: { setFieldValue, setFieldTouched, values },
  } = useSetPriceWizardFormContext();

  const currentCharge = values.charges[index] as UsageBasedChargeType;

  const selectedBillingModel = currentCharge.billingModel;

  const hasOverageChargeForFeature = values.overageCharges.charges.some(
    (overageCharge) => overageCharge.feature?.id === currentCharge.feature?.id,
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chargeId = `charges[${index}]`;
    const billingModelId = `${chargeId}.billingModel`;
    const billingModel = (event.target as HTMLInputElement).value as BillingModel;
    setFieldValue(billingModelId, billingModel);
    // For now we are not supporting tiers for usage base so we set tiersSchema to standard
    if (billingModel === BillingModel.UsageBased) {
      setFieldValue(`${chargeId}.tiersSchema`, TiersSchema.Standard);
      setFieldValue(`${chargeId}.resetPeriod`, EntitlementResetPeriod.Month);
      setFieldValue(`${chargeId}.resetPeriodConfiguration`, MonthlyAccordingTo.SubscriptionStart);
    } else {
      setFieldValue(`${chargeId}.resetPeriod`, undefined);
      setFieldValue(`${chargeId}.resetPeriodConfiguration`, undefined);
    }
    setFieldTouched(billingModelId);
  };

  const billingModelToDisabledTooltips = Object.fromEntries(
    USAGE_BASED_MODELS.map((billingModel) => [
      billingModel,
      getDisabledTooltip(billingModel, values.billingPeriods, hasOverageChargeForFeature),
    ]),
  );

  return (
    <RadioGroup value={selectedBillingModel} onChange={onChange} sx={{ width: '100%' }}>
      <GridFlex.Row $fullWidth>
        <GridFlex.Row gap={4} flex={1}>
          {USAGE_BASED_MODELS.map((billingModel) => (
            <RadioItem
              key={billingModel}
              value={billingModel}
              disabled={!!billingModelToDisabledTooltips[billingModel]}
              tooltip={billingModelToDisabledTooltips[billingModel]}
              selected={selectedBillingModel === billingModel}
              hideControl
              disableFlex
              height={36}
              label={t(`pricing.billingType.types.${billingModel}`)}
              dataTestId={`billingModel-${billingModel}`}
            />
          ))}
        </GridFlex.Row>

        <GridFlex.Row width={278} gap={1}>
          &#128161; {/* bulb emoji */}
          <GridFlex.Column pt={0.5}>
            <Text.B2>{t(`pricing.billingType.types.${selectedBillingModel}`)}</Text.B2>
            <Text.B2 color="secondary" sx={{ fontStyle: 'italic' }}>
              {t(`pricing.billingType.hints.${selectedBillingModel}`)}
            </Text.B2>
          </GridFlex.Column>
        </GridFlex.Row>
      </GridFlex.Row>

      <Divider my={6} />
    </RadioGroup>
  );
}
