import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

const GET_ENTITLEMENT_REQUEST = (
  featureRefId: string,
  addRequestedUsage: boolean,
) => `var result = await stigg.GetEntitlement.ExecuteAsync(new FetchEntitlementQuery()
{
  CustomerId = "${MOCK_CUSTOMER_ID}",
  FeatureId = "${featureRefId}",
  ResourceId = "site-1" // optional resource ID
  ${addRequestedUsage ? `Options = new EntitlementOptions { RequestedUsage = ${MOCK_REQUESTED_USAGE} }` : ''}
});

if (result.Data.Entitlement.IsGranted) {
// customer has access to the feature
} else {
// access denied
}`;

export const dotNetFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `using Stigg;

class App {
    public static void main() {
        var stigg = Stigg.createClient("${apiKey}");
    }
}`,
  checkBooleanEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkNumericEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkMeteredEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, true),
  reportUsageForMeteredFeature: (
    featureRefId: string,
  ) => `var resp = await stigg.ReportUsage.ExecuteAsync(new ReportUsageInput()
{
  CustomerId = "${MOCK_CUSTOMER_ID}",
  FeatureId = "${featureRefId}",
  ResourceId = "site-1", // optional resource ID
  Value = 1,
});`,

  reportEventForMeteredFeature: () => `var resp = await stigg.ReportEvent.ExecuteAsync(new UsageEventsReportInput()
{
  UsageEvents = new List<UsageEventReportInput>()
  {
    new()
    {
      CustomerId = "${MOCK_CUSTOMER_ID}",
      EventName = "user_login",
      Dimensions = JsonSerializer.SerializeToElement(new { user_id = "user-01", ip = "127.0.0.1" }),
      IdempotencyKey = Guid.NewGuid().ToString(),
      ResourceId = "site-1", // optional resource ID
    }
  }
});`,
};
