import { createSlice } from '@reduxjs/toolkit';
import { PackageEntitlementConnection, PackageEntitlementsFragment } from '@stigg-types/apiTypes';
import { fetchEntitlementsByPackageId } from './queries';
import { createPackageEntitlements } from './mutations/createPackageEntitlements';
import { deleteEntitlementByPackageId } from './mutations/deleteEntitlementByPackageId';
import { updatePackageEntitlement } from './mutations/updatePackageEntitlement';
import { updateEntitlementsOrder } from './mutations/updateEntitlementsOrder';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface CustomerEntitlementsState {
  isLoading: boolean;
  packageEntitlements: PackageEntitlementsFragment;
}

const initialState: CustomerEntitlementsState = {
  isLoading: false,
  packageEntitlements: {} as PackageEntitlementsFragment,
};

const fetchEntitlementsByPackageIdAction = createAppAsyncThunk(
  'fetchEntitlementsByPackageId',
  fetchEntitlementsByPackageId,
);
const deleteEntitlementByPackageIdAction = createAppAsyncThunk(
  'deleteEntitlementByPackageId',
  deleteEntitlementByPackageId,
);
const createPackageEntitlementsAction = createAppAsyncThunk('createPackageEntitlements', createPackageEntitlements);
const updatePackageEntitlementAction = createAppAsyncThunk('updatePackageEntitlement', updatePackageEntitlement);
const updateEntitlementsOrderAction = createAppAsyncThunk('updateEntitlementsOrder', updateEntitlementsOrder);

export const entitlementsSlice = createSlice({
  name: 'entitlements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitlementsByPackageIdAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEntitlementsByPackageIdAction.fulfilled, (state, { payload }) => {
        state.packageEntitlements = payload || ({} as PackageEntitlementConnection);
        state.isLoading = false;
      })

      .addCase(fetchEntitlementsByPackageIdAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export {
  fetchEntitlementsByPackageIdAction,
  createPackageEntitlementsAction,
  deleteEntitlementByPackageIdAction,
  updatePackageEntitlementAction,
  updateEntitlementsOrderAction,
};

export default entitlementsSlice.reducer;
