import { GridFlex, Text } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { CountryFlagWithCurrencyTooltip } from '../../../currency/CountryWithFlagByCode';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';

const MAX_COUNTRIES = 3;

export function PriceLocalizationStepSummary() {
  const {
    formRenderProps: {
      values: { priceLocalization },
    },
  } = useSetPriceWizardFormContext();

  if (!priceLocalization.enabled || isEmpty(priceLocalization.countries)) {
    return <Text.B2>{t('pricing.priceLocalization.none')}</Text.B2>;
  }

  const countriesToShow = priceLocalization.countries
    .filter(({ billingCountryCode, currency }) => !!billingCountryCode && !!currency)
    .slice(0, MAX_COUNTRIES);
  const totalRemaining = priceLocalization.countries.length - countriesToShow.length;

  return (
    <GridFlex.RowCenter gap={3}>
      {countriesToShow.map(({ billingCountryCode, currency }) => (
        <CountryFlagWithCurrencyTooltip
          key={billingCountryCode}
          countryCode={billingCountryCode!}
          currency={currency!}
        />
      ))}
      {totalRemaining > 0 && <Text.B2>{t('sharedComponents.andXMore', { count: totalRemaining })}</Text.B2>}
    </GridFlex.RowCenter>
  );
}
