// export const SERIES_COLORS = ['#65D2C7', '#E9763E', '#c39d42', '#327EEE'];
import moment from 'moment/moment';
import { TimeDimensionGranularity } from '@cubejs-client/core';

export const SERIES_COLORS = [
  '#E9763E',
  '#65D2C7',
  '#9F8E31',
  '#872FCD',
  '#327EEE',
  '#F8E857',
  '#DB5B6A',
  '#B05687',
  '#17756B',
  '#77588C',
  '#819748',
  '#455479',
  '#2F4858',
  '#46895C',
  '#1A5F6A',
  '#6D72DD',
  '#8B65C9',
  '#9D5AB3',
  '#A8509D',
  '#AD4987',
];

export type TimeRangeLabel = '1D' | '1W' | '1M' | '3M' | '1Y';

type TimeRange = {
  durationMs: number;
  label: TimeRangeLabel;
  granularity: TimeDimensionGranularity;
  duration: { unit: TimeDimensionGranularity; amount: number };
};

export const TIME_RANGES: TimeRange[] = [
  {
    durationMs: moment.duration(1, 'day').asMilliseconds(),
    label: '1D',
    granularity: 'day',
    duration: { unit: 'day', amount: 1 },
  },
  {
    durationMs: moment.duration(1, 'week').asMilliseconds(),
    label: '1W',
    granularity: 'week',
    duration: { unit: 'day', amount: 7 },
  },
  {
    durationMs: moment.duration(1, 'month').asMilliseconds(),
    label: '1M',
    granularity: 'month',
    duration: { unit: 'day', amount: 30 },
  },
  {
    durationMs: moment.duration(3, 'month').asMilliseconds(),
    label: '3M',
    granularity: 'quarter',
    duration: { unit: 'month', amount: 3 },
  },
  {
    durationMs: moment.duration(1, 'year').asMilliseconds(),
    label: '1Y',
    granularity: 'year',
    duration: { unit: 'year', amount: 1 },
  },
];
