import { Card, Grid } from '@stigg-components';
import { paperClasses } from '@mui/material/Paper';
import styled, { css } from 'styled-components';

export const UseCaseCard = styled(Card)<{ $isDisabled?: boolean }>`
  max-width: 600px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ $isDisabled, theme }) =>
    $isDisabled
      ? css`
          background-color: ${theme.itamar.palette.background.default};
          cursor: not-allowed;
        `
      : css`
          &.${paperClasses.root}:hover {
            background-color: ${theme.itamar.palette.background.default};
            box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
          }
        `}
`;

export const IconGrid = styled(Grid)`
  width: 140px;
  min-width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.itamar.palette.grey[50]};
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;
