import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ErrorCode,
  FeatureFragment,
  UpdateFeatureInput,
  UpdateFeatureMutation,
  UpdateFeatureMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchFeatureByRefIdAction } from '../featuresSlice';

const UPDATE_FEATURE = gql`
  mutation UpdateFeature($input: UpdateFeatureInput!) {
    updateFeature(input: $input) {
      id
    }
  }
`;

async function updateFeature(
  { featureRefId, updatePayload }: { featureRefId: string; updatePayload: Partial<UpdateFeatureInput> },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<{ updatedFeature: FeatureFragment | null }> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<UpdateFeatureMutation, UpdateFeatureMutationVariables>({
        mutation: UPDATE_FEATURE,
        variables: {
          input: { refId: featureRefId, environmentId: accountReducer.currentEnvironmentId, ...updatePayload },
        },
      });

      const updatedFeature = await dispatch(fetchFeatureByRefIdAction(featureRefId)).unwrap();

      return { updatedFeature };
    },
    {
      successMessage: t('features.api.successUpdate'),
      failureMessageHandler: () => t('features.api.failUpdate'),
      expectedErrorsMessage: {
        [ErrorCode.CannotUpdateUnitTransformationError]: t('features.api.failUpdateUnitTransformation'),
      },
    },
    dispatch,
  );
}

export { updateFeature };
