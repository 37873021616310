import { useMemo } from 'react';
import styled from 'styled-components/macro';
import filter from 'lodash/filter';
import { Grid as GridTiles, PlusSquare } from 'react-feather';
import { Grid, GridFlex, Text } from '@stigg-components';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { StripeProductListItem } from './ImportProductsStep';
import { PlansSummary } from './PlansSummary';
import { PackagePrices } from './PackagePrices';

const AddonIcon = styled(PlusSquare)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
const GridTilesIcon = styled(GridTiles)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

function AddonBox({ addon }: { addon: StripeProductListItem }) {
  return (
    <Grid
      gridColumn="span 6"
      height={135}
      p={6}
      sx={{
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        borderRadius: '10px',
      }}>
      <AddonIcon />
      <GridFlex.Column ml={4}>
        <Text.H6 mb={2} $bold>
          {addon.name}
        </Text.H6>
        <PackagePrices aPackage={addon} />
      </GridFlex.Column>
    </Grid>
  );
}

export function ProductCatalogSummary() {
  const importContext = useImportContext();
  const addons = useMemo(
    () => filter(importContext?.selectedProducts || [], (product) => importContext?.addonIds.includes(product.id)),
    [importContext],
  );

  return (
    <GridFlex.Column>
      <GridFlex.RowCenter mb={4}>
        <GridTilesIcon />
        <Text.H3 ml={2} color="primary">
          {importContext?.selectedProduct?.displayName}
        </Text.H3>
      </GridFlex.RowCenter>
      <GridFlex.Column
        sx={{
          border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
          maxHeight: 550,
          overflow: 'auto',
          width: '100%',
          borderRadius: '4px',
          padding: (theme) => theme.spacing(6),
        }}>
        <PlansSummary />

        {addons.length > 0 && (
          <>
            <GridFlex.RowCenter mb={4} mt={12}>
              <Text.H6 ml={2}>
                {addons.length} add-on{addons.length > 1 ? 's' : ''}
              </Text.H6>
            </GridFlex.RowCenter>
            <GridFlex.RowCenter
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              $fullWidth
              justifyContent="flex-start"
              flexWrap="wrap"
              rowGap={4}
              columnGap={4}>
              {addons.map((addon) => (
                <AddonBox key={addon.id} addon={addon} />
              ))}
            </GridFlex.RowCenter>
          </>
        )}
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
