import { DateText, EntityValueCell, LongText, OptionsDropdown } from '@stigg-components';
import { PackageGroupFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Edit2, Trash2 } from 'react-feather';
import { HeadCell } from '../../../../components/table/Table';

export const headCells = (
  onEditClick: (addonGroup: PackageGroupFragment) => void,
  onArchiveClick: (addonGroup: PackageGroupFragment) => void,
): Array<HeadCell<PackageGroupFragment, any>> => [
  {
    id: 'groupName',
    alignment: 'left',
    label: t('addons.addonGroups.tableColumns.groupName'),
    render: (addonGroup) => (
      <EntityValueCell entity={{ displayName: addonGroup.displayName, refId: addonGroup.packageGroupId }} />
    ),
  },
  {
    id: 'product',
    alignment: 'left',
    label: t('addons.addonGroups.tableColumns.product'),
    render: (addonGroup) => (
      <LongText variant="body2" color="primary" wordBreak>
        {addonGroup.product.displayName}
      </LongText>
    ),
  },
  {
    id: 'addonsCount',
    alignment: 'left',
    label: t('addons.addonGroups.tableColumns.add-ons'),
    render: (addonGroup) => <LongText variant="body2">{addonGroup.addons?.length || 0}</LongText>,
  },
  {
    id: 'lastUpdated',
    alignment: 'left',
    label: t('addons.addonGroups.tableColumns.lastUpdated'),
    render: (addonGroup) => <DateText date={addonGroup.updatedAt} withTime formatType="longDate" />,
  },
  {
    id: 'properties',
    alignment: 'right',
    label: '',
    disableClick: true,
    width: 128,
    render: (addonGroup) => (
      <OptionsDropdown
        options={[
          {
            icon: Edit2,
            text: t('sharedComponents.edit'),
            onClick: () => onEditClick(addonGroup),
          },
          {
            icon: Trash2,
            text: t('sharedComponents.archive'),
            onClick: () => onArchiveClick(addonGroup),
          },
        ]}
      />
    ),
  },
];
