/* eslint-disable no-restricted-imports */
import { FC } from 'react';
import { spacing } from '@mui/system';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import styled, { css } from 'styled-components/macro';
import { TextColor, getTextColor } from '@stigg-theme';
import { CustomVariants, variantToColor } from './types';

// due the face color is a props coming from MuiTypography when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiTypography['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it :)

type StyledTypographyType = {
  $capitalize?: boolean;
  $bold?: boolean;
  $medium?: boolean;
  $lineThrough?: boolean;
  // todo: remove the extra colors options other then TextColor (view TextColor in order to understand)
  $typesafeColor?: TextColor; // look at the first comment
  color?: TextColor;
  overrideColor?: string;
};

type OurTypography = Omit<TypographyProps, 'color' | 'variant'> & { variant: CustomVariants } & Omit<
    StyledTypographyType,
    '$typesafeColor'
  >;
export type ExcludeOurTypography = Omit<OurTypography, 'variant'>;

const SpacedTypography = styled(MuiTypography)(spacing);
const StyledTypography = styled(SpacedTypography)<StyledTypographyType>`
  ${({ $capitalize: capitalize }) =>
    capitalize &&
    css`
      &:first-letter {
        text-transform: uppercase;
      }
    `}
  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 700;
    `}
  ${({ $medium }) =>
    $medium &&
    css`
      font-weight: 500;
    `}
  ${({ $typesafeColor }) =>
    !!$typesafeColor &&
    css`
      color: ${getTextColor($typesafeColor)};
    `}
  ${({ overrideColor }) =>
    !!overrideColor &&
    css`
      color: ${overrideColor};
    `}
  ${({ $lineThrough }) =>
    $lineThrough &&
    css`
      text-decoration: line-through;
    `}
`;

// todo: no variant
export const Typography: FC<OurTypography> = ({ color, ...props }) => (
  <StyledTypography $typesafeColor={color} {...props} />
);

const Caption: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('caption')} {...props} />;

const B1: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('body1')} {...props} />;
const B2: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('body2')} {...props} />;

const H1: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('h1')} {...props} />;
const H3: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('h3')} {...props} />;
const H6: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('h6')} {...props} />;

const Sub2: FC<ExcludeOurTypography> = (props) => <Typography {...variantToColor('subtitle2')} {...props} />;

export const Text = {
  Caption,
  H1,
  H3,
  H6,
  B1,
  B2,
  Sub2,
};
