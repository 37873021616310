import { Text } from '@stigg-components';
import { t } from 'i18next';
import * as React from 'react';
import { ReactComponent as ExperimentPlanFeaturesIcon } from '../../../../../../assets/icons/experiments/ExperimentCustomerJourney.svg';
import { DetailsRow } from './DetailsRow';

export function UseCaseDetailsRow() {
  return (
    <DetailsRow iconComponent={<ExperimentPlanFeaturesIcon width={50} />}>
      <Text.B2 color="secondary">{t('experiments.useCase')}</Text.B2>
      <Text.B2 color="primary">
        {/* TODO: this is temporary hard-coded for the first experiment */}
        {t('experiments.useCases.customerJourney.title')}
      </Text.B2>
    </DetailsRow>
  );
}
