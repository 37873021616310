import * as React from 'react';
import { ProductSettingsFragment } from '@stigg-types/apiTypes';
import { useDummyFormRenderProps } from '@stigg-components';
import { CustomerJourneyExperimentDiff } from '../commom/diff/customerJourney/CustomerJourneyExperimentDiff';
import { calculateInitialCustomerJourneyFormValues } from '../../../products/components/productPage/customerJourney/CustomerJourney';
import { CustomerJourneyPlan } from '../../../products/components/productPage/customerJourney/types';

export type CustomerJourneyExperimentDefinitionProps = {
  productId: string;
  plans: CustomerJourneyPlan[];
  initialProductSettings: ProductSettingsFragment;
  variantProductSettings: ProductSettingsFragment;
};

export function CustomerJourneyExperimentDefinition({
  productId,
  plans,
  initialProductSettings,
  variantProductSettings,
}: CustomerJourneyExperimentDefinitionProps) {
  const controlValues = calculateInitialCustomerJourneyFormValues(plans, initialProductSettings);
  const variantValues = calculateInitialCustomerJourneyFormValues(plans, variantProductSettings);

  const controlFormRenderProps = useDummyFormRenderProps(controlValues);
  const variantFormRenderProps = useDummyFormRenderProps(variantValues);

  return (
    <CustomerJourneyExperimentDiff
      productId={productId}
      plans={plans}
      controlFormRenderProps={controlFormRenderProps}
      variantRenderProps={variantFormRenderProps}
      variantReadOnly
    />
  );
}
