import { PaymentCollection, SubscriptionDataFragment, SubscriptionScheduleStatus } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { SubscriptionBannerContainer } from '../SubscriptionBannerContainer';
import {
  getCtaButtons,
  getDescription,
  getIcon,
  getTitle,
  getVariant,
  isInvoicePendingPayment,
  MoreDetailsFooter,
} from './PaymentCollectionBanner.utils';

export type SubscriptionPendingPaymentProps = {
  subscription: SubscriptionDataFragment;
  onCancelSubscriptionFutureUpdatesClick: (futureUpdateStatus: ActiveFutureUpdateStatus) => void;
};

export function PaymentCollectionBanner({
  subscription,
  onCancelSubscriptionFutureUpdatesClick,
}: SubscriptionPendingPaymentProps) {
  const { status, paymentCollection, latestInvoice: subscriptionInvoice } = subscription;
  const errorMessage = subscriptionInvoice?.errorMessage || '';
  const pendingPaymentUpdates = subscription.futureUpdates.filter(
    (update) => update.scheduleStatus === SubscriptionScheduleStatus.PendingPayment,
  );

  const hasPendingPaymentUpdates = !isEmpty(pendingPaymentUpdates);

  if (paymentCollection === PaymentCollection.NotRequired) {
    return null;
  }

  const variant = getVariant(paymentCollection, errorMessage);
  const icon = getIcon(paymentCollection, errorMessage);
  const title = getTitle(paymentCollection, errorMessage);
  const description = getDescription(status, paymentCollection, pendingPaymentUpdates, subscription);
  const ctaButtons = getCtaButtons(
    paymentCollection,
    subscriptionInvoice,
    hasPendingPaymentUpdates,
    onCancelSubscriptionFutureUpdatesClick,
  );

  const footer = isInvoicePendingPayment(errorMessage) ? undefined : (
    <MoreDetailsFooter paymentCollection={paymentCollection} subscriptionInvoice={subscriptionInvoice} />
  );

  return (
    <SubscriptionBannerContainer icon={icon} title={title} buttons={ctaButtons} variant={variant} footer={footer}>
      {description}
    </SubscriptionBannerContainer>
  );
}
