import {
  Box,
  Button,
  Chip,
  ConfirmationDialog,
  Divider,
  ExternalLink,
  Grid,
  GridFlex,
  OptionsDropdown,
  PageCard,
  Text,
} from '@stigg-components';
import { t } from 'i18next';
import { Edit2, Trash2 } from 'react-feather';
import React, { useState } from 'react';
import { styled } from '@stigg-theme';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IntegrationIcon } from '../IntegrationCard';
import { availableIntegrations, VendorIdentifier } from '../../constants';
import { AvailableIntegration } from '../../models';
import { Dialog } from '../../../../components/Dialog';
import CreateHookForm from '../../../webhooks/components/hookCrud/CreateHookForm';
import { deleteHookByIdAction } from '../../../webhooks/hooksSlice';
import Loader from '../../../../components/Loader';
import UpdateHookForm from '../../../webhooks/components/hookCrud/UpdateHookForm';
import Table from '../../../../components/table/Table';
import { webhooksEventsHeadCells } from '../../../webhooks/components/hookPage/HookEventsSection';
import { useSlackIntegration } from './useSlackIntegration';
import { useAppDispatch } from '../../../../redux/store';
import { StyledAlert } from '../IntegrationPage';

const ConnectButton = styled(Button)`
  height: 36px;
  width: 100%;
`;
export function SlackIntegrationPage() {
  const dispatch = useAppDispatch();
  const { slackIntegration: slackIntegrationEnabled } = useFlags<FeatureFlags>();
  const integrationMetadata = availableIntegrations({ slackIntegrationEnabled }).find(
    (x) => x.vendorIdentifier === VendorIdentifier.Slack,
  ) as AvailableIntegration;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLoadingSlackIntegration, slackIntegration] = useSlackIntegration();
  const sortedEvents = Object.values(slackIntegration?.eventLogTypes || {})
    .sort()
    .map((event) => ({ eventLogType: event }));
  const handleDeleteDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && slackIntegration?.id) {
      await dispatch(deleteHookByIdAction({ hookId: slackIntegration.id, deleteSlackIntegration: true }));
    }
    setDeleteDialogOpen(false);
  };
  if (isLoadingSlackIntegration) {
    return <Loader />;
  }

  return (
    <Grid container flexWrap="nowrap" flexDirection="column" rowSpacing={6}>
      <Grid flexDirection="column" container item alignSelf="center" justifyContent="center" sx={{ maxWidth: 800 }}>
        <PageCard cardProps={{ p: 4 }}>
          <Grid container alignItems="center" columnSpacing={4} mb={4}>
            <Grid item display="flex" alignItems="center">
              <IntegrationIcon src={integrationMetadata.icon} alt={integrationMetadata.name} />
            </Grid>

            <GridFlex.Column item flex={1}>
              <Text.Sub2>{t('integrations.cardSubtitle')}</Text.Sub2>
              <Grid container alignItems="center">
                <Grid item>
                  <Text.H3>{integrationMetadata.name}</Text.H3>
                </Grid>
                {!!slackIntegration && (
                  <Grid item ml={4}>
                    <Chip size="small" label="Active" color="primary" />
                  </Grid>
                )}
              </Grid>
            </GridFlex.Column>

            <Grid item display="flex" alignItems="center">
              <Grid item>
                {!!slackIntegration && (
                  <OptionsDropdown
                    options={[
                      {
                        icon: Edit2,
                        text: t('sharedComponents.edit'),
                        onClick: () => setEditDialogOpen(true),
                      },
                      {
                        text: 'Remove',
                        icon: Trash2,
                        onClick: () => {
                          setCreateDialogOpen(false);
                          setDeleteDialogOpen(true);
                        },
                      },
                    ]}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <GridFlex.Column item $fullWidth rowSpacing={4}>
            <Box>
              <Text.B2 color="secondary" maxWidth={650}>
                {integrationMetadata.getDescription()}
              </Text.B2>
            </Box>

            {integrationMetadata.docsUrl && (
              <Grid mt={4}>
                <StyledAlert $muted severity="info" sx={{ width: '100%' }} icon={false}>
                  <Text.B2 gap={1}>
                    {t('integrations.integrationGuidePart1')}
                    <ExternalLink
                      label={t('integrations.integrationGuidePart2', { integrationName: integrationMetadata.name })}
                      url={integrationMetadata.docsUrl}
                    />
                  </Text.B2>
                </StyledAlert>
              </Grid>
            )}
          </GridFlex.Column>
          <>
            <Divider my={4} />
            {slackIntegration ? (
              <GridFlex.Column>
                <GridFlex.Row mt={6}>
                  <Text.Sub2 sx={{ width: 120, mr: 2 }}>{t('hooks.endpoint')}</Text.Sub2>
                  <Text.B2>{slackIntegration.endpoint}</Text.B2>
                </GridFlex.Row>
                <GridFlex.Item mt={4}>
                  <Table headCells={webhooksEventsHeadCells()} data={sortedEvents} label={t('hooks.eventsTable')} />
                </GridFlex.Item>
              </GridFlex.Column>
            ) : (
              <ConnectButton
                color="slack"
                variant="contained"
                size="medium"
                onClick={() => setCreateDialogOpen(true)}
                sx={{ mt: 4 }}>
                Connect with Slack
              </ConnectButton>
            )}
          </>
        </PageCard>
      </Grid>
      <Dialog
        content={<CreateHookForm onCancel={() => setCreateDialogOpen(false)} isSlackIntegrationHook />}
        titleText={t('integrations.slack.create.title')}
        isOpen={createDialogOpen}
        onCancel={() => setCreateDialogOpen(false)}
        aria-labelledby="create-hook-dialog"
        width={730}
      />
      <Dialog
        content={
          slackIntegration ? (
            <UpdateHookForm hook={slackIntegration} onCancel={() => setEditDialogOpen(false)} isSlackIntegrationHook />
          ) : null
        }
        titleText={t('integrations.slack.edit.title')}
        isOpen={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        aria-labelledby="edit-hook-dialog"
        width={730}
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />
    </Grid>
  );
}
