import { t } from 'i18next';
import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type TotalSectionProps = Pick<PreviewNextInvoiceFragment, 'total'>;
export function TotalSection({ total }: TotalSectionProps) {
  return (
    <>
      <ChargeRow description={t('subscriptions.breakdown.total')} charge={total} sx={{ mt: 4 }} bold />
    </>
  );
}
