import React from 'react';
import { FormRenderProps, GridFlex, useScrollableElement } from '@stigg-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { NewAddonAndAddonGroupsFormFields } from './AddCompatibleAddonOrAddonGroupDialog';
import { isAddon, isPackageGroup } from '../../../../common/packageUtils';
import { AddedAddonGroupWithoutConfiguration, AddedAddonItem } from './AddedAddonItem';
import { AddedAddonGroupItem } from './AddedAddonGroupItem';

export function AddedAddonOrAddonGroupList({
  formProps,
  remove,
  replace,
}: {
  formProps: FormRenderProps<NewAddonAndAddonGroupsFormFields>;
  remove: (index: number) => void;
  replace: (index: number, value: any) => void;
}) {
  const { planMinItemsFromAddonGroup: isPlanMinItemsFromAddonGroupEnabled } = useFlags<FeatureFlags>();
  const {
    values: { addedEntities },
  } = formProps;
  const { elementRef } = useScrollableElement({
    shouldAutoScroll: () => true,
  });

  return (
    <GridFlex.Column>
      {addedEntities.map((entity, index) => {
        return (
          <GridFlex.Item pb={2} key={index}>
            {isAddon(entity.entity) ? (
              <AddedAddonItem addon={entity.entity} remove={remove} index={index} key={index} />
            ) : isPackageGroup(entity.entity) ? (
              isPlanMinItemsFromAddonGroupEnabled ? (
                <AddedAddonGroupItem
                  addonGroup={entity.entity}
                  remove={remove}
                  replace={replace}
                  index={index}
                  ref={elementRef}
                  formRenderProps={formProps}
                />
              ) : (
                <AddedAddonGroupWithoutConfiguration
                  addonGroup={entity.entity}
                  index={index}
                  key={index}
                  remove={remove}
                />
              )
            ) : undefined}
          </GridFlex.Item>
        );
      })}
    </GridFlex.Column>
  );
}
