import styled, { css } from 'styled-components/macro';
import { Button } from '@stigg-components';

export type ButtonGroupPosition = 'left' | 'middle' | 'right';

export const StyledSelectableButton = styled(Button)<{
  $isSelected?: boolean;
  $buttonGroupPosition?: ButtonGroupPosition;
}>`
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.itamar.palette.primary.main : theme.itamar.palette.text.secondary};
  background-color: ${({ theme, $isSelected }) => {
    const notSelectedColor = theme.isLightTheme ? theme.itamar.palette.white : 'inherit';
    return $isSelected ? theme.itamar.palette.primary.outlinedHoverBackground : notSelectedColor;
  }};

  * {
    color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.itamar.palette.primary.main : theme.itamar.palette.text.secondary};
  }

  :hover {
    background-color: ${({ theme }) => theme.itamar.palette.action.hover};
  }

  &.Mui-disabled {
    color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.itamar.palette.text.primary : theme.itamar.palette.text.disabled};
    background-color: ${({ theme, $isSelected }) => {
      const notSelectedColor = theme.isLightTheme ? theme.itamar.palette.white : 'inherit';
      return $isSelected ? theme.itamar.palette.action.disabledBackground : notSelectedColor;
    }};

    * {
      color: ${({ theme, $isSelected }) =>
        $isSelected ? theme.itamar.palette.text.primary : theme.itamar.palette.text.disabled};
    }
  }

  ${({ $buttonGroupPosition }) => {
    if ($buttonGroupPosition === 'middle') {
      return '';
    }

    if ($buttonGroupPosition === 'right') {
      return css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      `;
    }

    return css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    `;
  }}
`;
