import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  SetWidgetConfigurationMutation,
  SetWidgetConfigurationMutationVariables,
  CustomerPortalConfigurationInput,
  PaywallConfigurationInput,
  CheckoutConfigurationInput,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const SET_WIDGET_CONFIG = gql`
  mutation SetWidgetConfiguration($input: WidgetConfigurationUpdateInput!) {
    setWidgetConfiguration(input: $input)
  }
`;

async function setWidgetConfiguration(
  {
    customerPortalConfiguration,
    paywallConfiguration,
    checkoutConfiguration,
  }: {
    customerPortalConfiguration?: CustomerPortalConfigurationInput;
    paywallConfiguration?: PaywallConfigurationInput;
    checkoutConfiguration?: CheckoutConfigurationInput;
  },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<void> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<SetWidgetConfigurationMutation, SetWidgetConfigurationMutationVariables>({
        mutation: SET_WIDGET_CONFIG,
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId,
            customerPortalConfiguration,
            paywallConfiguration,
            checkoutConfiguration,
          },
        },
      });
    },
    {
      successMessage: t('widgets.api.successSetConfig'),
      failureMessageHandler: () => t('widgets.api.failedSetConfig'),
    },
    dispatch,
  );
}

export { setWidgetConfiguration };
