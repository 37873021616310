import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  EntitlementDataFragment,
  FetchEffectiveEntitlementsByCustomerRefIdQuery,
  FetchEffectiveEntitlementsByCustomerRefIdQueryVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { RESET_PERIOD_CONFIGURATION } from '../../entitlements/queries/resetPeriodConfigurationFragment';

const FETCH_EFFECTIVE_ENTITLEMENTS = gql`
  query FetchEffectiveEntitlementsByCustomerRefId($query: FetchEntitlementsQuery!) {
    entitlements(query: $query) {
      ...EntitlementData
    }
  }
  fragment EntitlementData on EntitlementWithSummary {
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    currentUsage
    currentUsage
    resetPeriod
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
    nextResetDate
    requestedUsage
    accessDeniedReason
    isGranted
    meterId
    feature {
      ...FeatureData
    }
    summaries {
      ...EntitlementSummaryFragment
    }
  }
  fragment FeatureData on EntitlementFeature {
    id
    description
    displayName
    featureType
    meterType
    featureUnits
    featureUnitsPlural
    refId
    unitTransformation {
      divide
      round
      featureUnits
      featureUnitsPlural
    }
  }

  fragment EntitlementSummaryFragment on EntitlementSummary {
    isEffectiveEntitlement
    addonQuantity
    plan {
      refId
      displayName
      versionNumber
      isLatest
      basePlan {
        id
        displayName
        versionNumber
        isLatest
      }
      product {
        refId
        displayName
      }
    }
    featurePackageEntitlement {
      createdAt
      usageLimit
      packageId
      hasUnlimitedUsage
      hasSoftLimit
      package {
        type
        refId
        displayName
        versionNumber
        isLatest
      }
    }
    featurePromotionalEntitlement {
      createdAt
      usageLimit
      hasUnlimitedUsage
      hasSoftLimit
    }
    priceEntitlement {
      usageLimit
      hasUnlimitedUsage
    }
    subscription {
      status
    }
  }
  ${RESET_PERIOD_CONFIGURATION}
`;

type FetchEntitlementsByCustomerIdProps = {
  customerRefId: string;
  resourceRefId: string | undefined;
};

async function fetchEntitlementsByCustomerId(
  { customerRefId, resourceRefId }: FetchEntitlementsByCustomerIdProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<EntitlementDataFragment[]> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<
        FetchEffectiveEntitlementsByCustomerRefIdQuery,
        FetchEffectiveEntitlementsByCustomerRefIdQueryVariables
      >({
        query: FETCH_EFFECTIVE_ENTITLEMENTS,
        fetchPolicy: 'network-only',
        variables: {
          query: {
            customerId: customerRefId,
            resourceId: resourceRefId,
            environmentId: accountReducer.currentEnvironmentId || '',
          },
        },
      });

      return res.data.entitlements as EntitlementDataFragment[];
    },
    { failureMessageHandler: () => t('customers.api.failEntitlementsFetch') },
    dispatch,
  );
}

export { fetchEntitlementsByCustomerId };
