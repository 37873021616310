import { useLayoutEffect, useCallback, useState, useRef } from 'react';

export function useInfiniteScroll({ loadMore, hasMore }: { hasMore?: boolean; loadMore?: () => Promise<void> }) {
  const [distanceBottom, setDistanceBottom] = useState(0);
  const [loading, setLoading] = useState(false);
  const scrollContainerRef = useRef<any>();
  const scrollListener = useCallback(() => {
    if (!loadMore && !hasMore) {
      return;
    }
    const bottom = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.clientHeight;
    if (!distanceBottom) {
      // calculate distanceBottom that works for you
      setDistanceBottom(Math.round((bottom / 100) * 30));
    }

    if (scrollContainerRef.current.scrollTop > bottom - distanceBottom && loadMore && hasMore && !loading) {
      setLoading(true);
      loadMore().finally(() => {
        setLoading(false);
      });
    }
  }, [loadMore, hasMore, distanceBottom, loading]);

  useLayoutEffect(() => {
    if (!loadMore && !hasMore) {
      return () => null;
    }
    const containerRef = scrollContainerRef.current;
    containerRef?.addEventListener('scroll', scrollListener);
    return () => {
      containerRef?.removeEventListener('scroll', scrollListener);
    };
  }, [loadMore, hasMore, scrollListener, scrollContainerRef]);

  return {
    loading,
    scrollContainerRef,
    hasMore,
  };
}
