import { ClipboardJSON, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { pick } from 'lodash';
import { EventLog } from '@stigg-types/apiTypes';
import moment from 'moment';
import { Trans } from 'react-i18next';

const RELEASE_DATE = moment.utc('2024-09-30T06:22:37Z');
const RELEASE_DATE_FORMAT = RELEASE_DATE.clone().add(1, 'day').format('LL');

export type EventRequestProps = {
  event: EventLog | null | undefined;
};

export function EventLogRequest({ event }: EventRequestProps) {
  const { createdAt, request } = event || {};

  if (!request?.body && !request?.response) {
    const text =
      createdAt && RELEASE_DATE.isBefore(createdAt) ? (
        <Trans i18nKey="eventsLog.detailsDrawer.request.dataAvailableForApiOnly" />
      ) : (
        <Trans
          i18nKey="eventsLog.detailsDrawer.request.noDataAvailableBeforeRelease"
          values={{ date: RELEASE_DATE_FORMAT }}
        />
      );

    return (
      <GridFlex.RowMiddle $fullWidth pt={8}>
        <Text.H6 color="tertiary" textAlign="center">
          {text}
        </Text.H6>
      </GridFlex.RowMiddle>
    );
  }

  return (
    <GridFlex.Column gap={6}>
      {request?.body && (
        <ClipboardJSON
          title={t('eventsLog.detailsDrawer.request.body')}
          json={pick(request.body, ['mutationName', 'variables'])}
        />
      )}
      {request?.response && (
        <ClipboardJSON title={t('eventsLog.detailsDrawer.request.response')} json={request.response} />
      )}
    </GridFlex.Column>
  );
}
