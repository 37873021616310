import { Icon, Tabs, GridFlex, Text, InformationalTooltipIcon } from '@stigg-components';
import { BillingPeriod, Currency } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import last from 'lodash/last';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { PriceTiersPeriods, TIERS_SCHEMA_PER_UNIT, TiersSchema } from '../../SetPriceWizardForm.types';
import { ALL_BILLING_PERIODS } from '../utils/getPricePeriodFields';
import { TiersTable } from './TiersTable';

type PricingTiersTableProps = {
  priceTiersId: string;
  tiers: PriceTiersPeriods;
  tiersSchema: TiersSchema;
  currency: Currency;
  pricePeriodUnit: BillingPeriod;
  disableUnitChanges?: boolean;
  disableAddingTier?: boolean;
};

function MissingValuesErrorIcon() {
  return (
    <InformationalTooltipIcon
      icon={<Icon icon="Error" color="error" size={20} />}
      textColor="error.main"
      text={t('pricing.tiersTableInvalidValues')}
      placement="top"
    />
  );
}

export function PricingTiersTable({
  tiers,
  tiersSchema,
  currency,
  priceTiersId,
  pricePeriodUnit,
  disableAddingTier,
  disableUnitChanges,
}: PricingTiersTableProps) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { billingPeriods } = formRenderProps.values;
  const hasMonthlyBillingPeriod = billingPeriods.includes(BillingPeriod.Monthly);
  const hasAnnuallyBillingPeriod = billingPeriods.includes(BillingPeriod.Annually);
  const hasMultipleBillingPeriods = hasMonthlyBillingPeriod && hasAnnuallyBillingPeriod;

  const getBillingPeriodTiersId = (billingPeriod: BillingPeriod) => `${priceTiersId}.${billingPeriod}`;

  const hasErrors = (billingPeriod: BillingPeriod): boolean => {
    const billingPeriodErrors = get(formRenderProps.errors, getBillingPeriodTiersId(billingPeriod));
    const currentBillingPeriodTouched = get(formRenderProps.touched, getBillingPeriodTiersId(billingPeriod));
    const billingPeriodTouchedMonthly = get(formRenderProps.touched, getBillingPeriodTiersId(BillingPeriod.Monthly));
    const billingPeriodTouchedAnnually = get(formRenderProps.touched, getBillingPeriodTiersId(BillingPeriod.Annually));
    const isTouched = hasMultipleBillingPeriods
      ? billingPeriodTouchedMonthly || billingPeriodTouchedAnnually
      : currentBillingPeriodTouched;

    return isTouched && !isEmpty(billingPeriodErrors);
  };

  const onAddTier = () => {
    const lastTier = last(tiers.MONTHLY);

    if (TIERS_SCHEMA_PER_UNIT.includes(tiersSchema)) {
      const numberOfTiers = tiers.MONTHLY?.length;
      if (numberOfTiers === 1) {
        const firstTier = {
          endUnit: 1,
          unitPrice: null,
          tierPrice: null,
        };

        // Adding new tier at the beginning of the array
        ALL_BILLING_PERIODS.forEach((billingPeriod) => {
          formRenderProps.setFieldValue(getBillingPeriodTiersId(billingPeriod), [firstTier, ...tiers[billingPeriod]!]);
        });
      } else if (numberOfTiers && numberOfTiers > 1) {
        const beforeLastTier = tiers.MONTHLY![numberOfTiers - 2];
        const { endUnit } = beforeLastTier;
        const nextTier = {
          endUnit: endUnit ? endUnit + 2 : null,
          unitPrice: null,
          tierPrice: null,
        };

        // Adding new tier before the last one
        ALL_BILLING_PERIODS.forEach((billingPeriod) => {
          const cloneArray = [...tiers[billingPeriod]!];
          const lastTier = cloneArray.pop();
          const newTiers = [...cloneArray, nextTier, lastTier];
          formRenderProps.setFieldValue(getBillingPeriodTiersId(billingPeriod), newTiers);
        });
      }
    } else if (lastTier) {
      const { endUnit } = lastTier;
      const isEntireTier = tiersSchema === TiersSchema.VolumeBulkOfUnits;
      const nextTier = {
        endUnit: endUnit ? endUnit + (isEntireTier ? 1 : 2) : null,
        unitPrice: null,
        tierPrice: null,
      };
      ALL_BILLING_PERIODS.forEach((billingPeriod) => {
        formRenderProps.setFieldValue(getBillingPeriodTiersId(billingPeriod), tiers[billingPeriod]!.concat([nextTier]));
      });
    }
  };

  const onRemoveTier = (indexToRemove: number) => {
    ALL_BILLING_PERIODS.forEach((billingPeriod) => {
      const newTiers = tiers[billingPeriod]!.filter((_, index) => index !== indexToRemove);
      formRenderProps.setFieldValue(getBillingPeriodTiersId(billingPeriod), newTiers);
    });
  };

  return (
    <GridFlex.Column mb={4}>
      {hasMultipleBillingPeriods ? (
        <Tabs
          withoutQueryParams
          data={ALL_BILLING_PERIODS.map((billingPeriod) => ({
            title: t(`pricing.pricePeriod.${billingPeriod}`),
            endIcon: hasErrors(billingPeriod) ? <MissingValuesErrorIcon /> : null,
            dataTestId: `tiers-table-${billingPeriod}`,
            content: (
              <TiersTable
                tiers={tiers[billingPeriod]!}
                tiersSchema={tiersSchema}
                billingPeriod={billingPeriod}
                currency={currency}
                formRenderProps={formRenderProps}
                priceTiersId={priceTiersId}
                onAddTier={onAddTier}
                onRemoveTier={onRemoveTier}
                pricePeriodUnit={pricePeriodUnit}
                disableAddingTier={disableAddingTier}
                disableUnitChanges={disableUnitChanges}
              />
            ),
          }))}
        />
      ) : (
        <GridFlex.Column>
          <Text.B2 mb={3} color="secondary">
            {t(`pricing.pricePeriod.${hasMonthlyBillingPeriod ? BillingPeriod.Monthly : BillingPeriod.Annually}`)}
          </Text.B2>
          <TiersTable
            tiers={hasMonthlyBillingPeriod ? tiers.MONTHLY! : tiers.ANNUALLY!}
            tiersSchema={tiersSchema}
            currency={currency}
            formRenderProps={formRenderProps}
            priceTiersId={priceTiersId}
            billingPeriod={hasMonthlyBillingPeriod ? BillingPeriod.Monthly : BillingPeriod.Annually}
            onAddTier={onAddTier}
            onRemoveTier={onRemoveTier}
            pricePeriodUnit={pricePeriodUnit}
            disableAddingTier={disableAddingTier}
            disableUnitChanges={disableUnitChanges}
          />
        </GridFlex.Column>
      )}
    </GridFlex.Column>
  );
}
