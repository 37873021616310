import { AddonFragment, AddonListFragment, PlanFragment, PlanListFragment } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../navigation/useNavigation';
import { useAppDispatch } from '../../../../redux/store';
import { createPlanDraftAction } from '../../plans/plansSlice';
import { createAddonDraftAction } from '../../addons/addonSlice';
import { PackageFragment, PackageListFragment } from '../components/Packages';

type DraftPackage =
  | PlanFragment
  | PlanListFragment
  | AddonFragment
  | AddonListFragment
  | PackageListFragment
  | PackageFragment;

export default function useNavigateToDraft(): (aPackage: DraftPackage) => void {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const packageRoute = {
    Plan: (aPackage) => navigation.appRoutes.planPage(aPackage.refId, aPackage.draftSummary.version),
    Addon: (aPackage) => navigation.appRoutes.addonPage(aPackage.refId, aPackage.draftSummary.version),
  };

  const createDraftAction = {
    Plan: (aPackage) => createPlanDraftAction({ planId: aPackage.id }),
    Addon: (aPackage) => createAddonDraftAction(aPackage.id),
  };

  return (aPackage) => {
    if (!aPackage) return;
    if (aPackage.draftSummary) {
      navigation.navigateTo(packageRoute[aPackage.type](aPackage));
    } else {
      dispatch(createDraftAction[aPackage.type](aPackage));
    }
  };
}
