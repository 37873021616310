import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';
import { getIconColor, IconColor } from '../../theme';
import { StyledProperties } from '../styledComponentsUtils/styledPropertiesTypeUtils';

export type { IconColor } from '../../theme';

export type IconStylerProps = {
  color?: IconColor;
  size?: number;
  height?: number;
  width?: number;
  withHoverEffect?: boolean;
  display?: string;
  overrideStroke?: boolean;
  overrideFill?: boolean;
};

export type IconStylerStyledProps = PropsWithChildren<StyledProperties<IconStylerProps>>;

export const IconStyler = styled.div<IconStylerStyledProps>`
  display: ${({ $display }) => $display || 'contents'};
  ${({ $color }) => $color && `color:${getIconColor($color)};`};
  ${({ $withHoverEffect }) =>
    $withHoverEffect &&
    css`
      svg {
        opacity: 0.4;

        &:hover {
          opacity: 1 !important;
        }
      }
    `};

  ${({ $color, $overrideStroke, $overrideFill }) =>
    $color &&
    ($overrideStroke || $overrideFill) &&
    css`
      path,
      circle {
        ${$overrideStroke ? `stroke: ${getIconColor($color)};` : ''}
        ${$overrideFill ? `fill: ${getIconColor($color)};` : ''}
      }

      rect {
        ${$overrideStroke ? `stroke: ${getIconColor($color)};` : ''}
      }
    `}

  svg {
    transition: opacity 0.2s ease-in-out;
    flex-shrink: 0;
    ${({ $color }) => $color && `color: ${getIconColor($color)} !important;`};
    ${({ $size, $height }) => ($size || $height) && `height:${$size ?? $height}px;`};
    ${({ $size, $width }) => ($size || $width) && `width:${$size ?? $width}px;`};
  }
`;
