import { ExecuteOperationOptions, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { FetchExperimentByRefIdQuery, FetchExperimentByRefIdQueryVariables } from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { PRODUCT_FRAGMENT } from '../../products/queries/fetchProductByRefId';

export const EXPERIMENT_FRAGMENT = gql`
  fragment ExperimentFragment on Experiment {
    id
    refId
    name
    description
    status
    startedAt
    stoppedAt
    controlGroupName
    variantGroupName
    variantPercentage
    productSettings {
      ...ProductSettingsFragment
    }
    initialProductSettings {
      ...ProductSettingsFragment
    }
    product {
      ...ProductFragment
    }
  }

  ${PRODUCT_FRAGMENT}
`;

const FETCH_EXPERIMENT_BY_REF_ID = gql`
  query FetchExperimentByRefId($filter: ExperimentFilter!) {
    experiments(filter: $filter) {
      edges {
        node {
          ...ExperimentFragment
        }
      }
    }
  }

  ${EXPERIMENT_FRAGMENT}
`;

export type FetchExperimentsProps = ExecuteOperationOptions & {
  refId: string;
};

export async function fetchExperimentByRefId(
  { refId }: FetchExperimentsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId },
      } = getState();
      const response = await apolloClient.query<FetchExperimentByRefIdQuery, FetchExperimentByRefIdQueryVariables>({
        query: FETCH_EXPERIMENT_BY_REF_ID,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            refId: { eq: refId },
            environmentId: { eq: currentEnvironmentId },
          },
        },
      });

      return response.data.experiments.edges?.[0]?.node;
    },
    {
      failureMessageHandler: () => t('experiments.api.failFetchOne'),
    },
    dispatch,
  );
}
