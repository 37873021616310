import { gql } from '@apollo/client';
import { FetchSubscriptionSyncStatesQuery, FetchSubscriptionSyncStatesQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { SUBSCRIPTION_INVOICE } from './subscriptionInvoice';

const QUERY = gql`
  query FetchSubscriptionSyncStates($filter: CustomerSubscriptionFilter!) {
    customerSubscriptions(filter: $filter) {
      edges {
        node {
          billingId
          billingLinkUrl
          crmId
          crmLinkUrl
          status
          paymentCollection
          syncStates {
            vendorIdentifier
            status
            error
          }
          latestInvoice {
            ...SubscriptionInvoiceFragment
          }
        }
      }
    }
  }
  ${SUBSCRIPTION_INVOICE}
`;

export function watchSubscriptionSyncStates(subscriptionId: string, pollInterval: number) {
  return apolloClient.watchQuery<FetchSubscriptionSyncStatesQuery, FetchSubscriptionSyncStatesQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: subscriptionId,
        },
      },
    },
  });
}
