import { t } from 'i18next';
import { BillingPeriod, BillingCadence } from '@stigg-types/apiTypes';

export function getBillingPeriodText(
  prices: { billingPeriod: BillingPeriod; billingCadence: BillingCadence }[],
): string | undefined {
  if (!prices.length) {
    return undefined;
  }

  const hasMonthlyPrice = prices.some((price) => price.billingPeriod === BillingPeriod.Monthly);
  const hasAnnuallyPrice = prices.some((price) => price.billingPeriod === BillingPeriod.Annually);
  const isOneOff = prices.some((price) => price.billingCadence === BillingCadence.OneOff);
  let billingPeriodTextKey: string | null = null;
  if (isOneOff) {
    billingPeriodTextKey = 'billedOneTime';
  } else if (hasMonthlyPrice || hasAnnuallyPrice) {
    if (hasMonthlyPrice && hasAnnuallyPrice) {
      billingPeriodTextKey = 'billedMonthlyOrAnnually';
    } else {
      billingPeriodTextKey = hasMonthlyPrice ? 'billedMonthly' : 'billedAnnually';
    }
  }

  return billingPeriodTextKey ? t(`plans.pricingTypeColumn.${billingPeriodTextKey}`) : undefined;
}
