import { Link, Text, Alert, AlertTitle } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { AlertTriangle } from 'react-feather';
import { useNavigation } from '../../../navigation/useNavigation';

export function PackagePublishDisabledDueToDraftBasePlan({
  planRefId,
  planName,
}: {
  planRefId: string;
  planName: string;
}) {
  const navigation = useNavigation();

  return (
    <Alert severity="warning" icon={<AlertTriangle />}>
      <AlertTitle>
        <Text.B2 $bold color="warning">
          {t('plans.preventPublish.draftBasePlanHeader')}
        </Text.B2>
      </AlertTitle>
      <Trans
        i18nKey="plans.preventPublish.draftBasePlanContent"
        t={t}
        values={{ planName: `${planName} (Draft)` }}
        components={[
          <Link
            sx={{ color: 'inherit', textDecoration: 'underline' }}
            onClick={() => navigation.navigateTo(navigation.appRoutes.planPage(planRefId))}
          />,
        ]}
      />
    </Alert>
  );
}
