// import { t } from 'i18next';
import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import {
  PreviewNextInvoiceFragment,
  PreviewNextInvoiceMutation,
  PreviewNextInvoiceMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { PREVIEW_NEXT_INVOICE_FRAGMENT } from './previewNextInvoiceFragment';

const PREVIEW_NEXT_INVOICE = gql`
  mutation PreviewNextInvoice($input: PreviewNextInvoiceInput!) {
    previewNextInvoice(input: $input) {
      ...PreviewNextInvoiceFragment
    }
  }

  ${PREVIEW_NEXT_INVOICE_FRAGMENT}
`;

export type PreviewNextInvoiceProps = {
  subscriptionId: string;
};

async function previewNextInvoice(
  { subscriptionId }: PreviewNextInvoiceProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<PreviewNextInvoiceFragment | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      const res = await apolloClient.mutate<PreviewNextInvoiceMutation, PreviewNextInvoiceMutationVariables>({
        mutation: PREVIEW_NEXT_INVOICE,
        variables: {
          input: {
            subscriptionId,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return res.data?.previewNextInvoice;
    },
    {
      hideErrorMessages: true,
    },
    dispatch,
  );
}

export { previewNextInvoice };
