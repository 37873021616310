import { t } from 'i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { ItamarTheme, styled } from '@stigg-theme';
import { Alert, Button, Grid, Icon, AlertTitle, GridFlex } from '@stigg-components';
import moment from 'moment';
import useNavigateToDraft from '../hooks/useEditPackageNaivatge';
import { normalizedPackageName } from '../../../../i18n/utils';

const StyledAlert = styled(Alert)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }: { theme: ItamarTheme }) => theme.itamar.palette.warning.light};
  width: 100%;

  & .MuiAlert-message {
    flex: 1;
  }
`;

export default function PackageDetailsDraftAlert({
  aPackage,
  onDiscardChanges,
}: {
  aPackage: PlanFragment | AddonFragment; // package is a reserved word so adding a prefix
  onDiscardChanges: () => void;
}) {
  const { user } = useAuth0();
  const navigateToDraft = useNavigateToDraft();

  const onKeepEditing = () => navigateToDraft(aPackage);
  const totalChanges = aPackage.draftDetails?.changes?.totalChanges;

  return (
    <StyledAlert severity="warning" sx={{ alignItems: totalChanges ? 'flex-start' : 'center' }}>
      <GridFlex.RowCenter $fullWidth columnGap={2}>
        <GridFlex.Column flex={1} p={0} m={0}>
          <AlertTitle
            sx={{
              m: 0,
              fontWeight: totalChanges ? 'bold' : 'normal',
              fontSize: totalChanges ? '1rem' : '0.875rem',
            }}>
            {t('packages.newerVersionExists', {
              packageType: normalizedPackageName(aPackage.type),
              count: totalChanges,
            })}
          </AlertTitle>
          {totalChanges ? (
            <Grid item>
              Last change: {moment(aPackage.draftSummary?.updatedAt).fromNow()}
              {aPackage.draftSummary?.updatedBy
                ? user?.email === aPackage.draftSummary.updatedBy
                  ? t('packages.changedByYou')
                  : t('packages.changedBy', { email: aPackage.draftSummary.updatedBy })
                : ''}
            </Grid>
          ) : null}
        </GridFlex.Column>
        {!aPackage.draftDetails?.changes?.totalChanges && (
          <Button sx={{ height: 30, minWidth: 'fit-content' }} $outlined color="warning" onClick={onDiscardChanges}>
            {t('packages.discardDraft')}
          </Button>
        )}
        <Button
          data-testid="button-plans-review-and-publish"
          sx={{ height: 30, minWidth: 'fit-content' }}
          variant="contained"
          color="warning"
          startIcon={<Icon type="reactFeather" icon="Edit2" size={18} />}
          onClick={onKeepEditing}>
          {t('sharedComponents.keepEditing')}
        </Button>
      </GridFlex.RowCenter>
    </StyledAlert>
  );
}
