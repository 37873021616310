import { t } from 'i18next';
import { Dialog } from '../../../../../../components/Dialog';
import { ApplyCouponForm, ApplyCouponFormFields } from './ApplyCouponForm';

export type ApplyCouponDialogProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSubmit: (values: ApplyCouponFormFields) => void;
};

export function ApplyCouponDialog({ open, setOpen, onSubmit }: ApplyCouponDialogProps) {
  return (
    <Dialog
      content={<ApplyCouponForm onCancel={() => setOpen(false)} onSubmit={onSubmit} />}
      titleText={t('coupons.applyCouponDialog.titleText')}
      isOpen={open}
      onCancel={() => setOpen(false)}
      aria-labelledby="edit-customer-dialog"
      width={650}
    />
  );
}
