import { t } from 'i18next';
import { styled } from '@stigg-theme';
import {
  Box,
  LoadingButton,
  Text,
  WizardAccordionStep,
  ClipboardChip,
  ClipboardJSON,
  TextField,
  GridFlex,
  ExternalLink,
} from '@stigg-components';
import { StyledAlert } from '../IntegrationPage';

const accountIdPlaceholder = '__ACCOUNT_ID__';
const environmentIdPlaceholder = '__ENV_ID__';
const AWS_INTEGRATION_WEBHOOK_URL = `https://webhooks.stigg.io/handler/aws/${accountIdPlaceholder}/${environmentIdPlaceholder}`;
const POLICY_JSON = `{
  "Version": "2012-10-17",
  "Statement": [
      {
          "Action": [
              "aws-marketplace:BatchMeterUsage",
              "aws-marketplace:GetEntitlements",
              "aws-marketplace:ListEntities",
              "aws-marketplace:DescribeEntity"
          ],
          "Effect": "Allow",
          "Resource": "*"
      }
  ]
}`;

const EXTERNAL_ID_IDENTIFIER = '__EXTERNAL_ID__';

const EXTERNAL_LINK_JSON = `{
  "Version": "2012-10-17",
  "Statement": [
      {
          "Effect": "Allow",
          "Principal": {
              "AWS": "arn:aws:iam::502947190190:root"
          },
          "Action": "sts:AssumeRole",
          "Condition": {
              "StringEquals": {
                  "sts:ExternalId": "${EXTERNAL_ID_IDENTIFIER}"
              }
          }
      }
  ]
}`;

type UseWizardStepsProps = {
  hasIntegration: boolean;
  accountId: string | undefined;
  environmentId: string | undefined;
  externalId: string;
  isLoading: boolean;
  addIntegrationError: boolean;
  onAddIntegration: () => Promise<void>;
  roleARN?: string;
  onRoleARNChange: (value: string) => void;
};

const StyledCopyContainer = styled(GridFlex.RowMiddle)<{ $disabled?: boolean }>`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: ${({ theme }) => theme.spacing(2)};
  justify-content: space-between;

  ${({ theme, $disabled }) => $disabled && `background-color: ${theme.itamar.palette.grey[50]}`}
`;

export const useAWSMarketplaceIntegrationWizardSteps = ({
  hasIntegration,
  accountId = '',
  environmentId = '',
  externalId,
  isLoading,
  addIntegrationError,
  onAddIntegration,
  roleARN,
  onRoleARNChange,
}: UseWizardStepsProps): {
  connectWithStiggSteps: WizardAccordionStep[];
  notifyStiggSteps: WizardAccordionStep[];
} => {
  const webhooksUrl = AWS_INTEGRATION_WEBHOOK_URL.replace(accountIdPlaceholder, accountId).replace(
    environmentIdPlaceholder,
    environmentId,
  );

  const connectWithStiggSteps: WizardAccordionStep[] = [
    {
      id: 'setup-aws-policy',
      title: t('integrations.awsMarketplace.steps.awsPolicy.title'),
      content: (
        <Box>
          <Text.B2 marginBottom={6}>
            {t('integrations.awsMarketplace.steps.awsPolicy.content')}{' '}
            <ExternalLink
              label={t('integrations.awsMarketplace.showMeHow')}
              url={t('integrations.awsMarketplace.steps.awsPolicy.learnMoreUrl')}
            />
          </Text.B2>
          <ClipboardJSON title={t('integrations.awsMarketplace.steps.awsPolicy.jsonTitle')} json={POLICY_JSON} />
        </Box>
      ),
    },
    {
      id: 'setup-external-link',
      title: t('integrations.awsMarketplace.steps.externalLink.title'),
      content: (
        <Box maxWidth={800}>
          <Text.B2 marginBottom={4}>
            {t('integrations.awsMarketplace.steps.externalLink.content')}{' '}
            <ExternalLink
              label={t('integrations.awsMarketplace.showMeHow')}
              url={t('integrations.awsMarketplace.steps.externalLink.learnMoreUrl')}
            />
          </Text.B2>
          <ClipboardJSON
            title={t('integrations.awsMarketplace.steps.externalLink.jsonTitle')}
            json={EXTERNAL_LINK_JSON.replace(EXTERNAL_ID_IDENTIFIER, externalId || '')}
          />
        </Box>
      ),
    },
    {
      id: 'setup-access',
      title: t('integrations.awsMarketplace.steps.access.title'),
      content: () => (
        <>
          <Box>
            <Text.B2 marginBottom={4}>{t('integrations.awsMarketplace.steps.access.content')}</Text.B2>
            <TextField
              value={roleARN}
              onChange={(e) => onRoleARNChange(e.target.value)}
              error={addIntegrationError}
              errorText={t('integrations.awsMarketplace.steps.access.errorMessage')}
              touched={!!roleARN || addIntegrationError}
              placeholder={t('integrations.awsMarketplace.steps.access.placeholder')}
              InputProps={{ sx: { height: 54 } }}
              disabled={hasIntegration}
              endAdornment={
                hasIntegration ? (
                  <ClipboardChip copy={roleARN || ''} iconLocation="right" spaceIcon copyPadding={0} />
                ) : (
                  <LoadingButton
                    loading={isLoading}
                    disabled={!roleARN || roleARN.length < 3}
                    variant="contained"
                    color="primary"
                    loadingPosition="start"
                    onClick={(e) => {
                      // TODO: connect to verify + loading state + error state + move texts to en.json
                      e.stopPropagation();
                      void onAddIntegration();
                    }}>
                    <Text.B2 color="white">
                      {!hasIntegration ? t('integrations.awsMarketplace.connectButton') : 'Copy'}
                    </Text.B2>
                  </LoadingButton>
                )
              }
            />
          </Box>
        </>
      ),
    },
  ];

  const notifyStiggSteps: WizardAccordionStep[] = [
    {
      id: 'provision-aws-customers',
      title: t('integrations.awsMarketplace.steps.provisionAwsCustomers.title'),
      maxWidth: 600,
      content: (
        <Box>
          <Text.B2 marginBottom={6}>{t('integrations.awsMarketplace.steps.provisionAwsCustomers.content')}</Text.B2>

          <StyledAlert $muted severity="info" sx={{ width: '100%' }}>
            <Text.B2>
              {t('integrations.awsMarketplace.steps.provisionAwsCustomers.readMoreTextPart1')}
              <ExternalLink
                label={t('integrations.awsMarketplace.steps.provisionAwsCustomers.readMoreTextPart2')}
                url={t('integrations.awsMarketplace.steps.provisionAwsCustomers.readMoreUrl')}
              />
            </Text.B2>
          </StyledAlert>
        </Box>
      ),
    },
    {
      id: 'setup-webhook',
      title: t('integrations.awsMarketplace.steps.webhook.title'),
      maxWidth: 600,
      content: (
        <Box>
          <Text.B2 marginBottom={4}>
            {t('integrations.awsMarketplace.steps.webhook.content')}
            <ExternalLink
              label={t('integrations.awsMarketplace.showMeHow')}
              url={t('integrations.awsMarketplace.steps.webhook.learnMoreUrl')}
            />
          </Text.B2>

          <StyledCopyContainer $disabled>
            <Text.B2 color="disabled">{webhooksUrl}</Text.B2>
            <ClipboardChip copy={webhooksUrl} iconLocation="right" spaceIcon copyPadding={2} />
          </StyledCopyContainer>
        </Box>
      ),
    },
  ];

  return { connectWithStiggSteps, notifyStiggSteps };
};
