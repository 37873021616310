/* eslint-disable no-restricted-imports */
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { ChipColor, getChipColor, getTextColor, TextColor } from '@stigg-theme';
import { forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';

// due the face color is a props coming from MuiChipButton when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiChipButton['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it :)

type StyledChipType = {
  $typesafeTextColor?: TextColor; // look at the first comment
  textColor?: TextColor;
  $typesafeColor?: ChipColor; // look at the first comment
  color?: ChipColor;
  $hasPointer?: boolean;
};

type TypesafeChipProps = Omit<MuiChipProps, 'color' | 'textColor'> &
  Omit<StyledChipType, '$typesafeColor' | '$typesafeTextColor'>;

type ChipProps = TypesafeChipProps;

const StyledChip = styled(MuiChip)<StyledChipType>`
  cursor: ${({ $hasPointer }) => ($hasPointer ? 'pointer' : 'auto')};
  font-size: 14px;

  ${({ $typesafeColor }) =>
    !!$typesafeColor &&
    css`
      background: ${getChipColor($typesafeColor)};
      color: ${({ theme }) => theme.itamar.palette.white};
    `}
  ${({ $typesafeTextColor }) =>
    !!$typesafeTextColor &&
    css`
      color: ${getTextColor($typesafeTextColor)};
    `}

  /* outlined chip */
  ${({ variant, $typesafeTextColor, $typesafeColor }) =>
    !!$typesafeTextColor &&
    !!$typesafeColor &&
    variant === 'outlined' &&
    css`
      background: none;
      border: 1px solid ${getChipColor($typesafeColor)};
      color: ${getTextColor($typesafeTextColor)};
    `}
`;

const TypesafeChip = forwardRef<HTMLDivElement, PropsWithChildren<ChipProps>>(({ color, textColor, ...props }, ref) => (
  <StyledChip $typesafeColor={color} $typesafeTextColor={textColor} {...props} ref={ref} />
));

export const Chip = forwardRef<HTMLDivElement, PropsWithChildren<ChipProps>>(({ color, ...props }, ref) => (
  <TypesafeChip color={color} {...props} ref={ref} />
));
