import { Theme } from '@mui/material/styles';
import typography from '../../../../theme/typography';

export const getOtherColor = (theme: Theme) => theme.itamar.palette.grey[100];

export const getLineColor = (theme: Theme) => theme.itamar.palette.grey[200];

export const opacityColor = (color: string, opacity = 1) => {
  const hex = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');
  return `${color}${hex}`;
};

export const LEGEND_BOX_SIZE = 12;

export const getLabelColor = (theme: Theme) => theme.itamar.palette.text.secondary;

export const getLabelFont = () => ({
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
});

export const getAxisTitleColor = (theme: Theme) => theme.itamar.palette.text.primary;

export const getAxisTitleFont = () => ({
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
});

export const getAxisTickColor = (theme: Theme) => theme.itamar.palette.text.secondary;

export const getAxisColor = (theme: Theme) =>
  theme.isLightTheme ? theme.itamar.palette.other.outlineBorderLight : theme.itamar.palette.other.outlineBorderLight;

export const getAxisTickFont = () => ({
  family: typography.body2?.fontFamily as string,
  size: typography.body2?.fontSize as number,
});

export const GRID_BORDER_DASH = [8, 8];

export const AXIS_TITLE_SPACING = 15;
