import { Box, CollapsableSectionIcon, Collapse, GridFlex, Icon, IconButton, Link, Text } from '@stigg-components';
import { ReactNode, useState } from 'react';
import { BillingModel, TiersMode } from '@stigg-types/apiTypes';
import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { t } from 'i18next';
import { TextColor } from '@stigg-theme';
import { GraduatedPriceBreakdown } from './GraduatedPriceBreakdown';
import { getFeatureDisplayName } from '../../../../../features/utils/getFeatureDisplayName';
import { getPlanPriceBreakdownString, PlanPriceWithQuantity } from './priceBreakdown';

export type PlanPriceBreakdownItemProps = {
  priceWithQuantity: PlanPriceWithQuantity;
  textColor?: TextColor;
  showMonthlyPriceVariation: boolean;
  spacing: number;
};

export function PlanPriceBreakdownItem({
  priceWithQuantity,
  textColor,
  showMonthlyPriceVariation,
  spacing,
}: PlanPriceBreakdownItemProps) {
  const [isNestedBreakdownOpen, setIsNestedBreakdownOpen] = useState(false);
  const toggleNestedBreakdown = () => setIsNestedBreakdownOpen((prev) => !prev);

  const { billingModel, tiersMode } = priceWithQuantity.price;

  let nestedBreakdown: ReactNode;
  const shouldShowGraduatedPriceBreakdown =
    tiersMode === TiersMode.Graduated &&
    !!priceWithQuantity.price.tiers &&
    !isEmpty(priceWithQuantity.price.tiers) &&
    !isNil(priceWithQuantity.price.tiers[0].upTo) &&
    priceWithQuantity.unitQuantity > priceWithQuantity.price.tiers[0].upTo;

  if (shouldShowGraduatedPriceBreakdown) {
    nestedBreakdown = (
      <GraduatedPriceBreakdown
        priceWithQuantity={priceWithQuantity}
        showMonthlyPriceVariation={showMonthlyPriceVariation}
        spacing={spacing}
      />
    );
  }

  let title: ReactNode = (
    <Text.B2 color={textColor || 'secondary'}>
      {priceWithQuantity.price.feature
        ? getFeatureDisplayName(priceWithQuantity.price.feature)
        : t('pricing.basePrice')}
    </Text.B2>
  );

  if (nestedBreakdown) {
    title = (
      <Link onClick={toggleNestedBreakdown} underline="none">
        {title}
      </Link>
    );
  }

  return (
    <GridFlex.Column>
      <GridFlex.RowSpaceBetween alignItems="flex-start" item gap={spacing}>
        <GridFlex.RowCenter gap={1}>
          {title}
          {nestedBreakdown && (
            <IconButton onClick={toggleNestedBreakdown} sx={{ padding: 0 }}>
              <CollapsableSectionIcon $isOpen={isNestedBreakdownOpen} $size={16} />
            </IconButton>
          )}
        </GridFlex.RowCenter>
        <Text.B2
          data-testid="price-breakdown-template"
          color={priceWithQuantity.unitQuantity === 0 ? 'disabled' : textColor || 'secondary'}
          textAlign="right"
          display="inline-flex"
          alignItems="center"
          gap={2}>
          {billingModel === BillingModel.UsageBased && <Icon icon="PayAsYouGoCharge" size={16} />}
          {getPlanPriceBreakdownString({
            price: priceWithQuantity.price,
            unitQuantity: priceWithQuantity.unitQuantity,
            showMonthlyPriceVariation,
          })}
        </Text.B2>
      </GridFlex.RowSpaceBetween>
      {nestedBreakdown && (
        <Collapse in={isNestedBreakdownOpen}>
          <Box ml={spacing} pt={spacing}>
            {nestedBreakdown}
          </Box>
        </Collapse>
      )}
    </GridFlex.Column>
  );
}
