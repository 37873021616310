import { gql } from '@apollo/client';
import { RESET_PERIOD_CONFIGURATION } from '../../../entitlements/queries/resetPeriodConfigurationFragment';
import { PRICE_FRAGMENT } from '../../plans/queries/priceFragment';

export const ADDON_LIST_FRAGMENT = gql`
  fragment AddonListFieldsFragment on Addon {
    id
    refId
    displayName
    description
    billingId
    billingLinkUrl
    createdAt
    updatedAt
    status
    type
    versionNumber
    isLatest
    hiddenFromWidgets
    maxQuantity
    hasSubscriptions
    draftSummary {
      version
      updatedAt
      updatedBy
    }
    entitlements {
      packageId
      id
      featureId
      environmentId
      usageLimit
      hasUnlimitedUsage
      hasSoftLimit
      behavior
      resetPeriod
      resetPeriodConfiguration {
        __typename
        ... on YearlyResetPeriodConfig {
          ...YearlyResetPeriodConfigFragment
        }
        ... on MonthlyResetPeriodConfig {
          ...MonthlyResetPeriodConfigFragment
        }
        ... on WeeklyResetPeriodConfig {
          ...WeeklyResetPeriodConfigFragment
        }
      }
      feature {
        id
        displayName
        refId
        description
        featureStatus
        updatedAt
        environmentId
        featureType
        meterType
        featureUnits
        featureUnitsPlural
      }
    }
    product {
      id
      displayName
      refId
      awsMarketplaceProductId
      awsMarketplaceProductCode
      productSettings {
        subscriptionEndSetup
        subscriptionCancellationTime
      }
    }
    prices {
      ...PriceFragment
    }
    pricingType
  }

  fragment AddonListFragment on Addon {
    ...AddonListFieldsFragment
    dependencies {
      ...AddonListFieldsFragment
    }
  }

  ${PRICE_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
`;
