import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { AwsProduct } from '@stigg-types/apiTypes';
import { AWSProduct } from './AWSProduct';

type SelectAWSProductStepProps = { awsProducts: AwsProduct[]; onProductSelected: (awsProduct: AwsProduct) => void };

export function SelectAWSProductStep({ awsProducts, onProductSelected }: SelectAWSProductStepProps) {
  return (
    <>
      <Text.H3 pb={2}>{t('integrations.awsMarketplace.import.importProduct.title')}</Text.H3>
      <Text.B1 color="secondary" pb={8}>
        {t('integrations.awsMarketplace.import.importProduct.description')}
      </Text.B1>
      <GridFlex.Column gap={4}>
        {awsProducts?.map((awsProduct) => {
          return <AWSProduct onClick={() => onProductSelected(awsProduct)} awsProduct={awsProduct} />;
        })}
      </GridFlex.Column>
    </>
  );
}
