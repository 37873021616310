import { t } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PackageStatus } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useBreadcrumbs, useQueryParam } from '@stigg-common';
import { GridFlex, Loader, PackageHeaderLayout } from '@stigg-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import PlanOverview from './PlanOverview';
import { packageVersionFormat } from '../../../common/components/packageVersionFormat';
import { fetchPlanByRefIdAction } from '../../plansSlice';
import Tabs from '../../../../../components/tabs/Tabs';
import { setEditMode } from '../../../../application/applicationSlice';
import EventLog from '../../../../eventsLog/components/EventLog';
import OutdatedVersionBanner from '../../../common/OutdatedVersionBanner';
import PackageDetailsDraftAlert from '../../../common/components/PackageDetailsDraftAlert';
import { useDiscardChangesDialog } from '../../../common/hooks/useDiscardChangesDialog';
import { isDraftPackage, isOutdatedPackage } from '../../../package.utils';

type ViewPlanRouteParams = {
  refId: string;
};

export default function PlanPage() {
  const { refId } = useParams<ViewPlanRouteParams>();
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();
  const { value: versionNumber } = useQueryParam('version');
  const dispatch = useAppDispatch();
  const plan = useSelector((root: RootState) => root.plansReducer.plan);
  const isLoadingPlan = useSelector((root: RootState) => root.plansReducer.isLoadingPlan);
  const [DiscardPackageDraftDialog, showDiscardPackageDraftDialog, discardPackageDraftDialogProps] =
    useDiscardChangesDialog(plan);

  const planVersion = plan?.versionNumber ? packageVersionFormat(plan.versionNumber) : '';
  useBreadcrumbs(
    { to: '/plans', title: t('breadCrumbs.plans') },
    { title: `${refId} ${!isLoadingPlan && planVersion && !plan?.isLatest ? `(${planVersion})` : ''}` },
  );

  useEffect(() => {
    const fetchData = () => dispatch(fetchPlanByRefIdAction({ refId, versionNumber }));

    void fetchData();
  }, [dispatch, refId, versionNumber]);

  useEffect(() => {
    if (!isLoadingPlan && plan?.status === PackageStatus.Draft && !plan?.isLatest) {
      dispatch(setEditMode(true));
    }

    return () => {
      dispatch(setEditMode(false));
    };
  }, [isLoadingPlan, dispatch, plan?.status, plan?.isLatest]);

  const silentReloadPlanData = async () => {
    await dispatch(fetchPlanByRefIdAction({ refId, versionNumber, silentFetch: true }));
  };

  const onDiscardChanges = () => {
    showDiscardPackageDraftDialog(true);
  };

  if (isLoadingPlan || !plan || !plan.refId) {
    return <Loader />;
  }

  const showDraftAlert = isDraftPackage(plan);
  const showOutdatedBanner = isOutdatedPackage(plan);
  const showTabs = plan.isLatest;

  const planOverviewComponent = (
    <PlanOverview plan={plan} silentReloadPlanData={silentReloadPlanData} onDiscardChanges={onDiscardChanges} />
  );

  const tabs = [
    {
      title: t('plans.tabs.overview'),
      content: planOverviewComponent,
    },
    {
      title: t('plans.tabs.events'),
      content: <EventLog entityId={plan.refId} />,
    },
  ];

  return (
    <>
      <DiscardPackageDraftDialog {...discardPackageDraftDialogProps} />
      <GridFlex.Column gap={showEventLog ? 4 : 6} $fullHeight>
        <PackageHeaderLayout title={t('plans.planPageSubtitle')} displayName={plan.displayName} aPackage={plan} />
        {showDraftAlert ? <PackageDetailsDraftAlert aPackage={plan} onDiscardChanges={onDiscardChanges} /> : null}
        {showOutdatedBanner && <OutdatedVersionBanner aPackage={plan} />}
        {showEventLog && showTabs ? <Tabs data={tabs} /> : planOverviewComponent}
      </GridFlex.Column>
    </>
  );
}
