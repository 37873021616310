import { useTheme } from '@mui/material/styles';
import { Alert, Icon, Text } from '@stigg-components';
import { t } from 'i18next';

export default function BetaIndicator() {
  const theme = useTheme();
  const text = t('eventsLog.betaIndication');

  return (
    <Alert severity="info" icon={<Icon icon="Experiments" type="custom" />}>
      {theme.isLightTheme ? <Text.B2 color="info.dark">{text}</Text.B2> : text}
    </Alert>
  );
}
