import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateOneEnvironmentMutation,
  UpdateOneEnvironmentMutationVariables,
  EnvironmentInput,
  Environment,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { fetchEnvironmentsAction } from '../accountsSlice';

const UPDATE_ENVIRONMENT = gql`
  mutation UpdateOneEnvironment($input: UpdateOneEnvironmentInput!) {
    updateOneEnvironment(input: $input) {
      id
      description
      displayName
    }
  }
`;

async function updateEnvironment(
  { environmentId, updatePayload }: { environmentId: string; updatePayload: EnvironmentInput },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Environment> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateOneEnvironmentMutation, UpdateOneEnvironmentMutationVariables>({
        mutation: UPDATE_ENVIRONMENT,
        variables: { input: { id: environmentId, update: updatePayload } },
      });

      await dispatch(fetchEnvironmentsAction({}));
      return response.data?.updateOneEnvironment;
    },
    {
      successMessage: t('accounts.api.successEnvUpdate'),
      failureMessageHandler: () => t('accounts.api.failEnvUpdate'),
    },
    dispatch,
  );
}

export { updateEnvironment };
