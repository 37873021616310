import { Text, GridFlex } from '@stigg-components';
import { Feature } from '@stigg-types/apiTypes';
import { getFeatureDisplayName } from '../utils/getFeatureDisplayName';

export function FeatureDisplayNameAndId({ feature }: { feature: Feature }) {
  return (
    <GridFlex.Column>
      <Text.B2 color="primary">{getFeatureDisplayName(feature)}</Text.B2>
      <Text.B2 color="secondary">{feature.refId}</Text.B2>
    </GridFlex.Column>
  );
}
