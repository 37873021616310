import { ChangeType } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { ChangeGroupBox, ChangeRow } from '../DiffBaseComponents';
import { getDiffsBetweenJsons } from './AdditionalMetadata.utils';

export function AdditionalMetadataChangeRow({ before, after }: { before?: JSON | null; after?: JSON | null }) {
  const { updates, adds, removes } = getDiffsBetweenJsons(before, after);
  return (
    <>
      {!isEmpty(updates) && (
        <ChangeGroupBox changeType={ChangeType.Modified}>
          {updates.map((x) => (
            <ChangeRow
              before={`${x.key}: ${x.oldValue}`}
              after={x.value}
              label={x.key}
              showAlways
              changeType={ChangeType.Modified}
            />
          ))}
        </ChangeGroupBox>
      )}
      {!isEmpty(adds) && (
        <ChangeGroupBox changeType={ChangeType.Added}>
          {adds.map((x) => (
            <ChangeRow after={x.value} label={x.key} showAlways changeType={ChangeType.Added} />
          ))}
        </ChangeGroupBox>
      )}
      {!isEmpty(removes) && (
        <ChangeGroupBox changeType={ChangeType.Deleted}>
          {removes.map((x) => (
            <ChangeRow lineThroughLabel before={x.value} label={x.key} showAlways changeType={ChangeType.Deleted} />
          ))}
        </ChangeGroupBox>
      )}
    </>
  );
}
