import { Alert, Icon, Link } from '@stigg-components';
import { t } from 'i18next';
import { ExperimentStatus } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../../../navigation/useNavigation';

type Severity = 'info' | 'warning';

function computeValues(status: ExperimentStatus) {
  switch (status) {
    case ExperimentStatus.InProgress:
      return {
        text: t('subscriptions.partOfRunningExperiment'),
        severity: 'info' as Severity,
      };
    default:
      return {
        text: t('subscriptions.partOfEndedExperiment'),
        severity: 'warning' as Severity,
        sx: { color: 'inherit', textDecoration: 'underline' },
      };
  }
}

export function SubscriptionExperimentNote({ status, refId }: { status: ExperimentStatus; refId: string }) {
  const navigation = useNavigation();
  const { text, severity, sx } = computeValues(status);

  return (
    <Alert severity={severity} icon={<Icon icon="ScienceOutlined" type="materialIcons" />} sx={{ width: '100%' }}>
      {text}{' '}
      <Link sx={sx} onClick={() => navigation.navigateTo(navigation.appRoutes.experimentPage(refId))}>
        {t('experiments.viewExperiment')}
      </Link>
    </Alert>
  );
}
