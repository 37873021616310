import { useState } from 'react';
import { t } from 'i18next';
import { formatFeatureUnits } from '@stigg-common';
import { Box, Text } from '@stigg-components';
import { PriceFragment } from '@stigg-types/apiTypes';
import { AutocompleteSearch } from '../../../../../../packages/plans/components/AutoCompleteSearch';

type TierSelectionProps = {
  charge: PriceFragment;
  onSelection: (quantity: number) => void;
  selectedTier?: number | null;
};

export function TierSelection({ charge, onSelection, selectedTier }: TierSelectionProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchKeyUp = (e: any) => {
    setSearchTerm(e?.target?.value || '');
  };

  const filteredTiers =
    charge.tiers?.filter((tier) =>
      searchTerm ? formatFeatureUnits(charge.feature!, tier.upTo).includes(searchTerm) : true,
    ) || [];

  const selectedPriceTier = selectedTier ? charge.tiers?.find((tier) => tier.upTo === selectedTier) : undefined;

  return (
    <AutocompleteSearch
      resetInputOnChoose={false}
      disableClearable
      dataTestId={`charge-tier-selection-${charge.feature?.refId}`}
      noOptionsText={<Text.B2 color="disabled">{t('sharedComponents.noResultsFound')}</Text.B2>}
      chooseOption={(option) => {
        if (option) {
          const tierNumber = Number(option.value.upTo);
          if (tierNumber) {
            onSelection(tierNumber);
          }
          setSearchTerm('');
        }
      }}
      defaultValue={
        selectedPriceTier
          ? { displayName: formatFeatureUnits(charge.feature!, selectedPriceTier.upTo), value: selectedPriceTier }
          : undefined
      }
      searchOptions={
        filteredTiers.map((tier) => ({
          displayName: formatFeatureUnits(charge.feature!, tier.upTo),
          value: tier,
        })) || []
      }
      onSearchKeyUp={onSearchKeyUp}
      placeholder={t('sharedComponents.selectPlaceholder')}
      isFetching={false}
      getOptionLabel={(option) => `${option.displayName}`}
      renderOption={(props: any, option) => (
        <Box {...props}>
          <Text.B2>{option.displayName}</Text.B2>
        </Box>
      )}
      isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
    />
  );
}
