import { t } from 'i18next';
import { Icon, Alert, Link } from '@stigg-components';

export function OutdatedPackages({
  isActiveSubscription,
  outdatedPackageDescription,
  migrateSubscriptionToLatest,
  hasOutdatedPackages,
}: {
  isActiveSubscription: boolean;
  outdatedPackageDescription: string;
  migrateSubscriptionToLatest: () => void;
  hasOutdatedPackages: boolean;
}) {
  return (
    <Alert
      severity="warning"
      icon={
        hasOutdatedPackages ? (
          <Icon type="materialIcons" icon="Restore" color="warning" />
        ) : (
          <Icon type="custom" icon="OutdatedPrice" color="warning" size={26} />
        )
      }
      sx={{ width: '100%', alignItems: 'center' }}>
      {t('subscriptions.outdatedSubscription', { outdatedPackages: outdatedPackageDescription })}{' '}
      {isActiveSubscription && (
        <Link sx={{ color: 'inherit', textDecoration: 'underline' }} onClick={() => migrateSubscriptionToLatest()}>
          {t('subscriptions.migrateToLatestLink')}
        </Link>
      )}
    </Alert>
  );
}
