import { CouponType } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { generateRandomSlug, refIdValidation } from '@stigg-common';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import {
  ActiveIntegrationIcons,
  Button,
  FormLabel,
  Grid,
  GridFlex,
  RadioGroup,
  RadioItem,
  TextField,
} from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import Loader from '../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createCouponAction } from '../../couponsSlice';
import { getCouponTypes } from './getCouponTypes';

type CreateCouponFormFields = {
  refId: string;
  name: string;
  description: string;
  type: CouponType;
  discountValue: number;
};

const validationSchema = () =>
  Yup.object().shape({
    refId: refIdValidation(),
    name: Yup.string().required(t('coupons.nameReqValidation')),
    description: Yup.string(),
    discountValue: Yup.number()
      .required(t('coupons.discountValueReqValidation'))
      .positive(t('coupons.discountPercentageValueMinValidation'))
      .when('type', {
        is: CouponType.Percentage,
        then: Yup.number().max(100, t('coupons.discountPercentageValueMaxValidation')),
        otherwise: Yup.number().required(t('coupons.discountValueReqValidation')),
      }),
  });

export default function CreateCouponForm({ onCancel }: { onCancel: () => void }) {
  const { allowFixedAmountDiscount } = useFlags<FeatureFlags>();
  const isLoading = useSelector((root: RootState) => root.couponsReducer.isLoading);
  const initialValues = {
    refId: `coupon-${generateRandomSlug()}`,
    name: '',
    type: CouponType.Percentage,
  } as CreateCouponFormFields;

  const dispatch = useAppDispatch();
  const handleSubmit = async (values: CreateCouponFormFields) => {
    await dispatch(createCouponAction(values));
    onCancel();
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, isValid, values, setFieldValue, touched, handleSubmit, handleChange, handleBlur }) => (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column gap={3}>
                <TextField
                  autoFocus
                  name="name"
                  label={t('coupons.nameForm')}
                  value={values.name}
                  touched={Boolean(touched.name)}
                  error={Boolean(errors.name)}
                  fullWidth
                  errorText={errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  captionText={t('coupons.createCouponHelperText')}
                />
                <TextField
                  maxRows={4}
                  name="description"
                  label={t('coupons.descriptionForm')}
                  optional
                  value={values.description}
                  touched={Boolean(touched.description)}
                  error={Boolean(errors.description)}
                  fullWidth
                  errorText={errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  name="refId"
                  label={t('coupons.refIdForm')}
                  value={values.refId}
                  touched={Boolean(touched.refId)}
                  error={Boolean(errors.refId)}
                  fullWidth
                  errorText={errors.refId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {allowFixedAmountDiscount ? (
                  <RadioGroup
                    value={values.type}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('type', (event.target as HTMLInputElement).value as CouponType)
                    }>
                    <FormLabel id="coupon-type-radio" sx={{ fontSize: '14px', mb: 1 }}>
                      {t('coupons.discountType.label')}
                    </FormLabel>
                    <GridFlex.Row gap={2}>
                      {getCouponTypes().map((type) => (
                        <RadioItem
                          sx={{ p: 1 }}
                          key={type.value}
                          value={type.value}
                          label={type.label}
                          selected={values.type === type.value}
                        />
                      ))}
                    </GridFlex.Row>
                  </RadioGroup>
                ) : null}
                <TextField
                  name="discountValue"
                  label={
                    values.type === CouponType.Percentage
                      ? t('coupons.discountLabel.percentage')
                      : t('coupons.discountLabel.fixed')
                  }
                  value={values.discountValue}
                  touched={Boolean(touched.discountValue)}
                  error={Boolean(errors.discountValue)}
                  errorText={errors.discountValue}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="0"
                  endAdornment={values.type === CouponType.Percentage ? '%' : 'USD'}
                  fullWidth={false}
                  type="number"
                />
                <Grid item container mt={4} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ActiveIntegrationIcons />
                  </Grid>
                  <Grid item>
                    <Button sx={{ mr: 3 }} $outlined color="primary" onClick={onCancel}>
                      {t('coupons.cancelButton')}
                    </Button>
                    <Button disabled={!isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.createSaveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </GridFlex.Column>
            </form>
          )}
        </>
      )}
    </Formik>
  );
}
