import { AddonFragment, PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { TooltipFields } from '../../../components/InformationTooltip';
import {
  CantEditPricingTooltipContent,
  CustomEntitlementsDisabledTooltip,
  isDefaultStartPlan,
  isDowngradePlan,
} from './components/PlanTooltipsCollection';
import { isPackagePlan } from './packageUtils';

export function computeDisabledPricingTypes(aPackage: PlanFragment | AddonFragment): Map<PricingType, TooltipFields> {
  const disabledPricingTypes = new Map<PricingType, TooltipFields>();

  if (aPackage.pricingType === PricingType.Free) {
    if (isDowngradePlan(aPackage) || isDefaultStartPlan(aPackage)) {
      disabledPricingTypes.set(PricingType.Paid, {
        placement: 'top',
        title: (
          <CantEditPricingTooltipContent
            productRefId={(aPackage as PlanFragment).product.refId}
            i18key="pricing.pricingTypeDisabled.paidDisabledLinkToCustomerJourney"
          />
        ),
      });
      disabledPricingTypes.set(PricingType.Custom, {
        placement: 'top',
        title: (
          <CantEditPricingTooltipContent
            productRefId={(aPackage as PlanFragment).product.refId}
            i18key="pricing.pricingTypeDisabled.customPriceDisabledLinkToCustomerJourney"
          />
        ),
      });
    }
  } else if (isDefaultStartPlan(aPackage)) {
    disabledPricingTypes.set(PricingType.Free, {
      placement: 'left',
      title: (
        <CantEditPricingTooltipContent
          productRefId={(aPackage as PlanFragment).product.refId}
          i18key="pricing.pricingTypeDisabled.freeDisabledLinkToCustomerJourney"
        />
      ),
    });
  }

  const hasCustomEntitlements = isPackagePlan(aPackage) && aPackage.entitlements?.some(({ isCustom }) => isCustom);

  if (hasCustomEntitlements) {
    [PricingType.Free, PricingType.Paid].forEach((pricingType) => {
      disabledPricingTypes.set(pricingType, {
        placement: 'top',
        title: <CustomEntitlementsDisabledTooltip />,
      });
    });
  }

  return disabledPricingTypes;
}
