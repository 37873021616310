import React, { useEffect, useState } from 'react';
import { styled } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';
import { useQueryParam } from '@stigg-common';
import isFunction from 'lodash/isFunction';
import { Text, Tab, GridFlex, Box, TabPanel, TabList, TabContext } from '..';

type TabIcon = React.ReactNode | ((isSelected: boolean) => React.ReactNode);

export type TabProps = {
  title: string;
  url?: string;
  content: JSX.Element;
  startIcon?: TabIcon;
  endIcon?: TabIcon;
  dataTestId?: string;
  hidden?: boolean;
};

interface TabsComponentProps {
  data: TabProps[];
  withoutQueryParams?: boolean;
  queryParamName?: string;
  disableAll?: boolean;
}

const transformTabs = (tabs: TabProps[]) => {
  const filteredHidden = tabs.filter((tab) => !tab.hidden);
  const withUrlParam = filteredHidden.map((tab) => ({
    ...tab,
    url: tab.url?.toLowerCase() || tab.title.toLowerCase(),
  }));
  return withUrlParam;
};

const TabsWrapper = styled(Box)`
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.itamar.palette.primary.main};
  }
`;

const Tabs = ({ data, withoutQueryParams, queryParamName = 'tab', disableAll }: TabsComponentProps) => {
  const theme = useTheme();
  const tabs = transformTabs(data);
  const tabUrls = tabs.map((tab) => tab.url);
  const { setQueryParam, value: tabQueryParam } = useQueryParam(queryParamName);

  const initialTab = () => {
    if (withoutQueryParams) {
      return tabs[0].title.toLowerCase();
    }
    if (tabQueryParam && tabUrls.includes(tabQueryParam.toLowerCase())) {
      return tabQueryParam.toLowerCase();
    }
    return tabs[0].title.toLowerCase();
  };

  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
    if (withoutQueryParams) return;
    const tab = tabs.find((tab) => tab.title.toLowerCase() === newValue);
    if (tab) {
      setQueryParam(tab?.url);
    }
  };

  useEffect(() => {
    if (withoutQueryParams) return;
    if (!tabQueryParam) {
      setCurrentTab(tabs[0].title.toLowerCase());
      return;
    }
    const tab = tabs.find((tab) => tab.url === tabQueryParam);
    if (tab) {
      setCurrentTab(tab.title.toLowerCase());
    }
  }, [currentTab, withoutQueryParams, tabQueryParam, tabUrls, tabs]);

  const getIcon = (tabIcon: TabIcon, tabTitle: string) => {
    return isFunction(tabIcon) ? tabIcon(currentTab === tabTitle) : tabIcon;
  };

  const getColor = (tab: TabProps, currentTab: string) => {
    if (disableAll) {
      return 'disabled';
    }
    return currentTab === tab.title ? 'primary.main' : 'secondary';
  };

  return (
    <TabContext value={currentTab}>
      <GridFlex.Column $fullWidth>
        <TabsWrapper sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: disableAll ? { backgroundColor: theme.itamar.palette.other.outlineBorder } : {},
            }}>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={
                  <GridFlex.RowCenter data-testid={tab.dataTestId}>
                    {getIcon(tab.startIcon, tab.title)}
                    <Text.Sub2 ml={tab.startIcon ? 2 : 0} mr={tab.endIcon ? 2 : 0} color={getColor(tab, currentTab)}>
                      {tab.title}
                    </Text.Sub2>
                    {getIcon(tab.endIcon, tab.title)}
                  </GridFlex.RowCenter>
                }
                value={tab.title.toLowerCase()}
                disabled={disableAll}
              />
            ))}
          </TabList>
        </TabsWrapper>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tab.title.toLowerCase()} sx={{ padding: '0px', paddingTop: '16px' }}>
            {tab.content}
          </TabPanel>
        ))}
      </GridFlex.Column>
    </TabContext>
  );
};
export default Tabs;
