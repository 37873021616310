// eslint-disable-next-line no-restricted-imports
import { Breadcrumbs, Typography, MenuItem, Text, Icon, GridFlex, Divider, Select } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Breadcrumb } from '../../modules/common';
import { useNavigation } from '../../modules/navigation/useNavigation';
import { BreadcrumbLink } from './BreadcrumbLink';

export function PageBreadcrumbs({ links }: { links: Breadcrumb[] }) {
  const { navigateTo } = useNavigation();
  const [selectCurrentValue, setSelectCurrentValue] = useState('');
  const theme = useTheme();

  return (
    <Breadcrumbs
      color={theme.itamar.palette.text.primary}
      ml={theme.breakpoints.up('md') ? 7 : 2}
      key={`breadCrumb${links[0].title}`}
      aria-label="Breadcrumb">
      {links.map((link, index) => {
        if (link.to) {
          return (
            <BreadcrumbLink key={index} title={link.title} to={link.to}>
              {link.title}
            </BreadcrumbLink>
          );
        }

        if (link.menuItems) {
          const { menuItems, onAddEntityClick, onAddEntityText } = link;

          return (
            <Select
              variant="inline"
              value={selectCurrentValue || link.title}
              renderValue={(value: any) => {
                return <Text.B2>{value as string}</Text.B2>;
              }}>
              {menuItems.map(({ to, displayName }, index) => (
                <MenuItem
                  key={index}
                  value={displayName}
                  onClick={() => {
                    navigateTo(to);
                    setSelectCurrentValue(displayName);
                  }}>
                  <Text.B2 color="primary">{displayName}</Text.B2>
                </MenuItem>
              ))}

              <Divider style={{ margin: 0 }} />

              <MenuItem value="add-entity" onClick={onAddEntityClick}>
                <GridFlex.RowCenter container>
                  <Icon size={18} icon="Plus" color="primary.main" type="reactFeather" />
                  <Text.B2 ml={2} color="primary.main">
                    {onAddEntityText}
                  </Text.B2>
                </GridFlex.RowCenter>
              </MenuItem>
            </Select>
          );
        }

        return (
          <Typography
            variant={index === links.length ? 'body2' : 'subtitle2'}
            sx={{ color: (theme) => theme.itamar.palette.text.primary }}
            key={index}>
            {link.title}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}
