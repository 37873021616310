import { checkoutDefaultTypography, TypographyConfiguration } from '../types/typography';

export type CheckoutWidgetConfiguration = {
  palette: {
    primary: string;
    textColor: string;
    backgroundColor: string;
    borderColor: string;
    summaryBackgroundColor: string;
  };
  typography: TypographyConfiguration;
  css: string;
  content: {
    collectPhoneNumber: boolean;
  };
};

export const defaultCheckoutWidgetConfiguration: CheckoutWidgetConfiguration = {
  palette: {
    primary: '#327EEE',
    textColor: '#001E6C',
    backgroundColor: '#FFFFFF',
    borderColor: '#EBEDF3',
    summaryBackgroundColor: '#F8F9FB',
  },
  typography: checkoutDefaultTypography,
  css: '',
  content: {
    collectPhoneNumber: false,
  },
};
