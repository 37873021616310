import { createSlice } from '@reduxjs/toolkit';
import { CouponConnection, CouponFragment, FetchCouponSyncStatesQuery } from '@stigg-types/apiTypes';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { updateItemById } from '@stigg-common';
import { fetchCoupons } from './queries/fetchCoupons';
import { createCoupon } from './mutations/createCoupon';
import { fetchCouponByRefId } from './queries/fetchCouponByRefId';
import { updateCoupon } from './mutations/updateCoupon';
import { archiveCoupon } from './mutations/archiveCoupon';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

type CouponSyncState = FetchCouponSyncStatesQuery['coupons']['edges'][number]['node'];

export interface CouponsState {
  isLoading: boolean;
  isSearching: boolean;
  coupons: CouponConnection;
  searchedCoupons: CouponConnection;
  coupon: CouponFragment | null;
}

const initialState: CouponsState = {
  coupons: {} as CouponConnection,
  searchedCoupons: {} as CouponConnection,
  isLoading: false,
  isSearching: false,
  coupon: null,
};

const fetchCouponsAction = createAppAsyncThunk('fetchCoupons', fetchCoupons);
const searchCouponsAction = createAppAsyncThunk('searchCoupons', fetchCoupons);
const fetchCouponByRefIdAction = createAppAsyncThunk('fetchCouponByRefId', fetchCouponByRefId);
const createCouponAction = createAppAsyncThunk('createCoupon', createCoupon);
const updateCouponAction = createAppAsyncThunk('updateCoupon', updateCoupon);
const archiveCouponAction = createAppAsyncThunk('archiveCoupon', archiveCoupon);

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    resetCoupon: (state) => {
      state.coupon = null;
    },
    updateCouponSyncState: (state, action: { payload: CouponSyncState }) => {
      state.coupon = merge(state.coupon, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCouponsAction.pending, (state, { meta }) => {
        if (!isEmpty(meta.arg.search)) {
          state.coupons = {} as CouponConnection;
        }
        if (isEmpty(state.coupons.edges)) {
          state.isLoading = true;
        }
      })
      .addCase(fetchCouponsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.coupons = action.payload as CouponConnection;
      })
      .addCase(fetchCouponsAction.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(searchCouponsAction.pending, (state) => {
      state.isSearching = true;
    });
    builder.addCase(searchCouponsAction.fulfilled, (state, action) => {
      state.isSearching = false;
      state.searchedCoupons = action.payload as CouponConnection;
    });
    builder.addCase(searchCouponsAction.rejected, (state) => {
      state.isSearching = false;
    });
    builder.addCase(fetchCouponByRefIdAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCouponByRefIdAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.coupon = action.payload;
    });
    builder.addCase(fetchCouponByRefIdAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCouponAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCouponAction.fulfilled, (state, { payload }) => {
      updateItemById(state.coupons, payload);
      state.isLoading = false;
    });
    builder.addCase(updateCouponAction.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(archiveCouponAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(archiveCouponAction.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(archiveCouponAction.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const { resetCoupon, updateCouponSyncState } = couponsSlice.actions;
export {
  fetchCouponsAction,
  createCouponAction,
  fetchCouponByRefIdAction,
  resetCoupon,
  updateCouponAction,
  archiveCouponAction,
  searchCouponsAction,
  updateCouponSyncState,
};

export default couponsSlice.reducer;
