import { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';
import { LoadingButton as MuiLoadingButton, LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { spacing } from '@mui/system';
import { Save } from 'react-feather';
import { ButtonColor } from '@stigg-theme';
import { buttonStyle } from './Buttons.style';

// due the face color is a props coming from MuiLoadingButton when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiLoadingButton['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it :)

type StyledLoadingButtonType = {
  $typesafeColor?: ButtonColor; // look at the first comment
  color?: ButtonColor;
  $outlined?: boolean;
  $disabled?: boolean;
};

type LoadingButtonProps = Omit<MuiLoadingButtonProps, 'color'> &
  Omit<StyledLoadingButtonType, '$typesafeColor' | '$disabled'>;

const SpacedLoadingButton = styled(MuiLoadingButton)(spacing);
const StyledLoadingButton = styled(SpacedLoadingButton)<StyledLoadingButtonType>`
  ${({ $typesafeColor, $outlined, $disabled }) => buttonStyle($typesafeColor, $outlined, $disabled)}
`;

export const LoadingButton = forwardRef<HTMLButtonElement, PropsWithChildren<LoadingButtonProps>>(
  ({ color, disabled, loading, startIcon = loading ? <Save /> : null, loadingPosition, ...props }, ref) => (
    <StyledLoadingButton
      $typesafeColor={color}
      $disabled={disabled}
      disabled={disabled}
      startIcon={startIcon}
      loadingPosition={startIcon ? loadingPosition : undefined}
      loading={loading}
      {...props}
      ref={ref}
    />
  ),
);
