import { gql } from '@apollo/client';

export const MINIMUM_SPEND_FRAGMENT = gql`
  fragment MinimumSpendFragment on MinimumSpend {
    billingPeriod
    minimum {
      amount
      currency
    }
  }
`;
