import {
  BillingAnchor,
  BillingPeriod,
  CustomerSubscriptionDataFragment,
  SubscriptionDataFragment,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment';

function numberOfCompletePeriodCycles(date: Moment, period: moment.unitOfTime.Diff) {
  const now = moment.utc();
  const startDate = moment.utc(date);
  return now.diff(startDate, period);
}

export const calcEndOfBillingCycle = ({
  subscriptionStartDate,
  billingPeriod,
  subscriptionBillingAnchor,
  currentSubscriptionBillingAnchor,
}: {
  subscriptionStartDate: Date;
  billingPeriod: BillingPeriod;
  subscriptionBillingAnchor?: BillingAnchor | null;
  currentSubscriptionBillingAnchor?: Date;
}): Date => {
  let startDate = moment.utc(subscriptionStartDate);
  if (subscriptionBillingAnchor === BillingAnchor.StartOfTheMonth && billingPeriod === BillingPeriod.Monthly) {
    return moment.utc().add(1, 'month').startOf('month').toDate();
  }

  if (subscriptionBillingAnchor === BillingAnchor.SubscriptionsConsolidateBilling && currentSubscriptionBillingAnchor) {
    startDate = moment.utc(currentSubscriptionBillingAnchor);
  }

  const cycleUnits: moment.unitOfTime.Diff = billingPeriod === BillingPeriod.Monthly ? 'months' : 'years';
  const periodCycles = numberOfCompletePeriodCycles(startDate, cycleUnits);
  startDate.add(periodCycles + 1, cycleUnits);
  return startDate.toDate();
};

// todo: this is definitely should be calculated by the backend
export const calcSubscriptionEndOfBillingCycle = (
  subscription: CustomerSubscriptionDataFragment | SubscriptionDataFragment | null | undefined,
  subscriptionBillingAnchor?: BillingAnchor | null,
): Date => {
  const isTrial = subscription?.status === SubscriptionStatus.InTrial;
  if (isTrial) {
    return subscription.trialEndDate || new Date();
  }

  if (subscription?.currentBillingPeriodEnd) {
    return subscription.currentBillingPeriodEnd;
  }

  const billingPeriod =
    subscription?.prices && !isEmpty(subscription?.prices) ? subscription.prices[0].price?.billingPeriod : null;
  if (!billingPeriod) {
    return subscription?.endDate || new Date();
  }
  return calcEndOfBillingCycle({
    subscriptionStartDate: subscription?.startDate,
    billingPeriod,
    subscriptionBillingAnchor,
    currentSubscriptionBillingAnchor: subscription?.billingCycleAnchor,
  });
};
