import { t } from 'i18next';
import { Grid, GridFlex, Icon, Text, InformationTooltip } from '@stigg-components';
import { StatusChip } from '@stigg-common';
import { styled } from '@stigg-theme';
import { AwsProduct } from '../../../../types/apiTypes';

const AWSProductContainer = styled(Grid)`
  flex-direction: row;
  wrap: nowrap;
  gap: ${({ theme }) => theme.spacing(4)};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: ${({ theme }) => theme.spacing(4)};
  transition: background-color 0.1s ease-in;

  :hover {
    background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground2};
    cursor: pointer;
  }
`;

const AWSProductLogo = styled(Grid)<{ $logoUrl?: string }>`
  height: 64px;
  width: 64px;
  min-width: 64px;
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  background-image: url(${({ $logoUrl }) => $logoUrl});
  background-size: contain;
`;

type AWSProductProps = {
  awsProduct: AwsProduct;
  onClick: () => void;
};

export function AWSProduct({ awsProduct, onClick }: AWSProductProps) {
  const { logoUrl, title, description, visibility, stiggProductId } = awsProduct;
  const isLimited = visibility === 'Limited';

  return (
    <AWSProductContainer container onClick={onClick}>
      <AWSProductLogo $logoUrl={logoUrl || ''} />
      <Grid container flex="2" alignContent="flex-start" gap={4}>
        <GridFlex.RowSpaceBetween width="100%" alignItems="baseline" height="fit-content">
          <Text.B1 color="primary" $bold>
            {title}
          </Text.B1>

          <Grid gap={2}>
            {stiggProductId ? (
              <InformationTooltip
                style={{ cursor: 'pointer' }}
                arrow
                placement="top"
                $padding={2}
                $maxWidth={300}
                title={<Text.B2 color="primary">{t('integrations.awsMarketplace.import.syncedTooltip')}</Text.B2>}>
                <StatusChip
                  variant="outlined"
                  color="primary.outlinedRestingBackground"
                  textColor="primary.main"
                  sx={{ padding: 2 }}
                  icon={<Icon style={{ display: 'flex' }} icon="Synchronize" size={18} />}
                  label={<Text.B2 color="primary.main">{t('integrations.awsMarketplace.import.synced')}</Text.B2>}
                />
              </InformationTooltip>
            ) : null}
            <StatusChip
              color={isLimited ? 'primary.outlinedRestingBackground' : 'primary.outlinedHoverBackground'}
              textColor={isLimited ? 'white' : 'info.main'}
              label={visibility}
            />
          </Grid>
        </GridFlex.RowSpaceBetween>
        <Text.B2>{description}</Text.B2>
      </Grid>
    </AWSProductContainer>
  );
}
