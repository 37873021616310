import { Text, Tooltip, TooltipProps, Grid } from '@stigg-components';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { t } from 'i18next';
import { TextColor } from '@stigg-theme';

const TOOLTIP_RESET_DELAY = 1500;

export function ClipboardText({
  text,
  copy,
  tooltipText = t('sharedComponents.copy'),
  tooltipTextOnCopy = t('sharedComponents.copied'),
  tooltipPlacement = 'right',
  textColor = 'secondary',
}: {
  text?: string;
  copy?: string;
  tooltipText?: string;
  tooltipTextOnCopy?: string;
  tooltipPlacement?: TooltipProps['placement'];
  textColor?: TextColor;
}) {
  const [textCopied, setTextCopied] = useState(false);
  const tooltipTitle = textCopied ? tooltipTextOnCopy : tooltipText;

  useEffect(() => {
    if (textCopied) {
      setTimeout(() => {
        setTextCopied(false);
      }, TOOLTIP_RESET_DELAY);
    }
  }, [textCopied]);

  return (
    <CopyToClipboard text={copy || ''} onCopy={() => setTextCopied(true)}>
      <Tooltip arrow onClose={() => setTextCopied(false)} placement={tooltipPlacement} title={tooltipTitle || ''}>
        <Grid>
          <Text.B2 sx={{ cursor: 'pointer' }} color={textColor}>
            {text}
          </Text.B2>
        </Grid>
      </Tooltip>
    </CopyToClipboard>
  );
}
