import { useMemo } from 'react';
import {
  Button,
  IconButton,
  CustomDrawer,
  drawFormFields,
  Field,
  GridFlex,
  Text,
  Icon,
  DialogActionsButtons,
} from '@stigg-components';

import { EventLogType } from '@stigg-types/apiTypes';
import { generateRandomSlug } from '@stigg-common';
import styled from 'styled-components';
import { t } from 'i18next';
import * as Yup from 'yup';
import { FieldArray, useFormik, FormikProvider } from 'formik';
import { formatEventLogTypeName } from '../../hookUtils';

export const CardContent = styled(GridFlex.Row)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
`;

type UsageThresholdExceededConfigurationProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (thresholds: number[]) => Promise<void>;
  usageThresholds: number[];
};

type ThresholdStateType = {
  value: number | null;
};

type ThresholdState = {
  thresholds: ThresholdStateType[];
};

const validationSchema = () => {
  return Yup.object().shape({
    thresholds: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number().required('Required'),
        }),
      )
      .required('Required')
      .min(1, 'Minimum value is 1'),
  });
};

export function UsageThresholdExceededConfiguration({
  isOpen,
  onClose,
  usageThresholds,
  onSubmit,
}: UsageThresholdExceededConfigurationProps) {
  const initialState = useMemo(() => {
    return {
      thresholds: usageThresholds?.map((value) => ({ id: generateRandomSlug(), value })) || [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageThresholds, isOpen]);

  const formRenderProps = useFormik<ThresholdState>({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: validationSchema(),
    onSubmit: async (values) => {
      await onSubmit(values.thresholds?.map((threshold) => threshold.value!));
      onClose();
    },
  });

  const { values, dirty, isValid } = formRenderProps;

  return (
    <FormikProvider value={formRenderProps}>
      <CustomDrawer
        title={formatEventLogTypeName(EventLogType.EntitlementUsageExceeded)}
        pretitle={t('hooks.entitlementUsageExceededConfiguration.pretitle')}
        description={t('hooks.eventsDescriptions.ENTITLEMENT_USAGE_EXCEEDED')}
        isOpen={isOpen}
        onClose={onClose}>
        <GridFlex.Column>
          <CardContent mt={6} container alignItems="flex-start">
            <GridFlex.Column item mb={4}>
              <Text.B2>{t('hooks.entitlementUsageExceededConfiguration.content')}</Text.B2>
            </GridFlex.Column>

            <GridFlex.Column container gap={2}>
              <FieldArray name="thresholds">
                {({ push, remove }) => {
                  const handleKeyDown = (event: any) => {
                    const { value } = event.target;
                    if (event.key === t('accounts.enter') && value) {
                      push({ value: null });
                    }
                  };
                  const fields: Field<ThresholdState>[] = [
                    ...values.thresholds?.map((_, index): Field<ThresholdState> => {
                      return {
                        type: 'layout',
                        fields: [
                          {
                            type: 'text',
                            gridProps: { flex: 'none !important' },
                            id: `thresholds[${index}].value`,
                            textFieldProps: {
                              InputProps: { inputProps: { min: 1, onKeyDown: handleKeyDown }, autoFocus: true },
                            },
                            hideErrorText: true,
                            isNumberWithoutFraction: true,
                            textFieldType: 'number',
                            endAdornment: '%',
                          },
                          {
                            type: 'custom',
                            hide: (values) => values.thresholds.length === 1,
                            gridProps: {
                              flex: 'none !important',
                            },
                            //   hide: false,
                            render: () => {
                              return (
                                <IconButton key="remove-button" onClick={() => remove(index)}>
                                  <Icon icon="X" color="secondary" />
                                </IconButton>
                              );
                            },
                          },
                        ],
                      };
                    }),
                    {
                      type: 'custom',
                      label: 'Add Threshold',
                      render: () => {
                        return (
                          <Button key="add-button" onClick={() => push({ value: null })}>
                            <GridFlex.RowMiddle gap={1}>
                              <Icon icon="Add" color="primary.main" />
                              <Text.B2 color="primary.main">
                                {t('hooks.entitlementUsageExceededConfiguration.addThreshold')}
                              </Text.B2>
                            </GridFlex.RowMiddle>
                          </Button>
                        );
                      },
                    },
                  ];

                  return drawFormFields(fields, formRenderProps);
                }}
              </FieldArray>
            </GridFlex.Column>
          </CardContent>

          <GridFlex.Column
            item
            sx={{
              borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
              paddingTop: 6,
              marginTop: 8,
            }}>
            <DialogActionsButtons
              saveDisabled={!dirty || !isValid}
              saveText={t('sharedComponents.save')}
              onCancel={onClose}
              onSave={() => formRenderProps.submitForm()}
              saveButtonColor="primary"
              cancelText={t('sharedComponents.cancel')}
            />
          </GridFlex.Column>
        </GridFlex.Column>
      </CustomDrawer>
    </FormikProvider>
  );
}
