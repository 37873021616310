import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  PackagePublishInput,
  PublishPlanMutation,
  PublishPlanMutationVariables,
  PublishPackageResult,
  ProductSettingsInput,
  UpdateOneProductMutation,
  UpdateOneProductMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { fetchPlanByRefIdAction } from '../plansSlice';
import { UPDATE_PRODUCT } from '../../../products/mutations/updateProduct';
import { fetchProductsAction } from '../../../products/productsSlice';

const PUBLISH_PLAN = gql`
  mutation PublishPlan($input: PackagePublishInput!) {
    publishPlan(input: $input) {
      taskId
    }
  }
`;

async function publishPlan(
  props: PackagePublishInput & {
    refId: string;
    productSettingsToUpdate?: ProductSettingsInput & { productId: string };
  },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<PublishPackageResult | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const { refId, productSettingsToUpdate, ...restInput } = props;
      const response = await apolloClient.mutate<PublishPlanMutation, PublishPlanMutationVariables>({
        mutation: PUBLISH_PLAN,
        variables: {
          input: restInput,
        },
      });

      if (productSettingsToUpdate) {
        const { productId, ...productSettings } = productSettingsToUpdate;
        await apolloClient.mutate<UpdateOneProductMutation, UpdateOneProductMutationVariables>({
          mutation: UPDATE_PRODUCT,
          variables: {
            input: {
              id: productId,
              update: {
                productSettings,
              },
            },
          },
        });
        await dispatch(fetchProductsAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch: true }));
      }

      await dispatch(fetchPlanByRefIdAction({ refId }));
      return response.data?.publishPlan;
    },
    {
      successMessage: t('plans.api.successPublish'),
      failureMessageHandler: () => t('plans.api.failPublish'),
      autoHideDuration: 2000,
    },
    dispatch,
  );
}

export { publishPlan };
