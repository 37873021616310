// eslint-disable-next-line no-restricted-imports
import { FormLabel } from '@mui/material';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import { Text } from '../Typography';
import { GridFlex } from '../Grids';
import { HelpTooltip } from '../HelpTooltip';

const Optional = styled(Text.Caption)`
  padding: 0 ${({ theme }) => theme.spacing(1)};
  background: ${({ theme }) =>
    theme.isLightTheme
      ? theme.itamar.palette.action.disabledBackground
      : theme.itamar.palette.background.darkBackground3};
  border-color: ${({ theme }) => theme.itamar.palette.grey[100]};
  border-radius: 4px;
  height: 16px;
  display: flex;
  align-items: center;
`;

export function OutlinedFormFieldLabel({
  label,
  helpTooltipText,
  helpTooltipMaxWidth = 220,
  optional,
  isInErrorState,
  extraLabelText,
  disabled,
  marginBottom = 1,
}: {
  disabled?: boolean;
  label?: React.ReactNode;
  helpTooltipText?: React.ReactNode;
  helpTooltipMaxWidth?: number;
  optional?: boolean;
  isInErrorState?: boolean;
  extraLabelText?: string;
  marginBottom?: number;
}) {
  if (!label) {
    return null;
  }
  return (
    <FormLabel sx={{ marginBottom }}>
      <GridFlex.RowCenter>
        <Text.B2 className="form-label" color={isInErrorState ? 'error' : disabled ? 'disabled' : 'secondary'}>
          {label}
        </Text.B2>
        {helpTooltipText ? (
          <HelpTooltip $maxWidth={helpTooltipMaxWidth}>
            <Text.B2>{helpTooltipText}</Text.B2>
          </HelpTooltip>
        ) : null}
        {optional ? <Optional ml={1}>{t('sharedComponents.optional')}</Optional> : null}
        {extraLabelText ? <Optional ml={1}>{extraLabelText}</Optional> : null}
      </GridFlex.RowCenter>
    </FormLabel>
  );
}
