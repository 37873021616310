import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FeatureFragment,
  FeatureStatus,
  FetchFeatureByRefIdQuery,
  FetchFeatureByRefIdQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { FEATURE_FRAGMENT } from './featureFragment';

const FETCH_FEATURE = gql`
  query FetchFeatureByRefId($featureFilter: FeatureFilter!) {
    features(filter: $featureFilter) {
      edges {
        node {
          ...FeatureFragment
        }
      }
    }
  }
  ${FEATURE_FRAGMENT}
`;

async function fetchFeatureByRefId(
  refId: string,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<FeatureFragment | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<FetchFeatureByRefIdQuery, FetchFeatureByRefIdQueryVariables>({
        query: FETCH_FEATURE,
        fetchPolicy: 'network-only',
        variables: {
          featureFilter: {
            refId: {
              eq: refId,
            },
            featureStatus: {
              neq: FeatureStatus.Suspended,
            },
            environmentId: { eq: accountReducer.currentEnvironmentId },
          },
        },
      });

      const features = res.data.features.edges;
      const feature: FeatureFragment = { ...features[0].node };
      return feature;
    },
    {
      failureMessageHandler: () => t('features.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchFeatureByRefId };
