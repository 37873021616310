import { gql } from '@apollo/client';
import { t } from 'i18next';
import { FetchPackageEntitlementsQuery, FetchPackageEntitlementsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { PACKAGE_ENTITLEMENT_FRAGMENT } from './packageEntitlementFragment';

const FETCH_PACKAGE_ENTITLEMENTS = gql`
  query fetchPackageEntitlements($paging: CursorPaging, $filter: PackageEntitlementFilter) {
    packageEntitlements(paging: $paging, filter: $filter) {
      ...PackageEntitlementsFragment
    }
  }

  fragment PackageEntitlementsFragment on PackageEntitlementConnection {
    edges {
      node {
        ...PackageEntitlementFragment
      }
      cursor
    }
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }

  ${PACKAGE_ENTITLEMENT_FRAGMENT}
`;

export type FetchEntitlementsByPackageIdProps = {
  packageId: string;
};

async function fetchEntitlementsByPackageId(
  { packageId }: FetchEntitlementsByPackageIdProps,
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<FetchPackageEntitlementsQuery, FetchPackageEntitlementsQueryVariables>({
        query: FETCH_PACKAGE_ENTITLEMENTS,
        fetchPolicy: 'network-only',
        variables: {
          filter: { packageId: { eq: packageId } },
          paging: {
            first: 500,
          },
        },
      });

      return response.data.packageEntitlements;
    },
    {
      failureMessageHandler: () => t('entitlements.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchEntitlementsByPackageId };
