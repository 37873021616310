import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import {
  Alert,
  Icon,
  Box,
  Divider,
  ExternalLink,
  Field,
  Form,
  GridFlex,
  Text,
  WizardAccordionStep,
} from '@stigg-components';
import { BigQueryCredentialsInput } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { DatasetLocation } from './constants';

type UseWizardStepsProps = {
  hasIntegration: boolean;
  isLoading: boolean;
  onAddIntegration: (credentials: BigQueryCredentialsInput) => Promise<void>;
  credentials: BigQueryCredentialsInput;
};

const StyledDivider = styled(Divider)`
  &.MuiDivider-root::before {
    width: 0;
  }

  .MuiDivider-wrapper {
    padding-left: 0;
  }
`;

export const useBigQueryIntegrationWizardSteps = ({
  hasIntegration,
  isLoading,
  onAddIntegration,
  credentials,
}: UseWizardStepsProps): WizardAccordionStep[] => {
  return [
    {
      id: 'setup-gcm-bucket',
      title: t('integrations.bigQuery.steps.gcpBucketSetup.title'),
      content: (
        <GridFlex.Column>
          <Text.B2 mb={6}>{t('integrations.bigQuery.steps.gcpBucketSetup.description')}</Text.B2>

          <Alert
            icon={<Icon type="custom" icon="InstallPackageIcon" />}
            sx={{ backgroundColor: (theme) => theme.itamar.palette.grey[25], width: '100%' }}
            severity="info">
            <Text.B2 gap={1}>
              <ExternalLink
                label={t('integrations.bigQuery.steps.gcpBucketSetup.installText')}
                url={t('integrations.bigQuery.steps.gcpBucketSetup.installationUrl')}
              />
            </Text.B2>
          </Alert>
        </GridFlex.Column>
      ),
      maxWidth: 600,
    },
    {
      id: 'setup-credentials',
      title: t('integrations.bigQuery.steps.credentials.title'),
      content: () => {
        const validationSchema = () =>
          Yup.object().shape({
            credentialsJson: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.credentialsJson.required'),
            ),
            datasetId: Yup.string().required(t('integrations.bigQuery.steps.credentials.form.datasetId.required')),
            datasetLocation: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.datasetLocation.required'),
            ),
            gcsBucketName: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.gcsBucketName.required'),
            ),
            gcsBucketPath: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.gcsBucketPath.required'),
            ),
            hmacKeyAccessId: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.hmacKeyAccessId.required'),
            ),
            hmacKeySecret: Yup.string().required(
              t('integrations.bigQuery.steps.credentials.form.hmacKeySecret.required'),
            ),
            projectId: Yup.string().required(t('integrations.bigQuery.steps.credentials.form.projectId.required')),
          });

        return (
          <Box>
            <Text.B2 marginBottom={4}>{t('integrations.bigQuery.steps.credentials.content')}</Text.B2>

            <Form<BigQueryCredentialsInput>
              initialValues={credentials}
              validationSchema={validationSchema()}
              onSubmit={(values) => onAddIntegration(values)}
              disableSaveOnNoChanges
              submitButtonText={
                isLoading
                  ? t('integrations.bigQuery.steps.credentials.form.connecting')
                  : !hasIntegration
                  ? t('integrations.bigQuery.steps.credentials.form.connect')
                  : ''
              }
              hideSaveButton={hasIntegration}
              fields={(
                [
                  {
                    type: 'custom',
                    render: () => {
                      return (
                        <GridFlex.RowCenter $fullWidth>
                          <StyledDivider textAlign="left" sx={{ width: '100%' }}>
                            <Text.B2 color="secondary">
                              {t('integrations.bigQuery.steps.credentials.gcsSectionTitle')}
                            </Text.B2>
                          </StyledDivider>
                        </GridFlex.RowCenter>
                      );
                    },
                  },
                  {
                    type: 'text',
                    id: 'hmacKeyAccessId',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.hmacKeyAccessId.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.hmacKeyAccessId.label'),
                    textFieldType: 'password',
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: t('integrations.bigQuery.steps.credentials.form.hmacKeyAccessId.tooltip'),
                  },
                  {
                    type: 'text',
                    id: 'hmacKeySecret',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.hmacKeySecret.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.hmacKeySecret.label'),
                    textFieldType: 'password',
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: t('integrations.bigQuery.steps.credentials.form.hmacKeySecret.tooltip'),
                  },
                  {
                    type: 'text',
                    id: 'gcsBucketName',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.gcsBucketName.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.gcsBucketName.label'),
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: t('integrations.bigQuery.steps.credentials.form.gcsBucketName.tooltip'),
                  },
                  {
                    type: 'text',
                    id: 'gcsBucketPath',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.gcsBucketPath.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.gcsBucketPath.label'),
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: t('integrations.bigQuery.steps.credentials.form.gcsBucketPath.tooltip'),
                  },
                  {
                    type: 'custom',
                    render: () => {
                      return (
                        <GridFlex.RowCenter $fullWidth>
                          <StyledDivider textAlign="left" sx={{ width: '100%' }}>
                            <Text.B2 color="secondary">
                              {t('integrations.bigQuery.steps.credentials.bigQuerySectionTitle')}
                            </Text.B2>
                          </StyledDivider>
                        </GridFlex.RowCenter>
                      );
                    },
                  },
                  {
                    type: 'text',
                    id: 'projectId',
                    label: t('integrations.bigQuery.steps.credentials.form.projectId.label'),
                    placeholder: t('integrations.bigQuery.steps.credentials.form.projectId.label'),
                    autoFocus: true,
                    disabled: isLoading || hasIntegration,
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: (
                      <Trans
                        i18nKey="integrations.bigQuery.steps.credentials.form.projectId.tooltip"
                        components={[
                          <ExternalLink url="https://cloud.google.com/resource-manager/docs/creating-managing-projects#identifying_projects" />,
                        ]}
                      />
                    ),
                  },
                  {
                    type: 'select',
                    id: 'datasetLocation',
                    disabled: isLoading || hasIntegration,
                    label: t('integrations.bigQuery.steps.credentials.form.datasetLocation.label'),
                    restProps: {
                      menuMaxHeight: 350,
                      placeholder: t('integrations.bigQuery.steps.credentials.form.datasetLocation.placeholder'),
                    },
                    values: Object.keys(DatasetLocation).map((key) => ({
                      value: DatasetLocation[key],
                      displayValue: DatasetLocation[key],
                    })),
                  },
                  {
                    type: 'text',
                    id: 'datasetId',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.datasetId.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.datasetId.label'),
                    helpTooltipMaxWidth: 300,
                    helpTooltipText: (
                      <Trans
                        i18nKey="integrations.bigQuery.steps.credentials.form.datasetId.tooltip"
                        components={[
                          <ExternalLink url="https://cloud.google.com/bigquery/docs/datasets#create-dataset" />,
                        ]}
                      />
                    ),
                  },
                  {
                    type: 'custom',
                    render: () => {
                      return (
                        <GridFlex.RowCenter $fullWidth>
                          <StyledDivider textAlign="left" sx={{ width: '100%' }}>
                            <Text.B2 color="secondary">
                              {t('integrations.bigQuery.steps.credentials.serviceAccountSectionTitle')}
                            </Text.B2>
                          </StyledDivider>
                        </GridFlex.RowCenter>
                      );
                    },
                  },
                  {
                    type: 'text',
                    id: 'credentialsJson',
                    disabled: isLoading || hasIntegration,
                    placeholder: t('integrations.bigQuery.steps.credentials.form.credentialsJson.label'),
                    label: t('integrations.bigQuery.steps.credentials.form.credentialsJson.label'),
                    textFieldType: 'password',
                  },
                ] as Field<BigQueryCredentialsInput>[]
              ).map((field) => ({
                ...field,
                disabled: isLoading || hasIntegration,
              }))}
            />
          </Box>
        );
      },
    },
  ];
};
