/* eslint-disable no-restricted-imports */
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TextColor, getTextColor } from '@stigg-theme';
// todo: replace with stigg-Typography
import { Typography, TypographyProps } from '@mui/material';
import { InformationTooltip, TooltipProps, Box, Text } from '.';
import { CustomVariants, variantToColor } from './types';

const StyledText = styled(Typography)<{ $maxLines: number; $wordBreak?: boolean; $display: string; $bold?: boolean }>`
  display: ${({ $display }) => $display};
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  ${({ $wordBreak }) =>
    $wordBreak &&
    css`
      word-break: break-word;
    `}

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: bold;
    `}
`;

function checkTextClamped(element: HTMLSpanElement) {
  return element && (element.clientHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
}

export const LongText = ({
  leaveDelay,
  children,
  title,
  variant = 'subtitle2',
  maxLines = 1,
  wordBreak,
  bold,
  tooltipPlacement = 'top',
  color,
  className,
  display = '-webkit-box',
  sx,
  leftMargin = false,
  onClick,
}: {
  children: ReactNode;
  title?: ReactNode;
  leaveDelay?: number;
  variant?: CustomVariants;
  maxLines?: number;
  wordBreak?: boolean;
  bold?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  color?: TextColor;
  className?: string;
  display?: string;
  sx?: TypographyProps['sx'];
  leftMargin?: boolean;
  onClick?: () => void;
}) => {
  const [isTextClamped, setIsTextClamped] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const content = (
    <StyledText
      ref={ref}
      variant={variant}
      $maxLines={maxLines}
      $wordBreak={wordBreak}
      $bold={bold}
      color={getTextColor(color ?? variantToColor(variant).color)}
      className={className}
      $display={display}
      onClick={onClick}
      sx={sx}>
      {children}
    </StyledText>
  );

  // Trigger recalculate of text clamped since ref is not always exists on first render
  useEffect(() => {
    if (ref?.current) {
      const textClamped = checkTextClamped(ref.current);
      setIsTextClamped(textClamped);
    }
  }, [ref]);

  const textClamped = ref?.current && checkTextClamped(ref.current);
  return (
    <Box display="inline" width="100%">
      {isTextClamped || textClamped ? (
        <InformationTooltip
          $leftMargin={leftMargin}
          $padding={2}
          arrow
          placement={tooltipPlacement}
          leaveDelay={leaveDelay}
          title={<Text.B2>{title ?? children}</Text.B2>}>
          {content}
        </InformationTooltip>
      ) : (
        content
      )}
    </Box>
  );
};
