import { t } from 'i18next';
import map from 'lodash/map';
import { SubscriptionDataFragment, SubscriptionMigrationTime } from '@stigg-types/apiTypes';
import { hasOutdatedPackagesUtil } from '@stigg-common';
import { Select, GridFlex, Alert, Text, MenuItem } from '@stigg-components';
import { AlertTriangle } from 'react-feather';

export function MigrateSubscription({
  subscription,
  migrationSchedule,
  setMigrationSchedule,
}: {
  subscription: SubscriptionDataFragment;
  migrationSchedule: SubscriptionMigrationTime;
  setMigrationSchedule: (migrationSchedule: SubscriptionMigrationTime) => void;
}) {
  const { outdatedPackageDescription, hasOutdatedPrice } = hasOutdatedPackagesUtil(subscription);

  return (
    <GridFlex.Column container rowGap={4}>
      <GridFlex.Item>
        <Text.B2>
          {t('subscriptions.migrateToLatestDialogContent', { outdatedPackages: outdatedPackageDescription })}
        </Text.B2>
      </GridFlex.Item>
      <GridFlex.Item>
        <Select
          label={t('subscriptions.migrationSchedule')}
          value={migrationSchedule}
          onChange={(e) => setMigrationSchedule(e.target.value as SubscriptionMigrationTime)}
          renderValue={(migrationSchedule: any) => {
            return (
              <GridFlex.RowCenter>
                <Text.B2 color="primary">{t(`subscriptions.migrationSchedulesValues.${migrationSchedule}`)}</Text.B2>
              </GridFlex.RowCenter>
            );
          }}>
          {map(SubscriptionMigrationTime, (migrationScheduleType) => (
            <MenuItem value={migrationScheduleType} key={migrationScheduleType}>
              <Text.B2 color="primary">{t(`subscriptions.migrationSchedulesValues.${migrationScheduleType}`)}</Text.B2>
            </MenuItem>
          ))}
        </Select>
      </GridFlex.Item>

      {hasOutdatedPrice && (
        <GridFlex.Item>
          <Alert severity="warning" icon={<AlertTriangle />}>
            <Text.B2 color="warning">{t('subscriptions.migrateToLatestDialogWarning')}</Text.B2>
          </Alert>
        </GridFlex.Item>
      )}
    </GridFlex.Column>
  );
}
