import { BillingModel } from '@stigg-types/apiTypes';
import { Icon } from '@stigg-components';
import { DollarSign } from 'react-feather';
import { styled } from '@stigg-theme';

const FlatFeeIcon = styled(DollarSign)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export const getPricingModelIcon = (billingModel: BillingModel) => {
  switch (billingModel) {
    case BillingModel.FlatFee:
      return <FlatFeeIcon />;
    case BillingModel.PerUnit:
    case BillingModel.UsageBased:
      return <Icon type="custom" icon="MeteredHash" color="active" />;
    default:
      return null;
  }
};
