import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { BillingModel, PriceFragment } from '@stigg-types/apiTypes';

const CHARGES_BILLING_MODEL_ORDER = [BillingModel.FlatFee, BillingModel.PerUnit, BillingModel.UsageBased];

export const sortCharges = (charges: PriceFragment[]) => {
  return sortBy(charges, [
    (charge) => CHARGES_BILLING_MODEL_ORDER.indexOf(charge.billingModel),
    (charge) => charge.feature?.displayName,
  ]);
};

export const useChargesSort = (charges: PriceFragment[]) => {
  return useMemo(() => sortCharges(charges), [charges]);
};
