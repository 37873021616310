import { GridFlex, MenuItem, Select, Text } from '@stigg-components';
import { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useAppDispatch } from '../../../../../redux/store';
import { fetchEventsFieldsAction } from '../../../../features/featuresSlice';

export type EntitlementUsageFiltersProps = {
  meterId?: string | null;
  featureId?: string | null;
  customerRefId: string;
  resourceRefId?: string;

  selectedGroup: string | null;
  setSelectedGroup: (group: string | null) => void;
};

export function EntitlementUsageFilters(props: EntitlementUsageFiltersProps) {
  const { meterId, customerRefId, resourceRefId, selectedGroup, setSelectedGroup, featureId } = props;

  const dispatch = useAppDispatch();
  const { usageHistoryGraphMaxGroups } = useFlags<FeatureFlags>();
  const [groupByOptions, setGroupByOptions] = useState<string[]>([]);

  useEffect(() => {
    const load = async () => {
      if (usageHistoryGraphMaxGroups <= 0) {
        return;
      }
      try {
        const { fields } = await dispatch(
          fetchEventsFieldsAction({
            meterId,
            customerId: customerRefId,
            resourceId: resourceRefId,
            uniqueValuesLimit: usageHistoryGraphMaxGroups,
            featureId,
          }),
        ).unwrap();
        const groups = keys(fields).filter((key) => key !== 'eventName');
        setGroupByOptions(groups);
      } catch (err) {
        console.log('Failed to get possible groups of event fields', err);
        setGroupByOptions([]);
      }
    };

    void load();
  }, [meterId, customerRefId, resourceRefId, usageHistoryGraphMaxGroups, featureId, dispatch]);

  if (isEmpty(groupByOptions)) {
    return null;
  }

  return (
    <GridFlex.RowCenter gap={2}>
      <Text.B2 whiteSpace="nowrap">{t('customers.usageGroupBy')}</Text.B2>
      <Select
        value={selectedGroup}
        width={180}
        placeholder={t('customers.usageGroupByNone')}
        renderValue={(value) => <Text.B2>{value}</Text.B2>}
        onChange={(e) => setSelectedGroup(e.target.value as string)}>
        <MenuItem value="">
          <Text.B2>{t('customers.usageGroupByNone')}</Text.B2>
        </MenuItem>
        {groupByOptions.map((value) => (
          <MenuItem key={value} value={value}>
            <Text.B2>{value}</Text.B2>
          </MenuItem>
        ))}
      </Select>
    </GridFlex.RowCenter>
  );
}
