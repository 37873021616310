import React, { useState } from 'react';
import { t } from 'i18next';
import { PublishMigrationType } from '@stigg-types/apiTypes';
import {
  Box,
  Checkbox,
  Collapse,
  DialogActionsButtons,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Text,
} from '@stigg-components';
import { Trans } from 'react-i18next';
import { styled } from '@stigg-theme';

const RadioBox = styled(Box)<{ $isSelected: boolean; $errorColor?: boolean }>`
  border: 3px solid
    ${({ theme, $isSelected, $errorColor }) =>
      $isSelected
        ? $errorColor
          ? theme.itamar.palette.error.main
          : theme.itamar.palette.primary.main
        : theme.itamar.palette.action.disabledBackground};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  ${({ $isSelected, $errorColor, theme }) =>
    $isSelected && $errorColor && `background-color: ${theme.itamar.palette.error.background};`}
`;

interface MigrationTypeFormProps {
  environmentName: string;
  onSubmit: (migrationType: PublishMigrationType) => void;
  isSubmitLoading: boolean;
  onCancel: () => void;
}

export const MigrationTypeForm = ({ environmentName, onSubmit, onCancel, isSubmitLoading }: MigrationTypeFormProps) => {
  const [migrationType, setMigrationType] = useState<PublishMigrationType>(PublishMigrationType.NewCustomers);
  const [childPlansAck, setChildPlansAck] = useState(false);
  const [allSubscriptionsAck, setAllSubscriptionsAck] = useState(false);
  const [trialAck, setTrialAck] = useState(false);
  const [pricingAck, setPricingAck] = useState(false);

  const allImplicationsAcked = childPlansAck && allSubscriptionsAck && trialAck && pricingAck;
  return (
    <Flex.Column alignItems="stretch" gap={6}>
      <Text.B2 color="secondary">
        <Trans
          i18nKey="accounts.copyMergeEnvironments.migrationTypeForm.description"
          values={{
            environmentName,
          }}
          components={{ strong: <Text.B2 display="inline" $bold /> }}
        />
      </Text.B2>
      <RadioGroup
        value={migrationType}
        sx={{ gap: 4 }}
        onChange={(_, value) => {
          setMigrationType(value as PublishMigrationType);
        }}>
        <RadioBox
          $isSelected={migrationType === PublishMigrationType.NewCustomers}
          onClick={() => {
            setMigrationType(PublishMigrationType.NewCustomers);
          }}>
          <Radio
            value={PublishMigrationType.NewCustomers}
            label={<Text.B2>{t('accounts.copyMergeEnvironments.migrationTypeForm.newSubscriptions')}</Text.B2>}
          />
        </RadioBox>
        <RadioBox
          $isSelected={migrationType === PublishMigrationType.AllCustomers}
          onClick={() => {
            setMigrationType(PublishMigrationType.AllCustomers);
          }}
          $errorColor>
          <Flex.Column>
            <Radio
              color="error"
              value={PublishMigrationType.AllCustomers}
              label={<Text.B2>{t('accounts.copyMergeEnvironments.migrationTypeForm.allSubscriptions')}</Text.B2>}
            />
            <Collapse in={migrationType === PublishMigrationType.AllCustomers}>
              <Divider mt={2} />
              <Text.Sub2 my={2} color="error">
                {t('accounts.copyMergeEnvironments.migrationTypeForm.acknowledgeText')}
              </Text.Sub2>
              <Checkbox
                color="error"
                checked={childPlansAck}
                onChange={(_, checked) => {
                  setChildPlansAck(checked);
                }}
                label={
                  <Text.B2 color="secondary">
                    {t('accounts.copyMergeEnvironments.migrationTypeForm.childPlansAck')}
                  </Text.B2>
                }
              />
              <Checkbox
                color="error"
                checked={allSubscriptionsAck}
                onChange={(_, checked) => {
                  setAllSubscriptionsAck(checked);
                }}
                label={
                  <Text.B2 color="secondary">
                    {t('accounts.copyMergeEnvironments.migrationTypeForm.allSubscriptionsAck')}
                  </Text.B2>
                }
              />
              <Checkbox
                color="error"
                checked={trialAck}
                onChange={(_, checked) => {
                  setTrialAck(checked);
                }}
                label={
                  <Text.B2 color="secondary">{t('accounts.copyMergeEnvironments.migrationTypeForm.trialAck')}</Text.B2>
                }
              />
              <Checkbox
                color="error"
                checked={pricingAck}
                onChange={(_, checked) => {
                  setPricingAck(checked);
                }}
                label={
                  <Text.B2 color="secondary">
                    {t('accounts.copyMergeEnvironments.migrationTypeForm.pricingAck')}
                  </Text.B2>
                }
              />
            </Collapse>
          </Flex.Column>
        </RadioBox>
      </RadioGroup>
      <DialogActionsButtons
        saveText={t('accounts.copyMergeEnvironments.migrationTypeForm.saveButton')}
        isSaveLoading={isSubmitLoading}
        saveDisabled={migrationType === PublishMigrationType.AllCustomers && !allImplicationsAcked}
        onCancel={onCancel}
        onSave={() => onSubmit(migrationType)}
        saveButtonColor={migrationType === PublishMigrationType.AllCustomers ? 'error' : 'primary'}
      />
    </Flex.Column>
  );
};
