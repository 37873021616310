import { Box, GridFlex, Text } from '@stigg-components';
import styled from 'styled-components/macro';
import React, { ReactElement, ReactNode } from 'react';

type ContainerVariant = 'transparent' | 'error';

export const StyledContainer = styled(Box)<{ $variant: ContainerVariant }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ $variant, theme }) =>
    $variant === 'error' ? theme.itamar.palette.error.main : theme.itamar.palette.other.outlineBorder};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  ${({ $variant, theme }) => $variant === 'error' && `background-color: ${theme.itamar.palette.error.background}`}
`;

export type SubscriptionBannerContainerProps = {
  title: string;
  icon: ReactElement;
  children: ReactNode;
  buttons?: ReactElement;
  footer?: ReactElement;
  variant?: ContainerVariant;
};

export function SubscriptionBannerContainer({
  title,
  icon,
  children,
  buttons,
  footer,
  variant = 'transparent',
}: SubscriptionBannerContainerProps) {
  return (
    <StyledContainer $variant={variant}>
      <GridFlex.RowCenter $fullWidth gap={3} height={36}>
        {icon}
        <Text.H6 flex={1} color={variant === 'error' ? 'error' : 'primary'}>
          {title}
        </Text.H6>
        {buttons && <Box>{buttons}</Box>}
      </GridFlex.RowCenter>

      <GridFlex.Column mt={2.5} mx={9}>
        <Text.B2>{children}</Text.B2>
      </GridFlex.Column>
      {footer}
    </StyledContainer>
  );
}
