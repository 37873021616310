import React, { useState } from 'react';
import { Send, Trash2, Edit2 } from 'react-feather';
import { styled } from '@stigg-theme';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { t } from 'i18next';
import { Grid, Button, Text, OptionsDropdown, GridFlex, LongText, Icon } from '@stigg-components';
import { EventLogType, HookDataFragment } from '@stigg-types/apiTypes';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Dialog } from '../../../../components/Dialog';
import { PageCard } from '../../../../components/PageCard';
import Table, { HeadCell } from '../../../../components/table/Table';
import { useAppDispatch } from '../../../../redux/store';
import { updateHookAction } from '../../hooksSlice';
import { formatEventLogTypeName } from '../../hookUtils';
import { TestHookEvent } from './TestHookEvent';
import { UsageThresholdExceededConfiguration } from '../hookConfiguration/UsageThresholdExceededConfiguration';
import { WebhookEventLogType } from '../../constants';

type ViewHookHeaderProps = {
  hook: HookDataFragment;
  onEditClick: () => void;
};

type TableEventLogData = {
  eventLogType: EventLogType;
};

const EditIcon = styled(Edit2)`
  margin-right: 4px;
`;

export const webhooksEventsHeadCells = (): Array<HeadCell<TableEventLogData, any>> => [
  {
    width: 300,
    id: 'event',
    alignment: 'left',
    label: t('hooks.eventsTableColumn'),
    render: (data) => <Text.B2>{formatEventLogTypeName(data.eventLogType)}</Text.B2>,
  },
  {
    id: 'description',
    alignment: 'left',
    label: t('hooks.eventDescription'),
    render: (data) => (
      <LongText variant="body2" maxLines={2} color="primary">
        {t(`hooks.eventsDescriptions.${data.eventLogType}`)}
      </LongText>
    ),
  },
];

export const webhooksEventsHeadCellsAdditionalAction = ({
  onOpenConfiguration,
  usageThresholds,
}: {
  onOpenConfiguration: (eventLogType: EventLogType) => void;
  usageThresholds: number[];
}): Array<HeadCell<TableEventLogData, any>> => {
  return [
    {
      id: 'extraAction',
      width: 160,
      alignment: 'center',
      label: '',

      render: ({ eventLogType }) => {
        if (eventLogType !== EventLogType.EntitlementUsageExceeded) {
          return null;
        }

        return (
          <Button onClick={() => onOpenConfiguration(eventLogType)}>
            <GridFlex.RowCenter gap={1}>
              <Icon icon="SettingsOutlined" color="primary.main" />
              <Text.B2 color="primary.main">{usageThresholds?.length || 0} thresholds</Text.B2>
            </GridFlex.RowCenter>
          </Button>
        );
      },
    },
  ];
};

export const webhooksEventsHeadCellsOptions = (
  onRemoveClick: (eventLogType: EventLogType) => void,
  onSendTestClick: (eventLogType: EventLogType) => void,
): Array<HeadCell<TableEventLogData, any>> => [
  {
    id: 'options',
    width: 56,
    alignment: 'right',
    label: '',
    maxWidth: 36 + 8,
    render: (data) => (
      <OptionsDropdown
        options={[
          {
            icon: Send,
            text: t('hooks.sendTest'),
            onClick: () => {
              onSendTestClick(data.eventLogType);
            },
          },
          {
            icon: Trash2,
            text: t('hooks.removeEvent'),
            onClick: () => {
              onRemoveClick(data.eventLogType);
            },
          },
        ]}
      />
    ),
  },
];
const HookEventsSection: React.FC<ViewHookHeaderProps> = ({ hook, onEditClick }) => {
  const dispatch = useAppDispatch();
  const [configureUsageThresholdDrawerIsOpen, setConfigureUsageThresholdDrawerIsOpen] = useState(false);
  const [eventDeletionDialogIsOpen, setEventDeletionDialogIsOpen] = useState(false);
  const [eventToRemove, setEventToRemove] = useState<EventLogType | null>(null);
  const [testEventType, setTestEventType] = useState<EventLogType | null>(null);
  const [isTestEventDialogOpen, setIsTestEventDialogOpen] = useState(false);

  const usageThresholds = hook.configuration?.[WebhookEventLogType.ENTITLEMENT_USAGE_EXCEEDED]?.thresholds;
  const sortedEvents = Object.values(hook.eventLogTypes)
    .sort()
    .map((event) => ({ eventLogType: event }));

  const onRemoveClick = (event: EventLogType) => {
    setEventToRemove(event);
    setEventDeletionDialogIsOpen(true);
  };

  const handleDialogClose = async (shouldRemove: boolean) => {
    if (shouldRemove) {
      await dispatch(
        updateHookAction({
          hookId: hook.id,
          updatePayload: { eventLogTypes: filter(hook.eventLogTypes, (e) => e !== eventToRemove) },
        }),
      );
    }

    setEventDeletionDialogIsOpen(false);
  };

  const onSendTestClick = (event: EventLogType) => {
    setTestEventType(event);
    setIsTestEventDialogOpen(true);
  };

  return (
    <PageCard>
      <GridFlex.RowSpaceBetweenTop container wrap="nowrap">
        <GridFlex.Column item>
          <Text.H3 mb={2}>{t('hooks.webhookEventsTitle')}</Text.H3>
          <Text.Sub2 mr={3}>{t('hooks.webhookEventsSubTitle')}</Text.Sub2>
        </GridFlex.Column>
        <Grid item>
          <Button color="primary" $outlined onClick={() => onEditClick()}>
            <EditIcon size={16} />
            {t('hooks.edit')}
          </Button>
        </Grid>
      </GridFlex.RowSpaceBetweenTop>
      <Grid container>
        <Table
          headCells={[
            ...webhooksEventsHeadCells(),
            ...webhooksEventsHeadCellsAdditionalAction({
              usageThresholds,
              onOpenConfiguration: () => setConfigureUsageThresholdDrawerIsOpen(true),
            }),
            ...webhooksEventsHeadCellsOptions(onRemoveClick, onSendTestClick),
          ]}
          data={sortedEvents}
          label={t('hooks.eventsTable')}
        />
      </Grid>

      {testEventType && (
        <Dialog
          content={
            <TestHookEvent
              endpointUrl={hook.endpoint}
              eventLogType={testEventType}
              onCancel={() => setIsTestEventDialogOpen(false)}
            />
          }
          titleText={`${t('hooks.sendTestEventTitle', { eventType: formatEventLogTypeName(testEventType) })}`}
          isOpen={isTestEventDialogOpen}
          onCancel={() => setIsTestEventDialogOpen(false)}
          aria-labelledby="test-event-hook-dialog"
          width={730}
        />
      )}

      <ConfirmationDialog
        open={eventDeletionDialogIsOpen}
        handleClose={handleDialogClose}
        title={t('hooks.removeEventDialogTitle')}
        content={t('hooks.removeEventBody')}
        confirmationButtonText={t('hooks.removeEvent')}
      />

      <UsageThresholdExceededConfiguration
        usageThresholds={usageThresholds}
        isOpen={configureUsageThresholdDrawerIsOpen}
        onClose={() => setConfigureUsageThresholdDrawerIsOpen(false)}
        onSubmit={async (thresholds) => {
          await dispatch(
            updateHookAction({
              hookId: hook.id,
              updatePayload: {
                configuration: {
                  [WebhookEventLogType.ENTITLEMENT_USAGE_EXCEEDED]: { thresholds: sortBy(thresholds) },
                },
              },
            }),
          );
        }}
      />
    </PageCard>
  );
};

export default HookEventsSection;
