import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchPlansQuery,
  FetchPlansQueryVariables,
  PackageStatus,
  PlanFilter,
  PlanSortFields,
  SortDirection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { Paging } from '../../../../components/table/gqlTableHelper';
import { AppDispatch } from '../../../../redux/store';
import { applyEnvironmentFilter } from '../../../common/applyEnvironmentFilter';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { PLAN_LIST_FRAGMENT } from './planListFragment';
import { ExecuteOperationOptions } from '../../../common';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../common/pagination';

export const FETCH_SLIM_PLANS = gql`
  query FetchSlimPlans($paging: CursorPaging, $sorting: [PlanSort!], $filter: PlanFilter!) {
    plans(paging: $paging, sorting: $sorting, filter: $filter) {
      edges {
        node {
          refId
          displayName
        }
        cursor
      }
    }
  }
`;

const FETCH_PLANS = gql`
  query FetchPlans($paging: CursorPaging, $sorting: [PlanSort!], $filter: PlanFilter!) {
    plans(paging: $paging, sorting: $sorting, filter: $filter) {
      ...PlansFragment
    }
  }
  fragment PlansFragment on PlanConnection {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...PlanListFragment
      }
      cursor
    }
  }
  ${PLAN_LIST_FRAGMENT}
`;

export type FetchPlansProps = {
  paging?: Paging;
  search?: string | null;
  environmentId: string;
} & ExecuteOperationOptions;

async function fetchPlans(props: FetchPlansProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search, paging } = props;
  if (search === '') {
    search = null;
  }
  if (!paging) {
    paging = { first: DEFAULT_TABLE_PAGE_SIZE };
  }
  return executeOperationSafely(
    async () => {
      const filter: PlanFilter = {
        environmentId: { eq: props.environmentId },
        status: { neq: PackageStatus.Archived },
        isLatest: { is: true },
      };

      if (props.search) {
        filter.or = [
          { displayName: { iLike: `%${props.search}%` } },
          { refId: { iLike: `%${props.search}%` } },
          { description: { iLike: `%${props.search}%` } },
          { billingId: { iLike: `%${props.search}%` } },
        ].map((searchFilter) => applyEnvironmentFilter<PlanFilter>(searchFilter, props.environmentId));
      }

      const response = await apolloClient.query<FetchPlansQuery, FetchPlansQueryVariables>({
        query: FETCH_PLANS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: [
            { field: PlanSortFields.Status, direction: SortDirection.Desc },
            { field: PlanSortFields.DisplayName, direction: SortDirection.Asc },
          ],
          filter,
        },
      });

      return response.data.plans;
    },
    {
      failureMessageHandler: () => t('plans.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchPlans };
