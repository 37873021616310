import { ButtonAndOptionsDropdown, Icon, OptionsDropdown } from '@stigg-components';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import * as React from 'react';
import { Edit2, Trash2 } from 'react-feather';
import { useAppDispatch } from '../redux/store';
import { setArchiveDialog as setPlanArchiveDialogOpen } from '../modules/packages/plans/plansSlice';
import { setArchiveDialog as setAddonArchiveDialog } from '../modules/packages/addons/addonSlice';
import {
  CantDeletePlanTooltipContent,
  isDefaultStartPlan,
  isDowngradePlan,
} from '../modules/packages/common/components/PlanTooltipsCollection';
import { TooltipFields } from './InformationTooltip';
import useNavigateToDraft from '../modules/packages/common/hooks/useEditPackageNaivatge';
import { OptionsDropdownItemType } from './dropdowns/optionsDropdown/OptionsDropdown';
import { ButtonDropdownOptions } from './dropdowns/buttonAndOptionsDropdown/ButtonAndOptionsDropdown';

export const PRIMARY_MENU_ITEM_ID = 'primary-menu-item';

export function PackageEditDropdown({
  aPackage,
  isDraft,
}: {
  aPackage: PlanFragment | AddonFragment; // package is a reserved word so adding a prefix;
  isDraft: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigateToDraft = useNavigateToDraft();

  const onEditClick = () => navigateToDraft(aPackage);

  const onDeleteClick = () => {
    if (!aPackage) return;
    switch (aPackage.type) {
      case 'Plan':
        dispatch(setPlanArchiveDialogOpen({ plan: aPackage as PlanFragment, isOpen: true }));
        break;
      case 'Addon':
        dispatch(setAddonArchiveDialog({ addon: aPackage as AddonFragment, isOpen: true }));
        break;
      default:
    }
  };

  let deleteDisabledTooltip: TooltipFields | undefined;

  if (isDowngradePlan(aPackage)) {
    deleteDisabledTooltip = {
      title: <CantDeletePlanTooltipContent productRefId={(aPackage as PlanFragment).product.refId} isDowngradePlan />,
      placement: 'left',
    };
  } else if (isDefaultStartPlan(aPackage)) {
    deleteDisabledTooltip = {
      title: (
        <CantDeletePlanTooltipContent productRefId={(aPackage as PlanFragment).product.refId} isDowngradePlan={false} />
      ),
      placement: 'left',
    };
  }

  const editButtonOptions: Omit<ButtonDropdownOptions, 'icon'> = {
    text: isDraft ? t('sharedComponents.keepEditing') : t('sharedComponents.edit'),
    onClick: onEditClick,
    dataTestId: `${aPackage.type === 'Plan' ? 'plan' : 'addon'}-editbutton`,
  };

  const deleteButtonOptions: Omit<OptionsDropdownItemType, 'icon'> = {
    text: t('sharedComponents.archive'),
    onClick: onDeleteClick,
    disabled: Boolean(deleteDisabledTooltip),
    tooltip: deleteDisabledTooltip,
  };

  if (isDraft) {
    return <OptionsDropdown options={[{ icon: Trash2, ...deleteButtonOptions }]} />;
  }

  return (
    <ButtonAndOptionsDropdown
      isMenuWidthBasedOnTriggerWidth={!isDraft}
      menuItemId={PRIMARY_MENU_ITEM_ID}
      color="primary"
      button={{
        icon: <Edit2 size="16" />,
        ...editButtonOptions,
      }}
      options={[
        {
          icon: (() => <Icon icon="Trash2" type="reactFeather" size={16} color="active" />) as React.FC,
          ...deleteButtonOptions,
        },
      ]}
    />
  );
}
