import { t } from 'i18next';
import { AddonFragment, BillingCadence } from '@stigg-types/apiTypes';
import { Icon, InformationalTooltipIcon } from '@stigg-components';

export const AddonIcon = ({ addon, isOneOff }: { addon: AddonFragment; isOneOff?: boolean }) => {
  const resolveIsOneOff =
    isOneOff ?? (addon?.prices?.some((price) => price.billingCadence === BillingCadence.OneOff) || false);
  return (
    <InformationalTooltipIcon
      icon={
        <Icon
          type="custom"
          color="default"
          icon={resolveIsOneOff ? 'OneOff' : 'Recurring'}
          overrideFill
          overrideStroke
        />
      }
      text={t(`pricing.billingCadenceDescriptions.${resolveIsOneOff ? 'ONE_OFF' : 'RECURRING'}`)}
    />
  );
};
