import React, { createContext, useContext } from 'react';
import { broadcastChannelModule, BroadcastChannelModule } from './boradcastChannelModule';

const BroadcastChannelContext = createContext<BroadcastChannelModule | null>(null);

export function useBroadcastChannel() {
  const ctx = useContext(BroadcastChannelContext);
  if (!ctx) {
    throw new Error('Could not find BroadcastChannelContext');
  }
  return ctx;
}

export function BroadcastChannelProvider({ children }) {
  return <BroadcastChannelContext.Provider value={broadcastChannelModule}>{children}</BroadcastChannelContext.Provider>;
}
