import { GridFlex, Text } from '@stigg-components';
import React, { useCallback } from 'react';
import { t } from 'i18next';
import { Feature } from '@stigg-types/apiTypes';
import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';
import {
  AnnotationTooltipTranslateFunc,
  ChartJsExternalTooltip,
  ExternalTooltipInfo,
  useChartJsAnnotationExternalTooltip,
} from '../../../../common/chartjs/chartjs.tooltip.plugin';
import { SideColorMarker } from '../../../../common/chartjs/DefaultChartTooltip';
import { UNLIMITED_KEY, UnlimitedKey } from './UsageDistribution.chartjs';

export type PlanLimitTooltipData = {
  limit: number | UnlimitedKey;
  unit: string;
  plans: string[];
  color: string;
};

export type PlanLimitTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<PlanLimitTooltipData>;
};

export function PlanLimitTooltip({ tooltipInfo }: PlanLimitTooltipProps) {
  if (!tooltipInfo.payload) {
    return <></>;
  }

  const { color, limit, unit, plans } = tooltipInfo.payload;

  return (
    <ChartJsExternalTooltip tooltipInfo={tooltipInfo}>
      <GridFlex.Row>
        <SideColorMarker
          style={{
            background: `repeating-linear-gradient(  0deg,  ${color},  ${color} 8px,  transparent 8px,  transparent 16px)`,
          }}
        />
        <GridFlex.ColumnCenter>
          <GridFlex.Row mb={4}>
            <Text.B2 color="primary" mr={1}>
              {t(
                limit === UNLIMITED_KEY ? 'dashboards.featureInspector.unlimited' : 'dashboards.featureInspector.limit',
              )}
            </Text.B2>
            <Text.B2 color="secondary" mr={1}>
              {limit !== UNLIMITED_KEY && limit} {unit}
            </Text.B2>
          </GridFlex.Row>
          {plans.map((plan) => (
            <Text.B2 color="secondary">{plan}</Text.B2>
          ))}
        </GridFlex.ColumnCenter>
      </GridFlex.Row>
    </ChartJsExternalTooltip>
  );
}

export function usePlanLimitTooltip(feature: Feature) {
  const translate = useCallback<AnnotationTooltipTranslateFunc<PlanLimitTooltipData>>(
    (context) => {
      const color = context.element.options.borderColor as string;
      const unit = feature.featureUnitsPlural as string;
      const [limitStr, plansStr] = (context.element.options as AnnotationOptions<'line'>).label?.content as string[];

      const limit = limitStr === UNLIMITED_KEY ? limitStr : Number(limitStr);
      const plans = plansStr.split(',');

      return {
        color,
        limit,
        plans,
        unit,
      };
    },
    [feature],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsAnnotationExternalTooltip<PlanLimitTooltipData>(translate);

  return { tooltipInfo, tooltipHandler };
}
