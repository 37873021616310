import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { ValidateMergeEnvironmentQuery, ValidateMergeEnvironmentQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

const VALIDATE_MERGE_ENVIRONMENT_QUERY = gql`
  query ValidateMergeEnvironment($input: ValidateMergeEnvironmentInput!) {
    validateMergeEnvironment(input: $input) {
      errors
      isValid
    }
  }
`;

async function validateMergeEnvironment(
  {
    sourceEnvironmentSlug,
    destinationEnvironmentSlug,
  }: { sourceEnvironmentSlug: string; destinationEnvironmentSlug: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<ValidateMergeEnvironmentQuery, ValidateMergeEnvironmentQueryVariables>({
        query: VALIDATE_MERGE_ENVIRONMENT_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          input: { sourceEnvironmentSlug, destinationEnvironmentSlug },
        },
      });

      return response.data.validateMergeEnvironment;
    },
    {},
    dispatch,
  );
}

export { validateMergeEnvironment };
