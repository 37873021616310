import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { LinearProgress, Text, GridFlex } from '@stigg-components';
import { Trans } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import ImportStripeIconLight from '@assets/images/integrations/import-from-stripe.light.png';
import ImportStripeIconDark from '@assets/images/integrations/import-from-stripe.dark.png';
import { IntegrationFragment, TaskStatus } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { Check } from 'react-feather';
import * as S from './StripeImport.style';
import { Dialog } from '../../../../components/Dialog';
import { StripeImportWizard } from './StripeImportWizard';
import { getIconColor } from '../../../../theme';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchImportIntegrationTasksAction } from '../../integrationsSlice';
import { ImportStripeStatus } from './ImportStripeStatus';
import { usePollImportIntegrationTask } from './hooks/usePollImportIntegrationTask';
import { isTaskFinished, isTaskInProgress } from '../../../../components/layout/navbar/components/tasks/taskUtils';

type StripeImportProps = { integration: IntegrationFragment | null };

const STRIPE_USAGES = () => [
  t('integrations.importCatalogDescription'),
  t('integrations.billCustomersUsingStripe'),
  t('integrations.keepStripeAndStiggSynced'),
];

export function StripeImport({ integration }: StripeImportProps) {
  const theme = useTheme();
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const { importTasks } = useSelector((state: RootState) => state.integrations);
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (environmentId && integration && !isImportDialogOpen) {
      void dispatch(fetchImportIntegrationTasksAction({ environmentId }));
    }
  }, [dispatch, integration, isImportDialogOpen, environmentId]);

  const [taskProgress, setTaskProgress] = useState(0);
  const inProgressTask = importTasks.find((task) => isTaskInProgress(task.status));
  usePollImportIntegrationTask(inProgressTask ? inProgressTask.id : null, (task) => {
    setTaskProgress(task.progress);
    if (environmentId && isTaskFinished(task.status)) {
      void dispatch(fetchImportIntegrationTasksAction({ environmentId }));
    }
  });

  const openImportDialog = () => {
    if (inProgressTask) {
      return;
    }
    setIsImportDialogOpen(true);
  };

  const closeImportDialog = () => {
    setIsImportDialogOpen(false);
  };

  if (!integration) {
    return (
      <GridFlex.Column mt={12} px={10}>
        <Text.H6 mb={5}>{t('integrations.stripeIntegrationUsages')}</Text.H6>
        {STRIPE_USAGES().map((usage, index) => (
          <GridFlex.RowCenter key={index} mb={4}>
            <Check color={getIconColor('active')} />
            <Text.B2 ml={5} color="secondary">
              {usage}
            </Text.B2>
          </GridFlex.RowCenter>
        ))}
      </GridFlex.Column>
    );
  }

  const finishedTasks = importTasks.filter((task) => !isTaskInProgress(task.status));

  return (
    <GridFlex.Column mt={12}>
      <Text.H3>{t('integrations.importDataFromStripe')}</Text.H3>
      <S.ImportContainer container alignItems="center" onClick={openImportDialog} $inProgressTask={!!inProgressTask}>
        <S.ImportStripeImage
          $originalSize={!theme.isLightTheme}
          src={theme.isLightTheme ? ImportStripeIconLight : ImportStripeIconDark}
          alt="import-stripe"
        />

        <GridFlex.Column justifyContent="flex-start" pt={4} mx={12} flex={1} $fullWidth sx={{ height: '100%' }}>
          <Text.H6 mb={2}>{t('integrations.importData')}</Text.H6>
          <Text.B2 color="secondary">
            <Trans i18nKey="integrations.stripeImportProductsDescription" />
          </Text.B2>
          {inProgressTask && (
            <GridFlex.RowCenter mt={2} justifyContent="space-between">
              <Text.B2 color="secondary">Import in progress</Text.B2>
              <GridFlex.RowCenter>
                <LinearProgress
                  variant="determinate"
                  sx={{ width: 300 }}
                  value={inProgressTask.status === TaskStatus.Pending ? 0 : taskProgress}
                />
                <Text.B2 ml={2}>{taskProgress}%</Text.B2>
              </GridFlex.RowCenter>
            </GridFlex.RowCenter>
          )}
        </GridFlex.Column>
      </S.ImportContainer>
      {finishedTasks.length > 0 ? (
        <>
          <Text.H6 mt={6} mb={4}>
            {t('integrations.recentStripeImports')}
          </Text.H6>
          <GridFlex.Column sx={{ maxHeight: 300, overflow: 'auto' }}>
            {finishedTasks.map((task) => (
              <ImportStripeStatus key={task.id} task={task} />
            ))}
          </GridFlex.Column>
        </>
      ) : null}

      <Dialog
        fullScreen
        isOpen={isImportDialogOpen}
        onCancel={closeImportDialog}
        content={<StripeImportWizard integration={integration} closeImportDialog={closeImportDialog} />}
        padding={0}
      />
    </GridFlex.Column>
  );
}
