import { ExecuteOperationOptions, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  ExperimentStatus,
  FetchActiveExperimentsQuery,
  FetchActiveExperimentsQueryVariables,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';

// This fragment supposes to be as slim as possible, as it's
// loaded upon application startup and blocks it until it finish
export const EXPERIMENT_SLIM_FRAGMENT = gql`
  fragment ExperimentSlimFragment on Experiment {
    refId
    name
    product {
      refId
    }
  }
`;

const FETCH_ACTIVE_EXPERIMENTS = gql`
  query FetchActiveExperiments($filter: ExperimentFilter!, $paging: CursorPaging) {
    experiments(paging: $paging, filter: $filter) {
      edges {
        node {
          ...ExperimentSlimFragment
        }
      }
    }
  }

  ${EXPERIMENT_SLIM_FRAGMENT}
`;

export type FetchActiveExperimentProps = ExecuteOperationOptions;

export async function fetchActiveExperiments(
  props: FetchActiveExperimentProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId: environmentId },
      } = getState();

      if (!environmentId) {
        return {
          activeExperiments: [],
          environmentId: '',
        };
      }

      const response = await apolloClient.query<FetchActiveExperimentsQuery, FetchActiveExperimentsQueryVariables>({
        query: FETCH_ACTIVE_EXPERIMENTS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            environmentId: { eq: environmentId },
            status: { eq: ExperimentStatus.InProgress },
          },
        },
      });

      return {
        activeExperiments: response.data.experiments.edges.map((edge) => edge.node),
        environmentId,
      };
    },
    {
      failureMessageHandler: () => t('experiments.api.failFetchActive'),
    },
    dispatch,
  );
}
