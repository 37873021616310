import { BoundedSearchableSelect, Box, GridFlex, HighlightText, LongText, Text } from '@stigg-components';
import { Feature } from '@stigg-types/apiTypes';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import head from 'lodash/head';
import { getFeatureTypeIcon } from '../../../../components/getFeatureTypeIcon';
import { useLoadFeatures } from '../../../features/hooks/useLoadFeatures';
import {
  USAGE_BASED_FEATURE_TYPES,
  USAGE_BASED_METER_TYPES,
} from '../../../packages/pricing/components/SetPriceWizard/form/chargesStep/usageBasedPrice/FeatureAutocomplete';
import { getFeatureDisplayName } from '../../../features/utils/getFeatureDisplayName';

function shouldDisableItem(feature: Feature): boolean {
  return !!feature.hasMeter;
}

function renderItem(feature: Feature, search?: string) {
  return (
    <GridFlex.RowCenter gap={2} height={35}>
      <GridFlex.Column>{getFeatureTypeIcon(feature)}</GridFlex.Column>
      <GridFlex.Column>
        <LongText variant="body2" color="primary">
          <HighlightText
            highlight={search}
            text={`${getFeatureDisplayName(feature)} ${
              shouldDisableItem(feature) ? t(`dashboards.featureInspector.comingSoon`) : ''
            }`}
          />
        </LongText>
        {feature.refId && (
          <LongText variant="body2" color="secondary" wordBreak sx={{ marginTop: -1 }}>
            <HighlightText highlight={search} text={feature.refId} />
          </LongText>
        )}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}

function renderSelectedItem(selectedFeature: Feature) {
  return (
    <GridFlex.RowCenter gap={2} height={35}>
      <GridFlex.Column>{getFeatureTypeIcon(selectedFeature)}</GridFlex.Column>
      <Text.B2 color="secondary">{t(`dashboards.featureInspector.featureFilterPrefix`)}:</Text.B2>
      <GridFlex.Column>
        <LongText variant="body2" color="primary">
          {getFeatureDisplayName(selectedFeature)}
        </LongText>
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}

export function SelectFeatureFilter({
  selectedFeature,
  onChange,
}: {
  selectedFeature: Feature | undefined;
  onChange: (feature: Feature) => void;
}) {
  const [search, setSearch] = useState<string | undefined>();
  const { features, isLoading, totalCount } = useLoadFeatures({
    featureTypes: USAGE_BASED_FEATURE_TYPES,
    meterTypes: USAGE_BASED_METER_TYPES,
    search,
  });

  useEffect(() => {
    const selectableFeatures = features?.filter((feature) => !shouldDisableItem(feature));
    const firstSelectableFeature = head(selectableFeatures);

    if (firstSelectableFeature) {
      if (!selectedFeature) {
        onChange(firstSelectableFeature);
      } else if (selectedFeature.environmentId !== firstSelectableFeature.environmentId) {
        onChange(
          selectableFeatures.find((feature) => feature.refId === selectedFeature.refId) || firstSelectableFeature,
        );
      }
    }
  }, [onChange, selectedFeature, features]);

  return (
    <Box width={408} mr={2}>
      <BoundedSearchableSelect
        maxWidth={408}
        height={38}
        onSearch={setSearch}
        isSearching={isLoading}
        isOptionDisabled={(feature) => shouldDisableItem(feature)}
        totalItems={totalCount}
        items={features}
        itemSerializer={(item: Feature) => item.refId}
        onChange={onChange}
        value={selectedFeature}
        renderItem={renderItem}
        renderSelectedItem={renderSelectedItem}
        disablePaddingMenu
      />
    </Box>
  );
}
