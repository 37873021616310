import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { t } from 'i18next';
import { Form } from '@stigg-components';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { ProductSubscriptionType } from './ProductSubscriptionType';
import { fetchProductForEditByRefIdAction, resetProductForEdit, updateProductAction } from '../../productsSlice';

export type EditProductFormFields = {
  refId: string;
  displayName: string;
  description?: string | null;
  multipleSubscriptions: boolean;
};

const validationSchema = () =>
  Yup.object().shape({
    refId: Yup.string().required(t('products.yup.required')),
    displayName: Yup.string().required(t('products.yup.required')),
    description: Yup.string().nullable(true),
    multipleSubscriptions: Yup.boolean(),
  });

export function EditProductForm({ productRefId, closeDialog }: { productRefId: string; closeDialog: () => void }) {
  const { multipleSubscriptionsUi: multipleSubscriptionsUiEnabled } = useFlags<FeatureFlags>();
  const { isLoading, product } = useSelector((state: RootState) => state.productReducer.edit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(fetchProductForEditByRefIdAction({ refId: productRefId }));
    return () => {
      void dispatch(resetProductForEdit());
    };
  }, [dispatch, productRefId]);

  const initialValues: EditProductFormFields = {
    refId: product.refId || '',
    displayName: product.displayName || '',
    description: product.description,
    multipleSubscriptions: product.multipleSubscriptions,
  };

  const onSubmit = async (updatePayload: EditProductFormFields) => {
    await dispatch(
      updateProductAction({
        productId: product.id,
        updatePayload: omit(updatePayload, 'refId'),
        refId: updatePayload.refId,
      }),
    );
    closeDialog();
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Form
      disableSaveOnNoChanges
      onSubmit={onSubmit}
      onCancel={closeDialog}
      submitButtonText={t('sharedComponents.editSaveButton')}
      validationSchema={validationSchema}
      initialValues={initialValues}
      fields={[
        {
          type: 'text',
          id: 'refId',
          label: t('products.refId'),
          disabled: true,
        },
        {
          type: 'text',
          id: 'displayName',
          label: t('products.nameForm'),
        },
        {
          type: 'text',
          id: 'description',
          label: t('products.descriptionForm'),
          optional: true,
        },
        {
          type: 'custom',
          hide: () => !multipleSubscriptionsUiEnabled,
          render: ({ values, setFieldValue }) => (
            <ProductSubscriptionType
              isAwsProduct={!!product.awsMarketplaceProductId}
              value={values.multipleSubscriptions}
              updateValue={(newValue: boolean) => setFieldValue('multipleSubscriptions', newValue)}
              disabled={product.hasSubscriptions}
              subscriptionTypeText={
                product.hasSubscriptions ? t('products.subscriptionTypeTextCantBeChanged') : undefined
              }
            />
          ),
        },
      ]}
    />
  );
}
