import { t } from 'i18next';
import {
  Box,
  CircularProgress,
  CodeTypographyText,
  CollapsableSection,
  Divider,
  GridFlex,
  Icon,
  Text,
  ClipboardChip,
} from '@stigg-components';
import React, { useState } from 'react';
import {
  PaymentCollection,
  SubscriptionDataFragment,
  SubscriptionFutureUpdateDataFragment,
  SubscriptionScheduleStatus,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import isEmpty from 'lodash/isEmpty';
import { getIconColor } from '@stigg-theme';
import styled from 'styled-components/macro';
import { PaymentCollectionCtaButtons } from './PaymentCollectionCtaButtons';
import { SubscriptionFutureUpdateRow } from '../subscriptionUpdates/SubscriptionFutureUpdateRow';

const MoreDetailsContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  background-color: ${({ theme }) => theme.itamar.palette.white};
`;

const INVOICE_PENDING_PAYMENT_ERROR_MESSAGES = ['requires_payment_method', 'requires_confirmation'];

export function isInvoicePendingPayment(errorMessage?: string | null): boolean {
  return errorMessage ? INVOICE_PENDING_PAYMENT_ERROR_MESSAGES.includes(errorMessage) : false;
}

export function getVariant(paymentCollection: PaymentCollection, errorMessage: string) {
  switch (paymentCollection) {
    case PaymentCollection.Failed:
    case PaymentCollection.ActionRequired:
      if (isInvoicePendingPayment(errorMessage)) {
        return 'transparent';
      }
      return 'error';
    default:
      return 'transparent';
  }
}

export function getIcon(paymentCollection: PaymentCollection, errorMessage: string) {
  switch (paymentCollection) {
    case PaymentCollection.Failed:
    case PaymentCollection.ActionRequired:
      if (isInvoicePendingPayment(errorMessage)) {
        return <Icon type="custom" icon="PaymentPending" />;
      }
      return <Icon icon="AlertCircle" color="error" />;
    default:
      return <CircularProgress size={20} sx={{ color: getIconColor('active') }} />;
  }
}

export function getTitle(paymentCollection: PaymentCollection, errorMessage: string) {
  switch (paymentCollection) {
    case PaymentCollection.Processing:
      return t('subscriptions.paymentCollection.paymentPending');
    case PaymentCollection.Failed:
      return t('subscriptions.paymentCollection.paymentFailed');
    case PaymentCollection.ActionRequired:
      if (isInvoicePendingPayment(errorMessage)) {
        return t('subscriptions.paymentCollection.paymentPending');
      }
      return t('subscriptions.paymentCollection.paymentActionRequired');
    default:
      return '';
  }
}

export function getDescription(
  subscriptionStatus: SubscriptionStatus,
  paymentCollection: PaymentCollection.Failed | PaymentCollection.ActionRequired | PaymentCollection.Processing,
  pendingPaymentUpdates: SubscriptionFutureUpdateDataFragment[],
  subscription: SubscriptionDataFragment,
) {
  if (subscriptionStatus === SubscriptionStatus.PaymentPending) {
    return (
      <Text.B2 style={{ whiteSpace: 'pre-line' }}>
        {t(
          `subscriptions.paymentCollection.description.firstPayment.${
            // TODO: maybe add PENDING TO paymentCollectionStatus enum (?)
            isInvoicePendingPayment(subscription.latestInvoice?.errorMessage) ? 'PENDING' : paymentCollection
          }`,
        )}
      </Text.B2>
    );
  }

  if (!isEmpty(pendingPaymentUpdates)) {
    return (
      <>
        <Text.B2 mb={1} style={{ whiteSpace: 'pre-line' }}>
          {t(`subscriptions.paymentCollection.description.paymentForSubscriptionUpdate.${paymentCollection}`)}
        </Text.B2>
        {pendingPaymentUpdates.map((update, index) => (
          <SubscriptionFutureUpdateRow
            key={index}
            alertVariant={false}
            futureUpdate={update}
            subscription={subscription}
          />
        ))}
      </>
    );
  }

  return (
    <Text.B2 style={{ whiteSpace: 'pre-line' }}>
      {t(`subscriptions.paymentCollection.description.recurringPayment.${paymentCollection}`)}
    </Text.B2>
  );
}

export function getCtaButtons(
  paymentCollection: PaymentCollection,
  subscriptionInvoice: SubscriptionDataFragment['latestInvoice'],
  hasPendingPaymentUpdates: boolean,
  onCancelSubscriptionFutureUpdatesClick: (futureUpdateStatus: ActiveFutureUpdateStatus) => void,
) {
  const paymentUrl = subscriptionInvoice?.paymentUrl;
  const cancelUpdate = hasPendingPaymentUpdates
    ? () => onCancelSubscriptionFutureUpdatesClick(SubscriptionScheduleStatus.PendingPayment)
    : undefined;

  if (paymentCollection === PaymentCollection.Processing) {
    return undefined;
  }

  return <PaymentCollectionCtaButtons paymentLink={paymentUrl} cancelUpdate={cancelUpdate} />;
}

const StyledDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.itamar.palette.error.main};
`;

export function MoreDetailsFooter({
  paymentCollection,
  subscriptionInvoice,
}: {
  paymentCollection: PaymentCollection;
  subscriptionInvoice: SubscriptionDataFragment['latestInvoice'];
}) {
  const [open, setOpen] = useState(false);

  if (paymentCollection === PaymentCollection.Processing || !subscriptionInvoice?.errorMessage) {
    return null;
  }

  if (
    paymentCollection === PaymentCollection.ActionRequired &&
    subscriptionInvoice?.errorMessage === 'requires_action'
  ) {
    return null;
  }

  return (
    <Box mx={2} mt={6}>
      <CollapsableSection
        title={<Text.B2 color="error">{t('sharedComponents.moreDetails')}</Text.B2>}
        color="error"
        isSectionOpen={open}
        onClick={() => setOpen((prev) => !prev)}
        buttonSx={{ padding: 0 }}
        content={
          <>
            <StyledDivider mt={2} mb={4} />
            <MoreDetailsContainer>
              <GridFlex.RowSpaceBetween gap={2} $fullWidth>
                <CodeTypographyText color="secondary">{subscriptionInvoice.errorMessage}</CodeTypographyText>
                <ClipboardChip copy={subscriptionInvoice.errorMessage} iconSize={14} />
              </GridFlex.RowSpaceBetween>
            </MoreDetailsContainer>
          </>
        }
      />
    </Box>
  );
}
