import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  PackageGroupFilter,
  PackageGroupSortFields,
  FetchPackageGroupsQuery,
  FetchPackageGroupsQueryVariables,
  SortDirection,
  PackageGroupStatus,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { Paging } from '../../../../components/table/gqlTableHelper';
import { AppDispatch } from '../../../../redux/store';
import { applyEnvironmentFilter } from '../../../common/applyEnvironmentFilter';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../common/pagination';

const PACKAGE_GROUPS_FRAGMENT = gql`
  fragment PackageGroupsFragment on PackageGroupConnection {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...PackageGroupFragment
      }
      cursor
    }
  }
  fragment PackageGroupFragment on PackageGroup {
    displayName
    status
    updatedAt
    packageGroupId
    product {
      displayName
    }
    addons {
      id
    }
  }
`;

export const FETCH_PACKAGE_GROUPS = gql`
  query FetchPackageGroups($paging: CursorPaging, $sorting: [PackageGroupSort!], $filter: PackageGroupFilter) {
    packageGroups(paging: $paging, sorting: $sorting, filter: $filter) {
      ...PackageGroupsFragment
    }
  }
  ${PACKAGE_GROUPS_FRAGMENT}
`;

export type FetchPackageGroupsProps = {
  paging?: Paging;
  search?: string | null;
  environmentId: string;
  productId?: string;
};

async function fetchAddonGroups(props: FetchPackageGroupsProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search, paging } = props;
  if (search === '') {
    search = null;
  }
  if (!paging) {
    paging = { first: DEFAULT_TABLE_PAGE_SIZE };
  }
  return executeOperationSafely(
    async () => {
      const filter: PackageGroupFilter = {
        environmentId: { eq: props.environmentId },
        isLatest: { is: true },
        status: { eq: PackageGroupStatus.Published },
      };
      if (props.productId) {
        filter.productId = { eq: props.productId };
      }

      if (props.search) {
        filter.or = [
          { displayName: { iLike: `%${props.search}%` } },
          { packageGroupId: { iLike: `%${props.search}%` } },
        ].map((searchFilter) => applyEnvironmentFilter<PackageGroupFilter>(searchFilter, props.environmentId));
      }

      const response = await apolloClient.query<FetchPackageGroupsQuery, FetchPackageGroupsQueryVariables>({
        query: FETCH_PACKAGE_GROUPS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: [
            { field: PackageGroupSortFields.Status, direction: SortDirection.Desc },
            { field: PackageGroupSortFields.DisplayName, direction: SortDirection.Asc },
          ],
          filter,
        },
      });

      return response.data.packageGroups;
    },
    {
      failureMessageHandler: () => t('addons.api.failFetchPackageGroups'),
    },
    dispatch,
  );
}

export { fetchAddonGroups };
