import { t } from 'i18next';
import { CustomTable, GridFlex, HeadCell, Text } from '@stigg-components';
import { BillingPeriod, Currency, PriceFragment, PriceTier } from '@stigg-types/apiTypes';
import { getPeriodFormat, formatPrice } from '../../../pricing/utils/priceFormatUtils';
import { FallbackTierIcon } from './FallbackTierIcon';
import { hasTierWithFlatPrice, hasTierWithUnitPrice, isBulkTiers } from '../../../pricing/utils/priceTiersUtils';
import { Money } from '../../../pricing/utils/getPrice';

const TOOLTIP_TABLE_ROW_HEIGHT = 54;
const TOOLTIP_TABLE_MAX_VISIBLE_ROWS = 4.5; // clip last row to indicate more rows

function getUnitPrice(tier: PriceTier, billingPeriod: BillingPeriod, reversedPeriod?: boolean): string {
  let unitPrice: Money | undefined;

  if (tier.unitPrice) {
    ({ unitPrice } = tier);
  } else if (tier.flatPrice && tier.upTo) {
    unitPrice = {
      amount: tier.flatPrice.amount / tier.upTo,
      currency: tier.flatPrice.currency,
    };
  }

  return unitPrice ? formatPrice(unitPrice, billingPeriod, { reversedPeriod }) : '';
}

function getTierPrice(tier: PriceTier, billingPeriod: BillingPeriod, reversedPeriod?: boolean) {
  const currency = tier.unitPrice?.currency || tier.flatPrice?.currency || Currency.Usd;
  return formatPrice(tier.flatPrice || { amount: 0, currency }, billingPeriod, { reversedPeriod });
}

function createTooltipTableCells({
  price,
  reversedPeriod,
}: {
  price: PriceFragment;
  reversedPeriod?: boolean;
}): Array<HeadCell<PriceTier, any>> {
  const getPeriodLabel = (label: string) => (
    <GridFlex.Row columnGap={1}>
      <Text.B2 $medium>{label}</Text.B2>
      <Text.B2 color="disabled">/ {getPeriodFormat(price, { reversedPeriod, shortFormat: true })}</Text.B2>
    </GridFlex.Row>
  );
  const tiers = price.tiers || [];
  const isUnitTiers = hasTierWithUnitPrice(tiers);
  const hasFlatPrice = hasTierWithFlatPrice(tiers);
  const stairstepTiers = isBulkTiers(tiers);

  return [
    {
      id: 'start_unit',
      alignment: 'left',
      label: t('pricing.tierPriceTableTooltip.startUnit'),
      visible: isUnitTiers,
      render: (_: PriceTier, entityIndex: number) => {
        const currentTier = entityIndex === 0 ? null : tiers[entityIndex - 1];
        return <Text.B2>{entityIndex === 0 ? 1 : currentTier?.upTo ? currentTier?.upTo + 1 : 'Unlimited'}</Text.B2>;
      },
    },
    {
      id: 'last_unit',
      alignment: 'left',
      label: isUnitTiers ? t('pricing.tierPriceTableTooltip.endUnit') : t('pricing.tierPriceTableTooltip.numOfUnits'),
      render: (tier: PriceTier, entityIndex: number) => {
        const isLastTier = entityIndex === tiers.length - 1;
        return (
          <Text.B2>
            {isUnitTiers && isLastTier && !tier.upTo ? (
              <FallbackTierIcon iconColor="default" textColor="default" />
            ) : (
              tier.upTo
            )}
          </Text.B2>
        );
      },
    },
    {
      id: 'unit_price',
      alignment: 'left',
      label: getPeriodLabel(t('pricing.tierPriceTableTooltip.unitPrice')),
      render: (tier: PriceTier) => <Text.B2>{getUnitPrice(tier, price.billingPeriod, reversedPeriod)}</Text.B2>,
    },
    {
      id: 'tier_price',
      alignment: 'left',
      visible: hasFlatPrice,
      label: stairstepTiers
        ? getPeriodLabel(t('pricing.tierPriceTableTooltip.tierPrice'))
        : getPeriodLabel(t('pricing.tierPriceTableTooltip.flatPrice')),
      render: (tier: PriceTier) => <Text.B2>{getTierPrice(tier, price.billingPeriod, reversedPeriod)}</Text.B2>,
    },
  ];
}

function TieredPriceTable({
  price,
  reversedPeriod,
  fullHeight,
  withRowHover = true,
}: {
  price: PriceFragment;
  reversedPeriod?: boolean;
  fullHeight?: boolean;
  withRowHover?: boolean;
}) {
  return (
    <CustomTable
      backgroundColor="inherit"
      label={t('pricing.tierPriceTableTooltip.label')}
      rowHeight={TOOLTIP_TABLE_ROW_HEIGHT}
      withInnerDividers
      headCells={createTooltipTableCells({ price, reversedPeriod })}
      data={price.tiers || []}
      withRowHover={withRowHover}
      maxHeight={fullHeight ? undefined : TOOLTIP_TABLE_ROW_HEIGHT * TOOLTIP_TABLE_MAX_VISIBLE_ROWS}
    />
  );
}

export default TieredPriceTable;
