import React from 'react';
import { Text, GridFlex, Grid, Box, Button } from '@stigg-components';
import { LabelCard } from './CarouselCard.style';

export type CarouselCardProps = {
  label?: string;
  title: string;
  content: React.ReactNode;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  buttonIconPosition?: 'start' | 'end';
  buttonOnClick?: () => void;
  svg: React.ReactNode;
  customActions?: React.ReactNode;
  style?: React.CSSProperties;
};

export function CarouselCard({
  label,
  title,
  content,
  svg,
  buttonOnClick,
  buttonText,
  buttonIcon,
  buttonIconPosition = 'end',
  customActions,
  style,
}: CarouselCardProps) {
  const hasButton = buttonText && buttonOnClick;
  const buttonIconProps = { [`${buttonIconPosition}Icon`]: buttonIcon };

  return (
    <Box width="100%" textAlign="left" position="relative" style={style}>
      <Grid container justifyContent="space-between" flexWrap="nowrap" paddingY={12} paddingX={16}>
        <GridFlex.Column item flex={1} alignItems="flex-start" minWidth={306} maxWidth={306}>
          {label && (
            <Box mb={2}>
              <LabelCard>
                <Text.B1 color="secondary">{label}</Text.B1>
              </LabelCard>
            </Box>
          )}

          <Text.H3 mb={5}>{title}</Text.H3>
          {content}
          <div style={{ marginTop: 36 }}>
            {hasButton && (
              <Button variant="contained" onClick={buttonOnClick} {...buttonIconProps}>
                {buttonText}
              </Button>
            )}
            {customActions}
          </div>
        </GridFlex.Column>
        <GridFlex.RowMiddle flex={1}>{svg}</GridFlex.RowMiddle>
      </Grid>
    </Box>
  );
}
