import { gql } from '@apollo/client';

export const FEATURE_FRAGMENT = gql`
  fragment FeatureFragment on Feature {
    displayName
    id
    refId
    createdAt
    updatedAt
    description
    featureStatus
    environmentId
    featureType
    meterType
    featureUnits
    featureUnitsPlural
    additionalMetaData
    hasEntitlements
    hasMeter
    unitTransformation {
      divide
      round
      featureUnits
      featureUnitsPlural
    }
    meter {
      id
      filters {
        conditions {
          field
          operation
          value
        }
      }
      aggregation {
        function
        field
      }
    }
  }
`;
