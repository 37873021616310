export enum CustomerPageTabs {
  Overview = 'overview',
  EntitlementUsage = 'entitlement-usage',
  EntitlementSummary = 'entitlement-summary',
  Activity = 'activity',
}

export enum CustomerPageSubscriptionsTabs {
  Subscriptions = 'subscriptions',
  DelegatedSubscriptions = 'delegated-subscriptions',
}

export enum SubscriptionPageTabs {
  Overview = 'overview',
  EntitlementUsage = 'entitlement-usage',
  EntitlementSummary = 'entitlement-summary',
  Activity = 'activity',
}

export enum EventTabs {
  Details = 'details',
  ApiRequest = 'api-request',
}
