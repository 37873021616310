import { gql } from '@apollo/client';
import {
  EnvironmentType,
  ErrorCode,
  MergeEnvironmentInput,
  MergeEnvironmentMutation,
  MergeEnvironmentMutationVariables,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { fetchEnvironmentsAction } from '../accountsSlice';
import { LimitExceededAlert } from '../../../components/LimitExceededAlert';

const MERGE_ENVIRONMENT_MUTATION = gql`
  mutation MergeEnvironment($input: MergeEnvironmentInput!) {
    mergeEnvironment(input: $input) {
      environmentSlug
      taskIds
    }
  }
`;

export type MergeEnvironmentProps = MergeEnvironmentInput & {
  withSuccessMessage?: boolean;
};

export async function mergeEnvironment(
  { withSuccessMessage = true, ...mergeEnvironmentInput }: MergeEnvironmentProps,
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<MergeEnvironmentMutation, MergeEnvironmentMutationVariables>({
        mutation: MERGE_ENVIRONMENT_MUTATION,
        variables: { input: mergeEnvironmentInput },
      });

      if (!response.data) {
        throw new Error('Could not copy environment');
      }

      await dispatch(fetchEnvironmentsAction({ environmentSlug: response.data.mergeEnvironment.environmentSlug }));
      return response.data.mergeEnvironment;
    },
    {
      successMessage: withSuccessMessage ? t('accounts.copyMergeEnvironments.successCopy') : undefined,
      failureMessageHandler: () => t('accounts.copyMergeEnvironments.failCopy'),
      expectedErrorsMessage: {
        [ErrorCode.EntitlementLimitExceededError]: (
          <LimitExceededAlert
            alertMessage={
              mergeEnvironmentInput.destinationEnvironmentType === EnvironmentType.Production
                ? t('accounts.api.productionEntitlementLimitExceeded')
                : t('accounts.api.nonProductionEntitlementLimitExceeded')
            }
          />
        ),
      },
    },
    dispatch,
  );
}
