import React, { useEffect, useState } from 'react';
import { WizardAccordionStep } from '@stigg-components';

export function useWizardAccordionStateManagement({
  uniqueFlowId,
  isStepByStep,
  stepsToShow,
  initialFocusedStep,
  expandOnSingleStep,
}: {
  uniqueFlowId: string;
  isStepByStep?: boolean;
  stepsToShow: WizardAccordionStep[];
  initialFocusedStep?: string;
  expandOnSingleStep?: boolean;
}) {
  const initialExpandedSteps = initialFocusedStep
    ? [initialFocusedStep]
    : expandOnSingleStep && stepsToShow.length === 1
    ? [stepsToShow[0].id]
    : [];

  const [currentIndex, setCurrentIndex] = useState(-1);
  const [expandedSteps, setExpandedSteps] = useState<string[]>(initialExpandedSteps);
  const isFirstRender = React.useRef(true);

  const onContinue = () => {
    setCurrentIndex((previousIndex) => Math.min(previousIndex + 1, stepsToShow.length));
  };

  const toggleStep = (id: string) => {
    if (expandedSteps.includes(id)) {
      setExpandedSteps(expandedSteps.filter((stepId) => stepId !== id));
    } else {
      setExpandedSteps([...expandedSteps, id]);
    }
  };

  // reset current index if needed
  useEffect(() => {
    setCurrentIndex(isStepByStep ? 0 : -1);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setExpandedSteps([]);
    }
  }, [uniqueFlowId, isStepByStep]);

  return { currentIndex, expandedSteps, toggleStep, onContinue };
}
