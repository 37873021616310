import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  AddCompatibleAddonsToPlanMutation,
  AddCompatibleAddonsToPlanMutationVariables,
  Plan,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch } from '../../../../redux/store';
import { fetchPlanByRefIdAction } from '../plansSlice';

const ADD_COMPATIBLE_ADDONS = gql`
  mutation AddCompatibleAddonsToPlan($input: AddCompatibleAddonsToPlanInput!) {
    addCompatibleAddonsToPlan(input: $input) {
      id
    }
  }
`;

async function addCompatibleAddonsToPlan(
  {
    planRefId,
    planId,
    addonIds,
    versionNumber,
    skipFetchingPlan,
  }: { planRefId: string; planId: string; addonIds: string[]; versionNumber: number; skipFetchingPlan?: boolean },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Plan> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        AddCompatibleAddonsToPlanMutation,
        AddCompatibleAddonsToPlanMutationVariables
      >({
        mutation: ADD_COMPATIBLE_ADDONS,
        variables: { input: { id: planId, relationIds: addonIds } },
      });

      if (!skipFetchingPlan) {
        await dispatch(fetchPlanByRefIdAction({ refId: planRefId, versionNumber, silentFetch: true }));
      }

      return response.data?.addCompatibleAddonsToPlan;
    },
    {
      failureMessageHandler: () => t('plans.api.failAddingCompatibleAddon'),
    },
    dispatch,
  );
}

export { addCompatibleAddonsToPlan };
