import { t } from 'i18next';
import { useNavigation } from '../modules/navigation/useNavigation';
import { GridFlex } from './Grids';
import { Link } from './Link';
import { Text } from './Typography';
import { SettingsPageTabs } from '../modules/accounts/types/settingsPageTabs';

export function LimitExceededAlert({ alertMessage }: { alertMessage: string }) {
  const navigation = useNavigation();
  return (
    <GridFlex.RowCenter>
      <Text.B2 color="white" mr={2}>
        {alertMessage}
      </Text.B2>
      <Link
        variant="body2"
        color="white"
        $bold
        underline="always"
        onClick={() => {
          navigation.navigateTo(navigation.appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Billing }), {
            isGlobal: true,
          });
        }}>
        <Text.B2 color="white">{t('account.upgradeNow')}</Text.B2>
      </Link>
    </GridFlex.RowCenter>
  );
}
