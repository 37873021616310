import { gql } from '@apollo/client';
import { t } from 'i18next';
import { CreateHook, CreateHookMutation, CreateHookMutationVariables, ErrorCode, Hook } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchHooksAction } from '../hooksSlice';

const CREATE_HOOK = gql`
  mutation CreateHook($input: CreateOneHookInput!) {
    createOneHook(input: $input) {
      id
    }
  }
`;

async function createHook(
  hookData: CreateHook,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Hook> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<CreateHookMutation, CreateHookMutationVariables>({
        mutation: CREATE_HOOK,
        variables: {
          input: { hook: { ...hookData, environmentId: accountReducer.currentEnvironmentId } },
        },
      });
      const newHook = response.data?.createOneHook;
      await dispatch(fetchHooksAction({ environmentId: accountReducer.currentEnvironmentId }));
      return newHook;
    },
    {
      successMessage: t('hooks.api.successCreate'),
      failureMessageHandler: () => t('hooks.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('hooks.api.failCreateDupHook', {
          endpoint: hookData.endpoint,
        }),
      },
    },
    dispatch,
  );
}

export { createHook };
