import { t } from 'i18next';
import styled from 'styled-components/macro';
import { Text, Link, PageCard } from '@stigg-components';

const HidePageCard = styled(PageCard)`
  padding: 16px;
  background: linear-gradient(225.2deg, #bfd9fa 3.06%, #d4e9fd 100%);
  border: none;

  > div:last-child {
    padding: 0;
  }
`;

type HideGettingStartedPageProps = {
  onHide: () => void;
};

export function HideGettingStartedPage({ onHide }: HideGettingStartedPageProps) {
  return (
    <HidePageCard>
      <Text.B2 color="secondary">
        {t('gettingStarted.imDoneWithTheSetup')}
        <Link ml={2} onClick={onHide}>
          {t('gettingStarted.hideThisPage')}
        </Link>
      </Text.B2>
    </HidePageCard>
  );
}
