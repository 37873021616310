import { GridFlex, Text, RadioItem, RadioGroup } from '@stigg-components';
import React from 'react';
import { BillingCadence } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getBillingCadenceDescription, getBillingCadenceIcon } from './BillingCadenceStep.utils';
import { getPackageTypeDisplay } from '../../../../../common/getPackageTypeDisplay';

export function BillingCadenceStep() {
  const { aPackage, formRenderProps } = useSetPriceWizardFormContext();
  const { values, setFieldValue, setTouched } = formRenderProps;

  const onBillingCadenceChange = (billingCadence: BillingCadence) => {
    const newTouched = cloneDeep(formRenderProps.touched);

    // setting both values and touched in one
    // action to prevent redundant rendering
    setTouched(newTouched, false);
    setFieldValue('billingCadence', billingCadence);
  };

  return (
    <>
      <Text.B2 color="secondary" mb={6}>
        {t('pricing.selectBillingCadenceLabel', { packageType: getPackageTypeDisplay(aPackage) })}
      </Text.B2>

      <RadioGroup
        value={values.billingCadence}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const billingCadence = (event.target as HTMLInputElement).value as BillingCadence;
          onBillingCadenceChange(billingCadence);
        }}>
        <GridFlex.Row gap={2}>
          {[BillingCadence.Recurring, BillingCadence.OneOff].map((billingCadence) => {
            const selected = values.billingCadence === billingCadence;
            const icon = getBillingCadenceIcon({ billingCadence, selected });
            return (
              <RadioItem
                key={billingCadence}
                value={billingCadence}
                selected={selected}
                dataTestId={`billing-cadence-${billingCadence}`}
                label={
                  <GridFlex.RowCenter gap={2} height={42}>
                    {icon}
                    <Text.B2 color="secondary">{getBillingCadenceDescription(billingCadence)}</Text.B2>
                  </GridFlex.RowCenter>
                }
              />
            );
          })}
        </GridFlex.Row>
      </RadioGroup>
    </>
  );
}
