import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ProvisionSandboxInput,
  ProvisionSandboxMutation,
  ProvisionSandboxMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { fetchEnvironmentsAction, selectEnvironment } from '../accountsSlice';

const PROVISION_SANDBOX = gql`
  mutation ProvisionSandboxMutation($input: ProvisionSandboxInput!) {
    provisionSandbox(input: $input) {
      id
      displayName
      description
      slug
    }
  }
`;

async function provisionSandbox(
  {
    noSuccessMessage,
    noPostFetch,
    input,
  }: { noSuccessMessage?: boolean; noPostFetch?: boolean; input: ProvisionSandboxInput },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<ProvisionSandboxMutation, ProvisionSandboxMutationVariables>({
        mutation: PROVISION_SANDBOX,
        variables: { input },
      });

      if (!response.data) {
        throw new Error('Invalid response');
      }

      const newEnvironment = response.data.provisionSandbox;
      if (noPostFetch) {
        return newEnvironment;
      }

      await dispatch(fetchEnvironmentsAction({}));

      if (newEnvironment) {
        dispatch(selectEnvironment(newEnvironment.id));
      }

      return newEnvironment;
    },
    {
      successMessage: noSuccessMessage ? undefined : t('accounts.api.successProvisionSandbox'),
      failureMessageHandler: () => t('accounts.api.failProvisionSandbox'),
    },
    dispatch,
  );
}

export { provisionSandbox };
