import { t } from 'i18next';
import { useTheme } from '@mui/material/styles';
import styledComponents, { css } from 'styled-components/macro';
import { styled } from '@stigg-theme';
import importFromAWSLight from '@assets/images/integrations/import-from-aws.light.png';
import importFromAWSDark from '@assets/images/integrations/import-from-aws.dark.png';
import { Text, GridFlex } from '@stigg-components';

export const ImportContainer = styled(GridFlex.RowCenter)<{ $disabled?: boolean }>`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  height: 157px;
  padding: 24px;
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.paper};
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? theme.itamar.palette.grey[50] : theme.itamar.palette.primary.outlinedHoverBackground};
  }

  ${({ $disabled, theme }) =>
    $disabled &&
    `
    cursor: not-allowed;
    background-color: ${theme.itamar.palette.grey[50]};
  `}
`;

export const ImportStripeImage = styledComponents.img<{ $originalSize?: boolean }>`
${({ $originalSize }) =>
  $originalSize
    ? ''
    : css`
        height: 100px;
        width: 150px;
      `}
  
  margin-left: 10px;
`;

type ImportAWSProductsButtonProps = {
  onImport: () => void;
  disabled?: boolean;
};

export function ImportAWSProductsButton({ onImport, disabled }: ImportAWSProductsButtonProps) {
  const theme = useTheme();

  return (
    <ImportContainer
      $disabled={disabled}
      gap={4}
      onClick={() => {
        if (disabled) {
          return;
        }

        onImport();
      }}>
      <ImportStripeImage
        $originalSize={!theme.isLightTheme}
        src={theme.isLightTheme ? importFromAWSLight : importFromAWSDark}
        alt="import from aws"
      />

      <GridFlex.Column>
        <Text.H6 mb={2} color={disabled ? 'disabled' : 'primary'}>
          {t('integrations.importProducts')}
        </Text.H6>
        <Text.B2 color={disabled ? 'disabled' : 'secondary'}>{t('integrations.awsMarketplace.importContent')}</Text.B2>
      </GridFlex.Column>
    </ImportContainer>
  );
}
