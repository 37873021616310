import { t } from 'i18next';
import moment from 'moment-timezone';
import { TextColor } from '@stigg-theme';
import { useSelector } from 'react-redux';
import { FormatOptions, formatDate, getTimezoneUtcOffset, formatTimeSince } from '@stigg-common';
import { Text } from '../Typography';
import { Divider } from '../components.style';
import { RootState } from '../../redux/store';
import { InformationTooltip, InformationTooltipProps } from '../InformationTooltip';
import { DottedText } from '../dashedText';
import { Box } from '..';

export type LocalDateProps = {
  date: Date | string;
  showTimeSince?: boolean;
  withConversionTooltip?: boolean;
  formatOptions?: Omit<FormatOptions, 'timezone'>;
  color?: TextColor;
  tooltipPlacement?: InformationTooltipProps['placement'];
};

function withUtcOffset(formattedDate: string, timezone: string) {
  return `${formattedDate} (${getTimezoneUtcOffset(timezone)})`;
}

export function LocalDate({
  date,
  showTimeSince = false,
  withConversionTooltip = true,
  color,
  formatOptions = {},
  tooltipPlacement = 'top',
}: LocalDateProps) {
  const pcTimezone = moment.tz.guess();
  const accountTimezone = useSelector((state: RootState) => state.accountReducer?.account?.timezone) ?? pcTimezone;

  const accountFormat = formatDate(date, { ...formatOptions, timezone: accountTimezone });
  const pcFormat = formatDate(date, { ...formatOptions, timezone: pcTimezone });
  const utcFormat = formatDate(date, { ...formatOptions, timezone: 'UTC' });

  const text = showTimeSince ? formatTimeSince(date) : pcFormat;

  const textProps: { color?: TextColor } = {};
  if (color) {
    textProps.color = color;
  }

  if (!withConversionTooltip) {
    return <Text.B2 {...textProps}>{text}</Text.B2>;
  }

  return (
    <InformationTooltip
      PopperProps={{ onClick: (e) => e.stopPropagation() }}
      arrow
      placement={tooltipPlacement}
      title={
        <div>
          <Text.H6 mx={{ fontSize: 14, fontWeight: 500 }}>{t('localDate.title')}</Text.H6>
          <Divider my={2} />
          <Text.B2>{t('localDate.yourAccountTime')}</Text.B2>
          <Text.B2 color="secondary">{withUtcOffset(accountFormat, accountTimezone)}</Text.B2>
          <Divider my={2} />
          <Text.B2>{t('localDate.yourPcTime')}</Text.B2>
          <Text.B2 color="secondary">{withUtcOffset(pcFormat, pcTimezone)}</Text.B2>
          <Divider my={2} />
          <Text.B2 color="disabled">UTC - {utcFormat}</Text.B2>
        </div>
      }>
      <Box display="inline-block">
        <DottedText {...textProps}>{text}</DottedText>
      </Box>
    </InformationTooltip>
  );
}
