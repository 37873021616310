import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  EditAddonGroupMutation,
  EditAddonGroupMutationVariables,
  EditPackageGroupDetailsInput,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { fetchAddonGroupByRefIdAction } from '../addonSlice';

const EDIT_ADDON_GROUP = gql`
  mutation EditAddonGroup($input: EditPackageGroupDetailsInput!) {
    editPackageGroup(input: $input) {
      packageGroupId
    }
  }
`;

async function editAddonGroup(
  input: Omit<EditPackageGroupDetailsInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<EditAddonGroupMutation, EditAddonGroupMutationVariables>({
        mutation: EDIT_ADDON_GROUP,
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId,
            ...input,
          },
        },
      });
      const packageGroup = response.data?.editPackageGroup;
      if (packageGroup) {
        await dispatch(fetchAddonGroupByRefIdAction({ refId: packageGroup.packageGroupId }));
      }
      return packageGroup;
    },
    {
      failureMessageHandler: () => t('addons.api.failEditAddonGroup'),
    },
    dispatch,
  );
}

export { editAddonGroup };
