import { useEffect, useState } from 'react';
import { Text, Field, Form, GridFlex, Icon, FormRenderProps, Button } from '@stigg-components';
import { t } from 'i18next';
import { refIdValidation } from '@stigg-common';
import { AddonGroupFragment, ProductListItemFragment } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchProductsAction } from '../../../products/productsSlice';
import Loader from '../../../../components/Loader';
import { generateRefIdFromName } from '../../../../components/DisplayNameTextField';
import { createAddonGroupAction, editAddonGroupAction } from '../addonSlice';

export type CreateOrEditPackageGroupFormFields = {
  productId: string;
  displayName: string;
  description?: string;
  packageGroupId: string;
};

const validationSchema = () =>
  Yup.object().shape({
    displayName: Yup.string().required(t('products.yup.required')),
    packageGroupId: refIdValidation(),
    productId: Yup.string().required(t('products.yup.required')),
    description: Yup.string().nullable(true),
  });

export function CreateOrEditAddonGroup({
  onCancel,
  addonGroup,
}: {
  onCancel: () => void;
  addonGroup?: AddonGroupFragment;
}) {
  const dispatch = useAppDispatch();
  const products = useSelector((state: RootState) => state.productReducer.products);
  const isLoadingProducts = useSelector((state: RootState) => state.productReducer.isLoading);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(!!addonGroup?.description);
  const [isRefIdFieldOpen, setIsRefIdFieldOpen] = useState(false);
  const prefilledProductId = addonGroup?.product.refId || (products?.length === 1 ? products[0].refId : '');
  const initialValues: CreateOrEditPackageGroupFormFields = {
    productId: prefilledProductId,
    displayName: addonGroup?.displayName || '',
    description: addonGroup?.description || '',
    packageGroupId: addonGroup?.packageGroupId || '',
  };

  const handleSubmit = async (values: CreateOrEditPackageGroupFormFields) => {
    if (addonGroup) {
      await dispatch(
        editAddonGroupAction({
          packageGroupId: addonGroup.packageGroupId,
          displayName: values.displayName,
          description: values.description,
        }),
      );
    } else {
      await dispatch(
        createAddonGroupAction({
          productId: values.productId,
          displayName: values.displayName,
          description: values.description,
          packageGroupId: values.packageGroupId,
        }),
      );
    }
    onCancel();
  };

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchProductsAction({ environmentId: currentEnvironmentId, silentFetch: true }));
    }
  }, [dispatch, currentEnvironmentId]);

  const fields: (
    props: FormRenderProps<CreateOrEditPackageGroupFormFields>,
  ) => Field<CreateOrEditPackageGroupFormFields>[] = ({ values }) => [
    {
      id: 'productId',
      type: 'select',
      label: 'Product',
      restProps: {
        placeholder: t('addons.addonGroups.productPlaceholder'),
      },
      startAdornment: <Icon icon="Grid" color="active" />,
      disabled: !!addonGroup,
      hide: () => products.length < 2,
      values: products.map((product: ProductListItemFragment) => ({
        value: product.refId,
        displayValue: <Text.B2>{product.displayName}</Text.B2>,
      })),
    },
    {
      id: 'displayName',
      type: 'text',
      label: t('addons.addonGroups.addonGroupNameLabel'),
      placeholder: t('addons.addonGroups.addonGroupNameLabel'),
      handleChange: ({ setFieldValue, setFieldTouched, validateForm, values }) => {
        return (e) => {
          setFieldValue('displayName', e.target.value, false);
          const refId = e.target.value
            ? generateRefIdFromName(e.target.value, t('addons.addonGroups.addonGroupPrefix'))
            : '';
          setFieldValue('packageGroupId', refId, false);
          setFieldTouched('packageGroupId', true, false);
          setFieldTouched('displayName', true, false);
          void validateForm({
            ...values,
            refId,
            displayName: e.target.value,
          });
        };
      },
    },
    {
      type: 'collapse',
      hideIcon: true,
      title: isDescriptionOpen ? null : (
        <GridFlex.RowCenter>
          <Icon type="reactFeather" icon={isDescriptionOpen ? 'Minus' : 'Plus'} color="primary.main" size={16} />
          <Text.B2 ml={2} color="primary.main">
            {isDescriptionOpen ? t('sharedComponents.removeDescription') : t('sharedComponents.addDescription')}
          </Text.B2>
        </GridFlex.RowCenter>
      ),
      onClick: () => setIsDescriptionOpen((prevState) => !prevState),
      open: isDescriptionOpen,
      fields: [
        {
          id: 'description',
          type: 'text',
          optional: true,

          placeholder: t('sharedComponents.description'),
          textFieldProps: { multiline: true, maxRows: 2 },
        },
      ],
    },
    {
      type: 'collapse',
      hideIcon: true,
      hide: () => !!addonGroup,
      titleGridSx: { position: 'relative' },
      title: (
        <GridFlex.RowCenter $fullWidth justifyContent="space-between">
          <GridFlex.RowCenter>
            <Text.B2 color="secondary">{t('sharedComponents.id')}</Text.B2>
            {isRefIdFieldOpen ? null : (
              <Text.B2 color="secondary" ml={2}>
                {values.packageGroupId || t('sharedComponents.autoGenerated')}
              </Text.B2>
            )}
          </GridFlex.RowCenter>
          {!isRefIdFieldOpen && (
            <GridFlex.RowCenter sx={{ position: 'absolute', right: 0 }}>
              <Button variant="text">{t('sharedComponents.edit')}</Button>
            </GridFlex.RowCenter>
          )}
        </GridFlex.RowCenter>
      ),
      onClick: () => setIsRefIdFieldOpen((prevState) => !prevState),
      open: isRefIdFieldOpen,
      fields: [
        {
          id: 'packageGroupId',
          type: 'text',
          optional: true,
          placeholder: t('sharedComponents.autoGenerated'),
          textFieldProps: { multiline: true, maxRows: 2 },
        },
      ],
    },
  ];

  if (isLoadingProducts) {
    return <Loader />;
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      fields={fields}
      submitButtonText={addonGroup ? t('sharedComponents.saveChanges') : t('sharedComponents.createSaveButton')}
    />
  );
}
