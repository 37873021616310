import { useMemo } from 'react';
import { Grid, Box, Text, GridFlex } from '@stigg-components';
import { BillingModel } from '@stigg-types/apiTypes';
import partition from 'lodash/partition';
import { Package } from 'react-feather';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import sortBy from 'lodash/sortBy';
import take from 'lodash/take';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { StripeProductListItem } from './ImportProductsStep';
import { PackagePrices } from './PackagePrices';
import { getPricingModelIcon } from './getPricingModelIcon';

const MAX_PLANS_COUNT = 6;

const PackageIcon = styled(Package)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

function PlanBox({
  plan,
  billingModel,
  featureUnitName,
}: {
  plan: StripeProductListItem;
  billingModel: BillingModel;
  featureUnitName: string;
}) {
  return (
    <GridFlex.Column
      gridColumn="span 4"
      p={6}
      sx={{
        height: '100%',
        maxHeight: 220,
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        borderRadius: '10px',
        overflow: 'auto',
      }}>
      <Grid alignItems="flex-start" mb={4}>
        <Box mr={3}>
          <PackageIcon width={20} height={20} />
        </Box>
        <Text.H6 $bold>{plan.name}</Text.H6>
      </Grid>

      <PackagePrices aPackage={plan} billingModel={billingModel} featureUnitName={featureUnitName} />
    </GridFlex.Column>
  );
}

function PricingTypeChip({ billingModel }: { billingModel: BillingModel }) {
  return (
    <GridFlex.RowCenter
      px={2}
      py={1}
      sx={{
        borderRadius: '4px',
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        background: (theme) => theme.itamar.palette.action.hover,
      }}>
      {getPricingModelIcon(billingModel)}
      <Text.B2 ml={1}>{t(`pricing.billingModelDescriptions.${billingModel}`)}</Text.B2>
    </GridFlex.RowCenter>
  );
}

export function PlansSummary({ showCountTitle = true }: { showCountTitle?: boolean }) {
  const importContext = useImportContext();
  const [addons, selectedPlans] = useMemo(
    () => partition(importContext?.selectedProducts || [], (product) => importContext?.addonIds.includes(product.id)),
    [importContext],
  );

  const plans = sortBy(take(selectedPlans, MAX_PLANS_COUNT), (plan) => plan.prices[0]?.amount || 0);
  const hasMorePlans = selectedPlans.length > MAX_PLANS_COUNT;
  const plansCount = importContext?.productsData.headerSelectionChecked
    ? importContext?.productsData.totalCount - importContext.unSelectedProducts.length - addons.length
    : selectedPlans.length;
  const morePlansCount = selectedPlans.length - plans.length;

  return (
    <GridFlex.Column>
      {showCountTitle && (
        <GridFlex.RowCenter mb={4} justifyContent="space-between">
          <Text.H6 ml={2}>
            {plansCount} plan{plansCount > 1 ? 's' : ''}
          </Text.H6>
          <PricingTypeChip billingModel={importContext.billingModel} />
        </GridFlex.RowCenter>
      )}
      <GridFlex.RowCenter
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={3}
        $fullWidth
        justifyContent="flex-start"
        flexWrap="wrap">
        {plans.map((plan) => (
          <PlanBox
            key={plan.id}
            plan={plan}
            billingModel={importContext.billingModel}
            featureUnitName={importContext.featureUnitName}
          />
        ))}
      </GridFlex.RowCenter>
      {hasMorePlans && (
        <Text.B2 mt={5} $bold>
          + {morePlansCount} plan{morePlansCount > 1 ? 's' : ''}
        </Text.B2>
      )}
    </GridFlex.Column>
  );
}
