import { t } from 'i18next';
import { Grid } from '@stigg-components';
import { MinimumSpend, MinimumSpendChange } from '@stigg-types/apiTypes';
import { ChangeGroupBox, ChangeRow, ChangeSection, getChange } from './DiffBaseComponents';
import { formatPrice } from '../../../pricing/utils/priceFormatUtils';

type MinimumSpendChangeProps = {
  minimumSpendDiff: MinimumSpendChange[];
  prefix: JSX.Element;
};

const MINIMUM_SPEND_PRICE_FORMAT = {
  shortFormat: true,
  unitFormat: true,
};

function getMinimumSpendFormatted(change?: MinimumSpend | null) {
  if (!change) {
    return null;
  }

  const price = formatPrice(change.minimum, change.billingPeriod, MINIMUM_SPEND_PRICE_FORMAT);
  const period = t(`pricing.shortBillingPeriodPriceDescription.${change.billingPeriod}`);
  return `${price} / ${period}`;
}

function MinimumSpendRow({ change }: { change: MinimumSpendChange }) {
  const { changeType, before, after } = change;

  return (
    <ChangeGroupBox changeType={changeType}>
      <ChangeRow
        before={t(`pricing.billingPeriodDescriptions.${before?.billingPeriod}`).toString()}
        after={t(`pricing.billingPeriodDescriptions.${after?.billingPeriod}`).toString()}
        label={t('pricing.packageDifference.billingPeriod')}
        showAlways
        {...getChange(before?.billingPeriod, after?.billingPeriod)}
      />
      <ChangeRow
        before={getMinimumSpendFormatted(before)}
        after={getMinimumSpendFormatted(after)}
        label={t('pricing.packageDifference.minimumSpend')}
        changeType={changeType}
        showAlways
      />
    </ChangeGroupBox>
  );
}

export function MinimumSpendDiff({ minimumSpendDiff, prefix }: MinimumSpendChangeProps) {
  const changes = minimumSpendDiff.map((change, index) => (
    <Grid key={index}>
      <MinimumSpendRow change={change} />
    </Grid>
  ));

  return (
    <ChangeSection prefix={prefix} title={t('packages.changesPackageMinimumSpend')}>
      {changes}
    </ChangeSection>
  );
}
