import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { ApolloQueryResult, gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import {
  AutoCancellationTargetQuery,
  AutoCancellationTargetQueryVariables,
  PackageStatus,
  PlanFilter,
  PlanSortFields,
  SortDirection,
} from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { apolloClient } from '../../../ApolloClient';

const FETCH_AUTO_CANCELLATION_TARGETS = gql`
  query AutoCancellationTarget($paging: CursorPaging, $sorting: [PlanSort!], $filter: PlanFilter!) {
    plans(paging: $paging, sorting: $sorting, filter: $filter) {
      edges {
        node {
          refId
          displayName
          product {
            displayName
          }
        }
      }
    }
  }
`;

const transformEventLogResult = (result: ApolloQueryResult<AutoCancellationTargetQuery>) =>
  result.data?.plans?.edges?.map((edge) => edge.node);

export const useGetAutoCancellationTargets = (environmentId: string, search?: string) => {
  const dispatch = useDispatch();
  const filter: PlanFilter = {
    environmentId: { eq: environmentId },
    status: { neq: PackageStatus.Draft },
    isLatest: { is: true },
    product: {
      multipleSubscriptions: { is: true },
    },
  };

  if (!isEmpty(search)) {
    filter.displayName = { iLike: `%${search}%` };
  }

  return useQuery({
    queryKey: ['autoCancellationTargets', search, environmentId],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<AutoCancellationTargetQuery, AutoCancellationTargetQueryVariables>({
            fetchPolicy: 'network-only',
            query: FETCH_AUTO_CANCELLATION_TARGETS,
            variables: {
              filter,
              paging: { first: 100 },
              sorting: [{ field: PlanSortFields.ProductId, direction: SortDirection.Asc }],
            },
          }),
        {},
        dispatch,
      ),
    select: transformEventLogResult,
    retry: 0, // we automatically retry in apollo client
  });
};
