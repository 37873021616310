import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchPaywallConfigurationQuery,
  FetchPaywallConfigurationQueryVariables,
  PaywallConfiguration,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { TYPOGRAPHY_CONFIGURATION } from './typography';

const FETCH_PAYWALL_CONFIGURATION = gql`
  query FetchPaywallConfiguration($input: GetWidgetConfigurationInput!) {
    widgetConfiguration(input: $input) {
      paywall {
        ...PaywallConfigurationFragment
      }
    }
  }
  fragment PaywallConfigurationFragment on PaywallConfiguration {
    palette {
      primary
      textColor
      backgroundColor
      borderColor
      currentPlanBackground
    }
    typography {
      ...TypographyConfigurationFragment
    }
    layout {
      alignment
      planWidth
      planMargin
      planPadding
    }
    customCss
  }
  ${TYPOGRAPHY_CONFIGURATION}
`;

export type FetchPaywallConfigurationProps = {
  environmentId: string;
};

async function fetchPaywallConfiguration(
  props: FetchPaywallConfigurationProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<PaywallConfiguration | undefined | null> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<
        FetchPaywallConfigurationQuery,
        FetchPaywallConfigurationQueryVariables
      >({
        query: FETCH_PAYWALL_CONFIGURATION,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: props.environmentId,
          },
        },
      });

      return response.data.widgetConfiguration.paywall;
    },
    {
      failureMessageHandler: () => t('widgets.api.failedFetchConfiguration'),
    },
    dispatch,
  );
}

export { fetchPaywallConfiguration };
