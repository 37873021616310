import { gql } from '@apollo/client';
import {
  UpdateExperimentInput,
  UpdateOneExperimentMutation,
  UpdateOneExperimentMutationVariables,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';
import { fetchExperimentByRefIdAction } from '../experimentsSlice';

const UPDATE_EXPERIMENT = gql`
  mutation UpdateOneExperimentMutation($input: UpdateExperimentInput!) {
    updateOneExperiment(input: $input) {
      refId
    }
  }
`;

async function updateExperiment(
  experimentData: Omit<UpdateExperimentInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<void> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const experiment: UpdateExperimentInput = {
        ...experimentData,
        environmentId: accountReducer.currentEnvironmentId,
      };

      const response = await apolloClient.mutate<UpdateOneExperimentMutation, UpdateOneExperimentMutationVariables>({
        mutation: UPDATE_EXPERIMENT,
        variables: { input: experiment },
      });

      const updatedExperiment = response.data?.updateOneExperiment;
      if (updatedExperiment) {
        await dispatch(fetchExperimentByRefIdAction({ refId: updatedExperiment.refId, silentFetch: true }));
        dispatch(navigateTo(appRoutes.experimentPage(updatedExperiment.refId)));
      }
    },
    {
      successMessage: t('experiments.api.successUpdate'),
      failureMessageHandler: () => t('experiments.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateExperiment };
