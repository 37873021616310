import { Collapse, Field, FormRenderProps, Grid, Text } from '@stigg-components';
import { styled } from '@stigg-theme';
import isNil from 'lodash/isNil';
import { GridFlex } from '../Grids';
import { handleFormField } from './Form.utils';

const FormFieldLayoutGrid = styled(GridFlex.RowMiddle)`
  &:not(:last-child) .layout-content {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

export type LayoutOptions = {
  labelWidth?: number | string;
};

export function FormFieldLayout<FormValues extends Record<string, any>>({
  field,
  formRenderProps,
  index,
  layoutOptions = {},
}: {
  field: Field<FormValues>;
  formRenderProps: FormRenderProps<FormValues>;
  index: number;
  layoutOptions?: LayoutOptions;
}) {
  const { labelWidth = '50%' } = layoutOptions;

  const hasLabel = 'label' in field;
  const label = hasLabel ? field.label : null;
  if (hasLabel && field.layout === 'row') {
    field.label = '';
  }

  const formField = handleFormField(field, formRenderProps);

  if (field.layout === 'row') {
    let content = (
      <GridFlex.RowMiddle $fullWidth className="layout-content">
        {hasLabel ? (
          <GridFlex.Item width={labelWidth}>
            <Text.B2>{label}</Text.B2>
          </GridFlex.Item>
        ) : null}
        <GridFlex.Item flex={1}>{formField}</GridFlex.Item>
      </GridFlex.RowMiddle>
    );

    if (!isNil(field.collapsed)) {
      content = <StyledCollapse in={!field.collapsed}>{content}</StyledCollapse>;
    }

    return (
      <FormFieldLayoutGrid key={index} item xs={field.xs} {...(field.gridProps || {})}>
        {content}
      </FormFieldLayoutGrid>
    );
  }

  return (
    <Grid key={index} item xs={field.xs} {...(field.gridProps || {})}>
      {formField}
    </Grid>
  );
}
