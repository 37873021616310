import { useEffect } from 'react';
import { PackageStatus } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { startPollingAddonSyncStates } from '../../addons/components/addonSyncStates';
import { startPollingPlanSyncStates } from '../../plans/components/planSyncStates';

const DEFAULT_POLL_INTERVAL = 2000;

export function usePollPackageSyncStates(
  packageId: string,
  packageType: 'PLAN' | 'ADDON',
  packageStatus: PackageStatus,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
  onChange?: CallableFunction,
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let observableQuery: any;
    if (packageStatus === PackageStatus.Draft) {
      return () => {};
    }

    if (packageType === 'PLAN') {
      observableQuery = startPollingPlanSyncStates(packageId, dispatch, pollInterval);
    } else {
      observableQuery = startPollingAddonSyncStates(packageId, dispatch, pollInterval);
    }

    return () => observableQuery?.stopPolling();
  }, [packageId, packageType, packageStatus, onChange, pollInterval, dispatch]);
}
