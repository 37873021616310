import { AddonChangeVariables, SubscriptionAddonDataFragment } from '@stigg-types/apiTypes';
import { Grid, Link, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { useNavigation } from '../../../../../../../../navigation/useNavigation';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../../SubscriptionFutureUpdateRowContainer';

type AddonQuantityUpdatedRowProps = {
  subscriptionAddon: SubscriptionAddonDataFragment;
  changeVariables: AddonChangeVariables;
  alertVariant?: boolean;
};

export function AddonQuantityUpdatedRow({
  subscriptionAddon,
  changeVariables,
  alertVariant,
}: AddonQuantityUpdatedRowProps) {
  const navigation = useNavigation();

  const { quantity, addon } = subscriptionAddon;
  const { newQuantity } = changeVariables;
  const isIncrease = newQuantity > quantity;

  return (
    <SubscriptionFutureUpdateRowContainer changeType={isIncrease ? ChangeType.INCREASE : ChangeType.DECREASE}>
      <Grid alignItems="baseline" gap={1}>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>
          {t(
            isIncrease
              ? 'subscriptions.schedules.addonQuantityIncreasePrefix'
              : 'subscriptions.schedules.addonQuantityReducePrefix',
          )}
        </Text.B2>
        <Link
          variant="body2"
          onClick={() => {
            navigation.navigateTo(navigation.appRoutes.addonPage(addon.refId));
          }}>
          <Text.B2 $bold color={alertVariant ? 'warning.content' : undefined}>
            {addon.displayName}
          </Text.B2>
        </Link>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>
          {t('subscriptions.schedules.addonQuantityEnd', {
            newQuantity,
          })}
        </Text.B2>
      </Grid>
    </SubscriptionFutureUpdateRowContainer>
  );
}
