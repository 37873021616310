import { Edit2, Archive } from 'react-feather';
import { OptionsDropdown } from '@stigg-components';
import { t } from 'i18next';

export type CouponOptionsDropdownProps = {
  onEditClick: (status: boolean) => void;
  onDeleteClick: (status: boolean) => void;
};

export function CouponOptionsDropdown({ onEditClick, onDeleteClick }: CouponOptionsDropdownProps) {
  return (
    <OptionsDropdown
      options={[
        {
          icon: Edit2,
          text: t('sharedComponents.edit'),
          onClick: () => (onEditClick !== undefined ? onEditClick(true) : null),
        },
        {
          icon: Archive,
          text: t('coupons.archive'),
          onClick: () => (onDeleteClick !== undefined ? onDeleteClick(true) : null),
        },
      ]}
    />
  );
}
