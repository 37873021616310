import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import { FetchSlimProductsQuery, FetchSlimProductsQueryVariables } from '@stigg-types/apiTypes';
import { Navigation } from '../navigation/useNavigation';
import { apolloClient } from '../../ApolloClient';
import { executeSafely } from './utils';
import { FETCH_SLIM_PRODUCTS } from '../products/queries/fetchProducts';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import productsIconDark from './icons/products.dark.svg';
import productsIconLight from './icons/products.light.svg';

export function setProductsFetch(environmentId: string, navigation: Navigation, isLightTheme?: boolean) {
  const doFetchProducts: SearchFunction = async (search: string) => {
    const response = await apolloClient.query<FetchSlimProductsQuery, FetchSlimProductsQueryVariables>({
      query: FETCH_SLIM_PRODUCTS,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          environmentId: { eq: environmentId },
          displayName: { iLike: `%${search}%` },
        },
      },
    });
    const products = response.data.products.edges.map((edge) => edge.node);

    return products.map((product) => {
      const { refId: productId, displayName } = product;
      return { productId, label: displayName || productId, description: displayName ? productId : '' };
    });
  };

  window.CommandBar.addRecords('products', [], {
    onInputChange: executeSafely(doFetchProducts),
    defaultIcon: getCommandBarIcon(isLightTheme ? productsIconLight : productsIconDark),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.productPage('{{record.productId}}'),
    },
  });
}
