import { t } from 'i18next';
import { Box, DetailsLayout, LongText } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { DetailsLayoutType } from '@stigg-components/types';

export function CustomerDetails({ customer }: { customer: CustomerResponseFragment }) {
  const details: DetailsLayoutType = [
    {
      title: t('customers.customerPreviewId'),
      content: customer.customerId,
      copyableChip: true,
      withLabel: true,
      alignItems: 'center',
    },
  ];
  if (customer.name) {
    details.push({
      title: t('customers.name'),
      content: (
        <LongText variant="body2" color="primary" className="fs-mask" wordBreak>
          {customer.name}
        </LongText>
      ),
    });
  }
  if (customer.email) {
    details.push({
      title: t('customers.email'),
      content: (
        <LongText variant="body2" color="primary" className="fs-mask" wordBreak>
          {customer.email}
        </LongText>
      ),
    });
  }
  return (
    <Box mb={4}>
      <DetailsLayout details={details} titleWidth={160} />
    </Box>
  );
}
