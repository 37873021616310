import { TextColor } from '@stigg-theme';
import React, { ReactElement, useCallback, useState } from 'react';
import { Dialog } from '../../components/Dialog';

type UseModalResult = [({ children }: { children: React.ReactNode }) => JSX.Element, (open: boolean) => void, boolean];

export function useModal(options?: {
  title?: string;
  titleColor?: TextColor;
  titleIcon?: ReactElement;
  height?: number;
  width?: number;
  onCancel?: () => void;
  closeable?: boolean;
  padding?: number;
}): UseModalResult {
  const [isOpen, setIsOpen] = useState(false);
  const { title, titleColor, titleIcon, height, width, onCancel, padding, closeable = true } = options || {};

  const handleClose = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    setIsOpen(false);
  }, [onCancel]);

  const ModalWrapper = useCallback(
    ({ children }: any) => (
      <Dialog
        content={children}
        onCancel={closeable ? handleClose : undefined}
        titleText={title}
        titleColor={titleColor}
        titleIcon={titleIcon}
        isOpen={isOpen}
        height={height}
        width={width}
        padding={padding}
      />
    ),
    [isOpen, title, titleColor, titleIcon, height, width, handleClose, padding, closeable],
  );

  return [ModalWrapper, setIsOpen, isOpen];
}
