import { Box, HeadCell, TwoLinesLayout, GridFlex, Icon } from '@stigg-components';
import { t } from 'i18next';
import { EntitlementDataFragment, EntitlementSummaryFragment } from '@stigg-types/apiTypes';
import { EntitlementValueBox, SourceBox } from './components';
import { getFeatureTypeIcon } from '../../../../../components/getFeatureTypeIcon';
import {
  FEATURE_TYPE_CELL_WIDTH,
  isEntitlementBudgetExceeded,
  SOURCE_CELL_WIDTH,
  SOURCE_ICONS_WIDTH,
  VALUE_CELL_WIDTH,
} from './CustomerEntitlementsSummary';

export const headCells: (
  activePlanIds: string[],
  trialPlanIds: string[],
  focusedProductId?: string,
) => Array<HeadCell<EntitlementDataFragment, any>> = (
  activePlanIds: string[],
  trialPlanIds: string[],
  focusedProductId?: string,
) => [
  {
    disablePadding: true,
    width: FEATURE_TYPE_CELL_WIDTH,
    maxWidth: FEATURE_TYPE_CELL_WIDTH,
    id: 'feature_type',
    alignment: 'center',
    label: t('entitlements.featureType'),
    render: (entity: EntitlementDataFragment) => {
      return entity.feature ? (
        <Box px={4}>
          {getFeatureTypeIcon({ ...entity.feature, hasMeter: !!entity.meterId }, { entitlementsTooltip: true })}
        </Box>
      ) : null;
    },
  },
  {
    id: 'feature',
    alignment: 'left',
    noWrap: true,
    label: t('customers.entitlementUsageTableFeatureColumn'),
    render: (entity: EntitlementDataFragment) => (
      <TwoLinesLayout firstRow={entity.feature?.displayName} secondRow={entity.feature?.refId} />
    ),
  },
  {
    width: 25,
    id: 'value-icon',
    alignment: 'right',
    disablePadding: true,
    render: (entity) => {
      const isBudgetExceeded = isEntitlementBudgetExceeded(entity);
      if (!isBudgetExceeded) {
        return null;
      }

      return (
        <GridFlex.RowMiddle>
          <Icon icon="BudgetExceeded" />
        </GridFlex.RowMiddle>
      );
    },
  },
  {
    width: VALUE_CELL_WIDTH,
    id: 'value',
    alignment: 'left',
    label: t('customers.summaryTableValueColumn'),
    render: (entity) => <EntitlementValueBox entitlement={entity} />,
  },
  {
    // will render only the icons
    width: SOURCE_ICONS_WIDTH,
    id: 'source_icons',
    alignment: 'right',
    label: '',
    render: (entity) => (
      <SourceBox
        iconsOnly
        summaries={entity.summaries as EntitlementSummaryFragment[]}
        focusedProductId={focusedProductId}
        activePlanIds={activePlanIds}
        trialPlanIds={trialPlanIds}
      />
    ),
  },
  {
    id: 'source',
    width: SOURCE_CELL_WIDTH,
    alignment: 'left',
    label: t('customers.summaryTableSourceColumn'),
    disablePadding: true,
    noWrap: true,
    render: (entity) => (
      <SourceBox
        summaries={entity.summaries as EntitlementSummaryFragment[]}
        focusedProductId={focusedProductId}
        activePlanIds={activePlanIds}
        trialPlanIds={trialPlanIds}
      />
    ),
  },
];
