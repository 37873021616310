import * as Yup from 'yup';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Box, Button, TextField, Grid, GridFlex } from '@stigg-components';
import { IntegrationFragment, VendorIdentifier, OpenFgaCredentialsInput } from '@stigg-types/apiTypes';
import { Optional } from '@stigg-types/primitives';
import { updateIntegrationAction, createIntegrationAction } from '../../integrationsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';

export type OpenFGAIntegrationFormFields = {
  apiUrl: string;
  apiAudience: string;
  apiTokenIssuer: string;
  storeId: string;
  clientId: string;
  clientSecret: string;
};

const validationSchema = Yup.object().shape({
  apiUrl: Yup.string().required(t('packages.yup.required')),
  apiAudience: Yup.string().required(t('packages.yup.required')),
  apiTokenIssuer: Yup.string().required(t('packages.yup.required')),
  clientId: Yup.string().required(t('packages.yup.required')),
  clientSecret: Yup.string().required(t('packages.yup.required')),
  storeId: Yup.string().required(t('packages.yup.required')),
});

export type OpenFGAIntegrationFormProps = {
  onCancel: () => void;
  integration?: IntegrationFragment | null;
};

export function OpenFGAIntegrationForm({ onCancel, integration }: OpenFGAIntegrationFormProps) {
  const credentials = integration?.credentials as Optional<OpenFgaCredentialsInput>;
  const initialValues = {
    apiUrl: credentials?.apiUrl || 'https://api.us1.fga.dev',
    apiAudience: credentials?.apiAudience || 'https://api.us1.fga.dev/',
    apiTokenIssuer: credentials?.apiTokenIssuer || 'fga.us.auth0.com',
    clientId: credentials?.clientId || '',
    clientSecret: credentials?.clientSecret || '',
    storeId: credentials?.storeId || '',
  } as OpenFGAIntegrationFormFields;

  const createLoading = useSelector((state: RootState) => state.integrations.createLoading);
  const updateLoading = useSelector((state: RootState) => state.integrations.updateLoading);
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: OpenFGAIntegrationFormFields) => {
    const { clientId, clientSecret, apiUrl, apiTokenIssuer, apiAudience, storeId } = values;
    const openFGACredentials = { clientId, clientSecret, apiUrl, apiTokenIssuer, apiAudience, storeId };
    if (integration) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration.id,
          integrationData: {
            vendorIdentifier: VendorIdentifier.OpenFga,
            openFGACredentials,
          },
        }),
      ).unwrap();
    } else {
      await dispatch(
        createIntegrationAction({
          vendorIdentifier: VendorIdentifier.OpenFga,
          openFGACredentials,
        }),
      ).unwrap();
    }
    onCancel();
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isValid, dirty, handleSubmit, getFieldProps, getFieldMeta }) => (
        <GridFlex.Column $fullWidth>
          {createLoading || updateLoading ? (
            <Box alignItems="center" minHeight={300}>
              <Loader />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column $fullWidth item pt={5} container rowSpacing={4}>
                <GridFlex.Column item>
                  <TextField
                    label={t('integrations.openFga.apiUrl.label')}
                    placeholder={t('integrations.openFga.apiUrl.placeholder')}
                    fullWidth
                    {...getFieldProps('apiUrl')}
                    {...getFieldMeta('apiUrl')}
                  />
                </GridFlex.Column>
                <GridFlex.Column item>
                  <TextField
                    label={t('integrations.openFga.apiAudience.label')}
                    placeholder={t('integrations.openFga.apiAudience.placeholder')}
                    fullWidth
                    {...getFieldProps('apiAudience')}
                    {...getFieldMeta('apiAudience')}
                  />
                </GridFlex.Column>
                <GridFlex.Column item>
                  <TextField
                    label={t('integrations.openFga.apiTokenIssuer.label')}
                    placeholder={t('integrations.openFga.apiTokenIssuer.placeholder')}
                    fullWidth
                    {...getFieldProps('apiTokenIssuer')}
                    {...getFieldMeta('apiTokenIssuer')}
                  />
                </GridFlex.Column>
                <Grid mr={2} sm item>
                  <TextField
                    label={t('integrations.openFga.clientId.label')}
                    placeholder={t('integrations.openFga.clientId.placeholder')}
                    fullWidth
                    {...getFieldProps('clientId')}
                    {...getFieldMeta('clientId')}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="password"
                    label={t('integrations.openFga.clientSecret.label')}
                    placeholder={t('integrations.openFga.clientSecret.placeholder')}
                    fullWidth
                    {...getFieldProps('clientSecret')}
                    {...getFieldMeta('clientSecret')}
                  />
                </Grid>
                <GridFlex.Column item>
                  <TextField
                    label={t('integrations.openFga.storeId.label')}
                    placeholder={t('integrations.openFga.storeId.placeholder')}
                    fullWidth
                    {...getFieldProps('storeId')}
                    {...getFieldMeta('storeId')}
                  />
                </GridFlex.Column>
                {integration ? (
                  <Grid item alignSelf="end">
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('packages.cancelButton')}
                    </Button>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.editSaveButton')}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button disabled={!isValid} type="submit" variant="contained" color="primary" fullWidth>
                      {t('integrations.openFga.connectButton')}
                    </Button>
                  </Grid>
                )}
              </GridFlex.Column>
            </form>
          )}
        </GridFlex.Column>
      )}
    </Formik>
  );
}
