import * as Yup from 'yup';
import { t } from 'i18next';
import { Formik, Form } from 'formik';
import { EntitlementResetPeriod, Feature, FeatureType } from '@stigg-types/apiTypes';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import { isMetered } from '../../../features/utils/isMetered';

export const entitlementValidationSchema = Yup.object().shape({
  feature: Yup.object().required(t('pricing.yup.required')),
  isConfirmed: Yup.boolean().oneOf([true]),
  usageLimit: Yup.number().when(['feature', 'hasUnlimitedUsage', 'isCustom'], {
    is: (feature: Feature, hasUnlimitedUsage: boolean, isCustom: boolean) =>
      feature?.featureType === FeatureType.Number && !hasUnlimitedUsage && !isCustom,
    then: Yup.number()
      .required('Value is required')
      .integer('Units can not be float')
      .min(0, 'Units can not be negative')
      .typeError('Units has to be a number'),
    otherwise: Yup.number().notRequired().nullable(true),
  }),
  resetPeriod: Yup.mixed<EntitlementResetPeriod>().notRequired(),
  resetPeriodConfiguration: Yup.mixed<ResetPeriodConfiguration>().when(['feature', 'resetPeriod'], {
    is: (feature: Feature, resetPeriod: EntitlementResetPeriod) =>
      feature &&
      isMetered(feature) &&
      (resetPeriod === EntitlementResetPeriod.Year ||
        resetPeriod === EntitlementResetPeriod.Month ||
        resetPeriod === EntitlementResetPeriod.Week),
    then: Yup.mixed<ResetPeriodConfiguration>().required(t('features.yup.required')),
    otherwise: Yup.mixed<ResetPeriodConfiguration>().notRequired(),
  }),
});

export const entitlementsValidationSchema = Yup.object().shape({
  entitlements: Yup.array().of(entitlementValidationSchema).required().min(1),
});

type EntitlementsFormProps = {
  initialValues: any;
  enableReinitialize?: boolean;
  handleSubmit: any;
  children: any;
};

export function EntitlementsForm({
  children,
  initialValues,
  enableReinitialize = true,
  handleSubmit,
}: EntitlementsFormProps) {
  return (
    <Formik
      validationSchema={entitlementsValidationSchema}
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ isValid, errors, touched, handleBlur, values, handleChange, setFieldValue, setFieldTouched }) => (
        <Form>
          {children({ isValid, errors, touched, handleBlur, values, handleChange, setFieldValue, setFieldTouched })}
        </Form>
      )}
    </Formik>
  );
}
