import styled from 'styled-components/macro';
import notFoundImage from '@assets/images/notFound.png';
import { t } from 'i18next';
import { Button, GridFlex, Text } from '.';

const Image = styled.img`
  height: 200px;
`;

export function NotFoundPage() {
  return (
    <GridFlex.ColumnMiddle height="100%">
      <Image src={notFoundImage} alt="Page not found" />

      <Text.H1 align="center" gutterBottom>
        {t('notFound.title')}
      </Text.H1>
      <Text.H6 align="center" gutterBottom>
        {t('notFound.description')}
      </Text.H6>
      <Button href="/" variant="contained" color="primary" sx={{ mt: 2 }}>
        {t('notFound.link')}
      </Button>
    </GridFlex.ColumnMiddle>
  );
}

export default NotFoundPage;
