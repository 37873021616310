import {
  BillingCadence,
  BillingModel,
  BillingPeriod,
  Currency,
  EntitlementResetPeriod,
  PriceFeatureFragment,
  PricingType,
  OverageBillingPeriod,
} from '@stigg-types/apiTypes';
import { ResetPeriodConfiguration } from '@stigg-common/types';

export type Amount = number | null;

export enum ChargeType {
  BasePrice = 'basePrice',
  UsageBased = 'usageBased',
}

export enum TiersSchema {
  Standard = 'STANDARD',
  VolumeBulkOfUnits = 'VOLUME_BULK_OF_UNITS',
  VolumePerUnit = 'VOLUME_PER_UNIT',
  GraduatedPerUnit = 'GRADUATED_PER_UNIT',
  StandardPerBlock = 'STANDARD_PER_BLOCK',
}

export const TIERS_SCHEMA_PER_UNIT = [TiersSchema.VolumePerUnit, TiersSchema.GraduatedPerUnit];

export type PricePeriod = { amount: Amount; blockSize: number | null };

export type PricePeriods = {
  [billingPeriod in BillingPeriod]?: PricePeriod;
};

export type PriceTiersPeriods<T = PriceTier> = {
  [billingPeriod in BillingPeriod]?: T[];
};

type CommonCharge = {
  uuid: string; // used for front-end only
  type: ChargeType;
  billingModel: BillingModel | null;
  pricePeriods: PricePeriods;
  isBlockPricing?: boolean;
  isConfirmed: boolean;
};

export type BasePriceChargeType = CommonCharge & {
  type: ChargeType.BasePrice;
  billingModel: BillingModel.FlatFee;
};

export type PriceTier = {
  endUnit?: number | null;
  unitPrice?: Amount | null;
  tierPrice?: Amount | null;
};

export type AbstractUsageBasedChargeType = CommonCharge & {
  type: ChargeType.UsageBased;
  billingModel: BillingModel.PerUnit | BillingModel.UsageBased | null;
  tiersSchema?: TiersSchema;
  tiers?: PriceTiersPeriods;
  feature: PriceFeatureFragment | null;
  resetPeriod?: EntitlementResetPeriod;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
};

export type UsageBasedInAdvanceCommitmentChargeType = AbstractUsageBasedChargeType & {
  billingModel: BillingModel.PerUnit;
  minUnitQuantity?: number | null;
  maxUnitQuantity?: number | null;
};

export type UsageBasedPayAsYouGoChargeType = AbstractUsageBasedChargeType & {
  billingModel: BillingModel.UsageBased;
};

export type UsageBasedChargeType = UsageBasedInAdvanceCommitmentChargeType | UsageBasedPayAsYouGoChargeType;

export type Charge = BasePriceChargeType | UsageBasedChargeType;

export type OverageEntitlement = {
  featureId: string;
  usageLimit: number;
  resetPeriod?: EntitlementResetPeriod;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
};

export type OverageCharges = {
  enabled: boolean;
  charges: OverageCharge[];
};

export type OverageCharge = CommonCharge & {
  type: ChargeType.UsageBased;
  billingModel: BillingModel.PerUnit;
  feature: PriceFeatureFragment | null;
  entitlement?: OverageEntitlement | null;
};

export const LOCALIZED_PRICE_TIER_FIELDS: (keyof Pick<PriceTier, 'unitPrice' | 'tierPrice'>)[] = [
  'unitPrice',
  'tierPrice',
];

type LocalizedPriceTierFields = Pick<PriceTier, 'unitPrice' | 'tierPrice'>;

export type CountryPriceLocalization = {
  uuid: string; // used for front-end only
  usedInSubscriptions?: boolean;
  billingCountryCode?: string;
  currency?: Currency;
  countryAndCurrencyConfirmed: boolean;
  isConfirmed: boolean;
  chargesTieredPricePeriods: {
    [chargeUuid: string]: PriceTiersPeriods<LocalizedPriceTierFields>;
  };
  chargesPricePeriods: {
    [chargeUuid: string]: PricePeriods;
  };
  openedCharges: { [chargeUuid: string]: boolean };
};

export type MinimumSpendPrice = {
  amount: Amount;
  currency: Currency;
  billingPeriod: BillingPeriod;
};

export type PriceLocalization = {
  enabled: boolean;
  countries: CountryPriceLocalization[];
};

export type FreeTrial = {
  enabled: boolean;
  durationDays: number;
  spendLimit?: number;
};

export type MinimumSpend = {
  enabled: boolean;
  minimums: { [billingPeriod in BillingPeriod]?: number };
};

export type SetPriceWizardFormFields = {
  pricingType: PricingType | null;
  defaultCurrency: Currency.Usd; // this will be changed once we support custom default currency
  freeTrial: FreeTrial;
  billingPeriods: BillingPeriod[];
  billingCadence: BillingCadence;
  charges: Charge[];
  overageCharges: OverageCharges;
  priceLocalization: PriceLocalization;
  overageBillingPeriod: OverageBillingPeriod;
  minimumSpend: MinimumSpend;
};
