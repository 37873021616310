import {
  GridFlex,
  Icon,
  IconButton,
  Text,
  InformationalTooltipIcon,
  InformationTooltip,
  Divider,
} from '@stigg-components';
import React from 'react';
import { t } from 'i18next';
import { useConfirmationDialog } from '@stigg-common';
import {
  ContainerBox,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  IconContainer,
} from './StepAccordion.style';
import { DeferRender } from '../helpers/DeferRender';
import { NOOP } from '../../hooks/useExitConfirmationDialog.utils';

export type StepAccordionProps = {
  onToggle: () => void;
  onRemove?: () => void;
  confirmRemoveDialogTitle: string;
  confirmRemoveDialogContent: string;
  withRemoveConfirmation?: boolean;
  header: React.ReactNode;
  children: React.ReactNode;
  isFocused: boolean;
  permanent?: boolean;
  hasErrors?: boolean;
  minHeaderHeight?: number;
  isRemoveDisabled?: boolean;
  removeDisabledTooltip?: string;
  withSeparator?: boolean;
  dataTestId?: string;
};

export const StepAccordion = React.forwardRef<HTMLDivElement, StepAccordionProps>(
  (
    {
      onToggle,
      onRemove,
      confirmRemoveDialogTitle,
      confirmRemoveDialogContent,
      header,
      children,
      isFocused,
      permanent,
      hasErrors,
      minHeaderHeight,
      isRemoveDisabled,
      removeDisabledTooltip,
      dataTestId,
      withRemoveConfirmation = true,
      withSeparator,
    },
    ref,
  ) => {
    const [ConfirmRemoveDialog, setConfirmRemoveDialogOpen, confirmRemoveDialogProps] = useConfirmationDialog({
      title: confirmRemoveDialogTitle,
      content: confirmRemoveDialogContent,
      handleConfirm: onRemove || NOOP,
      confirmButtonText: t('sharedComponents.remove'),
      confirmButtonColor: 'error',
    });

    const shouldBeExpanded = permanent || isFocused;
    const [expanded, setExpanded] = React.useState<boolean>(shouldBeExpanded);

    const expandIcon = !permanent ? <Icon icon="ExpandMore" color="secondary" /> : undefined;
    const onExpandToggle = !permanent ? onToggle : undefined;

    const onRemoveButtonClick = onRemove
      ? (e) => {
          if (withRemoveConfirmation) {
            setConfirmRemoveDialogOpen(true);
          } else {
            onRemove();
          }
          // Prevent accordion from expanding/collapsing
          e.stopPropagation();
        }
      : undefined;

    return (
      <ContainerBox>
        <StyledAccordion ref={ref} expanded={expanded}>
          <StyledAccordionSummary
            data-testid={dataTestId}
            expandIcon={expandIcon}
            onClick={onExpandToggle}
            $minHeight={minHeaderHeight}
            $disablePointerEvents={permanent}>
            <GridFlex.RowSpaceBetween $fullWidth>
              {header}
              {!permanent && (
                <GridFlex.RowCenter>
                  {hasErrors && !expanded && (
                    <IconContainer>
                      <InformationalTooltipIcon
                        icon={<Icon icon="Error" color="error" size={20} />}
                        text={t('sharedComponents.errorsRequiresAttention')}
                        placement="left"
                      />
                    </IconContainer>
                  )}
                  {onRemoveButtonClick && (
                    <InformationTooltip
                      arrow
                      placement="top"
                      $maxWidth={250}
                      title={isRemoveDisabled && <Text.B2>{removeDisabledTooltip}</Text.B2>}>
                      <IconContainer $disabled={isRemoveDisabled}>
                        <IconButton
                          disableRipple
                          disabled={isRemoveDisabled}
                          onClick={onRemoveButtonClick}
                          sx={{ padding: 0 }}>
                          <Icon
                            icon="Trash2"
                            type="reactFeather"
                            color={isRemoveDisabled ? 'disabled' : 'secondary'}
                            size={18}
                          />
                        </IconButton>
                      </IconContainer>
                    </InformationTooltip>
                  )}
                </GridFlex.RowCenter>
              )}
            </GridFlex.RowSpaceBetween>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <DeferRender shouldRender={shouldBeExpanded} setIsReady={setExpanded}>
              {withSeparator && <Divider mt={2} mb={6} />}
              {children}
            </DeferRender>
          </StyledAccordionDetails>
        </StyledAccordion>

        <ConfirmRemoveDialog {...confirmRemoveDialogProps} />
      </ContainerBox>
    );
  },
);
