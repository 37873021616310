import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { executeOperationSafely } from '@stigg-common';
import { CursorPaging, EventLogsQuery, EventLogsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { FETCH_EVENT_LOGS } from './fetchEventLogs';
import { fetchEventLogFilters } from './fetchEventLogFilters';
import { RootState } from '../../../redux/store';
import { transformEventLogResult } from './transformEventLogResult';

export const DEFAULT_PAGE_SIZE = 15;

type GetEventLogFilters = {
  entityId?: string;
  parentEntityId?: string;
  cursor?: CursorPaging;
};

export const useGetEventLogs = ({ entityId, parentEntityId, cursor }: GetEventLogFilters) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  return useQuery({
    queryKey: ['eventLogs', environmentId, entityId, parentEntityId, cursor],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<EventLogsQuery, EventLogsQueryVariables>({
            fetchPolicy: 'network-only',
            query: FETCH_EVENT_LOGS,
            variables: {
              filter: fetchEventLogFilters(environmentId, entityId, parentEntityId),
              paging: cursor,
            },
          }),
        {},
        dispatch,
      ),
    select: transformEventLogResult,
    retry: 0, // we automatically retry in apollo client
  });
};
