import { useState, useMemo } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { GridFlex, Text } from '@stigg-components';
import { SdkType, SDK_TO_SNIPPET_DATA } from '../featureDetails/sdkToSnippetData';
import { CodeBlock } from '../featureDetails/CodeBlockSection';
import { CodeSnippetType, getFeatureCodeSnippet } from '../featureDetails/getFeatureCodeSnippet';
import { RootState } from '../../../../redux/store';
import { FeatureType } from '../../../../types/apiTypes';

type EventsNotFoundProps = {
  withFiltersMode?: boolean;
};

export function EventsNotFound({ withFiltersMode }: EventsNotFoundProps) {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const environmentApiKeys = currentEnvironment?.apiKeys ?? [];
  const availableReportEventSdks = useMemo(
    () =>
      Object.entries(SDK_TO_SNIPPET_DATA)
        .filter(([, data]) => data.featureSnippetCode?.reportEventForMeteredFeature)
        .map(([sdkType]) => sdkType as SdkType),
    [],
  );
  const [sdkType, setSdkType] = useState(availableReportEventSdks?.[0] ? availableReportEventSdks[0] : SdkType.NODE);

  return (
    <GridFlex.Column $fullHeight $fullWidth alignItems="center">
      <GridFlex.Column $fullWidth alignItems="center">
        <Text.H6 color="secondary" mb={2} mt={4}>
          {withFiltersMode ? t('features.noEventsFoundMatchingFilter') : t('features.noEventsReported')}
        </Text.H6>
        <Text.B2 color="secondary" textAlign="center" mb={4}>
          {withFiltersMode ? t('features.refineFilters') : t('features.noEventsReportedSubtitle')}
          <br />
          {withFiltersMode ? t('features.refineFiltersSubtitle') : t('features.noEventsReportedSecondSubtitle')}
        </Text.B2>
      </GridFlex.Column>

      {!withFiltersMode && (
        <GridFlex.Column mt={8} width="80%">
          <CodeBlock
            sdkType={sdkType}
            setSdkType={setSdkType}
            code={getFeatureCodeSnippet(
              { featureType: FeatureType.Number, refId: '' },
              environmentApiKeys,
              CodeSnippetType.REPORT_EVENT,
              sdkType,
            )}
            availableSdks={availableReportEventSdks}
          />
        </GridFlex.Column>
      )}
    </GridFlex.Column>
  );
}
