import {
  CustomTable,
  GridFlex,
  HeadCell,
  Text,
  IconButton,
  Icon,
  DateText,
  PackageChip,
  OptionsDropdown,
} from '@stigg-components';
import { t } from 'i18next';
import { AddonFragment } from '../../../../types/apiTypes';
import { AddonValueCell } from './AddonValueCell';

type AddonDependenciesListProps = {
  addons: AddonFragment[];
  height?: number;
  remove?: (index: number) => Promise<void> | void;
  withStatus?: boolean;
  withLastUpdated?: boolean;
  removeOptions?: {
    hide?: boolean;
    inOptions?: boolean;
  };
};

export function AddonDependenciesList({
  addons,
  height,
  remove,
  withStatus = false,
  withLastUpdated = false,
  removeOptions = {},
}: AddonDependenciesListProps) {
  const shouldShowRemoveOption = !!remove && !removeOptions.hide;

  const headCells: HeadCell<AddonFragment, any>[] = [
    {
      id: 'displayName',
      alignment: 'left',
      label: t('addons.addonDependencies.addonsDependencyList.nameColumn'),
      render: (addon: AddonFragment) => <AddonValueCell addon={addon} />,
    },
    {
      id: 'type',
      alignment: 'left',
      label: t('addons.addonDependencies.addonsDependencyList.typeColumn'),
      render: (addon: AddonFragment, __: number) => {
        return <Text.B2>{addon.maxQuantity === 1 ? 'Single instance' : 'Multiple instances'}</Text.B2>;
      },
    },
    {
      id: 'status',
      alignment: 'left',
      label: t('packages.status'),
      visible: withStatus,
      render: (addon: AddonFragment, __: number) => {
        return <PackageChip hasPointer status={addon.status} />;
      },
    },
    {
      id: 'lastUpdated',
      alignment: 'left',
      label: t('packages.lastUpdated'),
      visible: withLastUpdated,
      render: (addon: AddonFragment) => <DateText date={addon.updatedAt} withTime formatType="longDate" />,
    },
    {
      id: 'remove',
      alignment: 'right',
      width: 50,
      disablePadding: true,
      visible: shouldShowRemoveOption && !!removeOptions.inOptions,
      render: (_: AddonFragment, index: number) => (
        <GridFlex.ColumnMiddle>
          <IconButton onClick={() => remove && remove(index)}>
            <Icon icon="Close" color="active" size={24} />
          </IconButton>
        </GridFlex.ColumnMiddle>
      ),
    },
    {
      id: 'properties',
      alignment: 'right',
      label: '',
      disableClick: true,
      width: 128,
      visible: shouldShowRemoveOption && !removeOptions.inOptions,
      render: (_: AddonFragment, index: number) => (
        <OptionsDropdown
          options={[
            {
              icon: () => <Icon icon="Trash2" size={20} type="reactFeather" />,
              text: t('sharedComponents.remove'),
              onClick: () => remove && remove(index),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <CustomTable
      rowHeight={60}
      headerHeight={43}
      label="Addons"
      maxHeight={height}
      headCells={headCells}
      data={addons}
    />
  );
}
