import { Children, useState } from 'react';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { TableRow as MuiTableRow, TableRowProps, IconButton } from '@stigg-components';
import * as S from '../Table.style';

export const CollapsableTableRow = <TEntity,>(
  props: TableRowProps & {
    collapsableComponent: (entity: TEntity) => JSX.Element;
    rowColor?: string | null;
    rowHeight?: number;
    expandOnClick: boolean;
    entity: TEntity;
    disableBorder?: boolean | undefined;
  },
) => {
  const [open, setOpen] = useState(false);
  const {
    collapsableComponent,
    entity,
    children,
    sx,
    hover,
    onClick,
    expandOnClick,
    rowColor,
    rowHeight,
    disableBorder,
  } = props;

  const collapsableOnClick = (event: any) => {
    onClick?.(event);
    if (expandOnClick) {
      setOpen(!open);
    }
  };

  return (
    <>
      <S.CollapsableTableRow
        sx={sx}
        $rowColor={rowColor}
        $rowHeight={rowHeight}
        hover={hover}
        onClick={collapsableOnClick}>
        {children}
        <S.TableCell $disableBorder={disableBorder}>
          <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </S.TableCell>
      </S.CollapsableTableRow>
      <MuiTableRow sx={sx} onClick={onClick}>
        <S.TableCell $disablePadding $disableBorder colSpan={Children.toArray(children).length + 1}>
          <S.Collapse in={open} unmountOnExit timeout="auto">
            {collapsableComponent(entity)}
          </S.Collapse>
        </S.TableCell>
      </MuiTableRow>
    </>
  );
};
