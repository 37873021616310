import * as Sentry from '@sentry/react';
import { watchPlanSyncStates } from '../queries/watchPlanSyncStates';
import { AppDispatch } from '../../../../redux/store';
import { updatePlanSyncState } from '../plansSlice';

export function startPollingPlanSyncStates(planId: string, dispatch: AppDispatch, pollInterval: number) {
  const observableQuery = watchPlanSyncStates(planId, pollInterval);

  observableQuery.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.plans) {
        Sentry.captureException('got unexpected response for polling plan sync states', { extra: { value } });
      }

      const plans = value.data.plans.edges;
      const fetchedPlan = plans.length > 0 ? plans[0].node : null;
      if (fetchedPlan) {
        dispatch(updatePlanSyncState(fetchedPlan));
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling plan sync states' } });
    },
  );

  return observableQuery;
}
