import { DottedText, GridFlex, Text } from '@stigg-components';
import { CouponFragment, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { PriceBreakdown, useTotalPrice } from './priceBreakdown';
import { SubscriptionPriceBreakdownTooltip } from './SubscriptionPriceBreakdown';
import { SubscriptionPriceLocalizationIcon } from './SubscriptionPriceLocalizationIcon';

export function SubscriptionPrice({
  priceBreakdown,
  showMonthlyPriceVariation,
  showBillingPeriod,
  showDefaultPricingIcon,
  coupon,
}: {
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  showBillingPeriod?: boolean;
  showDefaultPricingIcon?: boolean;
  coupon?: CouponFragment | SubscriptionCouponDataFragment | null;
}) {
  const { total } = useTotalPrice({ priceBreakdown, withCodePostfix: true });
  if (!priceBreakdown) {
    return <Text.B2>{t('subscriptions.customPricing')}</Text.B2>;
  }
  const { price } = priceBreakdown?.planBreakdown?.prices?.[0];
  const { billingCountryCode } = price || {};

  return (
    <GridFlex.RowCenter gap={4}>
      <SubscriptionPriceLocalizationIcon
        billingCountryCode={billingCountryCode}
        showDefaultPricingIcon={showDefaultPricingIcon}
      />

      <SubscriptionPriceBreakdownTooltip
        coupon={coupon}
        priceBreakdown={priceBreakdown}
        showMonthlyPriceVariation={showMonthlyPriceVariation}>
        <GridFlex.Column container display="inline-flex" width="auto">
          <DottedText display="inline">{total}</DottedText>
          {showBillingPeriod && (
            <Text.B2 mt={1} color="secondary" display="inline">
              {t(`pricing.billedPeriod.${priceBreakdown.billingPeriod}`)}
            </Text.B2>
          )}
        </GridFlex.Column>
      </SubscriptionPriceBreakdownTooltip>
    </GridFlex.RowCenter>
  );
}
