import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { DottedText, GridFlex, Text, Button, CustomDrawer, ReloadButton, InformationTooltip } from '@stigg-components';
import { BillingModel, PreviewNextInvoiceFragment, SubscriptionDataFragment, SyncStatus } from '@stigg-types/apiTypes';
import { formatDate } from '@stigg-common';
import { previewNextInvoiceAction, triggerSubscriptionUsageSyncAction } from '../../../../customersSlice';
import { AppDispatch, RootState, useAppDispatch } from '../../../../../../redux/store';
import { currencyPriceFormatter } from '../../../../../packages/pricing/components/currency/currencyUtils';
import { InvoiceBreakdown } from './invoice';
import Loader from '../../../../../../components/Loader';

type SubscriptionNextBillProps = {
  subscription: SubscriptionDataFragment;
};

type UseUsageSyncProps = {
  subscription: SubscriptionDataFragment;
  nextInvoice?: PreviewNextInvoiceFragment;
  fetchNextInvoice: () => Promise<void>;
  dispatch: AppDispatch;
};

function useUsageSync({ subscription, nextInvoice, fetchNextInvoice, dispatch }: UseUsageSyncProps) {
  const [isPollingOn, setIsPollingOn] = useState(false);
  const lastSyncedUsageRef = useRef<Date | null>(null);

  useEffect(() => {
    if (lastSyncedUsageRef.current === null && nextInvoice?.lastUpdatedAt) {
      lastSyncedUsageRef.current = nextInvoice.lastUpdatedAt;
      return;
    }

    if (nextInvoice?.lastUpdatedAt && lastSyncedUsageRef.current !== nextInvoice.lastUpdatedAt) {
      setIsPollingOn(false);
      lastSyncedUsageRef.current = nextInvoice.lastUpdatedAt;
      return;
    }

    if (isPollingOn) {
      void fetchNextInvoice();
    }
  }, [nextInvoice, isPollingOn, fetchNextInvoice]);

  const onTriggerSubscriptionUsageSync = async () => {
    setIsPollingOn(true);
    await dispatch(
      triggerSubscriptionUsageSyncAction({
        customerId: subscription.customer.customerId,
        resourceId: subscription.resource?.resourceId,
      }),
    );
  };

  return { isPollingOn, onTriggerSubscriptionUsageSync };
}

export function SubscriptionNextBill({ subscription }: SubscriptionNextBillProps) {
  const { refId, latestInvoice, status } = subscription;

  const dispatch = useAppDispatch();
  const nextInvoiceBySubscription = useSelector((root: RootState) => root.customersReducer.nextInvoiceBySubscription);
  const { invoice: nextInvoice, isLoading } = nextInvoiceBySubscription[refId] || {};
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);

  const fetchNextInvoice = useCallback(async () => {
    await dispatch(previewNextInvoiceAction({ subscriptionId: refId }));
  }, [dispatch, refId]);

  useEffect(() => {
    void fetchNextInvoice();
  }, [fetchNextInvoice, latestInvoice, status]);

  const { onTriggerSubscriptionUsageSync, isPollingOn } = useUsageSync({
    subscription,
    nextInvoice,
    fetchNextInvoice,
    dispatch,
  });

  const handleOpenBreakdown = () => {
    setIsBreakdownOpen(true);
  };

  const handleCloseBreakdown = () => {
    setIsBreakdownOpen(false);
  };

  if (
    (isLoading && !nextInvoice) ||
    !nextInvoice ||
    subscription.syncStates?.some((s) => s.status === SyncStatus.Pending)
  ) {
    return (
      <GridFlex.Row container gap={1}>
        <Loader isInline size={12} />
      </GridFlex.Row>
    );
  }

  const hasPayAsYouGoPrices = subscription?.prices?.some((price) => price.billingModel === BillingModel.UsageBased);
  const { amountDue } = nextInvoice;
  const { amount, currency } = amountDue;

  const breakdownTitle = t('subscriptions.previewNextBill.breakdownTitle', {
    billingDate: formatDate(subscription.currentBillingPeriodEnd, {}),
  });

  const breakdownDescription = (
    <GridFlex.RowCenter gap={2}>
      <Text.B2 color="secondary">
        {t('subscriptions.lastUpdatedOn', { date: formatDate(nextInvoice.lastUpdatedAt, { withTime: true }) })}
      </Text.B2>
      {hasPayAsYouGoPrices ? (
        <ReloadButton color={undefined} isLoading={isPollingOn} onClick={onTriggerSubscriptionUsageSync} />
      ) : null}
    </GridFlex.RowCenter>
  );

  const footer = (
    <GridFlex.Row justifyContent="flex-end" pb={8}>
      <Button variant="outlined" onClick={handleCloseBreakdown}>
        {t('sharedComponents.close')}
      </Button>
    </GridFlex.Row>
  );

  return (
    <GridFlex.RowMiddle container gap={1}>
      <GridFlex.Column item>
        <InformationTooltip arrow placement="top" title={<Text.B2>{t('subscriptions.viewBreakdownTooltip')}</Text.B2>}>
          <Button
            sx={{ padding: '0px 8px' }}
            onClick={handleOpenBreakdown}
            data-testid="button-open-next-estimated-bill">
            <DottedText color="primary.main">
              {currencyPriceFormatter({ amount, currency, options: { withCodePostfix: true } })}
            </DottedText>
          </Button>
        </InformationTooltip>
      </GridFlex.Column>

      <CustomDrawer
        title={breakdownTitle}
        description={breakdownDescription}
        isOpen={isBreakdownOpen}
        onClose={handleCloseBreakdown}
        footer={footer}>
        <InvoiceBreakdown subscription={subscription} invoice={nextInvoice} />
      </CustomDrawer>
    </GridFlex.RowMiddle>
  );
}
