import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components/macro';
import Loader from '../../../components/Loader';

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  max-width: 100%;
  align-items: center;
  justify-content: center;
`;

type LoginPageProps = {
  prompt?: 'none' | 'login';
};

function LoginPage({ prompt = 'login' }: LoginPageProps) {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    void loginWithRedirect({ prompt, redirectUri: `${window.location.origin}/` });
  }, [loginWithRedirect, prompt]);

  return (
    <Root>
      <Loader />
    </Root>
  );
}

export default LoginPage;
