import React, { Suspense } from 'react';
import { Text, GridFlex } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import Loader from '../../../../../components/Loader';

const AddPaymentMethodContainer = React.lazy(
  () => import('../../../../../components/integrations/AddPaymentMethodContainer'),
);

export type AddSubscriptionPaymentMethodProps = {
  customer: CustomerResponseFragment;
  onSuccess: () => void;
  onCancel: () => void;
  addButtonText?: string;
  cancelButtonText?: string;
  subtitle?: string;
  additionalCustomControls?: React.ReactNode;
  withSuccessMessage?: boolean;
};

export function AddPaymentMethod({
  customer,
  onSuccess,
  onCancel,
  addButtonText,
  cancelButtonText,
  subtitle,
  additionalCustomControls,
  withSuccessMessage,
}: AddSubscriptionPaymentMethodProps) {
  return (
    <GridFlex.Column sx={{ height: '100%', width: '100%' }}>
      <Text.H3 mt={1} mb={subtitle ? 2 : 4}>
        {t('customers.paymentMethod.addPaymentMethod')}
      </Text.H3>
      {subtitle && <Text.B2 mb={4}>{subtitle}</Text.B2>}
      <Suspense fallback={<Loader />}>
        <AddPaymentMethodContainer
          customer={customer}
          onSuccess={onSuccess}
          onCancel={onCancel}
          addButtonText={addButtonText}
          cancelButtonText={cancelButtonText}
          additionalCustomControls={additionalCustomControls}
          withSuccessMessage={withSuccessMessage}
        />
      </Suspense>
    </GridFlex.Column>
  );
}
