import { gql } from '@apollo/client';
import { t } from 'i18next';
import { GetAwsExternalIdQuery, GetAwsExternalIdQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const FETCH_AWS_EXTERNAL_ID = gql`
  query GetAwsExternalId {
    getAwsExternalId {
      externalId
    }
  }
`;

async function fetchAWSExternalId(_: void, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<GetAwsExternalIdQuery, GetAwsExternalIdQueryVariables>({
        query: FETCH_AWS_EXTERNAL_ID,
      });

      return response.data.getAwsExternalId.externalId;
    },
    {
      failureMessageHandler: () => t('integrations.api.failFetchAWSExternalId'),
    },
    dispatch,
  );
}

export { fetchAWSExternalId };
