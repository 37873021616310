import { Tooltip, tooltipClasses, TooltipProps } from '@stigg-components';
import { styled } from '@stigg-theme';

export type InformationTooltipProps = TooltipProps & { $padding?: number; $maxWidth?: number; $leftMargin?: boolean };

export type TooltipFields = {
  placement?: InformationTooltipProps['placement'];
  title?: InformationTooltipProps['title'];
  maxWidth?: InformationTooltipProps['$maxWidth'];
};

export const InformationTooltip = styled(({ className, ...props }: InformationTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, $padding = 4, $maxWidth = 300, $leftMargin = false }) => {
  const borderProps = { border: theme.itamar.border.border, borderColor: theme.itamar.border.borderColor };
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.default,
      ...(theme.isLightTheme ? {} : { ...borderProps }),
      maxWidth: $maxWidth,
      boxShadow: theme.itamar.palette.shadow.popover,
      padding: theme.spacing($padding),
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.default,
      ...(theme.isLightTheme ? {} : { '&:before': { ...borderProps } }),
    },
    [`& .${tooltipClasses.tooltipPlacementLeft}`]: { right: $leftMargin ? '30px' : undefined },
  };
});
