import { Icon, InformationalTooltipIcon } from '@stigg-components';
import React from 'react';
import { BillingCadence } from '@stigg-types/apiTypes';
import { t } from 'i18next';

export function getBillingCadenceDescription(billingCadence: BillingCadence) {
  return billingCadence === BillingCadence.OneOff
    ? t('pricing.billingCadenceDescriptions.ONE_OFF')
    : t('pricing.billingCadenceDescriptions.RECURRING');
}

export function isOneOff(billingCadence: BillingCadence) {
  return billingCadence === BillingCadence.OneOff;
}

export function getBillingCadenceIcon({
  billingCadence,
  selected,
}: {
  billingCadence: BillingCadence;
  selected?: boolean;
}) {
  return isOneOff(billingCadence) ? (
    <Icon icon="OneOff" type="custom" color={selected ? 'primary.main' : 'active'} overrideStroke overrideFill />
  ) : (
    <Icon icon="Recurring" type="custom" color={selected ? 'primary.main' : 'active'} overrideStroke />
  );
}

export function getBillingCadenceIconTooltip({
  billingCadence,
  selected,
}: {
  billingCadence: BillingCadence;
  selected?: boolean;
}) {
  return (
    <InformationalTooltipIcon
      icon={getBillingCadenceIcon({
        billingCadence,
        selected,
      })}
      placement="top"
      text={t(`pricing.billingCadenceTooltips.${billingCadence}`).toString()}
    />
  );
}
