import { useSelector } from 'react-redux';
import head from 'lodash/head';
import { RootState } from '../../../redux/store';

export function useCurrentMember() {
  const currentAccountId = useSelector((state: RootState) => state.accountReducer.currentAccountId);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const memberships = user?.memberships || [];
  return memberships.find((m) => m.account.id === currentAccountId) || head(memberships);
}
