import { BillingCadence, BillingModel, PriceFeatureFragment, PriceFragment } from '@stigg-types/apiTypes';
import { groupBy } from 'lodash';
import sortBy from 'lodash/sortBy';
import { Charge, ChargeType } from '../components/SetPriceWizard/form/SetPriceWizardForm.types';

export type PriceGroup = Array<PriceFragment>;

function composePriceGroupId(billingModel: BillingModel, featureId?: string | null): string {
  return `${billingModel}-${featureId || ''}`;
}

export function getGroupIdFromPrice({ billingModel, featureId }: PriceFragment): string {
  return composePriceGroupId(billingModel, featureId);
}

export function getPriceGroupId(group: PriceGroup): string {
  return getGroupIdFromPrice(group[0]);
}

export function getChargeGroupId(charge: Charge): string {
  const featureId = charge.type === ChargeType.UsageBased ? charge.feature?.id : null;
  return composePriceGroupId(charge.billingModel, featureId);
}

export function getGroupBillingModel(group: PriceGroup): BillingModel {
  return group[0].billingModel;
}

export function getGroupFeature(group: PriceGroup): PriceFeatureFragment | undefined | null {
  return group[0].feature;
}
// we expect the minunitquantity to be the same for all prices in a group
export function getMinUnitQuantity(group: Array<PriceFragment>): number | undefined | null {
  return group[0].minUnitQuantity;
}

// we expect the maxunitquantity to be the same for all prices in a group
export function getMaxUnitQuantity(group: Array<PriceFragment>): number | undefined | null {
  return group[0].maxUnitQuantity;
}

export function getBillingCadence(group: Array<PriceFragment>): BillingCadence {
  return group[0].billingCadence;
}

export function toPriceGroups<TPrice extends PriceFragment>(prices?: TPrice[] | null): Array<Array<TPrice>> {
  const priceGroupById = groupBy(prices, getGroupIdFromPrice);
  const groups = Object.values(priceGroupById);

  // sort so we make sure that the base price is always first
  return sortBy(groups, (priceGroup) => (getGroupBillingModel(priceGroup) === BillingModel.FlatFee ? 0 : 1));
}
