import { gql } from '@apollo/client';
import {
  PackagePricingInput,
  SetPackagePricingMutation,
  SetPackagePricingMutationVariables,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';
import { ExecuteOperationOptions } from '../../../common';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { fetchAddonByRefIdAction } from '../../addons/addonSlice';
import { fetchPlanByRefIdAction } from '../../plans/plansSlice';

const SET_PACKAGE_PRICING = gql`
  mutation SetPackagePricing($input: PackagePricingInput!) {
    setPackagePricing(input: $input) {
      packageId
      pricingType
    }
  }
`;

export type SetPackagePricingInput = Omit<PackagePricingInput, 'environmentId'> & {
  planRefId?: string;
  addonRefId?: string;
  versionNumber: number;
} & ExecuteOperationOptions;

async function setPackagePricing(
  priceData: SetPackagePricingInput,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const { planRefId, addonRefId, versionNumber, silentFetch, ...priceInputData } = priceData;
      const response = await apolloClient.mutate<SetPackagePricingMutation, SetPackagePricingMutationVariables>({
        mutation: SET_PACKAGE_PRICING,
        variables: {
          input: { ...priceInputData, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      if (planRefId) {
        await dispatch(fetchPlanByRefIdAction({ refId: planRefId, versionNumber, silentFetch }));
      } else if (addonRefId) {
        await dispatch(fetchAddonByRefIdAction({ refId: addonRefId, versionNumber, silentFetch }));
      }
      return response.data?.setPackagePricing;
    },
    {
      failureMessageHandler: () => t('pricing.api.failureCreate'),
    },
    dispatch,
  );
}

export { setPackagePricing };
