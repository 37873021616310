import { t } from 'i18next';
import { HeadCell } from '@stigg-components';
import { EntitlementSummaryFragment } from '@stigg-types/apiTypes';
import { SummarySourceBox, FeatureSummaryValueBox } from './components';
import {
  SummaryWithFeature,
  VALUE_CELL_WIDTH,
  COLLAPSABLE_SOURCE_CELL_WIDTH,
  FEATURE_TYPE_CELL_WIDTH,
  SOURCE_ICONS_WIDTH,
} from './CustomerEntitlementsSummary';

export const headCellsCollapsibleSummary: (
  activePlanIds: string[],
  trialPlanIds: string[],
  focusedProductId?: string,
) => Array<HeadCell<SummaryWithFeature, any>> = (
  activePlanIds: string[],
  trialPlanIds: string[],
  focusedProductId?: string,
) => [
  {
    disablePadding: true,
    width: FEATURE_TYPE_CELL_WIDTH,
    id: 'feature_type',
    alignment: 'center',
    label: t('entitlements.featureType'),
    render: () => null,
  },
  {
    id: 'feature',
    alignment: 'left',
    label: '',
    render: () => null,
  },
  {
    width: VALUE_CELL_WIDTH,
    id: 'value',
    alignment: 'left',
    label: t('customers.summaryTableValueColumn'),
    render: (entity: SummaryWithFeature) => <FeatureSummaryValueBox summaryWithFeature={entity} />,
  },
  {
    // will render only the icons
    width: SOURCE_ICONS_WIDTH,
    id: 'source_icons',
    alignment: 'left',
    label: t('customers.summaryTableSourceColumn'),
    noWrap: true,
    render: (entity: SummaryWithFeature) => (
      <SummarySourceBox
        shouldPrintIcons
        summary={entity as EntitlementSummaryFragment}
        focusedProductId={focusedProductId}
        activePlanIds={activePlanIds}
        trialPlanIds={trialPlanIds}
      />
    ),
  },
  {
    id: 'source',
    width: COLLAPSABLE_SOURCE_CELL_WIDTH,
    alignment: 'left',
    label: 'Source',
    disablePadding: true,
    noWrap: true,
    render: (entity: SummaryWithFeature) => (
      <SummarySourceBox
        summary={entity as EntitlementSummaryFragment}
        focusedProductId={focusedProductId}
        activePlanIds={activePlanIds}
        trialPlanIds={trialPlanIds}
      />
    ),
  },
];
