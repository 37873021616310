import { t } from 'i18next';
import { Button, Icon, Text, GridFlex } from '@stigg-components';
import { StyledContainer } from './SubscriptionBannerContainer';

type SubscriptionBudgetExceededBannerProps = {
  onShowEntitlementsSummary: () => void;
};

export function SubscriptionBudgetExceededBanner({ onShowEntitlementsSummary }: SubscriptionBudgetExceededBannerProps) {
  const showEntitlementsButton = (
    <Button onClick={onShowEntitlementsSummary}>
      <Text.B2 color="info.main">{t('subscriptions.budgetExceededBanner.showEntitlements')}</Text.B2>
    </Button>
  );

  return (
    <StyledContainer $variant="error">
      <GridFlex.RowCenter $fullWidth gap={1}>
        <GridFlex.RowCenter item gap={3}>
          <Icon icon="BudgetExceeded" />
          <Text.B2 color="error">{t('subscriptions.budgetExceededBanner.title')}</Text.B2>
        </GridFlex.RowCenter>

        <GridFlex.Column item>{showEntitlementsButton}</GridFlex.Column>
      </GridFlex.RowCenter>
    </StyledContainer>
  );
}
