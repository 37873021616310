import { CouponType, Currency } from '@stigg-types/apiTypes';
import { currencyPriceFormatter } from '../../../packages/pricing/components/currency/currencyUtils';

export type BaseCoupon = {
  name: string;
  discountValue: number;
  type: CouponType;
  refId: string;
};

export const formatCouponValue = (
  discountValue: number,
  discountType: CouponType,
  currency: Currency | undefined = Currency.Usd,
  showNegativeSign = true,
) => {
  if (discountType === CouponType.Percentage) {
    return `${discountValue}% off`;
  }

  if (discountType === CouponType.Fixed) {
    const amount = showNegativeSign ? discountValue * -1 : discountValue;
    return currencyPriceFormatter({ amount, currency, options: { withCodePostfix: true } });
  }
  return '';
};

export const formatCoupon = ({ name, discountValue, type }: BaseCoupon) => {
  return `${name} (${formatCouponValue(discountValue, type)})`;
};
