import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateManyPromotionalEntitlementsInput,
  CreateManyPromotionalEntitlementsMutation,
  CreateManyPromotionalEntitlementsMutationVariables,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { apolloClient } from '../../../ApolloClient';
import { fetchCustomerByRefIdAction, fetchEntitlementsByRefIdAction } from '../customersSlice';

const CREATE_PROMOTIONAL_ENTITLEMENTS = gql`
  mutation createManyPromotionalEntitlements($input: CreateManyPromotionalEntitlementsInput!) {
    createManyPromotionalEntitlements(input: $input) {
      id
    }
  }
`;

function createPromotionalEntitlements(
  entitlementData: CreateManyPromotionalEntitlementsInput & { customerId: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  const { promotionalEntitlements, customerId } = entitlementData;
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        CreateManyPromotionalEntitlementsMutation,
        CreateManyPromotionalEntitlementsMutationVariables
      >({
        mutation: CREATE_PROMOTIONAL_ENTITLEMENTS,
        variables: {
          input: {
            promotionalEntitlements: promotionalEntitlements.map((ent) => ({
              ...ent,
              usageLimit: ent.usageLimit ? Math.floor(ent.usageLimit) : null,
            })),
          },
        },
      });

      const newEntitlements = response.data?.createManyPromotionalEntitlements;

      await Promise.all([
        dispatch(fetchCustomerByRefIdAction({ customerId })),
        dispatch(fetchEntitlementsByRefIdAction({ customerRefId: customerId, resourceRefId: undefined })),
      ]);

      return newEntitlements;
    },
    {
      successMessage: t('promotionalEntitlements.api.successCreate'),
      failureMessageHandler: () => t('promotionalEntitlements.api.createFailed'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('promotionalEntitlements.api.errorAlreadyGranted'),
      },
    },
    dispatch,
  );
}

export { createPromotionalEntitlements };
