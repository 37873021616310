import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

const GET_ENTITLEMENT_REQUEST = (
  featureRefId: string,
  addRequestedUsage: boolean,
) => `result, err := client.GetEntitlement(context.Background(), stigg.FetchEntitlementQuery{
  CustomerID: "${MOCK_CUSTOMER_ID}",
  FeatureID:  "${featureRefId}",
  ${
    addRequestedUsage
      ? `Options: &stigg.EntitlementOptions{
    RequestedUsage: Ptr(float64(${MOCK_REQUESTED_USAGE})),
  },`
      : ''
  }
})

if result.Entitlement.IsGranted {
  // customer has access to the feature 
} else {
  // access denied
}`;

export const goFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `import (
    "context"
    "github.com/stiggio/api-client-go"
    "github.com/stiggio/api-client-go/codegen/generated"
    "os"
)

// Ptr is a helper function that returns a pointer to whatever 
// value we pass to it, will be widely used in the examples below
func Ptr[T any](v T) *T {
    return &v
}


func main() {
    client := stigg.NewStiggClient("${apiKey}", nil, nil)
  
  // client is initialized
}`,
  checkBooleanEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkNumericEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkMeteredEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, true),
  reportUsageForMeteredFeature: (
    featureRefId: string,
  ) => `result, err := client.ReportUsage(context.Background(), stigg.ReportUsageInput{
  Value:      10,
  CustomerID: "${MOCK_CUSTOMER_ID}",
  FeatureID:  "${featureRefId}",
})`,
  reportEventForMeteredFeature: () => `result, err := client.ReportEvent(context.Background(), stigg.ReportEventInput{
  CustomerID: "example-customer-id",
  EventName:  "example-event-name",
  IdempotencyKey: "82f584b6-488f-4275-a0d3-47442d64ad79",
  Dimensions: map[string]string{
    "example_property": "1234",    
  },
})`,
};
