import partition from 'lodash/partition';
import { BillingModel, PriceFragment, PricingType } from '@stigg-types/apiTypes';
import { WizardAccordionStep, StepByStepStatus } from '@stigg-components';
import { AbstractUsageBasedChargeType, Charge, ChargeType, TiersSchema } from './SetPriceWizardForm.types';

export function getShouldShowActionButtons(
  isStepByStep: boolean,
  pricingType: PricingType | null,
  stepByStepStatus: StepByStepStatus,
  additionalSteps: WizardAccordionStep[],
) {
  if (!isStepByStep) {
    return true;
  }

  if (!pricingType) {
    return false;
  }

  if (stepByStepStatus === StepByStepStatus.LAST_STEP && additionalSteps.length === 0) {
    return true;
  }

  return stepByStepStatus === StepByStepStatus.COMPLETED && additionalSteps.length > 0;
}

export function isTieredCharge(charge: Charge | AbstractUsageBasedChargeType) {
  return charge.type === ChargeType.UsageBased && charge.tiersSchema !== TiersSchema.Standard;
}

export function splitPricesByPricingType(prices: PriceFragment[]) {
  const [tieredPrices, otherPrices] = partition(
    prices,
    ({ tiersMode, billingModel }) => billingModel === BillingModel.PerUnit && !!tiersMode,
  );

  return { tieredPrices, otherPrices };
}

export function splitChargesByChargeType(charges: Charge[]) {
  const [tieredCharges, otherCharges] = partition(charges, (charge) => isTieredCharge(charge));
  return { tieredCharges, otherCharges };
}
