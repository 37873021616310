import { GridFlex, InformationTooltip, Text } from '@stigg-components';
import styled from 'styled-components/macro';
import { FormikState } from 'formik';
import get from 'lodash/get';
import { SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';

const TextFieldContainer = styled(GridFlex.Column)`
  position: relative;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `2px solid ${theme.itamar.palette.primary.main}`};
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `1px solid ${theme.itamar.palette.error.main}`};
  }
  .MuiInputBase-root {
    height: 54px;
    padding-left: 16px;
  }
  .MuiInputBase-input {
    padding: 0;
  }
  .transfer-icon {
    display: none;
  }
  .Mui-focused .transfer-icon {
    display: block;
  }
`;

type CellTextFieldProps = {
  children: React.ReactNode;
  touched: FormikState<SetPriceWizardFormFields>['touched'];
  errors: FormikState<SetPriceWizardFormFields>['errors'];
  id: string;
};

export function CellTextField({ children, errors, touched, id }: CellTextFieldProps) {
  const errorText = get(errors, id);
  const isTouched = get(touched, id);
  return (
    <TextFieldContainer>
      <InformationTooltip
        arrow
        placement="top"
        title={isTouched && errorText ? <Text.B2 color="error.main">{errorText}</Text.B2> : ''}>
        <div>{children}</div>
      </InformationTooltip>
    </TextFieldContainer>
  );
}
