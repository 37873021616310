import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import { Popper, Box, GridFlex, Form, Text, Field, Icon, Checkbox, Button } from '@stigg-components';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Environment, EnvironmentType } from '@stigg-types/apiTypes';
import { urlFriendlyValidation } from '@stigg-common';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { omit } from 'lodash';
import { Trans } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../redux/store';
import Loader from '../../../components/Loader';
import { ENVIRONMENT_DEFAULT_COLOR } from './EnvironmentSwitch';
import { mergeEnvironmentAction } from '../accountsSlice';

const ENVIRONMENTS_COLORS = ['#4CAF50', '#FFB547', '#F88078', '#327EEE', '#784CAF', '#47DEFF', '#FFA37C', '#EE32D0'];

const ColorBox = styled(Box)<{ $color: string }>`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  background: ${(props) => props.$color};
  transition: background 0.2s ease-in-out;
  border-radius: 2px;
`;

const EnvironmentTypeDescription = styled(Text.Caption)`
  text-wrap: wrap;
`;

const validationSchema = () =>
  Yup.object().shape({
    displayName: urlFriendlyValidation(),
    description: Yup.string().nullable(true),
    copyFromExisting: Yup.boolean().required(t('fieldValidationMessages.required')),
    copyFromSlug: Yup.string().when(['copyFromExisting'], {
      is: (copyFromExisting: boolean) => copyFromExisting,
      then: (schema: any) => schema.required(t('fieldValidationMessages.required')),
      otherwise: (schema: any) => schema.nullable(true),
    }),
  });

function findEnvironmentColor(environments: Environment[]) {
  const currentEnvironmentColors = new Set(environments.map((env) => env.color?.toLowerCase()));
  return ENVIRONMENTS_COLORS.find((color) => !currentEnvironmentColors.has(color.toLowerCase()));
}

export type EnvironmentFormFields = {
  color: string;
  displayName: string;
  description: string;
  type: EnvironmentType;
  copyFromExisting?: boolean;
  copyFromSlug?: string;
};

export default function EnvironmentDetailsForm({
  onSubmit,
  onCancel,
  environment,
  copyFromSlug,
}: {
  onSubmit: (values: EnvironmentFormFields) => Promise<void>;
  onCancel: () => void;
  environment?: Environment;
  copyFromSlug?: string;
}) {
  const dispatch = useAppDispatch();
  const { stiggForStiggWeb } = useFlags<FeatureFlags>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const [color, setColor] = useState(
    (environment ? environment?.color : findEnvironmentColor(environments)) || ENVIRONMENT_DEFAULT_COLOR,
  );
  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);
  const [isCopyingEnv, setIsCopyingEnv] = useState(false);
  const [sourceAndDestinationNames, setSourceAndDestinationNames] = useState(['', '']);
  const isEdit = !isEmpty(environment);

  const initialValues: EnvironmentFormFields = {
    displayName: environment?.displayName || '',
    description: environment?.description || '',
    copyFromExisting: !!copyFromSlug,
    type: environment?.type || EnvironmentType.Development,
    color,
    copyFromSlug,
  };

  if (initialValues.copyFromExisting && isLoadingEnvironments) {
    return <Loader />;
  }

  const handleSubmit = async (values: EnvironmentFormFields) => {
    if (values.copyFromExisting && values.copyFromSlug) {
      const sourceEnv = environments.find((x) => x.slug === values.copyFromSlug);
      if (sourceEnv) {
        const sourceEnvSlug = sourceEnv?.slug || '';
        setSourceAndDestinationNames([sourceEnv?.displayName || '', values.displayName]);
        setIsCopyingEnv(true);

        const result = await dispatch(
          mergeEnvironmentAction({
            sourceEnvironmentSlug: sourceEnvSlug,
            destinationEnvironmentName: values.displayName,
            destinationEnvironmentType: values.type,
          }),
        ).unwrap();

        window.location.href = `/${result.environmentSlug}`;
      }
    } else {
      await onSubmit(omit(values, 'copyFromExisting'));
    }
  };

  const onChange = (color: string) => {
    setColor(color);
  };

  const onClickAway = () => {
    setIsColorPickerVisible(false);
    setAnchorEl(null);
  };
  const onClickColorBox = (event: any) => {
    if (!isColorPickerVisible) {
      setIsColorPickerVisible(true);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const fields: Field<EnvironmentFormFields>[] = [
    {
      type: 'custom',
      render: ({ renderTextField, setFieldValue }) => {
        return (
          <GridFlex.Row $fullWidth gap={2} alignItems="baseline" height={90}>
            <Button
              variant="outlined"
              endIcon={<Icon type="materialIcons" icon="ArrowDropDown" color="active" />}
              sx={{ borderColor: '#C4CBDD', p: 2 }}
              onClick={onClickColorBox}>
              <ColorBox $color={color} />
            </Button>
            {isColorPickerVisible ? (
              <>
                <Box
                  sx={{ position: 'fixed', zIndex: 2, top: 0, right: 0, bottom: 0, left: 0 }}
                  onClick={onClickAway}
                />
                <Popper
                  open={isColorPickerVisible}
                  disablePortal
                  sx={{ zIndex: 2 }}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  modifiers={[{ name: 'offset', options: { offset: [0, 6] } }]}>
                  <TwitterPicker
                    colors={ENVIRONMENTS_COLORS}
                    color={color}
                    onChange={(color) => {
                      onChange(color.hex);
                      setFieldValue('color', color.hex);
                    }}
                  />
                </Popper>
              </>
            ) : null}
            {renderTextField('displayName', 'Name', {
              placeholder: environment?.displayName || t('account.environmentNamePlaceholder'),
            })}
          </GridFlex.Row>
        );
      },
    },
    {
      type: 'text',
      id: 'description',
      label: 'Description',
      placeholder: environment?.description || '',
      optional: true,
    },
    {
      type: 'select',
      id: 'type',
      label: t('accounts.environmentTypeLabel'),
      hide: () => !stiggForStiggWeb,
      disabled: isEdit,
      values: [
        {
          value: EnvironmentType.Development,
          overrideDisplayValue: (
            <GridFlex.RowCenter>
              <Icon icon="DevEnvironmentIcon" type="custom" />
              <GridFlex.Column ml={2}>
                <Text.B2>{t('accounts.devEnvironmentLabel')}</Text.B2>
              </GridFlex.Column>
            </GridFlex.RowCenter>
          ),
          displayValue: (
            <GridFlex.RowCenter columnGap={3}>
              <Icon size={28} icon="DevEnvironmentIcon" type="custom" />
              <GridFlex.Column>
                <Text.B2>{t('accounts.devEnvironmentLabel')}</Text.B2>
                <EnvironmentTypeDescription>{t('accounts.devEnvironmentDescription')}</EnvironmentTypeDescription>
              </GridFlex.Column>
            </GridFlex.RowCenter>
          ),
        },
        {
          value: EnvironmentType.Production,
          overrideDisplayValue: (
            <GridFlex.RowCenter>
              <Icon icon="ProductionEnvironmentIcon" type="custom" />
              <GridFlex.Column ml={2}>
                <Text.B2>{t('accounts.prodEnvironmentLabel')}</Text.B2>
              </GridFlex.Column>
            </GridFlex.RowCenter>
          ),
          displayValue: (
            <GridFlex.RowCenter columnGap={3}>
              <Icon size={24} icon="ProductionEnvironmentIcon" type="custom" />
              <GridFlex.Column>
                <Text.B2>{t('accounts.prodEnvironmentLabel')}</Text.B2>
                <EnvironmentTypeDescription>{t('accounts.prodEnvironmentDescription')}</EnvironmentTypeDescription>
              </GridFlex.Column>
            </GridFlex.RowCenter>
          ),
        },
        // We are not allowing to choose sandbox as environment type but we want to show
        // it when editing a sandbox environment
        ...(isEdit
          ? [
              {
                value: EnvironmentType.Sandbox,
                disabled: true,
                overrideDisplayValue: (
                  <GridFlex.RowCenter>
                    <Icon icon="ProductionEnvironmentIcon" type="custom" />
                    <GridFlex.Column ml={2}>
                      <Text.B2>{t('accounts.sandboxEnvironmentLabel')}</Text.B2>
                    </GridFlex.Column>
                  </GridFlex.RowCenter>
                ),
                displayValue: (
                  <GridFlex.RowCenter columnGap={3}>
                    <Icon size={24} icon="ProductionEnvironmentIcon" type="custom" />
                    <GridFlex.Column>
                      <Text.B2>{t('accounts.sandboxEnvironmentLabel')}</Text.B2>
                    </GridFlex.Column>
                  </GridFlex.RowCenter>
                ),
              },
            ]
          : []),
      ],
    },
  ];

  if (!isEdit) {
    fields.push(
      {
        type: 'custom',
        render: ({ values, setFieldValue }) => {
          return (
            <Checkbox
              label={<Text.B2 color="secondary">{t('accounts.copyFromLabel')}</Text.B2>}
              checked={values.copyFromExisting}
              onChange={(_event, checked) => setFieldValue('copyFromExisting', checked)}
            />
          );
        },
      },
      {
        id: 'copyFromSlug',
        type: 'select',
        disabled: (values: EnvironmentFormFields) => !values.copyFromExisting,
        hide: (values: EnvironmentFormFields) =>
          isLoadingEnvironments || isEmpty(environments) || !values.copyFromExisting,
        restProps: {
          placeholder: t('accounts.copyToNewEnvCopyFromSlugPlaceholder'),
        },
        values: environments.map((environment: Environment) => ({
          value: environment.slug,
          displayValue: <Text.B2>{environment.displayName}</Text.B2>,
        })),
      },
    );
  }

  return isCopyingEnv ? (
    <>
      <Text.B2>
        <Trans
          t={t}
          i18nKey="accounts.copyToNewEnvAnimationDescription"
          values={{
            sourceEnvironmentName: sourceAndDestinationNames[0],
            destinationEnvironmentName: sourceAndDestinationNames[1],
          }}
          components={[<Text.B2 $bold display="inline" color="primary" />]}
        />
      </Text.B2>
      <Loader useAnimationLoader />
    </>
  ) : (
    <GridFlex.Column pt={3}>
      {!isEdit && !copyFromSlug && <Text.Sub2 mb={5}>{t('accounts.addEnvsText')}</Text.Sub2>}
      {copyFromSlug && <Text.Sub2 mb={5}>{t('accounts.copyEnvsText')}</Text.Sub2>}
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={fields}
        submitButtonText={
          copyFromSlug
            ? t('accounts.copyEnvironmentModalCTA')
            : environment
            ? t('sharedComponents.editSaveButton')
            : undefined
        }
      />
    </GridFlex.Column>
  );
}
