import * as React from 'react';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { PeriodDetailsRow } from './PeriodDetailsRow';
import { UseCaseDetailsRow } from './UseCaseDetailsRow';
import { ProductDetailsRow } from './ProductDetailsRow';

type ExperimentDetailsProps = {
  experiment: ExperimentFragment;
};

export function ExperimentDetails({ experiment }: ExperimentDetailsProps) {
  return (
    <>
      <UseCaseDetailsRow />
      <ProductDetailsRow experiment={experiment} />
      <PeriodDetailsRow experiment={experiment} />
    </>
  );
}
