import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateIntegrationInput,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
  Integration,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { fetchIntegrationByVendorAction } from '../integrationsSlice';

const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegration($input: UpdateOneIntegrationInput!) {
    updateOneIntegration(input: $input) {
      id
    }
  }
`;

async function updateIntegration(
  {
    integrationId,
    integrationData,
  }: {
    integrationId: string;
    integrationData: UpdateIntegrationInput;
  },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Integration> | undefined> {
  return executeOperationSafely(
    async () => {
      const integration = {
        ...integrationData,
      };
      const response = await apolloClient.mutate<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>({
        mutation: UPDATE_INTEGRATION,
        variables: { input: { id: integrationId, update: integration } },
      });
      const newIntegration = response.data?.updateOneIntegration;
      await dispatch(fetchIntegrationByVendorAction({ vendorIdentifier: integrationData.vendorIdentifier }));
      return newIntegration;
    },
    {
      successMessage: t('integrations.api.successUpdate'),
      failureMessageHandler: (err) => {
        // TODO: change this string comparison to use explicit error code instead
        if (err.graphQLErrors[0]?.message === 'Zuora credentials are not valid') {
          return t('integrations.api.invalidCredentials');
        }
        return t('integrations.api.failUpdate');
      },
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('integrations.api.alreadyExists', {
          vendorIdentifier: integrationData.vendorIdentifier,
        }),
      },
    },
    dispatch,
  );
}

export { updateIntegration };
