import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { ProductCatalogSummary } from './ProductCatalogSummary';

export function PreviewAndImportStep() {
  return (
    <GridFlex.Column>
      <Text.H3 mb={2}>{t('integrations.reviewStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={12}>
        {t('integrations.reviewStepSubtitle')}
      </Text.B2>
      <ProductCatalogSummary />
    </GridFlex.Column>
  );
}
