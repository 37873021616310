import * as jsonDiff from 'json-diff-ts';
import { IChange } from 'json-diff-ts/lib/jsonDiff';

export type JsonDiff = {
  updates: IChange[];
  adds: IChange[];
  removes: IChange[];
};

export function getDiffsBetweenJsons(before?: JSON | null, after?: JSON | null): JsonDiff {
  const diff = jsonDiff.diff(before || {}, after || {});
  const updates = diff.filter((x) => x.type === 'UPDATE');
  const adds = diff.filter((x) => x.type === 'ADD');
  const removes = diff.filter((x) => x.type === 'REMOVE');
  return { updates, adds, removes };
}
