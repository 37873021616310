import {
  AddonFragment,
  PackageGroupFragment,
  PlanCompatibleAddonFragment,
  PlanCompatiblePackageGroupFragment,
  PlanFragment,
} from '@stigg-types/apiTypes';
import includes from 'lodash/includes';
import { PackageListFragment } from './components/Packages';
import { AddonOrAddonGroupOption } from '../plans/components/planPage/compatibleAddons/AddCompatibleAddonOrAddonGroupDialog';

export function isPackagePlan(aPackage: PlanFragment | AddonFragment | PackageListFragment): aPackage is PlanFragment {
  return aPackage.type === 'Plan';
}

export function isPackageAddon(
  aPackage: PlanFragment | AddonFragment | PackageListFragment,
): aPackage is AddonFragment {
  return aPackage.type === 'Addon';
}

export function isPackageSingleInstanceAddon(aPackage: PlanFragment | AddonFragment | PackageListFragment): boolean {
  const isAddon = isPackageAddon(aPackage);
  if (!isAddon) {
    return false;
  }

  return aPackage.maxQuantity === 1;
}

export function isCompatibleAddonSingleInstance(addon: PlanCompatibleAddonFragment): boolean {
  return addon.maxQuantity === 1;
}

export function isAddon(entity: AddonOrAddonGroupOption): entity is AddonFragment {
  return entity.__typename === 'Addon';
}

export type CompatiblePackageGroup = PlanCompatiblePackageGroupFragment | PackageGroupFragment;

export function isPackageGroup(entity: AddonOrAddonGroupOption): entity is CompatiblePackageGroup {
  return includes(['PlanCompatiblePackageGroups', 'PackageGroup'], entity.__typename);
}

export function isCompatiblePackageGroup(
  entity: AddonOrAddonGroupOption,
): entity is PlanCompatiblePackageGroupFragment {
  return entity.__typename === 'PlanCompatiblePackageGroups';
}
