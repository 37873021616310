import { t } from 'i18next';
import get from 'lodash/get';
import { BillingPeriod, OverageBillingPeriod } from '@stigg-types/apiTypes';
import { Select, MenuItem, Text, GridFlex } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';

export function OverageBillingPeriodSelect() {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { values, setFieldValue, setFieldTouched } = formRenderProps;
  const { billingPeriods } = values;

  const overageBillingPeriodId = 'overageBillingPeriod';

  const hasAnnualPricing = billingPeriods?.includes(BillingPeriod.Annually);

  if (!values.overageCharges.enabled) {
    return null;
  }

  return (
    <GridFlex.RowCenter container gap={2}>
      {hasAnnualPricing ? (
        <>
          <GridFlex.Column>
            <Text.B2>{t('pricing.overageCharges.overagesBillingPeriod.label')}</Text.B2>
          </GridFlex.Column>
          <GridFlex.Column>
            <Select
              variant="inline"
              withoutBorder
              value={get(values, overageBillingPeriodId) ?? OverageBillingPeriod.Monthly}
              onChange={(e) => {
                setFieldValue(overageBillingPeriodId, e.target.value);
                setFieldTouched(overageBillingPeriodId);
              }}
              renderValue={(value: any) => {
                return <Text.B2 $bold>{t(`pricing.overageCharges.overagesBillingPeriod.options.${value}`)}</Text.B2>;
              }}>
              {Object.values(OverageBillingPeriod).map((overageBillingPeriod, index) => (
                <MenuItem key={index} value={overageBillingPeriod}>
                  <Text.B2 color="primary">
                    {t(`pricing.overageCharges.overagesBillingPeriod.options.${overageBillingPeriod}`)}
                  </Text.B2>
                </MenuItem>
              ))}
            </Select>
          </GridFlex.Column>
        </>
      ) : (
        <GridFlex.Column>
          <Text.B2>
            {t('pricing.overageCharges.overagesBillingPeriod.label')}{' '}
            <Text.B2 display="inline" $bold>
              {t(`pricing.overageCharges.overagesBillingPeriod.options.${OverageBillingPeriod.OnSubscriptionRenewal}`)}
            </Text.B2>
          </Text.B2>
        </GridFlex.Column>
      )}
    </GridFlex.RowCenter>
  );
}
