import { Feature, Plan } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { ResultSet } from '@cubejs-client/core';
import { Theme } from '@mui/material/styles';
import { ActiveElement, ChartData, ChartOptions } from 'chart.js';
import capitalize from 'lodash/capitalize';
import { isEmpty } from 'lodash';
import { Navigation } from '../../../../../navigation/useNavigation';
import '../../../../common/chartjs/chartjs.plugins';
import { numberFormatter, numberFullFormatter } from '../../../../common/formatters';
import {
  getAxisColor,
  getAxisTickColor,
  getAxisTickFont,
  getAxisTitleColor,
  getAxisTitleFont,
  AXIS_TITLE_SPACING,
  GRID_BORDER_DASH,
  getLabelColor,
  getLabelFont,
  LEGEND_BOX_SIZE,
  opacityColor,
} from '../../../../common/chartjs/chartjs.theme';
import { ExternalTooltipHandler } from '../../../../common/chartjs/chartjs.tooltip.plugin';
import { convertTextOverflowEllipsis } from '../../../../common/chartjs/chartjs.helpers';

export function computeChartData(
  resultSet: ResultSet | null,
  feature: Feature,
  plan: Plan,
  navigation: Navigation,
  color: string,
  tooltipHandler: ExternalTooltipHandler<'bar'>,
  theme: Theme,
) {
  if (!resultSet) {
    return {};
  }

  const dataSource = resultSet.tablePivot();

  if (isEmpty(dataSource)) {
    return {};
  }

  const customersRefIds = dataSource.map((x) => x['Customer.refId'] as string);
  const labels = dataSource.map((x) => x['Customer.name'] || (x['Customer.refId'] as string));

  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: t('sharedComponents.planSuffix', {
          planName: plan.displayName,
        }),
        data: dataSource.map((x) => Number(x['FeatureUsageDistribution.totalUsage'] as string)),
        backgroundColor: opacityColor(color, 0.3),
        borderColor: color,
        borderWidth: {
          top: 2,
        },
        categoryPercentage: 0.75,
        maxBarThickness: 30,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    onHover: (event, elements: ActiveElement[], chart) => {
      chart.canvas.style.cursor = elements.length === 0 ? 'default' : 'pointer';
    },
    onClick: (event, elements: ActiveElement[]) => {
      if (elements.length === 0) {
        return;
      }
      const customerRefId = customersRefIds[elements[0].index];
      const url = navigation.appRoutes.customerPage(customerRefId);

      // navigating on different tick in order to avoid error in plugin
      setTimeout(() => navigation.navigateTo(url), 0);
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        align: 'start',
        onClick: () => {}, // disable hide
        labels: {
          font: getLabelFont(),
          color: getLabelColor(theme),
          boxWidth: LEGEND_BOX_SIZE,
          boxHeight: LEGEND_BOX_SIZE,
        },
      },
      tooltip: {
        enabled: false,
        external: tooltipHandler,
      },
      datalabels: {
        labels: {
          title: {
            formatter: (_, context) => convertTextOverflowEllipsis(data.labels![context.dataIndex] as string, context),
            anchor: 'start',
            align: 'right',
            offset: 0,
            font: getAxisTitleFont(),
            color: getAxisTitleColor(theme),
          },
          value: {
            formatter: (value) => numberFullFormatter(value),
            anchor: 'end',
            align: 'left',
            display: 'auto',
            font: getLabelFont(),
            color: getLabelColor(theme),
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { bottom: AXIS_TITLE_SPACING },
          text: t('sharedComponents.customer_other'),
        },
      },
      x: {
        ticks: {
          callback: (value) => numberFormatter(value as number),
          precision: 0,
          font: getAxisTickFont(),
          color: getAxisTickColor(theme),
        },
        grid: {
          borderDash: GRID_BORDER_DASH,
          color: getAxisColor(theme),
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { top: AXIS_TITLE_SPACING },
          text: capitalize(feature.featureUnitsPlural as string),
        },
      },
    },
  };

  return { data, options };
}
