import { t } from 'i18next';
import { Button, GridFlex, Text, Icon } from '@stigg-components';

export function Header({
  readonly,
  setAddDialogOpen,
  packageType,
}: {
  readonly: boolean;
  setAddDialogOpen: (status: boolean) => void;
  packageType: 'plan' | 'add-on';
}) {
  return (
    <>
      <GridFlex.RowSpaceBetweenTop container>
        <Text.H3>{t('entitlements.entitlementsTitle')}</Text.H3>
        {!readonly && (
          <Button
            color="primary"
            $outlined
            onClick={() => setAddDialogOpen(true)}
            data-testid="button-add-entitlement"
            startIcon={<Icon icon="Add" type="materialIcons" />}>
            {t('entitlements.add')}
          </Button>
        )}
      </GridFlex.RowSpaceBetweenTop>
      <Text.Sub2 mb={4}>{t('entitlements.entitlementsSubtitle', { packageType })}</Text.Sub2>
    </>
  );
}
