import React, { useState, useCallback } from 'react';
import { Grid, GridFlex } from '@stigg-components';
import {
  EntitySelectionMode,
  ImportIntegrationCatalogInput,
  IntegrationFragment,
  TaskStatus,
  VendorIdentifier,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import pluralize from 'pluralize';
import * as Steps from '../wizardSteps';
import { StripeImportWizardFooter } from '../StripeImportWizardFooter';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { StripeImportTask } from '../StripeImportTask';
import { useAppDispatch } from '../../../../../redux/store';
import { triggerIntegrationCatalogImportAction } from '../../../integrationsSlice';
import { AnimatedStep, Wizard } from '../Wizard';
import { ProductCatalogSummary } from '../wizardSteps/ProductCatalogSummary';
import { StripeImportWizardType } from '../StripeImportWizard';
import { isTaskFinished, isTaskInProgress } from '../../../../../components/layout/navbar/components/tasks/taskUtils';
import { useNavigation } from '../../../../navigation/useNavigation';
import { isFeatureMetered } from '../../../../common/featureTypeUtils';

export enum ImportSteps {
  OVERVIEW = 0,
  SELECT_PRODUCTS = 1,
  PRICING_MODEL_SETTINGS = 2,
  REVIEW_AND_PUBLISH = 3,
}

const WIZARD_STEPS = [
  {
    component: (integration: IntegrationFragment) => <Steps.OverviewStep integration={integration} />,
  },
  {
    component: (integration: IntegrationFragment) => <Steps.ImportProductsStep integration={integration} />,
  },
  {
    component: () => <Steps.PricingModelSettingsStep />,
  },
  {
    component: () => <Steps.PreviewAndImportStep />,
  },
];

export function WizardMainContent({
  integration,
  activeStep,
  nextStep,
  prevStep,
  prevStepIndex,
  goToImportCustomers,
}: {
  integration: IntegrationFragment;
  activeStep: number;
  nextStep: () => void;
  prevStep: () => void;
  goToImportCustomers: () => void;
  prevStepIndex: React.MutableRefObject<number>;
}) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const importContext = useImportContext();
  const [importCatalogTask, setImportCatalogTask] = useState<{ taskId: string; taskStatus: TaskStatus } | null>(null);
  const onImport = useCallback(async () => {
    const {
      selectedProducts,
      unSelectedProducts,
      productsData: { headerSelectionChecked },
      selectedProduct,
      addonIds,
      billingModel,
      featureUnitName,
    } = importContext;

    let productsSelectionParams: Pick<
      ImportIntegrationCatalogInput,
      'entitySelectionMode' | 'plansSelectionBlacklist' | 'plansSelectionWhitelist'
    > | null = null;
    if (headerSelectionChecked) {
      productsSelectionParams = {
        entitySelectionMode: EntitySelectionMode.BlackList,
        plansSelectionBlacklist: unSelectedProducts.map((product) => product.id),
      };
    } else {
      productsSelectionParams = {
        entitySelectionMode: EntitySelectionMode.WhiteList,
        plansSelectionWhitelist: selectedProducts
          .filter((product) => !addonIds.includes(product.id))
          .map((product) => product.id),
      };
    }

    if (selectedProduct) {
      const importTask = await dispatch(
        triggerIntegrationCatalogImportAction({
          productId: selectedProduct.id,
          vendorIdentifier: VendorIdentifier.Stripe,
          selectedAddonBillingIds: addonIds,
          billingModel,
          featureUnitName: isFeatureMetered(billingModel) ? featureUnitName : null,
          featureUnitPluralName: isFeatureMetered(billingModel) ? pluralize.plural(featureUnitName) : null,
          ...productsSelectionParams,
        }),
      ).unwrap();
      if (importTask) {
        setImportCatalogTask({ taskId: importTask.taskId, taskStatus: TaskStatus.Pending });
      }
    }
  }, [dispatch, importContext]);

  const updateTaskStatus = useCallback((taskStatus: TaskStatus) => {
    setImportCatalogTask((prevState) => (prevState ? { ...prevState, taskStatus } : null));
  }, []);

  const isValid = () => {
    const { productsData, selectedProduct } = importContext;
    if (activeStep === ImportSteps.SELECT_PRODUCTS) {
      const hasSelectedItems =
        productsData.headerSelectionChecked ||
        productsData.products.some((product) => product.isSelected) ||
        productsData.searchWhitelistProducts.length > 0;
      return !!selectedProduct && hasSelectedItems;
    }

    return true;
  };

  const getButtonText = () => {
    if (activeStep === ImportSteps.REVIEW_AND_PUBLISH) {
      if (importCatalogTask) {
        return t('integrations.nextTask');
      }
      return t('integrations.importCTA');
    }

    return t('sharedComponents.nextButton');
  };

  const isImportCompleted = importCatalogTask && isTaskFinished(importCatalogTask.taskStatus);
  const isImportInProgress = importCatalogTask ? isTaskInProgress(importCatalogTask.taskStatus) : false;
  const onNextClick = () => {
    if (activeStep === ImportSteps.REVIEW_AND_PUBLISH) {
      if (isImportCompleted) {
        goToImportCustomers();
      } else {
        void onImport();
      }
    } else if (activeStep === ImportSteps.PRICING_MODEL_SETTINGS) {
      const hasError = isFeatureMetered(importContext.billingModel) && !importContext.featureUnitName;
      importContext.setFeatureUnitNameHasError(hasError);
      if (!hasError) {
        nextStep();
      }
    } else {
      nextStep();
    }
  };

  const onSkipClick = () => {
    if (!!importCatalogTask && activeStep === ImportSteps.REVIEW_AND_PUBLISH) {
      navigation.navigateTo(`/products/${importContext.selectedProduct?.refId}`);
    } else {
      goToImportCustomers();
    }
  };

  const skipButtonText =
    !!importCatalogTask && activeStep === ImportSteps.REVIEW_AND_PUBLISH
      ? t('integrations.viewImportedProducts')
      : t('integrations.importSkipButton');

  return (
    <Grid container item sm={8} xl={9} height="100%" justifyContent="center" sx={{ overflow: 'auto', zIndex: 0 }}>
      <GridFlex.Column xl={10} pt="35px" px="100px" height="100%">
        {importCatalogTask ? (
          <StripeImportTask
            taskId={importCatalogTask.taskId}
            setTaskStatus={updateTaskStatus}
            importSummary={<ProductCatalogSummary />}
            importType={StripeImportWizardType.IMPORT_CATALOG}
          />
        ) : (
          <Wizard activeStep={activeStep}>
            {WIZARD_STEPS.map(({ component }, index) => (
              <AnimatedStep key={index} activeStep={activeStep} prevStepIndex={prevStepIndex}>
                {component(integration)}
              </AnimatedStep>
            ))}
          </Wizard>
        )}
        <StripeImportWizardFooter
          activeStep={activeStep}
          isDisabled={isImportInProgress}
          prevStep={prevStep}
          onNextClick={onNextClick}
          getButtonText={getButtonText}
          isNextValid={isValid()}
          shouldShowSkipButton
          onSkipClick={onSkipClick}
          skipButtonText={skipButtonText}
          withBackButton={!importCatalogTask}
        />
      </GridFlex.Column>
    </Grid>
  );
}
