import { Chip, CodeTypographyText, GridFlex, Icon, LongText, useBroadcastChannel } from '@stigg-components';
import React, { useRef } from 'react';
import { t } from 'i18next';
import { MemberStatus } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { CustomerIcon } from '../../../customers/components/CustomerIcon';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { CardWrapper } from './SwitchAccountPage.style';
import { MembershipProps } from './SwitchAccountPage.types';
import { useNavigation, appRoutes } from '../../../navigation/useNavigation';
import { selectAccount } from '../../accountsSlice';

export function MembershipCard({
  membership: {
    account: { displayName, id: accountId },
    memberStatus,
  },
}: {
  membership: MembershipProps;
}) {
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigation();
  const currentAccountId = useSelector((state: RootState) => state.accountReducer.currentAccountId);
  const initialAccountId = useRef(currentAccountId);
  const broadcastChannel = useBroadcastChannel();

  const isInvited = memberStatus === MemberStatus.Invited;
  const isCurrent = initialAccountId.current === accountId;

  const onClick = () => {
    dispatch(selectAccount(accountId));
    broadcastChannel.sendMessage('switchAccount', {});
    navigateTo(appRoutes.confirmMemberPage(), { isGlobal: true });
  };

  return (
    <CardWrapper onClick={onClick}>
      <GridFlex.Row alignItems="center" gap={4} justifyContent="space-between" justifyItems="stretch">
        <GridFlex.Row alignItems="center" gap={4}>
          <CustomerIcon size={48} name={displayName} />
          <GridFlex.Column gap={2}>
            <LongText bold variant="body1" color="primary">
              {displayName}
            </LongText>
            <CodeTypographyText>{accountId}</CodeTypographyText>
          </GridFlex.Column>
        </GridFlex.Row>

        {isInvited && <Chip label={t('invited')} color="action.disabled" textColor="primary" size="small" />}
        {isCurrent && <Chip label={t('current')} color="primary" size="small" />}
        <Icon size={16} type="reactFeather" icon="ChevronRight" />
      </GridFlex.Row>
    </CardWrapper>
  );
}
