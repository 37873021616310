import { Add as AddIcon } from '@mui/icons-material';
import {
  Button,
  EmptyCardContent,
  Flex,
  FormRenderProps,
  Grid,
  InformationTooltip,
  Link,
  PageCard,
  Text,
  useScrollableAnchor,
  useHideIntercom,
} from '@stigg-components';
import { AddonFragment, PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Edit2 } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState } from '../../../../redux/store';
import { PlanTrialDefaultConfiguration } from '../../plans/components/PlanTrialDefaultConfiguration';
import { CustomPricingView, FreePricingView, PaidPricingView } from './pricingTypeViews';
import { getPackageTypeDisplay } from '../getPackageTypeDisplay';
import { SetPriceWizardFormFields } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import { SectionIdentifier } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import {
  DEFAULT_COUNTRY_CODE,
  extractCountryList,
  extractCountryWithCurrencyMap,
} from '../../pricing/components/currency/currencyUtils';
import { PlanOveragesPricing } from '../../plans/components/planPage/PlanOveragesPricing';
import { useCurrencySelector } from '../../pricing/components/currency/useCurrencySelector';
import { isPackagePlan } from '../packageUtils';
import { PlanMinimumSpend } from '../../plans/components/PlanMinimumSpend';

const EditIcon = styled(Edit2)`
  margin-left: 4px;
`;

type PackagePricingProps = {
  aPackage: PlanFragment | AddonFragment;
  readonly: boolean;
  hasPublishValidationError: boolean;
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void;
};

function renderPricingTypeView(
  aPackage: PlanFragment | AddonFragment,
  readonly: boolean,
  onEditPriceGroup: (priceGroupId: string, billingCountryCode: string) => void,
  billingCountryCode: string,
) {
  switch (aPackage.pricingType) {
    case PricingType.Free:
      return <FreePricingView />;
    case PricingType.Paid:
      return (
        <PaidPricingView
          aPackage={aPackage}
          readonly={readonly}
          onEditPriceGroup={onEditPriceGroup}
          billingCountryCode={billingCountryCode}
        />
      );
    case PricingType.Custom:
      return <CustomPricingView aPackage={aPackage} />;
    default:
      return null;
  }
}

export function PackagePricing({
  aPackage,
  readonly,
  hasPublishValidationError,
  openSetPriceWizard,
}: PackagePricingProps) {
  const packageTypeDisplayName = getPackageTypeDisplay(aPackage);
  const { elementRef } = useScrollableAnchor({
    anchor: 'pricing',
    delay: 250,
  });

  const countryCodesList = extractCountryList([aPackage]);
  const countryCodeToCurrencyMap = extractCountryWithCurrencyMap([aPackage]);
  const [CountryCodeSelectComponent, billingCountryCode] = useCurrencySelector({
    countryCodesList,
    countryCodeToCurrencyMap,
  });

  const { overagePricing: isOveragePricingEnabled, minimumSpend: isMinimumSpendEnabled } = useFlags<FeatureFlags>();

  const isRightDrawerOpen = useSelector((state: RootState) => state.application.isRightDrawerOpen);
  useHideIntercom(isRightDrawerOpen);

  const onEditPriceGroup = (priceGroupId: string, billingCountryCode: string) => {
    const section: SectionIdentifier =
      billingCountryCode === DEFAULT_COUNTRY_CODE
        ? { stepId: 'charges', priceGroupId }
        : { stepId: 'priceLocalization', billingCountryCode };

    openSetPriceWizard(section);
  };

  const isEdit = !!aPackage.pricingType;
  const isAwsProduct = !!aPackage.product?.awsMarketplaceProductId;
  const isPlanPackage = isPackagePlan(aPackage);

  const editButton = (
    <span>
      <Button
        disabled={isAwsProduct}
        color="primary"
        $outlined
        data-testid="edit-pricing-button"
        startIcon={isEdit ? <EditIcon size={16} /> : <AddIcon />}
        onClick={() => openSetPriceWizard()}>
        {isEdit ? t('sharedComponents.edit') : t('sharedComponents.setButton')}
      </Button>
    </span>
  );

  return (
    <PageCard ref={elementRef} cardProps={{ $withError: hasPublishValidationError }}>
      <Grid $fullWidth justifyContent="space-between">
        <Text.H3>{t('pricing.packageSectionTitle')}</Text.H3>
        {!readonly &&
          (isAwsProduct ? (
            <InformationTooltip
              arrow
              $padding={2}
              placement="left"
              title={<Text.B2>{t('pricing.awsMarketplace.editButtonTooltip')}</Text.B2>}>
              {editButton}
            </InformationTooltip>
          ) : (
            editButton
          ))}
      </Grid>

      <Text.Sub2>{t('pricing.packageSectionSubtitle', { packageType: packageTypeDisplayName })}</Text.Sub2>

      {!aPackage.pricingType ? (
        <EmptyCardContent>
          <Text.B2>{t('pricing.pricingEmptyText', { packageType: packageTypeDisplayName })}</Text.B2>
          {!readonly && (
            <Link variant="body2" ml={1} onClick={() => openSetPriceWizard()}>
              {t('pricing.pricingEmptySetLinkText')}
            </Link>
          )}
        </EmptyCardContent>
      ) : (
        <Flex.Column>
          {aPackage.pricingType === PricingType.Paid && countryCodesList.length > 1 && (
            <Flex.Item mt={4}>
              <CountryCodeSelectComponent />
            </Flex.Item>
          )}
          <Flex.Item mt={8}>
            {renderPricingTypeView(aPackage, readonly, onEditPriceGroup, billingCountryCode)}
          </Flex.Item>
        </Flex.Column>
      )}
      {isOveragePricingEnabled && isPlanPackage && !!aPackage?.prices?.length && (
        <PlanOveragesPricing
          plan={aPackage}
          readonly={readonly}
          openSetPriceWizard={openSetPriceWizard}
          billingCountryCode={billingCountryCode}
        />
      )}
      {isPlanPackage && isMinimumSpendEnabled && !!aPackage?.prices?.length && (
        <PlanMinimumSpend plan={aPackage} readonly={readonly} openSetPriceWizard={openSetPriceWizard} />
      )}
      {isPlanPackage &&
        !isAwsProduct &&
        (aPackage.pricingType === PricingType.Paid || aPackage.pricingType === PricingType.Custom) && (
          <PlanTrialDefaultConfiguration plan={aPackage} readonly={readonly} openSetPriceWizard={openSetPriceWizard} />
        )}
      {hasPublishValidationError && (
        <Text.B2 mt={4} color="error.main">
          {t('pricing.priceSetValidationError', { packageType: packageTypeDisplayName })}
        </Text.B2>
      )}
    </PageCard>
  );
}
