import { t } from 'i18next';
import { Trash2 } from 'react-feather';
import styled from 'styled-components/macro';
import { EmptyContent, Text, Link, GridFlex, IconButton } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { CouponSummary } from '../../../../coupons/components/CouponSummary';

export type CustomerCouponProps = {
  customer: CustomerResponseFragment;
  onAddCouponClick: () => void;
  onRemoveCouponClick: () => void;
};

const DeleteIcon = styled(Trash2)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  height: 20px;
  width: 20px;
`;

const CouponDeleteButton = styled(IconButton)`
  transition: 0.2s ease-in-out;
  opacity: 0;
`;

const HoverableGridFlex = styled(GridFlex.RowCenter)`
  :hover,
  :active,
  :focus {
    ${CouponDeleteButton} {
      opacity: 1;
    }
  }
`;

export function CustomerCoupon({ customer, onAddCouponClick, onRemoveCouponClick }: CustomerCouponProps) {
  const { coupon } = customer;

  if (!coupon) {
    return (
      <EmptyContent $padding={2}>
        <Text.B2 mr={2}>{t('customers.coupon.noValue')}</Text.B2>
        <Link onClick={onAddCouponClick}>{t('customers.coupon.addCouponLink')}</Link>
      </EmptyContent>
    );
  }

  return (
    <HoverableGridFlex>
      <CouponSummary coupon={coupon} withLink />
      <CouponDeleteButton onClick={onRemoveCouponClick}>
        <DeleteIcon />
      </CouponDeleteButton>
    </HoverableGridFlex>
  );
}
