import {
  AutocompleteRenderOptionState,
  Autocomplete,
  CircularProgress,
  Icon,
  InputProps,
  Paper,
  TextField,
} from '@stigg-components';
import { styled } from '@stigg-theme';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { ReactElement, ReactNode, useState } from 'react';
// only place we are using TextField directly as it's just part of the search and
// doesn't have a shared behaviour as other text fields. no label, no error.

interface AutocompleteSearchProps<TEntity> {
  chooseOption: (entity: TEntity | null) => void;
  searchOptions: readonly TEntity[];
  onSearchKeyUp: (event: any) => void;
  onFocus?: (entity: TEntity | null) => void;
  isFetching: boolean;
  isOptionDisabled?: (option: any) => boolean;
  placeholder?: string;
  getOptionLabel: (entity: TEntity) => string;
  renderOption: (props: any, entity: TEntity, state?: AutocompleteRenderOptionState) => ReactElement;
  isOptionEqualToValue: (option: TEntity, value: TEntity) => boolean;
  noOptionsText?: ReactNode;
  resetInputOnChoose?: boolean;
  defaultValue?: TEntity;
  inputProps?: InputProps;
  openSearchOnFocus?: boolean;
  selectWidth?: number;
  selectHeight?: number;
  disableClearable?: boolean;
  autoComplete?: boolean;
  autoHighlight?: boolean;
  autoSelect?: boolean;
  disablePortal?: boolean;
  blurOnSelect?: boolean;
  forcePopupIcon?: boolean;
  disabled?: boolean;
  allowReset?: boolean;
  onInputChange?: (newInputValue: string) => void;
  width?: number;
  dataTestId?: string;
  groupBy?: (option: TEntity) => string;
}

const ARROW_UP_KEYCODE = 38;
const ARROW_DOWN_KEYCODE = 40;

export const StyledPaper = styled(Paper)<{ $width?: number; $height?: number }>`
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground2};
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  max-height: 220px;
  overflow: auto;
  margin: 4px 0;
  ${({ $height }) => ($height ? `max-height: ${$height}px;` : '')}
  ${({ $width }) => ($width ? `width: ${$width}px;` : '')}
`;

export function AutocompleteSearch<TEntity extends { displayName: string }>(props: AutocompleteSearchProps<TEntity>) {
  const {
    chooseOption,
    searchOptions,
    onSearchKeyUp,
    onFocus,
    isFetching,
    isOptionDisabled,
    placeholder,
    getOptionLabel,
    renderOption,
    isOptionEqualToValue,
    noOptionsText,
    resetInputOnChoose = true,
    defaultValue,
    openSearchOnFocus,
    selectWidth,
    selectHeight,
    disableClearable,
    autoComplete,
    autoHighlight,
    autoSelect,
    disablePortal,
    blurOnSelect,
    onInputChange,
    forcePopupIcon,
    width,
    disabled,
    allowReset,
    inputProps = {},
    dataTestId,
    groupBy,
  } = props;

  const [inputValue, setInputValue] = useState(defaultValue ? defaultValue.displayName : '');
  const [value, setValue] = useState<TEntity | null>(defaultValue || null);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const loading = autoCompleteOpen && isFetching;

  const delayedSearch = debounce((e) => {
    // Do not search upon up and down arrow keys to allow users to choose an option with keyboard
    if (e.keyCode !== ARROW_DOWN_KEYCODE && e.keyCode !== ARROW_UP_KEYCODE) {
      onSearchKeyUp(e);
    }
  }, 150);

  return (
    <Autocomplete
      forcePopupIcon={forcePopupIcon}
      disabled={disabled}
      disablePortal={disablePortal}
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      blurOnSelect={blurOnSelect}
      autoSelect={autoSelect}
      data-testid={dataTestId}
      popupIcon={<Icon icon="ArrowDropDown" type="materialIcons" color="active" />}
      sx={{ width: width || '100%' }}
      noOptionsText={noOptionsText}
      value={value}
      groupBy={groupBy}
      PaperComponent={({ children }) => (
        <StyledPaper $width={selectWidth} $height={selectHeight}>
          {children}
        </StyledPaper>
      )}
      onChange={(e, option: TEntity | null, reason) => {
        if (option === null) {
          if (allowReset) {
            setValue(null);
            chooseOption(null);
          }
          return;
        }
        chooseOption(option);
        setValue(option);
        setInputValue(option.displayName);
        if (resetInputOnChoose && reason === 'selectOption') {
          setValue(null);
          setInputValue('');
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (allowReset || reason !== 'reset') {
          setInputValue(newInputValue);
        }
        if (onInputChange) {
          onInputChange(newInputValue);
        }
      }}
      getOptionLabel={(option) => getOptionLabel(option)}
      getOptionDisabled={(option) => (isOptionDisabled ? isOptionDisabled(option) : false)}
      renderOption={(props, option: TEntity, state) =>
        renderOption(
          {
            'data-value': option.displayName,
            ...props,
          },
          option,
          state,
        )
      }
      filterOptions={(x) => x}
      ListboxProps={{ style: { padding: 0, position: 'relative', overflow: 'unset' } }}
      open={autoCompleteOpen}
      onFocus={() => {
        if (openSearchOnFocus) {
          setAutoCompleteOpen(true);
        }
        if (onFocus) {
          onFocus(value);
        }
      }}
      onOpen={() => {
        setAutoCompleteOpen(true);
      }}
      onClose={() => {
        setAutoCompleteOpen(false);
      }}
      isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value)}
      options={sortBy(searchOptions, (option) => (isOptionDisabled ? isOptionDisabled(option) : false))}
      loading={loading}
      disableClearable={disableClearable ? value !== null : false}
      renderInput={(params) => {
        return (
          <TextField
            error={false}
            touched={false}
            name="feature"
            placeholder={placeholder}
            textFieldProps={params}
            InputProps={{ ...params.InputProps, ...inputProps }}
            endAdornment={
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            }
          />
        );
      }}
      onKeyUp={delayedSearch}
    />
  );
}
