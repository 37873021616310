import styled from 'styled-components/macro';
import { Chip } from '@stigg-components';

export const StatusChip = styled(Chip)<{ $withErrorBackground?: boolean; $miniature?: boolean }>`
  cursor: ${({ $hasPointer }) => ($hasPointer ? 'pointer' : 'auto')};
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  height: 28px;

  ${({ $withErrorBackground, theme }) =>
    $withErrorBackground && `background-color: ${theme.itamar.palette.error.background};`}
  ${({ $miniature }) => $miniature && `font-size: 12px;height: 22px;padding-left: 0px;padding-right: 0px;`}
`;
