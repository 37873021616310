import styled from 'styled-components/macro';
import { Box, Menu as MuiMenu } from '@stigg-components';

export const Menu = styled(MuiMenu)`
  margin-top: 4px;

  & > div {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }

  ul {
    padding: 0;
  }
`;

export const MenuHolder = styled(Box)<{ $fullWidth?: boolean }>`
  display: inline-block;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
`;
