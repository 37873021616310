import * as Yup from 'yup';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import map from 'lodash/map';
import { Box, Button, TextField, Text, Select, Grid, MenuItem, GridFlex } from '@stigg-components';
import { EnvironmentType, IntegrationFragment, VendorIdentifier, ZuoraCredentials } from '@stigg-types/apiTypes';
import { Optional } from '@stigg-types/primitives';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { ZUORA_DEVELOPMENT_TENANTS, ZUORA_PRODUCTION_TENANTS } from '../../constants';
import { updateIntegrationAction, createIntegrationAction } from '../../integrationsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { IntegrationContactUsState } from '../IntegrationContactUsState';

export type ZuoraIntegrationFormFields = {
  tenantBaseUrl: string;
  clientId: string;
  clientSecret: string;
  stripePublishableKey: string;
  stripeSecretKey: string;
};

const validationSchema = Yup.object().shape({
  tenantBaseUrl: Yup.string().required(t('packages.yup.required')),
  clientId: Yup.string().required(t('packages.yup.required')),
  clientSecret: Yup.string().required(t('packages.yup.required')),
});

export type ZuoraIntegrationFormProps = {
  onCancel: () => void;
  integration?: IntegrationFragment | null;
};

export function ZuoraIntegrationForm({ onCancel, integration }: ZuoraIntegrationFormProps) {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const availableTenants =
    currentEnvironment?.type === EnvironmentType.Production ? ZUORA_PRODUCTION_TENANTS : ZUORA_DEVELOPMENT_TENANTS;

  const credentials = integration?.credentials as Optional<ZuoraCredentials>;
  const hasIntegration = !!integration?.credentials;
  const initialValues = {
    tenantBaseUrl: credentials?.baseUrl || '',
    clientId: credentials?.clientId || '',
    clientSecret: credentials?.clientSecret || '',
    stripePublishableKey: credentials?.stripePublishableKey || '',
    stripeSecretKey: credentials?.stripeSecretKey || '',
  } as ZuoraIntegrationFormFields;
  const { zuoraIntegration, zuoraIntegrationEnableInsertStripeSecrets } = useFlags<FeatureFlags>();

  const createLoading = useSelector((state: RootState) => state.integrations.createLoading);
  const updateLoading = useSelector((state: RootState) => state.integrations.updateLoading);
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: ZuoraIntegrationFormFields) => {
    const { tenantBaseUrl, clientId, clientSecret, stripePublishableKey, stripeSecretKey } = values;
    if (integration) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration.id,
          integrationData: {
            vendorIdentifier: VendorIdentifier.Zuora,
            zuoraCredentials: { baseUrl: tenantBaseUrl, clientId, clientSecret, stripePublishableKey, stripeSecretKey },
          },
        }),
      ).unwrap();
    } else {
      await dispatch(
        createIntegrationAction({
          vendorIdentifier: VendorIdentifier.Zuora,
          zuoraCredentials: { baseUrl: tenantBaseUrl, clientId, clientSecret, stripePublishableKey, stripeSecretKey },
        }),
      ).unwrap();
    }
    onCancel();
  };
  if (!hasIntegration && !zuoraIntegration) {
    return <IntegrationContactUsState headerComponent={null} integrationType={VendorIdentifier.Zuora} />;
  }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur }) => (
        <GridFlex.Column $fullWidth>
          {createLoading || updateLoading ? (
            <Box alignItems="center" minHeight={300}>
              <Loader />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column $fullWidth item pt={5} container rowSpacing={4}>
                <GridFlex.Column item>
                  <Select
                    fullWidth
                    labelId="tenantBaseUrl"
                    label={t('integrations.zuoraTenant')}
                    value={values.tenantBaseUrl}
                    name="tenantBaseUrl"
                    renderValue={(s: any) => <>{s}</>}
                    onChange={handleChange}>
                    {map(availableTenants, (tenant) => (
                      <MenuItem key={tenant.baseUrl} value={tenant.baseUrl}>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>{tenant.name}</Grid>
                          <Grid item>
                            <Text.Sub2>{tenant.baseUrl}</Text.Sub2>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </GridFlex.Column>
                <Grid mr={2} sm item>
                  <TextField
                    name="clientId"
                    label={t('integrations.clientId')}
                    value={values.clientId}
                    touched={!!touched.clientId}
                    error={!!errors.clientId}
                    fullWidth
                    errorText={errors.clientId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="password"
                    name="clientSecret"
                    label={t('integrations.clientSecret')}
                    value={values.clientSecret}
                    touched={!!touched.clientSecret}
                    error={!!errors.clientSecret}
                    fullWidth
                    errorText={errors.clientSecret}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                {zuoraIntegrationEnableInsertStripeSecrets && (
                  <Grid item>
                    <TextField
                      type="password"
                      name="stripePublishableKey"
                      label={t('integrations.stripePublishableKey')}
                      value={values.stripePublishableKey}
                      touched={!!touched.stripePublishableKey}
                      error={!!errors.stripePublishableKey}
                      fullWidth
                      errorText={errors.stripePublishableKey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                {zuoraIntegrationEnableInsertStripeSecrets && (
                  <Grid item>
                    <TextField
                      type="password"
                      name="stripeSecretKey"
                      label={t('integrations.stripeSecretKey')}
                      value={values.stripeSecretKey}
                      touched={!!touched.stripeSecretKey}
                      error={!!errors.stripeSecretKey}
                      fullWidth
                      errorText={errors.stripeSecretKey}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
                {integration ? (
                  <Grid item alignSelf="end">
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('packages.cancelButton')}
                    </Button>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.editSaveButton')}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary" fullWidth>
                      {t('integrations.zuora.connectButton')}
                    </Button>
                  </Grid>
                )}
              </GridFlex.Column>
            </form>
          )}
        </GridFlex.Column>
      )}
    </Formik>
  );
}
