import Frame, { FrameContext } from 'react-frame-component';
import React, { useContext } from 'react';
import { StyleSheetManager } from 'styled-components';
import dottedBackgroundDark from '@assets/images/widgets/dottedPattern.png';
import { Theme } from '@mui/material/styles';

const InjectFrameStyles = (props: any) => {
  const { document } = useContext(FrameContext);
  const { children } = props;
  return <StyleSheetManager target={document?.head}>{children}</StyleSheetManager>;
};

type StyledFrameProps = {
  theme: Theme;
  [key: string]: any;
};

export const StyledFrame = (props: StyledFrameProps) => {
  const { style, children, theme, ...otherProps } = props;

  return (
    <Frame
      style={{
        colorScheme: 'light',
        display: 'block',
        overflow: 'scroll',
        border: 0,
        ...style,
        backgroundImage: `url(${dottedBackgroundDark})`,
        backgroundColor: theme.isLightTheme ? '#F1F1F1' : 'rgba(151, 150, 150, 0.50)',
      }}
      {...otherProps}>
      <InjectFrameStyles>{children}</InjectFrameStyles>
    </Frame>
  );
};
