import { gql } from '@apollo/client';

export const SUBSCRIPTION_INVOICE = gql`
  fragment SubscriptionInvoiceFragment on SubscriptionInvoice {
    status
    errorMessage
    paymentUrl
    pdfUrl
    createdAt
    updatedAt
    amountDue
    currency
  }
`;
