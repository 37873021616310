import { THEMES } from './variants';

const STIGG_THEME_KEY = 'STIGG_THEME';

export function getCachedTheme(): THEMES {
  try {
    const cachedTheme = localStorage.getItem(STIGG_THEME_KEY);
    return cachedTheme ? (cachedTheme as THEMES) : THEMES.LIGHT;
  } catch (e) {
    return THEMES.LIGHT;
  }
}

export function setCachedTheme(themeVariant: THEMES) {
  try {
    localStorage.setItem(STIGG_THEME_KEY, themeVariant);
  } catch (e) {
    console.error('Could not set theme variant in local storage', e);
  }
}
