import { Currency, PriceFragment, PriceTier, TiersMode } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { sum } from 'lodash';

export type Money = {
  amount: number;
  currency: Currency;
};

export type GetPriceOptions = { unitQuantity?: number; totalPrice?: boolean };

function getPriceCurrencyForTier({ unitPrice, flatPrice }: PriceTier): Currency {
  return unitPrice?.currency || flatPrice?.currency || Currency.Usd;
}

function getPriceAmountForTier(tier: PriceTier, unitQuantity: number): number {
  const { unitPrice, flatPrice } = tier;

  let amount = 0;

  if (unitPrice) {
    amount += unitPrice.amount * unitQuantity;
  }

  if (flatPrice) {
    amount += flatPrice.amount;
  }

  return amount;
}

export function findTierByQuantity(tiers: PriceTier[], unitQuantity: number) {
  const quantityTier = tiers.find((tier) => !tier.upTo || tier.upTo >= unitQuantity);

  // Fallback to the last tier if quantity exceeds the last tier
  return quantityTier || tiers[tiers.length - 1];
}

function getTotalPriceTierVolume(tiers: PriceTier[], unitQuantity: number): Money {
  const tier = findTierByQuantity(tiers, unitQuantity);
  const currency = getPriceCurrencyForTier(tier);
  const amount = getPriceAmountForTier(tier, unitQuantity);

  return { amount, currency };
}

export function getTotalPriceTierGraduated(
  tiers: PriceTier[],
  unitQuantity: number,
): { total: Money; breakdown: Array<{ unitQuantity: number; amount: number }> } {
  let remainingQuantity = unitQuantity;
  let prevUpTo = 0;
  let currentTierIndex = 0;

  const breakdown: Array<{ unitQuantity: number; amount: number }> = [];

  while (remainingQuantity > 0 && currentTierIndex < tiers.length) {
    const tier = tiers[currentTierIndex];
    const { upTo } = tier;

    if (isNil(upTo)) {
      breakdown.push({
        unitQuantity: remainingQuantity,
        amount: getPriceAmountForTier(tier, remainingQuantity),
      });
      remainingQuantity = 0;
    } else {
      const unitsInTier = upTo - prevUpTo;
      const consumed = Math.min(remainingQuantity, unitsInTier);
      breakdown.push({
        unitQuantity: consumed,
        amount: getPriceAmountForTier(tier, consumed),
      });
      remainingQuantity -= consumed;
      prevUpTo = upTo;
    }

    currentTierIndex += 1;
  }

  const currency = getPriceCurrencyForTier(tiers[0]);
  const amount = sum(breakdown.map(({ amount }) => amount));

  return {
    breakdown,
    total: { amount, currency },
  };
}

/*
    Returns the price point, for tiered pricing we return the correct tier 
*/
export function getPrice(
  price: Pick<PriceFragment, 'price' | 'tiersMode' | 'tiers' | 'blockSize'>,
  options: GetPriceOptions = {},
): Money {
  const { tiersMode, tiers, price: pricePoint, blockSize } = price;
  const { unitQuantity = 1, totalPrice } = options;

  let amount = 0;
  let currency = Currency.Usd;

  if (tiersMode && tiers) {
    if (totalPrice) {
      switch (tiersMode) {
        case TiersMode.Volume:
          return getTotalPriceTierVolume(tiers, unitQuantity);
        case TiersMode.Graduated:
          return getTotalPriceTierGraduated(tiers, unitQuantity).total;
        default:
          break;
      }
    } else {
      const tier = findTierByQuantity(tiers, unitQuantity);
      amount = getPriceAmountForTier(tier, 1);
      currency = getPriceCurrencyForTier(tier);
    }
  } else if (pricePoint) {
    ({ amount, currency } = pricePoint);

    if (totalPrice) {
      amount *= Math.ceil(unitQuantity / (blockSize || 1));
    }
  }

  if (unitQuantity === 0) {
    amount = 0;
  }

  return {
    amount,
    currency,
  };
}
