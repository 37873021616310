import React from 'react';
import { GridFlex, Text, TextField, Select, MenuItem } from '@stigg-components';
import { Currency } from '@stigg-types/apiTypes';
import get from 'lodash/get';
import getSymbolFromCurrency from 'currency-symbol-map';
import { t } from 'i18next';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { PricePeriod } from '../../SetPriceWizardForm.types';

export function OverageSetPriceComponent({
  pricePeriodsId,
  priceId,
  currency,
}: {
  pricePeriodsId: string;
  priceId: string;
  currency: Currency;
}) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { billingPeriods } = formRenderProps.values;

  const priceIds = billingPeriods.map((billingPeriod) => `${pricePeriodsId}[${billingPeriod}]`);

  // Currently we only support having one overage price - so we can just use
  // the first billingPeriod price id and set the same value for both billing periods
  const aPriceId = priceIds[0];
  const amountId = `${aPriceId}.amount`;
  const blockSizeId = `${aPriceId}.blockSize`;
  const isBlockPricingId = `${priceId}.isBlockPricing`;

  const { values, touched, errors, handleBlur, setFieldValue, setFieldTouched } = formRenderProps;

  const withBlockPricing = !!get(values, isBlockPricingId);

  const onFieldChange = (e: React.ChangeEvent<any>, field: keyof PricePeriod) => {
    const value = e.target.value ? Number(e.target.value) : undefined;

    priceIds.forEach((priceId) => {
      const fieldId = `${priceId}[${field}]`;
      setFieldValue(fieldId, value);
      setFieldTouched(fieldId);
    });
  };

  return (
    <GridFlex.Column>
      <GridFlex.RowCenter container gap={2}>
        <GridFlex.Column item maxWidth={150}>
          <TextField
            name={amountId}
            value={get(values, amountId)}
            touched={!!get(touched, amountId)}
            error={!!get(errors, amountId)}
            placeholder={t('pricing.priceAmountPlaceholder')}
            type="number"
            isNumberWithoutSigns
            startAdornment={getSymbolFromCurrency(currency)}
            onBlur={handleBlur}
            onChange={(e) => onFieldChange(e, 'amount')}
          />
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.B2>{t('pricing.overageCharges.price.charge.perAdditional')}</Text.B2>
        </GridFlex.Column>
        <GridFlex.Column item minWidth={100}>
          <Select
            onChange={(e) => {
              setFieldValue(`${priceId}.isBlockPricing`, e.target.value === 'block');
              onFieldChange({ target: { value: null } } as any, 'blockSize');
            }}
            value={withBlockPricing ? 'block' : 'unit'}
            dataTestId="block-or-unit-select-input"
            renderValue={(value: any) => {
              return <Text.B2>{value}</Text.B2>;
            }}>
            {['unit', 'block'].map((value) => (
              <MenuItem key={value} value={value}>
                <Text.B2 color="primary" data-testid={`block-or-unit-select-${value.toLowerCase()}`}>
                  {value}
                </Text.B2>
              </MenuItem>
            ))}
          </Select>
        </GridFlex.Column>
        {withBlockPricing ? (
          <>
            <GridFlex.Column item>
              <Text.B2>{t('sharedComponents.of')}</Text.B2>
            </GridFlex.Column>
            <GridFlex.Column item maxWidth={150}>
              <TextField
                name={blockSizeId}
                value={get(values, blockSizeId)}
                touched={!!get(touched, blockSizeId)}
                error={!!get(errors, blockSizeId)}
                type="number"
                isNumberWithoutSigns
                endAdornment={t('pricing.overageCharges.price.charge.units')}
                onBlur={handleBlur}
                onChange={(e) => onFieldChange(e, 'blockSize')}
              />
            </GridFlex.Column>
          </>
        ) : null}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
