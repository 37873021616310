import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  Product,
  ErrorCode,
  DuplicateProductInput,
  DuplicateProductMutation,
  DuplicateProductMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';
import { fetchProductsAction } from '../productsSlice';

const DUPLICATE_PRODUCT = gql`
  mutation DuplicateProduct($input: DuplicateProductInput!) {
    duplicateProduct(input: $input) {
      displayName
      id
      refId
    }
  }
`;

export async function duplicateProduct(
  productInput: Omit<DuplicateProductInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Product> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input = {
        ...productInput,
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<DuplicateProductMutation, DuplicateProductMutationVariables>({
        mutation: DUPLICATE_PRODUCT,
        variables: { input },
      });

      const newProduct = response.data?.duplicateProduct;
      if (newProduct) {
        void dispatch(fetchProductsAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch: true }));
        dispatch(navigateTo(appRoutes.productPage(newProduct.refId)));
      }

      return newProduct;
    },
    {
      successMessage: t('products.api.successCreate'),
      failureMessageHandler: () => t('products.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('products.api.failDupProduct'),
      },
    },
    dispatch,
  );
}
