import { useEffect } from 'react';
import { useId } from '../hooks/useId';
import { useStiggIntercomContext } from './StiggIntercomContext';

// In many cases we want to just hide the loader in specific component eg loaders,hiding ui elements
// But after the component is not visible we would like to load it back up
export function useHideIntercom(shouldHideIntercom: boolean) {
  const id = useId();
  const { setHideIntercom } = useStiggIntercomContext();

  useEffect(() => {
    setHideIntercom(id, shouldHideIntercom);
    return () => setHideIntercom(id, false);
  }, [id, shouldHideIntercom, setHideIntercom]);
}
