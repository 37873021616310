import { FormRenderProps } from '@stigg-components';
import { Formik, FormikHelpers } from 'formik';
import isFunction from 'lodash/isFunction';
import { useCallback, useState } from 'react';
import { Field, FormProps } from './Form.types';
import { FormFieldLayout, LayoutOptions } from './FormFieldLayout';
import { FormContent } from './FormContent';

export function drawFormFields<FormValues extends Record<string, any>>(
  fields: FormProps<FormValues>['fields'],
  formRenderProps: FormRenderProps<FormValues>,
  layoutOptions?: LayoutOptions,
) {
  const { values } = formRenderProps;
  const formFields = isFunction(fields) ? fields(formRenderProps) : fields;

  return formFields
    .filter((field) => !field.hide?.(values))
    .map((field: Field<FormValues>, index: number) => {
      return (
        <FormFieldLayout
          key={index}
          index={index}
          field={field}
          formRenderProps={formRenderProps}
          layoutOptions={layoutOptions}
        />
      );
    });
}

export function Form<FormValues extends Record<string, any>>(props: FormProps<FormValues>) {
  const {
    initialValues,
    validate,
    validationSchema,
    onSubmit,
    isInitialValid,
    validateOnChange = true,
    enableReinitialize,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      setIsSubmitting(true);
      await onSubmit(values, formikHelpers);
      setIsSubmitting(false);
    },
    [onSubmit],
  );

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      validateOnChange={validateOnChange}
      validate={validate}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      isInitialValid={isInitialValid}>
      {(formProps) => <FormContent formProps={formProps} isSubmitting={isSubmitting} {...props} />}
    </Formik>
  );
}
