import { Grid, GridFlex, Switch, Text, VisibilityOffIcon, VisibilityOnIcon } from '@stigg-components';
import { WidgetType } from '@stigg-types/apiTypes';
import { CustomerVisibilityFormFieldProps } from './types';

export function PackagePaywallCustomerVisibilityFormField({
  isPaywallHidden,
  onToggle,
}: CustomerVisibilityFormFieldProps) {
  return (
    <Grid item>
      <GridFlex.RowSpaceBetween>
        <Switch checked={!isPaywallHidden} onChange={(_) => onToggle(isPaywallHidden ? [] : [WidgetType.Paywall])} />
        <Text.B2 pr={2}>Visible to customers</Text.B2>
        {isPaywallHidden ? <VisibilityOffIcon size={20} /> : <VisibilityOnIcon size={20} />}
      </GridFlex.RowSpaceBetween>
    </Grid>
  );
}
