import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateEnvironment,
  CreateEnvironmentMutation,
  CreateEnvironmentMutationVariables,
  EnvironmentType,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { fetchEnvironmentsAction, selectEnvironment } from '../accountsSlice';
import { LimitExceededAlert } from '../../../components/LimitExceededAlert';

const ADD_ENVIRONMENT = gql`
  mutation CreateEnvironmentMutation($input: CreateOneEnvironmentInput!) {
    createOneEnvironment(input: $input) {
      id
      displayName
      description
      slug
    }
  }
`;

async function addEnvironment(
  {
    noSuccessMessage,
    noPostFetch,
    environment,
  }: { noSuccessMessage?: boolean; noPostFetch?: boolean; environment: CreateEnvironment },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>({
        mutation: ADD_ENVIRONMENT,
        variables: {
          input: {
            environment,
            options: { createDefaultProduct: true },
          },
        },
      });

      const newEnvironment = response.data!.createOneEnvironment;
      if (noPostFetch) {
        return newEnvironment;
      }

      await dispatch(fetchEnvironmentsAction({}));

      if (newEnvironment) {
        dispatch(selectEnvironment(newEnvironment.id));
      }

      return newEnvironment;
    },
    {
      successMessage: noSuccessMessage ? undefined : t('accounts.api.successCreateEnv'),
      failureMessageHandler: () => t('accounts.api.failCreateEnv'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('accounts.api.failEnvAlreadyExists'),
        [ErrorCode.EntitlementLimitExceededError]: (
          <LimitExceededAlert
            alertMessage={
              environment.type === EnvironmentType.Development
                ? t('accounts.api.nonProductionEntitlementLimitExceeded')
                : t('accounts.api.productionEntitlementLimitExceeded')
            }
          />
        ),
      },
    },
    dispatch,
  );
}

export { addEnvironment };
