import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { CouponFilter, FetchCouponsQuery, FetchCouponsQueryVariables } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { AppDispatch } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { COUPON_FRAGMENT } from './fetchCoupons';

const FETCH_COUPONS = gql`
  ${COUPON_FRAGMENT}
  query FetchCouponsByRefId($filter: CouponFilter, $sorting: [CouponSort!], $paging: CursorPaging) {
    coupons(filter: $filter, sorting: $sorting, paging: $paging) {
      edges {
        node {
          ...CouponFragment
        }
      }
    }
  }
`;

export type FetchCouponProps = {
  refId: string;
  environmentId: string;
};

async function fetchCouponByRefId(props: FetchCouponProps, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const filter: CouponFilter = {
        environmentId: { eq: props.environmentId },
        refId: { eq: props.refId },
      };

      const response = await apolloClient.query<FetchCouponsQuery, FetchCouponsQueryVariables>({
        query: FETCH_COUPONS,
        fetchPolicy: 'network-only',
        variables: {
          filter,
        },
      });

      return response.data?.coupons?.edges[0]?.node;
    },
    {
      failureMessageHandler: () => t('coupons.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchCouponByRefId };
