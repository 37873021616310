import { FormRenderProps, useMountValue } from '@stigg-components';
import { AddonFragment, PlanFragment, PricingType, VendorIdentifier } from '@stigg-types/apiTypes';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useIntegrations } from '@stigg-common';
import { SetPriceWizardFormProvider } from './SetPriceWizardForm.context';
import { validationSchema } from './SetPriceWizardForm.validationSchema';
import { getInitialValues } from './SetPriceWizardForm.initialValues';
import { useSetPriceWizardFormOnSubmit } from './useSetPriceWizardFormOnSubmit';
import { TooltipFields } from '../../../../../../components/InformationTooltip';
import { SectionIdentifier } from './SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from './SetPriceWizardForm.types';
import { SetPriceWizardFormContent } from './SetPriceWizardFormContent';
import { getBillingIntegration } from '../../../../../../utils/integrationUtils';

type SetPriceWizardFormProps = {
  aPackage: PlanFragment | AddonFragment;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  onClose: () => void;
  onExit: () => void;
  setHasPublishValidationError: (hasError: boolean) => void;
  disabledPricingTypes: Map<PricingType, TooltipFields>;
  initialFocusedSection?: SectionIdentifier;
  initialFormUpdate?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void;
};

export function SetPriceWizardForm({
  aPackage,
  setHasUnsavedChanges,
  onClose,
  onExit,
  setHasPublishValidationError,
  disabledPricingTypes,
  initialFocusedSection: initialFocusedSectionProp,
  initialFormUpdate,
}: SetPriceWizardFormProps) {
  const initialFocusedSection = useMountValue(initialFocusedSectionProp);
  const initialValues = useMemo(() => getInitialValues(aPackage), [aPackage]);
  const onSubmit = useSetPriceWizardFormOnSubmit(aPackage, initialValues, onClose, setHasPublishValidationError);
  const { integrations } = useIntegrations();
  const billingIntegration = getBillingIntegration(integrations);
  const vendorIdentifier = billingIntegration?.vendorIdentifier;
  const disallowTierBothPrices = vendorIdentifier === VendorIdentifier.Zuora;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema({ disallowTierBothPrices })}
      onSubmit={onSubmit}>
      {(formRenderProps) => {
        return (
          <SetPriceWizardFormProvider
            aPackage={aPackage}
            initialFocusedSection={initialFocusedSection}
            formRenderProps={formRenderProps}
            disabledPricingTypes={disabledPricingTypes}>
            <SetPriceWizardFormContent
              onExit={onExit}
              setHasUnsavedChanges={setHasUnsavedChanges}
              initialFormUpdate={initialFormUpdate}
              submitForm={formRenderProps.submitForm}
              isValid={formRenderProps.isValid}
              dirty={formRenderProps.dirty}
              isSubmitting={formRenderProps.isSubmitting}
            />
          </SetPriceWizardFormProvider>
        );
      }}
    </Formik>
  );
}
