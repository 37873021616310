import styled from 'styled-components/macro';

const Wrapper = styled.div<{ $time: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
    background: #fff;
  }
  .loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 7px solid ${({ theme }) => theme.itamar.palette.primary.main};
    animation: ${({ $time }) => `prixClipFix ${$time}s infinite linear`};
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`;

export function CircleFillLoader({ loadEverySeconds }: { loadEverySeconds: number }) {
  return (
    <Wrapper $time={loadEverySeconds}>
      <span className="loader" />
    </Wrapper>
  );
}
