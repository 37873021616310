import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateOnePackageEntitlementMutation,
  UpdateOnePackageEntitlementMutationVariables,
  PackageEntitlementUpdateInput,
  PackageEntitlement,
  ErrorCode,
} from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const UPDATE_PACKAGE_ENTITLEMENT = gql`
  mutation UpdateOnePackageEntitlement($input: UpdateOnePackageEntitlementInput!) {
    updateOnePackageEntitlement(input: $input) {
      id
    }
  }
`;

async function updatePackageEntitlement(
  {
    packageEntitlementId,
    updatePayload,
  }: { packageEntitlementId: string; updatePayload: PackageEntitlementUpdateInput },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<PackageEntitlement> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        UpdateOnePackageEntitlementMutation,
        UpdateOnePackageEntitlementMutationVariables
      >({
        mutation: UPDATE_PACKAGE_ENTITLEMENT,
        variables: {
          input: {
            id: packageEntitlementId,
            update: {
              ...updatePayload,
              ...(!isNil(updatePayload.usageLimit) ? { usageLimit: Math.floor(updatePayload.usageLimit) } : {}),
            },
          },
        },
      });

      return response.data?.updateOnePackageEntitlement;
    },
    {
      failureMessageHandler: () => t('entitlements.api.failUpdate'),
      expectedErrorsMessage: {
        [ErrorCode.CannotEditPackageInNonDraftMode]: t('entitlements.api.cannotEditNonDraftMode'),
      },
    },
    dispatch,
  );
}

export { updatePackageEntitlement };
