import { Box as MuiBox } from '@stigg-components';
import styled from 'styled-components/macro';

export const Box = styled(MuiBox)`
  background: ${({ theme }) =>
    theme.isLightTheme
      ? `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.itamar.palette.primary.main}`
      : theme.itamar.palette.success.background};

  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;
