import { ExternalLink, Collapse, GridFlex, IconButton, IconButtonProps, Text } from '@stigg-components';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { ChevronRight } from 'react-feather';
import { TextColor } from '@stigg-theme';
import isString from 'lodash/isString';
import { HelpTooltip } from './HelpTooltip';
import { GridProps } from './Grids';

export const CollapsableSectionIcon = styled(ChevronRight)<{ $isOpen: boolean; $errorColor?: boolean; $size?: number }>`
  height: ${({ $size = 24 }) => `${$size}px`};
  width: ${({ $size = 24 }) => `${$size}px`};
  color: ${({ $errorColor, theme }) =>
    $errorColor ? theme.itamar.palette.error.main : theme.itamar.palette.action.active};
  transition: all 0.2s ease-in;
  ${({ $isOpen }) => $isOpen && `transform: rotate(90deg)`}
`;

export function CollapsableSection({
  title,
  titleHelperTooltip,
  titleHelperTooltipLink,
  onClick,
  isSectionOpen,
  content,
  color,
  gap = 2,
  buttonSx,
  sx,
  titleGridSx,
  $fullWidth,
  hideIcon,
  iconSize,
  dataTestId,
}: {
  title: React.ReactNode;
  onClick: () => void;
  isSectionOpen: boolean;
  content: React.ReactNode;
  gap?: number;
  color?: TextColor;
  titleHelperTooltip?: string;
  titleHelperTooltipLink?: string;
  buttonSx?: IconButtonProps['sx'];
  sx?: GridProps['sx'];
  titleGridSx?: GridProps['sx'];
  $fullWidth?: boolean;
  hideIcon?: boolean;
  iconSize?: number;
  dataTestId?: string;
}) {
  return (
    <GridFlex.Column $fullWidth={$fullWidth} gap={gap} sx={sx} data-testid={dataTestId}>
      <GridFlex.RowCenter alignItems="center" $fullWidth={$fullWidth} sx={titleGridSx}>
        <GridFlex.RowCenter sx={{ cursor: 'pointer' }} $fullWidth={$fullWidth} onClick={onClick}>
          {isString(title) ? <Text.H6 color={color}>{title}</Text.H6> : title}
          {titleHelperTooltip && (
            <HelpTooltip $maxWidth={250}>
              <>
                <Text.B2 mb={2}>{titleHelperTooltip}</Text.B2>
                {titleHelperTooltipLink && (
                  <ExternalLink label={t('sharedComponents.learnMore')} url={titleHelperTooltipLink} />
                )}
              </>
            </HelpTooltip>
          )}
        </GridFlex.RowCenter>
        {!hideIcon && (
          <IconButton sx={buttonSx} onClick={onClick} data-testid="toggle-section" data-isopen={isSectionOpen}>
            <CollapsableSectionIcon $isOpen={isSectionOpen} $errorColor={color === 'error'} $size={iconSize} />
          </IconButton>
        )}
      </GridFlex.RowCenter>
      <Collapse in={isSectionOpen}>{content}</Collapse>
    </GridFlex.Column>
  );
}
