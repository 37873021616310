import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

const GET_ENTITLEMENT_REQUEST = (featureRefId: string, addRequestedUsage: boolean) => `var result = stigg.query(
  GetEntitlementQuery.builder()
  .query(FetchEntitlementQuery.builder()
         .customerId("${MOCK_CUSTOMER_ID}")
         .featureId("${featureRefId}")
         .resourceId("site-1") // optional resource ID
         ${
           addRequestedUsage
             ? `.options(EntitlementOptions.builder()
              .requestedUsage(${MOCK_REQUESTED_USAGE})
              .build()
          )
          .build())`
             : '.build())'
         }
  .build()
);
  
if (result.entitlement.isGranted) {
// customer has access to the feature
} else {
// access denied
}`;

export const javaFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `import io.stigg.api.client.Stigg;

class App {
    public static void main(String[] ...args) {
        var stigg = Stigg.createClient("${apiKey}");
    }
}`,
  checkBooleanEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkNumericEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkMeteredEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, true),
  reportUsageForMeteredFeature: (featureRefId: string) => `var resp = stigg.mutation(
    ReportUsageMutation.builder()
    .input(
      UsageMeasurementCreateInput.builder()
      .customerId("${MOCK_CUSTOMER_ID}")
      .featureId("${featureRefId}")
      .value(1.0)
      .resourceId("site-1") // optional resource ID
      .build())
    .build());`,

  reportEventForMeteredFeature: () => `var resp = stigg.mutation(
    ReportEventMutation.builder()
    .input(
      UsageEventsReportInput.builder()
      .usageEvents(List.of(
        UsageEventReportInput.builder()
        .customerId("${MOCK_CUSTOMER_ID}")
        .eventName("user_login")
        .idempotencyKey("227c1b73-883a-457b-b715-6ba5a2c69ce4")
        .timestamp(Instant.now())
        .dimensions(new HashMap<>() {{
          put("user_id", "user-01");
          put("ip", "127.0.0.1");
        }})
        .resourceId("site-1") // optional resource ID
        .build()
      ))
      .build())
    .build());`,
};
