import { t } from 'i18next';
import { gql } from '@apollo/client';
import { Dispatch } from '@reduxjs/toolkit';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { executeOperationSafely } from '@stigg-common';
import { ArchivePlanMutation, ArchivePlanMutationVariables, ErrorCode } from '@stigg-types/apiTypes';
import { RootState } from '../../../../redux/store';
import { apolloClient } from '../../../../ApolloClient';
import { removePlanById } from '../plansSlice';

const ARCHIVE_PLAN_BY_ID = gql`
  mutation ArchivePlan($input: ArchivePlanInput!) {
    archivePlan(input: $input) {
      id
    }
  }
`;

const mutationFunction = (id: string, environmentId: string, dispatch: Dispatch) => {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<ArchivePlanMutation, ArchivePlanMutationVariables>({
        mutation: ARCHIVE_PLAN_BY_ID,
        variables: {
          input: {
            id,
            environmentId,
          },
        },
      });
    },
    {
      successMessage: t('plans.api.successArchive'),
      failureMessageHandler: (err) => {
        const error = err.graphQLErrors[0]?.extensions;
        if (error.code === ErrorCode.PlanWithChildCantBeDeleted) {
          return t('plans.api.cantDeletePlansWithChildren', {
            childPlanRefId: error.childPlanRefId,
          });
        }
        return t('plans.api.failDelete');
      },
      expectedErrorsMessage: {
        [ErrorCode.IntegrityViolation]: t('plans.api.failDeleteIntegrityViolation'),
      },
    },
    dispatch,
  );
};

export const useArchivePlanById = (id: string) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  return useMutation({
    mutationFn: () => mutationFunction(id, environmentId, dispatch),
    onSuccess: () => {
      dispatch(removePlanById({ id }));
    },
  });
};
