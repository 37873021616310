import { styled } from '@stigg-theme';
import { Button, GridFlex, Icon, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { ChargeType } from '../../SetPriceWizardForm.types';

const StyledButton = styled(Button)`
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  padding: ${({ theme }) => theme.spacing(8)};

  &:hover {
    background: ${({ theme }) => theme.itamar.palette.action.hover};
  }
`;

export function ChargeButton({ onClick, type }: { onClick: () => void; type: ChargeType }) {
  return (
    <StyledButton disableRipple onClick={onClick} data-testid={`button-first-charge-${type}`}>
      <GridFlex.RowCenter gap={2} mb={2}>
        {type === ChargeType.BasePrice ? (
          <Icon icon="BasePrice" color="active" overrideStroke />
        ) : (
          <Icon type="custom" icon="WaveSignal" color="active" overrideStroke />
        )}
        <Text.B2 $bold>{t(`pricing.charges.${type}.title`)}</Text.B2>
      </GridFlex.RowCenter>
      <Text.B2>{t(`pricing.charges.${type}.description`)}</Text.B2>
      <Text.B2 color="secondary">{t(`pricing.charges.${type}.example`)}</Text.B2>
    </StyledButton>
  );
}
