import React, { useState } from 'react';
import { Check, Copy } from 'react-feather';
import styled from 'styled-components/macro';
import { Text, Link, GridFlex, IconButton, InformationTooltip } from '@stigg-components';
import { Currency, SubscriptionInvoiceFragment, SubscriptionInvoiceStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import toLower from 'lodash/toLower';
import capitalize from 'lodash/capitalize';
import { StatusChip } from '@stigg-common';
import { currencyPriceFormatter } from '../../../../../packages/pricing/components/currency/currencyUtils';

export type SubscriptionLatestInvoiceProps = {
  latestInvoice: SubscriptionInvoiceFragment;
  onMarkInvoiceAsPaidClick: () => void;
};

const CopyInvoiceIcon = styled(Copy)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  height: 20px;
  width: 20px;
`;

const MarkInvoiceAsPaidIcon = styled(Check)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  height: 20px;
  width: 20px;
`;

const CopyInvoiceButton = styled(IconButton)`
  transition: 0.2s ease-in-out;
  opacity: 0;
  padding: 0;
  margin-left: 8px;
`;

const MarkInvoiceAsPaidButton = styled(IconButton)`
  transition: 0.2s ease-in-out;
  opacity: 0;
  padding: 0;
  margin-left: 8px;
`;

const HoverableGridFlex = styled(GridFlex.RowCenter)`
  :hover,
  :active,
  :focus {
    ${CopyInvoiceButton} {
      opacity: 1;
    }
    ${MarkInvoiceAsPaidButton} {
      opacity: 1;
    }
  }
`;

export function SubscriptionLatestInvoice({ latestInvoice, onMarkInvoiceAsPaidClick }: SubscriptionLatestInvoiceProps) {
  const [copyTooltipOpen, setCopyTooltipOpenOpen] = useState(false);

  const onCopy = async (paymentUrl) => {
    await navigator.clipboard.writeText(paymentUrl);
    setCopyTooltipOpenOpen(true);
    setTimeout(() => {
      setCopyTooltipOpenOpen(false);
    }, 1500);
  };
  const { pdfUrl, paymentUrl } = latestInvoice;
  const pdfUrlNoDownload = pdfUrl?.replace(/\/pdf\?s=ap/g, '');

  return (
    <HoverableGridFlex>
      <GridFlex.RowCenter>
        <InformationTooltip
          arrow
          placement="top"
          $padding={2}
          $maxWidth={400}
          title={<Text.B2>{t('subscriptions.viewInvoice')}</Text.B2>}>
          <div>
            <Link
              mr={2}
              href={pdfUrlNoDownload}
              target="_billing_tab"
              onClick={(e) => e.stopPropagation()}
              display="inline-block">
              {currencyPriceFormatter({
                amount: latestInvoice.amountDue!,
                currency: latestInvoice.currency!.toUpperCase()! as Currency,
                options: { withCodePostfix: true },
              })}
            </Link>
          </div>
        </InformationTooltip>
        <StatusChip
          label={capitalize(toLower(latestInvoice.status))}
          color={latestInvoice.status === SubscriptionInvoiceStatus.Paid ? 'disabled' : 'error'}
          textColor={latestInvoice.status === SubscriptionInvoiceStatus.Paid ? 'white' : 'error'}
          variant={latestInvoice.status === SubscriptionInvoiceStatus.Paid ? 'filled' : 'outlined'}
          $miniature
        />
      </GridFlex.RowCenter>

      {latestInvoice.status === SubscriptionInvoiceStatus.Open && (
        <>
          <CopyInvoiceButton onClick={() => onCopy(paymentUrl)}>
            <InformationTooltip
              arrow
              placement="top"
              $padding={2}
              $maxWidth={400}
              onClose={() => setCopyTooltipOpenOpen(false)}
              title={
                <Text.B2>{copyTooltipOpen ? t('sharedComponents.copied') : t('subscriptions.copyPaymentLink')}</Text.B2>
              }>
              <CopyInvoiceIcon />
            </InformationTooltip>
          </CopyInvoiceButton>
          <MarkInvoiceAsPaidButton onClick={onMarkInvoiceAsPaidClick}>
            <InformationTooltip
              arrow
              placement="top"
              $padding={2}
              $maxWidth={400}
              title={<Text.B2 color="primary">{t('subscriptions.markAsPaidToolTip')}</Text.B2>}>
              <MarkInvoiceAsPaidIcon />
            </InformationTooltip>
          </MarkInvoiceAsPaidButton>
        </>
      )}
    </HoverableGridFlex>
  );
}
