import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { GridFlex } from '@stigg-components';

const Wrapper = styled(GridFlex.Column)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

const Popover = styled(GridFlex.Column)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  width: 550px;
  padding: 24px;
  border-radius: 10px;
  gap: 8px;
`;

type BlurredFeatureProps = {
  backgroundComponent: ReactNode;
  popoverComponent: ReactNode;
};

export function GatedFeature({ backgroundComponent, popoverComponent }: BlurredFeatureProps) {
  return (
    <Wrapper>
      {backgroundComponent}
      <GridFlex.Item
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backdropFilter: 'blur(5px)' }}>
        <Popover>{popoverComponent}</Popover>
      </GridFlex.Item>
    </Wrapper>
  );
}
