import { ReactNode } from 'react';
import { Grid, GridFlex, Text, TextWithHelpTooltip, TooltipProps } from '.';
import { ClipboardChip } from './clipboard/ClipboardChip';

export type DetailsLayoutType = Array<{
  title: string;
  content?: ReactNode;
  mask?: boolean;
  shouldPrintLine?: boolean;
  alignItems?: 'flex-start' | 'center';
  contentWidth?: string | number;
  helperTooltip?: ReactNode;
  helperTooltipPlacement?: TooltipProps['placement'];
  helperTooltipMaxWidth?: number;
  copyableChip?: boolean;
  withLabel?: boolean;
}>;

export function DetailsLayout({
  details,
  isFullWidth = true,
  titleWidth = 120,
  mt,
}: {
  details: DetailsLayoutType;
  isFullWidth?: boolean;
  titleWidth?: number;
  mt?: number;
}) {
  const items = details.filter(({ content, shouldPrintLine = true }) => content && shouldPrintLine);

  return (
    <GridFlex.Column container={isFullWidth} mt={mt}>
      {items.map(
        (
          {
            content,
            copyableChip,
            withLabel,
            title,
            mask,
            alignItems = 'flex-start',
            contentWidth,
            helperTooltip,
            helperTooltipPlacement,
            helperTooltipMaxWidth,
          },
          dIndex,
        ) => (
          <Grid key={dIndex} mb={dIndex < items.length - 1 ? 4 : 0} alignItems={alignItems}>
            {copyableChip && typeof content === 'string' ? (
              <>
                {withLabel && title ? (
                  <GridFlex.Item sx={{ width: titleWidth, minWidth: titleWidth, mr: 2 }}>
                    {helperTooltip ? (
                      <TextWithHelpTooltip
                        text={title}
                        textSize="B2"
                        textColor="secondary"
                        tooltipText={helperTooltip}
                        tooltipPlacement={helperTooltipPlacement}
                        tooltipMaxWidth={helperTooltipMaxWidth}
                      />
                    ) : (
                      <Text.Sub2>{title}</Text.Sub2>
                    )}
                  </GridFlex.Item>
                ) : null}
                <ClipboardChip
                  tooltipSuffix={title}
                  text={content}
                  copy={content}
                  iconLocation="right"
                  variant="code"
                />
              </>
            ) : (
              <>
                <GridFlex.Item sx={{ width: titleWidth, minWidth: titleWidth, mr: 2 }}>
                  {helperTooltip ? (
                    <TextWithHelpTooltip
                      text={title}
                      textSize="B2"
                      textColor="secondary"
                      tooltipText={helperTooltip}
                      tooltipPlacement={helperTooltipPlacement}
                      tooltipMaxWidth={helperTooltipMaxWidth}
                    />
                  ) : (
                    <Text.Sub2>{title}</Text.Sub2>
                  )}
                </GridFlex.Item>

                <Text.B2 className={mask ? 'fs-mask' : ''} sx={{ width: contentWidth }}>
                  {content}
                </Text.B2>
              </>
            )}
          </Grid>
        ),
      )}
    </GridFlex.Column>
  );
}
