import { Box, ClickAwayListener, Tooltip, tooltipClasses, TooltipProps } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import { styled } from '@stigg-theme';
import { useEffect, useState } from 'react';
import { CheckCircle } from 'react-feather';
import { ErrorOutline as BaseErrorOutline } from '@mui/icons-material';
import { RotatingLines } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { ImportIntegrationTaskResultFragment, SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { RootState } from '../../../../../redux/store';
import { TasksList } from './TasksList';
import { isTaskFailed, isTaskInProgress } from './taskUtils';

const TooltipCheckCircle = styled(CheckCircle)`
  color: #4caf50;
`;
export const ErrorOutline = styled(BaseErrorOutline)`
  color: ${({ theme }) => theme.itamar.palette.error.main};
`;

const ProgressIconWrapper = styled(Box)`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground2};
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: 0,
  },
});

export function TasksProgress({
  subscriptionMigrationTasks,
  importTask,
}: {
  subscriptionMigrationTasks: SubscriptionMigrationTaskFragment[];
  importTask: ImportIntegrationTaskResultFragment | null;
}) {
  const theme = useTheme();
  const isTasksProgressTooltipOpen = useSelector((state: RootState) => state.application.isTasksProgressTooltipOpen);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const hasAnyTaskInProgress =
    subscriptionMigrationTasks?.some((task) => isTaskInProgress(task.status)) ||
    (importTask && isTaskInProgress(importTask.status));
  const hasAnyTaskFailed =
    subscriptionMigrationTasks?.some((task) => isTaskFailed(task.status)) ||
    (importTask && isTaskFailed(importTask.status));

  useEffect(() => {
    setIsTooltipOpen(isTasksProgressTooltipOpen);
  }, [isTasksProgressTooltipOpen]);

  const toggleProgress = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };
  const closeTasksTooltip = () => {
    setIsTooltipOpen(false);
  };

  const tooltipBackgroundColor = theme.isLightTheme
    ? theme.itamar.palette.white
    : theme.itamar.palette.background.paper;
  const borderProps = theme.isLightTheme
    ? {}
    : {
        border: theme.itamar.border.border,
        borderColor: theme.itamar.border.borderColor,
      };

  return (
    <ClickAwayListener onClickAway={closeTasksTooltip}>
      <div>
        <StyledTooltip
          onClose={closeTasksTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isTooltipOpen}
          title={
            <TasksList
              subscriptionMigrationTasks={subscriptionMigrationTasks}
              importTask={importTask}
              close={toggleProgress}
            />
          }
          placement="bottom-end"
          arrow
          componentsProps={{
            arrow: {
              sx: {
                fontSize: 22,
                color: tooltipBackgroundColor,

                marginLeft: -10,
                '&::before': {
                  boxShadow: theme.itamar.palette.shadow.lightShadow,
                  ...borderProps,
                },
              },
            },
            tooltip: {
              sx: {
                top: 8,
                right: -40,
                backgroundColor: tooltipBackgroundColor,
                ...borderProps,
                boxShadow: theme.itamar.palette.shadow.lightShadow,
                color: 'text.primary',
              },
            },
          }}>
          <ProgressIconWrapper onClick={toggleProgress} mr={1}>
            {hasAnyTaskInProgress ? (
              <RotatingLines width="24" strokeWidth="3" strokeColor={theme.itamar.palette.primary.main} />
            ) : hasAnyTaskFailed ? (
              <ErrorOutline />
            ) : (
              <TooltipCheckCircle data-testid="check-circle-svg-migration-success" />
            )}
          </ProgressIconWrapper>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
}
