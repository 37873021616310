import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useToggleNavbarControls } from '@stigg-common';
import { RootState, useAppDispatch } from '../../redux/store';
import { selectEnvironment } from '../accounts/accountsSlice';
import NotFoundPage from '../../components/NotFoundPage';
import ProtectedRoute from '../auth/components/ProtectedRoute';
import { CustomerPage, Customers, SubscriptionPage } from '../customers/components';
import { AddonPage, Addons } from '../packages/addons/components';
import { PlanPage, Plans } from '../packages/plans/components';
import { Features } from '../features/components';
import ProductsPage from '../products/components/ProductsPage';
import ProductPage from '../products/components/productPage/ProductPage';
import Loader from '../../components/Loader';
import IntegrationsPage from '../integrations/components/IntegrationsPage';
import { IntegrationPage } from '../integrations/components/IntegrationPage';
import Hooks from '../webhooks/components/Hooks';
import HookPage from '../webhooks/components/hookPage/HookPage';
import { DashboardsRoutes } from './DashboardsRoutes';
import { GettingStarted } from '../gettingStarted/components/GettingStarted';
import { Coupons } from '../coupons/components';
import CouponPage from '../coupons/components/couponPage/CouponPage';
import { Experiments, ExperimentPage } from '../experiemnts/components';
import { SlackIntegrationPage } from '../integrations/components/slack/SlackIntegrationPage';
import WidgetsPage from '../widgets/WidgetsPage';
import WidgetPage from '../widgets/WidgetPage';
import { UsageEventsPage } from '../usageEvents/UsageEventsPage';
import { AddonGroupPage } from '../packages/addons/components/AddonGroupPage';
import EventLogPage from '../eventsLog/EventLogPage';
import { useCurrentMember } from '../auth/hooks/useCurrentMember';

export function EnvironmentRoutes() {
  const { path, params } = useRouteMatch<{ environmentSlug: string }>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { removeNavbarControls } = useToggleNavbarControls();

  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentMember = useCurrentMember();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const paramEnvironment = environments.find((x) => x.slug === params.environmentSlug);

  const defaultRedirectUrl = `${path}/${
    currentEnvironment?.isSandbox || currentMember?.hideGettingStartedPage
      ? 'dashboards/subscriptions'
      : 'getting-started'
  }`;

  const currentEnvironmentIdRef = useRef(currentEnvironmentId);
  currentEnvironmentIdRef.current = currentEnvironmentId;

  useEffect(() => {
    if (paramEnvironment && paramEnvironment.id !== currentEnvironmentIdRef.current) {
      dispatch(selectEnvironment(paramEnvironment.id));
    }
  }, [dispatch, paramEnvironment]);

  useEffect(() => {
    removeNavbarControls('editDeleteControls');
  }, [location, removeNavbarControls]);

  if (!paramEnvironment) {
    return <Route component={NotFoundPage} />;
  }

  // in case the useEffect above didnt trigger yet
  if (currentEnvironmentId !== paramEnvironment.id) {
    return <Loader />;
  }

  return (
    <Switch>
      <ProtectedRoute path={`${path}/dashboards`} component={DashboardsRoutes} />
      <ProtectedRoute exact path={`${path}/getting-started`} component={GettingStarted} />
      <ProtectedRoute exact path={`${path}/products`} component={ProductsPage} />
      <ProtectedRoute exact path={`${path}/products/:refId`} component={ProductPage} />
      <ProtectedRoute exact path={`${path}/customers`} component={Customers} />
      <ProtectedRoute exact path={`${path}/customers/:refId`} component={CustomerPage} />
      <ProtectedRoute exact path={`${path}/experiments`} component={Experiments} />
      <ProtectedRoute exact path={`${path}/experiments/:refId`} component={ExperimentPage} />
      <ProtectedRoute exact path={`${path}/subscriptions/:refId`} component={SubscriptionPage} />
      <ProtectedRoute exact path={`${path}/addons`} component={Addons} />
      <ProtectedRoute exact path={`${path}/addons/:refId`} component={AddonPage} />
      <ProtectedRoute exact path={`${path}/addon-groups/:refId`} component={AddonGroupPage} />
      <ProtectedRoute exact path={`${path}/plans`} component={Plans} />
      <ProtectedRoute exact path={`${path}/plans/:refId`} component={PlanPage} />
      <ProtectedRoute exact path={`${path}/features`} component={Features} />
      <ProtectedRoute exact path={`${path}/coupons`} component={Coupons} />
      <ProtectedRoute exact path={`${path}/coupons/:refId`} component={CouponPage} />
      <ProtectedRoute exact path={`${path}/features/:refId`} component={Features} />
      <ProtectedRoute exact path={`${path}/integrations`} component={IntegrationsPage} />
      <ProtectedRoute exact path={`${path}/webhooks`} component={Hooks} />
      <ProtectedRoute exact path={`${path}/usage-events`} component={UsageEventsPage} />
      <ProtectedRoute exact path={`${path}/activity-log`} component={EventLogPage} />
      <ProtectedRoute exact path={`${path}/widgets`} component={WidgetsPage} />
      <ProtectedRoute exact path={`${path}/widgets/:widgetIdentifier`} component={WidgetPage} />
      <ProtectedRoute exact path={`${path}/webhooks/:id`} component={HookPage} />
      <ProtectedRoute exact path={`${path}/integrations/slack`} component={SlackIntegrationPage} />
      <ProtectedRoute exact path={`${path}/integrations/:vendorIdentifier`} component={IntegrationPage} />
      <Redirect from={`${path}/`} to={defaultRedirectUrl} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
