import { useState } from 'react';
import { t } from 'i18next';
import { CustomDrawer } from '@stigg-components';
import { useAppDispatch } from '../../../../redux/store';
import { ProductListItemFragment } from '../../../../types/apiTypes';
import { ExecuteOperationOptions } from '../../../common';
import { CreatePackageForm, CreatePackageFormFields } from '../../common/components/CreatePackage';
import { createPlanAction } from '../plansSlice';

type UseCreatePlanProps = {
  selectedProduct?: ProductListItemFragment;
  onCreatePlan?: () => Promise<void>;
  simplifiedForm?: boolean;
} & ExecuteOperationOptions;

export function useCreatePlan({
  selectedProduct,
  onCreatePlan,
  navigateToEntity = true,
  simplifiedForm = false,
  withSuccessMessage,
}: UseCreatePlanProps = {}) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onCreateSubmit = async (values: CreatePackageFormFields) => {
    await dispatch(createPlanAction({ planData: values, navigateToEntity, withSuccessMessage, silentFetch: true }));

    if (onCreatePlan) {
      await onCreatePlan();
    }

    setIsOpen(false);
  };

  const createPlanComponent = (
    <CustomDrawer title={t('plans.create')} isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <CreatePackageForm
        selectedProduct={selectedProduct}
        onSubmit={onCreateSubmit}
        onCancel={() => setIsOpen(false)}
        refIdPrefix={t('plans.refIdPrefix')}
        packageType="Plan"
        simplifiedForm={simplifiedForm}
      />
    </CustomDrawer>
  );

  return {
    createPlanComponent,
    isCreatePlanDialogOpen: isOpen,
    setIsCreatePlanDialogOpen: setIsOpen,
  };
}
