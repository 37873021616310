import {
  CustomerSubscriptionFilter,
  SearchSubscriptionsQuery,
  SearchSubscriptionsQueryVariables,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';

const SEARCH_SUBSCRIPTIONS = gql`
  query SearchSubscriptions($filter: CustomerSubscriptionFilter!, $paging: CursorPaging) {
    customerSubscriptions(filter: $filter, paging: $paging) {
      edges {
        node {
          subscriptionId
          customer {
            customerId
            name
            email
          }
        }
      }
    }
  }
`;

export async function searchSubscription(environmentId: string, search: string, resultLimit?: number) {
  const filter: CustomerSubscriptionFilter = {
    and: [{ environmentId: { eq: environmentId } }],
  };

  if (search) {
    filter.or = [{ refId: { eq: search } }, { billingId: { eq: search } }];
  }

  const res = await apolloClient.query<SearchSubscriptionsQuery, SearchSubscriptionsQueryVariables>({
    query: SEARCH_SUBSCRIPTIONS,
    fetchPolicy: 'network-only',
    variables: {
      paging: { first: resultLimit },
      filter,
    },
  });

  return res.data.customerSubscriptions.edges;
}
