import { gql } from '@apollo/client';

export const RESET_PERIOD_CONFIGURATION = gql`
  fragment YearlyResetPeriodConfigFragment on YearlyResetPeriodConfig {
    yearlyAccordingTo
  }
  fragment MonthlyResetPeriodConfigFragment on MonthlyResetPeriodConfig {
    monthlyAccordingTo
  }
  fragment WeeklyResetPeriodConfigFragment on WeeklyResetPeriodConfig {
    weeklyAccordingTo
  }
`;
