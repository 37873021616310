import React from 'react';
import { Alert, Text, useScrollToAnchor } from '@stigg-components';
import { t } from 'i18next';
import styled from 'styled-components/macro';

const StyledSpan = styled.span``;
export function CustomCSSAlertBox() {
  const { scrollToAnchorAndHighlight: scrollToCustomCssEditor } = useScrollToAnchor({
    anchor: 'customCssEditor',
  });
  return (
    <Alert severity="warning">
      <Text.Sub2 color="warning.content">
        <StyledSpan
          style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer', marginRight: '4px' }}
          onClick={scrollToCustomCssEditor}>
          {t('widgets.updateStyleWithCssNotification1stPart')}
        </StyledSpan>
        {t('widgets.updateStyleWithCssNotification')}
      </Text.Sub2>
    </Alert>
  );
}
