import { MeteredEntitlement } from '@stigg/js-client-sdk/dist/models';
import { StiggFeature } from '../StiggFeature';
import { useOptionalStiggContext } from './useOptionalStiggContext';

export function useMeteredEntitlement({
  feature,
  requestedUsage = 0,
}: {
  feature: StiggFeature;
  requestedUsage: number;
}): MeteredEntitlement {
  const stigg = useOptionalStiggContext();

  if (!stigg) {
    return {
      hasAccess: true,
      isUnlimited: true,
      currentUsage: 0,
      usageLimit: undefined,
      requestedUsage,
      isFallback: true,
    };
  }

  return stigg.getMeteredEntitlement({
    featureId: feature,
    options: {
      requestedUsage,
    },
  });
}
