import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { ExperimentStatus, FetchExperimentsQuery, FetchExperimentsQueryVariables } from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { EXPERIMENT_FRAGMENT } from './fetchExperimentByRefId';

export const EXPERIMENT_LIST_FRAGMENT = gql`
  fragment ExperimentListFragment on Experiment {
    id
    refId
    name
    status
    startedAt
    stoppedAt
    variantPercentage
    controlGroupName
    variantGroupName
    description
  }
`;

const FETCH_EXPERIMENTS = gql`
  query fetchExperiments(
    $activeExperimentsFilter: ExperimentFilter!
    $nonActiveExperimentsCountFilter: ExperimentFilter!
  ) {
    activeExperiments: experiments(filter: $activeExperimentsFilter) {
      edges {
        node {
          ...ExperimentFragment
        }
      }
    }
    nonActiveExperimentsCount: experiments(filter: $nonActiveExperimentsCountFilter) {
      totalCount
    }
  }

  ${EXPERIMENT_FRAGMENT}
`;

export type FetchExperimentsProps = Record<string, never>;

export async function fetchExperiments(
  props: FetchExperimentsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId },
      } = getState();

      if (!currentEnvironmentId) {
        return { activeExperiments: [], nonActiveExperimentsCount: 0 };
      }

      const response = await apolloClient.query<FetchExperimentsQuery, FetchExperimentsQueryVariables>({
        query: FETCH_EXPERIMENTS,
        fetchPolicy: 'network-only',
        variables: {
          activeExperimentsFilter: {
            environmentId: { eq: currentEnvironmentId },
            status: { eq: ExperimentStatus.InProgress },
          },
          nonActiveExperimentsCountFilter: {
            environmentId: { eq: currentEnvironmentId },
            status: { neq: ExperimentStatus.InProgress },
          },
        },
      });

      return {
        activeExperiments: response.data.activeExperiments.edges.map((edge) => edge.node),
        nonActiveExperimentsCount: response.data.nonActiveExperimentsCount.totalCount,
      };
    },
    {
      failureMessageHandler: () => t('experiments.api.failFetch'),
    },
    dispatch,
  );
}
