import { gql } from '@apollo/client';
import {
  FetchProductForEditByRefIdQueryVariables,
  FetchProductForEditByRefIdQuery,
  Product,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { RootState } from '../../../redux/store';

export const PRODUCT_FOR_EDIT_FRAGMENT = gql`
  fragment ProductForEditFragment on Product {
    id
    refId
    description
    displayName
    multipleSubscriptions
    hasSubscriptions
    awsMarketplaceProductCode
    awsMarketplaceProductId
  }
`;

const FETCH_PRODUCT = gql`
  query FetchProductForEditByRefId($filter: ProductFilter!) {
    products(filter: $filter) {
      edges {
        node {
          ...ProductForEditFragment
        }
      }
    }
  }
  ${PRODUCT_FOR_EDIT_FRAGMENT}
`;

type FetchProductForEditByRefIdProps = { refId: string; silentFetch?: boolean };

async function fetchProductForEditByRefId(
  { refId }: FetchProductForEditByRefIdProps,
  { getState }: { getState: () => RootState },
): Promise<Product | any | null> {
  const { accountReducer } = getState();
  if (!accountReducer.currentEnvironmentId) {
    throw new Error('environment Id must be set');
  }
  const res = await apolloClient.query<FetchProductForEditByRefIdQuery, FetchProductForEditByRefIdQueryVariables>({
    query: FETCH_PRODUCT,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        refId: {
          eq: refId,
        },
        environmentId: { eq: accountReducer.currentEnvironmentId || '' },
      },
    },
  });

  const products = res.data.products.edges;
  return products.length > 0 ? products[0].node : null;
}

export { fetchProductForEditByRefId };
