import React, { useState } from 'react';
import { filter, includes, isEmpty } from 'lodash';
import * as Yup from 'yup';
import { Button, Chip, GridFlex, TextField, Text } from '@stigg-components';
import { Formik } from 'formik';
import { t } from 'i18next';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch } from '../../../../redux/store';
import { inviteNewMembersAction } from '../../accountsSlice';
import { Dialog, DialogActionsButtons } from '../../../../components/Dialog';
import { useMeteredEntitlement } from '../../../../doggo/hooks/useMeteredEntitlement';
import { StiggFeature } from '../../../../doggo/StiggFeature';
import { MembersLimitNotification } from './MembersLimitNotification';
import { useActiveSubscriptions } from '../../../../doggo/hooks/useActiveSubscriptions';
import { disableInvites } from './MembersLimitNotification.utils';
import { useOptionalStiggContext } from '../../../../doggo/hooks/useOptionalStiggContext';

interface InviteMembersDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().min(1).email(),
});
type InviteMembersFormFields = {
  email: string;
};

const initialValues = {
  email: '',
} as InviteMembersFormFields;

function InviteMembersDialog(props: InviteMembersDialogProps) {
  const { onCancel, isOpen, onSubmit } = props;
  const dispatch = useAppDispatch();
  const [invites, setInvites] = useState<string[]>([]);
  const entitlement = useMeteredEntitlement({ feature: StiggFeature.SEATS, requestedUsage: invites.length });

  const { stiggForStiggWeb } = useFlags<FeatureFlags>();

  const { activeSubscriptions } = useActiveSubscriptions();
  const stigg = useOptionalStiggContext();

  const addInvite = (invite: string) => {
    if (!includes(invites, invite)) {
      if (validationSchema.isValidSync({ email: invite })) {
        setInvites([...invites, invite]);
      }
    }
  };

  const sendInvites = async () => {
    await dispatch(inviteNewMembersAction(invites));
    onSubmit();
    setInvites([]);
    await stigg?.refresh();
  };

  function handleChipDelete(deletedInvite: string) {
    setInvites(filter(invites, (invite) => invite !== deletedInvite));
  }

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === t('accounts.enter') && value) {
      addInvite(value);
    }
  };

  const content = (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values: any, { resetForm }) => {
        addInvite(values.email);
        resetForm();
      }}>
      {({ errors, values, touched, handleSubmit, handleChange, handleBlur, resetForm }) => (
        <form onSubmit={handleSubmit}>
          {stiggForStiggWeb && (
            <MembersLimitNotification entitlement={entitlement} activeSubscriptions={activeSubscriptions} />
          )}
          <GridFlex.Column spacing={6} sx={{ height: '100%' }}>
            <TextField
              autoFocus
              aria-autocomplete="none"
              maxRows={4}
              name="email"
              label={t('accounts.email')}
              type="email"
              value={values.email}
              touched={!!touched.email}
              error={!!errors.email}
              errorText={errors.email}
              onKeyDown={handleKeyDown}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              captionText={t('accounts.enterPrompt')}
              disabled={stiggForStiggWeb && disableInvites(entitlement, activeSubscriptions, invites)}
              InputProps={{ style: { paddingRight: 0 } }}
              endAdornment={
                !!values.email &&
                !errors.email && (
                  <Button
                    sx={{ borderRadius: '6px' }}
                    variant="contained"
                    onClick={() => {
                      addInvite(values.email);
                      resetForm();
                    }}>
                    <Text.B2 color="white">{t('accounts.add')}</Text.B2>
                  </Button>
                )
              }
            />
            <GridFlex.Row rowGap={2} columnGap={2} flexWrap="wrap" my={4}>
              {invites &&
                invites.map((invite) => (
                  // todo: check correct color
                  <Chip color="primary" label={invite} key={invite} onDelete={() => handleChipDelete(invite)} />
                ))}
            </GridFlex.Row>
          </GridFlex.Column>
          <DialogActionsButtons
            saveText={t('accounts.invite')}
            saveDisabled={isEmpty(invites)}
            onCancel={onCancel}
            onSave={sendInvites}
            disableButtonAsSubmit
          />
        </form>
      )}
    </Formik>
  );

  return (
    <Dialog
      titleText={t('accounts.inviteWithEmail')}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      content={content}
      width={650}
      isOpen={isOpen}
      onCancel={() => {
        onCancel();
        setInvites([]);
      }}
    />
  );
}

export default InviteMembersDialog;
