import React, { forwardRef, PropsWithChildren } from 'react';
import { Collapse, CollapseCard } from '@stigg-components';
import { SpacingProps } from '@mui/system';
import { CardContent } from './index';

export const CollapsePageCard = forwardRef<
  any,
  PropsWithChildren<{
    contentOpen: boolean;
    cardTitle: React.ReactElement;
    className?: string;
    cardProps?: React.ComponentProps<typeof CollapseCard> & SpacingProps;
    cardContentProps?: React.ComponentProps<typeof CardContent>;
  }>
>(({ contentOpen, cardTitle, children, className, cardProps = {}, cardContentProps = {} }, ref) => (
  <CollapseCard p={10} className={className} {...cardProps} ref={ref} cardContentProps={cardContentProps}>
    {cardTitle}
    <Collapse in={contentOpen} sx={{ width: '100%', pt: contentOpen ? '24px' : undefined }}>
      {children}
    </Collapse>
  </CollapseCard>
));
