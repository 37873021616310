import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { RootState } from '../../../redux/store';
import Loader from '../../../components/Loader';
import { VendorIdentifier } from '../constants';

// When redirecting back from Stripe Connect, the query params conflicts with Auth0 query params,
// so we need to extract the authorization "code" and redirect again under a different name.
export const StripeConnectRedirectForwarder = () => {
  const searchQuery = useSelector((root: RootState) => root.router.location.query);
  const { code, error, state } = searchQuery;

  useEffect(() => {
    let redirectUrl = window.location.origin;

    if (state) {
      const { environmentSlug, isTestMode } = JSON.parse(decodeURIComponent(state));
      redirectUrl += `/${environmentSlug}/integrations/${VendorIdentifier.Stripe.toLowerCase()}`;
      if (!error) {
        redirectUrl += `?authorizationCode=${code}&isTestMode=${isTestMode}`;
      }
    }

    window.location.href = redirectUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, state]);

  return <Loader />;
};
