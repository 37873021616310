import { gql } from '@apollo/client';
import { t } from 'i18next';
import { Plan, CreatePlanDraftMutation, CreatePlanDraftMutationVariables } from '@stigg-types/apiTypes';
import { Anchors } from '@stigg-components';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';

const CREATE_PLAN_DRAFT = gql`
  mutation CreatePlanDraft($input: String!) {
    createPlanDraft(id: $input) {
      id
      refId
      versionNumber
    }
  }
`;

async function createPlanDraft(
  { planId, anchor }: { planId: string; anchor?: Anchors },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Plan> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<CreatePlanDraftMutation, CreatePlanDraftMutationVariables>({
        mutation: CREATE_PLAN_DRAFT,
        variables: {
          input: planId,
        },
      });
      const newPlan = response.data?.createPlanDraft;
      if (newPlan?.refId) {
        const { refId, versionNumber } = newPlan;
        dispatch(navigateTo(`/plans/${refId}?version=${versionNumber}`, { anchor }));
      }
      return newPlan;
    },
    {
      failureMessageHandler: () => t('plans.api.failCreateDraft'),
    },
    dispatch,
  );
}

export { createPlanDraft };
