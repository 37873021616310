import { ButtonAndOptionsDropdown, Icon, Text } from '@stigg-components';
import React from 'react';

type ButtonDefinition = {
  icon: React.ReactNode;
  text: string | React.ReactNode;
  onClick: () => void;
};

export type PendingPaymentButtonsProps = {
  paymentLink?: string | null;
  cancelUpdate?: () => void;
};

export function PaymentCollectionCtaButtons({ paymentLink, cancelUpdate }: PendingPaymentButtonsProps) {
  const buttons: ButtonDefinition[] = [];

  if (paymentLink) {
    buttons.push({
      icon: <Icon icon="Copy" size={20} color="active" />,
      text: <Text.B2>Copy payment link</Text.B2>,
      onClick: () => navigator.clipboard.writeText(paymentLink),
    });
    buttons.push({
      icon: <Icon icon="ExternalLink" size={20} color="active" />,
      text: <Text.B2>Open payment link</Text.B2>,
      onClick: () => window.open(paymentLink, '_blank'),
    });
  }

  if (cancelUpdate) {
    buttons.push({
      icon: <Icon icon="Trash2" type="reactFeather" size={20} color="active" />,
      text: <Text.B2>Cancel update</Text.B2>,
      onClick: cancelUpdate,
    });
  }

  const [firstButton, ...restButtons] = buttons;

  if (!firstButton) {
    return null;
  }

  return (
    <ButtonAndOptionsDropdown
      color="outlinedRestingBackground"
      variant="outlined"
      button={firstButton}
      options={restButtons.map((action) => ({
        ...action,
        icon: (() => action.icon) as React.FC,
      }))}
    />
  );
}
