import { motion, Variants } from 'framer-motion';
import { PropsWithChildren } from 'react';

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction === 0 ? -400 : 400,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction === 0 ? -400 : 400,
      opacity: 0,
    };
  },
};

export function AnimatedStep({ children, currentStep }: PropsWithChildren<{ currentStep: number }>) {
  return (
    <motion.div
      key={currentStep}
      custom={currentStep}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: 'spring',
        stiffness: 400,
        delay: 0,
        damping: 30,
      }}>
      {children}
    </motion.div>
  );
}
