import * as Yup from 'yup';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import { Box, Button, TextField, Text, Grid, GridFlex, Select, MenuItem } from '@stigg-components';
import { IntegrationFragment, VendorIdentifier, Auth0Credentials, Auth0Region } from '@stigg-types/apiTypes';
import { Optional } from '@stigg-types/primitives';
import { updateIntegrationAction, createIntegrationAction } from '../../integrationsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { CountryFlag } from '../../../packages/pricing/components/currency/CountryWithFlagByCode';

export type Auth0IntegrationFormFields = {
  tenant: string;
  region: Auth0Region;
  clientId: string;
  clientSecret: string;
};

const validationSchema = Yup.object().shape({
  clientId: Yup.string().required(t('packages.yup.required')),
  clientSecret: Yup.string().required(t('packages.yup.required')),
});

export type Auth0IntegrationFormProps = {
  onCancel: () => void;
  integration?: IntegrationFragment | null;
};

export function Auth0IntegrationForm({ onCancel, integration }: Auth0IntegrationFormProps) {
  const credentials = integration?.credentials as Optional<Auth0Credentials>;
  const initialValues = {
    tenant: credentials?.tenant || '',
    region: credentials?.region || Auth0Region.Us,
    clientId: credentials?.clientId || '',
    clientSecret: credentials?.clientSecret || '',
  } as Auth0IntegrationFormFields;

  const createLoading = useSelector((state: RootState) => state.integrations.createLoading);
  const updateLoading = useSelector((state: RootState) => state.integrations.updateLoading);
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: Auth0IntegrationFormFields) => {
    const { clientId, clientSecret, region, tenant } = values;
    if (integration) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration.id,
          integrationData: {
            vendorIdentifier: VendorIdentifier.Auth0,
            auth0Credentials: { clientId, clientSecret, region, tenant },
          },
        }),
      ).unwrap();
    } else {
      await dispatch(
        createIntegrationAction({
          vendorIdentifier: VendorIdentifier.Auth0,
          auth0Credentials: { clientId, clientSecret, region, tenant },
        }),
      ).unwrap();
    }
    onCancel();
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur }) => (
        <GridFlex.Column $fullWidth>
          {createLoading || updateLoading ? (
            <Box alignItems="center" minHeight={300}>
              <Loader />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column $fullWidth item pt={5} container rowSpacing={4}>
                <GridFlex.Column item>
                  <TextField
                    name="tenant"
                    label={t('integrations.auth0Tenant')}
                    value={values.tenant}
                    placeholder={t('integrations.auth0Tenant')}
                    touched={!!touched.tenant}
                    error={!!errors.tenant}
                    endAdornment={
                      <Text.B2>
                        {toLower(`.${t(`integrations.auth0RegionDescriptor.${values.region}`)}.auth0.com`)}
                      </Text.B2>
                    }
                    fullWidth
                    errorText={errors.tenant}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </GridFlex.Column>
                <GridFlex.Column item>
                  <Select
                    fullWidth
                    labelId="region"
                    label={t('integrations.auth0Region')}
                    placeholder={t('integrations.auth0Region')}
                    value={values.region}
                    name="region"
                    renderValue={(region: any) => (
                      <GridFlex.Row columnGap={2}>
                        <CountryFlag countryCode={region === Auth0Region.Uk ? 'gb' : region.toLowerCase()} />
                        <Grid item>
                          <Text.B2>{t(`integrations.auth0RegionDescriptor.${region}`)}</Text.B2>
                        </Grid>
                      </GridFlex.Row>
                    )}
                    onChange={handleChange}>
                    {map(Object.values(Auth0Region), (region: Auth0Region) => (
                      <MenuItem key={region} value={region}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <CountryFlag countryCode={region === Auth0Region.Uk ? 'gb' : region.toLowerCase()} />
                          </Grid>
                          <Grid item ml={2}>
                            <Text.B2>{t(`integrations.auth0RegionDescriptor.${region}`)}</Text.B2>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </GridFlex.Column>
                <Grid mr={2} sm item>
                  <TextField
                    name="clientId"
                    label={t('integrations.clientId')}
                    placeholder={t('integrations.clientId')}
                    value={values.clientId}
                    touched={!!touched.clientId}
                    error={!!errors.clientId}
                    fullWidth
                    errorText={errors.clientId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="password"
                    name="clientSecret"
                    label={t('integrations.clientSecret')}
                    placeholder={t('integrations.clientSecret')}
                    value={values.clientSecret}
                    touched={!!touched.clientSecret}
                    error={!!errors.clientSecret}
                    fullWidth
                    errorText={errors.clientSecret}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                {integration ? (
                  <Grid item alignSelf="end">
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('packages.cancelButton')}
                    </Button>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.editSaveButton')}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button disabled={!isValid} type="submit" variant="contained" color="primary" fullWidth>
                      {t('integrations.auth0.connectButton')}
                    </Button>
                  </Grid>
                )}
              </GridFlex.Column>
            </form>
          )}
        </GridFlex.Column>
      )}
    </Formik>
  );
}
