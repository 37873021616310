import { GridFlex, Link, Text } from '@stigg-components';
import { t } from 'i18next';
import { DowngradeChangeVariables, PlanChangeVariables, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../../../../redux/store';
import { fetchPlansAction } from '../../../../../../../packages/plans/plansSlice';
import { useNavigation } from '../../../../../../../navigation/useNavigation';
import { SubscriptionFutureUpdateRowContainer } from '../SubscriptionFutureUpdateRowContainer';
import {
  getAddonsChanges,
  getBillingPeriodChange,
  getPlanChange,
  getUnitAmountChanges,
} from './PlanChangeUpdateRow.utils';
import { BillingPeriodUpdateRow } from './BillingPeriodUpdateRow';
import { AddonUpdateRow } from './AddonUpdateRow';
import { UnitAmountUpdateRow } from './UnitAmountUpdateRow';

export function PlanChangeUpdateRow({
  changeVariables,
  subscription,
  alertVariant,
}: {
  changeVariables: PlanChangeVariables | DowngradeChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const plansListFragments = useSelector((state: RootState) => state.plansReducer.plans);
  const planChange = getPlanChange(changeVariables, plansListFragments);
  const billingPeriodChange = getBillingPeriodChange(changeVariables, subscription);
  const unitAmountChanges = getUnitAmountChanges(changeVariables, subscription);
  const addonsChange = getAddonsChanges(changeVariables, subscription);

  useEffect(() => {
    if (!plansListFragments.edges) {
      void dispatch(fetchPlansAction({ environmentId: currentEnvironmentId }));
    }
  }, [currentEnvironmentId, dispatch, plansListFragments.edges]);

  return (
    <>
      {planChange && (
        <SubscriptionFutureUpdateRowContainer changeType={planChange.changeType}>
          <GridFlex.Row alignItems="baseline" gap={1}>
            <Text.B2 color={alertVariant ? 'warning.content' : undefined}>{t(planChange.textPrefix)}</Text.B2>
            <Link
              color={alertVariant ? 'warning.content' : undefined}
              variant="body2"
              onClick={() => {
                navigation.navigateTo(navigation.appRoutes.planPage(planChange.planRefId));
              }}>
              <Text.B2 $bold color={alertVariant ? 'warning.content' : undefined}>
                {planChange.planDisplayName}
              </Text.B2>
            </Link>
            <Text.B2 color={alertVariant ? 'warning.content' : undefined}>
              {t('subscriptions.schedules.downgradePlan')}
            </Text.B2>
          </GridFlex.Row>
        </SubscriptionFutureUpdateRowContainer>
      )}
      {billingPeriodChange && (
        <BillingPeriodUpdateRow changeVariables={billingPeriodChange} alertVariant={alertVariant} />
      )}
      {unitAmountChanges.map((changeVariables) => (
        <UnitAmountUpdateRow
          key={changeVariables.featureId}
          changeVariables={changeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      ))}
      {addonsChange.map((changeVariables) => (
        <AddonUpdateRow
          key={changeVariables.addonRefId}
          changeVariables={changeVariables}
          subscription={subscription}
          alertVariant={alertVariant}
        />
      ))}
    </>
  );
}
