import { gql } from '@apollo/client';
import { t } from 'i18next';
import { StopExperimentMutation, StopExperimentMutationVariables } from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';
import { appRoutes } from '../../navigation/useNavigation';
import { fetchActiveExperimentsAction, fetchExperimentByRefIdAction } from '../experimentsSlice';

const STOP_EXPERIMENT = gql`
  mutation stopExperiment($input: StopExperimentInput!) {
    stopExperiment(input: $input)
  }
`;

export async function stopExperiment(
  { refId }: { refId: string },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId: environmentId },
      } = getState();
      if (!environmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<StopExperimentMutation, StopExperimentMutationVariables>({
        mutation: STOP_EXPERIMENT,
        variables: {
          input: {
            refId,
            environmentId,
          },
        },
      });

      await Promise.all([
        dispatch(fetchExperimentByRefIdAction({ refId, silentFetch: true })),
        dispatch(fetchActiveExperimentsAction({ silentFetch: true })),
      ]);

      dispatch(navigateTo(appRoutes.experimentPage(refId)));
    },
    {
      successMessage: t('experiments.api.successEndExperiment'),
      failureMessageHandler: () => t('experiments.api.failEndExperiment'),
    },
    dispatch,
  );
}
