import { t } from 'i18next';
import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomDrawer, GridFlex } from '@stigg-components';
import { PackageStatus, PlanFragment, UpdatePackageEntitlementOrderInput } from '@stigg-types/apiTypes';
import { useToggleNavbarControls } from '@stigg-common';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { EditPackageForm, EditPackageFormFields } from '../../../common/components/EditPackageForm';
import { PackageDetails } from '../../../common/components/PackageDetails';
import { PackageGrantedEntitlements } from '../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements';
import { PackagePricing } from '../../../common/components/PackagePricing';
import { updatePlanAction } from '../../plansSlice';
import { CompatibleAddons } from './compatibleAddons/CompatibleAddons';
import { PackageEditTopBar } from '../../../common/components/packageEditTopBar/PackageEditTopBar';
import { EntityMetadataCard } from '../../../../../components/entityMetadata/EntityMetadataCard';
import { updateEntitlementsOrderAction } from '../../../../entitlements/entitlementsSlice';
import { CompatibleAddonsNotSupported } from './compatibleAddons/CompatibleAddonsNotSupported';
import { usePriceWizard } from '../../../pricing/hooks/usePriceWizard';
import { ArchivePlanModal } from '../ArchivePlanModal';

type PlanOverviewProps = {
  plan: PlanFragment;
  silentReloadPlanData: () => Promise<void>;
  onDiscardChanges: () => void;
};

export default function PlanOverview({ plan, silentReloadPlanData, onDiscardChanges }: PlanOverviewProps) {
  const dispatch = useAppDispatch();
  const isSilentLoadingPlan = useSelector((root: RootState) => root.plansReducer.isSilentLoadingPlan);
  const isUpdatingPlan = useSelector((root: RootState) => root.plansReducer.isUpdatingPlan);
  const [isReorderingEntitlements, setIsReorderingEntitlements] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [hasPublishValidationError, setHasPublishValidationError] = useState(false);
  const { setNavbarControls } = useToggleNavbarControls();

  const { PriceWizard, openSetPriceWizard } = usePriceWizard(setHasPublishValidationError, plan);

  useEffect(() => {
    setNavbarControls({
      editDeleteControls: {
        aPackage: plan,
        title: 'plan',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  const onEditDetails = () => {
    setEditDialogOpen(true);
  };

  const onUpdate = async (updatePayload: EditPackageFormFields) => {
    await dispatch(
      updatePlanAction({
        planRefId: plan.refId,
        updatePayload: omit({ ...updatePayload, id: plan.id }, 'refId', 'productId', 'trialEnabled'),
      }),
    );
    setEditDialogOpen(false);
  };
  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    await dispatch(
      updatePlanAction({
        planRefId: plan.refId,
        updatePayload: { id: plan.id, additionalMetaData: metadata },
      }),
    );
  };

  const handleEntitlementsReorder = async (entitlements: UpdatePackageEntitlementOrderInput['entitlements']) => {
    setIsReorderingEntitlements(true);
    await dispatch(updateEntitlementsOrderAction({ packageId: plan.id, entitlements }));
    await silentReloadPlanData();
    setIsReorderingEntitlements(false);
  };
  const readonly = plan.status !== PackageStatus.Draft;
  const isAwsProduct = plan.product?.awsMarketplaceProductId;

  return (
    <GridFlex.Column container flexWrap="nowrap" gap={6}>
      <PackageEditTopBar
        aPackage={plan}
        isLoading={isSilentLoadingPlan || isReorderingEntitlements}
        setHasPublishValidationError={setHasPublishValidationError}
        onDiscardChanges={onDiscardChanges}
      />
      <PackageDetails aPackage={plan} onEditDetails={onEditDetails} readonly={readonly} />
      <PackagePricing
        aPackage={plan}
        readonly={readonly}
        hasPublishValidationError={hasPublishValidationError}
        openSetPriceWizard={openSetPriceWizard}
      />
      <PackageGrantedEntitlements
        withReordering
        readonly={readonly}
        aPackage={plan}
        basePlan={plan.basePlan || null}
        inheritedEntitlements={plan.inheritedEntitlements || []}
        onEntitlementsReorder={handleEntitlementsReorder}
        onEntitlementsChanged={silentReloadPlanData}
        openSetPriceWizard={openSetPriceWizard}
      />
      {isAwsProduct ? (
        <CompatibleAddonsNotSupported notSupportedText={t('plans.awsMarketplace.addonsNotSupported')} />
      ) : (
        <CompatibleAddons plan={plan} readonly={readonly} />
      )}
      <EntityMetadataCard
        metaData={plan.additionalMetaData}
        onSubmit={onSubmitSetMetaData}
        isReadonly={readonly}
        entityTypeName="plan"
      />
      <CustomDrawer
        title={t('plans.editPlanDialogTitle')}
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}>
        <EditPackageForm
          packageType="Plan"
          aPackage={plan}
          onCancel={() => setEditDialogOpen(false)}
          onUpdate={onUpdate}
          isUpdateInProgress={isUpdatingPlan}
        />
      </CustomDrawer>
      <PriceWizard />
      <ArchivePlanModal />
    </GridFlex.Column>
  );
}
