import { GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { ColorPickerInput } from './ColorPickerInput';
import { CustomerPortalThemeConfiguration } from '../../configurations/customerPortal';

export function CustomerPortalColorsTheme({ widgetConfig }: { widgetConfig: WidgetConfig }) {
  const { isLoading, configuration, onThemeChanged } = widgetConfig;
  const customerPortalConfiguration = configuration as CustomerPortalThemeConfiguration;
  const { palette } = customerPortalConfiguration;
  return (
    <GridFlex.Column gap={4}>
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.primaryColor')}
        initialColor={palette.primary}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { primary: color } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.textColor')}
        initialColor={palette.text.primary}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { text: { primary: color } } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.backgroundColor')}
        initialColor={palette.customerPortalBackground}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { customerPortalBackground: color } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.borderColor')}
        initialColor={palette.outlinedBorder}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { outlinedBorder: color } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.iconColor')}
        initialColor={palette.iconsColor}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { iconsColor: color } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.paywallBackgroundColor')}
        initialColor={palette.backgroundPaper}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { backgroundPaper: color } });
        }}
      />
      <ColorPickerInput
        isLoading={isLoading}
        label={t('widgets.currentPlanBackground')}
        initialColor={palette.backgroundHighlight}
        onColorChanged={(color) => {
          onThemeChanged({ palette: { backgroundHighlight: color } });
        }}
      />
    </GridFlex.Column>
  );
}
