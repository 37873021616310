import { Divider, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { WidgetIdentifier } from '../../useWidgetsData';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { ColorsThemeSection } from './ColorsThemeSection';
import { InfoBox } from './InfoBox';
import { TypographyThemeSection } from './TypographyThemeSection';
import { LayoutThemeSection } from './LayoutThemeSection';
import { CustomCssEditor } from './CustomCssEditor';
import { CustomCSSAlertBox } from './CustomCSSAlertBox';

export function WidgetStyle({
  widgetIdentifier,
  widgetConfig,
}: {
  widgetIdentifier: WidgetIdentifier;
  widgetConfig: WidgetConfig;
}) {
  return (
    <GridFlex.Column gap={4}>
      {widgetConfig.configuration.css ? (
        <CustomCSSAlertBox />
      ) : (
        <InfoBox text={t('widgets.updateStyleWithCss')} externalUrl={t('widgets.cssClassNamesUrlDocs')} />
      )}

      <ColorsThemeSection widgetConfig={widgetConfig} widgetIdentifier={widgetIdentifier} />
      <Divider />
      <TypographyThemeSection widgetConfig={widgetConfig} widgetIdentifier={widgetIdentifier} />
      {widgetIdentifier === WidgetIdentifier.Paywall && <Divider />}
      <LayoutThemeSection widgetIdentifier={widgetIdentifier} widgetConfig={widgetConfig} />
      <Divider />
      <CustomCssEditor widgetConfig={widgetConfig} widgetIdentifier={widgetIdentifier} />
    </GridFlex.Column>
  );
}
