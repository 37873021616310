import { gql } from '@apollo/client';
import { FEATURE_FRAGMENT } from '../../features/queries/featureFragment';

export const PREVIEW_NEXT_INVOICE_FRAGMENT = gql`
  fragment PreviewNextInvoiceFragment on SubscriptionInvoicePreview {
    amountDue {
      amount
      currency
    }
    total {
      amount
      currency
    }
    totalExcludingTax {
      amount
      currency
    }
    minimumSpendAdjustment {
      amount
      currency
    }
    subTotal {
      amount
      currency
    }
    subTotalExcludingTax {
      amount
      currency
    }
    tax {
      amount
      currency
    }
    taxDetails {
      displayName
      percentage
      inclusive
    }
    discount {
      amount
      currency
    }
    discountDetails {
      name
      type
      value
      durationType
      durationInMonths
    }
    credits {
      initial {
        amount
        currency
      }
      used {
        amount
        currency
      }
      remaining {
        amount
        currency
      }
    }
    lastUpdatedAt
    lines {
      type
      description
      costDescription
      quantity
      amount {
        amount
        currency
      }
      unitPrice {
        amount
        currency
      }
      proration
      usageLimit
      hasSoftLimit
      period {
        start
        end
      }
      lines {
        type
        description
        costDescription
        quantity
        amount {
          amount
          currency
        }
        unitPrice {
          amount
          currency
        }
      }
      price {
        id
        billingPeriod
        billingModel
        billingCadence
        tiersMode
        tiers {
          upTo
          unitPrice {
            amount
            currency
          }
          flatPrice {
            amount
            currency
          }
        }
        billingId
        price {
          amount
          currency
        }
        blockSize
        billingCountryCode
        minUnitQuantity
        maxUnitQuantity
        feature {
          ...FeatureFragment
        }
      }
    }
  }

  ${FEATURE_FRAGMENT}
`;
