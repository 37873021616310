import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import {
  SubscriptionMaximumSpendFragment,
  SubscriptionMaximumSpendMutation,
  SubscriptionMaximumSpendMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { PREVIEW_SUBSCRIPTION_MAX_SPEND_FRAGMENT } from './previewSubscriptionMaximumSpendFragment';

const PREVIEW_SUBSCRIPTION_MAX_SPEND = gql`
  mutation SubscriptionMaximumSpend($input: PreviewNextInvoiceInput!) {
    subscriptionMaximumSpend(input: $input) {
      ...SubscriptionMaximumSpendFragment
    }
  }

  ${PREVIEW_SUBSCRIPTION_MAX_SPEND_FRAGMENT}
`;

export type PreviewSubscriptionMaximumSpendProps = {
  subscriptionId: string;
};

async function previewSubscriptionMaximumSpend(
  { subscriptionId }: PreviewSubscriptionMaximumSpendProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<SubscriptionMaximumSpendFragment | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      const res = await apolloClient.mutate<
        SubscriptionMaximumSpendMutation,
        SubscriptionMaximumSpendMutationVariables
      >({
        mutation: PREVIEW_SUBSCRIPTION_MAX_SPEND,
        variables: {
          input: {
            subscriptionId,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return res.data?.subscriptionMaximumSpend;
    },
    {
      hideErrorMessages: true,
    },
    dispatch,
  );
}

export { previewSubscriptionMaximumSpend };
