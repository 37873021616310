import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FeatureType, MeterType } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchFeaturesAction } from '../featuresSlice';

type UseLoadFeaturesProps = {
  featureTypes?: FeatureType[];
  meterTypes?: MeterType[];
  search?: string;
};

export function useLoadFeatures({ featureTypes, meterTypes, search }: UseLoadFeaturesProps = {}) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isLoading = useSelector((state: RootState) => state.featuresReducer.isLoading);
  const { edges, totalCount } = useSelector((state: RootState) => state.featuresReducer.features);
  const features = edges?.map((feature) => feature.node) || [];

  useEffect(() => {
    void dispatch(
      fetchFeaturesAction({
        environmentId: currentEnvironmentId,
        featureTypes,
        meterTypes,
        search: search?.trim(),
        paging: { first: 150 },
      }),
    );
  }, [dispatch, currentEnvironmentId, search, featureTypes, meterTypes]);

  return { features, isLoading, totalCount };
}
