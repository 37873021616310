import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  RemoveCompatibleAddonsFromPlanMutation,
  RemoveCompatibleAddonsFromPlanMutationVariables,
  Plan,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch } from '../../../../redux/store';
import { fetchPlanByRefIdAction } from '../plansSlice';

const REMOVE_COMPATIBLE_ADDONS = gql`
  mutation RemoveCompatibleAddonsFromPlan($input: RemoveCompatibleAddonsFromPlanInput!) {
    removeCompatibleAddonsFromPlan(input: $input) {
      id
    }
  }
`;

async function removeCompatibleAddonsFromPlan(
  {
    planRefId,
    planId,
    addonIds,
    versionNumber,
  }: { planRefId: string; planId: string; addonIds: string[]; versionNumber: number },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Plan> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        RemoveCompatibleAddonsFromPlanMutation,
        RemoveCompatibleAddonsFromPlanMutationVariables
      >({
        mutation: REMOVE_COMPATIBLE_ADDONS,
        variables: { input: { id: planId, relationIds: addonIds } },
      });

      await dispatch(fetchPlanByRefIdAction({ refId: planRefId, versionNumber, silentFetch: true }));
      return response.data?.removeCompatibleAddonsFromPlan;
    },
    {
      failureMessageHandler: () => t('plans.api.failRemovedCompatibleAddon'),
    },
    dispatch,
  );
}

export { removeCompatibleAddonsFromPlan };
