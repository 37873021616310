import { gql } from '@apollo/client';
import { t } from 'i18next';
import { TestHookInput, TestHookQuery, TestHookQueryVariables, TestHookResultFragment } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const TEST_HOOK_EVENT = gql`
  query TestHook($testHookInput: TestHookInput!) {
    sendTestHook(testHookInput: $testHookInput) {
      ...TestHookResultFragment
    }
  }
  fragment TestHookResultFragment on TestHookResult {
    responseStatusCode
    responseStatusText
    responseSuccess
  }
`;

async function sendTestHookEvent(
  testHookInput: TestHookInput,
  { dispatch }: { dispatch: AppDispatch },
): Promise<TestHookResultFragment> {
  return executeOperationSafely(
    async () => {
      const res = await apolloClient.query<TestHookQuery, TestHookQueryVariables>({
        query: TEST_HOOK_EVENT,
        fetchPolicy: 'network-only',
        variables: {
          testHookInput,
        },
      });
      return res.data.sendTestHook;
    },
    {
      failureMessageHandler: () => t('hooks.api.failTestEventHook'),
    },
    dispatch,
  );
}

export { sendTestHookEvent };
