import { CSSProperties } from 'react';
import { styled, useStiggTheme } from '@stigg-theme';
import Lottie, { Options } from 'react-lottie';
import { Box, CircularProgress, CircularProgressProps, Text } from '.';
import lightAnimationData from '../assets/animations/loader-animation.light.json';
import darkAnimationData from '../assets/animations/loader-animation.dark.json';
import { useHideIntercom } from './StiggIntercom/useHideIntercom';

const Root = styled(Box)<{ $isInline?: boolean }>`
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.$isInline ? 'inline-block' : 'flex')};
  min-height: 100%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
`;

function Loader({
  size,
  text,
  isInline,
  cpStyle,
  useAnimationLoader = false,
  textPosition = 'left',
  textMargin = 4,
}: {
  size?: CircularProgressProps['size'];
  text?: string;
  isInline?: boolean;
  cpStyle?: CSSProperties;
  useAnimationLoader?: boolean;
  textPosition?: 'left' | 'right';
  textMargin?: number;
}) {
  useHideIntercom(true);
  const { theme } = useStiggTheme();
  const animationData = theme.isLightTheme ? lightAnimationData : darkAnimationData;
  if (useAnimationLoader) {
    const defaultOptions: Options = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <Root $isInline={isInline}>
        <Lottie options={defaultOptions} isClickToPauseDisabled height={150} width={159} />
      </Root>
    );
  }

  const textElement = text && (
    <Text.B2 color="inherit" {...(textPosition === 'left' ? { mr: textMargin } : { ml: textMargin })}>
      {text}
    </Text.B2>
  );

  return (
    <Root $isInline={isInline}>
      {textPosition === 'left' && textElement}
      <StyledCircularProgress size={size} color="primary" style={cpStyle} />
      {textPosition === 'right' && textElement}
    </Root>
  );
}

export default Loader;
