import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { PaymentCollection } from '@stigg-types/apiTypes';
import { watchSubscriptionSyncStates } from '../../queries/watchSubscriptionSyncStates';
import { AppDispatch, RootState, useAppDispatch } from '../../../../redux/store';
import { fetchSubscriptionByRefIdAction, updateSubscriptionSyncState } from '../../customersSlice';

const DEFAULT_POLL_INTERVAL = 2000;

function startPolling({
  subscriptionId,
  subscriptionRefId,
  lastPaymentCollection,
  dispatch,
  pollInterval,
}: {
  subscriptionId: string;
  subscriptionRefId: string;
  lastPaymentCollection: PaymentCollection;
  dispatch: AppDispatch;
  pollInterval: number;
}) {
  const observableQuery = watchSubscriptionSyncStates(subscriptionId, pollInterval);

  observableQuery.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.customerSubscriptions) {
        Sentry.captureException('got unexpected response for polling subscription sync states', { extra: { value } });
      }

      const subscriptions = value.data.customerSubscriptions.edges;
      const fetchedSubscription = subscriptions.length > 0 ? subscriptions[0].node : null;
      if (fetchedSubscription) {
        if (fetchedSubscription.paymentCollection !== lastPaymentCollection) {
          void dispatch(fetchSubscriptionByRefIdAction({ refId: subscriptionRefId, isSilentLoading: true }));
        } else {
          dispatch(updateSubscriptionSyncState(fetchedSubscription));
        }
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling subscription sync states' } });
    },
  );

  return observableQuery;
}

export function usePollSubscriptionSyncStates(
  subscriptionId: string,
  subscriptionRefId: string,
  lastPaymentCollection: PaymentCollection,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);

  useEffect(() => {
    const observableQuery = startPolling({
      subscriptionId,
      subscriptionRefId,
      lastPaymentCollection,
      dispatch,
      pollInterval,
    });
    return () => observableQuery?.stopPolling();
  }, [subscriptionId, subscriptionRefId, lastPaymentCollection, currentEnvironmentId, pollInterval, dispatch]);
}
