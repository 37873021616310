import { escapeRegExp } from 'lodash';
import { CSSProperties, useMemo } from 'react';
import compact from 'lodash/compact';

const highlightStyle: CSSProperties = { fontWeight: 'bold' };

export type HighlightTextProps = {
  text?: string;
  highlight?: string;
};

export function HighlightText({ text, highlight }: HighlightTextProps) {
  const parts = useMemo(() => {
    if (!text) {
      return [];
    }
    if (!highlight || !highlight.trim()) {
      return [text];
    }

    const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
    return compact(text.split(regex)).map((part, index) => {
      return regex.test(part) ? (
        <span key={index} style={highlightStyle}>
          {part}
        </span>
      ) : (
        <span>{part}</span>
      );
    });
  }, [text, highlight]);

  return <span>{parts}</span>;
}
