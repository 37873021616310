import React from 'react';
import { SidebarItemsType } from '../../../types/sidebar';
import SidebarNavList from './SidebarNavList';

type SidebarNavSectionProps = {
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
  isSidenavOpen: boolean;
};

const SidebarNavSection: React.FC<SidebarNavSectionProps> = (props) => {
  const { pages, component: Component = 'nav', isSidenavOpen, ...rest } = props;

  return (
    <Component {...rest}>
      <SidebarNavList pages={pages} depth={0} isSidenavOpen={isSidenavOpen} />
    </Component>
  );
};

export default SidebarNavSection;
