import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateOneSubscriptionMutation,
  UpdateOneSubscriptionMutationVariables,
  UpdateSubscriptionInput,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { navigateTo } from '../../navigation/actions';
import { appRoutes } from '../../navigation/useNavigation';

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateOneSubscription($input: UpdateSubscriptionInput!) {
    updateOneSubscription(input: $input) {
      subscriptionId
      additionalMetaData
    }
  }
`;

type UpdateSubscriptionData = Omit<UpdateSubscriptionInput, 'environmentId' | 'refId'>;

async function updateSubscription(
  updateSubscriptionData: UpdateSubscriptionData,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const subscriptionData: UpdateSubscriptionInput = {
        ...updateSubscriptionData,
        environmentId: accountReducer.currentEnvironmentId,
        awaitPaymentConfirmation: false,
      };
      const res = await apolloClient.mutate<UpdateOneSubscriptionMutation, UpdateOneSubscriptionMutationVariables>({
        mutation: UPDATE_SUBSCRIPTION,
        variables: { input: subscriptionData },
      });

      const updatedSubscription = res.data?.updateOneSubscription;

      if (updatedSubscription) {
        dispatch(navigateTo(appRoutes.subscriptionPage(updatedSubscription.subscriptionId)));
      }

      return updatedSubscription?.additionalMetaData;
    },
    {
      successMessage: t('subscriptions.api.successUpdate'),
      failureMessageHandler: () => t('subscriptions.api.updateFailed'),
      expectedErrorsMessage: {
        SubscriptionInvoiceStatusError: t('subscriptions.api.subscriptionInvoiceStatusError'),
      },
    },
    dispatch,
  );
}

export { updateSubscription };
