import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';
import { refIdValidation, StatusChip } from '@stigg-common';
import { Text, drawFormFields, FormRenderProps, GridFlex, InformationTooltip, Icon } from '@stigg-components';
import { AwsDimension, AwsProduct, DimensionsMappingInput } from '@stigg-types/apiTypes';
import Table, { HeadCell } from '../../../../components/table/Table';
import { useAppDispatch } from '../../../../redux/store';
import { fetchAWSProductDimensionsAction } from '../../integrationsSlice';
import { generateRefIdFromName } from '../../../../components/DisplayNameTextField';

type ConfigureAWSProductPlansStepProps = {
  awsProduct: AwsProduct;
  awsProductDimensions: AwsDimension[];
  isLoadingAWSProductDimensions: boolean;
  onChange: (dimensionsConfiguration: DimensionsMappingInput[]) => void;
};

type DimensionsMappingFormState = DimensionsMappingInput & {
  isSynced: boolean;
};

const validationSchema = () =>
  Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required(t('integrations.awsMarketplace.import.dimensionsConfiguration.yup.required')),
      planName: Yup.string().required(t('integrations.awsMarketplace.import.dimensionsConfiguration.yup.required')),
      planRefId: refIdValidation(),
    }),
  );

export const headCells = ({
  formRenderProps,
  awsProduct,
}: {
  formRenderProps: FormRenderProps<DimensionsMappingFormState[]>;
  awsProduct: AwsProduct;
}): Array<HeadCell<DimensionsMappingFormState, any>> => [
  {
    id: 'dimensionName',
    alignment: 'left',
    label: t('integrations.awsMarketplace.import.dimensionsConfiguration.dimensionName'),
    render: (awsDimension: DimensionsMappingFormState) => {
      return (
        <GridFlex.RowSpaceBetween>
          <Text.B2 className="fs-mask" color="primary">
            {awsDimension.key}
          </Text.B2>
          {awsDimension.isSynced ? (
            <InformationTooltip
              style={{ cursor: 'pointer' }}
              arrow
              placement="top"
              $padding={2}
              $maxWidth={300}
              title={<Text.B2 color="primary">{t('integrations.awsMarketplace.import.syncedTooltip')}</Text.B2>}>
              <StatusChip
                variant="outlined"
                color="primary.outlinedRestingBackground"
                textColor="primary.main"
                style={{ padding: 0 }}
                label={<Icon style={{ display: 'flex' }} icon="Synchronize" size={18} />}
              />
            </InformationTooltip>
          ) : null}
        </GridFlex.RowSpaceBetween>
      );
    },
  },
  {
    id: 'stiggPlanName',
    alignment: 'left',
    label: t('integrations.awsMarketplace.import.dimensionsConfiguration.stiggPlanName'),
    render: (_, index) => (
      <>
        {drawFormFields(
          ({ values }) => [
            {
              id: `[${index}].planName`,
              type: 'text',
              textFieldType: 'text',
              hideErrorText: true,
              disabled: !!values[index]?.isSynced,
              handleChange: ({ touched, handleChange, setFieldValue }) => {
                return (e: any) => {
                  if (!touched[index]?.planRefId) {
                    const newRefId = generateRefIdFromName(e?.target?.value, t('plans.refIdPrefix'), awsProduct.title);
                    setFieldValue(`[${index}].planRefId`, newRefId);
                  }

                  handleChange(e);
                };
              },
            },
          ],
          formRenderProps,
        )}
      </>
    ),
  },
  {
    id: 'stiggPlanId',
    alignment: 'left',
    label: t('integrations.awsMarketplace.import.dimensionsConfiguration.stiggPlanId'),
    render: (_, index) => {
      return (
        <>
          {drawFormFields(
            ({ values }) => [
              {
                id: `[${index}].planRefId`,
                type: 'text',
                textFieldType: 'text',
                hideErrorText: true,
                disabled: !!values[index]?.isSynced,
              },
            ],
            formRenderProps,
          )}
        </>
      );
    },
  },
];

export function ConfigureAWSProductPlansStep({
  awsProduct,
  awsProductDimensions,
  isLoadingAWSProductDimensions,
  onChange,
}: ConfigureAWSProductPlansStepProps) {
  const dispatch = useAppDispatch();

  const [initialValues, setInitialValues] = useState<DimensionsMappingFormState[]>([]);

  useEffect(() => {
    void dispatch(fetchAWSProductDimensionsAction({ productId: awsProduct.productId }));
  }, [dispatch, awsProduct.productId]);

  useEffect(() => {
    if (awsProductDimensions) {
      setInitialValues(
        awsProductDimensions.map<DimensionsMappingFormState>((awsDimension) => {
          const planName = awsDimension.stiggPlanName || awsDimension.name;

          return {
            key: awsDimension.key,
            planName,
            planRefId:
              awsDimension.stiggPlanId || generateRefIdFromName(planName, t('plans.refIdPrefix'), awsProduct.title),
            isSynced: !!awsDimension.stiggPlanId,
          };
        }),
      );
    }
  }, [awsProduct.title, awsProductDimensions]);

  const {
    values,
    touched,
    errors,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setValues,
    setTouched,
  } = useFormik<DimensionsMappingFormState[]>({
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const formRenderProps: FormRenderProps<DimensionsMappingFormState[]> = {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setValues,
    setTouched,
  };

  useEffect(() => {
    onChange(isValid ? values.map<DimensionsMappingInput>(({ isSynced: _, ...fields }) => ({ ...fields })) : []);
  }, [onChange, values, isValid]);

  return (
    <>
      <Text.H3 mb={2}>{t('integrations.awsMarketplace.import.dimensionsConfiguration.title')}</Text.H3>
      <Text.B1 mb={8} color="secondary">
        {t('integrations.awsMarketplace.import.dimensionsConfiguration.description', { productName: awsProduct.title })}
      </Text.B1>

      <Table
        isLoading={isLoadingAWSProductDimensions}
        headCells={headCells({ formRenderProps, awsProduct })}
        headerRowColor="#F2F4F8"
        data={values}
        maxHeight={500}
        stickyHeader
        rowHeight={85}
      />
    </>
  );
}
