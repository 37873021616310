import { Box, GridFlex, Text } from '@stigg-components';
import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { TooltipModel } from 'chart.js';
import isEmpty from 'lodash/isEmpty';
import {
  ChartJsExternalTooltip,
  ExternalTooltipInfo,
  ExternalTooltipTranslateFunc,
  useChartJsExternalTooltip,
} from './chartjs.tooltip.plugin';
import { numberFullFormatter } from '../formatters';

export type DatasetTooltipData = {
  label: string;
  value: number;
  color: string;
};

export type DefaultChartTooltipData = {
  label: string;
  datasets: DatasetTooltipData[];
};

export type DefaultChartTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<DefaultChartTooltipData>;
};

export const SideColorMarker = styled(Box)`
  width: 3px;
  margin-right: 15px;
`;

export const RoundedSideColorMarker = styled(SideColorMarker)`
  border-radius: 4px;
  width: 5px;
`;

export function DefaultChartTooltip({ tooltipInfo }: DefaultChartTooltipProps) {
  if (!tooltipInfo.payload || isEmpty(tooltipInfo.payload.datasets)) {
    return <></>;
  }

  const { label, datasets } = tooltipInfo.payload;

  // TODO: when we want to support more than 1 series, need to refactor this
  const dataset = datasets[0];

  return (
    <ChartJsExternalTooltip tooltipInfo={tooltipInfo}>
      <GridFlex.Row>
        <RoundedSideColorMarker style={{ backgroundColor: dataset.color }} />
        <GridFlex.ColumnCenter>
          <GridFlex.Row>
            <Text.B2 color="primary" mr={1}>
              {label}
            </Text.B2>
          </GridFlex.Row>
          <GridFlex.Row mt={1}>
            <Text.B2 color="secondary">
              {dataset.label} - {numberFullFormatter(dataset.value)}
            </Text.B2>
          </GridFlex.Row>
        </GridFlex.ColumnCenter>
      </GridFlex.Row>
    </ChartJsExternalTooltip>
  );
}

export function useDefaultChartTooltip() {
  const translate = useCallback<ExternalTooltipTranslateFunc<'line', DefaultChartTooltipData>>(
    (tooltip: TooltipModel<'line'>) => {
      if (isEmpty(tooltip.dataPoints) || isEmpty(tooltip.labelColors)) {
        return undefined;
      }

      const label = tooltip.title[0];
      const dataset: DatasetTooltipData[] = tooltip.dataPoints.map((value, index) => {
        return {
          value: value.parsed.y,
          label: value.dataset.label || '',
          color: tooltip.labelColors[index].borderColor as string,
        };
      });

      return {
        label,
        datasets: dataset,
      };
    },
    [],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsExternalTooltip<'line', DefaultChartTooltipData>(translate);

  return { tooltipInfo, tooltipHandler };
}

export function useDoughnutChartTooltip(label: string) {
  const translate = useCallback<ExternalTooltipTranslateFunc<'doughnut', DefaultChartTooltipData>>(
    (tooltip: TooltipModel<'doughnut'>) => {
      if (isEmpty(tooltip.dataPoints) || isEmpty(tooltip.labelColors)) {
        return undefined;
      }

      const dataset: DatasetTooltipData[] = tooltip.dataPoints.map((value, index) => {
        return {
          value: value.parsed,
          label: value.label,
          color: tooltip.labelColors[index].borderColor as string,
        };
      });

      return {
        label,
        datasets: dataset,
      };
    },
    [label],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsExternalTooltip<'doughnut', DefaultChartTooltipData>(translate);

  return { tooltipInfo, tooltipHandler };
}
