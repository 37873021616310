import { ExperimentFragment, ExperimentStatus } from '@stigg-types/apiTypes';
import {
  Box,
  Button,
  ButtonAndOptionsDropdown,
  GridFlex,
  Icon,
  InformationTooltip,
  OptionsDropdown,
  Text,
} from '@stigg-components';
import React, { useState } from 'react';
import { t } from 'i18next';
import { useConfirmationDialog } from '@stigg-common';
import { StartExperimentDialog } from './StartExperimentDialog';
import { useFindActiveExperiment } from '../../useFindActiveExperiment';
import { ExperimentWizard } from '../../../editExperimentWizard/ExperimentWizard';
import { useAppDispatch } from '../../../../../../redux/store';
import { stopExperimentAction } from '../../../../experimentsSlice';
import { Dialog } from '../../../../../../components/Dialog';

function ActiveExperimentOperations({ experiment }: { experiment: ExperimentFragment }) {
  const dispatch = useAppDispatch();

  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);
  const [EndExperimentDialog, setEndExperimentDialogOpen, endExperimentDialogProps] = useConfirmationDialog({
    title: t('experiments.endExperiment.confirmationDialog.header'),
    content: (
      <>
        <Text.B2 color="primary">{t('experiments.endExperiment.confirmationDialog.confirm')}</Text.B2>
        <Text.B2 color="primary">{t('experiments.endExperiment.confirmationDialog.note')}</Text.B2>
      </>
    ),
    handleConfirm: async () => {
      await dispatch(stopExperimentAction({ refId: experiment.refId }));
    },
    confirmButtonText: t('experiments.endExperiment.action'),
    confirmButtonColor: 'error',
  });

  return (
    <GridFlex.Row>
      <ButtonAndOptionsDropdown
        color="error"
        button={{
          text: t('experiments.endExperiment.action'),
          onClick: () => setEndExperimentDialogOpen(true),
        }}
        options={[
          {
            icon: (() => <Icon icon="Copy" size={16} color="secondary" />) as React.FC,
            text: t('sharedComponents.duplicate'),
            onClick: () => setCloneDialogIsOpen(true),
          },
        ]}
      />
      <EndExperimentDialog {...endExperimentDialogProps} />
      <Dialog
        fullScreen
        isOpen={cloneDialogIsOpen}
        onCancel={() => setCloneDialogIsOpen(false)}
        content={<ExperimentWizard experiment={experiment} closeWizard={() => setCloneDialogIsOpen(false)} />}
        padding={0}
      />
    </GridFlex.Row>
  );
}

function StartExperimentButton({ experiment }: { experiment: ExperimentFragment }) {
  const activeExperiment = useFindActiveExperiment(experiment.product?.refId);
  const [startExperimentConfirmationDialogOpen, setStartExperimentConfirmationDialogOpen] = useState(false);

  let disableTooltip: React.ReactNode = '';

  // Each product can have at most 1 active experiment
  if (activeExperiment) {
    disableTooltip = <Text.B2>{t('experiments.startExperiment.anotherExperimentOnTheSameProduct')}</Text.B2>;
  }

  return (
    <>
      <InformationTooltip arrow placement="bottom" title={disableTooltip}>
        <Box>
          <Button
            variant="contained"
            color="startOperation"
            disabled={Boolean(disableTooltip)}
            onClick={() => setStartExperimentConfirmationDialogOpen(true)}>
            <GridFlex.RowCenter columnGap={2}>
              <Icon icon="Play" />
              {t('experiments.startExperiment.action')}
            </GridFlex.RowCenter>
          </Button>
        </Box>
      </InformationTooltip>
      <StartExperimentDialog
        open={startExperimentConfirmationDialogOpen}
        setDialogOpen={setStartExperimentConfirmationDialogOpen}
        experiment={experiment}
      />
    </>
  );
}

function EditExperimentButton({ experiment }: { experiment: ExperimentFragment }) {
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  return (
    <Button color="primary" $outlined onClick={() => setEditDialogIsOpen(true)}>
      <GridFlex.RowCenter columnGap={2}>
        <Icon icon="Edit2" type="reactFeather" />
        {t('sharedComponents.edit')}
      </GridFlex.RowCenter>
      <Dialog
        fullScreen
        isOpen={editDialogIsOpen}
        onCancel={() => setEditDialogIsOpen(false)}
        content={<ExperimentWizard experiment={experiment} editMode closeWizard={() => setEditDialogIsOpen(false)} />}
        padding={0}
      />
    </Button>
  );
}

function EndedExperimentOperations({ experiment }: { experiment: ExperimentFragment }) {
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);
  return (
    <GridFlex.Row>
      <OptionsDropdown
        options={[
          {
            icon: (() => <Icon icon="Copy" size={20} />) as React.FC,
            text: t('sharedComponents.duplicate'),
            onClick: () => setCloneDialogIsOpen(true),
          },
        ]}
      />
      <Dialog
        fullScreen
        isOpen={cloneDialogIsOpen}
        onCancel={() => setCloneDialogIsOpen(false)}
        content={<ExperimentWizard experiment={experiment} closeWizard={() => setCloneDialogIsOpen(false)} />}
        padding={0}
      />
    </GridFlex.Row>
  );
}

function DraftExperimentOperations({ experiment }: { experiment: ExperimentFragment }) {
  return (
    <GridFlex.Row columnGap={2}>
      <EditExperimentButton experiment={experiment} />
      <StartExperimentButton experiment={experiment} />
    </GridFlex.Row>
  );
}

export function ExperimentsOperationBar({ experiment }: { experiment: ExperimentFragment }) {
  switch (experiment.status) {
    case ExperimentStatus.Draft:
      return <DraftExperimentOperations experiment={experiment} />;
    case ExperimentStatus.InProgress:
      return <ActiveExperimentOperations experiment={experiment} />;
    case ExperimentStatus.Completed:
      return <EndedExperimentOperations experiment={experiment} />;
    default:
      return null;
  }
}
