import { useStiggContext } from '@stigg/react-sdk';

export function useOptionalStiggContext() {
  try {
    // TODO: export a hook for this in @stigg/react-sdk
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { stigg } = useStiggContext();
    return stigg;
  } catch (e) {
    return undefined;
  }
}
