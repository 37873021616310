import { BarElement, Chart, ChartEvent, ChartType, TooltipModel } from 'chart.js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, InformationTooltip } from '@stigg-components';
import { styled } from '@stigg-theme';
import { EventContext } from 'chartjs-plugin-annotation';
import debounce from 'lodash/debounce';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

const CLOSE_DELAY = 300;

export type ExternalTooltipHandler<TType extends ChartType> = (context: {
  chart: Chart;
  tooltip: TooltipModel<TType>;
}) => void;

export type AnnotationTooltipHandler = (isMouseOver: boolean, context: EventContext, event: ChartEvent) => void;

export type ExternalTooltipInfo<TPayload> = {
  positionTop: number;
  positionLeft: number;
  isOpen: boolean;
  payload?: TPayload;
};

type ChartJsExternalTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<unknown>;
  children: React.ReactElement<any, any>;
  $padding?: number;
  placement?: TooltipProps['placement'];
};

export type ExternalTooltipTranslateFunc<TType extends ChartType, TPayload> = (
  tooltip: TooltipModel<TType>,
) => TPayload | undefined;
export type AnnotationTooltipTranslateFunc<TPayload> = (
  context: EventContext,
  event: ChartEvent,
) => TPayload | undefined;

type TooltipPosition = { top: number; left: number };
type ChartJsExternalTooltipPositioner<TType extends ChartType> = (
  chart: Chart,
  tooltip: TooltipModel<TType>,
) => TooltipPosition;

export function bottomBarTooltipPositioner(chart: Chart, tooltip: TooltipModel<'bar'>): TooltipPosition {
  const bar = chart.getDatasetMeta(tooltip.dataPoints[0].datasetIndex).data[
    tooltip.dataPoints[0].dataIndex
  ] as unknown as BarElement;

  const top = chart.canvas.offsetLeft + chart.chartArea.left;
  const left = chart.canvas.offsetTop + bar.y;

  return { top, left };
}

function defaultExternalTooltipPosition<TType extends ChartType>(
  chart: Chart,
  tooltip: TooltipModel<TType>,
): TooltipPosition {
  const top = chart.canvas.offsetLeft + tooltip.caretX;
  const left = chart.canvas.offsetTop + tooltip.caretY;

  return { top, left };
}

export function useChartJsExternalTooltip<TType extends ChartType, TPayload>(
  translate: ExternalTooltipTranslateFunc<TType, TPayload>,
  positioner: ChartJsExternalTooltipPositioner<TType> = defaultExternalTooltipPosition,
) {
  const [positionTop, setPositionTop] = useState(0);
  const [positionLeft, setPositionLeft] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState<TPayload>();

  const tooltipHandler: ExternalTooltipHandler<TType> = useCallback(
    (context: { chart: Chart; tooltip: TooltipModel<TType> }) => {
      const { chart, tooltip } = context;

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        setIsOpen(false);
        return;
      }

      const { top, left } = positioner(chart, tooltip);

      setPositionLeft(top);
      setPositionTop(left);
      setPayload(translate(tooltip));
      setIsOpen(true);
    },
    [translate, positioner],
  );

  const tooltipInfo: ExternalTooltipInfo<TPayload> = {
    positionTop,
    positionLeft,
    isOpen,
    payload,
  };

  return {
    tooltipInfo,
    tooltipHandler,
  };
}

export function useChartJsAnnotationExternalTooltip<TPayload>(translate: AnnotationTooltipTranslateFunc<TPayload>) {
  const [positionTop, setPositionTop] = useState(0);
  const [positionLeft, setPositionLeft] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState<TPayload>();

  const tooltipHandler: AnnotationTooltipHandler = useCallback(
    (isMouseOver: boolean, context: EventContext, event: ChartEvent) => {
      const { chart } = context;
      const { x, y } = event;

      if (!isMouseOver || !x || !y) {
        setIsOpen(false);
        return;
      }

      setPositionLeft(chart.canvas.offsetLeft + context.element.x);
      setPositionTop(chart.canvas.offsetTop + context.element.y - context.element.height);
      setPayload(translate(context, event));
      setIsOpen(true);
    },
    [translate],
  );

  const tooltipInfo: ExternalTooltipInfo<TPayload> = {
    positionTop,
    positionLeft,
    isOpen,
    payload,
  };

  return {
    tooltipInfo,
    tooltipHandler,
  };
}

const AnchorBox = styled(Box)`
  position: absolute;
`;

export const ChartJsExternalTooltip = ({
  children,
  tooltipInfo: { positionTop, positionLeft, payload, isOpen },
  placement = 'top',
  $padding,
}: ChartJsExternalTooltipProps) => {
  const [isTooltipHover, setIsTooltipHover] = useState(false);
  const [shouldBeOpened, setShouldBeOpened] = useState(false);

  const closeDebounce = useRef(debounce(() => setShouldBeOpened(false), CLOSE_DELAY));

  useEffect(() => {
    if (Boolean(payload) && (isTooltipHover || isOpen)) {
      closeDebounce.current.cancel();
      setShouldBeOpened(true);
    } else {
      closeDebounce.current();
    }
  }, [payload, isTooltipHover, isOpen]);

  return (
    <InformationTooltip
      placement={placement}
      arrow
      open={shouldBeOpened}
      title={children}
      $maxWidth={500}
      $padding={$padding}
      PopperProps={{
        onMouseEnter: () => setIsTooltipHover(true),
        onMouseLeave: () => setIsTooltipHover(false),
      }}>
      <AnchorBox style={{ top: positionTop, left: positionLeft }} />
    </InformationTooltip>
  );
};
