import { useCallback, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { IntercomProvider } from 'react-use-intercom';
import { StiggIntercomContext } from './StiggIntercomContext';
import config from '../../env.config';
import { IntercomButton } from './useIntercom';

export function StiggIntercomProvider({ children }: { children: React.ReactNode }) {
  const [sectionsHideIntercom, setSectionsHideIntercom] = useState<string[]>([]);

  const setHideIntercom = useCallback(
    (id: string, shouldHideIntercom: boolean) => {
      setSectionsHideIntercom((prev) => {
        if (shouldHideIntercom) {
          return [...prev, id];
        }
        return prev.filter((sectionId) => sectionId !== id);
      });
    },
    [setSectionsHideIntercom],
  );

  const isVisible = isEmpty(sectionsHideIntercom);

  const value = useMemo(() => ({ isVisible, setHideIntercom }), [isVisible, setHideIntercom]);

  return (
    <IntercomProvider appId={config.intercomId} autoBoot>
      <StiggIntercomContext.Provider value={value}>
        <IntercomButton />
        {children}
      </StiggIntercomContext.Provider>
    </IntercomProvider>
  );
}
