import { FormikHandlers, FormikHelpers, FormikState } from 'formik';
import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { EntitlementsTable } from '../../../../../packages/plans/components/EntitlementsTable';
import { EditPromotionalEntitlementFormFields } from './EditPromotionalEntitlementForm';

export type EditPromotionalEntitlementProps = {
  values: FormikState<EditPromotionalEntitlementFormFields>['values'];
  setFieldValue: FormikHelpers<EditPromotionalEntitlementFormFields>['setFieldValue'];
  setFieldTouched: FormikHelpers<EditPromotionalEntitlementFormFields>['setFieldTouched'];
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  touched: FormikState<EditPromotionalEntitlementFormFields>['touched'];
  errors: FormikState<EditPromotionalEntitlementFormFields>['errors'];
};

export function EditPromotionalEntitlement({
  errors,
  touched,
  handleBlur,
  values,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: EditPromotionalEntitlementProps) {
  return (
    <GridFlex.Column $fullWidth>
      <Text.H3 mt={1} mb={3}>
        {t('promotionalEntitlements.editFormTitle')}
      </Text.H3>
      <EntitlementsTable
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        entitlements={values.entitlements}
        handleChange={handleChange}
        height={270}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    </GridFlex.Column>
  );
}
