import { Checkbox, Divider, ExternalLink, GridFlex, Text, HighlightedCard } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import { HelpTooltip } from '../../../../../components/HelpTooltip';
import { CustomerJourneySectionTitle } from '../../../../products/components/productPage/customerJourney/CustomerJourneySectionTitle';

type PublishPackageDialogContentProps = {
  checkboxSetAsDowngradePlan: boolean;
  toggleCheckboxSetAsDowngradePlan: () => void;
};

export function ConfirmFreePlanAsDowngradePlan({
  checkboxSetAsDowngradePlan,
  toggleCheckboxSetAsDowngradePlan,
}: PublishPackageDialogContentProps) {
  return (
    <HighlightedCard p={4}>
      <GridFlex.Column gap={2}>
        <GridFlex.RowCenter gap={1}>
          <CustomerJourneySectionTitle
            icon="DoorFrontOutlined"
            iconSize={24}
            title={t('products.customerJourney.subscriptionEndsOrCanceled')}
            readonly
            lastChild
            minHeight={20}
          />
          <HelpTooltip $maxWidth={250}>
            <GridFlex.Column gap={1}>
              <Text.B2>
                <Trans
                  i18nKey="products.customerJourney.alwaysChangeBehavior"
                  t={t}
                  components={[<Text.B2 $bold display="inline" />]}
                />
              </Text.B2>
              <ExternalLink label={t('sharedComponents.learnMore')} url={t('products.customerJourney.learnMoreUrl')} />
            </GridFlex.Column>
          </HelpTooltip>
        </GridFlex.RowCenter>
        <Divider />
        <Checkbox
          checked={checkboxSetAsDowngradePlan}
          onChange={toggleCheckboxSetAsDowngradePlan}
          label={
            <Text.B2>
              <Trans
                i18nKey="packages.publishPlanSetAsDowngradePlan"
                t={t}
                components={[<Text.B2 $bold display="inline" />]}
              />
            </Text.B2>
          }
        />
      </GridFlex.Column>
    </HighlightedCard>
  );
}
