import { useCallback, useState } from 'react';
import { Grid, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import {
  EntitySelectionMode,
  ImportIntegrationCustomersInput,
  IntegrationFragment,
  TaskStatus,
  VendorIdentifier,
} from '@stigg-types/apiTypes';
import delay from 'lodash/delay';
import { useAppDispatch } from '../../../../../redux/store';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { StripeImportWizardFooter } from '../StripeImportWizardFooter';
import { AnimatedStep, Wizard } from '../Wizard';
import * as Steps from './wizardSteps';
import { triggerIntegrationCustomersImportAction } from '../../../integrationsSlice';
import { useNavigation } from '../../../../navigation/useNavigation';
import { StripeImportTask } from '../StripeImportTask';
import { ImportCustomersSummary } from './wizardSteps/ImportCustomersSummary';
import { StripeImportWizardType } from '../StripeImportWizard';
import { addImportTaskPollingId, setIsTasksProgressTooltipOpen } from '../../../../application/applicationSlice';
import { isTaskInProgress } from '../../../../../components/layout/navbar/components/tasks/taskUtils';

type ImportCustomersWizardMainContentProps = {
  integration: IntegrationFragment;
  activeStep: number;
  nextStep: () => void;
  prevStep: () => void;
  closeImportDialog: () => void;
  prevStepIndex: React.MutableRefObject<number>;
};

export enum ImportCustomersSteps {
  OVERVIEW = 0,
  SELECT_CUSTOMERS = 1,
  REVIEW_AND_PUBLISH = 2,
}

const WIZARD_STEPS = [
  {
    component: () => <Steps.ImportCustomersOverviewStep />,
  },
  {
    component: (integration: IntegrationFragment) => <Steps.SelectCustomersStep integration={integration} />,
  },
  {
    component: () => <Steps.ReviewAndImport />,
  },
];

export function ImportCustomersWizardMainContent({
  integration,
  activeStep,
  nextStep,
  prevStep,
  prevStepIndex,
  closeImportDialog,
}: ImportCustomersWizardMainContentProps) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [importCustomersTask, setImportCustomersTask] = useState<{ taskId: string; taskStatus: TaskStatus } | null>(
    null,
  );
  const importContext = useImportContext();
  const isValid = () => {
    if (activeStep === ImportCustomersSteps.SELECT_CUSTOMERS) {
      return importContext.selectedCustomers.length > 0;
    }
    return true;
  };

  const onImport = useCallback(async () => {
    const {
      customersData: { headerSelectionChecked },
      selectedProduct,
      selectedCustomers,
      unSelectedCustomers,
    } = importContext;
    let customersSelectionParams: Pick<
      ImportIntegrationCustomersInput,
      'entitySelectionMode' | 'customersSelectionBlacklist' | 'customersSelectionWhitelist'
    > | null = null;
    if (headerSelectionChecked) {
      customersSelectionParams = {
        entitySelectionMode: EntitySelectionMode.BlackList,
        customersSelectionBlacklist: unSelectedCustomers.map((product) => product.id),
      };
    } else {
      customersSelectionParams = {
        entitySelectionMode: EntitySelectionMode.WhiteList,
        customersSelectionWhitelist: selectedCustomers.map((customer) => customer.id),
      };
    }
    if (selectedProduct) {
      const importTask = await dispatch(
        triggerIntegrationCustomersImportAction({
          productId: selectedProduct.id,
          vendorIdentifier: VendorIdentifier.Stripe,
          ...customersSelectionParams,
        }),
      ).unwrap();
      if (importTask) {
        setImportCustomersTask({ taskId: importTask.taskId, taskStatus: TaskStatus.Pending });
      }
    }
  }, [dispatch, importContext]);

  const updateTaskStatus = useCallback((taskStatus: TaskStatus) => {
    setImportCustomersTask((prevState) => (prevState ? { ...prevState, taskStatus } : null));
  }, []);

  const onNextClick = () => {
    if (activeStep === ImportCustomersSteps.REVIEW_AND_PUBLISH) {
      if (importCustomersTask) {
        navigation.navigateTo('/customers');
      } else {
        void onImport();
      }
    } else {
      nextStep();
    }
  };

  const getButtonText = () => {
    if (activeStep === ImportCustomersSteps.REVIEW_AND_PUBLISH) {
      if (importCustomersTask) {
        return t('integrations.viewImportedCustomers');
      }
      return t('integrations.importCTA');
    }

    return t('sharedComponents.nextButton');
  };

  const onMinimize = () => {
    dispatch(addImportTaskPollingId(importCustomersTask!.taskId));
    delay(() => {
      void dispatch(setIsTasksProgressTooltipOpen(true));
    }, 200);
    closeImportDialog();
  };

  return (
    <Grid container item sm={8} xl={9} height="100%" justifyContent="center" sx={{ overflow: 'auto', zIndex: 0 }}>
      <GridFlex.Column xl={10} pt="35px" px="100px" height="100%">
        {importCustomersTask ? (
          <StripeImportTask
            taskId={importCustomersTask.taskId}
            setTaskStatus={updateTaskStatus}
            importSummary={<ImportCustomersSummary />}
            importType={StripeImportWizardType.IMPORT_CUSTOMERS_AND_SUBSCRIPTIONS}
            onMinimize={onMinimize}
          />
        ) : (
          <Wizard activeStep={activeStep}>
            {WIZARD_STEPS.map(({ component }, index) => (
              <AnimatedStep key={index} activeStep={activeStep} prevStepIndex={prevStepIndex}>
                {component(integration)}
              </AnimatedStep>
            ))}
          </Wizard>
        )}
        <StripeImportWizardFooter
          activeStep={activeStep}
          prevStep={prevStep}
          isDisabled={!!importCustomersTask && isTaskInProgress(importCustomersTask.taskStatus)}
          onNextClick={onNextClick}
          getButtonText={getButtonText}
          isNextValid={isValid()}
          withBackButton={!importCustomersTask}
        />
      </GridFlex.Column>
    </Grid>
  );
}
