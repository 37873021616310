import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchCustomerPortalConfigurationQuery,
  FetchCustomerPortalConfigurationQueryVariables,
  CustomerPortalConfiguration,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { TYPOGRAPHY_CONFIGURATION } from './typography';

const FETCH_CUSTOMER_PORTAL_CONFIGURATION = gql`
  query FetchCustomerPortalConfiguration($input: GetWidgetConfigurationInput!) {
    widgetConfiguration(input: $input) {
      customerPortal {
        ...CustomerPortalConfigurationFragment
      }
    }
  }
  fragment CustomerPortalConfigurationFragment on CustomerPortalConfiguration {
    palette {
      primary
      textColor
      iconsColor
      backgroundColor
      borderColor
      currentPlanBackground
      paywallBackgroundColor
    }
    typography {
      ...TypographyConfigurationFragment
    }
    customCss
  }
  ${TYPOGRAPHY_CONFIGURATION}
`;

export type FetchCustomerPortalConfigurationProps = {
  environmentId: string;
};

async function fetchCustomerPortalConfiguration(
  props: FetchCustomerPortalConfigurationProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<CustomerPortalConfiguration | undefined | null> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<
        FetchCustomerPortalConfigurationQuery,
        FetchCustomerPortalConfigurationQueryVariables
      >({
        query: FETCH_CUSTOMER_PORTAL_CONFIGURATION,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: props.environmentId,
          },
        },
      });

      return response.data.widgetConfiguration.customerPortal;
    },
    {
      failureMessageHandler: () => t('widgets.api.failedFetchConfiguration'),
    },
    dispatch,
  );
}

export { fetchCustomerPortalConfiguration };
