import { t } from 'i18next';
import { SubscriptionAddonDataFragment } from '@stigg-types/apiTypes';
import { GridFlex, HeadCell, TwoLinesLayout, LocalDate, Text } from '@stigg-components';
import { PackageVersionNumber } from '../../../../packages/common/components/PackageVersionNumber';
import { OutdatePriceSign } from '../subscriptionInfo/components/OutdatedSubscriptionSign';
import { getPriceAndPeriodFormat } from '../../../../packages/pricing/utils/priceFormatUtils';
import { getBillingCadenceIconTooltip } from '../../../../packages/pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';

export const createHeadCells = (
  outdatedPricePackages?: string[] | null,
): Array<HeadCell<SubscriptionAddonDataFragment, any>> => [
  {
    id: 'id',
    alignment: 'left',
    label: t('subscriptions.addonId'),
    render: (entity: SubscriptionAddonDataFragment) => (
      <GridFlex.RowCenter gap={4}>
        {entity.price &&
          getBillingCadenceIconTooltip({
            billingCadence: entity.price.billingCadence,
            selected: false,
          })}
        <TwoLinesLayout firstRow={entity.addon.displayName} secondRow={entity.addon.refId || ''} />
      </GridFlex.RowCenter>
    ),
  },
  {
    id: 'price',
    alignment: 'left',
    label: t('subscriptions.addonPrice'),
    render: (entity: SubscriptionAddonDataFragment) => (
      <Text.B2>
        {entity.price
          ? getPriceAndPeriodFormat(entity.price, { unitFormat: true, shortFormat: true })
          : t('subscriptions.customPricing')}
      </Text.B2>
    ),
  },
  {
    id: 'quantity',
    alignment: 'left',
    label: t('subscriptions.addonQuantity'),
    render: (entity: SubscriptionAddonDataFragment) => entity.quantity,
  },
  {
    id: 'version',
    alignment: 'left',
    label: t('packages.versionNumber'),
    render: (entity) => (
      <GridFlex.RowCenter gap={1}>
        <PackageVersionNumber versionNumber={entity.addon.versionNumber} isLatest={entity.addon.isLatest} />
        {outdatedPricePackages?.includes(entity.addon.refId) && <OutdatePriceSign />}
      </GridFlex.RowCenter>
    ),
  },
  {
    id: 'updatedAt',
    alignment: 'left',
    label: t('subscriptions.addonLastUpdated'),
    render: (entity: SubscriptionAddonDataFragment) => (
      <LocalDate date={entity.updatedAt} formatOptions={{ withTime: true }} />
    ),
  },
];
