import { t } from 'i18next';
import { PlanEntitlement } from '../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';

export function formatQuantityAndUnits(planEntitlement: PlanEntitlement) {
  return t('entitlements.quantityAndUnits', {
    quantity: planEntitlement.hasUnlimitedUsage
      ? t('entitlements.unlimitedUsage').toLowerCase()
      : planEntitlement.usageLimit,
    units:
      planEntitlement.usageLimit === 1
        ? planEntitlement.feature.featureUnits
        : planEntitlement.feature.featureUnitsPlural,
  });
}
