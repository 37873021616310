import { ExperimentStatus } from '@stigg-types/apiTypes';
import { GridFlex, Icon, IconProps, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';

export function SubscriptionAtExperiment({ status }: { status: ExperimentStatus }) {
  const text =
    status === ExperimentStatus.InProgress ? t('experiments.runningExperiment') : t('experiments.endedExperiment');
  const color: IconProps['color'] = status === ExperimentStatus.InProgress ? 'secondary' : 'warning';

  return (
    <InformationTooltip arrow placement="top" $padding={2} title={<Text.B2>{text}</Text.B2>}>
      <GridFlex.RowCenter>
        <Icon icon="ScienceOutlined" type="materialIcons" color={color} />
      </GridFlex.RowCenter>
    </InformationTooltip>
  );
}
