import { Text, GridFlex } from '@stigg-components';
import { ClipboardChip } from './ClipboardChip';

type ClipboardIdProps = {
  id: string;
};

export function ClipboardId({ id }: ClipboardIdProps) {
  return (
    <GridFlex.RowCenter
      display="inline-flex"
      width="fit-content"
      paddingX={2}
      sx={{
        backgroundColor: (theme) => theme.itamar.palette.grey[50],
        borderRadius: (theme) => theme.itamar.border.radius,
      }}>
      <Text.B2 color="secondary">ID: {id}</Text.B2>
      <ClipboardChip copy={id} iconSize={14} />
    </GridFlex.RowCenter>
  );
}
