import * as Yup from 'yup';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '@stigg-common';
import { AddonAssociatedEntities } from '@stigg-types/apiTypes';
import { Alert, AlertTitle, ExternalLink, Form, GridFlex, List, Text } from '@stigg-components';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { useNavigation } from '../../../navigation/useNavigation';
import { setArchiveDialog } from '../addonSlice';
import { PackageListFragment } from '../../common/components/Packages';
import { useGetAddonAssociatedEntities } from '../queries/useGetAddonAssociatedEntities';
import { useArchiveAddonByRefId } from '../mutations/useArchiveAddonByRefId';
import { navigateTo } from '../../../navigation/actions';

const validationSchema = (displayName: string, hasAssociatedEntities: boolean) => {
  if (!hasAssociatedEntities) {
    return Yup.object().shape({
      packageName: Yup.string().equals([displayName], t('features.yup.archiveInputInvalid')),
    });
  }

  return undefined;
};

function ArchiveAddonWarningComponent({
  addon,
  associatedEntities,
}: {
  addon: PackageListFragment;
  associatedEntities: AddonAssociatedEntities;
}) {
  const navigation = useNavigation();

  return (
    <Alert severity="error" icon={false}>
      <GridFlex.RowCenter>
        <AlertTitle>
          <Text.B2 color="warning.content">
            <Trans
              i18nKey="addons.archiveModal.archiveWarningAssociatedPackages"
              values={{ packageName: addon.displayName }}
            />
          </Text.B2>
        </AlertTitle>
      </GridFlex.RowCenter>
      <List
        sx={{
          maxHeight: 240,
          overflow: 'auto',
        }}
        dense
        role="list">
        <GridFlex.RowCenter p={1}>
          {associatedEntities?.plans?.map(({ refId, displayName }, idx) => (
            <ExternalLink
              key={idx}
              label={`${displayName} plan`}
              url={navigation.composeUrl(navigation.appRoutes.planPage(refId))}
            />
          ))}
          {associatedEntities?.packageGroups?.map(({ packageGroupId, displayName }, idx) => (
            <ExternalLink
              key={(associatedEntities?.plans?.length || 0) + idx}
              label={`${displayName} add-on group`}
              url={navigation.composeUrl(navigation.appRoutes.addonGroupPage(packageGroupId))}
            />
          ))}
        </GridFlex.RowCenter>
      </List>
    </Alert>
  );
}

function ArchiveAddonContent({ addon }: { addon: PackageListFragment }) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const { isLoading, data: associatedEntities } = useGetAddonAssociatedEntities({ addonId: addon.refId });
  const { mutateAsync: archiveAddon } = useArchiveAddonByRefId(addon.refId);

  const hasAssociatedEntities = !isEmpty(associatedEntities?.plans) || !isEmpty(associatedEntities?.packageGroups);

  const closeModal = () => {
    dispatch(setArchiveDialog({ isOpen: false }));
  };

  const handleSubmit = async () => {
    dispatch(navigateTo(navigation.appRoutes.addonsPage()));
    closeModal();
    await archiveAddon();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <GridFlex.RowCenter>
      <Form
        disableSaveOnNoChanges
        submitButtonText={t('sharedComponents.archive')}
        saveButtonColor="error"
        onCancel={closeModal}
        initialValues={{}}
        validationSchema={validationSchema(addon.displayName, hasAssociatedEntities)}
        onSubmit={handleSubmit}
        fields={() => [
          {
            type: 'custom',
            render: () => (
              <Text.B2 mb={2}>
                <Trans
                  i18nKey="addons.archiveModal.archiveWarning"
                  values={{ packageName: addon.displayName }}
                  components={{ strong: <Text.B2 display="inline" $bold /> }}
                />
              </Text.B2>
            ),
          },
          {
            id: 'packageName',
            type: 'text',
            hide: () => hasAssociatedEntities,
            label: <Text.B2 color="primary">{t('packages.archiveModal.confirm', { packageType: 'add-on' })} </Text.B2>,
            autoFocus: true,
            placeholder: addon.displayName,
          },
          {
            type: 'custom',
            hide: () => !hasAssociatedEntities,
            render: () => <ArchiveAddonWarningComponent addon={addon} associatedEntities={associatedEntities} />,
          },
        ]}
      />
    </GridFlex.RowCenter>
  );
}

export function ArchiveAddonModal() {
  const dispatch = useAppDispatch();
  const { addon, isOpen } = useSelector((state: RootState) => state.addonReducer.archiveDialog);
  const [ArchiveAddonModal, setArchiveAddonModal] = useModal({
    title: t('packages.archiveModal.title', { packageType: 'add-on' }),
    onCancel: () => dispatch(setArchiveDialog({ isOpen: false })),
  });

  useEffect(() => {
    setArchiveAddonModal(isOpen);
  }, [isOpen, setArchiveAddonModal]);

  if (!addon) {
    return null;
  }

  return (
    <ArchiveAddonModal>
      <ArchiveAddonContent addon={addon} />
    </ArchiveAddonModal>
  );
}
