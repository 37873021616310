import { Box, Icon, EntityValueCell } from '@stigg-components';
import React from 'react';
import head from 'lodash/head';
import { isAddon, isPackageGroup } from '../../../../common/packageUtils';
import { AddonOrAddonGroupOption } from './AddCompatibleAddonOrAddonGroupDialog';
import { getBillingCadenceIconTooltip } from '../../../../pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';

export function CompatibleAddonOrAddonGroupSearchOption({
  option,
  props,
  getDisabledDescription,
}: {
  option: AddonOrAddonGroupOption;
  props: any;
  getDisabledDescription: (addon: AddonOrAddonGroupOption) => string;
}) {
  const id = isAddon(option) ? option.refId : isPackageGroup(option) ? option.packageGroupId : undefined;
  const billingCadence = isAddon(option) ? head(option.prices)?.billingCadence : undefined;
  const icon = isPackageGroup(option) ? (
    <Icon icon="AddonGroup" type="custom" />
  ) : (
    billingCadence && getBillingCadenceIconTooltip({ billingCadence, selected: false })
  );

  return (
    <Box component="li" {...props} key={id}>
      <EntityValueCell
        entity={{ refId: id, displayName: option.displayName }}
        {...props}
        key={id}
        icon={icon}
        disabledText={props['aria-disabled'] ? getDisabledDescription(option) : undefined}
      />
    </Box>
  );
}
