import React from 'react';
import { Field, Text, TextFields } from '@stigg-components';
import { BillingCadence, BillingModel, BillingPeriod, Currency } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';

export const ALL_BILLING_PERIODS = [BillingPeriod.Monthly, BillingPeriod.Annually];

export function getPricePeriodFields({
  pricePeriodsId,
  billingModel,
  pricePeriodUnit,
  hideLabel,
  currency,
  billingCadence,
}: {
  pricePeriodsId: string;
  billingModel: BillingModel;
  pricePeriodUnit: BillingPeriod;
  hideLabel?: boolean;
  currency: Currency;
  billingCadence: BillingCadence;
}): TextFields<SetPriceWizardFormFields>[] {
  let priceUnit = `/ ${t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}`;

  if (billingModel === BillingModel.PerUnit) {
    priceUnit = `/ ${t('pricing.unit')} ${priceUnit}`;
  }
  if (billingModel === BillingModel.UsageBased) {
    priceUnit = `/ ${t('pricing.unit')}`;
  }
  const isOneOff = billingCadence === BillingCadence.OneOff;

  return ALL_BILLING_PERIODS.map((billingPeriod) => ({
    type: 'text',
    textFieldType: 'number',
    isNumberWithoutSigns: true,
    hide: ({ billingPeriods }) =>
      !billingPeriods.includes(billingPeriod) || (isOneOff && billingPeriod === BillingPeriod.Annually),
    id: `${pricePeriodsId}[${billingPeriod}].amount`,
    label: !hideLabel && isOneOff ? t('pricing.price') : t(`pricing.pricePeriod.${billingPeriod}`),
    placeholder: t('pricing.priceAmountPlaceholder'),
    startAdornment: getSymbolFromCurrency(currency),
    endAdornment: <Text.Caption>{isOneOff ? '' : priceUnit}</Text.Caption>,
    dataTestId: `${billingPeriod}-price`,
  }));
}

export function getBlockPricePeriodFields({
  pricePeriodsId,
  billingModel,
  pricePeriodUnit,
  hideLabel,
  currency,
  billingCadence,
}: {
  pricePeriodsId: string;
  billingModel: BillingModel;
  pricePeriodUnit: BillingPeriod;
  hideLabel?: boolean;
  currency: Currency;
  billingCadence: BillingCadence;
}): Field<SetPriceWizardFormFields>[] {
  let priceUnit = `/ ${t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}`;

  if (billingModel === BillingModel.PerUnit) {
    priceUnit = `/ ${t('pricing.units')} ${priceUnit}`;
  }
  if (billingModel === BillingModel.UsageBased) {
    priceUnit = t('pricing.units');
  }

  const isOneOff = billingCadence === BillingCadence.OneOff;

  return ALL_BILLING_PERIODS.map((billingPeriod) => {
    return {
      type: 'layout',
      hide: ({ billingPeriods }) =>
        !billingPeriods.includes(billingPeriod) || (isOneOff && billingPeriod === BillingPeriod.Annually),
      label: !hideLabel && isOneOff ? t('pricing.price') : t(`pricing.pricePeriod.${billingPeriod}`),
      labelGridProps: {
        width: 200,
        alignItems: 'baseline',
        mb: 1,
      },
      gridProps: {
        mb: 2,
      },
      contentGridProps: {
        flexDirection: 'column',
      },
      fields: [
        {
          type: 'layout',
          gridProps: {
            alignItems: 'center',
            flexDirection: 'row',
          },
          fields: [
            {
              type: 'text',
              textFieldType: 'number',
              isNumberWithoutSigns: true,
              id: `${pricePeriodsId}[${billingPeriod}].amount`,
              placeholder: t('pricing.priceAmountPlaceholder'),
              startAdornment: getSymbolFromCurrency(currency),
              dataTestId: `${billingPeriod}-price`,
            },
            {
              type: 'idle',
              component: <Text.B2 color="secondary">{t('pricing.per')}</Text.B2>,
              gridProps: {
                mx: 4,
                width: 'auto',
                flexGrow: '0 !important',
              },
            },
            {
              type: 'text',
              textFieldType: 'number',
              isNumberWithoutSigns: true,
              id: `${pricePeriodsId}[${billingPeriod}].blockSize`,
              placeholder: t('pricing.blockSizePlaceholder'),
              endAdornment: <Text.Caption>{priceUnit}</Text.Caption>,
              dataTestId: `${billingPeriod}-block-size`,
            },
          ],
        },
      ],
    };
  });
}
