import { Divider, Flex, Grid, Text } from '@stigg-components';
import { t } from 'i18next';
import { useBreadcrumbs } from '@stigg-common';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import { CustomersWidget } from './widgets/CustomersWidget';
// import { SubscriptionsWidget } from './widgets/SubscriptionsWidget';
import { SubscriptionsOverTimeWidget } from './widgets/SubscriptionsOverTimeWidget';
import { SubscriptionsByPlanWidget } from './widgets/SubscriptionsByPlanWidget';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchSubscriptionsOverviewFiltersAction, setTimeRangeFilter } from '../subscriptionsOverviewSlice';
import { TIME_RANGES, TimeRangeLabel } from './constants';
import { SubscriptionsOverviewFilters } from './filters';
import { ChartsLastUpdate } from '../../common/chartsLastUpdate/ChartsLastUpdate';
import { ChartsLastUpdateProvider } from '../../common/chartsLastUpdate/ChartsLastUpdateProvider';

export function SubscriptionsOverviewPage() {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const { isLoading, timeRangeFilter } = useSelector((state: RootState) => state.dashboards.subscriptionsOverview);

  const selectTimeRange = useCallback(
    (label: TimeRangeLabel) => {
      const { durationMs } = TIME_RANGES.find((x) => x.label === label)!;
      const untilTs = moment().endOf('day').valueOf();
      const sinceTs = moment(untilTs - durationMs).valueOf();
      dispatch(setTimeRangeFilter({ label, sinceTs, untilTs, durationMs, isRelativeFromNow: true }));
    },
    [dispatch],
  );

  useEffect(() => {
    selectTimeRange('1M');
    void dispatch(fetchSubscriptionsOverviewFiltersAction());
  }, [dispatch, selectTimeRange]);

  useBreadcrumbs({ title: t('breadCrumbs.dashboard') }, { title: t('dashboards.subscriptionsOverview.crumb') });

  if (!currentEnvironmentId || !timeRangeFilter || isLoading) {
    return <Loader />;
  }

  return (
    <ChartsLastUpdateProvider>
      <Grid alignItems="start">
        <Flex.Column flex={1}>
          <Text.H1 gutterBottom color="primary">
            {t('dashboards.subscriptionsOverview.title')}
          </Text.H1>
          <Text.B2 color="secondary">{t('dashboards.subscriptionsOverview.subtitle')}</Text.B2>
        </Flex.Column>
        <SubscriptionsOverviewFilters onSelectTimeRange={selectTimeRange} />
      </Grid>

      <Divider my={4} />

      <Grid display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
        <CustomersWidget />
        {/* SubscriptionsWidget is hidden for now due to issues with Cube.js and subscriptions schema */}
        {/* <SubscriptionsWidget /> */}
        <SubscriptionsByPlanWidget />
        <SubscriptionsOverTimeWidget />
      </Grid>

      <ChartsLastUpdate />
    </ChartsLastUpdateProvider>
  );
}
