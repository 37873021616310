import React from 'react';
import styled, { css } from 'styled-components/macro';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { SidebarItemsType } from '../../../types/sidebar';
import SidebarNavSection from './SidebarNavSection';

import '../../../vendor/perfect-scrollbar.css';

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

const Scrollbar = styled.div`
  flex: 1;
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

type SidebarNavProps = {
  items: {
    pages: SidebarItemsType[];
  }[];
  isSidebarOpen: boolean;
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items, isSidebarOpen }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (matches ? PerfectScrollbar : Scrollbar) as React.ElementType;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item, index) => (
              <SidebarNavSection component="div" key={index} pages={item.pages} isSidenavOpen={isSidebarOpen} />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
