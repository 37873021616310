import { t } from 'i18next';
import { EventLog } from '@stigg-types/apiTypes';
import { CustomDrawer, ClipboardChip, Tabs, TabProps } from '@stigg-components';
import { getEventName } from '../../utils/eventName.utils';
import EventLogDetailsFooter from './EventLogDetailsFooter';
import { EventLogRequest } from './EventLogRequest';
import { EventDetailsInfo } from './EventDetailsInfo';
import { EventTabs } from '../../../navigation/tabs';

export type EventLogDetailsProps = {
  event: EventLog | null | undefined;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
};

export default function EventLogDetails({ event, onClose, onPrevious, onNext }: EventLogDetailsProps) {
  const title = event?.eventLogType ? getEventName(event.eventLogType) : undefined;
  const copyEventButton = <ClipboardChip copy={window.location.href} tooltipSuffix="event link" variant="button" />;
  const tabs: TabProps[] = [
    {
      title: t('eventsLog.detailsDrawer.tabs.eventDetails'),
      url: EventTabs.Details,
      content: <EventDetailsInfo event={event} />,
    },
    {
      title: t('eventsLog.detailsDrawer.tabs.apiRequest'),
      url: EventTabs.ApiRequest,
      content: <EventLogRequest event={event} />,
    },
  ];

  return (
    <CustomDrawer
      title={title}
      additionalHeaderComponents={copyEventButton}
      isOpen={!!event}
      onClose={onClose}
      variant="persistent"
      footer={<EventLogDetailsFooter onClose={onClose} onPrevious={onPrevious} onNext={onNext} />}
      withStickyHeaderAndFooter
      noOverlay>
      <Tabs data={tabs} queryParamName="eventTab" />
    </CustomDrawer>
  );
}
