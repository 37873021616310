import { t } from 'i18next';
import { CouponStatus } from '@stigg-types/apiTypes';
import { Chip } from '@stigg-components';

export function CouponStatusChip({ status }: { status: CouponStatus }) {
  return (
    <Chip
      size="small"
      label={status === CouponStatus.Active ? t('coupons.active') : t('coupons.archived')}
      color={status === CouponStatus.Active ? 'primary' : 'disabled'}
    />
  );
}
