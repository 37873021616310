import { ExternalLink, Link, Text } from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { PlanFragment, ProductPlanFragment } from '@stigg-types/apiTypes';
import React from 'react';
import { appRoutes, useNavigation } from '../../../navigation/useNavigation';
import { PackageFragment, PackageListFragment } from './Packages';
import { useAppDispatch } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { createPlanDraftAction } from '../../plans/plansSlice';

export function isDowngradePlan(aPackage: PackageFragment | PackageListFragment) {
  const planFragment = aPackage as PlanFragment;
  return aPackage.type === 'Plan' && planFragment.product.productSettings.downgradePlan?.refId === planFragment.refId;
}

export function isDefaultStartPlan(aPackage: PackageFragment | PackageListFragment) {
  const planFragment = aPackage as PlanFragment;
  return (
    aPackage.type === 'Plan' && planFragment.product.productSettings.subscriptionStartPlan?.refId === planFragment.refId
  );
}

export function CantDeletePlanTooltipContent({
  productRefId,
  isDowngradePlan,
}: {
  productRefId: string;
  isDowngradePlan: boolean;
}) {
  const navigation = useNavigation();

  return (
    <Text.B2>
      <Trans
        i18nKey={
          isDowngradePlan
            ? 'plans.api.cantDeletePlanThatUsedAsDowngradePlan'
            : 'plans.api.cantDeletePlanThatUsedAsDefaultStartPlan'
        }
        t={t}
        components={[
          <Link
            onClick={() => {
              navigation.navigateTo(navigation.appRoutes.productPage(productRefId), { anchor: 'customerJourney' });
            }}
          />,
        ]}
      />
    </Text.B2>
  );
}

export function CantEditPricingTooltipContent({ productRefId, i18key }: { productRefId: string; i18key: string }) {
  const navigation = useNavigation();

  return (
    <Text.B2>
      <Trans
        i18nKey={i18key}
        t={t}
        components={[
          <Link
            onClick={() => {
              navigation.navigateTo(navigation.appRoutes.productPage(productRefId), { anchor: 'customerJourney' });
            }}
          />,
        ]}
      />
    </Text.B2>
  );
}

export function LinkToEditPlan({
  plan,
  i18nKey,
}: {
  plan: Pick<ProductPlanFragment, 'id' | 'refId' | 'draftSummary'>;
  i18nKey: string;
}) {
  const dispatch = useAppDispatch();
  return (
    <Text.B2>
      <Trans
        i18nKey={i18nKey}
        t={t}
        components={[
          <Link
            onClick={() => {
              if (plan.draftSummary) {
                void dispatch(
                  navigateTo(appRoutes.planPage(plan.refId, plan.draftSummary.version), { anchor: 'pricing' }),
                );
              } else {
                void dispatch(createPlanDraftAction({ planId: plan.id, anchor: 'pricing' }));
              }
            }}
          />,
        ]}
      />
    </Text.B2>
  );
}

export function LinkToCreatePlan({ productId, i18nKey }: { productId: string; i18nKey: string }) {
  const navigation = useNavigation();
  return (
    <Text.B2>
      <Trans
        i18nKey={i18nKey}
        t={t}
        components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.createPlanPage(productId))} />]}
      />
    </Text.B2>
  );
}

export function CustomEntitlementsDisabledTooltip() {
  return (
    <>
      <Text.B2 mb={2}>{t('pricing.customEntitlementsDisabledPlanText')}</Text.B2>
      <ExternalLink
        label={t('sharedComponents.learnMore')}
        // TODO: change url to docs
        url={t('pricing.customEntitlementsDisabledPlanLearnMoreLink')}
      />
    </>
  );
}
