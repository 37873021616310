import { GridFlex, Icon, Link, Text } from '@stigg-components';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Trans } from 'react-i18next';
import React from 'react';
import { useNavigation } from '../../../navigation/useNavigation';

const StyledGridFlexRow = styled(GridFlex.Row)`
  background: linear-gradient(0deg, #2871eb, #2871eb), #ed6c02;
  padding: ${(props) => props.theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

export function PackagePublishDisabledDueToActiveExperiment({ experimentRefId }: { experimentRefId: string }) {
  const navigation = useNavigation();

  return (
    <StyledGridFlexRow columnGap={2}>
      <Icon icon="ScienceOutlined" type="materialIcons" color="white" />
      <GridFlex.Column rowGap={2}>
        <Text.B1 color="white" $bold>
          {t('experiments.preventEdit.package.title')}
        </Text.B1>
        <Text.B2 color="white">
          <Trans
            i18nKey="experiments.preventEdit.package.content"
            t={t}
            components={[
              <Link
                color="white"
                underline="always"
                onClick={() => navigation.navigateTo(navigation.appRoutes.experimentPage(experimentRefId))}
              />,
            ]}
          />
        </Text.B2>
      </GridFlex.Column>
    </StyledGridFlexRow>
  );
}
