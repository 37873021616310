import { TaskStatus } from '@stigg-types/apiTypes';
import delay from 'lodash/delay';
import { useEffect, useRef, useState } from 'react';
import { setIsTasksProgressTooltipOpen } from '../../../../../modules/application/applicationSlice';
import { useAppDispatch } from '../../../../../redux/store';

export function TaskStatusCard({
  taskStatus,
  onComplete,
  onFailure,
  onInProgress,
  onPending,
}: {
  taskStatus: TaskStatus;
  onComplete: JSX.Element;
  onFailure: JSX.Element;
  onInProgress: JSX.Element;
  onPending: JSX.Element;
}) {
  const [shouldShowCompletedState, setShouldShowCompletedState] = useState(false);
  const previousStatus = useRef<TaskStatus | null>(null);
  const dispatch = useAppDispatch();

  // Show completed state only after some delay so we let the progress bar to finish
  useEffect(() => {
    // task status changed from in progress to completed
    if (
      previousStatus.current &&
      previousStatus.current !== TaskStatus.Completed &&
      taskStatus === TaskStatus.Completed
    ) {
      delay(() => {
        setShouldShowCompletedState(true);
      }, 1000);
      // Hide tasks progress tooltip after 5 seconds
      delay(() => {
        void dispatch(setIsTasksProgressTooltipOpen(false));
      }, 5000);
    } else if (taskStatus === TaskStatus.Completed) {
      setShouldShowCompletedState(true);
    }
    previousStatus.current = taskStatus;
  }, [dispatch, taskStatus]);

  if (shouldShowCompletedState) {
    return onComplete;
  }

  switch (taskStatus) {
    case TaskStatus.Pending: {
      return onPending;
    }
    case TaskStatus.Completed:
    case TaskStatus.InProgress: {
      return onInProgress;
    }
    case TaskStatus.PartiallyFailed:
    case TaskStatus.Failed: {
      return onFailure;
    }
    default:
      return null;
  }
}
