import { GridFlex, HighlightText, Icon, Text, BoundedSearchableSelect, LongText } from '@stigg-components';
import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useSelector } from 'react-redux';
import { CustomerSubscriptionResourceFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchCustomerSubscriptionsResourcesAction } from '../../customersSlice';

function getItemIdentifier(node: CustomerSubscriptionResourceFragment) {
  const { plan, resource } = node;
  return [plan.product.refId, plan.refId, resource?.resourceId].join('#');
}

function renderItem(subscriptionResource: CustomerSubscriptionResourceFragment, search?: string) {
  const {
    plan: {
      displayName: planDisplayName,
      product: { displayName: productDisplayName },
    },
  } = subscriptionResource;
  const resourceId = subscriptionResource.resource?.resourceId;

  return (
    <GridFlex.RowCenter gap={2} height={35}>
      <Icon icon={resourceId ? 'MultipleSubscriptions' : 'SingleSubscription'} type="custom" size={24} />
      <GridFlex.Column>
        <LongText variant="body2" color="primary">
          <HighlightText highlight={search} text={productDisplayName || ''} /> /{' '}
          <HighlightText highlight={search} text={planDisplayName} />
        </LongText>
        {resourceId && (
          <LongText variant="body2" color="secondary" wordBreak sx={{ marginTop: -1 }}>
            <HighlightText highlight={search} text={resourceId} />
          </LongText>
        )}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}

type SelectCustomerResourceProps = {
  selectedResource: CustomerSubscriptionResourceFragment;
  onUpdateResource: (resource: CustomerSubscriptionResourceFragment) => void;
};

export function SelectCustomerResource({ selectedResource, onUpdateResource }: SelectCustomerResourceProps) {
  const { multipleSubscriptionsUi: multipleSubscriptionsUiEnabled } = useFlags<FeatureFlags>();
  const customer = useSelector((root: RootState) => root.customersReducer.customer);
  const customerId = customer?.id || '';
  const dispatch = useAppDispatch();
  const products = useSelector((state: RootState) => state.productReducer.products);
  const isFetching = useSelector((state: RootState) => state.customersReducer.isLoadingCustomerSubscriptionsResources);
  const subscriptionsResources = useSelector((state: RootState) =>
    (state.customersReducer.customerSubscriptionsResources?.edges || []).map((x) => x.node),
  );
  const totalCount = useSelector(
    (state: RootState) => state.customersReducer.customerSubscriptionsResources?.totalCount,
  );

  const shouldShowSelect = useMemo(
    () => products.length > 1 || products.find((product) => product.multipleSubscriptions),
    [products],
  );

  const onSearch = (search: string) => {
    void dispatch(fetchCustomerSubscriptionsResourcesAction({ customerId, search }));
  };

  if (!multipleSubscriptionsUiEnabled) {
    return <></>;
  }

  if (!shouldShowSelect) {
    return <></>;
  }

  return (
    <GridFlex.Column mb={6} gap={1}>
      <Text.B2>{t('sharedComponents.activeSubscription')}</Text.B2>
      <BoundedSearchableSelect
        maxWidth={450}
        height={54}
        onSearch={onSearch}
        isSearching={isFetching}
        totalItems={totalCount}
        items={subscriptionsResources}
        itemSerializer={getItemIdentifier}
        onChange={onUpdateResource}
        value={selectedResource}
        renderItem={renderItem}
      />
    </GridFlex.Column>
  );
}
