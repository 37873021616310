import { ReactNode } from 'react';
import { SxProps } from '@mui/system';
import { Button } from '@stigg-components';

export default function DetailButtons({
  onClick,
  style,
  component,
  children,
}: {
  onClick?: () => void;
  style?: SxProps;
  component?: ReactNode;
  children?: ReactNode;
}) {
  const disabled = !onClick;
  return (
    <Button
      color="outlineBorder"
      textColor="primary"
      onClick={onClick}
      disabled={disabled}
      $outlined
      sx={{ paddingX: 2, minWidth: 0, ...style }}>
      {component || children}
    </Button>
  );
}
