import { t } from 'i18next';
import { Box, EmptyCardContent, GridFlex, PageCard, Text } from '@stigg-components';

type CustomerJourneyNotSupportedProps = {
  text: string;
};

export function CustomerJourneyNotSupported({ text }: CustomerJourneyNotSupportedProps) {
  return (
    <PageCard>
      <GridFlex.Column item flex={1}>
        <Text.H3 mb={2}>{t('products.customerJourney.title')}</Text.H3>
        <Box>
          <EmptyCardContent>
            <Text.B2>{text}</Text.B2>
          </EmptyCardContent>
        </Box>
      </GridFlex.Column>
    </PageCard>
  );
}
