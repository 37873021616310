import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateAddonMutation,
  CreateAddonMutationVariables,
  AddonCreateInput,
  Addon,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { appRoutes } from '../../../navigation/useNavigation';

const CREATE_ADDON = gql`
  mutation CreateAddon($input: AddonCreateInput!) {
    createOneAddon(input: $input) {
      id
      refId
    }
  }
`;

async function createAddon(
  addonData: Omit<AddonCreateInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Addon> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<CreateAddonMutation, CreateAddonMutationVariables>({
        mutation: CREATE_ADDON,
        variables: {
          input: { ...addonData, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      const newAddon = response.data?.createOneAddon;
      if (newAddon) {
        dispatch(navigateTo(appRoutes.addonPage(newAddon.refId)));
      }

      return newAddon;
    },
    {
      successMessage: t('addons.api.successCreate'),
      failureMessageHandler: () => t('addons.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('addons.api.failDupAddonId', {
          addonId: addonData.refId,
        }),
      },
    },
    dispatch,
  );
}

export { createAddon };
