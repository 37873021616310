import { FormRenderProps } from '@stigg-components';
import { useFormik } from 'formik';

export function useDummyFormRenderProps<T>(initialValues: T): FormRenderProps<T> {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setValues,
    setTouched,
  } = useFormik<T>({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  });
  return {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setValues,
    setTouched,
  };
}
