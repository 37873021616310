import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import { UsageEventsQuery, UsageEventsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { Filter } from '../components/createFeature/SidenavCreateFeature';

const FETCH_USAGE_EVENTS = gql`
  query UsageEvents($input: UsageEventsInput!) {
    usageEvents(input: $input) {
      events {
        ...UsageEventFragment
      }
    }
  }
  fragment UsageEventFragment on UsageEvent {
    id
    customerId
    resourceId
    eventName
    timestamp
    dimensions
  }
`;

async function fetchUsageEvents(
  { filters = [] }: { filters?: Filter[] },
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<UsageEventsQuery, UsageEventsQueryVariables>({
        query: FETCH_USAGE_EVENTS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId!,
            filters,
          },
        },
      });

      return res.data.usageEvents;
    },
    {
      failureMessageHandler: () => t('features.api.failFetchUsageEvents'),
    },
    dispatch,
  );
}

export { fetchUsageEvents };
