import type { FC } from 'react';
import styled from 'styled-components/macro';
import { Link, Text } from '@stigg-components';
import { ArrowRight } from 'react-feather';
import React from 'react';
import { t } from 'i18next';

export const SANDBOX_ENVIRONMENT_BANNER_HEIGHT = 41;

const Container = styled.div<{ $open: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: ${({ theme }) => (theme.zIndex.drawer as number) + 1};
  top: 0;
  left: 0;
  width: 100vw;
  height: ${SANDBOX_ENVIRONMENT_BANNER_HEIGHT}px;
  background: ${({ theme }) => theme.itamar.palette.background.sandboxEnvironment};
  justify-content: center;
  align-items: center;
  transform-origin: top;
  transform: scaleY(${({ $open }) => ($open ? 1 : 0)});
  transition: transform 0.15s;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;

export const SandboxEnvironmentBanner: FC<{ open: boolean; leaveSandbox: () => void }> = ({ open, leaveSandbox }) => (
  <Container $open={open}>
    <Text.H6 color="white" $bold>
      {t('sandbox.banner.boldText')}
    </Text.H6>
    &nbsp;
    <Text.H6 color="white">{t('sandbox.banner.text')}</Text.H6>
    &nbsp;
    <Link variant="h6" color="white" underline="always" onClick={leaveSandbox} display="flex">
      {t('sandbox.banner.linkText')}
      <IconWrapper>
        <ArrowRight color="white" size={20} />
      </IconWrapper>
    </Link>
  </Container>
);
