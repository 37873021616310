import { ChartOptions, ChartData } from 'chart.js';
import { groupBy, head, isEmpty, isInteger, range } from 'lodash';
import { t } from 'i18next';
import { Feature, Plan } from '@stigg-types/apiTypes';
import { ResultSet } from '@cubejs-client/core';
import { Theme } from '@mui/material/styles';
import { AnnotationOptions } from 'chartjs-plugin-annotation/types/options';
import capitalize from 'lodash/capitalize';
import { numberFormatter } from '../../../../common/formatters';
import '../../../../common/chartjs/chartjs.plugins';
import { getEntitlement, hasEntitlement } from '../../../helpers/plan.helper';
import {
  getAxisColor,
  getAxisTickColor,
  getAxisTickFont,
  getAxisTitleColor,
  getAxisTitleFont,
  AXIS_TITLE_SPACING,
  GRID_BORDER_DASH,
  getLabelColor,
  getLabelFont,
  LEGEND_BOX_SIZE,
  opacityColor,
  getOtherColor,
  getLineColor,
} from '../../../../common/chartjs/chartjs.theme';
import { AnnotationTooltipHandler, ExternalTooltipHandler } from '../../../../common/chartjs/chartjs.tooltip.plugin';

export type UnlimitedKey = 'unlimited';
export const UNLIMITED_KEY: UnlimitedKey = 'unlimited';

function preProcessData(
  dataSource: Array<{ [key: string]: string | number | boolean }>,
  feature: Feature,
  allPlans: Plan[],
) {
  const firstItem = head(dataSource)!;
  const bucketSize = Number(firstItem['FeatureUsageDistribution.bucketSize'] as string);
  const rangeBottom = Number(firstItem['FeatureUsageDistribution.rangeBottom'] as string);

  const bucketToPlanToCountMap: Map<number, Map<string, number>> = dataSource.reduce((accumulatorMap, item) => {
    const bucketNum = Number(item['FeatureUsageDistribution.bucket'] as string);
    const planRefId = item['FeatureUsageDistribution.planRefId'] as string;
    const count = Number(item['FeatureUsageDistribution.customersCount'] as string);

    let planToCountMap = accumulatorMap.get(bucketNum);

    if (!planToCountMap) {
      planToCountMap = new Map<string, number>();
      accumulatorMap.set(bucketNum, planToCountMap);
    }

    planToCountMap.set(planRefId, count);

    return accumulatorMap;
  }, new Map<number, Map<string, number>>());

  const plansWithEntitlement = allPlans.filter((plan) => hasEntitlement(plan, feature));
  const plansByLimits = groupBy(
    plansWithEntitlement,
    (plan) => getEntitlement(plan, feature)?.usageLimit || 'unlimited',
  );

  const resolvedBucketSize = bucketSize || 1;
  const limitBuckets = Object.keys(plansByLimits)
    .map(Number)
    .filter(isInteger)
    .map((num) => {
      return num % resolvedBucketSize === 0 ? num / resolvedBucketSize + 1 : Math.ceil(num / resolvedBucketSize);
    });

  const maxBucket = Math.max(...[...limitBuckets, ...Array.from(bucketToPlanToCountMap.keys())]);

  return {
    bucketSize,
    rangeBottom,
    maxBucket,
    bucketToPlanToCountMap,
    plansByLimits,
  };
}

function composeBucketLabel(bucketStart: number, bucketEnd: number) {
  return bucketStart === bucketEnd
    ? String(bucketStart)
    : `${numberFormatter(bucketStart)} - ${numberFormatter(bucketEnd)}`;
}

function getPlanValue(bucketData: Map<string, number> | undefined, plan: Plan) {
  return bucketData?.get(plan.refId) ?? 0;
}

function getOtherPlansValue(bucketData: Map<string, number> | undefined, plan: Plan) {
  return Array.from(bucketData?.entries() || [])
    .filter((entry) => entry[0] !== plan.refId)
    .map((entry) => entry[1])
    .reduce((count, current) => count + current, 0);
}

export function computeChartData(
  resultSet: ResultSet | null,
  feature: Feature,
  plan: Plan,
  allPlans: Plan[],
  color: string,
  tooltipHandler: ExternalTooltipHandler<'bar'>,
  planLimitTooltipHandler: AnnotationTooltipHandler,
  theme: Theme,
  setIsReady?: () => void,
) {
  if (!resultSet) {
    return {};
  }

  const dataSource = resultSet.tablePivot();

  if (isEmpty(dataSource)) {
    return {};
  }

  if (setIsReady) {
    setIsReady();
  }

  const { bucketSize, rangeBottom, maxBucket, bucketToPlanToCountMap, plansByLimits } = preProcessData(
    dataSource,
    feature,
    allPlans,
  );

  const labels: string[] = [];
  const planDataset: number[] = [];
  const otherPlansDataset: number[] = [];

  range(1, maxBucket + 1).forEach((bucketNum) => {
    const bucketData = bucketToPlanToCountMap.get(bucketNum);
    if (bucketSize === 0) {
      labels.push(composeBucketLabel(bucketNum - 1, bucketNum - 1));
    } else {
      const bucketStart = rangeBottom + (bucketNum - 1) * bucketSize + 1;
      const bucketEnd = bucketStart + bucketSize - 1;
      labels.push(composeBucketLabel(bucketStart, bucketEnd));
    }

    planDataset.push(getPlanValue(bucketData, plan));
    otherPlansDataset.push(getOtherPlansValue(bucketData, plan));
  });

  if (planDataset.every((value) => value === 0)) {
    return {};
  }

  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: t('sharedComponents.planSuffix', {
          planName: plan.displayName,
        }),
        data: planDataset,
        backgroundColor: opacityColor(color, 0.3),
        borderColor: color,
        borderWidth: {
          top: 2,
        },
        categoryPercentage: 1,
        barPercentage: 1,
      },
      {
        label: t('dashboards.featureInspector.featureUsageOnOtherPlans'),
        data: otherPlansDataset,
        backgroundColor: opacityColor(getOtherColor(theme), 0.5),
        hoverBackgroundColor: opacityColor(getOtherColor(theme), 0.5),
        categoryPercentage: 1,
        barPercentage: 1,
      },
    ],
  };

  const limitVerticalLines: AnnotationOptions[] = Object.keys(plansByLimits).map((limitStr) => {
    const limit = Number(limitStr);
    const planNames: string[] = plansByLimits[limitStr].map((plan) => plan.displayName);

    let xValue;
    if (Number.isNaN(limit)) {
      xValue = maxBucket - 0.51;
    } else {
      xValue = (limit - rangeBottom) / (bucketSize || 1) - 0.5;
    }
    const lineColor = plansByLimits[limitStr].some((item) => plan.refId === item.refId) ? color : getLineColor(theme);

    return {
      type: 'line',
      xMin: xValue,
      xMax: xValue,
      label: {
        content: [limitStr, planNames.map((plan) => t('sharedComponents.planSuffix', { planName: plan })).join(', ')],
      },
      borderColor: lineColor,
      borderDash: [8, 8],
      borderWidth: 2,
      enter: (context, event): boolean | void => {
        planLimitTooltipHandler(true, context, event);
      },
      leave: (context, event): boolean | void => {
        planLimitTooltipHandler(false, context, event);
      },
    };
  });

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
        external: tooltipHandler,
      },
      legend: {
        position: 'bottom' as const,
        align: 'start',
        onHover: (e) => {
          (e?.native?.target as HTMLElement).style.cursor = 'pointer';
        },
        onLeave: (e) => {
          (e?.native?.target as HTMLElement).style.cursor = 'default';
        },
        labels: {
          color: getLabelColor(theme),
          font: getLabelFont(),
          boxWidth: LEGEND_BOX_SIZE,
          boxHeight: LEGEND_BOX_SIZE,
        },
      },
      datalabels: {
        display: false,
      },
      annotation: {
        annotations: limitVerticalLines,
      },
    },
    scales: {
      y: {
        stacked: false,
        ticks: {
          callback: (value) => numberFormatter(value as number),
          maxTicksLimit: 5,
          font: getAxisTickFont(),
          color: getAxisTickColor(theme),
        },
        grid: {
          borderDash: GRID_BORDER_DASH,
          color: getAxisColor(theme),
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { bottom: AXIS_TITLE_SPACING },
          text: t('sharedComponents.customer_other'),
        },
      },
      x: {
        grid: {
          color: getAxisColor(theme),
        },
        stacked: true,
        ticks: {
          font: getAxisTickFont(),
          color: getAxisTickColor(theme),
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { top: AXIS_TITLE_SPACING },
          text: capitalize(feature.featureUnitsPlural as string),
        },
      },
    },
  };

  return { data, options };
}
