import { Text } from '@stigg-components';
import { EntitlementFields } from '../../../../../entitlements/components/entitlementSettings/types';

export function SelectedEntitlementsStatus({ entitlements }: { entitlements: EntitlementFields[] }) {
  return entitlements.length > 0 ? (
    <Text.Sub2>
      {entitlements.length} entitlement{entitlements.length > 1 ? 's' : ''} selected
    </Text.Sub2>
  ) : null;
}
