import { Text } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getBillingCadenceDescription } from './BillingCadenceStep.utils';

export function BillingCadenceStepSummary() {
  const {
    formRenderProps: {
      values: { billingCadence },
    },
  } = useSetPriceWizardFormContext();

  return <Text.B2>{getBillingCadenceDescription(billingCadence)}</Text.B2>;
}
