import React from 'react';
import { PricingType, SubscriptionStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { CustomerFilterChipProps } from './CustomerFilterChip';
import {
  ExtraSubscriptionStatuses,
  SubscriptionStatusChip,
} from './customerPage/customerSubscriptions/SubscriptionStatusChip';
import { CustomerFilterChipOption } from './CustomerFilterChip/CustomerFilterChip.types';

export const CUSTOMER_PLAN_FILTER_OPTIONS: CustomerFilterChipProps<PricingType>['options'] = [
  PricingType.Free,
  PricingType.Paid,
  PricingType.Custom,
].map((pricingType) => ({
  type: 'option',
  label: () => t(`pricing.pricingType.${pricingType}`),
  value: pricingType,
}));

export const SUBSCRIPTION_STATUS_FILTER_OPTIONS: CustomerFilterChipOption<
  SubscriptionStatus | ExtraSubscriptionStatuses
>[] = [
  {
    type: 'option',
    label: () => t('subscriptions.statuses.active'),
    value: SubscriptionStatus.Active,
    checkboxLabel: <SubscriptionStatusChip status={SubscriptionStatus.Active} hasPointer shouldNotTruncate />,
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.inTrial'),
    value: SubscriptionStatus.InTrial,
    checkboxLabel: <SubscriptionStatusChip status={SubscriptionStatus.InTrial} hasPointer shouldNotTruncate />,
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.scheduledCancellation'),
    value: ExtraSubscriptionStatuses.ScheduledCancellation,
    checkboxLabel: (
      <SubscriptionStatusChip status={ExtraSubscriptionStatuses.ScheduledCancellation} hasPointer shouldNotTruncate />
    ),
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.notStarted'),
    value: SubscriptionStatus.NotStarted,
    checkboxLabel: <SubscriptionStatusChip status={SubscriptionStatus.NotStarted} hasPointer shouldNotTruncate />,
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.pendingPayment'),
    value: SubscriptionStatus.PaymentPending,
    checkboxLabel: <SubscriptionStatusChip status={SubscriptionStatus.PaymentPending} hasPointer shouldNotTruncate />,
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.paymentError'),
    value: ExtraSubscriptionStatuses.PaymentError,
    checkboxLabel: (
      <SubscriptionStatusChip status={ExtraSubscriptionStatuses.PaymentError} hasPointer shouldNotTruncate />
    ),
  },
  {
    type: 'option',
    label: () => t('subscriptions.statuses.cancelled'),
    value: SubscriptionStatus.Canceled,
    checkboxLabel: <SubscriptionStatusChip status={SubscriptionStatus.Canceled} hasPointer shouldNotTruncate />,
  },
];
