import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  CursorPaging,
  CustomerSortFields,
  FetchCustomersQuery,
  FetchCustomersQueryVariables,
  PricingType,
  SortDirection,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { ApolloQueryResult } from '@apollo/client';

import { apolloClient } from '../../../ApolloClient';
import { RootState } from '../../../redux/store';
import { getFetchCustomersQuery } from './fetchCustomers';
import { generateFetchCustomersFilters } from './generateFetchCustomersFilters';
import { ExtraSubscriptionStatuses } from '../components/customerPage/customerSubscriptions/SubscriptionStatusChip';
import { ConditionalFilter } from '../../../components/conditionalFilters/ConditionalFilters';

export type GetCustomersProps = {
  environmentId?: string;
  pricingTypes?: PricingType[];
  subscriptionStatuses?: (SubscriptionStatus | ExtraSubscriptionStatuses)[];
  conditionalFilters?: ConditionalFilter[];
  search?: string | null;
  cursor?: CursorPaging;
};

export function generateGetCustomersQueryKey({
  environmentId,
  pricingTypes,
  subscriptionStatuses,
  conditionalFilters,
  search,
  cursor,
}: GetCustomersProps) {
  return ['customers', environmentId, pricingTypes, subscriptionStatuses, conditionalFilters, search, cursor];
}

export const useGetCustomers = (props: GetCustomersProps) => {
  const { conditionalFilters, cursor } = props;
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  return useQuery({
    queryKey: generateGetCustomersQueryKey({
      environmentId,
      ...props,
    }),
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<FetchCustomersQuery, FetchCustomersQueryVariables>({
            fetchPolicy: 'network-only',
            query: getFetchCustomersQuery(conditionalFilters?.some((filter) => !!filter.value)),
            variables: {
              paging: cursor,
              sorting: { field: CustomerSortFields.CreatedAt, direction: SortDirection.Desc },
              filter: generateFetchCustomersFilters({
                environmentId,
                ...props,
              }),
            },
          }),
        {
          failureMessageHandler: () => t('customers.api.failFetch'),
        },
        dispatch,
      ),
    select: (result: ApolloQueryResult<FetchCustomersQuery>) => ({
      customers: result.data?.customers?.edges?.map((edge) => edge.node),
      // @ts-ignore - fetch customer query does not always return totalCount
      totalCount: result.data?.customers?.totalCount,
      pageInfo: result.data?.customers?.pageInfo,
    }),
    retry: 0, // we automatically retry in apollo client
  });
};
