import { gql } from '@apollo/client';
import { t } from 'i18next';
import { UpdateOneAddonMutation, UpdateOneAddonMutationVariables, AddonUpdateInput } from '@stigg-types/apiTypes';
import { ExecuteOperationOptions } from '@stigg-common';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch } from '../../../../redux/store';
import { fetchAddonByRefIdAction } from '../addonSlice';

const UPDATE_ADDON = gql`
  mutation UpdateOneAddon($input: AddonUpdateInput!) {
    updateOneAddon(input: $input) {
      id
      description
      displayName
      billingId
      billingLinkUrl
      updatedAt
      versionNumber
      hiddenFromWidgets
      prices {
        id
        crmLinkUrl
        crmId
      }
      syncStates {
        vendorIdentifier
        status
        error
      }
    }
  }
`;

type UpdateAddonProps = { addonRefId: string; updatePayload: AddonUpdateInput } & ExecuteOperationOptions;

async function updateAddon(
  { addonRefId, updatePayload, silentFetch }: UpdateAddonProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<UpdateOneAddonMutation['updateOneAddon'] | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateOneAddonMutation, UpdateOneAddonMutationVariables>({
        mutation: UPDATE_ADDON,
        variables: { input: updatePayload },
      });

      const responseData = response.data?.updateOneAddon;
      if (responseData) {
        await dispatch(
          fetchAddonByRefIdAction({
            refId: addonRefId,
            versionNumber: responseData.versionNumber,
            silentFetch,
          }),
        );
      }
      return responseData;
    },
    {
      failureMessageHandler: () => t('addons.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateAddon };
