import { Grid, Link, Text } from '@stigg-components';
import { t } from 'i18next';
import { SubscriptionDataFragment, UnitAmountChangeVariables } from '@stigg-types/apiTypes';
import React from 'react';
import isNil from 'lodash/isNil';
import { useNavigation } from '../../../../../../../navigation/useNavigation';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../SubscriptionFutureUpdateRowContainer';

export function UnitAmountUpdateRow({
  changeVariables,
  subscription,
  alertVariant,
}: {
  changeVariables: UnitAmountChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const navigation = useNavigation();

  if (!subscription?.prices) {
    return null;
  }

  const { newUnitAmount, featureId } = changeVariables;

  const price =
    !featureId && subscription?.prices.length === 1
      ? subscription.prices[0] // backward compatibility
      : subscription?.prices.find((p) => p.featureId === featureId);

  if (!price || isNil(newUnitAmount)) {
    return null;
  }

  const { usageLimit } = price;
  const feature = price.price?.feature;

  if (isNil(usageLimit) || !feature || newUnitAmount === usageLimit) {
    return null;
  }

  const isIncrease = newUnitAmount > usageLimit;

  return (
    <SubscriptionFutureUpdateRowContainer changeType={isIncrease ? ChangeType.INCREASE : ChangeType.DECREASE}>
      <Grid alignItems="baseline" gap={1}>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>
          {t(
            isIncrease
              ? 'subscriptions.schedules.unitAmountIncreasePrefix'
              : 'subscriptions.schedules.unitAmountReducePrefix',
          )}
        </Text.B2>
        <Link
          variant="body2"
          onClick={() => {
            navigation.navigateTo(navigation.appRoutes.featurePage(feature?.refId || ''));
          }}>
          <Text.B2 $bold color={alertVariant ? 'warning.content' : undefined}>
            {feature?.featureUnits}
          </Text.B2>
        </Link>
        <Text.B2 color={alertVariant ? 'warning.content' : 'primary'}>
          {t('subscriptions.schedules.unitAmountEnd', {
            newUnitAmount,
            units: newUnitAmount > 1 ? feature?.featureUnitsPlural : feature?.featureUnits,
          })}
        </Text.B2>
      </Grid>
    </SubscriptionFutureUpdateRowContainer>
  );
}
