import moment from 'moment';

function isLessThenOneDay(date: Date) {
  if (moment.utc(date).startOf('days').diff(moment.utc().startOf('days'), 'days') === 0) {
    return moment.utc(date).startOf('days').diff(moment.utc().startOf('days'), 'hours') > 0;
  }
  return false;
}

export function getDateRemainingDays(date: Date) {
  const remainingDays = moment.utc(date).endOf('days').diff(moment.utc().startOf('days'), 'days');
  const lessThenOneDay = isLessThenOneDay(date);
  const isInRange = moment.utc(date) > moment.utc();
  return { remainingDays, isInRange, isLessThenOneDay: lessThenOneDay };
}
