import { Button, Icon } from '@stigg-components';
import { t } from 'i18next';

type ClientSideSecurityIndicatorProps = { enabled: boolean; onClick: () => void };

export function ClientSideSecurityIndicator({ enabled, onClick }: ClientSideSecurityIndicatorProps) {
  return (
    <Button
      size="small"
      sx={{ paddingX: 2 }}
      variant="contained"
      color={enabled ? 'success' : 'warning'}
      startIcon={<Icon size={18} type="reactFeather" icon={enabled ? 'Lock' : 'AlertTriangle'} />}
      onClick={onClick}
      endIcon={<Icon size={16} type="reactFeather" icon="ChevronRight" />}>
      {enabled ? t('accounts.enforced') : t('accounts.notEnforced')}
    </Button>
  );
}
