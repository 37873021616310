import { t } from 'i18next';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { VendorIdentifier, IntegrationFragment, AwsMarketplaceCredentials } from '@stigg-types/apiTypes';
import { Optional } from '../../../../types/primitives';
import { useAWSMarketplaceIntegrationWizardSteps } from './useAWSMarketplaceIntegrationWizardSteps';
import { ImportAWSProductsButton } from './ImportAWSProductsButton';
import { Dialog } from '../../../../components/Dialog';
import { AWSProductsImportWizard } from './AWSProductsImportWizard';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createIntegrationAction, fetchAWSExternalIdAction } from '../../integrationsSlice';
import { AWSMarketplaceContactUsState } from './AWSMarketplaceContactUsState';
import { useBooleanEntitlement } from '../../../../doggo/hooks/useBooleanEntitlement';
import { StiggFeature } from '../../../../doggo/StiggFeature';

type AWSMarketplaceIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function AWSMarketplaceIntegrationPage({ integration, headerComponent }: AWSMarketplaceIntegrationPageProps) {
  const integrationRoleARN = (integration?.credentials as Optional<AwsMarketplaceCredentials>)?.awsRoleArn;
  const hasIntegrationInitialStateRef = useRef(!!integrationRoleARN);

  const dispatch = useAppDispatch();
  const accountId = useSelector((state: RootState) => state.accountReducer.account?.id);
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const externalId = useSelector((state: RootState) => state.integrations.awsExternalId) || '';
  const entitlement = useBooleanEntitlement(StiggFeature.AWS_MARKETPLACE_INTEGRATION);

  const [hasIntegration, setHasIntegration] = useState(!!integrationRoleARN);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [addIntegrationError, setAddIntegrationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roleARN, setRoleARN] = useState<string>(integrationRoleARN || '');

  useEffect(() => {
    if (integrationRoleARN) {
      setRoleARN(integrationRoleARN);
      setHasIntegration(true);
    }
  }, [integrationRoleARN]);

  useEffect(() => {
    void dispatch(fetchAWSExternalIdAction());
  }, [dispatch]);

  const { connectWithStiggSteps, notifyStiggSteps } = useAWSMarketplaceIntegrationWizardSteps({
    hasIntegration,
    accountId,
    environmentId,
    externalId,
    roleARN,
    onRoleARNChange: setRoleARN,
    isLoading,
    addIntegrationError,
    onAddIntegration: async () => {
      setIsLoading(true);
      setAddIntegrationError(false);

      try {
        await dispatch(
          createIntegrationAction({
            vendorIdentifier: VendorIdentifier.AwsMarketplace,
            awsMarketplaceCredentials: {
              awsRoleArn: roleARN || '',
            },
          }),
        ).unwrap();

        setAddIntegrationError(false);
      } catch (e) {
        setAddIntegrationError(true);
        setHasIntegration(false);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleImportDialogClose = () => {
    setIsImportDialogOpen(false);
  };

  if (!hasIntegration && !entitlement.hasAccess) {
    return <AWSMarketplaceContactUsState headerComponent={headerComponent} />;
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      <Text.H6 mb={4}>{t('integrations.awsMarketplace.integrateWithStiggTitle')}</Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="aws-marketplace-connect-stigg"
        steps={connectWithStiggSteps}
        isStepByStep={!hasIntegration}
      />

      <Text.H6 mt={6} mb={4}>
        {t('integrations.awsMarketplace.NotifyStiggTitle')}
      </Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="aws-marketplace-notify-stigg"
        steps={notifyStiggSteps}
        disableAllSteps={!hasIntegration}
        isStepByStep={!hasIntegrationInitialStateRef.current && hasIntegration}
        showContinueButtonForLastStep
      />

      <ImportAWSProductsButton
        disabled={!hasIntegration}
        onImport={() => {
          setIsImportDialogOpen(true);
        }}
      />

      <Dialog
        key={`${hasIntegration}`}
        disableCloseOnEscapeKey
        fullScreen
        isOpen={isImportDialogOpen}
        onCancel={handleImportDialogClose}
        content={<AWSProductsImportWizard onClose={handleImportDialogClose} />}
        padding={0}
      />
    </>
  );
}
