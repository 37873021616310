import { SectionIdentifier, StepIdentifier } from '../SetPriceWizardForm.steps';
import { Charge, OverageCharge } from '../SetPriceWizardForm.types';
import { getChargeGroupId } from '../../../../utils/priceGroups';

export function getInitialFocusedChargeUUID(
  stepId: StepIdentifier,
  charges: (Charge | OverageCharge)[],
  initialFocusedSection?: SectionIdentifier,
) {
  let focusedCharge: Charge | undefined;

  if (initialFocusedSection?.stepId === stepId && initialFocusedSection.priceGroupId) {
    focusedCharge = charges.find((charge) => getChargeGroupId(charge) === initialFocusedSection.priceGroupId);
  } else if (charges.length === 1) {
    [focusedCharge] = charges;
  }

  return focusedCharge?.uuid;
}
