import { ClipboardJSON, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { EventLog } from '@stigg-types/apiTypes';
import EventActorSection from './EventActorSection';
import EventDetailsSection from './EventDetailsSection';

export type EventDetailsInfoProps = {
  event: EventLog | null | undefined;
};

export function EventDetailsInfo({ event }: EventDetailsInfoProps) {
  return (
    <GridFlex.Column gap={6}>
      <EventDetailsSection event={event} />
      <EventActorSection actor={event?.actor} />
      <ClipboardJSON title={t('eventsLog.detailsDrawer.payload')} json={event?.payload} />
    </GridFlex.Column>
  );
}
