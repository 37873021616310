import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type TaxesSectionProps = Pick<PreviewNextInvoiceFragment, 'tax' | 'taxDetails'>;

export function TaxesSection({ tax, taxDetails }: TaxesSectionProps) {
  if (!tax || !taxDetails) {
    return null;
  }

  return <ChargeRow description={taxDetails.displayName} charge={tax} sx={{ mt: 4 }} />;
}
