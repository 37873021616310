import { Text, GridFlex } from '@stigg-components';
import { styled } from '@stigg-theme';

const StatusDescriptionBox = styled(GridFlex.RowCenter)`
  height: 48px;
  border-radius: 4px;
  width: 100%;
  background-color: ${({ theme }) => theme.itamar.palette.grey[50]};
`;

export function SubscriptionStatusDescription({ title }: { title: string }) {
  return (
    <StatusDescriptionBox pl={4}>
      <Text.B2>{title}</Text.B2>
    </StatusDescriptionBox>
  );
}
