import { Text } from '@stigg-components';
import { t } from 'i18next';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getChargesText } from './ChargesStepSummary';

export function OverageChargesStepSummary() {
  const {
    formRenderProps: {
      values: { overageCharges },
    },
  } = useSetPriceWizardFormContext();

  return (
    <Text.B2>
      {getChargesText(overageCharges.charges.length, { emptyText: t('pricing.overageCharges.noCharges') })}
    </Text.B2>
  );
}
