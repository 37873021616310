import React, { forwardRef, PropsWithChildren } from 'react';
import { CardContent, Card } from '@stigg-components';
import { SpacingProps } from '@mui/system';

export const PageCard = forwardRef<
  any,
  PropsWithChildren<{
    className?: string;
    cardProps?: React.ComponentProps<typeof Card> & SpacingProps;
    cardContentProps?: React.ComponentProps<typeof CardContent>;
  }>
>(({ children, className, cardProps = {}, cardContentProps = {} }, ref) => (
  <Card p={2} className={className} {...cardProps} ref={ref}>
    <CardContent {...cardContentProps}>{children}</CardContent>
  </Card>
));
