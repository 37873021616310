import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { GridFlex, PageHeader, NotFoundPage } from '@stigg-components';
import EventLog, { EventLogColumns } from './components/EventLog';

const EVENT_LOG_COLUMNS = [
  EventLogColumns.Timestamp,
  EventLogColumns.EventName,
  EventLogColumns.Entity,
  EventLogColumns.Actor,
];

export default function EventLogPage() {
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();

  if (!showEventLog) {
    return <NotFoundPage />;
  }

  return (
    <GridFlex.Column gap={2} mb={10} $fullHeight>
      <PageHeader title={t('eventsLog.title')} />
      <EventLog columns={EVENT_LOG_COLUMNS} />
    </GridFlex.Column>
  );
}
