import { useState } from 'react';
import { t } from 'i18next';
import styled, { css } from 'styled-components/macro';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Box, CustomRenderProps, LongText, HighlightText } from '@stigg-components';
import { CreateFeatureFormFields } from './SidenavCreateFeature';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';
import { AutocompleteSearch } from '../../../packages/plans/components/AutoCompleteSearch';

const AutocompleteSearchWrapper = styled(Box)<{ $inputIsEmpty: boolean }>`
  .MuiAutocomplete-noOptions {
    padding: 6px 16px;
  }
  ${({ $inputIsEmpty }) =>
    $inputIsEmpty &&
    css`
      .MuiAutocomplete-noOptions {
        display: none;
      }
    `}
`;

export function FieldAutocomplete<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  possibleOptions,
  formRenderProps,
  fieldKey,
  readonly,
  defaultValue,
}: {
  possibleOptions: { displayName: string; value: string }[];
  formRenderProps: CustomRenderProps<T>;
  fieldKey: string;
  readonly?: boolean;
  defaultValue?: string;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredPossibleFields = possibleOptions.filter(
    ({ displayName }) => displayName.toLowerCase().indexOf(searchTerm?.toLowerCase() || '') > -1,
  );
  const onChooseOption = (field?: string) => {
    formRenderProps.setFieldValue(fieldKey, field, true);
    setTimeout(() => {
      formRenderProps.setFieldTouched(fieldKey, true);
    }, 0);
    setSearchTerm(field || '');
  };
  const onSearchKeyUp = (e) => {
    setSearchTerm(e?.target?.value || '');
  };
  const value = get(formRenderProps.values, fieldKey);

  const searchOptions = filteredPossibleFields.some(({ value }) => value === searchTerm)
    ? filteredPossibleFields
    : searchTerm
    ? [{ displayName: searchTerm, value: searchTerm }, ...filteredPossibleFields]
    : filteredPossibleFields;

  return (
    <AutocompleteSearchWrapper $inputIsEmpty={isEmpty(searchTerm)}>
      <AutocompleteSearch
        allowReset
        disabled={readonly}
        autoHighlight
        forcePopupIcon={false}
        blurOnSelect
        disablePortal
        resetInputOnChoose={false}
        chooseOption={(field) => onChooseOption(field?.displayName)}
        onInputChange={(inputValue) => setSearchTerm(inputValue)}
        searchOptions={searchOptions}
        onSearchKeyUp={onSearchKeyUp}
        placeholder={t('features.fieldEmptyPlaceholder')}
        width={200}
        defaultValue={
          value
            ? { displayName: value, value }
            : defaultValue
            ? { displayName: defaultValue, value: defaultValue }
            : undefined
        }
        isFetching={false}
        getOptionLabel={(option) => option.displayName}
        renderOption={(props: any, { displayName }, state) => (
          <Box {...props}>
            <LongText variant="body2" color="primary" wordBreak>
              {displayName === searchTerm ? (
                <span style={{ fontWeight: 'bold' }}>{displayName}</span>
              ) : (
                <HighlightText highlight={state?.inputValue} text={displayName} />
              )}
            </LongText>
          </Box>
        )}
        isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
        inputProps={{ disabled: readonly }}
      />
    </AutocompleteSearchWrapper>
  );
}
