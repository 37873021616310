import { Field } from '@stigg-components';
import { EntitlementBehavior } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { EntitlementFormFields } from '../types';

const ENTITLEMENT_BEHAVIOUR_VALUES = [EntitlementBehavior.Increment, EntitlementBehavior.Override];

export function getEntitlementBehaviorFields(
  index: number,
  withEntitlementBehaviourOption?: boolean,
): Field<EntitlementFormFields>[] {
  return [
    {
      type: 'select',
      id: `entitlements[${index}].behavior`,
      label: t('entitlements.entitlementBehavior.name'),
      hide: () => !withEntitlementBehaviourOption,
      layout: 'row',
      values: ENTITLEMENT_BEHAVIOUR_VALUES.map((value) => ({
        value,
        displayValue: t(`entitlements.entitlementBehavior.values.${value}`),
      })),
    },
  ];
}
