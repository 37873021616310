import { EventActor, EventActorInfo } from '@stigg-types/apiTypes';
import { CustomIcons } from '@stigg-components';
import { t } from 'i18next';

export const getActorIcon = (type: EventActor | null | undefined): CustomIcons => {
  switch (type) {
    case EventActor.Service:
    case EventActor.User:
      return 'AppActor';
    case EventActor.AppPublic:
    case EventActor.AppCustomer:
    case EventActor.AppServer:
      return 'ApiActor';
    case EventActor.Stripe:
      return 'Stripe';
    case EventActor.Salesforce:
      return 'SalesforceActor';
    case EventActor.Support:
      return 'SupportActor';
    case EventActor.Import:
      return 'ImportActor';
    case EventActor.Scheduler:
      return 'SchedulerActor';
    case EventActor.Migration:
      return 'MigrationActor';
    case EventActor.Aws:
      return 'AWSActor';
    case EventActor.System:
    default:
      return 'SystemActor';
  }
};

export const getActorTypeDisplayName = (type: EventActor | null | undefined) => {
  switch (type) {
    case EventActor.AppCustomer:
      return t('eventsLog.actorTypes.appCustomer');
    case EventActor.AppPublic:
      return t('eventsLog.actorTypes.appPublic');
    case EventActor.AppServer:
      return t('eventsLog.actorTypes.appServer');
    case EventActor.Salesforce:
      return t('eventsLog.actorTypes.salesforce');
    case EventActor.Service:
      return t('eventsLog.actorTypes.service');
    case EventActor.Stripe:
      return t('eventsLog.actorTypes.stripe');
    case EventActor.Support:
      return t('eventsLog.actorTypes.support');
    case EventActor.User:
      return t('eventsLog.actorTypes.user');
    case EventActor.Aws:
      return t('eventsLog.actorTypes.aws');
    case EventActor.Import:
      return t('eventsLog.actorTypes.import');
    case EventActor.Migration:
      return t('eventsLog.actorTypes.migration');
    case EventActor.Scheduler:
      return t('eventsLog.actorTypes.scheduler');
    case EventActor.System:
      return t('eventsLog.actorTypes.system');
    default:
      return t('eventsLog.actorTypes.system');
  }
};

export const getActorUserInformation = (actor: EventActorInfo | null | undefined) => {
  if (!actor || (!actor.name && !actor?.email)) {
    return null;
  }
  if (actor.name && actor.email) {
    return `${actor.name} (${actor.email})`;
  }

  return actor.name || actor.email || null;
};

export const getActorInformation = (actor: EventActorInfo | null | undefined) => {
  if (!actor || !actor.type) return { primary: 'System' }; // default value in case of missing data

  const actorType = getActorTypeDisplayName(actor.type);
  const actorUserInformation = getActorUserInformation(actor);

  if (actorUserInformation) {
    return { primary: actorUserInformation, secondary: actorType };
  }

  return { primary: actorType };
};
