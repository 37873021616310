import { combineReducers } from '@reduxjs/toolkit';
import featureInspectorReducer from './featureInspector/featureInspectorSlice';
import subscriptionsOverviewReducer from './subscriptionsOverview/subscriptionsOverviewSlice';

const dashboardsSlice = combineReducers({
  featureInspector: featureInspectorReducer,
  subscriptionsOverview: subscriptionsOverviewReducer,
});

export default dashboardsSlice;
