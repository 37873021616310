import { PlanFragment, PlanListFragment, PricingType, TrialPeriodUnits } from '@stigg-types/apiTypes';
import moment from 'moment-timezone';
import { calculateTrialEndDate, getDateRemainingDays } from '.';

export class TrialConfigPresentationModel {
  constructor(
    public state: {
      currentPlan?: PlanListFragment | PlanFragment;
      isTrialEnabled?: boolean;
      startDate: string;
      trialEndDate?: Date;
    },
    private controller: {
      setDuration: (value: number) => void;
      setUnits: (value: TrialPeriodUnits) => void;
      setEndDate?: (value: any) => void;
    },
  ) {}

  populateTrialSettings() {
    const { currentPlan, isTrialEnabled, startDate } = this.state;
    if (currentPlan) {
      const { defaultTrialConfig } = currentPlan;

      if (isTrialEnabled || this.hasDefaultTrialConfig()) {
        let durationInDays = 0;

        if (this.state.trialEndDate) {
          durationInDays = moment(this.state.trialEndDate).diff(startDate, 'days');
        } else {
          durationInDays = defaultTrialConfig?.duration || 30;

          if (defaultTrialConfig?.duration && defaultTrialConfig?.units === TrialPeriodUnits.Month) {
            durationInDays = moment().add(defaultTrialConfig?.duration, 'months').diff(startDate, 'days');
          }
        }

        this.controller.setDuration(durationInDays);
        if (this.controller.setEndDate) {
          this.controller.setEndDate(calculateTrialEndDate(this.state.startDate, durationInDays, TrialPeriodUnits.Day));
        }
      }
    }
  }

  cannotStartTrial() {
    return !this.state.currentPlan || this.planIsFree();
  }

  isTrialDisabled() {
    const isTrialDisabled = !this.state.currentPlan || this.planIsFree();

    if (this.state.trialEndDate) {
      const { isInRange } = getDateRemainingDays(this.state.trialEndDate);
      return isTrialDisabled || !isInRange;
    }

    return isTrialDisabled;
  }

  private planIsFree() {
    return this.state.currentPlan?.pricingType === PricingType.Free;
  }

  hasDefaultTrialConfig() {
    return !!this.state.currentPlan?.defaultTrialConfig;
  }
}
