import { useCallback, useState } from 'react';
import { t } from 'i18next';
import { AnimatePresence } from 'framer-motion';
import { Grid, GridFlex, FullScreenDialogLayout, Divider, Button, LoadingButton } from '@stigg-components';
import { AwsProduct, DimensionsMappingInput } from '@stigg-types/apiTypes';
import Loader from '../../../../components/Loader';
import { useAppDispatch } from '../../../../redux/store';
import { AWSImportWizardSideNav } from './AWSImportWizardSideNav';
import { SelectAWSProductStep } from './SelectAWSProductStep';
import { ConfigureAWSProductPlansStep } from './ConfigureAWSProductPlansStep';
import { AnimatedStep } from './AnimatedStep';
import { createOrUpdateAwsMarketplaceProductAction } from '../../../products/productsSlice';
import { generateRefIdFromName } from '../../../../components/DisplayNameTextField';
import { useAwsProducts, useAwsProductDimensions } from './hooks';

type AWSProductsImportWizardProps = {
  onClose: () => void;
};

export function AWSProductsImportWizard({ onClose }: AWSProductsImportWizardProps) {
  const dispatch = useAppDispatch();
  const [selectedProduct, setSelectedProduct] = useState<AwsProduct | null>(null);
  const [dimensionsConfiguration, setDimensionsConfiguration] = useState<DimensionsMappingInput[]>([]);
  const [isImportingAWSProduct, setIsImportingAWSProduct] = useState(false);
  const currentStep = selectedProduct ? 1 : 0;

  const { awsProducts, isLoadingAWSProducts } = useAwsProducts({ dispatch });

  const { awsProductDimensions, isLoadingAWSProductDimensions } = useAwsProductDimensions({
    dispatch,
    awsProduct: selectedProduct,
  });

  const handleOnDimensionsChange = useCallback(
    (plansDimensionConfiguration: DimensionsMappingInput[]) => {
      if (plansDimensionConfiguration.length === 0 && dimensionsConfiguration.length === 0) {
        return;
      }

      setDimensionsConfiguration(plansDimensionConfiguration);
    },
    [dimensionsConfiguration.length],
  );

  const handleBackClick = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setSelectedProduct(null);
    }
  };

  const handleImportClick = () => {
    if (!selectedProduct) {
      return;
    }

    const importAction = async () => {
      const refId =
        selectedProduct.stiggProductRefId ||
        `${generateRefIdFromName(selectedProduct.title, t('products.refIdPrefix'))}-aws`;

      await dispatch(
        createOrUpdateAwsMarketplaceProductAction({
          productId: selectedProduct.stiggProductId,
          refId,
          displayName: selectedProduct?.title,
          description: selectedProduct?.description,
          awsProductId: selectedProduct.productId,
          awsDimensionsMapping: dimensionsConfiguration,
        }),
      );

      setIsImportingAWSProduct(false);
    };

    setIsImportingAWSProduct(true);
    void importAction();
  };

  if (isLoadingAWSProducts || !awsProducts) {
    return <Loader />;
  }

  return (
    <FullScreenDialogLayout title={t('integrations.awsMarketplace.import.header')}>
      <Grid container height="100%" $fullWidth sx={{ overflow: 'auto' }}>
        <AWSImportWizardSideNav activeStep={currentStep} />
        <Grid
          container
          item
          flex="1"
          paddingX={2}
          justifyContent="center"
          sx={{ maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <GridFlex.Column pt="48px" height="100%" width={850}>
            <GridFlex.Column height="100%">
              <AnimatePresence initial={false} exitBeforeEnter>
                <AnimatedStep key={currentStep} currentStep={currentStep}>
                  {currentStep === 0 ? (
                    <SelectAWSProductStep
                      awsProducts={awsProducts}
                      onProductSelected={(awsProduct) => setSelectedProduct(awsProduct)}
                    />
                  ) : (
                    <ConfigureAWSProductPlansStep
                      awsProduct={selectedProduct!}
                      awsProductDimensions={awsProductDimensions || []}
                      isLoadingAWSProductDimensions={isLoadingAWSProductDimensions}
                      onChange={handleOnDimensionsChange}
                    />
                  )}
                </AnimatedStep>
              </AnimatePresence>
            </GridFlex.Column>

            <Divider paddingTop={4} />

            <GridFlex.RowSpaceBetween paddingTop={4} paddingBottom={6}>
              <Button sx={{ alignSelf: 'flex-start' }} onClick={handleBackClick}>
                Back
              </Button>
              {currentStep === 1 ? (
                <LoadingButton
                  disabled={!dimensionsConfiguration.length || isLoadingAWSProductDimensions}
                  variant="contained"
                  loadingPosition="start"
                  sx={{ alignSelf: 'flex-end' }}
                  loading={isImportingAWSProduct}
                  onClick={handleImportClick}>
                  Import
                </LoadingButton>
              ) : null}
            </GridFlex.RowSpaceBetween>
          </GridFlex.Column>
        </Grid>
      </Grid>
    </FullScreenDialogLayout>
  );
}
