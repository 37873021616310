import { gql } from '@apollo/client';
import { t } from 'i18next';
import { toNumber } from 'lodash';
import { FetchPlanByRefIdQuery, FetchPlanByRefIdQueryVariables, PlanFragment } from '@stigg-types/apiTypes';
import { ExecuteOperationOptions, executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';
import { PLAN_FRAGMENT } from './planFragment';

const FETCH_PLAN = gql`
  ${PLAN_FRAGMENT}
  query FetchPlanByRefId($input: GetPackageByRefIdInput!) {
    getPlanByRefId(input: $input) {
      ...PlanFragment
    }
  }
`;

type FetchPlanByRefIdProps = { refId: string; versionNumber?: string | number | null } & ExecuteOperationOptions;

async function fetchPlanByRefId(
  { refId, versionNumber }: FetchPlanByRefIdProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<PlanFragment | null | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input = {
        refId,
        versionNumber: toNumber(versionNumber),
        environmentId: accountReducer.currentEnvironmentId,
      };

      const res = await apolloClient.query<FetchPlanByRefIdQuery, FetchPlanByRefIdQueryVariables>({
        query: FETCH_PLAN,
        fetchPolicy: 'network-only',
        variables: { input },
      });

      return res.data?.getPlanByRefId;
    },
    {
      failureMessageHandler: () => t('plans.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchPlanByRefId };
