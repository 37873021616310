import { useState } from 'react';

export type StepsManagement = {
  isExpanded: (uuid: string) => boolean;
  setFocused: (uuid: string | undefined) => void;
  toggle: (uuid: string) => void;
};

export function useSubStepsState(initialFocused?: string): StepsManagement {
  const [focused, setFocused] = useState(initialFocused);
  const [overrides, setOverrides] = useState<{ [uuid: string]: boolean }>({});

  const isExpanded = (uuid: string) => overrides[uuid] ?? focused === uuid;
  const toggle = (uuid: string) =>
    setOverrides((prevState) => ({ ...prevState, [uuid]: !(prevState[uuid] ?? focused === uuid) }));

  return { isExpanded, setFocused, toggle };
}
