import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useBreadcrumbs } from '@stigg-common';
import { t } from 'i18next';
import { Text, ConfirmationDialog, Grid, GridFlex } from '@stigg-components';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { archiveCouponAction, fetchCouponByRefIdAction, resetCoupon, updateCouponAction } from '../../couponsSlice';
import Loader from '../../../../components/Loader';
import { EntityMetadataCard } from '../../../../components/entityMetadata/EntityMetadataCard';
import { CouponDetails } from './couponDetails/CouponDetails';
import { UpdateCouponDialog } from '../updateCoupon/UpdateCouponDialog';
import { UpdateCouponFormFields } from '../updateCoupon/UpdateCouponForm';

type ViewCouponRouteParams = {
  refId: string;
};

export default function CouponPage() {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const { refId } = useParams<ViewCouponRouteParams>();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const coupon = useSelector((state: RootState) => state.couponsReducer.coupon);
  const isLoading = useSelector((state: RootState) => state.couponsReducer.isLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(fetchCouponByRefIdAction({ refId, environmentId: currentEnvironmentId || '' }));

    return () => {
      dispatch(resetCoupon());
    };
  }, [dispatch, refId, currentEnvironmentId]);

  useBreadcrumbs({ to: '/coupons', title: t('breadCrumbs.coupons') }, { title: refId });

  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    if (coupon) {
      await dispatch(
        updateCouponAction({ refId, additionalMetaData: metadata, name: coupon.name, description: coupon.description }),
      );
    }
  };

  const onUpdate = async (values: UpdateCouponFormFields) => {
    await dispatch(updateCouponAction({ ...values, refId }));
    setEditDialogOpen(false);
  };

  const onEditClick = () => {
    setEditDialogOpen(true);
  };

  const onDeleteClick = () => {
    setArchiveDialogOpen(true);
  };

  const handleArchiveClose = async (shouldArchive: boolean) => {
    if (coupon && shouldArchive) {
      await dispatch(archiveCouponAction(coupon.refId));
    }
    setArchiveDialogOpen(false);
  };

  if (!coupon || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <GridFlex.Column container flexWrap="nowrap" flexDirection="column" rowSpacing={6}>
        <CouponDetails coupon={coupon} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />

        <Grid item>
          <EntityMetadataCard
            metaData={coupon.additionalMetaData}
            onSubmit={onSubmitSetMetaData}
            entityTypeName="coupon"
          />
        </Grid>
      </GridFlex.Column>
      <UpdateCouponDialog coupon={coupon} onUpdate={onUpdate} open={editDialogOpen} setOpen={setEditDialogOpen} />

      <ConfirmationDialog
        open={archiveDialogOpen}
        handleClose={handleArchiveClose}
        title={t('coupons.deleteDialogTitle')}
        content={
          <>
            <Text.B2>{t('coupons.dialogDeleteContent1')}</Text.B2>
            <Text.B2 mt={2}>{t('coupons.dialogDeleteContent2')}</Text.B2>
          </>
        }
        confirmationButtonText={t('coupons.deleteDialogConfirm')}
      />
    </>
  );
}
