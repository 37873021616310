import { useState } from 'react';
import { useNavigation } from '../../../navigation/useNavigation';
import InviteMembersDialog from './InviteMembersDialog';

type UseInviteMembersDialogResult = {
  shouldNavigateToAccountsMembersPageOnSubmit?: boolean;
};

export function useInviteMembersDialog({
  shouldNavigateToAccountsMembersPageOnSubmit,
}: UseInviteMembersDialogResult = {}) {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const { navigateTo } = useNavigation();

  const inviteMembersDialog = (
    <InviteMembersDialog
      isOpen={isInviteDialogOpen}
      onCancel={() => setIsInviteDialogOpen(false)}
      onSubmit={() => {
        setIsInviteDialogOpen(false);

        if (shouldNavigateToAccountsMembersPageOnSubmit) {
          navigateTo('/account/settings', { isGlobal: true, anchor: 'accountMembers' });
        }
      }}
    />
  );

  return { inviteMembersDialog, isInviteDialogOpen, setIsInviteDialogOpen };
}
