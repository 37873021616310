import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Divider, PageHeader, Grid, GridFlex, EmptyList, ConfirmationDialog } from '@stigg-components';
import { t } from 'i18next';
import { useSearchPlaceholder } from '@stigg-common';
import isEmpty from 'lodash/isEmpty';
import { Users } from 'react-feather';
import { Coupon } from '@stigg-types/apiTypes';
import { useNavigation } from '../../navigation/useNavigation';
import { useAppDispatch, RootState } from '../../../redux/store';
import createPageChangeFunc from '../../../components/table/gqlTableHelper';
import { archiveCouponAction, fetchCouponsAction, updateCouponAction } from '../couponsSlice';
import Search from '../../../components/Search';
import Table from '../../../components/table/Table';
import { headCells } from './headcells';
import { CreateCouponDialog } from './createCoupon/CreateCouponDialog';
import { UpdateCouponDialog } from './updateCoupon/UpdateCouponDialog';
import { UpdateCouponFormFields } from './updateCoupon/UpdateCouponForm';

function Coupons() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [search, setSearch] = useState('');
  const [pageNum, setPageNum] = useState(0);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const { pageInfo, totalCount, edges: coupons } = useSelector((state: RootState) => state.couponsReducer.coupons);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.couponsReducer.isLoading);
  const searchPlaceholder = useSearchPlaceholder({
    placeholder: t('coupons.searchPlaceholder'),
    searchParams: t('coupons.searchPlaceholderSearchParams'),
    withCRMIntegration: false,
  });

  const pageChangeFunc = createPageChangeFunc(dispatch, pageNum, pageInfo, setPageNum, fetchCouponsAction, {
    environmentId: currentEnvironmentId,
    search,
  });

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchCouponsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  const onCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const onEditClick = (coupon: Coupon) => {
    setCoupon(coupon);
    setEditDialogOpen(true);
  };

  const onDeleteClick = (coupon: Coupon) => {
    setCoupon(coupon);
    setArchiveDialogOpen(true);
  };

  const onUpdate = (values: UpdateCouponFormFields) => {
    if (coupon) {
      void dispatch(updateCouponAction({ ...values, refId: coupon.refId }));
      setEditDialogOpen(false);
    }
  };

  const onArchive = async (shouldArchive: boolean) => {
    if (coupon && shouldArchive) {
      await dispatch(archiveCouponAction(coupon.refId));
      await dispatch(
        fetchCouponsAction({
          environmentId: coupon.environmentId,
        }),
      );
    }
    setArchiveDialogOpen(false);
  };

  return (
    <>
      <PageHeader title={t('coupons.coupons')} buttonTitle={t('coupons.new')} buttonClick={onCreateClick} />
      <Divider mt={4} />
      <GridFlex.Column container mb={10}>
        <Grid my={5} maxWidth={400}>
          <Search
            variant="outlined"
            searchOnChange
            placeholder={searchPlaceholder}
            handleSearchFunc={(searchTerm) => {
              setSearch(searchTerm);
              if (searchTerm) {
                setPageNum(0);
              }
              void dispatch(
                fetchCouponsAction({
                  search: searchTerm,
                  environmentId: currentEnvironmentId || '',
                }),
              );
            }}
            searchedStr={search}
          />
        </Grid>

        {isEmpty(coupons) && !isLoading ? (
          <EmptyList
            title={!isEmpty(search) ? t('coupons.emptySearchText') : t('coupons.emptyStateText')}
            linkText={isEmpty(search) ? t('coupons.emptyStateTextActionLinkText') : ''}
            onLinkClick={onCreateClick}
            icon={Users}
          />
        ) : (
          <Table
            isLoading={isLoading}
            headCells={headCells(onEditClick, onDeleteClick)}
            label={t('coupons.table')}
            pageChangeFunc={pageChangeFunc}
            data={(coupons || []).map((item) => item.node)}
            pageNum={pageNum}
            totalCount={totalCount}
            onRowClick={(coupon: Coupon) => navigation.navigateTo(`/coupons/${coupon.refId}`)}
          />
        )}
      </GridFlex.Column>
      <CreateCouponDialog open={createDialogOpen} setOpen={setCreateDialogOpen} />
      {coupon && (
        <>
          <UpdateCouponDialog coupon={coupon} onUpdate={onUpdate} open={editDialogOpen} setOpen={setEditDialogOpen} />
          <ConfirmationDialog
            open={archiveDialogOpen}
            handleClose={onArchive}
            title={t('coupons.deleteDialogTitle')}
            content={
              <>
                <Text.B2>{t('coupons.dialogDeleteContent1')}</Text.B2>
                <Text.B2 mt={2}>{t('coupons.dialogDeleteContent2')}</Text.B2>
              </>
            }
            confirmationButtonText={t('coupons.deleteDialogConfirm')}
          />
        </>
      )}
    </>
  );
}

export default Coupons;
