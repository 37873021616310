import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ProductCreateInput,
  CreateProductMutation,
  CreateProductMutationVariables,
  Product,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';
import { fetchProductsAction } from '../productsSlice';

const CREATE_CUSTOMER = gql`
  mutation CreateProduct($input: CreateOneProductInput!) {
    createOneProduct(input: $input) {
      displayName
      id
      refId
    }
  }
`;

async function createProduct(
  productData: Omit<ProductCreateInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Product> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const product = {
        ...productData,
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<CreateProductMutation, CreateProductMutationVariables>({
        mutation: CREATE_CUSTOMER,
        variables: { input: { product } },
      });

      const newProduct = response.data?.createOneProduct;
      if (newProduct) {
        void dispatch(fetchProductsAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch: true }));
        dispatch(navigateTo(appRoutes.productPage(newProduct.refId)));
      }

      return newProduct;
    },
    {
      successMessage: t('products.api.successCreate'),
      failureMessageHandler: () => t('products.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('products.api.failDupProduct'),
      },
    },
    dispatch,
  );
}

export { createProduct };
