import { useEffect } from 'react';
import { Tabs, Text, Grid } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import Loader from '../../../components/Loader';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchAccountAction, fetchAccountMembersAction } from '../accountsSlice';
import { AccountLayout } from './AccountLayout';
import { BillingCustomerPortal } from './BillingCustomerPortal';
import { SettingsPageTabs } from '../types/settingsPageTabs';

function Settings() {
  const dispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const isLoading = useSelector((state: RootState) => state.accountReducer.isLoading);
  const { stiggForStiggWeb } = useFlags<FeatureFlags>();

  useEffect(() => {
    void dispatch(fetchAccountMembersAction({}));
  }, [dispatch]);

  useEffect(() => {
    void dispatch(fetchAccountAction());
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid wrap="nowrap" container flexDirection="column" rowSpacing={4}>
      <Text.H1 mb={6}>{t('accounts.pageTitle')}</Text.H1>

      {stiggForStiggWeb ? (
        <Tabs
          data={[
            {
              title: SettingsPageTabs.Account,
              content: <AccountLayout account={account} />,
            },
            {
              title: SettingsPageTabs.Billing,
              content: <BillingCustomerPortal />,
            },
          ]}
        />
      ) : (
        <AccountLayout account={account} />
      )}
    </Grid>
  );
}

export default Settings;
