import { useState } from 'react';
import { CustomerListFragment } from '@stigg-types/apiTypes';
import { GridFlex, LongText, HighlightText, Text, Box, BoundedSearchableSelect } from '@stigg-components';
import { t } from 'i18next';
import { useGetCustomers } from '../customers/queries/useGetCustomers';

function renderSelectedItem(customer: CustomerListFragment) {
  const { name, customerId } = customer;
  return (
    <GridFlex.RowCenter>
      <Text.B2 mr={1}>{t('usageEvents.filterCustomerLabel')}</Text.B2>
      <LongText variant="body2" color="primary">
        {name ? `${name} (${customerId})` : customerId}
      </LongText>
    </GridFlex.RowCenter>
  );
}

function renderItem(customer: CustomerListFragment, search?: string) {
  const { customerId, name } = customer;

  return (
    <GridFlex.Column>
      <LongText variant="body2" color="primary">
        <HighlightText highlight={search} text={name || ''} />
      </LongText>
      <LongText variant="body2" color="secondary">
        <HighlightText highlight={search} text={customerId} />
      </LongText>
    </GridFlex.Column>
  );
}

type EventsCustomerFilterProps = {
  onChange: (customer: CustomerListFragment) => void;
  selectedCustomer: CustomerListFragment | undefined;
};

export function UsageEventsCustomerFilter({ onChange, selectedCustomer }: EventsCustomerFilterProps) {
  const [search, setSearch] = useState<string | null>(null);
  const { isPending: isLoadingCustomers, data } = useGetCustomers({
    search,
  });
  const { customers, totalCount: totalCustomersCount } = data || {};

  return (
    <Box width={400} ml={4}>
      <BoundedSearchableSelect
        placeholder={t('usageEvents.searchCustomer')}
        maxWidth={400}
        height={36}
        onSearch={setSearch}
        isSearching={isLoadingCustomers}
        totalItems={totalCustomersCount}
        items={customers || []}
        itemSerializer={(item: CustomerListFragment) => item.customerId}
        onChange={onChange}
        value={selectedCustomer}
        renderItem={renderItem}
        renderSelectedItem={renderSelectedItem}
      />
    </Box>
  );
}
