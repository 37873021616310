import { gql } from '@apollo/client';
import { t } from 'i18next';
import { RemoveMemberMutation, RemoveMemberMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const REMOVE_MEMBER = gql`
  mutation RemoveMemberMutation($memberId: String!) {
    removeMember(memberId: $memberId)
  }
`;

async function removeMember(memberId: string, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<RemoveMemberMutation, RemoveMemberMutationVariables>({
        mutation: REMOVE_MEMBER,
        variables: { memberId },
      });

      return { id: memberId };
    },
    {
      successMessage: t('accounts.api.successMemberRemove'),
      failureMessageHandler: () => t('accounts.api.failMemberRemove'),
    },
    dispatch,
  );
}

export { removeMember };
