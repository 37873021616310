import { EntitlementBehavior, EntitlementResetPeriod, Feature } from '@stigg-types/apiTypes';
import { ResetPeriodConfiguration } from '@stigg-common/types';

export type EntitlementFullFields = {
  uuid: string; // used for front-end only
  id?: string;
  feature: Feature;
  isConfirmed: boolean;
  description?: string | null;
  usageLimit?: number | null;
  hasUnlimitedUsage?: boolean | null;
  hasSoftLimit?: boolean | null;
  isCustom?: boolean | null;
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
  behavior?: EntitlementBehavior | null;
};

export type EntitlementFields = Omit<EntitlementFullFields, 'feature'> & {
  feature: Feature | null | undefined;
};

export type EntitlementFormFields = {
  entitlements: EntitlementFields[];
};

export function getConfirmedEntitlements(list: EntitlementFields[]): EntitlementFullFields[] {
  const result: EntitlementFullFields[] = [];

  for (const item of list) {
    const { feature } = item;

    if (item.isConfirmed && feature) {
      result.push({
        ...item,
        feature,
      });
    }
  }

  return result;
}
