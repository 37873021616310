import { t } from 'i18next';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import sortBy from 'lodash/sortBy';
import { GridFlex, Divider, Text, Alert, Icon } from '@stigg-components';
import { formatDate } from '@stigg-common';
import { InvoiceLineItemType, PreviewNextInvoiceFragment, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import {
  ChargesBreakdown,
  ChargesList,
  SubTotalSection,
  DiscountsSection,
  TaxesSection,
  TotalSection,
  CreditsSection,
  AmountDueSection,
} from '.';

type InvoiceBreakdownProps = {
  invoice: PreviewNextInvoiceFragment;
  subscription?: SubscriptionDataFragment;
};

export function InvoiceBreakdown({ invoice, subscription }: InvoiceBreakdownProps) {
  const { lines, total, amountDue, credits, discount, discountDetails, tax, taxDetails } = invoice;

  const linesByPeriod = groupBy(lines, ({ period }) => {
    const withYear = new Date(period?.start)?.getFullYear() !== new Date(period?.end)?.getFullYear();
    return `${formatDate(period?.start, { formatType: 'shortDate', withYear })} - ${formatDate(period?.end, {
      formatType: 'shortDate',
      withYear: true,
    })}`;
  });
  const sortedPeriods = sortBy(toPairs(linesByPeriod), ([_, lines]) => lines?.[0].period?.start);

  return (
    <GridFlex.Column container>
      <GridFlex.Column container gap={6} marginTop={4}>
        {map(sortedPeriods, ([dateRange, periodLines]) => {
          const minSpendItems =
            periodLines?.filter((line) => line.type === InvoiceLineItemType.MinimumSpendAdjustmentCharge) || [];

          return (
            <GridFlex.Column container>
              <Text.B2 $bold mb={4}>
                {dateRange}
              </Text.B2>
              <ChargesBreakdown lines={periodLines} plan={subscription?.plan} />

              <ChargesList items={minSpendItems} marginTop={4} />
            </GridFlex.Column>
          );
        })}
      </GridFlex.Column>

      <Divider my={4} />

      <SubTotalSection subTotal={invoice.subTotal} />

      <DiscountsSection discount={discount} discountDetails={discountDetails} />

      <Divider mt={4} />

      <TaxesSection tax={tax} taxDetails={taxDetails} />

      <TotalSection total={total} />

      <CreditsSection credits={credits} />

      <AmountDueSection amountDue={amountDue} />

      <Alert
        severity="info"
        mt={4}
        mb={8}
        icon={<Icon icon="InfoOutlined" color="default" />}
        sx={(theme) => ({
          backgroundColor: theme.isLightTheme
            ? `${theme.itamar.palette.background.default} !important`
            : `${theme.itamar.palette.background.lightBackground2} !important`,
        })}>
        <Text.B2 color="primary">{t('subscriptions.previewNextBill.breakdownAlert')}</Text.B2>
      </Alert>
    </GridFlex.Column>
  );
}
