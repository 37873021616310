import { BillingModel, PriceFragment } from '@stigg-types/apiTypes';

export const isHiddenFromPaywall = (pricing: PriceFragment) => {
  const noPriceLimits =
    (!pricing.minUnitQuantity || (pricing.minUnitQuantity && pricing.minUnitQuantity === 1)) &&
    !pricing.maxUnitQuantity;
  const isPerUnitWithoutLimits = pricing.billingModel === BillingModel.PerUnit && !!noPriceLimits;

  return pricing.billingModel === BillingModel.UsageBased || isPerUnitWithoutLimits;
};
