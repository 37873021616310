import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  Account,
  UpdateAccountInput,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { fetchAccountAction } from '../accountsSlice';

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      displayName
      timezone
    }
  }
`;

async function updateAccount(
  updatedAccountInput: UpdateAccountInput,
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Account> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateAccountMutation, UpdateAccountMutationVariables>({
        mutation: UPDATE_ACCOUNT,
        variables: { input: updatedAccountInput },
      });

      await dispatch(fetchAccountAction());
      return response.data?.updateAccount;
    },
    {
      successMessage: t('accounts.api.successAccountUpdate'),
      failureMessageHandler: () => t('accounts.api.failAccountUpdate'),
    },
    dispatch,
  );
}

export { updateAccount };
