import * as Yup from 'yup';
import { t } from 'i18next';
import { Form } from '@stigg-components';
import { refIdValidation } from '@stigg-common';
import React from 'react';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch } from '../../../../redux/store';
import { createProductAction } from '../../productsSlice';
import { createDisplayNameTextFieldHandleChange } from '../../../../components/DisplayNameTextField';
import { ProductSubscriptionType } from '../productPage/ProductSubscriptionType';

type CreateProductFormFields = {
  refId: string;
  displayName: string;
  description: string;
  multipleSubscriptions: boolean;
};

const initialValues = {
  refId: '',
  displayName: '',
  description: '',
  multipleSubscriptions: false,
} as CreateProductFormFields;

const validationSchema = () =>
  Yup.object().shape({
    refId: refIdValidation(),
    displayName: Yup.string().required(t('products.yup.required')),
    multipleSubscriptions: Yup.boolean(),
  });

type CreateProductFormProps = {
  isAwsProduct?: boolean;
  onCancel: () => void;
};

export default function CreateProductForm({ isAwsProduct, onCancel }: CreateProductFormProps) {
  const { multipleSubscriptionsUi: multipleSubscriptionsUiEnabled } = useFlags<FeatureFlags>();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: CreateProductFormFields) => {
    await dispatch(createProductAction(values));
    onCancel();
  };

  return (
    <Form
      disableSaveOnNoChanges
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitButtonText={t('products.saveButton')}
      validationSchema={validationSchema()}
      initialValues={initialValues}
      fields={[
        {
          type: 'text',
          id: 'displayName',
          label: t('products.nameForm'),
          handleChange: createDisplayNameTextFieldHandleChange(t('products.refIdPrefix')),
        },
        {
          type: 'text',
          id: 'refId',
          label: t('products.refId'),
          captionText: t('products.productIdText'),
        },
        {
          type: 'text',
          id: 'description',
          label: t('products.descriptionForm'),
          optional: true,
        },
        {
          type: 'custom',
          hide: () => !multipleSubscriptionsUiEnabled,
          render: ({ values, setFieldValue }) => (
            <ProductSubscriptionType
              isAwsProduct={isAwsProduct}
              value={values.multipleSubscriptions}
              updateValue={(newValue: boolean) => setFieldValue('multipleSubscriptions', newValue)}
            />
          ),
        },
      ]}
    />
  );
}
