import styled from 'styled-components/macro';
import { Text } from '../Typography';

export const DottedTextB2 = styled(Text.B2)`
  display: inline;
  width: fit-content;
  transition: 0.3s;
  border-bottom: 1px dotted;
  border-color: rgba(148, 161, 193, 0.6);

  &:hover {
    border-color: ${({ theme }) => theme.itamar.palette.other.standardInputLine};
  }
`;
