import { t } from 'i18next';
import { Dialog } from '../../../../components/Dialog';
import CreateCustomerForm from './CreateCustomerForm';

export function CustomerCreateDialog({ open, setOpen }: any) {
  return (
    <Dialog
      content={<CreateCustomerForm onCancel={() => setOpen(false)} />}
      titleText={t('customers.createDialogTitle')}
      isOpen={open}
      onCancel={() => setOpen(false)}
      aria-labelledby="edit-customer-dialog"
      width={650}
    />
  );
}
