import { Add as AddIcon } from '@mui/icons-material';
import { Link, Grid, GridFlex, Text, PackageChip, LocalDate, Icon, Button } from '@stigg-components';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { ProductAddonFragment, ProductFragment } from '@stigg-types/apiTypes';
import { EmptyCardContent } from '../../../../components/EmptyCardContent';
import Table, { HeadCell } from '../../../../components/table/Table';
import { Navigation, useNavigation } from '../../../navigation/useNavigation';

const createHeadCells = (navigation: Navigation): Array<HeadCell<ProductAddonFragment, any>> => [
  {
    id: 'id',
    alignment: 'left',
    label: t('addons.addonId'),
    render: (entity: ProductAddonFragment) => (
      <Link
        variant="body2"
        onClick={() => {
          navigation.navigateTo(`addons/${entity.refId}`);
        }}>
        {entity.refId}
      </Link>
    ),
  },
  {
    id: 'name',
    alignment: 'left',
    label: t('addons.name'),
    render: (entity: ProductAddonFragment) => <Text.B2>{entity.displayName}</Text.B2>,
  },
  {
    id: 'addon_status',
    alignment: 'left',
    label: t('addons.status'),
    render: (entity) => <PackageChip hasPointer status={entity.status} />,
  },
  {
    id: 'lastUpdated',
    alignment: 'left',
    label: t('addons.updatedAt'),
    render: (entity) => <LocalDate date={entity.updatedAt} formatOptions={{ withTime: true }} />,
  },
];

export function AddonsTitle() {
  return (
    <GridFlex.RowCenter>
      <Icon icon="PlusSquare" type="reactFeather" color="default" size={20} />
      <Text.H6 ml={2}>{t('products.addons')}</Text.H6>
    </GridFlex.RowCenter>
  );
}

type ProductAddonsProps = {
  product: ProductFragment;
};

export function ProductAddons({ product }: ProductAddonsProps) {
  const addons = product.addons || ([] as ProductAddonFragment[]);
  const navigation = useNavigation();

  const navigateToAddonPlan = () => {
    const createPlanLink = `/addons?create=true&productId=${product.id}`;
    navigation.navigateTo(createPlanLink);
  };

  return (
    <>
      <Grid container justifyContent="space-between" my={8}>
        <AddonsTitle />

        <Button color="primary" $outlined onClick={navigateToAddonPlan}>
          <AddIcon />
          {t('products.addAddon')}
        </Button>
      </Grid>

      {isEmpty(addons) ? (
        <EmptyCardContent>
          <Text.B2>{t('products.noPackage', { packageType: 'add-on' })}</Text.B2>
          <Link variant="body2" ml={1} onClick={navigateToAddonPlan}>
            {t('products.noPackageCta', { packageType: 'add-on' })}
          </Link>
        </EmptyCardContent>
      ) : (
        <Table rowHeight={65} label={t('products.addons')} headCells={createHeadCells(navigation)} data={addons} />
      )}
    </>
  );
}
