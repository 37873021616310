import { UsageHistoryFragment, UsageMeasurementPoint, UsageMeasurementPointFragment } from '@stigg-types/apiTypes';

export type Series = {
  label: string;
  data: UsageMeasurementPoint[];
};

function enhancePointDates(points: UsageMeasurementPointFragment[]) {
  return points.map((x) => ({ ...x, date: new Date(x.date) }));
}

export function getChartData(usageHistory: UsageHistoryFragment): Series[] {
  const { usageMeasurements, groups } = usageHistory;

  if (groups) {
    return groups.map(({ groupInfo, usageMeasurements }) => ({
      label: groupInfo.map(({ value }) => value || 'none').join(' / '),
      data: enhancePointDates(usageMeasurements),
    }));
  }

  return [
    {
      label: 'Total',
      data: enhancePointDates(usageMeasurements),
    },
  ];
}

export function getResetPointDates(series: Series[]): Set<Date> {
  return new Set(
    series
      .flatMap(({ data }) => data)
      .filter(({ isResetPoint }) => isResetPoint)
      .map(({ date }) => date),
  );
}

export function getMaxDataPointValue(series: Series[], usageLimit?: number | null): number {
  const allValues = series.flatMap(({ data }) => data).map(({ value }) => value);
  const maxValue = Math.max(...allValues);
  return maxValue || usageLimit || 0;
}
