import { useMemo, useEffect } from 'react';
import { ExternalLink, Alert, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Lottie, { Options as LottieOptions } from 'react-lottie';
import { useTheme } from '@mui/material/styles';
import ImportProducts from '@assets/images/integrations/import-overview.png';
import { IntegrationFragment, StripeCredentialsFragment } from '@stigg-types/apiTypes';
import { Optional } from '../../../../../types/primitives';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { fetchProductsAction } from '../../../../products/productsSlice';
import { useImportContext } from '../hooks/useStripeWizardImportContext';

import darkAnimationData from '../../../../../assets/animations/import_product_catalog_overview.dark.json';

export function OverviewStep({ integration }: { integration: IntegrationFragment }) {
  const theme = useTheme();
  const credentials = integration.credentials as Optional<StripeCredentialsFragment>;
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const stiggProducts = useSelector((state: RootState) => state.productReducer.products);
  const importContext = useImportContext();
  const products = useMemo(() => importContext?.productsData.products || [], [importContext]);
  const dispatch = useAppDispatch();

  const { accountDisplayName, isTestMode } = credentials || {};

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchProductsAction({ environmentId: currentEnvironmentId }));
    }
  }, [dispatch, currentEnvironmentId]);

  useEffect(() => {
    if (!isEmpty(stiggProducts)) {
      if (importContext?.setSelectedProduct) {
        importContext.setSelectedProduct(stiggProducts[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, stiggProducts]);

  const animationOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: darkAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GridFlex.Column>
      <Text.H3 mb={1}>{t('integrations.overviewStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={12}>
        {t('integrations.overviewStepSubtitle')}
      </Text.B2>

      {theme.isLightTheme ? (
        <img width="100%" src={ImportProducts} alt="import products" />
      ) : (
        <Lottie options={animationOptions} isClickToPauseDisabled width="100%" />
      )}

      <Alert severity="info" mt={4} mb={4}>
        <Text.B2 $bold mb={1}>
          {t('integrations.stripeImportWarningTitle')}
        </Text.B2>
        <Text.B2 mb={1} color="secondary">
          {t('integrations.stripeImportWarningContent', {
            stripeAccountName: `${accountDisplayName} ${isTestMode ? '(Test)' : ''}`,
          })}
        </Text.B2>
        <ExternalLink label={t('sharedComponents.learnMore')} url={t('integrations.importOverviewLink')} />
      </Alert>
    </GridFlex.Column>
  );
}
