import * as FullStory from '@fullstory/browser';
import isEmpty from 'lodash/isEmpty';
import { UserFragment } from '@stigg-types/apiTypes';
import config from '../../env.config';

function shouldInitFullstory(user: UserFragment) {
  return !config.devMode && !isEmpty(config.fullstoryOrgId) && user.email && !user.email.endsWith('@stigg.io');
}

export function initFullstory(user: UserFragment) {
  if (shouldInitFullstory(user)) {
    FullStory.init({ devMode: config.devMode, orgId: config.fullstoryOrgId });
    FullStory.identify(user?.id, { email: user.email || '', displayName: user.name || '' });
  }
}
