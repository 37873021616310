import * as Yup from 'yup';
import { AggregationFunction, ConditionOperation, FeatureStatus, FeatureType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { refIdValidation } from '@stigg-common';
import { ValidationError } from 'yup';
import isEmpty from 'lodash/isEmpty';
import { ReportUsageType } from './FeatureTypeSelectionBox';
import { DoesFeatureExist } from '../../queries/validateFeatureRefId';

const filterValidationSchema = () =>
  Yup.object().shape({
    conditions: Yup.array()
      .of(
        Yup.object().shape({
          field: Yup.string().required(t('features.yup.required')),
          operation: Yup.mixed<ConditionOperation>()
            .oneOf(Object.values(ConditionOperation))
            .required(t('features.yup.required')),
          value: Yup.string().when('operation', {
            is: (operation: ConditionOperation) =>
              operation === ConditionOperation.IsNull || operation === ConditionOperation.IsNotNull,
            then: Yup.string().nullable().notRequired(),
            otherwise: Yup.string().required(t('features.yup.required')),
          }),
        }),
      )
      .required(t('features.yup.required')),
  });

export const validationSchema = (
  isFiltersOpen: boolean,
  isIdUnique: (refId: string | undefined) => Promise<DoesFeatureExist> = () =>
    Promise.resolve({ featureExists: false }),
  setIsRefIdFieldOpen?: (value: ((prevState: boolean) => boolean) | boolean) => void,
) =>
  Yup.object().shape({
    displayName: Yup.string().required(t('features.yup.required')),
    refId: refIdValidation()
      .required(t('features.yup.required'))
      .test('refIdUnique', '', async (value: string | undefined) => {
        const isUniqueResult = await isIdUnique(value);
        const isUnique = !isUniqueResult.featureExists;
        if (!isEmpty(value) && !isUnique) {
          if (setIsRefIdFieldOpen) {
            setIsRefIdFieldOpen(true);
          }
          const existingFeatureIsArchived = isUniqueResult.featureStatus === FeatureStatus.Suspended;
          const errorMessage = existingFeatureIsArchived
            ? t('features.yup.refIdNotUniqueArchived')
            : t('features.yup.refIdNotUnique');
          return new ValidationError(errorMessage);
        }
        return true;
      }),
    featureType: Yup.mixed<FeatureType>().oneOf(Object.values(FeatureType)).required(t('features.yup.required')),
    description: Yup.string().nullable(true),
    featureUnits: Yup.string().when('featureType', {
      is: FeatureType.Number,
      then: Yup.string().required(t('features.yup.required')),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    featureUnitsPlural: Yup.string().when('featureType', {
      is: FeatureType.Number,
      then: Yup.string().required(t('features.yup.required')),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    transformedUnitsPlural: Yup.string().when(['featureType', 'isUnitTransformationOpen'], {
      is: (featureType: FeatureType, isUnitTransformationOpen: boolean) =>
        isUnitTransformationOpen && featureType === FeatureType.Number,
      then: Yup.string().required(t('features.yup.required')),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    transformedUnits: Yup.string().when(['featureType', 'isUnitTransformationOpen'], {
      is: (featureType: FeatureType, isUnitTransformationOpen: boolean) =>
        isUnitTransformationOpen && featureType === FeatureType.Number,
      then: Yup.string().required(t('features.yup.required')),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    divideBy: Yup.number().when(['featureType', 'isUnitTransformationOpen'], {
      is: (featureType: FeatureType, isUnitTransformationOpen: boolean) =>
        isUnitTransformationOpen && featureType === FeatureType.Number,
      then: Yup.number().positive().integer().required(t('features.yup.required')),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    filters: Yup.array().when('reportUsageType', {
      is: (reportUsageType: ReportUsageType) => reportUsageType === ReportUsageType.REPORT_EVENTS && isFiltersOpen,
      then: Yup.array().of(filterValidationSchema()).required(t('features.yup.required')),
      otherwise: Yup.array().nullable().notRequired(),
    }),
    aggregation: Yup.object().when('reportUsageType', {
      is: (reportUsageType: ReportUsageType) => reportUsageType === ReportUsageType.REPORT_EVENTS && isFiltersOpen,
      then: Yup.object()
        .shape({
          function: Yup.mixed<AggregationFunction>()
            .oneOf(Object.values(AggregationFunction))
            .required(t('features.yup.required')),
          field: Yup.string().when('function', {
            is: (aggregationFunction: AggregationFunction) => aggregationFunction === AggregationFunction.Count,
            then: Yup.string().nullable().notRequired(),
            otherwise: Yup.string().required(t('features.yup.required')),
          }),
        })
        .required(t('features.yup.required')),
      otherwise: Yup.object().nullable().notRequired(),
    }),
  });
