import { Divider, Grid, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { WidgetCard } from './WidgetCard';
import { useWidgetsData } from './useWidgetsData';
import { ClientSideSecurityAlert } from './components/ClientSideSecurityAlert';

function WidgetsPage() {
  const widgets = useWidgetsData();
  return (
    <>
      <GridFlex.RowSpaceBetween container spacing={12}>
        <GridFlex.Column item xs={12}>
          <Text.H1 gutterBottom display="inline" color="primary">
            {t('widgets.title')}
          </Text.H1>
          <Text.B2 mt={-2}>{t('widgets.subtitle')}</Text.B2>
        </GridFlex.Column>
      </GridFlex.RowSpaceBetween>

      <Divider my={6} />
      <ClientSideSecurityAlert sx={{ mb: 6 }} />
      <Grid item container columnSpacing={4} rowSpacing={6}>
        {widgets.map((widget) => (
          <Grid key={widget.widgetIdentifier} item xs={12} md={6} lg={6} xl={4}>
            <WidgetCard widget={widget} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default WidgetsPage;
