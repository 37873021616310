import { t } from 'i18next';
import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type SubTotalSectionProps = Pick<PreviewNextInvoiceFragment, 'subTotal'> & {
  marginTop?: number;
};

export function SubTotalSection({ subTotal, marginTop }: SubTotalSectionProps) {
  return (
    <>
      <ChargeRow
        description={t('subscriptions.breakdown.subTotal')}
        charge={subTotal}
        bold
        sx={marginTop ? { marginTop } : undefined}
      />
    </>
  );
}
