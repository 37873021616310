import * as Sentry from '@sentry/react';
import config from '../env.config';

export default () => {
  Sentry.init({
    dsn: config.devMode ? '' : config.sentryDSN,
    environment: config.envName,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.2,
    normalizeDepth: 6,
    release: `stigg-web@${process.env.REACT_APP_VERSION}`,
  });
};
