import { Query } from '@cubejs-client/core';
import { PackageEntitlement } from '@stigg-types/apiTypes';
import { composeFilterForResetPeriod } from './cubeFilters.helper';

export function composeTopCustomersUsageQuery(
  environmentId: string,
  featureRefId: string,
  planRefId: string,
  entitlement: PackageEntitlement | undefined,
  showUncompletedPeriod: boolean,
  limit: number,
): Query {
  return {
    measures: ['FeatureUsageDistribution.totalUsage'],
    dimensions: ['Customer.refId', 'Customer.name'],
    filters: [
      {
        member: 'FeatureUsageDistribution.environmentId',
        operator: 'equals',
        values: [environmentId],
      },
      {
        member: 'FeatureUsageDistribution.featureRefId',
        operator: 'equals',
        values: [featureRefId],
      },
      {
        member: 'FeatureUsageDistribution.planRefId',
        operator: 'equals',
        values: [planRefId],
      },
      ...composeFilterForResetPeriod('FeatureUsageDistribution', entitlement, showUncompletedPeriod),
    ],
    order: {
      'FeatureUsageDistribution.totalUsage': 'desc',
    },
    limit,
  };
}
