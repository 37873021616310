import {
  BoundedSearchableSelect,
  FormControl,
  FormRenderProps,
  OutlinedFormFieldLabel,
  SearchableSelectOption,
} from '@stigg-components';
import { useEffect, useState } from 'react';
import { SubscriptionFormFields } from '../modules/customers/components/customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm.types';

export type DynamicDataSearchableOptions = SearchableSelectOption & {
  onOptionSelected?: (props: FormRenderProps<SubscriptionFormFields>) => void;
};

export type DynamicSearchDataLoaderOutput = {
  isLoading: boolean;
  data: DynamicDataSearchableOptions[] | undefined;
  totalItems?: number;
};

export type DynamicSearchDataLoader = (search: string) => DynamicSearchDataLoaderOutput;

export type DynamicDataSearchableSelectProps = {
  label?: string;
  useDataLoader: DynamicSearchDataLoader;
  selectedOptionId: string | undefined;
  placeholder?: string;
  onChange: (option: DynamicDataSearchableOptions) => void;
  dataTestId?: string;
  formRenderProps: FormRenderProps<any>;
};

export function DynamicDataSearchableSelect({
  label,
  placeholder,
  useDataLoader,
  selectedOptionId,
  dataTestId,
  formRenderProps,
  onChange,
}: DynamicDataSearchableSelectProps) {
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState<DynamicDataSearchableOptions | undefined>(undefined);

  const { isLoading, data, totalItems } = useDataLoader(search);

  useEffect(() => {
    if (selectedOptionId) {
      setSelectedOption(data?.find((option) => option.id === selectedOptionId));
    }
  }, [data, selectedOptionId]);

  const onChangeFunction = (option: DynamicDataSearchableOptions) => {
    if (option.onOptionSelected) {
      return option.onOptionSelected(formRenderProps);
    }
    return onChange(option);
  };

  return (
    <FormControl sx={{ display: 'inline-flex' }} variant="outlined" fullWidth>
      <OutlinedFormFieldLabel label={label} />
      <BoundedSearchableSelect
        onSearch={setSearch}
        isSearching={isLoading}
        totalItems={totalItems || data?.length || 0}
        placeholder={placeholder}
        value={selectedOption}
        items={data || []}
        itemSerializer={({ id }) => id}
        onChange={onChangeFunction}
        renderItem={({ render, isDisabled }) => render(search, isDisabled)}
        renderSelectedItem={({ renderSelected, render }) => (renderSelected ? renderSelected() : render())}
        isOptionDisabled={({ isDisabled }) => !!isDisabled}
        dataTestId={dataTestId}
      />
    </FormControl>
  );
}
