import { t } from 'i18next';
import { Text } from '@stigg-components';
import { BillingPeriod } from '@stigg-types/apiTypes';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { formatPrice } from '../../../../utils/priceFormatUtils';
import { MinimumSpend } from '../SetPriceWizardForm.types';
import { DEFAULT_CURRENCY } from '../../../currency/currencyUtils';

const formatSummaryPrice = (minimumSpend: MinimumSpend, billingPeriod: BillingPeriod) => {
  const amount = minimumSpend.minimums[billingPeriod];
  if (!amount) {
    return null;
  }

  return formatPrice({ amount, currency: DEFAULT_CURRENCY }, billingPeriod, { shortFormat: true });
};

const getMinimumSpendSummary = (minimumSpend: MinimumSpend, billingPeriods: BillingPeriod[]) => {
  const monthlyText = t('pricing.billingPeriodDescriptionsNew.MONTHLY');
  const annuallyText = t('pricing.billingPeriodDescriptionsNew.ANNUALLY');

  if (!minimumSpend.enabled) {
    return t('pricing.minimumSpend.summary_zero');
  }

  if (Object.keys(minimumSpend).length === 2 && billingPeriods.length === 2) {
    return `${monthlyText} and ${annuallyText}`;
  }
  if (minimumSpend.minimums?.MONTHLY && billingPeriods.includes(BillingPeriod.Monthly)) {
    return `${monthlyText}: ${formatSummaryPrice(minimumSpend, BillingPeriod.Monthly)}`;
  }
  if (minimumSpend.minimums?.ANNUALLY && billingPeriods.includes(BillingPeriod.Annually)) {
    return `${annuallyText}: ${formatSummaryPrice(minimumSpend, BillingPeriod.Annually)}`;
  }
  return t('pricing.minimumSpend.summary_zero');
};

export function MinimumSpendSummary() {
  const {
    formRenderProps: {
      values: { minimumSpend, billingPeriods },
    },
  } = useSetPriceWizardFormContext();

  return <Text.B2>{getMinimumSpendSummary(minimumSpend, billingPeriods)}</Text.B2>;
}
