import { Link, Text } from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import React from 'react';
import { useNavigation } from '../../../../navigation/useNavigation';

export function CantEditCustomerJourneyActiveExperiment({ productRefId }: { productRefId: string }) {
  const navigation = useNavigation();

  return (
    <Text.B2>
      <Trans
        i18nKey="experiments.preventEdit.customerJourney"
        t={t}
        components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.experimentPage(productRefId))} />]}
      />
    </Text.B2>
  );
}
