// eslint-disable-next-line no-restricted-imports
import { FormControlLabelProps, Radio as MuiRadio } from '@mui/material';
import React from 'react';
import { RadioProps as MuiRadioProps } from '@mui/material/Radio/Radio';
import { isString } from 'lodash';
import { styled } from '@stigg-theme';
import { InformationTooltip, Text } from '../index';
import { TooltipFields } from '../InformationTooltip';
import { StyledFormControlLabel } from './StyledFormControlLabel';

const StyledRadio = styled(MuiRadio)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.itamar.palette.action.disabled : theme.itamar.palette.action.active};
`;

export type RadioProps = Pick<MuiRadioProps, 'value' | 'color' | 'disabled'> & {
  label: string | React.ReactNode;
  height?: number;
  selected: boolean;
  tooltip?: TooltipFields;
  hideControl?: boolean;
  disableFlex?: boolean;
  dataTestId?: string;
  sx?: FormControlLabelProps['sx'];
  labelPlacement?: FormControlLabelProps['labelPlacement'];
};
export function RadioItem({
  value,
  selected,
  color,
  label,
  disabled,
  tooltip,
  height,
  hideControl,
  disableFlex,
  dataTestId,
  sx,
  labelPlacement,
}: RadioProps) {
  return (
    <InformationTooltip
      arrow
      placement={tooltip?.placement || 'right'}
      title={tooltip?.title || ''}
      $maxWidth={tooltip?.maxWidth}
      PopperProps={{
        onClick: (e) => disabled && e.stopPropagation(),
      }}>
      <StyledFormControlLabel
        $selected={selected}
        $disabled={disabled}
        $height={height}
        $disableFlex={disableFlex}
        value={value}
        disabled={disabled}
        data-testId={dataTestId}
        sx={sx}
        control={
          <StyledRadio
            color={color}
            $disabled={disabled}
            sx={{ cursor: disabled ? 'not-allowed' : undefined, display: hideControl ? 'none' : undefined }}
          />
        }
        label={isString(label) ? <Text.B2 color={disabled ? 'disabled' : 'primary'}>{label}</Text.B2> : label}
        labelPlacement={labelPlacement}
      />
    </InformationTooltip>
  );
}
