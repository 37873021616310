import { GridFlex, RadioItem, RadioGroup, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { PricingType } from '@stigg-types/apiTypes';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getPricingTypesList } from './PriceTypeList';
import { getPackageTypeDisplay } from '../../../../../common/getPackageTypeDisplay';

export function PriceTypeStep() {
  const {
    aPackage,
    disabledPricingTypes,
    formRenderProps: { setFieldValue, values },
  } = useSetPriceWizardFormContext();

  return (
    <>
      <Text.B2 color="secondary" mb={6}>
        {t('pricing.pricingTypeLabel', { packageType: getPackageTypeDisplay(aPackage) })}
      </Text.B2>

      <RadioGroup
        value={values.pricingType}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('pricingType', (event.target as HTMLInputElement).value as PricingType)
        }>
        <GridFlex.Column gap={4}>
          {getPricingTypesList(aPackage).map(({ value, icon, title, subtitle }) => (
            <RadioItem
              key={value}
              value={value}
              selected={values.pricingType === value}
              hideControl
              disabled={disabledPricingTypes.has(value)}
              tooltip={disabledPricingTypes.get(value)}
              dataTestId={`pricing-type-${value}`}
              label={
                <GridFlex.RowCenter gap={4} height={77}>
                  {icon}
                  <GridFlex.Column gap={1}>
                    <Text.H6>{title}</Text.H6>
                    <Text.B2 color="secondary">{subtitle}</Text.B2>
                  </GridFlex.Column>
                </GridFlex.RowCenter>
              }
            />
          ))}
        </GridFlex.Column>
      </RadioGroup>
    </>
  );
}
