import { Checkbox, FormControl, GridFlex, InformationTooltip, Radio, RadioGroup, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { BillingModel, PricingType } from '@stigg-types/apiTypes';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { WidgetContext } from '../../WidgetPage';
import { WidgetIdentifier } from '../../useWidgetsData';

export type CustomerPortalSection =
  | 'usage'
  | 'addons'
  | 'promotionalEntitlements'
  | 'billingInformation'
  | 'paymentDetails'
  | 'invoices';

const renderHeadTitle = (title: string) => (
  <Text.B2 color="disabled" fontSize={12} $bold>
    {title.toUpperCase()}
  </Text.B2>
);
export function PreviewOptionsSelectBox({ widgetContext }: { widgetContext: WidgetContext }) {
  const {
    pricingType,
    billingModel,
    setPricingType,
    setBillingModel,
    widgetIdentifier,
    isTrial,
    setIsTrial,
    customerPortalHiddenSections,
    setCustomerPortalHiddenSections,
  } = widgetContext;
  const handlePricingTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pricingType = (event.target as HTMLInputElement).value;
    if (pricingType === 'trial') {
      setPricingType(PricingType.Paid);
      setIsTrial(event.target.checked);
      return;
    }
    setPricingType(pricingType as PricingType);
    setIsTrial(false);
  };
  const handleBillingModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingModel((event.target as HTMLInputElement).value as BillingModel);
  };
  const sectionIsHidden = (section: CustomerPortalSection) =>
    customerPortalHiddenSections.find((x) => x === section) !== undefined;
  const handleDisplayCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCustomerPortalHiddenSections(customerPortalHiddenSections.filter((x) => x !== event.target.defaultValue));
    } else {
      setCustomerPortalHiddenSections([
        ...customerPortalHiddenSections,
        event.target.defaultValue as CustomerPortalSection,
      ]);
    }
  };
  const isCustomerPortalWidget = widgetIdentifier === WidgetIdentifier.CustomerPortal;
  const isPaywallPortalWidget = widgetIdentifier === WidgetIdentifier.Paywall;
  const isFreeSubscription = pricingType === PricingType.Free;
  const isPaidSubscription = pricingType === PricingType.Paid;
  const shouldEnableBillingModelChoice = (isPaidSubscription && isCustomerPortalWidget) || isPaywallPortalWidget;
  const pricingModelOptions = () =>
    map(
      filter(BillingModel, (billingModel) => billingModel !== BillingModel.MinimumSpend),
      (billingModel: BillingModel) => (
        <Radio
          key={billingModel}
          disabled={!shouldEnableBillingModelChoice}
          value={billingModel}
          label={t(`pricing.billingModelDescriptions.${billingModel}`)}
        />
      ),
    );
  return (
    <GridFlex.Row container alignItems="flex-start" py={4} pl={4}>
      {isCustomerPortalWidget && (
        <GridFlex.Column width={207}>
          {renderHeadTitle(t('widgets.subscription'))}
          <FormControl>
            <RadioGroup value={isTrial ? 'trial' : pricingType} onChange={handlePricingTypeChange}>
              <Radio label={t(`pricing.pricingType.${PricingType.Free}`)} value={PricingType.Free} />
              <Radio label={t(`pricing.pricingType.${PricingType.Paid}`)} value={PricingType.Paid} />
              <Radio label={t(`pricing.pricingType.${PricingType.Custom}`)} value={PricingType.Custom} />
              <Radio value="trial" label={t('widgets.paidTrial')} />
            </RadioGroup>
          </FormControl>
        </GridFlex.Column>
      )}

      <GridFlex.Column width={!isPaywallPortalWidget ? 207 : undefined}>
        <InformationTooltip
          arrow
          placement="top"
          title={shouldEnableBillingModelChoice ? '' : <Text.B2>{t('widgets.tooltipDisabledForFree')}</Text.B2>}>
          <GridFlex.Item>
            {renderHeadTitle(t('widgets.pricingModel'))}
            <FormControl>
              <RadioGroup
                value={shouldEnableBillingModelChoice ? billingModel : undefined}
                onChange={handleBillingModelChange}>
                {isPaywallPortalWidget ? <GridFlex.Row>{pricingModelOptions()}</GridFlex.Row> : pricingModelOptions()}
              </RadioGroup>
            </FormControl>
          </GridFlex.Item>
        </InformationTooltip>
      </GridFlex.Column>
      {isCustomerPortalWidget && (
        <GridFlex.Column width={207}>
          {renderHeadTitle(t('widgets.otherDisplayOptions'))}
          <Checkbox
            value="usage"
            checked={!sectionIsHidden('usage')}
            label={t('widgets.usage')}
            onChange={handleDisplayCheckbox}
          />
          <Checkbox
            value="addons"
            disabled={isFreeSubscription}
            checked={!sectionIsHidden('addons') && !isFreeSubscription}
            label={t('widgets.addons')}
            onChange={handleDisplayCheckbox}
          />
          <Checkbox
            value="promotionalEntitlements"
            checked={!sectionIsHidden('promotionalEntitlements')}
            label={t('widgets.promotionalEntitlements')}
            onChange={handleDisplayCheckbox}
          />
        </GridFlex.Column>
      )}
    </GridFlex.Row>
  );
}
