import * as Sentry from '@sentry/react';
import { watchAddonSyncStates } from '../queries/watchAddonSyncStates';
import { AppDispatch } from '../../../../redux/store';
import { updateAddonSyncState } from '../addonSlice';

export function startPollingAddonSyncStates(addonId: string, dispatch: AppDispatch, pollInterval: number) {
  const observableQuery = watchAddonSyncStates(addonId, pollInterval);

  observableQuery.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.addons) {
        Sentry.captureException('got unexpected response for polling addon sync states', { extra: { value } });
      }

      const addons = value.data.addons.edges;
      const fetchedAddon = addons.length > 0 ? addons[0].node : null;
      if (fetchedAddon) {
        dispatch(updateAddonSyncState(fetchedAddon));
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling addon sync states' } });
    },
  );

  return observableQuery;
}
