import { GridFlex } from '@stigg-components';
import React from 'react';
import { ChargeType } from '../../SetPriceWizardForm.types';
import { ChargeButton } from './ChargeButton';

export type ChargesEmptyStateProps = {
  createNewBasePrice: () => void;
  createNewUsageBasedPrice: () => void;
};

export function ChargesEmptyState({ createNewBasePrice, createNewUsageBasedPrice }: ChargesEmptyStateProps) {
  return (
    <GridFlex.Row gap={4}>
      <ChargeButton type={ChargeType.BasePrice} onClick={createNewBasePrice} />
      <ChargeButton type={ChargeType.UsageBased} onClick={createNewUsageBasedPrice} />
    </GridFlex.Row>
  );
}
