import { WizardAccordionStep } from '@stigg-components';
import { StepByStepStatus } from './WizardAccordion';

export function getStepByStepStatus(currentIndex: number, stepsToShow: WizardAccordionStep[]): StepByStepStatus {
  const stepsLength = stepsToShow.length;

  if (currentIndex >= 0 && currentIndex < stepsLength - 1) {
    return StepByStepStatus.IN_PROGRESS;
  }
  if (currentIndex === stepsLength - 1) {
    return StepByStepStatus.LAST_STEP;
  }
  if (currentIndex === stepsLength) {
    return StepByStepStatus.COMPLETED;
  }
  return StepByStepStatus.NA;
}

export function getShowContinueButton(
  isCurrentStep: boolean,
  currentIndex: number,
  status: StepByStepStatus,
  stepsToShow: WizardAccordionStep[],
  showContinueButtonForLastStep?: boolean,
): boolean {
  if (!isCurrentStep) {
    return false;
  }

  const stepsLength = stepsToShow.length;

  if (currentIndex < stepsLength - 1) {
    return true;
  }

  return !!showContinueButtonForLastStep && currentIndex === stepsLength - 1;
}
