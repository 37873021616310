import { styled } from '@stigg-theme';
import { Chip, GridFlex, Step, StepLabel, StepperPoints, Text } from '@stigg-components';
import { t } from 'i18next';

const TaskChip = styled(Chip)`
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 12px;
  height: 28px;
  border: ${({ theme }) => `1px solid ${theme.itamar.palette.grey[100]}`};
`;

const IMPORT_PRODUCTS_STEPS = () => [
  {
    label: t('integrations.sidenavOverview'),
  },
  {
    label: t('integrations.sidenavImportProducts'),
  },
  {
    label: t('integrations.sidenavPricingModelSettings'),
  },
  {
    label: t('integrations.sidenavPreviewAndImport'),
  },
];

const StyledStepperPoints = styled(StepperPoints)`
  .MuiSvgIcon-root:not(.Mui-active) {
    color: ${({ theme }) => theme.itamar.palette.text.disabled};
  }
`;

export function WizardSideNav({ activeStep }: { activeStep: number }) {
  return (
    <GridFlex.Column
      item
      sm={4}
      xl={3}
      p={14}
      sx={{
        height: '100%',
        borderRight: (theme) => `1px solid ${theme.itamar.palette.action.disabledBackground}`,
        zIndex: 2,
        paddingTop: '50px',
        backgroundColor: (theme) => (theme.isLightTheme ? undefined : theme.itamar.palette.background.darkBackground2),
      }}>
      <TaskChip color="action.disabled" textColor="secondary" label={<Text.B2>Task 1</Text.B2>} />
      <Text.H6 mb={6}>{t('integrations.importProductAndPricingData')}</Text.H6>
      <StyledStepperPoints activeStep={activeStep} orientation="vertical">
        {IMPORT_PRODUCTS_STEPS().map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </StyledStepperPoints>

      <GridFlex.Column
        pt={4}
        sx={{ borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}`, marginTop: 'auto' }}>
        <TaskChip color="action.disabled" textColor="secondary" label={<Text.B2>Task 2</Text.B2>} />
        <Text.H6 color="disabled">{t('integrations.importCustomersAndSubscriptionsData')}</Text.H6>
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
