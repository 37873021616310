import { useCallback, useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import {
  BillingModel,
  Currency,
  CustomerSubscriptionDataFragment,
  PackageStatus,
  PlanFragment,
  PlanListFragment,
  PricingType,
  ProductListItemFragment,
} from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { fetchPlanByRefIdAction, fetchPlansAction } from '../../../../../packages/plans/plansSlice';
import { getPrice } from '../../../../../packages/pricing/utils/getPrice';

type UseSubscriptionFormResourcesProps = {
  subscription: CustomerSubscriptionDataFragment | null | undefined;
};

export function useSubscriptionFormResources({ subscription }: UseSubscriptionFormResourcesProps) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isUpdatingSubscription = !!subscription;
  const currentPlan: PlanFragment | undefined = useSelector((state: RootState) => state.plansReducer.plan);
  const planEdges = useSelector((state: RootState) => state.plansReducer.plans.edges);
  const products = useSelector((state: RootState) => state.productReducer.products);

  // Plans
  const existingPlans: Array<PlanListFragment | PlanFragment> = useMemo(() => {
    if (isUpdatingSubscription) {
      if ([PricingType.Custom, PricingType.Free].includes(subscription.pricingType)) {
        return currentPlan && !isEmpty(currentPlan) ? [currentPlan] : [];
      }

      return currentPlan && !isEmpty(currentPlan)
        ? [
            {
              ...currentPlan,
              // Merging the subscription price with the plan since the price can be from different version
              prices: currentPlan.prices?.map((price) => {
                const subscriptionPrice = subscription?.prices?.find(
                  (sp) =>
                    sp.price?.featureId === price.featureId &&
                    sp.price?.billingPeriod === price.billingPeriod &&
                    sp.price?.billingCountryCode === price.billingCountryCode,
                );

                return subscriptionPrice?.price ? subscriptionPrice.price : price;
              }),
            },
          ]
        : [];
    }
    return (planEdges || []).map(({ node }) => node).filter(({ status }) => status !== PackageStatus.Draft);
  }, [isUpdatingSubscription, planEdges, subscription, currentPlan]);

  const existingProducts = useMemo(() => {
    return products.filter((product) => {
      if (isUpdatingSubscription && subscription) {
        return subscription.plan.product.refId === product.refId;
      }
      return existingPlans.some((plan) => plan.product.refId === product.refId);
    });
  }, [products, existingPlans, isUpdatingSubscription, subscription]);
  const hasMultipleProducts = isUpdatingSubscription ? products.length > 1 : existingProducts?.length > 1;

  const isLoadingProducts = useSelector((state: RootState) => state.productReducer.isLoading);
  const isLoadingPlans = useSelector((state: RootState) => {
    if (isUpdatingSubscription) {
      return state.plansReducer.isLoadingPlan;
    }
    return state.plansReducer.isLoading;
  });
  const isLoading = isLoadingProducts || isLoadingPlans;

  const getProduct = useCallback(
    (productId: string): ProductListItemFragment => {
      return existingProducts.find((product) => product.refId === productId) as ProductListItemFragment;
    },
    [existingProducts],
  );
  const getProductPlans = useCallback(
    (
      productId: string,
      { billingCurrency }: { billingCurrency?: Currency } = {},
    ): Array<PlanListFragment | PlanFragment> => {
      return existingPlans.filter(
        (plan) =>
          plan.product.refId === productId &&
          (billingCurrency && plan.pricingType === PricingType.Paid
            ? plan.prices?.some((price) => getPrice(price).currency === billingCurrency)
            : true),
      );
    },
    [existingPlans],
  );
  const getPlan = useCallback(
    (planId: string): PlanListFragment | PlanFragment => {
      return existingPlans.find((plan) => plan.refId === planId) as PlanListFragment | PlanFragment;
    },
    [existingPlans],
  );

  const isProductSupportMultipleSubscriptions = (productId: string) => getProduct(productId)?.multipleSubscriptions;
  const isCustomPricePlan = (planId: string) => !!planId && getPlan(planId)?.pricingType === PricingType.Custom;
  const isFreePlan = (planId: string) => !!planId && getPlan(planId)?.pricingType === PricingType.Free;
  const isPerUnitPricingModal = (planId: string) => {
    if (!planId) return false;

    const selectedPlan = existingPlans.find((plan) => plan.refId === planId);
    if (!selectedPlan) {
      return false;
    }
    const planPrices = selectedPlan.prices || [];

    return planId ? planPrices.some((price) => price.billingModel === BillingModel.PerUnit) : false;
  };

  useEffect(() => {
    if (currentEnvironmentId) {
      if (subscription) {
        void dispatch(
          fetchPlanByRefIdAction({ refId: subscription.plan.refId, versionNumber: subscription.plan.versionNumber }),
        );
      } else {
        void dispatch(fetchPlansAction({ environmentId: currentEnvironmentId, paging: { first: 100 } }));
      }
    }
  }, [dispatch, currentEnvironmentId, subscription]);

  return {
    existingProducts,
    isLoading,
    hasMultipleProducts,
    currentPlan,
    getProduct,
    getPlan,
    getProductPlans,
    isProductSupportMultipleSubscriptions,
    isCustomPricePlan,
    isFreePlan,
    isPerUnitPricingModal,
  };
}
