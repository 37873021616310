import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import {
  ExperimentConnection,
  ExperimentFragment,
  ExperimentSlimFragment,
  ExperimentStats,
} from '@stigg-types/apiTypes';
import { fetchNonActiveExperiments } from './queries/fetchNonActiveExperiments';
import { createExperiment } from './mutations/createExperiment';
import { fetchActiveExperiments } from './queries/fetchActiveExperiments';
import { fetchExperimentByRefId } from './queries/fetchExperimentByRefId';
import { startExperiment } from './mutations/startExperiment';
import { stopExperiment } from './mutations/stopExperiment';
import { fetchExperiments } from './queries/fetchExperiments';
import { updateExperiment } from './mutations/updateExperiment';
import { fetchExperimentStatsByRefId } from './queries/fetchExperimentStatsByRefId';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface ExperimentsState {
  experimentsPage: {
    isLoading: boolean;
    activeExperiments: ExperimentFragment[];
    hasNonActiveExperiments: boolean;
    isLoadingNonActiveExperiments: boolean;
    nonActiveExperiments: ExperimentConnection;
    isLoadingStats?: boolean;
    experimentStats?: ExperimentStats[];
  };
  single: {
    isLoading: boolean;
    experiment?: ExperimentFragment;
    isLoadingStats: boolean;
    experimentStats?: ExperimentStats;
  };
  global: {
    isLoading: boolean;
    activeExperiments: ExperimentSlimFragment[];
    environmentId: string;
  };
}

const initialState: ExperimentsState = {
  experimentsPage: {
    isLoading: false,
    activeExperiments: [],
    hasNonActiveExperiments: false,
    isLoadingNonActiveExperiments: false,
    nonActiveExperiments: {} as ExperimentConnection,
  },
  single: {
    isLoading: false,
    isLoadingStats: false,
  },
  global: {
    isLoading: false,
    activeExperiments: [],
    environmentId: '',
  },
};

const fetchExperimentsAction = createAppAsyncThunk('fetchExperiments', fetchExperiments);
const fetchNonActiveExperimentsAction = createAppAsyncThunk('fetchNonActiveExperiments', fetchNonActiveExperiments);
const createExperimentAction = createAppAsyncThunk('createExperiment', createExperiment);
const updateExperimentAction = createAppAsyncThunk('updateExperiment', updateExperiment);
const fetchActiveExperimentsAction = createAppAsyncThunk('fetchActiveExperiment', fetchActiveExperiments);
const fetchExperimentByRefIdAction = createAppAsyncThunk('fetchExperimentByRefId', fetchExperimentByRefId);
const fetchExperimentStatsByRefIdAction = createAppAsyncThunk(
  'fetchExperimentStatsByRefId',
  fetchExperimentStatsByRefId,
);
const startExperimentAction = createAppAsyncThunk('startExperiment', startExperiment);
const stopExperimentAction = createAppAsyncThunk('stopExperiment', stopExperiment);

export const experimentsSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    resetExperiment: (state) => {
      state.single.experiment = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperimentsAction.pending, (state) => {
        state.experimentsPage.isLoading = true;
      })
      .addCase(fetchExperimentsAction.fulfilled, (state, action) => {
        state.experimentsPage.activeExperiments = action.payload.activeExperiments;
        state.experimentsPage.hasNonActiveExperiments = action.payload.nonActiveExperimentsCount > 0;
        state.experimentsPage.isLoading = false;
      })
      .addCase(fetchExperimentsAction.rejected, (state) => {
        state.experimentsPage.isLoading = false;
      });

    builder
      .addCase(fetchNonActiveExperimentsAction.pending, (state, { meta }) => {
        // When searching we clear the current state of experiments
        if (!isEmpty(meta.arg.search)) {
          state.experimentsPage.nonActiveExperiments = {} as ExperimentConnection;
        }
        if (isEmpty(state.experimentsPage.nonActiveExperiments.edges)) {
          state.experimentsPage.isLoadingNonActiveExperiments = true;
        }
      })
      .addCase(fetchNonActiveExperimentsAction.fulfilled, (state, action) => {
        state.experimentsPage.nonActiveExperiments = action.payload as ExperimentConnection;
        state.experimentsPage.isLoadingNonActiveExperiments = false;
      })
      .addCase(fetchNonActiveExperimentsAction.rejected, (state) => {
        state.experimentsPage.isLoadingNonActiveExperiments = false;
      });

    builder
      .addCase(fetchExperimentByRefIdAction.pending, (state, action) => {
        state.single.isLoading = !action.meta.arg.silentFetch;
      })
      .addCase(fetchExperimentByRefIdAction.fulfilled, (state, action) => {
        state.single.experiment = action.payload;
        state.single.isLoading = false;
      })
      .addCase(fetchExperimentByRefIdAction.rejected, (state) => {
        state.single.isLoading = false;
      });

    builder
      .addCase(fetchActiveExperimentsAction.pending, (state, action) => {
        state.global.isLoading = !action.meta.arg.silentFetch;
      })
      .addCase(fetchActiveExperimentsAction.fulfilled, (state, action) => {
        state.global.activeExperiments = action.payload.activeExperiments;
        state.global.environmentId = action.payload.environmentId;
        state.global.isLoading = false;
      })
      .addCase(fetchActiveExperimentsAction.rejected, (state) => {
        state.global.isLoading = false;
      });

    builder
      .addCase(fetchExperimentStatsByRefIdAction.pending, (state, action) => {
        state.single.isLoadingStats = !action.meta.arg.silentFetch;
      })
      .addCase(fetchExperimentStatsByRefIdAction.fulfilled, (state, action) => {
        state.single.experimentStats = action.payload;
        state.single.isLoadingStats = false;
      })
      .addCase(fetchExperimentStatsByRefIdAction.rejected, (state) => {
        state.single.isLoadingStats = false;
      });
  },
});

const { resetExperiment } = experimentsSlice.actions;

export {
  fetchExperimentsAction,
  fetchNonActiveExperimentsAction,
  fetchExperimentStatsByRefIdAction,
  createExperimentAction,
  updateExperimentAction,
  fetchActiveExperimentsAction,
  fetchExperimentByRefIdAction,
  resetExperiment,
  startExperimentAction,
  stopExperimentAction,
};

export default experimentsSlice.reducer;
