import { Grid, Text, GridFlex, DialogActionsButtons } from '@stigg-components';
import { FieldArray, Formik } from 'formik';
import { t } from 'i18next';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  AddonFragment,
  PackageStatus,
  PlanCompatibleAddonFragment,
  PlanFragment,
  PricingType,
} from '@stigg-types/apiTypes';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { fetchAddonGroupsAction, fetchAddonsAction } from '../../../../addons/addonSlice';
import { AutocompleteSearch } from '../../AutoCompleteSearch';
import { CompatiblePackageGroup, isAddon, isPackageGroup } from '../../../../common/packageUtils';
import { AddedAddonOrAddonGroupList } from './AddedAddonOrAddonGroupList';
import { CompatibleAddonOrAddonGroupSearchOption } from './CompatibleAddonOrAddonGroupSearchOption';

type AddCompatibleAddonOrAddonGroupDialogProps = {
  setDialogOpen: any;
  onSubmit: (values: NewAddonAndAddonGroupsFormFields) => Promise<void>;
  excludedAddonIds: string[];
  excludedAddonGroupIds: string[];
  plan: PlanFragment;
};

export type AddonOrAddonGroupOption = AddonFragment | CompatiblePackageGroup | PlanCompatibleAddonFragment;

export type AddedAddonOrAddonGroup = {
  entity: AddonOrAddonGroupOption;
  minItems?: number;
  freeItems?: number;
};

export type NewAddonAndAddonGroupsFormFields = {
  addedEntities: AddedAddonOrAddonGroup[];
};

const validationSchema = Yup.object().shape({
  addedEntities: Yup.array().required().min(1),
});

export function AddCompatibleAddonOrAddonGroupDialog({
  plan,
  excludedAddonIds,
  excludedAddonGroupIds,
  onSubmit,
  setDialogOpen,
}: AddCompatibleAddonOrAddonGroupDialogProps) {
  const dispatch = useAppDispatch();
  const { edges: addonEdges } = useSelector((state: RootState) => state.addonReducer.addons);
  const { edges: addonGroupEdges } = useSelector((state: RootState) => state.addonReducer.packageGroups);
  const isFetchingAddons = useSelector((state: RootState) => state.addonReducer.isLoading);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const [searchOptions, setSearchOptions] = useState<readonly AddonOrAddonGroupOption[]>([]);
  const onSearchKeyUp = (event: any) => {
    const searchStr = event.target.value;
    void dispatch(
      fetchAddonsAction({
        search: searchStr,
        environmentId: currentEnvironmentId,
      }),
    );

    void dispatch(
      fetchAddonGroupsAction({
        search: searchStr,
        environmentId: currentEnvironmentId,
      }),
    );
  };

  useEffect(() => {
    void dispatch(fetchAddonsAction({ environmentId: currentEnvironmentId, productId: plan.product.id }));
    void dispatch(
      fetchAddonGroupsAction({
        environmentId: currentEnvironmentId,
      }),
    );
  }, [dispatch, currentEnvironmentId, plan.product.id]);

  useEffect(() => {
    const addonOptions = addonEdges ? addonEdges.map((edge) => edge.node) : [];
    const addonGroupOptions = addonGroupEdges ? addonGroupEdges.map((edge) => edge.node) : [];

    setSearchOptions(sortBy([...addonOptions, ...addonGroupOptions], (entity) => isAddon(entity)));
  }, [addonEdges, addonGroupEdges]);

  const onCancel = () => setDialogOpen(false);
  const handleSubmit = async (values: NewAddonAndAddonGroupsFormFields) => {
    try {
      await onSubmit(values);
      setDialogOpen(false);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const initialValues: NewAddonAndAddonGroupsFormFields = {
    addedEntities: [],
  };

  const chooseAddon = (push: any, option: AddonOrAddonGroupOption | null) => {
    if (option) {
      push({ entity: option });
    }
  };

  const isOptionDisabled = (currentAddons: AddonOrAddonGroupOption[], option: AddonOrAddonGroupOption) => {
    const notCompatibleWithPlanPricingType =
      plan.pricingType === PricingType.Paid && isAddon(option) && option.pricingType === PricingType.Custom;
    const selectedAddonIds = currentAddons.map((newAddon: AddonOrAddonGroupOption) =>
      isAddon(newAddon) ? newAddon.id : undefined,
    );
    const selectedAddonGroupIds = currentAddons.map((newAddon: AddonOrAddonGroupOption) =>
      isPackageGroup(newAddon) ? newAddon.packageGroupId : undefined,
    );
    const isDraftAddon = isAddon(option) && option.status === PackageStatus.Draft;
    const addonAlreadyIncluded =
      isAddon(option) && includes(compact([...selectedAddonIds, ...excludedAddonIds]), option.id);
    const addonGroupAlreadyIncluded =
      isPackageGroup(option) &&
      includes(compact([...selectedAddonGroupIds, ...excludedAddonGroupIds]), option.packageGroupId);
    return (
      isDraftAddon ||
      addonAlreadyIncluded ||
      addonGroupAlreadyIncluded ||
      // TODO: should we block something like that for addon groups as well?
      notCompatibleWithPlanPricingType
    );
  };

  const getDisabledDescription = (addon: AddonOrAddonGroupOption) => {
    const isItAddon = isAddon(addon);
    if (isItAddon && addon.status === PackageStatus.Draft) {
      return `(${t('addons.draftAddon')})`;
    }
    if (isItAddon && plan.pricingType === PricingType.Paid && addon.pricingType === PricingType.Custom) {
      return `(${t('addons.customAddon')})`;
    }
    return `(${t('sharedComponents.alreadyAdded')})`;
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {(formRenderProps) => {
        const { values, isValid } = formRenderProps;
        return (
          <FieldArray name="addedEntities">
            {({ push, remove, replace }) => {
              const numberOfAddedAddons = values.addedEntities.filter((entity) => isAddon(entity.entity)).length;
              const numberOfAddedAddonGroups = values.addedEntities.length - numberOfAddedAddons;

              return (
                <GridFlex.Column container>
                  <GridFlex.Column>
                    <Grid item pb={2}>
                      <AutocompleteSearch
                        chooseOption={(option) => chooseAddon(push, option)}
                        isOptionDisabled={(option: AddonOrAddonGroupOption) =>
                          isOptionDisabled(
                            values.addedEntities.map((item) => item.entity),
                            option,
                          )
                        }
                        searchOptions={searchOptions}
                        onSearchKeyUp={onSearchKeyUp}
                        isFetching={isFetchingAddons}
                        groupBy={(option: AddonOrAddonGroupOption) => (isAddon(option) ? 'Add-ons' : 'Add-on groups')}
                        placeholder={t('addons.searchPlaceholder')}
                        getOptionLabel={(option) =>
                          `${option.displayName} (${
                            isAddon(option) ? option.refId : isPackageGroup(option) ? option.packageGroupId : undefined
                          })`
                        }
                        renderOption={(props: any, option: AddonOrAddonGroupOption) => {
                          return (
                            <CompatibleAddonOrAddonGroupSearchOption
                              option={option}
                              props={props}
                              getDisabledDescription={getDisabledDescription}
                            />
                          );
                        }}
                        isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
                      />
                    </Grid>

                    <Grid item direction="column">
                      {!isEmpty(values.addedEntities) ? (
                        <AddedAddonOrAddonGroupList formProps={formRenderProps} remove={remove} replace={replace} />
                      ) : null}
                    </Grid>
                  </GridFlex.Column>
                  <Grid container item alignItems="baseline" justifyContent="space-between">
                    <Grid>
                      {numberOfAddedAddons > 0 && (
                        <Text.Sub2 pr={1}>
                          {`${numberOfAddedAddons} add-on${numberOfAddedAddons > 1 ? 's' : ''} selected`}
                        </Text.Sub2>
                      )}
                      {numberOfAddedAddonGroups > 0 && (
                        <Text.Sub2>
                          {`${numberOfAddedAddons > 0 ? 'and ' : ''}${numberOfAddedAddonGroups} add-on group${
                            numberOfAddedAddonGroups > 1 ? 's' : ''
                          } selected`}
                        </Text.Sub2>
                      )}
                    </Grid>
                    <Grid item>
                      <DialogActionsButtons
                        saveDisabled={!isValid || isEmpty(values.addedEntities)}
                        saveText="Add"
                        onCancel={onCancel}
                        onSave={formRenderProps.submitForm}
                      />
                    </Grid>
                  </Grid>
                </GridFlex.Column>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
}
