import { applyEnvironmentFilter, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  ExperimentFilter,
  ExperimentSortFields,
  ExperimentStatus,
  FetchNonActiveExperimentsQuery,
  FetchNonActiveExperimentsQueryVariables,
  SortDirection,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { Paging } from '../../../components/table/gqlTableHelper';
import { EXPERIMENT_LIST_FRAGMENT } from './fetchExperiments';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';

const FETCH_NON_ACTIVE_EXPERIMENTS = gql`
  query FetchNonActiveExperiments($paging: CursorPaging, $sorting: [ExperimentSort!], $filter: ExperimentFilter!) {
    experiments(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...ExperimentListFragment
        }
        cursor
      }
    }
  }

  ${EXPERIMENT_LIST_FRAGMENT}
`;

export type FetchNonActiveExperimentsProps = {
  paging?: Paging;
  search?: string | null;
};

export async function fetchNonActiveExperiments(
  props: FetchNonActiveExperimentsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId },
      } = getState();
      if (!currentEnvironmentId) {
        return { experiments: [], total: 0 };
      }

      let { search, paging } = props;
      if (search === '') {
        search = null;
      }

      const filter: ExperimentFilter = {
        environmentId: { eq: currentEnvironmentId },
        status: { neq: ExperimentStatus.InProgress },
      };

      if (search) {
        filter.or = [{ name: { iLike: `%${search}%` } }].map((searchFilter) =>
          applyEnvironmentFilter<ExperimentFilter>(searchFilter, currentEnvironmentId),
        );
      }

      if (!paging) {
        paging = { first: DEFAULT_TABLE_PAGE_SIZE };
      }

      const response = await apolloClient.query<
        FetchNonActiveExperimentsQuery,
        FetchNonActiveExperimentsQueryVariables
      >({
        query: FETCH_NON_ACTIVE_EXPERIMENTS,
        fetchPolicy: 'network-only',
        variables: {
          filter,
          paging,
          sorting: [
            { field: ExperimentSortFields.Status, direction: SortDirection.Asc },
            { field: ExperimentSortFields.CreatedAt, direction: SortDirection.Desc },
          ],
        },
      });

      return response.data.experiments;
    },
    {
      failureMessageHandler: () => t('experiments.api.failFetch'),
    },
    dispatch,
  );
}
