import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  AwsDimension,
  ListAwsProductDimensionsQuery,
  ListAwsProductDimensionsQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const FETCH_AWS_PRODUCT_DIMENSIONS = gql`
  query ListAwsProductDimensions($input: ListAwsProductDimensionsInput!) {
    listAwsProductDimensions(input: $input) {
      dimensions {
        name
        description
        key
        unit
        type
        stiggPlanName
        stiggPlanId
      }
    }
  }
`;

type FetchAWSProductDimensionsProps = {
  productId: string;
};

async function fetchAWSProductDimensions(
  props: FetchAWSProductDimensionsProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<AwsDimension[] | null> {
  const { accountReducer } = getState();
  if (!accountReducer.currentEnvironmentId) {
    throw new Error('environment Id must be set');
  }

  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<ListAwsProductDimensionsQuery, ListAwsProductDimensionsQueryVariables>({
        query: FETCH_AWS_PRODUCT_DIMENSIONS,
        variables: {
          input: {
            productId: props.productId,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return response.data.listAwsProductDimensions.dimensions;
    },
    {
      failureMessageHandler: () => t('integrations.api.failFetchAWSProductDimensions'),
    },
    dispatch,
  );
}

export { fetchAWSProductDimensions };
