import { TaskStatus } from '@stigg-types/apiTypes';

export function isTaskInProgress(taskStatus: TaskStatus) {
  return [TaskStatus.Pending, TaskStatus.InProgress].includes(taskStatus);
}

export function isTaskFailed(taskStatus: TaskStatus) {
  return [TaskStatus.Failed, TaskStatus.PartiallyFailed].includes(taskStatus);
}

export function isTaskFinished(taskStatus: TaskStatus) {
  return [TaskStatus.Completed, TaskStatus.Failed, TaskStatus.PartiallyFailed].includes(taskStatus);
}
