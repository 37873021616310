import { useState } from 'react';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { t } from 'i18next';
import { Alert, Box, ExternalLink, Form, GridFlex, Icon, Text, WizardAccordionStep } from '@stigg-components';
import {
  SalesforceCredentialsInput,
  SalesforceCredentialsFragment,
  ApiKey,
  VendorIdentifier,
} from '@stigg-types/apiTypes';
import { Optional } from '../../../../types/primitives';
import { EnvironmentApiKey } from '../../../accounts/components/EnvironmentApiKey';
import { useAppDispatch } from '../../../../redux/store';
import { fetchIntegrationByVendorAction } from '../../integrationsSlice';
import config from '../../../../env.config';

type UseWizardStepsProps = {
  hasIntegration: boolean;
  credentials: Optional<SalesforceCredentialsFragment>;
  salesforceApiKey?: ApiKey;
  onAddIntegration: (credentials: SalesforceCredentialsInput) => Promise<void>;
};

// eslint-disable-next-line no-useless-escape
const regMatch = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

const validationSchema = () =>
  Yup.object().shape({
    url: Yup.string().matches(regMatch, 'Input must be a valid URL').required(t('fieldValidationMessages.required')),
  });

function parseUrlHostname(url: string) {
  if (!url.startsWith('http') || !url.startsWith('https')) {
    url = `https://${url}`;
  }
  try {
    const urlParser = new URL(url);
    return urlParser.hostname;
  } catch (e) {
    return '';
  }
}

export const useSalesforceIntegrationWizardSteps = ({
  hasIntegration,
  credentials,
  onAddIntegration,
  salesforceApiKey,
}: UseWizardStepsProps): WizardAccordionStep[] => {
  const dispatch = useAppDispatch();
  const [domain, setDomain] = useState(credentials?.domain);
  const initialValues = {
    url: domain,
  };

  return [
    {
      id: 'deep-link-setup',
      title: t('integrations.salesforce.steps.deepLinkSetup.title'),
      content: (
        <Box>
          <Text.B2 whiteSpace="pre-line">{t('integrations.salesforce.steps.deepLinkSetup.content')}</Text.B2>

          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={() => onAddIntegration({ domain })}
            disableSaveOnNoChanges
            hideSaveButton={!hasIntegration}
            submitButtonText={t('sharedComponents.editSaveButton')}
            footerSx={{ borderTop: 'none' }}
            fields={[
              {
                type: 'text',
                id: 'url',
                placeholder: t('integrations.salesforce.domainPlaceholder'),
                autoFocus: true,
                handleChange: ({ setFieldValue }) => {
                  return (e: any) => {
                    const url = e?.target?.value;
                    setFieldValue('url', url);
                    const hostname = parseUrlHostname(url);
                    setDomain(hostname);
                  };
                },
              },
            ]}
          />
        </Box>
      ),
      onStepContinue: () => {
        return onAddIntegration({ domain });
      },
      hasErrors: !domain?.match(regMatch),
      maxWidth: 600,
    },
    {
      id: 'app-installation',
      title: t('integrations.salesforce.steps.setupScript.title'),
      content: (
        <GridFlex.Column rowGap={4}>
          <Text.B2>{t('integrations.salesforce.steps.setupScript.installText')}</Text.B2>
          <Alert
            icon={<Icon type="custom" icon="InstallPackageIcon" />}
            sx={{ backgroundColor: (theme) => theme.itamar.palette.grey[25], width: '100%' }}
            severity="info">
            <Text.B2 gap={1}>
              <ExternalLink
                label={t('integrations.salesforce.steps.setupScript.installationUrlLinkText')}
                url={t('integrations.salesforce.steps.setupScript.installationUrl', {
                  salesforceDomain: domain,
                  salesforceAppVersionId: config.salesforceAppVersionId,
                })}
              />
            </Text.B2>
          </Alert>
          <Text.B2>{t('integrations.salesforce.steps.setupScript.installForAdminText')}</Text.B2>
        </GridFlex.Column>
      ),
      maxWidth: 600,
    },
    {
      id: 'stigg-app-config',
      title: t('integrations.salesforce.steps.apiKeySetup.title'),
      content: (
        <Box>
          <Text.B2 mb={4} whiteSpace="pre-line">
            {t('integrations.salesforce.steps.apiKeySetup.content')}
          </Text.B2>
          {salesforceApiKey ? <EnvironmentApiKey apiKey={salesforceApiKey} truncateApiKey /> : null}
        </Box>
      ),
    },
    {
      id: 'stigg-app-customization',
      title: t('integrations.salesforce.steps.stiggAppCustomization.title'),
      content: (
        <Box>
          <Text.B2 mb={6} whiteSpace="pre-line">
            <Trans i18nKey="integrations.salesforce.steps.stiggAppCustomization.content" />
          </Text.B2>
          <Alert sx={{ backgroundColor: (theme) => theme.itamar.palette.grey[25], width: '100%' }} severity="info">
            <Text.B2 gap={1}>
              {t('integrations.salesforce.steps.stiggAppCustomization.learnMoreLabelPrefix')}
              <ExternalLink
                label={t('integrations.salesforce.steps.stiggAppCustomization.learnMoreLabel')}
                url={t('integrations.salesforce.steps.stiggAppCustomization.learnMoreUrl')}
              />
            </Text.B2>
          </Alert>
        </Box>
      ),
      onStepContinue: async () => {
        await dispatch(
          fetchIntegrationByVendorAction({
            vendorIdentifier: VendorIdentifier.Salesforce,
          }),
        );
      },
    },
  ];
};
