import { t } from 'i18next';
import Lottie, { Options as LottieOptions } from 'react-lottie';
import { GridFlex, Text } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import ImportCustomersOverview from '@assets/images/integrations/import-customers-overview.png';

import darkAnimationData from '../../../../../../assets/animations/import_customers_overview.dark.json';

export function ImportCustomersOverviewStep() {
  const theme = useTheme();

  const animationOptions: LottieOptions = {
    loop: true,
    autoplay: true,
    animationData: darkAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <GridFlex.Column>
      <Text.H3 mb={1}>{t('integrations.overviewStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={12}>
        {t('integrations.importCustomersOverviewSubtitle')}
      </Text.B2>
      {theme.isLightTheme ? (
        <img width="100%" src={ImportCustomersOverview} alt="import customers overview" />
      ) : (
        <Lottie options={animationOptions} isClickToPauseDisabled width="100%" />
      )}
    </GridFlex.Column>
  );
}
