import { t } from 'i18next';
import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type AmountDueSectionProps = Pick<PreviewNextInvoiceFragment, 'amountDue'> & { description?: string };

export function AmountDueSection({ amountDue, description }: AmountDueSectionProps) {
  return (
    <>
      <ChargeRow
        description={description || t('subscriptions.breakdown.EstimatedNextBill')}
        charge={amountDue}
        sx={{ mt: 4 }}
        bold
        dataTestId="amount-due"
      />
    </>
  );
}
