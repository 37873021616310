import { t } from 'i18next';
import { EntitlementResetPeriod } from '@stigg-types/apiTypes';

export function formatResetPeriod(resetPeriod: EntitlementResetPeriod, short = false) {
  if (short) {
    return {
      [EntitlementResetPeriod.Year]: t('entitlements.shortResetPeriods.year'),
      [EntitlementResetPeriod.Month]: t('entitlements.shortResetPeriods.month'),
      [EntitlementResetPeriod.Week]: t('entitlements.shortResetPeriods.week'),
      [EntitlementResetPeriod.Day]: t('entitlements.shortResetPeriods.day'),
      [EntitlementResetPeriod.Hour]: t('entitlements.shortResetPeriods.hour'),
    }[resetPeriod];
  }

  return {
    [EntitlementResetPeriod.Year]: t('entitlements.yearResetPeriod'),
    [EntitlementResetPeriod.Month]: t('entitlements.monthResetPeriod'),
    [EntitlementResetPeriod.Week]: t('entitlements.weekResetPeriod'),
    [EntitlementResetPeriod.Day]: t('entitlements.dayResetPeriod'),
    [EntitlementResetPeriod.Hour]: t('entitlements.hourResetPeriod'),
  }[resetPeriod];
}
