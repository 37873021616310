import { useIntegrations } from '@stigg-common';
import { VendorIdentifier, SalesforceCredentialsFragment } from '@stigg-types/apiTypes';
import { Optional } from '../../../../types/primitives';

export function useSalesforceUrl(salesforceId: string | null | undefined, salesforceEntity: 'ACCOUNT' | 'OPPORTUNITY') {
  const { integrations } = useIntegrations();
  const salesforceIntegration = integrations.find((x) => x.vendorIdentifier === VendorIdentifier.Salesforce);
  const salesforceCredentials = salesforceIntegration?.credentials as Optional<SalesforceCredentialsFragment>;

  if (!salesforceId || !salesforceCredentials?.domain) {
    return { salesforceUrl: undefined };
  }

  const salesforceBaseUrl = `https://${salesforceCredentials.domain}/lightning/r`;
  let salesforceUrl: string | undefined;
  switch (salesforceEntity) {
    case 'ACCOUNT':
      salesforceUrl = `${salesforceBaseUrl}/Account/${salesforceId}/view`;
      break;
    case 'OPPORTUNITY':
      salesforceUrl = `${salesforceBaseUrl}/Opportunity/${salesforceId}/view`;
      break;
    default:
      salesforceUrl = undefined;
  }

  return { salesforceUrl };
}
