import React from 'react';
import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';

type SubscriptionHeaderProps = {
  subscription: SubscriptionDataFragment;
};

export default function SubscriptionHeader({ subscription }: SubscriptionHeaderProps) {
  return (
    <GridFlex.Column item ml={4}>
      <Text.Sub2 mb={1}>{t('subscriptions.header')}</Text.Sub2>
      <GridFlex.RowCenter>
        <Text.H3 mr={4} data-testid="subscription-id-title">
          {subscription.subscriptionId}
        </Text.H3>
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
