import { Grid, GridFlex, Box, Text, CardContent, Card } from '@stigg-components';
import { styled } from '@stigg-theme';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import { ReactComponent as GraphEmpty } from '@assets/icons/GraphEmpty.svg';
import { FIRST_WIDGET_HEIGHT } from '../FeatureInspector';

const LinePlaceholder = styled(Box)`
  height: 1px;
  border-top: ${({ theme }) => `1px dashed ${theme.itamar.border.borderColor}`};
`;

export const BoxBorder = styled(GridFlex.ColumnMiddle)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export const ChartPlaceholder = React.memo(({ repeat = 5 }: { repeat?: number }) => {
  return (
    <GridFlex.Column $fullWidth height="100%" px={4}>
      {[...Array(repeat)].map((_, index) => (
        <LinePlaceholder my={8} key={index} />
      ))}
    </GridFlex.Column>
  );
});

const ChartMessage = ({ title, subtitle, height }: { title: string; subtitle: string; height?: number }) => {
  return (
    <GridFlex.ColumnCenter height={height} alignItems="center">
      <GraphEmpty />
      <Text.H6 color="primary" mt={5} mb={2}>
        {title}
      </Text.H6>
      <Text.B2 color="secondary" maxWidth={400} textAlign="center">
        {subtitle}
      </Text.B2>
    </GridFlex.ColumnCenter>
  );
};

export const ChartAlternativeMessage = React.memo(({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <GridFlex.Column $fullWidth height="100%" px={4}>
      <LinePlaceholder my={8} />
      <BoxBorder textAlign="center" flex={1} p={2}>
        <ChartMessage title={title} subtitle={subtitle} />
      </BoxBorder>
      <LinePlaceholder my={8} />
    </GridFlex.Column>
  );
});

const MessageWidgetCard = React.memo(({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Grid gridColumn="span 12" height={FIRST_WIDGET_HEIGHT}>
      <Card p={2}>
        <CardContent>
          <ChartMessage title={title} subtitle={subtitle} height={FIRST_WIDGET_HEIGHT - 58} />
        </CardContent>
      </Card>
    </Grid>
  );
});

export const NoMeteredFeaturesMessage = () => (
  <MessageWidgetCard
    title={t('dashboards.featureInspector.noMeteredFeaturesToInspect')}
    subtitle={t('dashboards.featureInspector.featureInspectorPromotion')}
  />
);

export const NoPlansAvailableMessage = () => (
  <MessageWidgetCard
    title={t('dashboards.featureInspector.noPlansToInspect')}
    subtitle={t('dashboards.featureInspector.featureInspectorPromotion')}
  />
);

export const NoPlansSelectedMessage = () => (
  <MessageWidgetCard
    title={t('dashboards.featureInspector.noPlansSelected')}
    subtitle={t('dashboards.featureInspector.featureInspectorPromotion')}
  />
);

export const ChartNoDataMessage = () => (
  <ChartAlternativeMessage
    title={t('dashboards.featureInspector.noDataTitle')}
    subtitle={t('dashboards.featureInspector.noUsageForFilterCriteria')}
  />
);

export const NotCompatibleWithMultipleSubscriptions = () => (
  <MessageWidgetCard
    title={t('dashboards.featureInspector.notCompatibleWithMultipleSubscriptionsProductTitle')}
    subtitle={t('dashboards.featureInspector.multipleSubscriptionsDataIsComingSoon')}
  />
);

export const ChartErrorMessage = React.memo(({ error }: { error: Error }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ChartAlternativeMessage
      title={t('dashboards.featureInspector.noDataTitle')}
      subtitle={t('dashboards.featureInspector.noDataErrorSubTitle')}
    />
  );
});
