import { FeatureType } from '@stigg-types/apiTypes';
import { Box, ExternalLink, Field, FormRenderProps, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import React from 'react';
import { SelectableIconButton } from '../../../../widgets/components/leftSidePanel/SelectableIconButton';
import { onSwitchChange } from '../../../../packages/plans/components/EntitlementRow';
import { EntitlementFullFields, EntitlementFormFields } from '../types';

export function getEntitlementValueFields(
  formRenderProps: FormRenderProps<EntitlementFormFields>,
  entitlement: EntitlementFullFields,
  index: number,
  withCustomEntitlementOption: boolean | undefined,
  setEntitlementPreviousValue: (featureId: string, value?: number | null) => void,
  previousValue: number | null | undefined,
): Field<EntitlementFormFields>[] {
  const { setFieldValue, setFieldTouched } = formRenderProps;

  let units =
    !entitlement.usageLimit || entitlement.usageLimit > 1
      ? entitlement.feature?.featureUnitsPlural || ''
      : entitlement.feature?.featureUnits || '';

  if (entitlement.hasUnlimitedUsage) {
    units = entitlement.feature?.featureUnitsPlural || '';
  }

  const isUsageLimitDisabled = entitlement.hasUnlimitedUsage || !!entitlement.isCustom;

  return [
    {
      type: 'layout',
      label: t('entitlements.featureValue'),
      layout: 'row',
      contentGridProps: {
        gap: 2,
      },
      fields: [
        {
          type: 'idle',
          hide: () => entitlement.feature.featureType !== FeatureType.Boolean,
          component: <Text.B2>Access granted</Text.B2>,
        },
        {
          type: 'text',
          id: `entitlements[${index}].usageLimit`,
          textFieldType: 'number',
          hideErrorText: true,
          autoFocus: true,
          hide: () => entitlement.feature.featureType === FeatureType.Boolean,
          endAdornment: isUsageLimitDisabled ? undefined : <Text.Caption>{units}</Text.Caption>,
          disabled: isUsageLimitDisabled,
          placeholder: isUsageLimitDisabled
            ? `${
                entitlement.hasUnlimitedUsage ? t('entitlements.unlimitedUsage') : t('entitlements.custom')
              } ${units?.toLowerCase()}`
            : undefined,
        },
        {
          type: 'custom',
          hide: () => entitlement.feature.featureType === FeatureType.Boolean,
          gridProps: {
            flex: undefined,
          },
          render: () => (
            <InformationTooltip
              arrow
              placement="top"
              $padding={2}
              title={<Text.B2>{t('entitlements.unlimitedUsage')}</Text.B2>}>
              <Box>
                <SelectableIconButton
                  isSelected={!!entitlement.hasUnlimitedUsage}
                  onClick={async () => {
                    setEntitlementPreviousValue(entitlement.feature.id, entitlement.usageLimit);
                    await onSwitchChange({
                      currentValue: entitlement.hasUnlimitedUsage,
                      switchField: 'hasUnlimitedUsage',
                      setFieldValue,
                      setFieldTouched,
                      index,
                      usageLimitValue: !entitlement.hasUnlimitedUsage ? undefined : previousValue,
                    });
                    if (!entitlement.hasUnlimitedUsage) {
                      setFieldValue(`entitlements[${index}].hasSoftLimit`, false, true);
                    }
                  }}
                  overrideStroke
                  icon="InfinityIcon"
                />
              </Box>
            </InformationTooltip>
          ),
        },
        {
          type: 'custom',
          hide: () => entitlement.feature.featureType === FeatureType.Boolean || !withCustomEntitlementOption,
          gridProps: {
            flex: undefined,
          },
          render: () => (
            <InformationTooltip
              arrow
              placement="top"
              $padding={4}
              title={
                <>
                  <Text.B2 mb={2} whiteSpace="pre-line">
                    <Trans
                      i18nKey="entitlements.customToggleTooltip"
                      t={t}
                      values={{ unitsPlural: entitlement.feature.featureUnitsPlural }}
                    />
                  </Text.B2>
                  <ExternalLink
                    label="Learn more"
                    url="https://docs.stigg.io/docs/plans#defining-variable-entitlement-values"
                  />
                </>
              }>
              <Box>
                <SelectableIconButton
                  isSelected={!!entitlement.isCustom}
                  onClick={async () => {
                    setEntitlementPreviousValue(entitlement.feature.id, entitlement.usageLimit);
                    await onSwitchChange({
                      currentValue: entitlement.isCustom,
                      switchField: 'isCustom',
                      setFieldValue,
                      setFieldTouched,
                      index,
                      usageLimitValue: !entitlement.isCustom ? undefined : previousValue,
                    });
                  }}
                  overrideStroke
                  icon="CustomEntitlement"
                />
              </Box>
            </InformationTooltip>
          ),
        },
      ],
    },
  ];
}
