import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ErrorCode,
  UpdateOnePromotionalEntitlementInput,
  UpdateOnePromotionalEntitlementMutation,
  UpdateOnePromotionalEntitlementMutationVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { apolloClient } from '../../../ApolloClient';
import { fetchCustomerByRefIdAction, fetchEntitlementsByRefIdAction } from '../customersSlice';

const UPDATE_PROMOTIONAL_ENTITLEMENTS = gql`
  mutation UpdateOnePromotionalEntitlement($input: UpdateOnePromotionalEntitlementInput!) {
    updateOnePromotionalEntitlement(input: $input) {
      id
    }
  }
`;

function updatePromotionalEntitlement(
  entitlementData: UpdateOnePromotionalEntitlementInput & { customerId: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  const { update, customerId } = entitlementData;
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        UpdateOnePromotionalEntitlementMutation,
        UpdateOnePromotionalEntitlementMutationVariables
      >({
        mutation: UPDATE_PROMOTIONAL_ENTITLEMENTS,
        variables: {
          input: {
            id: entitlementData.id,
            update: { ...update, usageLimit: update.usageLimit ? Math.floor(update.usageLimit) : null },
          },
        },
      });

      const newEntitlements = response.data?.updateOnePromotionalEntitlement;

      await Promise.all([
        dispatch(fetchCustomerByRefIdAction({ customerId })),
        dispatch(fetchEntitlementsByRefIdAction({ customerRefId: customerId, resourceRefId: undefined })),
      ]);

      return newEntitlements;
    },
    {
      successMessage: t('promotionalEntitlements.api.successEdit'),
      failureMessageHandler: () => t('promotionalEntitlements.api.editFailed'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('promotionalEntitlements.api.errorAlreadyGranted'),
      },
    },
    dispatch,
  );
}

export { updatePromotionalEntitlement };
