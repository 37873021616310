import OutlinedInput from '@mui/material/OutlinedInput';
import styled, { css } from 'styled-components';

export const Input = styled(OutlinedInput)<{
  $disableBorder?: boolean;
  $disablePadding?: boolean;
  $disableYPadding?: boolean;
}>`
  & .MuiOutlinedInput-notchedOutline {
    ${({ $disableBorder }) =>
      $disableBorder &&
      css`
        border: none !important;
      `}
  }
  & .MuiInputBase-input {
    font-size: 14px;
    padding-bottom: 0px;

    ${({ $disablePadding, $disableYPadding }) =>
      (!$disablePadding &&
        !$disableYPadding &&
        css`
          padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
        `) ||
      (!$disablePadding &&
        css`
          padding: ${({ theme }) => theme.spacing(0)} ${({ theme }) => theme.spacing(3)};
        `) ||
      css`
        padding: 0;
      `}
  }
`;
