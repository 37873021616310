import { Text, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { styled, getIconColor } from '@stigg-theme';
import { Users, FileText } from 'react-feather';
import { useImportContext } from '../../hooks/useStripeWizardImportContext';

const CustomersReviewContainer = styled(GridFlex.RowCenter)`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  padding: ${({ theme }) => theme.spacing(10)};
`;

export function ImportCustomersSummary() {
  const importContext = useImportContext();
  const { activeSubscriptionsCount, customersData, selectedCustomers, unSelectedCustomers } = importContext;
  const totalCustomersToImport = customersData.headerSelectionChecked
    ? customersData.totalCount - unSelectedCustomers.length
    : selectedCustomers.length;

  return (
    <CustomersReviewContainer>
      <Users size={36} color={getIconColor('active')} strokeWidth={1.5} />
      <Text.B2 ml={2} mr={6}>
        {t('integrations.selectedCustomersToImport', { count: totalCustomersToImport })}
      </Text.B2>
      <FileText size={36} color={getIconColor('active')} strokeWidth={1.5} />
      <Text.B2 ml={2}>{t('integrations.activeSubscriptions', { count: activeSubscriptionsCount })}</Text.B2>
    </CustomersReviewContainer>
  );
}
