import { drawFormFields, Field, GridFlex, FormRenderProps, Text } from '@stigg-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import React, { useMemo } from 'react';
import * as S from './CustomerJourney.style';
import { TooltipFields } from '../../../../../components/InformationTooltip';

export enum SubscriptionDowngradeBehavior {
  Immediately = 'IMMEDIATELY',
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
}

export type SubscriptionUpdateSetupFormFields = {
  subscriptionDowngradeBehavior: SubscriptionDowngradeBehavior;
};

export const SubscriptionUpdateSetupFormFieldsNames: (keyof SubscriptionUpdateSetupFormFields)[] = [
  'subscriptionDowngradeBehavior',
];

type SubscriptionUpdateSetupConfigurationProps = {
  formRenderProps: FormRenderProps<SubscriptionUpdateSetupFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
};

function computeFields(
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionUpdateSetupFormFields>[] {
  return [
    {
      type: 'inline',
      gridProps: { marginBottom: '16px' },
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" mb={4}>
              <Trans
                i18nKey="products.customerJourney.subscriptionUpdate.customerUpgrade"
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
          ),
        },
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              <Trans
                i18nKey="products.customerJourney.subscriptionUpdate.customerDowngrade"
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
          ),
        },
        {
          id: 'subscriptionDowngradeBehavior',
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: [
            {
              value: SubscriptionDowngradeBehavior.Immediately,
              displayValue: t('products.customerJourney.subscriptionUpdate.scheduleDowngradeDisabled'),
            },
            {
              value: SubscriptionDowngradeBehavior.EndOfBillingPeriod,
              displayValue: t('products.customerJourney.subscriptionUpdate.scheduleDowngradeEnabled'),
            },
          ],
        },
      ],
    },
  ];
}

function useFormFields(readonly: boolean, readonlyHideDottedText: boolean, readonlyTooltip: TooltipFields | undefined) {
  return useMemo<Field<SubscriptionUpdateSetupFormFields>[]>(
    () => computeFields(readonly, readonlyHideDottedText, readonlyTooltip),
    [readonly, readonlyHideDottedText, readonlyTooltip],
  );
}

export function SubscriptionUpdateSetupConfiguration({
  formRenderProps,
  readonly = false,
  readonlyHideDottedText = false,
  readonlyTooltip,
  highlight,
}: SubscriptionUpdateSetupConfigurationProps) {
  const fields = useFormFields(readonly, readonlyHideDottedText, readonlyTooltip);
  const content = (
    <GridFlex.Column container rowSpacing={3}>
      {drawFormFields(fields, formRenderProps)}
    </GridFlex.Column>
  );

  return highlight ? (
    <S.StyledBox $isHighlight={highlight} mt={4}>
      {content}
    </S.StyledBox>
  ) : (
    content
  );
}
