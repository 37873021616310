import { EventLogType, Hook } from '@stigg-types/apiTypes';
import { WebhookEventLogType } from '../../constants';

export const DEFAULT_USAGE_THRESHOLDS = [80, 100];

export type CreateHookFormFields = {
  endpoint: string;
  description: string;
  eventLogTypes: EventLogType[];
};

type HandleHookConfigurationProps = {
  currentHook?: Hook;
  values: CreateHookFormFields;
};

export const handleHookConfiguration = ({ currentHook, values }: HandleHookConfigurationProps) => {
  let configuration: { [key in WebhookEventLogType]?: any } | undefined;
  const isEntitlementUsageExceededAdded =
    (currentHook ? !currentHook.eventLogTypes.includes(EventLogType.EntitlementUsageExceeded) : true) &&
    values.eventLogTypes.includes(EventLogType.EntitlementUsageExceeded);

  if (isEntitlementUsageExceededAdded) {
    configuration = {
      [WebhookEventLogType.ENTITLEMENT_USAGE_EXCEEDED]: { thresholds: DEFAULT_USAGE_THRESHOLDS },
    };
  }

  return configuration;
};
