import { t } from 'i18next';
import * as Yup from 'yup';

const urlValidationRegex = /^https?:\/\/.*/g;
const slackcUrlValidationRegex = /^https:\/\/hooks.slack.com.*/g;

export const urlValidation = () =>
  Yup.string()
    .matches(urlValidationRegex, t('fieldValidationMessages.invalidUrl'))
    .test('url-validation', t('fieldValidationMessages.invalidUrlError'), (url) => {
      if (!url) {
        return false;
      }
      try {
        const urlObject = new URL(url);
        if (urlObject.hostname.includes('localhost') || urlObject.hostname.includes('127.0.0.1')) {
          return false;
        }
      } catch {
        return false;
      }

      return true;
    })
    .required(t('fieldValidationMessages.required'));

export const slackUrlValidation = () =>
  Yup.string()
    .matches(slackcUrlValidationRegex, t('fieldValidationMessages.invalidSlackUrl'))
    .required(t('fieldValidationMessages.required'));
