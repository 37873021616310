import { t } from 'i18next';
import { Grid, Text, PageCard, EmptyCardContent } from '@stigg-components';

type CompatibleAddonsNotSupportedProps = {
  notSupportedText?: string;
};

export function CompatibleAddonsNotSupported({ notSupportedText }: CompatibleAddonsNotSupportedProps) {
  return (
    <PageCard>
      <Text.H3 mb={2}>{t('addons.compatibleAddonsTitle')}</Text.H3>

      <EmptyCardContent>
        <Grid gap={2}>
          <Text.B2 color="secondary">{notSupportedText}</Text.B2>
        </Grid>
      </EmptyCardContent>
    </PageCard>
  );
}
