import {
  AddonFragment,
  PackageStatus,
  PlanFragment,
  PricingType,
  ProductSettingsInput,
  PublishMigrationType,
  SubscriptionEndSetup,
} from '@stigg-types/apiTypes';
import React, { useState } from 'react';
import { useConfirmationDialog } from '@stigg-common';
import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { Trans } from 'react-i18next';
import { useFindActiveExperiment } from '../../../../experiemnts/components/commom/useFindActiveExperiment';
import { ConfirmFreePlanAsDowngradePlan } from './ConfirmFreePlanAsDowngradePlan';
import { PackagePublishDisabledDueToActiveExperiment } from '../PackagePublishDisabledDueToActiveExperiment';
import { HandlePackagePublish } from './PackageEditTopBar';
import { PackagePublishDisabledDueToDraftBasePlan } from '../PackagePublishDisabledDueToDraftBasePlan';
import { normalizedPackageName } from '../../../../../i18n/utils';

export function usePublishPackageDialog(
  aPackage: PlanFragment | AddonFragment,
  handlePackagePublish: HandlePackagePublish,
) {
  const [checkboxSetAsDowngradePlan, setCheckboxSetAsDowngradePlan] = useState(true);

  const packageType = normalizedPackageName(aPackage.type);
  const activeExperiment = useFindActiveExperiment(aPackage.product?.refId);
  const basePlan = (aPackage as PlanFragment)?.basePlan;
  const isBasePlanADraft = basePlan && basePlan.status === PackageStatus.Draft;
  const shouldSuggestToSetPlanAsDowngradePlan = Boolean(
    aPackage.type === 'Plan' &&
      aPackage.pricingType === PricingType.Free &&
      aPackage.product?.productSettings.subscriptionEndSetup !== SubscriptionEndSetup.DowngradeToFree,
  );

  const composeProductSettingsToUpdate: () => (ProductSettingsInput & { productId: string }) | undefined = () => {
    if (!shouldSuggestToSetPlanAsDowngradePlan || !checkboxSetAsDowngradePlan || !aPackage?.product) {
      return undefined;
    }

    return {
      productId: aPackage.product.id,
      subscriptionCancellationTime: aPackage.product.productSettings.subscriptionCancellationTime,
      subscriptionStartPlanId: (aPackage as PlanFragment).product.productSettings.subscriptionStartPlan?.id,
      subscriptionStartSetup: (aPackage as PlanFragment).product.productSettings.subscriptionStartSetup,
      subscriptionEndSetup: SubscriptionEndSetup.DowngradeToFree,
      downgradePlanId: aPackage.id,
    };
  };

  return useConfirmationDialog({
    title: t('packages.publishConfirmDialogTitle', { packageType }),
    content: (
      <GridFlex.Column gap={6}>
        {!isBasePlanADraft && (
          <Text.B2>
            <Trans
              i18nKey="packages.publishConfirmDialogContent"
              t={t}
              values={{ packageType }}
              components={[<Text.B2 $bold={shouldSuggestToSetPlanAsDowngradePlan} display="inline" />]}
            />
          </Text.B2>
        )}
        {!isBasePlanADraft && shouldSuggestToSetPlanAsDowngradePlan && (
          <ConfirmFreePlanAsDowngradePlan
            checkboxSetAsDowngradePlan={checkboxSetAsDowngradePlan}
            toggleCheckboxSetAsDowngradePlan={() => setCheckboxSetAsDowngradePlan((prev) => !prev)}
          />
        )}
        {activeExperiment && <PackagePublishDisabledDueToActiveExperiment experimentRefId={activeExperiment.refId} />}
        {isBasePlanADraft && (
          <PackagePublishDisabledDueToDraftBasePlan planRefId={basePlan.refId} planName={basePlan.displayName} />
        )}
      </GridFlex.Column>
    ),
    confirmButtonColor: 'primary',
    confirmButtonText: t('packages.publishConfirmDialogConfirmButtonText'),
    disabled: Boolean(activeExperiment) || Boolean(isBasePlanADraft),
    handleConfirm: async () => {
      const productSettingsToUpdate = composeProductSettingsToUpdate();
      await handlePackagePublish(aPackage, PublishMigrationType.NewCustomers, productSettingsToUpdate);
    },
  });
}
