import { ActiveSubscriptionsFragment, SubscriptionStatus } from '@stigg-types/apiTypes';

export function isBasePlan(
  isTrialSubscription: boolean,
  planRefId: string | undefined,
  activePlanIds: string[],
  trialPlanIds: string[],
): boolean {
  if (!planRefId) {
    return false;
  }
  const list = isTrialSubscription ? trialPlanIds : activePlanIds;
  return !list.includes(planRefId);
}

export function findSubscriptionsPlansOfStatus(
  subscriptions: ActiveSubscriptionsFragment[],
  status: SubscriptionStatus,
) {
  return subscriptions
    .filter((subscription) => subscription.status === status)
    .map((subscription) => subscription.plan.refId);
}
