import { Box, Grid, Icon, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';
import countryList from 'country-list';
import { CountryFlag } from '../../../../packages/pricing/components/currency/CountryWithFlagByCode';

export type SubscriptionPriceLocalizationIconProps = {
  billingCountryCode?: string | null;
  showDefaultPricingIcon?: boolean;
};

export function SubscriptionPriceLocalizationIcon({
  billingCountryCode,
  showDefaultPricingIcon,
}: SubscriptionPriceLocalizationIconProps) {
  if (!billingCountryCode && !showDefaultPricingIcon) {
    return null;
  }

  const localizationTooltip = billingCountryCode
    ? `${t('pricing.currency.countryFlagTooltip')} ${countryList.getName(billingCountryCode)}`
    : t('pricing.currency.defaultPricingTooltip');

  const localizationIcon = billingCountryCode ? (
    <CountryFlag countryCode={billingCountryCode} />
  ) : (
    <Box ml={0.5} mr={0.5}>
      <Icon type="materialIcons" icon="Language" size={20} color="default" />
    </Box>
  );

  return (
    <InformationTooltip placement="top" arrow title={<Text.B2>{localizationTooltip}</Text.B2>}>
      <Grid>{localizationIcon}</Grid>
    </InformationTooltip>
  );
}
