import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Package as PackageIcon } from 'react-feather';
import { EmptyList } from '@stigg-components';
import { useQueryParam } from '@stigg-common';
import { fetchPlansAction, setArchiveDialog } from '../plansSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Packages, { PackageListFragment } from '../../common/components/Packages';
import { useNavigation } from '../../../navigation/useNavigation';
import { useCreatePlan } from './useCreatePlan';
import useNavigateToDraft from '../../common/hooks/useEditPackageNaivatge';
import { ArchivePlanModal } from './ArchivePlanModal';

function Plans() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const plans = useSelector((state: RootState) => state.plansReducer.plans);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const isLoading = useSelector((state: RootState) => state.plansReducer.isLoading);
  const { value: createQueryParam } = useQueryParam('create');
  const { createPlanComponent, setIsCreatePlanDialogOpen } = useCreatePlan();
  const navigateToDraft = useNavigateToDraft();

  useEffect(() => {
    setIsCreatePlanDialogOpen(!!createQueryParam);
  }, [createQueryParam, setIsCreatePlanDialogOpen]);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchPlansAction({ environmentId: currentEnvironmentId }));
    }
  }, [dispatch, currentEnvironmentId]);

  const onDeleteClick = (plan: PackageListFragment) => {
    dispatch(setArchiveDialog({ plan, isOpen: true }));
  };

  const onNewPlanClick = () => {
    setIsCreatePlanDialogOpen(true);
  };

  const onSearch = useCallback(
    (searchStr: string) => {
      void dispatch(fetchPlansAction({ search: searchStr, environmentId: currentEnvironmentId }));
    },
    [dispatch, currentEnvironmentId],
  );
  const onEditClick = (plan: PackageListFragment) => navigateToDraft(plan);

  return (
    <>
      <Packages
        title={t('plans.plans')}
        isLoading={isLoading}
        packages={plans}
        onNew={onNewPlanClick}
        onRowClick={(plan: PackageListFragment) =>
          navigation.navigateTo(navigation.appRoutes.planPage(plan.refId, plan.versionNumber))
        }
        onSearch={onSearch}
        newButtonText={t('plans.new')}
        fetchAction={fetchPlansAction}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        emptyState={(isSearching) => (
          <EmptyList
            title={isSearching ? t('plans.emptySearchText') : t('plans.emptyStateText')}
            linkText={isSearching ? '' : t('plans.emptyStateTextAddPlanLink')}
            onLinkClick={onNewPlanClick}
            icon={PackageIcon}
          />
        )}
        searchPlaceholder={t('plans.searchPlaceholder')}
        packageType="Plan"
      />
      <ArchivePlanModal />
      {createPlanComponent}
    </>
  );
}

export default Plans;
