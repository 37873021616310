import { Menu as MenuIcon } from '@mui/icons-material';
import { t } from 'i18next';
import { useTheme } from '@mui/material/styles';
import { GridFlex, useMediaQuery } from '@stigg-components';
import { isEmpty } from 'lodash';
import { PageBreadcrumbs } from '../../../../pageBreadcrumbs/PageBreadcrumbs';
import * as S from './MenuAndBreadcrumbs.style';
import { useBreadcrumbsContext } from '../../../../../modules/common';

export function MenuAndBreadcrumbs({
  onDrawerToggle,
  isDrawerExpanded,
}: {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  isDrawerExpanded: boolean;
}) {
  const { breadcrumbsState } = useBreadcrumbsContext();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <GridFlex.RowCenter>
      {!isDrawerExpanded && !isMdUp && (
        <S.MenuButton aria-label={t('openDrawer')} onClick={onDrawerToggle} size="large">
          <MenuIcon />
        </S.MenuButton>
      )}
      {!isEmpty(breadcrumbsState) && <PageBreadcrumbs links={breadcrumbsState || []} />}
    </GridFlex.RowCenter>
  );
}
