import { gql } from '@apollo/client';
import { FetchProductByRefIdQueryVariables, FetchProductByRefIdQuery, Product } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { RootState } from '../../../redux/store';
import { PRICE_FRAGMENT } from '../../packages/plans/queries/priceFragment';
import { OVERAGE_PRICE_FRAGMENT } from '../../packages/plans/queries/overagePriceFragment';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    createdAt
    description
    displayName
    refId
    environmentId
    updatedAt
    isDefaultProduct
    additionalMetaData
    multipleSubscriptions
    awsMarketplaceProductId
    awsMarketplaceProductCode
    productSettings {
      ...ProductSettingsFragment
    }
    plans {
      ...ProductPlanFragment
    }
    addons {
      ...ProductAddonFragment
    }
    autoCancellationRules {
      ...AutoCancellationRule
    }
    subscriptionUpdateUsageResetCutoffRule {
      behavior
    }
  }
  fragment ProductPlanFragment on Plan {
    id
    refId
    displayName
    type
    status
    updatedAt
    isLatest
    hiddenFromWidgets
    versionNumber
    draftSummary {
      version
      updatedAt
      updatedBy
    }
    basePlan {
      id
      refId
      displayName
    }
    entitlements {
      ...ProductPackageEntitlement
    }
    inheritedEntitlements {
      ...ProductPackageEntitlement
    }
    prices {
      ...PriceFragment
    }
    overagePrices {
      ...OveragePriceFragment
    }
    pricingType
    defaultTrialConfig {
      duration
      units
      budget {
        limit
      }
    }
    minimumSpend {
      billingPeriod
      minimum {
        currency
        amount
      }
    }
  }
  fragment ProductAddonFragment on Addon {
    id
    refId
    displayName
    type
    status
    updatedAt
    entitlements {
      ...ProductPackageEntitlement
    }
  }
  fragment ProductPackageEntitlement on PackageEntitlement {
    id
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    resetPeriod
    packageId
    hiddenFromWidgets
    displayNameOverride
    isCustom
    featureId
    feature {
      displayName
      description
      refId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
      hasMeter
    }
  }
  fragment AutoCancellationRule on AutoCancellationRule {
    sourcePlan {
      refId
      displayName
    }
    targetPlan {
      refId
      displayName
    }
  }
  fragment ProductSettingsFragment on ProductSettings {
    subscriptionEndSetup
    subscriptionCancellationTime
    prorateAtEndOfBillingPeriod
    downgradePlan {
      id
      refId
      displayName
      status
      pricingType
      defaultTrialConfig {
        units
        duration
        budget {
          limit
        }
      }
    }
    subscriptionStartSetup
    subscriptionStartPlan {
      id
      refId
      displayName
      status
      pricingType
      defaultTrialConfig {
        units
        duration
        budget {
          limit
        }
      }
    }
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
`;

const FETCH_PRODUCT = gql`
  query FetchProductByRefId($filter: ProductFilter!) {
    products(filter: $filter) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

type FetchProductByRefIdProps = { refId: string; silentFetch?: boolean };

async function fetchProductByRefId(
  { refId }: FetchProductByRefIdProps,
  { getState }: { getState: () => RootState },
): Promise<Product | any | null> {
  const { accountReducer } = getState();
  if (!accountReducer.currentEnvironmentId) {
    throw new Error('environment Id must be set');
  }
  const res = await apolloClient.query<FetchProductByRefIdQuery, FetchProductByRefIdQueryVariables>({
    query: FETCH_PRODUCT,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        refId: {
          eq: refId,
        },
        environmentId: { eq: accountReducer.currentEnvironmentId || '' },
      },
    },
  });

  const products = res.data.products.edges;
  return products.length > 0 ? products[0].node : null;
}

export { fetchProductByRefId };
