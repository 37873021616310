import { omit } from 'lodash';
import { Button, TextField, Grid, Box, GridFlex, ActiveIntegrationIcons } from '@stigg-components';
import { Formik, FormikHelpers } from 'formik';
import { useIntegrations } from '@stigg-common';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import { RootState } from '../../../../redux/store';
import { CustomerIcon } from '../CustomerIcon';
import { VendorIdentifier } from '../../../integrations/constants';

export type UpdateCustomerFormFields = {
  customerId: string;
  name?: string | null;
  email?: string | null;
  billingId?: string | null;
};

const validationSchema = () =>
  Yup.object().shape({
    customerId: Yup.string().required(t('customers.yup.required')),
    name: Yup.string(),
    email: Yup.string().email(),
  });

export function CustomerUpdateForm({
  customer,
  onCancel,
  onUpdate,
  editableFields,
}: {
  customer: UpdateCustomerFormFields;
  onCancel: () => void;
  onUpdate: (onUpdate: UpdateCustomerFormFields) => void;
  editableFields?: Array<keyof UpdateCustomerFormFields>;
}) {
  const isLoading = useSelector((root: RootState) => root.customersReducer.isLoading);
  const { isSyncingCustomerAndSubscriptions } = useIntegrations();

  const initialValues: UpdateCustomerFormFields = {
    customerId: customer.customerId,
    name: customer.name || '',
    email: customer.email || '',
    billingId: customer.billingId || '',
  };

  const visibleFields = new Set(editableFields || Object.keys(initialValues));

  const handleSubmit = (values: UpdateCustomerFormFields, actions: FormikHelpers<UpdateCustomerFormFields>) => {
    onUpdate(omit(values, 'billingId'));
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema()}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur }) => (
        <Box>
          {isLoading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={3}>
                {visibleFields.has('customerId') && (
                  <Grid item>
                    <TextField
                      disabled
                      name="customerId"
                      label={t('customers.customerId')}
                      value={values.customerId}
                      touched={!!touched.customerId}
                      error={!!errors.customerId}
                      fullWidth
                      errorText={errors.customerId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                )}

                {visibleFields.has('billingId') && (
                  <GridFlex.Column item>
                    <TextField
                      name="billingId"
                      label={t('customers.billingId')}
                      value={values.billingId}
                      touched={!!touched.billingId}
                      error={!!errors.billingId}
                      fullWidth
                      errorText={errors.billingId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled
                      captionText={t('customers.billingIdHelper')}
                    />
                  </GridFlex.Column>
                )}

                {visibleFields.has('name') && (
                  <Grid item>
                    <TextField
                      name="name"
                      label={t('customers.nameForm')}
                      touched={!!touched.name}
                      error={!!errors.name}
                      value={values.name}
                      fullWidth
                      errorText={errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      shouldMaskFromHj
                      optional
                    />
                  </Grid>
                )}

                {visibleFields.has('email') && (
                  <Grid item>
                    <TextField
                      maxRows={4}
                      name="email"
                      label={t('customers.emailForm')}
                      value={values.email}
                      touched={!!touched.email}
                      error={!!errors.email}
                      fullWidth
                      errorText={errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      shouldMaskFromHj
                      optional={!editableFields?.includes('email')}
                      endAdornment={values.email && <CustomerIcon domain={values.email?.split('@')[1] || ''} />}
                    />
                  </Grid>
                )}

                <Grid item container mt={4} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ActiveIntegrationIcons
                      excludedIntegrations={
                        !isSyncingCustomerAndSubscriptions || !customer.billingId ? [VendorIdentifier.Stripe] : []
                      }
                    />
                  </Grid>
                  <Grid item alignSelf="end" mt={4}>
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('customers.cancelButton')}
                    </Button>
                    <Button disabled={!dirty || !isValid} type="submit" variant="contained" color="primary">
                      {t('sharedComponents.editSaveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </GridFlex.Column>
            </form>
          )}
        </Box>
      )}
    </Formik>
  );
}
