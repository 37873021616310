import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { CustomDrawer, Field, Form, useExitConfirmationDialog } from '@stigg-components';
import { generateRandomSlug } from '@stigg-common';
import { EntitlementsTable } from './EntitlementsTable';
import { PlanEntitlement } from '../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { EntitlementSettings } from '../../../entitlements/components/entitlementSettings/EntitlementSettings';
import { EntitlementFormFields } from '../../../entitlements/components/entitlementSettings/types';
import { getResetPeriodConfig } from './EditEntitlementDrawer';
import { useSubStepsState } from '../../pricing/components/SetPriceWizard/form/utils/useSubStepsState';

type OverrideEntitlementDialogProps = {
  open: boolean;
  handleClose: () => void;
  entitlement: PlanEntitlement | null;
  handleSubmit: (values: any) => Promise<void>;
  withCustomEntitlementOption?: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
};

function OverrideEntitlementForm({
  handleClose,
  entitlement,
  handleSubmit,
  withCustomEntitlementOption,
  setHasUnsavedChanges,
}: Omit<OverrideEntitlementDialogProps, 'open'>) {
  const { entitlementsSettingsImprovedUi } = useFlags<FeatureFlags>();
  const entitlementsState = useSubStepsState();

  if (!entitlement) {
    return null;
  }

  const initialValues: EntitlementFormFields = {
    entitlements: [
      {
        ...entitlement,
        uuid: generateRandomSlug(),
        isConfirmed: true,
        resetPeriodConfiguration:
          entitlement.resetPeriodConfiguration && getResetPeriodConfig(entitlement.resetPeriodConfiguration),
      },
    ],
  };

  return (
    <Form
      withStickyFooter
      initialValues={initialValues}
      submitButtonText={t('sharedComponents.override')}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      setHasUnsavedChanges={setHasUnsavedChanges}
      fields={(formRenderProps) =>
        entitlementsSettingsImprovedUi
          ? formRenderProps.values.entitlements.map(
              (_, index): Field<EntitlementFormFields> => ({
                type: 'custom',
                render: () => (
                  <EntitlementSettings
                    index={index}
                    excludedFeaturesIds={[]}
                    entitlementsState={entitlementsState}
                    withCustomEntitlementOption={withCustomEntitlementOption}
                    formRenderProps={formRenderProps}
                    permanent
                  />
                ),
              }),
            )
          : [
              {
                type: 'custom',
                render: () => (
                  <EntitlementsTable
                    errors={formRenderProps.errors}
                    touched={formRenderProps.touched}
                    handleBlur={formRenderProps.handleBlur}
                    entitlements={formRenderProps.values.entitlements}
                    handleChange={formRenderProps.handleChange}
                    height={330}
                    setFieldValue={formRenderProps.setFieldValue}
                    setFieldTouched={formRenderProps.setFieldTouched}
                    withCustomEntitlementOption={withCustomEntitlementOption}
                  />
                ),
              },
            ]
      }
    />
  );
}

export function OverrideEntitlementDrawer({
  open,
  handleClose,
  entitlement,
  handleSubmit,
  withCustomEntitlementOption,
}: Omit<OverrideEntitlementDialogProps, 'setHasUnsavedChanges'>) {
  const { exitConfirmationDialog, onExit, setHasUnsavedChanges } = useExitConfirmationDialog({
    onClose: handleClose,
    shouldConfirm: open,
  });

  return (
    <>
      <CustomDrawer title={t('entitlements.overrideEntitlement')} isOpen={open} onClose={onExit}>
        <OverrideEntitlementForm
          entitlement={entitlement}
          handleSubmit={handleSubmit}
          withCustomEntitlementOption={withCustomEntitlementOption}
          handleClose={onExit}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      </CustomDrawer>

      {exitConfirmationDialog}
    </>
  );
}
