import { t } from 'i18next';
import { ChangeType, PackageEntitlementChangeFragment, WidgetType } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { formatFeatureUnits } from '../../../../common/formatFeatureUnits';
import { ChangeGroupBox, ChangeRow, getChange } from './DiffBaseComponents';
import { getWidgetVisibilityText } from './PackageDiff';

function formatWithSoftLimitPostfix(value: string, hasSoftLimit = false) {
  return `${value}${hasSoftLimit ? ' (soft limit)' : ''}`;
}

export function PackageEntitlementChangeRow({
  before,
  after,
  changeType,
  isAddon,
}: {
  before?: PackageEntitlementChangeFragment | null;
  after?: PackageEntitlementChangeFragment | null;
  changeType?: ChangeType | null;
  isAddon: boolean;
}) {
  const usageLimitChange = getChange(before?.usageLimit, after?.usageLimit);
  const hasUnlimitedLimitChange = getChange(before?.hasUnlimitedUsage || false, after?.hasUnlimitedUsage || false);
  const hasSoftLimitChange = getChange(before?.hasSoftLimit || false, after?.hasSoftLimit || false);
  const isCustomChange = getChange(before?.isCustom || false, after?.isCustom || false);

  return (
    <ChangeGroupBox changeType={changeType}>
      <ChangeRow
        before={before?.feature.displayName}
        after={after?.feature.displayName}
        label="Feature name"
        showAlways
        {...getChange(before?.feature.displayName, after?.feature.displayName)}
      />
      <ChangeRow
        before={before?.feature.refId}
        after={after?.feature.refId}
        label="Feature ID"
        showAlways
        {...getChange(before?.feature.refId, after?.feature.refId)}
      />
      {(!isNil(before?.usageLimit) ||
        !isNil(after?.usageLimit) ||
        before?.hasUnlimitedUsage ||
        after?.hasUnlimitedUsage ||
        before?.hasSoftLimit ||
        after?.hasSoftLimit) && (
        <ChangeRow
          before={
            before?.hasUnlimitedUsage
              ? t('entitlements.unlimitedUsage').toString()
              : before?.isCustom
              ? formatWithSoftLimitPostfix(t('pricing.isCustom').toString(), !!before.hasSoftLimit)
              : before?.feature
              ? formatWithSoftLimitPostfix(formatFeatureUnits(before.feature, before.usageLimit), !!before.hasSoftLimit)
              : null
          }
          after={
            after?.hasUnlimitedUsage
              ? t('entitlements.unlimitedUsage').toString()
              : after?.isCustom
              ? formatWithSoftLimitPostfix(t('pricing.isCustom').toString(), !!after.hasSoftLimit)
              : after?.feature
              ? formatWithSoftLimitPostfix(formatFeatureUnits(after.feature, after.usageLimit), !!after.hasSoftLimit)
              : null
          }
          label="Value"
          showAlways
          changeType={
            hasUnlimitedLimitChange.changeType ||
            isCustomChange.changeType ||
            usageLimitChange.changeType ||
            hasSoftLimitChange.changeType
          }
        />
      )}
      {after?.resetPeriod && (
        <ChangeRow
          before={t(`entitlements.entitlementResetPeriod.${before?.resetPeriod}`).toString()}
          after={t(`entitlements.entitlementResetPeriod.${after?.resetPeriod}`).toString()}
          label="Reset period"
          showAlways
          {...getChange(before?.resetPeriod, after?.resetPeriod)}
        />
      )}
      {isAddon && (
        <ChangeRow
          before={before?.behavior}
          after={after?.behavior}
          label={t('entitlements.entitlementBehavior.name')}
          renderValue={(value) => t(`entitlements.entitlementBehavior.values.${value}`)}
          showAlways={isAddon}
          {...getChange(before?.behavior, after?.behavior)}
        />
      )}
      {after?.hiddenFromWidgets && (
        <ChangeRow
          showAlways
          label={t('widgets.visibility.visibility')}
          before={getWidgetVisibilityText({
            hiddenFromWidgets: before?.hiddenFromWidgets,
            widgetType: WidgetType.Paywall,
          })}
          after={getWidgetVisibilityText({
            hiddenFromWidgets: after?.hiddenFromWidgets,
            widgetType: WidgetType.Paywall,
          })}
          {...getChange(before?.hiddenFromWidgets, after.hiddenFromWidgets)}
        />
      )}

      {after?.displayNameOverride && (
        <ChangeRow
          showAlways
          label={t('widgets.displayNameOverride.customDisplayText')}
          before={before?.displayNameOverride}
          after={after?.displayNameOverride}
          {...getChange(before?.displayNameOverride, after?.displayNameOverride)}
        />
      )}
    </ChangeGroupBox>
  );
}
