import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { User } from '@stigg-types/apiTypes';
import { init as initCommandBar } from 'commandbar';
import { RootState } from '../../redux/store';
import { useNavigation } from '../navigation/useNavigation';
import { setCustomersSearch } from './setCustomersFetch';
import { setSubscriptionsSearch } from './setSubscriptionsFetch';
import { setPlansSearch } from './setPlansFetch';
import { setAddonsSearch } from './setAddonsFetch';
import { setFeaturesFetch } from './setFeaturesFetch';
import { setProductsFetch } from './setProductsFetch';
import { setCouponsFetch } from './setCouponsFetch';
import config from '../../env.config';
import { useCurrentMember } from '../auth/hooks/useCurrentMember';
import { useStiggTheme } from '../../theme';

export const MAX_RESULTS_PER_COMMANDBAR_SEARCH = 3;

export function useCommandBar() {
  const { theme } = useStiggTheme();
  const navigation = useNavigation();
  const { commandBar: commandBarEnabled } = useFlags<FeatureFlags>();
  const accountId = useSelector((state: RootState) => state.accountReducer.account?.id);
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentMember = useCurrentMember();
  const user: User | null = useSelector((state: RootState) => state.authReducer.user);
  const { commandBarOrgId } = config;

  useEffect(() => {
    if (!commandBarEnabled) {
      return () => {};
    }

    if (!window.CommandBar && commandBarOrgId) {
      initCommandBar(commandBarOrgId);
    }

    if (currentMember && environmentId) {
      void window.CommandBar.boot(currentMember.id, {
        email: user?.email,
        environmentId,
        accountId,
      });

      window.CommandBar.addRouter((url: string) => navigation.navigateTo(url));

      if (theme) {
        // @ts-ignore
        window.CommandBar.setThemeMode(theme.isLightTheme ? 'light_mode' : 'dark_mode');
      }

      setCustomersSearch(environmentId, navigation, theme.isLightTheme);
      setSubscriptionsSearch(environmentId, navigation, theme.isLightTheme);
      setPlansSearch(environmentId, navigation, theme.isLightTheme);
      setAddonsSearch(environmentId, navigation, theme.isLightTheme);
      setFeaturesFetch(environmentId, navigation, theme.isLightTheme);
      setProductsFetch(environmentId, navigation, theme.isLightTheme);
      setCouponsFetch(environmentId, navigation, theme.isLightTheme);
    }

    return () => {
      window.CommandBar?.shutdown();
    };
  }, [accountId, environmentId, currentMember, user, navigation, commandBarEnabled, commandBarOrgId, theme]);
}
