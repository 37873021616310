import styled, { css } from 'styled-components/macro';
import { Checkbox, Text, GridFlex, Card, ExternalLink } from '@stigg-components';
import { ReactComponent as PayWallPreviewSvg } from '@assets/images/widgets/paywallPreview.svg';

const StyledWidgetSelectCard = styled(Card)<{ $isSelected: boolean }>`
  cursor: pointer;
  &:hover {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }

  ${({ $isSelected, theme }) => {
    if ($isSelected) {
      return css`
        background: ${theme.itamar.palette.background.lightBackground2};
        border-color: ${theme.itamar.palette.primary.main};
      `;
    }

    return '';
  }}
`;

type WidgetSelectCardProps = {
  isChecked: boolean;
  onClick: () => void;
  title: string;
  description?: string;
  link: string;
  linkLabel: string;
};

export function WidgetSelectCard({ isChecked, onClick, title, description, link, linkLabel }: WidgetSelectCardProps) {
  return (
    <GridFlex.RowCenter>
      <StyledWidgetSelectCard $isSelected={isChecked} padding={4} onClick={onClick}>
        <GridFlex.Row container gap={4}>
          <GridFlex.Column item flex={1}>
            <PayWallPreviewSvg />
          </GridFlex.Column>
          <GridFlex.Column item flex={4} gap={2}>
            <Text.B2 $bold color="primary">
              {title}
            </Text.B2>
            {description && <Text.B2 color="secondary">{description}</Text.B2>}
            <ExternalLink label={linkLabel} url={link} />
          </GridFlex.Column>
          <GridFlex.Row item flex={1} justifyContent="flex-end" alignSelf="start">
            <Checkbox noPadding checked={isChecked} onChange={onClick} />
          </GridFlex.Row>
        </GridFlex.Row>
      </StyledWidgetSelectCard>
    </GridFlex.RowCenter>
  );
}
