import { Text, Grid, GridFlex, Icon } from '@stigg-components';
import { t } from 'i18next';
import moment from 'moment';
import { SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { Navigation, useNavigation } from '../../../../../modules/navigation/useNavigation';
import { TaskTitle } from './TaskTitle';
import * as S from './TasksList.style';
import { TaskStatusCard } from './TaskStatusCard';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

function SuccessMigrationTask({
  task,
  navigation,
}: {
  task: SubscriptionMigrationTaskFragment;
  navigation: Navigation;
}) {
  return (
    <S.TaskBox flexDirection="row" $status="success" container wrap="nowrap">
      <Grid item>
        <S.CheckCircle data-testid="check-circle-svg-migration-success-card" color="#4caf50" size={20} />
      </Grid>
      <Grid container item ml={2}>
        <TaskTitle
          navigation={navigation}
          task={task}
          start={t('navbar.changesOf')}
          end={t('navbar.customersMigratedSuccessfully', { affectedCustomersCount: task.affectedCustomersCount })}
        />
        <Grid item>
          <Text.B2 color="secondary">{moment(task.startDate || task.createdAt).fromNow()}</Text.B2>
        </Grid>
      </Grid>
    </S.TaskBox>
  );
}

function PendingMigrationTask({
  task,
  navigation,
}: {
  task: SubscriptionMigrationTaskFragment;
  navigation: Navigation;
}) {
  return (
    <S.TaskBox $status="pending" container flexWrap="wrap">
      <TaskTitle
        navigation={navigation}
        task={task}
        start={t('navbar.applyingChanges')}
        end={t('navbar.exitingCustomers')}
        textColor="secondary"
      />
      <GridFlex.RowCenter item container mt={2}>
        <Icon type="materialIcons" icon="Timer" color="active" overrideFill={false} />

        <Text.B2 color="secondary" sx={{ ml: 2 }}>
          {t('navbar.pending')}
        </Text.B2>
      </GridFlex.RowCenter>
    </S.TaskBox>
  );
}

function InProgressTask({ task, navigation }: { task: SubscriptionMigrationTaskFragment; navigation: Navigation }) {
  return (
    <S.TaskBox $status="inProgress" container>
      <TaskTitle
        navigation={navigation}
        task={task}
        start={t('navbar.applyingChanges')}
        end={t('navbar.exitingCustomers')}
      />
      <Grid item mt={1} mb={2}>
        <Text.B2 color="secondary">{moment(task.startDate || task.createdAt).fromNow()}</Text.B2>
      </Grid>
      <Grid item>
        <LinearProgressWithLabel value={task.progress} />
      </Grid>
    </S.TaskBox>
  );
}

function FailedTask({ task, navigation }: { task: SubscriptionMigrationTaskFragment; navigation: Navigation }) {
  return (
    <S.TaskBox $status="error" container>
      <TaskTitle
        navigation={navigation}
        task={task}
        start={t('navbar.applyingChanges')}
        end={t('navbar.exitingCustomers')}
        textColor="primary"
      />
      <Grid item container alignItems="center" display="flex" mt={2}>
        <S.ErrorOutline sx={{ mr: 2 }} />
        <Text.B2 color="primary">
          {t('navbar.failed')} {moment(task.startDate || task.createdAt).fromNow()}
        </Text.B2>
      </Grid>
    </S.TaskBox>
  );
}

export function MigrationTask({ task }: { task: SubscriptionMigrationTaskFragment }) {
  const navigation = useNavigation();

  return (
    <TaskStatusCard
      taskStatus={task.status}
      onComplete={<SuccessMigrationTask task={task} navigation={navigation} />}
      onPending={<PendingMigrationTask task={task} navigation={navigation} />}
      onInProgress={<InProgressTask task={task} navigation={navigation} />}
      onFailure={<FailedTask task={task} navigation={navigation} />}
    />
  );
}
