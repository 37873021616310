import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  DeleteOnePromotionalEntitlementMutation,
  DeleteOnePromotionalEntitlementMutationVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { apolloClient } from '../../../ApolloClient';
import { fetchCustomerByRefIdAction, fetchEntitlementsByRefIdAction } from '../customersSlice';

const DELETE_PROMOTIONAL_ENTITLEMENT = gql`
  mutation deleteOnePromotionalEntitlement($input: DeleteOnePromotionalEntitlementInput!) {
    deleteOnePromotionalEntitlement(input: $input) {
      id
    }
  }
`;

function deletePromotionalEntitlement(
  deleteData: { promotionalEntitlementId: string; customerRefId: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        DeleteOnePromotionalEntitlementMutation,
        DeleteOnePromotionalEntitlementMutationVariables
      >({
        mutation: DELETE_PROMOTIONAL_ENTITLEMENT,
        variables: {
          input: {
            id: deleteData.promotionalEntitlementId,
          },
        },
      });

      await Promise.all([
        dispatch(fetchCustomerByRefIdAction({ customerId: deleteData.customerRefId })),
        dispatch(fetchEntitlementsByRefIdAction({ customerRefId: deleteData.customerRefId, resourceRefId: undefined })),
      ]);

      return response.data?.deleteOnePromotionalEntitlement;
    },
    {
      successMessage: t('promotionalEntitlements.api.successDelete'),
      failureMessageHandler: () => t('promotionalEntitlements.api.deleteFailed'),
    },
    dispatch,
  );
}

export { deletePromotionalEntitlement };
