import { t } from 'i18next';
import { EntitlementResetPeriod, PackageEntitlement } from '@stigg-types/apiTypes';

function getResetPeriodText(resetPeriod: EntitlementResetPeriod): string {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return t('dashboards.featureInspector.resetPeriod.year');
    case EntitlementResetPeriod.Month:
      return t('dashboards.featureInspector.resetPeriod.month');
    case EntitlementResetPeriod.Week:
      return t('dashboards.featureInspector.resetPeriod.week');
    case EntitlementResetPeriod.Day:
      return t('dashboards.featureInspector.resetPeriod.day');
    case EntitlementResetPeriod.Hour:
      return t('dashboards.featureInspector.resetPeriod.hour');
    default:
      return '';
  }
}

export function composeCurrentUsageWidgetSubtitle(entitlement: PackageEntitlement, showUncompletedPeriod: boolean) {
  if (!entitlement.resetPeriod) {
    return t('dashboards.featureInspector.currentUsage');
  }

  const payload = { resetPeriod: getResetPeriodText(entitlement.resetPeriod) };

  return showUncompletedPeriod
    ? t('dashboards.featureInspector.showUncompletedPeriod', payload)
    : t('dashboards.featureInspector.showCompletedPeriod', payload);
}

export function composeTimeBasedWidgetSubtitle(
  entitlement: PackageEntitlement,
  showUncompletedPeriod: boolean,
  daysBack: number,
) {
  if (!entitlement.resetPeriod) {
    return t('dashboards.featureInspector.LastXDays', { num: daysBack });
  }

  const payload = { resetPeriod: getResetPeriodText(entitlement.resetPeriod) };

  return showUncompletedPeriod
    ? t('dashboards.featureInspector.showUncompletedPeriod', payload)
    : t('dashboards.featureInspector.showCompletedPeriod', payload);
}
