import { Alert, AlertTitle, Box, Grid, GridFlex, Link, Text, useScrollToAnchor } from '@stigg-components';
import { AddonFragment, PackageStatus, PlanFragment, PublishMigrationType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useState } from 'react';
import { DialogActionsButtons } from '../../../../components/Dialog';
import Loader from '../../../../components/Loader';
import { useFindActiveExperiment } from '../../../experiemnts/components/commom/useFindActiveExperiment';
import { getAddonsWithMissingPlanCurrencies } from '../../plans/components/planPage/hooks/useAddonMissingCurrencies';
import { ChangesMigrationSelection } from './ChangesMigrationSelection';
import { PackageDiff } from './packageDiff/PackageDiff';
import { PackagePublishDisabledDueToActiveExperiment } from './PackagePublishDisabledDueToActiveExperiment';
import { PackagePublishDisabledDueToDraftBasePlan } from './PackagePublishDisabledDueToDraftBasePlan';

export function ReviewAndPublishPackageChanges({
  aPackage,
  onCancel,
  onPublish,
  isLoading,
}: {
  aPackage: PlanFragment | AddonFragment;
  onCancel: () => void;
  onPublish: (publishMigrationType: PublishMigrationType) => Promise<void>;
  isLoading?: boolean;
}) {
  const { scrollToAnchorAndHighlight: scrollToCompatibleAddons } = useScrollToAnchor({
    anchor: 'planCompatibleAddons',
  });
  const [checked, setChecked] = useState([false, false, false, false]);
  const [isPublishingInProgress, setIsPublishingInProgress] = useState(false);
  const [affectOption, setAffectOption] = useState(PublishMigrationType.NewCustomers);

  const activeExperiment = useFindActiveExperiment(aPackage.product?.refId);

  const handlePublish = async () => {
    setIsPublishingInProgress(true);
    await onPublish(affectOption);
    setIsPublishingInProgress(false);
  };

  const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = [...checked];
    newChecked[index] = event.target.checked;
    setChecked(newChecked);
  };

  const changes = aPackage.draftDetails?.changes;
  const hasMultipleCurrencies = aPackage?.prices?.some((price) => !!price.billingCountryCode);
  const changesOtherThanPricing = omit(changes, ['pricingType', 'prices', 'minimumSpend', '__typename']);
  const hasChangesOtherThanPricing = !isEmpty(Object.values(changesOtherThanPricing).filter((x) => !isEmpty(x)));

  const childPlansWithDraft = aPackage.draftDetails ? (aPackage as PlanFragment).draftDetails?.childPlansWithDraft : [];
  const affectedChildPlans = aPackage.draftDetails ? (aPackage as PlanFragment).draftDetails?.affectedChildPlans : [];
  const basePlan = (aPackage as PlanFragment)?.basePlan;
  const isBasePlanADraft = basePlan && basePlan.status === PackageStatus.Draft;
  const addonsWithMissingCurrency =
    aPackage.type === 'Plan' ? getAddonsWithMissingPlanCurrencies(aPackage as PlanFragment) : [];

  const isPublishButtonDisabled = () => {
    if (addonsWithMissingCurrency.length) {
      return true;
    }

    if (affectOption === PublishMigrationType.AllCustomers) {
      return checked.some((check, index) => {
        let isChecked = false;
        if (index === 0) {
          isChecked = !isEmpty(affectedChildPlans) && !check;
        } else if (index === 1) {
          isChecked = !check;
        } else if (index === 2) {
          isChecked = aPackage.type === 'Plan' && !check;
        } else if (index === 3) {
          isChecked = !check;
        }

        return isChecked;
      });
    }

    return (
      changes?.totalChanges === 0 ||
      !isEmpty(childPlansWithDraft) ||
      Boolean(activeExperiment) ||
      Boolean(isBasePlanADraft)
    );
  };

  if (isLoading) {
    return (
      <Box height={400}>
        <Loader />
      </Box>
    );
  }

  return (
    <GridFlex.Column container>
      <Grid item mb={4}>
        <Text.B2>
          {t('packages.packageChangesSubtitle', {
            count: changes?.totalChanges || 0,
          })}
        </Text.B2>
      </Grid>
      <Grid item mb={4} $fullWidth>
        <PackageDiff aPackage={aPackage} changes={changes} hasMultipleCurrencies={hasMultipleCurrencies} />
      </Grid>
      <Grid item mb={4} $fullWidth>
        <ChangesMigrationSelection
          aPackage={aPackage}
          affectOption={affectOption}
          setPublishMigrationType={setAffectOption}
          checked={checked}
          allowAllCustomersMigration={hasChangesOtherThanPricing}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Grid>
      {activeExperiment && (
        <Grid item mb={4}>
          <PackagePublishDisabledDueToActiveExperiment experimentRefId={activeExperiment.refId} />
        </Grid>
      )}
      {isBasePlanADraft && (
        <Grid item mb={4}>
          <PackagePublishDisabledDueToDraftBasePlan planRefId={basePlan.refId} planName={basePlan.displayName} />
        </Grid>
      )}
      {!!addonsWithMissingCurrency.length && (
        <Alert mb={4} severity="warning">
          <AlertTitle>{t('packages.packageChangesAddonsWithMissingCurrencyAlertTitle')}</AlertTitle>

          {t('packages.packageChangesAddonsWithMissingCurrencyAlertContent', {
            count: addonsWithMissingCurrency.length,
          })}
          <Link
            ml={1}
            onClick={() => {
              onCancel();
              setTimeout(scrollToCompatibleAddons, 100);
            }}>
            {t('packages.packageChangesAddonsWithMissingCurrencyAlertContentCTA')}
          </Link>
        </Alert>
      )}
      <DialogActionsButtons
        saveText={t('packages.publishChanges')}
        isSaveLoading={isPublishingInProgress}
        saveDisabled={isPublishButtonDisabled()}
        onCancel={onCancel}
        onSave={handlePublish}
        saveButtonColor={affectOption === PublishMigrationType.AllCustomers ? 'error' : 'warning'}
        cancelButtonColor={affectOption === PublishMigrationType.AllCustomers ? 'error' : 'warning'}
      />
    </GridFlex.Column>
  );
}
