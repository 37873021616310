import { t } from 'i18next';
import { Button, Icon } from '@stigg-components';
import { useNavigation } from '../../modules/navigation/useNavigation';
import { SettingsPageTabs } from '../../modules/accounts/types/settingsPageTabs';

export function UpgradeButton() {
  const navigation = useNavigation();

  return (
    <Button
      variant="contained"
      onClick={() => {
        navigation.navigateTo(navigation.appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Billing }), {
          isGlobal: true,
        });
      }}
      startIcon={<Icon icon="PowerUp" type="custom" color="white" overrideStroke />}>
      {t('account.upgradeNow')}
    </Button>
  );
}
