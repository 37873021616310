import { t } from 'i18next';
import * as moment from 'moment';
import flatMap from 'lodash/flatMap';
import keys from 'lodash/keys';
import styled from 'styled-components/macro';
import { UsageEventFragment } from '@stigg-types/apiTypes';
import {
  Text,
  DateText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@stigg-components';

const StyledPaper = styled(Paper)`
  border-radius: 0;
  border-top: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
`;

const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export function UsageEventsPreviewTable({ usageEvents }: { usageEvents: UsageEventFragment[] }) {
  const dimensionFields = new Set(flatMap(usageEvents, (event) => keys(event.dimensions)));
  const dimensionsArray = Array.from(dimensionFields);
  const shouldShowResourceIdColumn = usageEvents.some((event) => event.resourceId);
  return (
    <TableContainer component={StyledPaper}>
      <Table sx={{ borderRadius: 0 }} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              <Text.B2 color="primary">{t('features.eventsPreviewColumns.timestamp')}</Text.B2>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Text.B2 color="primary">{t('features.eventsPreviewColumns.customerId')}</Text.B2>
            </StyledTableCell>
            {shouldShowResourceIdColumn && (
              <StyledTableCell align="left">
                <Text.B2 color="primary">{t('features.eventsPreviewColumns.resourceId')}</Text.B2>
              </StyledTableCell>
            )}
            <StyledTableCell align="left">
              <Text.B2 color="primary">{t('features.eventsPreviewColumns.eventName')}</Text.B2>
            </StyledTableCell>
            {dimensionsArray.map((dimensionKey) => (
              <StyledTableCell align="left">
                <Text.B2 color="primary">{dimensionKey}</Text.B2>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {usageEvents.map((row) => (
            <TableRow key={row.id}>
              <StyledTableCell align="left">
                <DateText color="secondary" date={moment.utc(row.timestamp).toDate()} withTime formatType="shortDate" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <Text.B2 color="secondary">{row.customerId}</Text.B2>
              </StyledTableCell>
              {shouldShowResourceIdColumn && (
                <StyledTableCell align="left">
                  <Text.B2 color="secondary">{row.resourceId}</Text.B2>
                </StyledTableCell>
              )}
              <StyledTableCell align="left">
                <Text.B2 color="secondary">{row.eventName}</Text.B2>
              </StyledTableCell>
              {dimensionsArray.map((dimensionKey) => (
                <StyledTableCell align="left">
                  <Text.B2 color="secondary">{row.dimensions?.[dimensionKey]}</Text.B2>
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
