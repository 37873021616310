import { t } from 'i18next';
import { useCallback } from 'react';
import { useConfirmationDialog } from '@stigg-common';
import { AddonFragment, PlanFragment, PackageStatus } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../navigation/useNavigation';
import { useAppDispatch } from '../../../../redux/store';
import { discardPlanDraftAction } from '../../plans/plansSlice';
import { discardAddonDraftAction } from '../../addons/addonSlice';

export function useDiscardChangesDialog(
  aPackage: AddonFragment | PlanFragment | undefined,
): ReturnType<typeof useConfirmationDialog> {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const handleConfirm = useCallback(async () => {
    if (!aPackage) return;

    const isFirstDraft = aPackage.status === PackageStatus.Draft && aPackage.isLatest;
    if (aPackage.type === 'Plan') {
      await dispatch(discardPlanDraftAction(aPackage.refId));
      if (!isFirstDraft) {
        navigation.navigateTo(navigation.appRoutes.planPage(aPackage.refId));
      }
    } else if (aPackage.type === 'Addon') {
      await dispatch(discardAddonDraftAction(aPackage.refId));
      if (!isFirstDraft) {
        navigation.navigateTo(navigation.appRoutes.addonPage(aPackage.refId));
      }
    }
  }, [aPackage, dispatch, navigation]);

  return useConfirmationDialog({
    title: t('packages.discardChangesConfirmDialogTitle'),
    content: t('packages.discardChangesConfirmDialogContent'),
    confirmButtonText: t('packages.discardChangesConfirmDialogConfirmButtonText'),
    handleConfirm,
  });
}
