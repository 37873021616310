import { gql } from '@apollo/client';
import { t } from 'i18next';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const RESEND_EMAIL_VERIFICATION = gql`
  mutation ResendEmailVerification {
    resendEmailVerification
  }
`;

async function resendEmailVerification(_: void, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate({
        mutation: RESEND_EMAIL_VERIFICATION,
      });
    },
    {
      successMessage: t('emailVerification.api.success'),
      failureMessageHandler: () => t('emailVerification.api.failed'),
    },
    dispatch,
  );
}

export { resendEmailVerification };
