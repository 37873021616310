import { Grid, GridFlex, Link, Text } from '@stigg-components';
import { filter, isEmpty, isEqual, uniqWith } from 'lodash';
import { t } from 'i18next';
import { EntitlementSummaryFragment, SubscriptionStatus } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import { getPolicyTypeIcon } from '../../../../../../../components/getPolicyTypeIcon';
import { PackageVersionNumber } from '../../../../../../packages/common/components/PackageVersionNumber';
import { generateSummaryTitle } from './SourceBox.utils';
import { EntitlementMethod, getEntitlementMethod } from '../EntitlementMethod';
import { isBasePlan } from '../entitlementSummary.utils';

type EntitlementSourceProps = {
  summaryTitle: string;
  planPackage?: EntitlementSummaryFragment | null;
  overriddenPoliciesNumber: number;
  summaries: EntitlementSummaryFragment[];
};

function EntitlementSource({ summaryTitle, planPackage, overriddenPoliciesNumber, summaries }: EntitlementSourceProps) {
  const navigation = useNavigation();

  return (
    <GridFlex.Column textAlign="left">
      <Text.B2>{summaryTitle}</Text.B2>
      {planPackage &&
        (overriddenPoliciesNumber ? (
          <Grid
            item
            sx={{
              color: (theme) => theme.itamar.palette.grey[400],
            }}>
            {overriddenPoliciesNumber > 1
              ? t('customers.summarySectionOverridesEntitlements', { overriddenPoliciesNumber })
              : t('customers.summarySectionOverridesEntitlement')}
          </Grid>
        ) : summaries.length === 1 ? (
          <Grid item>
            <Link
              mr={2}
              underline="hover"
              variant="caption"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (planPackage.plan) {
                  navigation.navigateTo(
                    navigation.appRoutes.planPage(planPackage.plan.refId, planPackage.plan.versionNumber),
                  );
                }
              }}>
              {planPackage.plan?.product.displayName} / {planPackage.plan?.displayName}
            </Link>
            {!planPackage.plan?.isLatest && (
              <PackageVersionNumber versionNumber={planPackage.plan?.versionNumber} size="small" />
            )}
          </Grid>
        ) : null)}
    </GridFlex.Column>
  );
}

export function SourceBox({
  summaries,
  focusedProductId,
  activePlanIds,
  trialPlanIds,
  iconsOnly,
}: {
  summaries: EntitlementSummaryFragment[] | null;
  focusedProductId?: string;
  activePlanIds: string[];
  trialPlanIds: string[];
  iconsOnly?: boolean;
}) {
  if (!summaries) {
    return null;
  }

  const entitlementMethods = summaries.map((element) => ({
    type: getEntitlementMethod(element, activePlanIds, trialPlanIds, focusedProductId),
    overriddenPackage: !element.isEffectiveEntitlement,
  }));

  const amountOfAddons = filter(entitlementMethods, (x) => x.type === EntitlementMethod.ADDON).length;
  const planPackage = summaries.find(
    (element) => element.featurePackageEntitlement?.package?.type === 'Plan' || !!element.priceEntitlement,
  );
  const firstAddonDisplayName =
    summaries.find((element) => element?.featurePackageEntitlement?.package?.type === 'Addon')
      ?.featurePackageEntitlement?.package?.displayName || '';
  const isTrial = planPackage?.subscription?.status === SubscriptionStatus.InTrial;
  const uniqueEntitlementMethods = uniqWith(entitlementMethods, isEqual);
  const originatedFromBasePlan = isBasePlan(isTrial, planPackage?.plan?.refId, activePlanIds, trialPlanIds);
  const originatedFromOtherProduct = focusedProductId ? planPackage?.plan?.product.refId !== focusedProductId : false;
  const overriddenPoliciesNumber = filter(entitlementMethods, (x) => x.overriddenPackage).length;
  const isPromotionalEffective = !isEmpty(
    filter(summaries, (x) => x.featurePromotionalEntitlement && x.isEffectiveEntitlement),
  );

  const summaryTitle = generateSummaryTitle(
    planPackage as EntitlementSummaryFragment,
    amountOfAddons,
    firstAddonDisplayName,
    originatedFromBasePlan,
    originatedFromOtherProduct,
    isPromotionalEffective,
    isTrial,
  );

  if (iconsOnly) {
    return (
      <GridFlex.Row flexWrap="nowrap" flexDirection="row-reverse" gap={2}>
        {uniqueEntitlementMethods.map((entitlementMethod) =>
          getPolicyTypeIcon(entitlementMethod.type, entitlementMethod.overriddenPackage),
        )}
      </GridFlex.Row>
    );
  }

  return (
    <EntitlementSource
      summaryTitle={summaryTitle}
      planPackage={planPackage}
      overriddenPoliciesNumber={overriddenPoliciesNumber}
      summaries={summaries}
    />
  );
}
