import { BillingCadence } from '@stigg-types/apiTypes';
import { FormRenderProps, GridFlex, Text } from '@stigg-components';
import { getPriceAndPeriodFormat } from '../../../../../../packages/pricing/utils/priceFormatUtils';
import { SubscriptionChargeOverride } from './SubscriptionChargeOverride';
import { SubscriptionAddon, SubscriptionFormFields } from '../SubscriptionForm.types';
import { ChargeOverrideIndication } from './ChargeOverrideIndication';
import { ChargeEditButton } from './ChargeEditButton';

type SubscriptionAddonPriceProps = {
  addon: SubscriptionAddon;
  showPriceByMonth?: boolean;
  hovering?: boolean;
  formProps: FormRenderProps<SubscriptionFormFields>;
  isInEditMode: boolean;
  setIsInEditMode: (isInEditMode: boolean) => void;
};

export function SubscriptionAddonPrice({
  isInEditMode,
  setIsInEditMode,
  addon,
  showPriceByMonth,
  hovering,
  formProps,
}: SubscriptionAddonPriceProps) {
  const {
    values: { addonsPriceOverride },
  } = formProps;
  const addonPrice = addon.price!;
  const priceFormatOptions = {
    shortFormat: true,
    reversedPeriod: showPriceByMonth && addonPrice.billingCadence === BillingCadence.Recurring,
  };

  const override = addonsPriceOverride?.[addon.refId];
  const priceAndPeriodFormat = getPriceAndPeriodFormat(override || addonPrice, priceFormatOptions);

  return isInEditMode ? (
    <SubscriptionChargeOverride
      inputName={`addonsPriceOverride[${addon.refId}]`}
      priceOverrides={addonsPriceOverride}
      priceOverrideKey={addon.refId}
      charge={addonPrice}
      priceFormatOptions={priceFormatOptions}
      onClose={() => setIsInEditMode(false)}
      formProps={formProps}
      showPriceByMonth={showPriceByMonth}
    />
  ) : (
    <GridFlex.RowSpaceBetween>
      <GridFlex.Row gap={1}>
        <GridFlex.Column>
          <Text.B2>{priceAndPeriodFormat}</Text.B2>
        </GridFlex.Column>
        {(override || addonPrice.isOverridePrice) && <ChargeOverrideIndication />}
      </GridFlex.Row>
      <ChargeEditButton charge={addonPrice} hovering={hovering} onClick={() => setIsInEditMode(true)} />
    </GridFlex.RowSpaceBetween>
  );
}
