import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Link, StiggLogo, Text } from '@stigg-components';
import { t } from 'i18next';
import { useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useIntercom } from 'react-use-intercom';
import { useNavigation } from '../../navigation/useNavigation';

import { resendEmailVerificationAction } from '../authSlice';
import { useLogout } from '../hooks/useLogout';
import { useAppDispatch } from '../../../redux/store';

const Root = styled(Box)`
  display: flex;
  justify-content: center;
`;

const ContentBox = styled.div`
  position: relative;
  margin-top: 100px;
  padding: 60px 80px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.lightBackground2};
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
`;

function VerifyEmail() {
  const logout = useLogout();
  const { user } = useAuth0();
  const { navigateTo } = useNavigation();
  const dispatch = useAppDispatch();
  const intercom = useIntercom();
  useEffect(() => {
    if (!user) {
      navigateTo('/login');
    } else if (user.email_verified) {
      navigateTo('/');
    }
    intercom.shutdown();
  }, [user, navigateTo, intercom]);

  const onResendEmailVerificationClick = useCallback(() => {
    void dispatch(resendEmailVerificationAction());
  }, [dispatch]);

  return (
    <Root>
      <ContentBox>
        <StiggLogo />
        <Text.H3 mt={2} mb={4}>
          {t('emailVerification.title')}
        </Text.H3>
        <Text.B2>{t('emailVerification.subtitle')}</Text.B2>
        <Text.B2 sx={{ fontWeight: 'bolder' }} mb={5}>
          {user?.email}
        </Text.B2>
        <Text.B2 mb={5}>
          {t('emailVerification.bodyText')}
          <br />
          {t('emailVerification.bodySecondaryText')}
        </Text.B2>
        <Text.B2 mb={2}>{t('emailVerification.resendEmailSubtitle')}</Text.B2>
        <Button variant="contained" color="primary" onClick={onResendEmailVerificationClick}>
          {t('emailVerification.resendEmailButton')}
        </Button>
        <Text.B2 mt={5}>
          <Link onClick={logout}>{t('emailVerification.signInLinkText')}</Link>{' '}
          {t('emailVerification.signInAnotherUser')}
        </Text.B2>
      </ContentBox>
    </Root>
  );
}

export default VerifyEmail;
