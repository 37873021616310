import { useQueryParam } from '@stigg-common';
import { useEffect, useMemo, useState } from 'react';
import { ConditionalFilter, ConditionalFilterOperators } from './ConditionalFilters';
import { enumFindCaseInsensitive } from '../../utils/enumUtils';

export function useConditionalFilter(
  supportedConditionalFilterAttributes: Record<string, string>,
  defaultFilter?: ConditionalFilter,
) {
  const {
    value: attributeQueryParam,
    removeQueryParam: removeAttributeQueryParam,
    setQueryParam: setAttributeQueryParam,
  } = useQueryParam('attribute');
  const {
    value: operatorQueryParam,
    removeQueryParam: removeOperatorQueryParam,
    setQueryParam: setOperatorQueryParam,
  } = useQueryParam('operator');
  const {
    value: valueQueryParam,
    removeQueryParam: removeValueQueryParam,
    setQueryParam: setValueQueryParam,
  } = useQueryParam('value');

  const [conditionalFilter, setConditionalFilter] = useState<ConditionalFilter>({
    attribute: enumFindCaseInsensitive(
      supportedConditionalFilterAttributes,
      attributeQueryParam || (defaultFilter?.attribute as string),
    ),
    operator: enumFindCaseInsensitive(ConditionalFilterOperators, operatorQueryParam || defaultFilter?.operator),
    value: valueQueryParam || defaultFilter?.value || '',
  });

  const appliedConditionalFilter: ConditionalFilter = useMemo(
    () => ({
      attribute: enumFindCaseInsensitive(supportedConditionalFilterAttributes, attributeQueryParam),
      operator: enumFindCaseInsensitive(ConditionalFilterOperators, operatorQueryParam),
      value: valueQueryParam || '',
    }),
    [attributeQueryParam, operatorQueryParam, valueQueryParam, supportedConditionalFilterAttributes],
  );

  useEffect(() => {
    if (conditionalFilter.attribute && conditionalFilter.operator && conditionalFilter.value) {
      if (attributeQueryParam !== conditionalFilter.attribute) {
        setAttributeQueryParam(conditionalFilter.attribute);
      }
      if (operatorQueryParam !== conditionalFilter.operator) {
        setOperatorQueryParam(conditionalFilter.operator);
      }
      if (valueQueryParam !== conditionalFilter.value) {
        setValueQueryParam(conditionalFilter.value);
      }
    } else {
      removeAttributeQueryParam();
      removeOperatorQueryParam();
      removeValueQueryParam();
    }
  }, [
    conditionalFilter,
    attributeQueryParam,
    operatorQueryParam,
    valueQueryParam,
    removeAttributeQueryParam,
    removeOperatorQueryParam,
    removeValueQueryParam,
    setAttributeQueryParam,
    setOperatorQueryParam,
    setValueQueryParam,
  ]);

  return { conditionalFilter, setConditionalFilter, appliedConditionalFilter };
}
