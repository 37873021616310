export const BASE_FEE_MONTHLY = 200;
export const BASE_FEE_YEARLY = 175 * 12;
export const TIERS = [100, 500, 750];
export const TIERS_PRICE_MONTHLY = [10, 49, 70];
export const TIERS_PRICE_YEARLY = [8, 35, 50].map((p) => p * 12);

export const PER_UNIT_PRICE_MONTHLY = 12;
export const PER_UNIT_PRICE_YEARLY = 10 * 12;

export const ADDON_PRICE_MONTHLY = 50;
export const ADDON_PRICE_YEARLY = 35 * 12;

export const STRIPE_MOCK_ACCOUNT_ID = 'acct_1NnHoQG6EyqgvTaj';
export const STRIPE_MOCK_ACCOUNT_PK =
  'pk_test_51NnHoQG6EyqgvTajznajopWC01AozNtq7zgySeQ1qx4PH9TAXvMj0TnbZvYT3yOt46jbQAcCDs1EU2QKcfG8eEoO00tlW0Jp3r';
