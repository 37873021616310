import { Text, Grid, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { IntegrationFragment, OpenFgaCredentials } from '@stigg-types/apiTypes';
import { Optional } from '@stigg-types/primitives';

export type OpenFGAIntegrationDetailsProps = { integration: IntegrationFragment };

export function OpenFGAIntegrationDetails({ integration }: OpenFGAIntegrationDetailsProps) {
  const credentials = integration.credentials as Optional<OpenFgaCredentials>;
  if (!credentials) {
    return null;
  }
  const { clientId, apiUrl, apiAudience, apiTokenIssuer, storeId, modelId } = credentials;
  return (
    <>
      <Grid item container flexDirection="column">
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.apiUrl.label')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <Text.B2>{apiUrl}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.apiAudience.label')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <Text.B2>{apiAudience}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.apiTokenIssuer.label')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <Text.B2>{apiTokenIssuer}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.storeId.label')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <Text.B2>{storeId}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.modelId.label')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <Text.B2>{modelId}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.openFga.clientId.label')}</Text.Sub2>
          <Text.B2>{clientId}</Text.B2>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations..openFga.clientSecret.label')}</Text.Sub2>
          <Text.B2>••••••••</Text.B2>
        </GridFlex.Column>
      </Grid>
    </>
  );
}
