import { BillingPeriod } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Text } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';

export function BillingPeriodStepSummary() {
  const {
    formRenderProps: {
      values: { billingPeriods },
    },
  } = useSetPriceWizardFormContext();

  const texts: string[] = [];

  [BillingPeriod.Monthly, BillingPeriod.Annually].forEach((billingPeriod) => {
    if (billingPeriods.includes(billingPeriod)) {
      texts.push(t(`pricing.billingPeriodDescriptionsNew.${billingPeriod}`));
    }
  });

  return <Text.B2>{texts.join(` ${t('sharedComponents.or')} `)}</Text.B2>;
}
