import { InformationTooltip, Text, GridFlex, LocalDate } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useIsShowMockData } from '../hooks';
import { useChartsLastUpdateContext } from './ChartsLastUpdateProvider';

export function ChartsLastUpdate() {
  const { stiggInternalProductivityTools: stiggInternalProductivityToolsEnabled } = useFlags<FeatureFlags>();
  const isShowMockData = useIsShowMockData();
  const { chartsLastUpdate } = useChartsLastUpdateContext();

  if (!stiggInternalProductivityToolsEnabled || isShowMockData || isEmpty(chartsLastUpdate)) {
    return null;
  }

  const oldestUpdate = moment.min(chartsLastUpdate.map((update) => moment(update.lastUpdate))).toDate();

  const tooltipContent = (
    <GridFlex.Column gap={2}>
      {chartsLastUpdate.map(({ title, lastUpdate }) => (
        <GridFlex.RowSpaceBetween key={title} gap={4}>
          <Text.B2>{title}</Text.B2>
          <LocalDate
            date={lastUpdate}
            color="secondary"
            showTimeSince
            formatOptions={{ withTime: true }}
            tooltipPlacement="right"
          />
        </GridFlex.RowSpaceBetween>
      ))}
    </GridFlex.Column>
  );

  return (
    <GridFlex.RowCenter mt={4}>
      <InformationTooltip arrow placement="top" $maxWidth={600} title={tooltipContent}>
        <GridFlex.RowCenter gap={1}>
          <Text.B2 color="secondary">{t('dashboards.dataLastRefreshWas')}</Text.B2>
          <LocalDate
            date={oldestUpdate}
            color="secondary"
            showTimeSince
            withConversionTooltip={false}
            formatOptions={{ withTime: true }}
          />
        </GridFlex.RowCenter>
      </InformationTooltip>
    </GridFlex.RowCenter>
  );
}
