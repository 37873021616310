import { styled } from '@stigg-theme';
import { Card, GridFlex, Icon, Link } from '@stigg-components';
import { PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { useNavigation } from '../../../navigation/useNavigation';
import { CustomerJourneySectionTitle } from '../../../products/components/productPage/customerJourney/CustomerJourneySectionTitle';

const StyledCard = styled(Card)`
  border-color: ${({ theme }) => theme.itamar.palette.shadow.outlined};
`;

function calculateSections(plan: PlanFragment): ReactNode[] {
  const sections: ReactNode[] = [];

  const isSetAsDowngradePlan = plan.refId === plan.product?.productSettings.downgradePlan?.refId;
  const isSetForSubscriptionStart = plan.refId === plan.product?.productSettings.subscriptionStartPlan?.refId;

  const numberOfCustomerJourneyItems = [isSetAsDowngradePlan, isSetForSubscriptionStart].filter((item) => item).length;
  const shouldAddMarginToDowngradePlan = numberOfCustomerJourneyItems === 1;

  if (isSetForSubscriptionStart) {
    const text =
      plan.pricingType === PricingType.Free
        ? t('plans.customerJourneyIndication.startWithFreePlan')
        : t('plans.customerJourneyIndication.startTrailOfPaidPlan');
    sections.push(
      <CustomerJourneySectionTitle
        icon="Flag"
        iconType="reactFeather"
        iconSize={24}
        title={text}
        readonly
        lastChild={numberOfCustomerJourneyItems === 1}
        minHeight={20}
      />,
    );
  }

  if (isSetAsDowngradePlan) {
    sections.push(
      <CustomerJourneySectionTitle
        icon="DoorFrontOutlined"
        iconSize={24}
        title={t('plans.customerJourneyIndication.downgradePlan')}
        readonly
        minHeight={20}
        lastChild
        mt={shouldAddMarginToDowngradePlan ? 0 : 4}
      />,
    );
  }

  return sections;
}

export function PlanCustomerJourneyIndication({ plan }: { plan: PlanFragment }) {
  const navigation = useNavigation();
  const sections = calculateSections(plan);

  if (isEmpty(sections)) {
    return null;
  }

  return (
    <StyledCard p={4} mt={4}>
      <GridFlex.Row gap={4}>
        <GridFlex.Column flex={1} gap={2}>
          {sections}
        </GridFlex.Column>
        <Link
          variant="body2"
          display="inline-block"
          onClick={() =>
            navigation.navigateTo(navigation.appRoutes.productPage(plan.product.refId), { anchor: 'customerJourney' })
          }>
          <GridFlex.RowCenter gap={1}>
            <Icon icon="Edit2" type="reactFeather" size={16} />
            {t('sharedComponents.editInCustomerJourney')}
          </GridFlex.RowCenter>
        </Link>
      </GridFlex.Row>
    </StyledCard>
  );
}
