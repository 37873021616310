import { t } from 'i18next';
import { useState } from 'react';
import { useConfirmationDialog } from '@stigg-common';
import { SubscriptionDataFragment, SubscriptionMigrationTime } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../../redux/store';
import { MigrateSubscription } from '../MigrateSubscription';
import { migrateSubscriptionToLatestAction } from '../../../customersSlice';

type UseMigrateSubscriptionDialogProps = {
  subscription: SubscriptionDataFragment;
};

export function useMigrateSubscriptionDialog({ subscription }: UseMigrateSubscriptionDialogProps) {
  const dispatch = useAppDispatch();
  const [migrationSchedule, setMigrationSchedule] = useState(SubscriptionMigrationTime.Immediate);

  const [MigrateToLatestDialog, showMigrateToLatestDialog, migrateToLatestDialogProps] = useConfirmationDialog({
    title: t('subscriptions.migrateToLatestDialogTitle'),
    content: (
      <MigrateSubscription
        subscription={subscription}
        migrationSchedule={migrationSchedule}
        setMigrationSchedule={setMigrationSchedule}
      />
    ),
    confirmButtonColor: 'primary',
    confirmButtonText: t('subscriptions.migrateToLatest'),
    handleConfirm: async () => {
      await dispatch(
        migrateSubscriptionToLatestAction({
          subscriptionRefId: subscription.subscriptionId,
          subscriptionMigrationTime: migrationSchedule,
        }),
      );
    },
  });

  return {
    MigrateToLatestDialog,
    migrateToLatestDialogProps,
    showMigrateToLatestDialog,
  };
}
