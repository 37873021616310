import { ResultSet } from '@cubejs-client/core';
import { useEffect } from 'react';
import { useChartsLastUpdateContext } from './ChartsLastUpdateProvider';

type ResultSetWithLastRefreshTime = ResultSet & {
  loadResponses?: Array<{ lastRefreshTime: Date }>;
};

export function useSetChartLastUpdate(title: string, resultSet: ResultSet | null) {
  const { setChartLastUpdate } = useChartsLastUpdateContext();

  // casting since the CubeJS type does not export this field
  const lastUpdate = (resultSet as unknown as ResultSetWithLastRefreshTime | null)?.loadResponses?.[0]?.lastRefreshTime;

  useEffect(() => {
    setChartLastUpdate(title, lastUpdate);
    return () => {
      setChartLastUpdate(title, undefined);
    };
  }, [lastUpdate, setChartLastUpdate, title]);
}
