import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { drawFormFields, Field, GridFlex, Icon, Text } from '@stigg-components';
import { PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { SetTrialReferenceToCustomerJourney } from './SetTrialReferenceToCustomerJourney';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { TrialSpendLimit } from './TrialSpendLimit';

export function FreeTrialStep() {
  const { formRenderProps, aPackage } = useSetPriceWizardFormContext();
  const { budgetCap: isBudgetCapEnabled } = useFlags<FeatureFlags>();

  const {
    values: { pricingType },
  } = formRenderProps;

  const shouldShowFreeTrial =
    aPackage.type === 'Plan' && (pricingType === PricingType.Paid || pricingType === PricingType.Custom);
  if (!shouldShowFreeTrial) {
    return null;
  }

  const plan = aPackage as PlanFragment;

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'switch',
      id: 'freeTrial.enabled',
      label: t('pricing.trials.startInFreeTrial'),
    },
    {
      type: 'idle',
      component: <Text.B2 color="disabled">{t('pricing.trials.startInFreeTrialNote')}</Text.B2>,
      gridProps: {
        ml: 12,
      },
    },
    {
      type: 'layout',
      hide: (values) => !values.freeTrial.enabled,
      gridProps: {
        mt: 6,
        ml: 11,
        width: 306,
        gap: 9,
      },
      contentGridProps: {
        alignItems: 'center',
      },
      labelGridProps: {
        width: 110,
        mr: 0,
      },
      label: (
        <GridFlex.RowCenter gap={2}>
          <Icon type="custom" icon="HourglassEmpty" size={24} color="default" overrideStroke />
          <Text.B2>{t('pricing.trials.trialPeriod')}</Text.B2>
        </GridFlex.RowCenter>
      ),
      fields: [
        {
          type: 'text',
          id: 'freeTrial.durationDays',
          textFieldType: 'number',
          isNumberWithoutSigns: true,
          isNumberWithoutFraction: true,
          endAdornment: t('pricing.trials.days'),
        },
      ],
    },
    {
      type: 'layout',
      hide: (values) => !isBudgetCapEnabled || !values.freeTrial.enabled,
      gridProps: {
        mt: 6,
        ml: 11,
        gap: 9,
      },
      contentGridProps: {
        alignItems: 'center',
      },
      labelGridProps: {
        width: 110,
        mr: 0,
      },
      label: (
        <GridFlex.RowCenter gap={2}>
          <Icon icon="Charge" type="custom" size={24} color="default" overrideStroke />
          <Text.B2>{t('pricing.trials.trialSpendLimit')}</Text.B2>
        </GridFlex.RowCenter>
      ),
      fields: [
        {
          type: 'custom',
          render: () => <TrialSpendLimit />,
        },
      ],
    },
    {
      type: 'idle',
      hide: (values) => !values.freeTrial.enabled,
      component: <SetTrialReferenceToCustomerJourney plan={plan} />,
      gridProps: {
        mt: 8,
      },
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
