import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Environment, PublishMigrationType } from '@stigg-types/apiTypes';
import { DialogActionsButtons, Flex, FullScreenDialogLayout, Text } from '@stigg-components';
import { styled } from '@stigg-theme';
import { Trans } from 'react-i18next';
import { useModal } from '@stigg-common';
import { Dialog } from '../../../../components/Dialog';
import { EnvironmentOptionType } from './EnvironmentsAutocompleteSearch';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { mergeEnvironmentAction } from '../../accountsSlice';
import Loader from '../../../../components/Loader';
import { EnvironmentDiffViewer } from './EnvironmentDiffViewer';
import { useEnvironmentDiff } from './useEnvironmentDiff';
import { ChangesAlert } from './ChangesAlert';
import { MigrationTypeForm } from './MigrationTypeForm';

const DialogContentContainer = styled(Flex.Column)`
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(8)} 20vw`};
`;

const DialogContentHeaderContainer = styled(Flex.Column)`
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const DialogActionsContainer = styled(Flex.Item)`
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const DialogLoaderContainer = styled(Flex.Item)`
  flex: 1 0;
`;

const MIGRATION_TYPE_MODAL_WIDTH = 750;

interface CompareEnvironmentsDialogProps {
  sourceEnv: Environment;
  destEnv: Required<EnvironmentOptionType>;
  onClose: () => void;
  onMergeSuccess: () => void;
}

export const CompareEnvironmentsDialog = ({
  sourceEnv,
  destEnv,
  onClose,
  onMergeSuccess,
}: CompareEnvironmentsDialogProps) => {
  const dispatch = useAppDispatch();
  const [MigrationTypeModal, setMigrationTypeModalOpen] = useModal({
    title: t('accounts.copyMergeEnvironments.migrationTypeForm.title'),
    width: MIGRATION_TYPE_MODAL_WIDTH,
  });
  const { isMergingEnvironment } = useSelector((state: RootState) => state.accountReducer);
  const { changes, diff, isLoadingDiff } = useEnvironmentDiff(sourceEnv.slug, destEnv.slug);

  const closeDialog = () => {
    onClose();
  };

  const onMergeClick = async (migrationType: PublishMigrationType) => {
    await dispatch(
      mergeEnvironmentAction({
        sourceEnvironmentSlug: sourceEnv.slug,
        destinationEnvironmentSlug: destEnv.slug,
        migrationType,
      }),
    );
    setMigrationTypeModalOpen(false);
    closeDialog();
    onMergeSuccess();
  };

  return (
    <Dialog
      disableCloseOnEscapeKey
      fullScreen
      padding={0}
      content={
        <FullScreenDialogLayout
          title={<Text.B2>{t('accounts.copyMergeEnvironments.compareDialog.title')}</Text.B2>}
          variant="grey">
          <DialogContentContainer>
            <DialogContentHeaderContainer>
              <Text.H3>{t('accounts.copyMergeEnvironments.compareDialog.bodyHeader')}</Text.H3>
              <Text.B2>
                <Trans
                  i18nKey="accounts.copyMergeEnvironments.compareDialog.bodyText"
                  values={{
                    sourceEnvironment: sourceEnv.displayName,
                    destinationEnvironment: destEnv.displayName,
                  }}
                  components={{ strong: <Text.B2 display="inline" $bold /> }}
                />
              </Text.B2>
            </DialogContentHeaderContainer>
            {diff ? (
              <>
                <ChangesAlert changes={changes} sx={{ mb: 6 }} />
                <EnvironmentDiffViewer diff={diff} environmentName={destEnv.displayName} />
              </>
            ) : (
              <DialogLoaderContainer>
                <Loader useAnimationLoader />
              </DialogLoaderContainer>
            )}
            <DialogActionsContainer>
              <DialogActionsButtons
                onCancel={closeDialog}
                saveText={t('accounts.copyMergeEnvironments.compareDialog.saveText')}
                saveDisabled={!changes}
                isSaveLoading={isLoadingDiff}
                onSave={() => {
                  setMigrationTypeModalOpen(true);
                }}
              />
            </DialogActionsContainer>
          </DialogContentContainer>
          <MigrationTypeModal>
            <MigrationTypeForm
              environmentName={destEnv.displayName}
              onSubmit={onMergeClick}
              isSubmitLoading={isMergingEnvironment}
              onCancel={() => {
                setMigrationTypeModalOpen(false);
              }}
            />
          </MigrationTypeModal>
        </FullScreenDialogLayout>
      }
      isOpen
      onCancel={closeDialog}
    />
  );
};
