/* eslint-disable no-restricted-imports */
import { PropsWithChildren, ReactNode } from 'react';
import { Link } from '@mui/material';
import { Text } from '@stigg-components';
import { NavLink } from 'react-router-dom';
import { useNavigation } from '../../modules/navigation/useNavigation';

export function BreadcrumbLink({ title, to }: PropsWithChildren<{ title: string | ReactNode; to: string }>) {
  const navigation = useNavigation();
  return (
    <Link
      underline="hover"
      sx={{ color: (theme) => theme.itamar.palette.text.secondary }}
      component={NavLink}
      to={navigation.getEnvPath(to)}>
      <Text.Sub2>{title}</Text.Sub2>
    </Link>
  );
}
