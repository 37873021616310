import { CSSProperties } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Text, Grid, ClipboardChip } from '@stigg-components';
import { styled } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';

const StyledWrapperBox = styled(Grid)`
  overflow: auto;
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

const HeaderBox = styled(Grid)`
  ${({ theme }) => (theme.isLightTheme ? '' : `background-color: ${theme.itamar.palette.background.darkBackground2}`)};
  border-bottom: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => `${theme.itamar.border.radius} ${theme.itamar.border.radius} 0 0`};
`;

type ClipboardCodeProps = {
  title: string;
  copyText?: string;
  style?: CSSProperties;
};

type PrivateClipboardCodeProps = ClipboardCodeProps & {
  language: 'json' | 'sql';
  code: string;
};

function ClipboardSyntax({ title, copyText, language, code, style }: PrivateClipboardCodeProps) {
  const theme = useTheme();
  return (
    <StyledWrapperBox item container flexDirection="column">
      <HeaderBox container item px={4} py={3} justifyContent="space-between" wrap="nowrap" alignItems="center">
        <Grid item>
          <Text.B2 fontWeight="bolder">{title}</Text.B2>
        </Grid>
        <Grid item>
          <ClipboardChip text={copyText} copy={code} copyPadding={0} />
        </Grid>
      </HeaderBox>
      <SyntaxHighlighter
        wrapLines
        language={language}
        style={theme.isLightTheme ? docco : vs2015}
        showLineNumbers
        lineNumberStyle={{ display: 'none' }}
        customStyle={{
          fontSize: 14,
          margin: 0,
          padding: '16px',
          ...(theme.isLightTheme ? {} : { backgroundColor: theme.itamar.palette.background.lightBackground2 }),
          borderBottomLeftRadius: theme.itamar.border.radius,
          borderBottomRightRadius: theme.itamar.border.radius,
          maxWidth: '100%',
          ...style,
        }}>
        {code}
      </SyntaxHighlighter>
    </StyledWrapperBox>
  );
}

type ClipboardJSONProps = ClipboardCodeProps & {
  json: Record<string, any> | string;
};

export function ClipboardJSON({ title, copyText, json, style }: ClipboardJSONProps) {
  const jsonObject = typeof json === 'string' ? JSON.parse(json) : json;

  return (
    <ClipboardSyntax
      title={title}
      copyText={copyText}
      language="json"
      code={JSON.stringify(jsonObject, null, 2)}
      style={style}
    />
  );
}

type ClipboardSQLProps = ClipboardCodeProps & {
  sql: string;
};

export function ClipboardSQL({ title, copyText, sql, style }: ClipboardSQLProps) {
  return <ClipboardSyntax title={title} copyText={copyText} language="sql" code={sql} style={style} />;
}
