import { t } from 'i18next';
import { Link, Text, GridFlex } from '@stigg-components';
import { EmptyCardContent } from '../../../../components/EmptyCardContent';
import { ReactComponent as EmptyPlanSvg } from './emptyPlan.svg';

type PlansEmptyStateProps = { onCreatePlan: () => void };

export function PlansEmptyState({ onCreatePlan }: PlansEmptyStateProps) {
  return (
    <EmptyCardContent flexDirection="column">
      <GridFlex.ColumnMiddle py={24}>
        <GridFlex.Row container columnGap={2}>
          {[...Array(3)].map((_, index) => (
            <GridFlex.Column item key={index}>
              <EmptyPlanSvg />
            </GridFlex.Column>
          ))}
        </GridFlex.Row>

        <Text.B2 mt={4}>{t('products.noPackage', { packageType: 'plan' })}</Text.B2>
        <Link variant="body2" ml={1} onClick={onCreatePlan}>
          {t('products.noPackageCta', { packageType: 'plan' })}
        </Link>
      </GridFlex.ColumnMiddle>
    </EmptyCardContent>
  );
}
