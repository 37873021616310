import { useEffect, useState } from 'react';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import config from '../../../env.config';
import { useCurrentMember } from '../../auth/hooks/useCurrentMember';

export const useCubejsApi = () => {
  const [cubejsApi, setCubejsApi] = useState<CubejsApi | null>(null);

  const member = useCurrentMember();
  const cubejsToken = member?.cubejsToken;

  useEffect(() => {
    if (cubejsToken) {
      const cubejsApi = cubejs(cubejsToken, {
        apiUrl: config.cubejsApiUrl,
      });
      setCubejsApi(cubejsApi);
    }
  }, [cubejsToken]);

  return cubejsApi;
};
