import { GridFlex } from '@stigg-components';
import { FeatureFragment } from '@stigg-types/apiTypes';
import { updateFeatureAction } from '../../featuresSlice';
import { useAppDispatch } from '../../../../redux/store';
import { EntityMetadata } from '../../../../components/entityMetadata/EntityMetadataCard';

export function FeatureMetadata({ feature }: { feature: FeatureFragment }) {
  const dispatch = useAppDispatch();
  const updateMetadata = async (metadata: Record<string, string>) => {
    await dispatch(
      updateFeatureAction({
        featureRefId: feature.refId,
        updatePayload: { additionalMetaData: metadata },
      }),
    );
  };

  return (
    <>
      <GridFlex.Column pt={3}>
        <EntityMetadata metaData={feature.additionalMetaData} onSubmit={updateMetadata} entityTypeName="feature" />
      </GridFlex.Column>
    </>
  );
}
