import styled, { css } from 'styled-components/macro';
import { AppBar as MuiAppBar } from '@stigg-components';

export const AppBar = styled(MuiAppBar)<{
  $isDrawerExpanded: boolean;
  $drawerWidth: number;
  $top?: number;
}>`
  background: ${({ theme }) => theme.header.background};
  color: ${({ theme }) => theme.header.color};
  top: ${({ $top }) => ($top ? `${$top}px` : '0px')};
  position: sticky;
  width: auto;
  transition: all 0.15s;
  border-bottom: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 0;
  }

  ${(props) =>
    props.$isDrawerExpanded &&
    css`
      margin-left: 0;
    `}
`;
