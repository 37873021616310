import React, { useState, useCallback, useEffect } from 'react';
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Skeleton,
  GridFlex,
} from '@stigg-components';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import differenceBy from 'lodash/differenceBy';
import Button from '@mui/material/Button';
import ImportAddonsIcon from '@assets/images/integrations/import-addons.png';
import { mapStripeProduct, StripeProductListItem } from './ImportProductsStep';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { useInfiniteScroll } from '../../../../common/useInfiniteScroll';
import { useAppDispatch } from '../../../../../redux/store';
import { searchStripeProductsAction } from '../../../integrationsSlice';

const ImportIconsIcon = styled.img`
  height: 36px;
  width: 36px;
`;

export function ImportAddonsTransferList() {
  const {
    selectedProducts,
    unSelectedProducts,
    unseenBlackProducts,
    productsData: { nextPage, totalCount, headerSelectionChecked },
    setProductsData,
  } = useImportContext();
  const importContext = useImportContext();
  const dispatch = useAppDispatch();

  const [checked, setChecked] = React.useState<readonly string[]>([]);
  const [plans, setPlans] = React.useState<readonly StripeProductListItem[]>(selectedProducts);
  const [addons, setAddons] = React.useState<readonly StripeProductListItem[]>([]);
  const [paginationData, setPaginationData] = useState<{ nextPage?: string | null; totalCount: number } | null>({
    nextPage,
    totalCount: totalCount || 0,
  });
  useEffect(() => {
    importContext?.setAddonIds(addons.map((addon) => addon.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addons]);

  const plansChecked = plans.filter((plan) => checked.includes(plan.id));
  const addonsChecked = addons.filter((addon) => checked.includes(addon.id));

  const handleToggle = (value: StripeProductListItem) => () => {
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setAddons(addons.concat(plansChecked));
    setPlans(differenceBy(plans, plansChecked, 'id'));
    setChecked(checked.filter((checkedItem) => plansChecked.findIndex((plan) => plan.id === checkedItem) === -1));
  };

  const handleCheckedLeft = () => {
    setPlans(plans.concat(addonsChecked));
    setAddons(differenceBy(addons, addonsChecked, 'id'));
    setChecked(checked.filter((checkedItem) => addonsChecked.findIndex((addon) => addon.id === checkedItem) === -1));
  };

  const fetchStripeProducts = useCallback(
    async (nextPage?: string) => {
      const result = await dispatch(searchStripeProductsAction({ nextPage })).unwrap();
      const nextProducts = result.products
        .filter((product) => !unseenBlackProducts.some((blackProduct) => blackProduct.id === product.id))
        .map((stripeProduct) => mapStripeProduct(stripeProduct, headerSelectionChecked || false));
      setPlans((products) => [...products, ...nextProducts]);
      setPaginationData({ nextPage: result.nextPage, totalCount: result.totalCount });
      setProductsData((prevState) => ({
        ...prevState,
        nextPage: result.nextPage,
        totalCount: result.totalCount,
        products: [...prevState.products, ...nextProducts],
      }));
    },
    [dispatch, headerSelectionChecked, unseenBlackProducts, setProductsData],
  );

  const fetchMoreProducts = useCallback(async () => {
    if (paginationData?.nextPage) {
      await fetchStripeProducts(paginationData.nextPage);
    }
  }, [fetchStripeProducts, paginationData]);

  const { scrollContainerRef, hasMore, loading } = useInfiniteScroll({
    hasMore: !!paginationData?.nextPage,
    loadMore: fetchMoreProducts,
  });

  const customList = (
    title: React.ReactNode,
    items: readonly StripeProductListItem[],
    totalItems?: number,
    withLoader?: boolean,
    scrollContainerRef?: React.MutableRefObject<any>,
  ) => {
    return (
      <Card>
        <CardHeader
          sx={{ p: 4 }}
          avatar={<ImportIconsIcon src={ImportAddonsIcon} alt="Import icons" />}
          title={title}
          subheader={`${totalItems || items.length} items`}
        />
        <Divider />
        <List
          ref={scrollContainerRef}
          sx={{
            height: 230,
            maxHeight: 230,
            overflow: 'auto',
          }}
          dense
          role="list">
          {items.map((product: StripeProductListItem) => {
            const labelId = `transfer-list-all-item-${product.id}-label`;

            return (
              <ListItemButton
                key={product.id}
                selected={checked.some((check) => check === product.id)}
                onClick={handleToggle(product)}>
                <ListItemText id={labelId} secondary={product.price} primary={product.name} />
              </ListItemButton>
            );
          })}
          {withLoader && hasMore && loading && (
            <>
              {[...Array(3)].map((_, i) => (
                <ListItem key={i}>
                  <GridFlex.Column $fullWidth>
                    <Skeleton animation="wave" width="60%" height={20} />
                    <Skeleton animation="wave" width="30%" height={15} />
                  </GridFlex.Column>
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Card>
    );
  };

  const plansCount = headerSelectionChecked
    ? (totalCount || 0) - unSelectedProducts.length - addons.length
    : plans.length;
  const plansList = customList(
    t('integrations.plansHeader'),
    plans,
    plansCount,
    headerSelectionChecked,
    headerSelectionChecked ? scrollContainerRef : undefined,
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" width="100%" display="flex">
      <Grid item flex="1">
        {plansList}
      </Grid>
      <Grid item mx={3}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 1 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={plansChecked.length === 0}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            sx={{ my: 1 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={addonsChecked.length === 0}
            aria-label="move selected left">
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item flex="1">
        {customList(t('integrations.addonsHeader'), addons)}
      </Grid>
    </Grid>
  );
}
