import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { Edit2, Trash2 } from 'react-feather';
import { isEmpty } from 'lodash';
import { Divider, OptionsDropdown, Chip, Grid, Text, PageHeader, GridFlex } from '@stigg-components';
import { Hook, HookDataFragment, HookStatus } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { deleteHookByIdAction, fetchHooksAction } from '../hooksSlice';
import { RootState, useAppDispatch } from '../../../redux/store';
import Table, { HeadCell } from '../../../components/table/Table';
import { Dialog } from '../../../components/Dialog';
import { useNavigation } from '../../navigation/useNavigation';
import CreateHookForm from './hookCrud/CreateHookForm';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { EmptyList } from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import { ReactComponent as Webhook } from '../../../assets/icons/Webhook.svg';
import UpdateHookForm from './hookCrud/UpdateHookForm';
import { LongText } from '../../../components/LongText';
import { ExternalLink } from '../../../components/ExternalLink';

const headCells = (
  onDeleteClick: (hook: Hook) => void,
  onEditClick: (hook: Hook) => void,
): Array<HeadCell<Hook, any>> => [
  {
    id: 'description',
    alignment: 'left',
    label: t('hooks.description'),
    render: (hook) => (
      <LongText variant="body2" color="primary">
        {hook.description || ''}
      </LongText>
    ),
  },
  {
    id: 'endpointUrl',
    alignment: 'left',
    label: t('hooks.endpointUrl'),
    render: (hook) => (
      <LongText variant="body2" color="primary" wordBreak>
        {hook.endpoint || ''}
      </LongText>
    ),
  },
  {
    id: 'events_num',
    alignment: 'left',
    label: t('hooks.subscribedEventsNum'),
    render: (hook) => <Text.B2>{t('hooks.events', { length: hook.eventLogTypes.length })}</Text.B2>,
  },
  {
    id: 'status',
    alignment: 'left',
    label: t('hooks.status'),
    render: (hook) => (
      <Chip
        $hasPointer
        size="small"
        label={hook.status === HookStatus.Active ? t('hooks.active') : t('hooks.disabled')}
        // todo: check correct color
        color={hook.status === HookStatus.Active ? 'primary' : 'draft'}
      />
    ),
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    disableClick: true,
    width: 56,
    maxWidth: 36 + 8,
    render: (hook) => (
      <OptionsDropdown
        options={[
          {
            icon: Edit2,
            text: t('customers.edit'),
            onClick: () => {
              onEditClick(hook);
            },
          },
          {
            icon: Trash2,
            text: t('sharedComponents.delete'),
            onClick: () => {
              onDeleteClick(hook);
            },
          },
        ]}
      />
    ),
  },
];

function Hooks() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { slackIntegration: slackIntegrationEnabled } = useFlags<FeatureFlags>();
  const hooks: HookDataFragment[] = useSelector((state: RootState) => state.hooksReducer.hooks);
  const hooksToShow = hooks.filter(
    (x) => !slackIntegrationEnabled || !x.endpoint.startsWith('https://hooks.slack.com'),
  );
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.hooksReducer.isLoading);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [hook, setHook] = useState<Hook | null>(null);

  const onNewClick = () => {
    setCreateDialogOpen(true);
  };
  const onEditClick = (hook: Hook) => {
    setHook(hook);
    setEditDialogOpen(true);
  };
  const onDeleteClick = (hook: Hook) => {
    setHook(hook);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && hook?.id) {
      await dispatch(deleteHookByIdAction({ hookId: hook.id }));
    }
    setDeleteDialogOpen(false);
  };
  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchHooksAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  if (isLoading || !currentEnvironmentId) {
    return <Loader />;
  }

  return (
    <>
      <PageHeader
        title={t('hooks.hooksTitle')}
        buttonTitle={t('hooks.newHookButton')}
        buttonClick={onNewClick}
        subtitle={
          <GridFlex.RowCenter item>
            <Text.B2 m={0} mr={2} gutterBottom minWidth="max-content">
              {t('hooks.hooksSubTitle')}
            </Text.B2>
            <ExternalLink label={t('hooks.hooksSubTitleLinkText')} url={t('hooks.hooksSubTitleLink')} />
          </GridFlex.RowCenter>
        }
      />

      <Divider my={4} />

      <Grid container flexDirection="column" rowSpacing={8}>
        <Grid item>
          {isEmpty(hooksToShow) ? (
            <EmptyList
              title={t('hooks.emptyStateText')}
              linkText={t('hooks.emptyStateTextActionLinkText')}
              onLinkClick={onNewClick}
              icon={Webhook}
            />
          ) : (
            <Table
              rowHeight={65}
              label={t('hooks.hooksTableLabel')}
              headCells={headCells(onDeleteClick, onEditClick)}
              data={hooksToShow}
              onRowClick={(hook: HookDataFragment) => navigation.navigateTo(`/webhooks/${hook.id}`)}
            />
          )}
        </Grid>
      </Grid>

      <Dialog
        content={<CreateHookForm onCancel={() => setCreateDialogOpen(false)} />}
        titleText={t('hooks.createHook')}
        isOpen={createDialogOpen}
        onCancel={() => setCreateDialogOpen(false)}
        aria-labelledby="create-hook-dialog"
        width={730}
      />
      <Dialog
        content={hook ? <UpdateHookForm hook={hook} onCancel={() => setEditDialogOpen(false)} /> : null}
        titleText={t('hooks.editHook')}
        isOpen={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        aria-labelledby="edit-hook-dialog"
        width={730}
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('hooks.delete')}
        content={t('hooks.deleteConfirmation')}
      />
    </>
  );
}

export default Hooks;
