import React from 'react';
import { drawFormFields, Field, FormRenderProps, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { ExperimentPreset } from '../ExperimentWizard';
import { CircleControl, CircleVariant } from '../../commom/Circles';

export function PlanEntitlementsVariationEditor({
  formRenderProps,
}: {
  formRenderProps: FormRenderProps<ExperimentPreset>;
}) {
  const fields: Field<ExperimentPreset>[] = [
    {
      label: (
        <GridFlex.RowCenter gap={1}>
          <CircleControl />
          {t('experiments.wizard.controlGroupNameLabel')}
        </GridFlex.RowCenter>
      ),
      type: 'text',
      id: 'controlGroupName',

      xs: 6,
    },
    {
      label: (
        <GridFlex.RowCenter gap={1}>
          <CircleVariant />
          {t('experiments.wizard.variantGroupNameLabel')}
        </GridFlex.RowCenter>
      ),
      type: 'text',
      id: 'variantGroupName',

      xs: 6,
    },
  ];

  return (
    <GridFlex.Column mt={12} gap={10}>
      <GridFlex.Row gap={10}>{drawFormFields(fields, formRenderProps)}</GridFlex.Row>
      {/*  TODO: implement entitlement editor */}
    </GridFlex.Column>
  );
}
