import { useEffect } from 'react';
import {
  CustomerSubscriptionStatusByIdsQuery,
  CustomerSubscriptionStatusByIdsQueryVariables,
  PaymentCollection,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { ObservableQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { watchSubscriptionsPaymentProcessingByIds } from '../../queries/watchSubscriptionsPaymentProcessingByIds';
import { RootState } from '../../../../redux/store';

const DEFAULT_POLL_INTERVAL = 2500;

function findPaymentProcessingSubscriptions(
  subscriptions: { id: string; status: SubscriptionStatus; paymentCollection: PaymentCollection }[] | null | undefined,
): string[] {
  return (subscriptions || []).filter((x) => x.paymentCollection === PaymentCollection.Processing).map((x) => x.id);
}

function registerForChange(
  subscriptionIds: string[],
  onChange: CallableFunction,
  pollInterval: number,
): ObservableQuery<CustomerSubscriptionStatusByIdsQuery, CustomerSubscriptionStatusByIdsQueryVariables> {
  const pollSubscription = watchSubscriptionsPaymentProcessingByIds(subscriptionIds, pollInterval);
  pollSubscription.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.customerSubscriptions) {
        Sentry.captureException('got unexpected response for polling subscription status', { extra: { value } });
        return;
      }

      // In case the returned list of subscriptions is lower than the amount
      // that we originally registered to, it means that (at least) one of the status
      // has changed, so we call the callback function
      if (value.data.customerSubscriptions.edges.length < subscriptionIds.length) {
        pollSubscription.stopPolling();
        onChange();
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling subscription status' } });
    },
  );

  return pollSubscription;
}

export function usePollCustomerSubscriptionPaymentProcessing(
  onChange: CallableFunction,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
) {
  const subscriptions = useSelector((root: RootState) => root.customersReducer.customerSubscriptions?.edges)?.map(
    (x) => x.node,
  );

  useEffect(() => {
    let pollSubscription:
      | ObservableQuery<CustomerSubscriptionStatusByIdsQuery, CustomerSubscriptionStatusByIdsQueryVariables>
      | undefined;

    const subscriptionsIds = findPaymentProcessingSubscriptions(subscriptions);

    if (subscriptionsIds.length > 0) {
      pollSubscription = registerForChange(subscriptionsIds, onChange, pollInterval);
    }

    return () => pollSubscription?.stopPolling();
  }, [subscriptions, onChange, pollInterval]);
}
