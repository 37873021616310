import { GridFlex, IconButton, Icon } from '@stigg-components';
import { PriceTier } from '../../SetPriceWizardForm.types';

export function DeleteTierColumn({
  tiers,
  onRemoveTier,
}: {
  tiers: PriceTier[];
  onRemoveTier: (indexToRemove: number) => void;
}) {
  return (
    <GridFlex.Column mt={29} ml={2} rowGap={5} minWidth={36}>
      {tiers.map((_, index) =>
        index > 0 ? (
          <IconButton key={index} onClick={() => onRemoveTier(index)} data-testid={`button-remove-tier-${index}`}>
            <Icon type="reactFeather" icon="Trash2" size={20} color="disabled" />
          </IconButton>
        ) : null,
      )}
    </GridFlex.Column>
  );
}
