import { DottedText, drawFormFields, Field, GridFlex, FormRenderProps, Text } from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { PackageStatus, Plan, PricingType, SubscriptionEndSetup } from '@stigg-types/apiTypes';
import React, { useMemo } from 'react';
import * as S from './CustomerJourney.style';
import { LinkToCreatePlan } from '../../../../packages/common/components/PlanTooltipsCollection';
import { TooltipFields } from '../../../../../components/InformationTooltip';
import { CustomerJourneyPlan } from './types';

export type SubscriptionEndSetupFormFields = {
  subscriptionEndSetup?: SubscriptionEndSetup;
  downgradePlanId?: string;
};

export const SubscriptionEndSetupFormFieldsNames: (keyof SubscriptionEndSetupFormFields)[] = [
  'subscriptionEndSetup',
  'downgradePlanId',
];

type SubscriptionEndSetupConfigurationProps = {
  productPlans: CustomerJourneyPlan[];
  productId: string;
  formRenderProps: FormRenderProps<SubscriptionEndSetupFormFields>;
  readonly?: boolean;
  readonlyHideDottedText?: boolean;
  readonlyTooltip?: TooltipFields;
  highlight?: boolean;
};

function formatSubscriptionEndSetup(subscriptionEndSetup: SubscriptionEndSetup) {
  switch (subscriptionEndSetup) {
    case SubscriptionEndSetup.CancelSubscription:
      return t('products.trials.cancelSubscription');
    case SubscriptionEndSetup.DowngradeToFree:
      return t('products.trials.downGradeToFree');
    default:
      return `${subscriptionEndSetup}`;
  }
}

function computeSubscriptionEndSetupItemOptions(
  subscriptionEndSetup: SubscriptionEndSetup,
  productPlans: CustomerJourneyPlan[],
  productRefId: string,
): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  const publishPlans = productPlans.filter((x) => (x as Plan).status === PackageStatus.Published);
  const hasFreePlans = publishPlans.some((x) => (x as Plan).pricingType === PricingType.Free);

  if (subscriptionEndSetup === SubscriptionEndSetup.DowngradeToFree && !hasFreePlans) {
    return {
      disabled: true,
      tooltipTitle: (
        <LinkToCreatePlan productId={productRefId} i18nKey="products.customerJourney.noFreePlansForDowngrade" />
      ),
    };
  }

  return { disabled: false };
}

function computeFields(
  productFreePlans: CustomerJourneyPlan[],
  productId: string,
  subConfigurationFields: Field<SubscriptionEndSetupFormFields>[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionEndSetupFormFields>[] {
  return [
    {
      type: 'inline',
      fields: [
        {
          type: 'custom',
          render: () => (
            <Text.B2 color="secondary" display="inline">
              <Trans
                i18nKey="products.trials.subscriptionEnds"
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
          ),
        },
        {
          id: 'subscriptionEndSetup',
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          tooltip: readonlyTooltip,
          values: [SubscriptionEndSetup.CancelSubscription, SubscriptionEndSetup.DowngradeToFree].map(
            (subscriptionEndSetup) => {
              const { disabled, tooltipTitle } = computeSubscriptionEndSetupItemOptions(
                subscriptionEndSetup,
                productFreePlans,
                productId,
              );

              return {
                value: subscriptionEndSetup,
                disabled,
                tooltip: {
                  title: tooltipTitle || undefined,
                  placement: 'left',
                },
                displayValue: formatSubscriptionEndSetup(subscriptionEndSetup),
              };
            },
          ),
        },
        ...subConfigurationFields,
      ],
    },
  ];
}

function computeFreePlanItemOptions(plan: CustomerJourneyPlan): {
  disabled: boolean;
  tooltipTitle?: React.ReactNode;
} {
  if (plan.status === PackageStatus.Draft) {
    return {
      disabled: true,
      tooltipTitle: <Text.B2>{t('products.customerJourney.publishDraftToChoseIt')}</Text.B2>,
    };
  }
  return { disabled: false };
}

function computeFreePlanFields(
  productPlans: CustomerJourneyPlan[],
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
): Field<SubscriptionEndSetupFormFields>[] {
  return [
    {
      type: 'inline',
      fields: [
        {
          id: 'downgradePlanId',
          type: 'select',
          variant: 'inline',
          withoutPadding: true,
          disabled: readonly,
          disabledCursorNotAllowed: readonly,
          hideDottedText: readonlyHideDottedText,
          placeholder: t('products.customerJourney.planSelectionPlaceholder'),
          tooltip: readonlyTooltip,
          values: productPlans
            .filter((plan) => plan.pricingType === PricingType.Free)
            .map((plan) => {
              const { disabled, tooltipTitle } = computeFreePlanItemOptions(plan);

              return {
                value: plan.id,
                disabled,
                tooltip: {
                  title: tooltipTitle || undefined,
                },
                overrideDisplayValue: (
                  <DottedText color="primary">
                    {t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}
                  </DottedText>
                ),
                displayValue: (
                  <Text.B2 color="primary">
                    {t(
                      plan.status === PackageStatus.Draft
                        ? 'sharedComponents.planDraftSuffix'
                        : 'sharedComponents.planSuffix',
                      { planName: plan.displayName },
                    )}
                  </Text.B2>
                ),
              };
            }),
        },
      ],
    },
  ];
}

function useFormFields(
  productPlans: CustomerJourneyPlan[],
  productId: string,
  { values }: FormRenderProps<SubscriptionEndSetupFormFields>,
  readonly: boolean,
  readonlyHideDottedText: boolean,
  readonlyTooltip: TooltipFields | undefined,
) {
  const subscriptionEndsFreePlanFields = useMemo(
    () => computeFreePlanFields(productPlans, readonly, readonlyHideDottedText, readonlyTooltip),
    [productPlans, readonly, readonlyHideDottedText, readonlyTooltip],
  );

  const subConfigurationFields = useMemo(() => {
    switch (values.subscriptionEndSetup) {
      case SubscriptionEndSetup.DowngradeToFree:
        return subscriptionEndsFreePlanFields;
      default:
        return [];
    }
  }, [values.subscriptionEndSetup, subscriptionEndsFreePlanFields]);

  return useMemo<Field<SubscriptionEndSetupFormFields>[]>(
    () =>
      computeFields(productPlans, productId, subConfigurationFields, readonly, readonlyHideDottedText, readonlyTooltip),
    [productPlans, productId, subConfigurationFields, readonly, readonlyHideDottedText, readonlyTooltip],
  );
}

export function SubscriptionEndSetupConfiguration({
  productPlans,
  productId,
  formRenderProps,
  readonly = false,
  readonlyHideDottedText = false,
  readonlyTooltip,
  highlight,
}: SubscriptionEndSetupConfigurationProps) {
  const fields = useFormFields(
    productPlans,
    productId,
    formRenderProps,
    readonly,
    readonlyHideDottedText,
    readonlyTooltip,
  );
  const content = (
    <GridFlex.Column container rowSpacing={3}>
      {drawFormFields(fields, formRenderProps)}
    </GridFlex.Column>
  );

  return highlight ? (
    <S.StyledBox $isHighlight={highlight} mt={4}>
      {content}
    </S.StyledBox>
  ) : (
    content
  );
}
