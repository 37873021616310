import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  IntegrationDeleteResponse,
  DeleteOneIntegrationMutation,
  DeleteOneIntegrationMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const DELETE_CUSTOMER = gql`
  mutation DeleteOneIntegration($input: DeleteOneIntegrationInput!) {
    deleteOneIntegration(input: $input) {
      id
    }
  }
`;

async function deleteIntegrationById(
  integrationId: string,
  { dispatch }: { dispatch: AppDispatch },
): Promise<IntegrationDeleteResponse | undefined> {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<DeleteOneIntegrationMutation, DeleteOneIntegrationMutationVariables>({
        mutation: DELETE_CUSTOMER,
        variables: { input: { id: integrationId } },
      });
      return { id: integrationId };
    },
    {
      successMessage: t('integrations.api.successDelete'),
      failureMessageHandler: () => t('integrations.api.failDelete'),
    },
    dispatch,
  );
}

export { deleteIntegrationById };
