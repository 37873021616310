import { t } from 'i18next';
import toLower from 'lodash/toLower';
import { ChangeType } from '@stigg-types/apiTypes';
import { ChangeGroupBox, ChangeRow, getChange } from './DiffBaseComponents';

export const resolveAddonType = (maxQuantity?: number | null) => {
  return maxQuantity === 1
    ? `${t('packages.singleAddonType')} ${toLower(t('packages.addon'))}`
    : `${t('packages.multiAddonType')} ${toLower(t('packages.addon'))}`;
};

export function AddonTypeChangeRow({
  before,
  after,
  changeType,
}: {
  before?: number | null;
  after?: number | null;
  changeType?: ChangeType | null;
}) {
  const beforeValue = resolveAddonType(before);
  const afterValue = resolveAddonType(after);
  return (
    <ChangeGroupBox changeType={changeType}>
      <ChangeRow
        before={beforeValue}
        after={afterValue}
        label="Type"
        showAlways
        {...getChange(beforeValue, afterValue)}
      />
    </ChangeGroupBox>
  );
}
