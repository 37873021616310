import { BillingPeriod } from '@stigg-types/apiTypes';
import { roundMaximumFractionDigits } from '../../../../utils/priceFormatUtils';

export function getPricePeriodUnit(billingPeriods: BillingPeriod[]): BillingPeriod {
  return billingPeriods.includes(BillingPeriod.Monthly) ? BillingPeriod.Monthly : BillingPeriod.Annually;
}

export function convertToBackendAmount(
  amount: number,
  billingPeriod: BillingPeriod,
  availableBillingPeriods: BillingPeriod[],
  isOneOff?: boolean,
): number {
  const pricePeriodUnit = getPricePeriodUnit(availableBillingPeriods);

  return billingPeriod === BillingPeriod.Annually && pricePeriodUnit === BillingPeriod.Monthly && !isOneOff
    ? roundMaximumFractionDigits(amount * 12)
    : roundMaximumFractionDigits(amount);
}

export function convertToFrontendAmount(
  amount: number,
  billingPeriod: BillingPeriod,
  availableBillingPeriods: BillingPeriod[],
): number {
  const pricePeriodUnit = getPricePeriodUnit(availableBillingPeriods);

  return billingPeriod === BillingPeriod.Annually && pricePeriodUnit === BillingPeriod.Monthly
    ? roundMaximumFractionDigits(amount / 12)
    : roundMaximumFractionDigits(amount);
}
