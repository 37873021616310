import { drawFormFields, Field, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { BillingModel, Feature } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { ChargeType, SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';
import { FeatureAutocomplete } from '../usageBasedPrice/FeatureAutocomplete';
import { OverageSetPriceComponent } from './OverageSetPriceComponent';
import { OverageEntitlementComponent } from './OverageEntitlementComponent';

export function OveragePrice({ index }: { index: number }) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { addOverageToCharge: addOverageToChargeEnabled } = useFlags<FeatureFlags>();
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const { charges, overageCharges, defaultCurrency } = formRenderProps.values;

  const currentOverageChargeId = `overageCharges.charges[${index}]`;
  const currentOverageCharge = overageCharges.charges[index];
  const isFeatureSelected = !!currentOverageCharge.feature;

  const isFeatureDisabled = (feature: Feature) => {
    let isDisabled = false;
    let disabledReasonText;

    if (overageCharges.charges.some((charge) => charge.feature?.id === feature.id)) {
      isDisabled = true;
      disabledReasonText = t('sharedComponents.alreadyAdded');
    }

    const chargeWithFeature = charges.find(
      (charge) => charge.type === ChargeType.UsageBased && charge.feature?.id === feature.id,
    );
    if (chargeWithFeature) {
      if (!addOverageToChargeEnabled) {
        isDisabled = true;
        disabledReasonText = t('pricing.overageCharges.canNotAddOverageForACharge');
      }

      if (chargeWithFeature.billingModel !== BillingModel.PerUnit) {
        isDisabled = true;
        disabledReasonText = t('pricing.overageCharges.canOnlyBeUsedWithPerUnitCharge');
      }
    }

    return {
      isDisabled,
      disabledReasonText,
    };
  };

  const chooseFeature = (feature: Feature) => {
    const id = `${currentOverageChargeId}.feature`;
    setFieldValue(id, feature);
    setFieldTouched(id);
  };

  if (currentOverageCharge.type !== ChargeType.UsageBased) {
    return null;
  }

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'idle',
      hide: () => isFeatureSelected,
      component: <Text.B2 color="secondary">{t('pricing.overageCharges.price.selectFeature')}</Text.B2>,
    },
    {
      type: 'idle',
      hide: () => isFeatureSelected,
      component: <FeatureAutocomplete isFeatureDisabled={isFeatureDisabled} chooseFeature={chooseFeature} />,
      gridProps: {
        mt: 2,
      },
    },
    {
      type: 'custom',
      hide: () => !isFeatureSelected,
      gridProps: {
        flexDirection: 'column',
      },
      render: () => (
        <OverageEntitlementComponent
          feature={currentOverageCharge.feature}
          entitlementId={`${currentOverageChargeId}.entitlement`}
        />
      ),
    },
    {
      type: 'custom',
      hide: () => !isFeatureSelected,
      render: () => (
        <OverageSetPriceComponent
          pricePeriodsId={`${currentOverageChargeId}.pricePeriods`}
          priceId={currentOverageChargeId}
          currency={defaultCurrency}
        />
      ),
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
