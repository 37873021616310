import * as Yup from 'yup';
import React, { useState } from 'react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { Button, Checkbox, Form, GridFlex, Icon } from '@stigg-components';
import { FieldArray } from 'formik';
import { Feature } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { EntitlementsTable } from '../EntitlementsTable';
import { EntitlementFormFields } from '../../../../entitlements/components/entitlementSettings/types';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { entitlementValidationSchema } from '../EntitlementsForm';
import { EntitlementSettings } from '../../../../entitlements/components/entitlementSettings/EntitlementSettings';
import { EntitlementFeatureAutocomplete } from '../../../../entitlements/components/entitlementSettings/EntitlementFeatureAutocomplete';
import { useSubStepsState } from '../../../pricing/components/SetPriceWizard/form/utils/useSubStepsState';
import { AddFeatureProps } from './AddEntitlementsDrawer';

type AddEntitlementFormProps = {
  initialValues: EntitlementFormFields;
  onSubmit: (values: EntitlementFormFields) => Promise<void>;
  onCancel: () => void;
  setCreateFeatureFlowProps: (props: AddFeatureProps) => void;
  excludedFeaturesIds: string[];
  basePlanEntitlementsByFeatureId: Map<string, PlanEntitlement>;
  basePlan?: BasePlanProps | null;
  withCustomEntitlementOption?: boolean;
  withEntitlementBehaviourOption?: boolean;
  chooseFeature: (option: Feature | null, push?: any) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
};

export const addEntitlementsValidationSchema = Yup.object().shape({
  entitlements: Yup.array().of(entitlementValidationSchema).required().min(1),
});

export function AddEntitlementForm({
  initialValues,
  onSubmit,
  onCancel,
  setCreateFeatureFlowProps,
  excludedFeaturesIds,
  basePlanEntitlementsByFeatureId,
  basePlan,
  withCustomEntitlementOption,
  withEntitlementBehaviourOption,
  chooseFeature,
  setHasUnsavedChanges,
}: AddEntitlementFormProps) {
  const { entitlementsSettingsImprovedUi } = useFlags<FeatureFlags>();
  const entitlementsState = useSubStepsState();
  const [addAnotherSelected, setAddAnotherSelected] = useState(false);

  const removeFeature = (index: number, remove?: any) => {
    if (remove) {
      remove(index);
    }
  };

  const additionalActionButtons = (
    <Checkbox
      checked={addAnotherSelected}
      onChange={(e) => setAddAnotherSelected(e.target.checked)}
      label={t('entitlements.addAnother')}
    />
  );

  return (
    <Form
      withStickyFooter
      disableSaveOnNoChanges
      initialValues={initialValues}
      submitButtonText={entitlementsSettingsImprovedUi ? t('sharedComponents.saveChanges') : t('entitlements.add')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      validationSchema={addEntitlementsValidationSchema}
      setHasUnsavedChanges={setHasUnsavedChanges}
      fields={(formRenderProps) => [
        {
          id: 'entitlements',
          type: 'custom',
          render: () => (
            <FieldArray name="entitlements">
              {({ push, remove }) => {
                const onNewEntitlementConfirmed = () => {
                  if (addAnotherSelected) {
                    chooseFeature(null, push);
                  }
                };

                const showGlobalFeatureAutoComplete =
                  !entitlementsSettingsImprovedUi || isEmpty(formRenderProps.values.entitlements);

                return (
                  <GridFlex.Column container wrap="nowrap" rowSpacing={3}>
                    {showGlobalFeatureAutoComplete && (
                      <EntitlementFeatureAutocomplete
                        formRenderProps={formRenderProps}
                        excludedFeaturesIds={excludedFeaturesIds}
                        chooseFeature={(feature: Feature | null) => chooseFeature(feature, push)}
                        setCreateFeatureFlowProps={setCreateFeatureFlowProps}
                        basePlanEntitlementsByFeatureId={basePlanEntitlementsByFeatureId}
                        basePlan={basePlan}
                      />
                    )}

                    {entitlementsSettingsImprovedUi &&
                      formRenderProps.values.entitlements.map((_, index) => (
                        <EntitlementSettings
                          index={index}
                          entitlementsState={entitlementsState}
                          onRemove={() => removeFeature(index, remove)}
                          excludedFeaturesIds={excludedFeaturesIds}
                          setCreateFeatureFlowProps={setCreateFeatureFlowProps}
                          basePlanEntitlementsByFeatureId={basePlanEntitlementsByFeatureId}
                          basePlan={basePlan}
                          withCustomEntitlementOption={withCustomEntitlementOption}
                          withEntitlementBehaviourOption={withEntitlementBehaviourOption}
                          formRenderProps={formRenderProps}
                          additionalActionButtons={additionalActionButtons}
                          onNewEntitlementConfirmed={onNewEntitlementConfirmed}
                        />
                      ))}

                    {entitlementsSettingsImprovedUi &&
                      !isEmpty(formRenderProps.values.entitlements) &&
                      formRenderProps.values.entitlements.every(({ isConfirmed }) => isConfirmed) && (
                        <GridFlex.Row item justifyContent="end">
                          <Button
                            variant="outlined"
                            onClick={() => chooseFeature(null, push)}
                            startIcon={<Icon icon="Add" />}>
                            {t('entitlements.addMore')}
                          </Button>
                        </GridFlex.Row>
                      )}

                    {!entitlementsSettingsImprovedUi && !isEmpty(formRenderProps.values.entitlements) && (
                      <GridFlex.Item mt={4}>
                        <EntitlementsTable
                          errors={formRenderProps.errors}
                          touched={formRenderProps.touched}
                          handleBlur={formRenderProps.handleBlur}
                          entitlements={formRenderProps.values.entitlements}
                          handleChange={formRenderProps.handleChange}
                          setFieldValue={formRenderProps.setFieldValue}
                          setFieldTouched={formRenderProps.setFieldTouched}
                          remove={(index: number) => removeFeature(index, remove)}
                          isAdd
                          withCustomEntitlementOption={withCustomEntitlementOption}
                        />
                      </GridFlex.Item>
                    )}
                  </GridFlex.Column>
                );
              }}
            </FieldArray>
          ),
        },
      ]}
    />
  );
}
