import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  FetchFeatureInspectorFiltersQuery,
  FetchFeatureInspectorFiltersQueryVariables,
  FeatureInspectorProductFilterFragment,
  PackageStatus,
  Plan,
  SortDirection,
  ProductSortFields,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';

const FETCH_FEATURE_INSPECTOR_FILTERS = gql`
  query FetchFeatureInspectorFilters(
    $plansFilter: PlanFilter
    $productsFilter: ProductFilter
    $productsSorting: [ProductSort!]
  ) {
    plans(filter: $plansFilter) {
      edges {
        node {
          id
          refId
          displayName
          basePlan {
            id
          }
          inheritedEntitlements {
            featureId
            resetPeriod
            usageLimit
          }
          entitlements {
            featureId
            resetPeriod
            usageLimit
          }
          productId
        }
      }
    }
    products(filter: $productsFilter, sorting: $productsSorting) {
      edges {
        node {
          ...FeatureInspectorProductFilter
        }
      }
    }
  }
  fragment FeatureInspectorProductFilter on Product {
    id
    refId
    displayName
    multipleSubscriptions
  }
`;

export async function fetchFeatureInspectorFilters(
  _: void,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<
        FetchFeatureInspectorFiltersQuery,
        FetchFeatureInspectorFiltersQueryVariables
      >({
        query: FETCH_FEATURE_INSPECTOR_FILTERS,
        fetchPolicy: 'network-only',
        variables: {
          plansFilter: {
            environmentId: { eq: accountReducer.currentEnvironmentId },
            isLatest: { is: true },
            status: { eq: PackageStatus.Published },
          },
          productsFilter: {
            environmentId: { eq: accountReducer.currentEnvironmentId },
          },
          productsSorting: { field: ProductSortFields.CreatedAt, direction: SortDirection.Asc },
        },
      });

      return {
        plans: res.data.plans.edges.map((edge) => edge.node) as Plan[],
        products: res.data.products.edges.map((edge) => edge.node) as FeatureInspectorProductFilterFragment[],
      };
    },
    {
      failureMessageHandler: () => t('dashboards.featureInspector.filtersFetchError'),
    },
    dispatch,
  );
}
