import React from 'react';
import { Viewer } from 'json-diff-kit';
import { styled } from '@stigg-theme';
import { Flex, Text } from '@stigg-components';
import { t } from 'i18next';
import 'json-diff-kit/dist/viewer.css';
import { EnvironmentDiff } from './useEnvironmentDiff';

const DiffAndHeaderContainer = styled(Flex.Column)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-right: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  border-left: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  flex: 1 0;
  min-height: 0;
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.background.default : theme.itamar.palette.background.darkBackground2};
`;

const DiffHeader = styled(Flex.RowCenter)`
  flex: 1 0;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  border-radius: ${({ theme }) => `${theme.itamar.border.radius} ${theme.itamar.border.radius} 0 0`};
  border: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};

  &:first-child {
    border-left: initial;
  }

  &:last-child {
    border-right: initial;
  }
`;

const DiffSideTopSpacer = styled(Flex.Item)`
  flex-grow: 1;
  min-width: 0;
  height: ${({ theme }) => theme.spacing(4)};

  &:first-child {
    border-right: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  }

  &:last-child {
    border-left: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  }
`;

const DiffContainer = styled(Flex.Item)`
  flex: 1 0;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
`;

const DiffViewer = styled(Viewer)`
  & {
    // Override styles from default JSON diff viewer styles to space between the sides of the diff
    border-collapse: separate !important;
    border-spacing: ${({ theme }) => `${theme.spacing(4)} 0 !important`};
  }

  & td:first-child {
    border-right: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }

  & td:last-child {
    border-left: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

const DiffSideBottomSpacer = styled(Flex.Item)`
  border-radius: ${({ theme }) => `0 0 ${theme.itamar.border.radius} ${theme.itamar.border.radius}`};
  border-bottom: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  flex-grow: 1;
  min-width: 0;
  height: ${({ theme }) => theme.spacing(4)};

  &:first-child {
    border-right: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  }

  &:last-child {
    border-left: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  }
`;

interface EnvironmentDiffViewerProps {
  diff: EnvironmentDiff;
  environmentName: string;
}

export const EnvironmentDiffViewer = ({ diff, environmentName }: EnvironmentDiffViewerProps) => (
  <DiffAndHeaderContainer>
    <Flex.Row gap={4}>
      <DiffHeader>
        <Text.B2>{t('accounts.copyMergeEnvironments.compareDialog.beforeChip', { environmentName })}</Text.B2>
      </DiffHeader>
      <DiffHeader>
        <Text.B2>{t('accounts.copyMergeEnvironments.compareDialog.afterChip', { environmentName })}</Text.B2>
      </DiffHeader>
    </Flex.Row>
    <Flex.Row gap={4}>
      <DiffSideTopSpacer />
      <DiffSideTopSpacer />
    </Flex.Row>
    <DiffContainer>
      <DiffViewer diff={diff} />
    </DiffContainer>
    <Flex.Row gap={4}>
      <DiffSideBottomSpacer />
      <DiffSideBottomSpacer />
    </Flex.Row>
  </DiffAndHeaderContainer>
);
