import { t } from 'i18next';
import isNumber from 'lodash/isNumber';
import { BillingModel, EntitlementBehavior, FeatureType } from '@stigg-types/apiTypes';
import { Box, Grid, GridFlex, Icon, InformationTooltip, Text } from '@stigg-components';
import { formatFeatureUnits } from '@stigg-common';
import { Trans } from 'react-i18next';
import React, { ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { formatResetPeriod } from '../../../../../entitlements/formatResetPeriod';
import { BasePlanProps, PlanEntitlement } from '../PackageGrantedEntitlements.types';
import { InformationTooltipProps } from '../../../../../../components/InformationTooltip';
import { formatQuantityAndUnits } from '../../../../../entitlements/formatQuantityAndUnits';
import { calculateUnitQuantityTextFromPriceFragments } from '../../pricingTypeViews/calculateUnitQuantity';

function getOverriddenText(overridingEntity?: PlanEntitlement): InformationTooltipProps['title'] | undefined {
  if (!overridingEntity) {
    return undefined;
  }

  const quantityAndUnits = formatQuantityAndUnits(overridingEntity);

  return (
    <Text.B2>
      <Trans
        i18nKey="entitlements.overriddenPackageEntitlement"
        t={t}
        values={{ quantityAndUnits }}
        components={[<Text.B2 $bold display="inline" />]}
      />
    </Text.B2>
  );
}

function getOverridingIndication(
  entity: PlanEntitlement,
  overriddenEntity?: PlanEntitlement,
  basePlan?: BasePlanProps | null,
): ReactNode | undefined {
  let text: string | undefined;

  if (entity.behavior === EntitlementBehavior.Override) {
    text = t('entitlements.overridingPlanEntitlement');
  } else if (overriddenEntity && basePlan) {
    const quantityAndUnits = formatQuantityAndUnits(overriddenEntity);
    text = t('entitlements.overridingPackageEntitlement', {
      quantityAndUnits,
      planName: basePlan.displayName,
    });
  }

  if (!text) {
    return null;
  }

  const content = <Text.B2>{text}</Text.B2>;

  return (
    <InformationTooltip arrow placement="top" $maxWidth={600} $padding={2} title={content}>
      <Box height={20}>
        <Icon icon="Override" size={20} color="default" overrideStroke />
      </Box>
    </InformationTooltip>
  );
}

export function Value({
  entity,
  overridingEntity,
  overriddenEntity,
  basePlan,
  hasOverageCharge,
}: {
  entity: PlanEntitlement;
  overridingEntity?: PlanEntitlement;
  overriddenEntity?: PlanEntitlement;
  basePlan?: BasePlanProps | null;
  hasOverageCharge?: boolean;
}) {
  const { overagePricing: isOveragePricingEnabled } = useFlags<FeatureFlags>();
  const withOverageIndicator = hasOverageCharge && isOveragePricingEnabled;

  const overagePriceIndication = withOverageIndicator ? (
    <Grid item>
      <Text.Sub2>{t('entitlements.hasOverageCharge')}</Text.Sub2>
    </Grid>
  ) : null;

  if (entity.price) {
    return (
      <GridFlex.Column item container>
        <Grid item>
          <Text.B2>
            {entity.price.billingModel === BillingModel.PerUnit
              ? calculateUnitQuantityTextFromPriceFragments(entity.price, undefined, entity.feature.featureUnitsPlural)
              : t('pricing.usageBasedUnlimited')}
          </Text.B2>
        </Grid>
        {overagePriceIndication}
      </GridFlex.Column>
    );
  }

  const overriddenText = getOverriddenText(overridingEntity);
  const overridingIndication = getOverridingIndication(entity, overriddenEntity, basePlan);

  return (
    <GridFlex.RowCenter container justifyContent="left">
      {entity.feature?.featureType === FeatureType.Boolean ? (
        <Grid item pt={1} mr={2}>
          <Text.B2>{t('entitlements.accessGranted')}</Text.B2>
        </Grid>
      ) : (
        <GridFlex.Column item container sx={{ textDecoration: overriddenText ? 'line-through' : 'none' }}>
          <Grid item>
            <GridFlex.RowCenter gap={1}>
              <InformationTooltip
                arrow
                placement="top"
                $maxWidth={400}
                $padding={2}
                title={overriddenText ? <Text.B2>{overriddenText}</Text.B2> : ''}>
                <GridFlex.RowMiddle>
                  <Text.B2 sx={{ whiteSpace: 'nowrap' }}>
                    {entity.hasUnlimitedUsage
                      ? `${t('entitlements.unlimitedUsage')} ${entity.feature?.featureUnitsPlural}`
                      : entity.isCustom
                      ? `${t('entitlements.custom')} ${entity.feature?.featureUnitsPlural}`
                      : formatFeatureUnits(entity.feature, entity.usageLimit)}
                    {entity.resetPeriod &&
                      (isNumber(entity.usageLimit) || entity.hasUnlimitedUsage) &&
                      ` ${formatResetPeriod(entity.resetPeriod, true)}`}
                  </Text.B2>
                </GridFlex.RowMiddle>
              </InformationTooltip>
              {overridingIndication}
            </GridFlex.RowCenter>
          </Grid>
          {overagePriceIndication}
          {!withOverageIndicator && entity.hasSoftLimit && (
            <Grid item>
              <Text.Sub2>{t('entitlements.hasSoftLimit')}</Text.Sub2>
            </Grid>
          )}
        </GridFlex.Column>
      )}
    </GridFlex.RowCenter>
  );
}
