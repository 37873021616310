import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import { UsageHistoryQuery, UsageHistoryQueryVariables, UsageHistoryInput } from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';

const FETCH_USAGE_HISTORY = gql`
  query UsageHistory($usageHistoryInput: UsageHistoryInput!) {
    usageHistory(usageHistoryInput: $usageHistoryInput) {
      ...UsageHistoryFragment
    }
  }
  fragment UsageHistoryFragment on UsageHistory {
    startDate
    endDate
    usageMeasurements {
      ...UsageMeasurementPoint
    }
    groups {
      groupInfo {
        key
        value
      }
      usageMeasurements {
        ...UsageMeasurementPoint
      }
    }
  }

  fragment UsageMeasurementPoint on UsageMeasurementPoint {
    date
    value
    isResetPoint
  }
`;

async function fetchUsageHistory(
  usageHistoryInput: UsageHistoryInput,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();

      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const res = await apolloClient.query<UsageHistoryQuery, UsageHistoryQueryVariables>({
        query: FETCH_USAGE_HISTORY,
        fetchPolicy: 'no-cache',
        variables: {
          usageHistoryInput: {
            ...usageHistoryInput,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });
      return res.data.usageHistory;
    },
    { failureMessageHandler: () => t('customers.api.failUsageMeasurementsFetch') },
    dispatch,
  );
}

export { fetchUsageHistory };
