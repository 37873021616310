import { t } from 'i18next';
import { useState } from 'react';
import { EmptyCardContent, PageCard, useScrollableAnchor, Grid, Text, Button, Icon, Link } from '@stigg-components';
import { useConfirmationDialog } from '@stigg-common';
import { AddonFragment } from '@stigg-types/apiTypes';
import { AddDependentAddonsDialog } from './AddDependentAddonsDialog';
import { useAppDispatch } from '../../../../redux/store';
import { updateAddonAction } from '../addonSlice';
import { AddonDependenciesList } from './AddonDependenciesList';

type AddonDependenciesProps = {
  readonly: boolean;
  addon: AddonFragment;
};

type AddonDependenciesTitleProps = {
  readonly: boolean;
  onAddDependentAddons: () => void;
};

type EmptyAddonDependenciesProps = {
  readonly: boolean;
  onAddDependentAddons: () => void;
};

const AddonDependenciesTitle = ({ readonly, onAddDependentAddons }: AddonDependenciesTitleProps) => {
  return (
    <>
      <Grid $fullWidth justifyContent="space-between">
        <Text.H3>{t('addons.addonDependencies.title')}</Text.H3>
        {!readonly ? (
          <Button
            color="primary"
            $outlined
            data-testid="edit-addon-dependencies-button"
            startIcon={<Icon icon="Add" type="materialIcons" />}
            onClick={onAddDependentAddons}>
            {t('sharedComponents.add')}
          </Button>
        ) : null}
      </Grid>
      <Text.Sub2 mb={4}>{t('addons.addonDependencies.subtitle')}</Text.Sub2>
    </>
  );
};

const EmptyAddonDependencies = ({ readonly, onAddDependentAddons }: EmptyAddonDependenciesProps) => {
  return (
    <EmptyCardContent>
      <Text.B2>{t('addons.addonDependencies.emptyStateText')}</Text.B2>
      {!readonly && (
        <Link variant="body2" ml={1} onClick={onAddDependentAddons}>
          {t('addons.addonDependencies.emptyStateActionText')}
        </Link>
      )}
    </EmptyCardContent>
  );
};

export function AddonDependencies({ addon, readonly }: AddonDependenciesProps) {
  const dispatch = useAppDispatch();
  const { elementRef } = useScrollableAnchor({
    anchor: 'addonDependencies',
    delay: 250,
  });
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleAddDependentAddons = () => {
    setAddDialogOpen(true);
  };

  const handleUpdate = async (addons: AddonFragment[]) => {
    await dispatch(
      updateAddonAction({
        addonRefId: addon.refId,
        updatePayload: {
          id: addon.id,
          dependencies: addons.map(({ refId }) => refId) || [],
        },
        silentFetch: true,
      }),
    );
  };

  const [indexToRemove, setIndexToRemove] = useState<number | null>(null);
  const [RemoveConfirmationDialog, setRemoveConfirmationDialog, props] = useConfirmationDialog({
    title: t('addons.addonDependencies.deleteConfirmationDialogTitle'),
    content: t('addons.addonDependencies.deleteConfirmationDialogContent'),
    confirmButtonColor: 'error',
    confirmButtonText: t('sharedComponents.remove'),
    handleCancel: () => {
      setIndexToRemove(null);
    },
    handleConfirm: async () => {
      const updatedDependencies = addon.dependencies?.filter((_, i) => i !== indexToRemove) || [];
      await handleUpdate(updatedDependencies);
    },
  });

  return (
    <PageCard ref={elementRef}>
      <AddonDependenciesTitle readonly={readonly} onAddDependentAddons={handleAddDependentAddons} />

      {addon.dependencies?.length ? (
        <AddonDependenciesList
          addons={addon.dependencies}
          withLastUpdated
          withStatus
          remove={(index) => {
            setIndexToRemove(index);
            setRemoveConfirmationDialog(true);
          }}
        />
      ) : (
        <EmptyAddonDependencies readonly={readonly} onAddDependentAddons={handleAddDependentAddons} />
      )}
      <AddDependentAddonsDialog
        onSubmit={async (data) => {
          await handleUpdate(data.addons);
        }}
        open={addDialogOpen}
        setDialogOpen={setAddDialogOpen}
        currentAddon={addon}
        excludedAddonIds={addon.dependencies?.map(({ refId }) => refId) || []}
      />
      <RemoveConfirmationDialog {...props} />
    </PageCard>
  );
}
