import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  EventLogType,
  FetchTestHookDataQuery,
  FetchTestHookDataQueryVariables,
  TestHookDataFragment,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const FETCH_TEST_EVENT_HOOK_DATA = gql`
  query FetchTestHookData($eventLogType: EventLogType!) {
    testHookData(eventLogType: $eventLogType) {
      ...TestHookDataFragment
    }
  }
  fragment TestHookDataFragment on TestHook {
    testHookEventType
    testHookPayload
  }
`;

async function fetchTestHookEventData(
  eventLogType: EventLogType,
  { dispatch }: { dispatch: AppDispatch },
): Promise<TestHookDataFragment> {
  return executeOperationSafely(
    async () => {
      const res = await apolloClient.query<FetchTestHookDataQuery, FetchTestHookDataQueryVariables>({
        query: FETCH_TEST_EVENT_HOOK_DATA,
        fetchPolicy: 'network-only',
        variables: {
          eventLogType,
        },
      });
      return res.data.testHookData;
    },
    {
      failureMessageHandler: () => t('hooks.api.failFetchTestEventHookData'),
    },
    dispatch,
  );
}

export { fetchTestHookEventData };
