/* eslint-disable no-restricted-imports */
import { Grid as MuiGrid, GridProps as MuiGridProps } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';

type StyledGridProps = {
  $fullWidth?: boolean;
  $fullHeight?: boolean;
};

export type GridProps = MuiGridProps & StyledGridProps;

const StyledGrid = styled(MuiGrid)<StyledGridProps>`
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}

  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100%;
    `}
`;

const GridItem = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <StyledGrid item {...props} ref={ref} />
));

const GridFlexRow = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <StyledGrid display="flex" {...props} ref={ref} />
));

// COLUMN
const GridFlexColumn = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridFlexRow flexDirection="column" {...props} ref={ref} />
));
const GridFlexColumnCenter = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridFlexColumn justifyContent="center" {...props} ref={ref} />
));
const GridFlexColumnMiddle = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridFlexColumnCenter alignItems="center" {...props} ref={ref} />
));

// ROW
const GridCenter = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridFlexRow alignItems="center" {...props} ref={ref} />
));
const GridRowCenter = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridCenter justifyContent="center" {...props} ref={ref} />
));
const GridRowSpaceBetween = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridCenter justifyContent="space-between" {...props} ref={ref} />
));
const GridRowSpaceBetweenTop = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>((props, ref) => (
  <GridFlexRow justifyContent="space-between" {...props} ref={ref} />
));

export const Grid = GridFlexRow;
export const GridFlex = {
  Item: GridItem,
  Row: GridFlexRow,
  Column: GridFlexColumn,
  ColumnCenter: GridFlexColumnCenter,
  ColumnMiddle: GridFlexColumnMiddle,
  RowCenter: GridCenter,
  RowMiddle: GridRowCenter,
  RowSpaceBetween: GridRowSpaceBetween,
  RowSpaceBetweenTop: GridRowSpaceBetweenTop,
};
export const Flex = GridFlex;
