import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Feature, Plan } from '@stigg-types/apiTypes';
import { useCubeQuery } from '@cubejs-client/react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import '../../../../common/chartjs/chartjs.plugins';
import { WidgetCard } from '../WidgetCard';
import { composeUsageDistributionQuery } from '../../../queries/usageDistributionQuery';
import { getEntitlement } from '../../../helpers/plan.helper';
import { composeCurrentUsageWidgetSubtitle } from '../../../helpers/text.helper';
import { ChartErrorMessage, ChartNoDataMessage, ChartPlaceholder } from '../ChartPlaceholders';
import { UsageDistributionChartTooltip, useUsageDistributionChartTooltip } from './UsageDistributionChartTooltip';
import { PlanLimitTooltip, usePlanLimitTooltip } from './PlanLimitTooltip';
import { computeChartData } from './UsageDistribution.chartjs';
import { FIRST_WIDGET_HEIGHT } from '../../FeatureInspector';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { setShowUncompletedPeriod } from '../../../featureInspectorSlice';
import { useCubeFeatureStaticData } from '../useCubeFeatureStaticData';
import sandboxStaticCubeData from './sandbox-usage-distribution-query.json';
import { useIsShowMockData } from '../../../../common/hooks';
import { useSetChartLastUpdate } from '../../../../common/chartsLastUpdate/useSetChartLastUpdate';

/**
 * Automatically changes to "Current period" when detecting an empty "Completed period" chart.
 * ### This is a work around - this code should be moved up to `feature-inspector` component
 * along side all the query fetching for the widgets data ###
 */
const useAutoPeriodChange = ({
  showUncompletedPeriod,
  isReady,
  data,
  entitlement,
}: {
  showUncompletedPeriod: boolean;
  isReady?: boolean;
  data: ReturnType<typeof computeChartData>['data'];
  entitlement: ReturnType<typeof getEntitlement>;
}) => {
  const dispatch = useAppDispatch();
  const { allowOverridePeriod } = useSelector((state: RootState) => state.dashboards.featureInspector);

  useEffect(() => {
    if (!allowOverridePeriod || !isReady || showUncompletedPeriod || !entitlement?.resetPeriod) {
      return;
    }

    if (!data) {
      void dispatch(setShowUncompletedPeriod(true));
    }
  }, [dispatch, allowOverridePeriod, data, isReady, entitlement, showUncompletedPeriod]);
};

const useCubeRealData = ({
  plan,
  feature,
  environmentId,
  availablePlans,
  showUncompletedPeriod,
  skipCubeQuery,
}: {
  environmentId: string;
  feature: Feature;
  availablePlans: Plan[];
  showUncompletedPeriod: boolean;
  plan: Plan;
  skipCubeQuery?: boolean;
}) => {
  const entitlement = getEntitlement(plan, feature)!;

  const query = useMemo(() => {
    const availablePlansRefIds = availablePlans.map((plan) => plan.refId);

    return composeUsageDistributionQuery(
      environmentId,
      feature.refId,
      entitlement,
      availablePlansRefIds,
      showUncompletedPeriod,
    );
  }, [availablePlans, environmentId, feature.refId, entitlement, showUncompletedPeriod]);

  return useCubeQuery(query, { skip: skipCubeQuery });
};

export const UsageDistributionWidget = ({
  environmentId,
  feature,
  plan,
  availablePlans,
  showUncompletedPeriod,
  color,
}: {
  environmentId: string;
  feature: Feature;
  plan: Plan;
  availablePlans: Plan[];
  showUncompletedPeriod: boolean;
  color: string;
}) => {
  const theme = useTheme();
  const isShowMockData = useIsShowMockData();
  const entitlement = getEntitlement(plan, feature)!;
  const [isDataReady, setIsDataReady] = useState(false);
  const planLimitTooltip = usePlanLimitTooltip(feature);
  const chartTooltip = useUsageDistributionChartTooltip(feature, plan);

  const realData = useCubeRealData({
    plan,
    feature,
    environmentId,
    availablePlans,
    showUncompletedPeriod,
    skipCubeQuery: isShowMockData,
  });

  const mockData = useCubeFeatureStaticData({
    featureRefId: feature.refId,
    planRefId: plan.refId,
    mock: sandboxStaticCubeData,
  });

  const { error, isLoading, resultSet } = isShowMockData ? mockData : realData;

  const { data, options } = useMemo(
    () =>
      computeChartData(
        resultSet,
        feature,
        plan,
        availablePlans,
        color,
        chartTooltip.tooltipHandler,
        planLimitTooltip.tooltipHandler,
        theme,
        () => setIsDataReady(true),
      ),
    [
      resultSet,
      feature,
      plan,
      availablePlans,
      color,
      chartTooltip.tooltipHandler,
      planLimitTooltip.tooltipHandler,
      theme,
    ],
  );

  useAutoPeriodChange({ showUncompletedPeriod, isReady: isDataReady && !isLoading, data, entitlement });

  const title = t('dashboards.featureInspector.usageDistribution', { units: feature.featureUnitsPlural });
  const subtitle = composeCurrentUsageWidgetSubtitle(entitlement, showUncompletedPeriod);

  useSetChartLastUpdate(title, resultSet);

  let content: JSX.Element;

  if (isLoading) {
    content = <ChartPlaceholder />;
  } else if (error) {
    content = <ChartErrorMessage error={error} />;
  } else if (!resultSet || !data || !options) {
    content = <ChartNoDataMessage />;
  } else {
    content = (
      <>
        <UsageDistributionChartTooltip tooltipInfo={chartTooltip.tooltipInfo} />
        <PlanLimitTooltip tooltipInfo={planLimitTooltip.tooltipInfo} />
        <Bar options={options} data={data} />
      </>
    );
  }

  return (
    <WidgetCard isLoading={isLoading} height={FIRST_WIDGET_HEIGHT} span={12} title={title} subtitle={subtitle}>
      {content}
    </WidgetCard>
  );
};
