import { GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { useMemo } from 'react';
import { CodeBlockSection } from '../../../features/components/featureDetails/CodeBlockSection';
import { SdkType } from '../../../features/components/featureDetails/sdkToSnippetData';
import { WidgetIdentifier } from '../../useWidgetsData';
import { AdditionalSupportedLanguages } from './AdditionalSupportedLanguages';
import { getWidgetsCodeSnippets } from './getWidgetsCodeSnippets';
import { InfoBox } from './InfoBox';

export function WidgetCodeBlock({
  clientApiKey,
  widgetIdentifier,
  customerId,
  productId,
  customerPortalHiddenSections,
  countryCode,
}: any) {
  const docsUrl = useMemo(() => {
    switch (widgetIdentifier) {
      case WidgetIdentifier.Paywall:
        return t('widgets.paywallCodeSnippetInfoUrlDocs');
      case WidgetIdentifier.CustomerPortal:
        return t('widgets.customerPortalCodeSnippetInfoUrlDocs');
      case WidgetIdentifier.Checkout:
        return t('widgets.checkoutCodeSnippetInfoUrlDocs');
      default:
        return t('widgets.defaultCodeSnippetInfoUrlDocs');
    }
  }, [widgetIdentifier]);

  return (
    <GridFlex.Column gap={2}>
      <InfoBox text={t('widgets.codeSnippetInfoText')} externalUrl={docsUrl} />
      <CodeBlockSection
        maxHeight={500}
        collapsable={false}
        code={() =>
          getWidgetsCodeSnippets(
            clientApiKey,
            widgetIdentifier,
            customerPortalHiddenSections,
            customerId,
            productId,
            countryCode,
          )
        }
        availableSdks={[SdkType.REACT]}
      />
      <AdditionalSupportedLanguages widgetIdentifier={widgetIdentifier} />
    </GridFlex.Column>
  );
}
