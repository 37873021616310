import { TypographyProps } from '@stigg-components';
import { TextColor } from '@stigg-theme';
import { ReactNode } from 'react';

export type { DetailsLayoutType } from './DetailsLayout';
export type { OptionsDropdownType } from './dropdowns/optionsDropdown/OptionsDropdown';
export type { LinkProps } from './Link';
export type { GridProps } from './Grids';
export * from './form/Form.types';

export type CustomVariants = Extract<
  TypographyProps['variant'],
  'subtitle2' | 'caption' | 'body1' | 'body2' | 'h1' | 'h3' | 'h6'
>;

// set the default color for every variant-based components
export const variantToColor = (
  variant: CustomVariants,
): {
  variant: CustomVariants;
  color: TextColor;
} => {
  switch (variant) {
    case 'caption':
    case 'subtitle2':
    case 'body1':
      return {
        variant,
        color: 'secondary',
      };
    case 'body2':
    case 'h1':
    case 'h3':
    case 'h6':
    default:
      return {
        variant,
        color: 'primary',
      };
  }
};

export type ConfirmationDialogProps = {
  open: boolean;
  handleClose: (isConfirmed: boolean) => Promise<void> | void;
  title: string;
  content: string | ReactNode;
  width?: number;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: 'primary' | 'error';
  reverseButtonsOrder?: boolean;
  disabled?: boolean;
};
