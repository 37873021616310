import { Text } from '@stigg-components';
import { t } from 'i18next';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';

export function getChargesText(count: number, { emptyText }: { emptyText?: string } = {}) {
  if (count > 1) {
    return t('pricing.pluralCharges', { count });
  }
  if (count === 1) {
    return t('pricing.singularCharge');
  }
  return emptyText || t('pricing.noCharges');
}

export function ChargesStepSummary() {
  const {
    formRenderProps: {
      values: { charges },
    },
  } = useSetPriceWizardFormContext();

  return <Text.B2>{getChargesText(charges.length)}</Text.B2>;
}
