import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';
import { Button, Text, Icon, Divider, GridFlex, PageHeader, ExternalLink } from '@stigg-components';
import { CustomerListFragment, UsageEventWithCustomerFragment } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { t } from 'i18next';
import { UsageEventItem, fetchUsageEventsAction } from './usageEventsSlice';
import Table from '../../components/table/Table';
import { RootState, useAppDispatch } from '../../redux/store';
import { headCells } from './headCells';
import { CircleFillLoader } from './CircleFillLoader';
import { formatDate, generateRandomSlug } from '../common';
import { UsageEventsCustomerFilter } from './UsageEventsCustomerFilter';
import { UsageEventsDetails } from './UsageEventDetails';

export const EVENTS_DETAILS_SIDE_NAV_SIZE = 600;
const LOAD_EVENTS_INTERVAL_SECONDS = 5;

export function UsageEventsPage() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [streamEvents, setStreamEvents] = useState(true);
  const [currentEvent, setCurrentEvent] = useState<UsageEventItem | null>(null);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const usageEvents = useSelector((state: RootState) => state.eventsReducer.events);
  const isLoading = useSelector((state: RootState) => state.eventsReducer.isLoading);
  const [lastRefreshDate, setLastRefreshDate] = useState<Date | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerListFragment | undefined>();
  const fetchInterval = useRef<NodeJS.Timeout | null>(null);
  // We use this in order to re-render the circle loader so it synchronized with the fetch interval
  const fetchIntervalId = useRef(generateRandomSlug());
  const timezone = moment.tz.guess();

  const onToggleEventDetails = (event: UsageEventWithCustomerFragment) => {
    setCurrentEvent(event);
  };

  const onClose = () => {
    setCurrentEvent(null);
  };

  const startStreamInterval = useCallback(() => {
    const loadEvents = (isInitialLoad: boolean) => {
      if (currentEnvironmentId) {
        setLastRefreshDate(new Date());
        void dispatch(
          fetchUsageEventsAction({
            environmentId: currentEnvironmentId,
            customerId: selectedCustomer?.customerId,
            isInitialLoad,
          }),
        );
      }
    };

    if (fetchInterval.current) {
      clearInterval(fetchInterval.current);
      fetchInterval.current = null;
    }
    fetchInterval.current = setInterval(() => {
      loadEvents(false);
    }, LOAD_EVENTS_INTERVAL_SECONDS * 1000);

    fetchIntervalId.current = generateRandomSlug();
    loadEvents(true);
    setStreamEvents(true);
  }, [dispatch, currentEnvironmentId, selectedCustomer]);

  const stopStream = () => {
    setStreamEvents(false);
    if (fetchInterval.current) {
      clearInterval(fetchInterval.current);
      fetchInterval.current = null;
    }
  };

  const startStream = () => {
    setStreamEvents(true);
    startStreamInterval();
  };

  useEffect(() => {
    startStreamInterval();

    return () => {
      if (fetchInterval.current) {
        clearInterval(fetchInterval.current);
        fetchInterval.current = null;
      }
    };
  }, [startStreamInterval]);

  const shouldHighlightRow = (currentEvent: UsageEventItem, prevEvent: UsageEventItem) => {
    return currentEvent.id !== prevEvent.id && !!currentEvent.isNew;
  };

  const emptyEvents = !isNil(usageEvents) && usageEvents.length === 0;

  return (
    <>
      <PageHeader title={t('usageEvents.title')} />
      <Divider my={4} />

      <GridFlex.Column>
        <GridFlex.RowSpaceBetween>
          <GridFlex.RowCenter mb={4}>
            {streamEvents ? (
              <Button
                sx={{ width: 142 }}
                startIcon={
                  <CircleFillLoader key={fetchIntervalId.current} loadEverySeconds={LOAD_EVENTS_INTERVAL_SECONDS} />
                }
                variant="contained"
                onClick={stopStream}>
                {t('usageEvents.pauseStream')}
              </Button>
            ) : (
              <Button
                sx={{ width: 142 }}
                variant="contained"
                startIcon={<Icon icon="Play" size={20} color="default" />}
                onClick={startStream}
                color={theme.isLightTheme ? 'secondary' : 'disabled'}>
                {t('usageEvents.startStream')}
              </Button>
            )}

            <UsageEventsCustomerFilter onChange={setSelectedCustomer} selectedCustomer={selectedCustomer} />
          </GridFlex.RowCenter>

          {lastRefreshDate && (
            <Text.B2 color="secondary" mr={1}>
              {t('usageEvents.lastFeedRefresh', {
                lastUpdated: formatDate(lastRefreshDate, { timezone, withTime: true, formatType: 'longDate' }),
              })}
            </Text.B2>
          )}
        </GridFlex.RowSpaceBetween>
        {!emptyEvents ? (
          <Table
            isLoading={isLoading && isEmpty(usageEvents)}
            headCells={headCells()}
            shouldHighlightRow={shouldHighlightRow}
            highlighDelay={250}
            data={usageEvents || []}
            onRowClick={onToggleEventDetails}
          />
        ) : (
          <GridFlex.Column $fullWidth $fullHeight alignItems="center" mt={22}>
            <GridFlex.Column $fullWidth alignItems="center">
              <Text.H6 mb={2}>{t('usageEvents.noEventsReported')}</Text.H6>
              <GridFlex.RowCenter>
                <Text.B2 mr={1}>{t('usageEvents.noEventsReportedSubtitle')}</Text.B2>
                <ExternalLink label={t('sharedComponents.learnMore')} url={t('usageEvents.meteringLearnMoreLink')} />
              </GridFlex.RowCenter>
            </GridFlex.Column>
          </GridFlex.Column>
        )}
      </GridFlex.Column>

      <UsageEventsDetails currentEvent={currentEvent} onClose={onClose} />
    </>
  );
}
