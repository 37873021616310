import { getStiggTheme } from '@stigg-theme';

export type IconColor =
  | 'default'
  | 'active'
  | 'disabled'
  | 'warning'
  | 'success'
  | 'error'
  | 'white'
  | 'primary'
  | 'primary.main'
  | 'secondary'
  | 'other.outlineBorder';

export const getIconColor = (color: IconColor) => {
  const { theme } = getStiggTheme();
  switch (color) {
    case 'default':
      return theme.itamar.palette.action.active;
    case 'active':
      return theme.itamar.palette.action.active;
    case 'secondary':
      return theme.itamar.palette.secondary.main;
    case 'disabled':
      return theme.isLightTheme ? theme.itamar.palette.action.disabled : theme.itamar.palette.text.disabled;
    case 'warning':
      return theme.itamar.palette.warning.main;
    case 'success':
      return theme.itamar.palette.success.main;
    case 'white':
      return theme.itamar.palette.white;
    case 'primary':
      return theme.itamar.palette.text.primary;
    case 'primary.main':
      return theme.itamar.palette.primary.main;
    case 'error':
      return theme.itamar.palette.error.main;
    case 'other.outlineBorder':
      return theme.itamar.palette.other.outlineBorder;
    default:
      return color;
  }
};
