import {
  Box,
  InformationTooltip,
  Icon,
  Collapse,
  GridFlex,
  IconButton,
  MenuItem,
  Select,
  Text,
  Divider,
  LongText,
  Grid,
  Link,
  CollapsableSectionIcon,
} from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import React, { useState } from 'react';
import map from 'lodash/map';
import { WidgetIdentifier } from '../../useWidgetsData';
import { DataType, WidgetContext } from '../../WidgetPage';
import { useNavigation } from '../../../navigation/useNavigation';
import { PreviewOptionsSelectBox } from './PreviewOptionsSelectBox';
import { ProductListItemFragment } from '../../../../types/apiTypes';

const formatDataType = (dataType: DataType, textSuffix?: string) => {
  let text = dataType === DataType.YourData ? t('widgets.yourData') : t('widgets.demoData');
  if (textSuffix) {
    text = text.concat(textSuffix);
  }
  return <Text.B2>{text}</Text.B2>;
};
export function PreviewOptionsPanel({
  widgetContext,
  dataType,
  switchDataType,
  stiggProducts,
  onSelectedProductChange,
  hasPublishPlans,
  countrySelectComponent,
}: {
  dataType: DataType;
  switchDataType: (dataType: DataType) => void;
  widgetContext: WidgetContext;
  stiggProducts: ProductListItemFragment[];
  onSelectedProductChange: (product: ProductListItemFragment) => void;
  hasPublishPlans: boolean;
  countrySelectComponent: () => JSX.Element | null;
}) {
  const { widgetIdentifier } = widgetContext;
  const navigation = useNavigation();
  const [isPreviewOptionsOpen, setIsPreviewOptionsOpen] = useState(false);
  const isPaywallWidget = widgetIdentifier === WidgetIdentifier.Paywall;
  const yourDataPaywallWidget = dataType === DataType.YourData && isPaywallWidget;
  const widgetHasOptions = !yourDataPaywallWidget && widgetIdentifier !== WidgetIdentifier.Checkout;

  const optionInSelect = (widgetIdentifier: WidgetIdentifier, dataType: DataType) => {
    if (dataType === DataType.YourData) {
      if (widgetIdentifier !== WidgetIdentifier.Paywall) {
        return {
          disable: true,
          text: t('widgets.comingSoon'),
        };
      }
      if (!stiggProducts.length) {
        return {
          disable: true,
          tooltip: (
            <Trans
              t={t}
              i18nKey="widgets.noAvailableProductsHint"
              components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.createProductPage())} />]}
            />
          ),
        };
      }
      if (!hasPublishPlans) {
        return {
          disable: true,
          tooltip: (
            <Trans
              t={t}
              i18nKey="widgets.noAvailablePlansHint"
              components={[
                <Link
                  onClick={() => navigation.navigateTo(navigation.appRoutes.productPage(stiggProducts[0].refId))}
                />,
              ]}
            />
          ),
        };
      }
    }

    return {
      disable: false,
    };
  };

  const shouldShowProductsSelect = stiggProducts.length > 1 && isPaywallWidget && !!widgetContext.selectedProduct;

  const handleProductChange = (e: any) => {
    const { value: productId } = e.target;
    const currentSelectedProduct = stiggProducts.find((product) => product.id === productId);
    if (currentSelectedProduct) {
      onSelectedProductChange(currentSelectedProduct);
    }
  };

  return (
    <GridFlex.Column
      container
      borderBottom={(theme) => theme.itamar.border.border}
      borderColor={(theme) => theme.itamar.palette.other.outlineBorder}>
      <GridFlex.RowSpaceBetweenTop container alignItems="flex-start" py={4}>
        <GridFlex.RowMiddle gap={4} px={6}>
          <Text.H6 color="secondary">{t('widgets.preview')}</Text.H6>

          {shouldShowProductsSelect && (
            <Select
              width={200}
              placeholder={t('integrations.productFamilyPlaceholder')}
              onChange={handleProductChange}
              value={widgetContext.selectedProduct!.id}
              renderValue={(value) => (
                <GridFlex.RowCenter columnGap={2}>
                  <Icon icon="Grid" type="reactFeather" color="active" />
                  <LongText color="primary" variant="body2" wordBreak sx={{ maxWidth: 115 }}>
                    {stiggProducts?.find((product) => product.id === value)?.displayName || ''}
                  </LongText>
                </GridFlex.RowCenter>
              )}>
              {(stiggProducts || []).map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  <GridFlex.Column>
                    <LongText color="primary" variant="body2" wordBreak>
                      {product.displayName || ''}
                    </LongText>
                  </GridFlex.Column>
                </MenuItem>
              ))}
            </Select>
          )}

          <Select value={dataType} renderValue={(value: any) => formatDataType(value)} width={200}>
            {map(DataType, (dataType: DataType) => {
              const { tooltip, disable, text } = optionInSelect(widgetIdentifier, dataType);
              return (
                <InformationTooltip
                  arrow
                  key={dataType}
                  placement="right"
                  title={tooltip ? <Text.B2>{tooltip}</Text.B2> : ''}>
                  <Box sx={{ cursor: disable ? 'not-allowed' : 'default' }}>
                    <MenuItem
                      disabled={disable}
                      value={dataType}
                      onClick={() => {
                        switchDataType(dataType);
                      }}>
                      <div>{formatDataType(dataType, text)}</div>
                    </MenuItem>
                  </Box>
                </InformationTooltip>
              );
            })}
          </Select>
        </GridFlex.RowMiddle>
        {widgetHasOptions && (
          <GridFlex.RowMiddle
            mr={6}
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsPreviewOptionsOpen((prevState) => !prevState)}>
            <Text.B2>{t('widgets.previewOptions')}</Text.B2>
            <IconButton>
              <CollapsableSectionIcon $isOpen={isPreviewOptionsOpen} />
            </IconButton>
          </GridFlex.RowMiddle>
        )}
        {yourDataPaywallWidget && (
          <Grid item pr={4}>
            {countrySelectComponent()}
          </Grid>
        )}
      </GridFlex.RowSpaceBetweenTop>
      {widgetHasOptions && isPreviewOptionsOpen && <Divider mx={isPreviewOptionsOpen ? 6 : 0} />}
      {widgetHasOptions && (
        <Collapse in={isPreviewOptionsOpen}>
          <GridFlex.Item p={4}>
            <PreviewOptionsSelectBox widgetContext={widgetContext} />
          </GridFlex.Item>
        </Collapse>
      )}
    </GridFlex.Column>
  );
}
