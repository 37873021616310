import { ChangeType, CompatibleAddonChangeFragment } from '@stigg-types/apiTypes';
import { BaseAddonChangeEntity, BaseAddonChangeRow } from './BaseAddonChangeRow';

const mapToAddonChangeType = (entity?: CompatibleAddonChangeFragment | null): BaseAddonChangeEntity | null => {
  if (!entity) {
    return null;
  }

  return {
    displayName: entity.displayName,
    description: entity.description,
    refId: entity.refId,
    maxQuantity: entity.maxQuantity,
  };
};

export function CompatibleAddonChangeRow({
  before,
  after,
  changeType,
}: {
  before?: CompatibleAddonChangeFragment | null;
  after?: CompatibleAddonChangeFragment | null;
  changeType?: ChangeType | null;
}) {
  return (
    <BaseAddonChangeRow
      before={mapToAddonChangeType(before)}
      after={mapToAddonChangeType(after)}
      changeType={changeType}
    />
  );
}
