import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { t } from 'i18next';
import { EmptyBorderedCardContent, Form, GridFlex, Icon, Text } from '@stigg-components';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { ProductSubscriptionType } from './ProductSubscriptionType';
import { fetchProductForEditByRefIdAction, resetProductForEdit, duplicateProductAction } from '../../productsSlice';
import { EditProductFormFields } from './EditProductForm';
import { createDisplayNameTextFieldHandleChange } from '../../../../components/DisplayNameTextField';

const validationSchema = () =>
  Yup.object().shape({
    refId: Yup.string().required(t('products.yup.required')),
    displayName: Yup.string().required(t('products.yup.required')),
    description: Yup.string().nullable(true),
    multipleSubscriptions: Yup.boolean(),
  });

function DuplicateProductDialogHeader({ sourceProductName }: { sourceProductName: string }) {
  return (
    <EmptyBorderedCardContent>
      <GridFlex.RowCenter columnGap={4}>
        <Icon icon="LightBulb" type="custom" size={24} color="active" />
        <Text.B2>
          {t('products.duplicateProductHeaderText')}{' '}
          <Text.B2 $bold display="inline">
            {sourceProductName}
          </Text.B2>
        </Text.B2>
      </GridFlex.RowCenter>
    </EmptyBorderedCardContent>
  );
}

export function DuplicateProductForm({ productRefId, closeDialog }: { productRefId: string; closeDialog: () => void }) {
  const { multipleSubscriptionsUi: multipleSubscriptionsUiEnabled } = useFlags<FeatureFlags>();
  const { isLoading, product } = useSelector((state: RootState) => state.productReducer.edit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(fetchProductForEditByRefIdAction({ refId: productRefId }));
    return () => {
      void dispatch(resetProductForEdit());
    };
  }, [dispatch, productRefId]);

  const initialValues: EditProductFormFields = {
    refId: '',
    displayName: '',
    multipleSubscriptions: product.multipleSubscriptions,
  };

  const onSubmit = async (duplicateProductPayload: EditProductFormFields) => {
    await dispatch(
      duplicateProductAction({
        ...omit(duplicateProductPayload, 'multipleSubscriptions'),
        sourceProductId: product.id,
      }),
    );
    closeDialog();
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Form
      disableSaveOnNoChanges
      onSubmit={onSubmit}
      onCancel={closeDialog}
      submitButtonText={t('products.duplicateButton')}
      validationSchema={validationSchema}
      initialValues={initialValues}
      headerComponent={<DuplicateProductDialogHeader sourceProductName={product.displayName ?? productRefId} />}
      fields={[
        {
          type: 'text',
          id: 'displayName',
          label: t('products.nameForm'),
          handleChange: createDisplayNameTextFieldHandleChange(t('products.refIdPrefix')),
        },
        {
          type: 'text',
          id: 'refId',
          label: t('products.refId'),
        },
        {
          type: 'text',
          id: 'description',
          label: t('products.descriptionForm'),
          optional: true,
        },
        {
          type: 'custom',
          hide: () => !multipleSubscriptionsUiEnabled,
          render: ({ values, setFieldValue }) => (
            <ProductSubscriptionType
              isAwsProduct={!!product.awsMarketplaceProductId}
              value={values.multipleSubscriptions}
              updateValue={(newValue: boolean) => setFieldValue('multipleSubscriptions', newValue)}
              disabled
              subscriptionTypeText={t('products.duplicateProductSubscriptionTypeText')}
            />
          ),
        },
      ]}
    />
  );
}
