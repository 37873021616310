import { useState, useEffect } from 'react';
import { Grid, GridFlex } from '@stigg-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { deleteHookByIdAction, fetchHookByIdAction } from '../../hooksSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import HookDetails from './HookDetails';
import { useBreadcrumbs } from '../../../common/useBreadcrumbs';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { Dialog } from '../../../../components/Dialog';
import UpdateHookForm from '../hookCrud/UpdateHookForm';
import Loader from '../../../../components/Loader';
import HookEventsSection from './HookEventsSection';

type HookDetailsRouteParams = {
  id: string;
};

function HookPage() {
  const { id } = useParams<HookDetailsRouteParams>();

  const dispatch = useAppDispatch();
  const hook = useSelector((root: RootState) => root.hooksReducer.hook);
  const isLoadingHook = useSelector((root: RootState) => root.hooksReducer.isLoadingHook);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updateShouldUpdateOnlyDetails, setUpdateShouldUpdateOnlyDetails] = useState(true);
  const updateShouldUpdateOnlyEvents = !updateShouldUpdateOnlyDetails;

  useBreadcrumbs({ to: '/webhooks', title: t('breadCrumbs.hooks') }, { title: hook?.description || '' });

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && hook?.id) {
      await dispatch(deleteHookByIdAction({ hookId: hook.id }));
    }
    setDeleteDialogOpen(false);
  };

  useEffect(() => {
    void dispatch(fetchHookByIdAction(id));
  }, [dispatch, id]);

  const onDelete = () => {
    setDeleteDialogOpen(true);
  };
  const onEditDetails = () => {
    setUpdateShouldUpdateOnlyDetails(true);
    setEditDialogOpen(true);
  };

  const onEditEvents = () => {
    setUpdateShouldUpdateOnlyDetails(false);

    setEditDialogOpen(true);
  };

  if (!hook || isLoadingHook) {
    return <Loader />;
  }

  return (
    <GridFlex.Column container flexWrap="nowrap" rowSpacing={6}>
      <Grid item>
        <HookDetails hook={hook} onDeleteClick={onDelete} onEditClick={onEditDetails} />
      </Grid>
      <Grid item>
        <HookEventsSection hook={hook} onEditClick={onEditEvents} />
      </Grid>

      <Dialog
        content={
          <UpdateHookForm
            shouldIncludeDetails={updateShouldUpdateOnlyDetails}
            shouldIncludeEvents={updateShouldUpdateOnlyEvents}
            hook={hook}
            onCancel={() => setEditDialogOpen(false)}
          />
        }
        titleText={updateShouldUpdateOnlyDetails ? t('hooks.editHook') : t('hooks.editHookEvents')}
        isOpen={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        aria-labelledby="edit-hook-dialog"
        width={730}
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('hooks.delete')}
        content={t('hooks.deleteConfirmation')}
      />
    </GridFlex.Column>
  );
}

export default HookPage;
