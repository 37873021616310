import { Text, GridFlex, FormRenderProps, Field, drawFormFields, EndAdornment } from '@stigg-components';
import * as React from 'react';
import { ExperimentPreset } from './ExperimentWizard';

export function RatioValueBox({
  controlBox,
  formRenderProps,
}: {
  controlBox: boolean;
  formRenderProps: FormRenderProps<ExperimentPreset>;
}) {
  const {
    values: { controlGroupName, variantGroupName },
  } = formRenderProps;

  const fields: Field<ExperimentPreset>[] = [
    {
      type: 'text',
      id: controlBox ? 'controlPercentage' : 'variantPercentage',

      textFieldType: 'text',

      textFieldProps: {
        size: 'small',
        InputProps: {
          endAdornment: <EndAdornment position="end">%</EndAdornment>,
          sx: {
            color: (theme) => theme.itamar.palette.text.primary,
          },
        },
      },
      handleChange: ({ setFieldValue }) => {
        return (e: any) => {
          setFieldValue(controlBox ? 'controlPercentage' : 'variantPercentage', e.target.value);
          setFieldValue(controlBox ? 'variantPercentage' : 'controlPercentage', 100 - e.target.value);
        };
      },
    },
  ];
  return (
    <GridFlex.ColumnMiddle>
      <GridFlex.Item>
        <Text.B2 color="primary">{controlBox ? controlGroupName : variantGroupName}</Text.B2>
      </GridFlex.Item>
      <GridFlex.Item xs={8}>{drawFormFields(fields, formRenderProps)}</GridFlex.Item>
    </GridFlex.ColumnMiddle>
  );
}
