import { styled } from '@stigg-theme';
import { Theme } from '@mui/material/styles';
import { Text, Grid, Tooltip, GridFlex, Collapse, CollapsableSectionIcon, Link } from '@stigg-components';
import { t } from 'i18next';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import { ChangeType } from '@stigg-types/apiTypes';
import React from 'react';

const getChangeTypeAlertColor = (changeType: ChangeType | null | undefined) => {
  switch (changeType) {
    case ChangeType.Added:
      return 'success';
    case ChangeType.Modified:
      return 'warning';
    case ChangeType.Deleted:
      return 'error';
    default:
      return null;
  }
};

const getGroupBackgroundColor = (theme: Theme, changeType: ChangeType | null | undefined) => {
  const changeTypeAlertType = getChangeTypeAlertColor(changeType);
  if (!changeTypeAlertType) {
    return 'inherit';
  }

  if (theme.isLightTheme) {
    const mainColor = theme.itamar.palette[changeTypeAlertType].main;
    const gradientBaseColor = theme.itamar.palette.white;
    const gradientOpacity = 'e6';
    const gradientBaseColorWithOpacity = `${gradientBaseColor}${gradientOpacity}`;

    return `linear-gradient(0deg, ${gradientBaseColorWithOpacity}, ${gradientBaseColorWithOpacity}), ${mainColor}`;
  }

  return theme.itamar.palette[changeTypeAlertType].background;
};

const ChangeGroupWrapper = styled(Grid)<{ $changeType?: ChangeType | null }>`
  background: ${({ theme, $changeType }) => getGroupBackgroundColor(theme, $changeType)};
  width: 100%;
`;

export function getChange(before: any, after: any) {
  let changeType: ChangeType | null = null;
  if (isNil(before) && !isNil(after)) {
    changeType = ChangeType.Added;
  } else if (!isNil(before) && isNil(after)) {
    changeType = ChangeType.Deleted;
  } else if (!isNil(before) && !isNil(after) && !isEqual(before, after)) {
    changeType = ChangeType.Modified;
  }
  return { changeType };
}

export function ChangeRow({
  before,
  after,
  label,
  changeType,
  showAlways,
  renderValue,
  expandSectionText,
  expandSectionContent,
  lineThroughLabel = false,
}: {
  before?: number | string | null;
  after?: number | string | null;
  changeType?: ChangeType | null;
  label: string | null;
  showAlways?: boolean;
  renderValue?: (value?: number | string | null) => string;
  expandSectionText?: string;
  expandSectionContent?: React.ReactNode;
  lineThroughLabel?: boolean;
}) {
  const [shouldExpand, setShouldExpand] = React.useState(false);
  const toggleExpand = () => setShouldExpand(!shouldExpand);

  if (!showAlways && !changeType) {
    return null;
  }
  const value = changeType === ChangeType.Deleted ? before : after;

  return (
    <GridFlex.Column $fullWidth>
      <GridFlex.Row columnGap={1}>
        {label ? (
          <Text.B2 color="secondary" $lineThrough={lineThroughLabel}>
            {label}:
          </Text.B2>
        ) : null}
        <Text.B2 $lineThrough={changeType === ChangeType.Deleted}>{renderValue ? renderValue(value) : value}</Text.B2>
        {changeType === ChangeType.Modified && before && (
          <>
            <Text.B2 color="disabled" mx={2}>
              ({t('packages.previouslyValue')}
            </Text.B2>
            <Text.B2 color="disabled" $lineThrough>
              {renderValue ? renderValue(before) : before}
            </Text.B2>
            <Text.B2 color="disabled">)</Text.B2>
          </>
        )}
        {expandSectionText ? (
          <Link onClick={toggleExpand} underline="always">
            <GridFlex.Row ml={2} columnGap={1} alignItems="center" $fullHeight>
              <Text.B2 fontSize={12} color="secondary">
                {expandSectionText}
              </Text.B2>
              <CollapsableSectionIcon $isOpen={shouldExpand} size={16} />
            </GridFlex.Row>
          </Link>
        ) : null}
      </GridFlex.Row>
      {expandSectionContent ? (
        <Collapse in={shouldExpand}>
          <Text.B2>{expandSectionContent}</Text.B2>
        </Collapse>
      ) : null}
    </GridFlex.Column>
  );
}

export function ChangeSection({
  getIndex,
  children,
  title,
  tooltipTitle,
  changeType,
  paddingBottom,
  prefix,
  suffix,
}: {
  getIndex?: () => number | string;
  children: React.ReactNode;
  title?: React.ReactNode;
  tooltipTitle?: string;
  changeType?: ChangeType;
  paddingBottom?: number;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
}) {
  const content = (
    <ChangeGroupWrapper p={1} flexDirection="column" $changeType={changeType}>
      {children}
    </ChangeGroupWrapper>
  );

  const index = getIndex && getIndex();

  return (
    <GridFlex.Column $fullWidth>
      <GridFlex.RowSpaceBetween>
        <GridFlex.RowCenter alignItems="center" paddingBottom={paddingBottom}>
          {index && (
            <GridFlex.Item display="flex">
              <Text.B2 color="disabled">{`${index}${typeof index === 'number' ? '.' : ''}`}</Text.B2>
            </GridFlex.Item>
          )}

          {prefix && <GridFlex.Item>{prefix}</GridFlex.Item>}

          {title && <GridFlex.Item ml={2}>{title}</GridFlex.Item>}
        </GridFlex.RowCenter>
        {suffix && <GridFlex.Item>{suffix}</GridFlex.Item>}
      </GridFlex.RowSpaceBetween>

      <GridFlex.RowCenter>
        {tooltipTitle ? (
          <Tooltip arrow placement="top" title={tooltipTitle}>
            {content}
          </Tooltip>
        ) : (
          content
        )}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}

export function ChangeGroupBox({
  getIndex,
  children,
  changeType,
}: {
  getIndex?: () => number | string;
  children: React.ReactNode;
  changeType?: ChangeType | null;
}) {
  if (!changeType) {
    return null;
  }

  const getTooltipTitle = () => {
    if (changeType === ChangeType.Modified) {
      return t('packages.modified');
    }
    if (changeType === ChangeType.Added) {
      return t('packages.added');
    }
    if (changeType === ChangeType.Deleted) {
      return t('packages.removed');
    }
    return '';
  };

  return (
    <ChangeSection getIndex={getIndex} changeType={changeType} tooltipTitle={getTooltipTitle()}>
      {children}
    </ChangeSection>
  );
}

export function DiffSectionHeader({ title }: { title: string }) {
  return (
    <Grid item container alignItems="center">
      <Grid item sm>
        <Text.B2 fontWeight="bold" color="secondary">
          {title}
        </Text.B2>
      </Grid>
    </Grid>
  );
}
