import { Button, GridFlex, StepAccordion } from '@stigg-components';
import React, { ReactNode } from 'react';
import { t } from 'i18next';
import { SxProps } from '@mui/system';
import { StepAccordionProps } from './StepAccordion';

export type StepAccordionWithConfirmProps = StepAccordionProps & {
  isConfirmed: boolean;
  isConfirmDisabled: boolean;
  onConfirm: () => void;
  hideConfirm?: boolean;
  hideCancel?: boolean;
  additionalActionButtons?: ReactNode;
  dataTestId?: string;
  confirmSectionSx?: SxProps;
};

export const StepAccordionWithConfirm = React.forwardRef<HTMLDivElement, StepAccordionWithConfirmProps>(
  (props, ref) => {
    const {
      onRemove,
      isConfirmed,
      isConfirmDisabled,
      onConfirm,
      hideConfirm,
      hideCancel,
      additionalActionButtons,
      confirmSectionSx,
      children,
      ...restProps
    } = props;

    return (
      <StepAccordion ref={ref} onRemove={onRemove} {...restProps}>
        {children}

        {!isConfirmed && !hideConfirm && (
          <>
            <GridFlex.RowSpaceBetween mt={6} sx={confirmSectionSx}>
              <GridFlex.Row>{additionalActionButtons}</GridFlex.Row>
              <GridFlex.Row justifyContent="flex-end" gap={2}>
                {!hideCancel && (
                  <Button $outlined color="primary" onClick={onRemove}>
                    {t('sharedComponents.cancel')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onConfirm}
                  disabled={isConfirmDisabled}
                  data-testId="button-add-charge">
                  {t('sharedComponents.add')}
                </Button>
              </GridFlex.Row>
            </GridFlex.RowSpaceBetween>
          </>
        )}
      </StepAccordion>
    );
  },
);
