import { t } from 'i18next';
import sortBy from 'lodash/sortBy';
import { PreviewNextInvoiceFragment, InvoiceLineItemType, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { ChargesList } from './ChargesList';

type ChargesBreakdownProps = Pick<PreviewNextInvoiceFragment, 'lines'> & {
  plan?: SubscriptionDataFragment['plan'];
};

export function ChargesBreakdown({ lines, plan }: ChargesBreakdownProps) {
  const chargeItems = sortBy(
    lines?.filter((line) =>
      [
        InvoiceLineItemType.BaseCharge,
        InvoiceLineItemType.InAdvanceCommitmentCharge,
        InvoiceLineItemType.PayAsYouGoCharge,
        InvoiceLineItemType.MinimumSpendCharge,
        InvoiceLineItemType.Other,
      ].includes(line.type),
    ) || [],
    (line) => {
      switch (line.type) {
        case InvoiceLineItemType.BaseCharge:
          return 1;
        case InvoiceLineItemType.InAdvanceCommitmentCharge:
          return 2;
        case InvoiceLineItemType.MinimumSpendCharge:
          return 3;
        case InvoiceLineItemType.PayAsYouGoCharge:
          return 4;
        default:
          return 5;
      }
    },
  );

  const overageItems = sortBy(
    lines?.filter((line) => line.type === InvoiceLineItemType.OverageCharge) || [],
    'description',
  );
  const addonItems = sortBy(
    lines?.filter((line) => line.type === InvoiceLineItemType.AddonCharge) || [],
    'description',
  );

  return (
    <>
      <ChargesList
        items={chargeItems}
        title={t('subscriptions.breakdown.planCharges', { planName: plan?.displayName ? `${plan?.displayName} ` : '' })}
        marginTop={0}
      />

      <ChargesList items={overageItems} title={t('subscriptions.breakdown.onDemandCharges')} />

      <ChargesList items={addonItems} title={t('subscriptions.breakdown.addonCharges')} />
    </>
  );
}
