// eslint-disable-next-line no-restricted-imports
import { Radio as MuiRadio } from '@mui/material';
import React from 'react';
import { RadioProps as MuiRadioProps } from '@mui/material/Radio/Radio';
import { isString } from 'lodash';
import { styled } from '@stigg-theme';
import { FormControlLabel, Text } from './index';

const StyledRadio = styled(MuiRadio)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.itamar.palette.action.disabled : theme.itamar.palette.action.active};
`;
export type RadioProps = Pick<MuiRadioProps, 'value' | 'color' | 'disabled'> & {
  label: string | React.ReactNode;
};
export function Radio({ value, color, label, disabled }: RadioProps) {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      control={<StyledRadio color={color} $disabled={disabled} sx={{ cursor: disabled ? 'not-allowed' : undefined }} />}
      label={isString(label) ? <Text.B2 color={disabled ? 'disabled' : 'primary'}>{label}</Text.B2> : label}
    />
  );
}
