import { useState } from 'react';
import { CustomerResponseFragment, CustomerSubscriptionDataFragment } from '@stigg-types/apiTypes';
import delay from 'lodash/delay';
import { useAppDispatch } from '../../../../../../redux/store';
import { fetchCustomerSubscriptionsAction, fetchEntitlementsByRefIdAction } from '../../../../customersSlice';
import { SubscriptionWizard } from '../subscriptionForm/SubscriptionWizard';

type UseCreateSubscriptionProps = {
  customer: CustomerResponseFragment;
  pageSize?: number;
};

export const useSubscriptionActions = ({ customer, pageSize }: UseCreateSubscriptionProps) => {
  const dispatch = useAppDispatch();
  const [createSubscriptionOpen, setCreateSubscriptionOpen] = useState(false);
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<CustomerSubscriptionDataFragment | null | undefined>();

  const switchToCreateMode = () => {
    setCreateSubscriptionOpen(false);
    setSubscriptionToEdit(null);
    delay(() => {
      setCreateSubscriptionOpen(true);
    }, 300);
  };

  const onCreateSubscriptionClick = () => {
    setSubscriptionToEdit(null);
    setCreateSubscriptionOpen(true);
  };

  const onUpdateSubscriptionClick = (subscription: CustomerSubscriptionDataFragment) => {
    setSubscriptionToEdit(subscription);
    setCreateSubscriptionOpen(true);
  };

  const closeCreateSubscriptionDialog = () => {
    setSubscriptionToEdit(null);
    setCreateSubscriptionOpen(false);
  };

  const postSubmit = async () => {
    await dispatch(
      fetchCustomerSubscriptionsAction({
        customerId: customer.customerId,
        isSilentLoading: true,
        paging: { first: pageSize },
      }),
    );
    await dispatch(
      fetchEntitlementsByRefIdAction({
        customerRefId: customer.customerId,
        resourceRefId: undefined,
      }),
    );
  };

  const createSubscriptionDialog = (
    <SubscriptionWizard
      isOpen={createSubscriptionOpen}
      onClose={closeCreateSubscriptionDialog}
      postSubmit={postSubmit}
      customer={customer}
      subscription={subscriptionToEdit}
      switchToCreateMode={switchToCreateMode}
      ariaLabelledBy="add-customer-subscription"
    />
  );

  return {
    createSubscriptionDialog,
    onCreateSubscriptionClick,
    onUpdateSubscriptionClick,
  };
};
