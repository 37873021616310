import { useState, useCallback } from 'react';
import { Box, Button, NumberAnimation, Text, Alert, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { Minimize, Download, CheckCircle } from 'react-feather';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components/macro';
import { ErrorOutline } from '@mui/icons-material';
import { ImportIntegrationTaskResultFragment, ImportSubTaskError, TaskStatus } from '@stigg-types/apiTypes';
import { CSVLink } from 'react-csv';
import Lottie, { Options } from 'react-lottie';
import animationDataLight from '../../../../assets/animations/stripe-import-animation.light.json';
import animationDataDark from '../../../../assets/animations/stripe-import-animation.dark.json';
import { usePollImportIntegrationTask } from './hooks/usePollImportIntegrationTask';
import { StripeImportWizardType } from './StripeImportWizard';
import {
  isTaskFailed,
  isTaskFinished,
  isTaskInProgress,
} from '../../../../components/layout/navbar/components/tasks/taskUtils';

const ErrorOutlineIcon = styled(ErrorOutline)`
  width: 24px;
  height: 24px;
`;

const DownloadIcon = styled(Download)`
  margin-right: 8px;
  color: ${({ theme }) => theme.itamar.palette.error.main};
`;

const StyledAlert = styled(Alert)`
  height: 67px;
  display: flex;
  align-items: center;
  width: 100%;

  .MuiAlert-message {
    width: 100%;
  }
`;

export function StripeImportTask({
  taskId,
  setTaskStatus,
  importSummary,
  importType,
  onMinimize,
}: {
  taskId: string;
  setTaskStatus: (status: TaskStatus) => void;
  importSummary: React.ReactNode;
  importType: StripeImportWizardType;
  onMinimize?: () => void;
}) {
  const [taskState, setTaskState] = useState<{
    taskStatus: TaskStatus;
    progress: number;
    importErrors: ImportSubTaskError[];
    totalImportedItems: number;
  }>({
    taskStatus: TaskStatus.Pending,
    progress: 0,
    importErrors: [],
    totalImportedItems: 0,
  });
  const theme = useTheme();

  const onTaskData = useCallback(
    (importTask: ImportIntegrationTaskResultFragment) => {
      const { importErrors, totalSubtasksCount, progress, status: newTaskStatus } = importTask;
      setTaskState((taskState) => ({ ...taskState, progress }));
      if (isTaskFinished(newTaskStatus)) {
        setTimeout(() => {
          setTaskState((taskState) => ({
            ...taskState,
            taskStatus: newTaskStatus,
            importErrors,
            totalImportedItems: totalSubtasksCount,
          }));
          setTaskStatus(newTaskStatus);
        }, 800);
      } else {
        setTaskState((taskState) => ({ ...taskState, taskStatus: newTaskStatus }));
        setTaskStatus(newTaskStatus);
      }
    },
    [setTaskStatus],
  );
  const onError = useCallback(() => {
    setTaskState((taskState) => ({ ...taskState, taskStatus: TaskStatus.Failed }));
  }, []);

  usePollImportIntegrationTask(taskId, onTaskData, onError);

  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: theme.isLightTheme ? animationDataLight : animationDataDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const animationHeight = theme.isLightTheme ? 180 : 300;

  const { taskStatus, progress, importErrors, totalImportedItems } = taskState;

  if (isTaskInProgress(taskStatus)) {
    return (
      <GridFlex.Column alignItems="center" justifyContent="center" height="100%">
        <Text.H1 mb={2}>
          <NumberAnimation value={progress} />%
        </Text.H1>
        <Text.H6 mb={2}>
          {importType === StripeImportWizardType.IMPORT_CATALOG
            ? t('integrations.productsProgressSubtitle')
            : t('integrations.customersProgressSubtitle')}
        </Text.H6>

        <Lottie options={defaultOptions} isClickToPauseDisabled height={animationHeight} width={600} />

        {importType === StripeImportWizardType.IMPORT_CUSTOMERS_AND_SUBSCRIPTIONS && (
          <Box mt={4}>
            <Button color="primary" variant="contained" startIcon={<Minimize />} onClick={onMinimize}>
              {t('integrations.minimize')}
            </Button>
          </Box>
        )}
      </GridFlex.Column>
    );
  }

  if (isTaskFailed(taskStatus)) {
    const csvData = importErrors.map(({ id, error }) => ({ productId: id, error }));
    return (
      <GridFlex.Column $fullWidth>
        <StyledAlert severity="error" variant="standard" mb={8} icon={<ErrorOutlineIcon />}>
          <GridFlex.RowCenter justifyContent="space-between" $fullWidth>
            <Text.B2 color="error.content">
              {taskStatus === TaskStatus.Failed
                ? t('integrations.productsImportFailedMessage')
                : t('integrations.productsImportPartiallyFailedMessage', {
                    count: importErrors.length,
                    total: totalImportedItems,
                  })}
            </Text.B2>

            <CSVLink
              style={{ textDecoration: 'none' }}
              data={csvData}
              filename="stripe-products-import-troubleshoot.csv"
              target="_blank">
              <GridFlex.RowCenter>
                <DownloadIcon strokeWidth={2} />
                <Text.B2 color="error.main">{t('integrations.downloadTroubleshootCSV')}</Text.B2>
              </GridFlex.RowCenter>
            </CSVLink>
          </GridFlex.RowCenter>
        </StyledAlert>
        {importSummary}
      </GridFlex.Column>
    );
  }

  return (
    <GridFlex.Column>
      <Alert
        severity="success"
        variant="standard"
        mb={8}
        sx={{ height: 67, display: 'flex', alignItems: 'center' }}
        icon={<CheckCircle />}>
        {importType === StripeImportWizardType.IMPORT_CATALOG
          ? t('integrations.productsImportSuccessMessage')
          : t('integrations.customersImportSuccessMessage')}
      </Alert>
      {importSummary}
    </GridFlex.Column>
  );
}
