/* eslint-disable no-restricted-imports */
import { createGlobalStyle, ThemeProps } from 'styled-components/macro';
import { Theme } from '@mui/material';
import { Itamar } from '../theme/itamar/itamar.types';

const GlobalStyle = createGlobalStyle<{
  theme: ThemeProps<Theme> & {
    itamar: Itamar;
  };
}>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${({ theme }) => theme.itamar.palette.background.default};
    margin: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export default GlobalStyle;
