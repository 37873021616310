import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Redirect } from 'react-router-dom';
import stiggBlocked from '@assets/images/stiggBlockedBackground.png';
import { GridFlex } from '@stigg-components';
import { ReactComponent as StiggDark } from '@assets/images/stiggDark.svg';
import styled from 'styled-components/macro';
import { t } from 'i18next';

const StyledTitle = styled.span`
  font-family: Titillium Web;
  font-weight: 700;
  font-size: 46px;
  line-height: 60px;
  background: -webkit-linear-gradient(#e5e8ff, #c6ff7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const StyledSubtitle = styled.span`
  font-family: Titillium Web;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #e3d6fe;
`;
function StiggMobile() {
  const { shouldShowBlockedMobile } = useFlags<FeatureFlags>();

  if (!shouldShowBlockedMobile || window.innerWidth > 600) {
    return <Redirect to={{ pathname: '/' }} />;
  }
  return (
    <GridFlex.Column
      height="100%"
      width="100%"
      style={{ backgroundSize: 'cover', backgroundImage: `url(${stiggBlocked})` }}
      py={18}
      px={9}
      gap={8}>
      <StiggDark />
      <StyledTitle>{t('blockMobile.title')}</StyledTitle>
      <StyledSubtitle>{t('blockMobile.subtitle')}</StyledSubtitle>
    </GridFlex.Column>
  );
}

export default StiggMobile;
