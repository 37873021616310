/* eslint-disable */

import { Grid, GridFlex, Icon, Text, EMPTY_CHAR } from '@stigg-components';
import { styled } from '@stigg-theme';
import {
  AddonFragment,
  BillingPeriod,
  ChangeType,
  MinimumSpendChange,
  PackageChangesFragment,
  PackagePriceChange,
  PlanFragment,
  WidgetType,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { AdditionalMetadataChangeRow } from './additionalMetadata/AdditionalMetadataChangeRow';
import { CompatibleAddonChangeRow } from './CompatibleAddonChangeRow';
import { DefaultTrialConfigChangeRow } from './DefaultTrialConfigChangeRow';
import { ChangeGroupBox, ChangeRow, ChangeSection, DiffSectionHeader } from './DiffBaseComponents';
import { PackageEntitlementChangeRow } from './PackageEntitlementChangeRow';
import { PriceChangeRow } from './PriceChangeRow';
import { OveragePricesChange } from './OveragePricesChange';
import { AddonDependencyChangeRow } from './AddonDependencyChangeRow';
import { CompatiblePackageGroupChangeRow } from './CompatiblePackageGroupChangeRow';
import { AddonTypeChangeRow } from './AddonTypeChangeRow';
import { MinimumSpendDiff } from './MinimumSpendDiff';

const ChangesWrapper = styled(GridFlex.Column)`
  width: 100%;
  background-color: ${({ theme }) => theme.itamar.palette.background.default};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: 1px solid ${({ theme }) => theme.itamar.border.borderColor};
`;

const ScrollableChangesWrapper = styled(ChangesWrapper)`
  max-height: 30vh;
  overflow: auto;
`;

export function getWidgetVisibilityText({
  hiddenFromWidgets,
  widgetType,
}: {
  hiddenFromWidgets?: WidgetType[] | null;
  widgetType: WidgetType;
}) {
  const isHiddenInPaywall = (hiddenFromWidgets || []).includes(widgetType);
  return isHiddenInPaywall
    ? t('widgets.visibility.notVisibleInPricingTables')
    : t('widgets.visibility.visibleInPricingTables');
}
/*
check for multiple billing periods before or after the changes, even if they are not part of the changes
this is done to handle reverse pricing period and align all diff elements to monthly in such case
*/
const checkMultipleBillingPeriods = (aPackage: PlanFragment | AddonFragment, changes?: PackageChangesFragment) => {
  if (!changes) return false;
  const { prices } = changes;
  const monthly =
    prices.some(
      (price) =>
        price.before?.billingPeriod === BillingPeriod.Monthly || price.after?.billingPeriod === BillingPeriod.Monthly,
    ) || aPackage.prices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
  const annually =
    prices.some(
      (price) =>
        price.before?.billingPeriod === BillingPeriod.Annually || price.after?.billingPeriod === BillingPeriod.Annually,
    ) || aPackage.prices?.some((price) => price.billingPeriod === BillingPeriod.Annually);

  return monthly && annually;
};

export function PackageDiff({
  aPackage,
  changes,
  hasMultipleCurrencies,
}: {
  aPackage: PlanFragment | AddonFragment;
  changes?: PackageChangesFragment | null;
  hasMultipleCurrencies?: boolean;
}) {
  const getPrefix = () => EMPTY_CHAR;
  const isPlan = aPackage.type === 'Plan';
  const isAddon = aPackage.type === 'Addon';
  const pricePrefix = <Icon icon="DollarSign" type="reactFeather" color="disabled" display="flex" />;
  const priceSuffix = <Icon icon="PushPin" type="materialIcons" color="disabled" display="flex" />;
  const [entitlementsChanges, entitlementsReordered] = partition(
    changes?.entitlements,
    (change) => change.changeType !== ChangeType.Reordered,
  );

  const hasMultipleBillingPeriods = changes ? checkMultipleBillingPeriods(aPackage, changes) : false;

  return (
    <ScrollableChangesWrapper p={4}>
      {changes?.totalChanges ? (
        <GridFlex.Column container rowSpacing={2}>
          {(!isEmpty(changes.prices) ||
            !isEmpty(changes.overagePrices) ||
            !isEmpty(changes.minimumSpend) ||
            changes?.pricingType) && (
            <ChangesWrapper p={4} mb={2} mt={2}>
              <GridFlex.Column item container rowSpacing={2}>
                {!isEmpty(changes.prices) && (
                  <ChangeSection
                    prefix={pricePrefix}
                    suffix={priceSuffix}
                    title={<DiffSectionHeader title={t('packages.changesPackagePrices')} />}>
                    <ChangeGroupBox {...changes?.pricingType}>
                      <ChangeRow
                        {...changes?.pricingType}
                        label="Pricing type"
                        renderValue={(value) => (value ? t(`pricing.pricingType.${value}`) : '')}
                      />
                    </ChangeGroupBox>
                    {changes.prices.map((priceChange, index) => (
                      <Grid key={index}>
                        <PriceChangeRow
                          {...priceChange}
                          hasMultipleCurrencies={hasMultipleCurrencies}
                          hasMultipleBillingPeriods={hasMultipleBillingPeriods}
                        />
                      </Grid>
                    ))}
                  </ChangeSection>
                )}
                <OveragePricesChange
                  overagePricesDiff={changes.overagePrices as PackagePriceChange[]}
                  pricePrefix={pricePrefix}
                  hasMultipleCurrencies={hasMultipleCurrencies}
                />
                {!isEmpty(changes.minimumSpend) && (
                  <MinimumSpendDiff
                    minimumSpendDiff={changes.minimumSpend as MinimumSpendChange[]}
                    prefix={pricePrefix}
                  />
                )}
              </GridFlex.Column>
            </ChangesWrapper>
          )}

          {(changes?.displayName ||
            changes?.description ||
            changes?.basePlan ||
            changes?.hiddenFromWidgets ||
            changes?.maxQuantity) && (
            <GridFlex.Column item container rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={
                  <DiffSectionHeader
                    title={isPlan ? t('packages.changesPlanDetails') : t('packages.changesAddonDetails')}
                  />
                }>
                <ChangeGroupBox {...changes?.displayName}>
                  <ChangeRow {...changes?.displayName} label="Name" />
                </ChangeGroupBox>
                <ChangeGroupBox {...changes?.description}>
                  <ChangeRow {...changes?.description} label="Description" />
                </ChangeGroupBox>
                <ChangeGroupBox changeType={changes?.hiddenFromWidgets ? ChangeType.Modified : null}>
                  <ChangeRow
                    showAlways
                    label={t('widgets.visibility.visibility')}
                    before={getWidgetVisibilityText({
                      hiddenFromWidgets: changes?.hiddenFromWidgets?.before,
                      widgetType: WidgetType.Paywall,
                    })}
                    after={getWidgetVisibilityText({
                      hiddenFromWidgets: changes?.hiddenFromWidgets?.after,
                      widgetType: WidgetType.Paywall,
                    })}
                    changeType={ChangeType.Modified}
                  />
                </ChangeGroupBox>
                <ChangeGroupBox {...changes?.basePlan}>
                  <ChangeRow
                    {...changes?.basePlan}
                    before={`${changes?.basePlan?.before?.product?.displayName} / ${changes?.basePlan?.before?.displayName}`}
                    after={`${changes?.basePlan?.after?.product?.displayName} / ${changes?.basePlan?.after?.displayName}`}
                    label="Base plan"
                  />
                </ChangeGroupBox>
                <AddonTypeChangeRow {...changes?.maxQuantity} />
              </ChangeSection>
            </GridFlex.Column>
          )}

          {changes.defaultTrialConfig && (
            <Grid item container flexDirection="column" rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={<DiffSectionHeader title={t('packages.changesPackageTrialConfig')} />}>
                <DefaultTrialConfigChangeRow {...changes.defaultTrialConfig} />
              </ChangeSection>
            </Grid>
          )}

          {!isEmpty(changes.entitlements) && (
            <Grid item container flexDirection="column" rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={<DiffSectionHeader title={t('packages.changesPackageEntitlements')} />}>
                {!!entitlementsReordered.length && (
                  <Grid item>
                    <ChangeGroupBox changeType={ChangeType.Modified}>
                      <Text.B2 sx={{ display: 'inline' }}>{t('packages.changesPackageEntitlementsReordered')}</Text.B2>
                    </ChangeGroupBox>
                  </Grid>
                )}
                {entitlementsChanges.map((entitlementChange, index) => (
                  <Grid item key={index}>
                    <PackageEntitlementChangeRow isAddon={isAddon} {...entitlementChange} />
                  </Grid>
                ))}
              </ChangeSection>
            </Grid>
          )}

          {(!isEmpty(changes.compatibleAddons) || !isEmpty(changes.compatiblePackageGroups)) && (
            <Grid item container flexDirection="column" rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={<DiffSectionHeader title={t('packages.changesPlanCompatibleAddons')} />}>
                {changes.compatibleAddons?.map((compatibleAddonChange, index) => (
                  <Grid item key={index}>
                    <CompatibleAddonChangeRow {...compatibleAddonChange} />
                  </Grid>
                ))}
                {changes.compatiblePackageGroups?.map((compatiblePackageGroupChange, index) => (
                  <Grid item key={index}>
                    <CompatiblePackageGroupChangeRow {...compatiblePackageGroupChange} />
                  </Grid>
                ))}
              </ChangeSection>
            </Grid>
          )}

          {!isEmpty(changes.dependencies) && (
            <Grid item container flexDirection="column" rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={<DiffSectionHeader title={t('packages.changesAddonDependency')} />}>
                {changes.dependencies?.map((addonDiff, index) => (
                  <Grid item key={index}>
                    <AddonDependencyChangeRow {...addonDiff} />
                  </Grid>
                ))}
              </ChangeSection>
            </Grid>
          )}

          {changes.additionalMetaData && (
            <Grid item container flexDirection="column" rowSpacing={2}>
              <ChangeSection
                getIndex={getPrefix}
                title={<DiffSectionHeader title={t('packages.changesAdditionalMetaDataChange')} />}>
                <AdditionalMetadataChangeRow {...changes?.additionalMetaData} />
              </ChangeSection>
            </Grid>
          )}
        </GridFlex.Column>
      ) : (
        <Text.B2>{t('packages.noChanges')}</Text.B2>
      )}
    </ScrollableChangesWrapper>
  );
}
