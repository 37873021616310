import { t } from 'i18next';
import isNumber from 'lodash/isNumber';
import { EntitlementDataFragment, FeatureType } from '@stigg-types/apiTypes';
import { Text, GridFlex, LongText, Grid } from '@stigg-components';
import { formatFeatureUnits } from '../../../../../common/formatFeatureUnits';
import { formatResetPeriod } from '../../../../../entitlements/formatResetPeriod';
import { isEntitlementBudgetExceeded } from '../CustomerEntitlementsSummary';

export function EntitlementValueBox({ entitlement }: { entitlement: EntitlementDataFragment }) {
  const { feature, hasUnlimitedUsage, resetPeriod, usageLimit, hasSoftLimit } = entitlement;
  if (!feature) {
    return null;
  }

  const isBudgetExceeded = isEntitlementBudgetExceeded(entitlement);

  if (feature.featureType === FeatureType.Boolean) {
    return (
      <GridFlex.RowCenter>
        <Grid item pr={2}>
          <Text.B2>{t('entitlements.accessGranted')}</Text.B2>
        </Grid>
      </GridFlex.RowCenter>
    );
  }

  return (
    <>
      <GridFlex.Column item>
        <Grid item>
          <LongText variant="body2" tooltipPlacement="bottom">
            {`${formatFeatureUnits(feature, usageLimit, hasUnlimitedUsage)}`}
            {resetPeriod && (isNumber(usageLimit) || hasUnlimitedUsage) && ` ${formatResetPeriod(resetPeriod, true)}`}
          </LongText>
        </Grid>
        {isBudgetExceeded ? (
          <Grid item>
            <Text.Sub2 color="error">{t('entitlements.budgetExceeded')}</Text.Sub2>
          </Grid>
        ) : null}
        {hasSoftLimit ? (
          <Grid item>
            <Text.Sub2>{t('entitlements.hasSoftLimit')}</Text.Sub2>
          </Grid>
        ) : null}
      </GridFlex.Column>
    </>
  );
}
