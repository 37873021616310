import { Text, ExternalLink } from '@stigg-components';
import React from 'react';
import { BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { entries } from 'lodash';
import set from 'lodash/set';
import { FormikTouched } from 'formik/dist/types';
import { TooltipFields } from '../../../../../../../components/InformationTooltip';
import {
  Charge,
  OverageCharge,
  PriceLocalization,
  PricePeriods,
  SetPriceWizardFormFields,
} from '../SetPriceWizardForm.types';
import { roundMaximumFractionDigits } from '../../../../utils/priceFormatUtils';

type PricePeriodsWithId = {
  pricePeriodsId: string;
  pricePeriods: PricePeriods;
};

/** when billing periods list is change, update charges prices values accordingly */
export function updatePricePeriods(
  pricePeriodsToUpdate: PricePeriodsWithId[],
  billingPeriod: BillingPeriod,
  checked: boolean,
  oldPricePeriodUnit: BillingPeriod,
  newPricePeriodUnit: BillingPeriod,
  newValues: SetPriceWizardFormFields,
  newTouched: FormikTouched<SetPriceWizardFormFields>,
): void {
  pricePeriodsToUpdate.forEach(({ pricePeriodsId, pricePeriods }) => {
    const { amount: annuallyAmount } = pricePeriods[BillingPeriod.Annually] || {};

    // when changing between different price period units, we need to convert the annually prices
    if (oldPricePeriodUnit !== newPricePeriodUnit && annuallyAmount) {
      const newPrice = roundMaximumFractionDigits(
        oldPricePeriodUnit === BillingPeriod.Monthly ? annuallyAmount * 12 : annuallyAmount / 12,
      );
      const id = `${pricePeriodsId}[${BillingPeriod.Annually}]`;
      set(newValues, id, newPrice);
      set(newTouched, id, true);
    }

    // when checking new billing period, mark empty price
    // period as touched so the error message will be shown
    if (checked && !pricePeriods[billingPeriod]) {
      set(newTouched, `${pricePeriodsId}[${billingPeriod}]`, true);
    }
  });
}

export function getPricePeriodsToUpdate(charges: Charge[], priceLocalization: PriceLocalization) {
  const pricePeriodsToUpdate: PricePeriodsWithId[] = [];

  pricePeriodsToUpdate.push(
    ...charges.map((charge, index) => ({
      pricePeriodsId: `charges[${index}].pricePeriods`,
      pricePeriods: charge.pricePeriods,
    })),
  );

  // for each country, update prices for each charge
  pricePeriodsToUpdate.push(
    ...priceLocalization.countries.flatMap((priceLocalization, countryIndex) =>
      entries(priceLocalization.chargesPricePeriods).map(([chargeUuid, pricePeriods]) => ({
        pricePeriodsId: `priceLocalization.countries[${countryIndex}].chargesPricePeriods[${chargeUuid}]`,
        pricePeriods,
      })),
    ),
  );

  return pricePeriodsToUpdate;
}

export function updateOveragePricePeriods(
  overageCharges: OverageCharge[],
  newBillingPeriods: BillingPeriod[],
  newValues: SetPriceWizardFormFields,
  newTouched: FormikTouched<SetPriceWizardFormFields>,
) {
  overageCharges.forEach((overageCharge, index) => {
    const pricePeriodsId = `overageCharges.charges[${index}].pricePeriods`;

    const existingOveragePrice = overageCharge.pricePeriods.MONTHLY ?? overageCharge.pricePeriods.ANNUALLY;

    newBillingPeriods.forEach((billingPeriod) => {
      const id = `${pricePeriodsId}[${billingPeriod}]`;
      set(newValues, id, existingOveragePrice);
      set(newTouched, id, true);
    });
  });
}

export function getDisabledTooltip(billingPeriod: BillingPeriod, charges: Charge[]): TooltipFields | undefined {
  if (billingPeriod !== BillingPeriod.Annually) {
    return undefined;
  }

  const hasUsageBasedCharge = charges.some((charge) => charge.billingModel === BillingModel.UsageBased);
  if (!hasUsageBasedCharge) {
    return undefined;
  }

  return {
    placement: 'top',
    title: (
      <>
        <Text.B2 mb={2}>{t('pricing.annualDisabledDueToPayAsYouGoCharge')}</Text.B2>
        <ExternalLink label={t('sharedComponents.learnMore')} url="https://docs.stigg.io/docs/plans#paid-plans" />
      </>
    ),
  };
}
