/* eslint-disable no-restricted-imports */
import '@mui/lab/themeAugmentation';
import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';
import variants, { THEMES } from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import shadows from './shadows';
import { getComponents } from './components';
import shape from './shape';
import { Itamar } from './itamar';
import { getCachedTheme } from './cachedStiggTheme';

const stiggThemes = variants.reduce<{ [key in THEMES]: Theme }>((themes, variant) => {
  const themeVariant = variant.name as THEMES;
  const isLightTheme = themeVariant === THEMES.LIGHT;
  themes[variant.name] = createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      typography,
      shadows,
      components: getComponents(variant, isLightTheme),
      shape,
      itamar: variant.itamar,
      themeVariant,
      isLightTheme,
      palette: {
        mode: isLightTheme ? 'light' : 'dark',
        primary: {
          main: variant.itamar.palette.primary.main,
        },
        secondary: {
          main: variant.itamar.palette.secondary.main,
        },
      },
    },
    {
      name: variant.name,
      header: variant.header,
      footer: variant.footer,
      pageCard: variant.pageCard,
      sidebar: variant.sidebar,
      chip: variant.chip,
    },
  );
  return themes;
}, {} as { [key in THEMES]: Theme });

export const getStiggTheme = (themeVariant?: THEMES) => {
  const variant = themeVariant || getCachedTheme();
  const theme = stiggThemes[variant];
  return { theme, themeVariant: variant };
};

export { css } from 'styled-components';
export { styled } from '@mui/material';
export { spacing } from '@mui/system';
export * from './itamar';
export { useStiggTheme } from './StiggTheme';
export { THEMES } from './variants';

type ItamarTheme = Theme & {
  itamar: Itamar;
};

export type { ItamarTheme, Theme };
