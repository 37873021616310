import { GridFlex, Text } from '@stigg-components';
import { EventActorInfo } from '@stigg-types/apiTypes';
import { getActorInformation } from '../utils/actor.utils';
import EventActorIcon from './EventActorIcon';

type ActorInformationProps = {
  actor?: EventActorInfo | null | undefined;
};

export function ActorInformation({ actor }: ActorInformationProps) {
  if (!actor) {
    return null;
  }

  const { primary, secondary } = getActorInformation(actor);

  return (
    <GridFlex.RowCenter gap={2}>
      <EventActorIcon type={actor.type} />
      <GridFlex.Column>
        <Text.B2>{primary}</Text.B2>
        <Text.Sub2>{secondary}</Text.Sub2>
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
