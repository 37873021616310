import { TypographyOptions } from '@mui/material/styles/createTypography';

const headerFont = 'Space Grotesk';
export const textFont = 'DM Sans';
const color = '#0000008A';

const typography: TypographyOptions = {
  fontFamily: [
    textFont,
    headerFont,
    'Inter',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontFamily: headerFont,
    fontSize: '32px',
    lineHeight: '38px',
    letterSpacing: '-0.2px',
    fontWeight: 700,
  },
  h3: {
    fontFamily: headerFont,
    fontSize: '24px',
    lineHeight: '30px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  h6: {
    fontFamily: headerFont,
    fontSize: '17px',
    lineHeight: '20.5px',
    letterSpacing: '0.05px',
    fontWeight: 500,
  },
  body1: {
    fontFamily: textFont,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  body2: {
    fontFamily: textFont,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontFamily: textFont,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: '0.1px',
    color,
  },
  subtitle2: {
    fontFamily: textFont,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: '0.1px',
    color,
  },
  caption: {
    fontFamily: textFont,
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 400,
    letterSpacing: '0.4px',
    color,
  },
  button: {
    textTransform: 'none',
  },
};

export default typography;
