import React from 'react';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { useTheme } from '@mui/material/styles';
import { Text, GridFlex, Button, Icon, Divider, PageCard } from '@stigg-components';
import { ReactComponent as SandboxIcon } from '@assets/icons/sandbox.svg';
import { externalLinks } from '../../../common';
import { useInviteMembersDialog } from '../../../accounts/components/InviteMembersDialog/useInviteMembersDialog';
import { useNavigation } from '../../../navigation/useNavigation';
import { SegmentEvents, useAnalytics } from '../../../common/useAnalytics';

const StyledTipsCard = styled(PageCard)`
  height: 100%;
  padding: 16px;
  background: none;
  border: none;

  > div {
    height: 100%;

    &:last-child {
      padding: 0;
    }
  }
`;

const StyledSandboxIcon = styled(SandboxIcon)`
  width: 20px;
  height: 20px;
`;

export function TipsCard() {
  const theme = useTheme();
  const { track } = useAnalytics();
  const { navigateTo } = useNavigation();
  const { inviteMembersDialog, setIsInviteDialogOpen } = useInviteMembersDialog({
    shouldNavigateToAccountsMembersPageOnSubmit: true,
  });

  const trackTipCardClick = (buttonName: string) => {
    track(SegmentEvents.ButtonClicked, { buttonName });
  };

  return (
    <StyledTipsCard>
      <GridFlex.ColumnCenter height="100%" container p={2}>
        <Text.H6 mb={4}>{t('gettingStarted.tipsCard.inviteTeamMemberTip.title')}</Text.H6>
        <Button
          $outlined
          color={theme.isLightTheme ? 'secondary' : 'outlineBorder'}
          textColor={theme.isLightTheme ? undefined : 'primary'}
          size="large"
          sx={{ width: '100%' }}
          startIcon={<Icon icon="UserPlus" type="reactFeather" />}
          onClick={() => {
            trackTipCardClick('invite member');
            setIsInviteDialogOpen(true);
          }}>
          {t('gettingStarted.tipsCard.inviteTeamMemberTip.buttonText')}
        </Button>
        <Divider my={6} />
        <Text.H6 mb={4}>{t('gettingStarted.tipsCard.docsTip.title')}</Text.H6>
        <Button
          $outlined
          color={theme.isLightTheme ? 'secondary' : 'outlineBorder'}
          textColor={theme.isLightTheme ? undefined : 'primary'}
          size="large"
          sx={{ width: '100%' }}
          startIcon={<Icon icon="FileText" />}
          onClick={() => {
            trackTipCardClick('docs');
            window.open(externalLinks.stiggDocsMainPage, '_blank');
          }}>
          {t('gettingStarted.tipsCard.docsTip.buttonText')}
        </Button>
        <Divider my={6} />
        <Text.H6 mb={4}>{t('gettingStarted.tipsCard.generateSandbox.title')}</Text.H6>
        <Button
          $outlined
          color={theme.isLightTheme ? 'secondary' : 'outlineBorder'}
          textColor={theme.isLightTheme ? undefined : 'primary'}
          size="large"
          sx={{ width: '100%' }}
          startIcon={<StyledSandboxIcon />}
          onClick={() => {
            trackTipCardClick('generate sandbox');
            navigateTo('/generate-sandbox', { isGlobal: true });
          }}>
          {t('gettingStarted.tipsCard.generateSandbox.buttonText')}
        </Button>
        {inviteMembersDialog}
      </GridFlex.ColumnCenter>
    </StyledTipsCard>
  );
}
