import { BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import { ExternalLink, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { TooltipFields } from '../../../../../../../../components/InformationTooltip';

export function getDisabledTooltip(
  billingModel: BillingModel,
  billingPeriods: BillingPeriod[],
  hasOverageChargeForFeature: boolean,
): TooltipFields | undefined {
  if (billingModel !== BillingModel.UsageBased) {
    return undefined;
  }

  let toolTipTitleText;

  const hasAnnuallyPeriod = billingPeriods.some((billingPeriod) => billingPeriod === BillingPeriod.Annually);
  if (hasAnnuallyPeriod) {
    toolTipTitleText = t('pricing.billingType.payAsYouGoDisabledDueToAnnualPeriod');
  }

  if (hasOverageChargeForFeature) {
    toolTipTitleText = t('pricing.billingType.payAsYouGoDisabledDueToOverageCharge');
  }

  if (toolTipTitleText) {
    return {
      placement: 'top',
      title: (
        <>
          <Text.B2 mb={2}>{toolTipTitleText}</Text.B2>
          <ExternalLink label={t('sharedComponents.learnMore')} url="https://docs.stigg.io/docs/plans#paid-plans" />
        </>
      ),
    };
  }

  return undefined;
}
