import { Chip } from '@stigg-components';
import { PromotionalEntitlementStatus } from '@stigg-types/apiTypes';

const STATUS_FORMAT = {
  [PromotionalEntitlementStatus.Active]: 'Active',
  [PromotionalEntitlementStatus.Expired]: 'Expired',
  [PromotionalEntitlementStatus.Paused]: 'Paused',
};

type PromotionalEntitlementStatusChipProps = {
  status: PromotionalEntitlementStatus;
};

export function PromotionalEntitlementStatusChip({ status }: PromotionalEntitlementStatusChipProps) {
  return (
    <Chip
      label={STATUS_FORMAT[status]}
      size="small"
      color={status === PromotionalEntitlementStatus.Expired ? 'expired' : 'primary'}
      textColor={status === PromotionalEntitlementStatus.Expired ? 'primary' : 'white'}
    />
  );
}
