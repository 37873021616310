import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateManyPackageEntitlementMutation,
  CreateManyPackageEntitlementMutationVariables,
  CreateManyPackageEntitlementsInput,
  ErrorCode,
} from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const CREATE_PACKAGE_ENTITLEMENT = gql`
  mutation createManyPackageEntitlement($input: CreateManyPackageEntitlementsInput!) {
    createManyPackageEntitlements(input: $input) {
      id
    }
  }
`;

async function createPackageEntitlements(
  entitlementData: CreateManyPackageEntitlementsInput,
  { dispatch }: { dispatch: AppDispatch },
) {
  const { packageEntitlements } = entitlementData;
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<
        CreateManyPackageEntitlementMutation,
        CreateManyPackageEntitlementMutationVariables
      >({
        mutation: CREATE_PACKAGE_ENTITLEMENT,
        variables: {
          input: {
            packageEntitlements: packageEntitlements.map((pkgEntitlement) => ({
              ...pkgEntitlement,
              usageLimit: !isNil(pkgEntitlement.usageLimit) ? Math.floor(pkgEntitlement.usageLimit) : null,
            })),
          },
        },
      });
      const newEntitlement = response.data?.createManyPackageEntitlements;

      return newEntitlement;
    },
    {
      failureMessageHandler: () => t('entitlements.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('entitlements.api.errorAlreadyGranted'),
        [ErrorCode.CannotEditPackageInNonDraftMode]: t('entitlements.api.cannotEditNonDraftMode'),
      },
    },
    dispatch,
  );
}

export { createPackageEntitlements };
