import React from 'react';
import { Form, Formik } from 'formik';
import { Stepper } from '@stigg-components';
import { t } from 'i18next';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { first } from 'lodash';
import { CustomerPromotionalEntitlementsDataFragment, PromotionalEntitlementPeriod } from '@stigg-types/apiTypes';
import { generateRandomSlug } from '@stigg-common';
import { PromotionalEntitlementsConfiguration } from './PromotionalEntitlementsConfiguration';
import { entitlementValidationSchema } from '../../../../../packages/plans/components/EntitlementsForm';
import { getResetPeriodConfig } from '../../../../../packages/plans/components/EditEntitlementDrawer';
import { EntitlementFields } from '../../../../../entitlements/components/entitlementSettings/types';
import { updatePromotionalEntitlementAction } from '../../../../customersSlice';
import { getResetPeriodData } from '../../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { EditPromotionalEntitlement } from './EditPromotionalEntitlement';
import { SelectedEntitlementsStatus } from './SelectedEntitlementsStatus';

export type EditPromotionalEntitlementFormProps = {
  customerId: string;
  setEditDialogOpen: (open: boolean) => void;
  entitlement: CustomerPromotionalEntitlementsDataFragment | null;
};

export type EditPromotionalEntitlementFormFields = {
  entitlements: EntitlementFields[];
  endDate?: string | undefined;
  isVisible: boolean;
  period: PromotionalEntitlementPeriod;
};

const validationSchema = () =>
  Yup.object().shape({
    period: Yup.string().required(t('fieldValidationMessages.required')),
    endDate: Yup.date().when(['period'], {
      is: (period: PromotionalEntitlementPeriod) => period !== PromotionalEntitlementPeriod.Lifetime,
      then: (schema: any) => schema.required(t('fieldValidationMessages.required')),
      otherwise: (schema: any) => schema.nullable(true),
    }),
    isVisible: Yup.boolean().required(t('fieldValidationMessages.required')),
    entitlements: Yup.array().of(entitlementValidationSchema).required().max(1),
  });

export function EditPromotionalEntitlementForm({
  entitlement,
  setEditDialogOpen,
  customerId,
}: EditPromotionalEntitlementFormProps) {
  const dispatch = useAppDispatch();
  const isSavingPromotionalEntitlements = useSelector(
    (state: RootState) => state.customersReducer.isSavingPromotionalEntitlements,
  );

  if (!entitlement) {
    return null;
  }

  const onCancel = () => setEditDialogOpen(false);
  const handleSubmit = async (values: EditPromotionalEntitlementFormFields) => {
    const entitlementToUpdate = first(values.entitlements);

    if (entitlementToUpdate?.id) {
      await dispatch(
        updatePromotionalEntitlementAction({
          id: entitlementToUpdate.id,
          customerId,
          update: {
            isVisible: values.isVisible,
            period: values.period,
            endDate: values.endDate,
            description: entitlementToUpdate.description?.slice(0, 256),
            usageLimit: entitlementToUpdate.usageLimit,
            hasUnlimitedUsage: entitlementToUpdate.hasUnlimitedUsage,
            hasSoftLimit: entitlementToUpdate.hasSoftLimit,
            ...getResetPeriodData(entitlementToUpdate.resetPeriod, entitlementToUpdate.resetPeriodConfiguration),
          },
        }),
      );
    }
  };

  const initialValues: EditPromotionalEntitlementFormFields = {
    entitlements: [
      {
        ...entitlement,
        uuid: generateRandomSlug(),
        isConfirmed: true,
        resetPeriodConfiguration: entitlement.resetPeriodConfiguration
          ? getResetPeriodConfig(entitlement.resetPeriodConfiguration)
          : null,
      },
    ],
    endDate: entitlement.endDate,
    period: entitlement.period,
    isVisible: entitlement.isVisible,
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, submitForm, values, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid }) => (
        <Form>
          <Stepper
            steps={[
              {
                content: () => (
                  <EditPromotionalEntitlement
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                ),
              },
              {
                content: () => (
                  <PromotionalEntitlementsConfiguration
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                  />
                ),
              },
            ]}
            onCancel={onCancel}
            onDone={submitForm}
            isNextValid={isValid}
            isNextLoading={isSavingPromotionalEntitlements}
            renderAdditionalControls={() => <SelectedEntitlementsStatus entitlements={values.entitlements} />}
            lastStepCTAText={t('promotionalEntitlements.editFormCTA')}
          />
        </Form>
      )}
    </Formik>
  );
}
