import { useEffect, useCallback } from 'react';

const useEscKeyAction = (onEsc) => {
  const handleEscKey = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (onEsc) onEsc();
      }
    },
    [onEsc],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [handleEscKey]);
};

export default useEscKeyAction;
