import { t } from 'i18next';
import { SubscriptionMaximumSpendFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type MaximumSpendSectionProps = Pick<SubscriptionMaximumSpendFragment, 'maximumSpend'>;

export function MaximumSpendSection({ maximumSpend }: MaximumSpendSectionProps) {
  return (
    <>
      <ChargeRow description={t('subscriptions.maxSpend.maximumSpend')} charge={maximumSpend} sx={{ mt: 4 }} bold />
    </>
  );
}
