import { Alert, Button } from '@stigg-components';
import styled from 'styled-components/macro';

export const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    height: 54px;
    width: 100%;
  }
`;

export const ConnectButton = styled(Button)`
  height: 36px;
  width: 100%;
`;
