import isEmpty from 'lodash/isEmpty';
import { Field, FormRenderProps, GridFlex, Icon, IconContainer, InformationalTooltipIcon } from '@stigg-components';
import { BillingPeriod, Currency } from '@stigg-types/apiTypes';
import { t } from 'i18next';

import get from 'lodash/get';
import pick from 'lodash/pick';
import {
  Charge,
  ChargeType,
  LOCALIZED_PRICE_TIER_FIELDS,
  PriceLocalization,
  PriceTiersPeriods,
  SetPriceWizardFormFields,
  UsageBasedChargeType,
} from '../../SetPriceWizardForm.types';
import { ChargeTitle } from '../../../components/ChargeTitle';
import { getPricePeriodFields } from '../../chargesStep/utils/getPricePeriodFields';
import { getPricePeriodUnit } from '../../utils/priceConversions';
import { PricingTiersTable } from '../../chargesStep/usageBasedPrice/PricingTiersTable';
import { isTieredCharge } from '../../SetPriceWizardForm.utils';

type GetChargeTableFieldsProps = {
  charge: Charge;
  index: number;
  currency: Currency;
  chargeTitleWidth: string | number;
  pricePeriodUnit: BillingPeriod;
  priceLocalization: PriceLocalization;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
};

function LocalizedChargeTitle({ charge, hasErrors }: { charge: Charge; hasErrors: boolean }) {
  return (
    <GridFlex.RowSpaceBetween $fullWidth>
      <ChargeTitle charge={charge} hideSubtitle />
      {hasErrors ? (
        <IconContainer>
          <InformationalTooltipIcon
            icon={<Icon icon="Error" color="error" size={20} />}
            text={t('sharedComponents.errorsRequiresAttention')}
            placement="left"
          />
        </IconContainer>
      ) : null}
    </GridFlex.RowSpaceBetween>
  );
}

function hasChargeErrors({
  chargesField,
  chargeUuid,
  countryIndex,
  errors,
  touched,
}: {
  chargesField: 'chargesPricePeriods' | 'chargesTieredPricePeriods';
  countryIndex: number;
  chargeUuid: string;
  errors: FormRenderProps<SetPriceWizardFormFields>['errors'];
  touched: FormRenderProps<SetPriceWizardFormFields>['touched'];
}) {
  const hasChargeErrors = !isEmpty(
    get(errors, `priceLocalization.countries[${countryIndex}].${chargesField}[${chargeUuid}]`),
  );

  const isTouched = !isEmpty(
    get(touched, `priceLocalization.countries[${countryIndex}].${chargesField}[${chargeUuid}]`),
  );

  return isTouched && hasChargeErrors;
}

function getChargeTableFields({
  charge,
  index,
  currency,
  pricePeriodUnit,
  priceLocalization,
  formRenderProps,
}: GetChargeTableFieldsProps): Array<Field<SetPriceWizardFormFields>> {
  const currentCharge = charge as UsageBasedChargeType;
  const isOpen = !!priceLocalization.countries[index].openedCharges[currentCharge.uuid];
  const { billingCountryCode } = priceLocalization.countries[index];
  const { billingCadence } = formRenderProps.values;

  let dataTestId = `charge-localized-${billingCountryCode}-${currentCharge.type}`;
  if (currentCharge.feature?.refId) {
    dataTestId += `-${currentCharge.feature.refId}`;
  }

  if (!isTieredCharge(charge)) {
    const hasErrors = hasChargeErrors({
      chargesField: 'chargesPricePeriods',
      countryIndex: index,
      chargeUuid: currentCharge.uuid,
      errors: formRenderProps.errors,
      touched: formRenderProps.touched,
    });

    return [
      {
        type: 'collapse',
        titleTextProps: { width: '100%' },
        gap: 0,
        iconSize: 20,
        title: <LocalizedChargeTitle charge={currentCharge} hasErrors={!isOpen && hasErrors} />,
        dataTestId,
        onClick: () => {
          formRenderProps.setFieldValue(
            `priceLocalization.countries[${index}].openedCharges[${currentCharge.uuid}]`,
            !isOpen,
          );
        },
        open: isOpen,
        gridProps: {
          border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
          borderRadius: (theme) => theme.itamar.border.radius,
          paddingY: 3,
          paddingX: 3,
          marginBottom: 4,
        },
        fields: [
          {
            type: 'layout',
            gridProps: {
              marginTop: 4,
              gap: 4,
            },
            fields: getPricePeriodFields({
              pricePeriodsId: `priceLocalization.countries[${index}].chargesPricePeriods[${currentCharge.uuid}]`,
              billingModel: currentCharge.billingModel,
              pricePeriodUnit,
              currency,
              billingCadence,
            }),
          },
        ],
      },
    ];
  }

  const hasErrors = hasChargeErrors({
    chargesField: 'chargesTieredPricePeriods',
    countryIndex: index,
    chargeUuid: currentCharge.uuid,
    errors: formRenderProps.errors,
    touched: formRenderProps.touched,
  });

  const pricePeriodsId = `priceLocalization.countries[${index}].chargesTieredPricePeriods[${currentCharge.uuid}]`;
  const resolvedLocalizedChargeTiers = Object.values(BillingPeriod).reduce<PriceTiersPeriods>((acc, billingPeriod) => {
    const localizedTiers = get({ priceLocalization }, `${pricePeriodsId}[${billingPeriod}]`);

    acc[billingPeriod] =
      currentCharge.tiers![billingPeriod]?.map((tier, index) => {
        return { ...pick(tier, 'endUnit'), ...pick(localizedTiers?.[index], LOCALIZED_PRICE_TIER_FIELDS) };
      }) || [];

    return acc;
  }, {});

  return [
    {
      type: 'collapse',
      titleTextProps: { width: '100%' },
      gap: 0,
      iconSize: 20,
      title: <LocalizedChargeTitle charge={currentCharge} hasErrors={!isOpen && hasErrors} />,
      dataTestId,
      onClick: () => {
        formRenderProps.setFieldValue(
          `priceLocalization.countries[${index}].openedCharges[${currentCharge.uuid}]`,
          !isOpen,
        );
      },
      open: isOpen,
      gridProps: {
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        borderRadius: (theme) => theme.itamar.border.radius,
        paddingY: 3,
        paddingX: 3,
        marginBottom: 4,
      },
      fields: [
        {
          type: 'layout',
          fields: [
            {
              type: 'custom',
              render: () => {
                return (
                  <PricingTiersTable
                    priceTiersId={pricePeriodsId}
                    tiers={resolvedLocalizedChargeTiers}
                    tiersSchema={currentCharge.tiersSchema!}
                    currency={currency}
                    pricePeriodUnit={pricePeriodUnit}
                    disableUnitChanges
                    disableAddingTier
                  />
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

export function getLocalizedChargesFields(
  index: number,
  charges: Charge[],
  billingPeriods: BillingPeriod[],
  currency: Currency,
  priceLocalization: PriceLocalization,
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>,
): Field<SetPriceWizardFormFields>[] {
  const pricePeriodUnit = getPricePeriodUnit(billingPeriods);
  const chargeTitleWidth = billingPeriods.length === 1 ? '40%' : 180;
  const hasAnyOpenedPriceSection = !isEmpty(priceLocalization.countries[index].openedCharges);
  if (!hasAnyOpenedPriceSection) {
    formRenderProps.setFieldValue(`priceLocalization.countries[${index}].openedCharges[${charges[0].uuid}]`, true);
  }

  const fields: Field<SetPriceWizardFormFields>[] = charges
    .filter((charge) => charge.type === ChargeType.BasePrice || !!charge.feature)
    .flatMap((charge): Array<Field<SetPriceWizardFormFields>> => {
      return getChargeTableFields({
        charge,
        index,
        currency,
        pricePeriodUnit,
        chargeTitleWidth,
        priceLocalization,
        formRenderProps,
      });
    });

  return fields;
}
