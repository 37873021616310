import { Grid, Divider, GridFlex, PageHeader } from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useQueryParam } from '@stigg-common';
import { Grid as GridIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { EmptyList } from '../../../components/EmptyList';
import Loader from '../../../components/Loader';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchProductsAction } from '../productsSlice';
import CreateProductForm from './createProduct/CreateProductForm';
import { ProductCard } from './ProductCard';

function ProductsPage() {
  const { value: createQueryParam } = useQueryParam('create');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const products = useSelector((state: RootState) => state.productReducer.products);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.productReducer.isLoading);

  useEffect(() => {
    setCreateDialogOpen(!!createQueryParam);
  }, [createQueryParam, setCreateDialogOpen]);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchProductsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  const onCreateClick = () => {
    setCreateDialogOpen(true);
  };

  if (isLoading || !currentEnvironmentId) {
    return <Loader />;
  }
  return (
    <>
      <PageHeader title={t('products.title')} buttonTitle={t('products.new')} buttonClick={onCreateClick} />
      <Divider my={6} />

      {!isEmpty(products) ? (
        <GridFlex.Column wrap="nowrap" container rowSpacing={6}>
          {products.map((product) => (
            <Grid key={product.id} item>
              <ProductCard product={product} />
            </Grid>
          ))}
        </GridFlex.Column>
      ) : (
        <EmptyList
          title={t('products.emptyStateTitle')}
          linkText={t('products.emptyStateCreateNewLink')}
          onLinkClick={onCreateClick}
          icon={GridIcon}
        />
      )}

      <Dialog
        content={<CreateProductForm onCancel={() => setCreateDialogOpen(false)} />}
        titleText={t('products.createDialogTitle')}
        isOpen={createDialogOpen}
        onCancel={() => setCreateDialogOpen(false)}
        aria-labelledby="create-product-dialog"
        width={730}
      />
    </>
  );
}

export default ProductsPage;
