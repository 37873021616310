import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  CustomerResponseFragment,
  FetchCustomerByCustomerIdQuery,
  FetchCustomerByCustomerIdQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { RESET_PERIOD_CONFIGURATION } from '../../entitlements/queries/resetPeriodConfigurationFragment';
import { COUPON_FRAGMENT } from '../../coupons/queries/fetchCoupons';

const FETCH_CUSTOMER = gql`
  query FetchCustomerByCustomerId($input: GetCustomerByRefIdInput!) {
    getCustomerByRefId(input: $input) {
      ...CustomerResponse
    }
  }
  fragment CustomerResponse on Customer {
    additionalMetaData
    id
    customerId
    name
    email
    updatedAt
    billingCurrency
    promotionalEntitlements {
      ...CustomerPromotionalEntitlementsData
    }
    coupon {
      ...CouponFragment
    }
    hasActiveSubscription
    billingId
    awsMarketplaceCustomerId
    defaultPaymentMethodId
    defaultPaymentMethodType
    defaultPaymentMethodLast4Digits
    defaultPaymentExpirationMonth
    defaultPaymentExpirationYear
    billingLinkUrl
    crmHubspotCompanyId
    crmHubspotCompanyUrl
    salesforceId
    syncStates {
      vendorIdentifier
      status
      error
    }
    experimentInfo {
      id
      name
      status
      groupType
      groupName
    }
  }
  fragment CustomerPromotionalEntitlementsData on PromotionalEntitlement {
    id
    featureId
    usageLimit
    hasUnlimitedUsage
    hasSoftLimit
    resetPeriod
    status
    isVisible
    period
    endDate
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
    feature {
      id
      displayName
      refId
      description
      featureStatus
      updatedAt
      environmentId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
    }
  }
  ${RESET_PERIOD_CONFIGURATION}
  ${COUPON_FRAGMENT}
`;

async function fetchCustomerByCustomerId(
  { customerId, isSilentLoading: _isSilentLoading }: { customerId: string; isSilentLoading?: boolean },
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<CustomerResponseFragment | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<FetchCustomerByCustomerIdQuery, FetchCustomerByCustomerIdQueryVariables>({
        query: FETCH_CUSTOMER,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            customerId,
            environmentId: accountReducer.currentEnvironmentId || '',
          },
        },
      });

      return res.data.getCustomerByRefId || null;
    },
    {
      failureMessageHandler: () => t('customers.api.failFetchOne'),
    },
    dispatch,
  );
}

export { fetchCustomerByCustomerId };
