import { t } from 'i18next';
import { GridFlex, Icon, LongText, Text } from '@stigg-components';
import { OverageCharge } from '../../SetPriceWizardForm.types';
import { getFeatureTypeIcon } from '../../../../../../../../components/getFeatureTypeIcon';
import { getFeatureDisplayName } from '../../../../../../../features/utils/getFeatureDisplayName';

export type OverageChargeTitleProps = {
  charge: OverageCharge;
  bold?: boolean;
  hideSubtitle?: boolean;
  disableIcon?: boolean;
};

export function OverageChargeTitle({ charge, bold, hideSubtitle, disableIcon = false }: OverageChargeTitleProps) {
  const icon = charge.feature ? (
    getFeatureTypeIcon(charge.feature, { size: 24 })
  ) : (
    <Icon type="custom" icon="WaveSignal" color="active" overrideStroke />
  );
  const title = charge.feature ? getFeatureDisplayName(charge.feature) : t('pricing.overageCharges.price.title');
  const subtitle = charge.feature?.refId;

  return (
    <GridFlex.RowCenter gap={3}>
      {!disableIcon && icon}
      <GridFlex.Column>
        <LongText color="primary" bold={bold}>
          {title}
        </LongText>
        {subtitle && !hideSubtitle && <Text.Caption>{subtitle}</Text.Caption>}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
