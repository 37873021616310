import { useStiggTheme } from '@stigg-theme';
import { ReactComponent as StiggLogoDark } from './layout/sidebar/logo.dark.svg';
import { ReactComponent as StiggLogoLight } from './layout/sidebar/logo.light.svg';

export function StiggLogo(props: any) {
  const { theme } = useStiggTheme();
  const StiggLogoComponent = theme.isLightTheme ? StiggLogoLight : StiggLogoDark;

  return <StiggLogoComponent {...props} />;
}
