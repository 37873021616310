import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  StripeCustomerSearchInput,
  StripeCustomerSearchResultFragment,
  StripeCustomersQuery,
  StripeCustomersQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { RootState, AppDispatch } from '../../../redux/store';

const SEARCH_STRIPE_CUSTOMERS = gql`
  query StripeCustomers($input: StripeCustomerSearchInput!) {
    stripeCustomers(input: $input) {
      ...StripeCustomerSearchResultFragment
    }
  }
  fragment StripeCustomerSearchResultFragment on StripeCustomerSearchResult {
    customers {
      id
      name
      email
      createdAt
      subscriptionPlanName
      subscriptionsCount
      isSynced
      environmentId
    }
    totalCount
    nextPage
  }
`;

export type SearchStripeCustomersProps = {
  nextPage?: string;
  searchTerm?: string;
};

async function searchStripeCustomers(
  searchParams: SearchStripeCustomersProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<StripeCustomerSearchResultFragment> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input: StripeCustomerSearchInput = {
        environmentId: accountReducer.currentEnvironmentId,
      };
      if (searchParams.nextPage) {
        input.nextPage = searchParams.nextPage;
      }
      if (searchParams.searchTerm) {
        input.customerName = searchParams.searchTerm;
      }

      const response = await apolloClient.query<StripeCustomersQuery, StripeCustomersQueryVariables>({
        query: SEARCH_STRIPE_CUSTOMERS,
        fetchPolicy: 'network-only',
        variables: {
          input,
        },
      });
      return response.data.stripeCustomers;
    },
    {
      failureMessageHandler: () => t('integrations.api.failSearchCustomers'),
    },
    dispatch,
  );
}

export { searchStripeCustomers };
