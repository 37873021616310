import { Text, Switch, GridFlex, Divider, ExternalLink } from '@stigg-components';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { HelpTooltip } from '../../../components/HelpTooltip';

export function UnitTransformationSwitch({
  checked,
  onChange,
}: {
  checked?: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <GridFlex.RowCenter $fullWidth>
      <Switch checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <Text.B2>{t('features.featureSecondUnitLabelTitle')}</Text.B2>
      <HelpTooltip $maxWidth={250}>
        <Text.B2 color="primary">
          <Trans
            i18nKey="features.unitLabelHelperText"
            components={[
              <ExternalLink url="https://docs.stigg.io/docs/features#presenting-usage-to-customers-in-a-different-granularity" />,
            ]}
          />
        </Text.B2>
      </HelpTooltip>
      <Divider ml={2} sx={{ flex: 1 }} />
    </GridFlex.RowCenter>
  );
}
