import { IconButton, Icon, IconProps, IconButtonProps } from '@stigg-components';

export function SelectableIconButton({
  onClick,
  isSelected,
  isDisabled,
  sx,
  className,
  dataTestId,
  ...iconProps
}: {
  onClick: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  sx?: IconButtonProps['sx'];
  className?: string;
  dataTestId?: string;
} & Omit<IconProps, 'color'>) {
  return (
    <IconButton
      className={className}
      disabled={isDisabled}
      $withDisabledBackground={false}
      onClick={onClick}
      dataTestId={dataTestId}
      sx={{
        borderRadius: '10px',
        backgroundColor: (theme) => {
          if (isSelected) {
            return theme.itamar.palette.primary.outlinedHoverBackground;
          }

          return theme.isLightTheme ? 'white' : theme.itamar.palette.background.emptyState;
        },
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        ':hover': {
          backgroundColor: (theme) => theme.itamar.palette.primary.outlinedHoverBackground,
        },
        ...(sx || {}),
      }}>
      <Icon {...iconProps} color={isDisabled ? 'disabled' : isSelected ? 'primary.main' : 'active'} />
    </IconButton>
  );
}
