import { t } from 'i18next';
import isNil from 'lodash/isNil';
import { numberFormatter } from '../../utils/numberUtils';

export function formatFeatureUnits(
  feature: { featureUnits?: string | null; featureUnitsPlural?: string | null },
  usageLimit?: number | null,
  hasUnlimitedUsage?: boolean | null,
) {
  if (isNil(usageLimit) && !hasUnlimitedUsage) {
    return '';
  }

  const usageText: string = !isNil(usageLimit)
    ? numberFormatter(usageLimit)
    : hasUnlimitedUsage
    ? t('entitlements.unlimitedUsage')
    : '';

  const unitsText = usageLimit === 1 ? feature.featureUnits : feature.featureUnitsPlural;

  return `${usageText} ${unitsText}`;
}
