import { executeOperationSafely } from '@stigg-common';
import {
  ExperimentFilter,
  IsExperimentNameUniqueQuery,
  IsExperimentNameUniqueQueryVariables,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

const IS_EXPERIMENT_NAME_UNIQUE = gql`
  query IsExperimentNameUnique($paging: CursorPaging!, $filter: ExperimentFilter!) {
    experiments(paging: $paging, filter: $filter) {
      totalCount
    }
  }
`;

export type IsExperimentNameUniqueProps = {
  environmentId: string;
  name: string;
  excludeRedId?: string;
};

export async function isExperimentNameUnique(
  { environmentId, name, excludeRedId }: IsExperimentNameUniqueProps,
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const filter: ExperimentFilter = {
        environmentId: { eq: environmentId },
        name: { eq: name },
      };

      if (excludeRedId) {
        filter.refId = { neq: excludeRedId };
      }

      const response = await apolloClient.query<IsExperimentNameUniqueQuery, IsExperimentNameUniqueQueryVariables>({
        query: IS_EXPERIMENT_NAME_UNIQUE,
        fetchPolicy: 'network-only',
        variables: {
          filter,
          paging: { first: 1 },
        },
      });

      return response.data.experiments.totalCount === 0;
    },
    {},
    dispatch,
  );
}
