import { Tabs, GridFlex, PageHeader } from '@stigg-components';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../../../../types/featureFlags';
import { AddonGroups } from './AddonGroups';
import { AddonsList } from './AddonsList';

function Addons() {
  const { addonGroups: addonGroupsEnabled } = useFlags<FeatureFlags>();

  return (
    <GridFlex.Column>
      {addonGroupsEnabled && <PageHeader title={t('addons.sectionTitle')} />}
      {addonGroupsEnabled ? (
        <Tabs
          data={[
            {
              title: t('addons.sectionTitle'),
              content: <AddonsList />,
            },
            {
              title: t('addons.addonGroups.title'),
              content: <AddonGroups />,
            },
          ]}
        />
      ) : (
        <AddonsList title={t('addons.sectionTitle')} />
      )}
    </GridFlex.Column>
  );
}

export default Addons;
