import { t } from 'i18next';
import styled, { css } from 'styled-components/macro';
import { Icon, Chip, InformationTooltip, Text, Box, ChipProps } from '@stigg-components';
// import { StatusChip } from '.';

const StyledAwsChip = styled(Chip)<{ $logoOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  border-color: ${({ theme }) => theme.itamar.palette.integrations.awsMarketplace};
  ${({ $logoOnly }) =>
    $logoOnly
      ? css`
          flex-direction: column;
          padding-right: 8px;
        `
      : ''};
`;

type AwsChipProps = {
  logoOnly?: boolean;
  tooltipText?: string;
  height?: number;
} & Pick<ChipProps, 'size'>;

export function AwsChip({ size = 'small', height, logoOnly, tooltipText }: AwsChipProps) {
  const chip = (
    <StyledAwsChip
      size={height ? undefined : size}
      sx={height ? { height } : undefined}
      icon={<Icon icon="AWSMarketplace" size={16} type="custom" />}
      label={logoOnly ? null : 'AWS Marketplace'}
      variant="outlined"
      $logoOnly={logoOnly}
    />
  );

  const text = tooltipText || t('integrations.awsMarketplace.managedByAWSTooltipText');
  return (
    <InformationTooltip arrow placement="top" title={<Text.B2>{text}</Text.B2>} $padding={2}>
      <Box>{chip}</Box>
    </InformationTooltip>
  );
}
