// eslint-disable-next-line no-restricted-imports
import { Checkbox as MuiCheckbox, FormControlLabelProps } from '@mui/material';
import React from 'react';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { isString } from 'lodash';
import { styled } from '@stigg-theme';
import { InformationTooltip, Text } from '../index';
import { TooltipFields } from '../InformationTooltip';
import { StyledFormControlLabel } from './StyledFormControlLabel';

const StyledCheckbox = styled(MuiCheckbox)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.itamar.palette.action.disabled : theme.itamar.palette.action.active};
`;

export type CheckboxBoxProps = Pick<MuiCheckboxProps, 'value' | 'color' | 'disabled'> & {
  label: string | React.ReactNode;
  height?: number;
  checked: boolean;
  tooltip?: TooltipFields;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  sx?: FormControlLabelProps['sx'];
  dataTestId?: string;
};

export function CheckboxItem({
  value,
  checked,
  color,
  label,
  disabled,
  tooltip,
  height,
  onChange,
  labelPlacement,
  sx,
  dataTestId,
}: CheckboxBoxProps) {
  return (
    <InformationTooltip
      arrow
      placement={tooltip?.placement || 'right'}
      title={tooltip?.title || ''}
      $maxWidth={tooltip?.maxWidth}
      PopperProps={{
        onClick: (e) => disabled && e.stopPropagation(),
      }}>
      <StyledFormControlLabel
        $selected={checked}
        $disabled={disabled}
        $height={height}
        value={value}
        disabled={disabled}
        labelPlacement={labelPlacement}
        data-testid={dataTestId}
        sx={sx}
        control={
          <StyledCheckbox
            color={color}
            $disabled={disabled}
            checked={checked}
            onChange={onChange}
            sx={{ cursor: disabled ? 'not-allowed' : undefined }}
          />
        }
        label={isString(label) ? <Text.B2 color={disabled ? 'disabled' : 'primary'}>{label}</Text.B2> : label}
      />
    </InformationTooltip>
  );
}
