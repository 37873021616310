import { t } from 'i18next';
import { Text, GridFlex, OutlinedFormFieldLabel, Grid, Icon, ExternalLink } from '@stigg-components';
import { WidgetType } from '@stigg-types/apiTypes';
import { WidgetSelectCard } from './WidgetSelectCard';
import { CustomerVisibilityFormFieldProps } from './types';

export function EntitlementPaywallCustomerVisibilityFormField({
  isPaywallHidden,
  onToggle,
  sx,
}: CustomerVisibilityFormFieldProps) {
  const entityConfig = {
    learnMoreUrl: t('widgets.visibility.planPickerEntitlementsLearnMoreLink'),
    description: t('widgets.visibility.paywallEntitlementsDescription'),
  };

  return (
    <Grid direction="column" item sx={sx}>
      <GridFlex.RowSpaceBetween mb={4}>
        <OutlinedFormFieldLabel
          marginBottom={0}
          label={t('widgets.visibility.toggleField')}
          helpTooltipMaxWidth={250}
          helpTooltipText={
            <>
              <Text.B2 mb={2}>
                {t('widgets.visibility.helpTooltipText', {
                  entityName: t(`widgets.visibility.entitlement`),
                })}
              </Text.B2>
              <ExternalLink label={t('sharedComponents.learnMore')} url={entityConfig?.learnMoreUrl} />
            </>
          }
        />
        <GridFlex.RowCenter gap={2}>
          <Icon icon={isPaywallHidden ? 'EyeOff' : 'Eye'} color="default" />
          <Text.B2>{isPaywallHidden ? t('widgets.visibility.notVisible') : t('widgets.visibility.visible')}</Text.B2>
        </GridFlex.RowCenter>
      </GridFlex.RowSpaceBetween>
      <WidgetSelectCard
        isChecked={!isPaywallHidden}
        onClick={() => onToggle(isPaywallHidden ? [] : [WidgetType.Paywall])}
        title={t('widgets.planPickers')}
        description={entityConfig?.description}
        // TODO: change value from "learn more" to "go to widget" when widget screen is ready
        linkLabel={t('widgets.goToWidget')}
        // TODO: Change link to widget customization page?
        link="https://docs.stigg.io/docs/pricing-table"
      />
    </Grid>
  );
}
