import { Divider, Flex } from '@stigg-components';
import { t } from 'i18next';
import { CountText, useTotalAndTrendQuery, WidgetTotalAndTrend } from './base';
import { WidgetCard, WidgetTitle } from '../../../common/components/Widget';
import { numberFullFormatter } from '../../../common/formatters';
import sandboxStaticChartCubeData from './static/sandbox-customers-chart-query.json';
import sandboxStaticTotalCubeData from './static/sandbox-customers-total-query.json';
import { useCubeSubscriptionStaticTotalTrendData } from './static/useCubeSubscriptionStaticTotalTrendData';
import { useIsShowMockData } from '../../../common/hooks';
import { useSetChartLastUpdate } from '../../../common/chartsLastUpdate/useSetChartLastUpdate';

const COUNT_MEASURE = 'Customer.rollingCount';

export function CustomersWidget() {
  const isShowMockData = useIsShowMockData();

  const mockData = useCubeSubscriptionStaticTotalTrendData({
    chartMock: sandboxStaticChartCubeData,
    totalMock: sandboxStaticTotalCubeData,
  });
  const realData = useTotalAndTrendQuery({
    countMeasure: COUNT_MEASURE,
    timeDimension: 'Customer.createdAt',
    skipCubeQuery: isShowMockData,
  });

  const { chartQuery, isLoadingTotal, isLoadingChart, periods, currentPeriodValue, previousPeriodValue } =
    isShowMockData ? mockData : realData;

  const title = t('dashboards.subscriptionsOverview.totalCustomers');

  useSetChartLastUpdate(title, chartQuery.resultSet);

  return (
    <WidgetCard
      colSpan={8}
      height={180}
      title={<WidgetTitle color="secondary">{title}</WidgetTitle>}
      subtitle={<CountText>{numberFullFormatter(currentPeriodValue)}</CountText>}
      isLoading={isLoadingTotal}>
      <Flex.Column flex={1} sx={{ position: 'relative' }}>
        <Divider my={3} />

        <WidgetTotalAndTrend
          isLoading={isLoadingTotal || isLoadingChart}
          currentPeriodValue={currentPeriodValue}
          previousPeriodValue={previousPeriodValue}
          periods={periods}
          chartQueryResultSet={chartQuery.resultSet!}
        />
      </Flex.Column>
    </WidgetCard>
  );
}
