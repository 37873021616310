import { useState, useEffect } from 'react';
import { t } from 'i18next';
import {
  Box,
  TextField,
  ExternalLink,
  Collapse,
  Divider,
  GridFlex,
  Text,
  TextWithHelpTooltip,
  ButtonGroup,
  Button,
  Select,
  MenuItem,
} from '@stigg-components';
import { BillingModel } from '@stigg-types/apiTypes';
import { Plus, Minus } from 'react-feather';
import styled from 'styled-components/macro';
import ImportAddonsIcon from '@assets/images/integrations/import-addons.png';
import ImportPricingModelIcon from '@assets/images/integrations/import-pricing-model.png';
import { ImportAddonsTransferList } from './ImportAddonsTransferList';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { PlansSummary } from './PlansSummary';
import { getPricingModelIcon } from './getPricingModelIcon';
import { isFeatureMetered } from '../../../../common/featureTypeUtils';

const ModelSettingsBox = styled(GridFlex.Column)`
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  padding: ${({ theme }) => theme.spacing(6)};
`;

const PlusIcon = styled(Plus)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
  height: 18px;
  width: 18px;
`;
const MinusIcon = styled(Minus)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
  height: 18px;
  width: 18px;
`;

const ListItemIcon = styled.img`
  height: 36px;
  width: 36px;
`;

export function PricingModelSettingsStep() {
  const importContext = useImportContext();
  const [withAddons, setWithAddons] = useState(false);
  const [pricingPreviewVisible, setPricingPreviewVisible] = useState(true);
  const [selectedBillingModel, setSelectedBillingModel] = useState(
    importContext.productsData.usageBasedProductPresent ? BillingModel.UsageBased : BillingModel.FlatFee,
  );

  const onToggleWithAddons = (withAddons: boolean) => {
    setWithAddons(withAddons);
  };

  useEffect(() => {
    if (isFeatureMetered(selectedBillingModel)) {
      setPricingPreviewVisible(true);
    }
  }, [selectedBillingModel]);

  useEffect(() => {
    importContext.setBillingModel(selectedBillingModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBillingModel]);

  return (
    <GridFlex.Column mb={5}>
      <Text.H3>{t('integrations.pricingModelSettingsStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={8}>
        {t('integrations.pricingModelSettingsStepSubtitle')}
      </Text.B2>

      <GridFlex.Column>
        <ModelSettingsBox mb={4}>
          <GridFlex.RowCenter>
            <ListItemIcon src={ImportAddonsIcon} alt="Import addons" />
            <GridFlex.Column ml={7}>
              <TextWithHelpTooltip
                text={t('integrations.addonsMappingHeader')}
                tooltipText={
                  <>
                    <Text.B2 mb={1}>{t('integrations.addonsMappingTooltip')}</Text.B2>
                    <ExternalLink
                      label={t('sharedComponents.learnMore')}
                      url={t('integrations.addonsMappingTooltipLearnMoreUrl')}
                    />
                  </>
                }
                tooltipPlacement="top"
              />
              <Text.B2 mt={2}>{t('integrations.areYouUsingAddons')}</Text.B2>
            </GridFlex.Column>
            <ButtonGroup sx={{ marginLeft: 'auto' }}>
              <Button
                onClick={() => onToggleWithAddons(true)}
                color={withAddons ? 'primary' : 'disabled'}
                variant={withAddons ? 'contained' : 'outlined'}>
                {t('integrations.usingAddonsYes')}
              </Button>
              <Button
                onClick={() => onToggleWithAddons(false)}
                color={withAddons ? 'disabled' : 'primary'}
                variant={withAddons ? 'outlined' : 'contained'}>
                {t('integrations.usingAddonsNo')}
              </Button>
            </ButtonGroup>
          </GridFlex.RowCenter>

          <Collapse in={withAddons}>
            <Divider my={6} />
            <Text.B2 mb={6}>{t('integrations.helpSortAddonsAndPlans')}</Text.B2>

            <ImportAddonsTransferList />
          </Collapse>
        </ModelSettingsBox>

        <ModelSettingsBox>
          <GridFlex.RowCenter alignItems="flex-start">
            <ListItemIcon src={ImportPricingModelIcon} alt="Select plan pricing model" />
            <GridFlex.Column ml={7}>
              <TextWithHelpTooltip
                text={t('integrations.planPricingModel')}
                tooltipText={
                  <>
                    <Text.B2 mb={1}>{t('integrations.planPricingModelTooltip')}</Text.B2>
                    <ExternalLink
                      label={t('sharedComponents.learnMore')}
                      url={t('integrations.selectPlansPricingModelLearnMoreUrl')}
                    />
                  </>
                }
                tooltipPlacement="top"
              />
              <Text.B2 mt={2}>{t('integrations.selectPricingModelSubtitle')}</Text.B2>
            </GridFlex.Column>

            <GridFlex.Column sx={{ marginLeft: 'auto', width: 250 }}>
              <Select
                placeholder={t('integrations.pricingModelPlaceholder')}
                onChange={(e) => {
                  setSelectedBillingModel(e.target.value as BillingModel);
                }}
                menuMaxWidth={700}
                value={selectedBillingModel}
                renderValue={(value) => (
                  <GridFlex.RowCenter>
                    {getPricingModelIcon(value as BillingModel)}
                    <Text.B2 ml={2}>{t(`pricing.billingModelDescriptions.${value}`)}</Text.B2>
                  </GridFlex.RowCenter>
                )}>
                {Object.values(BillingModel).map((billingModel) => (
                  <MenuItem key={billingModel} value={billingModel}>
                    <GridFlex.RowCenter>
                      {getPricingModelIcon(billingModel)}
                      <GridFlex.Column ml={4}>
                        <Text.B2>{t(`pricing.billingModelDescriptions.${billingModel}`)}</Text.B2>
                        <Text.B2 color="secondary">{t(`pricing.billingModelSubtitles.${billingModel}`)}</Text.B2>
                      </GridFlex.Column>
                    </GridFlex.RowCenter>
                  </MenuItem>
                ))}
              </Select>
              {(selectedBillingModel === BillingModel.PerUnit || selectedBillingModel === BillingModel.UsageBased) && (
                <GridFlex.Column mt={2}>
                  <TextWithHelpTooltip
                    textSize="B2"
                    textColor={importContext.featureUnitNameHasError ? 'error.main' : 'secondary'}
                    text={t('integrations.enterUnitName')}
                    tooltipText={
                      <>
                        <Text.B2 mb={1}>{t('integrations.enterUnitNameTooltip')}</Text.B2>
                        <ExternalLink
                          label={t('sharedComponents.learnMore')}
                          url={t('integrations.selectPlansPricingModelLearnMoreUrl')}
                        />
                      </>
                    }
                    tooltipPlacement="top"
                  />
                  <TextField
                    autoFocus
                    InputProps={{ sx: { height: 40, color: (theme) => theme.itamar.palette.text.primary } }}
                    error={importContext.featureUnitNameHasError}
                    name="featureUnitName"
                    value={importContext.featureUnitName}
                    onChange={(e) => {
                      importContext.setFeatureUnitNameHasError(false);
                      importContext.setFeatureUnitName(e.target.value);
                    }}
                    placeholder="e.g. seat, user"
                    touched={false}
                  />
                  {importContext.featureUnitNameHasError && (
                    <Text.B2 color="error.main" mt={1}>
                      {t('integrations.yup.required')}
                    </Text.B2>
                  )}
                </GridFlex.Column>
              )}
            </GridFlex.Column>
          </GridFlex.RowCenter>
          <GridFlex.Column mt={2}>
            <GridFlex.RowCenter $fullWidth alignItems="center">
              <GridFlex.RowCenter
                sx={{ cursor: 'pointer' }}
                mr={4}
                onClick={() => setPricingPreviewVisible((prevPricingPreviewVisible) => !prevPricingPreviewVisible)}>
                {pricingPreviewVisible ? <MinusIcon /> : <PlusIcon />}
                <Text.B2 ml={2} color="primary.main">
                  {pricingPreviewVisible ? 'Hide preview' : 'Show preview'}
                </Text.B2>
              </GridFlex.RowCenter>
              <Divider sx={{ flex: 1, opacity: pricingPreviewVisible ? 1 : 0, transition: 'opacity 0.2s' }} />
            </GridFlex.RowCenter>
            <Collapse in={pricingPreviewVisible}>
              <Box pt={4}>
                <PlansSummary showCountTitle={false} />
              </Box>
            </Collapse>
          </GridFlex.Column>
        </ModelSettingsBox>
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
