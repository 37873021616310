import { t } from 'i18next';
import { ProductPackageEntitlementFragment } from '../../types/apiTypes';
import { formatResetPeriod } from './formatResetPeriod';

function formatUsageNumber(usageLimit: number) {
  return usageLimit.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
}

export const formatEntitlementDisplayText = ({
  usageLimit,
  hasUnlimitedUsage,
  isCustom,
  feature,
  resetPeriod,
}: Pick<
  ProductPackageEntitlementFragment,
  'usageLimit' | 'hasUnlimitedUsage' | 'isCustom' | 'feature' | 'resetPeriod'
>) => {
  return `${
    hasUnlimitedUsage
      ? `${t('entitlements.unlimitedUsage')} ${feature?.featureUnitsPlural}`
      : isCustom
      ? `${t('entitlements.custom')} ${feature?.featureUnitsPlural}`
      : usageLimit === 1
      ? `${formatUsageNumber(usageLimit)} ${feature?.featureUnits}`
      : usageLimit !== null && usageLimit !== undefined
      ? `${formatUsageNumber(usageLimit)} ${feature?.featureUnitsPlural}`
      : feature?.displayName
  } ${resetPeriod && usageLimit ? formatResetPeriod(resetPeriod).toLowerCase() : ''}`;
};
