import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  PlanCreateInput,
  CreatePlanMutation,
  CreatePlanMutationVariables,
  Plan,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely, ExecuteOperationOptions } from '../../../common';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { appRoutes } from '../../../navigation/useNavigation';
import { fetchPlansAction } from '../plansSlice';

const CREATE_PLAN = gql`
  mutation CreatePlan($input: PlanCreateInput!) {
    createOnePlan(input: $input) {
      id
      refId
    }
  }
`;

export type CreatePlanProps = {
  planData: Omit<PlanCreateInput, 'environmentId'>;
} & ExecuteOperationOptions;

async function createPlan(
  { planData, navigateToEntity = true, withSuccessMessage = true, silentFetch }: CreatePlanProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Plan> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<CreatePlanMutation, CreatePlanMutationVariables>({
        mutation: CREATE_PLAN,
        variables: {
          input: {
            ...planData,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      await dispatch(fetchPlansAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch }));

      const newPlan = response.data?.createOnePlan;
      if (newPlan && navigateToEntity) {
        dispatch(navigateTo(appRoutes.planPage(newPlan.refId)));
      }

      return newPlan;
    },
    {
      successMessage: withSuccessMessage ? t('plans.api.successCreate') : undefined,
      failureMessageHandler: () => t('plans.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('plans.api.failDupPlanId', {
          planId: planData.refId,
        }),
      },
    },
    dispatch,
  );
}

export { createPlan };
