import { styled } from '@stigg-theme';
import { t } from 'i18next';
import capitalize from 'lodash/capitalize';
import { Edit2 } from 'react-feather';
import { AddonFragment, PackageStatus, PlanFragment, WidgetType } from '@stigg-types/apiTypes';
import { Button, DetailsLayout, Grid, GridFlex, Link, PageCard, Text } from '@stigg-components';
import { DetailsLayoutType } from '@stigg-components/types';
import { useIntegrations } from '@stigg-common';
import { useNavigation } from '../../../navigation/useNavigation';
import { packageVersionFormat } from './packageVersionFormat';
import { PackageVersionNumber } from './PackageVersionNumber';
import { IntegrationIdLink } from '../../../../components/IntegrationIdLink';
import { usePollPackageSyncStates } from './usePollPackageSyncStates';
import { PlanCustomerJourneyIndication } from '../../plans/components/PlanCustomerJourneyIndication';
import { HiddenIcon } from './hiddenFromWidgets/HiddenIcon';
import { usePaywallVisibilityControlFeature } from './usePaywallVisibilityControlFeature';
import { AddonTypeValue, AddonType } from '../../addons/components/AddonTypeValue';
import { isPackageAddon, isPackageSingleInstanceAddon } from '../packageUtils';

const EditIcon = styled(Edit2)`
  margin-left: 4px;
`;

export function PackageDetails({
  aPackage,
  onEditDetails,
  readonly,
}: {
  aPackage: PlanFragment | AddonFragment; // package is a reserved word so adding a prefix
  onEditDetails: () => void;
  readonly: boolean;
}) {
  const navigation = useNavigation();

  const { hasCRMIntegration, hasBillingIntegration } = useIntegrations();
  const packageType = aPackage.type === 'Plan' ? 'PLAN' : 'ADDON';
  const showPaywallVisibilityControl = usePaywallVisibilityControlFeature(packageType === 'PLAN' ? 'Plan' : 'Add-on');
  const isPaywallHidden = (aPackage?.hiddenFromWidgets || []).some((value) => value === WidgetType.Paywall);
  usePollPackageSyncStates(aPackage.id, packageType, aPackage.status);

  const details: DetailsLayoutType = [
    {
      title: t('packages.packageId'),
      content: aPackage.refId,
      copyableChip: true,
      withLabel: true,
    },
    {
      title: t('packages.versionNumber'),
      content:
        !aPackage.isLatest && aPackage.status === PackageStatus.Published ? (
          <PackageVersionNumber versionNumber={aPackage.versionNumber} />
        ) : (
          <Text.B2 color="secondary">
            {packageVersionFormat(
              aPackage.versionNumber,
              aPackage.isLatest && aPackage.status === PackageStatus.Published,
            )}
          </Text.B2>
        ),
    },
    {
      title: t('packages.description'),
      content: aPackage.description,
    },
    {
      title: t('packages.product'),
      content: (
        <Link variant="body2" onClick={() => navigation.navigateTo(`/products/${aPackage.product?.refId}`)}>
          {aPackage.product?.displayName}
        </Link>
      ),
    },
    {
      title: t('packages.billingId'),
      content: (
        <IntegrationIdLink
          vendorCategory="BILLING"
          entityType={packageType}
          syncStates={aPackage.syncStates}
          refId={aPackage.refId}
          to={{ integrationId: aPackage.billingId, integrationLinkUrl: aPackage.billingLinkUrl }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: aPackage.status !== PackageStatus.Draft && hasBillingIntegration,
    },
    {
      title: t('packages.crmId'),
      content: (
        <IntegrationIdLink
          vendorCategory="CRM"
          entityType={packageType}
          syncStates={aPackage.syncStates}
          refId={aPackage.refId}
          to={{
            integrationId: aPackage.prices?.[0]?.crmId,
            integrationLinkUrl: aPackage.prices?.[0]?.crmLinkUrl,
          }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: aPackage.status !== PackageStatus.Draft && hasCRMIntegration,
    },
    {
      title: t('packages.addonTypeLabel'),
      content: <AddonTypeValue value={isPackageSingleInstanceAddon(aPackage) ? AddonType.SINGLE : AddonType.MULTI} />,
      shouldPrintLine: isPackageAddon(aPackage),
      alignItems: 'flex-start',
    },
    {
      shouldPrintLine: showPaywallVisibilityControl,
      title: t('packages.customerVisibility'),
      content: (
        <GridFlex.RowMiddle>
          <HiddenIcon
            isHidden={isPaywallHidden}
            content={
              <Text.B2 ml={2}>
                {isPaywallHidden ? t('widgets.visibility.notVisible') : t('widgets.visibility.visible')}
              </Text.B2>
            }
          />
        </GridFlex.RowMiddle>
      ),
    },
  ];

  return (
    <GridFlex.Column container>
      <PageCard>
        <Grid flexDirection="column" item container rowSpacing={3}>
          <Grid justifyContent="space-between" item container mb={4}>
            <Grid item>
              <Text.H3>{t('packages.packageDetailsTitle', { packageType: capitalize(packageType) })}</Text.H3>
            </Grid>
            {!readonly && (
              <Grid item>
                <Button color="primary" $outlined onClick={() => onEditDetails()} startIcon={<EditIcon size={16} />}>
                  {t('packages.edit')}
                </Button>
              </Grid>
            )}
          </Grid>

          <DetailsLayout details={details} titleWidth={140} />

          {aPackage.type === 'Plan' && <PlanCustomerJourneyIndication plan={aPackage as PlanFragment} />}
        </Grid>
      </PageCard>
    </GridFlex.Column>
  );
}
