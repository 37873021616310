import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export const useIsShowMockData = () => {
  const { alwaysMockDashboardsData: alwaysMockDashboardsDataEnabled } = useFlags<FeatureFlags>();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId)!;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  return currentEnvironment?.isSandbox || alwaysMockDashboardsDataEnabled;
};
