import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

export const jsFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `import Stigg from '@stigg/js-client-sdk';

const stiggClient = await Stigg.initialize({ 
    apiKey: '${apiKey}'
});
export default stiggClient;

// set the customer ID usually after the customer signs in or restores a session
stiggClient.setCustomerId('${MOCK_CUSTOMER_ID}')`,
  checkBooleanEntitlement: (featureRefId) => `const entitlement = stiggClient.getBooleanEntitlement({
  featureId: '${featureRefId}'
});
  
if (entitlement.hasAccess) {
  // customer has access to the feature
} else {
  // no access, offer an upsell
}`,
  checkNumericEntitlement: (featureRefId) => `const entitlement = stiggClient.getNumericEntitlement({
  featureId: '${featureRefId}',
});
  
if (entitlement.hasAccess) {
  // get the entitlement numeric value that is defined for the feature
  console.log(entitlement.value);
}`,
  checkMeteredEntitlement: (featureRefId) => `const entitlement = stiggClient.getMeteredEntitlement({
  featureId: '${featureRefId}',
  options: {
    requestedUsage: ${MOCK_REQUESTED_USAGE}
  }
});

if (entitlement.hasAccess) {
  // has access and the requested usage is within the allowed quota
} else {
  // the customer has exceeded his quota for this feature
}`,
};
