import React from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { removeSnackbar } from '../notificationsSlice';
import { RootState, useAppDispatch } from '../../../redux/store';

let displayed = [] as any[];

const Notifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useSelector((store: RootState) => store.notificationsSlice.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      if (key) {
        storeDisplayed(key);
      }
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
