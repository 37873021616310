import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  SubscriptionMigrationTime,
  MigrateSubscriptionToLatestMutation,
  MigrateSubscriptionToLatestMutationVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { apolloClient } from '../../../ApolloClient';
import { fetchSubscriptionByRefIdAction } from '../customersSlice';

const MIGRATE_SUBSCRIPTION_TO_LATEST = gql`
  mutation migrateSubscriptionToLatest($input: SubscriptionMigrationInput!) {
    migrateSubscriptionToLatest(input: $input) {
      id
    }
  }
`;

function migrateSubscriptionToLatest(
  {
    subscriptionRefId,
    subscriptionMigrationTime,
  }: { subscriptionRefId: string; subscriptionMigrationTime: SubscriptionMigrationTime },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<
        MigrateSubscriptionToLatestMutation,
        MigrateSubscriptionToLatestMutationVariables
      >({
        mutation: MIGRATE_SUBSCRIPTION_TO_LATEST,
        variables: {
          input: {
            subscriptionId: subscriptionRefId,
            subscriptionMigrationTime,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      await dispatch(fetchSubscriptionByRefIdAction({ refId: subscriptionRefId }));

      return response.data?.migrateSubscriptionToLatest;
    },
    {
      successMessage: t('subscriptions.api.successMigration'),
      failureMessageHandler: () => t('subscriptions.api.migrationFailed'),
    },
    dispatch,
  );
}

export { migrateSubscriptionToLatest };
