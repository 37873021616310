import { gql } from '@apollo/client';

export const SUBSCRIPTION_FUTURE_UPDATE_FRAGMENT = gql`
  fragment SubscriptionFutureUpdateData on SubscriptionFutureUpdate {
    subscriptionScheduleType
    scheduleStatus
    scheduledExecutionTime
    scheduleVariables {
      ... on PlanChangeVariables {
        planRefId
        changeType
        billingPeriod
        billableFeatures {
          featureId
          quantity
        }
        addons {
          addonRefId
          quantity
        }
      }
      ... on DowngradeChangeVariables {
        addonRefIds
        billingPeriod
        downgradePlanRefId
        billableFeatures {
          featureId
          quantity
        }
        addons {
          addonRefId
          quantity
        }
      }
      ... on BillingPeriodChangeVariables {
        billingPeriod
      }
      ... on UnitAmountChangeVariables {
        newUnitAmount
        featureId
      }
      ... on AddonChangeVariables {
        addonRefId
        newQuantity
      }
    }
  }
`;
