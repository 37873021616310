import { BillingCadence, BillingModel, BillingPeriod, ChangeType, PriceFragment } from '@stigg-types/apiTypes';
import countryList from 'country-list';
import { t } from 'i18next';
import { getPrice } from '../../../pricing/utils/getPrice';
import { getBillingModelDescription, getPriceAndPeriodFormat } from '../../../pricing/utils/priceFormatUtils';
import { ChangeGroupBox, ChangeRow, getChange } from './DiffBaseComponents';
import TieredPriceTable from '../pricingTypeViews/TieredPriceTable';
import { toTiersSchema } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.initialValues';

const expandTieredPricing = (price?: PriceFragment | null, reversePeriod?: boolean) => {
  return price?.tiersMode
    ? {
        expandSectionText: t('pricing.tierPriceTableTooltip.viewTiers'),
        expandSectionContent: (
          <TieredPriceTable price={price} reversedPeriod={reversePeriod} fullHeight withRowHover={false} />
        ),
      }
    : {};
};

export function PriceChangeRow({
  before,
  after,
  changeType,
  hasMultipleCurrencies,
  hasMultipleBillingPeriods,
}: {
  before?: PriceFragment | null;
  after?: PriceFragment | null;
  changeType?: ChangeType | null;
  hasMultipleCurrencies?: boolean;
  hasMultipleBillingPeriods?: boolean;
}) {
  const perUnitPricing = after?.billingModel === BillingModel.PerUnit;
  const tieredPricing = before?.tiersMode || after?.tiersMode;
  const showCountry = hasMultipleCurrencies || !!after?.billingCountryCode || !!before?.billingCountryCode;
  const reverseBefore = hasMultipleBillingPeriods && before?.billingPeriod === BillingPeriod.Annually;
  const reverseAfter = hasMultipleBillingPeriods && after?.billingPeriod === BillingPeriod.Annually;
  const isOneOff = after?.billingCadence === BillingCadence.OneOff;

  return (
    <ChangeGroupBox changeType={changeType}>
      {showCountry && (
        <ChangeRow
          before={before?.billingCountryCode ? countryList.getName(before?.billingCountryCode) || '' : 'Default'}
          after={after?.billingCountryCode ? countryList.getName(after?.billingCountryCode) || '' : 'Default'}
          label={t('pricing.packageDifference.country')}
          showAlways
          {...getChange(before?.billingCountryCode, after?.billingCountryCode)}
        />
      )}
      {!isOneOff && (
        <ChangeRow
          before={t(`pricing.billingPeriodDescriptions.${before?.billingPeriod}`).toString()}
          after={t(`pricing.billingPeriodDescriptions.${after?.billingPeriod}`).toString()}
          label={t('pricing.packageDifference.billingPeriod')}
          showAlways
          {...getChange(before?.billingPeriod, after?.billingPeriod)}
        />
      )}
      {before?.feature || after?.feature ? (
        <ChangeRow
          before={before?.feature?.displayName}
          after={after?.feature?.displayName}
          label={t('pricing.packageDifference.usageBasedFeature')}
          showAlways
          {...getChange(before?.feature?.displayName, after?.feature?.displayName)}
        />
      ) : null}
      <ChangeRow
        before={getBillingModelDescription(before?.billingModel, before?.billingCadence)}
        after={getBillingModelDescription(after?.billingModel, after?.billingCadence)}
        label={t('pricing.packageDifference.chargeType')}
        showAlways
        {...getChange(before?.billingModel, after?.billingModel)}
      />
      {tieredPricing ? (
        <ChangeRow
          before={t(`pricing.tiersSchema.${toTiersSchema(before?.tiersMode, before?.tiers)}`).toString()}
          after={t(`pricing.tiersSchema.${toTiersSchema(after?.tiersMode, after?.tiers)}`).toString()}
          label={t('pricing.packageDifference.pricingModel')}
          showAlways
          {...getChange(before?.tiersMode, after?.tiersMode)}
          {...expandTieredPricing(after, reverseAfter)}
        />
      ) : (
        <ChangeRow
          before={
            before
              ? getPriceAndPeriodFormat(before, { shortFormat: true, reversedPeriod: reverseBefore, unitFormat: true })
              : null
          }
          after={
            after
              ? getPriceAndPeriodFormat(after, { shortFormat: true, reversedPeriod: reverseAfter, unitFormat: true })
              : null
          }
          label={t('pricing.packageDifference.price')}
          showAlways
          {...getChange(
            before ? { amount: getPrice(before).amount, blockSize: before.blockSize } : undefined,
            after ? { amount: getPrice(after).amount, blockSize: after.blockSize } : undefined,
          )}
        />
      )}
      {perUnitPricing ? (
        <>
          {before?.minUnitQuantity || after?.minUnitQuantity ? (
            <ChangeRow
              before={(before?.minUnitQuantity || 0).toString()}
              after={(after?.minUnitQuantity || 0).toString()}
              label={t('pricing.minQuantity')}
              showAlways
              {...getChange(before?.minUnitQuantity, after?.minUnitQuantity)}
            />
          ) : null}
          {before?.maxUnitQuantity || after?.maxUnitQuantity ? (
            <ChangeRow
              before={(before?.maxUnitQuantity || 'Unlimited').toString()}
              after={(after?.maxUnitQuantity || 'Unlimited').toString()}
              label={t('pricing.maxQuantity')}
              showAlways
              {...getChange(before?.maxUnitQuantity, after?.maxUnitQuantity)}
            />
          ) : null}
        </>
      ) : null}
    </ChangeGroupBox>
  );
}
