import { CustomerSubscriptionDataFragment, PlanFragment } from '@stigg-types/apiTypes';

export type SubscriptionBillableFeatures = { [featureRefId: string]: number | null | undefined };

export const mapSubscriptionToBillableFeatures = (
  subscription: Pick<CustomerSubscriptionDataFragment, 'prices'>,
  currentPlan?: PlanFragment,
) => {
  return (
    subscription.prices?.reduce<{ [key: string]: number | null | undefined }>((acc, price) => {
      const featureRefId = price.price?.feature?.refId;
      if (featureRefId) {
        acc[featureRefId] = price.usageLimit || undefined;
      } else if (price.featureId && currentPlan) {
        const planPrice = currentPlan.prices?.find((planPrice) => planPrice.featureId === price.featureId);
        const featureRefId = planPrice?.feature?.refId;
        if (featureRefId) {
          acc[featureRefId] = price.usageLimit || undefined;
        }
      }
      return acc;
    }, {}) || undefined
  );
};
