import { t } from 'i18next';
import { Dialog } from '../../../../components/Dialog';
import CreateCouponForm from './CreateCouponForm';

export function CreateCouponDialog({ open, setOpen }: any) {
  return (
    <Dialog
      content={<CreateCouponForm onCancel={() => setOpen(false)} />}
      titleText={t('coupons.createDialogTitle')}
      isOpen={open}
      onCancel={() => setOpen(false)}
      aria-labelledby="edit-coupon-dialog"
      width={650}
    />
  );
}
