import { t } from 'i18next';
import {
  DialogActionsButtons,
  Divider,
  FormRenderProps,
  Text,
  WizardAccordion,
  StepByStepStatus,
  useMount,
} from '@stigg-components';
import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormikProps } from 'formik';
import { useSetPriceWizardFormContext } from './SetPriceWizardForm.context';
import { getPackageTypeDisplay } from '../../../../common/getPackageTypeDisplay';
import { useSetPricingWizardSteps } from './SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from './SetPriceWizardForm.types';
import { getShouldShowActionButtons } from './SetPriceWizardForm.utils';

type SetPriceWizardFormContentProps = {
  onExit: () => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  initialFormUpdate?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void;
  dirty: FormikProps<SetPriceWizardFormFields>['dirty'];
  isValid: FormikProps<SetPriceWizardFormFields>['isValid'];
  isSubmitting: FormikProps<SetPriceWizardFormFields>['isSubmitting'];
  submitForm: FormikProps<SetPriceWizardFormFields>['submitForm'];
};

export function SetPriceWizardFormContent({
  onExit,
  setHasUnsavedChanges,
  initialFormUpdate,
  dirty,
  isValid,
  isSubmitting,
  submitForm,
}: SetPriceWizardFormContentProps) {
  const { aPackage, formRenderProps, initialFocusedSection } = useSetPriceWizardFormContext();
  const { values } = formRenderProps;
  const { pricingType } = values;

  const [stepByStepStatus, setStepByStepStatus] = React.useState(StepByStepStatus.NA);

  useMount(() => {
    initialFormUpdate?.(formRenderProps);
  });
  useEffect(() => setHasUnsavedChanges(dirty), [dirty, setHasUnsavedChanges]);

  const { steps, additionalSteps } = useSetPricingWizardSteps();

  const hasAdditionalSteps = !isEmpty(additionalSteps);
  const isFirstTime = !aPackage.pricingType;
  const isStepByStep = isFirstTime || pricingType !== aPackage.pricingType;
  const shouldShowActionButtons = getShouldShowActionButtons(
    isStepByStep,
    pricingType,
    stepByStepStatus,
    additionalSteps,
  );

  const uniqueFlowId = pricingType?.toString() || '';

  return (
    <>
      <Text.H3 mb={8}>{t('pricing.setPackageWizardTitle', { packageType: getPackageTypeDisplay(aPackage) })}</Text.H3>

      <WizardAccordion
        steps={steps}
        isStepByStep={isStepByStep}
        onStepByStepStatusChange={setStepByStepStatus}
        initialFocusedStep={initialFocusedSection?.stepId}
        uniqueFlowId={uniqueFlowId}
        expandOnSingleStep
        showContinueButtonForLastStep={hasAdditionalSteps}
      />

      {hasAdditionalSteps && (
        <>
          <Text.H6 mt={9} mb={4}>
            {t('pricing.additionalSettings')}
          </Text.H6>
          <WizardAccordion
            steps={additionalSteps}
            disableAllSteps={!shouldShowActionButtons}
            initialFocusedStep={initialFocusedSection?.stepId}
            uniqueFlowId={uniqueFlowId}
          />
        </>
      )}

      {shouldShowActionButtons && (
        <>
          <Divider my={6} />
          <DialogActionsButtons
            isSaveLoading={isSubmitting}
            saveDisabled={!dirty || !isValid}
            saveText={isFirstTime ? t('sharedComponents.setButton') : t('sharedComponents.saveChanges')}
            onCancel={onExit}
            onSave={submitForm}
            saveButtonSx={{ minWidth: 111 }}
          />
        </>
      )}
    </>
  );
}
