import { t } from 'i18next';
import { Text, Grid, GridFlex } from '@stigg-components';
import { DollarSign } from 'react-feather';
import { BillingModel, PriceFragment, ProductPlanFragment } from '@stigg-types/apiTypes';
import { styled, getIconColor } from '@stigg-theme';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import * as S from './PlanOffering.style';
import { calculateUnitQuantityTextFromPriceFragments } from '../../../packages/common/components/pricingTypeViews/calculateUnitQuantity';
import { HiddenIcon } from '../../../packages/common/components/hiddenFromWidgets/HiddenIcon';
import { isHiddenFromPaywall } from '../../../entitlements/visibility-utils';
import { getConnectedEntityByFeatureId } from '../../../packages/common/overageCharges.utils';

const PriceEntitlementCard = styled(S.WrapperGridFlexRowSpaceBetween)<{ $isHidden?: boolean }>`
  background-color: ${({ theme }) =>
    theme.isLightTheme
      ? theme.itamar.palette.background.lightBackground2
      : theme.itamar.palette.background.fadedBackground};
  opacity: ${({ $isHidden }) => ($isHidden ? 0.5 : 1)};
`;

export function PriceEntitlement({ pricing, plan }: { pricing: PriceFragment; plan: ProductPlanFragment }) {
  const { overagePricing: isOveragePricingEnabled } = useFlags<FeatureFlags>();
  const { billingModel, feature } = pricing;

  if (!feature || (billingModel !== BillingModel.PerUnit && billingModel !== BillingModel.UsageBased)) {
    return null;
  }
  const isHiddenFromPlanPicker = isHiddenFromPaywall(pricing);
  const hasOverage = !!pricing.featureId && !!getConnectedEntityByFeatureId(pricing.featureId, plan.overagePrices);

  return (
    <PriceEntitlementCard $isHidden={isHiddenFromPlanPicker} container wrap="nowrap">
      <Grid item>
        <DollarSign color={getIconColor('default')} />
      </Grid>
      <GridFlex.Column container item justifyContent="center" ml={4}>
        <Grid item>
          <Text.B2>
            {billingModel === BillingModel.PerUnit
              ? calculateUnitQuantityTextFromPriceFragments(
                  pricing,
                  undefined,
                  pricing.feature?.featureUnitsPlural,
                  pricing.feature?.displayName,
                )
              : `${pricing.feature?.displayName} (pay-as-you-go)`}
          </Text.B2>
        </Grid>
        {isOveragePricingEnabled && hasOverage && (
          <Grid item>
            <Text.Sub2>{t('entitlements.hasOverageCharge')}</Text.Sub2>
          </Grid>
        )}
        {/* // TODO: Reuse with original displayName when overrideDisplayName exists */}
        {/* <Grid item xs={12} md={8}>
          <LongText tooltipPlacement="bottom">{feature.refId}</LongText>
        </Grid> */}
      </GridFlex.Column>
      {isHiddenFromPlanPicker && (
        <Grid item>
          <HiddenIcon
            isHidden
            tooltip={<Text.B2>{t('widgets.visibility.pricingMetricsNotVisibleInPricingTables')}</Text.B2>}
          />
        </Grid>
      )}
    </PriceEntitlementCard>
  );
}
