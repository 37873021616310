import { gql } from '@apollo/client';
import { t } from 'i18next';
import { RemoveAddonDraftMutation, RemoveAddonDraftMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';

const REMOVE_ADDON_DRAFT = gql`
  mutation RemoveAddonDraft($input: DiscardPackageDraftInput!) {
    removeAddonDraft(input: $input)
  }
`;

async function discardAddonDraft(
  addonRefId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      await apolloClient.mutate<RemoveAddonDraftMutation, RemoveAddonDraftMutationVariables>({
        mutation: REMOVE_ADDON_DRAFT,
        variables: {
          input: { refId: addonRefId, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      dispatch(navigateTo('/addons'));
      return { refId: addonRefId };
    },
    {
      failureMessageHandler: () => t('addons.api.failDiscardDraft'),
    },
    dispatch,
  );
}

export { discardAddonDraft };
