import { Box, GridFlex, Text } from '@stigg-components';
import React, { useCallback } from 'react';
import { t } from 'i18next';
import { TooltipModel } from 'chart.js';
import isEmpty from 'lodash/isEmpty';
import { Feature, Plan } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import lowerCase from 'lodash/lowerCase';
import {
  ChartJsExternalTooltip,
  ExternalTooltipInfo,
  ExternalTooltipTranslateFunc,
  useChartJsExternalTooltip,
} from '../../../../common/chartjs/chartjs.tooltip.plugin';
import { numberFullFormatter, percentFormatter } from '../../../../common/formatters';
import { RoundedSideColorMarker } from '../../../../common/chartjs/DefaultChartTooltip';

export type UsageDistributionChartTooltipData = {
  numberOfCustomers: number;
  planName: string;
  percentage: number;
  bucketName: string;
  units: string;
  color: string;
};

export type UsageDistributionChartTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<UsageDistributionChartTooltipData>;
};

export const BackgroundBox = styled(Box)`
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
  border-radius: 4px;
`;

export function UsageDistributionChartTooltip({ tooltipInfo }: UsageDistributionChartTooltipProps) {
  if (!tooltipInfo.payload) {
    return <></>;
  }

  const { numberOfCustomers, planName, percentage, bucketName, units, color } = tooltipInfo.payload;

  return (
    <ChartJsExternalTooltip tooltipInfo={tooltipInfo}>
      <GridFlex.Row>
        <RoundedSideColorMarker style={{ backgroundColor: color }} />
        <GridFlex.ColumnCenter>
          <GridFlex.Row>
            <Text.B2 color="primary">{bucketName}</Text.B2>
            <Text.B2 color="secondary" ml={1}>
              {units}
            </Text.B2>
          </GridFlex.Row>
          <Text.B2 color="disabled" mt={4}>
            {t('dashboards.featureInspector.usedReportedBy')}
          </Text.B2>
          <GridFlex.Row mt={1}>
            <Text.B2 color="primary">{numberFullFormatter(numberOfCustomers)}</Text.B2>
            <Text.B2 color="secondary" ml={1}>
              {lowerCase(t('sharedComponents.customer', { count: numberOfCustomers }))}
            </Text.B2>
          </GridFlex.Row>
          <BackgroundBox mt={1}>
            <GridFlex.Row>
              <Text.B2 color="primary">{percentFormatter(percentage, 2)}</Text.B2>
              <Text.B2 color="secondary" ml={1}>
                {t('dashboards.featureInspector.ofThePlanCustomers', { planName })}
              </Text.B2>
            </GridFlex.Row>
          </BackgroundBox>
        </GridFlex.ColumnCenter>
      </GridFlex.Row>
    </ChartJsExternalTooltip>
  );
}

export function useUsageDistributionChartTooltip(feature: Feature, plan: Plan) {
  const translate = useCallback<ExternalTooltipTranslateFunc<'bar', UsageDistributionChartTooltipData>>(
    (tooltip: TooltipModel<'bar'>) => {
      if (isEmpty(tooltip.dataPoints) || isEmpty(tooltip.labelColors)) {
        return undefined;
      }

      const index = tooltip.dataPoints.findIndex((item) => item.datasetIndex === 0);

      // we show the tooltip only for the first dataset (which is the plan that is selected)
      if (index < 0) {
        return undefined;
      }

      const numberOfCustomers = tooltip.dataPoints[index].parsed.y;
      const totalCustomers = tooltip.chart.data.datasets[index].data.reduce((num, acc) => acc + num, 0);
      const percentage = numberOfCustomers / totalCustomers;
      const bucketName = tooltip.title[0];
      const color = tooltip.labelColors[index].borderColor as string;

      const planName = plan.displayName;
      const units = feature.featureUnitsPlural as string;

      return {
        numberOfCustomers,
        planName,
        percentage,
        bucketName,
        units,
        color,
      };
    },
    [feature, plan],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsExternalTooltip<'bar', UsageDistributionChartTooltipData>(
    translate,
  );

  return { tooltipInfo, tooltipHandler };
}
