import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FeatureAssociatedLatestPackagesQuery,
  FeatureAssociatedLatestPackagesQueryVariables,
  SlimPackageFragment,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const FETCH_FEATURE_ASSOCIATED_LATEST_PACKAGES = gql`
  query FeatureAssociatedLatestPackages($input: FeatureAssociatedLatestPackages!) {
    featureAssociatedLatestPackages(input: $input) {
      ...SlimPackage
    }
  }
  fragment SlimPackage on PackageDTO {
    refId
    displayName
    type
  }
`;

async function fetchFeatureAssociatedLatestPackages(
  featureId: string,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<SlimPackageFragment[]> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<
        FeatureAssociatedLatestPackagesQuery,
        FeatureAssociatedLatestPackagesQueryVariables
      >({
        query: FETCH_FEATURE_ASSOCIATED_LATEST_PACKAGES,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId!,
            featureId,
          },
        },
      });

      return res.data.featureAssociatedLatestPackages;
    },
    {
      failureMessageHandler: () => t('features.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchFeatureAssociatedLatestPackages };
