import { AddonChangeVariables, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import React from 'react';
import { AddonQuantityUpdatedRow } from './addon/AddonQuantityUpdatedRow';
import { AddonRemovedRow } from './addon/AddonRemovedRow';
import { AddonAddedRow } from './addon/AddonAddedRow';

export function AddonUpdateRow({
  changeVariables,
  subscription,
  alertVariant,
}: {
  changeVariables: AddonChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const { addonRefId, newQuantity } = changeVariables;
  const subscriptionAddon = subscription?.addons?.find(({ addon }) => addon.refId === addonRefId);

  if (!subscriptionAddon) {
    const addon = subscription.plan.compatibleAddons?.find(({ refId }) => refId === addonRefId);
    return addon ? <AddonAddedRow addon={addon} changeVariables={changeVariables} alertVariant={alertVariant} /> : null;
  }
  if (newQuantity === 0) {
    return <AddonRemovedRow subscriptionAddon={subscriptionAddon} alertVariant={alertVariant} />;
  }
  return (
    <AddonQuantityUpdatedRow
      subscriptionAddon={subscriptionAddon}
      changeVariables={changeVariables}
      alertVariant={alertVariant}
    />
  );
}
