import { GridFlex } from '@stigg-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Feature } from '@stigg-types/apiTypes';
import { selectFeature, selectPlan, setShowUncompletedPeriod } from '../featureInspectorSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { getEntitlement } from '../helpers/plan.helper';
import { PlanSelection } from './PlanSelection';
import { ResetPeriodFilter } from './ResetPeriodFilter';
import { SelectFeatureFilter } from './SelectFeatureFilter';

export function Filters() {
  const dispatch = useAppDispatch();
  const { filteredPlans, selectedFeature, selectedPlan, showUncompletedPeriod } = useSelector(
    (state: RootState) => state.dashboards.featureInspector,
  );
  const entitlement = selectedPlan && selectedFeature && getEntitlement(selectedPlan, selectedFeature);

  return (
    <GridFlex.RowCenter mb={5} justifyContent="space-between">
      <SelectFeatureFilter
        selectedFeature={selectedFeature}
        onChange={(feature: Feature) => dispatch(selectFeature(feature))}
      />
      <GridFlex.RowCenter>
        {entitlement?.resetPeriod && (
          <ResetPeriodFilter
            showUncompletedPeriod={showUncompletedPeriod}
            onChange={(isChecked) => dispatch(setShowUncompletedPeriod(isChecked))}
          />
        )}

        <PlanSelection
          availablePlans={filteredPlans}
          selectedPlan={selectedPlan}
          onPlanSelected={(plan) => dispatch(selectPlan(plan.refId))}
          selectedFeature={selectedFeature}
        />
      </GridFlex.RowCenter>
    </GridFlex.RowCenter>
  );
}
