import { t } from 'i18next';
import { CouponType } from '@stigg-types/apiTypes';

export const getCouponTypes = () => [
  {
    value: CouponType.Percentage,
    label: t('coupons.discountType.percentage'),
  },
  {
    value: CouponType.Fixed,
    label: t('coupons.discountType.fixed'),
  },
];
