import { t } from 'i18next';
import { EntitlementMethod } from '../EntitlementMethod';

function computePackageTitle(isTrial: boolean, originatedFromBasePlan: boolean): string {
  if (isTrial) {
    return originatedFromBasePlan ? 'customers.summarySectionTrialBasePlan' : 'customers.summarySectionTrialPlan';
  }

  return originatedFromBasePlan ? 'customers.summarySectionBasePlan' : 'customers.summarySectionActivePlan';
}

export function generateTitle(
  entitlementMethod: EntitlementMethod,
  originatedFromBasePlan: boolean,
  isTrial: boolean,
  addonName?: string,
) {
  switch (entitlementMethod) {
    case EntitlementMethod.PRICE:
    case EntitlementMethod.PLAN:
    case EntitlementMethod.BASE_PLAN:
      return t(computePackageTitle(isTrial, originatedFromBasePlan));
    case EntitlementMethod.OTHER_PRODUCT_PLAN:
      return t('customers.summarySectionOtherProductPlan');
    case EntitlementMethod.ADDON:
      return t('customers.summarySectionNewAddon', { displayName: addonName });
    case EntitlementMethod.PROMOTIONAL:
      return t('customers.summarySectionPromotional');
    default:
      return null;
  }
}
