import { gql } from '@apollo/client';

export const FETCH_EVENT_LOGS = gql`
  query EventLogs($filter: EventLogFilter!, $paging: CursorPaging, $sorting: [EventLogSort!]) {
    eventLogs(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        node {
          id
          createdAt
          accountId
          environmentId
          eventLogType
          entityType
          entityId
          parentEntityId
          actor {
            type
            name
            email
            ipAddress
          }
          payload
          webhooks {
            id
            endpoint
          }
          request {
            traceId
            body
            response
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
