import { gql } from '@apollo/client';

const CUSTOMER_LIST_FRAGMENT = gql`
  fragment CustomerListFragment on Customer {
    id
    customerId
    name
    email
    updatedAt
    totalActiveSubscription
    totalActivePromotionalEntitlements
    billingId
    billingLinkUrl
    defaultPaymentMethodId
    defaultPaymentMethodType
    defaultPaymentMethodLast4Digits
  }
`;

export const getFetchCustomersQuery = (withTotalCount?: boolean) => gql`
  query FetchCustomers($paging: CursorPaging, $sorting: [CustomerSort!], $filter: CustomerFilter!) {
    customers(paging: $paging, sorting: $sorting, filter: $filter) {
      ${withTotalCount ? 'totalCount' : ''}
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...CustomerListFragment
        }
        cursor
      }
    }
  }
  ${CUSTOMER_LIST_FRAGMENT}
`;
