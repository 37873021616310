import { t } from 'i18next';
import { gql } from '@apollo/client';
import {
  PackagePublishInput,
  PublishAddonMutation,
  PublishAddonMutationVariables,
  PublishPackageResult,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { fetchAddonByRefIdAction } from '../addonSlice';

const PUBLISH_ADDON = gql`
  mutation PublishAddon($input: PackagePublishInput!) {
    publishAddon(input: $input) {
      taskId
    }
  }
`;

async function publishAddon(
  addonData: PackagePublishInput & { refId: string },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<PublishPackageResult | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const { refId, ...restInput } = addonData;
      const response = await apolloClient.mutate<PublishAddonMutation, PublishAddonMutationVariables>({
        mutation: PUBLISH_ADDON,
        variables: {
          input: restInput,
        },
      });
      await dispatch(fetchAddonByRefIdAction({ refId }));
      return response.data?.publishAddon;
    },
    {
      successMessage: t('addons.api.successPublish'),
      failureMessageHandler: () => t('addons.api.failPublish'),
      autoHideDuration: 2000,
    },
    dispatch,
  );
}

export { publishAddon };
