import { AggregationFunction } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Text, GridFlex, CustomRenderProps } from '@stigg-components';
import { CreateFeatureFormFields } from './SidenavCreateFeature';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';
import { FieldAutocomplete } from './FieldAutocomplete';
import { EventsFieldsResult } from '../../queries/fetchEventsFields';

export function MeterAggregation<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  formRenderProps,
  readonly,
  eventsFields,
}: {
  formRenderProps: CustomRenderProps<T>;
  readonly?: boolean;
  eventsFields: EventsFieldsResult;
}) {
  const { values } = formRenderProps;
  console.log(eventsFields);
  const defaultPossibleFields = Object.keys(eventsFields).map((key) => ({
    displayName: key,
    value: key,
  }));
  return (
    <GridFlex.Row alignItems="flex-start" columnGap={4} maxWidth={530}>
      <GridFlex.Item flex={1}>
        {formRenderProps.renderSelect(
          'aggregation.function',
          t('features.aggregationFunctionLabel'),
          Object.values(AggregationFunction).map((func) => ({
            value: func,
            displayValue: t(`features.aggregationFunction.${func}`),
          })),
          {
            disabled: readonly,
          },
        )}
      </GridFlex.Item>
      <GridFlex.Item flex={1}>
        {values.aggregation?.function !== AggregationFunction.Count && (
          <GridFlex.Column>
            <Text.B2 mb={1} color="secondary">
              {t('features.aggregationFieldLabel')}
            </Text.B2>
            <FieldAutocomplete
              formRenderProps={formRenderProps}
              readonly={readonly}
              fieldKey="aggregation.field"
              possibleOptions={defaultPossibleFields}
            />
          </GridFlex.Column>
        )}
      </GridFlex.Item>
    </GridFlex.Row>
  );
}
