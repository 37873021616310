import { useSelector } from 'react-redux';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchIntegrationsAction } from '../integrations/integrationsSlice';
import { BILLING_VENDORS, CRM_VENDORS } from '../integrations/constants';

export function useIntegrations() {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const integrations = useSelector((state: RootState) => state.integrations.integrations);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoading);
  const { stripeSyncOnlyProductCatalog, stripeSecretBilling: stripeSecretBillingEnabled } = useFlags<FeatureFlags>();

  useEffect(() => {
    if (currentEnvironmentId && !integrations.length) {
      void dispatch(
        fetchIntegrationsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId, integrations.length]);

  const hasBillingIntegration =
    !stripeSecretBillingEnabled && !!integrations.some((x) => BILLING_VENDORS.has(x.vendorIdentifier));

  return {
    isLoading: isLoading || !currentEnvironmentId,
    integrations,
    hasBillingIntegration,
    hasCRMIntegration: !!integrations.find((x) => CRM_VENDORS.has(x.vendorIdentifier)),
    isSyncingCustomerAndSubscriptions: !stripeSyncOnlyProductCatalog,
  };
}
