import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { MemberStatus } from '@stigg-types/apiTypes';
import { useBroadcastChannelListener } from '@stigg-components';
import { useCookies } from 'react-cookie';
import AppLayout from '../../components/layout/AppLayout';
import NotFoundPage from '../../components/NotFoundPage';
import { browserHistory } from '../../browserHistory';
import { RootState } from '../../redux/store';
import SetAccountPage from '../accounts/components/createAccount/SetAccountPage';
import { GenerateSandboxPage } from '../accounts/components/sandboxGenerator/GenerateSandboxPage/GenerateSandboxPage';
import SettingsPage from '../accounts/components/SettingsPage';
import ConfirmRegisterMemberPage from '../auth/components/ConfirmRegisterMemberPage';
import LoginPage from '../auth/components/LoginPage';
import ProtectedRoute from '../auth/components/ProtectedRoute';
import PublicOnlyRoute from '../auth/components/PublicOnlyRoute';
import VerifyEmail from '../auth/components/VerifyEmail';
import { EnvironmentRoutes } from './EnvironmentRoutes';
import { appRoutes, useNavigation } from './useNavigation';
import { StripeConnectRedirectForwarder } from '../integrations/components/StripeConnectRedirectForwarder';
import { HubspotRedirectForwarder } from '../integrations/components/HubspotRedirectForwarder';
import BlockedPage from '../auth/components/BlockedPage';
import SignupPage from '../auth/components/SignupPage';
import { SandboxCreationPage } from '../accounts/components/sandboxTamplats/SandboxCreationPage';
import StiggMobile from '../auth/components/StiggMobile';
import Bootstrapper from '../../Bootstrapper';
import { useAnalytics } from '../common/useAnalytics';
import SwitchAccountPage from '../accounts/components/switchAccount/SwitchAccountPage';

export const SANDBOX_TEMPLATE_COOKIE_NAME = 'sandboxTemplateName';

function getRedirectUrlByOrigin(origin: string) {
  switch (origin) {
    case 'pricing.new':
      return 'plans';
    case 'addon.new':
      return 'addons';
    case 'paywall.new':
      return 'widgets/paywall';
    case 'meter.new':
    case 'feature.new':
      return 'features';
    default:
      return '';
  }
}

export function BroadcastChannelRouter() {
  useBroadcastChannelListener((message) => {
    if (message.type === 'switchAccount') {
      window.location.assign('/');
    }
  });
  return null;
}

const AppRoutes = () => {
  const { user: auth0User } = useAuth0();
  const navigation = useNavigation();
  const searchQuery = useSelector((root: RootState) => root.router.location.query);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const [cookies] = useCookies([SANDBOX_TEMPLATE_COOKIE_NAME]);
  const currentAccountId = useSelector((state: RootState) => state.accountReducer.currentAccountId);

  const isMultipleAccountsWithoutCurrentAccountId = (user?.memberships || []).length > 1 && !currentAccountId;

  const redirectTo = () => {
    if (!user || !auth0User) {
      // workaround to prevent redirect to login
      if (auth0User) {
        return '/signInEndpoint'; // dummy path just not to be '/'
      }
      return '/login';
    }

    if (!auth0User.email_verified) {
      return '/verify';
    }

    if (user.memberships.length === 0) {
      return '/account/create';
    }

    if (
      user.memberships.some((membership) => membership.memberStatus === MemberStatus.Invited) ||
      isMultipleAccountsWithoutCurrentAccountId
    ) {
      return '/account/switch';
    }
    if (cookies.sandboxTemplateName) {
      const { sandboxTemplateName } = cookies;
      return `/sandbox/${sandboxTemplateName}`;
    }

    if (environments?.length === 0) {
      return '/generate-sandbox';
    }

    return navigation.getEnvPath(`/${getRedirectUrlByOrigin(searchQuery.origin)}`);
  };

  return (
    <AppLayout>
      <Switch>
        <ProtectedRoute path="/account/settings" component={SettingsPage} />
        <ProtectedRoute path="/:environmentSlug" component={EnvironmentRoutes} />

        <Redirect from="/" to={{ pathname: redirectTo() }} />
        <Route component={NotFoundPage} />
      </Switch>
    </AppLayout>
  );
};

function Routes() {
  const location = useLocation();
  const { page } = useAnalytics();

  useEffect(() => {
    page();
  }, [location, page]);

  return (
    <Switch>
      <PublicOnlyRoute exact path="/login" component={LoginPage} />
      {/* for IdP initiated login, we get the callback to this
              route to complete the login flow. It's not a public
              route only in order to always complete the login flow */}
      <Route exact path="/idp-callback">
        <LoginPage prompt="none" />
      </Route>
      <PublicOnlyRoute exact path="/signup" component={SignupPage} />
      <Route exact path="/verify" component={VerifyEmail} />
      <Route exact path="/stigg-mobile" component={StiggMobile} />
      <ProtectedRoute requiresAccount={false} exact path="/account/create" component={SetAccountPage} />
      <ProtectedRoute exact path="/account/switch" component={SwitchAccountPage} />
      <ProtectedRoute requiresEnvironment={false} exact path="/generate-sandbox" component={GenerateSandboxPage} />
      <ProtectedRoute exact path="/sandbox/:templateName" component={SandboxCreationPage} />
      <ProtectedRoute exact path="/demo/:templateName">
        <SandboxCreationPage isDemo />
      </ProtectedRoute>
      <ProtectedRoute
        requiresAccount={false}
        exact
        path={appRoutes.confirmMemberPage()}
        component={ConfirmRegisterMemberPage}
      />
      <Route exact path="/redirects/stripe" component={StripeConnectRedirectForwarder} />
      <Route exact path="/creating-account" component={BlockedPage} />
      <Route exact path="/redirects/hubspot" component={HubspotRedirectForwarder} />
      <Route path="/" component={AppRoutes} />
    </Switch>
  );
}

export default function Router() {
  return (
    <ConnectedRouter history={browserHistory}>
      <Bootstrapper>
        <Routes />
      </Bootstrapper>
    </ConnectedRouter>
  );
}
