import { gql } from '@apollo/client';
import { t } from 'i18next';
import { UpdateOneHookMutation, UpdateOneHookMutationVariables, Hook, UpdateHook } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { HOOK_DATA_FRAGMENT } from '../queries/hookDataFragment';

const UPDATE_HOOK = gql`
  ${HOOK_DATA_FRAGMENT}
  mutation UpdateOneHook($input: UpdateOneHookInput!) {
    updateOneHook(input: $input) {
      ...HookData
    }
  }
`;

async function updateHook(
  { hookId, updatePayload }: { hookId: string; updatePayload: UpdateHook },
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<Hook> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateOneHookMutation, UpdateOneHookMutationVariables>({
        mutation: UPDATE_HOOK,
        variables: { input: { id: hookId, update: updatePayload } },
      });

      return response.data?.updateOneHook;
    },
    {
      successMessage: t('hooks.api.successUpdate'),
      failureMessageHandler: () => t('hooks.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateHook };
