import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Save } from 'react-feather';
import { Formik } from 'formik';
import { Box, Button, Grid, GridFlex, Text, LoadingButton, ActiveIntegrationIcons } from '@stigg-components';
import { t } from 'i18next';
import * as Yup from 'yup';
import { useAppDispatch, RootState } from '../../../../../../redux/store';
import { searchCouponsAction } from '../../../../../coupons/couponsSlice';
import { AutocompleteSearch } from '../../../../../packages/plans/components/AutoCompleteSearch';
import { formatCoupon } from '../../../../../coupons/components/utils';

export type ApplyCouponFormFields = {
  selectedCouponRefId: string | null;
};

type CouponOption = {
  displayName: string;
  refId: string;
};

export function ApplyCouponForm({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void;
  onSubmit: (values: ApplyCouponFormFields) => void;
}) {
  const dispatch = useAppDispatch();
  const { edges } = useSelector((state: RootState) => state.couponsReducer.searchedCoupons);
  const isFetchingCoupons = useSelector((state: RootState) => state.couponsReducer.isSearching);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  const [searchOptions, setSearchOptions] = useState<readonly CouponOption[]>([]);
  const onSearchKeyUp = (event: any) => {
    void dispatch(
      searchCouponsAction({
        search: event.target.value,
        environmentId: currentEnvironmentId,
      }),
    );
  };

  useEffect(() => {
    void dispatch(
      searchCouponsAction({
        environmentId: currentEnvironmentId,
      }),
    );
  }, [dispatch, currentEnvironmentId]);

  useEffect(() => {
    setSearchOptions(
      edges
        ? edges.map((edge) => ({
            displayName: formatCoupon(edge.node),
            refId: edge.node.refId,
          }))
        : [],
    );
  }, [edges]);

  const handleSubmit = (values: ApplyCouponFormFields) => {
    onSubmit(values);
  };

  const validationSchema = () =>
    Yup.object().shape({
      selectedCouponRefId: Yup.string().nullable(false).required(),
    });

  const initialValues = {
    selectedCouponRefId: null,
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema()} onSubmit={handleSubmit}>
      {({ isValid, handleSubmit, setFieldValue, isSubmitting }) => (
        <Box>
          <>
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={3} mt={2}>
                <AutocompleteSearch
                  chooseOption={(option: CouponOption | null) =>
                    option && setFieldValue('selectedCouponRefId', option.refId)
                  }
                  searchOptions={searchOptions}
                  onSearchKeyUp={onSearchKeyUp}
                  isFetching={isFetchingCoupons}
                  noOptionsText={t('sharedComponents.noResults')}
                  placeholder={t('coupons.applyCouponForm.placeholder')}
                  resetInputOnChoose={false}
                  getOptionLabel={(option) => `${option.displayName}`}
                  renderOption={(props: any, option: CouponOption) => (
                    <Box component="li" {...props} key={option.refId}>
                      <Grid alignItems="center" justifyContent="space-between" container spacing={6}>
                        <Grid item>
                          <Text.B2 mr={2}>{option.displayName}</Text.B2>
                        </Grid>
                        <Grid item sx={{ color: (theme) => theme.itamar.palette.grey[700] }}>
                          <Text.B2>ID: {option.refId}</Text.B2>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
                />
              </GridFlex.Column>

              <Grid item container justifyContent="space-between" alignItems="center" mt={8}>
                <Grid item>
                  <ActiveIntegrationIcons />
                </Grid>
                <Grid item>
                  <Button sx={{ mr: 3 }} $outlined color="primary" onClick={onCancel}>
                    {t('customers.cancelButton')}
                  </Button>
                  <LoadingButton
                    type="submit"
                    loadingPosition="start"
                    loading={isSubmitting}
                    startIcon={isSubmitting ? <Save /> : null}
                    disabled={!isValid}
                    variant="contained"
                    color="primary">
                    {t('sharedComponents.applyButton')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </>
        </Box>
      )}
    </Formik>
  );
}
