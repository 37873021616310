import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { GridFlex, Button, Grid, CustomDrawer, Icon } from '@stigg-components';
import { useConfirmationDialog, useQueryParam } from '@stigg-common';
import { EmptyList } from '../../../../components/EmptyList';
import Search from '../../../../components/Search';
import Table from '../../../../components/table/Table';
import createPageChangeFunc from '../../../../components/table/gqlTableHelper';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { archiveAddonGroupAction, fetchAddonGroupsAction } from '../addonSlice';
import { headCells } from './headCells';
import { useNavigation } from '../../../navigation/useNavigation';
import { PackageGroupFragment } from '../../../../types/apiTypes';
import { CreateOrEditAddonGroup } from './CreateOrEditAddonGroup';

export function AddonGroups() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [pageNum, setPageNum] = useState(0);
  const [search, setSearch] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [addonGroupToArchive, setAddonGroupToArchive] = useState<PackageGroupFragment | null>(null);
  const { value: createQueryParam } = useQueryParam('create');
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const packageGroups = useSelector((state: RootState) => state.addonReducer.packageGroups);
  const isLoadingPackageGroups = useSelector((state: RootState) => state.addonReducer.isLoadingPackageGroups);
  const { pageInfo, totalCount, edges } = packageGroups;
  const pageChangeFunc = createPageChangeFunc(dispatch, pageNum, pageInfo, setPageNum, fetchAddonGroupsAction, {
    environmentId: currentEnvironmentId,
    search,
  });
  const addonGroups = ((isLoadingPackageGroups ? [] : edges) || []).map((packageGroup) => packageGroup.node);
  const onEditClick = (addonGroup: PackageGroupFragment) => {
    navigation.navigateTo(`/addon-groups/${addonGroup.packageGroupId}`);
  };
  const onNewAddonGroupClick = () => {
    setIsDrawerOpen(true);
  };
  const onCloseCreateSidebar = () => {
    setIsDrawerOpen(false);
  };

  const [ArchiveAddonGroupConfirmationDialog, setAddonGroupArchiveDialogOpen, archiveAddonGroupDialogProps] =
    useConfirmationDialog({
      title: t('addons.addonGroups.archiveAddonGroupConfirmationDialogTitle'),
      content: t('addons.addonGroups.archiveAddonGroupConfirmationDialogText', {
        addonGroupName: addonGroupToArchive?.displayName,
      }),
      confirmButtonColor: 'error',
      confirmButtonText: t('sharedComponents.archive'),
      handleConfirm: async () => {
        if (addonGroupToArchive) {
          await dispatch(archiveAddonGroupAction(addonGroupToArchive.packageGroupId));
        }
      },
    });

  const onArchiveClick = (addonGroup: PackageGroupFragment) => {
    setAddonGroupToArchive(addonGroup);
    setAddonGroupArchiveDialogOpen(true);
  };

  useEffect(() => {
    setIsDrawerOpen(!!createQueryParam);
  }, [createQueryParam]);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchAddonGroupsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  return (
    <GridFlex.Column container mb={10}>
      <GridFlex.RowSpaceBetween my={5}>
        <Grid item maxWidth={400} flex={1}>
          <Search
            variant="outlined"
            searchOnChange
            handleSearchFunc={(searchStr) => {
              setSearch(searchStr);
              if (searchStr) {
                setPageNum(0);
              }
              void dispatch(fetchAddonGroupsAction({ search: searchStr, environmentId: currentEnvironmentId || '' }));
            }}
            searchedStr={search}
            placeholder={t('addons.addonGroups.searchPlaceholder')}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          textColor="white"
          onClick={onNewAddonGroupClick}
          data-testid="button-create-new"
          startIcon={<Icon icon="Add" color="white" />}>
          {t('addons.addonGroups.new')}
        </Button>
      </GridFlex.RowSpaceBetween>
      {isEmpty(addonGroups) && !isLoadingPackageGroups ? (
        <EmptyList
          title={
            !isEmpty(search)
              ? t('addons.addonGroups.addonGroupsEmptySearchText')
              : t('addons.addonGroups.addonGroupsEmptyText')
          }
          linkText={isEmpty(search) ? t('addons.addonGroups.addonGroupsEmptyStateTextActionLinkText') : ''}
          onLinkClick={onNewAddonGroupClick}
        />
      ) : (
        <Table
          isLoading={isLoadingPackageGroups}
          rowHeight={65}
          label={t('addons.addonGroups.tableLabel')}
          headCells={headCells(onEditClick, onArchiveClick)}
          data={addonGroups}
          totalCount={totalCount}
          pageNum={pageNum}
          pageChangeFunc={pageChangeFunc}
          onRowClick={(addonGroup) => navigation.navigateTo(`/addon-groups/${addonGroup.packageGroupId}`)}
        />
      )}

      <CustomDrawer title={t('addons.addonGroups.createTitle')} isOpen={isDrawerOpen} onClose={onCloseCreateSidebar}>
        <CreateOrEditAddonGroup onCancel={() => setIsDrawerOpen(false)} />
      </CustomDrawer>

      <ArchiveAddonGroupConfirmationDialog {...archiveAddonGroupDialogProps} />
    </GridFlex.Column>
  );
}
