// eslint-disable-next-line no-restricted-imports
import { Switch as MuiSwitch } from '@mui/material';
import { styled } from '@stigg-theme';

export const Switch = styled(MuiSwitch)`
  & .MuiSwitch-track {
    background-color: ${({ theme }) =>
      theme.isLightTheme ? theme.itamar.palette.grey[900] : theme.itamar.palette.grey[200]};
  }
`;
