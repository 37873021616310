import { t } from 'i18next';
import { AggregatedUsageByCustomerFragment } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import {
  Text,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  GridFlex,
} from '@stigg-components';

const StyledPaper = styled(Paper)`
  border-radius: 0;
  border-top: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
`;

export function AggregatedUsageByCustomerPreviewTable({
  aggregatedUsageByCustomer,
}: {
  aggregatedUsageByCustomer: AggregatedUsageByCustomerFragment[] | null;
}) {
  return (
    <GridFlex.Column rowGap={6}>
      <TableContainer component={StyledPaper}>
        <Table sx={{ borderRadius: 0, minWidth: 200 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Text.B2 color="primary">{t('features.aggregatedUsageByCustomerColumns.customerId')}</Text.B2>
              </TableCell>
              <TableCell align="left">
                <Text.B2 color="primary">{t('features.aggregatedUsageByCustomerColumns.usage')}</Text.B2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(aggregatedUsageByCustomer || []).map((row) => (
              <TableRow key={row.customerId}>
                <TableCell align="left">
                  <Text.B2 color="secondary">{row.customerId}</Text.B2>
                </TableCell>
                <TableCell align="left">
                  <Text.B2 color="secondary">{row.usage}</Text.B2>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </GridFlex.Column>
  );
}
