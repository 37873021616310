import React from 'react';
import { styled } from '@stigg-theme';
import { GridFlex, Text, ExternalLink } from '@stigg-components';

const InfoBoxWrapper = styled(GridFlex.Column)`
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: 10px;
`;

export function InfoBox({ title, text, externalUrl }: { title?: string; text: string; externalUrl: string }) {
  return (
    <InfoBoxWrapper gap={4}>
      {title && <Text.B2 $bold>{title}</Text.B2>}
      <Text.Sub2 color="secondary">{text}</Text.Sub2>
      <ExternalLink label="Learn more" url={externalUrl} />
    </InfoBoxWrapper>
  );
}
