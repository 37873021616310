import React, { useEffect, useState, useMemo } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { Add as AddIcon } from '@mui/icons-material';
import {
  LocalDate,
  Avatar,
  Button,
  OptionsDropdown,
  Chip,
  Grid,
  Text,
  GridFlex,
  useScrollableAnchor,
  InformationTooltip,
  ExternalLink,
} from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Trash2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { Member, MemberStatus } from '@stigg-types/apiTypes';
import Loader from '../../../components/Loader';
import { PageCard } from '../../../components/PageCard';
import createPageChangeFunc from '../../../components/table/gqlTableHelper';
import Table, { HeadCell } from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useConfirmationDialog } from '../../common/useConfirmationDialog';
import { fetchAccountMembersAction, removeMemberAction } from '../accountsSlice';
import { useInviteMembersDialog } from './InviteMembersDialog/useInviteMembersDialog';
import { useOptionalStiggContext } from '../../../doggo/hooks/useOptionalStiggContext';

const headCells = (onRemove: (member: Member) => void, currentUser: User | undefined): Array<HeadCell<Member, any>> => [
  {
    id: 'name',
    alignment: 'left',
    label: t('accounts.userName'),
    render: (member) => {
      return (
        <Grid container flexDirection="row" alignItems="center">
          <Avatar alt="user-avatar" src={(member.user && (member.user.profileImageUrl || member.user.name)) || ''} />
          <GridFlex.Column item ml={4} mr={4}>
            {member.user && (
              <Grid item>
                <Text.B2>{member.user && member.user.name}</Text.B2>
              </Grid>
            )}
            <Grid item>
              <Text.Sub2>{member.email}</Text.Sub2>
            </Grid>
          </GridFlex.Column>
          <Grid item>
            {member.memberStatus === MemberStatus.Invited && <Chip label="Pending" color="primary" size="small" />}
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 'lastSeenAt',
    alignment: 'left',
    label: t('accounts.lastSeen'),
    render: (member) => (
      <Text.B2>
        {member.user && member.user.lastSeenAt ? (
          <LocalDate date={member.user.lastSeenAt} formatOptions={{ withTime: true }} />
        ) : (
          '-'
        )}
      </Text.B2>
    ),
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    disableClick: true,
    width: 56,
    maxWidth: 36 + 8,
    render: (member) => (
      <OptionsDropdown
        options={[
          {
            icon: Trash2,
            text: t('sharedComponents.remove'),
            disabled: currentUser?.email === member.email,
            onClick: () => {
              onRemove(member);
            },
          },
        ]}
      />
    ),
  },
];

function AccountMembers() {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const [pageNum, setPageNum] = useState(0);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const stigg = useOptionalStiggContext();

  const { inviteMembersDialog, setIsInviteDialogOpen } = useInviteMembersDialog();
  const [teamMemberToDelete, setTeamMemberToDelete] = useState<Member | null>(null);

  const [DeleteMemberDialog, showDeleteMemberDialog, deleteMemberDialogProps] = useConfirmationDialog({
    title: t('accounts.deleteAccountMemberDialogTitle'),
    content: t('accounts.deleteAccountMemberDialogContent', { teamMember: teamMemberToDelete?.email }),
    handleConfirm: async () => {
      if (teamMemberToDelete) {
        await dispatch(removeMemberAction(teamMemberToDelete.id));
        await stigg?.refresh();
      }
    },
  });

  const { elementRef } = useScrollableAnchor({ anchor: 'accountMembers' });

  useEffect(() => {
    void dispatch(fetchAccountMembersAction({}));
  }, [dispatch]);

  const isLoadingAccountMembers = useSelector((state: RootState) => state.accountReducer.isLoadingAccountMembers);
  const { pageInfo, edges } = useSelector((state: RootState) => state.accountReducer.members);
  const pageChangeFunc = createPageChangeFunc(dispatch, pageNum, pageInfo, setPageNum, fetchAccountMembersAction);

  const onMemberRemove = (member: Member) => {
    setTeamMemberToDelete(member);
    showDeleteMemberDialog(true);
  };
  const ssoTooltip = (
    <GridFlex.Column>
      <Text.B2>{t('account.inviteMembersBlocked')}</Text.B2>
      <ExternalLink label={t('accounts.sso.learnMore')} url="https://docs.stigg.io/docs/single-sign-on" />
    </GridFlex.Column>
  );

  const inviteMembersDisabled = useMemo(
    () => (account?.samlEnabled && !user?.email?.endsWith('@stigg.io')) || false,
    [account?.samlEnabled, user?.email],
  );

  return (
    <PageCard ref={elementRef}>
      <Grid wrap="nowrap" justifyContent="space-between" container spacing={12} mb={5}>
        <GridFlex.Column item xs={6}>
          <Text.H3 mb={2}>{t('accounts.members')}</Text.H3>
          <Text.Sub2>{t('accounts.inviteMembers')}</Text.Sub2>
        </GridFlex.Column>
        <Grid item container justifyContent="flex-end" alignItems="baseline" spacing={2}>
          <InformationTooltip arrow title={inviteMembersDisabled ? ssoTooltip : ''}>
            <Grid item>
              <Button
                $outlined
                color="primary"
                disabled={inviteMembersDisabled}
                onClick={() => setIsInviteDialogOpen(true)}
                startIcon={<AddIcon />}>
                {t('accounts.invite')}
              </Button>
              {inviteMembersDialog}
            </Grid>
          </InformationTooltip>
        </Grid>
      </Grid>

      {isLoadingAccountMembers || isEmpty(edges) ? (
        <Loader />
      ) : (
        <Table
          rowHeight={65}
          headCells={headCells(onMemberRemove, user)}
          label={t('accounts.membersTable')}
          pageChangeFunc={pageChangeFunc}
          data={edges.map((item) => item.node)}
        />
      )}

      <DeleteMemberDialog {...deleteMemberDialogProps} />
    </PageCard>
  );
}

export default AccountMembers;
