import { Box, Button, Grid, GridFlex, TextField, ActiveIntegrationIcons } from '@stigg-components';
import { Formik } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { refIdValidation, generateRandomSlug, useIntegrations } from '@stigg-common';
import Loader from '../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createCustomerAction } from '../../customersSlice';
import { CustomerIcon } from '../CustomerIcon';
import { VendorIdentifier } from '../../../integrations/constants';

type CreateCustomerFormFields = {
  customerId: string;
  name: string;
  email: string;
};

const validationSchema = () =>
  Yup.object().shape({
    customerId: refIdValidation(),
    name: Yup.string(),
    email: Yup.string().email(),
  });

export default function CreateCustomerForm({ onCancel }: { onCancel: () => void }) {
  const isLoading = useSelector((root: RootState) => root.customersReducer.isLoading);

  const initialValues = {
    customerId: `customer-${generateRandomSlug()}`,
    name: '',
    email: '',
  } as CreateCustomerFormFields;

  const { isSyncingCustomerAndSubscriptions } = useIntegrations();
  const dispatch = useAppDispatch();
  const handleSubmit = async (values: CreateCustomerFormFields) => {
    await dispatch(createCustomerAction(values));
    onCancel();
  };

  return (
    <Formik validationSchema={validationSchema()} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, isValid, values, touched, handleSubmit, handleChange, handleBlur }) => (
        <Box>
          {isLoading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={3}>
                <GridFlex.Column data-testid="customer-id-input" item>
                  <TextField
                    name="customerId"
                    label={t('customers.customerId')}
                    value={values.customerId}
                    touched={!!touched.customerId}
                    error={!!errors.customerId}
                    fullWidth
                    errorText={errors.customerId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    captionText={t('customers.createIdHelperText')}
                  />
                </GridFlex.Column>
                <Grid item>
                  <TextField
                    name="name"
                    label={t('customers.nameForm')}
                    touched={!!touched.name}
                    error={!!errors.name}
                    fullWidth
                    errorText={errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    shouldMaskFromHj
                    optional
                  />
                </Grid>
                <Grid item>
                  <TextField
                    maxRows={4}
                    name="email"
                    label={t('customers.emailForm')}
                    value={values.email}
                    touched={!!touched.email}
                    error={!!errors.email}
                    fullWidth
                    errorText={errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    shouldMaskFromHj
                    optional
                    endAdornment={values.email && <CustomerIcon domain={values.email.split('@')[1] || ''} />}
                  />
                </Grid>

                <Grid item container mt={4} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ActiveIntegrationIcons
                      excludedIntegrations={!isSyncingCustomerAndSubscriptions ? [VendorIdentifier.Stripe] : []}
                    />
                  </Grid>
                  <Grid item>
                    <Button sx={{ mr: 3 }} $outlined color="primary" onClick={onCancel}>
                      {t('customers.cancelButton')}
                    </Button>
                    <Button
                      data-testid="submit-new-customer-button"
                      disabled={!isValid}
                      type="submit"
                      variant="contained"
                      color="primary">
                      {t('sharedComponents.createSaveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </GridFlex.Column>
            </form>
          )}
        </Box>
      )}
    </Formik>
  );
}
