import { ChangeType, CompatiblePackageGroupChangeFragment } from '@stigg-types/apiTypes';
import { BaseAddonChangeEntity, BaseAddonChangeRow } from './BaseAddonChangeRow';

const mapToAddonChangeType = (entity?: CompatiblePackageGroupChangeFragment | null): BaseAddonChangeEntity | null => {
  if (!entity) {
    return null;
  }

  return {
    displayName: entity.displayName,
    description: entity.description,
    refId: entity.packageGroupId,
  };
};

export function CompatiblePackageGroupChangeRow({
  before,
  after,
  changeType,
}: {
  before?: CompatiblePackageGroupChangeFragment | null;
  after?: CompatiblePackageGroupChangeFragment | null;
  changeType?: ChangeType | null;
}) {
  return (
    <BaseAddonChangeRow
      before={mapToAddonChangeType(before)}
      after={mapToAddonChangeType(after)}
      changeType={changeType}
      isAddonGroup
    />
  );
}
