import styled from 'styled-components';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import Loader from './Loader';
import { Text, Grid, Tooltip, GridFlex } from '.';
import { availableIntegrations, VendorIdentifier } from '../modules/integrations/constants';
import { useIntegrations } from '../modules/common/useLoadIntegrations';

const EXCLUDED_INTEGRATIONS = [
  VendorIdentifier.AwsMarketplace,
  VendorIdentifier.Salesforce,
  VendorIdentifier.Auth0,
  VendorIdentifier.OpenFGA,
];

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
`;

// Move tooltip closer to the icon
const popperProps = { modifiers: [{ name: 'offset', options: { offset: [0, -2] } }] };

export function ActiveIntegrationIcons({ excludedIntegrations }: { excludedIntegrations?: VendorIdentifier[] }) {
  const { integrations, isLoading } = useIntegrations();
  const { slackIntegration: slackIntegrationEnabled, stripeSecretBilling: stripeSecretBillingEnabled } =
    useFlags<FeatureFlags>();
  const resolvedExcludedIntegrations = EXCLUDED_INTEGRATIONS.concat(excludedIntegrations || []);

  if (isLoading) {
    return <Loader />;
  }

  const includedIntegrations = integrations.filter(
    (integration) => !resolvedExcludedIntegrations.includes(integration.vendorIdentifier),
  );

  const filteredIntegrations = availableIntegrations({
    slackIntegrationEnabled,
    stripeSecretBillingEnabled,
  }).filter(
    (x) =>
      includedIntegrations.some((integration) => x.vendorIdentifier === integration.vendorIdentifier) && !x.isDisabled,
  );

  return filteredIntegrations.length > 0 ? (
    <GridFlex.RowCenter columnSpacing={2}>
      <Grid item sx={{ opacity: 0.54 }} mr={2}>
        <Text.B2>
          <Text.B2 color="primary" display="inline" fontWeight="bold" mr={1}>
            {filteredIntegrations.length}
          </Text.B2>
          {t('activeIntegrationIcons.integrationsUpdate', { count: filteredIntegrations.length })}
        </Text.B2>
      </Grid>
      {filteredIntegrations.map((availableIntegration, index) => {
        if (!availableIntegration) {
          return null;
        }

        const { name, iconSmall, icon, iconSvg: IconSvg } = availableIntegration;

        return (
          <GridFlex.RowCenter item key={index}>
            <Tooltip arrow title={name} sx={{ cursor: 'pointer' }} PopperProps={popperProps}>
              <StyledIconWrapper>
                {IconSvg ? <IconSvg /> : <img src={iconSmall || icon} alt={name} />}
              </StyledIconWrapper>
            </Tooltip>
          </GridFlex.RowCenter>
        );
      })}
    </GridFlex.RowCenter>
  ) : null;
}
