import { gql } from '@apollo/client';
import {
  AddonGroupFragment,
  FetchAddonGroupByRefIdQuery,
  FetchAddonGroupByRefIdQueryVariables,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';
import { ExecuteOperationOptions } from '../../../common';
import { executeOperationSafely } from '../../../common/executeOperationSafely';

const FETCH_ADDON_GROUP = gql`
  query FetchAddonGroupByRefId($input: GetPackageGroup!) {
    getPackageGroup(input: $input) {
      ...AddonGroupFragment
    }
  }
  fragment AddonGroupFragment on PackageGroup {
    displayName
    description
    packageGroupId
    addons {
      ...AddonGroupAddonFragment
    }
    product {
      id
      displayName
      refId
    }
  }
  fragment AddonGroupAddonFragment on Addon {
    id
    displayName
    refId
    maxQuantity
    pricingType
    prices {
      id
      billingModel
      billingPeriod
      billingCadence
    }
  }
`;

type FetchAddonGroupByRefIdProps = { refId: string } & ExecuteOperationOptions;

async function fetchAddonGroupByRefId(
  { refId }: FetchAddonGroupByRefIdProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<AddonGroupFragment | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const res = await apolloClient.query<FetchAddonGroupByRefIdQuery, FetchAddonGroupByRefIdQueryVariables>({
        query: FETCH_ADDON_GROUP,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            packageGroupId: refId,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return res.data?.getPackageGroup;
    },
    {
      failureMessageHandler: () => t('addons.api.failFetchAddonGroup'),
    },
    dispatch,
  );
}

export { fetchAddonGroupByRefId };
