import { Text, GridFlex, Icon, EmptyBorderedCardContent } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';

export function ConfirmationDialogContent() {
  return (
    <GridFlex.Column rowGap={6} mt={2}>
      <GridFlex.Item>
        <Text.B2>{t('products.customerJourney.confirmationDialog.confirmMessage')}</Text.B2>
      </GridFlex.Item>
      <EmptyBorderedCardContent>
        <GridFlex.RowCenter columnGap={4}>
          <Icon icon="JourneyStart" type="custom" size={24} color="active" overrideStroke />
          <Text.B2>
            <Trans
              i18nKey="products.customerJourney.confirmationDialog.subscriptionStartNote"
              t={t}
              components={[<Text.B2 $bold display="inline" />]}
            />
          </Text.B2>
        </GridFlex.RowCenter>
      </EmptyBorderedCardContent>

      <EmptyBorderedCardContent>
        <GridFlex.RowCenter columnGap={4}>
          <Icon icon="RefreshCcw" type="reactFeather" size={24} color="active" overrideStroke />
          <Text.B2>
            <Trans
              i18nKey="products.customerJourney.confirmationDialog.subscriptionUpdateNote"
              t={t}
              components={[<Text.B2 $bold display="inline" />]}
            />
          </Text.B2>
        </GridFlex.RowCenter>
      </EmptyBorderedCardContent>

      <EmptyBorderedCardContent>
        <GridFlex.RowCenter columnGap={4}>
          <Icon icon="JourneyEnd" type="custom" size={24} color="active" overrideStroke />
          <Text.B2>
            <Trans
              i18nKey="products.customerJourney.confirmationDialog.subscriptionEndNote"
              t={t}
              components={[<Text.B2 $bold display="inline" />]}
            />
          </Text.B2>
        </GridFlex.RowCenter>
      </EmptyBorderedCardContent>
    </GridFlex.Column>
  );
}
