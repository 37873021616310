import { Skeleton, Card, CardContent, Grid, GridFlex, Text } from '@stigg-components';

export function WidgetCard({
  children,
  height,
  span,
  title,
  subtitle,
  isLoading,
}: {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  height: number;
  span: number;
  isLoading: boolean;
}) {
  const animation = isLoading ? 'wave' : false;

  return (
    <Grid gridColumn={`span ${span}`}>
      <Card p={2}>
        <CardContent>
          <GridFlex.Column sx={{ height: height && height - 58 }}>
            <GridFlex.Column mb={6}>
              {!title || isLoading ? (
                <Skeleton variant="text" animation={animation} width={240} />
              ) : (
                <Text.H6 color="primary">{title}</Text.H6>
              )}
              {!subtitle || isLoading ? (
                <Skeleton variant="text" animation={animation} width={100} sx={{ marginTop: 2 }} />
              ) : (
                <Text.B2 mt={2} color="secondary">
                  {subtitle}
                </Text.B2>
              )}
            </GridFlex.Column>
            <Grid flex={1}>{children}</Grid>
          </GridFlex.Column>
        </CardContent>
      </Card>
    </Grid>
  );
}
