import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

const GET_ENTITLEMENT_REQUEST = (
  featureRefId: string,
  addRequestedUsage: boolean,
) => `resp = client.request(Stigg::Query::GetEntitlement, {
  "query": {
    "customerId": "${MOCK_CUSTOMER_ID}",
    "featureId": "${featureRefId}"${
  addRequestedUsage
    ? `,
    "options": {  
      "requestedUsage": ${MOCK_REQUESTED_USAGE}  
    }  `
    : ''
}
  }
})

if resp.data.entitlement.is_granted
    puts "Customer has access to the feature"
else
    puts "Access denied"
end`;

export const rubyFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `require("stigg")

client = Stigg.create_client("${apiKey}")`,
  checkBooleanEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkNumericEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkMeteredEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, true),
  reportUsageForMeteredFeature: (featureRefId: string) => `resp = client.request(Stigg::Mutation::ReportUsage, {
  "input": {
    "value": 10,
    "customerId": "${MOCK_CUSTOMER_ID}",
    "featureId": "${featureRefId}"
  }
})`,
  reportEventForMeteredFeature: () => `resp = client.request(Stigg::Mutation::ReportEvent, {
  "input": {
    customerId: 'example-customer-id',
    eventName: 'example-event_name',
    idempotencyKey: '82f584b6-488f-4275-a0d3-47442d64ad79',
    dimensions: {
      example_property: '1234',
    }
  }
})`,
};
