import { motion } from 'framer-motion';
import { t } from 'i18next';
import { Button, Box, Icon, GridFlex, Text, ExternalLink } from '@stigg-components';
import { FeatureType, MeterType } from '@stigg-types/apiTypes';
import { useTheme } from '@mui/material/styles';
import styled, { css } from 'styled-components/macro';
import { getFeatureTypeIcon } from '../../../../components/getFeatureTypeIcon';
import { formatFeatureType } from '../featureTypesFormatter';
import { HelpTooltip } from '../../../../components/HelpTooltip';
import { Aggregation, Filter } from './SidenavCreateFeature';

const SelectionCard = styled(motion.div)<{
  $isSelected: boolean;
  $isMetered?: boolean;
  $isViewMode?: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground2};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      cursor: default;
    `}
  ${({ $isSelected, $isMetered, $isViewMode, theme }) =>
    $isSelected &&
    !$isViewMode &&
    css`
      background-color: ${$isMetered
        ? theme.itamar.palette.background.lightBackground
        : theme.itamar.palette.background.lightBackground2};
    `}
    ${({ $isSelected, $isViewMode, theme }) =>
    $isSelected &&
    $isViewMode &&
    css`
      background-color: ${theme.itamar.palette.grey[25]};
    `}

  ${({ $isSelected, theme }) =>
    !$isSelected &&
    css`
      &:hover {
        background-color: ${theme.itamar.palette.grey[25]};
      }
    `}
`;

export enum ReportUsageType {
  REPORT_USAGE = 'REPORT_USAGE',
  REPORT_EVENTS = 'REPORT_EVENTS',
}

type ReportUsageTypeSelectionBoxProps = {
  isSelected: boolean;
  readonly?: boolean;
  reportUsageType: ReportUsageType;
  isViewMode?: boolean;
  onReportUsageClick?: (reportUsageType: ReportUsageType) => void;
  filters?: Filter[];
  aggregation?: Aggregation | null;
  openEditFilters?: (defaultStep?: number) => void;
};

const IconWrapper = styled.div`
  path {
    stroke: ${({ theme }) => theme.itamar.palette.text.primary};
  }
`;

const REPORT_USAGE_TYPE_TEXTS = () => ({
  [ReportUsageType.REPORT_USAGE]: {
    icon: <Icon type="custom" icon="SignHashtag" />,
    title: t('features.calculatedUsage'),
    subtitle: t('features.calculatedUsageSubtitle'),
    label: t('features.calculatedUsageLabel'),
  },
  [ReportUsageType.REPORT_EVENTS]: {
    icon: <Icon type="custom" icon="MeteredEventsSign" />,
    title: t('features.rawEvents'),
    subtitle: t('features.rawEventsSubtitle'),
    label: t('features.rawEventsLabel'),
  },
});

function EditMeterButton({ onClick }: { onClick: () => void }) {
  return (
    <Button variant="text" onClick={onClick}>
      {t('sharedComponents.edit')}
    </Button>
  );
}

function ReportUsageTypeSelectionBox({
  isSelected,
  reportUsageType,
  onReportUsageClick,
  isViewMode,
  filters,
  aggregation,
  openEditFilters,
  readonly,
}: ReportUsageTypeSelectionBoxProps) {
  const theme = useTheme();
  const { icon, label, subtitle, title } = REPORT_USAGE_TYPE_TEXTS()[reportUsageType];
  const backgroundColor = {
    backgroundColor: theme.isLightTheme
      ? theme.itamar.palette.white
      : theme.itamar.palette.primary.outlinedRestingBackground,
  };
  return (
    <SelectionCard
      $isSelected={isSelected}
      $isViewMode={isViewMode}
      onClick={(e) => {
        e.stopPropagation();
        if (onReportUsageClick) {
          onReportUsageClick(reportUsageType);
        }
      }}
      style={{ flexDirection: 'column', ...(isViewMode ? backgroundColor : {}) }}>
      <GridFlex.Column rowGap={2} $fullWidth>
        <GridFlex.RowCenter columnGap={3}>
          <IconWrapper>{icon}</IconWrapper>
          <Text.B2 $bold color="primary">
            {title}
          </Text.B2>
        </GridFlex.RowCenter>
        <Text.B2 color="secondary">{subtitle}</Text.B2>
        <Text.B2 color="disabled">{label}</Text.B2>
      </GridFlex.Column>

      {filters && aggregation && (
        <GridFlex.Column
          mt={4}
          pt={4}
          rowGap={4}
          $fullWidth
          sx={{ borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}` }}>
          <GridFlex.Column rowGap={2}>
            <Text.Sub2 color="primary">{t('features.filtersTitle')}</Text.Sub2>
            <GridFlex.RowCenter justifyContent="space-between">
              <GridFlex.RowCenter columnGap={1}>
                <Text.B2 color="secondary">
                  {t(filters.length > 1 ? 'features.filtersAppliedCountPlural' : 'features.filtersAppliedCount', {
                    count: filters.length,
                  })}
                </Text.B2>
                <Button onClick={() => openEditFilters?.()}>{t('features.viewFilters')}</Button>
              </GridFlex.RowCenter>

              {!readonly && <EditMeterButton onClick={() => openEditFilters?.()} />}
            </GridFlex.RowCenter>
          </GridFlex.Column>

          <GridFlex.Column rowGap={2}>
            <Text.Sub2 color="primary">{t('features.aggregationTitle')}</Text.Sub2>
            <GridFlex.RowCenter justifyContent="space-between">
              <GridFlex.RowCenter columnGap={1}>
                <Text.B2 color="secondary">{t(`features.aggregationFunction.${aggregation.function}`)}</Text.B2>
                {aggregation.field && <Text.B2 color="primary">({aggregation.field})</Text.B2>}
              </GridFlex.RowCenter>

              {!readonly && <EditMeterButton onClick={() => openEditFilters?.(1)} />}
            </GridFlex.RowCenter>
          </GridFlex.Column>
        </GridFlex.Column>
      )}
    </SelectionCard>
  );
}

type MeterTypeBoxProps = {
  filters?: Filter[];
  aggregation?: Aggregation | null;
  selectedReportUsageType?: ReportUsageType;
  onReportUsageClick?: (reportUsageType: ReportUsageType) => void;
  isViewMode?: boolean;
  readonly?: boolean;
  openEditFilters?: (defaultStep?: number) => void;
};

function MeterTypeBox({
  selectedReportUsageType,
  onReportUsageClick,
  filters,
  aggregation,
  isViewMode,
  openEditFilters,
  readonly,
}: MeterTypeBoxProps) {
  const isReportUsage = selectedReportUsageType === ReportUsageType.REPORT_USAGE;
  const isReportEvents = selectedReportUsageType === ReportUsageType.REPORT_EVENTS;
  const shouldNotShowReportUsage = isViewMode && !isReportUsage;
  const shouldNotShowReportEvents = isViewMode && !isReportEvents;
  return (
    <GridFlex.Column $fullWidth>
      <GridFlex.RowCenter>
        <Icon type="custom" icon="WaveSignal" color="active" size={24} overrideStroke />
        <GridFlex.Column ml={4}>
          <Text.B2 color="primary">{t('features.featureType.metered')}</Text.B2>
          <Text.B2 color="secondary">{t('features.meteredDescription')}</Text.B2>
        </GridFlex.Column>
      </GridFlex.RowCenter>

      <GridFlex.Column mt={4}>
        <GridFlex.RowCenter mb={2}>
          <Text.B2 color="primary">{t('features.dataSource')}</Text.B2>
          <HelpTooltip $maxWidth={250}>
            <GridFlex.Column rowGap={2}>
              <Text.B2 color="primary">{t('features.dataSourceHelpTooltip')}</Text.B2>
              <ExternalLink label={t('sharedComponents.learnMore')} url={t('features.dataSourceLinkMoreUrl')} />
            </GridFlex.Column>
          </HelpTooltip>
        </GridFlex.RowCenter>

        <GridFlex.Column rowGap={4} $fullWidth>
          {!shouldNotShowReportUsage && (
            <ReportUsageTypeSelectionBox
              isSelected={isReportUsage}
              reportUsageType={ReportUsageType.REPORT_USAGE}
              onReportUsageClick={onReportUsageClick}
              isViewMode={isViewMode}
              openEditFilters={openEditFilters}
              readonly={readonly}
            />
          )}
          {!shouldNotShowReportEvents && (
            <ReportUsageTypeSelectionBox
              isSelected={isReportEvents}
              reportUsageType={ReportUsageType.REPORT_EVENTS}
              onReportUsageClick={onReportUsageClick}
              isViewMode={isViewMode}
              filters={filters}
              aggregation={aggregation}
              openEditFilters={openEditFilters}
              readonly={readonly}
            />
          )}
        </GridFlex.Column>
      </GridFlex.Column>
    </GridFlex.Column>
  );
}

function SwitchTypeButton({ onReset }: { onReset: () => void }) {
  return (
    <Box
      sx={{ position: 'absolute', cursor: 'pointer', right: 12, top: 12 }}
      onClick={(e) => {
        e.stopPropagation();
        onReset();
      }}>
      <Icon type="custom" icon="Delete" color="active" size={16} />
    </Box>
  );
}

type FeatureTypeSelectionBoxProps = {
  featureType: FeatureType;
  meterType?: MeterType;
  reportUsageType?: ReportUsageType;
  filters?: Filter[];
  aggregation?: Aggregation | null;
  description: string;
  exampleDescription?: string;
  onClick?: () => void;
  onReportUsageClick?: (reportUsageType: ReportUsageType) => void;
  isSelected: boolean;
  onReset?: () => void;
  openEditFilters?: (defaultStep?: number) => void;
  isViewMode?: boolean;
  readonly?: boolean;
};

export function FeatureTypeSelectionBox({
  featureType,
  meterType,
  reportUsageType,
  description,
  exampleDescription,
  filters,
  aggregation,
  onClick,
  onReportUsageClick,
  isSelected,
  onReset,
  isViewMode,
  openEditFilters,
  readonly,
}: FeatureTypeSelectionBoxProps) {
  const isMetered = meterType === MeterType.Incremental;
  const getContent = () => {
    if (isSelected && isMetered) {
      return (
        <MeterTypeBox
          selectedReportUsageType={reportUsageType}
          onReportUsageClick={onReportUsageClick}
          filters={filters}
          aggregation={aggregation}
          isViewMode={isViewMode}
          openEditFilters={openEditFilters}
          readonly={readonly}
        />
      );
    }

    return (
      <>
        {isMetered ? (
          <Icon type="custom" icon="WaveSignal" color="active" size={24} overrideStroke />
        ) : (
          getFeatureTypeIcon({ featureType, meterType }, { size: 24 })
        )}
        <GridFlex.Column ml={4} rowGap={1}>
          <Text.B2 color="primary">{formatFeatureType({ featureType, meterType })}</Text.B2>
          <Text.B2 color="secondary">{description}</Text.B2>
          {exampleDescription && <Text.B2 color="disabled">{exampleDescription}</Text.B2>}
        </GridFlex.Column>
      </>
    );
  };
  return (
    <SelectionCard
      layout
      transition={{
        delay: 0,
        opacity: { duration: 0.15, ease: 'linear' },
        layout: { duration: 0.4, type: 'spring' },
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClick}
      $isSelected={isSelected}
      $isMetered={isMetered}
      $isViewMode={isViewMode}>
      {isSelected && onReset && <SwitchTypeButton onReset={onReset} />}
      {getContent()}
    </SelectionCard>
  );
}
