import { createSlice } from '@reduxjs/toolkit';
import { setPackagePricing } from './mutations';

import { createAppAsyncThunk } from '../../../redux/createAppAsyncThunk';

export interface PriceState {
  isLoading: boolean;
}

const initialState: PriceState = {
  isLoading: false,
};

const setPackagePricingAction = createAppAsyncThunk('setPackagePricing', setPackagePricing);

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setPackagePricingAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setPackagePricingAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setPackagePricingAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export { setPackagePricingAction };

export default plansSlice.reducer;
