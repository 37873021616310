import { Text, Icon, InformationTooltip, Box } from '@stigg-components';
import { t } from 'i18next';
import { SubscriptionType } from '@stigg-common/types';
import { hasOutdatedPackagesUtil } from '@stigg-common';
import { PackageVersionNumber } from '../../../../../packages/common/components/PackageVersionNumber';

export function OutdatePriceSign({ disabled }: { disabled?: boolean }) {
  return (
    <InformationTooltip arrow placement="top" $padding={2} title={<Text.B2>{t('packages.outdatedPrice')}</Text.B2>}>
      <Box alignItems="center" display="flex">
        <Icon type="custom" icon="OutdatedPrice" size={23} color={disabled ? 'disabled' : 'active'} />
      </Box>
    </InformationTooltip>
  );
}

export function OutdatedSubscriptionSign({
  subscription,
  checkOnlyPriceOutdated,
  disabled,
}: {
  subscription: SubscriptionType;
  checkOnlyPriceOutdated?: boolean;
  disabled?: boolean;
}) {
  const { hasOutdatedPrice, hasOutdatedPackages, hasOutdatedPlan, hasOutdatedAddons } =
    hasOutdatedPackagesUtil(subscription);
  const tooltipText =
    hasOutdatedPlan && hasOutdatedAddons
      ? t('packages.outdatedPlanAndAddonVersions')
      : hasOutdatedPlan
      ? t('packages.outdatedPlanVersion')
      : hasOutdatedAddons
      ? t('packages.outdatedAddonVersion')
      : undefined;

  if (hasOutdatedPackages && !checkOnlyPriceOutdated) {
    return (
      <PackageVersionNumber
        versionNumber={subscription.plan.versionNumber}
        withoutVersionNumber
        disabled={disabled}
        tooltip={tooltipText}
      />
    );
  }

  if (hasOutdatedPrice) {
    return <OutdatePriceSign disabled={disabled} />;
  }

  return null;
}
