import { t } from 'i18next';
import { AddonFragment, FeatureStatus, FeatureType, PlanFragment, WidgetType } from '@stigg-types/apiTypes';
import { Edit2, Trash2 } from 'react-feather';
import { Box, EMPTY_CHAR, FormRenderProps, Icon, InformationTooltip, OptionsDropdown, Text } from '@stigg-components';
import { StatusChip } from '@stigg-common';
import { OptionsDropdownType } from '@stigg-components/types';
import { HeadCell } from '../../../../../components/table/Table';
import { BasePlanProps, PlanEntitlement } from './PackageGrantedEntitlements.types';
import { Value } from './components';
import { HiddenIcon } from '../hiddenFromWidgets/HiddenIcon';
import { isHiddenFromPaywall } from '../../../../entitlements/visibility-utils';
import { getFeatureTypeIcon } from '../../../../../components/getFeatureTypeIcon';
import { FeatureDisplayNameAndId } from '../../../../features/components/FeatureDisplayNameAndId';
import { SectionIdentifier } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import { getGroupIdFromPrice } from '../../../pricing/utils/priceGroups';
import { getConnectedEntityByFeatureId } from '../../overageCharges.utils';

const HEAD_CELLS_FIXED_WIDTH = 220;

export const createHeadCells = (
  basePlanEntitlementsByFeatureId: Map<string, PlanEntitlement>,
  triggerDeleteEntitlement: (packageEntitlement: PlanEntitlement) => void,
  triggerEditEntitlement: (packageEntitlement: PlanEntitlement) => void,
  triggerEditEntitlementDisplayOptions: (packageEntitlement: PlanEntitlement) => void,
  readonly: boolean,
  aPackage: PlanFragment | AddonFragment,
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void,
  isOveragePricingEnabled: boolean,
  basePlan?: BasePlanProps | null,
): Array<HeadCell<PlanEntitlement, any>> => [
  {
    disablePadding: true,
    width: 25,
    maxWidth: 25,
    id: 'feature_type',
    alignment: 'center',
    label: t('entitlements.featureType'),
    render: (entity: PlanEntitlement) => {
      return (
        <Box px={4} display="flex" alignItems="center" justifyContent="center">
          {entity.price ? (
            <InformationTooltip
              arrow
              $padding={2}
              placement="top"
              title={<Text.B2>{t('packages.pricingMetric')}</Text.B2>}>
              <div>
                <Icon type="reactFeather" icon="DollarSign" color="active" size={20} />
              </div>
            </InformationTooltip>
          ) : (
            getFeatureTypeIcon(entity.feature, { entitlementsTooltip: true })
          )}
        </Box>
      );
    },
  },
  {
    width: HEAD_CELLS_FIXED_WIDTH,
    id: 'id',
    alignment: 'left',
    label: t('entitlements.feature'),
    render: (entity: PlanEntitlement) => {
      return <FeatureDisplayNameAndId feature={entity.feature} />;
    },
  },
  {
    width: HEAD_CELLS_FIXED_WIDTH,
    id: 'value',
    alignment: 'left',
    label: t('entitlements.featureValue'),
    render: (entity: PlanEntitlement) => (
      <Value
        entity={entity}
        overriddenEntity={basePlanEntitlementsByFeatureId.get(entity.feature.id)}
        basePlan={basePlan}
        hasOverageCharge={!!getConnectedEntityByFeatureId(entity.featureId, aPackage.overagePrices)}
      />
    ),
  },
  {
    width: HEAD_CELLS_FIXED_WIDTH,
    maxWidth: HEAD_CELLS_FIXED_WIDTH,
    id: 'feature-widget-display-name-override',
    alignment: 'left' as any,
    label: t('widgets.customTextAndVisibilityColumn'),
    render: (entity: PlanEntitlement) => {
      return <Text.B2>{entity.displayNameOverride || EMPTY_CHAR}</Text.B2>;
    },
  },
  {
    width: readonly ? 60 : 30,
    maxWidth: readonly ? 60 : 30,
    disablePadding: true,
    id: 'feature-archive-status',
    alignment: 'right' as any,
    render: (entity: PlanEntitlement) => {
      if (entity.feature.featureStatus !== FeatureStatus.Suspended) {
        return null;
      }
      return (
        <StatusChip
          textColor="primary"
          color="disabled"
          variant="outlined"
          label={t('features.archived')}
          size="small"
        />
      );
    },
  },
  {
    width: 25,
    maxWidth: 25,
    disablePadding: true,
    id: 'feature-widget-visibility',
    alignment: 'right' as any,
    render: (entity: PlanEntitlement) => {
      let customTooltip;
      let isHidden = (entity.hiddenFromWidgets || []).includes(WidgetType.Paywall);
      if (entity.price) {
        isHidden = isHiddenFromPaywall(entity.price);
        customTooltip = (
          <Text.B2>
            {isHidden
              ? t('widgets.visibility.pricingMetricsNotVisibleInPricingTables')
              : t('widgets.visibility.priceEntitlementTooltip')}
          </Text.B2>
        );
      }

      return <HiddenIcon isHidden={isHidden} tooltip={customTooltip} tooltipPlacement="left" />;
    },
  },
  {
    id: 'options',
    alignment: 'center',
    label: '',
    width: 25,
    maxWidth: 25,
    disablePadding: true,
    visible: !readonly,
    render: (entity: PlanEntitlement) => {
      const options: OptionsDropdownType = [];
      if (entity.price) {
        options.push({
          icon: () => <Icon icon="Charge" type="custom" size={24} />,
          text: t('entitlements.editCharge'),
          dataTestId: 'button-edit-charge',
          onClick: () => {
            openSetPriceWizard({ stepId: 'charges', priceGroupId: getGroupIdFromPrice(entity.price!) });
          },
        });

        if (entity.price.featureId) {
          const priceOveragePrice = getConnectedEntityByFeatureId(entity.price.featureId, aPackage.overagePrices);
          if (priceOveragePrice && isOveragePricingEnabled) {
            options.push({
              icon: () => <Icon icon="OverageCharge" type="custom" size={24} />,
              text: t('entitlements.editOverageCharge'),
              dataTestId: 'button-edit-overage-charge',
              onClick: () => {
                openSetPriceWizard({ stepId: 'overageCharges', priceGroupId: getGroupIdFromPrice(priceOveragePrice) });
              },
            });
          }
        }
      } else {
        options.push(
          {
            icon: () => <Icon icon="Eye" type="reactFeather" size={20} />,
            text: t('entitlements.customizeEntitlementDisplayOptions'),
            onClick: () => triggerEditEntitlementDisplayOptions(entity),
          },
          {
            icon: Trash2,
            text: t('entitlements.removeEntitlement'),
            onClick: () => triggerDeleteEntitlement(entity),
          },
        );

        if (entity.feature?.featureType !== FeatureType.Boolean) {
          const entitlementOveragePrice = getConnectedEntityByFeatureId(entity.featureId, aPackage.overagePrices);
          const withOverageCharge = !!entitlementOveragePrice && isOveragePricingEnabled;

          options.unshift({
            icon: Edit2,
            text: withOverageCharge ? t('entitlements.editOverageCharge') : t('entitlements.editEntitlement'),
            dataTestId: 'button-edit-entitlement',
            onClick: () => {
              if (withOverageCharge) {
                openSetPriceWizard({
                  stepId: 'overageCharges',
                  priceGroupId: getGroupIdFromPrice(entitlementOveragePrice),
                });
              } else {
                triggerEditEntitlement(entity);
              }
            },
          });
        }
      }

      return <OptionsDropdown options={options} dataTestId={`select-entitlement-options-${entity.feature.refId}`} />;
    },
  },
];
