const BROADCAST_CHANNEL_NAME = 'stigg';

export interface BroadcastChannelModule {
  channel: BroadcastChannel;
  sendMessage: (type: string, payload: any) => void;
  addListener: (handler: (data: any) => void) => void;
  removeListener: () => void;
}

export const broadcastChannelModule: BroadcastChannelModule = {
  channel: new BroadcastChannel(BROADCAST_CHANNEL_NAME),
  sendMessage: (type, payload) => {
    broadcastChannelModule.channel.postMessage({ type, payload });
  },

  addListener: (handler) => {
    broadcastChannelModule.channel.onmessage = (event) => {
      handler(event.data);
    };
  },

  removeListener: () => {
    broadcastChannelModule.channel.onmessage = null;
  },
};
