import { Field, FormRenderProps } from '@stigg-components';
import { t } from 'i18next';
import { EntitlementFormFields } from '../types';
import { onSwitchChange } from '../../../../packages/plans/components/EntitlementRow';
import { isMetered } from '../../../../features/utils/isMetered';

export function getEntitlementSoftLimitFields(
  formRenderProps: FormRenderProps<EntitlementFormFields>,
  index: number,
  previousValue: number | null | undefined,
): Field<EntitlementFormFields>[] {
  const { values } = formRenderProps;
  const entitlement = values.entitlements[index];

  if (!entitlement.feature || !isMetered(entitlement.feature)) {
    return [];
  }

  return [
    {
      type: 'switch',
      id: `entitlements[${index}].hasSoftLimit`,
      negateValue: true,
      label: t('entitlements.hardLimit'),
      collapsed: !!entitlement.hasUnlimitedUsage,
      layout: 'row',
      onChange: async (hasSoftLimitValue) => {
        if (hasSoftLimitValue && entitlement.hasUnlimitedUsage) {
          await onSwitchChange({
            currentValue: true,
            switchField: 'hasUnlimitedUsage',
            setFieldValue: formRenderProps.setFieldValue,
            setFieldTouched: formRenderProps.setFieldTouched,
            index,
            usageLimitValue: previousValue,
          });
        }
      },
    },
  ];
}
