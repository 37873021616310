import { useQuery } from '@tanstack/react-query';
import { ApolloQueryResult, gql } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { executeOperationSafely } from '@stigg-common';
import { AddonAssociatedEntitiesQuery, AddonAssociatedEntitiesQueryVariables } from '@stigg-types/apiTypes';
import { RootState } from '../../../../redux/store';
import { apolloClient } from '../../../../ApolloClient';

export const FETCH_ADDON_ASSOCIATED_ENTITIES = gql`
  query AddonAssociatedEntities($input: AddonAssociatedEntitiesInput!) {
    addonAssociatedEntities(input: $input) {
      plans {
        refId
        status
        versionNumber
        displayName
      }
      packageGroups {
        packageGroupId
        displayName
      }
    }
  }
`;

export const useGetAddonAssociatedEntities = ({ addonId }: { addonId: string }) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  return useQuery({
    queryKey: ['addonAssociatedEntities', addonId],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<AddonAssociatedEntitiesQuery, AddonAssociatedEntitiesQueryVariables>({
            fetchPolicy: 'network-only',
            query: FETCH_ADDON_ASSOCIATED_ENTITIES,
            variables: {
              input: {
                environmentId,
                refId: addonId,
              },
            },
          }),
        {},
        dispatch,
      ),
    select: (result: ApolloQueryResult<AddonAssociatedEntitiesQuery>) => result.data?.addonAssociatedEntities,
    retry: 0, // we automatically retry in apollo client
  });
};
