import { EntitlementResetPeriod } from '@stigg-types/apiTypes';
import { ResetPeriodConfiguration } from '@stigg-common/types';

export const getResetPeriodData = (
  resetPeriod?: EntitlementResetPeriod | null,
  resetPeriodConfiguration?: ResetPeriodConfiguration | null,
) => {
  let config = {};
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      config = {
        yearlyResetPeriodConfiguration: { accordingTo: resetPeriodConfiguration },
      };
      break;
    case EntitlementResetPeriod.Month:
      config = {
        monthlyResetPeriodConfiguration: { accordingTo: resetPeriodConfiguration },
      };
      break;
    case EntitlementResetPeriod.Week:
      config = {
        weeklyResetPeriodConfiguration: { accordingTo: resetPeriodConfiguration },
      };
      break;
    default:
      config = {};
  }

  return {
    resetPeriod,
    ...config,
  };
};
