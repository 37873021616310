import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fetchProductsAction, resetProductsState } from '../../modules/products/productsSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchIntegrationsAction } from '../../modules/integrations/integrationsSlice';
import { fetchActiveExperimentsAction } from '../../modules/experiemnts/experimentsSlice';
import { resetPlansState } from '../../modules/packages/plans/plansSlice';

// environment resources that should be available to
// the entire web application are loaded here
export function useReloadEnvironmentResources() {
  const lastEnvironmentId = useRef<string>('');
  const dispatch = useAppDispatch();
  const { currentEnvironmentId: environmentId } = useSelector((state: RootState) => state.accountReducer);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const { environmentId: activeExperimentsEnvironmentId } = useSelector(
    (state: RootState) => state.experimentsReducer.global,
  );
  const userHasAnAccount = user && user?.memberships?.length > 0;

  useEffect(() => {
    const loadEnvironmentData = async () => {
      if (!userHasAnAccount) {
        return;
      }
      if (!environmentId || lastEnvironmentId.current === environmentId) {
        return;
      }

      // On first load, no need to reset the state, as the state is already empty.
      if (lastEnvironmentId.current) {
        await Promise.all([dispatch(resetProductsState()), dispatch(resetPlansState())]);
      }

      void dispatch(fetchProductsAction({ environmentId }));
      void dispatch(fetchIntegrationsAction({ environmentId }));

      // the condition to prevent double loading upon page startup,
      // since this is also loaded by Bootstrapper as well.
      if (environmentId !== activeExperimentsEnvironmentId) {
        void dispatch(fetchActiveExperimentsAction({ silentFetch: true }));
      }

      lastEnvironmentId.current = environmentId;
    };

    void loadEnvironmentData();
  }, [dispatch, environmentId, activeExperimentsEnvironmentId, userHasAnAccount]);
}
