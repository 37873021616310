import { useRef, useState } from 'react';
import { IntegrationFragment } from '../../../../../types/apiTypes';
import { ImportCustomersWizardMainContent } from './ImportCustomersWizardMainContent';
import { ImportCustomersWizardSideNav } from './ImportCustomersWizardSideNav';

export function ImportCustomersWizard({
  integration,
  closeImportDialog,
}: {
  integration: IntegrationFragment;
  closeImportDialog: () => void;
}) {
  const prevStepIndex = useRef(0);
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = () => {
    setActiveStep((step) => step + 1);
  };

  const prevStep = () => {
    setActiveStep((step) => step - 1);
  };

  return (
    <>
      <ImportCustomersWizardSideNav activeStep={activeStep} />
      <ImportCustomersWizardMainContent
        integration={integration}
        activeStep={activeStep}
        nextStep={nextStep}
        prevStep={prevStep}
        prevStepIndex={prevStepIndex}
        closeImportDialog={closeImportDialog}
      />
    </>
  );
}
