import { FontWeight, TypographyConfigurationFragment } from '@stigg-types/apiTypes';
import { DeepPartial } from '@reduxjs/toolkit';
import { TypographyConfiguration } from '../types/typography';

export const convertBooleanToFontWeight = (isBold: boolean) => (isBold ? FontWeight.Bold : FontWeight.Normal);
export const convertFontWeight = (weight: FontWeight | undefined | null): 'bold' | 'normal' =>
  weight === FontWeight.Bold ? 'bold' : 'normal';

export function mapTypography(
  typography?: TypographyConfigurationFragment | null,
): DeepPartial<TypographyConfiguration> | undefined {
  if (!typography) {
    return undefined;
  }

  return {
    fontFamily: typography?.fontFamily || undefined,
    h1: {
      fontSize: typography?.h1?.fontSize || undefined,
      fontWeight: typography?.h1?.fontWeight || undefined,
    },
    h2: {
      fontSize: typography?.h2?.fontSize || undefined,
      fontWeight: typography?.h2?.fontWeight || undefined,
    },
    h3: {
      fontSize: typography?.h3?.fontSize || undefined,
      fontWeight: typography?.h3?.fontWeight || undefined,
    },
    body: {
      fontSize: typography?.body?.fontSize || undefined,
      fontWeight: typography?.body?.fontWeight || undefined,
    },
  };
}
