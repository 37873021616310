import { useEffect } from 'react';
import { FieldArray } from 'formik';
import { t } from 'i18next';
import { omit, compact, isEmpty } from 'lodash';
import {
  AddonFragment,
  BillingPeriod,
  CustomerSubscriptionDataFragment,
  PlanFragment,
  PlanListFragment,
  PriceFragment,
  PricingType,
} from '@stigg-types/apiTypes';
import { FormRenderProps, Grid, OutlinedFormFieldLabel } from '@stigg-components';
import { SubscriptionAddon, SubscriptionFormFields } from '../SubscriptionForm.types';
import { AddedAddonsList } from '../../../../../../packages/plans/components/planPage/compatibleAddons/AddedAddonsList';
import { AddonAutoCompleteSearch } from './AddonAutoCompleteSearch';

type SubscriptionAddonsProps = {
  currentPlan?: PlanListFragment | PlanFragment;
  isCustomSubscription?: boolean;
  formProps: FormRenderProps<SubscriptionFormFields>;
  subscription: CustomerSubscriptionDataFragment | null | undefined;
  addonsMap: Map<string, AddonFragment>;
};

const isFreePlan = (plan: PlanListFragment | PlanFragment) => !!plan && plan?.pricingType === PricingType.Free;

function shouldShowMonthlyPriceVariation(currentBillingPeriod: BillingPeriod, planPrices?: PriceFragment[] | null) {
  const hasMonthlyPrice = planPrices?.some((price) => price.billingPeriod === BillingPeriod.Monthly);
  return hasMonthlyPrice && currentBillingPeriod === BillingPeriod.Annually;
}

const findAddonPrice = (addon: AddonFragment, billingPeriod: BillingPeriod, billingCountryCode: string | null) =>
  addon.prices?.find(
    (price) => price.billingPeriod === billingPeriod && price.billingCountryCode === billingCountryCode,
  );

const useHandleAddonsPriceOnPricePropertiesChange = ({
  addonsMap,
  isCustomSubscription,
  formProps,
}: Pick<SubscriptionAddonsProps, 'addonsMap' | 'isCustomSubscription' | 'formProps'>) => {
  const { values, setFieldValue } = formProps;
  const { addons, billingPeriod, billingCountryCode } = values;

  useEffect(() => {
    if (isCustomSubscription) {
      addons.forEach((_, index) => {
        setFieldValue(`addons[${index}].price`, null);
      });
    } else if (billingPeriod) {
      const addonsWithPrices = compact(
        addons.map((addon) => {
          const originalAddon = addonsMap.get(addon.id);
          if (originalAddon) {
            const addonPrice = findAddonPrice(originalAddon, billingPeriod, billingCountryCode || null);
            if (addonPrice) {
              return { ...addon, price: addonPrice };
            }
          }

          return addon;
        }),
      );

      setFieldValue('addons', addonsWithPrices);
    }
    // Only run when billingPeriod / isCustomSubscription / billingCountryCode changes as they effect the addons price
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomSubscription, billingCountryCode, billingPeriod]);
};

export const SubscriptionAddons = ({
  currentPlan,
  isCustomSubscription,
  formProps,
  subscription,
  addonsMap,
}: SubscriptionAddonsProps) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formProps;
  const { addons, billingPeriod, billingCountryCode } = values;

  const hasPrices = !!billingPeriod && !isCustomSubscription;

  useHandleAddonsPriceOnPricePropertiesChange({ addonsMap, formProps, isCustomSubscription });

  const showMonthlyPriceVariation = hasPrices && shouldShowMonthlyPriceVariation(billingPeriod, currentPlan?.prices);

  const onRemoveAddon = (remove: (index: number) => void, index: number, addon: SubscriptionAddon) => {
    addonsMap.delete(addon.id);
    remove(index);
    setFieldValue('addonsPriceOverride', omit(values.addonsPriceOverride, addon.refId));
  };

  if (!currentPlan || isFreePlan(currentPlan)) {
    return null;
  }

  return (
    <FieldArray name="addons" validateOnChange={false}>
      {({ push, remove }) => {
        return (
          <Grid container flexDirection="column">
            <OutlinedFormFieldLabel
              label={t('subscriptions.addons')}
              helpTooltipText={t('subscriptions.extendSubscriptionPlanWithAddons')}
              optional
            />
            <Grid item>
              <AddonAutoCompleteSearch
                addonsMap={addonsMap}
                billingCountryCode={billingCountryCode}
                billingPeriod={billingPeriod}
                isCustomSubscription={isCustomSubscription}
                subscription={subscription}
                push={push}
              />
            </Grid>
            {!isEmpty(addons) ? (
              <Grid item mt={2}>
                <AddedAddonsList
                  addons={addons}
                  remove={(index, addon) => onRemoveAddon(remove, index, addon)}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  formProps={formProps}
                  touched={touched}
                  showMonthlyPriceVariation={!!showMonthlyPriceVariation}
                  withQuantity
                  withPrice
                  withAddonId={false}
                />
              </Grid>
            ) : null}
          </Grid>
        );
      }}
    </FieldArray>
  );
};
