import { useSelector } from 'react-redux';
import { createHmac } from 'crypto';
import { useMemo } from 'react';
import { RootState } from '../../../redux/store';

export function createCustomerToken(customerId: string, signingSecret: string): string {
  const signature = createHmac('sha256', signingSecret).update(customerId).digest('hex');
  return `HMAC-SHA256 ${customerId}:${signature}`;
}

export const useCustomerToken = (customerId: string | undefined): string | undefined => {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);

  return useMemo(() => {
    if (!currentEnvironment || !customerId) {
      return undefined;
    }

    const { hardenClientAccessEnabled, signingToken } = currentEnvironment;
    return hardenClientAccessEnabled ? createCustomerToken(customerId, signingToken) : undefined;
  }, [customerId, currentEnvironment]);
};
