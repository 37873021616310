import { drawFormFields, Field, FormRenderProps, StepAccordionWithConfirm, Text, GridFlex } from '@stigg-components';
import React, { ReactElement } from 'react';
import { get, omit } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { SxProps } from '@mui/system';
import { FeatureHeader } from './FeatureTitle';
import { EntitlementFormFields } from './types';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { useEntitlementSettingsFields } from './fields/useEntitlementSettingsFields';
import { StepsManagement } from '../../../packages/pricing/components/SetPriceWizard/form/utils/useSubStepsState';
import { useEntitlementFeatureSelectionFields } from './fields/useEntitlementFeatureSelectionFields';
import { AddFeatureProps } from '../../../packages/plans/components/addEntitlement/AddEntitlementsDrawer';

const CONFIRM_SECTION_SX: SxProps = { justifyContent: 'flex-end' };

export type EntitlementSettingsProps = {
  formRenderProps: FormRenderProps<EntitlementFormFields>;
  index: number;
  onRemove?: () => void;
  excludedFeaturesIds: string[];
  setCreateFeatureFlowProps?: (props: AddFeatureProps) => void;
  basePlanEntitlementsByFeatureId?: Map<string, PlanEntitlement>;
  basePlan?: BasePlanProps | null;
  withCustomEntitlementOption?: boolean;
  withEntitlementBehaviourOption?: boolean;
  entitlementsState: StepsManagement;
  additionalActionButtons?: ReactElement;
  onNewEntitlementConfirmed?: () => void;
  permanent?: boolean;
};

export const EntitlementSettings = React.forwardRef<HTMLDivElement, EntitlementSettingsProps>(
  (
    {
      onRemove,
      index,
      formRenderProps,
      excludedFeaturesIds,
      setCreateFeatureFlowProps,
      basePlanEntitlementsByFeatureId,
      basePlan,
      withCustomEntitlementOption,
      withEntitlementBehaviourOption,
      entitlementsState,
      additionalActionButtons,
      onNewEntitlementConfirmed,
      permanent,
    }: EntitlementSettingsProps,
    ref,
  ) => {
    const entitlement = formRenderProps.values.entitlements[index];
    const entitlementErrors = get(formRenderProps.errors, `entitlements[${index}]`);
    const hasErrors = !isEmpty(entitlementErrors);
    const hasErrorsWithoutIsConfirmed = !isEmpty(omit(entitlementErrors, 'isConfirmed'));
    const hasSingleEntitlement = formRenderProps.values.entitlements.length === 1;

    const onConfirm = () => {
      formRenderProps.setFieldValue(`entitlements[${index}].isConfirmed`, true);
      formRenderProps.setFieldTouched(`entitlements[${index}].isConfirmed`, true);
      entitlementsState.setFocused(undefined);
      onNewEntitlementConfirmed?.();
    };

    const settingFields = useEntitlementSettingsFields({
      formRenderProps,
      index,
      withCustomEntitlementOption,
      withEntitlementBehaviourOption,
    });

    const featureSelectionFields = useEntitlementFeatureSelectionFields({
      formRenderProps,
      index,
      onRemove,
      excludedFeaturesIds,
      setCreateFeatureFlowProps,
      basePlanEntitlementsByFeatureId,
      basePlan,
      hasSingleEntitlement,
    });

    let header: React.ReactNode;
    let withSeparator: boolean;
    let fields: Field<EntitlementFormFields>[];

    if (entitlement.feature) {
      header = <FeatureHeader feature={entitlement.feature} bold />;
      withSeparator = true;
      fields = settingFields;
    } else {
      header = <Text.B2 $bold>Select feature</Text.B2>;
      withSeparator = false;
      fields = featureSelectionFields;
    }

    return (
      <StepAccordionWithConfirm
        onToggle={() => entitlementsState.toggle(entitlement.uuid)}
        onRemove={onRemove}
        confirmRemoveDialogTitle="Remove entitlement"
        confirmRemoveDialogContent="Are you sure you want to remove this entitlement?"
        header={header}
        withSeparator={withSeparator}
        isFocused={entitlementsState.isExpanded(entitlement.uuid)}
        hasErrors={hasErrors}
        ref={ref}
        hideCancel={hasSingleEntitlement && !entitlement.feature}
        permanent={permanent || !entitlement.isConfirmed}
        isConfirmed={entitlement.isConfirmed}
        isConfirmDisabled={hasErrorsWithoutIsConfirmed}
        hideConfirm={!entitlement.feature}
        onConfirm={onConfirm}
        additionalActionButtons={entitlement.feature && additionalActionButtons}
        confirmSectionSx={CONFIRM_SECTION_SX}>
        <GridFlex.Column>
          {drawFormFields(fields, formRenderProps, {
            labelWidth: '35%',
          })}
        </GridFlex.Column>
      </StepAccordionWithConfirm>
    );
  },
);
