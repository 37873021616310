import { findIndex } from 'lodash';
import { EventLogType } from '@stigg-types/apiTypes';
import { WebhookEventLogType } from './constants';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatEventLogTypeName = (str: string) => {
  return WebhookEventLogType[str as keyof typeof WebhookEventLogType];
};

export const findIndexOfEventTypeToRemove = (
  eventLogTypes: EventLogType[],
  eventLogTypeToRemove: EventLogType,
): number => {
  return findIndex(eventLogTypes, (eventLogType) => eventLogType === eventLogTypeToRemove);
};
