import { Grid, Divider, Text } from '@stigg-components';
import { t } from 'i18next';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import Loader from '../../../components/Loader';
import { RootState, useAppDispatch } from '../../../redux/store';
import { availableIntegrations, VendorIdentifier } from '../constants';
import { fetchIntegrationsAction } from '../integrationsSlice';
import { IntegrationCard } from './IntegrationCard';
import { useSlackIntegration } from './slack/useSlackIntegration';

function IntegrationsPage() {
  const dispatch = useAppDispatch();
  const integrations = useSelector((state: RootState) => state.integrations.integrations);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoading);
  const [isLoadingSlackIntegration, slackIntegration] = useSlackIntegration();
  const {
    slackIntegration: slackIntegrationEnabled,
    stripeSecretBilling: stripeSecretBillingEnabled,
    auth0Integration: auth0IntegrationEnabled,
    openFgaIntegration: openFgaIntegrationEnabled,
  } = useFlags<FeatureFlags>();

  const [activeIntegrations, disabledIntegrations] = partition(
    availableIntegrations({
      slackIntegrationEnabled,
      stripeSecretBillingEnabled,
      auth0IntegrationEnabled,
      openFgaIntegrationEnabled,
    }),
    ({ isDisabled, vendorIdentifier }) => {
      if (isDisabled) {
        return false;
      }

      if (slackIntegration && vendorIdentifier === VendorIdentifier.Slack) {
        return true;
      }

      return integrations.some((integration) => integration.vendorIdentifier === vendorIdentifier);
    },
  );

  const hasActiveIntegrations = activeIntegrations.length > 0;

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchIntegrationsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  if (isLoading || !currentEnvironmentId || isLoadingSlackIntegration) {
    return <Loader />;
  }

  return (
    <>
      <Grid flexDirection="column" container spacing={12}>
        <Grid item xs={6}>
          <Text.H1 gutterBottom display="inline">
            {t('integrations.title')}
          </Text.H1>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container columnSpacing={6} rowSpacing={6}>
        {hasActiveIntegrations ? (
          <>
            <Grid item>
              <Text.H3>{t('integrations.activeIntegrations')}</Text.H3>
            </Grid>
            <Grid item container columnSpacing={4} rowSpacing={6}>
              {activeIntegrations.map((availableIntegration) => (
                <Grid key={availableIntegration.vendorIdentifier} item xs={12} md={6} lg={6} xl={4}>
                  <IntegrationCard
                    availableIntegration={availableIntegration}
                    integration={integrations.find((x) => x.vendorIdentifier === availableIntegration.vendorIdentifier)}
                    webhookIntegration={slackIntegration}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
        {hasActiveIntegrations && (
          <Grid item>
            <Text.H3>{t('integrations.additionalIntegrations')}</Text.H3>
          </Grid>
        )}
        <Grid item container columnSpacing={4} rowSpacing={6}>
          {sortBy(disabledIntegrations, (x) => !!x.isComingSoon).map((availableIntegration) =>
            !availableIntegration.hidden ? (
              <Grid key={availableIntegration.vendorIdentifier} item xs={12} md={6} lg={6} xl={4}>
                <IntegrationCard availableIntegration={availableIntegration} />
              </Grid>
            ) : null,
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default IntegrationsPage;
