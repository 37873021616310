import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { BillingModel } from '@stigg-types/apiTypes';
import {
  getBillingCadence,
  getGroupBillingModel,
  getGroupFeature,
  getMaxUnitQuantity,
  getMinUnitQuantity,
  PriceGroup,
} from '../../../pricing/utils/priceGroups';
import { calculateUnitQuantityText } from './calculateUnitQuantity';
import { toTiersSchema } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.initialValues';
import { getBillingModelDescription } from '../../../pricing/utils/priceFormatUtils';

function GroupMinMaxQuantity({ priceGroup }: { priceGroup: PriceGroup }) {
  const minQuantity = getMinUnitQuantity(priceGroup);
  const maxQuantity = getMaxUnitQuantity(priceGroup);
  const shouldRenderQuantityLimitations =
    getGroupBillingModel(priceGroup) === BillingModel.PerUnit && (maxQuantity || (minQuantity && minQuantity > 1));

  return shouldRenderQuantityLimitations ? (
    <Text.B2 color="secondary">
      {calculateUnitQuantityText(
        minQuantity,
        maxQuantity,
        getGroupFeature(priceGroup)?.featureUnitsPlural,
        getGroupFeature(priceGroup)?.displayName,
      )}
    </Text.B2>
  ) : priceGroup.some((price) => price.billingModel === BillingModel.FlatFee) ? (
    <Text.B2 color="secondary">{t('pricing.chargeType.description.flatRate')}</Text.B2>
  ) : null;
}

function InAdvanceCommitment({ priceGroup }: { priceGroup: PriceGroup }) {
  return priceGroup[0].tiers && priceGroup[0].tiersMode ? (
    <Text.B2 color="secondary">
      {t(`pricing.tiersSchema.${toTiersSchema(priceGroup[0].tiersMode, priceGroup[0].tiers)}`)}
    </Text.B2>
  ) : null;
}

const PackagePricing = ({ priceGroup }: { priceGroup: PriceGroup }) => {
  const isPackagePricing = priceGroup.some((price) => price.blockSize && price.blockSize > 1);
  return (
    <>
      <Text.B2 color="secondary">
        {isPackagePricing ? t('pricing.chargeType.description.packagePricing') : null}
      </Text.B2>
    </>
  );
};

function ChargeTypeCell({ priceGroup }: { priceGroup: PriceGroup }) {
  return (
    <GridFlex.Column item>
      <Text.B2>{getBillingModelDescription(getGroupBillingModel(priceGroup), getBillingCadence(priceGroup))}</Text.B2>
      <GroupMinMaxQuantity priceGroup={priceGroup} />
      <InAdvanceCommitment priceGroup={priceGroup} />
      <PackagePricing priceGroup={priceGroup} />
    </GridFlex.Column>
  );
}

export default ChargeTypeCell;
