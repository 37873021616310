import { Timer } from '@mui/icons-material';
import { Link, Text, Grid, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import moment from 'moment';
import { TaskType, ImportIntegrationTaskResultFragment, TaskStatus } from '@stigg-types/apiTypes';
import * as S from './TasksList.style';
import { TaskStatusCard } from './TaskStatusCard';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { Navigation, useNavigation } from '../../../../../modules/navigation/useNavigation';

function SuccessImportTask({ task }: { task: ImportIntegrationTaskResultFragment }) {
  const taskMessage =
    task.taskType === TaskType.ImportIntegrationCatalog
      ? t('integrations.productsImportedSuccessfullyMessage', { count: task.productsCount })
      : t('integrations.customersImportedSuccessfullyMessage', { count: task.customersCount });

  return (
    <S.TaskBox flexDirection="row" $status="success" container wrap="nowrap">
      <Grid item>
        <S.CheckCircle color="#4caf50" size={20} data-testid="check-circle-svg-import-success" />
      </Grid>
      <Grid container item ml={2}>
        <Text.B2>{taskMessage}</Text.B2>
        <Grid item>
          <Text.B2 color="secondary">{moment(task.createdAt).fromNow()}</Text.B2>
        </Grid>
      </Grid>
    </S.TaskBox>
  );
}

function PendingImportTask({ task }: { task: ImportIntegrationTaskResultFragment }) {
  const taskMessage =
    task.taskType === TaskType.ImportIntegrationCatalog
      ? t('integrations.importProductsTaskOngoingMessage')
      : t('integrations.importCustomersTaskOngoingMessage');

  return (
    <S.TaskBox $status="pending" container flexWrap="wrap">
      <Text.B2>{taskMessage}</Text.B2>
      <GridFlex.RowCenter item container mt={2}>
        <Timer sx={{ mr: 2, color: '#808EB5' }} />
        <Text.B2 color="secondary">{t('navbar.pending')}</Text.B2>
      </GridFlex.RowCenter>
    </S.TaskBox>
  );
}

function InProgressTask({ task, navigation }: { task: ImportIntegrationTaskResultFragment; navigation: Navigation }) {
  const taskMessage =
    task.taskType === TaskType.ImportIntegrationCatalog
      ? t('integrations.importProductsTaskOngoingMessage')
      : t('integrations.importCustomersTaskOngoingMessage');

  return (
    <S.TaskBox $status="inProgress" container>
      <Link variant="body2" onClick={() => navigation.navigateTo('/integrations/stripe')}>
        <Text.B2>{taskMessage}</Text.B2>
      </Link>
      <Grid item mt={1} mb={2}>
        <Text.B2 color="secondary">{moment(task.createdAt).fromNow()}</Text.B2>
      </Grid>
      <Grid item>
        <LinearProgressWithLabel value={task.progress} />
      </Grid>
    </S.TaskBox>
  );
}

function FailedTask({ task }: { task: ImportIntegrationTaskResultFragment }) {
  let message = '';
  if (task.taskType === TaskType.ImportIntegrationCustomers) {
    message =
      task.status === TaskStatus.Failed
        ? t('integrations.importCustomersTaskFailedMessage')
        : t('integrations.importCustomersTaskPartiallyFailedMessage', {
            failedCount: task.importErrors.length,
            totalCount: task.totalSubtasksCount,
          });
  } else {
    message =
      task.status === TaskStatus.Failed
        ? t('integrations.importProductsTaskFailedMessage')
        : t('integrations.importProductsTaskPartiallyFailedMessage', {
            failedCount: task.importErrors.length,
            totalCount: task.totalSubtasksCount,
          });
  }
  return (
    <S.TaskBox $status="error" container>
      <Text.B2>{message}</Text.B2>
      <Grid item container alignItems="center" display="flex" mt={2}>
        <S.ErrorOutline sx={{ mr: 2 }} />
        <Text.B2 color="error.content">
          {t('navbar.failed')} {moment(task.createdAt).fromNow()}
        </Text.B2>
      </Grid>
    </S.TaskBox>
  );
}

export function ImportTask({ task }: { task: ImportIntegrationTaskResultFragment }) {
  const navigation = useNavigation();
  return (
    <TaskStatusCard
      taskStatus={task.status}
      onComplete={<SuccessImportTask task={task} />}
      onPending={<PendingImportTask task={task} />}
      onInProgress={<InProgressTask navigation={navigation} task={task} />}
      onFailure={<FailedTask task={task} />}
    />
  );
}
