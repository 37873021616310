import { t } from 'i18next';
import { Feature, PackageEntitlement, Plan } from '@stigg-types/apiTypes';
import { Theme } from '@mui/material/styles';
import '../../../../common/chartjs/chartjs.plugins';
import { ResultSet, Series, TimeDimensionGranularity } from '@cubejs-client/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { capitalize } from 'lodash';
import { dateFormatter, numberFormatter } from '../../../../common/formatters';
import {
  getAxisColor,
  getAxisTickColor,
  getAxisTickFont,
  getAxisTitleColor,
  getAxisTitleFont,
  AXIS_TITLE_SPACING,
  GRID_BORDER_DASH,
  getLabelColor,
  getLabelFont,
  opacityColor,
} from '../../../../common/chartjs/chartjs.theme';
import { ExternalTooltipHandler } from '../../../../common/chartjs/chartjs.tooltip.plugin';

function getResultsForPlan(resultSet: ResultSet, plan: Plan) {
  return resultSet.series().find((series) => series.title.split(',')[0] === plan.refId);
}

function hasData(resultsForPlan: Series<any>): boolean {
  return resultsForPlan.series.some((item) => Boolean(item.value));
}

export function computeChartData(
  resultSet: ResultSet | null,
  feature: Feature,
  plan: Plan,
  baseTime: Moment | undefined,
  granularity: TimeDimensionGranularity | undefined,
  color: string,
  entitlement: PackageEntitlement,
  tooltipHandler: ExternalTooltipHandler<'line'>,
  theme: Theme,
) {
  if (!resultSet) {
    return {};
  }

  const resultsForPlan = getResultsForPlan(resultSet, plan);
  if (!resultsForPlan || !hasData(resultsForPlan)) {
    return {};
  }

  const labels = resultSet
    .categories()
    .filter((category) => !baseTime || moment.utc(category.x).isSameOrAfter(baseTime))
    .map((category) => {
      if (baseTime && granularity) {
        const diff = moment.utc(category.x).diff(baseTime, granularity);
        return diff === 0 ? t('dashboards.featureInspector.periodStart') : `${capitalize(granularity)} ${diff}`;
      }
      return dateFormatter(category.x);
    });
  const dataset = {
    label: t('sharedComponents.planSuffix', {
      planName: plan.displayName,
    }),
    data: resultsForPlan.series
      .filter((category) => !baseTime || moment.utc(category.x).isSameOrAfter(baseTime))
      .map((item) => item.value),
    borderColor: color,
    tension: 0,
    fill: { value: 0 },
    gradient: {
      backgroundColor: {
        axis: 'y' as const,
        colors: {
          0: opacityColor(theme.itamar.palette.white, 0.3),
          100: opacityColor(color, 0.3),
        },
      },
    },
  };

  // if there is reset period, then the graph values must increase as
  // we are looking at the same period, so if we see decrease to 0 in value,
  // it means that it's from the next period, so we exclude it.
  if (entitlement.resetPeriod) {
    for (let i = 1; i < dataset.data.length; i += 1) {
      if (dataset.data[i] === 0 && dataset.data[i] < dataset.data[i - 1]) {
        dataset.data.splice(i);
        labels.splice(i);
        break;
      }
    }
  }

  const data: ChartData<'line'> = {
    labels,
    datasets: [dataset],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        align: 'start',
        position: 'bottom',
        onClick: () => {}, // disable hide
        labels: {
          font: getLabelFont(),
          color: getLabelColor(theme),
          boxWidth: 20,
          boxHeight: 1,
        },
      },
      tooltip: {
        enabled: false,
        external: tooltipHandler,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: getAxisColor(theme),
        },
        ticks: {
          maxTicksLimit: 10,
          font: getAxisTickFont(),
          color: getAxisTickColor(theme),
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { top: AXIS_TITLE_SPACING },
          text: t('dashboards.featureInspector.xAxisTime'),
        },
      },
      y: {
        ticks: {
          callback: (value) => numberFormatter(value as number),
          maxTicksLimit: 5,
          precision: 0,
          font: getAxisTickFont(),
          color: getAxisTickColor(theme),
        },
        grid: {
          borderDash: GRID_BORDER_DASH,
          color: getAxisColor(theme),
        },
        title: {
          display: true,
          font: getAxisTitleFont(),
          color: getAxisTitleColor(theme),
          padding: { bottom: AXIS_TITLE_SPACING },
          text: capitalize(feature.featureUnitsPlural as string),
        },
      },
    },
  };

  return { data, options };
}
