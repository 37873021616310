import { createSlice } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import { FeatureConnection, FeatureFragment, SlimPackageFragment } from '@stigg-types/apiTypes';
import { updateItemById } from '@stigg-common';
import {
  fetchEventsFields,
  fetchFeatures,
  fetchFeatureByRefId,
  fetchUsageEvents,
  fetchAggregatedUsageByCustomer,
} from './queries';
import { deleteFeatureById, createFeature, updateFeature } from './mutations';
import { archiveFeatureById } from './mutations/archiveFeatureById';
import { fetchFeatureAssociatedLatestPackages } from './queries/fetchFeatureAssicatedLatestPackages';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface FeaturesState {
  isLoading: boolean;
  isLoadingFeature: boolean;
  isLoadingFeatureAssociatedLatestPackages: boolean;
  features: FeatureConnection;
  feature: FeatureFragment | null;
  newlyCreatedFeatureId?: string;
  featureAssociatedLatestPackages: SlimPackageFragment[] | undefined;
}

const initialState: FeaturesState = {
  isLoading: false,
  features: {} as FeatureConnection,
  feature: null,
  isLoadingFeature: false,
  isLoadingFeatureAssociatedLatestPackages: false,
  featureAssociatedLatestPackages: undefined,
};

const fetchAggregatedUsageByCustomerAction = createAppAsyncThunk(
  'fetchAggregatedUsageByCustomer',
  fetchAggregatedUsageByCustomer,
);
const fetchEventsFieldsAction = createAppAsyncThunk('fetchEventsFields', fetchEventsFields);
const fetchUsageEventsAction = createAppAsyncThunk('fetchUsageEvents', fetchUsageEvents);
const fetchFeaturesAction = createAppAsyncThunk('fetchFeatures', fetchFeatures);
const createFeatureAction = createAppAsyncThunk('createFeature', createFeature);
const fetchFeatureByRefIdAction = createAppAsyncThunk('fetchFeatureById', fetchFeatureByRefId);

const fetchFeatureAssociatedLatestPackagesAction = createAppAsyncThunk(
  'fetchFeatureAssociatedLatestPackages',
  fetchFeatureAssociatedLatestPackages,
);
const deleteFeatureByIdAction = createAppAsyncThunk('deleteFeatureById', deleteFeatureById);
const archiveFeatureByIdAction = createAppAsyncThunk('archiveFeatureById', archiveFeatureById);
const updateFeatureAction = createAppAsyncThunk('updateFeature', updateFeature);

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    resetSelectedFeature: (state) => {
      state.feature = null;
      state.featureAssociatedLatestPackages = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturesAction.pending, (state, { meta }) => {
        if (!meta?.arg?.silentFetch) {
          state.isLoading = true;
        }
      })
      .addCase(fetchFeaturesAction.fulfilled, (state, action) => {
        state.features = action.payload as FeatureConnection;
        state.isLoading = false;
      })
      .addCase(fetchFeaturesAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(createFeatureAction.fulfilled, (state, { payload }) => {
      state.newlyCreatedFeatureId = payload?.id;
    });

    builder
      .addCase(fetchFeatureByRefIdAction.pending, (state) => {
        state.isLoadingFeature = true;
      })
      .addCase(fetchFeatureByRefIdAction.fulfilled, (state, { payload }) => {
        state.feature = payload;
        state.isLoadingFeature = false;
      })
      .addCase(fetchFeatureByRefIdAction.rejected, (state) => {
        state.isLoadingFeature = false;
      });
    builder
      .addCase(fetchFeatureAssociatedLatestPackagesAction.pending, (state) => {
        state.isLoadingFeatureAssociatedLatestPackages = true;
      })
      .addCase(fetchFeatureAssociatedLatestPackagesAction.fulfilled, (state, { payload }) => {
        state.featureAssociatedLatestPackages = payload;
        state.isLoadingFeatureAssociatedLatestPackages = false;
      })
      .addCase(fetchFeatureAssociatedLatestPackagesAction.rejected, (state) => {
        state.isLoadingFeatureAssociatedLatestPackages = false;
      });

    builder.addCase(updateFeatureAction.fulfilled, (state, { payload }) => {
      state.feature = payload.updatedFeature;
      updateItemById(state.features, state.feature, false);
    });

    builder.addCase(deleteFeatureByIdAction.fulfilled, (state, { payload }) => {
      remove(state.features.edges, (feature) => feature.node.refId === payload?.id);
    });

    builder.addCase(archiveFeatureByIdAction.fulfilled, (state, { payload }) => {
      remove(state.features.edges, (feature) => feature.node.refId === payload?.id);
    });
  },
});

const { resetSelectedFeature } = featuresSlice.actions;
export {
  fetchFeaturesAction,
  createFeatureAction,
  fetchFeatureByRefIdAction,
  deleteFeatureByIdAction,
  archiveFeatureByIdAction,
  updateFeatureAction,
  fetchUsageEventsAction,
  fetchAggregatedUsageByCustomerAction,
  fetchEventsFieldsAction,
  fetchFeatureAssociatedLatestPackagesAction,
  resetSelectedFeature,
};

export default featuresSlice.reducer;
