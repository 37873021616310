import { t } from 'i18next';
import { Divider } from '@stigg-components';
import { PreviewNextInvoiceFragment } from '@stigg-types/apiTypes';
import { ChargeRow } from './ChargeRow';

type CreditsSectionProps = Pick<PreviewNextInvoiceFragment, 'credits'>;

export function CreditsSection({ credits }: CreditsSectionProps) {
  if (!credits || credits?.used?.amount === 0) {
    return null;
  }

  return (
    <>
      <Divider mt={4} />
      <ChargeRow
        description={t('subscriptions.breakdown.appliedCredits')}
        charge={{ ...credits.used, amount: -1 * credits.used.amount }}
        sx={{ mt: 4 }}
      />
      <Divider mt={4} />
    </>
  );
}
