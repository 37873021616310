import { t } from 'i18next';
import { UsageEventFragment, AggregatedUsageByCustomerFragment } from '@stigg-types/apiTypes';
import styled, { css } from 'styled-components/macro';
import { Box, InformationTooltip, Button, Icon, GridFlex, Text } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import Loader from '../../../../components/Loader';
import { EventsNotFound } from './EventsNotFound';
import { AggregatedUsageByCustomerPreviewTable } from './AggregatedUsageByCustomerPreviewTable';
import { UsageEventsPreviewTable } from './UsageEventsPreviewTable';

const PreviewSidenavContainer = styled(GridFlex.Column)<{ $isPreviewExpanded: boolean }>`
  width: 35%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  background: ${({ theme }) => theme.itamar.palette.grey[50]};
  transition: width 0.3s ease-in-out;

  ${({ $isPreviewExpanded }) =>
    $isPreviewExpanded &&
    css`
      width: 75%;
    `}
`;

export function MeterPreviewSidenav({
  isPreviewExpanded,
  isPreviewLoading,
  togglePreview,
  usageEvents,
  aggregatedUsageByCustomer,
  activeStep,
  isRefreshDisabled,
  onRefresh,
  withFiltersMode,
}: {
  isPreviewExpanded: boolean;
  isPreviewLoading: boolean;
  withFiltersMode: boolean;
  togglePreview: () => void;
  onRefresh: () => void;
  isRefreshDisabled: boolean;
  activeStep: number;
  usageEvents: UsageEventFragment[] | null;
  aggregatedUsageByCustomer: AggregatedUsageByCustomerFragment[] | null;
}) {
  const isFiltersStep = activeStep === 0;
  const isAggregationStep = activeStep === 1;
  const renderContent = () => {
    if (isPreviewLoading) {
      return (
        <GridFlex.Column $fullHeight alignItems="center" justifyContent="center">
          <Loader isInline />
        </GridFlex.Column>
      );
    }

    if (isFiltersStep) {
      return isEmpty(usageEvents) ? (
        <EventsNotFound withFiltersMode={withFiltersMode} />
      ) : (
        <UsageEventsPreviewTable usageEvents={usageEvents!} />
      );
    }

    if (isAggregationStep) {
      return isEmpty(aggregatedUsageByCustomer) ? (
        <EventsNotFound withFiltersMode />
      ) : (
        <AggregatedUsageByCustomerPreviewTable aggregatedUsageByCustomer={aggregatedUsageByCustomer} />
      );
    }

    return null;
  };

  return (
    <PreviewSidenavContainer $fullHeight $isPreviewExpanded={isPreviewExpanded}>
      <GridFlex.RowCenter p={8} mb={6} justifyContent="space-between">
        <GridFlex.RowCenter columnGap={4} onClick={togglePreview} sx={{ cursor: 'pointer' }}>
          <Icon type="reactFeather" icon={isPreviewExpanded ? 'ChevronsRight' : 'ChevronsLeft'} color="active" />
          <Text.H6>{t('features.showEventsPreview')}</Text.H6>
        </GridFlex.RowCenter>
        <InformationTooltip
          arrow
          $maxWidth={250}
          placement="left"
          title={isRefreshDisabled ? <Text.B2>{t('features.refreshNotAllowed')}</Text.B2> : ''}>
          <Box sx={{ cursor: isRefreshDisabled ? 'not-allowed' : 'pointer' }}>
            <Button
              disabled={isRefreshDisabled}
              onClick={onRefresh}
              variant="outlined"
              startIcon={<Icon type="materialIcons" icon="Refresh" />}>
              {t('features.refreshEventsPreview')}
            </Button>
          </Box>
        </InformationTooltip>
      </GridFlex.RowCenter>

      {renderContent()}
    </PreviewSidenavContainer>
  );
}
