import { t } from 'i18next';
import { EmptyContent, Text, Link, PaymentDetails } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';

export type CustomerPaymentMethodProps = {
  customer: CustomerResponseFragment;
  onAddPaymentMethodClick: () => void;
};

export function CustomerPaymentMethod({ customer, onAddPaymentMethodClick }: CustomerPaymentMethodProps) {
  const {
    defaultPaymentMethodId,
    defaultPaymentMethodType,
    defaultPaymentMethodLast4Digits,
    defaultPaymentExpirationMonth,
    defaultPaymentExpirationYear,
  } = customer;

  if (!defaultPaymentMethodId) {
    return (
      <EmptyContent $padding={2}>
        <Text.B2 mr={2}>{t('customers.paymentMethod.noValue')}</Text.B2>
        <Link onClick={onAddPaymentMethodClick}>{t('customers.paymentMethod.addPaymentMethod')}</Link>
      </EmptyContent>
    );
  }

  return (
    <PaymentDetails
      paymentMethodType={defaultPaymentMethodType}
      last4Digits={defaultPaymentMethodLast4Digits}
      expirationMonth={defaultPaymentExpirationMonth}
      expirationYear={defaultPaymentExpirationYear}
    />
  );
}
