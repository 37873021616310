import { gql } from '@apollo/client';
import { t } from 'i18next';
import { FetchEnvironmentsQuery, Environment, FetchEnvironmentsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const ENVIRONMENT_FRAGMENT = gql`
  fragment EnvironmentFragment on Environment {
    id
    displayName
    description
    type
    apiKeys {
      id
      keyType
      token
    }
    slug
    account {
      id
      displayName
    }
    hardenClientAccessEnabled
    signingToken
    isSandbox
    color
  }
`;

const FETCH_ENVIRONMENTS = gql`
  query FetchEnvironments($environmentSlug: String!) {
    focusedEnvironment: environments(
      paging: { first: 1 }
      filter: { slug: { eq: $environmentSlug }, permanentDeletionDate: { is: null } }
    ) {
      edges {
        node {
          ...EnvironmentFragment
        }
      }
    }
    otherEnvironments: environments(
      # MAX_OTHER_ENVIRONMENTS
      paging: { first: 16 }
      filter: { slug: { neq: $environmentSlug }, permanentDeletionDate: { is: null } }
    ) {
      edges {
        node {
          ...EnvironmentFragment
        }
      }
    }
  }
  ${ENVIRONMENT_FRAGMENT}
`;

type FetchEnvironmentsProps = {
  environmentSlug?: string;
  silentFetch?: boolean;
};

async function fetchEnvironments(
  { environmentSlug = '' }: FetchEnvironmentsProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Environment[]> {
  return executeOperationSafely(
    async () => {
      // if no environment slug is provided, use the redux state for focused environment
      if (!environmentSlug) {
        const { accountReducer } = getState();
        const { currentEnvironmentId, environments } = accountReducer;
        environmentSlug = environments.find((env) => env.id === currentEnvironmentId)?.slug || '';
      }

      const response = await apolloClient.query<FetchEnvironmentsQuery, FetchEnvironmentsQueryVariables>({
        query: FETCH_ENVIRONMENTS,
        fetchPolicy: 'network-only',
        variables: {
          environmentSlug,
        },
      });

      const { focusedEnvironment, otherEnvironments } = response.data;
      return [focusedEnvironment, otherEnvironments].flatMap(({ edges }) => edges).map(({ node }) => node);
    },
    {
      failureMessageHandler: () => t('accounts.api.failFetchEnv'),
    },
    dispatch,
  );
}

export { fetchEnvironments };
