import { t } from 'i18next';
import { useState } from 'react';
import { CollapsableSection } from '@stigg-components';
import { WidgetConfig } from '../../hooks/useWidgetConfiguration';
import { WidgetIdentifier } from '../../useWidgetsData';
import { CustomerPortalColorsTheme } from './CustomerPortalColorsTheme';
import { PaywallColorsStyling } from './PaywallColorsTheme';
import { CheckoutColorsTheme } from './CheckoutColorsTheme';

function WidgetThemeSection({
  widgetIdentifier,
  widgetConfig,
}: {
  widgetIdentifier: WidgetIdentifier;
  widgetConfig: WidgetConfig;
}) {
  switch (widgetIdentifier) {
    case WidgetIdentifier.Paywall:
      return <PaywallColorsStyling widgetConfig={widgetConfig} />;
    case WidgetIdentifier.CustomerPortal:
      return <CustomerPortalColorsTheme widgetConfig={widgetConfig} />;
    case WidgetIdentifier.Checkout:
      return <CheckoutColorsTheme widgetConfig={widgetConfig} />;
    default:
      throw new Error('Unknown widget identifier');
  }
}

export function ColorsThemeSection({
  widgetIdentifier,
  widgetConfig,
}: {
  widgetIdentifier: WidgetIdentifier;
  widgetConfig: WidgetConfig;
}) {
  const [isColorsSectionOpen, setIsColorsSectionOpen] = useState(true);

  return (
    <CollapsableSection
      title={t('widgets.colorsSectionTitle')}
      sx={{
        color: (theme) => theme.itamar.palette.text.primary,
      }}
      $fullWidth
      isSectionOpen={isColorsSectionOpen}
      onClick={() => setIsColorsSectionOpen(!isColorsSectionOpen)}
      content={<WidgetThemeSection widgetIdentifier={widgetIdentifier} widgetConfig={widgetConfig} />}
    />
  );
}
