import { t } from 'i18next';
import { CustomerUpdateForm, UpdateCustomerFormFields } from './CustomerUpdateForm';
import { Dialog } from '../../../../components/Dialog';

type CustomerUpdateDialogProps = {
  customer: UpdateCustomerFormFields;
  onUpdate: (onUpdate: UpdateCustomerFormFields) => void;
  setOpen: (isOpen: boolean) => void;
  open: boolean;
  editableFields?: Array<keyof UpdateCustomerFormFields>;
};

export function CustomerUpdateDialog({ customer, onUpdate, open, setOpen, editableFields }: CustomerUpdateDialogProps) {
  return (
    <Dialog
      content={
        <CustomerUpdateForm
          onUpdate={onUpdate}
          onCancel={() => setOpen(false)}
          customer={customer}
          editableFields={editableFields}
        />
      }
      titleText={t('customers.editDialogTitle')}
      isOpen={open}
      onCancel={() => setOpen(false)}
      aria-labelledby="edit-customer-dialog"
    />
  );
}
