import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQueryParam } from '@stigg-common';
import { CustomerPageSubscriptionsTabs } from '../../../../../navigation/tabs';

type UseSubscriptionTabSelectorProps = {
  isLoaded: boolean;
  hasSubscriptions: boolean;
  hasDelegatedSubscriptions: boolean;
};

export const useSubscriptionTabSelector = ({
  isLoaded,
  hasSubscriptions,
  hasDelegatedSubscriptions,
}: UseSubscriptionTabSelectorProps) => {
  const { setQueryParam, value } = useQueryParam('tab');
  const currentTab = value as CustomerPageSubscriptionsTabs | null;

  useEffect(() => {
    if (isLoaded && isEmpty(value) && !hasSubscriptions && hasDelegatedSubscriptions) {
      setQueryParam(CustomerPageSubscriptionsTabs.DelegatedSubscriptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, hasSubscriptions, hasDelegatedSubscriptions]);

  return { currentTab };
};
