import { gql } from '@apollo/client';
import { t } from 'i18next';
import { MemberSortFields, SortDirection } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { Paging } from '../../../components/table/gqlTableHelper';

const ACCOUNT_MEMBERS_LIMIT = 50;

const FETCH_ACCOUNT_MEMBERS = gql`
  query FetchAccountMembers($paging: CursorPaging, $sorting: [MemberSort!], $filter: MemberFilter) {
    members(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          memberStatus
          email
          user {
            name
            lastSeenAt
            profileImageUrl
          }
        }
        cursor
      }
    }
  }
`;

export type FetchMembersProps = {
  paging?: Paging;
  search?: string | null;
};

async function fetchAccountMembers(props: FetchMembersProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search } = props;
  if (search === '') {
    search = null;
  }

  const filter = search
    ? {
        or: [{ email: { iLike: `%${props.search}%` } }, { name: { iLike: `%${props.search}%` } }],
      }
    : {};

  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query({
        query: FETCH_ACCOUNT_MEMBERS,
        fetchPolicy: 'network-only',
        variables: {
          // todo: max size is 50 on the server, increase or fix pagination
          paging: { first: ACCOUNT_MEMBERS_LIMIT },
          sorting: { field: MemberSortFields.Id, direction: SortDirection.Asc },
          filter,
        },
      });

      return response.data.members;
    },
    {
      failureMessageHandler: () => t('accounts.api.failFetchMem'),
    },
    dispatch,
  );
}

export { fetchAccountMembers };
