import React from 'react';
import { Button, Grid, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { CollapsePageCard } from '../../../../../components/CollapsePageCard';

export function VariationCard({
  open,
  ratioCardOpen,
  setRatioCardOpen,
  noChangesInVariant,
  children,
}: {
  open: boolean;
  ratioCardOpen: boolean;
  setRatioCardOpen: () => void;
  noChangesInVariant: boolean;
  children: React.ReactNode;
}) {
  return (
    <CollapsePageCard
      cardProps={{ $withClosed: !open }}
      contentOpen={open}
      cardTitle={<Text.H6>{t('experiments.wizard.experimentVariationTitle')}</Text.H6>}>
      <GridFlex.Column container rowSpacing={10}>
        {children}
        {!ratioCardOpen && (
          <Grid item alignSelf="end">
            <Button variant="contained" $outlined onClick={setRatioCardOpen} disabled={noChangesInVariant}>
              {t('sharedComponents.nextButton')}
            </Button>
          </Grid>
        )}
      </GridFlex.Column>
    </CollapsePageCard>
  );
}
