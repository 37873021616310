import { useEffect, useRef, useState } from 'react';
import { ShouldHighlightRow } from '../Table.types';

const TRANSITION_TIME = 1000;

export const useHighlightRow = <TEntity>(
  item: TEntity,
  shouldHighlightRow: ShouldHighlightRow<TEntity> | undefined,
  delay: number,
): {
  isHighLight: boolean;
  isLongBackgroundTransition: boolean;
} => {
  const prevItem = useRef(item);
  const [isHighLight, setIsHighLight] = useState(false);
  const [isLongBackgroundTransition, setIsLongBackgroundTransition] = useState(false);

  useEffect(() => {
    if (!shouldHighlightRow) {
      return () => null;
    }

    let timeout: NodeJS.Timeout;

    if (shouldHighlightRow(item, prevItem.current)) {
      setIsLongBackgroundTransition(true);
      setIsHighLight(true);

      // first, after the given timeout update the prev value
      timeout = setTimeout(() => {
        setIsHighLight(false);
        prevItem.current = item;

        // second, forget the long background transition
        // this is needed, in order to have larger transition for un-highlight
        timeout = setTimeout(() => {
          setIsLongBackgroundTransition(false);
        }, TRANSITION_TIME);
      }, delay + TRANSITION_TIME);
    }

    return () => clearTimeout(timeout);
  }, [item, shouldHighlightRow, delay]);

  return { isHighLight, isLongBackgroundTransition };
};
