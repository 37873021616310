import { t } from 'i18next';
import {
  CancelSubscriptionUpdatesMutation,
  CancelSubscriptionUpdatesMutationVariables,
  ErrorCode,
  SubscriptionUpdateScheduleCancellationInput,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { fetchCustomerSubscriptionsAction, fetchSubscriptionByRefIdAction } from '../customersSlice';

const CANCEL_SUBSCRIPTION_UPDATES = gql`
  mutation cancelSubscriptionUpdates($input: SubscriptionUpdateScheduleCancellationInput!) {
    cancelSchedule(input: $input)
  }
`;

async function cancelSubscriptionUpdates(
  data: { subscriptionRefId: string; customerId: string; futureUpdateStatus: ActiveFutureUpdateStatus },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const input: SubscriptionUpdateScheduleCancellationInput = {
        environmentId: accountReducer.currentEnvironmentId,
        subscriptionId: data.subscriptionRefId,
        status: data.futureUpdateStatus,
      };
      await apolloClient.mutate<CancelSubscriptionUpdatesMutation, CancelSubscriptionUpdatesMutationVariables>({
        mutation: CANCEL_SUBSCRIPTION_UPDATES,
        variables: { input },
      });

      await dispatch(fetchCustomerSubscriptionsAction({ customerId: data.customerId }));
      await dispatch(fetchSubscriptionByRefIdAction({ refId: data.subscriptionRefId }));
    },
    {
      successMessage: t('subscriptions.api.successCancelFutureUpdate'),
      failureMessageHandler: () => t('subscriptions.api.cancelFutureUpdateFailed'),
      expectedErrorsMessage: {
        [ErrorCode.SubscriptionNotFound]: t('subscriptions.api.SubscriptionNotFound'),
      },
    },
    dispatch,
  );
}

export { cancelSubscriptionUpdates };
