import { useEffect, useState } from 'react';
import { CircularProgress, Box } from '@stigg-components';
import { encode } from 'querystring';
import { useSelector } from 'react-redux';
import { Environment, VendorIdentifier } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { createIntegrationAction } from '../../integrationsSlice';
import { useNavigation } from '../../../navigation/useNavigation';
import Loader from '../../../../components/Loader';
import config from '../../../../env.config';
import { useQueryParam } from '../../../common/useQueryParam';
import * as S from './HubspotConnect.style';

const HUBSPOT_AUTHORIZE_ENDPOINT_URL = 'https://app.hubspot.com/oauth/authorize';

const redirectToHubspotConnect = (currentEnvironment: Environment) => {
  const scopes = [
    'crm.objects.contacts.read',
    'crm.objects.contacts.write',
    'crm.objects.deals.read',
    'crm.objects.deals.write',
    'e-commerce', // this is for products, this requires our customer to have a paid hubspot account
    'crm.schemas.contacts.read',
    'crm.schemas.contacts.write',
    'crm.import',
    'crm.objects.companies.read',
    'crm.objects.companies.write',
    'crm.objects.line_items.write',
    'crm.objects.line_items.read',
  ];
  const clientId = config.hubspotClientId;
  const redirectUri = `${window.location.origin}/redirects/hubspot`;

  const state = JSON.stringify({ environmentSlug: currentEnvironment.slug });

  const authUrlParams = encode({
    client_id: clientId,
    scope: scopes.join(' '),
    redirect_uri: redirectUri,
    state,
  });
  const authUrl = `${HUBSPOT_AUTHORIZE_ENDPOINT_URL}?${authUrlParams}`;
  window.location.href = authUrl;
};

export function HubspotConnect() {
  const [isConnecting, setIsConnecting] = useState(false);

  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironment = environments.find((x) => x.id === currentEnvironmentId)!;
  const createLoading = useSelector((state: RootState) => state.integrations.createLoading);
  const updateLoading = useSelector((state: RootState) => state.integrations.updateLoading);

  const { value: authorizationCodeQueryParam } = useQueryParam('code');

  const createIntegration = async () => {
    const integrationData = {
      vendorIdentifier: VendorIdentifier.Hubspot,
      hubspotCredentials: {
        authorizationCode: authorizationCodeQueryParam!,
      },
    };
    await dispatch(createIntegrationAction(integrationData));
  };

  useEffect(() => {
    if (authorizationCodeQueryParam) {
      void createIntegration();
      // remove the authorization code from URL
      navigation.navigateTo(`/integrations/${VendorIdentifier.Hubspot.toLowerCase()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationCodeQueryParam]);

  const connectHubspot = () => {
    setIsConnecting(true);
    redirectToHubspotConnect(currentEnvironment);
  };

  return createLoading || updateLoading ? (
    <S.StyledAlert icon={false} severity="info" mt={4}>
      <Loader size={20} text="Connecting to your Hubspot account..." />
    </S.StyledAlert>
  ) : (
    <Box>
      <S.ConnectButton
        color="hubspot"
        variant="contained"
        size="medium"
        onClick={connectHubspot}
        disabled={isConnecting}>
        {isConnecting ? <CircularProgress size={20} color="inherit" /> : <>Connect with HubSpot</>}
      </S.ConnectButton>
    </Box>
  );
}
