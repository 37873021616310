import React from 'react';
import { t } from 'i18next';
import { Box, ExternalLink, Field, Form, Text, WizardAccordionStep, ClipboardSQL } from '@stigg-components';
import { SnowflakeCredentialsInput } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import { CREATE_NETWORK_POLICY_SQL, SETUP_SQL, SHOW_POLICIES_SQL } from './constants';

type UseWizardStepsProps = {
  hasIntegration: boolean;
  isLoading: boolean;
  onAddIntegration: (credentials: SnowflakeCredentialsInput) => Promise<void>;
  credentials: SnowflakeCredentialsInput;
};

export const useSnowflakeIntegrationWizardSteps = ({
  hasIntegration,
  isLoading,
  onAddIntegration,
  credentials,
}: UseWizardStepsProps): WizardAccordionStep[] => {
  return [
    {
      id: 'setup-script',
      title: t('integrations.snowflake.steps.setupScript.title'),
      content: (
        <Box>
          <Text.B2 marginBottom={6}>
            {t('integrations.snowflake.steps.setupScript.content')}
            <ExternalLink
              ml={1}
              label={t('sharedComponents.learnMore')}
              url={t('integrations.snowflake.steps.setupScript.learnMoreUrl')}
            />
          </Text.B2>
          <ClipboardSQL title={t('integrations.snowflake.steps.setupScript.sql')} sql={SETUP_SQL} />
        </Box>
      ),
      maxWidth: 600,
    },
    {
      id: 'network-policy',
      title: t('integrations.snowflake.steps.networkPolicy.title'),
      content: (
        <Box>
          <Text.B2 marginBottom={6}>{t('integrations.snowflake.steps.networkPolicy.policies')}</Text.B2>
          <ClipboardSQL title={t('integrations.snowflake.steps.networkPolicy.policiesSql')} sql={SHOW_POLICIES_SQL} />

          <Text.B2 marginBottom={6}>
            <Trans i18nKey="integrations.snowflake.steps.networkPolicy.createPolicy" />
          </Text.B2>

          <ClipboardSQL
            title={t('integrations.snowflake.steps.networkPolicy.createPolicySql')}
            sql={CREATE_NETWORK_POLICY_SQL}
          />
        </Box>
      ),
    },
    {
      id: 'setup-credentials',
      title: t('integrations.snowflake.steps.credentials.title'),
      content: () => {
        const validationSchema = () =>
          Yup.object().shape({
            host: Yup.string().required(t('integrations.snowflake.steps.credentials.form.host.required')),
            database: Yup.string().required(t('integrations.snowflake.steps.credentials.form.database.required')),
            password: Yup.string().required(t('integrations.snowflake.steps.credentials.form.password.required')),
            role: Yup.string().required(t('integrations.snowflake.steps.credentials.form.role.required')),
            schemaName: Yup.string().required(t('integrations.snowflake.steps.credentials.form.schemaName.required')),
            username: Yup.string().required(t('integrations.snowflake.steps.credentials.form.username.required')),
            warehouse: Yup.string().required(t('integrations.snowflake.steps.credentials.form.warehouse.required')),
          });

        return (
          <Box>
            <Text.B2 marginBottom={4}>{t('integrations.snowflake.steps.credentials.content')}</Text.B2>

            <Form<SnowflakeCredentialsInput>
              initialValues={credentials}
              validationSchema={validationSchema()}
              onSubmit={(values) => onAddIntegration(values)}
              disableSaveOnNoChanges
              submitButtonText={
                isLoading
                  ? t('integrations.snowflake.steps.credentials.form.connecting')
                  : !hasIntegration
                  ? t('integrations.snowflake.steps.credentials.form.connect')
                  : ''
              }
              hideSaveButton={hasIntegration}
              fields={(
                [
                  {
                    type: 'text',
                    id: 'host',
                    label: t('integrations.snowflake.steps.credentials.form.host.label'),
                    placeholder: t('integrations.snowflake.steps.credentials.form.host.placeholder'),
                    autoFocus: true,
                  },
                  {
                    type: 'text',
                    id: 'role',
                    label: t('integrations.snowflake.steps.credentials.form.role.label'),
                  },
                  {
                    type: 'text',
                    id: 'warehouse',
                    label: t('integrations.snowflake.steps.credentials.form.warehouse.label'),
                  },
                  {
                    type: 'text',
                    id: 'database',
                    label: t('integrations.snowflake.steps.credentials.form.database.label'),
                  },
                  {
                    type: 'text',
                    id: 'schemaName',
                    label: t('integrations.snowflake.steps.credentials.form.schemaName.label'),
                  },
                  {
                    type: 'text',
                    id: 'username',
                    label: t('integrations.snowflake.steps.credentials.form.username.label'),
                  },
                  {
                    type: 'text',
                    id: 'password',
                    label: t('integrations.snowflake.steps.credentials.form.password.label'),
                    textFieldType: 'password',
                  },
                ] as Field<SnowflakeCredentialsInput>[]
              ).map((field) => ({
                ...field,
                disabled: isLoading || hasIntegration,
              }))}
            />
          </Box>
        );
      },
    },
  ];
};
