import { t } from 'i18next';
import {
  EntitlementResetPeriod,
  MonthlyAccordingTo,
  WeeklyAccordingTo,
  YearlyAccordingTo,
} from '@stigg-types/apiTypes';

export type ResetPeriodValue = {
  text: string;
  value: string;
};

enum AdditionalResetPeriods {
  NO_RESET = 'noReset',
}

export const ENTITLEMENT_NO_RESET_VALUE = AdditionalResetPeriods.NO_RESET;

export const resetPeriods = (i18nPath: string, withNoResetOption?: boolean): ResetPeriodValue[] => {
  const allResetPeriods: ResetPeriodValue[] = [
    { text: t(`${i18nPath}.entitlementYearResetPeriod`), value: EntitlementResetPeriod.Year },
    { text: t(`${i18nPath}.entitlementMonthResetPeriod`), value: EntitlementResetPeriod.Month },
    { text: t(`${i18nPath}.entitlementWeekResetPeriod`), value: EntitlementResetPeriod.Week },
    { text: t(`${i18nPath}.entitlementDayResetPeriod`), value: EntitlementResetPeriod.Day },
    { text: t(`${i18nPath}.entitlementHourResetPeriod`), value: EntitlementResetPeriod.Hour },
  ];

  if (withNoResetOption) {
    allResetPeriods.push({ text: t(`${i18nPath}.entitlementNoResetPeriod`), value: ENTITLEMENT_NO_RESET_VALUE });
  }

  return allResetPeriods;
};

export const resetPeriodValueToText = (i18nPath: string, value: EntitlementResetPeriod) => {
  switch (value) {
    case EntitlementResetPeriod.Year:
      return t(`${i18nPath}.entitlementYearly`);
    case EntitlementResetPeriod.Month:
      return t(`${i18nPath}.entitlementMonthly`);
    case EntitlementResetPeriod.Week:
      return t(`${i18nPath}.entitlementWeekly`);
    case EntitlementResetPeriod.Day:
      return t(`${i18nPath}.entitlementDaily`);
    case EntitlementResetPeriod.Hour:
      return t(`${i18nPath}.entitlementHourly`);
    default:
      return '';
  }
};

const yearlyAccordingTo = (i18nPath: string) => [
  { text: t(`${i18nPath}.yearlyAccordingToSubscription`), value: YearlyAccordingTo.SubscriptionStart },
];

const monthlyAccordingTo = (i18nPath: string) => [
  { text: t(`${i18nPath}.monthlyAccordingToSubscription`), value: MonthlyAccordingTo.SubscriptionStart },
  { text: t(`${i18nPath}.monthlyAccordingToStartOfMonth`), value: MonthlyAccordingTo.StartOfTheMonth },
];

const weeklyAccordingTo = (i18nPath: string) => [
  { text: t(`${i18nPath}.weeklyAccordingToSubscription`), value: WeeklyAccordingTo.SubscriptionStart },
  { text: t(`${i18nPath}.weeklyAccordingToSunday`), value: WeeklyAccordingTo.EverySunday },
  { text: t(`${i18nPath}.weeklyAccordingToMonday`), value: WeeklyAccordingTo.EveryMonday },
  { text: t(`${i18nPath}.weeklyAccordingToTuesday`), value: WeeklyAccordingTo.EveryTuesday },
  { text: t(`${i18nPath}.weeklyAccordingToWednesday`), value: WeeklyAccordingTo.EveryWednesday },
  { text: t(`${i18nPath}.weeklyAccordingToThursday`), value: WeeklyAccordingTo.EveryThursday },
  { text: t(`${i18nPath}.weeklyAccordingToFriday`), value: WeeklyAccordingTo.EveryFriday },
  { text: t(`${i18nPath}.weeklyAccordingToSaturday`), value: WeeklyAccordingTo.EverySaturday },
];

export function getAccordingToOptions(resetPeriod: EntitlementResetPeriod, i18nPath: string) {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return yearlyAccordingTo(i18nPath);
    case EntitlementResetPeriod.Month:
      return monthlyAccordingTo(i18nPath);
    case EntitlementResetPeriod.Week:
      return weeklyAccordingTo(i18nPath);
    default:
      return null;
  }
}
