import { Card, Chip, Text, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import styled, { css } from 'styled-components/macro';
import { LongText } from '../../components/LongText';
import { WidgetProps } from './useWidgetsData';
import { useNavigation } from '../navigation/useNavigation';

const CardWrapper = styled(Card)<{ $isComingSoon?: boolean }>`
  padding: 32px;
  min-height: 230px;
  cursor: auto;

  ${({ $isComingSoon, theme }) =>
    $isComingSoon
      ? css`
          border-color: ${theme.itamar.palette.action.disabledBackground} !important;
          background-color: ${theme.itamar.palette.action.disabledBackground};
        `
      : css`
          cursor: pointer;
          &:hover {
            box-shadow: ${theme.itamar.palette.shadow.lightShadow};
          }
        `}
`;

export function WidgetCard({
  widget: { isComingSoon, widgetIdentifier, svg, name, description, enabled, docsUrl },
}: {
  widget: WidgetProps;
}) {
  const navigation = useNavigation();
  const onClick = () => {
    if (!isComingSoon)
      if (enabled) {
        navigation.navigateTo(`/widgets/${widgetIdentifier.toLowerCase()}`);
      } else {
        window.open(docsUrl, '_blank');
      }
  };
  return (
    <CardWrapper onClick={onClick} $isComingSoon={isComingSoon} sx={{ position: 'relative' }}>
      <GridFlex.Row justifyContent="flex-end" alignItems="flex-start" />
      <GridFlex.ColumnMiddle>
        {isComingSoon && (
          <Chip
            label={t('sharedComponents.comingSoon')}
            color="disabled"
            textColor="white"
            style={{ position: 'absolute', right: 16, top: 16 }}
          />
        )}
        {svg}
      </GridFlex.ColumnMiddle>
      <Text.H3 mb={2} mt={6}>
        {name}
      </Text.H3>
      <LongText variant="body2" color="secondary" maxLines={3} tooltipPlacement="bottom">
        {description}
      </LongText>
    </CardWrapper>
  );
}
