import { Text, Grid } from '@stigg-components';
import { t } from 'i18next';
import { BillingPeriod, BillingPeriodChangeVariables } from '@stigg-types/apiTypes';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../SubscriptionFutureUpdateRowContainer';

export function BillingPeriodUpdateRow({
  changeVariables,
  alertVariant,
}: {
  changeVariables: BillingPeriodChangeVariables;
  alertVariant?: boolean;
}) {
  const { billingPeriod: nextBillingPeriod } = changeVariables;
  const textColor = alertVariant ? 'warning.content' : 'primary';

  const changeType = nextBillingPeriod === BillingPeriod.Annually ? ChangeType.INCREASE : ChangeType.DECREASE;

  return (
    <SubscriptionFutureUpdateRowContainer changeType={changeType}>
      <Grid alignItems="baseline" gap={1}>
        <Text.B2 color={textColor}>
          {t('subscriptions.schedules.billingPeriod', {
            currentPeriod: nextBillingPeriod === BillingPeriod.Annually ? 'monthly' : 'annually',
          })}
        </Text.B2>
        <Text.B2 $bold color={textColor}>
          {nextBillingPeriod?.toLowerCase()}
        </Text.B2>
        <Text.B2 color={textColor}>{t('subscriptions.schedules.billingPeriodTo')}</Text.B2>
      </Grid>
    </SubscriptionFutureUpdateRowContainer>
  );
}
