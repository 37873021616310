import compact from 'lodash/compact';
import { EventLogFilter, EventLogType } from '@stigg-types/apiTypes';

export const fetchEventLogFilters = (
  environmentId: string,
  entityId?: string,
  parentEntityId?: string,
): EventLogFilter => {
  const environmentIdFilter = {
    environmentId: {
      eq: environmentId,
    },
  };

  const entityFilters = [
    entityId
      ? {
          ...environmentIdFilter,
          entityId: {
            eq: entityId,
          },
        }
      : null,
    parentEntityId
      ? {
          ...environmentIdFilter,
          parentEntityId: {
            eq: parentEntityId,
          },
        }
      : null,
  ];

  return {
    ...environmentIdFilter,
    eventLogType: {
      notIn: [EventLogType.MeasurementReported],
    },
    or: compact(entityFilters),
  };
};
