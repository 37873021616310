import React, { useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(null);

interface AnalyticsProviderProps {
  writeKey: string;
  children: React.ReactNode;
}
export const AnalyticsProvider = ({ writeKey, children }: AnalyticsProviderProps) => {
  const analyticsBrowser = useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);
  return <AnalyticsContext.Provider value={analyticsBrowser}>{children}</AnalyticsContext.Provider>;
};
