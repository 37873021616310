import { GridFlex, Icon, LongText, Text } from '@stigg-components';
import { PriceFeatureFragment } from '@stigg-types/apiTypes';
import { getFeatureTypeIcon } from '../../../../components/getFeatureTypeIcon';
import { getFeatureDisplayName } from '../../../features/utils/getFeatureDisplayName';

export type FeatureHeaderProps = {
  feature: PriceFeatureFragment;
  fallbackTitle?: string;
  bold?: boolean;
  hideSubtitle?: boolean;
  disableIcon?: boolean;
};

export function FeatureHeader({
  feature,
  fallbackTitle = '',
  bold,
  hideSubtitle,
  disableIcon = false,
}: FeatureHeaderProps) {
  const icon = feature ? (
    getFeatureTypeIcon(feature, { size: 24 })
  ) : (
    <Icon type="custom" icon="WaveSignal" color="active" overrideStroke />
  );
  const title = feature ? getFeatureDisplayName(feature, true) : fallbackTitle;
  const subtitle = feature?.refId;

  return (
    <GridFlex.RowCenter gap={3}>
      {!disableIcon && icon}
      <GridFlex.Column>
        <LongText color="primary" bold={bold}>
          {title}
        </LongText>
        {subtitle && !hideSubtitle && <Text.Caption>{subtitle}</Text.Caption>}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
