import {
  Button,
  FormRenderProps,
  Grid,
  GridFlex,
  Icon,
  MenuItem,
  OutlinedFormFieldLabel,
  Select,
  Text,
  TextField,
} from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import React, { useState } from 'react';
import { t } from 'i18next';
import { updateCustomerAction } from '../../../../../../customersSlice';
import { UpdateCustomerFormFields } from '../../../../CustomerUpdateForm';
import { useAppDispatch } from '../../../../../../../../redux/store';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { CustomerUpdateDialog } from '../../../../CustomerUpdateDialog';

const INVOICE_STATUSES = ['OPEN', 'PAID'] as const;

export function InvoiceCollectionMethod({
  customer,
  form,
}: {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
}) {
  const dispatch = useAppDispatch();
  const [isEditCustomerDialogOpen, setIsEditCustomerDialogOpen] = useState(false);
  const { setFieldValue, values } = form;
  const invoiceStatus = values.isInvoicePaid ? 'PAID' : 'OPEN';

  const onUpdate = (updatePayload: UpdateCustomerFormFields) => {
    void dispatch(updateCustomerAction(updatePayload));
    setIsEditCustomerDialogOpen(false);
  };

  return customer.email ? (
    <>
      <GridFlex.RowCenter container gap={2}>
        <GridFlex.ColumnMiddle item>
          <Icon icon="EventAvailableOutlined" type="materialIcons" color="default" />
        </GridFlex.ColumnMiddle>

        <GridFlex.ColumnMiddle item>
          <Text.B2 color="secondary">{t('subscriptionForm.invoice.invoiceStatus')}</Text.B2>
        </GridFlex.ColumnMiddle>

        <GridFlex.ColumnMiddle item ml={4}>
          <Select
            width={200}
            menuMaxWidth={610}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            value={invoiceStatus}
            onChange={(e) => setFieldValue('isInvoicePaid', e.target.value === 'PAID')}
            renderValue={(value: any) => (
              <Text.B2 color="secondary">{t(`subscriptionForm.invoice.status.${value}.name`)}</Text.B2>
            )}>
            {INVOICE_STATUSES.map((value) => (
              <MenuItem key={value} value={value}>
                <GridFlex.Column>
                  <Text.B2 color="primary">{t(`subscriptionForm.invoice.status.${value}.name`)}</Text.B2>
                  <Text.Caption color="secondary" sx={{ textWrap: 'wrap' }}>
                    {t(`subscriptionForm.invoice.status.${value}.description`)}
                  </Text.Caption>
                </GridFlex.Column>
              </MenuItem>
            ))}
          </Select>
        </GridFlex.ColumnMiddle>

        {invoiceStatus === 'OPEN' && (
          <>
            <GridFlex.ColumnMiddle item ml={4}>
              <OutlinedFormFieldLabel
                label={t('subscriptionForm.invoice.daysUntilDue.label')}
                helpTooltipText={t('subscriptionForm.invoice.daysUntilDue.tooltip')}
                helpTooltipMaxWidth={300}
              />
            </GridFlex.ColumnMiddle>

            <GridFlex.ColumnMiddle item ml={4}>
              <TextField
                type="number"
                placeholder={t('subscriptionForm.invoice.daysUntilDue.placeholder')}
                withAbsoluteErrorMessage
                {...form.getFieldProps('invoiceDaysUntilDue')}
                {...form.getFieldMeta('invoiceDaysUntilDue')}
                errorTextStyle={{ width: 'max-content' }}
              />
            </GridFlex.ColumnMiddle>
          </>
        )}
      </GridFlex.RowCenter>
    </>
  ) : (
    <>
      <GridFlex.Row gap={2} container justifyContent="flex-start" alignItems="flex-start">
        <Grid>
          <Button
            startIcon={<Icon icon="Plus" type="reactFeather" color="primary.main" />}
            variant="outlined"
            onClick={() => setIsEditCustomerDialogOpen(true)}>
            {t('subscriptionForm.invoice.addEmail')}
          </Button>
        </Grid>
        <GridFlex.RowCenter item mt={2}>
          <Icon icon="Error" size={18} color="error" />
          <Text.B2 color="error.main" ml={2}>
            {t('subscriptionForm.invoice.addEmailRequired')}
          </Text.B2>
        </GridFlex.RowCenter>
      </GridFlex.Row>
      <CustomerUpdateDialog
        customer={customer}
        onUpdate={onUpdate}
        open={isEditCustomerDialogOpen}
        setOpen={setIsEditCustomerDialogOpen}
        editableFields={['email']}
      />
    </>
  );
}
