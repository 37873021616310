import { t } from 'i18next';
import * as Yup from 'yup';

const refIdRegex = /^[a-zA-Z0-9][a-zA-Z0-9_.-]*$/g;
export const refIdValidation = () =>
  Yup.string()
    .matches(refIdRegex, t('fieldValidationMessages.invalidRefId'))
    .required(t('fieldValidationMessages.required'));

const urlFriendlyRegex = /^[a-zA-Z0-9][a-zA-Z0-9_. -]*$/g;
export const urlFriendlyValidation = () =>
  Yup.string()
    .matches(urlFriendlyRegex, t('fieldValidationMessages.invalidUrlFriendly'))
    .required(t('fieldValidationMessages.required'));
