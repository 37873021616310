import * as React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';
import { FrameContextConsumer } from 'react-frame-component';

const memoizedCreateCacheWithContainer = weakMemoize((container) => {
  // @ts-ignore
  return createCache({ key: 'stigg-theme', container });
});

export const FrameProvider = (props) => {
  const { children } = props;
  return (
    <FrameContextConsumer>
      {({ document }) => {
        // @ts-ignore
        return <CacheProvider value={memoizedCreateCacheWithContainer(document?.head)}>{children}</CacheProvider>;
      }}
    </FrameContextConsumer>
  );
};
