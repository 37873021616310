import { ResultSet } from '@cubejs-client/core';
import { useMemo } from 'react';

export type CubeFeatureData = { isLoading: boolean; error: Error | null; resultSet: ResultSet | null };

export const useCubeFeatureStaticData = ({
  mock,
  featureRefId,
  planRefId,
}: {
  mock: Record<string, Record<string, unknown>>;
  featureRefId: string;
  planRefId: string;
}) => {
  const data = mock[planRefId]?.[featureRefId];
  return useMemo(() => {
    const serialized = data ? { loadResponse: data } : null;
    const deserialized = serialized ? ResultSet.deserialize(serialized) : null;

    return { isLoading: false, error: null, resultSet: deserialized };
  }, [data]);
};
