import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ErrorCode,
  ImportIntegrationCatalogInput,
  TriggerImportCatalogMutation,
  TriggerImportCatalogMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const TRIGGER_INTEGRATION_IMPORT = gql`
  mutation TriggerImportCatalog($input: ImportIntegrationCatalogInput!) {
    triggerImportCatalog(input: $input) {
      taskId
    }
  }
`;

async function triggerIntegrationCatalogImport(
  integrationData: Omit<ImportIntegrationCatalogInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const triggerImportCatalog = {
        ...integrationData,
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<TriggerImportCatalogMutation, TriggerImportCatalogMutationVariables>({
        mutation: TRIGGER_INTEGRATION_IMPORT,
        variables: { input: triggerImportCatalog },
      });
      const newIntegration = response.data?.triggerImportCatalog;
      return newIntegration;
    },
    {
      failureMessageHandler: () => t('integrations.api.importTriggerFailed'),
      expectedErrorsMessage: {
        [ErrorCode.ImportAlreadyInProgress]: t('integrations.api.importAlreadyInProgress'),
      },
    },
    dispatch,
  );
}

export { triggerIntegrationCatalogImport };
