import { gql } from '@apollo/client';
import { t } from 'i18next';
import { DeleteOneHookMutation, DeleteOneHookMutationVariables, HookDeleteResponse } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';

const DELETE_HOOK = gql`
  mutation DeleteOneHook($input: DeleteOneHookInput!) {
    deleteOneHook(input: $input) {
      id
    }
  }
`;

async function deleteHookById(
  {
    hookId,
    redirectUrl = 'webhooks',
  }: {
    hookId: string;
    deleteSlackIntegration?: boolean;
    redirectUrl?: 'webhooks' | 'integrations';
  },
  { dispatch }: { dispatch: AppDispatch },
): Promise<HookDeleteResponse> {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<DeleteOneHookMutation, DeleteOneHookMutationVariables>({
        mutation: DELETE_HOOK,
        variables: { input: { id: hookId } },
      });

      dispatch(navigateTo(`/${redirectUrl}`));
      return { id: hookId };
    },
    {
      successMessage: t('hooks.api.successDelete'),
      failureMessageHandler: () => t('hooks.api.failDelete'),
    },
    dispatch,
  );
}

export { deleteHookById };
