// noinspection SqlNoDataSourceInspection

export const SETUP_SQL_VARS = {
  ROLE: 'STIGG_ROLE',
  WAREHOUSE: 'STIGG_WAREHOUSE',
  DATABASE: 'STIGG_DATABASE',
  SCHEMA: 'STIGG_SCHEMA',
  USER: 'STIGG_USER',
};

// language=Snowflake
export const SETUP_SQL = `-- set variables (these need to be uppercase)
set export_role = '${SETUP_SQL_VARS.ROLE}';
set export_warehouse = '${SETUP_SQL_VARS.WAREHOUSE}';
set export_database = '${SETUP_SQL_VARS.DATABASE}';
set export_schema = '${SETUP_SQL_VARS.SCHEMA}';

-- set credentials
set export_username = '${SETUP_SQL_VARS.USER}';
set export_password = '<PASSWORD>';

begin;

-- create role
use role securityadmin;
create role if not exists identifier ($export_role);
grant role identifier ($export_role) to role SYSADMIN;

-- create user
create user if not exists identifier ($export_username)
    password = $export_password
    default_role = $export_role
    default_warehouse = $export_warehouse;

grant role identifier ($export_role) to user identifier ($export_username);

-- change role to sysadmin for warehouse / database steps
use role sysadmin;

-- create warehouse
create warehouse if not exists identifier ($export_warehouse)
    warehouse_size = xsmall warehouse_type = standard
auto_suspend = 60
auto_resume = true
initially_suspended = true;

-- create database
create database if not exists identifier ($export_database);

-- grant warehouse access
grant USAGE
    on warehouse identifier ($export_warehouse)
    to role identifier ($export_role);

-- grant database access
grant OWNERSHIP
    on database identifier ($export_database)
    to role identifier ($export_role);

commit;

begin;

USE DATABASE identifier ($export_database);

-- create schema
CREATE SCHEMA IF NOT EXISTS identifier ($export_schema);

commit;

begin;

-- grant schema access
grant OWNERSHIP
    on schema identifier ($export_schema)
    to role identifier ($export_role);

commit;
`;

// language=Snowflake
export const SHOW_POLICIES_SQL = `SHOW NETWORK POLICIES;`;

// language=Snowflake
export const CREATE_NETWORK_POLICY_SQL = `
CREATE NETWORK POLICY $EXPORT_POLICY_NAME ALLOWED_IP_LIST = (
-- Depending on your data residency location, you may need to allowlist the following IP addresses:
-- United States:
  '34.106.109.131/32',
  '34.106.196.165/32',
  '34.106.60.246/32',
  '34.106.229.69/32',
  '34.106.127.139/32',
  '34.106.218.58/32',
  '34.106.115.240/32',
  '34.106.225.141/32',
-- European Union:
  '13.37.4.46/32',
  '13.37.142.60/32',
  '35.181.124.238/32'
);
`;
