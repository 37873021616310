import { gql } from '@apollo/client';
import { t } from 'i18next';
import { ArchiveFeatureMutation, ArchiveFeatureMutationVariables, ErrorCode } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';
import { fetchFeaturesAction } from '../featuresSlice';

const ARCHIVE_FEATURE = gql`
  mutation ArchiveFeature($input: ArchiveFeatureInput!) {
    archiveFeature(input: $input) {
      id
    }
  }
`;

async function archiveFeatureById(
  featureIdRefId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<{ id: string } | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<ArchiveFeatureMutation, ArchiveFeatureMutationVariables>({
        mutation: ARCHIVE_FEATURE,
        variables: { input: { id: featureIdRefId, environmentId: accountReducer.currentEnvironmentId } },
      });
      await dispatch(fetchFeaturesAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch: true }));
      dispatch(navigateTo('/features'));
      return { id: featureIdRefId };
    },
    {
      successMessage: t('features.api.successArchive'),
      failureMessageHandler: () => t('features.api.failArchive'),
      expectedErrorsMessage: {
        [ErrorCode.CannotArchiveFeatureError]: t('features.api.canNotArchiveFeature'),
      },
    },
    dispatch,
  );
}

export { archiveFeatureById };
