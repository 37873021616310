import { t } from 'i18next';
import ceil from 'lodash/ceil';
import { Form, GridFlex, Icon, LongText, Text } from '@stigg-components';
import {
  CustomerSubscriptionResourceFragment,
  EntitlementDataFragment,
  UnitTransformation,
  UsageUpdateBehavior,
} from '@stigg-types/apiTypes';
import { FormikHelpers } from 'formik';
import { useSelector } from 'react-redux';
import { EntitlementUsageBox } from './EntitlementUsageBox';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { reportUsageAction } from '../../../customersSlice';
import { CreateFeatureFormFields } from '../../../../features/components/createFeature/SidenavCreateFeature';

type ReportUsageFormFields = {
  timestamp: Date;
  updateBehavior: UsageUpdateBehavior;
  value: number;
};

function FeatureInfo({ entitlement }: { entitlement: EntitlementDataFragment }) {
  return (
    <GridFlex.Column>
      <Text.B2 $bold>{entitlement.feature?.displayName}</Text.B2>
      <Text.Sub2>{entitlement.feature?.refId}</Text.Sub2>
    </GridFlex.Column>
  );
}

function isSafeNumber(value: number) {
  return value <= Number.MAX_SAFE_INTEGER && value >= Number.MIN_SAFE_INTEGER;
}

function FeatureUsagePreview({
  entitlement,
  updateBehavior,
  usageValue,
}: {
  entitlement: EntitlementDataFragment;
  updateBehavior: UsageUpdateBehavior;
  usageValue: number;
}) {
  const currentUsage = updateBehavior === 'DELTA' ? (entitlement.currentUsage || 0) + usageValue : usageValue;
  return <EntitlementUsageBox entitlement={entitlement} overrideUsage={currentUsage} />;
}

const setUsageValue = (
  entitlement: EntitlementDataFragment,
  setFieldValue: FormikHelpers<CreateFeatureFormFields>['setFieldValue'],
) => {
  return (e) => {
    setFieldValue('updateBehavior', e.target.value, false);
    const setValue = e.target.value === UsageUpdateBehavior.Set ? entitlement.currentUsage || 0 : 0;
    setFieldValue('value', setValue);
  };
};

const validate = (
  currentUsage: number,
  values: ReportUsageFormFields,
  unitTransformation?: UnitTransformation | null,
) => {
  const errors = {} as Record<keyof ReportUsageFormFields, string>;
  const { updateBehavior } = values;
  const currentEntitlementUsage = unitTransformation ? currentUsage * unitTransformation.divide : currentUsage;

  if (updateBehavior === UsageUpdateBehavior.Delta && values.value === 0) {
    errors.value = 'When updating usage as delta, the value must not be 0';
  }

  if (updateBehavior === UsageUpdateBehavior.Set && values.value === currentEntitlementUsage) {
    errors.value = 'Target value is equal to current value';
  }

  const delta = updateBehavior === UsageUpdateBehavior.Delta ? values.value : values.value - currentEntitlementUsage;
  const newUsage = updateBehavior === UsageUpdateBehavior.Delta ? currentEntitlementUsage + values.value : values.value;

  if (!isSafeNumber(newUsage)) {
    errors.value = Math.sign(newUsage) > 0 ? 'Total usage is too high' : 'Total usage is too low';
  } else if (!isSafeNumber(delta)) {
    errors.value = Math.sign(delta) > 0 ? 'Usage record is too high' : 'Usage record is too low';
  }

  return errors;
};

function transformUsageUnit(usage: number, unitTransformation?: UnitTransformation | null) {
  if (!unitTransformation) {
    return usage;
  }

  return ceil(usage / unitTransformation.divide, 3);
}

export function ReportUsageForm({
  customerRefId,
  entitlement,
  subscription,
  onCancel,
  onSubmit,
}: {
  customerRefId: string;
  entitlement: EntitlementDataFragment;
  subscription?: CustomerSubscriptionResourceFragment;
  onCancel: () => void;
  onSubmit: () => void;
}) {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);

  const defaultValues: ReportUsageFormFields = {
    timestamp: new Date(),
    updateBehavior: UsageUpdateBehavior.Delta,
    value: 0,
  };

  const featureUnits = entitlement.feature?.unitTransformation
    ? entitlement.feature.unitTransformation.featureUnitsPlural
    : entitlement.feature?.featureUnitsPlural;

  const handleSubmit = async (values: ReportUsageFormFields) => {
    if (!entitlement.feature) {
      return;
    }

    if (!currentEnvironmentId) {
      return;
    }

    await dispatch(
      reportUsageAction({
        environmentId: currentEnvironmentId,
        customerId: customerRefId,
        featureId: entitlement.feature.refId,
        resourceId: subscription?.resource?.resourceId,
        createdAt: values.updateBehavior === UsageUpdateBehavior.Set ? null : values.timestamp,
        updateBehavior: values.updateBehavior,
        value: values.value,
      }),
    );

    onSubmit();
  };

  return (
    <Form
      initialValues={defaultValues}
      submitButtonText={t('customers.addUsageRecord.submitButtonText')}
      onSubmit={handleSubmit}
      withStickyFooter
      onCancel={onCancel}
      validate={(values) => validate(entitlement.currentUsage || 0, values, entitlement.feature?.unitTransformation)}
      fields={({ values }) => [
        {
          id: 'feature',
          type: 'custom',
          label: 'Feature',
          layout: 'row',
          gridProps: {
            alignItems: 'flex-start',
            mb: -3, // hack to solve big gap between feature and resource
          },
          render: () => <FeatureInfo entitlement={entitlement} />,
        },
        {
          id: 'resource',
          type: 'custom',
          label: 'Resource',
          layout: 'row',
          hide: () => !subscription?.resource,
          gridProps: {
            mb: 2,
          },
          render: () => (
            <GridFlex.RowCenter gap={1}>
              <Icon icon="Layers" type="reactFeather" color="active" size={16} />
              <LongText variant="body2" color="secondary" wordBreak>
                {subscription?.resource?.resourceId}
              </LongText>
            </GridFlex.RowCenter>
          ),
        },
        {
          id: 'timestamp',
          label: 'Timestamp',
          layout: 'row',
          type: 'custom',
          render: () => <Text.B2 mb={1}>Now</Text.B2>,
        },
        {
          id: 'updateBehavior',
          type: 'select',
          label: 'Update behavior',
          layout: 'row',
          values: [
            {
              value: UsageUpdateBehavior.Delta,
              displayValue: 'Delta',
            },
            {
              value: UsageUpdateBehavior.Set,
              displayValue: 'Set',
            },
          ],
          handleChange: ({ setFieldValue }) => setUsageValue(entitlement, setFieldValue),
        },
        {
          id: 'value',
          type: 'text',
          label: 'Value',
          layout: 'row',
          textFieldType: 'number',
          isNumberWithoutFraction: true,
          textFieldProps: {
            InputProps: {
              endAdornment: featureUnits ? (
                <LongText variant="body2" color="secondary" wordBreak sx={{ textAlign: 'right' }}>
                  {featureUnits}
                </LongText>
              ) : null,
            },
          },
        },
        {
          id: 'preview',
          type: 'custom',
          label: 'Preview',
          layout: 'row',
          gridProps: {
            mt: 2,
          },
          render: () => (
            <FeatureUsagePreview
              entitlement={entitlement}
              updateBehavior={values.updateBehavior}
              usageValue={transformUsageUnit(values.value, entitlement.feature?.unitTransformation)}
            />
          ),
        },
      ]}
    />
  );
}
