import { BarElement, Chart, Plugin } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';

const PIXELS_PER_CHAR = 10.5;

export function convertTextOverflowEllipsis(text: string, context: Context) {
  const bar = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex] as unknown as BarElement;
  const { width } = bar.getProps(['width']);

  // on first render we don't have width yet
  if (!width) {
    return '';
  }

  if (width < PIXELS_PER_CHAR) {
    return '';
  }
  if (width < 2 * PIXELS_PER_CHAR) {
    return '..';
  }

  const maxChars = Math.floor(width / PIXELS_PER_CHAR);

  return text.length <= maxChars ? text : `${text.slice(0, maxChars - 1)}...`;
}

// This is a workaround for data-labels plugin which runs the text formatter
// function before the bar element is drawn, so we don't have the width yet.
// So this small plugin update the chart before draw in order to re-run the formatter
export const redrawBeforeFirstDrawPlugin: Plugin = {
  id: 'redrawBeforeFirstDrawPlugin',

  beforeDraw(chart: Chart) {
    // @ts-ignore
    if (!chart.$rendered) {
      // @ts-ignore
      chart.$rendered = true;
      chart.update('none');
    }
  },
};
