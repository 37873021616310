import { t } from 'i18next';
import { Optional } from '@stigg-types/primitives';
import { GridFlex, Text } from '@stigg-components';
import { HubspotCredentialsFragment, IntegrationFragment } from '@stigg-types/apiTypes';

export type HubspotIntegrationDetailsProps = { integration: IntegrationFragment };

export function HubspotIntegrationDetails({ integration }: HubspotIntegrationDetailsProps) {
  const credentials = integration.credentials as Optional<HubspotCredentialsFragment>;
  if (!credentials) {
    return null;
  }
  const { hubDomain } = credentials;
  return (
    <GridFlex.Column item container>
      <GridFlex.Column item>
        <Text.Sub2 mb={2}>{t('integrations.hubspot.hubDomain')}</Text.Sub2>
        <Text.B2>{hubDomain}</Text.B2>
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
