import {
  AddonChangeVariables,
  BillingPeriodChangeVariables,
  DowngradeChangeVariables,
  PlanChangeType,
  PlanChangeVariables,
  PlansFragment,
  SubscriptionDataFragment,
  UnitAmountChangeVariables,
} from '@stigg-types/apiTypes';
import { difference } from 'lodash';
import { ChangeType } from '../SubscriptionFutureUpdateRowContainer';

const CHANGE_TYPE_MAP: Record<PlanChangeType.Downgrade | PlanChangeType.Upgrade, ChangeType> = {
  [PlanChangeType.Downgrade]: ChangeType.DECREASE,
  [PlanChangeType.Upgrade]: ChangeType.INCREASE,
};

const CHANGE_TEXT_PREFIX_MAP: Record<PlanChangeType.Downgrade | PlanChangeType.Upgrade, string> = {
  [PlanChangeType.Downgrade]: 'subscriptions.schedules.downgrade',
  [PlanChangeType.Upgrade]: 'subscriptions.schedules.upgrade',
};

export function getPlanChange(
  changeVariables: PlanChangeVariables | DowngradeChangeVariables,
  plansListFragments: PlansFragment,
) {
  let planRefId: string;
  let changeType: ChangeType;
  let textPrefix: string;

  switch (changeVariables?.__typename) {
    case 'PlanChangeVariables': {
      if (changeVariables.changeType === PlanChangeType.None) {
        return undefined;
      }
      planRefId = changeVariables.planRefId;
      changeType = CHANGE_TYPE_MAP[changeVariables.changeType];
      textPrefix = CHANGE_TEXT_PREFIX_MAP[changeVariables.changeType];
      break;
    }
    case 'DowngradeChangeVariables': {
      planRefId = changeVariables.downgradePlanRefId;
      changeType = CHANGE_TYPE_MAP[PlanChangeType.Downgrade];
      textPrefix = CHANGE_TEXT_PREFIX_MAP[PlanChangeType.Downgrade];
      break;
    }
    default:
      return undefined;
  }

  const plan = plansListFragments?.edges?.map(({ node }) => node)?.find(({ refId }) => refId === planRefId);
  const planDisplayName = plan?.displayName || planRefId;

  return { planRefId, planDisplayName, changeType, textPrefix };
}

export function getBillingPeriodChange(
  changeVariables: PlanChangeVariables | DowngradeChangeVariables,
  subscription: SubscriptionDataFragment,
): BillingPeriodChangeVariables | undefined {
  const oldBillingPeriod = subscription.prices?.[0]?.price?.billingPeriod;
  const { billingPeriod } = changeVariables;

  if (!oldBillingPeriod || !billingPeriod || oldBillingPeriod === billingPeriod) {
    return undefined;
  }

  return { billingPeriod };
}

export function getAddonsChanges(
  changeVariables: PlanChangeVariables | DowngradeChangeVariables,
  subscription: SubscriptionDataFragment,
): Array<AddonChangeVariables> {
  const oldAddons = subscription.addons || [];
  const addons = changeVariables.addons || [];

  const oldAddonsIds = oldAddons.map(({ addon }) => addon.refId);
  const addonsIds = addons.map(({ addonRefId }) => addonRefId);

  const removedAddonIds = difference(oldAddonsIds, addonsIds);
  const removedAddons = oldAddons.filter(({ addon }) => removedAddonIds.includes(addon.refId));

  const updatedAddons = addons.filter(({ addonRefId, quantity }) => {
    const oldAddon = oldAddons.find(({ addon }) => addon.refId === addonRefId);
    return !oldAddon || oldAddon.quantity !== quantity;
  });

  return [
    ...updatedAddons.map(({ addonRefId, quantity }) => ({ addonRefId, newQuantity: quantity })),
    ...removedAddons.map(({ addon }) => ({ addonRefId: addon.refId, newQuantity: 0 })),
  ];
}

export function getUnitAmountChanges(
  changeVariables: PlanChangeVariables | DowngradeChangeVariables,
  subscription: SubscriptionDataFragment,
): Array<UnitAmountChangeVariables> {
  const existingBillableFeatures = subscription.prices || [];
  const billableFeatures = changeVariables.billableFeatures || [];

  return billableFeatures
    .filter(({ featureId, quantity }) => {
      const existingBillableFeature = existingBillableFeatures.find((feature) => feature.featureId === featureId);
      return existingBillableFeature && existingBillableFeature.usageLimit !== quantity;
    })
    .map(({ featureId, quantity }) => ({ featureId, newUnitAmount: quantity }));
}
