import { useEffect } from 'react';
import { useBroadcastChannel } from './useBroadcastChannel';

export function useBroadcastChannelListener(handler) {
  const broadcastChannel = useBroadcastChannel();

  useEffect(() => {
    broadcastChannel.addListener(handler);
    return () => {
      broadcastChannel.removeListener();
    };
  }, [broadcastChannel, handler]);
}
