import { GridFlex, Icon, IconProps, Text } from '@stigg-components';

export function DetailsSectionHeader({ icon, title }: { icon: IconProps['icon']; title: string }) {
  return (
    <GridFlex.RowCenter sx={{ mt: 9, mb: 1 }} columnGap={2}>
      <Icon icon={icon} color="secondary" size={20} />
      <Text.B2 color="secondary">{title}</Text.B2>
    </GridFlex.RowCenter>
  );
}
