import { t } from 'i18next';
import moment from 'moment';
import styled from 'styled-components/macro';
// eslint-disable-next-line no-restricted-imports
import { Grid, Text, InformationTooltip } from '@stigg-components';
import { EntitlementResetPeriod } from '@stigg-types/apiTypes';

const TooltipTitle = styled(Text.B2)`
  font-weight: 900;
`;

function getResetDateFormat(resetPeriod: EntitlementResetPeriod, resetDate?: Date) {
  const momentResetDate = moment.utc(resetDate);
  const tooltipSubtitle = `The next reset will take place on ${momentResetDate.format(
    'dddd, MMMM Do, YYYY',
  )} at ${momentResetDate.format('hh:mm:ssa')} UTC.`;

  const byPeriod = {
    [EntitlementResetPeriod.Year]: {
      tooltipTitle: `Usage resets yearly on ${momentResetDate.format('MMMM Do')}.`,
      tooltipSubtitle,
      text: t('customers.entitlementUsageResetYearPeriod'),
    },
    [EntitlementResetPeriod.Month]: {
      tooltipTitle: `Usage resets monthly on the ${momentResetDate.format('Do')} day of every month.`,
      tooltipSubtitle,
      text: t('customers.entitlementUsageResetMonthPeriod'),
    },
    [EntitlementResetPeriod.Week]: {
      tooltipTitle: `Usage resets weekly on ${momentResetDate.format('dddd')}s.`,
      tooltipSubtitle,
      text: t('customers.entitlementUsageResetWeekPeriod'),
    },
    [EntitlementResetPeriod.Day]: {
      tooltipTitle: 'Usage resets daily at 12:00am UTC.',
      tooltipSubtitle,
      text: t('customers.entitlementUsageResetDayPeriod'),
    },
    [EntitlementResetPeriod.Hour]: {
      tooltipTitle: 'Usage resets every hour on the hour.',
      tooltipSubtitle,
      text: t('customers.entitlementUsageResetHourPeriod'),
    },
  };

  return byPeriod[resetPeriod];
}

type EntitlementResetDateProps = {
  resetPeriod: EntitlementResetPeriod;
  resetDate: Date;
};

function ResetDateTooltip({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Grid container>
      <Grid>
        <TooltipTitle>{title}</TooltipTitle>
      </Grid>
      <Grid>
        <Text.B2>{subtitle}</Text.B2>
      </Grid>
    </Grid>
  );
}

export function EntitlementResetDate({ resetPeriod, resetDate }: EntitlementResetDateProps) {
  const { tooltipTitle, tooltipSubtitle, text } = getResetDateFormat(resetPeriod, resetDate);

  return (
    <InformationTooltip
      arrow
      placement="top"
      title={<ResetDateTooltip title={tooltipTitle} subtitle={tooltipSubtitle} />}>
      <div>
        <Text.Sub2 sx={{ textWrap: 'nowrap' }}>{text}</Text.Sub2>
      </div>
    </InformationTooltip>
  );
}
