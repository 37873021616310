import styled, { css } from 'styled-components/macro';
import { GridFlex } from '@stigg-components';

export const HeaderContainer = styled(GridFlex.RowSpaceBetween)<{ $sticky?: boolean }>`
  ${({ $sticky, theme }) =>
    $sticky &&
    css`
      position: sticky;
      top: 0;
      background-color: ${theme.itamar.palette.background.paper};
      z-index: 10;
    `}
`;

export const FooterContainer = styled(GridFlex.Item)<{ $sticky?: boolean }>`
  ${({ $sticky, theme }) =>
    $sticky &&
    css`
      position: sticky;
      bottom: 0;
      background-color: ${theme.itamar.palette.background.paper};
    `}
`;
