import { useEffect, useRef, useState } from 'react';
import { AddonFragment } from '@stigg-types/apiTypes';
import { SubscriptionAddon } from '../SubscriptionForm.types';

export const useAddedAddonsMap = (addons: SubscriptionAddon[]) => {
  const AddedAddonsMapRef = useRef(new Map(addons.map((addon) => [addon.id, addon as AddonFragment])));
  const [planId, setPlanId] = useState<string>('');

  useEffect(() => {
    AddedAddonsMapRef.current = new Map();
  }, [planId]);

  return { onPlanIdChange: setPlanId, addedAddonsMap: AddedAddonsMapRef.current };
};
