import AdapterMoment from '@mui/lab/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Tooltip,
  HelpIcon,
  Select,
  StyledSwitch,
  TextField,
  Text,
  VisibilityOnIcon,
  GridFlex,
  Alert,
  Icon,
} from '@stigg-components';
import { t } from 'i18next';

import { FormikHandlers, FormikHelpers, FormikState } from 'formik';
import moment from 'moment';
import { PromotionalEntitlementPeriod } from '@stigg-types/apiTypes';
import { AddPromotionalEntitlementFormFields } from './AddPromotionalEntitlementForm';
import { EditPromotionalEntitlementFormFields } from './EditPromotionalEntitlementForm';

export type PromotionalEntitlementsConfigurationProps<T> = {
  values: FormikState<T>['values'];
  setFieldValue: FormikHelpers<T>['setFieldValue'];
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  touched: FormikState<T>['touched'];
  errors: FormikState<T>['errors'];
  setFieldTouched: FormikHelpers<T>['setFieldTouched'];
  isAdd?: boolean;
  hasActiveSubscription?: boolean;
};

const sortedPeriods = [
  PromotionalEntitlementPeriod.OneWeek,
  PromotionalEntitlementPeriod.OneMonth,
  PromotionalEntitlementPeriod.SixMonth,
  PromotionalEntitlementPeriod.OneYear,
  PromotionalEntitlementPeriod.Custom,
  PromotionalEntitlementPeriod.Lifetime,
];

function getPeriodValue(period: PromotionalEntitlementPeriod) {
  switch (period) {
    case PromotionalEntitlementPeriod.Lifetime:
      return t('promotionalEntitlements.lifetimeValue');
    case PromotionalEntitlementPeriod.Custom:
      return t('promotionalEntitlements.customValue');
    case PromotionalEntitlementPeriod.OneMonth:
      return t('promotionalEntitlements.oneMonthValue');
    case PromotionalEntitlementPeriod.OneWeek:
      return t('promotionalEntitlements.oneWeekValue');
    case PromotionalEntitlementPeriod.OneYear:
      return t('promotionalEntitlements.oneYearValue');
    case PromotionalEntitlementPeriod.SixMonth:
      return t('promotionalEntitlements.sixMonthValue');
    default:
      return '';
  }
}

export function getPeriodDate(value: PromotionalEntitlementPeriod, date: Date) {
  const startDate = moment(date);
  switch (value) {
    case PromotionalEntitlementPeriod.OneMonth:
      return startDate.add(1, 'month');
    case PromotionalEntitlementPeriod.OneWeek:
      return startDate.add(1, 'week');
    case PromotionalEntitlementPeriod.OneYear:
      return startDate.add(1, 'year');
    case PromotionalEntitlementPeriod.SixMonth:
      return startDate.add(6, 'month');
    case PromotionalEntitlementPeriod.Custom:
      return undefined;
    case PromotionalEntitlementPeriod.Lifetime:
    default:
      return null;
  }
}

export function PromotionalEntitlementsConfiguration({
  values,
  setFieldValue,
  handleBlur,
  setFieldTouched,
  handleChange,
  touched,
  errors,
  isAdd = false,
  hasActiveSubscription,
}: PromotionalEntitlementsConfigurationProps<
  AddPromotionalEntitlementFormFields | EditPromotionalEntitlementFormFields
>) {
  return (
    <GridFlex.Column $fullWidth>
      <Text.H3 mt={1} mb={3}>
        {isAdd ? t('promotionalEntitlements.createFormTitle') : t('promotionalEntitlements.editFormTitle')}
      </Text.H3>
      <Grid container wrap="nowrap" flexDirection="column" rowSpacing={3}>
        <Grid container item spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Select
              renderValue={(period: any) => getPeriodValue(period)}
              value={values.period}
              fullWidth
              name="period"
              label={t('promotionalEntitlements.createFormPeriodLabel')}
              onChange={(e) => {
                const calculatedEndDate = getPeriodDate(e.target.value as PromotionalEntitlementPeriod, new Date());
                setFieldValue('endDate', calculatedEndDate, false);
                handleChange(e);
              }}>
              {sortedPeriods.map((period) => {
                return (
                  <MenuItem key={period} value={period}>
                    <Text.B2 color="primary">{getPeriodValue(period)}</Text.B2>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={values.period === PromotionalEntitlementPeriod.Lifetime}
                value={values.endDate}
                minDate={moment().add(1, 'day')}
                InputAdornmentProps={{ sx: { pr: 2 } }}
                inputFormat="MMMM DD YYYY"
                onChange={(value) => {
                  setFieldTouched('endDate');
                  setFieldValue('endDate', value);
                  setFieldValue('period', PromotionalEntitlementPeriod.Custom, false);
                }}
                PaperProps={{
                  sx: {
                    boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
                  },
                }}
                components={{
                  OpenPickerIcon: () => (
                    <Icon
                      icon="DateRange"
                      type="materialIcons"
                      color={values.period === PromotionalEntitlementPeriod.Lifetime ? 'disabled' : 'active'}
                    />
                  ),
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      name="enddate"
                      fullWidth
                      {...params}
                      onBlur={handleBlur}
                      label={t('promotionalEntitlements.createFormEndDateLabel')}
                      touched={!!touched.endDate}
                      error={!!errors.endDate}
                      errorText={t('fieldValidationMessages.invalidDate')}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <StyledSwitch
                checked={values.isVisible || false}
                name="isVisible"
                onChange={(e) => setFieldValue('isVisible', e.target.checked, false)}
                size="small"
              />
            }
            labelPlacement="end"
            label={
              <>
                <Grid container flexDirection="row" ml={2} mt={2}>
                  <Grid item mr={2}>
                    <VisibilityOnIcon />
                  </Grid>
                  <Grid item>
                    {values.isVisible ? (
                      <Text.B2>{t('promotionalEntitlements.createFormIsVisibleLabel')}</Text.B2>
                    ) : (
                      <Text.Sub2>{t('promotionalEntitlements.createFormIsVisibleLabel')}</Text.Sub2>
                    )}
                  </Grid>
                  <Grid item ml={2}>
                    <Tooltip
                      arrow
                      placement="top"
                      leaveDelay={500}
                      title={t('promotionalEntitlements.createFormIsVisibleTooltip') || ''}>
                      <HelpIcon aria-disabled />
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item>
          {isAdd && !hasActiveSubscription && (
            <Alert severity="warning">{t('customers.promotionalEntitlementsNoActiveSubscriptionWarning')}</Alert>
          )}
        </Grid>
      </Grid>
    </GridFlex.Column>
  );
}
