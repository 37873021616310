import { styled, spacing } from '@stigg-theme';
import { ExternalLink as LinkIcon } from 'react-feather';
import { Link, GridFlex } from '.';
import { CustomVariants, LinkProps } from './types';

const SpacedLinkIcon = styled(LinkIcon)(spacing);
export const StyledLinkIcon = styled(SpacedLinkIcon)`
  color: ${({ theme }) => theme.itamar.palette.primary.main};
`;

type LinkWithIconProps = {
  children?: React.ReactNode;
  variant?: CustomVariants;
  onClick?: () => void;
  hideIcon?: boolean;
} & LinkProps;

export const LinkWithIcon = ({ children, variant, onClick, sx, hideIcon, ...linkProps }: LinkWithIconProps) => (
  <GridFlex.RowCenter display="inline-flex">
    <Link
      color="primary.main"
      onClick={onClick}
      variant={variant}
      width="max-content"
      display="flex"
      alignItems="center"
      sx={sx}
      {...linkProps}>
      {children}
      {!hideIcon && <StyledLinkIcon width={16} height={16} strokeWidth={3} ml={1} />}
    </Link>
  </GridFlex.RowCenter>
);

type ExternalLinkProps = {
  url: string;
  label?: string;
  children?: React.ReactNode;
  variant?: CustomVariants;
  onClick?: () => void;
  hideIcon?: boolean;
} & LinkProps;

export const ExternalLink = ({
  url,
  label,
  children,
  variant = 'body2',
  onClick,
  sx,
  hideIcon,
  ...props
}: ExternalLinkProps) => {
  const onLinkClick = () => {
    onClick?.();
    window.open(url, '_blank');
  };

  return (
    <LinkWithIcon onClick={onLinkClick} variant={variant} sx={sx} hideIcon={hideIcon} {...props}>
      {children || label}
    </LinkWithIcon>
  );
};
