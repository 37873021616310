import { t } from 'i18next';
import { EventLogType } from '@stigg-types/apiTypes';

const getEventNameUtils: Partial<{ [key in EventLogType]: string }> = {
  [EventLogType.AddonCreated]: 'events.addonCreated',
  [EventLogType.AddonDeleted]: 'events.addonDeleted',
  [EventLogType.AddonUpdated]: 'events.addonUpdated',
  [EventLogType.CouponArchived]: 'events.couponArchived',
  [EventLogType.CouponCreated]: 'events.couponCreated',
  [EventLogType.CouponUpdated]: 'events.couponUpdated',
  [EventLogType.CreateSubscriptionFailed]: 'events.createSubscriptionFailed',
  [EventLogType.CustomerCreated]: 'events.customerCreated',
  [EventLogType.CustomerDeleted]: 'events.customerDeleted',
  [EventLogType.CustomerPaymentFailed]: 'events.customerPaymentFailed',
  [EventLogType.CustomerUpdated]: 'events.customerUpdated',
  [EventLogType.EntitlementsUpdated]: 'events.entitlementsUpdated',
  [EventLogType.EntitlementDenied]: 'events.entitlementDenied',
  [EventLogType.EntitlementGranted]: 'events.entitlementGranted',
  [EventLogType.EntitlementRequested]: 'events.entitlementRequested',
  [EventLogType.EntitlementUsageExceeded]: 'events.entitlementUsageExceeded',
  [EventLogType.FeatureArchived]: 'events.featureArchived',
  [EventLogType.FeatureCreated]: 'events.featureCreated',
  [EventLogType.FeatureDeleted]: 'events.featureDeleted',
  [EventLogType.FeatureUpdated]: 'events.featureUpdated',
  [EventLogType.ImportIntegrationCatalogTriggered]: 'events.importIntegrationCatalogTriggered',
  [EventLogType.ImportIntegrationCustomersTriggered]: 'events.importIntegrationCustomersTriggered',
  [EventLogType.ImportSubscriptionsBulkTriggered]: 'events.importSubscriptionsBulkTriggered',
  [EventLogType.MeasurementReported]: 'events.measurementReported',
  [EventLogType.PackageGroupCreated]: 'events.packageGroupCreated',
  [EventLogType.PackageGroupUpdated]: 'events.packageGroupUpdated',
  [EventLogType.PackagePublished]: 'events.packagePublished',
  [EventLogType.PlanCreated]: 'events.planCreated',
  [EventLogType.PlanDeleted]: 'events.planDeleted',
  [EventLogType.PlanUpdated]: 'events.planUpdated',
  [EventLogType.ProductCreated]: 'events.productCreated',
  [EventLogType.ProductDeleted]: 'events.productDeleted',
  [EventLogType.ProductUpdated]: 'events.productUpdated',
  [EventLogType.PromotionalEntitlementExpired]: 'events.promotionalEntitlementExpired',
  [EventLogType.PromotionalEntitlementGranted]: 'events.promotionalEntitlementGranted',
  [EventLogType.PromotionalEntitlementRevoked]: 'events.promotionalEntitlementRevoked',
  [EventLogType.PromotionalEntitlementUpdated]: 'events.promotionalEntitlementUpdated',
  [EventLogType.RecalculateEntitlementsTriggered]: 'events.recalculateEntitlementsTriggered',
  [EventLogType.ResyncIntegrationTriggered]: 'events.resyncIntegrationTriggered',
  [EventLogType.SubscriptionsMigrationTriggered]: 'events.subscriptionsMigrationTriggered',
  [EventLogType.SubscriptionBillingMonthEndsSoon]: 'events.subscriptionBillingMonthEndsSoon',
  [EventLogType.SubscriptionCanceled]: 'events.subscriptionCanceled',
  [EventLogType.SubscriptionCreated]: 'events.subscriptionCreated',
  [EventLogType.SubscriptionExpired]: 'events.subscriptionExpired',
  [EventLogType.SubscriptionSpentLimitExceeded]: 'events.subscriptionSpentLimitExceeded',
  [EventLogType.SubscriptionTrialConverted]: 'events.subscriptionTrialConverted',
  [EventLogType.SubscriptionTrialEndsSoon]: 'events.subscriptionTrialEndsSoon',
  [EventLogType.SubscriptionTrialExpired]: 'events.subscriptionTrialExpired',
  [EventLogType.SubscriptionTrialStarted]: 'events.subscriptionTrialStarted',
  [EventLogType.SubscriptionUpdated]: 'events.subscriptionUpdated',
  [EventLogType.SubscriptionUsageChargeTriggered]: 'events.subscriptionUsageChargeTriggered',
  [EventLogType.SubscriptionUsageUpdated]: 'events.subscriptionUsageUpdated',
  [EventLogType.SyncFailed]: 'events.syncFailed',
  [EventLogType.WidgetConfigurationUpdated]: 'events.widgetConfigurationUpdated',
};

// The mapper cant hold the direct translation of the event type to the event name
// as it does not exist on creation type, and requires context
export function getEventName(eventType: EventLogType): string {
  const key = getEventNameUtils[eventType];
  return key ? t(key) : eventType;
}
