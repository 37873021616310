import { useEffect } from 'react';
import { AwsProduct } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { fetchAWSProductDimensionsAction } from '../../../integrationsSlice';

type UseAwsProductDimensionsProps = {
  dispatch: ReturnType<typeof useAppDispatch>;
  awsProduct: AwsProduct | null;
};

export function useAwsProductDimensions({ dispatch, awsProduct }: UseAwsProductDimensionsProps) {
  const awsProductDimensions = useSelector((state: RootState) => state.integrations.awsProductDimensions);
  const isLoadingAWSProductDimensions = useSelector(
    (state: RootState) => state.integrations.isLoadingAWSProductDimensions,
  );

  useEffect(() => {
    if (awsProduct?.productId) {
      void dispatch(fetchAWSProductDimensionsAction({ productId: awsProduct.productId }));
    }
  }, [dispatch, awsProduct]);

  return { awsProductDimensions, isLoadingAWSProductDimensions };
}
