import { Text } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getPricingTypesList } from './PriceTypeList';

export function PriceTypeStepSummary() {
  const {
    aPackage,
    formRenderProps: { values },
  } = useSetPriceWizardFormContext();

  const title = getPricingTypesList(aPackage).find(({ value }) => value === values.pricingType)?.title;

  return title ? <Text.B2>{title}</Text.B2> : null;
}
