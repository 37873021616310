import { t } from 'i18next';
import { ReportUsageInput } from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchEntitlementsByRefIdAction } from '../customersSlice';

const REPORT_USAGE = gql`
  mutation ReportUsage($input: ReportUsageInput!) {
    reportUsage(input: $input) {
      id
    }
  }
`;

async function reportUsage(
  input: ReportUsageInput,
  { dispatch }: { dispatch: AppDispatch; getState: () => RootState },
) {
  const resourceRefId = input.resourceId ?? undefined;

  await executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate({
        mutation: REPORT_USAGE,
        variables: { input },
      });

      return response.data?.reportUsage;
    },
    {
      successMessage: t('customers.api.addUsageRecord.success'),
      failureMessageHandler: () => t('customers.api.addUsageRecord.fail'),
    },
    dispatch,
  );
  void dispatch(fetchEntitlementsByRefIdAction({ customerRefId: input.customerId, resourceRefId }));
}

export { reportUsage };
