import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { DumpEnvironmentProductCatalogQuery, DumpEnvironmentProductCatalogQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

const ENVIRONMENTS_COMPARISON_QUERY = gql`
  query DumpEnvironmentProductCatalog($input: DumpEnvironmentProductCatalogInput!) {
    dumpEnvironmentProductCatalog(input: $input) {
      dump
    }
  }
`;

type DumpEnvironmentProductCatalogPros = {
  environmentSlug: string;
};

async function dumpEnvironmentProductCatalog(
  { environmentSlug }: DumpEnvironmentProductCatalogPros,
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<
        DumpEnvironmentProductCatalogQuery,
        DumpEnvironmentProductCatalogQueryVariables
      >({
        query: ENVIRONMENTS_COMPARISON_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          input: { environmentSlug },
        },
      });

      return response.data.dumpEnvironmentProductCatalog.dump;
    },
    {},
    dispatch,
  );
}

export { dumpEnvironmentProductCatalog };
