import { Alignment } from '@stigg-types/apiTypes';
import { TypographyConfiguration, defaultTypography } from '../types/typography';
import { LayoutConfiguration } from '../types/layout';

export type PaywallThemeConfiguration = {
  palette: {
    primary: string;
    backgroundPaper: string;
    outlinedBorder: string;
    backgroundHighlight: string;
    text: {
      primary: string;
    };
  };
  typography: TypographyConfiguration;
  layout: LayoutConfiguration;
  css: string;
};

export const defaultPaywallTheme: PaywallThemeConfiguration = {
  palette: {
    primary: '#327EEE',
    backgroundHighlight: '#F5F6F9',
    backgroundPaper: '#FFFFFF',
    outlinedBorder: '#D9DDE9',
    text: {
      primary: '#001E6C',
    },
  },
  typography: defaultTypography,
  layout: {
    alignment: Alignment.Left,
    planWidth: 330,
    planMargin: 20,
    planPadding: 40,
  },
  css: '',
};
