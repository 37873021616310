import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Grid, GridFlex, Skeleton, ConfirmationDialog } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import { arrayIncludes } from '@mui/lab/internal/pickers/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { BreadCrumbMenuItem, useBreadcrumbs } from '@stigg-common';
import { RootState, useAppDispatch } from '../../../../redux/store';
import {
  deleteProductByIdAction,
  fetchProductByRefIdAction,
  resetProduct,
  updateProductAction,
} from '../../productsSlice';
import { Dialog } from '../../../../components/Dialog';
import { EditProductForm } from './EditProductForm';
import ProductDetails from './ProductDetails';
import { ProductOffering } from './ProductOffering';
import Loader from '../../../../components/Loader';
import { EntityMetadataCard } from '../../../../components/entityMetadata/EntityMetadataCard';
import { CustomerJourney } from './customerJourney/CustomerJourney';
import { appRoutes } from '../../../navigation/useNavigation';
import CreateProductForm from '../createProduct/CreateProductForm';
import { navigateTo } from '../../../navigation/actions';
import { CustomerJourneyNotSupported } from './customerJourney/CustomerJourneyNotSupported';
import { DuplicateProductForm } from './DuplicateProductForm';

type ViewProductRouteParams = {
  refId: string;
};

function ProductPage() {
  const theme = useTheme();
  const { refId } = useParams<ViewProductRouteParams>();
  const { autoCancellationRules: autoCancellationRulesEnabled } = useFlags<FeatureFlags>();
  const dispatch = useAppDispatch();
  const product = useSelector((root: RootState) => root.productReducer.product);
  const products = useSelector((root: RootState) => root.productReducer.products);
  const isLoadingProduct = useSelector((root: RootState) => root.productReducer.isLoading);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const hasPlans = !isEmpty(product?.plans);
  const isAwsProduct = !!product.awsMarketplaceProductId;

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete) {
      await dispatch(deleteProductByIdAction(product.id));
    }
    setDeleteDialogOpen(false);
  };

  const breadCrumbs = useMemo(
    () => [
      { to: '/products', title: t('breadCrumbs.products') },
      {
        title: product.displayName || (
          <Skeleton
            variant="text"
            sx={theme.isLightTheme ? undefined : { backgroundColor: theme.itamar.palette.white }}
            animation="wave"
            width={75}
          />
        ),
        menuItems: products.map(
          ({ refId, displayName }) => ({ refId, displayName, to: appRoutes.productPage(refId) } as BreadCrumbMenuItem),
        ),
        onAddEntityClick: () => setCreateDialogOpen(true),
        onAddEntityText: t('products.addNewProduct'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product.displayName, products],
  );

  useBreadcrumbs(...breadCrumbs);

  useEffect(() => {
    const fetchProduct = async () => {
      const { payload: product } = await dispatch(fetchProductByRefIdAction({ refId }));
      if (!product) {
        dispatch(navigateTo(appRoutes.productPage()));
      }
    };

    void fetchProduct();
    return () => {
      dispatch(resetProduct());
    };
  }, [dispatch, refId]);

  if (isLoadingProduct || isEmpty(product)) {
    return <Loader />;
  }

  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    await dispatch(
      updateProductAction({
        productId: product.id,
        updatePayload: { additionalMetaData: metadata },
        refId: product.refId,
      }),
    );
  };

  const hasBothSingleAndMultipleProductsInEnv = arrayIncludes(
    products.map((product) => product.multipleSubscriptions),
    [true, false],
  );

  const showAutoCancellationRules =
    hasBothSingleAndMultipleProductsInEnv && !product.multipleSubscriptions && autoCancellationRulesEnabled;

  return (
    <GridFlex.Column container flexWrap="nowrap" rowSpacing={6}>
      <Grid item>
        <ProductDetails
          setEditDialogOpen={setEditDialogOpen}
          setDuplicateDialogOpen={setDuplicateDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
          product={product}
        />
      </Grid>
      <Grid item>
        <ProductOffering product={product} />
      </Grid>
      {hasPlans && (
        <>
          <Grid item>
            {!isAwsProduct ? (
              <CustomerJourney product={product} showAutoCancellationRules={showAutoCancellationRules} />
            ) : (
              <CustomerJourneyNotSupported text={t('products.customerJourneyNotSupportedInAwsProducts')} />
            )}
          </Grid>
          <Grid item>
            <EntityMetadataCard
              metaData={product.additionalMetaData}
              onSubmit={onSubmitSetMetaData}
              entityTypeName="product"
            />
          </Grid>
        </>
      )}
      <Dialog
        content={<EditProductForm productRefId={product.refId} closeDialog={() => setEditDialogOpen(false)} />}
        titleText={t('products.editProductDialogTitle')}
        isOpen={editDialogOpen}
        onCancel={() => setEditDialogOpen(false)}
        width={730}
      />

      <Dialog
        content={
          <DuplicateProductForm productRefId={product.refId} closeDialog={() => setDuplicateDialogOpen(false)} />
        }
        titleText={t('products.duplicateProductDialogTitle')}
        isOpen={duplicateDialogOpen}
        onCancel={() => setDuplicateDialogOpen(false)}
        width={730}
      />

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('products.delete')}
        content={t('products.deleteConfirmation')}
      />

      <Dialog
        content={<CreateProductForm isAwsProduct={isAwsProduct} onCancel={() => setCreateDialogOpen(false)} />}
        titleText={t('products.createDialogTitle')}
        isOpen={createDialogOpen}
        onCancel={() => setCreateDialogOpen(false)}
        aria-labelledby="create-product-dialog"
        width={730}
      />
    </GridFlex.Column>
  );
}

export default ProductPage;
