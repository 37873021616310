import { Field, Text, Box, InformationTooltip } from '@stigg-components';
import {
  EntitlementResetPeriod,
  MonthlyAccordingTo,
  WeeklyAccordingTo,
  YearlyAccordingTo,
} from '@stigg-types/apiTypes';
import React from 'react';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import { t } from 'i18next';
import { getAccordingToOptions } from '../../../../packages/plans/components/EntitlementRow';
import { SelectableIconButton } from '../../../../widgets/components/leftSidePanel/SelectableIconButton';

type ResetPeriodValue = {
  text: string;
  value: EntitlementResetPeriod | null;
};

const resetPeriods = (): ResetPeriodValue[] => [
  { text: t('entitlements.resetPeriod.never'), value: null },
  { text: t('entitlements.resetPeriod.everyMonth'), value: EntitlementResetPeriod.Month },
  { text: t('entitlements.resetPeriod.everyWeek'), value: EntitlementResetPeriod.Week },
  { text: t('entitlements.resetPeriod.everyDay'), value: EntitlementResetPeriod.Day },
  { text: t('entitlements.resetPeriod.everyHour'), value: EntitlementResetPeriod.Hour },
  { text: t('entitlements.resetPeriod.everyYear'), value: EntitlementResetPeriod.Year },
];

function getResetPeriodConfigurationTooltipText(
  resetPeriod: EntitlementResetPeriod | null | undefined,
  resetPeriodConfigurationOpen: boolean,
): string | undefined {
  if (!resetPeriod) {
    return undefined;
  }

  return resetPeriodConfigurationOpen
    ? t('entitlements.resetSettings.toggle.hide')
    : t('entitlements.resetSettings.toggle.show');
}

type ResetPeriodEntity = {
  resetPeriod?: EntitlementResetPeriod | null;
  resetPeriodConfiguration?: ResetPeriodConfiguration | null;
};

type GetEntitlementResetPeriodFieldsProps<ValueType extends ResetPeriodEntity> = {
  index: number;
  value: ValueType;
  valueId: string;
  resetPeriodConfigurationOpen: boolean;
  setResetPeriodConfigurationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterResetPeriods?: (resetPeriod: ResetPeriodValue) => boolean;
  layout?: 'row' | 'stacked';
};

export function getEntitlementResetPeriodFields<ValueType extends ResetPeriodEntity, FormFieldsType>({
  index,
  value,
  valueId,
  resetPeriodConfigurationOpen,
  setResetPeriodConfigurationOpen,
  filterResetPeriods = () => true,
  layout = 'row',
}: GetEntitlementResetPeriodFieldsProps<ValueType>): Field<FormFieldsType>[] {
  const resetPeriodId = `${valueId}[${index}].resetPeriod`;
  const resetPeriodConfigurationId = `${valueId}[${index}].resetPeriodConfiguration`;

  const resetPeriodSettingsTooltipText = getResetPeriodConfigurationTooltipText(
    value.resetPeriod,
    resetPeriodConfigurationOpen,
  );

  const accordingToOptions = getAccordingToOptions(value.resetPeriod);
  const isResetPeriodConfigurationDisabled = accordingToOptions.length <= 1;

  const fields: Field<FormFieldsType>[] = [];

  const resetPeriodFields: Field<FormFieldsType>[] = [
    {
      type: 'select',
      id: resetPeriodId,
      dataTestId: 'reset-period-select-input',
      values: [
        ...resetPeriods()
          .filter(filterResetPeriods)
          .map(({ value, text }) => ({
            value,
            displayValue: text,
          })),
      ],
      handleChange: ({ handleChange, setFieldValue }) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
          const resetPeriod = e.target.value as EntitlementResetPeriod;
          let resetPeriodConfiguration: ResetPeriodConfiguration | null = null;

          if (resetPeriod === EntitlementResetPeriod.Year) {
            resetPeriodConfiguration = YearlyAccordingTo.SubscriptionStart;
          } else if (resetPeriod === EntitlementResetPeriod.Month) {
            resetPeriodConfiguration = MonthlyAccordingTo.SubscriptionStart;
          } else if (resetPeriod === EntitlementResetPeriod.Week) {
            resetPeriodConfiguration = WeeklyAccordingTo.SubscriptionStart;
          }

          if (!resetPeriod) {
            setResetPeriodConfigurationOpen(false);
          }

          setFieldValue(resetPeriodConfigurationId, resetPeriodConfiguration);
          // Using setTimeout here because otherwise Formik doesn't revalidate the resetPeriodConfiguration field
          // See discussion about it here: https://github.com/jaredpalmer/formik/issues/2059
          setTimeout(() => {
            handleChange(e);
          }, 0);
        };
      },
    },
    {
      type: 'custom',
      gridProps: {
        flex: undefined,
      },
      render: () => (
        <InformationTooltip
          arrow
          placement="top"
          $padding={2}
          title={resetPeriodSettingsTooltipText && <Text.B2>{resetPeriodSettingsTooltipText}</Text.B2>}>
          <Box>
            <SelectableIconButton
              isSelected={resetPeriodConfigurationOpen}
              isDisabled={!value.resetPeriod}
              onClick={() => setResetPeriodConfigurationOpen((prev) => !prev)}
              overrideStroke
              icon={resetPeriodConfigurationOpen ? 'SettingsOpened' : 'SettingsClosed'}
              dataTestId="expand-entitlement-reset-period-settings"
            />
          </Box>
        </InformationTooltip>
      ),
    },
  ];

  if (layout === 'row') {
    fields.push({
      type: 'layout',
      label: t('entitlements.usageReset'),
      layout: 'row',
      contentGridProps: {
        gap: 2,
      },
      fields: resetPeriodFields,
    });
  } else {
    fields.push({
      type: 'custom',
      gridProps: {
        mb: 2,
      },
      render: () => <Text.B2 color="secondary">{t('entitlements.usageReset')}</Text.B2>,
    });
    fields.push({
      type: 'layout',
      contentGridProps: {
        gap: 2,
      },
      fields: resetPeriodFields,
    });
  }

  fields.push({
    type: 'select',
    gridProps: { mt: 2 },
    id: resetPeriodConfigurationId,
    layout: 'row',
    collapsed: !resetPeriodConfigurationOpen,
    hideSelectIcon: isResetPeriodConfigurationDisabled,
    disabled: isResetPeriodConfigurationDisabled,
    values: accordingToOptions.map(({ value, text }) => ({
      value,
      displayValue: text,
    })),
  });

  return fields;
}
