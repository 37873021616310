import { Link, Text } from '@stigg-components';
import { t } from 'i18next';
import { SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { Navigation } from '../../../../../modules/navigation/useNavigation';
import { getPackageTypeDisplay } from '../../../../../modules/packages/common/getPackageTypeDisplay';

export function getPackageLink(task: SubscriptionMigrationTaskFragment, navigation: Navigation) {
  // Taking the first plan which that the user changed
  const aPackage = task.packages.find(({ id }) => id === task.initiatedPackageId);
  if (!aPackage) {
    return <Text.B2>{t('navbar.emptyTaskPackageName')}</Text.B2>;
  }

  const packageTypeDescription = getPackageTypeDisplay(aPackage);
  const title = `${aPackage.displayName} ${packageTypeDescription} (v${aPackage.versionNumber})`;
  const pageLink =
    aPackage.type === 'Plan'
      ? navigation.appRoutes.planPage(aPackage.refId, aPackage.versionNumber)
      : navigation.appRoutes.addonPage(aPackage.refId, aPackage.versionNumber);

  return (
    <Link variant="body2" onClick={() => navigation.navigateTo(pageLink)}>
      {title}
    </Link>
  );
}
