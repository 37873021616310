import { ListItemIcon as MuiListItemIcon } from '@stigg-components';
import styled from 'styled-components/macro';
// eslint-disable-next-line no-restricted-imports
import { MenuItem as MuiMenuItem } from '@mui/material';

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px !important;

  & > * {
    color: ${({ theme }) => theme.itamar.palette.action.active};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.action.hover} !important;
  }

  .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.itamar.palette.action.active} !important;
  }
`;
