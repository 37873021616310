import { Text } from '@stigg-components';
import lowerCase from 'lodash/lowerCase';
import { BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { StripeProductListItem } from './ImportProductsStep';
import { isFeatureMetered } from '../../../../common/featureTypeUtils';
import { currencyPriceFormatter } from '../../../../packages/pricing/components/currency/currencyUtils';

function FeatureUnitText({ featureUnitName, billingModel }: { featureUnitName?: string; billingModel: BillingModel }) {
  return (
    <>
      <Text.B2 display="inline" mr={1}>
        {billingModel === BillingModel.UsageBased ? '/' : 'per'}
      </Text.B2>
      <Text.B2 display="inline" color={featureUnitName ? 'primary' : 'disabled'} $bold>
        {lowerCase(featureUnitName) || 'unit'}
      </Text.B2>
    </>
  );
}

export function PackagePrices({
  aPackage,
  billingModel,
  featureUnitName,
}: {
  aPackage: StripeProductListItem;
  billingModel?: BillingModel;
  featureUnitName?: string;
}) {
  const isPricedPerFeature = billingModel && isFeatureMetered(billingModel);
  const priceNumberByPeriod = (priceAmount: number, billingPeriod?: BillingPeriod | null) =>
    billingPeriod === BillingPeriod.Annually
      ? currencyPriceFormatter({ amount: priceAmount / 12, options: { withCodePostfix: true } })
      : currencyPriceFormatter({ amount: priceAmount, options: { withCodePostfix: true } });
  return (
    <>
      {aPackage.prices.map((price, index) => (
        <Text.B2 key={index} color="secondary" mb={1}>
          ${priceNumberByPeriod(price.amount, price.billingPeriod)}{' '}
          {isPricedPerFeature && <FeatureUnitText featureUnitName={featureUnitName} billingModel={billingModel} />}
          {billingModel === BillingModel.UsageBased ? '' : ' / month'},{' '}
          {price.billingPeriod === BillingPeriod.Monthly
            ? t('integrations.billedMonthly')
            : t('integrations.billedAnnually')}
        </Text.B2>
      ))}
    </>
  );
}
