import { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, Button, Text, GridFlex, Grid, MenuItem, Select, StiggLogo } from '@stigg-components';
import { Department } from '@stigg-types/apiTypes';
import { Formik, FormikHelpers } from 'formik';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useNavigation } from '../../../navigation/useNavigation';
import { addAccountAction } from '../../accountsSlice';
import { updateUserAction } from '../../../auth/authSlice';
import { useAnalytics } from '../../../common/useAnalytics';
import type { CompanySize, SetAccountFormFields } from './SetAccountPage.types';
import { Container, FormWrapper, FadedGrid } from './SetAccountPage.style';

const COMPANY_SIZE_OPTIONS: Record<CompanySize, string> = {
  '1-10': '1 - 10',
  '11-50': '11 - 50',
  '51-100': '51 - 100',
  '101-250': '101 - 250',
  '251-1K': '251 - 1K',
  '1K-5K': '1K - 5K',
  '5K+': '5K+',
};

const DEPARTMENT_OPTIONS: Record<Department, string> = {
  [Department.Engineering]: 'department.display.engineering',
  [Department.Product]: 'department.display.product',
  [Department.Growth]: 'department.display.growth',
  [Department.Marketing]: 'department.display.marketing',
  [Department.Monetization]: 'department.display.monetization',
  [Department.CeoOrFounder]: 'department.display.ceoOrFounder',
  [Department.Other]: 'department.display.other',
};

const validationSchema = () =>
  Yup.object().shape({
    fullName: Yup.string().required(t('account.new.form.fullNameError')),
    companyName: Yup.string().required(t('account.new.form.companyNameError')),
    companySize: Yup.mixed<CompanySize>()
      .oneOf(Object.keys(COMPANY_SIZE_OPTIONS) as CompanySize[])
      .required(t('account.new.form.companySizeError')),
    yourRole: Yup.mixed<Department>()
      .oneOf(Object.keys(DEPARTMENT_OPTIONS) as Department[])
      .required(t('account.new.form.yourRoleError')),
  });

function SetAccountPage() {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { track, group } = useAnalytics();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  useEffect(() => {
    if ((user?.memberships.length ?? 0) === 0 || isCreatingAccount) {
      return;
    }

    navigation.navigateTo(navigation.getEnvPath('/'));
  }, [navigation, user?.memberships.length, isCreatingAccount]);

  const handleSubmit = useCallback(
    async (values: SetAccountFormFields, actions: FormikHelpers<SetAccountFormFields>) => {
      if (isCreatingAccount || !values.companyName || !values.fullName || !values.companySize || !values.yourRole) {
        return;
      }

      const { companyName, fullName, yourRole: department, companySize } = values;

      setIsCreatingAccount(true);
      try {
        const account = await dispatch(addAccountAction({ noSuccessMessage: true, displayName: companyName })).unwrap();

        try {
          await dispatch(updateUserAction({ name: fullName, department }));
        } catch (e) {
          // Don't throw and fail functionality
          console.error('SetAccountPage updateUserAction error', e);
        }
        const fullNameArr = fullName?.split(' ');
        const firstName = fullNameArr?.shift();
        const lastName = fullNameArr.join(' ');
        track('Account created', {
          account_name: companyName,
          full_name: fullName,
          first_name: firstName,
          last_name: lastName,
          company_size: companySize,
          department,
        });
        group(account.account.id || 'noAccount', {
          company_name: account.account.displayName,
          company_size: companySize,
        });
        window.location.reload();
      } catch (e) {
        console.error('SetAccountPage form error', e);
        actions.setSubmitting(false);
        setIsCreatingAccount(false);
      }
    },
    [dispatch, isCreatingAccount, track, group],
  );

  const initialValues = useMemo<SetAccountFormFields>(
    () => ({
      ...(user?.name && user.name !== user.email ? { fullName: user.name } : {}),
    }),
    [user?.name, user?.email],
  );

  return (
    <Container>
      <FadedGrid />

      <FormWrapper>
        <StiggLogo style={{ alignSelf: 'center' }} />

        <Text.H1 mt={4} color="primary" align="center" mb={3}>
          {t('account.new.title')}
        </Text.H1>
        <Text.B1 color="primary" align="center" mb={9}>
          {t('account.new.subtitle')}
        </Text.B1>
        <Formik
          enableReinitialize
          validationSchema={validationSchema()}
          initialValues={initialValues}
          onSubmit={handleSubmit}>
          {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={4}>
                <Grid item>
                  <TextField
                    name="fullName"
                    label={t('account.new.form.fullName')}
                    placeholder={t('account.new.form.fullNamePlaceholder')}
                    value={values.fullName}
                    touched={!!touched.fullName}
                    error={!!errors.fullName}
                    fullWidth
                    errorText={errors.fullName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="companyName"
                    label={t('account.new.form.companyName')}
                    placeholder={t('account.new.form.companyNamePlaceholder')}
                    value={values.companyName}
                    touched={Boolean(touched.companyName)}
                    error={Boolean(errors.companyName)}
                    fullWidth
                    errorText={errors.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <Select
                    renderValue={(value) => <Text.B2>{COMPANY_SIZE_OPTIONS[value]}</Text.B2>}
                    fullWidth
                    value={values.companySize}
                    name="companySize"
                    labelId="company-size"
                    label={t('account.new.form.companySize')}
                    placeholder={t('account.new.form.companySizePlaceholder')}
                    onChange={handleChange}>
                    {Object.entries(COMPANY_SIZE_OPTIONS).map(([value, title]) => (
                      <MenuItem key={value} value={value}>
                        <Text.B2 color="primary">{title}</Text.B2>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <Select
                    renderValue={(value) => <Text.B2>{t(DEPARTMENT_OPTIONS[value])}</Text.B2>}
                    fullWidth
                    value={values.yourRole}
                    name="yourRole"
                    labelId="your-role"
                    label={t('account.new.form.yourRole')}
                    placeholder={t('account.new.form.yourRolePlaceholder')}
                    onChange={handleChange}>
                    {Object.entries(DEPARTMENT_OPTIONS).map(([value, title]) => (
                      <MenuItem key={value} value={value}>
                        <Text.B2 color="primary">{t(title)}</Text.B2>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item container mt={13}>
                  <Button
                    disabled={!dirty || !isValid || isCreatingAccount}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth>
                    {t('account.new.form.submit')}
                  </Button>
                </Grid>
              </GridFlex.Column>
            </form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
}

export default SetAccountPage;
