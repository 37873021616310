import { IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { upperFirst } from 'lodash';
import { BILLING_VENDORS } from '../modules/integrations/constants';

export function getBillingIntegration(integrations: IntegrationListItemFragment[]) {
  const billingIntegrations = integrations.filter((x) => BILLING_VENDORS.has(x.vendorIdentifier));
  if (!billingIntegrations.length) {
    return null;
  }
  if (billingIntegrations.length > 1) {
    return null;
  }
  return billingIntegrations[0];
}

export function getReadableVendorIdentifier(vendorIdentifier: string) {
  return upperFirst(vendorIdentifier.toLowerCase());
}
