import { gql } from '@apollo/client';
import { Dispatch } from '@reduxjs/toolkit';
import {
  FetchSubscriptionMigrationTasksQuery,
  FetchSubscriptionMigrationTasksQueryVariables,
  SubscriptionMigrationTaskFilter,
  TaskStatus,
  TaskType,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';

const FETCH_SUBSCRIPTION_MIGRATION_TASKS = gql`
  query FetchSubscriptionMigrationTasks($filter: SubscriptionMigrationTaskFilter) {
    subscriptionMigrationTasks(filter: $filter) {
      edges {
        node {
          ...SubscriptionMigrationTaskFragment
        }
      }
    }
  }
  fragment SubscriptionMigrationTaskFragment on SubscriptionMigrationTask {
    id
    status
    taskType
    progress
    initiatedPackageId
    createdAt
    packages {
      id
      displayName
      versionNumber
      type
      refId
    }
    startDate
    affectedCustomersCount
  }
`;

type FetchSubscriptionMigrationTasksProps = {
  environmentId: string;
  taskIds?: string[];
};

async function fetchSubscriptionMigrationTasks(
  { environmentId, taskIds }: FetchSubscriptionMigrationTasksProps,
  { dispatch }: { dispatch: Dispatch },
) {
  return executeOperationSafely(
    async () => {
      const filter: SubscriptionMigrationTaskFilter = {
        environmentId: { eq: environmentId },
      };
      if (taskIds) {
        filter.id = { in: taskIds };
      } else {
        filter.status = { in: [TaskStatus.Pending, TaskStatus.InProgress] };
      }

      const response = await apolloClient.query<
        FetchSubscriptionMigrationTasksQuery,
        FetchSubscriptionMigrationTasksQueryVariables
      >({
        query: FETCH_SUBSCRIPTION_MIGRATION_TASKS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            taskType: { in: [TaskType.SubscriptionMigration, TaskType.SubscriptionMigrationV2] },
            ...filter,
          },
        },
      });

      return response.data.subscriptionMigrationTasks.edges.map((x) => x.node);
    },
    {},
    dispatch,
  );
}

export { fetchSubscriptionMigrationTasks };
