import {
  drawFormFields,
  FormProps,
  FormRenderProps,
  Grid,
  GridFlex,
  Icon,
  LongText,
  StepAccordion,
  Text,
} from '@stigg-components';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { AddedAddonOrAddonGroup, NewAddonAndAddonGroupsFormFields } from './AddCompatibleAddonOrAddonGroupDialog';
import { CompatiblePackageGroup } from '../../../../common/packageUtils';

type AddedAddonGroupProps = {
  addonGroup: CompatiblePackageGroup;
  index: number;
  remove: (index: number) => void;
  replace: (index: number, value: AddedAddonOrAddonGroup) => void;
  formRenderProps: FormRenderProps<NewAddonAndAddonGroupsFormFields>;
};

export function AddedAddonGroupLabel({
  packageGroup,
  minItems,
  showOptions,
  freeItems,
}: {
  packageGroup: CompatiblePackageGroup;
  showOptions: boolean;
  minItems?: number;
  freeItems?: number;
}) {
  const { packageGroupId, displayName } = packageGroup;
  return (
    <GridFlex.Column>
      <GridFlex.RowMiddle container gap={4} wrap="nowrap">
        <Icon type="custom" icon="AddonGroup" />
        <GridFlex.Column container item>
          <Grid item gap={2}>
            <LongText>
              <Text.B2>{displayName}</Text.B2>
            </LongText>
          </Grid>
          <Grid item>
            <LongText>
              <Text.B2 color="secondary">{packageGroupId}</Text.B2>
            </LongText>
          </Grid>
        </GridFlex.Column>
      </GridFlex.RowMiddle>
      {showOptions && (
        <GridFlex.Column pt={2}>
          {minItems && (
            <Text.B2 color="secondary">
              <Trans t={t} i18nKey="plans.compatiblePackageGroupMinItems" values={{ minItems }} />
            </Text.B2>
          )}
          {freeItems && (
            <Text.B2 color="secondary">
              <Trans t={t} i18nKey="plans.compatiblePackageGroupFreeItems" values={{ freeItems }} />
            </Text.B2>
          )}
        </GridFlex.Column>
      )}
    </GridFlex.Column>
  );
}

export const AddedAddonGroupItem = React.forwardRef<HTMLDivElement, AddedAddonGroupProps>(
  ({ remove, replace, addonGroup, index, formRenderProps }, ref) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { planFreeItemsFromAddonGroup: isPlanFreeItemsFromAddonGroupEnabled } = useFlags<FeatureFlags>();
    const currentValue = formRenderProps.values.addedEntities[index];

    const minItemsFieldId = `addedEntities[${index}].minItems`;
    const freeItemsFieldId = `addedEntities[${index}].freeItems`;

    const minItemsValue = currentValue.minItems;
    const freeItemsValue = currentValue.freeItems;

    const fields: FormProps<NewAddonAndAddonGroupsFormFields>['fields'] = [
      {
        id: minItemsFieldId,
        type: 'text',
        label: t(`plans.compatiblePackageGroupMinItemsFormLabel`),
        autoFocus: true,
        endAdornment: 'add-on',
        layout: 'row',
        textFieldType: 'number',
        isNumberWithoutFraction: true,
        isNumberWithoutSigns: true,
        handleChange: ({ setFieldValue }: FormRenderProps<NewAddonAndAddonGroupsFormFields>) => {
          return (e: any) => {
            const numberValue = parseInt(e?.target?.value, 10);
            replace(index, { ...currentValue, minItems: numberValue });
            setFieldValue(minItemsFieldId, numberValue);
          };
        },
      },
    ];

    if (isPlanFreeItemsFromAddonGroupEnabled) {
      fields.push({
        id: freeItemsFieldId,
        type: 'text',
        label: t(`plans.compatiblePackageGroupFreeItemsFormLabel`),
        autoFocus: true,
        endAdornment: 'add-on',
        layout: 'row',
        textFieldType: 'number',
        isNumberWithoutFraction: true,
        isNumberWithoutSigns: true,
        handleChange: ({ setFieldValue }: FormRenderProps<NewAddonAndAddonGroupsFormFields>) => {
          return (e: any) => {
            const numberValue = parseInt(e?.target?.value, 10);
            replace(index, { ...currentValue, freeItems: numberValue });
            setFieldValue(freeItemsFieldId, numberValue);
          };
        },
      });
    }

    return (
      <StepAccordion
        onToggle={() => setExpanded(!expanded)}
        onRemove={() => remove(index)}
        confirmRemoveDialogTitle=""
        confirmRemoveDialogContent=""
        withRemoveConfirmation={false}
        ref={ref}
        header={
          <AddedAddonGroupLabel
            packageGroup={addonGroup}
            minItems={minItemsValue}
            freeItems={freeItemsValue}
            showOptions={!expanded}
          />
        }
        isFocused={expanded}>
        {drawFormFields(fields, formRenderProps)}
      </StepAccordion>
    );
  },
);
