import { gql } from '@apollo/client';
import { t } from 'i18next';
import { SetPackageGroupAddonsMutation, SetPackageGroupAddonsMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch } from '../../../../redux/store';
import { fetchAddonGroupByRefIdAction } from '../addonSlice';

const SET_PACKAGE_GROUP_ADDONS = gql`
  mutation SetPackageGroupAddons($input: SetPackageGroupAddons!) {
    setPackageGroupAddons(input: $input) {
      productId
    }
  }
`;

async function setAddonsToAddonGroup(
  { packageGroupId, environmentId, addonIds }: { packageGroupId: string; environmentId: string; addonIds: string[] },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<SetPackageGroupAddonsMutation, SetPackageGroupAddonsMutationVariables>(
        {
          mutation: SET_PACKAGE_GROUP_ADDONS,
          variables: { input: { environmentId, packageGroupId, addons: addonIds } },
        },
      );

      await dispatch(fetchAddonGroupByRefIdAction({ refId: packageGroupId }));
      return response.data?.setPackageGroupAddons;
    },
    {
      failureMessageHandler: () => t('plans.api.failAddingAddonsToAddonGroup'),
    },
    dispatch,
  );
}

export { setAddonsToAddonGroup };
