import { createContext, useCallback, useContext, useState } from 'react';

export type ChartLastUpdate = {
  title: string;
  lastUpdate: Date;
};

export type SetChartLastUpdate = (title: string, lastUpdate?: Date) => void;

type SetPriceWizardContextType = {
  chartsLastUpdate: ChartLastUpdate[];
  setChartLastUpdate: SetChartLastUpdate;
};

const ChartsLastUpdateContext = createContext<SetPriceWizardContextType | null>(null);

export function useChartsLastUpdateContext() {
  const ctx = useContext(ChartsLastUpdateContext);
  if (!ctx) {
    throw new Error('Could not find ChartsLastUpdateProvider');
  }
  return ctx;
}

export function ChartsLastUpdateProvider({ children }: { children: React.ReactNode }) {
  const [chartsLastUpdate, setChartsLastUpdate] = useState<ChartLastUpdate[]>([]);

  const setChartLastUpdate: SetChartLastUpdate = useCallback((title: string, lastUpdate?: Date) => {
    setChartsLastUpdate((prevState) => {
      const newState = prevState.filter((chart) => chart.title !== title);
      if (lastUpdate) {
        newState.push({ title, lastUpdate });
      }
      newState.sort((a, b) => a.title.localeCompare(b.title));
      return newState;
    });
  }, []);

  const value = {
    chartsLastUpdate,
    setChartLastUpdate,
  };

  return <ChartsLastUpdateContext.Provider value={value}>{children}</ChartsLastUpdateContext.Provider>;
}
