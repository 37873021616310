import { GridFlex, Text } from '@stigg-components';
import React, { useCallback } from 'react';
import { TooltipModel } from 'chart.js';
import isEmpty from 'lodash/isEmpty';
import { Feature } from '@stigg-types/apiTypes';
import {
  ChartJsExternalTooltip,
  ExternalTooltipInfo,
  ExternalTooltipTranslateFunc,
  bottomBarTooltipPositioner,
  useChartJsExternalTooltip,
} from '../../../../common/chartjs/chartjs.tooltip.plugin';
import { RoundedSideColorMarker } from '../../../../common/chartjs/DefaultChartTooltip';
import { numberFullFormatter } from '../../../../common/formatters';

export type TopCustomersUsageChartTooltipData = {
  customerName: string;
  usage: number;
  units: string;
  color: string;
};

export type TopCustomersUsageChartTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<TopCustomersUsageChartTooltipData>;
};

export function TopCustomersUsageChartTooltip({ tooltipInfo }: TopCustomersUsageChartTooltipProps) {
  if (!tooltipInfo.payload) {
    return <></>;
  }

  const { customerName, usage, units, color } = tooltipInfo.payload;

  return (
    <ChartJsExternalTooltip tooltipInfo={tooltipInfo} placement="left">
      <GridFlex.Row>
        <RoundedSideColorMarker style={{ backgroundColor: color }} />
        <GridFlex.ColumnCenter>
          <Text.B2 color="primary" className="fs-mask">
            {customerName}
          </Text.B2>
          <GridFlex.Row mt={1}>
            <Text.B2 color="primary">{numberFullFormatter(usage)}</Text.B2>
            <Text.B2 color="secondary" ml={1}>
              {units}
            </Text.B2>
          </GridFlex.Row>
        </GridFlex.ColumnCenter>
      </GridFlex.Row>
    </ChartJsExternalTooltip>
  );
}

export function useTopCustomersUsageChartTooltip(feature: Feature) {
  const translate = useCallback<ExternalTooltipTranslateFunc<'bar', TopCustomersUsageChartTooltipData>>(
    (tooltip: TooltipModel<'bar'>) => {
      if (isEmpty(tooltip.dataPoints) || isEmpty(tooltip.labelColors)) {
        return undefined;
      }

      const index = 0;
      const customerName = tooltip.title[0];
      const usage = tooltip.dataPoints[index].parsed.x;
      const color = tooltip.labelColors[index].borderColor as string;
      const units = feature.featureUnitsPlural as string;

      return {
        customerName,
        color,
        usage,
        units,
      };
    },
    [feature],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsExternalTooltip<'bar', TopCustomersUsageChartTooltipData>(
    translate,
    bottomBarTooltipPositioner,
  );

  return { tooltipInfo, tooltipHandler };
}
