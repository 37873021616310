import { gql } from '@apollo/client';
import { RESET_PERIOD_CONFIGURATION } from '../../../entitlements/queries/resetPeriodConfigurationFragment';
import { PRICE_FRAGMENT } from './priceFragment';
import { OVERAGE_PRICE_FRAGMENT } from './overagePriceFragment';
import { MINIMUM_SPEND_FRAGMENT } from './minimumSpendFragment';

export const PLAN_LIST_FRAGMENT = gql`
  fragment PlanListFragment on Plan {
    id
    refId
    displayName
    description
    status
    billingId
    billingLinkUrl
    type
    updatedAt
    versionNumber
    hiddenFromWidgets
    awsMarketplacePlanDimension
    draftSummary {
      version
      updatedAt
      updatedBy
    }
    isLatest
    defaultTrialConfig {
      units
      duration
      budget {
        limit
      }
    }
    basePlan {
      id
      displayName
      refId
      isLatest
      versionNumber
    }
    isParent
    product {
      id
      displayName
      refId
      multipleSubscriptions
      awsMarketplaceProductId
      awsMarketplaceProductCode
      productSettings {
        subscriptionEndSetup
        subscriptionCancellationTime
        downgradePlan {
          refId
          displayName
        }
        subscriptionStartPlan {
          refId
        }
      }
    }
    compatibleAddons {
      id
      refId
    }
    compatiblePackageGroups {
      packageGroupId
      addons {
        id
        refId
      }
    }
    entitlements {
      ...PlanListEntitlementFragment
    }
    inheritedEntitlements {
      ...PlanListEntitlementFragment
    }
    prices {
      ...PriceFragment
    }
    overagePrices {
      ...OveragePriceFragment
    }
    minimumSpend {
      ...MinimumSpendFragment
    }
    pricingType
  }
  fragment PlanListEntitlementFragment on PackageEntitlement {
    id
    usageLimit
    packageId
    featureId
    hasUnlimitedUsage
    hasSoftLimit
    isCustom
    resetPeriod
    resetPeriodConfiguration {
      __typename
      ... on YearlyResetPeriodConfig {
        ...YearlyResetPeriodConfigFragment
      }
      ... on MonthlyResetPeriodConfig {
        ...MonthlyResetPeriodConfigFragment
      }
      ... on WeeklyResetPeriodConfig {
        ...WeeklyResetPeriodConfigFragment
      }
    }
    feature {
      id
      displayName
      description
      updatedAt
      refId
      featureStatus
      environmentId
      featureType
      meterType
      featureUnits
      featureUnitsPlural
    }
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
  ${MINIMUM_SPEND_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
`;
