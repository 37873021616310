import { t } from 'i18next';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { IntegrationFragment, BigQueryCredentialsInput, VendorIdentifier } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { useBigQueryIntegrationWizardSteps } from './useBigQueryIntegrationWizardSteps';
import { createIntegrationAction, fetchIntegrationByVendorAction } from '../../integrationsSlice';
import { IntegrationContactUsState } from '../IntegrationContactUsState';
import { useBooleanEntitlement } from '../../../../doggo/hooks/useBooleanEntitlement';
import { StiggFeature } from '../../../../doggo/StiggFeature';

type BigQueryIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function BigQueryIntegrationPage({ integration, headerComponent }: BigQueryIntegrationPageProps) {
  const dispatch = useAppDispatch();
  const hasIntegration = !!integration?.credentials;
  const [isLoading, setIsLoading] = useState(false);
  const entitlement = useBooleanEntitlement(StiggFeature.BIGQUERY_INTEGRATION);

  const credentials: BigQueryCredentialsInput = {
    projectId: '',
    datasetId: '',
    datasetLocation: '',
    gcsBucketName: '',
    gcsBucketPath: '',
    hmacKeyAccessId: '',
    hmacKeySecret: '',
    ...(integration ? { ...integration.credentials, credentialsJson: '********' } : { credentialsJson: '' }),
  };

  const steps = useBigQueryIntegrationWizardSteps({
    hasIntegration,
    credentials,
    isLoading,
    onAddIntegration: async (credentials) => {
      setIsLoading(true);

      try {
        await dispatch(
          createIntegrationAction({
            vendorIdentifier: VendorIdentifier.BigQuery,
            bigQueryCredentials: credentials,
          }),
        ).unwrap();

        await dispatch(
          fetchIntegrationByVendorAction({
            vendorIdentifier: VendorIdentifier.BigQuery,
          }),
        );
      } catch (e) {
        setIsLoading(false);
      }
    },
  });

  if (!hasIntegration && !entitlement.hasAccess) {
    return <IntegrationContactUsState headerComponent={headerComponent} integrationType={VendorIdentifier.BigQuery} />;
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      <Text.H6 mb={4}>{t('integrations.bigQuery.integrateWithStiggTitle')}</Text.H6>

      <WizardAccordion
        layoutProps={{ maxWidth: '100%' }}
        uniqueFlowId="big-query-connect-stigg"
        steps={steps}
        isStepByStep={!hasIntegration}
      />
    </>
  );
}
