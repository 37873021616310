import { GridFlex, Divider, Text } from '@stigg-components';

type SectionTitleProps = {
  title?: string;
};

export function SectionTitle({ title }: SectionTitleProps) {
  return (
    <GridFlex.RowMiddle item my={4}>
      {title ? (
        <Text.Caption color="secondary" pr={2}>
          {title}
        </Text.Caption>
      ) : null}
      <Divider my={2} sx={{ flex: 1 }} />
    </GridFlex.RowMiddle>
  );
}
