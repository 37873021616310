import {
  Box,
  Divider,
  Grid,
  GridFlex,
  Text,
  Select,
  MenuItem,
  Icon,
  InformationTooltip,
  LongText,
} from '@stigg-components';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBreadcrumbs } from '@stigg-common';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { fetchFeatureInspectorFiltersAction, selectProduct } from '../featureInspectorSlice';
import { Filters } from './Filters';
import { UsageDistributionWidget } from './widgets/usageDistribution/UsageDistributionWidget';
import { MedianFeatureUsageWidget } from './widgets/medianFeatureUsage/MedianFeatureUsageWidget';
import { TopCustomersUsageWidget } from './widgets/topCustomersUsage/TopCustomersUsageWidget';
import { SERIES_COLORS } from '../../subscriptionsOverview/components/constants';
import {
  NoMeteredFeaturesMessage,
  NoPlansAvailableMessage,
  NoPlansSelectedMessage,
  NotCompatibleWithMultipleSubscriptions,
} from './widgets/ChartPlaceholders';
import { ChartsLastUpdate } from '../../common/chartsLastUpdate/ChartsLastUpdate';
import { ChartsLastUpdateProvider } from '../../common/chartsLastUpdate/ChartsLastUpdateProvider';

export const FIRST_WIDGET_HEIGHT = 487;

const DAYS_BACK = 30;
const NUMBER_OF_CUSTOMERS_TO_SHOW = 6;

export function FeatureInspector() {
  const dispatch = useAppDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId as string);
  const {
    isLoading,
    selectedFeature,
    selectedPlan,
    filteredPlans,
    showUncompletedPeriod,
    selectedProduct,
    availableProducts,
  } = useSelector((state: RootState) => state.dashboards.featureInspector);

  const color = useMemo(() => {
    if (selectedPlan) {
      const index = filteredPlans.findIndex((plan) => plan.refId === selectedPlan.refId);
      if (index > -1) {
        return SERIES_COLORS[index % SERIES_COLORS.length];
      }
    }
    // as fallback take the last color
    return SERIES_COLORS[SERIES_COLORS.length - 1];
  }, [selectedPlan, filteredPlans]);

  useBreadcrumbs({ title: t('breadCrumbs.dashboard') }, { title: t('dashboards.featureInspector.crumb') });

  useEffect(() => {
    void dispatch(fetchFeatureInspectorFiltersAction());
  }, [dispatch]);

  if (isLoading || !environmentId) {
    return <Loader />;
  }

  const noCompatibleProducts =
    availableProducts?.length > 0 && availableProducts.every(({ multipleSubscriptions }) => multipleSubscriptions);
  let content;

  if (!selectedFeature) {
    content = <NoMeteredFeaturesMessage />;
  } else if (!selectedPlan) {
    content = filteredPlans.length > 0 ? <NoPlansSelectedMessage /> : <NoPlansAvailableMessage />;
  } else {
    content = (
      <Grid display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={4}>
        <UsageDistributionWidget
          environmentId={environmentId}
          feature={selectedFeature}
          plan={selectedPlan}
          availablePlans={filteredPlans}
          showUncompletedPeriod={showUncompletedPeriod}
          color={color}
        />
        <MedianFeatureUsageWidget
          environmentId={environmentId}
          feature={selectedFeature}
          plan={selectedPlan}
          daysBack={DAYS_BACK}
          showUncompletedPeriod={showUncompletedPeriod}
          color={color}
        />
        <TopCustomersUsageWidget
          environmentId={environmentId}
          feature={selectedFeature}
          plan={selectedPlan}
          numberOfCustomersToShow={NUMBER_OF_CUSTOMERS_TO_SHOW}
          showUncompletedPeriod={showUncompletedPeriod}
          color={color}
        />
      </Grid>
    );
  }

  return (
    <ChartsLastUpdateProvider>
      <GridFlex.RowCenter>
        <GridFlex.Column container>
          <GridFlex.RowCenter justifyContent="space-between">
            <Text.H1 gutterBottom>{t('dashboards.featureInspector.title')}</Text.H1>
            {availableProducts.length > 1 && (
              <Box>
                <Select
                  maxWidth={300}
                  value={selectedProduct}
                  onChange={(e) => {
                    return dispatch(selectProduct(e.target.value as string));
                  }}
                  renderValue={() => (
                    <GridFlex.RowCenter>
                      <Icon icon="Grid" color="active" />
                      <LongText variant="body2" sx={{ marginX: 2 }}>
                        {`Product: ${selectedProduct?.displayName || '-'}`}
                      </LongText>
                    </GridFlex.RowCenter>
                  )}>
                  {availableProducts.map(({ id, displayName, multipleSubscriptions }) => {
                    const content = (
                      <MenuItem key={id} value={id} disabled={multipleSubscriptions}>
                        <LongText variant="body2" color="primary">
                          {`${displayName}${
                            multipleSubscriptions ? ` ${t('dashboards.featureInspector.comingSoon')}` : ''
                          }`}
                        </LongText>
                      </MenuItem>
                    );

                    if (!multipleSubscriptions) {
                      return content;
                    }

                    return (
                      <InformationTooltip
                        key={id}
                        arrow
                        placement="right"
                        $maxWidth={235}
                        $padding={2}
                        title={
                          <Text.B2>{t('dashboards.featureInspector.multipleSubscriptionsDataIsComingSoon')}</Text.B2>
                        }>
                        <Box sx={{ cursor: 'not-allowed' }}>{content}</Box>
                      </InformationTooltip>
                    );
                  })}
                </Select>
              </Box>
            )}
          </GridFlex.RowCenter>
          <Text.B2 color="secondary">{t('dashboards.featureInspector.subtitle')}</Text.B2>
        </GridFlex.Column>
      </GridFlex.RowCenter>

      {noCompatibleProducts ? (
        <Box mt={4}>
          <NotCompatibleWithMultipleSubscriptions />
        </Box>
      ) : (
        <>
          <Divider my={4} />

          <Filters />

          {content}

          <ChartsLastUpdate />
        </>
      )}
    </ChartsLastUpdateProvider>
  );
}
