import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CheckoutConfiguration,
  FetchCheckoutConfigurationQuery,
  FetchCheckoutConfigurationQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { TYPOGRAPHY_CONFIGURATION } from './typography';

const FETCH_CHECKOUT_CONFIGURATION = gql`
  query FetchCheckoutConfiguration($input: GetWidgetConfigurationInput!) {
    widgetConfiguration(input: $input) {
      checkout {
        ...CheckoutConfigurationFragment
      }
    }
  }
  fragment CheckoutConfigurationFragment on CheckoutConfiguration {
    palette {
      primary
      textColor
      backgroundColor
      borderColor
      summaryBackgroundColor
    }
    typography {
      ...TypographyConfigurationFragment
    }
    customCss
    content {
      collectPhoneNumber
    }
  }
  ${TYPOGRAPHY_CONFIGURATION}
`;

export type FetchCheckoutConfigurationProps = {
  environmentId: string;
};

async function fetchCheckoutConfiguration(
  props: FetchCheckoutConfigurationProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<CheckoutConfiguration | undefined | null> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<
        FetchCheckoutConfigurationQuery,
        FetchCheckoutConfigurationQueryVariables
      >({
        query: FETCH_CHECKOUT_CONFIGURATION,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: props.environmentId,
          },
        },
      });

      return response.data.widgetConfiguration.checkout;
    },
    {
      failureMessageHandler: () => t('widgets.api.failedFetchConfiguration'),
    },
    dispatch,
  );
}

export { fetchCheckoutConfiguration };
