import { BillingCadence, BillingModel, BillingPeriod, PriceFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { currencyPriceFormatter } from '../components/currency/currencyUtils';
import { getCalculatedPrice } from './getCalculatedPrice';
import { getPrice, GetPriceOptions, Money } from './getPrice';

export const MAX_FRACTION_DIGITS = 5;

type GetPeriodOptions = {
  reversedPeriod?: boolean;
  shortFormat?: boolean;
  unitFormat?: boolean;
  tiersAsUnit?: boolean;
  noBillingPeriod?: boolean;
};
export type GetPriceAndPeriodOptions = GetPriceOptions & GetPeriodOptions;

function reversePeriod(billingPeriod: BillingPeriod) {
  return billingPeriod === BillingPeriod.Monthly ? BillingPeriod.Annually : BillingPeriod.Monthly;
}

export function validateMaximumFractionDigits(
  val: number | undefined,
  numberOfDecimalDigits: number = MAX_FRACTION_DIGITS,
) {
  const maximumFractionDigitsRegex = new RegExp(`^\\d+(\\.\\d{0,${numberOfDecimalDigits}})?$`);
  return val ? maximumFractionDigitsRegex.test(val.toString()) : true;
}

export function roundMaximumFractionDigits(val: number): number {
  return parseFloat(val.toFixed(MAX_FRACTION_DIGITS));
}

export const getPeriodFormat = (price: PriceFragment, options: GetPeriodOptions = {}) => {
  const { billingPeriod, feature, blockSize } = price;
  const { reversedPeriod, shortFormat, unitFormat, tiersAsUnit, noBillingPeriod } = options;
  const period = reversedPeriod ? reversePeriod(billingPeriod) : billingPeriod;
  const billingPeriodFormat = period === BillingPeriod.Monthly ? 'month' : 'year';
  const isTieredPrice = !!price.tiersMode;

  if (feature && (!isTieredPrice || tiersAsUnit)) {
    const unitsCount = blockSize || 1;
    const featureUnitsAndBlockSize = `${unitsCount > 1 ? `${blockSize} ` : ''}${feature.featureUnits || ''}`;

    if (price.billingModel === BillingModel.UsageBased) {
      if (unitFormat) {
        return t('pricing.unit', { count: unitsCount });
      }
      return featureUnitsAndBlockSize;
    }
    if (unitFormat) {
      return `${t('pricing.unit', { count: unitsCount })} / ${billingPeriodFormat}`;
    }
    if (noBillingPeriod) {
      return shortFormat ? featureUnitsAndBlockSize : `per ${featureUnitsAndBlockSize}`;
    }
    return shortFormat
      ? `${featureUnitsAndBlockSize} / ${billingPeriodFormat}`
      : `per ${featureUnitsAndBlockSize} / per ${billingPeriodFormat}`;
  }

  return shortFormat
    ? t(`pricing.shortBillingPeriodPriceDescription.${period}`)
    : t(`pricing.billingPeriodPriceDescription.${period}`);
};

export function formatPrice(
  pricePoint: Money,
  billingPeriod: BillingPeriod,
  options?: Pick<GetPriceAndPeriodOptions, 'reversedPeriod' | 'shortFormat'>,
) {
  const priceAmount = options?.reversedPeriod
    ? getCalculatedPrice(pricePoint.amount, billingPeriod)
    : pricePoint.amount;
  const priceFormat = priceAmount.toFixed(MAX_FRACTION_DIGITS);
  const currencyFormattedPrice = currencyPriceFormatter({
    amount: +priceFormat,
    currency: pricePoint.currency,
  });
  return options?.shortFormat ? currencyFormattedPrice : `${currencyFormattedPrice} ${pricePoint.currency}`;
}

export const getPriceFormat = (price: PriceFragment, options: GetPriceAndPeriodOptions = {}) => {
  const pricePoint = getPrice(price, options);
  return formatPrice(pricePoint, price.billingPeriod, options);
};

export const getPriceAndPeriodFormat = (price: PriceFragment, options: GetPriceAndPeriodOptions = {}) => {
  const periodFormat = getPeriodFormat(price, options);
  const priceFormat = getPriceFormat(price, options);
  if (periodFormat && price.billingCadence !== BillingCadence.OneOff) {
    return `${priceFormat} / ${periodFormat}`;
  }
  return priceFormat;
};

export const getBillingModelDescription = (
  billingModel?: BillingModel,
  billingCadence: BillingCadence = BillingCadence.Recurring,
) => {
  const billingCadenceDescription: string = t(`pricing.billingCadenceDescriptions.${billingCadence}`).toString();
  const billingModelDescriptions: string = t(`pricing.newBillingModelDescriptions.${billingModel}`).toString();
  if (billingModel === BillingModel.FlatFee) {
    return `${billingCadenceDescription} ${billingModelDescriptions}`;
  }
  return billingModelDescriptions;
};
