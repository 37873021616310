import { styled } from '@stigg-theme';
import { t } from 'i18next';
import { Text, GridFlex, Icon } from '@stigg-components';

const PriceViewBox = styled(GridFlex.RowCenter)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export function FreePricingView() {
  return (
    <PriceViewBox p={4}>
      <Icon icon="FreePricingType" color="active" overrideStroke />
      <Text.H6 ml={4}>{t('pricing.freePricingType')}</Text.H6>
      <Text.B2 ml={4}>{t('pricing.freePricingViewSubtitle')}</Text.B2>
    </PriceViewBox>
  );
}
