import { ApiKey } from '@stigg-types/apiTypes';
import { useStiggTheme } from '@stigg-theme';
import { Box } from '@stigg-components';
import styled from 'styled-components/macro';
import { ClipboardChip } from '../../../components/clipboard/ClipboardChip';
import { truncateApiKey as truncate } from '../utils/truncate';

type EnvironmentApiKeyProps = { apiKey: ApiKey; truncateApiKey?: boolean };

const ApiKeyBox = styled(Box)`
  width: fit-content;
`;

export function EnvironmentApiKey({ apiKey, truncateApiKey }: EnvironmentApiKeyProps) {
  const { theme } = useStiggTheme();
  const text = truncateApiKey ? truncate(apiKey.token) : apiKey.token;

  return (
    <ApiKeyBox>
      <ClipboardChip
        copy={apiKey.token}
        text={text}
        variant="code"
        iconLocation="right"
        backgroundColor={theme.itamar.palette.action.hover}
      />
    </ApiKeyBox>
  );
}
