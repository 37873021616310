import { GridFlex, Icon, Text } from '@stigg-components';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

export function SetPriceWizardTitle({ aPackage }: { aPackage: PlanFragment | AddonFragment }) {
  const products = useSelector((state: RootState) => state.productReducer.products);
  const hasMultipleProducts = products.length > 1;
  const packageDisplayName =
    aPackage.type === 'Plan'
      ? t('sharedComponents.planSuffix', { planName: aPackage.displayName })
      : t('sharedComponents.addonSuffix', { planName: aPackage.displayName });

  return (
    <GridFlex.RowCenter gap={2}>
      <Icon icon="Edit" type="reactFeather" color="primary.main" size={22} />
      {hasMultipleProducts && aPackage.product && (
        <>
          <Text.B2 color="secondary">{aPackage.product.displayName}</Text.B2>
          <Text.B2 color="secondary">/</Text.B2>
        </>
      )}
      <Text.B2 color="secondary">{packageDisplayName}</Text.B2>
      <Text.B2 color="secondary">/</Text.B2>
      <Text.B2 color="primary">{t('pricing.setPackageWizardTitle')}</Text.B2>
    </GridFlex.RowCenter>
  );
}
