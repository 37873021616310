import * as Yup from 'yup';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from '@stigg-common';
import { PackageStatus } from '@stigg-types/apiTypes';
import { Form, GridFlex, Text } from '@stigg-components';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useNavigation } from '../../../navigation/useNavigation';
import { discardPlanDraftAction, setArchiveDialog } from '../plansSlice';
import { PackageListFragment } from '../../common/components/Packages';
import { navigateTo } from '../../../navigation/actions';
import { useArchivePlanById } from '../mutations/useArchivePlanById';

const validationSchema = (displayName: string) => {
  return Yup.object().shape({
    packageName: Yup.string().equals([displayName], t('features.yup.archiveInputInvalid')),
  });
};

function ArchivePlanContent({ plan }: { plan: PackageListFragment }) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const { mutateAsync: archivePlan } = useArchivePlanById(plan.id);

  const closeModal = () => {
    dispatch(setArchiveDialog({ isOpen: false }));
  };

  const handleSubmit = async () => {
    dispatch(navigateTo(navigation.appRoutes.plansPage()));
    closeModal();

    // plan has 2 mutations, we call the right one based on the plan status
    if (plan.status === PackageStatus.Published) {
      await archivePlan();
      return;
    }

    if (plan.status === PackageStatus.Draft) {
      await dispatch(discardPlanDraftAction(plan.refId));
    }
  };

  return (
    <GridFlex.RowCenter>
      <Form
        disableSaveOnNoChanges
        submitButtonText={t('sharedComponents.archive')}
        saveButtonColor="error"
        onCancel={closeModal}
        initialValues={{}}
        validationSchema={validationSchema(plan.displayName)}
        onSubmit={handleSubmit}
        fields={() => [
          {
            type: 'custom',
            render: () => (
              <Text.B2 mb={2}>
                <Trans
                  i18nKey="plans.archiveModal.archiveWarning"
                  values={{ packageName: plan.displayName }}
                  components={{ strong: <Text.B2 display="inline" $bold /> }}
                />
              </Text.B2>
            ),
          },
          {
            id: 'packageName',
            type: 'text',
            label: <Text.B2 color="primary">{t('packages.archiveModal.confirm', { packageType: 'plan' })} </Text.B2>,
            autoFocus: true,
            placeholder: plan.displayName,
          },
        ]}
      />
    </GridFlex.RowCenter>
  );
}

export function ArchivePlanModal() {
  const dispatch = useAppDispatch();
  const { plan, isOpen } = useSelector((state: RootState) => state.plansReducer.archiveDialog);
  const [ArchivePlanModal, setArchivePlanModal] = useModal({
    title: t('packages.archiveModal.title', { packageType: 'plan' }),
    onCancel: () => dispatch(setArchiveDialog({ isOpen: false })),
  });

  useEffect(() => {
    setArchivePlanModal(isOpen);
  }, [isOpen, setArchivePlanModal]);

  if (!plan) {
    return null;
  }

  return (
    <ArchivePlanModal>
      <ArchivePlanContent plan={plan} />
    </ArchivePlanModal>
  );
}
