import { StiggFeature } from '../StiggFeature';
import { useOptionalStiggContext } from './useOptionalStiggContext';

export function useBooleanEntitlement(feature: StiggFeature) {
  const stigg = useOptionalStiggContext();

  if (!stigg) {
    return { hasAccess: true };
  }

  return stigg.getBooleanEntitlement({
    featureId: feature,
  });
}
