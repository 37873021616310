import { t } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PackageStatus } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useBreadcrumbs, useQueryParam } from '@stigg-common';
import { GridFlex, Loader, PackageHeaderLayout } from '@stigg-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import AddonOverview from './AddonOverview';
import EventLog from '../../../../eventsLog/components/EventLog';
import PackageDetailsDraftAlert from '../../../common/components/PackageDetailsDraftAlert';
import OutdatedVersionBanner from '../../../common/OutdatedVersionBanner';
import { fetchAddonByRefIdAction } from '../../addonSlice';
import { useDiscardChangesDialog } from '../../../common/hooks/useDiscardChangesDialog';
import Tabs from '../../../../../components/tabs/Tabs';
import { setEditMode } from '../../../../application/applicationSlice';
import { isDraftPackage, isOutdatedPackage } from '../../../package.utils';
import { ArchiveAddonModal } from '../ArchiveAddonModal';
import { packageVersionFormat } from '../../../common/components/packageVersionFormat';

type ViewAddonRouteParams = {
  refId: string;
};

export default function AddonPage() {
  const { refId } = useParams<ViewAddonRouteParams>();
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();
  const { value: versionNumber } = useQueryParam('version');
  const dispatch = useAppDispatch();
  const addon = useSelector((root: RootState) => root.addonReducer.addon);
  const isLoadingAddon = useSelector((root: RootState) => root.addonReducer.isLoadingAddon);
  const [DiscardPackageDraftDialog, showDiscardPackageDraftDialog, discardPackageDraftDialogProps] =
    useDiscardChangesDialog(addon);

  const addonVersion = addon?.versionNumber ? packageVersionFormat(addon.versionNumber) : '';

  useBreadcrumbs(
    { to: '/addons', title: t('breadCrumbs.addons') },
    { title: `${refId} ${!isLoadingAddon && addonVersion && !addon?.isLatest ? `(${addonVersion})` : ''}` },
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchAddonByRefIdAction({ refId, versionNumber }));
    };
    void fetchData();
  }, [dispatch, refId, versionNumber]);

  useEffect(() => {
    if (!isLoadingAddon && addon?.status === PackageStatus.Draft && !addon?.isLatest) {
      dispatch(setEditMode(true));
    }

    return () => {
      dispatch(setEditMode(false));
    };
  }, [isLoadingAddon, dispatch, addon?.status, addon?.isLatest]);

  const silentReloadAddonData = async () => {
    await dispatch(fetchAddonByRefIdAction({ refId, versionNumber, silentFetch: true }));
  };

  const onDiscardChanges = () => {
    showDiscardPackageDraftDialog(true);
  };

  if (isLoadingAddon || !addon || !addon.refId) {
    return <Loader />;
  }

  const showDraftAlert = isDraftPackage(addon);
  const showOutdatedBanner = isOutdatedPackage(addon);
  const showTabs = addon.isLatest;

  const addonOverviewComponent = (
    <AddonOverview addon={addon} silentReloadAddonData={silentReloadAddonData} onDiscardChanges={onDiscardChanges} />
  );

  const tabs = [
    {
      title: t('addons.tabs.overview'),
      content: addonOverviewComponent,
    },
    {
      title: t('addons.tabs.events'),
      content: <EventLog entityId={addon.refId} />,
    },
  ];

  return (
    <>
      <GridFlex.Column gap={showEventLog ? 4 : 6} $fullHeight>
        <PackageHeaderLayout title={t('addons.subtitle')} displayName={addon.displayName} aPackage={addon} />
        {showDraftAlert ? <PackageDetailsDraftAlert aPackage={addon} onDiscardChanges={onDiscardChanges} /> : null}
        {showOutdatedBanner && <OutdatedVersionBanner aPackage={addon} />}
        {showEventLog && showTabs ? <Tabs data={tabs} /> : addonOverviewComponent}
      </GridFlex.Column>
      <DiscardPackageDraftDialog {...discardPackageDraftDialogProps} />
      <ArchiveAddonModal />
    </>
  );
}
