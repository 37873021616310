import { Button, Grid, GridFlex, Link, PageCard, Text, Icon } from '@stigg-components';
import { t } from 'i18next';
import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Edit2 } from 'react-feather';
import { styled } from '@stigg-theme';
import { EmptyCardContent } from '../EmptyCardContent';
import Table, { HeadCell } from '../table/Table';
import { SetMetadataDialog } from './SetMetadataDialog';

const EditIcon = styled(Edit2)`
  margin-left: 4px;
`;

export type MetadataKV = {
  key: string;
  value: string;
};
export const metadataToKVArray = (metaData: Record<string, string>): MetadataKV[] | null => {
  if (!metaData) {
    return null;
  }
  return Object.entries(metaData).map(([key, value]) => ({ key, value: value?.toString() }));
};
export const kVArrayToMetadata = (metaDataKv: MetadataKV[]): Record<string, string> | null => {
  if (!metaDataKv) {
    return null;
  }
  const res: Record<string, string> = {};
  metaDataKv.forEach((x) => {
    res[x.key] = x.value;
  });
  return res;
};
const createHeadCells = (): Array<HeadCell<MetadataKV, any>> => [
  {
    id: 'key',
    alignment: 'left',
    label: t('metaData.key'),
    width: 300,
    render: (entity: MetadataKV) => <Text.B2 mr={2}>{entity.key}</Text.B2>,
  },
  {
    id: 'value',
    alignment: 'left',
    label: t('metaData.value'),
    render: (entity: MetadataKV) => <Text.B2>{entity.value}</Text.B2>,
  },
];

type EntityMetadataProps = {
  metaData: any;
  onSubmit: (metadata: Record<string, string>) => Promise<void>;
  entityTypeName: string;
  isReadonly?: boolean;
};

export function EntityMetadata({ metaData, onSubmit, entityTypeName, isReadonly = false }: EntityMetadataProps) {
  const [metadataDialogOpen, setSetMetadataMetadataDialogOpen] = useState(false);
  const hasMetadata = !isEmpty(metaData);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Text.H3>{t('metaData.title')}</Text.H3>
        {!isReadonly && (
          <Button
            color="primary"
            $outlined
            onClick={() => setSetMetadataMetadataDialogOpen(true)}
            startIcon={hasMetadata ? <EditIcon size={16} /> : <Icon icon="Add" type="materialIcons" />}>
            {hasMetadata ? t('sharedComponents.edit') : t('sharedComponents.addSaveButton')}
          </Button>
        )}
      </Grid>
      <Text.Sub2 mb={4} mt={2}>
        {t('metaData.subTitle', { entityTypeName })}
      </Text.Sub2>
      {hasMetadata ? (
        <GridFlex.Column>
          <Table
            label={t('metaData.title')}
            rowHeight={53}
            headCells={createHeadCells()}
            data={metadataToKVArray(metaData) || []}
          />
        </GridFlex.Column>
      ) : (
        <EmptyCardContent>
          <Text.B2>{t('metaData.noMetaData')}</Text.B2>
          {!isReadonly && (
            <Link variant="body2" ml={1} onClick={() => setSetMetadataMetadataDialogOpen(true)}>
              {t('metaData.addMetadata')}
            </Link>
          )}
        </EmptyCardContent>
      )}
      <SetMetadataDialog
        metadata={metadataToKVArray(metaData) || []}
        onSubmit={onSubmit}
        open={metadataDialogOpen}
        setDialogOpen={setSetMetadataMetadataDialogOpen}
      />
    </>
  );
}

export function EntityMetadataCard(props: EntityMetadataProps) {
  return (
    <PageCard>
      <EntityMetadata {...props} />
    </PageCard>
  );
}
