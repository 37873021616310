import { t } from 'i18next';
import { EventActorInfo } from '@stigg-types/apiTypes';
import { GridFlex, Text } from '@stigg-components';
import DetailsRow from './DetailsRow';
import EventActorIcon from '../EventActorIcon';
import { getActorTypeDisplayName, getActorUserInformation } from '../../utils/actor.utils';

const LOCAL_IP_ADDRESSED = ['::1', '127.0.0.1'];

type EventActorSectionProps = {
  actor: EventActorInfo | null | undefined;
};

function ActorTypeRow({ actor }: { actor: EventActorInfo }) {
  const value = (
    <GridFlex.Row gap={2}>
      <EventActorIcon type={actor.type} />
      <Text.B2>{getActorTypeDisplayName(actor.type)}</Text.B2>
    </GridFlex.Row>
  );

  return <DetailsRow title={t('eventsLog.detailsDrawer.actor.type')} value={value} />;
}

function ActorUserRow({ actor }: { actor: EventActorInfo }) {
  const actorUserInformation = getActorUserInformation(actor);

  if (!actorUserInformation) {
    return null;
  }

  return <DetailsRow title={t('eventsLog.detailsDrawer.actor.user')} value={actorUserInformation} />;
}

function ActorIpAddressRow({ actor }: { actor: EventActorInfo }) {
  if (!actor.ipAddress) {
    return null;
  }

  const ipAddress = LOCAL_IP_ADDRESSED.includes(actor.ipAddress) ? 'localhost' : actor.ipAddress;

  return <DetailsRow title={t('eventsLog.detailsDrawer.actor.ipAddress')} value={ipAddress} />;
}

export default function EventActorSection({ actor }: EventActorSectionProps) {
  if (!actor) {
    return null;
  }

  return (
    <GridFlex.Column gap={4}>
      <Text.B2 $bold>{t('eventsLog.detailsDrawer.actor.title')}</Text.B2>
      <ActorTypeRow actor={actor} />
      <ActorUserRow actor={actor} />
      <ActorIpAddressRow actor={actor} />
    </GridFlex.Column>
  );
}
