import { Text, Grid, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { ImportIntegrationTaskResultFragment, SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { MigrationTask } from './MigrationTask';
import * as S from './TasksList.style';
import { ImportTask } from './ImportTask';

export function TasksList({
  subscriptionMigrationTasks,
  importTask,
  close,
}: {
  subscriptionMigrationTasks: SubscriptionMigrationTaskFragment[];
  importTask: ImportIntegrationTaskResultFragment | null;
  close: () => void;
}) {
  return (
    <GridFlex.Column container width={360} p={2}>
      <GridFlex.RowSpaceBetween item $fullWidth mb={2}>
        <Text.H6>{t('navbar.progressTooltipTitle')}</Text.H6>
        <S.TooltipClose onClick={close} />
      </GridFlex.RowSpaceBetween>
      <GridFlex.Column item container rowSpacing={2}>
        {subscriptionMigrationTasks.map((task) => (
          <Grid item>
            <MigrationTask task={task} />
          </Grid>
        ))}
        {importTask && (
          <Grid item>
            <ImportTask task={importTask} />
          </Grid>
        )}
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
