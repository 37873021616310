import { ErrorOutline } from '@mui/icons-material';
import { styled } from '@stigg-theme';
import {
  AlertTitle,
  Alert,
  Checkbox,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Box,
  Text,
  Link,
  Divider,
  GridFlex,
  ExternalLink,
  InformationTooltip,
} from '@stigg-components';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { PlanFragment, PublishMigrationType } from '@stigg-types/apiTypes';
import { AlertTriangle } from 'react-feather';
import { useNavigation } from '../../../navigation/useNavigation';
import { PackageFragment } from './Packages';

const RadioBox = styled(Box)<{ $isSelected: boolean; $errorColor?: boolean }>`
  border: 3px solid
    ${({ theme, $isSelected, $errorColor }) =>
      $isSelected
        ? $errorColor
          ? theme.itamar.palette.error.main
          : theme.itamar.palette.warning.main
        : theme.itamar.palette.action.disabledBackground};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  width: 100%;
  cursor: pointer;
  ${({ $isSelected, $errorColor, theme }) =>
    $isSelected && $errorColor && `background: ${theme.itamar.palette.error.background};`}
`;

export function ChangesMigrationSelection({
  aPackage,
  affectOption,
  setPublishMigrationType,
  checked,
  handleCheckboxChange,
  allowAllCustomersMigration,
}: {
  aPackage: PackageFragment;
  affectOption: PublishMigrationType;
  setPublishMigrationType: (publishMigrationType: PublishMigrationType) => void;
  checked: boolean[];
  handleCheckboxChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowAllCustomersMigration: boolean;
}) {
  const navigation = useNavigation();
  const childPlansWithDraft = aPackage.draftDetails ? (aPackage as PlanFragment).draftDetails?.childPlansWithDraft : [];
  const affectedChildPlans = aPackage.draftDetails ? (aPackage as PlanFragment).draftDetails?.affectedChildPlans : [];
  const customersAffected = aPackage.draftDetails?.customersAffected;

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublishMigrationType((event.target as HTMLInputElement).value as PublishMigrationType);
  };

  if (!isEmpty(childPlansWithDraft)) {
    return (
      <Alert sx={{ width: '100%' }} severity="warning" icon={<AlertTriangle />}>
        <AlertTitle>
          <Text.B2 $bold color="warning">
            {t('packages.canNotPublishChangesConflict')}
          </Text.B2>
        </AlertTitle>
        <Text.B2 color="inherit">
          {t('packages.foundPendingChanges', { count: childPlansWithDraft?.length || 0 })}
        </Text.B2>
        <GridFlex.Column>
          {childPlansWithDraft?.map((childPlan) => (
            <GridFlex.Item>
              <Link
                sx={{ color: 'inherit', textDecoration: 'underline' }}
                onClick={() => navigation.navigateTo(navigation.appRoutes.planPage(childPlan.refId))}>
                {childPlan.product.displayName} / {childPlan.displayName}
              </Link>
            </GridFlex.Item>
          ))}
        </GridFlex.Column>
        <Text.B2 color="inherit" mt={3}>
          {t('packages.proceedToResolveConflict')}
        </Text.B2>
      </Alert>
    );
  }

  if (!customersAffected) {
    return <Text.B2>{t('packages.notAffectingExistingCustomers')}</Text.B2>;
  }

  const learnMoreLink = (
    <ExternalLink
      label={t('sharedComponents.learnMore')}
      url={
        aPackage.type === 'Plan'
          ? 'https://docs.stigg.io/docs/plans#publishing-changes'
          : 'https://docs.stigg.io/docs/add-ons#publishing-changes'
      }
    />
  );

  const blockedPricingMigrationTooltip = (
    <GridFlex.Column>
      <Text.B2>{t('packages.changesToPriceInfoTooltip')}</Text.B2>
      {learnMoreLink}
    </GridFlex.Column>
  );

  return (
    <Grid container>
      <Grid item mb={2}>
        <Text.B2>{t('packages.packageChangesAffect')}</Text.B2>
      </Grid>
      <Grid item width="100%">
        <RadioGroup
          sx={{ width: '100%', cursor: allowAllCustomersMigration ? undefined : 'not-allowed' }}
          aria-label="gender"
          value={affectOption}
          name="publish-affect"
          onChange={onRadioChange}>
          <RadioBox
            width="100%"
            mb={4}
            p={1}
            pl={4}
            sx={{ cursor: allowAllCustomersMigration ? undefined : 'not-allowed' }}
            $isSelected={affectOption === PublishMigrationType.NewCustomers}
            onClick={() => setPublishMigrationType(PublishMigrationType.NewCustomers)}>
            <Radio
              value={PublishMigrationType.NewCustomers}
              color="warning"
              label={
                <>
                  <Text.B2 sx={{ fontSize: 16, display: 'inline' }} mr={2}>
                    {t('packages.newSubscriptionsOnlyTitle')}
                  </Text.B2>
                  <Text.B2 fontWeight="bolder" sx={{ display: 'inline' }}>
                    {t('packages.recommended')}
                  </Text.B2>
                </>
              }
            />
          </RadioBox>
          <InformationTooltip
            placement="top"
            arrow
            title={allowAllCustomersMigration ? '' : blockedPricingMigrationTooltip}>
            <RadioBox
              width="100%"
              data-testid="radio-publish-all-subscriptions"
              p={1}
              px={4}
              sx={{
                backgroundColor: (theme) => (allowAllCustomersMigration ? undefined : theme.itamar.palette.grey[100]),
                cursor: allowAllCustomersMigration ? undefined : 'not-allowed',
              }}
              $isSelected={allowAllCustomersMigration && affectOption === PublishMigrationType.AllCustomers}
              $errorColor
              onClick={() => {
                if (allowAllCustomersMigration) {
                  setPublishMigrationType(PublishMigrationType.AllCustomers);
                }
              }}>
              <Grid container flexDirection="column">
                <GridFlex.RowSpaceBetween container item>
                  <Grid item>
                    <Radio
                      color="error"
                      disabled={!allowAllCustomersMigration}
                      value={PublishMigrationType.AllCustomers}
                      label={
                        <GridFlex.RowCenter sx={{ cursor: allowAllCustomersMigration ? undefined : 'not-allowed' }}>
                          <Text.B2
                            sx={{ fontSize: 16, display: 'inline' }}
                            color={allowAllCustomersMigration ? 'primary' : 'disabled'}
                            mr={2}>
                            {t('packages.allSubscriptionsTitle')}
                          </Text.B2>
                          <Text.B2
                            color={allowAllCustomersMigration ? 'primary' : 'disabled'}
                            fontWeight="bolder"
                            sx={{ display: 'inline' }}>
                            {t('packages.packageChangesSubscriptionsAffected', {
                              subscriptionsAffected: customersAffected,
                            })}
                          </Text.B2>
                        </GridFlex.RowCenter>
                      }
                    />
                  </Grid>
                  <ErrorOutline color="error" />
                </GridFlex.RowSpaceBetween>
                <Collapse in={affectOption === PublishMigrationType.AllCustomers}>
                  <Divider mb={2} />
                  <GridFlex.Column item>
                    <Text.B2 color="error" fontSize={12} mt={2} mb={1}>
                      {t('packages.changesConfirmOptions')}
                    </Text.B2>
                    {!isEmpty(affectedChildPlans) ? (
                      <Box alignItems="flex-start" pt={1} pr={2}>
                        <Checkbox
                          dataTestid="checkbox-migrateallsubscriptions-0"
                          color="error"
                          checked={checked[0]}
                          onChange={handleCheckboxChange(0)}
                          label={
                            <>
                              <Text.B2 sx={{ display: 'inline' }} mr={1}>
                                {t('packages.affectedChildPlans', { affectedPlansLength: affectedChildPlans?.length })}
                              </Text.B2>
                              <Text.B2 fontWeight="bold" sx={{ display: 'inline' }} mr={1}>
                                {affectedChildPlans
                                  ?.map((childPlan) => `${childPlan.product.displayName} / ${childPlan.displayName}`)
                                  .join(', ')}
                              </Text.B2>
                            </>
                          }
                        />
                      </Box>
                    ) : null}
                    <Checkbox
                      color="error"
                      checked={checked[1]}
                      onChange={handleCheckboxChange(1)}
                      dataTestid="checkbox-migrateallsubscriptions-1"
                      label={t('packages.changesAppliedToAllSubscriptions')}
                    />
                    {aPackage.type === 'Plan' && (
                      <Checkbox
                        color="error"
                        checked={checked[2]}
                        onChange={handleCheckboxChange(2)}
                        dataTestid="checkbox-migrateallsubscriptions-2"
                        label={t('packages.changesToTrialWarning')}
                      />
                    )}
                    <Checkbox
                      color="error"
                      checked={checked[3]}
                      onChange={handleCheckboxChange(3)}
                      dataTestid="checkbox-migrateallsubscriptions-3"
                      label={
                        <GridFlex.Row>
                          <Text.B2 mr={1}>{t('packages.changesToPriceWarning')}</Text.B2>
                          {learnMoreLink}
                        </GridFlex.Row>
                      }
                    />
                  </GridFlex.Column>
                </Collapse>
              </Grid>
            </RadioBox>
          </InformationTooltip>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
