import get from 'lodash/get';
import { useEffect } from 'react';
import { BillingModel, EntitlementResetPeriod } from '@stigg-types/apiTypes';
import { drawFormFields, FormRenderProps } from '@stigg-components';
import { SetPriceWizardFormFields, UsageBasedChargeType } from '../../SetPriceWizardForm.types';
import { getEntitlementResetPeriodFields } from '../../../../../../../entitlements/components/entitlementSettings/fields/getEntitlementResetPeriodFields';

type ChargeResetPeriodConfigurationProps = {
  index: number;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  resetPeriodConfigurationOpen: boolean;
  setResetPeriodConfigurationOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ChargeResetPeriodConfiguration({
  index,
  formRenderProps,
  resetPeriodConfigurationOpen,
  setResetPeriodConfigurationOpen,
}: ChargeResetPeriodConfigurationProps) {
  const { values } = formRenderProps;
  const isPaygoCharge = get(values, `charges[${index}].billingModel`) === BillingModel.UsageBased;
  const chargeId = `charges[${index}]`;
  const charge = get(values, chargeId);

  useEffect(() => {
    if (!charge.resetPeriod) {
      setResetPeriodConfigurationOpen(false);
    }
  }, [setResetPeriodConfigurationOpen, charge.resetPeriod]);

  const fields = getEntitlementResetPeriodFields<UsageBasedChargeType, SetPriceWizardFormFields>({
    index,
    value: charge,
    valueId: 'charges',
    resetPeriodConfigurationOpen,
    setResetPeriodConfigurationOpen,
    layout: 'stacked',
    filterResetPeriods: (resetPeriod) =>
      isPaygoCharge ? [EntitlementResetPeriod.Month, null].includes(resetPeriod.value) : true,
  });

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
