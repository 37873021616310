import { t } from 'i18next';
import moment from 'moment';
import { EventLog } from '@stigg-types/apiTypes';
import { ClipboardChip, DateText, GridFlex, Text } from '@stigg-components';
import DetailsRow from './DetailsRow';
import EventEntity from '../common/EventEntity';

type EventDetailsSectionProps = {
  event: EventLog | null | undefined;
};

function EventTimestamp({ event }: { event: EventLog }) {
  const timestamp = (
    <DateText color="secondary" date={moment.utc(event.createdAt).toDate()} withTime formatType="longDate" />
  );

  return <DetailsRow title={t('eventsLog.detailsDrawer.details.timestamp')} value={timestamp} />;
}

function EventTrace({ event }: { event: EventLog }) {
  if (!event.request?.traceId) {
    return null;
  }

  const { traceId } = event.request;

  const traceIdComponent = <ClipboardChip text={traceId} copy={traceId} iconLocation="right" variant="code" />;

  return <DetailsRow title={t('eventsLog.detailsDrawer.details.traceId')} value={traceIdComponent} />;
}

function EventEntityRow({ event }: { event: EventLog }) {
  return <DetailsRow title={t('eventsLog.detailsDrawer.details.entity')} value={<EventEntity event={event} />} />;
}

export default function EventDetailsSection({ event }: EventDetailsSectionProps) {
  if (!event) {
    return null;
  }

  return (
    <GridFlex.Column gap={4}>
      <Text.B2 $bold>{t('eventsLog.detailsDrawer.details.title')}</Text.B2>
      <EventTimestamp event={event} />
      <EventTrace event={event} />
      <EventEntityRow event={event} />
    </GridFlex.Column>
  );
}
