import { PricingType, TrialPeriodUnits } from '@stigg-types/apiTypes';
import { Text } from '@stigg-components';
import { t } from 'i18next';
import { formatTrialPeriod } from '@stigg-common';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { formatTrialSpendLimit } from '../utils/trialSpendLimitFormat';

export function FreeTrialStepSummary() {
  const { formRenderProps, aPackage } = useSetPriceWizardFormContext();

  const { pricingType } = formRenderProps.values;

  const shouldShowFreeTrial =
    aPackage.type === 'Plan' && (pricingType === PricingType.Paid || pricingType === PricingType.Custom);
  if (!shouldShowFreeTrial) {
    return null;
  }

  const { freeTrial } = formRenderProps.values;

  let text = freeTrial.enabled
    ? formatTrialPeriod(freeTrial?.durationDays, TrialPeriodUnits.Day)
    : t('pricing.trials.noTrialSummary');

  if (freeTrial?.spendLimit) {
    const formattedLimit = formatTrialSpendLimit(freeTrial.spendLimit);
    text = `${text}, ${formattedLimit} ${t('pricing.trials.trialSpendLimit').toLowerCase()}`;
  }

  return <Text.B2>{text}</Text.B2>;
}
