import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import {
  DumpEnvironmentForMergeComparisonQuery,
  DumpEnvironmentForMergeComparisonQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

const ENVIRONMENTS_COMPARISON_QUERY = gql`
  query DumpEnvironmentForMergeComparison($input: DumpEnvironmentForForMergeComparisonInput!) {
    dumpEnvironmentForMergeComparison(input: $input) {
      preMergeDump
      postMergeDump
    }
  }
`;

async function dumpEnvironmentForMergeComparison(
  {
    sourceEnvironmentSlug,
    destinationEnvironmentSlug,
  }: { sourceEnvironmentSlug: string; destinationEnvironmentSlug: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<
        DumpEnvironmentForMergeComparisonQuery,
        DumpEnvironmentForMergeComparisonQueryVariables
      >({
        query: ENVIRONMENTS_COMPARISON_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          input: { sourceEnvironmentSlug, destinationEnvironmentSlug },
        },
      });

      return response.data.dumpEnvironmentForMergeComparison;
    },
    {},
    dispatch,
  );
}

export { dumpEnvironmentForMergeComparison };
