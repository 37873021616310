import {
  CustomerSubscriptionSortFields,
  FetchCustomerSubscriptionsQuery,
  FetchCustomerSubscriptionsQueryVariables,
  SortDirection,
  SortNulls,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { PRICE_FRAGMENT } from '../../packages/plans/queries/priceFragment';
import { SUBSCRIPTION_COUPON_FRAGMENT } from '../../coupons/queries/fetchCoupons';
import { Paging } from '../../../components/table/gqlTableHelper';
import { CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE } from '../components/customerPage/customerSubscriptions/CustomerSubscriptions';
import { RESET_PERIOD_CONFIGURATION } from '../../entitlements/queries/resetPeriodConfigurationFragment';
import { SUBSCRIPTION_FUTURE_UPDATE_FRAGMENT } from './subscriptionFutureUpdateFragment';
import { CUSTOMER_RESOURCE_FRAGMENT } from './customerResourceFragment';
import { composeSubscriptionsFilter } from './fetchCustomerSubscriptionsResources';
import { OVERAGE_PRICE_FRAGMENT } from '../../packages/plans/queries/overagePriceFragment';
import { MINIMUM_SPEND_FRAGMENT } from '../../packages/plans/queries/minimumSpendFragment';

const FETCH_CUSTOMER_SUBSCRIPTIONS = gql`
  query FetchCustomerSubscriptions(
    $paging: CursorPaging
    $sorting: [CustomerSubscriptionSort!]
    $filter: CustomerSubscriptionFilter
  ) {
    customerSubscriptions(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...CustomerSubscriptionData
        }
      }
    }
  }
  fragment CustomerSubscriptionData on CustomerSubscription {
    id
    startDate
    endDate
    payingCustomer {
      id
      customerId
      email
      name
      billingId
      billingLinkUrl
    }
    trialEndDate
    cancellationDate
    effectiveEndDate
    currentBillingPeriodEnd
    billingCycleAnchor
    wasInTrial
    outdatedPricePackages
    subscriptionId
    additionalMetaData
    budget {
      limit
      hasSoftLimit
    }
    budgetExceeded
    freeItems {
      addonId
      quantity
    }
    resource {
      ...CustomerResourceFragment
    }
    subscriptionEntitlements {
      id
      usageLimit
      featureId
      hasUnlimitedUsage
      hasSoftLimit
      resetPeriod
      resetPeriodConfiguration {
        __typename
        ... on YearlyResetPeriodConfig {
          ...YearlyResetPeriodConfigFragment
        }
        ... on MonthlyResetPeriodConfig {
          ...MonthlyResetPeriodConfigFragment
        }
        ... on WeeklyResetPeriodConfig {
          ...WeeklyResetPeriodConfigFragment
        }
      }
      feature {
        id
        displayName
        description
        updatedAt
        refId
        featureStatus
        environmentId
        featureType
        meterType
        featureUnits
        featureUnitsPlural
      }
    }
    futureUpdates {
      ...SubscriptionFutureUpdateData
    }
    plan {
      id
      refId
      displayName
      status
      awsMarketplacePlanDimension
      entitlements {
        id
        usageLimit
        packageId
        featureId
        hasUnlimitedUsage
        hasSoftLimit
        isCustom
        resetPeriod
        resetPeriodConfiguration {
          __typename
          ... on YearlyResetPeriodConfig {
            ...YearlyResetPeriodConfigFragment
          }
          ... on MonthlyResetPeriodConfig {
            ...MonthlyResetPeriodConfigFragment
          }
          ... on WeeklyResetPeriodConfig {
            ...WeeklyResetPeriodConfigFragment
          }
        }
        feature {
          id
          displayName
          description
          updatedAt
          refId
          featureStatus
          environmentId
          featureType
          meterType
          featureUnits
          featureUnitsPlural
        }
      }
      product {
        refId
        displayName
        productSettings {
          subscriptionEndSetup
          subscriptionCancellationTime
        }
      }
      prices {
        ...PriceFragment
      }
      overagePrices {
        ...OveragePriceFragment
      }
      minimumSpend {
        ...MinimumSpendFragment
      }
      versionNumber
      isLatest
    }
    addons {
      id
      quantity
      addon {
        id
        refId
        displayName
        versionNumber
        isLatest
        prices {
          ...PriceFragment
        }
      }
      price {
        ...PriceFragment
      }
    }
    prices {
      usageLimit
      featureId
      billingModel
      price {
        ...PriceFragment
      }
    }
    coupon {
      ...SubscriptionCouponData
    }
    pricingType
    status
    billingId
    oldBillingId
    billingLinkUrl
    experimentInfo {
      status
    }
    paymentCollection
    paymentCollectionMethod
    minimumSpend {
      minimum {
        amount
        currency
      }
      isOverride
    }
    latestInvoice {
      errorMessage
    }
  }
  ${PRICE_FRAGMENT}
  ${OVERAGE_PRICE_FRAGMENT}
  ${SUBSCRIPTION_COUPON_FRAGMENT}
  ${RESET_PERIOD_CONFIGURATION}
  ${SUBSCRIPTION_FUTURE_UPDATE_FRAGMENT}
  ${CUSTOMER_RESOURCE_FRAGMENT}
  ${MINIMUM_SPEND_FRAGMENT}
`;

export type FetchCustomerSubscriptionsProps = {
  paging?: Paging;
  search?: string;
  customerId?: string;
  payingCustomerId?: string;
  loadHistory?: boolean;
  isSilentLoading?: boolean;
};

async function fetchCustomerSubscriptions(
  { customerId, payingCustomerId, paging, search, loadHistory = false }: FetchCustomerSubscriptionsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  if (!customerId && !payingCustomerId) {
    throw new Error('customerId or payingCustomerId must be provided');
  }

  return executeOperationSafely(
    async () => {
      const {
        accountReducer,
        productReducer: { products },
      } = getState();

      if (!paging) {
        paging = { first: CUSTOMER_SUBSCRIPTIONS_PAGE_SIZE };
      }
      const environmentId = accountReducer.currentEnvironmentId || '';
      const statues = loadHistory
        ? []
        : [
            SubscriptionStatus.Active,
            SubscriptionStatus.InTrial,
            SubscriptionStatus.PaymentPending,
            SubscriptionStatus.NotStarted,
          ];
      const filter = composeSubscriptionsFilter({
        customerId,
        payingCustomerId,
        environmentId,
        products,
        statues,
        search,
      });

      const subscriptionsRes = await apolloClient.query<
        FetchCustomerSubscriptionsQuery,
        FetchCustomerSubscriptionsQueryVariables
      >({
        query: FETCH_CUSTOMER_SUBSCRIPTIONS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          filter,
          sorting: [
            {
              field: CustomerSubscriptionSortFields.ResourceId,
              direction: SortDirection.Desc,
              nulls: SortNulls.NullsFirst,
            },
            {
              field: CustomerSubscriptionSortFields.StartDate,
              direction: SortDirection.Desc,
            },
          ],
        },
      });

      return subscriptionsRes.data;
    },
    {
      failureMessageHandler: () => t('customers.api.failFetchOneSubscriptions'),
    },
    dispatch,
  );
}

export { fetchCustomerSubscriptions };
