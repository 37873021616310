import { FC } from 'react';
import styled from 'styled-components/macro';
import { useTheme } from '@mui/material/styles';
import { Card, Text, Link } from '@stigg-components';
import { Grid, GridFlex } from './Grids';

const EmptyListBox = styled(Card)`
  height: 267px;
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export function EmptyList({
  icon: Icon,
  title,
  linkText,
  onLinkClick,
}: {
  icon?: FC<any>;
  title: string;
  linkText?: string;
  onLinkClick?: () => void;
}) {
  const theme = useTheme();

  return (
    <EmptyListBox>
      <GridFlex.ColumnMiddle sx={{ height: '100%' }} container>
        <Grid item>{Icon && <Icon strokeWidth={1} size={40} color={theme.itamar.palette.action.active} />}</Grid>
        <GridFlex.RowMiddle mt={3} item container>
          <Text.Sub2
            sx={{
              color: (theme) => theme.itamar.palette.text.secondary,
            }}>
            {title}
          </Text.Sub2>
          {linkText && onLinkClick && (
            <Link variant="subtitle2" ml={1} onClick={onLinkClick}>
              {linkText}
            </Link>
          )}
        </GridFlex.RowMiddle>
      </GridFlex.ColumnMiddle>
    </EmptyListBox>
  );
}
