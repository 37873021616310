import styled from 'styled-components/macro';
import { Box } from '@stigg-components';

const Circle = styled(Box)`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
`;

export const CircleControl = styled(Circle)`
  background-color: #d9d9d9;
`;

export const CircleVariant = styled(Circle)`
  background-color: #1a5ad6;
`;
