import { Feature } from '@stigg-types/apiTypes';
import lowercase from 'lodash/lowerCase';

export function getFeatureDisplayName(
  feature: Pick<Feature, 'displayName' | 'featureUnits' | 'featureUnitsPlural'>,
  hideUnits = false,
) {
  const displayNameLowerCase = lowercase(feature.displayName);
  if (
    displayNameLowerCase === lowercase(feature.featureUnits || '') ||
    displayNameLowerCase === lowercase(feature.featureUnitsPlural || '')
  ) {
    return feature.displayName;
  }

  if (!feature.featureUnitsPlural || hideUnits) {
    return feature.displayName;
  }

  return `${feature.displayName} (${feature.featureUnitsPlural})`;
}
