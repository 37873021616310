import moment from 'moment';
import { sample, sortBy } from 'lodash';
import { generateRandomSlug } from '@stigg-common';
import { EventActor, EventEntityType, EventLog, EventLogType } from '@stigg-types/apiTypes';

const accountId = '1234';
const environmentId = '5678';

const names = [
  'Olivia Smith',
  'Liam Johnson',
  'Emma Williams',
  'Noah Brown',
  'Ava Jones',
  'Elijah Garcia',
  'Sophia Martinez',
  'James Rodriguez',
  'Isabella Hernandez',
  'Lucas Wilson',
  'Mia Anderson',
  'Mason Thomas',
  'Charlotte Lee',
  'Ethan Taylor',
  'Amelia Moore',
  'Alexander Martin',
];

const mockEventLog: (name: string) => EventLog = (name: string) => {
  const entityType = sample([EventEntityType.Customer, EventEntityType.Subscription]);
  const eventLogType =
    entityType === EventEntityType.Customer ? EventLogType.CustomerCreated : EventLogType.SubscriptionCreated;
  const slugPrefix = entityType === EventEntityType.Customer ? 'cus' : 'sub';
  const actorType = sample([EventActor.User, EventActor.System, EventActor.User]);

  return {
    id: generateRandomSlug(),
    accountId,
    environmentId,
    entityId: `${slugPrefix}_${generateRandomSlug()}`,
    createdAt: moment().subtract(Math.floor(Math.random() * 10), 'minutes'),
    eventLogType,
    entityType,
    actor: {
      id: generateRandomSlug(),
      type: actorType,
      name: actorType !== EventActor.System ? name : undefined,
    },
    payload: {},
  };
};

export const MOCK_EVENT_LOG_DATA = sortBy(
  names.map((name) => mockEventLog(name)),
  'createdAt',
);
