import React from 'react';
import { Text, GridFlex, ExternalLink, Box, DetailsLayout } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { PageCard } from '../../../components/PageCard';
import { RootState } from '../../../redux/store';

const formatContactSupportLink = () => {
  const subject = encodeURIComponent(`Hey! i'd like to add a SSO integration`);
  return `mailto:support@stigg.io?subject=${subject}`;
};
export const NotConfiguredStyledBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
`;
export const ConfiguredStyledBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.divider};
`;

function SingleSignOnSettings() {
  const account = useSelector((state: RootState) => state.accountReducer.account);

  return (
    <PageCard>
      <GridFlex.Column>
        <Text.H3 mb={2}>{t('accounts.sso.title')}</Text.H3>
        <GridFlex.Row>
          <Text.Sub2 color="secondary" mr={1}>
            {t('accounts.sso.subtitle')}
            &nbsp;
            <ExternalLink label={t('accounts.sso.learnMore')} url="https://docs.stigg.io/docs/single-sign-on" />
          </Text.Sub2>
        </GridFlex.Row>

        {account?.samlEnabled ? (
          <ConfiguredStyledBox mt={4}>
            <GridFlex.Column>
              <DetailsLayout
                titleWidth={200}
                details={[
                  {
                    title: t('accounts.sso.enforcement'),
                    content: t('accounts.sso.enforcementValue'),
                  },
                  {
                    title: t('accounts.sso.idp'),
                    content: t('accounts.sso.idpValue'),
                  },
                  {
                    title: t('accounts.sso.authDomain'),
                    content: account?.accountEmailDomain,
                    helperTooltip: <Text.B2>{t('accounts.sso.authDomainTooltip')}</Text.B2>,
                  },
                ]}
              />
            </GridFlex.Column>
          </ConfiguredStyledBox>
        ) : (
          <NotConfiguredStyledBox mt={2}>
            <Text.B2 mr={1}>{t('accounts.sso.notConfigured')}</Text.B2>
            <ExternalLink label={t('accounts.sso.contactSupport')} url={formatContactSupportLink()} />
          </NotConfiguredStyledBox>
        )}
      </GridFlex.Column>
    </PageCard>
  );
}

export default SingleSignOnSettings;
