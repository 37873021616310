import { t } from 'i18next';
import { capitalize, delay } from 'lodash';
import {
  AddonFragment,
  PackageStatus,
  PlanFragment,
  ProductSettingsInput,
  PublishMigrationType,
} from '@stigg-types/apiTypes';
import { useAppDrawer } from '../../../../../components/layout/AppLayout';
import { useAppDispatch } from '../../../../../redux/store';
import { setIsTasksProgressTooltipOpen } from '../../../../application/applicationSlice';
import { useModal } from '../../../../common/useModal';
import { publishAddonAction } from '../../../addons/addonSlice';
import { publishPlanAction } from '../../../plans/plansSlice';
import { ReviewAndPublishPackageChanges } from '../ReviewAndPublishPackageChanges';
import { EditNewPackageBar } from './EditNewPackageBar';
import { EditExistingPackageBar } from './EditExistingPackageBar';
import { usePublishPackageDialog } from './PublishPackageDialog';
import { normalizedPackageName } from '../../../../../i18n/utils';

export type HandlePackagePublish = (
  aPackage: PlanFragment | AddonFragment,
  publishMigrationType: PublishMigrationType,
  productSettingsToUpdate?: ProductSettingsInput & { productId: string },
) => Promise<void>;

export function PackageEditTopBar({
  aPackage,
  setHasPublishValidationError,
  isLoading,
  onDiscardChanges,
}: {
  aPackage: PlanFragment | AddonFragment;
  setHasPublishValidationError: (hasError: boolean) => void;
  isLoading?: boolean;
  onDiscardChanges: () => void;
}) {
  const packageType = normalizedPackageName(aPackage.type);
  const dispatch = useAppDispatch();
  const { isDrawerExpanded, isTopBannerOpen, onDrawerToggle, isSandboxBannerOpen } = useAppDrawer();
  const [PackageChangesModal, setPackageChangeModalOpen] = useModal({
    title: t('packages.packageChangeDialogTitle', { packageType: capitalize(packageType) }),
    width: 850,
  });

  const handlePackagePublish: HandlePackagePublish = async (
    aPackage: PlanFragment | AddonFragment,
    publishMigrationType: PublishMigrationType,
    productSettingsToUpdate?: ProductSettingsInput & { productId: string },
  ) => {
    if (aPackage.type === 'Plan') {
      await dispatch(
        publishPlanAction({
          id: aPackage.id,
          refId: aPackage.refId,
          migrationType: publishMigrationType,
          productSettingsToUpdate,
        }),
      );
    } else if (aPackage.type === 'Addon') {
      await dispatch(
        publishAddonAction({ id: aPackage.id, refId: aPackage.refId, migrationType: publishMigrationType }),
      );
    }

    if (publishMigrationType === PublishMigrationType.AllCustomers) {
      delay(() => {
        void dispatch(setIsTasksProgressTooltipOpen(true));
      }, 2000);
    }
  };

  const [PublishPackageDialog, showPublishPackageDialog, publishDialogProps] = usePublishPackageDialog(
    aPackage,
    handlePackagePublish,
  );

  const isFirstDraft = aPackage.status === PackageStatus.Draft && aPackage.isLatest;

  const onPublish = () => {
    if (!aPackage.pricingType) {
      setHasPublishValidationError(true);
      return;
    }

    if (isFirstDraft) {
      showPublishPackageDialog(true);
    } else {
      setPackageChangeModalOpen(true);
    }
  };

  return (
    <>
      {isFirstDraft ? (
        <EditNewPackageBar
          isLoading={isLoading}
          isDrawerExpanded={isDrawerExpanded}
          isTopBannerOpen={isTopBannerOpen}
          onDrawerToggle={onDrawerToggle}
          onDiscardChanges={onDiscardChanges}
          onPublish={onPublish}
          packageType={packageType}
          isSandboxBannerOpen={isSandboxBannerOpen}
        />
      ) : aPackage.status === PackageStatus.Draft ? (
        <EditExistingPackageBar
          isLoading={isLoading}
          isDrawerExpanded={isDrawerExpanded}
          isTopBannerOpen={isTopBannerOpen}
          onDrawerToggle={onDrawerToggle}
          onDiscardChanges={onDiscardChanges}
          onPublish={onPublish}
          packageType={packageType}
          aPackage={aPackage}
          setPackageChangeModalOpen={setPackageChangeModalOpen}
          isSandboxBannerOpen={isSandboxBannerOpen}
        />
      ) : null}
      <PublishPackageDialog {...publishDialogProps} />
      <PackageChangesModal>
        <ReviewAndPublishPackageChanges
          isLoading={isLoading}
          aPackage={aPackage}
          onCancel={() => setPackageChangeModalOpen(false)}
          onPublish={(publishMigrationType: PublishMigrationType) =>
            handlePackagePublish(aPackage, publishMigrationType)
          }
        />
      </PackageChangesModal>
    </>
  );
}
