import { useState, useEffect, useCallback } from 'react';
import { styled } from '@stigg-theme';
import { SxProps } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { ChevronDown } from 'react-feather';
import { Box, Collapse, GridFlex, IconButton, MenuItem, Select, Text } from '@stigg-components';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { isString } from 'lodash';
import { ClipboardChip } from '../../../../components/clipboard/ClipboardChip';
import { SDK_TO_SNIPPET_DATA, SdkType } from './sdkToSnippetData';

const StyledChevronDown = styled(ChevronDown)<{ $isUp: boolean }>`
  color: ${({ theme }) => theme.itamar.palette.action.active};
  transition: all 0.2s ease-in;
  ${({ $isUp }) =>
    $isUp &&
    `
    transform: rotate(-180deg)
  `}
`;

function SdkSelectionItem({ sdkType }: { sdkType: SdkType }) {
  return (
    <GridFlex.RowCenter alignItems="center" pr={2}>
      {SDK_TO_SNIPPET_DATA[sdkType].icon}
      <Text.B2 ml={2}>{SDK_TO_SNIPPET_DATA[sdkType].text}</Text.B2>
    </GridFlex.RowCenter>
  );
}

function SdkSelection({
  sdkType,
  onChange,
  availableSdks,
}: {
  sdkType: SdkType;
  onChange: (value: SdkType) => void;
  availableSdks?: SdkType[];
}) {
  return (
    <Select
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onChange={(value) => onChange(value.target.value as SdkType)}
      value={sdkType}
      withoutBorder
      renderValue={(value) => <SdkSelectionItem sdkType={value as SdkType} />}>
      {Object.values(SdkType)
        .filter((sdkType) => (availableSdks ? availableSdks.includes(sdkType) : true))
        .map((sdkType) => (
          <MenuItem key={sdkType} value={sdkType}>
            <SdkSelectionItem sdkType={sdkType} />
          </MenuItem>
        ))}
    </Select>
  );
}

export function CodeBlock({
  code,
  sdkType,
  setSdkType,
  maxHeight,
  availableSdks,
  roundedBorder = true,
  wrapperSx,
}: {
  code: string;
  sdkType: SdkType;
  setSdkType: (sdkType: SdkType) => void;
  maxHeight?: number;
  availableSdks?: SdkType[];
  roundedBorder?: boolean;
  wrapperSx?: SxProps;
}) {
  const theme = useTheme();
  return (
    <GridFlex.Column
      sx={{
        border: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
        borderRadius: roundedBorder ? '4px' : '0',
        ...wrapperSx,
      }}>
      <GridFlex.RowCenter
        pr={4}
        pl={1}
        py={1}
        justifyContent="space-between"
        sx={{
          backgroundColor: (theme) =>
            theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground2,
          borderBottom: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
          borderTopLeftRadius: roundedBorder ? '4px' : 0,
          borderTopRightRadius: roundedBorder ? '4px' : 0,
        }}>
        <Box>
          <SdkSelection sdkType={sdkType} onChange={setSdkType} availableSdks={availableSdks} />
        </Box>
        <ClipboardChip copy={code} iconSize={14} tooltipPlacement="top" />
      </GridFlex.RowCenter>
      <SyntaxHighlighter
        wrapLines
        language={SDK_TO_SNIPPET_DATA[sdkType].syntaxHighlighterLanguage}
        style={theme.isLightTheme ? docco : vs2015}
        showLineNumbers
        lineNumberStyle={{ display: 'none' }}
        customStyle={{
          fontSize: 14,
          margin: 0,
          padding: '16px',
          ...(theme.isLightTheme ? {} : { backgroundColor: theme.itamar.palette.background.lightBackground2 }),
          maxHeight,
          borderBottomLeftRadius: roundedBorder ? '4px' : 0,
          borderBottomRightRadius: roundedBorder ? '4px' : 0,
        }}>
        {code}
      </SyntaxHighlighter>
    </GridFlex.Column>
  );
}

export function CodeBlockSection({
  title,
  code,
  availableSdks,
  collapsable = true,
  maxHeight = 180,
  onChangeSdkType,
  selectedSdkType,
}: {
  code: (sdkType: SdkType) => string;
  title?: string | React.ReactNode;
  availableSdks?: SdkType[];
  collapsable?: boolean;
  maxHeight?: number;
  onChangeSdkType?: (sdkType: SdkType) => void;
  selectedSdkType?: SdkType;
}) {
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [sdkType, setSdkType] = useState(selectedSdkType ?? (availableSdks ? availableSdks[0] : SdkType.NODE));

  useEffect(() => {
    if (!selectedSdkType) {
      return;
    }

    setSdkType(selectedSdkType);
  }, [selectedSdkType]);

  const onCodeBlockSetSdkType = useCallback(
    (sdkType: SdkType) => {
      setSdkType(sdkType);
      onChangeSdkType?.(sdkType);
    },
    [onChangeSdkType],
  );

  return (
    <GridFlex.Column
      mt={1}
      py={3}
      sx={{ borderBottom: (theme) => (isSectionOpen ? 'none' : `1px solid ${theme.itamar.border.borderColor}`) }}>
      <GridFlex.RowCenter
        mb={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: collapsable ? 'pointer' : undefined }}
        onClick={() => collapsable && setIsSectionOpen((prevState) => !prevState)}>
        {isString(title) ? (
          <>
            <Text.B2 $medium>{title}</Text.B2>
            <IconButton>
              <StyledChevronDown $isUp={isSectionOpen} />
            </IconButton>
          </>
        ) : (
          title
        )}
      </GridFlex.RowCenter>
      <Collapse in={isSectionOpen}>
        <CodeBlock
          sdkType={sdkType}
          setSdkType={onCodeBlockSetSdkType}
          code={code(sdkType)}
          maxHeight={maxHeight}
          availableSdks={availableSdks}
        />
      </Collapse>
    </GridFlex.Column>
  );
}
