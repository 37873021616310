import { useState } from 'react';
import { t } from 'i18next';
import { Grid, FullScreenDialogLayout } from '@stigg-components';
import { IntegrationFragment } from '../../../../types/apiTypes';
import { ImportCatalogWizard } from './importCatalogWizard/ImportCatalogWizard';
import { ImportCustomersWizard } from './importCustomers/ImportCustomersWizard';
import { StripeImportContext, useStripeWizardImportContext } from './hooks/useStripeWizardImportContext';

export enum StripeImportWizardType {
  IMPORT_CATALOG = 'IMPORT_CATALOG',
  IMPORT_CUSTOMERS_AND_SUBSCRIPTIONS = 'IMPORT_CUSTOMERS_AND_SUBSCRIPTIONS',
}

export function StripeImportWizard({
  integration,
  closeImportDialog,
}: {
  integration: IntegrationFragment;
  closeImportDialog: () => void;
}) {
  const [currentWizardType, setCurrentWizardType] = useState(StripeImportWizardType.IMPORT_CATALOG);
  const importContext = useStripeWizardImportContext();

  return (
    <StripeImportContext.Provider value={importContext}>
      <FullScreenDialogLayout title={t('integrations.importStripeHeaderTitle')}>
        <Grid container height="100%" $fullWidth sx={{ overflow: 'auto' }}>
          {currentWizardType === StripeImportWizardType.IMPORT_CATALOG ? (
            <ImportCatalogWizard
              integration={integration}
              goToImportCustomers={() =>
                setCurrentWizardType(StripeImportWizardType.IMPORT_CUSTOMERS_AND_SUBSCRIPTIONS)
              }
            />
          ) : (
            <ImportCustomersWizard integration={integration} closeImportDialog={closeImportDialog} />
          )}
        </Grid>
      </FullScreenDialogLayout>
    </StripeImportContext.Provider>
  );
}
