import { useEffect } from 'react';
import { ImportIntegrationTaskResultFragment } from '@stigg-types/apiTypes';
import { watchImportIntegrationTask } from '../../../queries/watchImportIntegrationTasks';
import { isTaskFinished } from '../../../../../components/layout/navbar/components/tasks/taskUtils';

const DEFAULT_TASK_POLL_INTERVAL = 2000;

export function usePollImportIntegrationTask(
  taskId: string | null,
  onData: (importTask: ImportIntegrationTaskResultFragment) => void,
  onError?: (err: any) => void,
) {
  useEffect(() => {
    if (!taskId) {
      return () => undefined;
    }
    const pollIntegrationTask = watchImportIntegrationTask(taskId, DEFAULT_TASK_POLL_INTERVAL);

    if (pollIntegrationTask) {
      pollIntegrationTask.subscribe(
        (value) => {
          if (value.errors || !value.data || !value.data.importIntegrationTasks) {
            if (onError) {
              onError(value);
            }
            return;
          }

          if (value.data && value.data.importIntegrationTasks.edges.length > 0) {
            const [{ node: importTask }] = value.data.importIntegrationTasks.edges;

            onData(importTask);

            if (isTaskFinished(importTask.status)) {
              pollIntegrationTask?.stopPolling();
            }
          }
        },
        (err: any) => {
          if (onError) {
            onError(err);
          }
        },
      );
    }

    return () => pollIntegrationTask?.stopPolling();
  }, [onData, taskId, onError]);
}
