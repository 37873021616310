import { useMemo } from 'react';
import { ResultSet } from '@cubejs-client/core';
import { calculatePeriodValues, TimeRangePeriods, useCurrentAndPreviousPeriods } from '../base';
import { CubeSubscriptionData } from './useCubeSubscriptionStaticData';

export type CubeSubscriptionTotalTrendData = {
  periods: TimeRangePeriods;
  isLoadingTotal: boolean;
  isLoadingChart: boolean;
  chartQuery: CubeSubscriptionData;
  totalQuery: CubeSubscriptionData;
  currentPeriodValue: number;
  previousPeriodValue: number;
};

export const useCubeSubscriptionStaticTotalTrendData = ({
  chartMock,
  totalMock,
}: {
  chartMock: Record<string, unknown>;
  totalMock: Record<string, unknown>;
}) => {
  const periods = useCurrentAndPreviousPeriods();

  return useMemo(() => {
    const { label } = periods || {};
    const chartSerialized = { loadResponse: chartMock[label] };
    const chartDeserialized = ResultSet.deserialize(chartSerialized);

    const totalSerialized = { loadResponse: totalMock[label] };
    const totalDeserialized = totalSerialized ? ResultSet.deserialize(totalSerialized) : null;

    const [currentPeriodValue, previousPeriodValue] = calculatePeriodValues(totalDeserialized ?? undefined);

    return {
      chartQuery: { isLoading: false, error: null, resultSet: chartDeserialized },
      totalQuery: { isLoading: false, error: null, resultSet: totalDeserialized },
      isLoadingTotal: false,
      isLoadingChart: false,
      periods,
      currentPeriodValue,
      previousPeriodValue,
    };
  }, [chartMock, totalMock, periods]);
};
