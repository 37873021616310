import { createSlice } from '@reduxjs/toolkit';
import {
  CheckoutConfigurationFragment,
  CustomerPortalConfigurationFragment,
  PaywallConfigurationFragment,
} from '@stigg-types/apiTypes';
import { setWidgetConfiguration } from './mutations/upsertWidgetConfiguration';
import { fetchCustomerPortalConfiguration } from './queries/fetchCustomerPortalConfiguration';
import { fetchPaywallConfiguration } from './queries/fetchPaywallConfiguration';
import { fetchCheckoutConfiguration } from './queries/fetchCheckoutConfiguration';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface WidgetsState {
  isLoading: boolean;
  isLoadingSetConfig: boolean;
  paywallConfiguration?: PaywallConfigurationFragment | null;
  customerPortalConfiguration?: CustomerPortalConfigurationFragment | null;
  checkoutConfiguration?: CheckoutConfigurationFragment | null;
}

const initialState: WidgetsState = {
  isLoading: false,
  isLoadingSetConfig: false,
  paywallConfiguration: null,
  customerPortalConfiguration: null,
  checkoutConfiguration: null,
};

const setWidgetConfigurationAction = createAppAsyncThunk('setWidgetConfiguration', setWidgetConfiguration);
const fetchPaywallConfigurationAction = createAppAsyncThunk('fetchPaywallConfiguration', fetchPaywallConfiguration);
const fetchCustomerPortalConfigurationAction = createAppAsyncThunk(
  'fetchCustomerPortalConfiguration',
  fetchCustomerPortalConfiguration,
);
const fetchCheckoutConfigurationAction = createAppAsyncThunk(
  'fetchCheckoutConfigurationAction',
  fetchCheckoutConfiguration,
);

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setWidgetConfigurationAction.pending, (state) => {
        state.isLoadingSetConfig = true;
      })
      .addCase(setWidgetConfigurationAction.fulfilled, (state) => {
        state.isLoadingSetConfig = false;
      })
      .addCase(setWidgetConfigurationAction.rejected, (state) => {
        state.isLoadingSetConfig = false;
      });

    builder
      .addCase(fetchPaywallConfigurationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPaywallConfigurationAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.paywallConfiguration = payload;
      })
      .addCase(fetchPaywallConfigurationAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchCustomerPortalConfigurationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerPortalConfigurationAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.customerPortalConfiguration = payload;
      })
      .addCase(fetchCustomerPortalConfigurationAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchCheckoutConfigurationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCheckoutConfigurationAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.checkoutConfiguration = payload;
      })
      .addCase(fetchCheckoutConfigurationAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export {
  fetchPaywallConfigurationAction,
  setWidgetConfigurationAction,
  fetchCustomerPortalConfigurationAction,
  fetchCheckoutConfigurationAction,
};

export default widgetsSlice.reducer;
