import { t } from 'i18next';
import { Trash2, Archive } from 'react-feather';
import {
  Box,
  LongText,
  GridFlex,
  Text,
  OptionsDropdown,
  Chip,
  LocalDate,
  HeadCell,
  TwoLinesLayout,
} from '@stigg-components';
import { Feature, FeatureStatus } from '@stigg-types/apiTypes';
import { formatFeatureType } from './featureTypesFormatter';
import { getFeatureTypeIcon } from '../../../components/getFeatureTypeIcon';

export const headCells = (
  onDeleteClick: (feature: Feature) => void,
  onArchiveClick: (feature: Feature) => void,
  shouldUseArchive: boolean,
): Array<HeadCell<Feature, any>> => [
  {
    id: 'id',
    alignment: 'left',
    label: t('features.featureId'),
    render: (feature) => (
      <LongText variant="body2" maxLines={2} tooltipPlacement="top">
        {feature.refId}
      </LongText>
    ),
  },
  {
    id: 'name',
    alignment: 'left',
    label: t('features.featureName'),
    render: (feature) => (
      <Box>
        <TwoLinesLayout firstRow={feature.displayName} secondRow={feature.description || ''} />
      </Box>
    ),
  },
  {
    id: 'feature_status',
    alignment: 'left',
    label: t('features.featureStatus'),
    render: (feature) => (
      <Chip
        $hasPointer
        size="small"
        label={feature.featureStatus === FeatureStatus.New ? 'Active' : 'Draft'}
        color={feature.featureStatus === FeatureStatus.New ? 'primary' : 'success'}
      />
    ),
  },
  {
    id: 'feature_type',
    alignment: 'left',
    label: t('features.featureTypeColumn'),
    render: (feature) => (
      <GridFlex.RowCenter alignItems="center">
        {getFeatureTypeIcon(feature)}
        <GridFlex.Column item container ml={2}>
          <Text.B2>{formatFeatureType(feature)}</Text.B2>
        </GridFlex.Column>
      </GridFlex.RowCenter>
    ),
  },
  {
    id: 'last_updated',
    alignment: 'left',
    label: t('features.lastUpdated'),
    render: (feature) => <LocalDate date={feature.updatedAt} formatOptions={{ withTime: true }} />,
  },
  {
    id: 'options',
    alignment: 'right',
    label: '',
    width: 72,
    maxWidth: 36 + 8,
    disableClick: true,
    render: (feature) => (
      <OptionsDropdown
        options={[
          {
            icon: shouldUseArchive ? Archive : Trash2,
            text: shouldUseArchive ? t('sharedComponents.archive') : t('sharedComponents.delete'),
            onClick: () => {
              return shouldUseArchive ? onArchiveClick(feature) : onDeleteClick(feature);
            },
          },
        ]}
      />
    ),
  },
];
