import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateEntitlementsOrderMutation,
  UpdateEntitlementsOrderMutationVariables,
  UpdatePackageEntitlementOrderInput,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const UPDATE_ENTITLEMENTS_ORDER = gql`
  mutation UpdateEntitlementsOrder($input: UpdatePackageEntitlementOrderInput!) {
    updateEntitlementsOrder(input: $input) {
      id
      order
    }
  }
`;

async function updateEntitlementsOrder(
  input: UpdatePackageEntitlementOrderInput,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<UpdateEntitlementsOrderMutation['updateEntitlementsOrder'] | undefined> {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId },
      } = getState();

      const response = await apolloClient.mutate<
        UpdateEntitlementsOrderMutation,
        UpdateEntitlementsOrderMutationVariables
      >({
        mutation: UPDATE_ENTITLEMENTS_ORDER,
        variables: { input: { ...input, environmentId: currentEnvironmentId } },
      });

      return response.data?.updateEntitlementsOrder;
    },
    { failureMessageHandler: () => t('entitlements.reorderFailedMessage') },
    dispatch,
  );
}

export { updateEntitlementsOrder };
