import { ReactNode } from 'react';
import { Button, drawFormFields, Field, GridFlex, Icon, Text } from '@stigg-components';
import { t } from 'i18next';
import { FieldArray } from 'formik';
import { generateRandomSlug } from '@stigg-common';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { LocalizedCountry } from './countryPriceLocalization/LocalizedCountry';
import { CountryPriceLocalization, SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { StepsManagement } from '../utils/useSubStepsState';

export type PriceLocalizationStepProps = {
  countriesState: StepsManagement;
  renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode;
};

export function PriceLocalizationStep({ renderStepActionButtons, countriesState }: PriceLocalizationStepProps) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { overageCharges, priceLocalization } = formRenderProps.values;
  const { countries, enabled } = priceLocalization;
  const hasCountryUsedInSubscriptions = countries.some(({ usedInSubscriptions }) => usedInSubscriptions);
  const hasOverageCharges = !!overageCharges.enabled;

  const disabledTooltipTitle = hasCountryUsedInSubscriptions
    ? t('pricing.priceLocalization.localizationSwitchPriceUsedInSubscriptionsTooltip')
    : hasOverageCharges
    ? t('pricing.priceLocalization.localizationSwitchHasOverageChargesTooltip')
    : '';

  const addFirstLocalizationIfMissing = (value: boolean) => {
    if (value && countries.length === 0) {
      const item: CountryPriceLocalization = {
        uuid: generateRandomSlug(),
        countryAndCurrencyConfirmed: false,
        isConfirmed: false,
        chargesPricePeriods: {},
        chargesTieredPricePeriods: {},
        openedCharges: {},
      };

      formRenderProps.setFieldValue('priceLocalization.countries', [item]);
      countriesState.setFocused(item.uuid);
    }
  };

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'switch',
      id: 'priceLocalization.enabled',
      disabledTooltipProps: {
        maxWidth: 250,
        title: disabledTooltipTitle,
      },
      disabled: hasCountryUsedInSubscriptions || hasOverageCharges,
      label: t('pricing.priceLocalization.enable'),
      onChange: addFirstLocalizationIfMissing,
      dataTestId: 'switch-price-localization',
    },
    {
      type: 'idle',
      component: <Text.B2 color="disabled">{t('pricing.priceLocalization.enableNote')}</Text.B2>,
      gridProps: {
        ml: 12,
        mb: 8,
      },
    },
  ];

  return (
    <>
      {drawFormFields(fields, formRenderProps)}

      {enabled && (
        <FieldArray name="priceLocalization.countries">
          {({ push, remove }) => {
            const createNewLocalizedPrice = () => {
              const item: CountryPriceLocalization = {
                uuid: generateRandomSlug(),
                countryAndCurrencyConfirmed: false,
                isConfirmed: false,
                chargesPricePeriods: {},
                chargesTieredPricePeriods: {},
                openedCharges: {},
              };

              push(item);
              countriesState.setFocused(item.uuid);
            };

            const removeAndDisableIfNoCountries = (index: number) => {
              remove(index);
              if (countries.length === 1) {
                formRenderProps.setFieldValue('priceLocalization.enabled', false);
              }
            };

            const addLocalizedPriceButton: ReactNode = (
              <Button
                variant="outlined"
                startIcon={<Icon icon="Plus" size={20} />}
                onClick={createNewLocalizedPrice}
                data-testid="button-new-localized-country">
                {t('pricing.priceLocalization.addLocalizedPrice')}
              </Button>
            );

            return (
              <>
                <GridFlex.Column $fullWidth>
                  {countries.map(({ uuid, isConfirmed }, index) => (
                    <LocalizedCountry
                      key={uuid}
                      index={index}
                      isFocused={countriesState.isExpanded(uuid)}
                      onRemove={() => removeAndDisableIfNoCountries(index)}
                      onConfirm={() => countriesState.setFocused(undefined)}
                      onToggle={() => countriesState.toggle(uuid)}
                      permanent={!isConfirmed}
                    />
                  ))}
                </GridFlex.Column>

                {renderStepActionButtons(addLocalizedPriceButton)}
              </>
            );
          }}
        </FieldArray>
      )}
    </>
  );
}
