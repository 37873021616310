import { t } from 'i18next';
import { Alert } from '@stigg-components';
import React from 'react';
import { SxProps, Theme } from '@mui/system';

interface ChangesAlertProps {
  changes: number;
  sx?: SxProps<Theme>;
}

export const ChangesAlert = ({ sx, changes }: ChangesAlertProps) => (
  <Alert sx={{ width: '100%', ...sx }} severity={changes ? 'info' : 'success'}>
    {changes
      ? t('accounts.copyMergeEnvironments.compareDialog.changesFound', { count: changes })
      : t('accounts.copyMergeEnvironments.compareDialog.noChangesFound')}
  </Alert>
);
