import { createSlice } from '@reduxjs/toolkit';
import differenceBy from 'lodash/differenceBy';
import { UsageEventWithCustomerFragment } from '@stigg-types/apiTypes';
import { fetchUsageEventsWithCustomer } from './queries/fetchUsageEventsWithCustomer';
import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export type UsageEventItem = UsageEventWithCustomerFragment & { isNew?: boolean };

export interface UsageEventsState {
  events: UsageEventItem[] | null;
  isLoading: boolean;
}

export const initialState: UsageEventsState = {
  events: null,
  isLoading: false,
};

const fetchUsageEventsAction = createAppAsyncThunk('fetchUsageEventsWithCustomer', fetchUsageEventsWithCustomer);

export const usageEventsSlice = createSlice({
  name: 'usageEventsWithCustomerSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsageEventsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsageEventsAction.fulfilled, (state, { payload, meta }) => {
        const prevEvents = state.events;
        // In those cases we want to replace the events list with the new data
        if (prevEvents?.length === 0 || meta.arg.isInitialLoad) {
          state.events = payload;
        } else {
          const newEvents = differenceBy(payload, prevEvents || [], 'id');

          const nextEvents = payload.map((e) => ({
            ...e,
            isNew: newEvents.includes(e),
          }));

          state.events = nextEvents;
        }

        state.isLoading = false;
      })
      .addCase(fetchUsageEventsAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export { fetchUsageEventsAction };

export default usageEventsSlice.reducer;
