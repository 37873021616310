import { GridFlex, Text } from '@stigg-components';
import { PriceTierFragment } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { getTotalPriceTierGraduated } from '../../../../../packages/pricing/utils/getPrice';
import { PlanPriceWithQuantity, formatPricePerUnit } from './priceBreakdown';
import { numberFormatter } from '../../../../../../utils/numberUtils';

function getLabel(tiers: PriceTierFragment[], index: number): string {
  const { unitPrice, upTo } = tiers[index];
  if (!unitPrice) {
    return '';
  }

  if (index === 0) {
    return `First ${upTo}`;
  }

  const previousTierUpTo = tiers[index - 1].upTo || 0;
  const startUnit = previousTierUpTo + 1;

  return isNil(upTo)
    ? `${numberFormatter(startUnit)} and above`
    : `Next ${numberFormatter(startUnit)} to ${numberFormatter(upTo)}`;
}

export type GraduatedPriceBreakdownProps = {
  priceWithQuantity: PlanPriceWithQuantity;
  showMonthlyPriceVariation: boolean;
  spacing: number;
};

export function GraduatedPriceBreakdown({
  priceWithQuantity,
  showMonthlyPriceVariation,
  spacing,
}: GraduatedPriceBreakdownProps) {
  const { price, unitQuantity } = priceWithQuantity;
  const { billingModel, billingPeriod, billingCadence } = price;
  const tiers = price.tiers || [];

  const {
    breakdown,
    total: { currency },
  } = getTotalPriceTierGraduated(tiers, unitQuantity);

  if (breakdown.length === 1) {
    return null;
  }

  return (
    <GridFlex.Column gap={spacing}>
      {breakdown.map(({ unitQuantity, amount }, index) => (
        <GridFlex.RowSpaceBetween key={index} gap={spacing}>
          <Text.B2 color="tertiary">{getLabel(tiers, index)}</Text.B2>
          <Text.B2 color="tertiary">
            {formatPricePerUnit({
              unitQuantity,
              billingPeriod,
              billingCadence,
              billingModel,
              showMonthlyPriceVariation,
              totalAmount: amount,
              currency,
              priceTier: tiers[index],
            })}
          </Text.B2>
        </GridFlex.RowSpaceBetween>
      ))}
    </GridFlex.Column>
  );
}
