import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';

export const executeSafely = (fn: SearchFunction): SearchFunction => {
  return (search: string) => {
    try {
      return fn(search);
    } catch (e) {
      return [];
    }
  };
};
