import isEmpty from 'lodash/isEmpty';
import { GridFlex, Text } from '@stigg-components';
import React, { useCallback } from 'react';
import { TooltipModel } from 'chart.js';
import { Feature } from '@stigg-types/apiTypes';
import { numberFullFormatter } from '../../../../common/formatters';
import {
  ChartJsExternalTooltip,
  ExternalTooltipInfo,
  ExternalTooltipTranslateFunc,
  useChartJsExternalTooltip,
} from '../../../../common/chartjs/chartjs.tooltip.plugin';
import { RoundedSideColorMarker } from '../../../../common/chartjs/DefaultChartTooltip';

export type MedianFeatureUsageChartTooltipData = {
  label: string;
  value: number;
  color: string;
  units: string;
};

export type MedianFeatureUsageChartTooltipProps = {
  tooltipInfo: ExternalTooltipInfo<MedianFeatureUsageChartTooltipData>;
};

export function MedianFeatureUsageChartTooltip({ tooltipInfo }: MedianFeatureUsageChartTooltipProps) {
  if (!tooltipInfo.payload) {
    return <></>;
  }

  const { label, value, color, units } = tooltipInfo.payload;

  return (
    <ChartJsExternalTooltip tooltipInfo={tooltipInfo}>
      <GridFlex.Row>
        <RoundedSideColorMarker style={{ backgroundColor: color }} />
        <GridFlex.ColumnCenter>
          <GridFlex.Row>
            <Text.B2 color="primary" mr={1}>
              {label}
            </Text.B2>
          </GridFlex.Row>
          <GridFlex.Row mt={1}>
            <Text.B2 color="primary">{numberFullFormatter(value)}</Text.B2>
            <Text.B2 color="secondary" ml={1}>
              {units}
            </Text.B2>
          </GridFlex.Row>
        </GridFlex.ColumnCenter>
      </GridFlex.Row>
    </ChartJsExternalTooltip>
  );
}

export function useMedianFeatureUsageChartTooltip(feature: Feature) {
  const translate = useCallback<ExternalTooltipTranslateFunc<'line', MedianFeatureUsageChartTooltipData>>(
    (tooltip: TooltipModel<'line'>) => {
      if (isEmpty(tooltip.dataPoints) || isEmpty(tooltip.labelColors)) {
        return undefined;
      }

      const label = tooltip.title[0];
      const value = tooltip.dataPoints[0].parsed.y;
      const color = tooltip.labelColors[0].borderColor as string;
      const units = feature.featureUnitsPlural as string;

      return {
        label,
        value,
        color,
        units,
      };
    },
    [feature],
  );

  const { tooltipInfo, tooltipHandler } = useChartJsExternalTooltip<'line', MedianFeatureUsageChartTooltipData>(
    translate,
  );

  return { tooltipInfo, tooltipHandler };
}
