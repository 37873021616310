import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import {
  CustomerSortFields,
  FetchCustomersQuery,
  FetchCustomersQueryVariables,
  SortDirection,
} from '@stigg-types/apiTypes';
import { Navigation } from '../navigation/useNavigation';
import { generateFetchCustomersFilters } from '../customers/queries/generateFetchCustomersFilters';
import { apolloClient } from '../../ApolloClient';
import { getFetchCustomersQuery } from '../customers/queries/fetchCustomers';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { executeSafely } from './utils';
import { getCommandBarCustomerIcon } from './icons/getCommandBarCustomerIcon';
import { ConditionalFilterOperators } from '../../components/conditionalFilters/ConditionalFilters';
import { CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES } from '../customers/components/Customers';

export function setCustomersSearch(environmentId: string, navigation: Navigation, isLightTheme?: boolean) {
  const doFetchCustomers: SearchFunction = async (search: string) => {
    const filter = generateFetchCustomersFilters({
      environmentId,
      conditionalFilters: Object.values(CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES).map((attribute) => ({
        attribute,
        operator: ConditionalFilterOperators.IS,
        value: search,
      })),
    });
    const paging = { first: MAX_RESULTS_PER_COMMANDBAR_SEARCH };

    const response = await apolloClient.query<FetchCustomersQuery, FetchCustomersQueryVariables>({
      query: getFetchCustomersQuery(false),
      fetchPolicy: 'network-only',
      variables: {
        paging,
        sorting: { field: CustomerSortFields.CreatedAt, direction: SortDirection.Desc },
        filter,
      },
    });

    const customers = response.data.customers.edges;
    return customers.map((customer) => {
      const { name, customerId, email } = customer.node;
      const label = name || email;
      return {
        customerId,
        label: label || customerId,
        description: label ? customerId : '',
        icon: getCommandBarCustomerIcon(email, isLightTheme),
      };
    });
  };

  window.CommandBar.addRecords('customers', [], {
    onInputChange: executeSafely(doFetchCustomers),
    defaultIcon: getCommandBarCustomerIcon(undefined, isLightTheme),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.customerPage('{{record.customerId}}'),
    },
  });
}
