import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { watchCouponSyncStates } from '../queries/watchCouponSyncStates';
import { updateCouponSyncState } from '../couponsSlice';
import { useAppDispatch, AppDispatch } from '../../../redux/store';

const DEFAULT_POLL_INTERVAL = 2000;

function startPolling(couponId: string, dispatch: AppDispatch, pollInterval: number) {
  const observableQuery = watchCouponSyncStates(couponId, pollInterval);

  observableQuery.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.coupons) {
        Sentry.captureException('got unexpected response for polling coupon sync states', { extra: { value } });
      }

      const coupons = value.data.coupons.edges;
      const fetchedCoupon = coupons.length > 0 ? coupons[0].node : null;
      if (fetchedCoupon) {
        dispatch(updateCouponSyncState(fetchedCoupon));
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling coupon sync states' } });
    },
  );

  return observableQuery;
}

export function usePollCouponSyncStates(
  couponId: string,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
  onChange?: CallableFunction,
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const observableQuery = startPolling(couponId, dispatch, pollInterval);
    return () => observableQuery?.stopPolling();
  }, [couponId, onChange, pollInterval, dispatch]);
}
