import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Box, Button, Collapse, Divider, GridFlex, Icon, InformationTooltip, Text, TextField } from '@stigg-components';
import get from 'lodash/get';
import { Trans } from 'react-i18next';
import { EntitlementResetPeriod, PriceFeatureFragment } from '@stigg-types/apiTypes';
import isNumber from 'lodash/isNumber';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { ChargeType, UsageBasedInAdvanceCommitmentChargeType } from '../../SetPriceWizardForm.types';
import { EntitlementsResetPeriodsSelect } from '../../../../../../plans/components/EntitlementsResetPeriod/EntitlementsResetPeriods';
import {
  ENTITLEMENT_NO_RESET_VALUE,
  getAccordingToOptions,
  resetPeriodValueToText,
} from '../../../../../../plans/components/EntitlementsResetPeriod/EntitlementsResetPeriods.utils';
import { ResetPeriodConfiguration } from '../../../../../../plans/components/EntitlementsResetPeriod/EntitlementsResetPeriodConfiguration';

export const getChargeQuantityText = (
  charge: Pick<UsageBasedInAdvanceCommitmentChargeType, 'minUnitQuantity' | 'maxUnitQuantity'>,
): { count: number; countString: string } => {
  if (charge.minUnitQuantity && charge.maxUnitQuantity) {
    return {
      count: charge.maxUnitQuantity,
      countString: `${charge.minUnitQuantity}-${charge.maxUnitQuantity}`,
    };
  }
  if (charge.maxUnitQuantity) {
    return {
      count: charge.maxUnitQuantity,
      countString: `Up to ${charge.maxUnitQuantity}`,
    };
  }
  return {
    count: charge.minUnitQuantity ?? 1,
    countString: `At least ${charge.minUnitQuantity ?? 1}`,
  };
};

interface FeatureWithChargeTextProps {
  charge: UsageBasedInAdvanceCommitmentChargeType;
}

function FeatureWithChargeText({ charge }: FeatureWithChargeTextProps) {
  const { count, countString } = getChargeQuantityText(charge);
  return (
    <Text.B2>
      <Trans i18nKey="pricing.overageCharges.includedInYourPlan" values={{ count, countString }} />
    </Text.B2>
  );
}

export function OverageEntitlementComponent({
  entitlementId,
  feature,
}: {
  entitlementId: string;
  feature: PriceFeatureFragment | null;
}) {
  const { aPackage, formRenderProps } = useSetPriceWizardFormContext();
  const { values, setFieldTouched, setFieldValue, touched, errors, handleBlur, handleChange } = formRenderProps;

  const usageLimitId = `${entitlementId}['usageLimit']`;
  const resetPeriodId = `${entitlementId}['resetPeriod']`;
  const resetPeriodConfigurationId = `${entitlementId}['resetPeriodConfiguration']`;
  const i18nPath = 'pricing.entitlements.resetPeriod';

  const value = get(values, usageLimitId);
  const resetPeriodValue = get(values, resetPeriodId);
  const [showEntitlementResetPeriodsRow, setShowEntitlementResetPeriodsRow] = useState(!!resetPeriodValue);

  useEffect(() => {
    setShowEntitlementResetPeriodsRow(!!resetPeriodValue);
  }, [resetPeriodValue]);

  if (!feature) {
    return null;
  }

  const connectedCharge = values.charges.find(
    (charge) => charge.type === ChargeType.UsageBased && charge.feature?.id === feature.id,
  );

  const packageEntitlement = aPackage?.entitlements?.find((entitlement) => entitlement.feature?.id === feature.id);

  if (packageEntitlement && !get(touched, usageLimitId)) {
    setFieldValue(usageLimitId, packageEntitlement?.usageLimit);
    setFieldTouched(usageLimitId);
  }

  const onResetPeriodChange = (event: any) => {
    const value = event.target.value === ENTITLEMENT_NO_RESET_VALUE ? null : event.target.value;

    setFieldValue(resetPeriodId, value);
    setFieldTouched(resetPeriodId);

    const accordingToDefaultValue = getAccordingToOptions(value, i18nPath)?.[0].value;
    setFieldValue(resetPeriodConfigurationId, accordingToDefaultValue);
    setFieldTouched(resetPeriodId);
  };

  const onResetPeriodConfigurationChange = (event: any) => {
    setFieldValue(resetPeriodConfigurationId, event.target.value);
    setFieldTouched(resetPeriodId);
  };

  return (
    <GridFlex.Column gap={4}>
      <GridFlex.RowCenter container gap={2}>
        {connectedCharge ? (
          <GridFlex.Column item>
            <FeatureWithChargeText charge={connectedCharge as UsageBasedInAdvanceCommitmentChargeType} />
          </GridFlex.Column>
        ) : (
          <>
            <GridFlex.Column item>
              <TextField
                name={usageLimitId}
                value={value}
                touched={!!get(touched, usageLimitId)}
                error={!!get(errors, usageLimitId)}
                placeholder="1"
                type="number"
                width={200}
                isNumberWithoutSigns
                isNumberWithoutFraction
                endAdornment={
                  <Text.B2 color="disabled">
                    {t('pricing.overageCharges.price.entitlement.endAdornment', { count: isNumber(value) ? value : 1 })}
                  </Text.B2>
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </GridFlex.Column>
            <GridFlex.Column item>
              <Text.B2>{t('sharedComponents.with')}</Text.B2>
            </GridFlex.Column>
            <GridFlex.Column item minWidth={200}>
              <EntitlementsResetPeriodsSelect
                name={resetPeriodId}
                value={resetPeriodValue}
                onChange={onResetPeriodChange}
                i18nPath={i18nPath}
                withNoResetOption={false}
                defaultSelection={EntitlementResetPeriod.Month}
              />
            </GridFlex.Column>
            {resetPeriodValue ? (
              <GridFlex.Column item>
                <InformationTooltip
                  arrow
                  placement="top"
                  title={
                    <Text.B2>
                      {showEntitlementResetPeriodsRow
                        ? t('entitlements.resetSettings.toggle.hide')
                        : t('entitlements.resetSettings.toggle.show')}
                    </Text.B2>
                  }>
                  <Button
                    $outlined
                    onClick={() => setShowEntitlementResetPeriodsRow((prev) => !prev)}
                    variant="outlined"
                    color={showEntitlementResetPeriodsRow ? 'primary' : 'outlineBorder'}
                    style={{ height: 40, padding: 8, minWidth: 'unset' }}>
                    <Icon icon={showEntitlementResetPeriodsRow ? 'SettingsOpened' : 'SettingsClosed'} />
                  </Button>
                </InformationTooltip>
              </GridFlex.Column>
            ) : null}
          </>
        )}
      </GridFlex.RowCenter>
      <Collapse in={!!resetPeriodValue && showEntitlementResetPeriodsRow}>
        {resetPeriodValue ? (
          <>
            <GridFlex.RowCenter container gap={1}>
              <GridFlex.Column item>
                <Text.B2>
                  <Trans
                    i18nKey={`${i18nPath}.usageResetLabel`}
                    values={{ resetPeriod: resetPeriodValueToText(i18nPath, get(values, resetPeriodId)) }}
                  />
                </Text.B2>
              </GridFlex.Column>
              <GridFlex.Column item minWidth={220}>
                <ResetPeriodConfiguration
                  resetPeriodConfigurationId={resetPeriodConfigurationId}
                  value={get(values, resetPeriodConfigurationId)}
                  resetPeriod={get(values, resetPeriodId)}
                  onChange={onResetPeriodConfigurationChange}
                  i18nPath={i18nPath}
                />
              </GridFlex.Column>
            </GridFlex.RowCenter>
            <GridFlex.RowCenter style={{ width: '100%' }}>
              <Divider my={4} style={{ width: '100%' }} />
            </GridFlex.RowCenter>
          </>
        ) : (
          <Box height={40} /> // Placeholder to keep the layout consistent when collapsing
        )}
      </Collapse>
    </GridFlex.Column>
  );
}
