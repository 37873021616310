import { createContext, useContext, useMemo } from 'react';
import { AddonFragment, PlanFragment, PricingType } from '@stigg-types/apiTypes';
import { FormRenderProps } from '@stigg-components';
import { SetPriceWizardFormFields } from './SetPriceWizardForm.types';
import { TooltipFields } from '../../../../../../components/InformationTooltip';
import { SectionIdentifier } from './SetPriceWizardForm.steps';

type SetPriceWizardContextType = {
  aPackage: PlanFragment | AddonFragment;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  disabledPricingTypes: Map<PricingType, TooltipFields>;
  initialFocusedSection?: SectionIdentifier;
};

const SetPriceWizardFormContext = createContext<SetPriceWizardContextType | null>(null);

export function useSetPriceWizardFormContext() {
  const ctx = useContext(SetPriceWizardFormContext);
  if (!ctx) {
    throw new Error('Could not find SetPriceWizardContext');
  }
  return ctx;
}

export function SetPriceWizardFormProvider({
  children,
  aPackage,
  formRenderProps,
  disabledPricingTypes,
  initialFocusedSection,
}: {
  children: React.ReactNode;
  aPackage: PlanFragment | AddonFragment;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  disabledPricingTypes: Map<PricingType, TooltipFields>;
  initialFocusedSection?: SectionIdentifier;
}) {
  const value = useMemo(
    () => ({ aPackage, formRenderProps, disabledPricingTypes, initialFocusedSection }),
    [aPackage, formRenderProps, disabledPricingTypes, initialFocusedSection],
  );

  return <SetPriceWizardFormContext.Provider value={value}>{children}</SetPriceWizardFormContext.Provider>;
}
