import React from 'react';
import { SxProps } from '@mui/system';
import { ItamarTheme } from '@stigg-theme';
import { Alert, ExternalLink, Text } from '@stigg-components';
import { t } from 'i18next';

interface MissingBillingIntegrationProps {
  sx?: SxProps<ItamarTheme>;
}

function MissingBillingIntegration({ sx }: MissingBillingIntegrationProps) {
  return (
    <Alert sx={{ ...sx, alignItems: 'center' }} severity="warning">
      <Text.B2>
        {t('widgets.missingBillingIntegration.alert')}
        <ExternalLink url={t('widgets.missingBillingIntegration.url')}>
          {t('widgets.missingBillingIntegration.link')}
        </ExternalLink>
      </Text.B2>
    </Alert>
  );
}

export default MissingBillingIntegration;
