import { PackageEntitlement } from '@stigg-types/apiTypes';
import { Query } from '@cubejs-client/core';
import { composeFilterForResetPeriod } from './cubeFilters.helper';

export function composeUsageDistributionQuery(
  environmentId: string,
  featureRefId: string,
  entitlement: PackageEntitlement | undefined,
  planRefIds: string[],
  showUncompletedPeriod: boolean,
): Query {
  return {
    measures: [
      'FeatureUsageDistribution.customersCount',
      'FeatureUsageDistribution.rangeBottom',
      'FeatureUsageDistribution.bucketSize',
    ],
    dimensions: ['FeatureUsageDistribution.bucket', 'FeatureUsageDistribution.planRefId'],
    filters: [
      {
        member: 'FeatureUsageDistribution.environmentId',
        operator: 'equals',
        values: [environmentId],
      },
      {
        member: 'FeatureUsageDistribution.featureRefId',
        operator: 'equals',
        values: [featureRefId],
      },
      {
        member: 'FeatureUsageDistribution.planRefId',
        operator: 'equals',
        values: planRefIds,
      },
      ...composeFilterForResetPeriod('FeatureUsageDistribution', entitlement, showUncompletedPeriod),
    ],
    order: [['FeatureUsageDistribution.bucket', 'asc']],
  };
}
