import { Divider, Grid, GridFlex, PageHeader } from '@stigg-components';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { isEmpty, pick } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Users } from 'react-feather';
import { CursorPaging, CustomerListFragment, CustomerSortFields } from '@stigg-types/apiTypes';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { EmptyList } from '../../../components/EmptyList';
import Table from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';
import { archiveCustomerByIdAction, updateCustomerAction } from '../customersSlice';
import { CustomerCreateDialog } from './createCustomer/CustomerCreateDialog';
import { CustomerUpdateDialog } from './customerPage/CustomerUpdateDialog';
import { UpdateCustomerFormFields } from './customerPage/CustomerUpdateForm';
import { headCells } from './headCells';
import { CustomerFilters } from './CustomerFilters';
import { CUSTOMER_PLAN_FILTER_OPTIONS, SUBSCRIPTION_STATUS_FILTER_OPTIONS } from './CustomerFiltersDefinitions';
import { useQueryParamsArray } from '../../common/useQueryParamsArray';
import { CustomerFilterChipProps } from './CustomerFilterChip';
import {
  ConditionalFilter,
  ConditionalFilterOperators,
  ConditionsBox,
} from '../../../components/conditionalFilters/ConditionalFilters';
import { useConditionalFilter } from '../../../components/conditionalFilters/useConditionalFilter';
import { generateGetCustomersQueryKey, useGetCustomers } from '../queries/useGetCustomers';
import { useReactQueryPagination } from '../../../components/table/useReactQueryPagination';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';

export const PLAN_PRICE_QUERY_STRING_PARAM = 'plan';
export const SUBSCRIPTION_STATUS_QUERY_STRING_PARAM = 'status';

export const CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES = pick(CustomerSortFields, [
  'CustomerId',
  'Name',
  'Email',
  'BillingId',
]);

const getCustomerFilterOptionsValues = <T extends string>(options: CustomerFilterChipProps<T>['options']): T[] =>
  options.reduce<T[]>(
    (acc, option) => [
      ...acc,
      ...(option.type === 'option' ? [option.value] : option.options.map(({ value }) => value)),
    ],
    [],
  );

const DEFAULT_CUSTOMER_FILTER_KEY = 'STIGG_DEFAULT_CUSTOMER_SEARCH_FILTER';

const getDefaultCustomerFilter = () => {
  const fallbackValue: ConditionalFilter = {
    attribute: CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES.CustomerId,
    operator: ConditionalFilterOperators.IS,
    value: '',
  };

  try {
    const defaultCustomerFilterJson = localStorage.getItem(DEFAULT_CUSTOMER_FILTER_KEY) as string;
    const defaultCustomerFilter = JSON.parse(defaultCustomerFilterJson) as ConditionalFilter;
    if (!defaultCustomerFilter) {
      return fallbackValue;
    }
    return defaultCustomerFilter;
  } catch (e) {
    return fallbackValue;
  }
};

const setDefaultFilter = (filter: ConditionalFilter) => {
  try {
    const defaultFilter: ConditionalFilter = {
      attribute: filter.attribute,
      operator: filter.operator,
      value: '',
    };
    localStorage.setItem(DEFAULT_CUSTOMER_FILTER_KEY, JSON.stringify(defaultFilter));
  } catch (e) {
    console.error('Could not set default customer search filter in local storage', e);
  }
};

function Customers() {
  const navigation = useNavigation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [customer, setCustomer] = useState<CustomerListFragment | null>(null);
  const [cursor, setCursor] = useState<CursorPaging>({ first: DEFAULT_TABLE_PAGE_SIZE });
  const dispatch = useAppDispatch();

  const [planPriceFilter, setPlanPriceFilter] = useQueryParamsArray(
    PLAN_PRICE_QUERY_STRING_PARAM,
    useMemo(() => getCustomerFilterOptionsValues(CUSTOMER_PLAN_FILTER_OPTIONS), []),
  );

  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useQueryParamsArray(
    SUBSCRIPTION_STATUS_QUERY_STRING_PARAM,
    useMemo(() => getCustomerFilterOptionsValues(SUBSCRIPTION_STATUS_FILTER_OPTIONS), []),
  );

  const defaultFilter = getDefaultCustomerFilter();

  const { conditionalFilter, setConditionalFilter, appliedConditionalFilter } = useConditionalFilter(
    CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES,
    defaultFilter,
  );

  const applyConditionalFilter = (filter: ConditionalFilter) => {
    setDefaultFilter(filter);
    setConditionalFilter(filter);
  };

  const { isPending, data } = useGetCustomers({
    pricingTypes: planPriceFilter,
    subscriptionStatuses: subscriptionStatusFilter,
    conditionalFilters: [appliedConditionalFilter],
    cursor,
  });
  const { customers, totalCount, pageInfo } = data || {};

  const { pageNumber, pageChangeFunc } = useReactQueryPagination(pageInfo, setCursor, DEFAULT_TABLE_PAGE_SIZE);

  const queryClient = useQueryClient();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  const refetchCurrentCustomers = async () => {
    await queryClient.refetchQueries({
      queryKey: generateGetCustomersQueryKey({
        environmentId,
        pricingTypes: planPriceFilter,
        subscriptionStatuses: subscriptionStatusFilter,
        conditionalFilters: [appliedConditionalFilter],
        cursor,
      }),
      type: 'active',
    });
  };

  const onUpdate = async (updatePayload: UpdateCustomerFormFields) => {
    if (customer?.id) {
      await dispatch(updateCustomerAction(updatePayload));
      await refetchCurrentCustomers();
    }
    setEditDialogOpen(false);
  };

  const onEditClick = (customer: CustomerListFragment) => {
    setCustomer(customer);
    setEditDialogOpen(true);
  };
  const onCreateClick = () => {
    setCreateDialogOpen(true);
  };
  const onDeleteClick = (customer: CustomerListFragment) => {
    setCustomer(customer);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && customer?.id) {
      await dispatch(archiveCustomerByIdAction(customer.customerId));
      await refetchCurrentCustomers();
    }
    setDeleteDialogOpen(false);
  };

  const filterApplied = useMemo(
    () => !isEmpty(appliedConditionalFilter.value) || planPriceFilter.length > 0 || subscriptionStatusFilter.length > 0,
    [appliedConditionalFilter, planPriceFilter, subscriptionStatusFilter],
  );

  return (
    <>
      <PageHeader title={t('customers.customers')} buttonTitle={t('customers.new')} buttonClick={onCreateClick} />
      <Divider mt={4} />
      <GridFlex.Column container mb={10}>
        <Grid>
          <ConditionsBox
            attributes={CUSTOMER_CONDITIONAL_FILTER_ATTRIBUTES}
            operators={ConditionalFilterOperators}
            conditionalFilter={conditionalFilter}
            setConditionalFilter={applyConditionalFilter}
          />

          <CustomerFilters
            planPriceFilter={planPriceFilter}
            setPlanPriceFilter={setPlanPriceFilter}
            subscriptionStatusFilter={subscriptionStatusFilter}
            setSubscriptionStatusFilter={setSubscriptionStatusFilter}
          />
        </Grid>
        {isEmpty(customers) && !isPending ? (
          <EmptyList
            title={filterApplied ? t('customers.emptySearchText') : t('customers.emptyStateText')}
            linkText={filterApplied ? '' : t('customers.emptyStateTextActionLinkText')}
            onLinkClick={onCreateClick}
            icon={Users}
          />
        ) : (
          <Table
            isLoading={isPending}
            headCells={headCells(onEditClick, onDeleteClick)}
            label={t('customers.table')}
            pageChangeFunc={pageChangeFunc}
            pageInfo={pageInfo}
            data={customers || []}
            pageNum={pageNumber}
            totalCount={totalCount}
            onRowClick={(customer: { __typename?: 'Customer' } & CustomerListFragment) =>
              navigation.navigateTo(`/customers/${customer.customerId}`)
            }
          />
        )}
      </GridFlex.Column>
      <CustomerCreateDialog open={createDialogOpen} setOpen={setCreateDialogOpen} />
      {customer && (
        <CustomerUpdateDialog
          customer={customer}
          onUpdate={onUpdate}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
        />
      )}
      <ConfirmationDialog
        confirmationButtonText={t('customers.archive')}
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('customers.deleteDialogTitle')}
        content={t('customers.dialogDelete')}
      />
    </>
  );
}

export default Customers;
