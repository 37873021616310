import { ReactNode } from 'react';
import { GridFlex, Text } from '@stigg-components';

type DetailsRowProps = {
  title: string;
  value: string | ReactNode;
};

export default function DetailsRow({ title, value }: DetailsRowProps) {
  if (!value) {
    return null;
  }

  return (
    <GridFlex.Row>
      <Text.B2 minWidth={170}>{title}</Text.B2>
      {typeof value === 'string' ? <Text.B2>{value}</Text.B2> : value}
    </GridFlex.Row>
  );
}
