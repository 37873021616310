import { ExperimentGroupType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { EMPTY_CHAR, Text } from '@stigg-components';
import * as React from 'react';
import numeral from 'numeral';
import { HeadCell } from '../../../../../../components/table/Table.types';

const PERCENT_FORMAT = '0.[00]%';

export type GroupResult = {
  groupType: ExperimentGroupType;
  groupName: string;
  percentage: number;
  subscriptions: number | null;
  paidSubscriptions: number | null;
};

export const headCells = (): Array<HeadCell<GroupResult, any>> => [
  {
    id: 'variation',
    alignment: 'left',
    label: t('experiments.variation'),
    render: (groupResult) => <Text.B2>{groupResult.groupName}</Text.B2>,
  },
  {
    id: 'percentage',
    alignment: 'left',
    label: t('experiments.percentage'),
    render: (groupResult) => <Text.B2>{numeral(groupResult.percentage / 100).format(PERCENT_FORMAT)}</Text.B2>,
  },
  {
    id: 'subscriptions',
    alignment: 'left',
    label: t('experiments.totalSubscriptions'),
    render: (groupResult) => <Text.B2>{groupResult.subscriptions || EMPTY_CHAR}</Text.B2>,
  },
  {
    id: 'paidSubscriptions',
    alignment: 'left',
    label: t('experiments.paidSubscriptions'),
    render: (groupResult) => <Text.B2>{groupResult.paidSubscriptions || EMPTY_CHAR}</Text.B2>,
  },
  {
    id: 'conversion',
    alignment: 'left',
    label: t('experiments.conversionRate'),
    render: (groupResult) => (
      <Text.B2>
        {(groupResult.subscriptions &&
          numeral((groupResult.paidSubscriptions || 0) / groupResult.subscriptions).format(PERCENT_FORMAT)) ||
          EMPTY_CHAR}
      </Text.B2>
    ),
  },
];
