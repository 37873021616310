import { Shadows } from '@mui/material/styles/shadows';

function createShadow() {
  // todo: figure out if we should use elevation shadows or not, so it will be consistent
  return 'none'; // return `0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;
}

const shadows: Shadows = [
  'none',
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
];

export default shadows;
