import { FeatureType } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useState } from 'react';
import { Field, FormRenderProps } from '@stigg-components';
import { getEntitlementValueFields } from './getEntitlementValueFields';
import { isMetered } from '../../../../features/utils/isMetered';
import { getEntitlementResetPeriodFields } from './getEntitlementResetPeriodFields';
import { getEntitlementSoftLimitFields } from './getEntitlementSoftLimitFields';
import { getEntitlementBehaviorFields } from './getEntitlementBehaviorFields';
import { EntitlementFullFields, EntitlementFormFields } from '../types';

export type UseEntitlementsSettingsFieldsProps = {
  formRenderProps: FormRenderProps<EntitlementFormFields>;
  index: number;
  withCustomEntitlementOption?: boolean;
  withEntitlementBehaviourOption?: boolean;
};

export function useEntitlementSettingsFields({
  formRenderProps,
  index,
  withCustomEntitlementOption,
  withEntitlementBehaviourOption,
}: UseEntitlementsSettingsFieldsProps) {
  const { entitlementBehaviorConfiguration: entitlementBehaviorConfigurationEnabled } = useFlags<FeatureFlags>();
  const [resetPeriodConfigurationOpen, setResetPeriodConfigurationOpen] = useState<boolean>(false);

  const entitlementOptional = formRenderProps.values.entitlements[index];

  const [optionsByEntitlementFeatureId, setOptionsByEntitlementFeatureId] = useState<{
    [key: string]: { isOpen?: boolean; previousValue?: number | null };
  }>({});

  if (!entitlementOptional.feature) {
    return [];
  }

  const entitlement: EntitlementFullFields = entitlementOptional as EntitlementFullFields;

  const getEntitlementPreviousValue = (featureId: string) => optionsByEntitlementFeatureId[featureId]?.previousValue;

  const setEntitlementPreviousValue = (featureId: string, value?: number | null) =>
    setOptionsByEntitlementFeatureId((prevState) => ({
      ...prevState,
      [featureId]: { ...prevState[featureId], previousValue: value },
    }));

  const previousValue = getEntitlementPreviousValue(entitlement.feature?.id);

  const fields: Field<EntitlementFormFields>[] = [];

  fields.push(
    ...getEntitlementValueFields(
      formRenderProps,
      entitlement,
      index,
      withCustomEntitlementOption,
      setEntitlementPreviousValue,
      previousValue,
    ),
  );

  if (isMetered(entitlement.feature)) {
    fields.push(
      ...getEntitlementResetPeriodFields<EntitlementFullFields, EntitlementFormFields>({
        index,
        value: entitlement,
        valueId: 'entitlements',
        resetPeriodConfigurationOpen,
        setResetPeriodConfigurationOpen,
      }),
    );

    fields.push(...getEntitlementSoftLimitFields(formRenderProps, index, previousValue));
  }

  if (entitlement.feature.featureType === FeatureType.Number && entitlementBehaviorConfigurationEnabled) {
    fields.push(...getEntitlementBehaviorFields(index, withEntitlementBehaviourOption));
  }

  return fields;
}
