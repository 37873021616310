import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LoadingButton,
  Button,
  Text,
} from '@stigg-components';
import { ConfirmationDialogProps } from '@stigg-components/types';
import { t } from 'i18next';
import { Save } from 'react-feather';
import { useEffect, useRef, useState } from 'react';

export function ConfirmationDialog({
  open,
  handleClose,
  title,
  content,
  width = 500,
  confirmationButtonText = t('sharedComponents.delete'),
  cancelButtonText = t('sharedComponents.cancel'),
  confirmButtonColor = 'error',
  reverseButtonsOrder,
  disabled,
}: ConfirmationDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  const handleConfirm = async () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setIsLoading(true);
    try {
      await handleClose(true);
    } finally {
      // setting timeout to avoid flickering while
      // the dialog is closing with animation
      timeoutId.current = setTimeout(() => setIsLoading(false), 200);
    }
  };

  useEffect(() => {
    return () => timeoutId.current && clearTimeout(timeoutId.current);
  }, []);

  const cancelButtonOutlined = !reverseButtonsOrder;
  const cancelButtonVariant = reverseButtonsOrder ? 'contained' : 'outlined';

  const confirmButtonOutlined = reverseButtonsOrder;
  const confirmButtonVariant = !reverseButtonsOrder ? 'contained' : 'outlined';

  const cancelButton = (
    <Button
      key="cancel"
      onClick={() => handleClose(false)}
      variant={cancelButtonVariant}
      $outlined={cancelButtonOutlined}>
      {cancelButtonText}
    </Button>
  );

  const confirmButton = (
    <LoadingButton
      key="confirm"
      loadingPosition="start"
      loading={isLoading}
      disabled={disabled}
      // HACK: we need to show some icon here when button is on loading
      // state so the position of the loading spinner will be correct :/
      startIcon={isLoading ? <Save /> : null}
      onClick={handleConfirm}
      data-testid="button-dialog-confirm"
      variant={confirmButtonVariant}
      $outlined={confirmButtonOutlined}
      color={confirmButtonColor}>
      {confirmationButtonText}
    </LoadingButton>
  );

  const buttons = [cancelButton, confirmButton];
  if (reverseButtonsOrder) {
    buttons.reverse();
  }

  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      PaperProps={{ sx: { p: 10, minWidth: width, backgroundColor: (theme) => theme.itamar.palette.background.paper } }}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" sx={{ p: 0, pb: 6 }}>
        <Text.H3>{title}</Text.H3>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }} id="alert-dialog-description">
          <Text.B2>{content}</Text.B2>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 0, pt: 8 }}>{buttons}</DialogActions>
    </Dialog>
  );
}
