import { EventLog as EventLogSDK } from '@stigg-types/apiTypes';

export const getEventListCursors = (
  events?: EventLogSDK[] | null,
  currentEvent?: EventLogSDK | null,
  eventSetter?: (event: EventLogSDK) => void,
) => {
  if (!currentEvent || !events || !events.length || !eventSetter) {
    return {};
  }

  const currentEventIdx = events.findIndex((event) => event.id === currentEvent.id);

  const previousEvent = events[currentEventIdx - 1];
  const nextEvent = events[currentEventIdx + 1];

  return {
    setPrevEvent: previousEvent ? () => eventSetter(previousEvent) : undefined,
    setNextEvent: nextEvent ? () => eventSetter(nextEvent) : undefined,
  };
};
