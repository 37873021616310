import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  BudgetConfigurationInput,
  CustomerSubscriptionDataFragment,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PricingType,
  SubscriptionAddonInput,
  SubscriptionDataFragment,
  SubscriptionEntitlementInput,
} from '@stigg-types/apiTypes';
import { getConfirmedEntitlements } from '../../../../../entitlements/components/entitlementSettings/types';
import { SubscriptionFormFields } from './SubscriptionForm.types';
import { getResetPeriodData } from '../../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';
import {
  convertAddonPriceOverrideToInput,
  convertPriceOverrideToInput,
  generateSubscriptionRefId,
  mapBillableFeaturesToBillableFeaturesInput,
  mapMinimumSpendInput,
} from './SubscriptionForm.utils';
import { createSubscriptionAction, updateSubscriptionAction } from '../../../../customersSlice';
import { AppDispatch } from '../../../../../../redux/store';
import { mapSubscriptionFormEntitlementsToSubscriptionEntitlementsUpdate } from '../../../subscriptions/subscriptionEntitlements/subscriptionEntitlementsUtils';

export const handleUpdateSubscription = (
  existingSubscription: SubscriptionDataFragment | CustomerSubscriptionDataFragment,
  values: SubscriptionFormFields,
  dispatch: AppDispatch,
) => {
  const { subscriptionId, coupon: existingCoupon } = existingSubscription;
  const subscriptionEntitlements = mapSubscriptionFormEntitlementsToSubscriptionEntitlementsUpdate(values.entitlements);

  const addons: SubscriptionAddonInput[] = values.addons.map((addon) => ({
    addonId: addon.refId,
    quantity: addon.quantity || 1,
  }));

  const billableFeatures = mapBillableFeaturesToBillableFeaturesInput(values.billableFeatures);

  let billingInfo = values.isInvoicePaid ? { isInvoicePaid: values.isInvoicePaid } : {};
  // @ts-ignore-next-line
  billingInfo = values.invoiceDaysUntilDue ? { ...billingInfo, invoiceDaysUntilDue: values.invoiceDaysUntilDue } : {};

  const budget: BudgetConfigurationInput | null = values.budget
    ? {
        limit: values.budget.limit || 0,
        hasSoftLimit: !!values.budget.hasSoftLimit,
      }
    : null;

  const chargesOverrides = convertPriceOverrideToInput(values.billableFeaturesPriceOverride);
  const addonOverrides = convertAddonPriceOverrideToInput(values.addonsPriceOverride);
  const priceOverrides =
    chargesOverrides || addonOverrides ? [...(chargesOverrides || []), ...(addonOverrides || [])] : undefined;

  const appliedCoupon = {
    couponId: values.couponRefId ? values.couponRefId : existingCoupon?.refId ? '' : undefined, // remove coupon if it exists
  };

  return dispatch(
    updateSubscriptionAction({
      subscriptionId,
      addons,
      subscriptionEntitlements,
      budget,
      appliedCoupon,
      ...(billableFeatures ? { billableFeatures } : {}),
      ...(values.isTrial && values.endDate ? { trialEndDate: values.endDate } : {}),
      ...(!isEmpty(billingInfo) ? { billingInformation: billingInfo } : {}),
      ...(priceOverrides ? { priceOverrides } : {}),
    }),
  );
};

export const handleCreateSubscription = (
  customerId: string,
  dispatch: AppDispatch,
  { entitlements, ...values }: SubscriptionFormFields,
  plan?: PlanListFragment | PlanFragment,
  isAllowEmptyResourceIdEnabled?: boolean,
) => {
  const subscriptionNewEntitlements = getConfirmedEntitlements(entitlements).map<SubscriptionEntitlementInput>(
    (entitlement) => ({
      featureId: entitlement.feature.id,
      description: entitlement.description,
      hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
      hasSoftLimit: entitlement.hasSoftLimit,
      usageLimit: !isNil(entitlement.usageLimit) ? Math.floor(entitlement.usageLimit) : null,
      ...getResetPeriodData(entitlement.resetPeriod, entitlement.resetPeriodConfiguration),
    }),
  );

  const addons: SubscriptionAddonInput[] = values.addons.map((addon) => ({
    addonId: addon.refId,
    quantity: addon.quantity || 1,
  }));
  // If there is no trial set, currently the endDate is never set (from UI)
  if (!values.isTrial) {
    values.endDate = undefined;
  }
  if (values.paymentCollectionMethod === PaymentCollectionMethod.None) {
    values.billingPeriod = undefined;
    values.billingCountryCode = undefined;
  }

  const resolveResourceId = (resourceId: string, isMultipleSubscriptions?: boolean, allowEmptyResourceId?: boolean) => {
    if (!isMultipleSubscriptions || (allowEmptyResourceId && !resourceId)) {
      return undefined;
    }
    return resourceId;
  };

  const resourceId = resolveResourceId(
    values.resourceId,
    plan?.product.multipleSubscriptions,
    isAllowEmptyResourceIdEnabled,
  );
  const isBackdated = moment(values.startDate).startOf('day').isBefore(moment().startOf('day'));
  const budget: BudgetConfigurationInput | null = values.budget
    ? {
        limit: values.budget.limit || 0,
        hasSoftLimit: !!values.budget.hasSoftLimit,
      }
    : null;

  let billingInfo = {};
  billingInfo = isBackdated ? { ...billingInfo, isBackdated } : billingInfo;
  billingInfo = values.isInvoicePaid ? { ...billingInfo, isInvoicePaid: values.isInvoicePaid } : billingInfo;
  billingInfo = values.invoiceDaysUntilDue
    ? { ...billingInfo, invoiceDaysUntilDue: values.invoiceDaysUntilDue }
    : billingInfo;

  const chargesOverrides = convertPriceOverrideToInput(values.billableFeaturesPriceOverride);
  const addonOverrides = convertAddonPriceOverrideToInput(values.addonsPriceOverride);
  const priceOverrides =
    chargesOverrides || addonOverrides ? [...(chargesOverrides || []), ...(addonOverrides || [])] : undefined;

  const minimumSpend = values.isTrial
    ? undefined
    : mapMinimumSpendInput(values.minimumSpend, values.billingPeriod, plan);

  return dispatch(
    createSubscriptionAction({
      ...omit(
        values,
        'productId',
        'isInvoicePaid',
        'billableFeaturesPriceOverride',
        'addonsPriceOverride',
        'minimumSpend',
        'invoiceDaysUntilDue',
        'coupon',
        'couponRefId',
      ),
      subscriptionId: generateSubscriptionRefId(values.planId),
      addons,
      billingPeriod: values.billingPeriod || null,
      customerId,
      resourceId,
      budget,
      billableFeatures: mapBillableFeaturesToBillableFeaturesInput(values.billableFeatures),
      isOverridingTrialConfig: values.isTrial === isEmpty(plan?.defaultTrialConfig),
      awaitPaymentConfirmation: false,
      subscriptionEntitlements: subscriptionNewEntitlements,
      ...(!isEmpty(billingInfo) ? { billingInformation: billingInfo } : {}),
      subscriptionPricingType:
        values.paymentCollectionMethod === PaymentCollectionMethod.None ? PricingType.Custom : plan!.pricingType!,
      priceOverrides,
      minimumSpend,
      appliedCoupon: {
        couponId: values.couponRefId,
      },
    }),
  );
};
