import React from 'react';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import { DateText, ExternalLink, GridFlex, Icon, Link, LongText, Text } from '@stigg-components';
import { DetailsLayoutType } from '@stigg-components/types';
import { PricingType, SubscriptionDataFragment, SubscriptionStatus, VendorIdentifier } from '@stigg-types/apiTypes';
import { useIntegrations } from '@stigg-common';
import { SubscriptionDataType } from './useSubscriptionData';
import { SubscriptionPrice } from '../../customerPage/customerSubscriptions/SubscriptionPrice';
import { TrialSubscriptionIndicatorIcon } from '../../customerPage/customerSubscriptions/TrialSubscriptionIndicatorIcon';
import { IntegrationIdLink, IntegrationIdWithIcon } from '../../../../../components/IntegrationIdLink';
import { OutdatePriceSign } from './components/OutdatedSubscriptionSign';
import { packageVersionFormat } from '../../../../packages/common/components/packageVersionFormat';
import { PackageVersionNumber } from '../../../../packages/common/components/PackageVersionNumber';
import { useSalesforceUrl } from '../../../../integrations/components/salesforce/useSalesforceUrl';
import { SubscriptionNextBill } from './components/SubscriptionNextBill';
import { SubscriptionLatestInvoice } from './components/SubscriptionLatestInvoice';
import { SubscriptionMaximumSpend } from './components/SubscriptionMaximumSpend';
import { SubscriptionPayingCustomer } from './components/SubscriptionPayingCustomer';

export const useSubscriptionDetailsLayout = (
  subscription: SubscriptionDataFragment,
  { navigation, priceBreakdown, showMonthlyPriceVariation, subscriptionPrices, coupon }: SubscriptionDataType,
  onMarkInvoiceAsPaidClick: () => void,
): DetailsLayoutType => {
  const { hasCRMIntegration, hasBillingIntegration } = useIntegrations();
  const { salesforceUrl } = useSalesforceUrl(subscription.salesforceId, 'OPPORTUNITY');
  const billingPeriod = subscriptionPrices?.[0]?.price?.billingPeriod;
  const isPaidSubscription = subscription.pricingType === PricingType.Paid;

  const shouldShowMaxSpend =
    !!subscription.budget &&
    isPaidSubscription &&
    [SubscriptionStatus.Active, SubscriptionStatus.InTrial].includes(subscription.status);

  const shouldShowNextBill = subscription.status === SubscriptionStatus.Active && !subscription.cancellationDate;
  const shouldPrintNextInvoiceBreakdown =
    shouldShowNextBill &&
    isPaidSubscription &&
    ![SubscriptionStatus.Canceled, SubscriptionStatus.Expired].includes(subscription.status);

  return [
    {
      title: t('subscriptions.refId'),
      content: subscription.subscriptionId,
      copyableChip: true,
      withLabel: true,
    },
    {
      title: t('subscriptions.subscriptionPlan'),
      content: (
        <GridFlex.RowCenter item container>
          <Link
            mr={2}
            variant="body2"
            onClick={() => {
              navigation.navigateTo(
                navigation.appRoutes.planPage(subscription.plan.refId, subscription.plan.versionNumber),
              );
            }}>
            {subscription.plan.product.displayName} / {subscription.plan.displayName}{' '}
            {!subscription.plan.isLatest ? packageVersionFormat(subscription.plan.versionNumber) : ''}
          </Link>
          <TrialSubscriptionIndicatorIcon subscription={subscription} />
          {!subscription.plan.isLatest && (
            <PackageVersionNumber
              versionNumber={subscription.plan.versionNumber}
              withoutVersionNumber
              tooltip={t('packages.outdatedPlanVersion')}
            />
          )}
        </GridFlex.RowCenter>
      ),
    },
    {
      title: t('sharedComponents.resourceId'),
      content: (
        <GridFlex.RowCenter gap={1}>
          <Icon icon="Layers" type="reactFeather" color="active" size={16} />
          <LongText variant="body2" color="secondary" wordBreak>
            {subscription.resource?.resourceId}
          </LongText>
        </GridFlex.RowCenter>
      ),
      alignItems: 'center',
      shouldPrintLine: !!subscription.resource,
      helperTooltipPlacement: 'top',
      helperTooltipMaxWidth: 235,
      helperTooltip: (
        <GridFlex.Column gap={2}>
          <Text.B2>{t('sharedComponents.resourceIdExplanation')}</Text.B2>
          <ExternalLink
            label={t('sharedComponents.learnMore')}
            url="https://docs.stigg.io/docs/multiple-subscriptions"
          />
        </GridFlex.Column>
      ),
    },
    {
      title: t('subscriptions.billingId'),
      content: (
        <IntegrationIdLink
          vendorCategory="BILLING"
          entityType="SUBSCRIPTION"
          syncStates={subscription.syncStates}
          refId={subscription.subscriptionId}
          to={{
            integrationId: subscription.billingId || subscription.oldBillingId,
            integrationLinkUrl: subscription.billingLinkUrl,
          }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasBillingIntegration,
    },
    {
      title: t('subscriptions.crmId'),
      content: (
        <IntegrationIdLink
          vendorCategory="CRM"
          entityType="SUBSCRIPTION"
          syncStates={subscription.syncStates}
          refId={subscription.subscriptionId}
          to={{ integrationId: subscription.crmId, integrationLinkUrl: subscription.crmLinkUrl }}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: hasCRMIntegration,
    },
    {
      title: t('subscriptions.salesforceId'),
      content: (
        <IntegrationIdWithIcon
          integrationId={subscription.salesforceId}
          integrationType={VendorIdentifier.Salesforce}
          integrationLinkUrl={salesforceUrl}
        />
      ),
      alignItems: 'center',
      shouldPrintLine: !!subscription.salesforceId,
    },
    {
      title: capitalize(t('subscriptions.recurringPrice')),
      shouldPrintLine: !shouldPrintNextInvoiceBreakdown,
      content:
        subscription.pricingType === PricingType.Free ? (
          <Text.B2>{t('subscriptions.freePricing')}</Text.B2>
        ) : subscription.pricingType === PricingType.Custom || !subscriptionPrices?.length ? (
          <Text.B2>{t('subscriptions.customPricing')}</Text.B2>
        ) : priceBreakdown && !!subscriptionPrices?.length ? (
          <GridFlex.RowCenter data-testid="subscription-price" gap={2}>
            <SubscriptionPrice
              priceBreakdown={priceBreakdown}
              showMonthlyPriceVariation={!!showMonthlyPriceVariation}
              coupon={coupon}
            />
            {subscription.outdatedPricePackages?.includes(subscription.plan.refId) && <OutdatePriceSign />}
          </GridFlex.RowCenter>
        ) : null,
    },
    {
      title: t('subscriptions.billingPeriod'),
      content: <Text.B2>{t(`pricing.billingPeriodDescriptions.${billingPeriod}`)}</Text.B2>,
      shouldPrintLine: !!billingPeriod,
    },
    {
      title: t('subscriptions.subscriptionStartedAt'),
      content: <DateText date={subscription.startDate} withTime />,
    },
    {
      title: t('subscriptions.subscriptionEndedAt'),
      content: <DateText date={subscription.effectiveEndDate} withTime />,
      shouldPrintLine: !!subscription.effectiveEndDate,
    },
    {
      title: t('subscriptions.latestInvoice'),
      shouldPrintLine: !!subscription.latestInvoice?.pdfUrl,
      content: !!subscription.latestInvoice?.pdfUrl && (
        <SubscriptionLatestInvoice
          latestInvoice={subscription.latestInvoice}
          onMarkInvoiceAsPaidClick={onMarkInvoiceAsPaidClick}
        />
      ),
    },
    {
      title: t('subscriptions.subscriptionNextBillingPeriod'),
      content: <DateText date={subscription.currentBillingPeriodEnd} withTime />,
      shouldPrintLine:
        !!subscription.currentBillingPeriodEnd &&
        ![SubscriptionStatus.Canceled, SubscriptionStatus.Expired].includes(subscription.status),
    },
    {
      title: t('subscriptions.subscriptionNextEstimatedBill'),
      content: shouldPrintNextInvoiceBreakdown ? <SubscriptionNextBill subscription={subscription} /> : null,
      shouldPrintLine: shouldPrintNextInvoiceBreakdown,
    },
    {
      title:
        subscription.status === SubscriptionStatus.InTrial
          ? t('subscriptions.trialTotalSpend')
          : t('subscriptions.maximumSpend'),
      content: <SubscriptionMaximumSpend subscription={subscription} />,
      shouldPrintLine: shouldShowMaxSpend,
    },
    {
      title: t('subscriptions.paidBy'),
      content: <SubscriptionPayingCustomer payingCustomer={subscription.payingCustomer} />,
      shouldPrintLine: !!subscription.payingCustomer,
    },
  ];
};
