import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  Customer,
  AttachCustomerPaymentMethodInput,
  AttachCustomerPaymentMethodMutation,
  AttachCustomerPaymentMethodMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely, ExecuteOperationOptions } from '../../common';
import { AppDispatch, RootState } from '../../../redux/store';

const SAVE_CUSTOMER_PAYMENT_METHOD = gql`
  mutation AttachCustomerPaymentMethod($input: AttachCustomerPaymentMethodInput!) {
    attachCustomerPaymentMethod(input: $input) {
      id
      name
      email
      billingId
      billingLinkUrl
      updatedAt
      additionalMetaData
      defaultPaymentMethodId
      defaultPaymentMethodType
      defaultPaymentMethodLast4Digits
    }
  }
`;

async function attachCustomerPaymentMethod(
  input: AttachCustomerPaymentMethodInput & ExecuteOperationOptions,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Customer> | undefined> {
  const { withSuccessMessage = true, ...actionInput } = input;

  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<
        AttachCustomerPaymentMethodMutation,
        AttachCustomerPaymentMethodMutationVariables
      >({
        mutation: SAVE_CUSTOMER_PAYMENT_METHOD,
        variables: { input: { ...actionInput, environmentId: accountReducer.currentEnvironmentId } },
      });

      return response.data?.attachCustomerPaymentMethod;
    },
    {
      successMessage: withSuccessMessage ? t('customers.api.paymentMethod.addSuccess') : undefined,
      failureMessageHandler: () => t('customers.api.paymentMethod.addFail'),
    },
    dispatch,
  );
}

export { attachCustomerPaymentMethod };
