import { Text, Grid, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import toLower from 'lodash/toLower';
import { Auth0Credentials, Auth0Region, IntegrationFragment } from '@stigg-types/apiTypes';
import { Optional } from '@stigg-types/primitives';
import { truncateApiKey as truncate } from '../../../accounts/utils/truncate';
import { CountryFlag } from '../../../packages/pricing/components/currency/CountryWithFlagByCode';

export type Auth0IntegrationDetailsProps = { integration: IntegrationFragment };

export function Auth0IntegrationDetails({ integration }: Auth0IntegrationDetailsProps) {
  const credentials = integration.credentials as Optional<Auth0Credentials>;
  if (!credentials) {
    return null;
  }
  const { clientId, clientSecret, tenant, region } = credentials;
  return (
    <>
      <Grid item container flexDirection="column">
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.auth0TenantDomain')}</Text.Sub2>
          <GridFlex.Row columnGap={2}>
            <CountryFlag countryCode={region === Auth0Region.Uk ? 'gb' : region.toLowerCase()} />
            <Text.B2>{toLower(`${tenant}.${t(`integrations.auth0RegionDescriptor.${region}`)}.auth0.com`)}</Text.B2>
          </GridFlex.Row>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.clientId')}</Text.Sub2>
          <Text.B2>{clientId}</Text.B2>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.clientSecret')}</Text.Sub2>
          <Text.B2>{clientSecret && truncate(clientSecret)}</Text.B2>
        </GridFlex.Column>
      </Grid>
    </>
  );
}
