import { LocalDate } from '@stigg-components';
import * as React from 'react';
import { t } from 'i18next';

export function DatePeriod({ start, end }: { start: Date | string | undefined; end: Date | string | undefined }) {
  if (!start && !end) {
    return <>-</>;
  }
  if (start && end) {
    return (
      <>
        <LocalDate date={start} formatOptions={{ withTime: true }} />
        {' - '}
        <LocalDate date={end} formatOptions={{ withTime: true }} />
      </>
    );
  }
  if (start) {
    return (
      <>
        <LocalDate date={start} formatOptions={{ withTime: true }} />
        {' - '}
        {t('localDate.now')}
      </>
    );
  }
  // assuming start is always before end
  return <></>;
}
