import { t } from 'i18next';
import * as Yup from 'yup';
import { Form, Text, ExternalLink } from '@stigg-components';
import { Dialog } from '../../../../../components/Dialog';
import { WidgetType } from '../../../../../types/apiTypes';
import { PlanEntitlement } from './PackageGrantedEntitlements.types';
import { formatEntitlementDisplayText } from '../../../../entitlements/formatEntitlementDisplayText';
import { EntitlementPaywallCustomerVisibilityFormField } from '../hiddenFromWidgets/EntitlementPaywallCustomerVisibilityFormField';

const EMPTY_CHAR = '‎';

const validationSchema = Yup.object().shape({
  hiddenFromWidgets: Yup.array().required().min(0),
  displayNameOverride: Yup.string().optional(),
});

export type EditEntitlementDisplayOptionsDialogProps = {
  open: boolean;
  entitlement: PlanEntitlement | null;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
};

export type EditEntitlementDisplayOptionsFormFields = {
  id: PlanEntitlement['id'];
  hiddenFromWidgets: PlanEntitlement['hiddenFromWidgets'];
  displayNameOverride?: PlanEntitlement['displayNameOverride'];
};

export function EditEntitlementDisplayOptionsDialog({
  open,
  entitlement,
  handleSubmit,
  handleClose,
}: EditEntitlementDisplayOptionsDialogProps) {
  const initialValues: EditEntitlementDisplayOptionsFormFields = {
    id: entitlement?.id || '',
    hiddenFromWidgets: entitlement?.hiddenFromWidgets || [],
    displayNameOverride: entitlement?.displayNameOverride || '',
  };

  const dialogContent = () => {
    if (!entitlement) {
      return null;
    }

    return (
      <Form
        disableSaveOnNoChanges
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancel={handleClose}
        submitButtonText={t('sharedComponents.saveChanges')}
        fields={({ values }) => {
          const entitlementDisplayName = formatEntitlementDisplayText({
            feature: entitlement.feature,
            hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
            resetPeriod: entitlement.resetPeriod,
            usageLimit: entitlement.usageLimit,
          });

          return [
            {
              type: 'custom',
              render: ({ setFieldValue }) => {
                const isPaywallHidden = (values?.hiddenFromWidgets || []).some((value) => value === WidgetType.Paywall);

                return (
                  <EntitlementPaywallCustomerVisibilityFormField
                    isPaywallHidden={isPaywallHidden}
                    onToggle={(hiddenFromWidgets) => setFieldValue('hiddenFromWidgets', hiddenFromWidgets)}
                  />
                );
              },
            },
            {
              id: 'displayNameOverride',
              type: 'text',
              label: 'Custom display text',
              helpTooltipMaxWidth: 250,
              helpTooltipText: (
                <>
                  <Text.B2 mb={2}>{t('widgets.displayNameOverride.overrideFieldHelpTooltipText')}</Text.B2>
                  <ExternalLink
                    label={t('sharedComponents.learnMore')}
                    url={t('widgets.displayNameOverride.learnMoreLink')}
                  />
                </>
              ),
              placeholder: entitlementDisplayName,
              captionText: values.displayNameOverride
                ? `${t('sharedComponents.default')}: ${entitlementDisplayName}`
                : EMPTY_CHAR,
            },
          ];
        }}
      />
    );
  };

  return (
    <Dialog
      isOpen={open}
      content={dialogContent()}
      titleText={t('entitlements.customizeEntitlementDisplayOptions')}
      onCancel={handleClose}
    />
  );
}
