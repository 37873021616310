import { ListItemText, MenuDropdown, GridFlex, InformationTooltip, Text, Button, Icon } from '@stigg-components';
import * as React from 'react';
import { OptionsDropdownType } from '@stigg-components/types';
import { ButtonColor } from '@stigg-theme';
import isEmpty from 'lodash/isEmpty';
import * as S from './ButtonAndOptionsDropdown.style';

export type ButtonDropdownOptions = {
  icon?: React.ReactNode;
  text: string | React.ReactNode;
  onClick?: () => void;
  dataTestId?: string;
};

export function ButtonAndOptionsDropdown({
  isMenuWidthBasedOnTriggerWidth,
  menuItemId = '',
  color,
  variant = 'contained',
  button,
  options,
}: {
  isMenuWidthBasedOnTriggerWidth?: boolean;
  menuItemId?: string;
  color: ButtonColor;
  variant?: 'contained' | 'outlined';
  button: ButtonDropdownOptions;
  options: OptionsDropdownType;
}) {
  const primaryButton = (
    <Button
      variant={variant}
      $outlined={variant === 'outlined'}
      color={color}
      startIcon={button.icon}
      data-testid={button.dataTestId}
      sx={{ height: 36 }}
      onClick={(e) => {
        e.stopPropagation();
        button.onClick?.();
      }}>
      {button.text}
    </Button>
  );

  const shownOptions = options.filter(({ hide }) => !hide);

  if (isEmpty(shownOptions)) {
    return primaryButton;
  }

  return (
    <MenuDropdown
      isMenuWidthBasedOnTriggerWidth={isMenuWidthBasedOnTriggerWidth}
      dropdownTrigger={
        <S.PrimaryMenuItem id={menuItemId}>
          {primaryButton}
          <Button
            variant={variant}
            $outlined={variant === 'outlined'}
            color={color}
            sx={{
              width: 36,
              height: 36,
              borderInlineStart: variant === 'contained' ? `1px solid #ffffff80 !important;` : undefined,
            }}>
            <Icon icon="ArrowDropDown" color={variant === 'contained' ? 'white' : 'active'} />
          </Button>
        </S.PrimaryMenuItem>
      }
      menuItems={shownOptions.map(({ onClick, text, icon: Icon, disabled, tooltip }, index) => (
        <S.MenuItem key={index} disabled={disabled} onClick={onClick}>
          <InformationTooltip
            arrow
            placement={tooltip?.placement || 'top'}
            title={tooltip?.title || ''}
            PopperProps={{
              onClick: (e) => disabled && e.stopPropagation(),
            }}>
            <GridFlex.RowCenter
              $fullWidth
              sx={{ pointerEvents: 'auto' }}
              onClick={(e) => disabled && e.stopPropagation()}>
              <S.ListItemIcon>
                <Icon />
              </S.ListItemIcon>
              <ListItemText primary={<Text.B2>{text}</Text.B2>} />
            </GridFlex.RowCenter>
          </InformationTooltip>
        </S.MenuItem>
      ))}
    />
  );
}
