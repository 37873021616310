import { t } from 'i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { drawFormFields, Field, TextFields, Text } from '@stigg-components';
import { SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { ALL_BILLING_PERIODS } from '../chargesStep/utils/getPricePeriodFields';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';

export function MinimumSpendForm() {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { defaultCurrency, minimumSpend } = formRenderProps.values;

  const fields: Field<SetPriceWizardFormFields>[] = [];

  if (!minimumSpend?.enabled) {
    return null;
  }

  const formFields: TextFields<SetPriceWizardFormFields>[] = ALL_BILLING_PERIODS.map((billingPeriod) => ({
    type: 'text',
    textFieldType: 'number',
    isNumberWithoutSigns: true,
    id: `minimumSpend.minimums.${billingPeriod}`,
    hide: ({ billingPeriods }) => !billingPeriods.includes(billingPeriod),
    label: t(`pricing.minimumSpend.periods.${billingPeriod}`),
    placeholder: t('pricing.minimumSpend.placeholder'),
    startAdornment: getSymbolFromCurrency(defaultCurrency),
    endAdornment: <Text.Caption>/ {t(`pricing.shortBillingPeriodPriceDescription.${billingPeriod}`)}</Text.Caption>,
  }));

  fields.push({
    type: 'layout',
    gridProps: {
      mt: 4,
      alignItems: 'baseline',
    },
    fields: formFields,
  });

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
