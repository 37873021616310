import { isNumber } from 'lodash';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { FeatureType } from '@stigg-types/apiTypes';
import { Text, GridFlex, LongText, Grid } from '@stigg-components';
import { SummaryWithFeature } from '../CustomerEntitlementsSummary';
import { formatFeatureUnits } from '../../../../../common/formatFeatureUnits';

const GridWithStroke = styled(Grid)<{ withStroke: boolean }>`
  text-decoration: ${(props) => (props.withStroke ? 'line-through' : 'none')};
`;

export function FeatureSummaryValueBox({
  summaryWithFeature: {
    featurePackageEntitlement,
    featurePromotionalEntitlement,
    priceEntitlement,
    addonQuantity,
    feature,
    isEffectiveEntitlement,
  },
}: {
  summaryWithFeature: SummaryWithFeature;
}) {
  const { hasUnlimitedUsage: promotionalHasUnlimitedUsage, usageLimit: promotionalUsageLimit } =
    featurePromotionalEntitlement || {};
  const { hasUnlimitedUsage: packageHasUnlimitedUsage, usageLimit: packageUsageLimit } =
    featurePackageEntitlement || {};
  const { hasUnlimitedUsage: priceEntitlementHasUnlimitedUsage, usageLimit: priceEntitlementUsageLimit } =
    priceEntitlement || {};

  if (!feature) {
    return null;
  }

  if (feature.featureType === FeatureType.Boolean) {
    return (
      <GridFlex.RowCenter container>
        <Grid item pr={2}>
          <Text.B2>{t('entitlements.accessGranted')}</Text.B2>
        </Grid>
      </GridFlex.RowCenter>
    );
  }
  if (packageHasUnlimitedUsage || promotionalHasUnlimitedUsage || priceEntitlementHasUnlimitedUsage) {
    return (
      <GridWithStroke withStroke={!isEffectiveEntitlement} item>
        {t('entitlements.unlimitedUsage')}
      </GridWithStroke>
    );
  }
  if (priceEntitlementUsageLimit) {
    return (
      <GridWithStroke withStroke={!isEffectiveEntitlement} item>
        <LongText variant="body2" tooltipPlacement="bottom">
          {formatFeatureUnits(feature, priceEntitlementUsageLimit)}
        </LongText>
      </GridWithStroke>
    );
  }
  if (promotionalUsageLimit) {
    return (
      <GridWithStroke withStroke={!isEffectiveEntitlement} item>
        <LongText variant="body2" tooltipPlacement="bottom">
          {formatFeatureUnits(feature, promotionalUsageLimit)}
        </LongText>
      </GridWithStroke>
    );
  }
  // could be null in case there is not winning policy (eg just sum of addons)
  const shouldStrike = !isEffectiveEntitlement;
  switch (featurePackageEntitlement?.package?.type) {
    case 'Addon':
      return (
        <GridWithStroke
          withStroke={shouldStrike}
          title={isNumber(addonQuantity) ? `Add-on quantity: ${addonQuantity}` : ''}
          item>
          <LongText variant="body2" tooltipPlacement="bottom">
            {`${addonQuantity} x ${formatFeatureUnits(feature, packageUsageLimit)}`}
          </LongText>
        </GridWithStroke>
      );

    case 'Plan':
      return (
        <GridWithStroke withStroke={shouldStrike} item>
          <LongText variant="body2" tooltipPlacement="bottom">
            {`${formatFeatureUnits(feature, packageUsageLimit)}`}
          </LongText>
        </GridWithStroke>
      );
    default:
      return null;
  }
}
