import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Text } from '@stigg-components';
import styled from 'styled-components/macro';
import { useCookies } from 'react-cookie';
import { useAnalytics } from '../../../common/useAnalytics';
import { useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { SANDBOX_TEMPLATE_COOKIE_NAME } from '../../../navigation/Router';
import { mergeEnvironmentAction } from '../../accountsSlice';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  align-items: center;
  justify-content: center;
`;
type SandboxCreationPageParams = {
  templateName: string;
};

export function SandboxCreationPage({ isDemo = false }: { isDemo: boolean }) {
  const dispatch = useAppDispatch();
  const { templateName } = useParams<SandboxCreationPageParams>();
  const { track } = useAnalytics();
  const [creatingEnv, setCreatingEnv] = useState(false);
  const [isTemplateNotFound, setIsTemplateNotFound] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([SANDBOX_TEMPLATE_COOKIE_NAME]);
  const slicesTemplate = templateName.split('-')[0];
  const capitalizedTemplateName = slicesTemplate.charAt(0).toUpperCase() + slicesTemplate.slice(1);

  useEffect(() => {
    const onCreate = async () => {
      setCreatingEnv(true);

      const response = await fetch(
        `https://stigg-formation-templates.s3.us-east-2.amazonaws.com/${isDemo ? 'demo' : 'new'}/${templateName}.json`,
      );

      if (response.ok) {
        const sourceTemplate = await response.json();
        const destinationEnvironmentName = `${capitalizedTemplateName} sandbox`;

        const { environmentSlug } = await dispatch(
          mergeEnvironmentAction({
            sourceTemplate,
            destinationEnvironmentName,
            withSuccessMessage: false,
          }),
        ).unwrap();

        track('Sandbox template created', { templateName });
        removeCookie(SANDBOX_TEMPLATE_COOKIE_NAME);

        if (isDemo) {
          window.location.href = `/${environmentSlug}/products/default`;
        } else {
          window.location.href = `/${environmentSlug}/widgets/paywall?showcode=true`;
        }
      } else {
        setIsTemplateNotFound(true);
      }

      setCreatingEnv(false);
    };
    void onCreate();
  }, [dispatch, templateName, track, removeCookie, capitalizedTemplateName, isDemo]);

  const message = creatingEnv
    ? `Cloning ${capitalizedTemplateName} pricing in Stigg...`
    : isTemplateNotFound
    ? `Template ${capitalizedTemplateName} not found.`
    : null;

  return (
    <Root>
      {message && <Text.H1> {message}</Text.H1>}
      {creatingEnv && <Loader useAnimationLoader />}
    </Root>
  );
}
