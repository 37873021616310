import { GridFlex, Icon, MenuItem, Select, Text } from '@stigg-components';
import React from 'react';
import { CustomerFilter, StringFieldComparison } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import Search from '../Search';

export enum ConditionalFilterOperators {
  IS = 'IS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
}

export type ConditionalFilter = {
  attribute?: string;
  operator?: ConditionalFilterOperators;
  value?: string;
};

export function getStringFieldComparison(conditionalFilter: ConditionalFilter): StringFieldComparison {
  switch (conditionalFilter.operator) {
    case ConditionalFilterOperators.IS:
      return { eq: conditionalFilter.value };
    case ConditionalFilterOperators.STARTS_WITH:
      return { iLike: `${conditionalFilter.value}%` };
    case ConditionalFilterOperators.ENDS_WITH:
      return { iLike: `%${conditionalFilter.value}` };
    case ConditionalFilterOperators.CONTAINS:
      return { iLike: `%${conditionalFilter.value}%` };
    default:
      return {};
  }
}

export function parseConditionalFilterToQueryFilter(conditionalFilter?: ConditionalFilter): CustomerFilter {
  if (!conditionalFilter || !conditionalFilter.attribute || !conditionalFilter.operator || !conditionalFilter.value) {
    return {};
  }
  const stringFieldComparison = getStringFieldComparison(conditionalFilter);

  return { [conditionalFilter.attribute]: stringFieldComparison };
}

export function ConditionsBox({
  attributes,
  operators,
  conditionalFilter,
  setConditionalFilter,
}: {
  attributes: Record<string, string>;
  operators: Record<string, ConditionalFilterOperators>;
  conditionalFilter: ConditionalFilter;
  setConditionalFilter: (ConditionalFilter) => void;
}) {
  return (
    <GridFlex.RowCenter container gap={2} maxWidth={700} my={2}>
      <GridFlex.RowCenter gap={2}>
        <Icon type="materialIcons" icon="FilterList" color="active" />
        <Text.B2 color="secondary">{t('sharedComponents.conditionalFilters.title')}</Text.B2>
      </GridFlex.RowCenter>
      <GridFlex.Row>
        <Select
          width={150}
          value={conditionalFilter.attribute}
          dataTestId="attribure-select-input"
          renderValue={(value) => <Text.B2>{t(`sharedComponents.conditionalFilters.attributes.${value}`)}</Text.B2>}>
          {Object.entries(attributes).map(([value, attribute]) => (
            <MenuItem
              key={value}
              value={attribute}
              onClick={() => {
                setConditionalFilter({ ...conditionalFilter, attribute });
              }}>
              <Text.B2 color="primary">{t(`sharedComponents.conditionalFilters.attributes.${attribute}`)}</Text.B2>
            </MenuItem>
          ))}
        </Select>
      </GridFlex.Row>

      <GridFlex.Row>
        <Select
          width={150}
          value={conditionalFilter.operator}
          dataTestId="operator-select-input"
          renderValue={(value) => <Text.B2>{t(`sharedComponents.conditionalFilters.operators.${value}`)}</Text.B2>}>
          {Object.entries(operators).map(([value, operator]) => (
            <MenuItem
              key={value}
              value={operator}
              onClick={() => {
                setConditionalFilter({ ...conditionalFilter, operator });
              }}>
              <Text.B2 color="primary">{t(`sharedComponents.conditionalFilters.operators.${operator}`)}</Text.B2>
            </MenuItem>
          ))}
        </Select>
      </GridFlex.Row>
      <GridFlex.Row width={300}>
        <Search
          variant="outlined"
          placeholder={t('sharedComponents.conditionalFilters.placeholder')}
          handleSearchFunc={(value) => {
            setConditionalFilter({ ...conditionalFilter, value });
          }}
          searchedStr={conditionalFilter.value ? conditionalFilter.value : undefined}
          shouldMaskFromHjAndFs
          searchOnChange
          hideSearchIcon
        />
      </GridFlex.Row>
    </GridFlex.RowCenter>
  );
}
