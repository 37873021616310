import { GridFlex, RadioGroup, Text, RadioItem, OutlinedFormFieldLabel } from '@stigg-components';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import {
  createNotSupportedByAwsProductTooltipFields,
  createSubscriptionTypeTooltipFields,
  MultipleSubscriptionsLabel,
  SingleSubscriptionsLabel,
} from './ProductSubscriptionType.utils';

export type ProductSubscriptionTypeProps = {
  value: boolean;
  updateValue: (value: boolean) => void;
  disabled?: boolean;
  isAwsProduct?: boolean;
  subscriptionTypeText?: string;
};

export function ProductSubscriptionType({
  value,
  updateValue,
  isAwsProduct = false,
  disabled = false,
  subscriptionTypeText,
}: ProductSubscriptionTypeProps) {
  const multipleSubscriptionsTooltip = useMemo(() => createSubscriptionTypeTooltipFields(true), []);
  const singleSubscriptionTooltip = useMemo(() => createSubscriptionTypeTooltipFields(false), []);
  const notSupportedByAwsProductTooltip = useMemo(() => createNotSupportedByAwsProductTooltipFields(), []);
  const isDisabled = isAwsProduct || disabled;

  return (
    <GridFlex.Column $fullWidth>
      <RadioGroup
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          updateValue((event.target as HTMLInputElement).value === 'true')
        }>
        <GridFlex.Column gap={1}>
          <OutlinedFormFieldLabel label={t('products.subscriptionType')} marginBottom={0} />
          <GridFlex.Row gap={4}>
            <RadioItem
              height={67}
              label={<SingleSubscriptionsLabel disabled={isDisabled} />}
              disabled={isDisabled}
              value={false}
              selected={!value}
              tooltip={isAwsProduct ? notSupportedByAwsProductTooltip : singleSubscriptionTooltip}
            />
            <RadioItem
              height={67}
              label={<MultipleSubscriptionsLabel disabled={isDisabled} />}
              disabled={isDisabled}
              value
              selected={value}
              tooltip={isAwsProduct ? notSupportedByAwsProductTooltip : multipleSubscriptionsTooltip}
            />
          </GridFlex.Row>
          {!isAwsProduct ? (
            <Text.Sub2 color="secondary">{subscriptionTypeText ?? t('products.subscriptionTypeText')}</Text.Sub2>
          ) : null}
        </GridFlex.Column>
      </RadioGroup>
    </GridFlex.Column>
  );
}
