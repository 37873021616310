import { useSelector } from 'react-redux';
import { ApiKeyType } from '@stigg-types/apiTypes';
import { RootState } from '../../../redux/store';

export const useCurrentClientApiKey = (): string | undefined => {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  return currentEnvironment?.apiKeys.find((x) => x.keyType === ApiKeyType.Client)?.token;
};
