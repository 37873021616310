import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useQueryParam(paramName: string) {
  const { search } = useLocation();
  const history = useHistory();

  const value = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(paramName);
  }, [search, paramName]);

  const setQueryParam = useCallback(
    (value: string) => {
      const urlSearchParams = new URLSearchParams(search);
      urlSearchParams.set(paramName, value);
      history.push({ search: urlSearchParams.toString() });
    },
    [history, search, paramName],
  );

  const removeQueryParam = useCallback(() => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete(paramName);
    history.push({ search: urlSearchParams.toString() });
  }, [history, search, paramName]);

  return { setQueryParam, removeQueryParam, value };
}
