import styled, { css } from 'styled-components/macro';
import { GridFlex } from '../Grids';

export const FormWrapperGrid = styled(GridFlex.Row)<{ $withStickyFooter?: boolean }>`
  width: 100%;
  > form {
    width: 100%;
  }

  ${({ $withStickyFooter }) =>
    $withStickyFooter &&
    css`
      height: 100%;
      > form {
        height: 100%;
      }
    `}
`;
