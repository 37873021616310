import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import { setIsDrawerExpanded, setIsMobileDrawerOpen } from '../../modules/application/applicationSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import getSidebarItems from './sidebar/sidebarItems';
import { TopBanner, TOP_BANNER_HEIGHT } from './TopBanner';
import { useReloadEnvironmentResources } from './useReloadEnvironmentResources';
import { SANDBOX_ENVIRONMENT_BANNER_HEIGHT, SandboxEnvironmentBanner } from './SandboxEnvironmentBanner';
import { addEnvironmentAction } from '../../modules/accounts/accountsSlice';
import { useSwitchEnvironment } from '../../modules/navigation/useSwitchEnvironment';
import { useAnalytics } from '../../modules/common/useAnalytics';
import * as S from './AppLayout.style';
import { useCurrentMember } from '../../modules/auth/hooks/useCurrentMember';

export const useAppDrawer = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDrawerExpandedState = useSelector((state: RootState) => state.application.isDrawerExpanded);
  const drawerExpanded = isDrawerExpandedState === 'true' || isNil(isDrawerExpandedState);
  const isRightDrawerOpen = useSelector((state: RootState) => state.application.isRightDrawerOpen);
  const rightDrawerWidth = useSelector((state: RootState) => state.application.rightDrawerWidth);
  const mobileDrawerOpen = useSelector((state: RootState) => state.application.isMobileDrawerOpen);
  const isTopBannerOpen = useSelector((state: RootState) => state.application.isTopBannerOpen);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isDrawerExpanded = isMdUp ? drawerExpanded : mobileDrawerOpen;

  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const isSandboxBannerOpen = !!currentEnvironment?.isSandbox;

  const onDrawerToggle = () => {
    if (isMdUp) {
      dispatch(setIsDrawerExpanded(!isDrawerExpanded));
    } else {
      dispatch(setIsMobileDrawerOpen(!mobileDrawerOpen));
    }
  };

  return {
    isDrawerExpanded,
    isRightDrawerOpen,
    rightDrawerWidth,
    isTopBannerOpen,
    onDrawerToggle,
    isSandboxBannerOpen,
  };
};

const AppLayout = ({ children }: any) => {
  const { track } = useAnalytics();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const switchEnvironment = useSwitchEnvironment();
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();
  const currentMember = useCurrentMember();
  const editMode = useSelector((state: RootState) => state.application.editMode);
  const products = useSelector((state: RootState) => state.productReducer.products);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const {
    isDrawerExpanded,
    isRightDrawerOpen,
    rightDrawerWidth,
    isTopBannerOpen,
    onDrawerToggle,
    isSandboxBannerOpen,
  } = useAppDrawer();

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isXlDown = useMediaQuery(theme.breakpoints.down('xl'));

  useReloadEnvironmentResources();
  const sidebarItems = getSidebarItems({
    products,
    withGettingStartedPage: !isSandboxBannerOpen && !currentMember?.hideGettingStartedPage,
    withEventLog: showEventLog,
  });

  const leaveSandbox = async () => {
    track('Start integrating Stigg clicked');

    const newEnvironmentId = head((environments ?? []).filter(({ isSandbox }) => !isSandbox))?.id;
    if (newEnvironmentId) {
      switchEnvironment(newEnvironmentId);
      return;
    }

    const newEnvironment = await dispatch(
      addEnvironmentAction({ environment: { displayName: 'Development', color: '#4CAF50' } }),
    ).unwrap();
    switchEnvironment(newEnvironment.id);
  };

  if (!isAuthenticated || !currentMember) {
    return <S.StyledContent>{children}</S.StyledContent>;
  }

  const marginTop = isSandboxBannerOpen ? SANDBOX_ENVIRONMENT_BANNER_HEIGHT : isTopBannerOpen ? TOP_BANNER_HEIGHT : 0;

  return (
    <S.Root>
      <TopBanner open={isTopBannerOpen} />
      <SandboxEnvironmentBanner open={isSandboxBannerOpen} leaveSandbox={leaveSandbox} />
      <S.Drawer $open={isDrawerExpanded}>
        <Sidebar
          onClose={onDrawerToggle}
          open={isDrawerExpanded}
          variant={isMdUp ? 'permanent' : 'temporary'}
          items={sidebarItems}
          toggleSidebar={onDrawerToggle}
          marginTop={marginTop}
        />
      </S.Drawer>
      <S.AppContent>
        <Navbar
          onDrawerToggle={onDrawerToggle}
          drawerWidth={S.DRAWER_WIDTH}
          isDrawerExpanded={isDrawerExpanded}
          marginTop={marginTop}
        />
        <S.MainContent
          $isEditMode={editMode}
          $rightDrawerOpen={isRightDrawerOpen}
          $rightDrawerWidth={rightDrawerWidth}
          $drawerOpen={isDrawerExpanded}
          $marginTop={marginTop}
          $isXlDown={isXlDown}
          p={isLgUp ? 10 : 5}>
          {children}
        </S.MainContent>
      </S.AppContent>
    </S.Root>
  );
};

export default AppLayout;
