import { gql } from '@apollo/client';
import { t } from 'i18next';
import { InviteMembersMutation, InviteMembersMutationVariables, MembersInviteResponse } from '@stigg-types/apiTypes';
import { SnackbarMessage, executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { fetchAccountMembersAction } from '../accountsSlice';

const INVITE_MEMBERS = gql`
  mutation InviteMembersMutation($input: [String!]!) {
    inviteMembers(invites: $input) {
      successInvites
      skippedInvites
      failedInvites
    }
  }
`;

export function computeSnackbarMessage(inviteMembersResponse: MembersInviteResponse | undefined): SnackbarMessage[] {
  if (!inviteMembersResponse) {
    return [{ message: t('accounts.api.failMemInviteGeneral'), type: 'error' }];
  }

  const successMessages: SnackbarMessage[] = inviteMembersResponse.successInvites.map((email) => {
    return {
      message: t('accounts.api.successMemInvite', { email }),
      type: 'success',
    };
  });

  const skippedMessages: SnackbarMessage[] = inviteMembersResponse.skippedInvites.map((email) => {
    return {
      message: t('accounts.api.infoAlreadyInvited', { email }),
      type: 'info',
    };
  });

  const failedMessages: SnackbarMessage[] = inviteMembersResponse.failedInvites.map((email) => {
    return {
      message: t('accounts.api.failMemInvite', { email }),
      type: 'info',
    };
  });

  return [...successMessages, ...skippedMessages, ...failedMessages];
}

async function inviteMembers(invites: string[], { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<InviteMembersMutation, InviteMembersMutationVariables>({
        mutation: INVITE_MEMBERS,
        variables: { input: invites },
      });

      const inviteMembersResponse = response.data?.inviteMembers;

      await dispatch(fetchAccountMembersAction({}));

      return inviteMembersResponse;
    },
    {
      computeSnackbarMessage,
      failureMessageHandler: () => t('accounts.api.failMemInviteGeneral'),
    },
    dispatch,
  );
}

export { inviteMembers };
