import { Redirect, Route, RouteProps } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { RootState } from '../../../redux/store';
import { useNavigation } from '../../navigation/useNavigation';

function PublicOnlyRoute(props: RouteProps) {
  const navigation = useNavigation();
  const user = useSelector((state: RootState) => state.authReducer.user);

  if (!user || isEmpty(user.memberships)) {
    return <Route {...props} />;
  }

  return <Redirect to={{ pathname: navigation.getEnvPath('/') }} />;
}

export default PublicOnlyRoute;
