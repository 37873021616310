import { TextField } from '@stigg-components';
import { FormikTouched } from 'formik';
import kebabCase from 'lodash/kebabCase';

export function generateRefIdFromName(name: string, prefix: string, category?: string) {
  return `${prefix}${category ? `-${kebabCase(category)}` : ''}-${kebabCase(name)}`;
}

export function createDisplayNameTextFieldHandleChange(prefix: string, category?: string) {
  return <T extends { displayName: string; refId: string }>({
    values,
    touched,
    setFieldValue,
  }: {
    setFieldValue: (field: keyof T, value: string) => void;
    touched: FormikTouched<T>;
    values: T;
  }) => {
    return (e: any) => {
      setFieldValue('displayName', e.target.value);
      setFieldValue('refId', touched.refId ? values.refId : generateRefIdFromName(e.target.value, prefix, category));
    };
  };
}

type DisplayNameTextFieldProps<T extends { displayName: string; refId: string }> = {
  values: T;
  setFieldValue: (field: keyof T, value: string) => void;
  touched: any;
  errors: any;
  handleBlur: any;
  label: string;
  prefix: string;
  productName?: string;
  autoFocus?: boolean;
  onChange?: CallableFunction;
  dataTestId?: string;
};

export function DisplayNameTextField<T extends { displayName: string; refId: string }>({
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  label,
  prefix,
  productName,
  autoFocus,
  onChange,
  dataTestId,
}: DisplayNameTextFieldProps<T>) {
  return (
    <>
      <TextField
        autoFocus={autoFocus}
        name="displayName"
        label={label}
        value={values.displayName}
        touched={Boolean(touched.displayName)}
        error={Boolean(errors.displayName)}
        fullWidth
        errorText={touched.displayName && errors.displayName}
        onBlur={handleBlur}
        dataTestId={dataTestId}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          createDisplayNameTextFieldHandleChange(prefix, productName)({ values, touched, setFieldValue })(e);
        }}
      />
    </>
  );
}
