import { Text, Grid, GridFlex } from '@stigg-components';
import { EventLogType, TestHookResultFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Send } from 'react-feather';
import { useSelector } from 'react-redux';
import { styled } from '@stigg-theme';
import { ClipboardChip } from '../../../../components/clipboard/ClipboardChip';
import { DialogActionsButtons } from '../../../../components/Dialog';
import Loader from '../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchTestHookEventDataAction, sendTestHookEventAction } from '../../hooksSlice';

type TestHookEventProps = {
  endpointUrl: string;
  eventLogType: EventLogType;
  onCancel: () => void;
};

const StyledWrapperBox = styled(Grid)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

const JsonWrapper = styled(GridFlex.Column)<{ $isRequestFailed?: boolean }>`
  background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground};
  border: ${(props) => (props.$isRequestFailed ? '1px solid #F44336' : 'none')};
`;

const HeaderBox = styled(Grid)`
  border-bottom: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

const CodeTypography = styled(Text.B2)`
  font-family: 'Fira Code';
  word-break: break-all;
  white-space: pre-wrap;
`;

export function TestHookEvent({ eventLogType, endpointUrl, onCancel }: TestHookEventProps) {
  const dispatch = useAppDispatch();
  const environmentId = useSelector((root: RootState) => root.accountReducer.currentEnvironmentId) || '';
  const testHookData = useSelector((root: RootState) => root.hooksReducer.testHookData);
  const isLoadingTestHookData = useSelector((root: RootState) => root.hooksReducer.isLoadingTestHookData);
  const [testEventInProgress, setIsTestEventLoading] = useState(false);
  const [testEventResult, setTestEventResult] = useState<TestHookResultFragment | null>(null);

  useEffect(() => {
    void dispatch(fetchTestHookEventDataAction(eventLogType));
  }, [dispatch, eventLogType]);

  const handleTestClick = async () => {
    if (!testHookData?.testHookPayload) {
      return;
    }

    setIsTestEventLoading(true);
    const res = await dispatch(sendTestHookEventAction({ endpointUrl, hookEventType: eventLogType, environmentId }));
    setTestEventResult(res.payload as TestHookResultFragment);
    setIsTestEventLoading(false);
  };

  const jsonFormat = (json: string) => {
    return json.split('\n').map((line, index) => {
      return (
        <Grid container wrap="nowrap">
          <Grid item mr={4}>
            <Text.B2 color="disabled">{index + 1}</Text.B2>
          </Grid>
          <Grid item>
            <CodeTypography color="secondary">{line}</CodeTypography>
          </Grid>
        </Grid>
      );
    });
  };

  const getResponseStatusText = (testEventResult: TestHookResultFragment | null) => {
    if (!testEventResult) {
      return null;
    }

    const { responseStatusCode, responseStatusText, responseSuccess } = testEventResult;
    if (!responseStatusCode) {
      return null;
    }

    if (responseSuccess) {
      return (
        <Text.B2 fontWeight="lighter" color="#4CAF50">
          {responseStatusCode} {responseStatusText}
        </Text.B2>
      );
    }

    return (
      <Text.B2 fontWeight="lighter" color="#F44336">
        {responseStatusCode} {responseStatusText}
      </Text.B2>
    );
  };

  if (isLoadingTestHookData || !testHookData) {
    return <Loader />;
  }

  return (
    <Grid container flexDirection="column">
      <StyledWrapperBox item container flexDirection="column" mb={4}>
        <HeaderBox container item px={4} py={3} justifyContent="space-between" wrap="nowrap" alignItems="center">
          <Grid item>
            <Text.B2 fontWeight="bolder">{t('hooks.requestBody')}</Text.B2>
          </Grid>
          <Grid item>
            <ClipboardChip text={t('sharedComponents.copy')} copy={testHookData.testHookPayload} />
          </Grid>
        </HeaderBox>
        <JsonWrapper item minHeight={150} maxHeight={150} overflow="auto" p={4}>
          {jsonFormat(testHookData.testHookPayload)}
        </JsonWrapper>
      </StyledWrapperBox>
      <StyledWrapperBox item container flexDirection="column">
        <HeaderBox item container px={4} py={3} justifyContent="space-between" wrap="nowrap">
          <Grid container item alignItems="center">
            <Grid item mr={2}>
              <Text.B2 fontWeight="bolder">{t('hooks.response')}</Text.B2>
            </Grid>
          </Grid>
        </HeaderBox>
        <JsonWrapper
          $isRequestFailed={!!testEventResult && !testEventResult.responseSuccess}
          item
          minHeight={150}
          maxHeight={150}
          overflow="auto"
          p={4}>
          <CodeTypography color="secondary">{getResponseStatusText(testEventResult)}</CodeTypography>
        </JsonWrapper>
      </StyledWrapperBox>

      <DialogActionsButtons
        isSaveLoading={testEventInProgress}
        onSave={handleTestClick}
        saveStartIcon={<Send size={22} />}
        saveText={t('hooks.sendTestEvent')}
        cancelText={t('sharedComponents.dismiss')}
        onCancel={onCancel}
      />
    </Grid>
  );
}
