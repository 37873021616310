import { gql } from '@apollo/client';
import { FetchPlanSyncStatesQuery, FetchPlanSyncStatesQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';

const QUERY = gql`
  query FetchPlanSyncStates($filter: PlanFilter!) {
    plans(filter: $filter) {
      edges {
        node {
          id
          prices {
            id
            crmId
            crmLinkUrl
          }
          billingId
          billingLinkUrl
          syncStates {
            vendorIdentifier
            status
            error
          }
        }
      }
    }
  }
`;

export function watchPlanSyncStates(planId: string, pollInterval: number) {
  return apolloClient.watchQuery<FetchPlanSyncStatesQuery, FetchPlanSyncStatesQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: planId,
        },
      },
    },
  });
}
