import { useEffect, useState } from 'react';
import { Subscription } from '@stigg/react-sdk';
import { useOptionalStiggContext } from './useOptionalStiggContext';

export function useActiveSubscriptions() {
  const stigg = useOptionalStiggContext();
  const [activeSubscriptions, setActiveSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    const fetchActiveSubscriptions = async () => {
      if (!stigg) {
        return;
      }
      const subscriptions = await stigg.getActiveSubscriptions();
      setActiveSubscriptions(subscriptions);
    };
    void fetchActiveSubscriptions();
  }, [stigg]);
  return { activeSubscriptions };
}
