import { t } from 'i18next';
import { GridFlex, Icon, InformationTooltip, Text } from '..';
import { CustomerResponseFragment, PaymentMethodType } from '../../types/apiTypes';
import { GridProps } from '../Grids';

type IconProps = { iconSize?: number };

export const PaymentMethodIconByType = {
  [PaymentMethodType.Card]: ({ iconSize }: IconProps = {}) => (
    <Icon icon="CreditCard" type="materialIcons" color="default" size={iconSize} />
  ),
  [PaymentMethodType.Bank]: ({ iconSize }: IconProps = {}) => (
    <Icon icon="AccountBalance" type="materialIcons" color="default" size={iconSize} />
  ),
};

type PaymentDetailsProps = {
  paymentMethodType: CustomerResponseFragment['defaultPaymentMethodType'];
  last4Digits: CustomerResponseFragment['defaultPaymentMethodLast4Digits'];
  expirationMonth?: CustomerResponseFragment['defaultPaymentExpirationMonth'];
  expirationYear?: CustomerResponseFragment['defaultPaymentExpirationYear'];
  iconSize?: number;
  iconWrapperProps?: GridProps;
};

export function PaymentDetails({
  paymentMethodType,
  last4Digits,
  expirationMonth,
  expirationYear,
  iconSize,
  iconWrapperProps = {},
}: PaymentDetailsProps) {
  const resolvedPaymentMethodType = paymentMethodType || PaymentMethodType.Card;
  const paymentMethodText = t(`customers.paymentMethod.${resolvedPaymentMethodType}`);

  return (
    <InformationTooltip arrow placement="top" $padding={2} title={<Text.B2>{paymentMethodText}</Text.B2>}>
      <GridFlex.RowCenter>
        <GridFlex.RowCenter {...iconWrapperProps}>
          {PaymentMethodIconByType[resolvedPaymentMethodType]({ iconSize })}
        </GridFlex.RowCenter>

        <Text.B2 color="secondary" ml={2} className="fs-mask">
          {last4Digits
            ? t(`customers.paymentMethod.endingWith.${resolvedPaymentMethodType}`, { last4Digits })
            : paymentMethodText}
        </Text.B2>

        {!!(expirationMonth && expirationYear) && (
          <Text.B2 color="secondary" ml={4}>{`${t(
            'customers.paymentMethod.expiration',
          )} ${expirationMonth}/${expirationYear}`}</Text.B2>
        )}
      </GridFlex.RowCenter>
    </InformationTooltip>
  );
}
