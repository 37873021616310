import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CustomerInput,
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  Customer,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { omit } from 'lodash';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';

const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CustomerInput!) {
    createOneCustomer(input: $input) {
      id
      customerId
      name
      updatedAt
    }
  }
`;

async function createCustomer(
  customerData: CustomerInput,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Customer> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const customer: CustomerInput = {
        ...omit(customerData, ['email']),
        email: customerData.email ? customerData.email : undefined,
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
        mutation: CREATE_CUSTOMER,
        variables: { input: customer },
      });

      const newCustomer = response.data?.createOneCustomer;
      if (newCustomer) {
        dispatch(navigateTo(appRoutes.customerPage(newCustomer.customerId)));
      }

      return newCustomer;
    },
    {
      successMessage: t('customers.api.successCreate'),
      failureMessageHandler: () => t('customers.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('customers.api.failDupCustId', {
          customerId: customerData.customerId,
        }),
        [ErrorCode.EntityIsArchivedError]: t('customers.api.failCustArchived'),
      },
    },
    dispatch,
  );
}

export { createCustomer };
