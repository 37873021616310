import styled from 'styled-components/macro';
import { Box } from '@stigg-components';

export const EmptyCardContent = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
`;

export const EmptyBorderedCardContent = styled(EmptyCardContent)`
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export const EmptyContent = styled(EmptyCardContent)<{ $padding: number }>`
  padding: ${({ $padding, theme }) => theme.spacing($padding || 4)};
`;
