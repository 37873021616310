import { gql, ObservableQuery } from '@apollo/client';
import {
  CustomerSubscriptionStatusByIdsQuery,
  CustomerSubscriptionStatusByIdsQueryVariables,
  PaymentCollection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';

const QUERY = gql`
  query CustomerSubscriptionStatusByIds($filter: CustomerSubscriptionFilter!) {
    customerSubscriptions(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export function watchSubscriptionsPaymentProcessingByIds(
  ids: string[],
  pollInterval: number,
): ObservableQuery<CustomerSubscriptionStatusByIdsQuery, CustomerSubscriptionStatusByIdsQueryVariables> {
  return apolloClient.watchQuery<CustomerSubscriptionStatusByIdsQuery, CustomerSubscriptionStatusByIdsQueryVariables>({
    query: QUERY,
    fetchPolicy: 'network-only',
    pollInterval,
    variables: {
      filter: {
        id: {
          in: ids,
        },
        paymentCollection: {
          eq: PaymentCollection.Processing,
        },
      },
    },
  });
}
