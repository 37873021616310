import { styled, TextColor } from '@stigg-theme';
import { Text, Grid, InformationTooltip, TooltipProps, GridFlex } from '@stigg-components';
import { HelpCenter } from '@mui/icons-material';
import { FC } from 'react';
import { ExcludeOurTypography } from './Typography';

export type HelpTooltipProps = {
  text: string;
  textColor?: TextColor;
  tooltipText: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
  tooltipMaxWidth?: number;
  leaveDelay?: number;
  textSize?: 'B2' | 'H6' | 'H3';
  textFontWeight?: ExcludeOurTypography['fontWeight'];
};

export const StyledHelpCenterIcon = styled(HelpCenter)`
  color: ${({ theme }) => theme.itamar.palette.text.disabled};
  width: 18px;
  height: 18px;
`;

function getTextComponent(textSize?: 'B2' | 'H6' | 'H3'): FC<ExcludeOurTypography> {
  switch (textSize) {
    case 'B2':
      return Text.B2;
    case 'H3':
      return Text.H3;
    case 'H6':
      return Text.H6;
    default:
      return Text.H6;
  }
}

export const TextWithHelpTooltip = ({
  text,
  textColor,
  tooltipText,
  leaveDelay,
  tooltipPlacement = 'bottom',
  tooltipMaxWidth,
  textSize,
  textFontWeight,
}: HelpTooltipProps) => {
  const TextComponent = getTextComponent(textSize);
  return (
    <GridFlex.RowCenter>
      <Grid item>
        <TextComponent fontWeight={textFontWeight} color={textColor || 'primary'}>
          {text}
        </TextComponent>
      </Grid>
      <Grid item pl={1}>
        <InformationTooltip
          arrow
          placement={tooltipPlacement}
          leaveDelay={leaveDelay}
          title={tooltipText}
          $maxWidth={tooltipMaxWidth}>
          <StyledHelpCenterIcon />
        </InformationTooltip>
      </Grid>
    </GridFlex.RowCenter>
  );
};
