import { gql } from '@apollo/client';

export const HOOK_DATA_FRAGMENT = gql`
  fragment HookData on Hook {
    id
    description
    endpoint
    status
    environmentId
    eventLogTypes
    secretKey
    configuration
  }
`;
