import { Text } from '@stigg-components';
import { TextColor } from '@stigg-theme';
import { SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { Navigation } from '../../../../../modules/navigation/useNavigation';
import { getPackageLink } from './TasksList.utils';
import * as S from './TasksList.style';

export function TaskTitle({
  start,
  task,
  navigation,
  end,
  textColor = 'primary',
}: {
  start?: string;
  task: SubscriptionMigrationTaskFragment;
  navigation: Navigation;
  end?: string;
  textColor?: TextColor;
}) {
  return (
    <S.TaskTitleBox>
      <Text.B2 color={textColor} mr={1}>
        {start}
      </Text.B2>
      {getPackageLink(task, navigation)}
      <Text.B2 color={textColor} ml={1}>
        {end}
      </Text.B2>
    </S.TaskTitleBox>
  );
}
