import { ReactNode } from 'react';
import { Button, Grid, GridFlex, Text, Icon } from '@stigg-components';

export function PageHeader({
  title,
  subtitle,
  buttonTitle,
  buttonClick,
  hideButton,
}: {
  title: string;
  subtitle?: ReactNode;
  buttonTitle?: string;
  buttonClick?: () => void;
  hideButton?: boolean;
}) {
  return (
    <GridFlex.RowSpaceBetween container spacing={12}>
      <GridFlex.Column item xs={6}>
        <Text.H1 gutterBottom display="inline" color="primary">
          {title}
        </Text.H1>
        {subtitle && <Text.B2 mt={-2}>{subtitle}</Text.B2>}
      </GridFlex.Column>
      {!hideButton && buttonClick && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            textColor="white"
            onClick={buttonClick}
            data-testid="button-create-new"
            startIcon={<Icon icon="Add" color="white" />}>
            {buttonTitle}
          </Button>
        </Grid>
      )}
    </GridFlex.RowSpaceBetween>
  );
}
