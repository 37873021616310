import { useState } from 'react';
import { CustomDrawer, useExitConfirmationDialog } from '@stigg-components';
import { t } from 'i18next';
import { EntitlementBehavior, Feature } from '@stigg-types/apiTypes';
import { generateRandomSlug } from '@stigg-common';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { SideNavCreateFeature } from '../../../../features/components/createFeature/SidenavCreateFeature';
import { AddEntitlementForm } from './AddEntitlementForm';
import {
  EntitlementFields,
  EntitlementFormFields,
} from '../../../../entitlements/components/entitlementSettings/types';

export type AddFeatureProps = {
  name: string;
  onCreated: (feature: Feature) => void;
};

export function AddEntitlementsDrawer({
  excludedFeaturesIds,
  basePlanEntitlementsByFeatureId,
  basePlan,
  onSubmit,
  open,
  setDialogOpen,
  withCustomEntitlementOption,
  withEntitlementBehaviourOption,
}: {
  open: boolean;
  setDialogOpen: any;
  onSubmit: (values: EntitlementFormFields) => Promise<void>;
  excludedFeaturesIds: string[];
  basePlanEntitlementsByFeatureId: Map<string, PlanEntitlement>;
  basePlan?: BasePlanProps | null;
  withCustomEntitlementOption?: boolean;
  withEntitlementBehaviourOption?: boolean;
}) {
  const { entitlementsSettingsImprovedUi } = useFlags<FeatureFlags>();
  const [createFeatureFlowProps, setCreateFeatureFlowProps] = useState<AddFeatureProps | null>(null);

  const initialValues: EntitlementFormFields = { entitlements: [] };

  const onClose = () => {
    setDialogOpen(false);
  };

  const { exitConfirmationDialog, onExit, setHasUnsavedChanges } = useExitConfirmationDialog({
    onClose,
    shouldConfirm: open,
  });

  const chooseFeature = (option: Feature | null, push?: any) => {
    const newFeature: EntitlementFields = {
      uuid: generateRandomSlug(),
      feature: option,
      isConfirmed: !entitlementsSettingsImprovedUi,
      hasUnlimitedUsage: false,
      behavior: EntitlementBehavior.Increment,
    };

    if (push) {
      push(newFeature);
    }
  };

  const onFeatureCreateClose = () => {
    setCreateFeatureFlowProps(null);
  };

  const onFeatureCreated = (_: boolean, feature: Feature) => {
    onFeatureCreateClose();
    createFeatureFlowProps?.onCreated(feature);
  };

  return (
    <>
      <CustomDrawer isOpen={open} onClose={onExit} title={t('entitlements.grant')}>
        <AddEntitlementForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onExit}
          setCreateFeatureFlowProps={setCreateFeatureFlowProps}
          excludedFeaturesIds={excludedFeaturesIds}
          basePlanEntitlementsByFeatureId={basePlanEntitlementsByFeatureId}
          basePlan={basePlan}
          withCustomEntitlementOption={withCustomEntitlementOption}
          withEntitlementBehaviourOption={withEntitlementBehaviourOption}
          chooseFeature={chooseFeature}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      </CustomDrawer>

      <CustomDrawer
        title={t('entitlements.createNewFeature')}
        noOverlay
        isOpen={!!createFeatureFlowProps}
        onClose={onFeatureCreateClose}>
        <SideNavCreateFeature
          featureName={createFeatureFlowProps?.name}
          preventNavigateAfterCreate
          hideCreateAnotherFeature
          onSubmit={onFeatureCreated}
          onClose={onFeatureCreateClose}
        />
      </CustomDrawer>

      {exitConfirmationDialog}
    </>
  );
}
