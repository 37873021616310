import { Box, Button, Field, FormRenderProps, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { Feature } from '@stigg-types/apiTypes';
import { EntitlementFormFields } from '../types';
import { EntitlementFeatureAutocomplete } from '../EntitlementFeatureAutocomplete';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { AddFeatureProps } from '../../../../packages/plans/components/addEntitlement/AddEntitlementsDrawer';

export type UseEntitlementFeatureSelectionFieldsProps = {
  formRenderProps: FormRenderProps<EntitlementFormFields>;
  index: number;
  onRemove?: () => void;
  excludedFeaturesIds: string[];
  setCreateFeatureFlowProps?: (props: AddFeatureProps) => void;
  basePlanEntitlementsByFeatureId?: Map<string, PlanEntitlement>;
  basePlan?: BasePlanProps | null;
  hasSingleEntitlement: boolean;
};

export function useEntitlementFeatureSelectionFields({
  formRenderProps,
  index,
  onRemove,
  excludedFeaturesIds,
  setCreateFeatureFlowProps,
  basePlanEntitlementsByFeatureId,
  basePlan,
  hasSingleEntitlement,
}: UseEntitlementFeatureSelectionFieldsProps) {
  const chooseFeature = (feature: Feature | null) => {
    if (feature) {
      const id = `entitlements[${index}].feature`;
      formRenderProps.setFieldValue(id, feature);
      formRenderProps.setFieldTouched(id);
    }
  };

  const fields: Field<EntitlementFormFields>[] = [
    {
      type: 'idle',
      component: (
        <EntitlementFeatureAutocomplete
          formRenderProps={formRenderProps}
          excludedFeaturesIds={excludedFeaturesIds}
          chooseFeature={chooseFeature}
          setCreateFeatureFlowProps={setCreateFeatureFlowProps!}
          basePlanEntitlementsByFeatureId={basePlanEntitlementsByFeatureId!}
          basePlan={basePlan}
        />
      ),
      gridProps: {
        mt: 2,
      },
    },
    {
      type: 'idle',
      hide: () => hasSingleEntitlement,
      component: (
        <Box flex={1}>
          <GridFlex.Row justifyContent="flex-end" gap={2} mt={6}>
            <Button $outlined color="primary" onClick={onRemove}>
              {t('sharedComponents.cancel')}
            </Button>
          </GridFlex.Row>
        </Box>
      ),
    },
  ];

  return fields;
}
