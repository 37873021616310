import { Text, Grid } from '@stigg-components';
import { t } from 'i18next';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../SubscriptionFutureUpdateRowContainer';

export function MigrationUpdateRow({
  subscription,
  alertVariant,
}: {
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
}) {
  const hasAddons = subscription.addons?.length;
  const textColor = alertVariant ? 'warning.content' : 'primary';
  return (
    <SubscriptionFutureUpdateRowContainer changeType={ChangeType.MOVE}>
      <Grid alignItems="baseline" gap={1}>
        <Text.B2 color={textColor}>
          {hasAddons
            ? t('subscriptions.schedules.migrationToLatestPlanAndAddons')
            : t('subscriptions.schedules.migrationToLatestPlan')}
        </Text.B2>
      </Grid>
    </SubscriptionFutureUpdateRowContainer>
  );
}
