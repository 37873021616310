import styled from 'styled-components/macro';
import { Box } from '@stigg-components';
import { css } from 'styled-components';

export const StyledBox = styled(Box)<{ $isHighlight?: boolean }>`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  align-items: center;
  padding: 16px;
  width: 100%;

  ${({ $isHighlight }) =>
    $isHighlight &&
    css`
      background: ${({ theme }) => theme.itamar.palette.background.lightBackground};
      border-color: ${({ theme }) => theme.itamar.palette.other.focusBorder};
    `}
`;

export const HorizontalLine = styled(Box)`
  border-top: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  margin-top: 5px;
  padding-top: 5px;
  width: 100%;
`;
