import { BillingModel, PricingType } from '@stigg-types/apiTypes';

const CustomerPortalTestUserPrefix = 'stiggTestData';
const PaywallTestUserPrefix = 'stiggTestData';
export const calculateCustomerPortalCustomerID = (
  pricingType: PricingType,
  billingModel: BillingModel,
  isTrial: boolean,
) => {
  const customerId = `${CustomerPortalTestUserPrefix}-${pricingType.toLowerCase()}-${billingModel
    .toLowerCase()
    .replace('_', '-')}`;
  return isTrial ? `${customerId}-trial` : customerId;
};
export const calculatePaywallProductID = (billingModel: BillingModel) => {
  return `${PaywallTestUserPrefix}-${billingModel.toLowerCase().replace('_', '-')}`;
};
