import styled from 'styled-components/macro';
import { Box, IconButton } from '../index';

const SEARCH_BOX_HEIGHT = 57;
const BUFFER_FROM_BOTTOM_OF_SCREEN = 20;
const CONSTANT_HEIGHT = BUFFER_FROM_BOTTOM_OF_SCREEN + SEARCH_BOX_HEIGHT;

const MIN_ITEMS_HEIGHT = 70;
export const MINIMAL_REQUIRED_HEIGHT = CONSTANT_HEIGHT + MIN_ITEMS_HEIGHT;

export const StyledList = styled.ul`
  padding: 0;
`;

export const VerticalBorderBox = styled(Box)<{ $isBorderTop: boolean }>`
  border-${({ $isBorderTop }) => ($isBorderTop ? 'top' : 'bottom')}: ${({ theme }) =>
  `1px solid ${theme.itamar.border.borderColor}`};
`;

export const ClearIconButton = styled(IconButton)`
  padding: 0;
  &.MuiButtonBase-root:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.itamar.palette.text.primary};
  }
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

export function calculateListBoxMaxHeight(availableSpace: number) {
  return Math.min(availableSpace - CONSTANT_HEIGHT, 400);
}
