import { t } from 'i18next';
import { GridFlex, Text, CloseableInformationTooltip } from '@stigg-components';
import { PriceFragment } from '@stigg-types/apiTypes';
import { getPriceAndPeriodFormat } from '../../../pricing/utils/priceFormatUtils';
import TieredPriceTable from './TieredPriceTable';
import { toTiersSchema } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.initialValues';
import { hasTierWithUnitPrice } from '../../../pricing/utils/priceTiersUtils';

function TieredPricePackageCell({ price, reversedPeriod }: { price: PriceFragment; reversedPeriod: boolean }) {
  const tiersAsUnit = hasTierWithUnitPrice(price.tiers);
  return (
    <GridFlex.Column rowGap="2px" my={1}>
      <Text.B2 color="secondary">{t('priceBreakdown.startsAt')}</Text.B2>
      <Text.B2>
        {getPriceAndPeriodFormat(price, {
          reversedPeriod,
          shortFormat: true,
          unitQuantity: 1,
          unitFormat: true,
          tiersAsUnit,
          totalPrice: true,
        })}
      </Text.B2>
      <CloseableInformationTooltip
        arrow
        placement="top"
        $maxWidth={680}
        PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 40] } }] }}
        header={
          <Text.B2>
            {price.feature?.displayName} - {t(`pricing.tiersSchema.${toTiersSchema(price.tiersMode, price.tiers)}`)}
          </Text.B2>
        }
        title={<TieredPriceTable price={price} reversedPeriod={reversedPeriod} />}>
        <>{t('pricing.tierPriceTableTooltip.viewTiers')}</>
      </CloseableInformationTooltip>
    </GridFlex.Column>
  );
}

export default TieredPricePackageCell;
