import { useEffect } from 'react';
import { VendorIdentifier } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { fetchAWSProductsAction } from '../../../integrationsSlice';

type UseAwsProductsProps = {
  dispatch: ReturnType<typeof useAppDispatch>;
};

export function useAwsProducts({ dispatch }: UseAwsProductsProps) {
  const currentIntegration = useSelector((state: RootState) => state.integrations.integration);
  const awsProducts = useSelector((state: RootState) => state.integrations.awsProducts);
  const isLoadingAWSProducts = useSelector((state: RootState) => state.integrations.isLoadingAWSProducts);
  const isAWSIntegration = currentIntegration?.vendorIdentifier === VendorIdentifier.AwsMarketplace;

  useEffect(() => {
    if (isAWSIntegration && !isLoadingAWSProducts) {
      void dispatch(fetchAWSProductsAction());
    }
    // Only fetch on mount and when integration changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIntegration?.id]);

  return { awsProducts, isLoadingAWSProducts };
}
