import { Button, GridFlex, Text, Icon } from '@stigg-components';
import { t } from 'i18next';
import { styled } from '@stigg-theme';

const BackButton = styled(Button)`
  background: none;
`;

export function StripeImportWizardFooter({
  activeStep,
  prevStep,
  isNextValid,
  getButtonText,
  onNextClick,
  isDisabled,
  shouldShowSkipButton,
  skipButtonText,
  onSkipClick,
  withBackButton,
}: {
  activeStep: number;
  prevStep: () => void;
  isNextValid?: boolean;
  getButtonText: () => string;
  onNextClick: () => void;
  isDisabled: boolean;
  shouldShowSkipButton?: boolean;
  skipButtonText?: string;
  onSkipClick?: () => void;
  withBackButton?: boolean;
}) {
  const isBackButtonVisible = activeStep > 0 && withBackButton;
  const color = isDisabled ? 'disabled' : 'primary.main';
  return (
    <GridFlex.RowCenter
      px={2}
      py={8}
      justifyContent={isBackButtonVisible ? 'space-between' : 'flex-end'}
      sx={{
        position: 'sticky',
        bottom: '0px',
        zIndex: 6,
        marginTop: 'auto',
        borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
      }}>
      {isBackButtonVisible && (
        <BackButton
          disabled={isDisabled}
          variant="text"
          onClick={prevStep}
          sx={{ height: '42px', background: 'none' }}
          startIcon={<Icon type="reactFeather" icon="ArrowLeft" size={20} color={color} />}>
          <Text.B2 color={color} mr={2}>
            {t('integrations.importBackButton')}
          </Text.B2>
        </BackButton>
      )}
      <GridFlex.RowCenter>
        {shouldShowSkipButton && onSkipClick && (
          <Button sx={{ marginRight: 4 }} onClick={onSkipClick} disabled={isDisabled}>
            <Text.B2 color="primary.main" mr={2}>
              <Text.B2 color="primary.main">{skipButtonText || t('integrations.importSkipButton')}</Text.B2>
            </Text.B2>
          </Button>
        )}
        <Button
          variant="contained"
          disabled={isDisabled || !isNextValid}
          color="primary"
          onClick={onNextClick}
          sx={{ height: '42px' }}
          endIcon={<Icon type="reactFeather" icon="ArrowRight" size={20} color="white" />}>
          {getButtonText()}
        </Button>
      </GridFlex.RowCenter>
    </GridFlex.RowCenter>
  );
}
