import { gql } from '@apollo/client';
import {
  CreateExperimentInput,
  CreateOneExperimentMutation,
  CreateOneExperimentMutationVariables,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';

const CREATE_EXPERIMENT = gql`
  mutation CreateOneExperimentMutation($input: CreateExperimentInput!) {
    createOneExperiment(input: $input) {
      refId
    }
  }
`;

async function createExperiment(
  experimentData: Omit<CreateExperimentInput, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<void> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const experiment: CreateExperimentInput = {
        ...experimentData,
        environmentId: accountReducer.currentEnvironmentId,
      };

      const response = await apolloClient.mutate<CreateOneExperimentMutation, CreateOneExperimentMutationVariables>({
        mutation: CREATE_EXPERIMENT,
        variables: { input: experiment },
      });

      const newExperiment = response.data?.createOneExperiment;
      if (newExperiment) {
        dispatch(navigateTo(appRoutes.experimentPage(newExperiment.refId)));
      }
    },
    {
      successMessage: t('experiments.api.successCreate'),
      failureMessageHandler: () => t('experiments.api.failCreate'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('experiments.api.dup', {
          experimentName: experimentData.name,
        }),
      },
    },
    dispatch,
  );
}

export { createExperiment };
