import { GridFlex, Icon, InformationTooltip, Text } from '@stigg-components';
import { PlanCompatibleAddonFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { LinkWithIcon } from '../../../../../../components/ExternalLink';
import { Navigation } from '../../../../../navigation/useNavigation';

type AddonMissingCurrencyAlertWithTooltipProps = {
  navigation: Navigation;
  planAddon: PlanCompatibleAddonFragment;
};

export function AddonMissingCurrencyAlertWithTooltip({
  navigation,
  planAddon,
}: AddonMissingCurrencyAlertWithTooltipProps) {
  return (
    <InformationTooltip
      arrow
      placement="top"
      title={
        <>
          <Text.B2 mb={2}>{t('pricing.currency.planPageAddonMissingCurrencyAlertText')}</Text.B2>
          <Text.B2>
            <LinkWithIcon onClick={() => navigation.navigateTo(navigation.appRoutes.addonPage(planAddon.refId))}>
              {t('pricing.currency.addMissingLocalizedPrice')}
            </LinkWithIcon>
          </Text.B2>
        </>
      }
      $maxWidth={320}>
      <GridFlex.RowCenter>
        <Icon icon="AlertTriangle" color="warning" size={20} />
      </GridFlex.RowCenter>
    </InformationTooltip>
  );
}
