import { AsyncThunk } from '@reduxjs/toolkit';
import { PageInfo } from '@stigg-types/apiTypes';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../modules/common/pagination';

const createPageChangeFunc = (
  dispatch: any,
  pageNum: number,
  pageInfo: PageInfo,
  setPageNum: (pageNum: number) => void,
  fetchAction: AsyncThunk<any, any, any>,
  dispatchArgs: any = {},
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
) => {
  return (event: any, nextPage: number) => {
    // page can change up or down and both triggers this func.
    // nextPage > pageNum => paging up than we will give it an "after" prop otherwise a before gl prop
    const pagingParams: Paging =
      nextPage > pageNum
        ? {
            after: pageInfo.endCursor,
            first: pageSize,
          }
        : { before: pageInfo.startCursor, last: pageSize };
    dispatch(fetchAction({ paging: { ...pagingParams }, ...dispatchArgs }));
    setPageNum(nextPage);
  };
};

export type Paging = {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
};
export default createPageChangeFunc;
