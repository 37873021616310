import { useMemo } from 'react';
import { ResultSet } from '@cubejs-client/core';
import { useCurrentAndPreviousPeriods } from '../base';

export type CubeSubscriptionData = { isLoading: boolean; error: Error | null; resultSet: ResultSet | null };

export const useCubeSubscriptionStaticData = ({ mock }: { mock: Record<string, unknown> }) => {
  const { label } = useCurrentAndPreviousPeriods();

  return useMemo(() => {
    const serialized = { loadResponse: mock[label] };
    const deserialized = ResultSet.deserialize(serialized);
    return { isLoading: false, error: null, resultSet: deserialized };
  }, [label, mock]);
};
