import { gql } from '@apollo/client';
import { t } from 'i18next';
import { DeleteFeatureMutation, DeleteFeatureMutationVariables, ErrorCode } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';

const DELETE_FEATURE = gql`
  mutation DeleteFeature($input: DeleteFeatureInput!) {
    deleteFeature(input: $input) {
      id
    }
  }
`;

async function deleteFeatureById(
  featureIdRefId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<{ id: string } | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<DeleteFeatureMutation, DeleteFeatureMutationVariables>({
        mutation: DELETE_FEATURE,
        variables: { input: { id: featureIdRefId, environmentId: accountReducer.currentEnvironmentId } },
      });
      dispatch(navigateTo('/features'));
      return { id: featureIdRefId };
    },
    {
      successMessage: t('features.api.successDelete'),
      failureMessageHandler: () => t('features.api.failDelete'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('features.api.failDeleteEntGranted'),
        [ErrorCode.CannotDeleteFeatureError]: t('features.api.canNotDeleteFeature'),
      },
    },
    dispatch,
  );
}

export { deleteFeatureById };
