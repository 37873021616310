import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { UserFragment } from '@stigg-types/apiTypes';
import { useKoala } from '@getkoala/react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';
import { StiggProvider } from '@stigg/react-sdk';
import { useRouteMatch } from 'react-router-dom';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useHideIntercom } from '@stigg-components';
import head from 'lodash/head';
import Loader from './components/Loader';
import { fetchUserAction } from './modules/auth/authSlice';
import { setAccessTokenProvider } from './ApolloClient';
import { RootState, useAppDispatch } from './redux/store';
import { fetchEnvironmentsAction, selectAccount, selectEnvironmentBySlug } from './modules/accounts/accountsSlice';
import { initFullstory } from './vendor/fullstory';
import { fetchActiveExperimentsAction } from './modules/experiemnts/experimentsSlice';
import { useAnalytics } from './modules/common/useAnalytics';
import { useCommandBar } from './modules/commandbar/useCommandBar';
import config from './env.config';
import { useCurrentMember } from './modules/auth/hooks/useCurrentMember';

function useIdentifyUser() {
  const ldClient = useLDClient();
  const analytics = useAnalytics();
  const { koala, ready: koalaReady } = useKoala();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const member = useCurrentMember();

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    ldClient?.identify({
      key: user.id,
      email: user.email || '',
      name: user.name || '',
      custom: {
        account: member?.account.displayName || '',
        accountId: member?.account.id || '',
        environmentId: currentEnvironmentId || '',
      },
    });
    analytics.alias(user.id);
    analytics.identify(user.id);
  }, [ldClient, analytics, currentEnvironmentId, user, member]);

  useEffect(() => {
    if (koala && user && koalaReady) {
      koala.identify(user.email, { name: user.name });
    }
  }, [koala, koalaReady, user]);

  return {
    user,
    member,
  };
}

const Bootstrapper: React.FC = ({ children }) => {
  const [isFinished, setIsFinished] = useState(false);
  const { isAuthenticated, isLoading: isLoadingAuth0, getAccessTokenSilently } = useAuth0();

  const theme = useTheme();
  const isUserLoading = useSelector((state: RootState) => state.authReducer.isLoading);
  const isLoadingActiveExperiments = useSelector((state: RootState) => state.experimentsReducer.global.isLoading);
  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);
  const currentAccountId = useSelector((state: RootState) => state.accountReducer.currentAccountId);
  const dispatch = useAppDispatch();
  const { stiggForStiggWeb } = useFlags<FeatureFlags>();

  // using ref here so the useEffect below doesn't have to be re-run on every change to URL
  const routeMatch = useRouteMatch<{ environmentSlug: string }>('/:environmentSlug');
  const environmentSlugRef = useRef<string>();
  environmentSlugRef.current = routeMatch?.params.environmentSlug;

  useEffect(() => {
    setAccessTokenProvider(getAccessTokenSilently);

    const loadUser = async () => {
      if (isLoadingAuth0) {
        return;
      }

      if (!isAuthenticated) {
        setIsFinished(true);
        return;
      }

      if (isFinished) {
        return;
      }

      try {
        const user: UserFragment = await dispatch(fetchUserAction()).unwrap();
        if (user) {
          const userData = { email: user.email || '', username: user.name || '', userId: user.id };
          Sentry.setUser(userData);
          Sentry.setTags(userData);
          initFullstory(user);

          const currentMember =
            user.memberships.find((m) => m.account.id === currentAccountId) || head(user.memberships);
          if (currentMember) {
            dispatch(selectAccount(currentMember.account.id));
          }

          const hasMembership = user.memberships.length > 0; // i think this validation is not needed

          if (hasMembership) {
            await dispatch(fetchEnvironmentsAction({ environmentSlug: environmentSlugRef.current }));
            if (environmentSlugRef.current) {
              dispatch(selectEnvironmentBySlug(environmentSlugRef.current));
            }
            await dispatch(fetchActiveExperimentsAction({}));
          }
        }
      } catch (err: any) {
        console.error(err);
        // handle error silently and prevent unhandled promise rejection
      }

      setIsFinished(true);
    };

    void loadUser();
  }, [dispatch, getAccessTokenSilently, isLoadingAuth0, isAuthenticated, isFinished, currentAccountId]);

  const { member } = useIdentifyUser();
  useCommandBar();

  const showLoader =
    isLoadingAuth0 || isUserLoading || !isFinished || isLoadingEnvironments || isLoadingActiveExperiments;
  useHideIntercom(showLoader); // we want to avoid showing intercom on loading screens
  if (showLoader) {
    return <Loader useAnimationLoader />;
  }

  if (stiggForStiggWeb && config.stiggDoggoApiKey) {
    return (
      <StiggProvider
        apiKey={config.stiggDoggoApiKey}
        enableEdge={config.stiggDoggoEnableEdge}
        baseUri={config.stiggDoggoBaseUrl}
        customerId={member?.account.id}
        customerToken={member?.customerToken || undefined}
        {...(theme.isLightTheme
          ? {}
          : {
              theme: {
                border: { radius: theme.itamar.border.radius },
                palette: {
                  backgroundPaper: theme.itamar.palette.background.default,
                  backgroundSection: theme.itamar.palette.background.paper,
                  backgroundHighlight: theme.itamar.palette.success.background,
                  primary: theme.itamar.palette.primary.main,
                  primaryLight: theme.itamar.palette.primary.light,
                  primaryDark: theme.itamar.palette.primary.dark,
                  error: theme.itamar.palette.error.main,
                  errorDark: theme.itamar.palette.error.dark,
                  success: theme.itamar.palette.success.main,
                  successDark: theme.itamar.palette.success.dark,
                  warning: theme.itamar.palette.warning.main,
                  switchBorder: theme.itamar.palette.other.outlineBorder,
                  outlinedBorder: theme.itamar.palette.other.outlineBorder,
                  outlinedHoverBackground: theme.itamar.palette.primary.outlinedHoverBackground,
                  white: theme.itamar.palette.background.darkBackground,
                  backgroundButton: theme.itamar.palette.background.paper,
                  outlinedRestingBorder: theme.itamar.palette.other.outlineBorder,
                  text: {
                    primary: theme.itamar.palette.text.primary,
                    secondary: theme.itamar.palette.text.secondary,
                    disabled: theme.itamar.palette.text.disabled,
                    tertiary: theme.itamar.palette.text.tertiary,
                  },
                },
              },
            })}>
        {children}
      </StiggProvider>
    );
  }

  return children as ReactElement;
};

export default Bootstrapper;
