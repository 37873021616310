import { t } from 'i18next';
import { Feature, FeatureType, MeterType } from '@stigg-types/apiTypes';

export const formatFeatureType = (feature: Pick<Feature, 'featureType' | 'meterType'>) => {
  const { featureType, meterType } = feature;
  switch (featureType) {
    case FeatureType.Boolean:
      return t('features.featureType.boolean');
    case FeatureType.Number:
      return !meterType || meterType === MeterType.None
        ? t('features.featureType.number')
        : t('features.featureType.metered');
    default:
      return t('features.featureType.metered');
  }
};
