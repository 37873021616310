import { FontWeight } from '@stigg-types/apiTypes';

export type FontVariant = {
  fontSize: number;
  fontWeight: FontWeight;
};

export type TypographyConfiguration = {
  fontFamily?: string;
  h1: FontVariant;
  h2: FontVariant;
  h3: FontVariant;
  body: FontVariant;
};

export const defaultTypography: TypographyConfiguration = {
  h1: {
    fontSize: 32,
    fontWeight: FontWeight.Bold,
  },
  h2: {
    fontSize: 24,
    fontWeight: FontWeight.Normal,
  },
  h3: {
    fontSize: 16,
    fontWeight: FontWeight.Normal,
  },
  body: {
    fontSize: 14,
    fontWeight: FontWeight.Normal,
  },
};

export const checkoutDefaultTypography: TypographyConfiguration = {
  h1: {
    fontSize: 24,
    fontWeight: FontWeight.Bold,
  },
  h2: {
    fontSize: 16,
    fontWeight: FontWeight.Normal,
  },
  h3: {
    fontSize: 16,
    fontWeight: FontWeight.Normal,
  },
  body: {
    fontSize: 14,
    fontWeight: FontWeight.Normal,
  },
};
