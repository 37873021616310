import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Member, User } from '@stigg-types/apiTypes';
import { useIntercom } from 'react-use-intercom';
import { useAuth0 } from '@auth0/auth0-react';
import { useStiggIntercomContext } from './StiggIntercomContext';
import { RootState } from '../../redux/store';
import { useCurrentMember } from '../../modules/auth/hooks/useCurrentMember';

const boot = (user: User, member?: Member, intercom?: any) => {
  // if the user is not yet a member in any account hes probably not yet verified.
  // using auto boot in IntercomProvider and shutting down to add metadata.
  // Without auto boot for some reason pressing on the intercom button cause our site to refresh
  intercom?.shutdown();
  intercom?.boot({
    email: user.email || '',
    userId: user.id || '',
    name: user.name || '',
    userHash: user?.intercomToken,
    ...(member
      ? {
          customAttributes: {
            accountId: member.account.id || '',
            accountName: member.account.displayName,
          },
        }
      : {}),
  });
};

export function IntercomButton() {
  const intercom = useIntercom();
  const { isVisible } = useStiggIntercomContext();
  const { user: auth0User } = useAuth0();
  const user: User | null = useSelector((state: RootState) => state.authReducer.user);
  const member = useCurrentMember();

  useEffect(() => {
    intercom?.shutdown();

    if (user && auth0User?.email_verified && isVisible) {
      boot(user, member, intercom);
    }
  }, [user, member, intercom, auth0User, isVisible]);

  return null;
}
