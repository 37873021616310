import { Box, Icon } from '@stigg-components';

export function SyncPriceIcon({ placement }: { placement: 'left' | 'right' }) {
  const position = placement === 'left' ? { left: -8 } : { right: -8 };
  return (
    <Box
      className="transfer-icon"
      sx={{
        position: 'absolute',
        top: 18,
        zIndex: 10,
        backgroundColor: 'white',
        width: 16,
        height: 16,
        ...position,
      }}>
      <Icon type="custom" icon="Transfer" color="disabled" />
    </Box>
  );
}
