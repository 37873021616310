import { gql } from '@apollo/client';
import { t } from 'i18next';
import { HideGettingStartedPageMutation, HideGettingStartedPageMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const HIDE_GETTING_STARTED_PAGE = gql`
  mutation HideGettingStartedPage($memberId: String!) {
    hideGettingStartedPage(memberId: $memberId)
  }
`;

async function hideGettingStartedPage(memberId: string, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<HideGettingStartedPageMutation, HideGettingStartedPageMutationVariables>({
        mutation: HIDE_GETTING_STARTED_PAGE,
        variables: { memberId },
      });

      return { id: memberId };
    },
    {
      failureMessageHandler: () => t('sharedComponents.somethingWentWrong'),
    },
    dispatch,
  );
}

export { hideGettingStartedPage };
