import { Feature, PackageEntitlement, Plan } from '@stigg-types/apiTypes';

export function getEntitlement(plan: Plan, feature: Feature): PackageEntitlement | undefined {
  return (
    plan.entitlements?.find((entitlement) => entitlement.featureId === feature.id) ||
    plan.inheritedEntitlements?.find((entitlement) => entitlement.featureId === feature.id)
  );
}

export function hasEntitlement(plan: Plan, feature: Feature): boolean {
  return Boolean(getEntitlement(plan, feature));
}
