import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { ConfirmationDialogProps } from '@stigg-components/types';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';

type UseConfirmationResult = [typeof ConfirmationDialog, (isOpen: boolean) => void, ConfirmationDialogProps];

export function useConfirmationDialog({
  title,
  content,
  handleConfirm,
  handleCancel,
  width,
  confirmButtonText = t('sharedComponents.remove'),
  cancelButtonText = t('sharedComponents.cancel'),
  confirmButtonColor = 'error',
  disabled,
}: {
  title: string;
  content: string | React.ReactNode;
  handleConfirm: () => void | Promise<void>;
  handleCancel?: () => void | Promise<void>;
  width?: number;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: 'primary' | 'error';
  isButtonLoading?: boolean;
  disabled?: boolean;
}): UseConfirmationResult {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleDialogClose = useCallback(
    async (isConfirmed: boolean) => {
      if (isConfirmed) {
        setIsButtonLoading(true);
        await handleConfirm();
      } else if (handleCancel) {
        await handleCancel();
      }
      setDialogOpen(false);
    },
    [handleConfirm, handleCancel],
  );

  useEffect(() => {
    if (dialogOpen) {
      setIsButtonLoading(false);
    }
  }, [dialogOpen]);

  const props = {
    open: dialogOpen,
    handleClose: handleDialogClose,
    confirmationButtonText: confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    title,
    content,
    width,
    isButtonLoading,
    disabled,
  };

  return [ConfirmationDialog, setDialogOpen, props];
}
