import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddonFragment,
  AddonGroupFragment,
  AddonsFragment,
  FetchAddonSyncStatesQuery,
  PackageGroupsFragment,
} from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import { updateItemById } from '@stigg-common';
import {
  archiveAddonGroup,
  createAddon,
  createAddonDraft,
  createAddonGroup,
  discardAddonDraft,
  editAddonGroup,
  publishAddon,
  setAddonsToAddonGroup,
  updateAddon,
} from './mutations';
import { fetchAddonByRefId, fetchAddons, fetchAddonGroups, fetchAddonGroupByRefId } from './queries';

import { createAppAsyncThunk } from '../../../redux/createAppAsyncThunk';
import { PackageListFragment } from '../common/components/Packages';

type ArchiveDialogProps = {
  isOpen: boolean;
  addon?: PackageListFragment;
};

export interface AddonsState {
  isLoading: boolean;
  isLoadingAddon: boolean;
  isLoadingAddonGroup: boolean;
  isLoadingPackageGroups: boolean;
  isSilentLoadingAddon: boolean;
  addons: AddonsFragment;
  packageGroups: PackageGroupsFragment;
  addon?: AddonFragment;
  addonGroup?: AddonGroupFragment | null;
  archiveDialog: ArchiveDialogProps;
}

const initialState: AddonsState = {
  isLoading: false,
  isLoadingAddon: false,
  isLoadingAddonGroup: false,
  isLoadingPackageGroups: false,
  isSilentLoadingAddon: false,
  addons: {} as AddonsFragment,
  packageGroups: {} as PackageGroupsFragment,
  archiveDialog: {
    isOpen: false,
    addon: undefined,
  },
};

const fetchAddonGroupsAction = createAppAsyncThunk('fetchAddonGroups', fetchAddonGroups);
const fetchAddonsAction = createAppAsyncThunk('fetchAddons', fetchAddons);
const createAddonAction = createAppAsyncThunk('createAddon', createAddon);
const setAddonsToAddonGroupAction = createAppAsyncThunk('setAddonsToAddonGroup', setAddonsToAddonGroup);
const fetchAddonByRefIdAction = createAppAsyncThunk('fetchAddonByRefId', fetchAddonByRefId);
const fetchAddonGroupByRefIdAction = createAppAsyncThunk('fetchAddonGroupByRefId', fetchAddonGroupByRefId);
const updateAddonAction = createAppAsyncThunk('updateAddon', updateAddon);
const editAddonGroupAction = createAppAsyncThunk('editAddonGroup', editAddonGroup);
const createAddonDraftAction = createAppAsyncThunk('createAddonDraft', createAddonDraft);
const createAddonGroupAction = createAppAsyncThunk('createAddonGroupAction', createAddonGroup);
const discardAddonDraftAction = createAppAsyncThunk('discardAddonDraft', discardAddonDraft);
const publishAddonAction = createAppAsyncThunk('publishAddon', publishAddon);
const archiveAddonGroupAction = createAppAsyncThunk('archiveAddonGroup', archiveAddonGroup);

type AddonSyncState = FetchAddonSyncStatesQuery['addons']['edges'][number]['node'];

export const addonsSlice = createSlice({
  name: 'addons',
  initialState,
  reducers: {
    setArchiveDialog: (state, action: PayloadAction<ArchiveDialogProps>) => {
      state.archiveDialog = action.payload;
    },
    updateAddonSyncState: (state, action: { payload: AddonSyncState }) => {
      state.addon = merge(state.addon, action.payload);
    },
    resetAddonGroup: (state) => {
      state.addonGroup = null;
    },
    removeAddonByRefId: (state, action: PayloadAction<{ refId: string }>) => {
      remove(state.addons.edges, (addon) => addon.node.refId === action.payload.refId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddonsAction.pending, (state, { meta }) => {
        if (!isEmpty(meta.arg.search)) {
          state.addons = {} as AddonsFragment;
        }
        if (isEmpty(state.addons.edges)) {
          state.isLoading = true;
        }
      })
      .addCase(fetchAddonsAction.fulfilled, (state, action) => {
        state.addons = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAddonsAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchAddonGroupsAction.pending, (state, { meta }) => {
        if (!isEmpty(meta.arg.search)) {
          state.packageGroups = {} as PackageGroupsFragment;
        }
        if (isEmpty(state.packageGroups.edges)) {
          state.isLoadingPackageGroups = true;
        }
      })
      .addCase(fetchAddonGroupsAction.fulfilled, (state, action) => {
        state.packageGroups = action.payload;
        state.isLoadingPackageGroups = false;
      })
      .addCase(fetchAddonGroupsAction.rejected, (state) => {
        state.isLoadingPackageGroups = false;
      });

    builder
      .addCase(fetchAddonByRefIdAction.pending, (state, action) => {
        state.isLoadingAddon = !action.meta.arg.silentFetch;
        state.isSilentLoadingAddon = !!action.meta.arg.silentFetch;
      })
      .addCase(fetchAddonByRefIdAction.fulfilled, (state, { payload }) => {
        state.addon = payload || ({} as AddonFragment);
        state.isLoadingAddon = false;
        state.isSilentLoadingAddon = false;
      })
      .addCase(fetchAddonByRefIdAction.rejected, (state) => {
        state.isLoadingAddon = false;
        state.isSilentLoadingAddon = false;
      });

    builder
      .addCase(fetchAddonGroupByRefIdAction.pending, (state, action) => {
        state.isLoadingAddonGroup = !action.meta.arg.silentFetch;
        state.isSilentLoadingAddon = !!action.meta.arg.silentFetch;
      })
      .addCase(fetchAddonGroupByRefIdAction.fulfilled, (state, { payload }) => {
        state.addonGroup = payload || ({} as AddonGroupFragment);
        state.isLoadingAddonGroup = false;
        state.isSilentLoadingAddon = false;
      })
      .addCase(fetchAddonGroupByRefIdAction.rejected, (state) => {
        state.isLoadingAddonGroup = false;
        state.isSilentLoadingAddon = false;
      });

    builder.addCase(discardAddonDraftAction.fulfilled, (state, { payload }) => {
      remove(state.addons.edges, (addon) => addon.node.refId === payload.refId);
    });

    builder.addCase(updateAddonAction.fulfilled, (state, { payload }) => {
      state.addon = merge(state.addon, payload);
      updateItemById(state.addons, payload);
    });

    builder.addCase(archiveAddonGroupAction.fulfilled, (state, { payload }) => {
      remove(state.packageGroups.edges, (group) => group.node.packageGroupId === payload?.id);
    });
  },
});

const { resetAddonGroup, setArchiveDialog, removeAddonByRefId, updateAddonSyncState } = addonsSlice.actions;

export {
  fetchAddonsAction,
  fetchAddonGroupsAction,
  fetchAddonByRefIdAction,
  createAddonAction,
  updateAddonAction,
  createAddonDraftAction,
  discardAddonDraftAction,
  publishAddonAction,
  setArchiveDialog,
  removeAddonByRefId,
  updateAddonSyncState,
  createAddonGroupAction,
  fetchAddonGroupByRefIdAction,
  setAddonsToAddonGroupAction,
  resetAddonGroup,
  editAddonGroupAction,
  archiveAddonGroupAction,
};

export default addonsSlice.reducer;
