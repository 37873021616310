import { gql } from '@apollo/client';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import {
  AddonFilter,
  AddonsFragment,
  AddonSortFields,
  FetchAddonsQuery,
  FetchAddonsQueryVariables,
  PackageStatus,
  SortDirection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { Paging } from '../../../../components/table/gqlTableHelper';
import { AppDispatch } from '../../../../redux/store';
import { applyEnvironmentFilter } from '../../../common/applyEnvironmentFilter';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { ADDON_LIST_FRAGMENT } from './addonListFragment';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../common/pagination';

export const FETCH_SLIM_ADDONS = gql`
  query FetchSlimAddons($paging: CursorPaging, $filter: AddonFilter!) {
    addons(paging: $paging, filter: $filter) {
      edges {
        node {
          refId
          displayName
        }
      }
    }
  }
`;

const FETCH_ADDONS = gql`
  ${ADDON_LIST_FRAGMENT}
  query FetchAddons($paging: CursorPaging, $sorting: [AddonSort!], $filter: AddonFilter!) {
    addons(paging: $paging, sorting: $sorting, filter: $filter) {
      ...AddonsFragment
    }
  }
  fragment AddonsFragment on AddonConnection {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...AddonListFragment
      }
      cursor
    }
  }
`;

export type FetchAddonsProps = {
  paging?: Paging;
  search?: string | null;
  environmentId: string;
  addonIds?: string[];
  productId?: string;
};

async function fetchAddons(props: FetchAddonsProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search, paging } = props;
  if (search === '') {
    search = null;
  }
  if (!paging) {
    paging = { first: DEFAULT_TABLE_PAGE_SIZE };
  }
  // If addonIds is defined and it's an empty array, return an empty result
  if (props.addonIds && isEmpty(props.addonIds)) {
    return {} as AddonsFragment;
  }
  return executeOperationSafely(
    async () => {
      const filter: AddonFilter = {
        environmentId: { eq: props.environmentId },
        isLatest: { is: true },
      };
      if (props.addonIds) {
        filter.refId = { in: props.addonIds };
      }
      if (props.productId) {
        filter.productId = { eq: props.productId };
      }

      if (props.search) {
        filter.or = [
          { displayName: { iLike: `%${props.search}%` } },
          { refId: { iLike: `%${props.search}%` } },
          { description: { iLike: `%${props.search}%` } },
          { billingId: { iLike: `%${props.search}%` } },
        ].map((searchFilter) => applyEnvironmentFilter<AddonFilter>(searchFilter, props.environmentId));
      }

      // if the user specifies a list of addons, we should return archived addons as well
      if (!props.addonIds) {
        filter.status = { notIn: [PackageStatus.Archived] };
      }

      const response = await apolloClient.query<FetchAddonsQuery, FetchAddonsQueryVariables>({
        query: FETCH_ADDONS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: [
            { field: AddonSortFields.Status, direction: SortDirection.Desc },
            { field: AddonSortFields.DisplayName, direction: SortDirection.Asc },
          ],
          filter,
        },
      });

      return response.data.addons;
    },
    {
      failureMessageHandler: () => t('addons.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchAddons };
