import React from 'react';
import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { CustomerIcon } from '../CustomerIcon';

type CustomerHeaderProps = {
  customer: CustomerResponseFragment;
};

export default function CustomerHeader({ customer }: CustomerHeaderProps) {
  return (
    <GridFlex.RowCenter item>
      <CustomerIcon size={48} email={customer?.email} name={customer?.name} />
      <GridFlex.Column item ml={4}>
        <Text.Sub2 mb={1}>{t('customers.header')}</Text.Sub2>
        <GridFlex.RowCenter>
          <Text.H3 mr={4}>{customer.customerId}</Text.H3>
        </GridFlex.RowCenter>
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
