import { t } from 'i18next';
import { GridFlex, Icon } from '@stigg-components';
import { EventLogDetailsProps } from './EventLogDetails';
import DetailButtons from './DetailButtons';

type EventLogDetailsFooterProps = Pick<EventLogDetailsProps, 'onClose' | 'onPrevious' | 'onNext'>;

export default function EventLogDetailsFooter({ onClose, onPrevious, onNext }: EventLogDetailsFooterProps) {
  return (
    <GridFlex.RowSpaceBetween>
      <GridFlex.Row gap={2}>
        <DetailButtons
          onClick={onPrevious}
          component={<Icon icon="ChevronLeft" color={onPrevious ? 'primary.main' : 'disabled'} />}
        />
        <DetailButtons
          onClick={onNext}
          component={<Icon icon="ChevronRight" color={onNext ? 'primary.main' : 'disabled'} />}
        />
      </GridFlex.Row>
      <DetailButtons onClick={onClose} component={t('eventsLog.detailsDrawer.close')} style={{ paddingX: 4 }} />
    </GridFlex.RowSpaceBetween>
  );
}
