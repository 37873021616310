import * as Yup from 'yup';
import { SubscriptionEndSetup, SubscriptionStartSetup } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { refIdValidation } from '@stigg-common';

export const customerJourneyValidationJson = {
  subscriptionEndSetup: Yup.mixed<SubscriptionEndSetup>()
    .oneOf(Object.values(SubscriptionEndSetup))
    .required(t('products.yup.required')),
  downgradePlanId: Yup.string().when('subscriptionEndSetup', {
    is: SubscriptionEndSetup.DowngradeToFree,
    then: Yup.string().required(t('products.yup.required')),
    otherwise: Yup.string().notRequired(),
  }),
  subscriptionStartTrialPlanId: Yup.string().when('subscriptionStartSetup', {
    is: SubscriptionStartSetup.TrialPeriod,
    then: Yup.string().required(t('products.yup.required')),
    otherwise: Yup.string().notRequired(),
  }),
  subscriptionStartFreePlanId: Yup.string().when('subscriptionStartSetup', {
    is: SubscriptionStartSetup.FreePlan,
    then: Yup.string().required(t('products.yup.required')),
    otherwise: Yup.string().notRequired(),
  }),
  autoCancellationRules: Yup.array()
    .notRequired()
    .of(
      Yup.object().shape({
        sourcePlanId: refIdValidation(),
        targetPlanId: refIdValidation(),
      }),
    ),
};
