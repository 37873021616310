import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SubscriptionStatus } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import {
  fetchActiveSubscriptionsAction,
  fetchCustomerByRefIdAction,
  fetchEntitlementsByRefIdAction,
  fetchSubscriptionByRefIdAction,
  resetEntitlements,
} from '../../../customersSlice';

export function useSubscriptionPage() {
  const dispatch = useAppDispatch();
  const { refId } = useParams<{ refId: string }>();

  const subscription = useSelector((root: RootState) => root.customersReducer.subscription);
  const activeSubscriptions = useSelector((root: RootState) => root.customersReducer.activeSubscriptions);
  const isLoadingSubscription = useSelector((root: RootState) => root.customersReducer.isLoading);
  const entitlements = useSelector((root: RootState) => root.customersReducer.entitlements);
  const entitlementsLastUpdated = useSelector((root: RootState) => root.customersReducer.entitlementsLastUpdated);
  const isLoadingEntitlements = useSelector((root: RootState) => root.customersReducer.isLoadingEntitlements);
  const customer = useSelector((root: RootState) => root.customersReducer.customer);

  const isSubscriptionActive = [SubscriptionStatus.Active, SubscriptionStatus.InTrial].includes(subscription.status);

  useEffect(() => {
    void dispatch(fetchSubscriptionByRefIdAction({ refId }));
  }, [dispatch, refId]);

  useEffect(() => {
    if (!customer?.customerId && subscription.customer?.customerId) {
      void dispatch(
        fetchCustomerByRefIdAction({ customerId: subscription.customer?.customerId, isSilentLoading: true }),
      );
    }
  }, [dispatch, customer?.customerId, subscription.customer?.customerId]);

  useEffect(() => {
    if (subscription && customer?.customerId) {
      void dispatch(
        fetchActiveSubscriptionsAction({
          customerId: customer.customerId,
          resourceId: subscription.resource?.resourceId,
        }),
      );
    }
  }, [subscription, customer?.customerId, refId, dispatch]);

  const dispatchFetchEntitlements = useCallback(() => {
    if (customer?.customerId && subscription && isSubscriptionActive) {
      const resourceRefId = subscription.resource?.resourceId;
      void dispatch(fetchEntitlementsByRefIdAction({ customerRefId: customer.customerId, resourceRefId }));
    }
  }, [customer?.customerId, dispatch, isSubscriptionActive, subscription]);

  useEffect(() => {
    dispatchFetchEntitlements();

    return () => {
      dispatch(resetEntitlements());
    };
  }, [dispatch, dispatchFetchEntitlements]);

  return {
    refId,
    subscription,
    activeSubscriptions,
    isLoadingSubscription,
    entitlements,
    entitlementsLastUpdated,
    isLoadingEntitlements,
    customer,
    dispatchFetchEntitlements,
  };
}
