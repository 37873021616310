import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  ProductDeleteResponse,
  DeleteOneProductMutation,
  DeleteOneProductMutationVariables,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';

const DELETE_CUSTOMER = gql`
  mutation DeleteOneProduct($input: DeleteOneProductInput!) {
    deleteOneProduct(input: $input) {
      id
    }
  }
`;

async function deleteProductById(
  productId: string,
  { dispatch }: { dispatch: AppDispatch },
): Promise<ProductDeleteResponse | undefined> {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<DeleteOneProductMutation, DeleteOneProductMutationVariables>({
        mutation: DELETE_CUSTOMER,
        variables: { input: { id: productId } },
      });
      dispatch(navigateTo('/products'));
      return { id: productId };
    },
    {
      successMessage: t('products.api.successDelete'),
      failureMessageHandler: () => t('products.api.failDelete'),
      expectedErrorsMessage: {
        [ErrorCode.CannotDeleteProductError]: t('products.api.cannotDeleteProduct'),
      },
    },
    dispatch,
  );
}

export { deleteProductById };
