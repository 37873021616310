import styled from 'styled-components/macro';
import { ArrowDropDown as MuiArrowDropDownIcon } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { MenuItem as MuiMenuItem } from '@mui/material';
import { ListItemIcon as MuiListItemIcon, ButtonGroup } from '../..';

export const PrimaryMenuItem = styled(ButtonGroup)`
  .MuiButtonGroup-grouped {
    min-width: 0;
  }
`;

export const ArrowDropDownIcon = styled(MuiArrowDropDownIcon)`
  color: ${({ theme }) => theme.itamar.palette.white};
`;

export const MenuItem = styled(MuiMenuItem)`
  width: 100%;
  box-shadow: none;
  color: ${({ theme }) => theme.itamar.palette.text.primary}de;
  font-size: 14px;
  padding: 10px 16px;
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 0 !important;
  margin-inline-end: 8px;
`;
