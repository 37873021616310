import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { watchCustomerSyncStates } from '../../queries/watchCustomerSyncStates';
import { AppDispatch, useAppDispatch } from '../../../../redux/store';
import { updateCustomerSyncState } from '../../customersSlice';
import { CustomerResponseFragment } from '../../../../types/apiTypes';

const DEFAULT_POLL_INTERVAL = 2000;

function startPolling(customerId: string, dispatch: AppDispatch, pollInterval: number) {
  const observableQuery = watchCustomerSyncStates(customerId, pollInterval);

  observableQuery.subscribe(
    (value) => {
      if (value.errors || !value.data || !value.data.customers) {
        Sentry.captureException('got unexpected response for polling customer sync states', { extra: { value } });
      }

      const customers = value.data.customers.edges;
      const fetchedCustomer = customers.length > 0 ? customers[0].node : null;
      if (fetchedCustomer) {
        dispatch(updateCustomerSyncState(fetchedCustomer));
      }
    },
    (err) => {
      Sentry.captureException(err, { extra: { errorMessage: 'error during polling customer sync states' } });
    },
  );

  return observableQuery;
}

export function usePollCustomerSyncStates(
  customer: CustomerResponseFragment,
  pollInterval: number = DEFAULT_POLL_INTERVAL,
  onChange?: CallableFunction,
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const observableQuery = startPolling(customer.id, dispatch, pollInterval);
    return () => observableQuery?.stopPolling();
  }, [customer, onChange, pollInterval, dispatch]);
}
