import React, { useEffect } from 'react';
import { Alert, AlertTitle, ExternalLink, Form, GridFlex, List, Text } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import { SlimPackageFragment } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { archiveFeatureByIdAction, fetchFeatureAssociatedLatestPackagesAction } from '../../featuresSlice';
import Loader from '../../../../components/Loader';
import { useNavigation } from '../../../navigation/useNavigation';
import { normalizedPackageName } from '../../../../i18n/utils';

type ArchiveFeatureProps = {
  onClose: () => void;
  featureId: string;
  featureName: string;
};

export type ArchiveFeatureFormFields = {
  featureName: string;
};

const validationSchema = (featureName: string, associatedLatestPackages: SlimPackageFragment[]) => {
  if (isEmpty(associatedLatestPackages)) {
    return Yup.object().shape({
      featureName: Yup.string().equals([featureName], t('features.yup.archiveInputInvalid')),
    });
  }

  return undefined;
};
export function ArchiveFeature({ featureId, featureName, onClose }: ArchiveFeatureProps) {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const isLoadingAssociatedLatestPackages = useSelector(
    (root: RootState) => root.featuresReducer.isLoadingFeatureAssociatedLatestPackages,
  );
  const associatedLatestPackages: SlimPackageFragment[] | undefined = useSelector(
    (root: RootState) => root.featuresReducer.featureAssociatedLatestPackages,
  );

  const scrollMinHeight = associatedLatestPackages?.length === 1 ? 50 : 120;
  const initialValues: ArchiveFeatureFormFields = {
    featureName: '',
  };

  const handleSubmit = async () => {
    await dispatch(archiveFeatureByIdAction(featureId));
    onClose();
  };

  useEffect(() => {
    void dispatch(fetchFeatureAssociatedLatestPackagesAction(featureId));
  }, [featureId, dispatch]);

  if (isLoadingAssociatedLatestPackages) {
    return <Loader />;
  }

  return (
    <GridFlex.RowCenter>
      <Form
        disableSaveOnNoChanges
        submitButtonText={t('sharedComponents.archive')}
        saveButtonColor="error"
        onCancel={onClose}
        initialValues={initialValues}
        validationSchema={validationSchema(featureName, associatedLatestPackages || [])}
        onSubmit={handleSubmit}
        fields={() => [
          {
            type: 'custom',
            render: () => (
              <Text.B2 mb={2}>
                <Trans
                  i18nKey="features.archiveWarning"
                  values={{ featureName }}
                  components={{ strong: <Text.B2 display="inline" $bold /> }}
                />
              </Text.B2>
            ),
          },
          isEmpty(associatedLatestPackages)
            ? {
                id: 'featureName',
                type: 'text',
                label: <Text.B2 color="primary">{t('features.confirmArchiveLabel')} </Text.B2>,
                autoFocus: true,
                placeholder: featureName,
              }
            : {
                type: 'custom',
                render: () => (
                  <Alert severity="error" icon={false}>
                    <GridFlex.RowCenter>
                      <AlertTitle>
                        <Text.B2 color="warning.content">
                          <Trans i18nKey="features.archiveWarningAssociatedPackages" values={{ featureName }} />
                        </Text.B2>
                      </AlertTitle>
                    </GridFlex.RowCenter>
                    <List
                      sx={{
                        height: scrollMinHeight,
                        maxHeight: 240,
                        overflow: 'auto',
                      }}
                      dense
                      role="list">
                      {associatedLatestPackages?.map(({ type, refId, displayName }) => {
                        return (
                          <GridFlex.RowCenter p={1}>
                            <ExternalLink
                              label={`${displayName} ${normalizedPackageName(type)}`}
                              url={
                                type === 'Plan'
                                  ? navigation.composeUrl(navigation.appRoutes.planPage(refId))
                                  : navigation.composeUrl(navigation.appRoutes.addonPage(refId))
                              }
                            />
                          </GridFlex.RowCenter>
                        );
                      })}
                    </List>
                  </Alert>
                ),
              },
        ]}
      />
    </GridFlex.RowCenter>
  );
}
