import { gql } from '@apollo/client';
import { EventsFieldsInput, EventsFieldsQuery, EventsFieldsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const FETCH_EVENTS_FIELDS = gql`
  query EventsFields($input: EventsFieldsInput!) {
    eventsFields(input: $input) {
      fields
    }
  }
`;

export type EventsFieldsResult = {
  [eventKey: string]: string[];
};

async function fetchEventsFields(
  input: Omit<EventsFieldsInput, 'environmentId'>,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<{ fields: EventsFieldsResult }> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<EventsFieldsQuery, EventsFieldsQueryVariables>({
        query: FETCH_EVENTS_FIELDS,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            ...input,
            environmentId: accountReducer.currentEnvironmentId!,
          },
        },
      });

      return res.data.eventsFields;
    },
    {
      hideErrorMessages: true,
    },
    dispatch,
  );
}

export { fetchEventsFields };
