import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreatePackageGroup,
  ErrorCode,
  CreateAddonGroupMutation,
  CreateAddonGroupMutationVariables,
  PackageGroup,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';
import { appRoutes } from '../../../navigation/useNavigation';

const CREATE_ADDON_GROUP = gql`
  mutation CreateAddonGroup($input: CreatePackageGroup!) {
    createPackageGroup(input: $input) {
      packageGroupId
    }
  }
`;

async function createAddonGroup(
  addonGroupData: Omit<CreatePackageGroup, 'environmentId'>,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<PackageGroup> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<CreateAddonGroupMutation, CreateAddonGroupMutationVariables>({
        mutation: CREATE_ADDON_GROUP,
        variables: {
          input: { ...addonGroupData, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      const newAddonGroup = response.data?.createPackageGroup;
      if (newAddonGroup) {
        dispatch(navigateTo(appRoutes.addonGroupPage(newAddonGroup.packageGroupId)));
      }

      return newAddonGroup;
    },
    {
      successMessage: t('addons.api.successCreateAddonGroup'),
      failureMessageHandler: () => t('addons.api.failCreateAddonGroup'),
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('addons.api.failDupAddonGroupId', {
          addonGroupId: addonGroupData.packageGroupId,
        }),
      },
    },
    dispatch,
  );
}

export { createAddonGroup };
