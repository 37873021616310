import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Breadcrumb, useBreadcrumbs } from '@stigg-common';
import { GridFlex } from '@stigg-components';
import { t } from 'i18next';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchExperimentByRefIdAction, resetExperiment } from '../../experimentsSlice';
import { ExperimentGeneralInfo } from '../commom/generalInfo/ExperimentGeneralInfo';
import Loader from '../../../../components/Loader';
import { ExperimentDetails } from './ExperimentDetails';

type ViewExperimentRouteParams = {
  refId: string;
};

export function ExperimentPage() {
  const { refId } = useParams<ViewExperimentRouteParams>();
  const dispatch = useAppDispatch();
  const { experiment, isLoading } = useSelector((root: RootState) => root.experimentsReducer.single);

  const breadcrumbs: Breadcrumb[] = experiment
    ? [{ to: '/experiments', title: t('breadCrumbs.experiments') }, { title: experiment.name }]
    : [];
  useBreadcrumbs(...breadcrumbs);

  useEffect(() => {
    void dispatch(fetchExperimentByRefIdAction({ refId }));

    return () => {
      dispatch(resetExperiment());
    };
  }, [dispatch, refId]);

  if (!experiment || isLoading) {
    return <Loader />;
  }

  return (
    <GridFlex.Column rowGap={8}>
      <ExperimentGeneralInfo experiment={experiment} />
      <ExperimentDetails experiment={experiment} />
    </GridFlex.Column>
  );
}
