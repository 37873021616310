import { Grid, GridFlex, Icon, IconColor, Icons } from '@stigg-components';
import React from 'react';

export enum ChangeType {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  MOVE = 'MOVE',
}

export type SubscriptionFutureUpdateRowContainerProps = {
  changeType: ChangeType;
  children: React.ReactNode;
};

export function SubscriptionFutureUpdateRowContainer({
  changeType,
  children,
}: SubscriptionFutureUpdateRowContainerProps) {
  let icon: Icons;
  let color: IconColor;

  switch (changeType) {
    case ChangeType.ADDED: {
      icon = 'Plus';
      color = 'success';
      break;
    }
    case ChangeType.REMOVED: {
      icon = 'Minus';
      color = 'error';
      break;
    }
    case ChangeType.INCREASE: {
      icon = 'ArrowUp';
      color = 'success';
      break;
    }
    case ChangeType.DECREASE: {
      icon = 'ArrowDown';
      color = 'error';
      break;
    }
    case ChangeType.MOVE: {
      icon = 'ArrowRight';
      color = 'active';
      break;
    }
    default: {
      return null;
    }
  }

  return (
    <GridFlex.RowCenter>
      <Grid mr={1}>
        <Icon icon={icon} type="reactFeather" size={20} color={color} />
      </Grid>
      {children}
    </GridFlex.RowCenter>
  );
}
