import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import debounce from 'lodash/debounce';
import upperCase from 'lodash/upperCase';
import { Icon, Text, Avatar } from '@stigg-components';
import { CustomerListFragment } from '@stigg-types/apiTypes';
import { getCustomerEmailDomain, getDomainIcon } from './utils/customerIcon.utils';

const UserFavicon = styled(Avatar)`
  background-color: ${({ theme }) => theme.itamar.palette.background.lightBackground2};
  border-radius: 10px;
`;

const FaviconDomainImage = styled(Avatar)<{ $withBorder?: boolean }>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  border-radius: 10px;
  border: ${({ theme, $withBorder }) =>
    $withBorder ? `1px solid ${theme.itamar.palette.other.outlineBorderLight}` : 'none'};
  transition: all 0.3s ease-in-out;
  animation: fadeIn 0.3s;
`;

const ICON_SIZE = 18;
const FETCH_ICON_SIZE = 32;

function DefaultIcon({ size = ICON_SIZE }: { size?: number }) {
  return (
    <UserFavicon sx={{ width: size, height: size }}>
      <Icon type="reactFeather" icon="User" size={ICON_SIZE} color="default" />
    </UserFavicon>
  );
}

function InitialsIcon({ size, nameInitials }: { size?: number; nameInitials: string }) {
  return (
    <UserFavicon sx={{ width: size, height: size }}>
      <Text.B1 color="secondary.main">{nameInitials}</Text.B1>
    </UserFavicon>
  );
}

export function CustomerIcon({
  customer,
  domain,
  name,
  email,
  size = 24,
}: {
  customer?: CustomerListFragment;
  domain?: string;
  name?: string | null;
  email?: string | null;
  size?: number;
}) {
  const [src, setSrc] = useState<string | null>(null);

  const customerName = customer?.name || name;
  const { emailDomain, isValidDomain } = getCustomerEmailDomain(customer?.email || email, domain);
  const nameInitials = customerName && upperCase(customerName.slice(0, 1));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadImage = useCallback(
    debounce((domain) => {
      const image = new Image();
      image.onload = () => {
        if (image.naturalHeight === FETCH_ICON_SIZE) {
          setSrc(image.src);
        } else {
          setSrc(null);
        }
      };
      image.src = getDomainIcon(domain);
    }, 300),
    [],
  );

  useEffect(() => {
    if (isValidDomain) {
      loadImage(emailDomain);
    } else {
      setSrc(null);
    }
  }, [emailDomain, isValidDomain, loadImage]);

  if (!isValidDomain && !nameInitials) {
    return <DefaultIcon size={size} />;
  }

  if (!isValidDomain && nameInitials) {
    return <InitialsIcon size={size} nameInitials={nameInitials} />;
  }

  return src ? (
    <FaviconDomainImage
      sx={{ bgcolor: 'transparent' }}
      style={{ width: size, height: size }}
      imgProps={{ style: { width: ICON_SIZE, height: ICON_SIZE } }}
      $withBorder={size > FETCH_ICON_SIZE}
      src={src}
      alt="customer-icon"
    />
  ) : nameInitials ? (
    <InitialsIcon size={size} nameInitials={nameInitials} />
  ) : (
    <DefaultIcon size={size} />
  );
}
