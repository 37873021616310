import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  UpdateOneProductMutation,
  UpdateOneProductMutationVariables,
  ProductUpdateInput,
  Product,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchProductByRefIdAction, fetchProductsAction } from '../productsSlice';
import { appRoutes } from '../../navigation/useNavigation';
import { navigateTo } from '../../navigation/actions';

export const UPDATE_PRODUCT = gql`
  mutation UpdateOneProduct($input: UpdateOneProductInput!) {
    updateOneProduct(input: $input) {
      id
      displayName
      updatedAt
      description
    }
  }
`;

async function updateProduct(
  {
    productId,
    updatePayload,
    refId,
    successMessage,
    failureMessage,
    navigateToProduct,
  }: {
    productId: string;
    updatePayload: ProductUpdateInput;
    refId: string;
    successMessage?: string;
    failureMessage?: string;
    navigateToProduct?: boolean;
  },
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<Partial<Product> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const response = await apolloClient.mutate<UpdateOneProductMutation, UpdateOneProductMutationVariables>({
        mutation: UPDATE_PRODUCT,
        variables: { input: { id: productId, update: updatePayload } },
      });

      await dispatch(fetchProductByRefIdAction({ refId, silentFetch: true }));
      await dispatch(fetchProductsAction({ environmentId: accountReducer.currentEnvironmentId, silentFetch: true }));

      if (navigateToProduct) {
        dispatch(navigateTo(appRoutes.productPage(refId)));
      }

      return response.data?.updateOneProduct;
    },
    {
      successMessage: successMessage || t('products.api.successUpdate'),
      failureMessageHandler: () => failureMessage || t('products.api.failUpdate'),
    },
    dispatch,
  );
}

export { updateProduct };
