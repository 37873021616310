import numeral from 'numeral';
import moment from 'moment';

export const numberFullFormatter = (item: number) => numeral(item).format('0,0');

export const numberFormatter = (item: number) => numeral(item).format('0,0a');

export const percentFormatter = (item: number, precision = 1) =>
  numeral(item).format(`0[.]${''.padStart(precision, '0')}%`);

export const dateFormatter = (value: string, withHours = false) =>
  moment(value).format(withHours ? 'DD MMM, hh:mm A' : 'DD MMM');

export const formatPeriodDate = (x: moment.MomentInput) => moment(x).format('YYYY-MM-DD');
