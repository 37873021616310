import isNil from 'lodash/isNil';
import { CollapsableComponentOptions } from './Table.types';

export function shouldAllowCollapseForCurrentRow<TEntity>(
  item: TEntity,
  collapsableComponentOptions: CollapsableComponentOptions<TEntity> | undefined,
) {
  if (!collapsableComponentOptions) {
    return false;
  }
  // If predictor not provided, allow collapse
  if (!collapsableComponentOptions.shouldCollapseRow) {
    return true;
  }
  return collapsableComponentOptions.shouldCollapseRow(item);
}

export type GetPaginationContextProps = {
  totalCount?: number;
  pageNum?: number;
  pageSize: number;
  data: any[];
  pageInfo?: any;
};

function getItemsCount({ totalCount, pageNum, pageSize, data, pageInfo }: GetPaginationContextProps) {
  if (!isNil(totalCount)) {
    return totalCount;
  }

  if (isNil(pageNum) || isNil(pageInfo)) {
    return undefined;
  }

  if (pageInfo.hasNextPage) {
    return -1;
  }

  return pageNum * pageSize + data.length;
}

export function getPaginationContext(props: GetPaginationContextProps) {
  const totalCount = getItemsCount(props);
  if (isNil(totalCount) || (totalCount > -1 && totalCount < props.pageSize)) {
    return null;
  }

  // If totalCount is -1, we implement next button's disabled state on our own
  const nextIconButtonProps =
    totalCount === -1 && !isNil(props.pageInfo) && !props.pageInfo.hasNextPage ? { disabled: true } : undefined;

  return {
    totalCount,
    nextIconButtonProps,
  };
}
