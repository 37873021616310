import { gql } from '@apollo/client';
import { t } from 'i18next';
import { RemovePlanDraftMutation, RemovePlanDraftMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { navigateTo } from '../../../navigation/actions';

const REMOVE_PLAN_DRAFT = gql`
  mutation RemovePlanDraft($input: DiscardPackageDraftInput!) {
    removePlanDraft(input: $input)
  }
`;

async function discardPlanDraft(
  planRefId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      await apolloClient.mutate<RemovePlanDraftMutation, RemovePlanDraftMutationVariables>({
        mutation: REMOVE_PLAN_DRAFT,
        variables: {
          input: { refId: planRefId, environmentId: accountReducer.currentEnvironmentId },
        },
      });

      dispatch(navigateTo(`/plans`));
      return { refId: planRefId };
    },
    {
      failureMessageHandler: () => t('plans.api.failCreateDraft'),
    },
    dispatch,
  );
}

export { discardPlanDraft };
