import { EventLogType } from '@stigg-types/apiTypes';
import { groupBy } from 'lodash';

export enum WebhookEventLogType {
  CUSTOMER_CREATED = 'customer.created',
  CUSTOMER_UPDATED = 'customer.updated',
  CUSTOMER_DELETED = 'customer.deleted',
  SUBSCRIPTION_CREATED = 'subscription.created',
  SUBSCRIPTION_TRIAL_STARTED = 'subscription.trial_started',
  SUBSCRIPTION_TRIAL_EXPIRED = 'subscription.trial_expired',
  SUBSCRIPTION_TRIAL_CONVERTED = 'subscription.trial_converted',
  SUBSCRIPTION_TRIAL_ENDS_SOON = 'subscription.trial.ends_soon',
  SUBSCRIPTION_UPDATED = 'subscription.updated',
  SUBSCRIPTION_CANCELED = 'subscription.canceled',
  SUBSCRIPTION_EXPIRED = 'subscription.expired',
  SUBSCRIPTION_SPENT_LIMIT_EXCEEDED = 'subscription.spent_limit.exceeded',
  CREATE_SUBSCRIPTION_FAILED = 'subscription.create_failed',
  PLAN_CREATED = 'plan.created',
  PLAN_UPDATED = 'plan.updated',
  PLAN_DELETED = 'plan.deleted',
  ADDON_CREATED = 'addon.created',
  ADDON_UPDATED = 'addon.updated',
  ADDON_DELETED = 'addon.deleted',
  FEATURE_CREATED = 'feature.created',
  FEATURE_UPDATED = 'feature.updated',
  FEATURE_DELETED = 'feature.deleted',
  FEATURE_ARCHIVED = 'feature.archived',
  ENTITLEMENT_REQUESTED = 'entitlement.requested',
  ENTITLEMENT_GRANTED = 'entitlement.granted',
  ENTITLEMENT_DENIED = 'entitlement.denied',
  ENTITLEMENTS_UPDATED = 'entitlements.updated',
  ENTITLEMENT_USAGE_EXCEEDED = 'entitlement.usage_exceeded',
  MEASUREMENT_REPORTED = 'measurement.reported',
  PROMOTIONAL_ENTITLEMENT_GRANTED = 'promotionalEntitlement.granted',
  PROMOTIONAL_ENTITLEMENT_UPDATED = 'promotionalEntitlement.updated',
  PROMOTIONAL_ENTITLEMENT_EXPIRED = 'promotionalEntitlement.expired',
  PROMOTIONAL_ENTITLEMENT_REVOKED = 'promotionalEntitlement.revoked',
  COUPON_CREATED = 'coupon.created',
  COUPON_UPDATED = 'coupon.updated',
  COUPON_ARCHIVED = 'coupon.archived',
  SYNC_FAILED = 'sync.failed',
  CUSTOMER_PAYMENT_FAILED = 'customer.payment_failed',
  SUBSCRIPTION_BILLING_MONTH_ENDS_SOON = 'subscription.billing_month.ends_soon',
}

export const SUPPORTED_EVENT_LOG_TYPES = Object.values(EventLogType).filter(
  (x) => WebhookEventLogType[x as keyof typeof WebhookEventLogType],
) as EventLogType[];

export const EVENT_LOG_TYPES_BY_GROUP: Record<string, EventLogType[]> = groupBy(
  SUPPORTED_EVENT_LOG_TYPES,
  (x) => WebhookEventLogType[x as keyof typeof WebhookEventLogType].split('.')[0],
);
export enum SlackWebhookEventLogType {
  SyncFailed = 'SYNC_FAILED',
  CreateSubscriptionFailed = 'CREATE_SUBSCRIPTION_FAILED',
  CustomerPaymentFailed = 'CUSTOMER_PAYMENT_FAILED',
}
export const SLACK_LOG_TYPES_BY_GROUP: Record<string, EventLogType[]> = {
  FailedEvents: [EventLogType.SyncFailed, EventLogType.CreateSubscriptionFailed, EventLogType.CustomerPaymentFailed],
};
