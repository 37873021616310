import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

const GET_ENTITLEMENT_REQUEST = (
  featureRefId: string,
  addRequestedUsage: boolean,
) => `from stigg.generated import FetchEntitlementQuery

resp = client.get_entitlement(FetchEntitlementQuery(
  **{
    "customer_id": "${MOCK_CUSTOMER_ID}",
    "feature_id": "${featureRefId}"${
  addRequestedUsage
    ? `,
    "options": {  
      "requested_usage": ${MOCK_REQUESTED_USAGE}  
    }  `
    : ''
}
  }
))

if resp.entitlement.is_granted:
  # customer has access to the feature
  pass
else:
  # access denied
  pass`;

export const pythonFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `from stigg import Stigg

stigg_client = Stigg.create_client("${apiKey}")`,
  checkBooleanEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkNumericEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, false),
  checkMeteredEntitlement: (featureRefId) => GET_ENTITLEMENT_REQUEST(featureRefId, true),
  reportUsageForMeteredFeature: (featureRefId: string) => `from stigg.generated import UsageMeasurementCreateInput
  
data = stigg_client.report_usage(UsageMeasurementCreateInput(
  **{
    "value": 10,
    "customer_id": "${MOCK_CUSTOMER_ID}",
    "feature_id": "${featureRefId}"
}))`,
  reportEventForMeteredFeature: () => `from stigg.generated import UsageMeasurementCreateInput
  
data = stigg_client.request(Stigg.mutation.report_event, UsageEventReportInput(
  **{
    # replace with real customer ID
    customer_id: 'example-customer-id',
    event_name: 'example-event_name',
    idempotency_key: '82f584b6-488f-4275-a0d3-47442d64ad79';
    dimensions: {
      example_property: '1234',
    }
  }
))`,
};
