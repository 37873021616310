import { useMemo, useState } from 'react';
import { Text, Alert, GridFlex, Button } from '@stigg-components';
import { FeatureFragment } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { UserPlus, FileText } from 'react-feather';
import { getIconColor } from '../../../../theme';
import { RootState } from '../../../../redux/store';
import { getFeatureCodeSnippet, CodeSnippetType } from './getFeatureCodeSnippet';
import { CodeBlockSection } from './CodeBlockSection';
import { SDK_TO_SNIPPET_DATA, SdkType } from './sdkToSnippetData';
import { useInviteMembersDialog } from '../../../accounts/components/InviteMembersDialog/useInviteMembersDialog';
import { isMetered } from '../../utils/isMetered';

export function IntegrateFeature({ feature }: { feature: FeatureFragment }) {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);

  const [selectedSdkType, setSelectedSdkType] = useState<SdkType | undefined>();

  const { inviteMembersDialog, setIsInviteDialogOpen } = useInviteMembersDialog({
    shouldNavigateToAccountsMembersPageOnSubmit: true,
  });

  const availableSdks = useMemo(
    () =>
      Object.entries(SDK_TO_SNIPPET_DATA)
        .filter(([, data]) => data.featureSnippetCode)
        .map(([sdkType]) => sdkType as SdkType),
    [],
  );

  const availableReportUsagesSdks = useMemo(
    () =>
      Object.entries(SDK_TO_SNIPPET_DATA)
        .filter(([, data]) => data.featureSnippetCode?.reportUsageForMeteredFeature)
        .map(([sdkType]) => sdkType as SdkType),
    [],
  );

  if (!currentEnvironment) {
    return null;
  }

  const environmentApiKeys = currentEnvironment?.apiKeys;
  const isMeteredFeature = isMetered(feature);

  return (
    <>
      <GridFlex.Column pt={3}>
        <Alert severity="info">
          <Text.B2>{t('features.inviteEngineer')}</Text.B2>
          <GridFlex.RowCenter mt={3} $fullWidth justifyContent="stretch">
            <Button
              $outlined
              color="primary"
              startIcon={<UserPlus size={20} color={getIconColor('primary.main')} />}
              onClick={() => setIsInviteDialogOpen(true)}
              sx={{ marginRight: 2 }}>
              <Text.B2 color="primary.main">{t('features.inviteTeamMember')}</Text.B2>
            </Button>
            <Button
              $outlined
              color="primary"
              startIcon={<FileText size={20} color={getIconColor('primary.main')} />}
              onClick={() => window.open(t('features.integrateFeatureDocsUrl', '_blank'))}>
              <Text.B2 color="primary.main">{t('features.seeDocs')}</Text.B2>
            </Button>
          </GridFlex.RowCenter>
        </Alert>
        <CodeBlockSection
          title={t('features.initializeSdkTitle')}
          code={(sdkType: SdkType) =>
            getFeatureCodeSnippet(feature, environmentApiKeys, CodeSnippetType.INIT_SDK, sdkType)
          }
          onChangeSdkType={setSelectedSdkType}
          selectedSdkType={selectedSdkType}
          availableSdks={availableSdks}
        />
        <CodeBlockSection
          title={t('features.accessCheckFeatures')}
          code={(sdkType: SdkType) =>
            getFeatureCodeSnippet(feature, environmentApiKeys, CodeSnippetType.CHECK_ENTITLEMENT, sdkType)
          }
          onChangeSdkType={setSelectedSdkType}
          selectedSdkType={selectedSdkType}
          availableSdks={availableSdks}
        />
        {isMeteredFeature ? (
          <CodeBlockSection
            title={t('features.reportUsageForMeteredFeature')}
            code={(sdkType: SdkType) =>
              getFeatureCodeSnippet(
                feature,
                environmentApiKeys,
                feature.hasMeter ? CodeSnippetType.REPORT_EVENT : CodeSnippetType.REPORT_USAGE,
                sdkType,
              )
            }
            onChangeSdkType={setSelectedSdkType}
            selectedSdkType={
              selectedSdkType && availableReportUsagesSdks.includes(selectedSdkType) ? selectedSdkType : undefined
            }
            availableSdks={availableReportUsagesSdks}
          />
        ) : null}
      </GridFlex.Column>
      {inviteMembersDialog}
    </>
  );
}
