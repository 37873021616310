import React from 'react';
import { MoreVertical } from 'react-feather';
import { ListItemText, MenuDropdown, Text, GridFlex, InformationTooltip, Box } from '@stigg-components';
import { TextColor } from '@stigg-theme';
import * as S from './OptionsDropdown.style';
import * as DDS from '../dropdowns.style';
import { TooltipFields } from '../../InformationTooltip';

export type OptionsDropdownItemType = {
  icon: React.FC<any>;
  text: string | React.ReactNode;
  color?: TextColor;
  hide?: boolean;
  disabled?: boolean;
  tooltip?: TooltipFields;
  onClick?: () => void;
  dataTestId?: string;
};

export type OptionsDropdownType = Array<OptionsDropdownItemType>;

export function OptionsDropdown({
  disabled,
  disabledTooltip,
  tooltipOffset,
  tooltipPadding,
  options,
  icon: Icon,
  dataTestId,
}: {
  disabled?: boolean;
  disabledTooltip?: React.ReactNode;
  tooltipOffset?: Array<number>;
  tooltipPadding?: number;
  options: OptionsDropdownType;
  icon?: React.FC<any>;
  dataTestId?: string;
}) {
  const resolvedIcon = Icon ? <Icon /> : <MoreVertical size={20} />;
  const dropdownElement = (
    <MenuDropdown
      withFullWidthContainer={false}
      disabled={disabled}
      dropdownTrigger={
        <S.ThreeDotsIconContainer data-testid={dataTestId} disabled={disabled}>
          {resolvedIcon}
        </S.ThreeDotsIconContainer>
      }
      menuItems={options
        .filter(({ hide }) => !hide)
        .map(({ onClick, text, icon: Icon, disabled, tooltip, dataTestId }, index) => (
          <DDS.MenuItem disabled={disabled} key={index} onClick={onClick}>
            <InformationTooltip
              arrow
              placement={tooltip?.placement || 'top'}
              title={tooltip?.title || ''}
              PopperProps={{
                onClick: (e) => disabled && e.stopPropagation(),
              }}>
              <GridFlex.RowCenter
                $fullWidth
                py={1}
                sx={{ pointerEvents: 'auto' }}
                onClick={(e) => disabled && e.stopPropagation()}>
                <DDS.ListItemIcon>
                  <Icon />
                </DDS.ListItemIcon>
                <ListItemText
                  primary={
                    <Text.Sub2 data-testid={dataTestId} color="primary">
                      {text}
                    </Text.Sub2>
                  }
                />
              </GridFlex.RowCenter>
            </InformationTooltip>
          </DDS.MenuItem>
        ))}
    />
  );

  if (disabled && disabledTooltip) {
    return (
      <InformationTooltip
        arrow
        $padding={tooltipPadding}
        placement="left"
        title={disabledTooltip}
        PopperProps={{
          onClick: (e) => disabled && e.stopPropagation(),
          ...(tooltipOffset
            ? {
                popperOptions: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: tooltipOffset,
                      },
                    },
                  ],
                },
              }
            : {}),
        }}>
        <Box>{dropdownElement}</Box>
      </InformationTooltip>
    );
  }

  return dropdownElement;
}
