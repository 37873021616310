import { gql } from '@apollo/client';

export const TYPOGRAPHY_CONFIGURATION = gql`
  fragment TypographyConfigurationFragment on TypographyConfiguration {
    fontFamily
    h1 {
      ...FontVariantFragment
    }
    h2 {
      ...FontVariantFragment
    }
    h3 {
      ...FontVariantFragment
    }
    body {
      ...FontVariantFragment
    }
  }
  fragment FontVariantFragment on FontVariant {
    fontSize
    fontWeight
  }
`;
