import { UpdateSubscriptionEntitlementInput } from '@stigg-types/apiTypes';
import isNil from 'lodash/isNil';
import { getResetPeriodData } from '../../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.utils';
import { SubscriptionFormFields } from '../../customerPage/customerSubscriptions/subscriptionForm/SubscriptionForm.types';
import { getConfirmedEntitlements } from '../../../../entitlements/components/entitlementSettings/types';

export function mapSubscriptionFormEntitlementsToSubscriptionEntitlementsUpdate(
  entitlements: SubscriptionFormFields['entitlements'],
) {
  return getConfirmedEntitlements(entitlements).map<UpdateSubscriptionEntitlementInput>((entitlement) => ({
    featureId: entitlement.feature.refId,
    hasUnlimitedUsage: entitlement.hasUnlimitedUsage,
    hasSoftLimit: entitlement.hasSoftLimit,
    usageLimit: !isNil(entitlement.usageLimit) ? Math.floor(entitlement.usageLimit) : null,
    ...getResetPeriodData(entitlement.resetPeriod, entitlement.resetPeriodConfiguration),
  }));
}
