import { capitalize, isEmpty, partition } from 'lodash';
import { t } from 'i18next';
import { TextColor } from '@stigg-theme';
import { BoldDivider, Box, Divider, Grid, GridFlex, Icon, InformationTooltip, Text } from '@stigg-components';
import { BillingCadence, CouponFragment, CouponType, SubscriptionCouponDataFragment } from '@stigg-types/apiTypes';
import { getAddonPriceBreakdownString, PriceBreakdown, useTotalPrice } from './priceBreakdown';
import { CouponSummary } from '../../../../coupons/components/CouponSummary';
import { BaseCoupon, formatCouponValue } from '../../../../coupons/components/utils';
import { PlanPriceBreakdownItem } from './priceBreakdown/PlanPriceBreakdownItem';

export const SUBSCRIPTION_PREVIEW_MIN_WIDTH = 400;
export const SUBSCRIPTION_PREVIEW_MAX_WIDTH = 550;

export function SubscriptionPriceBreakdown({
  priceBreakdown,
  showMonthlyPriceVariation,
  textColor,
  spacing = 2,
  coupon,
  showOneOffCharges = false,
  planName,
}: {
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  textColor?: TextColor;
  spacing?: number;
  coupon?: BaseCoupon | undefined | null;
  showOneOffCharges?: boolean;
  planName?: string;
}) {
  const { total, minimumSpend, currency, oneOffTotal } = useTotalPrice({
    priceBreakdown,
    withCodePostfix: true,
  });
  const { total: subTotal, oneOffSubtotal } = useTotalPrice({
    priceBreakdown,
    withoutDiscount: true,
    withCodePostfix: true,
  });

  const { hasPayAsYouGoPrice, planBreakdown, addonsBreakDown } = priceBreakdown;
  const [recurringAddonBreakdown, oneOffAddonBreakdown] = partition(
    addonsBreakDown,
    (addonBreakdown) => addonBreakdown?.price.billingCadence === BillingCadence.Recurring,
  );
  const hasOneOffCharges = !isEmpty(oneOffAddonBreakdown);

  return (
    <Grid container flexDirection="column" rowSpacing={spacing}>
      <GridFlex.RowMiddle item>
        <Text.Caption pr={2}>{t('priceBreakdown.planChargesTitle', { planName: planName ?? 'Plan' })}</Text.Caption>
        <Divider my={2} sx={{ flex: 1 }} />
      </GridFlex.RowMiddle>

      <GridFlex.Column item gap={spacing * 2}>
        {planBreakdown.prices?.map((priceWithQuantity) => (
          <PlanPriceBreakdownItem
            key={priceWithQuantity.price.featureId || priceWithQuantity.price.id}
            priceWithQuantity={priceWithQuantity}
            textColor={textColor}
            showMonthlyPriceVariation={showMonthlyPriceVariation}
            spacing={spacing}
          />
        ))}
      </GridFlex.Column>
      {!isEmpty(recurringAddonBreakdown) && (
        <GridFlex.RowMiddle item mt={2}>
          <Text.Caption pr={2}>{t('priceBreakdown.addonsTitle')}</Text.Caption>
          <Divider my={2} sx={{ flex: 1 }} />
        </GridFlex.RowMiddle>
      )}
      {!isEmpty(recurringAddonBreakdown) && (
        <GridFlex.Column item gap={spacing * 2}>
          {recurringAddonBreakdown.map((addonBreakdown) =>
            addonBreakdown ? (
              <GridFlex.RowSpaceBetween alignItems="flex-start" key={addonBreakdown.addon.price?.id}>
                <Text.B2 color={textColor || 'secondary'} mr={4}>
                  {addonBreakdown.addon.displayName}
                </Text.B2>
                <Text.B2 color={textColor || 'secondary'} textAlign="right">
                  {getAddonPriceBreakdownString(addonBreakdown, showMonthlyPriceVariation)}
                </Text.B2>
              </GridFlex.RowSpaceBetween>
            ) : null,
          )}
        </GridFlex.Column>
      )}

      <GridFlex.Column item>
        <Divider my={2} />
      </GridFlex.Column>
      {coupon && (
        <>
          <GridFlex.RowSpaceBetween item>
            <Text.B2 color={textColor || 'primary'} mr={4}>
              {t('priceBreakdown.subTotal')}
            </Text.B2>
            <Text.B2
              color={textColor || 'primary'}
              textAlign="right"
              whiteSpace="nowrap"
              data-testid="subscription-price-subtotal">
              {subTotal}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
          <GridFlex.RowSpaceBetween item>
            <GridFlex.RowCenter item>
              <Text.B2 color={textColor || 'primary'} mr={2}>
                {t('priceBreakdown.discount')}
              </Text.B2>

              <CouponSummary size={18} />
            </GridFlex.RowCenter>
            <Text.B2 color={textColor || 'primary'} textAlign="right">
              {formatCouponValue(
                coupon.type === CouponType.Fixed ? priceBreakdown.appliedDiscount : coupon.discountValue,
                coupon.type,
                currency,
              )}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </>
      )}
      {minimumSpend && (
        <GridFlex.RowSpaceBetween item>
          <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
            <Text.B2 color={textColor || 'primary'}>{t('priceBreakdown.minimumSpend')}</Text.B2>
          </GridFlex.RowCenter>
          <Text.B2
            color={textColor || 'primary'}
            textAlign="right"
            whiteSpace="nowrap"
            data-testid="subscription-price-minimum-spend">
            {minimumSpend}
          </Text.B2>
        </GridFlex.RowSpaceBetween>
      )}
      <GridFlex.RowSpaceBetween item>
        <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
          <Text.B2 $bold color={textColor || 'primary'}>
            {t('priceBreakdown.total')} {hasOneOffCharges ? t('priceBreakdown.recurringCharges') : ''}
          </Text.B2>
        </GridFlex.RowCenter>
        <Text.B2
          $bold
          color={textColor || 'primary'}
          textAlign="right"
          whiteSpace="nowrap"
          data-testid="subscription-price-total">
          {total}
        </Text.B2>
      </GridFlex.RowSpaceBetween>

      {showOneOffCharges && hasOneOffCharges && (
        <>
          <GridFlex.Column item>
            <BoldDivider my={4} />
          </GridFlex.Column>
          <GridFlex.Column item>
            <Text.B2>{capitalize(t('priceBreakdown.oneOffCharges'))}</Text.B2>
          </GridFlex.Column>

          <GridFlex.RowMiddle item mt={2}>
            <Text.Caption pr={2}>{t('priceBreakdown.addonsTitle')}</Text.Caption>
            <Divider my={2} sx={{ flex: 1 }} />
          </GridFlex.RowMiddle>

          <GridFlex.Column item gap={spacing * 2}>
            {oneOffAddonBreakdown.map((addonBreakdown) =>
              addonBreakdown ? (
                <GridFlex.RowSpaceBetween item alignItems="flex-start" key={addonBreakdown.addon.displayName}>
                  <Text.B2 color={textColor || 'secondary'} mr={4}>
                    {addonBreakdown.addon.displayName}
                  </Text.B2>
                  <Text.B2 color={textColor || 'secondary'} textAlign="right">
                    {getAddonPriceBreakdownString(addonBreakdown, showMonthlyPriceVariation)}
                  </Text.B2>
                </GridFlex.RowSpaceBetween>
              ) : null,
            )}
          </GridFlex.Column>

          <GridFlex.Column item>
            <Divider my={3} />
          </GridFlex.Column>

          {coupon && (
            <>
              <GridFlex.RowSpaceBetween item>
                <Text.B2 color={textColor || 'primary'} mr={4}>
                  {t('priceBreakdown.subTotal')}
                </Text.B2>
                <Text.B2
                  color={textColor || 'primary'}
                  textAlign="right"
                  whiteSpace="nowrap"
                  data-testid="subscription-price-subtotal-one-off">
                  {oneOffSubtotal}
                </Text.B2>
              </GridFlex.RowSpaceBetween>
              <GridFlex.RowSpaceBetween item>
                <GridFlex.RowCenter item>
                  <Text.B2 color={textColor || 'primary'} mr={2}>
                    {t('priceBreakdown.discount')}
                  </Text.B2>

                  <CouponSummary size={18} />
                </GridFlex.RowCenter>
                <Text.B2 color={textColor || 'primary'} textAlign="right">
                  {formatCouponValue(coupon.discountValue, coupon.type, currency)}
                </Text.B2>
              </GridFlex.RowSpaceBetween>
            </>
          )}

          <GridFlex.RowSpaceBetween item>
            <GridFlex.RowCenter item mr={4} minWidth={105} gap={2} flexWrap="wrap">
              <Text.B2 $bold color={textColor || 'primary'}>
                {capitalize(t('priceBreakdown.total'))} {t('priceBreakdown.oneOffCharges')}
              </Text.B2>
            </GridFlex.RowCenter>
            <Text.B2
              $bold
              color={textColor || 'primary'}
              textAlign="right"
              whiteSpace="nowrap"
              data-testid="subscription-price-total-one-off">
              {oneOffTotal}
            </Text.B2>
          </GridFlex.RowSpaceBetween>
        </>
      )}

      <GridFlex.Column item mt={4}>
        {hasPayAsYouGoPrice && (
          <Text.Caption color="secondary" display="inline-flex" alignItems="center" gap={1}>
            <Icon icon="PayAsYouGoCharge" type="custom" />
            {t('priceBreakdown.payAsYouGoText')}
          </Text.Caption>
        )}

        <Text.Caption color="secondary" display="inline-flex" gap={1}>
          <Icon icon="Asterisk" type="custom" />
          {t('priceBreakdown.additionalTaxesMayApplyText')}
        </Text.Caption>
      </GridFlex.Column>
    </Grid>
  );
}

export function SubscriptionPriceBreakdownTooltip({
  children,
  priceBreakdown,
  showMonthlyPriceVariation,
  coupon,
}: {
  children: any;
  priceBreakdown: PriceBreakdown;
  showMonthlyPriceVariation: boolean;
  coupon?: CouponFragment | SubscriptionCouponDataFragment | null;
}) {
  return (
    <InformationTooltip
      PopperProps={{ onClick: (e) => e.stopPropagation() }}
      arrow
      placement="top"
      $maxWidth={600}
      title={
        <Box minWidth={SUBSCRIPTION_PREVIEW_MIN_WIDTH} maxWidth={SUBSCRIPTION_PREVIEW_MAX_WIDTH}>
          <Text.B2 mb={4} fontWeight={500}>
            {t('pricing.pricingBreakdown')}
          </Text.B2>

          <Grid item>
            <SubscriptionPriceBreakdown
              priceBreakdown={priceBreakdown}
              coupon={coupon}
              showMonthlyPriceVariation={!!showMonthlyPriceVariation}
            />
          </Grid>
        </Box>
      }>
      {children}
    </InformationTooltip>
  );
}
