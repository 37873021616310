import { gql } from '@apollo/client';
import { applyEnvironmentFilter, executeOperationSafely } from '@stigg-common';
import {
  CouponFilter,
  CouponSortFields,
  CouponStatus,
  FetchCouponsQuery,
  FetchCouponsQueryVariables,
  SortDirection,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Paging } from '../../../components/table/gqlTableHelper';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';

export const FETCH_SLIM_COUPONS = gql`
  query FetchSlimCoupons($paging: CursorPaging, $filter: CouponFilter!) {
    coupons(paging: $paging, filter: $filter) {
      edges {
        node {
          refId
          name
        }
      }
    }
  }
`;

export const SUBSCRIPTION_COUPON_FRAGMENT = gql`
  fragment SubscriptionCouponData on SubscriptionCoupon {
    name
    discountValue
    type
    refId
  }
`;
export const COUPON_FRAGMENT = gql`
  fragment CouponFragment on Coupon {
    id
    discountValue
    type
    additionalMetaData
    refId
    name
    description
    createdAt
    updatedAt
    billingId
    billingLinkUrl
    type
    status
    syncStates {
      vendorIdentifier
      status
      error
    }
  }
`;

export const FETCH_COUPONS = gql`
  ${COUPON_FRAGMENT}
  query FetchCoupons($filter: CouponFilter, $sorting: [CouponSort!], $paging: CursorPaging) {
    coupons(filter: $filter, sorting: $sorting, paging: $paging) {
      edges {
        node {
          ...CouponFragment
        }
      }
      totalCount
    }
  }
`;

export type FetchCouponsProps = {
  paging?: Paging;
  search?: string | null;
  environmentId: string;
};

async function fetchCoupons(
  props: FetchCouponsProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      let { search, paging } = props;

      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      if (search === '') {
        search = null;
      }

      const filter: CouponFilter = {
        environmentId: { eq: accountReducer.currentEnvironmentId },
        status: { eq: CouponStatus.Active },
      };

      if (search) {
        filter.or = [{ refId: { iLike: `%${search}%` } }, { name: { iLike: `%${search}%` } }].map((searchFilter) =>
          applyEnvironmentFilter<CouponFilter>(searchFilter, props.environmentId),
        );
      }

      if (!paging) {
        paging = { first: DEFAULT_TABLE_PAGE_SIZE };
      }

      const response = await apolloClient.query<FetchCouponsQuery, FetchCouponsQueryVariables>({
        query: FETCH_COUPONS,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: { field: CouponSortFields.CreatedAt, direction: SortDirection.Desc },
          filter,
        },
      });

      return response.data.coupons;
    },
    {
      failureMessageHandler: () => t('coupons.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchCoupons };
