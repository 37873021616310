import { Icon, GridFlex, Text, Icons } from '@stigg-components';
import { TiersSchema } from '../../SetPriceWizardForm.types';

type PricingModelSelectItemProps = {
  tiersSchema: TiersSchema;
  title: string;
  subtitle: string;
  secondSubtitle?: string;
};

const getIcon = (tiersSchema: TiersSchema): Icons => {
  switch (tiersSchema) {
    case TiersSchema.Standard:
      return 'StandardPricingModel';
    case TiersSchema.VolumeBulkOfUnits:
      return 'VolumePerBulkOfUnitsPricingModel';
    case TiersSchema.VolumePerUnit:
      return 'VolumePerUnitPricingModel';
    case TiersSchema.GraduatedPerUnit:
      return 'GraduatedPerUnitPricingModel';
    case TiersSchema.StandardPerBlock:
      return 'PackagePricingModel';
    default:
      return 'StandardPricingModel';
  }
};

export function PricingModelSelectItem({ tiersSchema, title, subtitle, secondSubtitle }: PricingModelSelectItemProps) {
  return (
    <GridFlex.RowCenter p={2}>
      <Icon type="custom" icon={getIcon(tiersSchema)} size={36} color="default" overrideStroke />
      <GridFlex.Column ml={6}>
        <Text.B2 color="primary" $medium>
          {title}
        </Text.B2>
        <Text.B2 color="secondary">{subtitle}</Text.B2>
        {secondSubtitle && <Text.B2 color="disabled">{secondSubtitle}</Text.B2>}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
