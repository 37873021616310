import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  StripeProductSearchInput,
  StripeProductSearchResultFragment,
  StripeProductsQuery,
  StripeProductsQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { RootState, AppDispatch } from '../../../redux/store';

const SEARCH_STRIPE_PRODUCTS = gql`
  query StripeProducts($input: StripeProductSearchInput!) {
    stripeProducts(input: $input) {
      ...StripeProductSearchResultFragment
    }
  }
  fragment StripeProductSearchResultFragment on StripeProductSearchResult {
    products {
      name
      id
      prices {
        amount
        billingPeriod
      }
      updatedAt
      isSynced
      notSupportedForImport
      environmentId
    }
    usageBasedProductPresent
    totalCount
    nextPage
  }
`;

export type SearchStripeProductsProps = {
  nextPage?: string;
  searchTerm?: string;
};

async function searchStripeProducts(
  props: SearchStripeProductsProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<StripeProductSearchResultFragment> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input: StripeProductSearchInput = {
        environmentId: accountReducer.currentEnvironmentId,
      };
      if (props.nextPage) {
        input.nextPage = props.nextPage;
      }
      if (props.searchTerm) {
        input.productName = props.searchTerm;
      }

      const response = await apolloClient.query<StripeProductsQuery, StripeProductsQueryVariables>({
        query: SEARCH_STRIPE_PRODUCTS,
        fetchPolicy: 'network-only',
        variables: {
          input,
        },
      });
      return response.data.stripeProducts;
    },
    {
      failureMessageHandler: () => t('integrations.api.failSearchProducts'),
    },
    dispatch,
  );
}

export { searchStripeProducts };
