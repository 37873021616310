import { useScrollableElement, StepAccordionWithConfirm } from '@stigg-components';
import React from 'react';
import { get, omit } from 'lodash';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { getChargeGroupId } from '../../../../utils/priceGroups';
import { OveragePrice } from './overagePrice/OveragePrice';
import { OverageChargeTitle } from './overagePrice/OverageChargeTitle';

export type ChargeProps = {
  index: number;
  isFocused: boolean;
  onRemove: () => void;
  onConfirm: () => void;
  onToggle: () => void;
  permanent: boolean;
};

export function OverageChargeAccordion({ index, isFocused, onRemove, onConfirm, onToggle, permanent }: ChargeProps) {
  const { formRenderProps, initialFocusedSection } = useSetPriceWizardFormContext();
  const { values, setFieldValue } = formRenderProps;
  const { overageCharges } = values;
  const currentCharge = overageCharges.charges[index];
  const singleCharge = overageCharges.charges.length === 1;

  const currentChargeErrors = get(formRenderProps.errors, `overageCharges.charges[${index}]`);
  const hasErrors = !isEmpty(currentChargeErrors);
  const hasErrorsWithoutIsConfirmed = !isEmpty(omit(currentChargeErrors, 'isConfirmed'));

  const { elementRef } = useScrollableElement({
    shouldAutoScroll: () =>
      initialFocusedSection?.stepId === 'overageCharges' &&
      initialFocusedSection.priceGroupId === getChargeGroupId(currentCharge),
  });

  const onConfirmButton = () => {
    setFieldValue(`overageCharges.charges[${index}].isConfirmed`, true);
    onConfirm();
  };

  return (
    <StepAccordionWithConfirm
      ref={elementRef}
      minHeaderHeight={71}
      isFocused={isFocused}
      onToggle={onToggle}
      hasErrors={hasErrors}
      hideCancel={singleCharge}
      onRemove={onRemove}
      confirmRemoveDialogTitle={t(`pricing.deleteChargeDialogTitle`)}
      confirmRemoveDialogContent={t(`pricing.deleteChargeDialogContent`)}
      permanent={permanent}
      header={<OverageChargeTitle charge={currentCharge} bold />}
      isConfirmed={currentCharge.isConfirmed}
      isConfirmDisabled={hasErrorsWithoutIsConfirmed}
      onConfirm={onConfirmButton}>
      <OveragePrice index={index} />
    </StepAccordionWithConfirm>
  );
}
