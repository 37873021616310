import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { ExternalLink } from '@stigg-components';
import { Plan, SubscriptionCancellationTime, SubscriptionEndSetup } from '@stigg-types/apiTypes';
import { Navigation } from '../navigation/useNavigation';

export const downgradeProductFormat = (
  navigation: Navigation,
  subscriptionEndSetup?: SubscriptionEndSetup | null,
  downgradePlan?: Partial<Plan> | null,
): any => {
  switch (subscriptionEndSetup) {
    case SubscriptionEndSetup.DowngradeToFree:
      return (
        <Trans
          i18nKey="products.trials.downgradeToPredefinedFree"
          t={t}
          values={{ planName: downgradePlan?.displayName }}
          components={[
            <ExternalLink url={navigation.composeUrl(navigation.appRoutes.planPage(downgradePlan?.refId || ''))} />,
          ]}
        />
      );

    case SubscriptionEndSetup.CancelSubscription:
      return t('products.trials.cancelSubscriptionDowngrade');
    default:
      return '';
  }
};

export const downgradeProductTimeFormat = (
  subscriptionCancellationTime?: SubscriptionCancellationTime | null,
  isTrial?: boolean,
): string => {
  switch (subscriptionCancellationTime) {
    case SubscriptionCancellationTime.Immediate:
      return t('products.trials.cancelSubscriptionImmediate');

    case SubscriptionCancellationTime.EndOfBillingPeriod:
      return isTrial
        ? t('products.trials.cancelSubscriptionEndOfTrialPeriod')
        : t('products.trials.cancelSubscriptionEndOfBillingPeriod');
    default:
      return '';
  }
};
