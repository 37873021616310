import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export const useElementPosition = (requiredSpace: number) => {
  const [availableSpace, setAvailableSpace] = useState(0);
  const [isPlacementTop, setIsPlacementTop] = useState(false);
  const elementPositionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const updateSize = debounce(() => {
      if (elementPositionRef.current) {
        const domRect = elementPositionRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - domRect.bottom;
        const spaceAbove = domRect.top;
        const isPlacementTop = spaceBelow < requiredSpace && spaceAbove >= requiredSpace;
        setIsPlacementTop(isPlacementTop);
        setAvailableSpace(isPlacementTop ? spaceAbove : spaceBelow);
      }
    }, 150);

    window.addEventListener('resize', updateSize);
    window.addEventListener('scroll', updateSize, true);

    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('scroll', updateSize, true);
    };
  }, [requiredSpace]);

  return { elementPositionRef, isPlacementTop, availableSpace };
};
