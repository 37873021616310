import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { t } from 'i18next';
import { Formik, FormikHelpers } from 'formik';
import {
  ActiveIntegrationIcons,
  Box,
  Button,
  Grid,
  GridFlex,
  Icon,
  LoadingButton,
  MenuItem,
  Select,
  Text,
  TextField,
} from '@stigg-components';
import { WidgetType } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { Save } from 'react-feather';
import map from 'lodash/map';
import { PackageFragment } from './Packages';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchProductsAction } from '../../../products/productsSlice';
import Loader from '../../../../components/Loader';
import { fetchDialogPlansAction } from '../../plans/plansSlice';
import { usePaywallVisibilityControlFeature } from './usePaywallVisibilityControlFeature';
import { AddonTypeValue, AddonType } from '../../addons/components/AddonTypeValue';
import { isPackageAddon, isPackageSingleInstanceAddon } from '../packageUtils';
import { PackagePaywallCustomerVisibilityFormField } from './hiddenFromWidgets/PackagePaywallCustomerVisibilityFormField';

export type EditPackageFormFields = {
  productId: string;
  refId: string;
  billingId?: string | null;
  displayName: string;
  description?: string | null;
  hiddenFromWidgets?: WidgetType[] | null;
  addonType?: AddonType;
  dependencies?: string[];
};

export type EditPackageFormProps = {
  aPackage: PackageFragment;
  onCancel: () => void;
  onUpdate: (onUpdate: EditPackageFormFields) => void;
  packageType: 'Plan' | 'Add-on';
  isUpdateInProgress?: boolean;
};

const validationSchema = Yup.object().shape({
  refId: Yup.string().required(t('packages.yup.required')),
  displayName: Yup.string().required(t('packages.yup.required')),
  description: Yup.string().nullable(true),
});

export function EditPackageForm({
  aPackage,
  onCancel,
  onUpdate,
  packageType,
  isUpdateInProgress,
}: EditPackageFormProps) {
  const isLoadingProducts = useSelector((state: RootState) => state.productReducer.isLoading);
  // first created product is the default one. fetchProductsAction is ordered by creation date.
  const products = useSelector((state: RootState) => state.productReducer.products);
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const packageIsAddon = isPackageAddon(aPackage);
  const packageIsPlan = isPackageAddon(aPackage);

  const showPaywallVisibilityControl = usePaywallVisibilityControlFeature(packageType);

  useEffect(() => {
    if (currentEnvironmentId) {
      if (packageIsPlan) {
        void dispatch(
          fetchDialogPlansAction({
            environmentId: currentEnvironmentId,
            paging: { first: 100 },
          }),
        );
      }

      void dispatch(fetchProductsAction({ environmentId: currentEnvironmentId }));
    }
  }, [dispatch, currentEnvironmentId, packageIsPlan]);

  if (isLoadingProducts) {
    return <Loader />;
  }

  const initialValues: EditPackageFormFields = {
    productId: aPackage.product?.id || '',
    refId: aPackage.refId,
    displayName: aPackage.displayName,
    description: aPackage.description,
    billingId: aPackage.billingId,
    hiddenFromWidgets: aPackage.hiddenFromWidgets,
    addonType: packageIsAddon
      ? isPackageSingleInstanceAddon(aPackage)
        ? AddonType.SINGLE
        : AddonType.MULTI
      : undefined,
  };

  const handleSubmit = (values: EditPackageFormFields, actions: FormikHelpers<EditPackageFormFields>) => {
    onUpdate(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ errors, isValid, dirty, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
        const isPaywallHidden = (values?.hiddenFromWidgets || []).some((value) => value === WidgetType.Paywall);
        return (
          <Box>
            <form onSubmit={handleSubmit}>
              <GridFlex.Column container rowSpacing={2}>
                <GridFlex.Column item>
                  <Select
                    fullWidth
                    name="productId"
                    value={values.productId}
                    labelId="package-product"
                    label="Product"
                    onChange={handleChange}
                    placeholder={t('packages.selectProductPlaceholder')}
                    renderValue={(value) => (
                      <GridFlex.RowCenter>
                        <Icon icon="Grid" color="active" />
                        <Text.B2 ml={2}>{products.find((v: any) => v.id === value)?.displayName}</Text.B2>
                      </GridFlex.RowCenter>
                    )}
                    disabled
                  />
                </GridFlex.Column>
                <Grid item>
                  <TextField
                    name="displayName"
                    label={t('packages.name')}
                    value={values.displayName}
                    touched={!!touched.displayName}
                    error={!!errors.displayName}
                    fullWidth
                    errorText={errors.displayName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <GridFlex.Column mr={2} sm item>
                  <TextField
                    disabled
                    name="refId"
                    label={t('packages.packageId')}
                    value={values.refId}
                    touched={!!touched.refId}
                    error={!!errors.refId}
                    fullWidth
                    errorText={errors.refId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    captionText={t('packages.packageIdText', {
                      packageType: packageType === 'Plan' ? 'plan' : 'add-on',
                    })}
                  />
                </GridFlex.Column>

                <GridFlex.Column item>
                  <TextField
                    name="billingId"
                    label={t('packages.billingId')}
                    value={values.billingId}
                    touched={!!touched.billingId}
                    error={!!errors.billingId}
                    fullWidth
                    errorText={errors.billingId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    captionText={t('packages.billingIdHelperText', {
                      packageType: packageType === 'Plan' ? 'plan' : 'add-on',
                    })}
                  />
                </GridFlex.Column>

                <Grid item>
                  <TextField
                    maxRows={4}
                    name="description"
                    label={t('packages.descriptionForm')}
                    value={values.description}
                    touched={!!touched.description}
                    error={!!errors.description}
                    fullWidth
                    errorText={errors.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    optional
                  />
                </Grid>
                {packageIsAddon && (
                  <GridFlex.Column item>
                    <Select
                      fullWidth
                      value={values.addonType}
                      name="addonType"
                      labelId="addon-type"
                      renderValue={(value) => <AddonTypeValue value={value} />}
                      label={t('packages.addonTypeLabel')}
                      disabled={!!aPackage.hasSubscriptions}
                      helpTooltipText={aPackage.hasSubscriptions ? t('packages.cantChangeAddonTypeLabel') : null}
                      onChange={handleChange}>
                      {map(Object.values(AddonType), (maxQuantity: AddonType) => (
                        <MenuItem key={maxQuantity} value={maxQuantity}>
                          <AddonTypeValue value={maxQuantity} withExample />
                        </MenuItem>
                      ))}
                    </Select>
                  </GridFlex.Column>
                )}
                {showPaywallVisibilityControl && (
                  <PackagePaywallCustomerVisibilityFormField
                    isPaywallHidden={isPaywallHidden}
                    onToggle={(hiddenFromWidgets) => setFieldValue('hiddenFromWidgets', hiddenFromWidgets)}
                  />
                )}

                <Grid item container mt={4} justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <ActiveIntegrationIcons />
                  </Grid>
                  <Grid item>
                    <Button onClick={onCancel} sx={{ mr: 3 }} $outlined color="primary">
                      {t('packages.cancelButton')}
                    </Button>
                    <LoadingButton
                      loadingPosition="start"
                      loading={isUpdateInProgress}
                      startIcon={isUpdateInProgress ? <Save /> : null}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!dirty || !isValid}>
                      {t('sharedComponents.editSaveButton')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </GridFlex.Column>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
}
