import { Text, GridFlex, ExternalLink } from '@stigg-components';
import { t } from 'i18next';
import { IntegrationFragment, StripeCredentialsFragment } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { Optional } from '../../../types/primitives';
import { ClipboardChip } from '../../../components/clipboard/ClipboardChip';

export type StripeIntegrationDetailsProps = { integration: IntegrationFragment };

export function StripeIntegrationDetails({ integration }: StripeIntegrationDetailsProps) {
  const { stiggInternalProductivityTools: stiggInternalProductivityToolsEnabled } = useFlags<FeatureFlags>();
  const credentials = integration.credentials as Optional<StripeCredentialsFragment>;
  if (!credentials) {
    return null;
  }
  const { accountDisplayName, isTestMode, accountId } = credentials;

  return (
    <GridFlex.RowSpaceBetween>
      <GridFlex.Column item gap={2}>
        <Text.Sub2>{t('integrations.stripe.accountName')}</Text.Sub2>
        <Text.B2>
          {accountDisplayName}
          {isTestMode && ' (Test)'}
        </Text.B2>
      </GridFlex.Column>
      {stiggInternalProductivityToolsEnabled && (
        <GridFlex.Column gap={2} alignItems="end">
          <ExternalLink
            url={`https://dashboard.stripe.com${isTestMode ? '/test' : ''}/connect/accounts/${accountId}/activity`}>
            Open Stripe dashboard
          </ExternalLink>
          <ClipboardChip text={accountId} copy={accountId} iconLocation="right" variant="code" />
        </GridFlex.Column>
      )}
    </GridFlex.RowSpaceBetween>
  );
}
