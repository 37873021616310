import { useState } from 'react';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import {
  GridFlex,
  Icon,
  Box,
  Stepper,
  StepperPoints,
  Step as MuiStep,
  StepLabel,
  FullScreenDialogLayout,
  CustomRenderProps,
  Text,
} from '@stigg-components';
import { CreateFeatureFormFields } from './SidenavCreateFeature';
import { STEP_TITLES, Steps } from './MeterDefinitionSteps';
import { UpdateFeatureFields } from '../featureDetails/FeatureDetailsTab';

function DialogStepIcon(props: { completed?: boolean; active?: boolean }) {
  const { completed, active } = props;

  return completed ? (
    <Icon type="custom" icon="StepperIndicator" color="primary.main" size={20} />
  ) : (
    <Icon type="reactFeather" icon="Circle" color={active ? 'primary.main' : 'disabled'} size={20} />
  );
}

const StyledStepLabel = styled(StepLabel)`
  .Mui-active,
  .Mui-completed {
    color: ${({ theme }) => theme.itamar.palette.primary.main};
  }
`;

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.itamar.palette.grey[50]};
`;

function DialogHeader({
  activeStep,
  featureName,
  isUpdate,
  readonly,
}: {
  activeStep: number;
  featureName: string;
  isUpdate?: boolean;
  readonly?: boolean;
}) {
  return (
    <GridFlex.RowCenter justifyContent="space-between" $fullWidth mr={20}>
      <GridFlex.RowCenter>
        <IconBox mr={4}>
          <Icon type="custom" icon="MeteredEvents" />
        </IconBox>
        <GridFlex.RowCenter columnGap={1}>
          <Text.H6 color="secondary" sx={{ fontWeight: 400 }}>
            {isUpdate
              ? readonly
                ? t('features.filtersAndAggregationTitleViewMode')
                : t('features.filtersAndAggregationTitleUpdateMode')
              : t('features.filtersAndAggregationTitleCreateMode')}
            :
          </Text.H6>
          <Text.H6 $bold={false}>{featureName}</Text.H6>
        </GridFlex.RowCenter>
      </GridFlex.RowCenter>
      {!readonly && (
        <Box pl={3} sx={{ width: '380px' }}>
          <StepperPoints activeStep={activeStep}>
            {STEP_TITLES().map((title) => (
              <MuiStep key={title}>
                <StyledStepLabel StepIconComponent={DialogStepIcon}>{title}</StyledStepLabel>
              </MuiStep>
            ))}
          </StepperPoints>
        </Box>
      )}
    </GridFlex.RowCenter>
  );
}

type FiltersAndAggregationProps<T extends CreateFeatureFormFields | UpdateFeatureFields> = {
  onCancel: () => void;
  onSubmit: () => Promise<void> | void;
  formRenderProps: CustomRenderProps<T>;
  readonly?: boolean;
  isUpdate?: boolean;
  defaultStep?: number;
};

export function FiltersAndAggregation<T extends CreateFeatureFormFields | UpdateFeatureFields>({
  onCancel,
  onSubmit,
  formRenderProps,
  readonly,
  isUpdate,
  defaultStep,
}: FiltersAndAggregationProps<T>) {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { values } = formRenderProps;

  const onDone = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  const featureName = formRenderProps.values.displayName;
  const stepperSteps = Steps({ filters: values.filters, formRenderProps, readonly, isUpdate });
  return (
    <FullScreenDialogLayout
      variant="grey"
      title={
        <DialogHeader activeStep={activeStep} featureName={featureName} isUpdate={isUpdate} readonly={readonly} />
      }>
      <Stepper
        hideStepsIndication
        steps={stepperSteps}
        onCancel={onCancel}
        onStepChange={setActiveStep}
        onDone={onDone}
        isNextValid={stepperSteps[activeStep].isNextValid}
        isNextLoading={isLoading}
        defaultStep={defaultStep}
      />
    </FullScreenDialogLayout>
  );
}
