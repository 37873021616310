import { FormRenderProps, Text, GridFlex, SplitRate } from '@stigg-components';
import { t } from 'i18next';
import * as React from 'react';
import { CollapsePageCard } from '../../../../components/CollapsePageCard';
import { ExperimentPreset } from './ExperimentWizard';
import { RatioValueBox } from './RatioValueBox';

export function RatioCard({
  formRenderProps,
  open,
}: {
  formRenderProps: FormRenderProps<ExperimentPreset>;
  open: boolean;
}) {
  return (
    <CollapsePageCard
      cardProps={{ $withClosed: !open }}
      contentOpen={open}
      cardTitle={<Text.H6>{t('experiments.wizard.experimentRatioTitle')}</Text.H6>}>
      <GridFlex.RowSpaceBetween container rowSpacing={3}>
        <GridFlex.Item xs={2}>
          <RatioValueBox controlBox formRenderProps={formRenderProps} />
        </GridFlex.Item>
        <GridFlex.Item xs={8} mt={6}>
          <SplitRate
            percentage={formRenderProps.values.variantPercentage}
            reverse
            hideSplit
            variant="slider"
            setValue={(value) => {
              formRenderProps.setFieldValue('variantPercentage', 100 - value);
              formRenderProps.setFieldValue('controlPercentage', value);
            }}
          />
        </GridFlex.Item>
        <GridFlex.Item xs={2}>
          <RatioValueBox controlBox={false} formRenderProps={formRenderProps} />
        </GridFlex.Item>
      </GridFlex.RowSpaceBetween>
    </CollapsePageCard>
  );
}
