import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { Text } from '@stigg-components';
import { SubscriptionAddonDataFragment } from '@stigg-types/apiTypes';
import { EmptyCardContent } from '../../../../../components/EmptyCardContent';
import Table from '../../../../../components/table/Table';
import { PageCard } from '../../../../../components/PageCard';
import { useNavigation } from '../../../../navigation/useNavigation';
import { createHeadCells } from './createHeadCells';

type SubscriptionAddonsProps = {
  addons: SubscriptionAddonDataFragment[];
  outdatedPricePackages?: string[] | null;
};

export function SubscriptionAddons({ addons, outdatedPricePackages }: SubscriptionAddonsProps) {
  const navigation = useNavigation();

  return (
    <PageCard>
      <Text.H3 mb={3}>{t('subscriptions.addons')}</Text.H3>
      <Text.Sub2 mb={4}>{t('subscriptions.addonsSubtitle')}</Text.Sub2>

      {!isEmpty(addons) ? (
        <Table
          label={t('subscriptions.addons')}
          headCells={createHeadCells(outdatedPricePackages)}
          data={addons || []}
          onRowClick={(addon: SubscriptionAddonDataFragment) =>
            navigation.navigateTo(navigation.appRoutes.addonPage(addon.addon.refId, addon.addon.versionNumber))
          }
        />
      ) : (
        <EmptyCardContent>
          <Text.Sub2>{t('subscriptions.emptyAddonsState')}</Text.Sub2>
        </EmptyCardContent>
      )}
    </PageCard>
  );
}
