import { useEffect, useCallback } from 'react';

type ArrowKeyActionProps = {
  onLeftArrow?: () => void;
  onRightArrow?: () => void;
};

const useArrowKeyAction = ({ onLeftArrow, onRightArrow }: ArrowKeyActionProps) => {
  const handleArrowNavigation = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (onLeftArrow) onLeftArrow();
          break;
        case 'ArrowRight':
          if (onRightArrow) onRightArrow();
          break;
        default:
          break;
      }
    },
    [onLeftArrow, onRightArrow],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleArrowNavigation);
    return () => {
      window.removeEventListener('keydown', handleArrowNavigation);
    };
  }, [handleArrowNavigation]);
};

export default useArrowKeyAction;
