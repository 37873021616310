// eslint-disable-next-line no-restricted-imports
import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import styled from 'styled-components/macro';

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(2),
    border: 0,

    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export const ToggleButtonGroup: typeof MuiToggleButtonGroup = ({ color, ...rest }) => (
  <StyledToggleButtonGroup color={color || 'primary'} {...rest} />
);
