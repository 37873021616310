import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { ExperimentFragment, ProductFragment, ProductListItemFragment } from '@stigg-types/apiTypes';
import { ExperimentPreset } from './ExperimentWizard';
import { calculateInitialCustomerJourneyFormValues } from '../../../products/components/productPage/customerJourney/CustomerJourney';
import { ExperimentType } from '../commom/ExperimentType';

export function composeExperimentPreset(experiment: ExperimentFragment, product: ProductFragment): ExperimentPreset {
  const customerJourney = !isEmpty(product)
    ? calculateInitialCustomerJourneyFormValues(product.plans, experiment.productSettings)
    : undefined;

  return {
    name: experiment.name,
    description: experiment.description || '',
    variantGroupName: experiment.variantGroupName,
    controlGroupName: experiment.controlGroupName,
    variantPercentage: experiment.variantPercentage,
    controlPercentage: 100 - experiment.variantPercentage,
    productId: experiment.product?.id || '',
    ...customerJourney,
  };
}

export function composeEmptyExperimentPreset(
  products: ProductListItemFragment[],
  product: ProductFragment,
): ExperimentPreset {
  const customerJourney = !isEmpty(product)
    ? calculateInitialCustomerJourneyFormValues(product.plans, product.productSettings)
    : undefined;

  return {
    name: '',
    description: '',
    variantGroupName: t('experiments.wizard.variantGroupPlaceholder'),
    controlGroupName: t('experiments.wizard.controlGroupPlaceholder'),
    variantPercentage: 50,
    controlPercentage: 50,
    productId: products.length === 1 ? products[0].id : !isEmpty(product) ? product.id : '',
    ...customerJourney,
  };
}

export function computeExperimentType(
  experimentEntitlementsFeatureEnabled: boolean,
  experiment: ExperimentFragment | undefined,
  requestedExperimentType: ExperimentType | undefined,
): ExperimentType {
  if (experiment) {
    return experiment.productSettings ? ExperimentType.CUSTOMER_JOURNEY : ExperimentType.PLAN_ENTITLEMENTS;
  }
  if (requestedExperimentType) {
    return requestedExperimentType;
  }
  return experimentEntitlementsFeatureEnabled ? ExperimentType.PLAN_ENTITLEMENTS : ExperimentType.CUSTOMER_JOURNEY;
}
