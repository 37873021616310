import { gql } from '@apollo/client';
import { User, UpdateUserInput, UpdateUserMutation, UpdateUserMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const UPDATE_ACCOUNT = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

async function updateUser(
  updateUserInput: UpdateUserInput,
  { dispatch }: { dispatch: AppDispatch },
): Promise<Partial<User> | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: UPDATE_ACCOUNT,
        variables: { input: updateUserInput },
      });

      return response.data?.updateUser;
    },
    {},
    dispatch,
  );
}

export { updateUser };
