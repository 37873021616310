import { t } from 'i18next';
import { ExperimentGroupType } from '@stigg-types/apiTypes';
import { Theme, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as React from 'react';
import { headCells, GroupResult } from './headCells';
import Table from '../../../../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { fetchExperimentStatsByRefIdAction } from '../../../../experimentsSlice';
import Loader from '../../../../../../components/Loader';

const getGroupBackground = (theme: Theme): Record<ExperimentGroupType, string> => ({
  [ExperimentGroupType.Control]: `${theme.itamar.palette.grey[200]}33`, // 20% color opacity
  [ExperimentGroupType.Variant]: theme.isLightTheme
    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(225.2deg, #56ACF6 3.06%, #1559E6 100%);'
    : `linear-gradient(225.2deg, ${theme.itamar.palette.primary.light} 3.06%, ${theme.itamar.palette.primary.main} 100%);`,
});

type ExperimentResultsTableProps = {
  controlGroupName: string;
  variantGroupName: string;
  experimentRefId: string;
  variantPercentage: number;
};

export function ExperimentResultsTable({
  controlGroupName,
  variantGroupName,
  experimentRefId,
  variantPercentage,
}: ExperimentResultsTableProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    single: { experimentStats, isLoadingStats },
  } = useSelector((root: RootState) => root.experimentsReducer);
  const groupBackgrounds = getGroupBackground(theme);

  useEffect(() => {
    void dispatch(fetchExperimentStatsByRefIdAction({ refId: experimentRefId }));
  }, [dispatch, experimentRefId]);

  if (!experimentStats || isLoadingStats) {
    return <Loader />;
  }

  const data: GroupResult[] = [
    {
      groupType: ExperimentGroupType.Control,
      groupName: controlGroupName,
      subscriptions: experimentStats.controlSubscriptions,
      paidSubscriptions: experimentStats.controlPaidSubscriptions,
      percentage: 100 - variantPercentage,
    },
    {
      groupType: ExperimentGroupType.Variant,
      groupName: variantGroupName,
      subscriptions: experimentStats.variantSubscriptions,
      paidSubscriptions: experimentStats.variantPaidSubscriptions,
      percentage: variantPercentage,
    },
  ];
  return (
    <Table
      headCells={headCells()}
      label={t('experiments.results')}
      data={data}
      rowColor={(groupResult) => groupResult && groupBackgrounds[groupResult.groupType]}
      disableBoldHeader
    />
  );
}
