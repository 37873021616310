import { useEffect, useRef } from 'react';
import { animate } from 'framer-motion';

/**
 * This component is used to animate a number value when the value prop changes.
 */
export function NumberAnimation({ value }: { value: number }) {
  const nodeRef = useRef<any>();
  const prevValue = useRef<number>(value);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(prevValue.current, value, {
      onUpdate(value) {
        node.textContent = Math.round(value);
      },
      onComplete() {
        prevValue.current = value;
      },
    });

    return () => controls.stop();
  }, [value]);

  return <span ref={nodeRef} />;
}
