import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

export const useSubscriptions = (isLoaded: boolean) => {
  const [hasDelegatedSubscriptions, setHasDelegatedSubscriptions] = useState(false);
  const [hasSubscriptions, setHasSubscriptions] = useState(false);

  // Subscriptions
  const isLoadingSubscriptions = useSelector(
    ({ customersReducer }: RootState) => customersReducer.isLoadingSubscriptions,
  );
  const isLoadingNonActiveSubscriptionsCount = useSelector(
    ({ customersReducer }: RootState) => customersReducer.isLoadingNonActiveSubscriptionsCount,
  );
  const { totalCount: activeSubscriptionsCount } = useSelector(
    ({ customersReducer }: RootState) => customersReducer.customerSubscriptions,
  );
  const nonActiveSubscriptionsCount =
    useSelector(({ customersReducer }: RootState) => customersReducer.nonActiveSubscriptionsCount) || 0;

  // Delegated subscriptions
  const isLoadingDelegatedSubscriptions = useSelector(
    ({ customersReducer }: RootState) => customersReducer.isLoadingDelegatedSubscriptions,
  );
  const isLoadingNonActiveDelegatedSubscriptionsCount = useSelector(
    ({ customersReducer }: RootState) => customersReducer.isLoadingNonActiveDelegatedSubscriptionsCount,
  );
  const { totalCount: activeDelegatedSubscriptionsCount } = useSelector(
    ({ customersReducer }: RootState) => customersReducer.customerDelegatedSubscriptions,
  );
  const nonActiveDelegatedSubscriptionsCount =
    useSelector(({ customersReducer }: RootState) => customersReducer.nonActiveDelegatedSubscriptionsCount) || 0;

  const isSubscriptionsLoaded = !isLoadingSubscriptions && !isLoadingNonActiveSubscriptionsCount;
  const isDelegatedSubscriptionsLoaded =
    !isLoadingDelegatedSubscriptions && !isLoadingNonActiveDelegatedSubscriptionsCount;

  useEffect(() => {
    if (isLoaded && isSubscriptionsLoaded && isDelegatedSubscriptionsLoaded) {
      const hasSubscriptionsUpdate = (activeSubscriptionsCount || 0) + (nonActiveSubscriptionsCount || 0) > 0;
      if (hasSubscriptions !== hasSubscriptionsUpdate) {
        setHasSubscriptions(hasSubscriptionsUpdate);
      }

      const hasDelegatedSubscriptionsUpdate =
        (activeDelegatedSubscriptionsCount || 0) + (nonActiveDelegatedSubscriptionsCount || 0) > 0;
      if (hasDelegatedSubscriptions !== hasDelegatedSubscriptionsUpdate) {
        setHasDelegatedSubscriptions(hasDelegatedSubscriptionsUpdate);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return { hasSubscriptions, hasDelegatedSubscriptions };
};
