import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useConfirmationDialog } from '@stigg-common';
import { MinimumSpendFragment, PlanFragment } from '@stigg-types/apiTypes';
import {
  GridFlex,
  EmptyCardContent,
  FormRenderProps,
  Icon,
  Link,
  Text,
  Card,
  Grid,
  OptionsDropdown,
  InformationalTooltipIcon,
  ExternalLink,
} from '@stigg-components';
import { Edit2, Trash2 } from 'react-feather';
import { SectionIdentifier } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from '../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import { formatPrice } from '../../pricing/utils/priceFormatUtils';
import { updatePlanAction } from '../plansSlice';
import { useAppDispatch } from '../../../../redux/store';

type PlanMinimumSpendProps = {
  plan: PlanFragment;
  readonly: boolean;
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void;
};

export const getFormattedPlanMinimum = (minimum: MinimumSpendFragment) => {
  const price = formatPrice(minimum.minimum, minimum.billingPeriod, { shortFormat: true });
  const period = t(`pricing.shortBillingPeriodPriceDescription.${minimum.billingPeriod}`);
  return `${price} / ${period}`;
};

function PlanMinimumSpendEmpty({ readonly, onAddClick }: { readonly: boolean; onAddClick: () => void }) {
  return (
    <EmptyCardContent maxHeight={53} gap={2}>
      <Icon icon="Charge" color="default" overrideStroke />
      <Text.B2>{t('pricing.minimumSpend.noMinimumConfigured')}</Text.B2>
      {!readonly && (
        <Link variant="body2" data-testid="add-minimum-spend-link" onClick={onAddClick}>
          {t('pricing.minimumSpend.addMinimumSpend')}
        </Link>
      )}
    </EmptyCardContent>
  );
}

function PlanMinimumSpendCard({
  minimums,
  readonly,
  onEditClick,
  onDeleteClick,
}: {
  minimums: MinimumSpendFragment[];
  readonly: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
}) {
  // make sure monthly comes before annually
  const sortedMinimums = [...minimums].sort((a, b) => b.billingPeriod.localeCompare(a.billingPeriod));

  return (
    <Card p={4}>
      <GridFlex.RowCenter gap={2} position="relative">
        <Icon icon="MinimumSpend" color="default" overrideStroke overrideFill />
        <GridFlex.RowCenter gap={16}>
          {sortedMinimums.map((minimum) => (
            <GridFlex.RowCenter gap={2}>
              <Text.B2>{t(`pricing.billingPeriodDescriptions.${minimum.billingPeriod}`)}</Text.B2>
              <Text.B2 $bold>{getFormattedPlanMinimum(minimum)}</Text.B2>
            </GridFlex.RowCenter>
          ))}
        </GridFlex.RowCenter>
        {!readonly && (
          <Grid item position="absolute" right={0}>
            <OptionsDropdown
              options={[
                {
                  icon: Edit2,
                  text: t('sharedComponents.edit'),
                  onClick: onEditClick,
                },
                {
                  icon: Trash2,
                  text: t('sharedComponents.remove'),
                  onClick: onDeleteClick,
                },
              ]}
            />
          </Grid>
        )}
      </GridFlex.RowCenter>
    </Card>
  );
}

function MinimumSpendTooltip() {
  return (
    <GridFlex.Column>
      <Text.B2 mb={1}>{t('pricing.minimumSpend.tooltipText')}</Text.B2>
      <ExternalLink label={t('sharedComponents.learnMore')} url={t('pricing.minimumSpend.tooltipLearnMoreUrl')} />
    </GridFlex.Column>
  );
}

export function PlanMinimumSpend({ plan, readonly, openSetPriceWizard }: PlanMinimumSpendProps) {
  const dispatch = useAppDispatch();

  const setMinimumSpendEnabled = ({ setFieldValue }: FormRenderProps<SetPriceWizardFormFields>) =>
    setFieldValue('minimumSpend.enabled', true);

  const onAddClick = () => openSetPriceWizard({ stepId: 'minimumSpend' }, setMinimumSpendEnabled);

  const [DeleteMinimumSpendDialog, setShowDeleteMinimumSpendDialog, deleteMinimumSpendDialogProps] =
    useConfirmationDialog({
      title: t('pricing.minimumSpend.deleteDialogTitle'),
      content: t('pricing.minimumSpend.deleteDialogContent'),
      handleConfirm: async () => {
        await dispatch(
          updatePlanAction({
            planRefId: plan.refId,
            updatePayload: { minimumSpend: null, id: plan.id },
            silentFetch: true,
          }),
        );
      },
    });

  const onDeleteClick = () => setShowDeleteMinimumSpendDialog(true);

  const hasMinimumSpend = !isEmpty(plan.minimumSpend);

  return (
    <>
      <DeleteMinimumSpendDialog {...deleteMinimumSpendDialogProps} />
      <GridFlex.Column mt={8} mb={8}>
        <GridFlex.RowCenter gap={2} mb={4}>
          <Text.H6>{t('pricing.minimumSpend.title')}</Text.H6>
          <InformationalTooltipIcon
            $padding={3}
            $maxWidth={200}
            icon={<Icon type="custom" icon="HelpInfo" color="default" overrideFill />}
            text={<MinimumSpendTooltip />}
          />
        </GridFlex.RowCenter>
        {plan.minimumSpend && hasMinimumSpend ? (
          <PlanMinimumSpendCard
            minimums={plan.minimumSpend}
            readonly={readonly}
            onEditClick={onAddClick}
            onDeleteClick={onDeleteClick}
          />
        ) : (
          <PlanMinimumSpendEmpty readonly={readonly} onAddClick={onAddClick} />
        )}
      </GridFlex.Column>
    </>
  );
}
