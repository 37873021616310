import { t } from 'i18next';
import { Restore } from '@mui/icons-material';
import { Alert, GridFlex, Link } from '@stigg-components';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { useNavigation } from '../../navigation/useNavigation';

export default function OutdatedVersionBanner({ aPackage }: { aPackage: PlanFragment | AddonFragment }) {
  const navigation = useNavigation();
  const navigateToPackageVersion = (versionNumber?: number) => {
    navigation.navigateTo(
      aPackage.type === 'Plan'
        ? navigation.appRoutes.planPage(aPackage.refId, versionNumber)
        : navigation.appRoutes.addonPage(aPackage.refId, versionNumber),
    );
  };

  return (
    <GridFlex.Column $fullWidth>
      <Alert severity="warning" icon={<Restore />}>
        {t('packages.viewOutdatedPackage')}
        <Link ml={1} sx={{ color: 'inherit', textDecoration: 'underline' }} onClick={() => navigateToPackageVersion()}>
          {t('packages.viewLatestVersion')}
        </Link>
      </Alert>
    </GridFlex.Column>
  );
}
