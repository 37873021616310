import { t } from 'i18next';
import { EntitlementResetPeriod, ResetPeriodConfiguration } from '@stigg-types/apiTypes';

export function getUsageTitle(resetPeriod?: EntitlementResetPeriod | null) {
  let days = 30;
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return t('customers.usageGraphSubtitleYearly');
    case EntitlementResetPeriod.Month:
    case EntitlementResetPeriod.Week:
      days = 30;
      break;
    case EntitlementResetPeriod.Day:
      days = 7;
      break;
    case EntitlementResetPeriod.Hour:
      days = 7;
      break;
    default:
      days = 30;
      break;
  }

  return t('customers.usageGraphSubtitle', { days });
}

export function getResetPeriodConfig(resetPeriodConfiguration?: ResetPeriodConfiguration | null) {
  switch (resetPeriodConfiguration?.__typename) {
    case 'YearlyResetPeriodConfig':
      return resetPeriodConfiguration.yearlyAccordingTo;
    case 'MonthlyResetPeriodConfig':
      return resetPeriodConfiguration.monthlyAccordingTo;
    case 'WeeklyResetPeriodConfig':
      return resetPeriodConfiguration.weeklyAccordingTo;
    default:
      return null;
  }
}
