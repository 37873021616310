import { EntitlementResetPeriod } from '@stigg-types/apiTypes';
import { LongText, MenuItem, Select, Text } from '@stigg-components';
import map from 'lodash/map';
import { t } from 'i18next';
import { ResetPeriodConfiguration as ResetPeriodConfigurationType } from '@stigg-common/types';
import { getAccordingToOptions } from './EntitlementsResetPeriods.utils';

type ResetPeriodConfigurationProps = {
  resetPeriodConfigurationId: string;
  value: ResetPeriodConfigurationType | null;
  onChange: (event: any) => void;
  resetPeriod: EntitlementResetPeriod;
  i18nPath: string;
};

function AccordingToSelect({
  resetPeriodConfigurationId,
  onChange,
  value,
  resetPeriod,
  i18nPath,
}: ResetPeriodConfigurationProps) {
  const accordingToOptions = getAccordingToOptions(resetPeriod, i18nPath);

  return (
    <Select
      fullWidth
      onChange={onChange}
      value={value}
      name={resetPeriodConfigurationId}
      renderValue={(value: any) => {
        // @ts-ignore
        const displayValue = accordingToOptions.find((v: any) => v.value === value)?.text;
        return <LongText color="primary">{displayValue}</LongText>;
      }}>
      {map(accordingToOptions, (resetPeriod: any) => (
        <MenuItem key={resetPeriod.value} value={resetPeriod.value} sx={{ whiteSpace: 'pre-wrap' }}>
          <Text.B2 color="primary">{resetPeriod.text}</Text.B2>
        </MenuItem>
      ))}
    </Select>
  );
}

export function ResetPeriodConfiguration({
  resetPeriodConfigurationId,
  onChange,
  value,
  resetPeriod,
  i18nPath,
}: ResetPeriodConfigurationProps) {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Day:
      return <Text.B2 $bold>{t('pricing.entitlements.resetPeriod.dailyAccordingToText')}</Text.B2>;

    case EntitlementResetPeriod.Hour:
      return <Text.B2 $bold>{t('pricing.entitlements.resetPeriod.hourlyAccordingToText')}</Text.B2>;

    case EntitlementResetPeriod.Year:
      return <Text.B2 $bold>{t('pricing.entitlements.resetPeriod.yearlyAccordingToSubscription')}</Text.B2>;

    case EntitlementResetPeriod.Month:
    case EntitlementResetPeriod.Week:
      return (
        <AccordingToSelect
          value={value}
          resetPeriod={resetPeriod}
          onChange={onChange}
          resetPeriodConfigurationId={resetPeriodConfigurationId}
          i18nPath={i18nPath}
        />
      );
    default:
      return null;
  }
}
