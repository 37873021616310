import { ExecuteOperationOptions, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { GetExperimentStatsQuery, GetExperimentStatsQueryVariables } from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';

const FETCH_EXPERIMENT_STATS_BY_REF_ID = gql`
  query GetExperimentStats($query: ExperimentStatsQuery!) {
    getExperimentStats(query: $query) {
      controlSubscriptions
      controlPaidSubscriptions
      variantSubscriptions
      variantPaidSubscriptions
    }
  }
`;

export type FetchExperimentsStatsProps = ExecuteOperationOptions & {
  refId: string;
};

export async function fetchExperimentStatsByRefId(
  { refId }: FetchExperimentsStatsProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const {
        accountReducer: { currentEnvironmentId },
      } = getState();
      const response = await apolloClient.query<GetExperimentStatsQuery, GetExperimentStatsQueryVariables>({
        query: FETCH_EXPERIMENT_STATS_BY_REF_ID,
        fetchPolicy: 'network-only',
        variables: {
          query: {
            experimentRefId: refId,
            environmentId: currentEnvironmentId,
          },
        },
      });

      return response.data.getExperimentStats;
    },
    {
      failureMessageHandler: () => t('experiments.api.failFetchStatsOne'),
    },
    dispatch,
  );
}
