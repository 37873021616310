import { t } from 'i18next';
import { Icon, InformationTooltip, Text, Box } from '@stigg-components';

export function ChargeOverrideIndication() {
  return (
    <InformationTooltip
      disableInteractive
      title={<Text.B2>{t('pricing.override.overrideIndicationTooltip')}</Text.B2>}
      arrow
      placement="top">
      <Box width={22} height={22}>
        <Icon type="custom" icon="Replace" />
      </Box>
    </InformationTooltip>
  );
}
