import React, { useCallback } from 'react';
import { t } from 'i18next';
import {
  AddonFragment,
  OveragePriceFragment,
  PlanEntitlementFragment,
  PlanFragment,
  PriceFragment,
} from '@stigg-types/apiTypes';
import {
  EmptyCardContent,
  Flex,
  FormRenderProps,
  HeadCell,
  Icon,
  Link,
  OptionsDropdown,
  Text,
} from '@stigg-components';
import { Edit2 } from 'react-feather';
import { Trans } from 'react-i18next';
import { SectionIdentifier } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from '../../../pricing/components/SetPriceWizard/form/SetPriceWizardForm.types';
import Table from '../../../../../components/table/Table';
import { DEFAULT_COUNTRY_CODE } from '../../../pricing/components/currency/currencyUtils';
import { getPriceGroupId, toPriceGroups } from '../../../pricing/utils/priceGroups';
import { Charge } from '../../../common/components/pricingTypeViews/PaidPricingView';
import { getChargeQuantityText } from '../../../pricing/components/SetPriceWizard/form/chargesStep/overagePrice/OverageEntitlementComponent';
import { getPriceFormat } from '../../../pricing/utils/priceFormatUtils';
import { getConnectedEntityByFeatureId } from '../../../common/overageCharges.utils';

interface OverageChargeTextProps {
  overagePrice: OveragePriceFragment;
  price: Pick<PriceFragment, 'minUnitQuantity' | 'maxUnitQuantity'>;
}

const OverageChargeText = ({ overagePrice, price }: OverageChargeTextProps) => {
  const { count, countString } = getChargeQuantityText(price);
  return (
    <Text.B2>
      <Trans i18nKey="pricing.planOveragesPricing.table.chargePrice" values={{ count, countString }} />
      <Trans
        i18nKey="pricing.planOveragesPricing.table.overagePriceText"
        values={{
          count: overagePrice.blockSize || 1,
          price: getPriceFormat(overagePrice, { shortFormat: true }),
          blockSize: overagePrice.blockSize,
        }}
      />
    </Text.B2>
  );
};

interface OverageEntitlementTextProps {
  overagePrice: OveragePriceFragment;
  entitlement: Pick<PlanEntitlementFragment, 'usageLimit'>;
}

const OverageEntitlementText = ({ overagePrice, entitlement }: OverageEntitlementTextProps) => (
  <Text.B2>
    <Trans
      i18nKey="pricing.planOveragesPricing.table.entitlementPriceText"
      values={{ count: entitlement.usageLimit }}
    />
    <Trans
      i18nKey="pricing.planOveragesPricing.table.overagePriceText"
      values={{
        count: overagePrice.blockSize || 1,
        price: getPriceFormat(overagePrice, { shortFormat: true }),
        blockSize: overagePrice.blockSize,
      }}
    />
  </Text.B2>
);

interface OveragePriceTableItem {
  overagePrices: OveragePriceFragment[];
  price?: Pick<PriceFragment, 'minUnitQuantity' | 'maxUnitQuantity'>;
  entitlement?: Pick<PlanEntitlementFragment, 'usageLimit'>;
}

const headCells = ({
  readonly,
  onEditOverageCharge,
}: {
  readonly: boolean;
  onEditOverageCharge: (priceGroupId: string) => void;
}): Array<HeadCell<OveragePriceTableItem, any>> => [
  {
    id: 'feature',
    alignment: 'left',
    width: '25%',
    label: t('pricing.planOveragesPricing.table.featureHeader'),
    render: ({ overagePrices }: OveragePriceTableItem) => <Charge priceGroup={overagePrices} />,
  },
  {
    id: 'price',
    alignment: 'left',
    label: t('pricing.planOveragesPricing.table.priceHeader'),
    // Currently we only support having one overage price - so we always use the first
    render: ({ overagePrices, price, entitlement }: OveragePriceTableItem) => {
      const overagePrice = overagePrices[0];
      if (price) {
        return <OverageChargeText overagePrice={overagePrice} price={price} />;
      }

      if (entitlement) {
        return <OverageEntitlementText overagePrice={overagePrice} entitlement={entitlement} />;
      }

      return null;
    },
  },
  {
    id: 'options',
    alignment: 'center',
    label: '',
    width: 36,
    disablePadding: true,
    visible: !readonly,
    render: ({ overagePrices }: OveragePriceTableItem) => {
      const options = [
        {
          icon: Edit2,
          text: t('pricing.planOveragesPricing.table.editLabel'),
          onClick: () => onEditOverageCharge(getPriceGroupId(overagePrices)),
        },
      ];

      return <OptionsDropdown options={options} />;
    },
  },
];

interface PlanOveragesPricingProps {
  plan: PlanFragment | AddonFragment;
  readonly: boolean;
  openSetPriceWizard: (
    section?: SectionIdentifier,
    modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
  ) => void;
  billingCountryCode: string;
}

export const PlanOveragesPricing = ({
  plan,
  readonly,
  openSetPriceWizard,
  billingCountryCode,
}: PlanOveragesPricingProps) => {
  const onEditOverageCharge = useCallback(
    (priceGroupId: string) => {
      const section: SectionIdentifier =
        billingCountryCode && billingCountryCode !== DEFAULT_COUNTRY_CODE
          ? { stepId: 'priceLocalization', billingCountryCode }
          : { stepId: 'overageCharges', priceGroupId };

      openSetPriceWizard(section);
    },
    [billingCountryCode, openSetPriceWizard],
  );

  const overagePriceGroups = toPriceGroups(plan.overagePrices);
  const tableItems: OveragePriceTableItem[] = overagePriceGroups.map((overagePriceGroup) => {
    const overagePriceFeatureId = overagePriceGroup[0].featureId!;
    const price = getConnectedEntityByFeatureId(overagePriceFeatureId, plan.prices);
    const entitlement = getConnectedEntityByFeatureId(overagePriceFeatureId, plan.entitlements);

    return {
      overagePrices: overagePriceGroup,
      price,
      entitlement,
    };
  });

  const hasOverageCharges = !!tableItems.length;

  const overageBillingPeriodText = t(`pricing.planOveragesPricing.overagesBillingPeriods.${plan.overageBillingPeriod}`);

  return (
    <Flex.Column mt={8} mb={8}>
      <Text.H6 mb={4}>{t('pricing.planOveragesPricing.title')}</Text.H6>

      {hasOverageCharges ? (
        <>
          <Text.B2 mb={4}>
            {t('pricing.planOveragesPricing.overagesBillingPeriodCharge', { overageBillingPeriodText })}
          </Text.B2>

          <Table
            label={t('pricing.planOveragesPricing.tableLabel')}
            headCells={headCells({
              readonly,
              onEditOverageCharge,
            })}
            data={tableItems}
          />
        </>
      ) : (
        <EmptyCardContent maxHeight={53} gap={2}>
          <Icon icon="OverageCharge" color="default" overrideStroke />
          <Text.B2>{t('pricing.overageCharges.noChargesPlanPage')}</Text.B2>
          {!readonly && (
            <Link
              variant="body2"
              data-testid="add-overage-charges-link"
              onClick={() => openSetPriceWizard({ stepId: 'overageCharges' })}>
              {t('pricing.overageCharges.addOverageCharge')}
            </Link>
          )}
        </EmptyCardContent>
      )}
    </Flex.Column>
  );
};
