import { gql } from '@apollo/client';
import {
  ArchiveCouponInput,
  ArchiveOneCouponMutation,
  ArchiveOneCouponMutationVariables,
  CouponFragment,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { fetchCouponByRefIdAction } from '../couponsSlice';
import { COUPON_FRAGMENT } from '../queries/fetchCoupons';

const ARCHIVE_COUPON = gql`
  mutation ArchiveOneCouponMutation($input: ArchiveCouponInput!) {
    archiveOneCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${COUPON_FRAGMENT}
`;

async function archiveCoupon(
  couponRefId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<CouponFragment> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const coupon: ArchiveCouponInput = {
        refId: couponRefId,
        environmentId: accountReducer.currentEnvironmentId,
      };

      const response = await apolloClient.mutate<ArchiveOneCouponMutation, ArchiveOneCouponMutationVariables>({
        mutation: ARCHIVE_COUPON,
        variables: { input: coupon },
      });

      const archivedCoupon = response.data?.archiveOneCoupon;
      await dispatch(
        fetchCouponByRefIdAction({ environmentId: accountReducer.currentEnvironmentId, refId: coupon.refId }),
      );
      return archivedCoupon;
    },
    {
      successMessage: t('coupons.api.successArchive'),
      failureMessageHandler: () => t('coupons.api.failArchive'),
    },
    dispatch,
  );
}

export { archiveCoupon };
