import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { CubeProvider } from '@cubejs-client/react';
import ProtectedRoute from '../auth/components/ProtectedRoute';
import { SubscriptionsOverviewPage } from '../dashboards/subscriptionsOverview/components/SubscriptionsOverviewPage';
import { FeatureInspector } from '../dashboards/featureInspector/components/FeatureInspector';
import { useCubejsApi } from '../dashboards/common/useCubejsApi';
import Loader from '../../components/Loader';

export function DashboardsRoutes() {
  const { path } = useRouteMatch();
  const cubejsApi = useCubejsApi();

  if (!cubejsApi) {
    return <Loader />;
  }

  // TODO The data of the pages under the “Dashboard” section should be rendered using static data for isSandbox
  // TODO environments; that is, not by querying Cube.js

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Switch>
        <ProtectedRoute exact path={`${path}/subscriptions`}>
          <SubscriptionsOverviewPage />
        </ProtectedRoute>
        <ProtectedRoute exact path={`${path}/feature-inspector`}>
          <FeatureInspector />
        </ProtectedRoute>
      </Switch>
    </CubeProvider>
  );
}
