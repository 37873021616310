import { t } from 'i18next';
import React from 'react';
import { ConfirmationDialogProps } from '@stigg-components/types';
import { useConfirmationDialog } from '@stigg-common';
import { Box, ConfirmationDialog, Grid, GridFlex, Text } from '@stigg-components';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import styled from 'styled-components/macro';
import { cancelSubscriptionUpdatesAction } from '../../../customersSlice';
import { useAppDispatch } from '../../../../../redux/store';
import { SubscriptionFutureUpdateRow } from '../subscriptionInfo/components/subscriptionUpdates/SubscriptionFutureUpdateRow';

export const StyledContainer = styled(Box)<{ $alertBackground?: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  border: ${({ theme }) => theme.itamar.border.border};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  color: ${({ theme }) => theme.itamar.palette.text.primary};
  background-color: ${({ theme }) => theme.itamar.palette.background.emptyState};
`;

type UseConfirmationResult = [typeof ConfirmationDialog, (isOpen: boolean) => void, ConfirmationDialogProps];

type UseCancelFutureUpdatesDialogProps = {
  subscription?: SubscriptionDataFragment | null;
  futureUpdateStatus: ActiveFutureUpdateStatus;
};

export function useCancelFutureUpdatesDialog({
  subscription,
  futureUpdateStatus,
}: UseCancelFutureUpdatesDialogProps): UseConfirmationResult {
  const dispatch = useAppDispatch();
  const futureUpdates = (subscription?.futureUpdates || []).filter(
    ({ scheduleStatus }) => scheduleStatus === futureUpdateStatus,
  );

  const content = subscription ? (
    <GridFlex.Column container>
      <Grid>
        <Text.B2>{t('subscriptions.schedules.cancelDialogSubtitle')}</Text.B2>
      </Grid>
      {futureUpdates?.length ? (
        <StyledContainer mt={4}>
          {futureUpdates.map((scheduledUpdate, index) => (
            <SubscriptionFutureUpdateRow key={index} futureUpdate={scheduledUpdate} subscription={subscription} />
          ))}
        </StyledContainer>
      ) : null}
    </GridFlex.Column>
  ) : (
    <Grid />
  );
  return useConfirmationDialog({
    width: 600,
    title: t('subscriptions.schedules.cancelUpdate'),
    content,
    confirmButtonColor: 'error',
    confirmButtonText: t('subscriptions.schedules.cancelUpdatesCta'),
    cancelButtonText: t('subscriptions.schedules.dismiss'),
    isButtonLoading: true,
    handleConfirm: async () => {
      await dispatch(
        cancelSubscriptionUpdatesAction({
          subscriptionRefId: subscription?.subscriptionId || '',
          customerId: subscription?.customer?.id || '',
          futureUpdateStatus,
        }),
      );
    },
  });
}
