import { COLORS } from '../colors';
import { ItamarPalette, CustomColor, ItamarBorder } from './itamar.types';

const grey: CustomColor = {
  10: COLORS['grey.10'],
  25: COLORS['grey.25'],
  50: COLORS['grey.50'],
  80: COLORS['grey.80'],
  100: COLORS['grey.100'],
  200: COLORS['grey.200'],
  300: COLORS['grey.300'],
  400: COLORS['grey.400'],
  500: COLORS['grey.500'],
  600: COLORS['grey.600'],
  700: COLORS['grey.700'],
  800: COLORS['grey.800'],
  900: COLORS['grey.900'],
};

export const palette: ItamarPalette = {
  white: COLORS.white,
  black: COLORS.black,
  text: {
    white: COLORS.white,
    primary: COLORS['text.primary'],
    secondary: COLORS['text.secondary'],
    tertiary: COLORS['text.tertiary'],
    disabled: COLORS['text.disabled'],
  },
  primary: {
    main: COLORS['primary.main'],
    dark: COLORS['primary.dark'],
    light: COLORS['primary.light'],
    contrastText: COLORS['primary.contrastText'],
    outlinedRestingBackground: COLORS['primary.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['primary.outlinedHoverBackground'],
    containedHoverBackground: COLORS['primary.containedHoverBackground'],
  },
  secondary: {
    main: COLORS['secondary.main'],
    dark: COLORS['secondary.dark'],
    light: COLORS['secondary.light'],
    contrastText: COLORS['secondary.contrastText'],
    outlinedRestingBackground: COLORS['secondary.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['secondary.outlinedHoverBackground'],
    containedHoverBackground: COLORS['secondary.containedHoverBackground'],
  },
  action: {
    active: COLORS['action.active'],
    hover: COLORS['action.hover'],
    selected: COLORS['action.selected'],
    disabled: COLORS['action.disabled'],
    disabledBackground: COLORS['action.disabledBackground'],
    focus: COLORS['action.focus'],
  },
  error: {
    main: COLORS['error.main'],
    dark: COLORS['error.dark'],
    light: COLORS['error.light'],
    contrastText: COLORS['error.contrastText'],
    outlinedRestingBackground: COLORS['error.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['error.outlinedHoverBackground'],
    containedHoverBackground: COLORS['error.containedHoverBackground'],
    content: COLORS['error.content'],
    background: COLORS['error.background'],
  },
  warning: {
    main: COLORS['warning.main'],
    dark: COLORS['warning.dark'],
    light: COLORS['warning.light'],
    contrastText: COLORS['warning.contrastText'],
    outlinedRestingBackground: COLORS['warning.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['warning.outlinedHoverBackground'],
    containedHoverBackground: COLORS['warning.containedHoverBackground'],
    content: COLORS['warning.content'],
    background: COLORS['warning.background'],
  },
  info: {
    main: COLORS['info.main'],
    dark: COLORS['info.dark'],
    light: COLORS['info.light'],
    contrastText: COLORS['info.contrastText'],
    outlinedRestingBackground: COLORS['info.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['info.outlinedHoverBackground'],
    containedHoverBackground: COLORS['info.containedHoverBackground'],
    content: COLORS['info.content'],
    background: COLORS['info.background'],
  },
  success: {
    main: COLORS['success.main'],
    dark: COLORS['success.dark'],
    light: COLORS['success.light'],
    contrastText: COLORS['success.contrastText'],
    outlinedRestingBackground: COLORS['success.outlinedRestingBackground'],
    outlinedHoverBackground: COLORS['success.outlinedHoverBackground'],
    containedHoverBackground: COLORS['success.containedHoverBackground'],
    content: COLORS['success.content'],
    background: COLORS['success.background'],
  },
  background: {
    paper: COLORS['background.paper'],
    default: COLORS['background.default'],
    lightBackground: COLORS['background.lightBackground'],
    lightBackground2: COLORS['background.lightBackground2'],
    darkBackground: COLORS['background.darkBackground'],
    darkBackground2: COLORS['background.darkBackground2'],
    darkBackground3: COLORS['background.darkBackground3'],
    darkBackground4: COLORS['background.darkBackground4'],
    darkBackground5: COLORS['background.darkBackground5'],
    fadedBackground: COLORS['background.faded'],
    emptyState: COLORS['background.emptyState'],
    runningFlow: COLORS['background.runningFlow'],
    sandboxEnvironment: COLORS['background.sandboxEnvironment'],
  },
  border: {
    primary: COLORS['border.primary'],
  },
  other: {
    outlineBorder: COLORS['other.outlineBorder'],
    outlineBorderLight: COLORS['other.outlineBorderLight'],
    backdropOverlay: COLORS['other.backdropOverlay'],
    filledInputBackground: COLORS['other.filledInputBackground'],
    standardInputLine: COLORS['other.standardInputLine'],
    snackbarBackground: COLORS['other.snackbarBackground'],
    ratingActive: COLORS['other.ratingActive'],
    divider: COLORS['other.divider'],
    focusBorder: COLORS['other.focusBorder'],
    containedBackground: COLORS['other.containedBackground'],
    boldDivider: COLORS['other.boldDivider'],
  },
  shadow: {
    outlined: COLORS['shadow.outlined'],
    lightShadow: COLORS['shadow.lightShadow'],
    popover: COLORS['shadow.popover'],
    inset: COLORS['shadow.inset'],
    paper: COLORS['shadow.paper'],
  },
  iconDefault: COLORS.iconDefault,
  sidebarText: COLORS.sidebarText,
  expiredColor: COLORS.expiredColor,
  grey,
  integrations: {
    stripe: COLORS.stripe,
    slack: COLORS.slack,
    hubspot: COLORS.hubspot,
    awsMarketplace: COLORS.awsMarketplace,
  },
  environmentSwitch: {
    background: COLORS['background.faded'],
    selectedBackground: COLORS['background.darkHover'],
    hoverBackground: COLORS['background.darkHover'],
    border: COLORS['other.fadedBorder'],
  },
};

export const border: ItamarBorder = {
  border: '1px solid',
  radius: '10px',
  borderColor: palette.shadow.outlined,
};
