import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  SortDirection,
  UsageMeasurementDataFragment,
  UsageMeasurementSortFields,
  UsageMeasurementsQuery,
  UsageMeasurementsQueryVariables,
} from '@stigg-types/apiTypes';
import { AppDispatch, RootState } from '../../../redux/store';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const FETCH_USAGE_MEASUREMENTS_BY_FEATURE_ID = gql`
  query UsageMeasurements($filter: UsageMeasurementFilter, $sorting: [UsageMeasurementSort!]) {
    usageMeasurements(filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...UsageMeasurementData
        }
      }
    }
  }
  fragment UsageMeasurementData on UsageMeasurement {
    createdAt
    value
  }
`;

export type FetchUsageMeasurementsByFeatureIdProps = {
  featureRefId: string;
  customerRefId: string;
};

async function fetchUsageMeasurementsByFeatureRefId(
  { featureRefId, customerRefId }: FetchUsageMeasurementsByFeatureIdProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<UsageMeasurementsQuery, UsageMeasurementsQueryVariables>({
        query: FETCH_USAGE_MEASUREMENTS_BY_FEATURE_ID,
        variables: {
          filter: {
            and: [
              { feature: { refId: { eq: featureRefId } } },
              { customer: { refId: { eq: customerRefId } } },
              { environmentId: { eq: accountReducer.currentEnvironmentId } },
            ],
          },
          sorting: { field: UsageMeasurementSortFields.CreatedAt, direction: SortDirection.Asc },
        },
      });
      return res.data.usageMeasurements.edges.map(
        (usageMeasurement) => usageMeasurement.node as UsageMeasurementDataFragment,
      );
    },
    { failureMessageHandler: () => t('customers.api.failUsageMeasurementsFetch') },
    dispatch,
  );
}

export { fetchUsageMeasurementsByFeatureRefId };
