import { useExitConfirmationDialog } from '@stigg-components';
import { useState } from 'react';
import {
  CustomerResponseFragment,
  CustomerSubscriptionDataFragment,
  PlanFragment,
  PlanListFragment,
} from '@stigg-types/apiTypes';
import { Dialog } from '../../../../../../components/Dialog';
import { SubscriptionForm } from './SubscriptionForm';
import { SubscriptionFormFields } from './SubscriptionForm.types';
import { handleCreateSubscription, handleUpdateSubscription } from './subscriptionActions';
import { DispatchResponse, useAppDispatch } from '../../../../../../redux/store';

export type SubscriptionWizardProps = {
  isOpen: boolean;
  onClose: () => void;
  postSubmit: () => Promise<void>;
  customer: CustomerResponseFragment | null | undefined;
  subscription: CustomerSubscriptionDataFragment | null | undefined;
  switchToCreateMode: () => void;
  ariaLabelledBy: string;
};

export function SubscriptionWizard({
  isOpen,
  onClose,
  postSubmit,
  customer,
  subscription,
  switchToCreateMode,
  ariaLabelledBy,
}: SubscriptionWizardProps) {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { exitConfirmationDialog, onExit, setHasUnsavedChanges } = useExitConfirmationDialog({
    onClose,
    shouldConfirm: isOpen && !isSubmitting,
  });

  const handleSubmit = async (
    values: SubscriptionFormFields,
    plan?: PlanListFragment | PlanFragment,
    isAllowEmptyResourceIdEnabled?: boolean,
  ) => {
    if (!customer) {
      return;
    }

    setIsSubmitting(true);
    try {
      const res: DispatchResponse = subscription
        ? await handleUpdateSubscription(subscription, values, dispatch)
        : await handleCreateSubscription(customer.customerId, dispatch, values, plan, isAllowEmptyResourceIdEnabled);

      const hadError = res?.meta.requestStatus === 'rejected';
      if (hadError) {
        // error occurred, don't close the modal
        return;
      }

      await postSubmit();
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  const form =
    isOpen && customer ? (
      <SubscriptionForm
        customer={customer}
        onCancel={onExit}
        handleSubmit={handleSubmit}
        subscription={subscription}
        switchToCreateMode={switchToCreateMode}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
    ) : null;

  return (
    <Dialog
      fullScreen
      padding={0}
      content={
        <>
          {form}
          {exitConfirmationDialog}
        </>
      }
      isOpen={isOpen}
      onCancel={onExit}
      aria-labelledby={ariaLabelledBy}
    />
  );
}
