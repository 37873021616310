import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useBreadcrumbs, useConfirmationDialog } from '@stigg-common';
import { ConfirmationDialog, GridFlex, Text } from '@stigg-components';
import { CustomerSubscriptionResourceFragment, SubscriptionStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import head from 'lodash/head';
import { EntityMetadataCard } from '../../../../components/entityMetadata/EntityMetadataCard';
import Tabs from '../../../../components/tabs/Tabs';
import Loader from '../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../redux/store';
import {
  archiveCustomerByIdAction,
  fetchActiveSubscriptionsAction,
  fetchCustomerByRefIdAction,
  fetchCustomerSubscriptionsAction,
  fetchCustomerSubscriptionsResourcesAction,
  fetchEntitlementsByRefIdAction,
  resetCustomer,
  resetEntitlements,
  updateCustomerAction,
} from '../../customersSlice';
import { CustomerDetails } from './customerDetails/CustomerDetails';
import { ApplyCouponDialog } from './customerDiscountsAndPromotional/customerDiscounts/ApplyCouponDialog';
import { ApplyCouponFormFields } from './customerDiscountsAndPromotional/customerDiscounts/ApplyCouponForm';
import { PromotionalEntitlements } from './customerDiscountsAndPromotional/customerPromotionalEntitlements/PromotionalEntitlements';
import { CustomerEntitlementsSummary } from './customerEntitlementSummary/CustomerEntitlementsSummary';
import { CustomerEntitlementsUsage } from './customerEntitlementsUsage/CustomerEntitlementsUsage';
import { CustomerSubscriptionsCard } from './customerSubscriptions/CustomerSubscriptionsCard';
import { CustomerUpdateDialog } from './CustomerUpdateDialog';
import { UpdateCustomerFormFields } from './CustomerUpdateForm';
import { findSubscriptionsPlansOfStatus } from './customerEntitlementSummary/components/entitlementSummary.utils';
import EventLog from '../../../eventsLog/components/EventLog';
import CustomerHeader from './CustomerHeader';
import { CustomerPageTabs } from '../../../navigation/tabs';

type ViewCustomerRouteParams = {
  refId: string;
};

function CustomerPage() {
  const { eventLogInEntityContext: showEventLog } = useFlags<FeatureFlags>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [applyCouponOpen, setApplyCouponOpen] = useState(false);

  const { refId } = useParams<ViewCustomerRouteParams>();
  const dispatch = useAppDispatch();
  const customer = useSelector((root: RootState) => root.customersReducer.customer);
  const entitlements = useSelector((root: RootState) => root.customersReducer.entitlements);
  const activeSubscriptions = useSelector((root: RootState) => root.customersReducer.activeSubscriptions);
  const subscriptions = useSelector((state: RootState) => state.customersReducer.customerSubscriptions?.edges)?.map(
    (x) => x.node,
  );

  const isLoadingCustomers = useSelector((root: RootState) => root.customersReducer.isLoading);
  const isLoadingEntitlements = useSelector((root: RootState) => root.customersReducer.isLoadingEntitlements);
  const entitlementsLastUpdated = useSelector((root: RootState) => root.customersReducer.entitlementsLastUpdated);
  const isLoadingActiveSubscriptions = useSelector(
    (root: RootState) => root.customersReducer.isLoadingActiveSubscriptions,
  );
  const isLoadingCustomerSubscriptions = useSelector((root: RootState) => root.customersReducer.isLoadingSubscriptions);
  const hasSubscriptions = !isEmpty(subscriptions);

  const isLoadingResources = useSelector(
    (root: RootState) => root.customersReducer.isLoadingCustomerSubscriptionsResources,
  );
  const firstResource = useSelector((state: RootState) =>
    head(state.customersReducer.customerSubscriptionsResources?.edges?.map((x) => x.node)),
  );
  const [selectedResource, setSelectedResource] = useState<CustomerSubscriptionResourceFragment>();

  const [RemoveCouponDialog, setRemoveCouponDialogOpen, removeCouponDialogProps] = useConfirmationDialog({
    title: t('customers.deleteCouponDialogTitle'),
    content: (
      <>
        <Text.B2>{t('customers.deleteCouponDialogContent1')}</Text.B2>
        <Text.B2 mt={2}>{t('customers.deleteCouponDialogContent2')}</Text.B2>
      </>
    ),
    handleConfirm: async () => {
      if (customer?.customerId) {
        await dispatch(updateCustomerAction({ customerId: customer.customerId, couponRefId: null }));
      }
    },
    confirmButtonText: t('sharedComponents.remove'),
    confirmButtonColor: 'error',
  });

  useEffect(() => {
    if (!isEmpty(refId)) {
      void dispatch(fetchCustomerByRefIdAction({ customerId: refId }));
    }

    return () => {
      dispatch(resetCustomer());
    };
  }, [dispatch, refId]);

  useEffect(() => {
    const customerId = customer?.id;
    if (customerId) {
      void dispatch(fetchCustomerSubscriptionsAction({ customerId }));
      void dispatch(fetchCustomerSubscriptionsResourcesAction({ customerId }));
    }
  }, [dispatch, customer?.id]);

  useEffect(() => {
    if (!selectedResource && firstResource) {
      setSelectedResource(firstResource);
    }
    if (!firstResource) {
      setSelectedResource(undefined);
    }
  }, [selectedResource, firstResource]);

  useEffect(() => {
    if (refId && selectedResource) {
      void dispatch(
        fetchActiveSubscriptionsAction({ customerId: refId, resourceId: selectedResource.resource?.resourceId }),
      );
    }
  }, [selectedResource, refId, dispatch]);

  const dispatchFetchEntitlements = useCallback(() => {
    if (hasSubscriptions && selectedResource) {
      const resourceRefId = selectedResource.resource?.resourceId;
      void dispatch(fetchEntitlementsByRefIdAction({ customerRefId: refId, resourceRefId }));
    }
  }, [dispatch, refId, hasSubscriptions, selectedResource]);

  useEffect(() => {
    if (!isLoadingCustomerSubscriptions) {
      dispatchFetchEntitlements();
    }

    return () => {
      dispatch(resetEntitlements());
    };
  }, [dispatch, isLoadingCustomerSubscriptions, dispatchFetchEntitlements]);

  useBreadcrumbs({ to: '/customers', title: t('breadCrumbs.customers') }, { title: refId });

  const onEditClick = () => {
    setEditDialogOpen(true);
  };
  const onDeleteClick = () => {
    setDeleteDialogOpen(true);
  };
  const isLoadingCustomer = !customer || customer.customerId !== refId;
  if (isLoadingCustomer || isLoadingCustomers || (!selectedResource && isLoadingResources)) {
    return <Loader />;
  }

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete) {
      await dispatch(archiveCustomerByIdAction(customer.customerId));
    }
    setDeleteDialogOpen(false);
  };

  const onUpdate = async (updatePayload: UpdateCustomerFormFields) => {
    await dispatch(updateCustomerAction(updatePayload));
    setEditDialogOpen(false);
  };

  const onSubmitApplyCoupon = async (values: ApplyCouponFormFields) => {
    await dispatch(updateCustomerAction({ customerId: customer.customerId, couponRefId: values.selectedCouponRefId }));
    setApplyCouponOpen(false);
  };

  const onSubmitSetMetaData = async (metadata: Record<string, string>) => {
    await dispatch(updateCustomerAction({ additionalMetaData: metadata, customerId: customer.customerId }));
  };

  // we have an assumption that at most there is one active subscription and one trial subscription (per product)
  const activePlanIds = findSubscriptionsPlansOfStatus(activeSubscriptions, SubscriptionStatus.Active);
  const trialPlanIds = findSubscriptionsPlansOfStatus(activeSubscriptions, SubscriptionStatus.InTrial);

  const tabs = [
    {
      title: t('customers.tabs.overview'),
      url: CustomerPageTabs.Overview,
      content: (
        <GridFlex.Column gap={6}>
          <CustomerDetails
            customer={customer}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onApplyCouponClick={() => setApplyCouponOpen(true)}
            onRemoveCouponClick={() => setRemoveCouponDialogOpen(true)}
          />
          <CustomerSubscriptionsCard customer={customer} />
          <PromotionalEntitlements customer={customer} />
          <EntityMetadataCard
            metaData={customer.additionalMetaData}
            onSubmit={onSubmitSetMetaData}
            entityTypeName="customer"
          />
        </GridFlex.Column>
      ),
    },
    {
      title: t('customers.tabs.entitlementUsage'),
      url: CustomerPageTabs.EntitlementUsage,
      content: (
        <CustomerEntitlementsUsage
          entitlements={entitlements}
          isLoading={isLoadingEntitlements}
          reloadEntitlementsAction={dispatchFetchEntitlements}
          entitlementsLastUpdated={entitlementsLastUpdated}
          customerRefId={refId}
          selectedResource={selectedResource}
          onUpdateResource={setSelectedResource}
        />
      ),
    },
    {
      title: t('customers.tabs.entitlementSummary'),
      url: CustomerPageTabs.EntitlementSummary,
      content: (
        <CustomerEntitlementsSummary
          entitlements={entitlements}
          isLoading={isLoadingEntitlements || isLoadingActiveSubscriptions}
          reloadEntitlementsAction={dispatchFetchEntitlements}
          entitlementsLastUpdated={entitlementsLastUpdated}
          activePlanIds={activePlanIds}
          trialPlanIds={trialPlanIds}
          selectedResource={selectedResource}
          onUpdateResource={setSelectedResource}
        />
      ),
    },
    {
      title: t('customers.tabs.events'),
      url: CustomerPageTabs.Activity,
      content: <EventLog entityId={customer.customerId} parentEntityId={customer.customerId} />,
      hidden: !showEventLog,
    },
  ];

  return (
    <GridFlex.Column gap={6} flexWrap="nowrap" $fullHeight>
      <CustomerHeader customer={customer} />
      <Tabs data={tabs} />
      <CustomerUpdateDialog customer={customer} onUpdate={onUpdate} open={editDialogOpen} setOpen={setEditDialogOpen} />
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('customers.deleteDialogTitle')}
        content={t('customers.dialogDelete')}
        confirmationButtonText={t('customers.archive')}
      />
      <ApplyCouponDialog open={applyCouponOpen} setOpen={setApplyCouponOpen} onSubmit={onSubmitApplyCoupon} />
      <RemoveCouponDialog {...removeCouponDialogProps} />
    </GridFlex.Column>
  );
}

export default CustomerPage;
