import { FeatureDetailsSnippetsCode } from './featureDetailsSnippets.types';
import { MOCK_CUSTOMER_ID, MOCK_REQUESTED_USAGE } from './snippetMockData';

export const nodeFeatureDetailsSnippetsCode: FeatureDetailsSnippetsCode = {
  initializeSDK: (apiKey) => `import Stigg from '@stigg/node-server-sdk';

const stiggClient = await Stigg.initialize({
    apiKey: '${apiKey}'
});

export default stiggClient;`,
  checkBooleanEntitlement: (featureRefId) => `const entitlement = await stiggClient.getBooleanEntitlement({
  // replace with real customer ID
  customerId: '${MOCK_CUSTOMER_ID}',
  featureId: '${featureRefId}',
});
    
if (entitlement.hasAccess) {
  // customer has access to the feature
} else {
  // access denied
}`,
  checkNumericEntitlement: (featureRefId) => `const entitlement = stiggClient.getNumericEntitlement({
  // replace with real customer ID
  customerId: '${MOCK_CUSTOMER_ID}',
  featureId: '${featureRefId}',
});
    
if (entitlement.hasAccess) {
  // get the entitlement numeric value that is defined for the feature
  console.log(entitlement.value);
}`,
  checkMeteredEntitlement: (featureRefId) => `const entitlement = stiggClient.getMeteredEntitlement({
  // replace with real customer ID
  customerId: '${MOCK_CUSTOMER_ID}',
  featureId: '${featureRefId}',
  options: {
    requestedUsage: ${MOCK_REQUESTED_USAGE}
  }
});
  
if (entitlement.hasAccess) {
  // has access and the requested usage is within the allowed quota
} else {
  // the customer has exceeded his quota for this feature
}`,
  reportUsageForMeteredFeature: (featureRefId: string) => `await stiggClient.reportUsage({
  // replace with real customer ID
  customerId: '${MOCK_CUSTOMER_ID}',
  featureId: '${featureRefId}',
  value: 10
});`,

  reportEventForMeteredFeature: () => `await stiggClient.reportEvent({
  // replace with real customer ID
  customerId: 'example-customer-id',
  eventName: 'example-event-name',
  idempotencyKey: '82f584b6-488f-4275-a0d3-47442d64ad79';
  dimensions: {
    example_property: '1234',
  }
});`,
};
