import React, { useState } from 'react';
import { get, isNumber, isNil } from 'lodash';
import styled from 'styled-components/macro';
import { FormRenderProps, GridFlex, Icon, IconButton, TextField } from '@stigg-components';
import { BillingPeriod, PriceFragment } from '@stigg-types/apiTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getPeriodFormat, GetPriceAndPeriodOptions } from '../../../../../../packages/pricing/utils/priceFormatUtils';
import { AddonsPriceOverride, BillableFeaturesPriceOverride, SubscriptionFormFields } from '../SubscriptionForm.types';

type SubscriptionChargeOverrideProps = {
  charge: PriceFragment;
  priceFormatOptions: GetPriceAndPeriodOptions;
  onClose: () => void;
  formProps: FormRenderProps<SubscriptionFormFields>;
  showPriceByMonth?: boolean;
  priceOverrides: BillableFeaturesPriceOverride | AddonsPriceOverride | undefined;
  priceOverrideKey: string;
  inputName: string;
};

const ChargeOverrideButton = styled(IconButton)`
  border-radius: 4px;
  &:hover {
    background-color: #e2eeff;
    opacity: 1;
    svg {
      path {
        stroke: ${({ theme }) => theme.itamar.palette.primary.main};
      }
    }
  }
`;

const convertAmountPerBillingPeriod = (
  amount?: number,
  billingPeriod?: BillingPeriod,
  reverse?: boolean,
  showPriceByMonth?: boolean,
) => {
  if (isNil(amount)) {
    return undefined;
  }
  if (billingPeriod === BillingPeriod.Monthly || !showPriceByMonth) {
    return amount;
  }

  return reverse ? amount / 12 : amount * 12;
};

export function SubscriptionChargeOverride({
  charge,
  priceFormatOptions,
  onClose,
  formProps,
  showPriceByMonth,
  priceOverrides,
  priceOverrideKey,
  inputName,
}: SubscriptionChargeOverrideProps) {
  const { values, errors, touched, setFieldValue, handleBlur } = formProps;
  const { billingPeriod } = values;
  const [initialValue] = useState<number | null | undefined>(() => {
    const overrideValue = get(priceOverrides, priceOverrideKey)?.price?.amount;
    return convertAmountPerBillingPeriod(overrideValue, billingPeriod, true, showPriceByMonth);
  });
  const placeholder = convertAmountPerBillingPeriod(
    charge.price?.amount,
    billingPeriod,
    true,
    showPriceByMonth,
  )?.toString();

  const currentValue = convertAmountPerBillingPeriod(
    get(priceOverrides, priceOverrideKey)?.price?.amount,
    billingPeriod,
    true,
    showPriceByMonth,
  );

  const handleInputChange = (value: number | null | undefined) => {
    const chargeOverride: PriceFragment | undefined =
      charge.price && isNumber(value)
        ? {
            ...charge,
            price: {
              ...charge.price,
              amount: convertAmountPerBillingPeriod(value, billingPeriod, false, showPriceByMonth)!,
            },
          }
        : undefined;
    setFieldValue(inputName, chargeOverride);
  };

  const handleOverrideApprove = () => {
    onClose();
  };

  const handleOverrideCancel = () => {
    handleInputChange(initialValue);
    onClose();
  };

  const handleOverrideRevert = () => {
    setFieldValue(inputName, undefined);
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      onClose();
    } else if (event.key === 'Enter') {
      handleOverrideApprove();
    }
  };

  return (
    <GridFlex.Row gap={1}>
      <TextField
        type="number"
        name={inputName}
        value={currentValue}
        placeholder={placeholder}
        touched={!!get(touched, inputName)}
        error={!!get(errors, inputName)}
        errorText={get(errors, inputName)}
        onBlur={handleBlur}
        startAdornment={getSymbolFromCurrency(charge.price?.currency || '')}
        endAdornment={`/ ${getPeriodFormat(charge, priceFormatOptions)}`}
        onChange={(event) => handleInputChange(event.target.value ? Number(event.target.value) : undefined)}
        onKeyDown={handleKeyDown}
      />
      <ChargeOverrideButton onClick={handleOverrideApprove}>
        <Icon type="custom" icon="Check" size={16} />
      </ChargeOverrideButton>
      <ChargeOverrideButton onClick={handleOverrideCancel}>
        <Icon type="custom" icon="Close" size={12} />
      </ChargeOverrideButton>
      {initialValue && (
        <ChargeOverrideButton onClick={handleOverrideRevert}>
          <Icon type="custom" icon="Revert" size={16} />
        </ChargeOverrideButton>
      )}
    </GridFlex.Row>
  );
}
