import { ReactNode, useEffect, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import { useMount } from '@stigg-components';
import { ACCORDION_TOGGLE_TRANSITION_DURATION_MS } from './transition';

export type DeferRenderProps = {
  children: ReactNode | (() => ReactNode);
  shouldRender: boolean;
  setIsReady: (isReady: boolean) => void;
};

export function DeferRender({ children, shouldRender, setIsReady }: DeferRenderProps) {
  const timeoutId = useRef<NodeJS.Timeout>();
  const [flag, setFlag] = useState(shouldRender);

  // upon change of shouldRender, update internal flag state
  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    if (shouldRender) {
      setFlag(true);
    } else {
      setIsReady(false);
      // wait for transition to finish before removing from DOM
      timeoutId.current = setTimeout(() => setFlag(false), ACCORDION_TOGGLE_TRANSITION_DURATION_MS * 2);
    }
  }, [shouldRender, setIsReady]);

  // cleanup timeout when component is unmounted
  useMount(() => {
    return () => timeoutId.current && clearTimeout(timeoutId.current);
  });

  // set isReady when component is mounted
  useEffect(() => {
    if (flag) {
      setIsReady(true);
    }
  }, [setIsReady, flag]);

  let body: ReactNode = null;

  if (flag) {
    body = isFunction(children) ? children() : children;
  }

  return <>{body}</>;
}
