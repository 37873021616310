import { createSlice } from '@reduxjs/toolkit';
import { SnackbarMessage, SnackbarKey, OptionsObject } from 'notistack';

export const ENQUEUE_SNACKBAR = 'enqueueSnackbar';
export const CLOSE_SNACKBAR = 'closeSnackbar';
export const REMOVE_SNACKBAR = 'removeSnackbar';

type Notification = {
  message: SnackbarMessage;
  options?: Omit<OptionsObject, 'defaultValue'>;
  dismissed?: boolean;
  key?: SnackbarKey;
};

export interface NotificationsState {
  notifications: Notification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    [ENQUEUE_SNACKBAR]: {
      reducer: (state, { payload }: { payload: Notification }) => {
        // @ts-ignore
        state.notifications.push(payload);
      },
      prepare: (notification: Notification) => {
        const payload = {
          ...notification,
          key: notification.options?.key || (new Date().getTime() + Math.random()).toString(),
        };
        return { payload };
      },
    },
    [CLOSE_SNACKBAR]: {
      reducer: (state, { payload }: any) => {
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss = payload.dismissAll || notification.key === payload.key;
          return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
        });
      },
      prepare: (key) => ({ payload: { key, dismissAll: !key } }),
    },
    [REMOVE_SNACKBAR]: (state, { payload }) => {
      state.notifications = state.notifications.filter((notification) => notification.key !== payload);
    },
  },
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = notificationsSlice.actions;

export default notificationsSlice.reducer;
