import { t } from 'i18next';
import {
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables,
  ErrorCode,
  SubscriptionCancellationInput,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { fetchSubscriptionByRefIdAction } from '../customersSlice';

const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($input: SubscriptionCancellationInput!) {
    cancelSubscription(input: $input) {
      subscriptionId
    }
  }
`;
export type CancelSubscriptionInput = Omit<SubscriptionCancellationInput, 'environmentId'> & { customerId: string };

async function cancelSubscription(
  data: CancelSubscriptionInput,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input: SubscriptionCancellationInput = {
        environmentId: accountReducer.currentEnvironmentId,
        endDate: data.endDate,
        subscriptionRefId: data.subscriptionRefId,
        subscriptionCancellationTime: data.subscriptionCancellationTime,
      };
      await apolloClient.mutate<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>({
        mutation: CANCEL_SUBSCRIPTION,
        variables: { input },
      });

      await dispatch(fetchSubscriptionByRefIdAction({ refId: data.subscriptionRefId, isSilentLoading: true }));
    },
    {
      successMessage: t('subscriptions.api.successCancel'),
      failureMessageHandler: () => t('subscriptions.api.cancelFailed'),
      expectedErrorsMessage: {
        [ErrorCode.InvalidCancellationDate]: t('subscriptions.api.failDeleteIntegrityViolation'),
        [ErrorCode.TrialMustBeCancelledImmediately]: t('subscriptions.api.trialMustBeCancelledImmediately'),
      },
    },
    dispatch,
  );
}

export { cancelSubscription };
