import { gql } from '@apollo/client';
import { t } from 'i18next';
import { ArchiveEnvironmentMutation, ArchiveEnvironmentMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';
import { fetchEnvironmentsAction } from '../accountsSlice';

const ARCHIVE_ENVIRONMENT = gql`
  mutation ArchiveEnvironment($input: ArchiveEnvironmentInput!) {
    archiveEnvironment(input: $input) {
      id
    }
  }
`;

export type ArchiveEnvironmentProps = {
  environmentId: string;
  withSuccessMessage?: boolean;
};

async function archiveEnvironment(
  { withSuccessMessage = true, environmentId }: ArchiveEnvironmentProps,
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<ArchiveEnvironmentMutation, ArchiveEnvironmentMutationVariables>({
        mutation: ARCHIVE_ENVIRONMENT,
        variables: { input: { id: environmentId } },
      });

      await dispatch(fetchEnvironmentsAction({}));
      return { id: environmentId };
    },
    {
      successMessage: withSuccessMessage ? t('accounts.api.successEnvDelete') : undefined,
      failureMessageHandler: () => t('accounts.api.failEnvDelete'),
    },
    dispatch,
  );
}

export { archiveEnvironment };
