import { drawFormFields, Field, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import { SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';
import { getPricePeriodUnit } from '../../utils/priceConversions';
import { getPricePeriodFields } from '../utils/getPricePeriodFields';

type BasePriceProps = {
  index: number;
};

export function BasePrice({ index }: BasePriceProps) {
  const { aPackage, formRenderProps } = useSetPriceWizardFormContext();
  const { billingPeriods, billingCadence, charges, defaultCurrency } = formRenderProps.values;
  const currentCharge = charges[index];
  const pricePeriodUnit = getPricePeriodUnit(billingPeriods);
  const title = t(`pricing.charges.basePrice.description`);
  const isAddon = aPackage.type === 'Addon';

  const fields: Field<SetPriceWizardFormFields>[] = [];

  if (!isAddon) {
    fields.push({
      type: 'idle',
      component: <Text.B2 color="secondary">{title}</Text.B2>,
    });
  }

  fields.push({
    type: 'layout',
    gridProps: {
      mt: 4,
      alignItems: 'baseline',
    },
    fields: getPricePeriodFields({
      pricePeriodsId: `charges[${index}].pricePeriods`,
      billingModel: currentCharge.billingModel,
      pricePeriodUnit,
      currency: defaultCurrency,
      billingCadence,
    }),
  });

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
