import { Icon } from '@stigg-components';
import { t } from 'i18next';
import { SidebarItemsType } from '@stigg-types/sidebar';
import { ProductListItemFragment } from '../../../types/apiTypes';
import { appRoutes } from '../../../modules/navigation/useNavigation';
import { getStiggTheme } from '../../../theme';

type GetSidebarItemsProps = {
  products: ProductListItemFragment[];
  withGettingStartedPage: boolean;
  withEventLog: boolean;
};

function renderIcon(iconRenderer: (iconColor) => React.ReactNode) {
  const { theme } = getStiggTheme();
  const iconColor = theme.isLightTheme ? 'active' : 'primary.main';
  return iconRenderer(iconColor);
}

function CatalogIcon() {
  return renderIcon((iconColor) => <Icon type="custom" icon="Catalog" color={iconColor} overrideStroke />);
}

function CustomersIcon() {
  return renderIcon((iconColor) => <Icon type="reactFeather" icon="Users" color={iconColor} overrideStroke />);
}

function DashboardOutlinedIcon() {
  return renderIcon((iconColor) => <Icon type="materialIcons" icon="DashboardOutlined" color={iconColor} />);
}

function ZapIcon() {
  return renderIcon((iconColor) => <Icon type="reactFeather" icon="Zap" color={iconColor} overrideStroke />);
}

function PlayCircleIcon() {
  return renderIcon((iconColor) => <Icon type="reactFeather" icon="PlayCircle" color={iconColor} overrideStroke />);
}

function ToggleLeftIcon() {
  return renderIcon((iconColor) => <Icon type="reactFeather" icon="ToggleLeft" color={iconColor} overrideStroke />);
}

function EventsIcon() {
  return renderIcon((iconColor) => <Icon type="custom" icon="HamburgerMenu" color={iconColor} overrideStroke />);
}

function EventLog() {
  return renderIcon((iconColor) => <Icon type="custom" icon="EventLog" color={iconColor} overrideStroke />);
}

function Experiments() {
  return renderIcon((iconColor) => <Icon type="custom" icon="Experiments" color={iconColor} overrideStroke />);
}

const getSidebarItems = ({ products, withGettingStartedPage, withEventLog }: GetSidebarItemsProps) => {
  let productRefId;
  if (products?.length === 1) {
    productRefId = products[0].refId;
  }
  const integrations = [
    {
      href: '/integrations',
      title: t('sidenav.thirdPartyIntegrations'),
      requiresEnvironment: true,
    },
    {
      href: '/webhooks',
      title: t('sidenav.hooks'),
      requiresEnvironment: true,
    },
  ];

  return [
    {
      pages: [
        ...(withGettingStartedPage
          ? [
              {
                href: '/getting-started',
                requiresEnvironment: true,
                title: t('sidenav.gettingStarted'),
                icon: PlayCircleIcon,
              },
            ]
          : []),
        {
          href: '',
          title: t('sidenav.dashboard'),
          icon: DashboardOutlinedIcon,
          open: !withGettingStartedPage,
          children: [
            {
              href: '/dashboards/subscriptions',
              title: t('sidenav.subscriptionsOverview'),
              requiresEnvironment: true,
            },
            {
              href: '/dashboards/feature-inspector',
              title: t('sidenav.featureInspector'),
              requiresEnvironment: true,
            },
          ],
        },
        {
          href: '',
          icon: CatalogIcon,
          title: t('sidenav.productCatalog'),
          open: withGettingStartedPage,
          children: [
            {
              href: appRoutes.productPage(productRefId),
              title: t('sidenav.products'),
              requiresEnvironment: true,
              dataTestId: 'sidenav-products',
            },
            {
              href: '/plans',
              title: t('sidenav.plans'),
              requiresEnvironment: true,
              dataTestId: 'sidenav-plans',
            },
            {
              href: '/addons',
              title: t('sidenav.addons'),
              requiresEnvironment: true,
            },
            {
              href: '/features',
              title: t('sidenav.features'),
              requiresEnvironment: true,
            },
            {
              href: '/coupons',
              title: t('sidenav.coupons'),
              requiresEnvironment: true,
            },
          ],
        },
        {
          href: '/customers',
          icon: CustomersIcon,
          title: t('sidenav.customers'),
          requiresEnvironment: true,
          dataTestId: 'sidenav-customers',
        },
        {
          href: '/experiments',
          icon: Experiments,
          title: t('sidenav.experiments'),
          requiresEnvironment: true,
        },
        {
          href: '',
          icon: ZapIcon,
          title: t('sidenav.integrations'),
          children: integrations,
        },
        {
          href: '/usage-events',
          icon: EventsIcon,
          title: t('sidenav.usageEvents'),
          requiresEnvironment: true,
        },
        {
          href: '/widgets',
          icon: ToggleLeftIcon,
          title: t('sidenav.widgets'),
          requiresEnvironment: true,
        },
        ...(withEventLog
          ? [
              {
                href: '/activity-log',
                icon: EventLog,
                title: t('sidenav.eventLog'),
                requiresEnvironment: true,
              },
            ]
          : []),
      ] as SidebarItemsType[],
    },
  ];
};

export default getSidebarItems;
