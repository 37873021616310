import { Link, Text } from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import React from 'react';
import { TooltipFields } from '../../../../../../../components/InformationTooltip';
import { useNavigation } from '../../../../../../navigation/useNavigation';

function NoAvailablePlansContent({ productId }: { productId: string }) {
  const navigation = useNavigation();

  return (
    <Text.B2>
      <Trans
        t={t}
        i18nKey="subscriptionForm.noAvailablePlansHint"
        components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.productPage(productId))} />]}
      />
    </Text.B2>
  );
}

function NoAvailableProductsContent() {
  const navigation = useNavigation();

  return (
    <Text.B2>
      <Trans
        t={t}
        i18nKey="subscriptionForm.noAvailableProductsHint"
        components={[<Link onClick={() => navigation.navigateTo(navigation.appRoutes.createProductPage())} />]}
      />
    </Text.B2>
  );
}

function SwitchToCreateMode({ text, switchToCreateMode }: { text: string; switchToCreateMode: () => void }) {
  return (
    <Text.B2>
      {text} <Link onClick={switchToCreateMode}>{t('subscriptionForm.createNewSubscription')}</Link>
    </Text.B2>
  );
}

export const noAvailablePlansTooltip: (productId: string) => TooltipFields = (productId: string) => ({
  maxWidth: 235,
  placement: 'top',
  title: <NoAvailablePlansContent productId={productId} />,
});

export const noAvailableProductsTooltip: TooltipFields = {
  maxWidth: 235,
  placement: 'top',
  title: <NoAvailableProductsContent />,
};

export const switchToCreateModeTooltip: (text: string, switchToCreateMode: () => void) => TooltipFields = (
  text,
  switchToCreateMode,
) => ({
  maxWidth: 350,
  placement: 'top',
  title: <SwitchToCreateMode text={text} switchToCreateMode={switchToCreateMode} />,
});
