import { CountryPriceLocalization } from '../SetPriceWizardForm.types';
import { SectionIdentifier } from '../SetPriceWizardForm.steps';

export function getInitialFocusedCountryUUID(
  countries: CountryPriceLocalization[],
  initialFocusedSection?: SectionIdentifier,
) {
  let focusedCountry: CountryPriceLocalization | undefined;

  if (initialFocusedSection?.stepId === 'priceLocalization' && initialFocusedSection.billingCountryCode) {
    focusedCountry = countries.find(
      (country) => country.billingCountryCode === initialFocusedSection.billingCountryCode,
    );
  } else if (countries.length === 1) {
    [focusedCountry] = countries;
  }

  return focusedCountry?.uuid;
}
