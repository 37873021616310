import { gql, ObservableQuery } from '@apollo/client';
import {
  ImportIntegrationTaskFilter,
  ImportIntegrationTasksQuery,
  ImportIntegrationTasksQueryVariables,
  ImportIntegrationTaskSortFields,
  SortDirection,
  TaskType,
  TaskStatus,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common';

const QUERY = gql`
  query ImportIntegrationTasks($filter: ImportIntegrationTaskFilter, $sorting: [ImportIntegrationTaskSort!]) {
    importIntegrationTasks(filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...ImportIntegrationTaskResult
        }
      }
    }
  }
  fragment ImportIntegrationTaskResult on ImportIntegrationTask {
    id
    progress
    status
    taskType
    productsCount
    customersCount
    totalSubtasksCount
    createdAt
    createdAt
    importErrors {
      id
      error
    }
  }
`;

export function fetchImportIntegrationTasks(
  { environmentId, inProgressTasks }: { inProgressTasks?: boolean; environmentId: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const filter: ImportIntegrationTaskFilter = {
        environmentId: { eq: environmentId },
        taskType: { in: [TaskType.ImportIntegrationCatalog, TaskType.ImportIntegrationCustomers] },
      };
      if (inProgressTasks) {
        filter.status = { in: [TaskStatus.Pending, TaskStatus.InProgress] };
      }
      const response = await apolloClient.query<ImportIntegrationTasksQuery, ImportIntegrationTasksQueryVariables>({
        query: QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          filter,
          sorting: [
            {
              field: ImportIntegrationTaskSortFields.CreatedAt,
              direction: SortDirection.Desc,
            },
          ],
        },
      });
      return response.data.importIntegrationTasks.edges.map((edge) => edge.node);
    },
    {
      failureMessageHandler: () => t('integrations.api.recentImportsFetchFailed'),
    },
    dispatch,
  );
}

export function watchImportIntegrationTask(
  taskId: string,
  pollInterval: number,
): ObservableQuery<ImportIntegrationTasksQuery, ImportIntegrationTasksQueryVariables> | null {
  if (!taskId) {
    return null;
  }
  return apolloClient.watchQuery<ImportIntegrationTasksQuery, ImportIntegrationTasksQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: taskId,
        },
      },
    },
  });
}
