import { styled } from '@stigg-theme';
import { useState } from 'react';
import { ClickAwayListener, Icon, IconButton, Link } from '@stigg-components';
import { GridFlex } from './Grids';
import { Text } from './Typography';
import { InformationTooltip, InformationTooltipProps } from './InformationTooltip';

type CloseableInformationTooltipProps = InformationTooltipProps & {
  header?: React.ReactNode;
};

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export function CloseableInformationTooltip({ header, children, ...props }: CloseableInformationTooltipProps) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  const tooltipContent = (
    <GridFlex.Column rowGap={2} padding={0}>
      <GridFlex.RowSpaceBetween>
        <Text.B2>{header}</Text.B2>
        <CloseIconButton aria-label="close" onClick={handleTooltipClose}>
          <Icon type="materialIcons" icon="Close" color="active" size={20} />
        </CloseIconButton>
      </GridFlex.RowSpaceBetween>
      {props.title}
    </GridFlex.Column>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <GridFlex.Item width="fit-content">
        <InformationTooltip
          {...props}
          title={tooltipContent}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener>
          <GridFlex.Item width="fit-content">
            <Link underline="always" color="secondary" variant="body2" onClick={handleTooltipToggle}>
              {children}
            </Link>
          </GridFlex.Item>
        </InformationTooltip>
      </GridFlex.Item>
    </ClickAwayListener>
  );
}
