import { useCallback, useState } from 'react';
import { FormRenderProps } from '@stigg-components';
import { AddonFragment, PlanFragment } from '@stigg-types/apiTypes';
import { SectionIdentifier } from '../components/SetPriceWizard/form/SetPriceWizardForm.steps';
import { SetPriceWizardFormFields } from '../components/SetPriceWizard/form/SetPriceWizardForm.types';
import { SetPriceWizard } from '../components/SetPriceWizard/SetPriceWizard';
import { computeDisabledPricingTypes } from '../../common/computeDisabledPricingTypes';

export const usePriceWizard = (
  setHasPublishValidationError: (hasError: boolean) => void,
  aPackage?: PlanFragment | AddonFragment,
) => {
  const [isSetPriceWizardOpen, setIsSetPriceWizardOpen] = useState(false);
  const [focusedPriceWizardSection, setFocusedPriceWizardSection] = useState<SectionIdentifier | undefined>();
  const [modifyPriceWizardForm, setModifyPriceWizardForm] = useState<
    ((formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void) | undefined
  >();

  const openSetPriceWizard = useCallback(
    (
      section?: SectionIdentifier,
      modifyForm?: (formRenderProps: FormRenderProps<SetPriceWizardFormFields>) => void,
    ) => {
      setFocusedPriceWizardSection(section);
      setModifyPriceWizardForm(() => modifyForm);
      setIsSetPriceWizardOpen(true);
    },
    [setFocusedPriceWizardSection, setModifyPriceWizardForm, setIsSetPriceWizardOpen],
  );

  const closeSetPriceWizard = useCallback(() => {
    setIsSetPriceWizardOpen(false);
    setModifyPriceWizardForm(undefined);
    setFocusedPriceWizardSection(undefined);
  }, [setIsSetPriceWizardOpen, setModifyPriceWizardForm, setFocusedPriceWizardSection]);

  const PriceWizard = useCallback(
    () =>
      aPackage ? (
        <SetPriceWizard
          aPackage={aPackage}
          isOpen={isSetPriceWizardOpen}
          onClose={closeSetPriceWizard}
          initialFocusedSection={focusedPriceWizardSection}
          initialFormUpdate={modifyPriceWizardForm}
          setHasPublishValidationError={setHasPublishValidationError}
          disabledPricingTypes={computeDisabledPricingTypes(aPackage)}
        />
      ) : null,
    [
      aPackage,
      isSetPriceWizardOpen,
      closeSetPriceWizard,
      focusedPriceWizardSection,
      modifyPriceWizardForm,
      setHasPublishValidationError,
    ],
  );

  return {
    PriceWizard,
    openSetPriceWizard,
  };
};
