import { t } from 'i18next';
import { CustomDrawer } from '@stigg-components';
import { CustomerSubscriptionResourceFragment, EntitlementDataFragment } from '@stigg-types/apiTypes';
import { ReportUsageForm } from './ReportUsageForm';

export function ReportUsageDrawer({
  customerRefId,
  entitlement,
  subscription,
  isOpen,
  onClose,
}: {
  customerRefId: string;
  entitlement: EntitlementDataFragment;
  subscription?: CustomerSubscriptionResourceFragment;
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <CustomDrawer
      title={t('customers.addUsageRecord.title')}
      description={t('customers.addUsageRecord.description')}
      isOpen={isOpen}
      onClose={onClose}>
      <ReportUsageForm
        customerRefId={customerRefId}
        entitlement={entitlement}
        subscription={subscription}
        onCancel={onClose}
        onSubmit={onClose}
      />
    </CustomDrawer>
  );
}
