import { Alert } from '@stigg-components';
import { PlanFragment, PriceFragment, PricingType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { getPrice } from '../../../../pricing/utils/getPrice';
import { AddonCurrencyMissingAlertProps } from '../compatibleAddons/CompatibleAddons';

const generatePriceId = (price: PriceFragment) =>
  `${price.billingCountryCode}-${getPrice(price).currency}-${price.billingPeriod}`;

export function getAddonsWithMissingPlanCurrencies(plan: PlanFragment) {
  const planAdditionalPrices = plan.prices?.filter(({ billingCountryCode }) => !!billingCountryCode) || [];

  if (plan.pricingType !== PricingType.Paid || !plan.compatibleAddons?.length || !planAdditionalPrices.length) {
    return [];
  }

  const planPriceIds = planAdditionalPrices.map(generatePriceId);

  const addonsWithMissingCurrency = plan.compatibleAddons.filter((addon) => {
    const addonAdditionalPrices = addon.prices?.filter(({ billingCountryCode }) => !!billingCountryCode) || [];
    const addonAdditionalPricesSet = new Set(addonAdditionalPrices.map(generatePriceId));

    return planPriceIds.some((planPriceId) => !addonAdditionalPricesSet.has(planPriceId));
  });

  return addonsWithMissingCurrency;
}

export function AddonCurrencyMissingAlert({ plan, addonsWithMissingCurrency }: AddonCurrencyMissingAlertProps) {
  if (!addonsWithMissingCurrency) {
    addonsWithMissingCurrency = plan ? getAddonsWithMissingPlanCurrencies(plan) : [];
  }

  if (!addonsWithMissingCurrency.length) {
    return null;
  }

  return (
    <Alert mb={4} severity="warning">
      {t('pricing.currency.addonCurrencyMissingAlert', { count: addonsWithMissingCurrency.length })}
    </Alert>
  );
}

export function useAddonMissingCurrencies({ plan }: { plan: PlanFragment }) {
  const addonsWithMissingCurrency = getAddonsWithMissingPlanCurrencies(plan);

  return {
    addonsWithMissingCurrenciesAlert: (
      <AddonCurrencyMissingAlert addonsWithMissingCurrency={addonsWithMissingCurrency} />
    ),
    addonsWithMissingCurrency,
  };
}
