import { Text, LinearProgressProps, LinearProgress, Box, GridFlex } from '@stigg-components';

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const { value } = props;
  return (
    <GridFlex.RowCenter $fullWidth>
      <Box sx={{ width: '100%', mr: 2 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 40 }}>
        <Text.B2 color="secondary">{`${Math.round(value)}%`}</Text.B2>
      </Box>
    </GridFlex.RowCenter>
  );
}
