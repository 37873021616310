import { t } from 'i18next';
import { gql } from '@apollo/client';
import { Dispatch } from '@reduxjs/toolkit';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { executeOperationSafely } from '@stigg-common';
import { ArchiveAddonMutation, ArchiveAddonMutationVariables, ErrorCode } from '@stigg-types/apiTypes';
import { RootState } from '../../../../redux/store';
import { apolloClient } from '../../../../ApolloClient';
import { removeAddonByRefId } from '../addonSlice';

const ARCHIVE_ADDON_BY_REF_ID = gql`
  mutation ArchiveAddon($input: AddonArchiveInput!) {
    archiveAddon(input: $input) {
      id
    }
  }
`;

const mutationFunction = (refId: string, environmentId: string, dispatch: Dispatch) => {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<ArchiveAddonMutation, ArchiveAddonMutationVariables>({
        mutation: ARCHIVE_ADDON_BY_REF_ID,
        variables: {
          input: {
            refId,
            environmentId,
          },
        },
      });
    },
    {
      successMessage: t('addons.api.successArchive'),
      failureMessageHandler: () => t('addons.api.failDelete'),
      expectedErrorsMessage: {
        [ErrorCode.AddonWithDraftCannotBeDeletedError]: t('addons.api.failArchiveAddonWithDraftCannotBeDeletedError'),
      },
    },
    dispatch,
  );
};

export const useArchiveAddonByRefId = (refId: string) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  return useMutation({
    mutationFn: () => mutationFunction(refId, environmentId, dispatch),
    onSuccess: () => {
      dispatch(removeAddonByRefId({ refId }));
    },
  });
};
