import { ReactNode } from 'react';
import { t } from 'i18next';
import { FieldArray } from 'formik';
import { drawFormFields, Field } from '@stigg-components';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { SetPriceWizardFormFields } from '../SetPriceWizardForm.types';
import { useLoadFeatures } from '../../../../../../features/hooks/useLoadFeatures';
import { USAGE_BASED_FEATURE_TYPES, USAGE_BASED_METER_TYPES } from './usageBasedPrice/FeatureAutocomplete';
import { StepsManagement } from '../utils/useSubStepsState';

import { OverageChargesList } from './overagePrice/OverageChargesList';
import { OverageBillingPeriodSelect } from './overagePrice/OverageBillingPeriodSelect';

export type ChargesStepProps = {
  chargesState: StepsManagement;
  renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode;
};

export function OverageChargesStep({ renderStepActionButtons, chargesState }: ChargesStepProps) {
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { values } = formRenderProps;

  const {
    priceLocalization: { enabled: priceLocalizationEnabled },
  } = values;

  // preload features for usage based charges
  useLoadFeatures({
    featureTypes: USAGE_BASED_FEATURE_TYPES,
    meterTypes: USAGE_BASED_METER_TYPES,
  });

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'switch',
      id: 'overageCharges.enabled',
      disabled: priceLocalizationEnabled,
      disabledTooltipProps: {
        title: t('pricing.overageCharges.overageDisabledWithPriceLocalization'),
      },
      label: t('pricing.overageCharges.description'),
    },
    {
      type: 'custom',
      gridProps: {
        flexDirection: 'column',
        mt: 4,
      },
      render: () => {
        return <OverageBillingPeriodSelect />;
      },
    },
    {
      type: 'custom',
      gridProps: {
        flexDirection: 'column',
        mt: 4,
      },
      render: () => {
        return (
          <FieldArray name="overageCharges.charges">
            {({ push, remove }) => (
              <OverageChargesList
                formRenderProps={formRenderProps}
                chargesState={chargesState}
                renderStepActionButtons={renderStepActionButtons}
                push={push}
                remove={remove}
              />
            )}
          </FieldArray>
        );
      },
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
