import { t } from 'i18next';
import { ExternalLink, GridFlex, Text, GatedFeature, UpgradeButton } from '@stigg-components';
import Table from '../../components/table/Table';
import { headCells } from './components/headCells';
import { DEFAULT_PAGE_SIZE } from './queries/useGetEventLogs';
import { MOCK_EVENT_LOG_DATA } from './utils/featureGateMockData';
import { EventLogColumns } from './components/EventLog';

export default function NoEventLogAccess({ columns }: { columns?: EventLogColumns[] }) {
  const background = (
    <Table
      skeletonOptions={{ rowsCount: 5 }}
      data={MOCK_EVENT_LOG_DATA.slice(0, 8)}
      headCells={headCells(columns)}
      pageSize={DEFAULT_PAGE_SIZE}
      hasBorder={false}
    />
  );

  const popover = (
    <>
      <Text.H3>{t('eventsLog.noAccess.title')}</Text.H3>
      <Text.B2 color="secondary">{t('eventsLog.noAccess.body')}</Text.B2>
      <GridFlex.Row justifyContent="flex-end" gap={4}>
        <ExternalLink hideIcon url="https://docs.stigg.io/docs/activity-log">
          {t('eventsLog.noAccess.learnMore')}
        </ExternalLink>
        <UpgradeButton />
      </GridFlex.Row>
    </>
  );

  return <GatedFeature backgroundComponent={background} popoverComponent={popover} />;
}
