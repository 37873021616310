import { GridFlex, Icon, Text, WizardAccordionStep } from '@stigg-components';
import React, { ReactNode } from 'react';
import { isFunction } from 'lodash';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './WizardAccordion.style';
import { StepStatusIcon } from './StepStatusIcon';
import { StepActionButtons } from './StepActionButtons';
import { DeferRender } from '../helpers/DeferRender';

type WizardAccordionItemProps = {
  step: WizardAccordionStep;
  index: number;
  currentIndex: number;
  expandedSteps: string[];
  disableAllSteps?: boolean;
  showContinueButton: boolean;
  onContinue: () => void;
  toggleStep: (id: string) => void;
};

export function WizardAccordionItem({
  step,
  index,
  currentIndex,
  expandedSteps,
  disableAllSteps,
  showContinueButton,
  onContinue,
  toggleStep,
}: WizardAccordionItemProps) {
  const {
    id,
    title,
    subTitle,
    summary,
    content,
    hasErrors,
    alwaysExpanded,
    descriptionStep,
    hideStepActionsButtons,
    maxWidth = 300,
  } = step;
  const isCurrentStep = index === currentIndex;
  const disabled = disableAllSteps || (currentIndex >= 0 && index > currentIndex);
  const canToggleState = !alwaysExpanded && !isCurrentStep && !disabled;

  const shouldBeExpanded = alwaysExpanded || isCurrentStep || expandedSteps.includes(id);
  const [expanded, setExpanded] = React.useState<boolean>(shouldBeExpanded);

  const onContinueClick = async () => {
    if (step.onStepContinue) {
      await step.onStepContinue();
    }
    onContinue();
  };

  const renderStepActionButtons = (additionalActionButtons?: ReactNode): ReactNode =>
    !hideStepActionsButtons && (
      <StepActionButtons
        hideContinueButton={!showContinueButton}
        onContinue={onContinueClick}
        hasErrors={hasErrors}
        additionalActionButtons={additionalActionButtons}
      />
    );

  let body: ReactNode;

  if (isFunction(content)) {
    // some steps might need to add extra buttons to the action buttons
    body = content(renderStepActionButtons);
  } else {
    body = (
      <>
        {content}
        {renderStepActionButtons()}
      </>
    );
  }

  return (
    <StyledAccordion expanded={expanded && !disabled} disabled={disabled} disableGutters elevation={0}>
      {!descriptionStep && (
        <StyledAccordionSummary
          sx={{ pointerEvents: !canToggleState ? 'none' : undefined }}
          expandIcon={canToggleState && <Icon icon="ExpandMore" color="active" />}
          aria-controls={`panel-${id}-content`}
          id={`panel-${id}-header`}
          data-testid={`panel-${id}-header`}
          onClick={() => toggleStep(id)}>
          <GridFlex.RowCenter justifyContent="space-between" $fullWidth>
            <GridFlex.Row>
              <Text.H6 mr={4} maxWidth={maxWidth} color={disabled ? 'disabled' : 'primary'}>
                {title}
              </Text.H6>
              {!summary && subTitle ? subTitle : null}
              {!expanded && !disabled && summary ? summary : null}
            </GridFlex.Row>

            <StepStatusIcon
              expanded={expanded}
              disabled={disabled}
              index={index}
              currentIndex={currentIndex}
              hasErrors={hasErrors}
            />
          </GridFlex.RowCenter>
        </StyledAccordionSummary>
      )}

      <StyledAccordionDetails $withPaddingTop={!!descriptionStep}>
        <DeferRender shouldRender={shouldBeExpanded} setIsReady={setExpanded}>
          {body}
        </DeferRender>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
}
