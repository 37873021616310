import { EntitlementResetPeriod, PackageEntitlement } from '@stigg-types/apiTypes';
import moment, { DurationInputArg2 } from 'moment/moment';
import { Filter } from '@cubejs-client/core';
import { Moment } from 'moment';

export function getUnit(resetPeriod: EntitlementResetPeriod): DurationInputArg2 {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return 'year';
    case EntitlementResetPeriod.Month:
      return 'month';
    case EntitlementResetPeriod.Week:
      return 'week';
    case EntitlementResetPeriod.Day:
      return 'day';
    case EntitlementResetPeriod.Hour:
      return 'hour';
    default:
      throw new Error(`Unsupported period ${resetPeriod}`);
  }
}

export function extractEpoch(m: Moment): string {
  return (m.valueOf() / 1000).toString();
}

export function calculateNormalizedPeriodStart(resetPeriod: EntitlementResetPeriod, showUncompletedPeriod: boolean) {
  const unit = getUnit(resetPeriod);
  const offset = showUncompletedPeriod ? 0 : 1;

  return moment.utc().subtract(offset, unit).startOf(unit);
}

export function composeFilterForResetPeriod(
  cubeName: string,
  entitlement: PackageEntitlement | undefined,
  showUncompletedPeriod: boolean,
): Filter[] {
  if (!entitlement?.resetPeriod) {
    return [];
  }

  const start = calculateNormalizedPeriodStart(entitlement.resetPeriod, showUncompletedPeriod);

  return [
    {
      member: `${cubeName}.periodStartNormalize`,
      operator: 'equals',
      values: [extractEpoch(start)],
    },
  ];
}
