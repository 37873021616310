import { DateRange, Filter, Query, TimeDimensionGranularity } from '@cubejs-client/core';
import { EntitlementResetPeriod, PackageEntitlement } from '@stigg-types/apiTypes';
import { Moment } from 'moment';
import { calculateNormalizedPeriodStart, extractEpoch, getUnit } from './cubeFilters.helper';
import { formatPeriodDate } from '../../common/formatters';

export function getGranularity(entitlement: PackageEntitlement | undefined): TimeDimensionGranularity {
  if (!entitlement?.resetPeriod) {
    return 'day';
  }
  switch (entitlement.resetPeriod) {
    case EntitlementResetPeriod.Year:
      return 'month';
    case EntitlementResetPeriod.Month:
    case EntitlementResetPeriod.Week:
      return 'day';
    case EntitlementResetPeriod.Day:
      return 'hour';
    case EntitlementResetPeriod.Hour:
      return 'minute';
    default:
      throw new Error(`Unsupported reset period ${entitlement.resetPeriod}`);
  }
}

export function getTimeDimension(
  cubeName: string,
  entitlement: PackageEntitlement | undefined,
  daysBack: number,
  showUncompletedPeriod: boolean,
): {
  dateRange: DateRange;
  additionalFilters: Filter[];
  baseTime?: Moment;
} {
  if (!entitlement?.resetPeriod) {
    return {
      dateRange: `Last ${daysBack} days`,
      additionalFilters: [],
    };
  }

  const unit = getUnit(entitlement.resetPeriod);
  const start = calculateNormalizedPeriodStart(entitlement.resetPeriod, showUncompletedPeriod);

  // we take 2 a buffer of 1 extra period, since in month period the number of days varies (28-31)
  // we have an assumption that we have another filter for the period identifier, and later we would
  // trim out all values after the first graph decent
  const end = start.clone().add(2, unit);
  const dateRange: DateRange = [formatPeriodDate(start), formatPeriodDate(end)];

  const baseTime = start;
  const additionalFilters: Filter[] = [
    {
      member: `${cubeName}.periodStartNormalize`,
      operator: 'equals',
      values: [extractEpoch(start)],
    },
  ];

  return {
    dateRange,
    additionalFilters,
    baseTime,
  };
}

export function composeFeatureDailyUsageQuery(
  environmentId: string,
  featureRefId: string,
  granularity: TimeDimensionGranularity,
  cubeName: string,
  dateRange: DateRange,
  additionalFilters: Filter[],
): Query {
  const query: Query = {
    measures: [`${cubeName}.medianUsage`],
    dimensions: [`Package.refId`],
    timeDimensions: [
      {
        dimension: `${cubeName}.sampleTime`,
        granularity,
        dateRange,
      },
    ],
    filters: [
      {
        member: `${cubeName}.environmentId`,
        operator: 'equals',
        values: [environmentId],
      },
      {
        member: 'Feature.refId',
        operator: 'equals',
        values: [featureRefId],
      },
      ...additionalFilters,
    ],
  };

  return query;
}
