// eslint-disable-next-line no-restricted-imports
import { Checkbox as MuiCheckbox } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { isString } from 'lodash';
import { styled } from '@stigg-theme';
import { FormControlLabel, Text } from './index';

const StyledCheckbox = styled(MuiCheckbox)<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.itamar.palette.action.disabled : theme.itamar.palette.action.active};
`;
export type CheckboxProps = Pick<
  MuiCheckboxProps,
  'value' | 'color' | 'disabled' | 'checked' | 'onChange' | 'indeterminate'
> & {
  label?: string | React.ReactNode;
  ariaLabel?: string;
  onClick?: MouseEventHandler<any> | undefined;
  noPadding?: boolean;
  dataTestid?: string;
};
export function Checkbox({
  value,
  color,
  label,
  disabled,
  checked,
  onChange,
  indeterminate,
  ariaLabel,
  onClick,
  noPadding,
  dataTestid,
}: CheckboxProps) {
  const sx = noPadding ? { padding: 0 } : undefined;
  return (
    <FormControlLabel
      onClick={onClick}
      value={value}
      control={
        <StyledCheckbox
          disableRipple={disabled}
          data-testid={dataTestid}
          indeterminate={indeterminate}
          onChange={onChange}
          checked={checked}
          color={color}
          $disabled={disabled}
          inputProps={{ 'aria-label': ariaLabel }}
          sx={sx}
        />
      }
      label={isString(label) ? <Text.B2 color={disabled ? 'disabled' : 'primary'}>{label}</Text.B2> : label}
    />
  );
}
