import { ChangeType, PackagePriceChange, PriceFragment } from '@stigg-types/apiTypes';
import countryList from 'country-list';
import { t } from 'i18next';
import { groupBy } from 'lodash';
import { Grid } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import { getPriceAndPeriodFormat } from '../../../pricing/utils/priceFormatUtils';
import { ChangeGroupBox, ChangeRow, ChangeSection, DiffSectionHeader, getChange } from './DiffBaseComponents';

type OveragePricesChangeProps = {
  overagePricesDiff: PackagePriceChange[];
  pricePrefix: JSX.Element;
  hasMultipleCurrencies?: boolean;
};

function getOveragePriceFormat(overagePrice: PriceFragment) {
  const { blockSize } = overagePrice || {};
  const priceText = getPriceAndPeriodFormat(overagePrice, {
    shortFormat: true,
    reversedPeriod: false,
    noBillingPeriod: true,
  });

  return `${priceText} ${t('pricing.overageCharges.packagePricingUnits', { count: blockSize || 1 })}`;
}

function OveragePriceChangeRow({
  before,
  after,
  changeType,
  hasMultipleCurrencies,
}: {
  before?: PriceFragment | null;
  after?: PriceFragment | null;
  changeType?: ChangeType | null;
  hasMultipleCurrencies?: boolean;
}) {
  const showCountry = hasMultipleCurrencies || !!after?.billingCountryCode || !!before?.billingCountryCode;

  return (
    <ChangeGroupBox changeType={changeType}>
      {showCountry && (
        <ChangeRow
          before={before?.billingCountryCode ? countryList.getName(before?.billingCountryCode) || '' : 'Default'}
          after={after?.billingCountryCode ? countryList.getName(after?.billingCountryCode) || '' : 'Default'}
          label={t('pricing.packageDifference.country')}
          showAlways
          {...getChange(before?.billingCountryCode, after?.billingCountryCode)}
        />
      )}
      {before?.feature || after?.feature ? (
        <ChangeRow
          before={before?.feature?.displayName}
          after={after?.feature?.displayName}
          label={t('pricing.packageDifference.feature')}
          showAlways
          {...getChange(before?.feature?.displayName, after?.feature?.displayName)}
        />
      ) : null}
      <ChangeRow
        before={before ? getOveragePriceFormat(before) : null}
        after={after ? getOveragePriceFormat(after) : null}
        label={t('pricing.packageDifference.price')}
        showAlways
        changeType={changeType}
      />
    </ChangeGroupBox>
  );
}

export function OveragePricesChange({
  overagePricesDiff,
  pricePrefix,
  hasMultipleCurrencies,
}: OveragePricesChangeProps): JSX.Element | null {
  // We currently only support the same overage price for both billing period.
  // This means that there is no sense in showing the same changes in both billing periods.
  // We group the changes by feature and show them only once.
  const overageByFeature = groupBy(overagePricesDiff, (priceDiff) => priceDiff.after?.featureId);

  const filteredOveragePrices = Object.entries(overageByFeature).map(([_, priceDiffs], index) => {
    return (
      <Grid key={index}>
        <OveragePriceChangeRow {...priceDiffs[0]} hasMultipleCurrencies={hasMultipleCurrencies} />
      </Grid>
    );
  });

  if (isEmpty(filteredOveragePrices)) {
    return null;
  }

  return (
    <ChangeSection prefix={pricePrefix} title={<DiffSectionHeader title={t('packages.changesPackageOveragePrices')} />}>
      {filteredOveragePrices}
    </ChangeSection>
  );
}
