import { useCubeQuery } from '@cubejs-client/react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { applyProductFilter, useCurrentAndPreviousPeriods, WidgetChartContainer } from './base';
import { WidgetCard, WidgetSubtitle, WidgetTitle } from '../../../common/components/Widget';
import { AreaChartRenderer } from './charts';
import { RootState } from '../../../../../redux/store';
import sandboxStaticCubeData from './static/sandbox-subscriptions-over-time.json';
import { useCubeSubscriptionStaticData } from './static/useCubeSubscriptionStaticData';
import { useIsShowMockData } from '../../../common/hooks';
import { useSetChartLastUpdate } from '../../../common/chartsLastUpdate/useSetChartLastUpdate';

function getGranularity(daysBack: number) {
  // todo: make this smarter
  switch (daysBack) {
    case 1:
      return 'hour';
    case 7:
      return 'day';
    case 30:
      return 'day';
    case 90:
    case 91:
      return 'week';
    case 365:
      return 'month';
    default:
      return 'day';
  }
}

const useCubeRealData = ({ skipCubeQuery }: { skipCubeQuery?: boolean } = {}) => {
  const productFilter = useSelector((state: RootState) => state.dashboards.subscriptionsOverview.productFilter);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId)!;
  const { currentPeriod, daysBack } = useCurrentAndPreviousPeriods();
  const granularity = getGranularity(daysBack);

  const filters = applyProductFilter(
    [
      {
        member: 'SubscriptionV2.rollingActiveCount',
        operator: 'gt',
        values: ['0'],
      },
      {
        member: 'SubscriptionV2.environmentId',
        operator: 'equals',
        values: [currentEnvironmentId],
      },
    ],
    productFilter,
  );

  return useCubeQuery(
    {
      measures: ['SubscriptionV2.rollingActiveCount'],
      timeDimensions: [
        {
          dimension: 'SubscriptionV2.startDate',
          granularity,
          dateRange: currentPeriod,
        },
      ],
      dimensions: ['Package.displayName'],
      order: [['SubscriptionV2.startDate', 'asc']],
      filters,
    },
    { skip: skipCubeQuery },
  );
};

export function SubscriptionsOverTimeWidget() {
  const isShowMockData = useIsShowMockData();
  const { daysBack, duration } = useCurrentAndPreviousPeriods();
  const aDuration = duration || { unit: 'day', amount: daysBack };

  const realData = useCubeRealData({ skipCubeQuery: isShowMockData });
  const mockData = useCubeSubscriptionStaticData({ mock: sandboxStaticCubeData });

  const { error, isLoading, resultSet } = isShowMockData ? mockData : realData;

  const title = t('dashboards.subscriptionsOverview.activeSubscriptionsOverTime');

  useSetChartLastUpdate(title, resultSet);

  return (
    <WidgetCard
      colSpan={8}
      rowSpan={3}
      height={540}
      title={<WidgetTitle color="primary">{title}</WidgetTitle>}
      subtitle={
        <WidgetSubtitle color="secondary">
          {t('dashboards.subscriptionsOverview.lastPeriod', {
            count: aDuration.amount,
            unit: aDuration.unit,
          })}
        </WidgetSubtitle>
      }
      isLoading={isLoading}>
      <WidgetChartContainer isLoading={isLoading} error={error} resultSet={resultSet}>
        {(resultSet) => <AreaChartRenderer resultSet={resultSet} />}
      </WidgetChartContainer>
    </WidgetCard>
  );
}
