import customersIconDark from './customers.dark.svg';
import customersIconLight from './customers.light.svg';
import { getCustomerEmailDomain, getDomainIcon } from '../../customers/components/utils/customerIcon.utils';

// commandbar can only render strings, not react components
export function getCommandBarCustomerIcon(email?: string | null, isLightTheme?: boolean) {
  const { emailDomain, isValidDomain } = getCustomerEmailDomain(email);
  const customersIcon = isLightTheme ? customersIconLight : customersIconDark;
  const domainIcon = isValidDomain ? getDomainIcon(emailDomain) : customersIcon;

  return `<object data="${domainIcon}" type="image/png" width="24" height="24" ><img src="${customersIcon}" width="24" height="24"/></object>`;
}
