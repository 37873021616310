import { FormRenderProps, Grid } from '@stigg-components';
import { Feature } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { includes } from 'lodash';
import compact from 'lodash/compact';
import { EntitlementFields, EntitlementFormFields } from './types';
import {
  BasePlanProps,
  PlanEntitlement,
} from '../../../packages/common/components/packageGrantedEntitlements/PackageGrantedEntitlements.types';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { FeatureOptionType, NewEntitlementsOption } from '../../../packages/plans/components/addEntitlement/types';
import { fetchFeaturesAction } from '../../../features/featuresSlice';
import { AutocompleteSearch } from '../../../packages/plans/components/AutoCompleteSearch';
import { FeatureOption } from '../../../packages/plans/components/addEntitlement/FeatureOption';
import { AddFeatureProps } from '../../../packages/plans/components/addEntitlement/AddEntitlementsDrawer';

type EntitlementFeatureAutocompleteProps = {
  formRenderProps: FormRenderProps<EntitlementFormFields>;
  excludedFeaturesIds: string[];
  chooseFeature: (option: Feature | null) => void;
  setCreateFeatureFlowProps: (props: AddFeatureProps) => void;
  basePlanEntitlementsByFeatureId: Map<string, PlanEntitlement>;
  basePlan?: BasePlanProps | null;
};

export function EntitlementFeatureAutocomplete({
  formRenderProps,
  excludedFeaturesIds,
  chooseFeature,
  setCreateFeatureFlowProps,
  basePlan,
  basePlanEntitlementsByFeatureId,
}: EntitlementFeatureAutocompleteProps) {
  const dispatch = useAppDispatch();
  const { edges } = useSelector((state: RootState) => state.featuresReducer.features);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const [searchOptions, setSearchOptions] = useState<readonly NewEntitlementsOption[]>([]);
  const isFetchingFeatures = useSelector((state: RootState) => state.featuresReducer.isLoading);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    void dispatch(fetchFeaturesAction({ environmentId: currentEnvironmentId }));
  }, [dispatch, currentEnvironmentId]);

  useEffect(() => {
    const addNewFeatureOption: any = { optionType: FeatureOptionType.ADD_FEATURE };

    setSearchOptions(
      edges?.length > 0
        ? [...edges.map((edge) => ({ ...edge.node, isAlreadyAdded: true })), addNewFeatureOption]
        : [{ displayName: t('entitlements.noFeaturesCreated') }, addNewFeatureOption],
    );
  }, [edges]);

  const onSearchKeyUp = (event: any) => {
    const search = event.target.value;

    setSearchQuery(search);
    void dispatch(fetchFeaturesAction({ search, environmentId: currentEnvironmentId }));
  };

  const isOptionDisabled = (entitlements: EntitlementFields[], option: Feature) => {
    const selectedFeatureIds = compact(entitlements.map((ent: any) => ent.feature?.id));
    return includes([...selectedFeatureIds, ...excludedFeaturesIds], option.id) || !option.refId;
  };

  const onAddFeatureClick = () => {
    setCreateFeatureFlowProps({
      name: searchQuery,
      onCreated: chooseFeature,
    });
  };

  return (
    <Grid data-testid="entitlements-dialog-search-feature" $fullWidth>
      <AutocompleteSearch
        chooseOption={(option) => chooseFeature(option)}
        isOptionDisabled={(option: Feature) => isOptionDisabled(formRenderProps.values.entitlements, option)}
        searchOptions={searchOptions}
        onSearchKeyUp={onSearchKeyUp}
        isFetching={isFetchingFeatures}
        placeholder={t('entitlements.searchPlaceholder')}
        getOptionLabel={(option) => `${option.displayName} (${option.refId})`}
        renderOption={(props: any, option: NewEntitlementsOption) => (
          <FeatureOption
            key={option.id}
            optionProps={props}
            option={option}
            onAddFeatureClick={onAddFeatureClick}
            overriddenEntitlement={basePlanEntitlementsByFeatureId.get(option.id)}
            basePlan={basePlan}
          />
        )}
        isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
      />
    </Grid>
  );
}
