import { t } from 'i18next';
import { GridFlex, Icon, Text } from '@stigg-components';
import { PlanFragment, PlanListFragment } from '@stigg-types/apiTypes';
import { downgradeProductFormat, formatTrialPeriod, TrialConfigPresentationModel } from '@stigg-common';
import { useNavigation } from '../../../../../navigation/useNavigation';

type TrialFootnotesProps = {
  trialConfigModel: TrialConfigPresentationModel;
  currentPlan?: PlanListFragment | PlanFragment;
};

export function TrialFootnotes({ trialConfigModel, currentPlan }: TrialFootnotesProps) {
  const navigation = useNavigation();

  const defaultTrialDurationText = formatTrialPeriod(
    currentPlan?.defaultTrialConfig?.duration,
    currentPlan?.defaultTrialConfig?.units,
  );
  const productSubscriptionEndSetup = currentPlan?.product.productSettings.subscriptionEndSetup;
  const downgradePlan = currentPlan?.product.productSettings.downgradePlan;

  return (
    <GridFlex.Column>
      <GridFlex.RowCenter item mt={2}>
        <Icon icon="Info" type="reactFeather" size={18} color="active" />
        <Text.Caption $capitalize color="secondary" mt={1} ml={2}>
          {trialConfigModel.hasDefaultTrialConfig()
            ? t('customers.trials.trialEndDateHelper', { trialLengthStr: defaultTrialDurationText })
            : defaultTrialDurationText}
        </Text.Caption>
      </GridFlex.RowCenter>
      <GridFlex.RowCenter item>
        <Icon icon="Info" type="reactFeather" size={18} color="active" />
        <Text.Caption $capitalize color="secondary" mt={1} ml={2}>
          {`${t('customers.trials.whenTrialEnds')}, `}
          {downgradeProductFormat(navigation, productSubscriptionEndSetup, downgradePlan)}
        </Text.Caption>
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
