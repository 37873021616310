import moment from 'moment-timezone';
import { padStart } from 'lodash';

export type FormatOptions = {
  formatType?: DateFormat;
  timezone?: string;
  withTime?: boolean;
  withTimezone?: boolean;
  withYear?: boolean;
};

type DateFormat = keyof typeof dateFormats;

const dateFormats = {
  standard: ({ withTime = false, withTimezone = false, withYear = true }: FormatOptions = {}) =>
    `MMMM Do${withYear ? ', YYYY' : ''}${withTime ? ' h:mm A' : ''}${withTimezone ? ' z' : ''}`,
  shortDate: ({ withTime = false, withTimezone = false, withYear = false }: FormatOptions = {}) =>
    `MMM Do${withYear ? ', YYYY' : ''}${withTime ? ' h:mm A' : ''}${withTimezone ? ' z' : ''}`,
  longDate: ({ withTime = false, withTimezone = false, withYear = true }: FormatOptions = {}) =>
    `MMM DD${withYear ? ', YYYY' : ''}${withTime ? ' h:mm:ss A' : ''}${withTimezone ? ' z' : ''}`,
};

export function formatDate(date: Date | string, options: FormatOptions = {}) {
  const { formatType = 'standard', timezone = '' } = options;
  const format = dateFormats[formatType](options);

  return moment.tz(date, timezone).format(format);
}

export function getTimezoneUtcOffset(timezone: string) {
  const utcOffset = moment.tz(timezone).utcOffset();
  const utcHoursOffset = Math.abs(Math.floor(utcOffset / 60)).toString();
  const utcMinutesOffset = Math.abs(utcOffset % 60).toString();

  const timeDifference = `${padStart(utcHoursOffset, 2, '0')}:${padStart(utcMinutesOffset, 2, '0')}`;
  const timeSymbol = utcOffset >= 0 ? '+' : '-';

  return `UTC${timeSymbol}${timeDifference}`;
}

export function formatTimezone(timezone: string) {
  const utcOffsetFormat = getTimezoneUtcOffset(timezone);
  const timezoneFormat = timezone.replace(/\//g, ' - ').replace(/_/g, ' ');

  return `${timezoneFormat} (${utcOffsetFormat})`;
}

export function formatTimeSince(date: Date | string) {
  return moment(date).fromNow();
}
