import { Text, GridFlex, Icon, Grid, LoadingButton } from '@stigg-components';
import { t } from 'i18next';
import React, { useState } from 'react';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { Trans } from 'react-i18next';
import numeral from 'numeral';
import { Dialog } from '../../../../../../components/Dialog';
import { useAppDispatch } from '../../../../../../redux/store';
import { startExperimentAction } from '../../../../experimentsSlice';
import { PERCENT_FORMAT } from '../../../../../../components/SplitRate';

type StartExperimentConfirmationDialogProps = {
  open: boolean;
  setDialogOpen: (state: boolean) => void;
  experiment: ExperimentFragment;
};

export function StartExperimentDialog({ open, setDialogOpen, experiment }: StartExperimentConfirmationDialogProps) {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onCancel = () => setDialogOpen(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await dispatch(startExperimentAction({ refId: experiment.refId }));
    setIsSubmitting(false);
  };

  const dialogContent = (
    <GridFlex.ColumnMiddle mt={10}>
      <Text.H3 color="primary" textAlign="center" maxWidth={400} mb={8}>
        {t('experiments.startExperiment.confirmationDialog.header')}
      </Text.H3>
      <Text.B2 color="secondary" textAlign="center">
        <Trans
          i18nKey="experiments.startExperiment.confirmationDialog.note"
          t={t}
          components={[<Text.B2 $bold display="inline" />]}
        />
      </Text.B2>
      <GridFlex.RowCenter justifyContent="center" columnGap={6} my={8}>
        <Icon icon="ExperimentVisualize" />
        <GridFlex.ColumnCenter>
          <Text.B2>{experiment.controlGroupName}</Text.B2>
          <Text.H3 mb={12}>{numeral(1 - experiment.variantPercentage / 100).format(PERCENT_FORMAT)}</Text.H3>
          <Text.B2>{experiment.variantGroupName}</Text.B2>
          <Text.H3>{numeral(experiment.variantPercentage / 100).format(PERCENT_FORMAT)}</Text.H3>
        </GridFlex.ColumnCenter>
      </GridFlex.RowCenter>
      <Grid item alignSelf="center">
        <LoadingButton
          type="button"
          loadingPosition="start"
          loading={isSubmitting}
          startIcon={<Icon icon="Play" />}
          onClick={handleSubmit}
          variant="contained"
          color="startOperation">
          {t('experiments.startExperiment.action')}
        </LoadingButton>
      </Grid>
    </GridFlex.ColumnMiddle>
  );

  return <Dialog onCancel={onCancel} content={dialogContent} isOpen={open} width={565} />;
}
