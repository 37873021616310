import { Icon, Text, MenuItem as BaseMenuItem, Select, Box, Button, LongText, GridFlex, Flex } from '@stigg-components';
import { t } from 'i18next';
import map from 'lodash/map';
import uppercase from 'lodash/upperCase';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@stigg-theme';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '../../../components/Dialog';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useSwitchEnvironment } from '../../navigation/useSwitchEnvironment';
import { addEnvironmentAction, fetchAccountAction } from '../accountsSlice';
import EditEnvironment, { EnvironmentFormFields } from './EnvironmentDetailsForm';
import { SelectProps } from '../../../components/Select';
import { MergeEnvironmentsDrawer } from './mergeEnvironments/MergeEnvironmentsDrawer';

export const ENVIRONMENT_DEFAULT_COLOR = '#327EEE';

const Wrapper = styled(Box)<{ $isSidebarOpen?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.sidebar.background};
  padding: ${(props) => props.theme.spacing(props.$isSidebarOpen ? 4 : 2.25)}
    ${(props) => props.theme.spacing(props.$isSidebarOpen ? 4 : 2.25)};
  border-right: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

const EnvironmentName = styled(Text.B2)<{ $isSidebarOpen: boolean }>`
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => props.theme.itamar.palette.white};
  font-size: ${({ $isSidebarOpen }) => ($isSidebarOpen ? '14px' : '22px')};
`;

const AccountName = styled(EnvironmentName)`
  color: rgba(255, 255, 255, 0.54);
`;

const MenuItem = styled(BaseMenuItem)`
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.environmentSwitch.hoverBackground} !important;
  }
  &,
  & > * {
    width: 100%;
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.itamar.palette.environmentSwitch.selectedBackground} !important;
  }
`;

const HeaderMenuItem = styled(Box)`
  margin: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
`;

const ButtonMenuItem = styled(Box)`
  margin: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(2)} 0;
`;

export const EnvironmentSticklight = styled(Box)<{ $isSidebarOpen?: boolean; $color: string }>`
  min-width: 6px;
  width: 6px;
  height: 32px;
  background: ${(props) =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), ${props.$color}`};
  border-radius: 10px;
  margin-right: ${(props) => props.theme.spacing(props.$isSidebarOpen ? 4 : 2)};
  margin-left: ${(props) => props.theme.spacing(props.$isSidebarOpen ? 0 : -1)};
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
  pointer-events: none;
`;

const SelectBorder = styled(Box)<{ $color: string }>`
  padding: 1px;
  border-radius: 10px;
  background: ${(props) => `linear-gradient(180deg, ${props.$color} 0%, ${props.$color}75 100%)`};
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export function EnvironmentSwitch({ isSidebarOpen }: { isSidebarOpen: boolean }) {
  const theme = useTheme();
  const switchEnvironment = useSwitchEnvironment();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  // environments are fetched during bootstrapping
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);
  const [open, setOpen] = useState(false);
  const [isMergeEnvironmentsOpen, setIsMergeEnvironmentsOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(fetchAccountAction());
  }, [dispatch]);

  const handleChange = ({ target: { value: selectedEnvironmentId } }: any) => {
    if (selectedEnvironmentId) {
      switchEnvironment(selectedEnvironmentId);
    }
  };

  const handleSubmit = async (values: EnvironmentFormFields) => {
    try {
      const newEnvironment = await dispatch(addEnvironmentAction({ environment: values })).unwrap();
      setOpen(false);
      setIsSelectOpen(false);
      switchEnvironment(newEnvironment.id);
    } catch (err) {
      // handle error silently and prevent unhandled promise rejection
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  if (isLoadingEnvironments || !currentEnvironmentId) {
    return null;
  }

  const environmentColor = currentEnvironment?.color || ENVIRONMENT_DEFAULT_COLOR;

  const selectMinimizedProps: Partial<SelectProps> = isSidebarOpen
    ? {}
    : {
        paperStyle: { top: '60px !important', left: '73px !important' },
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 'bottom' },
      };

  return (
    <Wrapper $isSidebarOpen={isSidebarOpen}>
      <SelectBorder $color={environmentColor}>
        <Select
          open={isSelectOpen}
          onOpen={() => setIsSelectOpen(true)}
          onClose={() => setIsSelectOpen(false)}
          backgroundColor={theme.itamar.palette.environmentSwitch.background}
          optionsBackgroundColor={theme.itamar.palette.environmentSwitch.background}
          borderColor={theme.itamar.palette.environmentSwitch.border}
          value={currentEnvironmentId}
          onChange={handleChange}
          fullWidth
          displayEmpty
          height={isSidebarOpen ? 58 : 44}
          menuMinWidth={225}
          disablePaddingInMenuItems={false}
          IconComponent={() => {
            if (!isSidebarOpen) {
              return null;
            }
            return (
              <IconWrapper mr={2}>
                <Icon
                  type="custom"
                  icon="ChevronsUpDown"
                  color={theme.isLightTheme ? 'active' : 'primary.main'}
                  size={16}
                  overrideStroke
                />
              </IconWrapper>
            );
          }}
          renderValue={(value: any) => {
            const selectedEnv = environments.find((env) => env.id === value);
            return (
              <Flex.RowCenter>
                <EnvironmentSticklight
                  $color={selectedEnv?.color || ENVIRONMENT_DEFAULT_COLOR}
                  $isSidebarOpen={isSidebarOpen}
                />
                <GridFlex.Column data-testid="select-env">
                  <EnvironmentName $isSidebarOpen={isSidebarOpen}>
                    {isSidebarOpen ? selectedEnv?.displayName : uppercase(selectedEnv?.displayName[0])}
                  </EnvironmentName>
                  {isSidebarOpen && <AccountName $isSidebarOpen={isSidebarOpen}>{account?.displayName}</AccountName>}
                </GridFlex.Column>
              </Flex.RowCenter>
            );
          }}
          {...selectMinimizedProps}>
          <HeaderMenuItem>
            <Text.B2 color={theme.isLightTheme ? 'disabled' : 'secondary'}>{t('accounts.selectEnv')}</Text.B2>
          </HeaderMenuItem>
          {map(environments, (environment) => (
            <MenuItem data-testid={`environment-item-${environment.slug}`} key={environment.id} value={environment.id}>
              <EnvironmentSticklight
                $color={environment.color || ENVIRONMENT_DEFAULT_COLOR}
                sx={{ height: 20, mr: 2 }}
              />
              <LongText color="white" variant="body2" wordBreak>
                {environment?.displayName}
              </LongText>
            </MenuItem>
          ))}
          <ButtonMenuItem>
            <Button
              sx={{ width: '100%', height: '28px' }}
              startIcon={
                <Icon
                  type="materialIcons"
                  icon="Add"
                  size={20}
                  color={theme.isLightTheme ? undefined : 'primary.main'}
                />
              }
              color="outlineBorder"
              textColor="white"
              size="small"
              $outlined
              onClick={() => setOpen(true)}>
              {t('accounts.addNewEnvButton')}
            </Button>
          </ButtonMenuItem>
          <ButtonMenuItem>
            <Button
              sx={{ width: '100%', height: '28px' }}
              startIcon={
                <Icon
                  type="materialIcons"
                  icon="CompareArrows"
                  size={20}
                  color={theme.isLightTheme ? undefined : 'primary.main'}
                />
              }
              color="outlineBorder"
              textColor="white"
              size="small"
              $outlined
              onClick={() => {
                setIsSelectOpen(false);
                setIsMergeEnvironmentsOpen(true);
              }}>
              {t('accounts.copyEnvToButton')}
            </Button>
          </ButtonMenuItem>
        </Select>
      </SelectBorder>

      <Dialog
        content={<EditEnvironment onSubmit={handleSubmit} onCancel={onCancel} />}
        onCancel={onCancel}
        titleText={t('accounts.addEnv')}
        isOpen={open}
      />

      <MergeEnvironmentsDrawer
        open={isMergeEnvironmentsOpen && !!currentEnvironment}
        onClose={() => setIsMergeEnvironmentsOpen(false)}
        environment={currentEnvironment || null}
      />
    </Wrapper>
  );
}
