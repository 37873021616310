import React from 'react';
import {
  Grid,
  MenuItem,
  TextFieldProps,
  Select,
  TextField,
  Text,
  GridFlex,
  InformationTooltip,
  Box,
  FormRenderProps,
  DottedText,
  Icon,
  Switch,
  FormControlLabel,
  drawFormFields,
  CollapsableSection,
  SearchableSelect,
} from '@stigg-components';
import { FormikHandlers } from 'formik';
import { t } from 'i18next';
import { get, isFunction } from 'lodash';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { getDateRemainingDays } from '@stigg-common';
import isNil from 'lodash/isNil';
import { SelectFields, Field } from './Form.types';
import { DynamicDataSearchableSelect } from '../DynamicDataSearchableSelect';

type SelectProps = React.ComponentProps<typeof Select>;

function FormSelect<FormValues extends Record<string, any>>({
  field,
  values,
  handleChange,
  restProps,
}: {
  field: Omit<SelectFields<FormValues>, 'type'>;
  values: FormValues;
  handleChange: FormikHandlers['handleChange'];
  restProps?: Partial<SelectProps>;
}) {
  const { id, label, values: selectValues } = field;
  const currentValue = get(values, id);
  const hasEmptyValue = selectValues.some(({ value }) => isNil(value));

  const renderValue = (value: any) => {
    const selectValue = selectValues.find((v: any) => v.value === value);
    if (!selectValue) {
      return null;
    }
    const v = selectValue?.overrideDisplayValue || selectValue?.displayValue;
    if (field.variant !== 'inline') {
      if (field.startAdornment) {
        return (
          <GridFlex.RowCenter columnGap={2}>
            {field.startAdornment}
            <Text.B2>{v}</Text.B2>
          </GridFlex.RowCenter>
        );
      }
      // optional prop. will be set to inline only when needed
      return <Text.B2>{v}</Text.B2>;
    }
    return field.hideDottedText ? <Text.B2>{v}</Text.B2> : <DottedText>{v}</DottedText>;
  };

  return (
    <Select
      withoutBorder={field.withoutBorder}
      withoutPadding={field.withoutPadding}
      variant={field.variant}
      helpTooltipText={field.helpTooltipText}
      optional={field.optional}
      renderValue={renderValue}
      hasEmptyValue={hasEmptyValue}
      labelId={id}
      displayEmpty={field.displayEmpty}
      disabled={isFunction(field.disabled) ? field.disabled(values) : Boolean(field.disabled)}
      disabledCursorNotAllowed={field.disabledCursorNotAllowed}
      hideSelectIcon={field.hideSelectIcon}
      tooltip={field.tooltip}
      fullWidth={!field.maxWidth}
      label={label}
      value={currentValue}
      name={id}
      maxWidth={field.maxWidth}
      onChange={handleChange}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder}
      {...restProps}
      {...(field.restProps || {})}>
      {selectValues.map((selectValue) => {
        const displayValue = selectValue.displayValue || selectValue.value;
        const { subtitle, value, subtitlePosition, disabled, disabledText, tooltip, isCategory, key, divider } =
          selectValue;
        const selectSubtitlePosition = subtitlePosition || 'bottom';
        const isDisabled = isFunction(disabled) ? disabled(values) : Boolean(disabled);

        return (
          <MenuItem disabled={isCategory || isDisabled} key={key || value} value={value} divider={divider}>
            <InformationTooltip
              arrow
              placement={tooltip?.placement || 'right'}
              title={tooltip?.title || ''}
              $maxWidth={tooltip?.maxWidth}
              PopperProps={{
                onClick: (e) => (isCategory || isDisabled) && e.stopPropagation(),
              }}>
              <Grid
                onClick={(e) => (isCategory || isDisabled) && e.stopPropagation()}
                sx={{ pointerEvents: 'auto' }}
                container
                flexDirection={selectSubtitlePosition === 'bottom' ? 'column' : 'row'}
                justifyContent={selectSubtitlePosition === 'bottom' ? 'normal' : 'space-between'}>
                <Grid item>
                  <Text.B2 mr={2} $bold={isCategory}>
                    {displayValue}
                  </Text.B2>
                  <Text.B2>{isDisabled && disabledText ? disabledText : ''}</Text.B2>
                </Grid>
                {subtitle && (
                  <Grid item>
                    <Text.Sub2>{subtitle}</Text.Sub2>
                  </Grid>
                )}
              </Grid>
            </InformationTooltip>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export function handleFormField<FormValues extends Record<string, any>>(
  field: Field<FormValues>,
  formRenderProps: FormRenderProps<FormValues>,
): React.ReactNode {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = formRenderProps;

  const onChange: FormikHandlers['handleChange'] = (e: React.ChangeEvent<any>) => {
    const handleChangeAction = field?.handleChange?.(formRenderProps) ?? handleChange;

    return handleChangeAction(e);
  };

  switch (field.type) {
    case 'idle': {
      return field.component;
    }
    case 'text': {
      const isOutlinedVariant = field.variant === 'outlined';

      return (
        <TextField
          autoFocus={field.autoFocus}
          type={field.textFieldType || 'text'}
          name={field.id}
          label={isOutlinedVariant ? '' : field.label}
          value={get(values, field.id)}
          touched={!!get(touched, field.id)}
          error={!!get(errors, field.id)}
          fullWidth
          errorText={!field.hideErrorText && get(errors, field.id)}
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={field.placeholder}
          disabled={!!field.disabled}
          helpTooltipText={field.helpTooltipText}
          helpTooltipMaxWidth={field.helpTooltipMaxWidth}
          optional={field.optional}
          captionText={field.captionText}
          isNumberWithoutSigns={field.isNumberWithoutSigns}
          isNumberWithoutFraction={field.isNumberWithoutFraction}
          startAdornment={field.startAdornment}
          endAdornment={field.endAdornment}
          dataTestId={field.dataTestId}
          {...field.textFieldProps}
        />
      );
    }
    case 'select': {
      return <FormSelect field={field} values={values} handleChange={onChange} />;
    }
    case 'searchableSelect': {
      return (
        <SearchableSelect
          options={field.options}
          selectedOptionId={get(values, field.id)}
          placeholder={field.placeholder}
          maxOptionsToShow={field.maxOptionsToShow}
          isOptionDisabled={field.isOptionDisabled}
          onChange={field.onChange ? (id) => field.onChange!(id) : (itemKey) => setFieldValue(field.id, itemKey)}
          label={field.label}
          dataTestId={field.dataTestId}
        />
      );
    }
    case 'dynamicDataSearchableSelect': {
      return (
        <DynamicDataSearchableSelect
          label={field.label}
          placeholder={field.placeholder}
          useDataLoader={field.useDataLoader}
          selectedOptionId={get(values, field.id)}
          onChange={(option) => (field.onChange ? field.onChange(option) : setFieldValue(field.id, option.id))}
          dataTestId={field.dataTestId}
          formRenderProps={formRenderProps}
        />
      );
    }
    case 'switch': {
      const getActualValue = (value: boolean) => (field.negateValue ? !value : value);
      const checked: boolean = getActualValue(get(values, field.id));
      const isDisabled = isFunction(field.disabled) ? field.disabled(values) : field.disabled;
      const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newValue = getActualValue(checked);
        setFieldValue(field.id, newValue);
        field.onChange?.(newValue);
      };

      return (
        <FormControlLabel
          name={field.id}
          value={checked}
          label={<Text.B2>{field.label}</Text.B2>}
          control={
            field.disabledTooltipProps?.title ? (
              <InformationTooltip
                arrow
                placement="top"
                $maxWidth={field.disabledTooltipProps?.maxWidth}
                title={isDisabled && <Text.B2>{field.disabledTooltipProps?.title}</Text.B2>}>
                <Grid>
                  <Switch
                    disabled={isDisabled}
                    checked={checked}
                    onChange={onSwitchChange}
                    data-testid={field.dataTestId}
                  />
                </Grid>
              </InformationTooltip>
            ) : (
              <Switch
                disabled={isDisabled}
                checked={checked}
                onChange={onSwitchChange}
                data-testid={field.dataTestId}
              />
            )
          }
        />
      );
    }
    case 'datePicker': {
      const { remainingDays, isInRange, isLessThenOneDay } = getDateRemainingDays(values[field.id]);
      const isDisabled = isFunction(field.disabled) ? field.disabled(values) : field.disabled;

      return (
        <Grid container>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={values[field.id]}
              disabled={isDisabled}
              disableFuture={field.disableFuture}
              InputAdornmentProps={{
                sx: { pr: 2 },
              }}
              inputFormat="DD/MM/YYYY"
              onChange={(value) => {
                setFieldTouched(field.id);
                setFieldValue(field.id, value);
              }}
              PaperProps={{
                sx: {
                  boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
                },
              }}
              components={{
                OpenPickerIcon: () => (
                  <Icon icon="DateRange" type="materialIcons" color={isDisabled ? 'disabled' : 'active'} />
                ),
              }}
              renderInput={(params) => (
                <TextField
                  name="datePick"
                  fullWidth
                  {...params}
                  label={field.label}
                  onBlur={handleBlur}
                  errorText={t('fieldValidationMessages.invalidDate')}
                  error={!!errors[field.id]}
                  touched={!!touched[field.id]}
                  captionText={
                    (field.showRemainingDays &&
                      isInRange &&
                      (isLessThenOneDay
                        ? t('subscriptions.inDaysZero')
                        : t('subscriptions.inDays_other', { count: remainingDays }))) ||
                    undefined
                  }
                />
              )}
              minDate={field.minDate}
            />
          </LocalizationProvider>
        </Grid>
      );
    }
    case 'custom': {
      const renderTextField = (id: string, label?: string, props?: Partial<TextFieldProps>) => {
        return (
          <TextField
            type="text"
            name={id}
            label={label}
            value={get(values, id)}
            touched={!!get(touched, id)}
            error={!!get(errors, id)}
            errorText={get(touched, id) && get(errors, id)}
            fullWidth
            onBlur={handleBlur}
            onChange={onChange}
            {...props}
          />
        );
      };

      const renderSelect = (
        id: string,
        label: string,
        selectValues: Array<{ value: any; displayValue?: string; subtitle?: string }>,
        restProps?: Partial<SelectProps>,
      ) => {
        return (
          <FormSelect
            field={{ id, label, values: selectValues }}
            values={formRenderProps.values}
            handleChange={onChange}
            restProps={restProps}
          />
        );
      };
      return field.render({
        ...formRenderProps,
        renderSelect,
        renderTextField,
      });
    }

    case 'layout': {
      return (
        <GridFlex.Row $fullWidth gap={4} {...(field.contentGridProps || {})}>
          {field.label && (
            <GridFlex.Row mr={10} width={85} {...(field.labelGridProps || {})}>
              <Text.B2 color="primary">{field.label}</Text.B2>
            </GridFlex.Row>
          )}
          {field.fields.map(
            (subField, index) =>
              !subField.hide?.(values) && (
                <GridFlex.Item key={index} flex={1} {...(subField.gridProps || {})}>
                  {handleFormField(subField, formRenderProps)}
                </GridFlex.Item>
              ),
          )}
        </GridFlex.Row>
      );
    }

    case 'inline': {
      return (
        <Box display="inline">
          {field.fields.map(
            (subField, index) =>
              !subField.hide?.(values) && (
                <Box key={index} display="inline">
                  {handleFormField(subField, formRenderProps)}
                </Box>
              ),
          )}
        </Box>
      );
    }

    case 'collapse': {
      return (
        <CollapsableSection
          title={<Text.B2 {...(field.titleTextProps || {})}>{field.title}</Text.B2>}
          isSectionOpen={field.open}
          onClick={field.onClick}
          titleGridSx={field.titleGridSx}
          content={drawFormFields(field.fields, formRenderProps)}
          $fullWidth
          hideIcon={field.hideIcon}
          gap={field.gap}
          iconSize={field.iconSize}
          dataTestId={field.dataTestId}
        />
      );
    }

    default:
      return null;
  }
}
