import { gql } from '@apollo/client';
import { t } from 'i18next';
import { executeOperationSafely } from '@stigg-common';
import {
  PreparedPaymentMethodForm,
  PreparePaymentMethodFormInput,
  PreparePaymentMethodFormMutation,
  PreparePaymentMethodFormMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';

const INIT_ADD_CUSTOMER_PAYMENT_METHOD = gql`
  mutation PreparePaymentMethodForm($input: PreparePaymentMethodFormInput!) {
    preparePaymentMethodForm(input: $input) {
      vendorIdentifier
      paymentMethodForm {
        ... on StripePaymentMethodForm {
          clientSecret
          stripePublishableKey
        }
        ... on ZuoraPaymentMethodForm {
          signature
          token
          tenantId
          key
          pageId
          pageUrl
        }
      }
    }
  }
`;

async function preparePaymentMethodForm(
  input: PreparePaymentMethodFormInput,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<PreparedPaymentMethodForm> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<
        PreparePaymentMethodFormMutation,
        PreparePaymentMethodFormMutationVariables
      >({
        mutation: INIT_ADD_CUSTOMER_PAYMENT_METHOD,
        variables: { input: { ...input, environmentId: accountReducer.currentEnvironmentId } },
      });

      if (!response.data) {
        throw new Error('Invalid response');
      }

      return response.data.preparePaymentMethodForm;
    },
    {
      failureMessageHandler: () => t('customers.api.preparePaymentMethodForm.prepareFail'),
    },
    dispatch,
  );
}

export { preparePaymentMethodForm };
