const FETCH_ICON_SIZE = 32;
const EXCLUDED_DOMAINS = ['gmail.com'];

const getEmailDomain = (email?: string | null, domain?: string) => {
  return (email && email.split('@')[1]) || domain || '';
};

export const isValidEmailDomain = (domain?: string) => {
  return !!domain && !EXCLUDED_DOMAINS.some((excludedDomain) => new RegExp(`${excludedDomain}$`, 'i').test(domain));
};

export const getCustomerEmailDomain = (email?: string | null, domain?: string) => {
  const emailDomain = getEmailDomain(email, domain);
  const isValidDomain = isValidEmailDomain(emailDomain);
  return {
    emailDomain,
    isValidDomain,
  };
};

export const getDomainIcon = (domain: string) => {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${FETCH_ICON_SIZE}`;
};
