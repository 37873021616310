import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  AggregatedUsageByCustomerFragment,
  FetchAggregatedUsageByCustomerQuery,
  FetchAggregatedUsageByCustomerQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { Aggregation, Filter } from '../components/createFeature/SidenavCreateFeature';

const FETCH_AGGREGATED_USAGE_BY_CUSTOMER = gql`
  query FetchAggregatedUsageByCustomer($input: AggregatedEventsByCustomerInput!) {
    aggregatedEventsByCustomer(input: $input) {
      aggregatedUsage {
        ...AggregatedUsageByCustomerFragment
      }
    }
  }
  fragment AggregatedUsageByCustomerFragment on CustomerAggregatedUsage {
    customerId
    usage
  }
`;

async function fetchAggregatedUsageByCustomer(
  { filters, aggregation, customerId }: { filters: Filter[]; aggregation: Aggregation; customerId?: string },
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<AggregatedUsageByCustomerFragment[]> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<
        FetchAggregatedUsageByCustomerQuery,
        FetchAggregatedUsageByCustomerQueryVariables
      >({
        query: FETCH_AGGREGATED_USAGE_BY_CUSTOMER,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId!,
            filters,
            aggregation,
            customerId,
          },
        },
      });

      return res.data.aggregatedEventsByCustomer.aggregatedUsage;
    },
    {
      failureMessageHandler: () => t('features.api.failFetchUsageEvents'),
    },
    dispatch,
  );
}

export { fetchAggregatedUsageByCustomer };
