import { PropsWithChildren } from 'react';
import { InformationTooltip, ButtonProps } from '@stigg-components';
import { ButtonGroupPosition, StyledSelectableButton } from './SelectableButton.style';
import { TooltipFields } from '../InformationTooltip';

export function SelectableButton({
  onClick,
  isSelected,
  sx,
  className,
  children,
  disabled,
  buttonGroupPosition = 'middle',
  dataTestId,
  tooltip,
}: PropsWithChildren<{
  onClick: () => void;
  isSelected?: boolean;
  sx?: ButtonProps['sx'];
  className?: string;
  disabled?: boolean;
  buttonGroupPosition?: ButtonGroupPosition;
  dataTestId?: string;
  tooltip?: TooltipFields;
}>) {
  return (
    <InformationTooltip
      arrow
      placement={tooltip?.placement || 'right'}
      title={tooltip?.title || ''}
      $maxWidth={tooltip?.maxWidth}
      PopperProps={{
        onClick: (e) => disabled && e.stopPropagation(),
      }}>
      <div>
        <StyledSelectableButton
          $buttonGroupPosition={buttonGroupPosition}
          $isSelected={isSelected}
          disabled={disabled}
          className={className}
          onClick={onClick}
          data-testid={dataTestId}
          sx={sx}>
          {children}
        </StyledSelectableButton>
      </div>
    </InformationTooltip>
  );
}
