import { createFilterOptions } from '@mui/material/Autocomplete';
import { Autocomplete, Flex, Icon, ListItem, Paper, Text, TextField } from '@stigg-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import React from 'react';

export const ENVIRONMENT_SELECT_WIDTH = 360;

export interface EnvironmentOptionType {
  // Slug does not exist when selecting a "new" environment
  slug?: string;
  displayName: string;
}

interface EnvironmentsAutocompleteSearchProps {
  envOptions: EnvironmentOptionType[];
  selectedEnvironment: EnvironmentOptionType | null;
  sourceEnvironmentName: string;
  setSelectedEnvironment: (option: EnvironmentOptionType | null) => void;
}

const filter = createFilterOptions<EnvironmentOptionType>();

export const EnvironmentsAutocompleteSearch = ({
  envOptions,
  selectedEnvironment,
  sourceEnvironmentName,
  setSelectedEnvironment,
}: EnvironmentsAutocompleteSearchProps) => {
  const environmentAlreadyExists = (inputValue: string): boolean =>
    inputValue === sourceEnvironmentName || !!envOptions.find((envOption) => envOption.displayName === inputValue);
  return (
    <Autocomplete
      value={selectedEnvironment}
      onChange={(_, newValue) => {
        if (typeof newValue === 'string') {
          setSelectedEnvironment({
            displayName: newValue,
          });
        } else {
          setSelectedEnvironment(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        if (inputValue && !filtered.find((env) => env.displayName === inputValue)) {
          filtered.push({ displayName: inputValue });
        }

        return filtered;
      }}
      ListboxProps={{ style: { padding: 0 } }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={envOptions}
      PaperComponent={({ children }) => (
        <Paper sx={{ boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow, mt: 2 }}>{children}</Paper>
      )}
      getOptionLabel={(option) => option.displayName}
      renderOption={(props, option, state) => {
        const envNameAlreadyExists = environmentAlreadyExists(state.inputValue);
        return (
          <ListItem
            {...props}
            aria-disabled={option.displayName === state.inputValue && !option.slug && envNameAlreadyExists}>
            {option.slug ? (
              <Text.B2>{option.displayName}</Text.B2>
            ) : (
              <Flex.RowCenter gap={2}>
                {!envNameAlreadyExists && <Icon icon="Plus" color="primary.main" />}
                <Text.B2 color="primary.main">
                  <Trans
                    i18nKey={
                      envNameAlreadyExists
                        ? 'accounts.copyMergeEnvironments.sidebar.newEnvExistsText'
                        : 'accounts.copyMergeEnvironments.sidebar.selectNewEnvText'
                    }
                    values={{ environmentName: option.displayName }}
                    components={{ strong: <Text.B2 color="primary.main" display="inline" $bold /> }}
                  />
                </Text.B2>
              </Flex.RowCenter>
            )}
          </ListItem>
        );
      }}
      sx={{ width: ENVIRONMENT_SELECT_WIDTH }}
      renderInput={(params) => (
        <TextField
          error={false}
          touched={false}
          placeholder={t('accounts.copyMergeEnvironments.sidebar.selectPlaceholder')}
          textFieldProps={params}
          InputProps={params.InputProps}
        />
      )}
    />
  );
};
