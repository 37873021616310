import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import includes from 'lodash/includes';
import { FieldArray, FormikHandlers, FormikHelpers, FormikState } from 'formik';
import { t } from 'i18next';
import { Box, Text, Grid, GridFlex } from '@stigg-components';
import isEmpty from 'lodash/isEmpty';
import { Feature } from '@stigg-types/apiTypes';
import { generateRandomSlug } from '@stigg-common';
import { AutocompleteSearch } from '../../../../../packages/plans/components/AutoCompleteSearch';
import { fetchFeaturesAction } from '../../../../../features/featuresSlice';
import { RootState, useAppDispatch } from '../../../../../../redux/store';
import { EntitlementsTable } from '../../../../../packages/plans/components/EntitlementsTable';
import { EntitlementFields } from '../../../../../entitlements/components/entitlementSettings/types';
import { AddPromotionalEntitlementFormFields } from './AddPromotionalEntitlementForm';

export type SelectEntitlementsProps = {
  excludedFeaturesIds: string[];
  values: FormikState<AddPromotionalEntitlementFormFields>['values'];
  setFieldValue: FormikHelpers<AddPromotionalEntitlementFormFields>['setFieldValue'];
  setFieldTouched: FormikHelpers<AddPromotionalEntitlementFormFields>['setFieldTouched'];
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  touched: FormikState<AddPromotionalEntitlementFormFields>['touched'];
  errors: FormikState<AddPromotionalEntitlementFormFields>['errors'];
};

export function SelectEntitlements({
  excludedFeaturesIds,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
}: SelectEntitlementsProps) {
  const dispatch = useAppDispatch();
  const { edges } = useSelector((state: RootState) => state.featuresReducer.features);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  // autosearch related stuff
  const [searchOptions, setSearchOptions] = useState<readonly Feature[]>([]);
  const onSearchKeyUp = (event: any) => {
    void dispatch(
      fetchFeaturesAction({
        search: event.target.value,
        environmentId: currentEnvironmentId,
      }),
    );
  };
  const isFetchingFeatures = useSelector((state: RootState) => state.featuresReducer.isLoading);

  useEffect(() => {
    void dispatch(fetchFeaturesAction({ environmentId: currentEnvironmentId }));
  }, [dispatch, currentEnvironmentId]);

  useEffect(() => {
    setSearchOptions(edges ? edges.map((edge) => edge.node) : []);
  }, [edges]);

  const chooseFeature = (push: any, option: Feature | null) => {
    if (option) {
      const newEntitlement: EntitlementFields = {
        uuid: generateRandomSlug(),
        feature: option,
        isConfirmed: true,
        hasUnlimitedUsage: false,
      };
      push(newEntitlement);
    }
  };

  const isOptionDisabled = (entitlements: EntitlementFields[], option: Feature) => {
    const selectedFeautreIds = entitlements.map((ent: any) => ent.feature.id);
    return includes([...selectedFeautreIds, ...excludedFeaturesIds], option.id);
  };

  return (
    <GridFlex.Column $fullWidth>
      <Text.H3 mt={1} mb={3}>
        {t('promotionalEntitlements.createFormTitle')}
      </Text.H3>
      <FieldArray name="entitlements">
        {({ push, remove }) => (
          <Grid container wrap="nowrap" flexDirection="column" rowSpacing={3}>
            <Grid item>
              <AutocompleteSearch
                chooseOption={(option) => chooseFeature(push, option)}
                isOptionDisabled={(option: Feature) => isOptionDisabled(values.entitlements, option)}
                searchOptions={searchOptions}
                onSearchKeyUp={onSearchKeyUp}
                isFetching={isFetchingFeatures}
                placeholder={t('entitlements.searchPlaceholder')}
                getOptionLabel={(option) => `${option.displayName} (${option.refId})`}
                renderOption={(props: any, option: Feature) => (
                  <Box component="li" {...props} key={option.refId}>
                    <Grid alignItems="center" justifyContent="space-between" container spacing={6}>
                      <Grid item>
                        <Text.B2 mr={2}>{option.displayName}</Text.B2>
                        <Text.B2>{props['aria-disabled'] ? `(${t('sharedComponents.alreadyAdded')})` : ''}</Text.B2>
                      </Grid>
                      <Grid item sx={{ color: (theme) => theme.itamar.palette.grey[700] }}>
                        <Text.B2>ID: {option.refId}</Text.B2>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              />
            </Grid>

            <Grid item sx={{ maxHeight: 330 }} mb={4}>
              {!isEmpty(values.entitlements) ? (
                <EntitlementsTable
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  entitlements={values.entitlements}
                  handleChange={handleChange}
                  height={310}
                  remove={remove}
                  isAdd
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              ) : null}
            </Grid>
          </Grid>
        )}
      </FieldArray>
    </GridFlex.Column>
  );
}
