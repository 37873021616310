import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  FetchSubscriptionsOverviewFiltersQuery,
  FetchSubscriptionsOverviewFiltersQueryVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';

const FETCH_SUBSCRIPTIONS_OVERVIEW_FILTERS = gql`
  query FetchSubscriptionsOverviewFilters($productsFilter: ProductFilter) {
    products(filter: $productsFilter) {
      edges {
        node {
          id
          refId
          displayName
        }
      }
    }
  }
`;

export type ProductFilterItem = {
  id: string;
  refId: string;
  displayName: string;
};

export async function fetchSubscriptionsOverviewFilters(
  _: void,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      const res = await apolloClient.query<
        FetchSubscriptionsOverviewFiltersQuery,
        FetchSubscriptionsOverviewFiltersQueryVariables
      >({
        query: FETCH_SUBSCRIPTIONS_OVERVIEW_FILTERS,
        variables: {
          productsFilter: {
            environmentId: { eq: accountReducer.currentEnvironmentId },
          },
        },
      });

      return {
        products: res.data.products.edges.map((edge) => edge.node) as ProductFilterItem[],
      };
    },
    {
      failureMessageHandler: () => t('dashboards.subscriptionsOverview.filtersFetchError'),
    },
    dispatch,
  );
}
