import { gql } from '@apollo/client';
import {
  PreviewSubscriptionInput,
  PreviewSubscriptionMutation,
  PreviewSubscriptionMutationVariables,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const PREVIEW_SUBSCRIPTION = gql`
  mutation PreviewSubscription($input: PreviewSubscriptionInput!) {
    previewSubscription(input: $input) {
      ...SubscriptionPreviewV2Fragment
    }
  }
  fragment SubscriptionPreviewV2Fragment on SubscriptionPreviewV2 {
    immediateInvoice {
      total {
        amount
        currency
      }
      subTotal {
        amount
        currency
      }
      totalExcludingTax {
        amount
        currency
      }
      minimumSpendAdjustment {
        amount
        currency
      }
      tax {
        amount
        currency
      }
      discount {
        amount
        currency
      }
      taxDetails {
        displayName
        percentage
        inclusive
      }
      discountDetails {
        type
        value
        durationType
        durationInMonths
      }
      credits {
        initial {
          amount
          currency
        }
        used {
          amount
          currency
        }
        remaining {
          amount
          currency
        }
      }
      proration {
        prorationDate
        hasProrations
        credit {
          amount
          currency
        }
        debit {
          amount
          currency
        }
        netAmount {
          amount
          currency
        }
      }
    }

    recurringInvoice {
      total {
        amount
        currency
      }
      subTotal {
        amount
        currency
      }
      totalExcludingTax {
        amount
        currency
      }
      minimumSpendAdjustment {
        amount
        currency
      }
      tax {
        amount
        currency
      }
      discount {
        amount
        currency
      }
      taxDetails {
        displayName
        percentage
        inclusive
      }
      discountDetails {
        type
        value
        durationType
        durationInMonths
      }
    }
    billingPeriodRange {
      start
      end
    }

    isPlanDowngrade
    hasScheduledUpdates
    freeItems {
      addonId
      quantity
    }
  }
`;

type PreviewSubscriptionData = Omit<PreviewSubscriptionInput, 'environmentId'>;

async function previewSubscription(
  previewSubscriptionData: PreviewSubscriptionData,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const res = await apolloClient.mutate<PreviewSubscriptionMutation, PreviewSubscriptionMutationVariables>({
        mutation: PREVIEW_SUBSCRIPTION,
        variables: {
          input: {
            ...previewSubscriptionData,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return res.data?.previewSubscription;
    },
    { hideErrorMessages: true },
    dispatch,
  );
}

export { previewSubscription };
