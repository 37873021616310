import { GridFlex, Link, Text } from '@stigg-components';
import { SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { useNavigation } from '../../../../../navigation/useNavigation';

type SubscriptionPayingCustomerProps = {
  payingCustomer: SubscriptionDataFragment['payingCustomer'];
};

export function SubscriptionPayingCustomer({ payingCustomer }: SubscriptionPayingCustomerProps) {
  const navigation = useNavigation();

  if (!payingCustomer) {
    return null;
  }

  const customerPath = navigation.appRoutes.customerPage(payingCustomer.customerId);
  const customerUrl = navigation.composeUrl(customerPath);
  const customerName = payingCustomer.name || payingCustomer.customerId;
  const customerEmail = payingCustomer.email ? ` (${payingCustomer.email})` : null;

  return (
    <GridFlex.RowMiddle container gap={2}>
      <Link
        href={customerUrl}
        onClick={(e) => {
          e.preventDefault();
          navigation.navigateTo(customerPath);
        }}
        display="inline-block">
        {customerName}
      </Link>
      {customerEmail ? <Text.B2>{customerEmail}</Text.B2> : null}
    </GridFlex.RowMiddle>
  );
}
