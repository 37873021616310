import React, { useEffect, useCallback, useLayoutEffect, useState } from 'react';
import { Button, Text, Grid, LoadingButton } from '@stigg-components';
import { t } from 'i18next';

export type ContentRendererProps = {
  onNext: () => void;
  onBack: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  isNextValid: boolean;
  isNextLoading?: boolean;
  activeStep: number;
};

export type Step = {
  onBack?: () => void;
  content: (props: ContentRendererProps) => React.ReactNode;
  hideFooter?: boolean;
  disabled?: boolean;
};

export type StepperProps = {
  steps: Step[];
  onCancel: () => void;
  onDone: () => void;
  onStepChange?: (step: number) => void;
  isNextValid?: boolean;
  isNextLoading?: boolean;
  lastStepCTAText?: string;
  renderAdditionalControls?: () => React.ReactNode;
  hideStepsIndication?: boolean;
  defaultStep?: number;
};

enum StepDirection {
  Back,
  Next,
}

const useSteps = ({
  steps,
  onDone,
  onCancel,
  onStepChange,
  defaultStep = 0,
}: Pick<StepperProps, 'onStepChange' | 'steps' | 'onDone' | 'onCancel' | 'defaultStep'>) => {
  const [lastStepDirection, setLastStepDirection] = useState<StepDirection>(StepDirection.Next);
  const [activeStep, setActiveStep] = useState(defaultStep);
  const isFirstStep = activeStep === 0;
  const hasNextSteps = steps.some((step, index) => index > activeStep && !step.disabled);
  const isLastStep = activeStep === steps.length - 1 || !hasNextSteps;

  useEffect(() => {
    if (onStepChange) {
      onStepChange(activeStep);
    }
  }, [onStepChange, activeStep]);

  const onClickNext = useCallback(() => {
    if (isLastStep) {
      onDone();
    } else {
      const nextStep = activeStep + 1;
      if (nextStep < steps.length) {
        setActiveStep(nextStep);
        setLastStepDirection(StepDirection.Next);
      }
    }
  }, [activeStep, isLastStep, onDone, steps]);

  const onClickBack = useCallback(() => {
    if (isFirstStep) {
      onCancel();
    } else {
      const currentStep = steps[activeStep];
      if (currentStep.onBack) {
        currentStep.onBack();
      }
      setActiveStep(activeStep - 1);
      setLastStepDirection(StepDirection.Back);
    }
  }, [activeStep, isFirstStep, onCancel, steps]);

  useLayoutEffect(() => {
    if (steps[activeStep]?.disabled && !isLastStep && !isFirstStep) {
      if (lastStepDirection === StepDirection.Next) {
        onClickNext();
      } else {
        onClickBack();
      }
    }
  }, [steps, activeStep, lastStepDirection, onClickBack, onClickNext, isLastStep, isFirstStep]);

  return { onClickNext, onClickBack, activeStep, isFirstStep, isLastStep };
};

export function Stepper({
  steps,
  onCancel,
  onDone,
  isNextValid = true,
  isNextLoading,
  lastStepCTAText = t('sharedComponents.createSaveButton'),
  renderAdditionalControls,
  hideStepsIndication,
  onStepChange,
  defaultStep,
}: StepperProps) {
  const { activeStep, isFirstStep, isLastStep, onClickNext, onClickBack } = useSteps({
    steps,
    defaultStep,
    onCancel,
    onDone,
    onStepChange,
  });
  const currentStep = steps[activeStep];
  const enabledSteps = steps.filter((step) => !step.disabled);

  if (!currentStep || currentStep.disabled) {
    return null;
  }

  return (
    <Grid container flexDirection="column" $fullHeight>
      <Grid item>
        {!hideStepsIndication && (
          <Text.Sub2>
            {t('sharedComponents.stepperStepIndicationTitle')} {activeStep + 1}/{enabledSteps.length}
          </Text.Sub2>
        )}
      </Grid>
      <Grid item $fullHeight>
        {currentStep.content({
          onNext: onClickNext,
          onBack: onClickBack,
          isFirstStep,
          isLastStep,
          activeStep,
          isNextValid,
          isNextLoading,
        })}
      </Grid>

      {!currentStep.hideFooter && (
        <Grid item container mt={4} justifyContent="space-between" alignItems="center">
          <Grid item>{renderAdditionalControls?.()}</Grid>
          <Grid item alignSelf="flex-end">
            <Button sx={{ mr: 3 }} $outlined color="primary" onClick={onClickBack}>
              {isFirstStep ? t('sharedComponents.cancel') : t('sharedComponents.backButton')}
            </Button>
            <LoadingButton
              disabled={!isNextValid}
              onClick={onClickNext}
              variant="contained"
              color="primary"
              loadingPosition="start"
              loading={isNextLoading}>
              {isLastStep ? lastStepCTAText : t('sharedComponents.nextButton')}
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
