import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { browserHistory } from '../browserHistory';
import authReducer from '../modules/auth/authSlice';
import featuresReducer from '../modules/features/featuresSlice';
import accountReducer from '../modules/accounts/accountsSlice';
import notificationsSlice from '../modules/notifications/notificationsSlice';
import customersReducer from '../modules/customers/customersSlice';
import experimentsReducer from '../modules/experiemnts/experimentsSlice';
import entitlementsReducer from '../modules/entitlements/entitlementsSlice';
import plansReducer from '../modules/packages/plans/plansSlice';
import addonReducer from '../modules/packages/addons/addonSlice';
import productReducer from '../modules/products/productsSlice';
import applicationReducer from '../modules/application/applicationSlice';
import integrationsReducer from '../modules/integrations/integrationsSlice';
import dashboardsReducer from '../modules/dashboards/dashboardsSlice';
import hooksReducer from '../modules/webhooks/hooksSlice';
import priceReducer from '../modules/packages/pricing/priceSlice';
import couponsReducer from '../modules/coupons/couponsSlice';
import widgetsReducer from '../modules/widgets/widgetsSlice';
import eventsReducer from '../modules/usageEvents/usageEventsSlice';

export const rootReducer = combineReducers({
  router: connectRouter(browserHistory),
  authReducer,
  featuresReducer,
  accountReducer,
  notificationsSlice,
  customersReducer,
  experimentsReducer,
  entitlementsReducer,
  plansReducer,
  addonReducer,
  productReducer,
  application: applicationReducer,
  integrations: integrationsReducer,
  dashboards: dashboardsReducer,
  hooksReducer,
  priceReducer,
  couponsReducer,
  widgetsReducer,
  eventsReducer,
});
