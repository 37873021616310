import { useEffect, useState } from 'react';
import { Popper, TextField, GridFlex, Skeleton, Box } from '@stigg-components';
import { ChromePicker } from 'react-color';

function isValidHexColor(color: string) {
  return /^#[0-9A-F]{6}$/i.test(color);
}

export function ColorPickerInput({
  isLoading,
  label,
  initialColor,
  onColorChanged,
}: {
  isLoading: boolean;
  label: string;
  initialColor: string;
  onColorChanged?: (color: string) => void;
}) {
  const [color, setColor] = useState(initialColor);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const onClickAway = () => {
    setIsColorPickerVisible(false);
    setAnchorEl(null);
  };
  const onClickColorBox = (event: any) => {
    if (!isColorPickerVisible) {
      setIsColorPickerVisible(true);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };
  const onChange = (color: string) => {
    setColor(color);
    if (onColorChanged && isValidHexColor(color)) {
      onColorChanged(color);
    }
  };

  if (isLoading) {
    return (
      <GridFlex.Column gap={1}>
        <Skeleton width={60} variant="text" />
        <Skeleton width="100%" variant="rounded" height={30} />
      </GridFlex.Column>
    );
  }

  return (
    <GridFlex.Column sx={{ position: 'relative' }}>
      <TextField
        label={label}
        value={color.toUpperCase()}
        startAdornment={
          <Box
            onClick={(e) => onClickColorBox(e)}
            sx={{
              width: '18px',
              height: '18px',
              backgroundColor: isValidHexColor(color) ? color : '',
              borderRadius: '2px',
              border: '1px solid #C4CBDD',
              cursor: 'pointer',
            }}
          />
        }
        onChange={(e) => onChange(e.target.value)}
        error={false}
        touched={false}
      />
      {isColorPickerVisible && (
        <>
          <Box sx={{ position: 'fixed', zIndex: 2, top: 0, right: 0, bottom: 0, left: 0 }} onClick={onClickAway} />
          <Popper
            open={isColorPickerVisible}
            disablePortal
            sx={{ zIndex: 2 }}
            anchorEl={anchorEl}
            placement="bottom-start"
            modifiers={[{ name: 'offset', options: { offset: [0, 6] } }]}>
            <ChromePicker
              disableAlpha
              color={color}
              onChange={(color) => {
                onChange(color.hex);
              }}
            />
          </Popper>
        </>
      )}
    </GridFlex.Column>
  );
}
