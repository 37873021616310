import { useCubeQuery } from '@cubejs-client/react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from '@stigg-components';
import styled from 'styled-components/macro';
import { ResultSet } from '@cubejs-client/core';
import { t } from 'i18next';
import { applyProductFilter, useCurrentAndPreviousPeriods, WidgetChartContainer } from './base';
import { WidgetCard, WidgetTitle } from '../../../common/components/Widget';
import { PieChartRenderer } from './charts';
import { RootState } from '../../../../../redux/store';
import { numberFullFormatter, percentFormatter } from '../../../common/formatters';
import { useCubeSubscriptionStaticData } from './static/useCubeSubscriptionStaticData';
import sandboxStaticCubeData from './static/sandbox-subscriptions-by-plan.json';
import { useIsShowMockData } from '../../../common/hooks';
import { useSetChartLastUpdate } from '../../../common/chartsLastUpdate/useSetChartLastUpdate';

const Square = styled(Box)<{ $color: string }>`
  width: 12px;
  height: 12px;
  background: ${(props) => props.$color};
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const StyledRow = styled(Flex.Row)`
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.itamar.palette.grey[200]};
`;

const MEASURE = 'SubscriptionV2.rollingActiveCount';
const UNITS = 'Package.displayName';

const WidgetContent = ({ resultSet }: { resultSet: ResultSet }) => {
  const total = resultSet.rawData().reduce((acc: number, row) => acc + parseFloat(row[MEASURE]), 0);

  return (
    <>
      <PieChartRenderer
        resultSet={resultSet}
        units={UNITS}
        total={total}
        totalText="Active subscriptions"
        tooltipLabel="Plan"
        renderLegend={(data) => {
          const dataset = data.datasets[0];
          if (!dataset) {
            return null;
          }

          return (
            <Flex.Column mt={3} sx={{ overflowY: 'auto', maxHeight: '320px' }}>
              <Box>
                {dataset.data.map((value, i) => {
                  if ((value || 0) === 0) {
                    return null;
                  }
                  const backgroundColors = dataset.backgroundColor as string[];
                  const labels = data.labels as string[];
                  return (
                    <StyledRow key={i}>
                      <Flex.RowCenter>
                        <Square $color={backgroundColors[i]} />
                        <Text.B2 color="secondary">{labels[i]}</Text.B2>
                      </Flex.RowCenter>
                      <Text.B2 ml={2} color="primary">
                        {numberFullFormatter(value)} {`(${percentFormatter(value / total, 0)})`}
                      </Text.B2>
                    </StyledRow>
                  );
                })}
              </Box>
            </Flex.Column>
          );
        }}
      />
    </>
  );
};

const useCubeRealData = ({ skipCubeQuery }: { skipCubeQuery?: boolean } = {}) => {
  const productFilter = useSelector((state: RootState) => state.dashboards.subscriptionsOverview.productFilter);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId)!;
  const { currentPeriod } = useCurrentAndPreviousPeriods();

  const filters = applyProductFilter(
    [
      {
        member: MEASURE,
        operator: 'gt',
        values: ['0'],
      },
      {
        member: 'SubscriptionV2.environmentId',
        operator: 'equals',
        values: [currentEnvironmentId],
      },
    ],
    productFilter,
  );

  return useCubeQuery(
    {
      measures: [MEASURE],
      timeDimensions: [
        {
          dimension: 'SubscriptionV2.startDate',
          granularity: 'day',
          dateRange: [currentPeriod[1], currentPeriod[1]],
        },
      ],
      dimensions: [UNITS],
      order: {
        'SubscriptionV2.rollingActiveCount': 'desc',
      },
      filters,
    },
    { skip: skipCubeQuery },
  );
};

export function SubscriptionsByPlanWidget() {
  const isShowMockData = useIsShowMockData();

  const realData = useCubeRealData({ skipCubeQuery: isShowMockData });
  const mockData = useCubeSubscriptionStaticData({ mock: sandboxStaticCubeData });

  const { error, isLoading, resultSet } = isShowMockData ? mockData : realData;

  const title = t('dashboards.subscriptionsOverview.activeSubscriptionsByPlan');

  useSetChartLastUpdate(title, resultSet);

  return (
    <WidgetCard
      colSpan={4}
      rowSpan={4}
      height={736}
      title={<WidgetTitle color="primary">{title}</WidgetTitle>}
      isLoading={isLoading}>
      <WidgetChartContainer isLoading={isLoading} error={error} resultSet={resultSet}>
        {(resultSet) => <WidgetContent resultSet={resultSet} />}
      </WidgetChartContainer>
    </WidgetCard>
  );
}
