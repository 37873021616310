import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  Plan,
  SetPlanCompatiblePackageGroupsMutation,
  SetPlanCompatiblePackageGroupsMutationVariables,
  SetPlanCompatiblePackageGroup,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../../redux/store';
import { fetchPlanByRefIdAction } from '../plansSlice';

const SET_COMPATIBLE_ADDON_GROUPS_TO_PLAN = gql`
  mutation SetPlanCompatiblePackageGroups($input: SetPlanCompatiblePackageGroups!) {
    setCompatiblePackageGroups(input: $input) {
      id
    }
  }
`;

type SetCompatibleAddonGroupToPlanProps = {
  planRefId: string;
  planId: string;
  versionNumber: number;
  packageGroups: SetPlanCompatiblePackageGroup[];
  skipFetchingPlan?: boolean;
};

async function setCompatibleAddonGroupsToPlan(
  { planRefId, packageGroups, versionNumber, skipFetchingPlan }: SetCompatibleAddonGroupToPlanProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Plan> | undefined> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<
        SetPlanCompatiblePackageGroupsMutation,
        SetPlanCompatiblePackageGroupsMutationVariables
      >({
        mutation: SET_COMPATIBLE_ADDON_GROUPS_TO_PLAN,
        variables: {
          input: {
            id: planRefId,
            packageGroups,
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      if (!skipFetchingPlan) {
        await dispatch(fetchPlanByRefIdAction({ refId: planRefId, versionNumber, silentFetch: true }));
      }

      return response.data?.setCompatiblePackageGroups;
    },
    {
      failureMessageHandler: () => t('plans.api.failAddingCompatibleAddon'),
    },
    dispatch,
  );
}

export { setCompatibleAddonGroupsToPlan };
