import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  FetchFeaturesQuery,
  FetchFeaturesQueryVariables,
  FeatureSortFields,
  SortDirection,
  FeatureFilter,
  FeatureType,
  MeterType,
  FeatureStatus,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { applyEnvironmentFilter } from '../../common/applyEnvironmentFilter';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { Paging } from '../../../components/table/gqlTableHelper';
import { ExecuteOperationOptions } from '../../common';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';
import { FEATURE_LIST_FRAGMENT } from './featureListFragment';

export const FETCH_SLIM_FEATURES = gql`
  query FetchSlimFeatures($paging: CursorPaging, $filter: FeatureFilter!) {
    features(paging: $paging, filter: $filter) {
      edges {
        node {
          refId
          displayName
        }
      }
    }
  }
`;

const FETCH_FEATURES = gql`
  query FetchFeatures($paging: CursorPaging, $sorting: [FeatureSort!], $filter: FeatureFilter!) {
    features(paging: $paging, sorting: $sorting, filter: $filter) {
      ...FeaturesFragment
    }
  }
  fragment FeaturesFragment on FeatureConnection {
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...FeatureListFragment
      }
      cursor
    }
  }
  ${FEATURE_LIST_FRAGMENT}
`;

export type FetchFeaturesProps = {
  paging?: Paging;
  search?: string | null;
  featureTypes?: FeatureType[];
  meterTypes?: MeterType[];
  environmentId: string;
} & ExecuteOperationOptions;

async function fetchFeatures(props: FetchFeaturesProps, { dispatch }: { dispatch: AppDispatch }) {
  let { search, paging } = props;
  if (search === '') {
    search = null;
  }

  return executeOperationSafely(
    async () => {
      const filter: FeatureFilter = {
        environmentId: { eq: props.environmentId },
        featureStatus: { neq: FeatureStatus.Suspended },
      };

      if (props.search) {
        filter.or = [{ displayName: { iLike: `%${props.search}%` } }, { refId: { iLike: `%${props.search}%` } }].map(
          (searchFilter) => applyEnvironmentFilter<FeatureFilter>(searchFilter, props.environmentId),
        );
      }

      if (props.featureTypes) {
        filter.featureType = { in: props.featureTypes };
      }

      if (props.meterTypes) {
        filter.meterType = { in: props.meterTypes };
      }

      if (!paging) {
        paging = { first: DEFAULT_TABLE_PAGE_SIZE };
      }

      const response = await apolloClient.query<FetchFeaturesQuery, FetchFeaturesQueryVariables>({
        query: FETCH_FEATURES,
        fetchPolicy: 'network-only',
        variables: {
          paging,
          sorting: { field: FeatureSortFields.DisplayName, direction: SortDirection.Asc },
          filter,
        },
      });

      return response.data.features;
    },
    {
      failureMessageHandler: () => t('features.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchFeatures };
