import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Box, Text, GridFlex, Button } from '@stigg-components';
import { useIntercom } from 'react-use-intercom';

export const ContactUsContainer = styled(GridFlex.RowCenter)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  gap: ${({ theme }) => theme.spacing(10)};
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground};
  padding: 32px;
  margin-top: 24px;
`;

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: ${({ theme }) => theme.spacing(4)};
`;

type AWSMarketplaceContactUsStateProps = {
  headerComponent: React.ReactNode;
};

export function AWSMarketplaceContactUsState({ headerComponent }: AWSMarketplaceContactUsStateProps) {
  const intercom = useIntercom();

  const handleContactUsClick = () => {
    intercom.showNewMessage(t('integrations.awsMarketplace.contactUs.intercomPrefilledMessage'));
  };

  return (
    <>
      <StyledBox>{headerComponent}</StyledBox>
      <ContactUsContainer>
        <GridFlex.Column flex={1}>
          <Text.B2 color="primary">{t('integrations.awsMarketplace.contactUs.content')}</Text.B2>
        </GridFlex.Column>
        <Button variant="contained" onClick={handleContactUsClick}>
          {t('integrations.awsMarketplace.contactUs.buttonText')}
        </Button>
      </ContactUsContainer>
    </>
  );
}
