import { Menu as MenuIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, GridFlex, IconButton, LoadingButton, Text, useMediaQuery, Icon } from '@stigg-components';
import { t } from 'i18next';
import * as S from './PackageEditTopBar.style';

export function EditNewPackageBar({
  isDrawerExpanded,
  isTopBannerOpen,
  onDrawerToggle,
  onDiscardChanges,
  onPublish,
  packageType,
  isLoading,
  isSandboxBannerOpen,
}: {
  isDrawerExpanded: boolean;
  isTopBannerOpen: boolean;
  onDrawerToggle: () => void;
  onDiscardChanges: () => void;
  onPublish: () => void;
  packageType: 'plan' | 'add-on';
  isLoading?: boolean;
  isSandboxBannerOpen: boolean;
}) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <S.EditModeBar
      $isDrawerExpanded={isDrawerExpanded}
      $topBannerOpen={isTopBannerOpen}
      $isSandboxBannerOpen={isSandboxBannerOpen}
      position="fixed">
      <GridFlex.RowCenter container wrap="nowrap">
        {!isDrawerExpanded && !isMdUp && (
          <Grid item>
            <IconButton color="primary" aria-label={t('openDrawer')} onClick={onDrawerToggle} size="large">
              <MenuIcon />
            </IconButton>
          </Grid>
        )}
        <GridFlex.RowCenter item container>
          <Grid item mr={2}>
            <S.Edit size={20} strokeWidth={2.5} />
          </Grid>
          <Grid item>
            <Text.B2 color="primary" fontWeight="bold">
              {t('packages.firstPackageEdit', { packageType })}
            </Text.B2>
          </Grid>
        </GridFlex.RowCenter>

        <Grid item>
          <Button
            color="primary"
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto', mr: 2 }}
            $outlined
            onClick={onDiscardChanges}>
            {t('packages.deleteDraft')}
          </Button>
          <LoadingButton
            loading={isLoading}
            color="primary"
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
            variant="contained"
            data-testid={`button-publish-${packageType}`}
            startIcon={<Icon type="reactFeather" icon="Send" color="white" size={18} />}
            onClick={onPublish}>
            {t('packages.publishPackage', { packageType })}
          </LoadingButton>
        </Grid>
      </GridFlex.RowCenter>
    </S.EditModeBar>
  );
}
