import merge from 'lodash/merge';

type EntityConnection = {
  edges: {
    node: {
      id: string;
      [key: string]: any;
    };
  }[];
};

export function updateItemById(entityConnection: EntityConnection, payload: any, recursive = true) {
  entityConnection.edges?.forEach((customer: any) => {
    if (customer.node.id === payload?.id) {
      customer.node = recursive ? merge(customer.node, payload) : Object.assign(customer.node, payload);
    }
    return customer;
  });
}
