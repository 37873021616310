import { GridFlex, Icon, Text } from '@stigg-components';
import React from 'react';
import { t } from 'i18next';

export enum AddonType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export type AddonTypeProps = {
  value: AddonType;
  disabled?: boolean;
  withExample?: boolean;
  addonTypeText?: string;
};

export function AddonTypeValue({ value, withExample = false }: AddonTypeProps) {
  const isSingleAddonType = value === AddonType.SINGLE;
  return (
    <GridFlex.Column $fullWidth>
      {isSingleAddonType ? (
        <GridFlex.RowCenter gap={1}>
          <Icon icon="SingleAddonType" type="custom" size={25} color="default" overrideStroke />
          <GridFlex.Column>
            <Text.B2 color="primary">{t('packages.singleAddonType')}</Text.B2>
            {withExample && <Text.B2 color="primary">{t('packages.singleAddonTypeExample')}</Text.B2>}
          </GridFlex.Column>
        </GridFlex.RowCenter>
      ) : (
        <GridFlex.RowCenter gap={1}>
          <Icon icon="MultiAddonType" type="custom" size={25} color="default" overrideFill />
          <GridFlex.Column>
            <Text.B2 color="primary">{t('packages.multiAddonType')}</Text.B2>
            {withExample && <Text.B2 color="primary">{t('packages.multiAddonTypeExample')}</Text.B2>}
          </GridFlex.Column>
        </GridFlex.RowCenter>
      )}
    </GridFlex.Column>
  );
}
