import { ApiKeyType } from '@stigg-types/apiTypes';
import { ReactComponent as NodeIcon } from '@assets/icons/nodejs-icon.svg';
import { ReactComponent as JavaIcon } from '@assets/icons/java-logo.svg';
import { ReactComponent as ReactIcon } from '@assets/icons/react.svg';
import { ReactComponent as DotnetIcon } from '@assets/icons/DotnetIcon.svg';
import { ReactComponent as JavascriptIcon } from '@assets/icons/javascript-icon.svg';
import { ReactComponent as PythonIcon } from '@assets/icons/python.svg';
import { ReactComponent as GoIcon } from '@assets/icons/go.svg';
import { ReactComponent as RubyIcon } from '@assets/icons/ruby.svg';
import { nodeFeatureDetailsSnippetsCode } from './snippets/nodeFeatureSnippets';
import { jsFeatureDetailsSnippetsCode } from './snippets/javascriptCodeSnippets';
import { pythonFeatureDetailsSnippetsCode } from './snippets/pythonCodeSnippets';
import { goFeatureDetailsSnippetsCode } from './snippets/goCodeSnippets';
import { rubyFeatureDetailsSnippetsCode } from './snippets/rubyCodeSnippets';
import { javaFeatureDetailsSnippetsCode } from './snippets/javaCodeSnippets';
import type { FeatureDetailsSnippetsCode } from './snippets/featureDetailsSnippets.types';
import { dotNetFeatureDetailsSnippetsCode } from './snippets/dotnetCodeSnippets';

export enum SdkType {
  NODE = 'NODE',
  PYTHON = 'PYTHON',
  RUBY = 'RUBY',
  GO = 'GO',
  REACT = 'REACT',
  JS = 'JS',
  JAVA = 'JAVA',
  DOT_NET = 'DOT_NET',
}

export interface SnippetData {
  text: string;
  icon: JSX.Element;
  featureSnippetCode?: FeatureDetailsSnippetsCode;
  syntaxHighlighterLanguage: string;
  apiKeyType: ApiKeyType;
}

export const SDK_TO_SNIPPET_DATA: Record<SdkType, SnippetData> = {
  [SdkType.NODE]: {
    text: 'Node.js (Server)',
    icon: <NodeIcon width={24} height={24} />,
    featureSnippetCode: nodeFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'javascript',
    apiKeyType: ApiKeyType.Server,
  },
  [SdkType.JAVA]: {
    text: 'Java (Server)',
    icon: <JavaIcon width={24} height={24} />,
    featureSnippetCode: javaFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'java',
    apiKeyType: ApiKeyType.Server,
  },
  [SdkType.PYTHON]: {
    text: 'Python (Server)',
    icon: <PythonIcon width={24} height={24} />,
    featureSnippetCode: pythonFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'python',
    apiKeyType: ApiKeyType.Server,
  },
  [SdkType.RUBY]: {
    text: 'Ruby (Server)',
    icon: <RubyIcon width={24} height={24} />,
    featureSnippetCode: rubyFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'ruby',
    apiKeyType: ApiKeyType.Server,
  },
  [SdkType.GO]: {
    text: 'Go (Server)',
    icon: <GoIcon width={24} height={24} />,
    featureSnippetCode: goFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'go',
    apiKeyType: ApiKeyType.Server,
  },
  [SdkType.REACT]: {
    text: 'React',
    icon: <ReactIcon width={24} height={24} />,
    syntaxHighlighterLanguage: 'javascript',
    apiKeyType: ApiKeyType.Client,
  },
  [SdkType.JS]: {
    text: 'Javascript (Client)',
    icon: <JavascriptIcon width={24} height={24} />,
    featureSnippetCode: jsFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'javascript',
    apiKeyType: ApiKeyType.Client,
  },
  [SdkType.DOT_NET]: {
    text: '.NET (Server)',
    icon: <DotnetIcon width={24} height={24} />,
    featureSnippetCode: dotNetFeatureDetailsSnippetsCode,
    syntaxHighlighterLanguage: 'csharp',
    apiKeyType: ApiKeyType.Server,
  },
};
