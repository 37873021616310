import { GridFlex, Icon, InformationalTooltipIcon, HighlightText, LongText } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { CustomerResourceFragment } from '@stigg-types/apiTypes';

type SubscriptionResourceProps = {
  resource: CustomerResourceFragment;
  highlight?: string;
};

export function SubscriptionResourceLabel({ resource, highlight }: SubscriptionResourceProps) {
  return (
    <GridFlex.RowCenter gap={1}>
      <InformationalTooltipIcon
        icon={<Icon icon="Layers" type="reactFeather" color="active" size={16} />}
        text={t('sharedComponents.resourceId')}
        placement="left"
      />
      <LongText color="secondary" wordBreak>
        <HighlightText highlight={highlight} text={resource.resourceId} />
      </LongText>
    </GridFlex.RowCenter>
  );
}
