import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fetchSubscriptionMigrationTasksAction } from '../../../../../modules/application/applicationSlice';
import { RootState, useAppDispatch } from '../../../../../redux/store';

function useInterval(callback: () => void, delay = 1000) {
  const savedCallback = useRef<() => void | null>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export const usePollSubscriptionMigrationTasks = (pollInterval: number) => {
  const dispatch = useAppDispatch();
  const pollingTaskIds = useSelector((state: RootState) => state.application.subscriptionMigrationPollingTaskIds);
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const subscriptionMigrationTasks = useSelector((state: RootState) => state.application.subscriptionMigrationTasks);

  useEffect(() => {
    if (environmentId) {
      void dispatch(fetchSubscriptionMigrationTasksAction({ environmentId }));
    }
  }, [dispatch, environmentId]);

  useInterval(() => {
    if (environmentId && pollingTaskIds.length > 0) {
      void dispatch(fetchSubscriptionMigrationTasksAction({ environmentId, taskIds: Array.from(pollingTaskIds) }));
    }
  }, pollInterval);

  return subscriptionMigrationTasks;
};
