import { Restore } from '@mui/icons-material';
import { GridFlex, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';
import { packageVersionFormat } from './packageVersionFormat';

export function PackageVersionNumber({
  versionNumber,
  isLatest,
  withoutVersionNumber,
  tooltip,
  disabled,
  size,
}: {
  versionNumber?: number | null;
  isLatest?: boolean | null;
  withoutVersionNumber?: boolean;
  tooltip?: string;
  disabled?: boolean;
  size?: 'small';
}) {
  if (!versionNumber) {
    return null;
  }

  return (
    <InformationTooltip
      arrow
      placement="top"
      $padding={2}
      title={!isLatest ? <Text.B2>{tooltip || t('packages.outdatedVersion')}</Text.B2> : ''}>
      <GridFlex.RowCenter container width="auto" display="inline-flex">
        {!withoutVersionNumber && (
          <Text.Sub2 mr={1} color={isLatest ? 'primary' : 'warning.main'}>
            {packageVersionFormat(versionNumber, isLatest)}
          </Text.Sub2>
        )}
        {!isLatest && (
          <Restore
            fontSize={size || 'medium'}
            sx={{
              color: (theme) => (disabled ? theme.itamar.palette.grey[200] : theme.itamar.palette.warning.main),
            }}
          />
        )}
      </GridFlex.RowCenter>
    </InformationTooltip>
  );
}
