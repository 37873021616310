import { SearchFunction } from 'commandbar/build/internal/src/client/AddContextOptions';
import { Navigation } from '../navigation/useNavigation';
import { searchSubscription } from '../customers/queries/searchSubscription';
import { MAX_RESULTS_PER_COMMANDBAR_SEARCH } from './useCommandBar';
import { executeSafely } from './utils';
import { getCommandBarIcon } from './icons/getCommandBarIcon';
import subscriptionsIconDark from './icons/subscriptions.dark.svg';
import subscriptionsIconLight from './icons/subscriptions.light.svg';

export function setSubscriptionsSearch(environmentId: string, navigation: Navigation, isLightTheme?: boolean) {
  const doFetchSubscriptions: SearchFunction = async (search: string) => {
    const subscriptions = await searchSubscription(environmentId, search, MAX_RESULTS_PER_COMMANDBAR_SEARCH);
    return subscriptions.map((subscription) => {
      const {
        subscriptionId,
        customer: { customerId, name, email },
      } = subscription.node;
      return { subscriptionId, label: subscriptionId, description: name || email || customerId };
    });
  };

  window.CommandBar.addRecords('subscriptions', [], {
    onInputChange: executeSafely(doFetchSubscriptions),
    defaultIcon: getCommandBarIcon(isLightTheme ? subscriptionsIconLight : subscriptionsIconDark),
    labelKey: 'label',
    descriptionKey: 'description',
    recordOptions: {
      url: navigation.appRoutes.subscriptionPage('{{record.subscriptionId}}'),
    },
  });
}
