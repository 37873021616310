import { Grid, LocalDate, Text } from '@stigg-components';
import { TextColor } from '@stigg-theme';
import { getDateRemainingDays, FormatOptions } from '@stigg-common';
import { t } from 'i18next';

type DateTextProps = {
  date: Date;
  withTime?: boolean;
  isEndDate?: boolean;
  color?: TextColor;
} & Pick<FormatOptions, 'formatType' | 'withTime'>;

export function DateText({ date, withTime, isEndDate, formatType, color }: DateTextProps) {
  const { remainingDays, isInRange, isLessThenOneDay } = getDateRemainingDays(date);
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <LocalDate color={color} date={date} formatOptions={{ withTime, formatType }} />
      </Grid>

      <Grid item>
        {isEndDate && isInRange && (
          <Text.Sub2>
            {isLessThenOneDay
              ? t('subscriptions.daysRemainingZero')
              : t('subscriptions.daysRemaining', { count: remainingDays })}
          </Text.Sub2>
        )}
      </Grid>
    </Grid>
  );
}
