import { Member } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { MembershipProps } from './SwitchAccountPage.types';
import { RootState } from '../../../../redux/store';

function sortMembershipsByCreatedAt(memberships: Member[]) {
  return [...memberships].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
}

export function useMembershipsData(): MembershipProps[] {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const memberships = user?.memberships || [];
  return sortMembershipsByCreatedAt(memberships);
}
