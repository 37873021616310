import { ExperimentStatus } from '@stigg-types/apiTypes';
import { Chip } from '@stigg-components';
import { t } from 'i18next';
import { ChipColor, TextColor } from '@stigg-theme';

function getChipDefinition(status: ExperimentStatus): { label: string; color: ChipColor; textColor: TextColor } {
  switch (status) {
    case ExperimentStatus.Draft:
      return {
        label: t('experiments.statuses.draft'),
        color: 'action.disabledBackground',
        textColor: 'primary',
      };
    case ExperimentStatus.InProgress:
      return {
        label: t('experiments.statuses.inProgress'),
        color: 'primary',
        textColor: 'white',
      };
    case ExperimentStatus.Completed:
      return {
        label: t('experiments.statuses.completed'),
        color: 'grey.400',
        textColor: 'white',
      };
    default:
      return {
        label: '',
        color: 'action.disabledBackground',
        textColor: 'primary',
      };
  }
}

export function ExperimentChip({ status }: { status: ExperimentStatus }) {
  const { label, color, textColor } = getChipDefinition(status);

  return <Chip size="small" label={label} color={color} textColor={textColor} />;
}
