import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { Box, Text, GridFlex, EmptyCardContent } from '@stigg-components';
import { ProductFragment } from '@stigg-types/apiTypes';
import { PageCard } from '../../../../components/PageCard';
import { ProductPlans } from './ProductPlans';
import { ProductAddons, AddonsTitle } from './ProductAddons';

export function ProductOffering({ product }: { product: ProductFragment }) {
  const hasPlans = !isEmpty(product?.plans);
  const isAwsProduct = !!product.awsMarketplaceProductId;

  return (
    <PageCard>
      <GridFlex.RowSpaceBetween>
        <Box>
          <GridFlex.RowSpaceBetweenTop container>
            <Text.H3 mb={2}>{t('products.productOfferingTitle')}</Text.H3>
          </GridFlex.RowSpaceBetweenTop>
          <Text.Sub2 mb={8}>{t('products.productOfferingSubtitle')}</Text.Sub2>
        </Box>
      </GridFlex.RowSpaceBetween>

      <ProductPlans product={product} />

      {!isAwsProduct && hasPlans ? <ProductAddons product={product} /> : null}
      {isAwsProduct ? (
        <GridFlex.Column mt={8} gap={8}>
          <AddonsTitle />
          <EmptyCardContent>
            <Text.B2>{t('products.noAddonsAllowedForAwsMarketplaceProducts')}</Text.B2>
          </EmptyCardContent>
        </GridFlex.Column>
      ) : null}
    </PageCard>
  );
}
