import { gql } from '@apollo/client';
import { executeOperationSafely } from '@stigg-common';
import { FeatureStatus, ValidateFeatureIdQuery, ValidateFeatureIdQueryVariables } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';

export type DoesFeatureExist = { featureStatus?: FeatureStatus | null; featureExists: boolean };

const VALIDATE_FEATURE_REF_ID = gql`
  query ValidateFeatureID($featureFilter: FeatureFilter!) {
    features(filter: $featureFilter) {
      edges {
        node {
          featureStatus
        }
      }
    }
  }
`;

async function validateFeatureRefId(
  environmentId: string,
  featureIdRefId: string,
  { dispatch }: { dispatch: AppDispatch },
): Promise<DoesFeatureExist> {
  return executeOperationSafely(
    async () => {
      const res = await apolloClient.query<ValidateFeatureIdQuery, ValidateFeatureIdQueryVariables>({
        query: VALIDATE_FEATURE_REF_ID,
        fetchPolicy: 'network-only',
        variables: {
          featureFilter: {
            refId: {
              eq: featureIdRefId,
            },
            environmentId: { eq: environmentId },
          },
        },
      });

      const features = res.data.features.edges;
      if (isEmpty(features)) {
        return { featureExists: false };
      }

      return { featureExists: true, featureStatus: features[0].node.featureStatus };
    },
    { hideErrorMessages: true },
    dispatch,
  );
}

export { validateFeatureRefId };
