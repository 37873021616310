import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { executeOperationSafely } from '@stigg-common';
import { EventLogsQuery, EventLogsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { FETCH_EVENT_LOGS } from './fetchEventLogs';
import { RootState } from '../../../redux/store';
import { transformEventLogResult } from './transformEventLogResult';

export const useGetEventLog = ({ eventLogId }: { eventLogId: string | null }) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  return useQuery({
    queryKey: ['eventLog', eventLogId],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<EventLogsQuery, EventLogsQueryVariables>({
            fetchPolicy: 'network-only',
            query: FETCH_EVENT_LOGS,
            variables: {
              filter: {
                environmentId: {
                  eq: environmentId,
                },
                id: {
                  eq: eventLogId,
                },
              },
            },
          }),
        { failureMessageHandler: () => t('eventsLog.errors.singleEventFetchFailed') },
        dispatch,
      ),
    enabled: !!eventLogId,
    select: transformEventLogResult,
    retry: 0, // we automatically retry in apollo client
  });
};
