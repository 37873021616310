import * as React from 'react';
import { GridFlex } from '@stigg-components';

export function DetailsRow({ iconComponent, children }: { iconComponent: React.ReactNode; children: React.ReactNode }) {
  return (
    <GridFlex.RowCenter columnGap={4}>
      {iconComponent}
      <GridFlex.Column $fullHeight justifyContent="space-evenly">
        {children}
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}
