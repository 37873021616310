import { styled } from '@stigg-theme';
import { Card, CardContent, Grid, GridFlex, Skeleton, Text } from '@stigg-components';

export const WidgetTitle = styled(Text.B1)`
  font-size: 16px;
  line-height: 24px;
`;

export const WidgetSubtitle = styled(Text.B2)`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export function WidgetCard({
  children,
  height,
  span = 12,
  colSpan = span,
  title,
  subtitle,
  rowSpan = 1,
  isLoading,
}: {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  height?: number;
  span?: number; // backwards compatibility :)
  colSpan?: number;
  rowSpan?: number;
  isLoading?: boolean;
}) {
  return (
    <Grid gridColumn={`span ${colSpan}`} gridRow={rowSpan ? `span ${rowSpan}` : undefined}>
      <Card sx={{ height }}>
        <CardContent sx={{ p: 6, height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <GridFlex.Column item flex="auto">
            {title && (isLoading ? <Skeleton variant="text" animation="wave" width={240} /> : title)}
            {subtitle &&
              (isLoading ? <Skeleton variant="text" animation="wave" width={100} sx={{ marginTop: 2 }} /> : subtitle)}
            {children}
          </GridFlex.Column>
        </CardContent>
      </Card>
    </Grid>
  );
}
