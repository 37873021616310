import { Box, Icon, Button, FormRenderProps, GridFlex, CustomTable } from '@stigg-components';
import { BillingPeriod, Currency } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { PriceTier, SetPriceWizardFormFields, TiersSchema } from '../../SetPriceWizardForm.types';
import { DeleteTierColumn } from './DeleteTierColumn';
import { headCells } from './tiersTableCells';

type TiersTableProps = {
  tiers: PriceTier[];
  tiersSchema: TiersSchema;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  currency: Currency;
  priceTiersId: string;
  billingPeriod: BillingPeriod;
  pricePeriodUnit: BillingPeriod;
  disableUnitChanges?: boolean;
  disableAddingTier?: boolean;
  onAddTier: () => void;
  onRemoveTier: (indexToRemove: number) => void;
};

export function TiersTable({
  tiers,
  tiersSchema,
  formRenderProps,
  currency,
  priceTiersId,
  billingPeriod,
  onAddTier,
  onRemoveTier,
  pricePeriodUnit,
  disableAddingTier,
  disableUnitChanges,
}: TiersTableProps) {
  const lastIndex: number = tiers.length - 1;
  return (
    <GridFlex.Column>
      <GridFlex.Row>
        <CustomTable
          key={tiers.length}
          rowHeight={54}
          withRowHover={false}
          backgroundColor="#ffffff"
          headCells={headCells({
            formRenderProps,
            currency,
            priceTiersId,
            billingPeriod,
            pricePeriodUnit,
            lastIndex,
            tiersSchema,
            tiers,
            disableUnitChanges,
          })}
          data={tiers}
          withInnerDividers
        />
        {!disableAddingTier ? <DeleteTierColumn tiers={tiers} onRemoveTier={onRemoveTier} /> : null}
      </GridFlex.Row>

      {!disableAddingTier ? (
        <Box mt={2}>
          <Button
            variant="text"
            startIcon={<Icon type="reactFeather" icon="Plus" size={18} />}
            onClick={onAddTier}
            data-testid="button-add-tier">
            {t('pricing.addTierCTA')}
          </Button>
        </Box>
      ) : null}
    </GridFlex.Column>
  );
}
