import { useRef, useEffect, useCallback, MutableRefObject } from 'react';
import debounce from 'lodash/debounce';
import { useTheme } from '@mui/material/styles';
import { useLatestRef } from './useLatestRef';

const getHash = () => {
  return decodeURI(window.location.hash.slice(1));
};

export type Anchors =
  | 'accountMembers'
  | 'customerJourney'
  | 'pricing'
  | 'planCompatibleAddons'
  | 'customCssEditor'
  | 'addonDependencies';

type UseScrollableAnchorProps = {
  anchor: Anchors;
  isLoading?: boolean | null;
  delay?: number;
};

export const useScrollToAnchor = ({
  anchor,
  elementRef,
}: { anchor?: string; elementRef?: MutableRefObject<HTMLElement | null> } = {}) => {
  const theme = useTheme();

  const scrollToAnchorAndHighlight = useCallback(() => {
    const element = anchor ? document.getElementById(anchor) : elementRef?.current;
    if (!element) {
      return undefined;
    }

    element.style.background = theme.itamar.palette.background.lightBackground2;
    element.style.borderColor = theme.itamar.palette.primary.outlinedRestingBackground;
    element.scrollIntoView({ behavior: 'smooth' });

    // assuming that this is after the element was scrolled into view
    return setTimeout(() => {
      if (element) {
        element.style.transition = '1.5s';
        element.style.background = '';
        element.style.borderColor = '';
      }
    }, 1000);
  }, [anchor, theme, elementRef]);

  return { scrollToAnchorAndHighlight };
};

export const useScrollableElement = ({
  isLoading,
  delay = 100,
  shouldAutoScroll,
}: {
  isLoading?: boolean | null;
  delay?: number;
  shouldAutoScroll: () => boolean;
}) => {
  const shouldAutoScrollRef = useLatestRef(shouldAutoScroll);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const { scrollToAnchorAndHighlight } = useScrollToAnchor({ elementRef });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const focusElement = useCallback(debounce(scrollToAnchorAndHighlight, delay), [scrollToAnchorAndHighlight, delay]);

  useEffect(() => {
    let futureTransition: NodeJS.Timeout | undefined;

    if (!isLoading && shouldAutoScrollRef.current()) {
      futureTransition = focusElement();
    }

    return () => {
      if (futureTransition) {
        clearTimeout(futureTransition);
      }
      focusElement.cancel();
    };
  }, [isLoading, focusElement, shouldAutoScrollRef]);

  return { elementRef };
};

export const useScrollableAnchor = ({ anchor, isLoading, delay }: UseScrollableAnchorProps) => {
  const { elementRef } = useScrollableElement({
    isLoading,
    delay,
    shouldAutoScroll: () => getHash() === anchor,
  });

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.setAttribute('id', anchor);
    }
    // Keep element.current in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor, elementRef.current]);

  return { elementRef };
};
