import { gql } from '@apollo/client';
import { AddonFragment, FetchAddonByRefIdQuery, FetchAddonByRefIdQueryVariables } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import toNumber from 'lodash/toNumber';
import { apolloClient } from '../../../../ApolloClient';
import { AppDispatch, RootState } from '../../../../redux/store';
import { ExecuteOperationOptions } from '../../../common';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { ADDON_FRAGMENT } from './addonFragment';

const FETCH_ADDON = gql`
  ${ADDON_FRAGMENT}
  query FetchAddonByRefId($input: GetPackageByRefIdInput!) {
    getAddonByRefId(input: $input) {
      ...AddonFragment
    }
  }
`;

type FetchAddonByRefIdProps = { refId: string; versionNumber?: string | number | null } & ExecuteOperationOptions;

async function fetchAddonByRefId(
  { refId, versionNumber }: FetchAddonByRefIdProps,
  { getState, dispatch }: { getState: () => RootState; dispatch: AppDispatch },
): Promise<AddonFragment | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }
      const res = await apolloClient.query<FetchAddonByRefIdQuery, FetchAddonByRefIdQueryVariables>({
        query: FETCH_ADDON,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            refId,
            versionNumber: toNumber(versionNumber),
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return res.data?.getAddonByRefId;
    },
    {
      failureMessageHandler: () => t('addons.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchAddonByRefId };
