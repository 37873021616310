import { Text, Link } from '@stigg-components';
import { t } from 'i18next';
import * as React from 'react';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { useNavigation } from '../../../../../navigation/useNavigation';
import { ReactComponent as ProductIcon } from '../../../../../../assets/icons/experiments/ProductTile.svg';
import { DetailsRow } from './DetailsRow';
import { RootState } from '../../../../../../redux/store';

export function ProductDetailsRow({ experiment }: { experiment: ExperimentFragment }) {
  const navigation = useNavigation();
  const products = useSelector((state: RootState) => state.productReducer.products);

  // no need to show this information if there is only a single product
  if (products.length < 2) {
    return null;
  }

  return (
    <DetailsRow iconComponent={<ProductIcon width={50} />}>
      <Text.B2 color="secondary">{t('experiments.relatedProduct')}</Text.B2>
      <Text.B2 color="primary">
        <Link onClick={() => navigation.navigateTo(navigation.appRoutes.productPage(experiment.product?.refId))}>
          {experiment.product?.displayName}
        </Link>
      </Text.B2>
    </DetailsRow>
  );
}
