import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AccountStatus } from '@stigg-types/apiTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { RootState } from '../../../redux/store';
import { SANDBOX_TEMPLATE_COOKIE_NAME } from '../../navigation/Router';
import { useCurrentMember } from '../hooks/useCurrentMember';

type ProtectedRouteProps = RouteProps & {
  requiresAccount?: boolean;
  requiresEnvironment?: boolean;
};
function ProtectedRoute({ requiresAccount = true, requiresEnvironment = true, ...props }: ProtectedRouteProps) {
  const { user: auth0User } = useAuth0();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const { setAccountToBlockedOnCreation: accountShouldBeBlocked } = useFlags<FeatureFlags>();
  const [cookies, setCookie] = useCookies([SANDBOX_TEMPLATE_COOKIE_NAME]);
  const { shouldShowBlockedMobile } = useFlags<FeatureFlags>();
  const defaultMembership = useCurrentMember();

  if (!user) {
    // if the path was the template generation path and no user, save a cookie so we can proceed from same place
    // after registration
    if (props.path === '/sandbox/:templateName') {
      setCookie(SANDBOX_TEMPLATE_COOKIE_NAME, props.location?.pathname.split('/')[2], {
        expires: moment.utc().add(1, 'hour').toDate(),
        path: '/',
      });
    }
    // workaround to prevent redirect to login
    if (auth0User) {
      return <Redirect to={{ pathname: '/signInEndpoint' }} />;
    }
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (requiresAccount) {
    if (auth0User && !auth0User.email_verified) {
      return <Redirect to={{ pathname: '/verify' }} />;
    }

    if (!defaultMembership) {
      return <Redirect to={{ pathname: '/account/create' }} />;
    }

    if (defaultMembership.account.accountStatus === AccountStatus.Blocked || accountShouldBeBlocked) {
      return <Redirect to={{ pathname: '/creating-account' }} />;
    }

    if (requiresEnvironment && environments?.length === 0 && !cookies.sandboxTemplateName) {
      return <Redirect to={{ pathname: '/generate-sandbox' }} />;
    }
    if (window.innerWidth <= 600 && shouldShowBlockedMobile) {
      return <Redirect to={{ pathname: '/stigg-mobile' }} />;
    }
  }

  return <Route {...props} />;
}

export default ProtectedRoute;
