import { DialogActionsButtons, drawFormFields, Grid, GridFlex } from '@stigg-components';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import { Form as FormikForm, FormikProps } from 'formik';
import { t } from 'i18next';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import { useEffect } from 'react';
import { FormWrapperGrid } from './FormContent.style';
import { FormProps } from './Form.types';

export type FormContentProps<FormValues extends Record<string, any>> = FormProps<FormValues> & {
  formProps: FormikProps<FormValues>;
  isSubmitting: boolean;
};

export function FormContent<FormValues extends Record<string, any>>({
  formProps,
  isSubmitting,
  onCancel,
  disableSaveOnNoChanges,
  fields,
  submitButtonText = t('sharedComponents.createSaveButton'),
  saveEndIcon,
  headerComponent = null,
  footerComponent = null,
  gridDirection = 'column',
  saveButtonColor = 'primary',
  cancelButtonText = t('sharedComponents.cancel'),
  withStickyFooter,
  footerSx,
  actionDialogRightSpacing,
  rowSpacing,
  paddingRight,
  headerSx,
  contentRenderer,
  overrideIsValid,
  withFooterSpacing,
  hideSaveButton,
  setHasUnsavedChanges,
}: FormContentProps<FormValues>) {
  const { dirty, isValid, values } = formProps;
  const saveButtonText = isFunction(submitButtonText) ? submitButtonText(values) : submitButtonText;

  useEffect(() => setHasUnsavedChanges?.(dirty), [dirty, setHasUnsavedChanges]);

  const footerStyle: SxProps<Theme> = {
    ...(withStickyFooter
      ? {
          paddingTop: 2,
          paddingBottom: 2,
          paddingRight: actionDialogRightSpacing,
          width: '100%',
        }
      : {
          borderTop: (theme) => `1px solid ${theme.itamar.border.borderColor}`,
          paddingTop: 4,
        }),
    ...footerSx,
  };

  const formContent = (
    <GridFlex.Column container sx={withStickyFooter ? { height: '100%' } : {}}>
      {headerComponent && (
        <Grid sx={headerSx} justifyContent="space-between" item container mt={4}>
          {headerComponent}
        </Grid>
      )}

      <Grid
        container
        flexDirection={gridDirection}
        spacing={3}
        rowSpacing={rowSpacing || 3}
        justifyContent={withStickyFooter ? 'flex-start' : 'space-between'}
        alignItems={gridDirection === 'row' ? 'center' : 'stretch'}
        marginTop={0}
        paddingRight={paddingRight}
        flex={withStickyFooter ? '1' : 'auto'}
        overflow={withStickyFooter ? 'auto' : 'visible'}
        flexWrap={withStickyFooter ? 'nowrap' : 'wrap'}>
        {drawFormFields(fields, formProps)}
      </Grid>

      <GridFlex.RowCenter
        sx={footerStyle}
        justifyContent={withFooterSpacing && footerComponent ? 'space-between' : 'flex-end'}
        mt={4}>
        {footerComponent && <Grid>{footerComponent}</Grid>}
        <DialogActionsButtons
          isSaveLoading={isSubmitting}
          saveDisabled={
            (disableSaveOnNoChanges && !dirty) ||
            (isNil(overrideIsValid) ? !isValid : !overrideIsValid(values, formProps))
          }
          saveText={saveButtonText || ''}
          saveEndIcon={saveEndIcon ? saveEndIcon(values) : null}
          onCancel={onCancel}
          saveButtonColor={saveButtonColor}
          cancelText={cancelButtonText}
          isSaveButtonHidden={hideSaveButton}
        />
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );

  return (
    <FormWrapperGrid $withStickyFooter={withStickyFooter}>
      <FormikForm>{contentRenderer ? contentRenderer({ formContent, formProps }) : formContent}</FormikForm>
    </FormWrapperGrid>
  );
}
