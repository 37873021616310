import { PaywallThemeConfiguration } from './paywall';
import { defaultTypography } from '../types/typography';

export type CustomerPortalThemeConfiguration = Omit<PaywallThemeConfiguration, 'layout'> & {
  palette: {
    iconsColor: string;
    customerPortalBackground: string;
  };
};

export const defaultCustomerPortalTheme: CustomerPortalThemeConfiguration = {
  palette: {
    primary: '#327EEE',
    backgroundHighlight: '#F5F6F9',
    backgroundPaper: '#FFFFFF',
    customerPortalBackground: '#FFFFFF',
    outlinedBorder: '#D9DDE9',
    iconsColor: '#7586B0',
    text: {
      primary: '#001E6C',
    },
  },
  typography: defaultTypography,
  css: '',
};
