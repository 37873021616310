import React, { useMemo } from 'react';
import { t } from 'i18next';
import { ClockIcon, Icon, InformationTooltip, Text } from '@stigg-components';
import { ChipColor, TextColor } from '@stigg-theme';
import { PaymentCollection, SubscriptionStatus } from '@stigg-types/apiTypes';
import { ReactComponent as Schedualed } from '@assets/icons/Schedualed.svg';
import { StatusChip } from '@stigg-common';

export enum ExtraSubscriptionStatuses {
  ScheduledCancellation = 'SCHEDULED_CANCELLATION',
  ScheduledUpdate = 'SCHEDULED_UPDATE',
  PaymentError = 'PAYMENT_ERROR',
}

type SubscriptionStatusChipType = SubscriptionStatus | ExtraSubscriptionStatuses | PaymentCollection.Processing;

type ChipDefinition = {
  text: () => string;
  textColor: TextColor;
  color: ChipColor;
  variant: 'filled' | 'outlined';
  icon?: React.ReactElement;
  shouldNotTruncate?: boolean;
  withErrorBackground?: boolean;
};

const StyledHourglassIcon = (
  <Icon
    type="custom"
    icon="HourglassEmpty"
    size={20}
    color="white"
    overrideStroke
    style={{ marginRight: 40, marginLeft: 40 }}
  />
);

const STATUS_FORMAT: Record<SubscriptionStatusChipType, ChipDefinition> = {
  [SubscriptionStatus.Active]: {
    text: () => t('subscriptions.statuses.active'),
    textColor: 'white',
    color: 'primary',
    variant: 'filled',
  },
  [SubscriptionStatus.Canceled]: {
    text: () => t('subscriptions.statuses.cancelled'),
    textColor: 'primary',
    color: 'action.disabledBackground',
    variant: 'filled',
  },
  [SubscriptionStatus.NotStarted]: {
    text: () => t('subscriptions.statuses.notStarted'),
    textColor: 'primary.main',
    color: 'primary',
    variant: 'outlined',
    icon: <ClockIcon $color="primary.main" />,
  },
  [SubscriptionStatus.Expired]: {
    text: () => t('subscriptions.statuses.expired'),
    textColor: 'primary',
    color: 'expired',
    variant: 'filled',
  },
  [SubscriptionStatus.InTrial]: {
    text: () => t('subscriptions.statuses.inTrial'),
    textColor: 'white',
    color: 'trial',
    variant: 'filled',
    icon: StyledHourglassIcon,
  },
  [SubscriptionStatus.PaymentPending]: {
    text: () => t('subscriptions.statuses.pendingPayment'),
    textColor: 'secondary',
    color: 'other.outlineBorder',
    variant: 'outlined',
    icon: <ClockIcon $color="action.active" />,
    shouldNotTruncate: true,
  },
  [PaymentCollection.Processing]: {
    text: () => t('subscriptions.statuses.paymentPending'),
    textColor: 'secondary',
    color: 'other.outlineBorder',
    variant: 'outlined',
    icon: <Icon icon="LoadingCircle" size={16} />,
    shouldNotTruncate: true,
  },
  [ExtraSubscriptionStatuses.PaymentError]: {
    text: () => t('subscriptions.statuses.paymentError'),
    textColor: 'error',
    color: 'error',
    variant: 'outlined',
    icon: <Icon icon="AlertCircle" width={16} />,
    shouldNotTruncate: true,
    withErrorBackground: true,
  },
  [ExtraSubscriptionStatuses.ScheduledCancellation]: {
    text: () => t('subscriptions.statuses.scheduledCancellation'),
    textColor: 'error.main',
    color: 'error',
    variant: 'outlined',
    icon: <ClockIcon $color="error.main" />,
  },
  [ExtraSubscriptionStatuses.ScheduledUpdate]: {
    text: () => t('subscriptions.statuses.scheduledUpdate'),
    textColor: 'secondary',
    color: 'other.outlineBorder',
    variant: 'outlined',
    icon: <Schedualed width={16} />,
    shouldNotTruncate: true,
  },
};

export function SubscriptionStatusChip({
  status,
  cancellationDate,
  hasPointer,
  tooltip,
  shouldNotTruncate: _shouldNotTruncate,
  dataTestId,
}: {
  status: SubscriptionStatusChipType;
  cancellationDate?: Date;
  hasPointer?: boolean;
  tooltip?: React.ReactNode;
  shouldNotTruncate?: boolean;
  dataTestId?: string;
}) {
  // If there is end date, it is still an active subscription, but scheduled to be canceled.
  if (status === SubscriptionStatus.Active && cancellationDate) {
    status = ExtraSubscriptionStatuses.ScheduledCancellation;
  }
  const { text, shouldNotTruncate } = STATUS_FORMAT[status];
  const label = text();

  // the length of the word `Cancellation` is 12 and thats how we currently fit the max chip size
  const shouldTruncateLabel = useMemo(
    () => !_shouldNotTruncate && !shouldNotTruncate && label.length > 12,
    [_shouldNotTruncate, label.length, shouldNotTruncate],
  );
  return (
    <InformationTooltip
      arrow
      $padding={2}
      placement="top"
      title={shouldTruncateLabel ? <Text.B2>{label}</Text.B2> : tooltip || ''}>
      <StatusChip
        $hasPointer={hasPointer}
        icon={STATUS_FORMAT[status].icon}
        label={shouldTruncateLabel ? `${label.substr(0, 12)}...` : label}
        size="small"
        variant={STATUS_FORMAT[status].variant}
        textColor={STATUS_FORMAT[status].textColor}
        color={STATUS_FORMAT[status].color}
        data-testid={dataTestId}
        $withErrorBackground={STATUS_FORMAT[status].withErrorBackground}
      />
    </InformationTooltip>
  );
}
