import { BillingPeriod } from '@stigg-types/apiTypes';

export const getCalculatedPrice = (priceAmount: number, billingPeriod: BillingPeriod) => {
  let calculatedPrice = priceAmount;
  switch (billingPeriod) {
    case BillingPeriod.Monthly:
      calculatedPrice = priceAmount * 12;
      break;
    case BillingPeriod.Annually:
      calculatedPrice = priceAmount / 12;
      break;
    default:
      calculatedPrice = priceAmount;
  }

  return calculatedPrice;
};
