import { Box } from '@stigg-components';
import styled from 'styled-components/macro';
import { Icon } from './icon';
import { InformationTooltip } from './InformationTooltip';

const ICON_SIZE = 18;

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.itamar.palette.text.disabled};
  height: ${ICON_SIZE}px;
  margin-left: ${(props) => props.theme.spacing(1)};
`;

export function HelpTooltip({ children, $maxWidth }: { children: React.ReactChild; $maxWidth?: number }) {
  return (
    <InformationTooltip $maxWidth={$maxWidth} arrow placement="top" title={children}>
      <StyledBox>
        <Icon icon="HelpCenter" size={ICON_SIZE} color="disabled" />
      </StyledBox>
    </InformationTooltip>
  );
}
