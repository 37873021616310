import { useState } from 'react';
import { DragDropContextProps } from 'react-beautiful-dnd';

const queryAttr = 'data-rbd-draggable-id';

const getDraggedDom = (draggableId: string) => {
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  return draggedDOM;
};

type PlaceholderProps = {
  clientHeight?: number;
  clientWidth?: number;
  clientY?: number;
  clientX?: number;
};

export const useCustomTableDndPlaceholder = (indexOffset = 0) => {
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>({});

  const handleDragStart: DragDropContextProps['onDragStart'] = (event) => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM?.parentNode) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index + indexOffset;
    const clientY =
      // @ts-ignore
      Number(parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop)) +
      Number(
        // @ts-ignore
        [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total: any, curr: any) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return Number(total) + Number(curr.clientHeight) + Number(marginBottom);
        }, 0),
      );

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      // @ts-ignore
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  const handleDragEnd: DragDropContextProps['onDragEnd'] = () => {
    setPlaceholderProps({});
  };

  const handleDragUpdate: DragDropContextProps['onDragUpdate'] = (event) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM?.parentNode) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index + indexOffset;
    const sourceIndex = event.source.index;

    // @ts-ignore
    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      // @ts-ignore
      Number(parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop)) +
      Number(
        updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
          const style = curr.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return Number(total) + Number(curr.clientHeight) + Number(marginBottom);
        }, 0),
      );

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      // @ts-ignore
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft),
    });
  };

  return { placeholderProps, handleDragStart, handleDragUpdate, handleDragEnd };
};
