import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import map from 'lodash/map';
import { GridFlex, Icon, LongText, MenuItem, Select, Text } from '@stigg-components';
import { Currency } from '@stigg-types/apiTypes';
import { CountryWithFlagByCode } from './CountryWithFlagByCode';
import { DEFAULT_COUNTRY_CODE, getCurrencyText } from './currencyUtils';

type UseCurrencyResult = [() => JSX.Element | null, string, (countryCode: string) => void];

export const useCurrencySelector = ({
  countryCodesList,
  onCountryCodeChange,
  selectedCountryCode,
  disabled = false,
  defaultCountryText = 'Default',
  hideWhenNoLocalization = false,
  disableCaption,
  countryCodeToCurrencyMap,
  width,
}: {
  countryCodesList: string[];
  onCountryCodeChange?: (countryCode: string) => void;
  selectedCountryCode?: string | null;
  disabled?: boolean;
  defaultCountryText?: string;
  hideWhenNoLocalization?: boolean;
  disableCaption?: boolean;
  countryCodeToCurrencyMap?: Map<string, Currency>;
  width?: number;
}): UseCurrencyResult => {
  const [countryCode, setCountryCode] = useState<string>(selectedCountryCode || DEFAULT_COUNTRY_CODE);
  const shouldShowCurrency = !!countryCodeToCurrencyMap;

  width = width ?? shouldShowCurrency ? 200 : 160;

  useEffect(() => {
    setCountryCode(selectedCountryCode || DEFAULT_COUNTRY_CODE);
  }, [selectedCountryCode]);

  const handleCountryCodeChange = (countryCode: string) => {
    setCountryCode(countryCode);
    if (onCountryCodeChange) {
      onCountryCodeChange(countryCode);
    }
  };

  const defaultCountryResolvedText = `${defaultCountryText}${
    shouldShowCurrency ? ` (${getCurrencyText(Currency.Usd)})` : ''
  }`;

  const SelectComponent = (
    <Select
      disabled={disabled}
      width={width}
      onChange={(e) => handleCountryCodeChange(e.target.value as string)}
      value={countryCode}
      dataTestId="currency-selector"
      renderValue={(countryCode: any) => {
        if (countryCode === DEFAULT_COUNTRY_CODE) {
          return (
            <GridFlex.RowCenter flex={1}>
              <Icon type="materialIcons" icon="Language" size={20} color="default" />
              <LongText tooltipPlacement="top" wordBreak sx={{ mr: 2, width: '90%' }} leftMargin>
                {defaultCountryResolvedText}
              </LongText>
            </GridFlex.RowCenter>
          );
        }
        return (
          <CountryWithFlagByCode countryCode={countryCode} currency={countryCodeToCurrencyMap?.get(countryCode)} />
        );
      }}>
      {[
        ...(!disableCaption
          ? [
              <MenuItem value={countryCode} key="caption-localization" disabled>
                <GridFlex.RowMiddle>
                  <Text.Caption color="secondary">{t('pricing.currency.priceLocationTitle')}</Text.Caption>
                </GridFlex.RowMiddle>
              </MenuItem>,
            ]
          : []),
        ...map(countryCodesList, (currentCountryCode) => {
          return currentCountryCode === DEFAULT_COUNTRY_CODE ? (
            <MenuItem value={currentCountryCode} key={currentCountryCode}>
              <GridFlex.RowCenter flex={1}>
                <Icon type="materialIcons" icon="Language" size={20} color="default" />
                <LongText tooltipPlacement="top" wordBreak sx={{ ml: 2, width: '90%' }} leftMargin>
                  {defaultCountryResolvedText}
                </LongText>
              </GridFlex.RowCenter>
            </MenuItem>
          ) : (
            <MenuItem value={currentCountryCode} key={currentCountryCode}>
              <CountryWithFlagByCode
                countryCode={currentCountryCode}
                currency={countryCodeToCurrencyMap?.get(currentCountryCode)}
              />
            </MenuItem>
          );
        }),
      ]}
    </Select>
  );

  const shouldHideComponent =
    hideWhenNoLocalization && countryCodesList.length === 1 && countryCodesList[0] === DEFAULT_COUNTRY_CODE;

  return [() => (shouldHideComponent ? null : SelectComponent), countryCode, setCountryCode];
};
