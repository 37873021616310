import { Button, GridFlex, Icon, Text } from '@stigg-components';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { FieldArray } from 'formik';
import { BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { generateRandomSlug } from '@stigg-common';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { useSetPriceWizardFormContext } from '../SetPriceWizardForm.context';
import { ChargesEmptyState } from './emptyState/ChargesEmptyState';
import {
  AbstractUsageBasedChargeType,
  BasePriceChargeType,
  ChargeType,
  TiersSchema,
} from '../SetPriceWizardForm.types';
import { useLoadFeatures } from '../../../../../../features/hooks/useLoadFeatures';
import { USAGE_BASED_FEATURE_TYPES, USAGE_BASED_METER_TYPES } from './usageBasedPrice/FeatureAutocomplete';
import { ChargeAccordion } from './ChargeAccordion';
import { ALL_BILLING_PERIODS } from './utils/getPricePeriodFields';
import { StepsManagement } from '../utils/useSubStepsState';
import { DEFAULT_PRICE_TIER } from '../SetPriceWizardForm.initialValues';

export type ChargesStepProps = {
  chargesState: StepsManagement;
  renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode;
};

export function ChargesStep({ renderStepActionButtons, chargesState }: ChargesStepProps) {
  const {
    aPackage,
    formRenderProps: { values, touched, setTouched },
  } = useSetPriceWizardFormContext();

  const isAddon = aPackage.type === 'Addon';
  const hasBasePrice = values.charges.some((charge) => charge.type === ChargeType.BasePrice);

  // preload features for usage based charges
  useLoadFeatures({
    featureTypes: USAGE_BASED_FEATURE_TYPES,
    meterTypes: USAGE_BASED_METER_TYPES,
  });

  return (
    <>
      <Text.B2 color="secondary" mb={6}>
        {t(`pricing.addChargesLabel.${aPackage.type}`)}
      </Text.B2>

      <FieldArray name="charges">
        {({ push, remove }) => {
          const hasCharges = !isEmpty(values.charges);

          const updatePriceLocalizationForCharge = (charge: BasePriceChargeType | AbstractUsageBasedChargeType) => {
            const newTouched = cloneDeep(touched);
            const isUsageBasedCharge = charge.type === ChargeType.UsageBased;

            values.priceLocalization.countries.forEach((_, index) => {
              ALL_BILLING_PERIODS.forEach((billingPeriod) => {
                set(
                  newTouched,
                  `priceLocalization.countries[${index}].chargesPricePeriods[${charge.uuid}][${billingPeriod}]`,
                  true,
                );
                if (isUsageBasedCharge) {
                  set(
                    newTouched,
                    `priceLocalization.countries[${index}].chargesTieredPricePeriods[${charge.uuid}][${billingPeriod}]`,
                    true,
                  );
                }
              });
            });

            // setting touched for all price periods for all countries
            // in one action to prevent redundant rendering
            setTouched(newTouched);
          };

          const pushNewCharge = (item: BasePriceChargeType | AbstractUsageBasedChargeType) => {
            push(item);
            updatePriceLocalizationForCharge(item);
            chargesState.setFocused(item.uuid);
          };

          const createNewBasePrice = () =>
            pushNewCharge({
              uuid: generateRandomSlug(),
              type: ChargeType.BasePrice,
              billingModel: BillingModel.FlatFee,
              pricePeriods: {},
              isConfirmed: false,
            } as BasePriceChargeType);

          const createNewUsageBasedPrice = () =>
            pushNewCharge({
              uuid: generateRandomSlug(),
              type: ChargeType.UsageBased,
              billingModel: BillingModel.PerUnit, // this is default model
              tiersSchema: TiersSchema.Standard,
              feature: null,
              pricePeriods: {},
              tiers: {
                [BillingPeriod.Monthly]: [DEFAULT_PRICE_TIER],
                [BillingPeriod.Annually]: [DEFAULT_PRICE_TIER],
              },
              isConfirmed: false,
            } as AbstractUsageBasedChargeType);

          const addChargeButtons: ReactNode = !isAddon && hasCharges && (
            <GridFlex.Row gap={4}>
              {!hasBasePrice && (
                <Button
                  variant="outlined"
                  startIcon={<Icon icon="Plus" size={20} />}
                  onClick={createNewBasePrice}
                  data-testid="button-add-base-charge">
                  {t('pricing.addBaseCharge')}
                </Button>
              )}
              <Button
                variant="outlined"
                startIcon={<Icon icon="Plus" size={20} />}
                onClick={createNewUsageBasedPrice}
                data-testid="button-add-usage-based-charge">
                {t('pricing.addUsageBasedCharge')}
              </Button>
            </GridFlex.Row>
          );

          return (
            <>
              {!hasCharges && (
                <ChargesEmptyState
                  createNewBasePrice={createNewBasePrice}
                  createNewUsageBasedPrice={createNewUsageBasedPrice}
                />
              )}

              {values.charges.map((charge, index) => (
                <ChargeAccordion
                  key={charge.uuid}
                  index={index}
                  isFocused={chargesState.isExpanded(charge.uuid)}
                  onRemove={() => remove(index)}
                  permanent={isAddon || !charge.isConfirmed}
                  onConfirm={() => chargesState.setFocused(undefined)}
                  onToggle={() => chargesState.toggle(charge.uuid)}
                />
              ))}

              {renderStepActionButtons(addChargeButtons)}
            </>
          );
        }}
      </FieldArray>
    </>
  );
}
