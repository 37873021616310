import { gql } from '@apollo/client';
import { t } from 'i18next';
import { ListAwsProductsQuery, ListAwsProductsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';

const FETCH_AWS_PRODUCTS = gql`
  query ListAwsProducts($input: ListAwsProductsInput!) {
    listAwsProducts(input: $input) {
      products {
        stiggProductId
        stiggProductRefId
        productId
        title
        productCode
        description
        visibility
        logoUrl
      }
    }
  }
`;

async function fetchAWSProducts(_: void, { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState }) {
  const { accountReducer } = getState();
  if (!accountReducer.currentEnvironmentId) {
    throw new Error('environment Id must be set');
  }

  return executeOperationSafely(
    async () => {
      const response = await apolloClient.query<ListAwsProductsQuery, ListAwsProductsQueryVariables>({
        query: FETCH_AWS_PRODUCTS,
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId,
          },
        },
      });

      return response.data.listAwsProducts.products;
    },
    {
      failureMessageHandler: () => t('integrations.api.failFetchAWSProducts'),
    },
    dispatch,
  );
}

export { fetchAWSProducts };
