import { Divider, PageCard, GridFlex, Text } from '@stigg-components';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import * as React from 'react';
import { ExperimentHeaderLayout } from './ExperimentHeaderLayout';
import { ExperimentResultsTable } from './resultsTable/ExperimentResultsTable';
import { ExperimentDetails } from './details/ExperimentDetails';

type ExperimentGeneralInfoProps = {
  experiment: ExperimentFragment;
  linkToExperimentPage?: boolean;
};

export function ExperimentGeneralInfo({ experiment, linkToExperimentPage }: ExperimentGeneralInfoProps) {
  return (
    <PageCard>
      <ExperimentHeaderLayout experiment={experiment} linkToExperimentPage={linkToExperimentPage} />
      <Divider my={6} />
      <GridFlex.Row $fullWidth columnGap={8}>
        <GridFlex.Column flex={1} rowGap={6}>
          <Text.H6>{t('experiments.experimentDetails')}</Text.H6>
          <ExperimentDetails experiment={experiment} />
        </GridFlex.Column>
        <GridFlex.Column flex={1} rowGap={6}>
          <Text.H6>{t('experiments.results')}</Text.H6>
          <ExperimentResultsTable
            controlGroupName={experiment.controlGroupName}
            variantGroupName={experiment.variantGroupName}
            experimentRefId={experiment.refId}
            variantPercentage={experiment.variantPercentage}
          />
        </GridFlex.Column>
      </GridFlex.Row>
    </PageCard>
  );
}
