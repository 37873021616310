import { Button, FormRenderProps, GridFlex, Icon, Text } from '@stigg-components';
import React from 'react';
import { ProductFragment } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { useGetAutoCancellationTargets } from '../../../queries/useGetAutoCancellationTargets';
import { AutoCancellationRuleItemConfiguration } from './AutoCancellationRuleItemConfiguration';

type AutoCancellationRuleItem = {
  sourcePlanId?: string;
  targetPlanId?: string;
  sourcePlanDisplayName?: string;
  targetPlanDisplayName?: string;
};
export type SubscriptionAutoCancellationFormFields = {
  autoCancellationRules?: AutoCancellationRuleItem[];
};

type SubscriptionAutoCancellationConfigurationProps = {
  formRenderProps: FormRenderProps<SubscriptionAutoCancellationFormFields>;
  product: ProductFragment;
};

export function SubscriptionAutoCancellationConfiguration({
  formRenderProps: parentFormRenderProps,
  product,
}: SubscriptionAutoCancellationConfigurationProps) {
  const { plans, environmentId } = product;
  const { data: targets = [] } = useGetAutoCancellationTargets(environmentId);

  const autoCancellationRules = parentFormRenderProps.values.autoCancellationRules || [];
  const sourceOptions = plans.map((plan) => ({ displayName: plan.displayName, refId: plan.refId })) || [];
  const targetOptions = targets.map((target) => {
    return {
      refId: target.refId,
      displayName: target.displayName,
      productName: target.product.displayName || '',
    };
  });

  if (isEmpty(sourceOptions) || isEmpty(targetOptions)) {
    return null;
  }

  return (
    <GridFlex.Item mt={4}>
      <GridFlex.Column rowGap={4}>
        {autoCancellationRules?.map((_, index) => {
          return (
            <AutoCancellationRuleItemConfiguration
              key={index}
              index={index}
              formRenderProps={parentFormRenderProps}
              sourceOptions={sourceOptions}
              targetOptions={targetOptions}
              onDelete={() => {
                parentFormRenderProps.setFieldValue(
                  `autoCancellationRules`,
                  autoCancellationRules.filter((rule, i) => i !== index),
                );
              }}
            />
          );
        })}

        <GridFlex.RowMiddle justifyContent="flex-start">
          <Button
            onClick={() =>
              parentFormRenderProps.setFieldValue(`autoCancellationRules`, [...autoCancellationRules, {}])
            }>
            <Icon icon="Plus" color="primary.main" />
            <Text.B2 color="primary.main" display="inline" ml={2}>
              {t('products.customerJourney.autoCancellationRule.addRule')}
            </Text.B2>
          </Button>
        </GridFlex.RowMiddle>
      </GridFlex.Column>
    </GridFlex.Item>
  );
}
