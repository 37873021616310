import { drawFormFields, FormProps, FormRenderProps, Icon, IconButton, Text } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { SubscriptionAutoCancellationFormFields } from './SubscriptionAutoCancellationConfiguration';

type AutoCancellationRuleItemProps = {
  onDelete: (index: number) => void;
  index: number;
  formRenderProps: FormRenderProps<SubscriptionAutoCancellationFormFields>;
  sourceOptions: { displayName: string; refId: string }[];
  targetOptions: { displayName: string; refId: string; productName: string }[];
};

export function AutoCancellationRuleItemConfiguration({
  onDelete,
  formRenderProps,
  sourceOptions,
  index,
  targetOptions,
}: AutoCancellationRuleItemProps) {
  const fields: FormProps<SubscriptionAutoCancellationFormFields>['fields'] = [
    {
      type: 'inline',
      gridProps: {
        sx: {
          display: 'flex',
          alignItems: 'center',
          ':hover': {
            backgroundColor: (theme) => theme.itamar.palette.action.hover,
          },
        },
      },
      fields: [
        {
          type: 'idle',
          component: (
            <Text.B2 color="secondary" display="inline" mr={1}>
              {t('products.customerJourney.autoCancellationRule.sourcePlanPrefix')}
            </Text.B2>
          ),
        },
        {
          type: 'select',
          gridProps: {
            sx: {
              width: '180px',
            },
          },
          id: `autoCancellationRules[${index}].sourcePlanId`,
          placeholder: t('products.customerJourney.autoCancellationRule.planPlaceholder'),
          variant: 'inline',
          values: sourceOptions.map((plan) => {
            return {
              value: plan.refId,
              displayValue: t('sharedComponents.planSuffix', {
                planName: plan.displayName,
              }),
            };
          }),
        },
        {
          type: 'idle',
          component: (
            <Text.B2 color="secondary" display="inline" mr={1}>
              {t('products.customerJourney.autoCancellationRule.targetPlanPrefix')}
            </Text.B2>
          ),
        },
        {
          type: 'select',
          id: `autoCancellationRules[${index}].targetPlanId`,
          placeholder: t('products.customerJourney.autoCancellationRule.planPlaceholder'),
          variant: 'inline',
          values: targetOptions.map((plan) => {
            return {
              value: plan.refId,
              displayValue: t('products.customerJourney.autoCancellationRule.targetPlanDisplay', {
                planName: plan.displayName,
                productName: plan.productName,
              }),
            };
          }),
        },
        {
          type: 'custom',
          render: () => (
            <IconButton display="inline" sx={{ padding: 0 }} onClick={() => onDelete(index)}>
              <Icon icon="X" color="secondary" />
            </IconButton>
          ),
        },
      ],
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
