import { useState, createContext, useContext, useCallback, useMemo, useLayoutEffect } from 'react';
import { Theme } from '@mui/material/styles';
import { getStiggTheme } from '.';
import { THEMES } from './variants';
import { getCachedTheme, setCachedTheme } from './cachedStiggTheme';

export { THEMES } from './variants';

type StiggThemeContextType = {
  theme: Theme;
  themeVariant: THEMES;
  changeThemeVariant: (themeVariant: THEMES) => void;
};

export const StiggThemeContext = createContext<StiggThemeContextType | null>(null);

export function useStiggTheme() {
  const ctx = useContext(StiggThemeContext);
  if (!ctx) {
    throw new Error('Could not find StiggThemeContext');
  }
  return ctx;
}

type StiggThemeContextProviderProps = {
  children: (context: StiggThemeContextType) => React.ReactNode;
};

export function StiggThemeContextProvider({ children }: StiggThemeContextProviderProps) {
  const [themeVariant, setThemeVariant] = useState<THEMES>(getCachedTheme());

  useLayoutEffect(() => {
    if (themeVariant === THEMES.DARK) {
      document.documentElement.style.setProperty('color-scheme', 'dark');
    } else {
      document.documentElement.style.setProperty('color-scheme', 'light');
    }
  }, [themeVariant]);

  const changeThemeVariant = useCallback((value: THEMES) => {
    setCachedTheme(value);
    setThemeVariant(value);
  }, []);

  const value = useMemo(() => {
    const { theme } = getStiggTheme(themeVariant);
    return { theme, themeVariant, changeThemeVariant };
  }, [themeVariant, changeThemeVariant]);

  return <StiggThemeContext.Provider value={value}>{children(value)}</StiggThemeContext.Provider>;
}
