import { Icon, InformationTooltip, GridFlex, Text } from '@stigg-components';
import { EventActor } from '@stigg-types/apiTypes';
import { getActorIcon, getActorTypeDisplayName } from '../utils/actor.utils';

export default function EventActorIcon({ type }: { type: EventActor | null | undefined }) {
  return (
    <InformationTooltip arrow placement="top" title={<Text.B2>{getActorTypeDisplayName(type)}</Text.B2>} $padding={2}>
      <GridFlex.Item width={24} height={24}>
        <Icon icon={getActorIcon(type)} size={24} />
      </GridFlex.Item>
    </InformationTooltip>
  );
}
