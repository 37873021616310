import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();
  const intercom = useIntercom();

  return useCallback(() => {
    intercom.hardShutdown();
    auth0Logout({ returnTo: `${window.location.origin}/login` });
  }, [auth0Logout, intercom]);
};
