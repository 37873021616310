import { useRef, useState } from 'react';
import { IntegrationFragment } from '../../../../../types/apiTypes';
import { WizardMainContent } from './StripeImportCatalogWizardMainContent';
import { WizardSideNav } from './StripeImportWizardSideNav';

export function ImportCatalogWizard({
  integration,
  goToImportCustomers,
}: {
  integration: IntegrationFragment;
  goToImportCustomers: () => void;
}) {
  const prevStepIndex = useRef(0);
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = () => {
    setActiveStep((step) => step + 1);
  };

  const prevStep = () => {
    setActiveStep((step) => step - 1);
  };

  return (
    <>
      <WizardSideNav activeStep={activeStep} />
      <WizardMainContent
        integration={integration}
        activeStep={activeStep}
        nextStep={nextStep}
        prevStep={prevStep}
        prevStepIndex={prevStepIndex}
        goToImportCustomers={goToImportCustomers}
      />
    </>
  );
}
