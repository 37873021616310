import { useState } from 'react';
import { BillingModel, PriceFragment, TiersMode } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import get from 'lodash/get';
import { CloseableInformationTooltip, FormRenderProps, GridFlex, Text } from '@stigg-components';
import { hasTierWithUnitPrice } from '../../../../../../packages/pricing/utils/priceTiersUtils';
import { getPriceAndPeriodFormat } from '../../../../../../packages/pricing/utils/priceFormatUtils';
import { toTiersSchema } from '../../../../../../packages/pricing/components/SetPriceWizard/form/SetPriceWizardForm.initialValues';
import TieredPriceTable from '../../../../../../packages/common/components/pricingTypeViews/TieredPriceTable';
import { SubscriptionBillableFeatures } from '../../priceBreakdown';
import { SubscriptionChargeOverride } from './SubscriptionChargeOverride';
import { SubscriptionFormFields } from '../SubscriptionForm.types';
import { ChargeEditButton } from './ChargeEditButton';
import { ChargeOverrideIndication } from './ChargeOverrideIndication';

type SubscriptionChargeProps = {
  billableFeatures?: SubscriptionBillableFeatures;
  charge: PriceFragment;
  showPriceByMonth?: boolean;
  hovering?: boolean;
  formProps: FormRenderProps<SubscriptionFormFields>;
};

function TiersTooltip({ charge, showPriceByMonth }: { charge: PriceFragment; showPriceByMonth?: boolean }) {
  return (
    <CloseableInformationTooltip
      arrow
      placement="top"
      $maxWidth={680}
      PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 40] } }] }}
      header={
        <Text.B2>
          {charge.feature?.displayName} - {t(`pricing.tiersSchema.${toTiersSchema(charge.tiersMode, charge.tiers)}`)}
        </Text.B2>
      }
      title={<TieredPriceTable price={charge} reversedPeriod={showPriceByMonth} />}>
      {t('pricing.tierPriceTableTooltip.viewTiers')}
    </CloseableInformationTooltip>
  );
}

export function SubscriptionCharge({
  billableFeatures,
  charge,
  showPriceByMonth,
  hovering,
  formProps,
}: SubscriptionChargeProps) {
  const {
    values: { billableFeaturesPriceOverride },
  } = formProps;
  const [isInEditMode, setIsInEditMode] = useState(false);
  const featureRefId = charge.feature?.refId;
  const unitQuantity = featureRefId ? get(billableFeatures, featureRefId) : undefined;
  const unitBased = charge.tiersMode
    ? charge.tiersMode !== TiersMode.Graduated && hasTierWithUnitPrice(charge.tiers)
    : charge.billingModel === BillingModel.PerUnit;

  const supportedPriceOverride =
    charge.billingModel === BillingModel.FlatFee ||
    charge.billingModel === BillingModel.UsageBased ||
    (charge.billingModel === BillingModel.PerUnit && !charge.tiersMode);

  const priceFormatOptions = {
    reversedPeriod: showPriceByMonth,
    shortFormat: true,
    unitFormat: true,
    unitQuantity: unitQuantity || 1,
    tiersAsUnit: unitBased,
    totalPrice: !unitBased,
  };

  const chargeId = charge.feature?.refId || 'baseCharge';
  const override = billableFeaturesPriceOverride?.[chargeId];
  const priceAndPeriodFormat = getPriceAndPeriodFormat(override || charge, priceFormatOptions);

  return isInEditMode ? (
    <SubscriptionChargeOverride
      inputName={`billableFeaturesPriceOverride[${chargeId}]`}
      priceOverrides={billableFeaturesPriceOverride}
      priceOverrideKey={chargeId}
      charge={charge}
      priceFormatOptions={priceFormatOptions}
      onClose={() => setIsInEditMode(false)}
      formProps={formProps}
      showPriceByMonth={showPriceByMonth}
    />
  ) : (
    <GridFlex.RowSpaceBetween>
      <GridFlex.Row gap={1}>
        <GridFlex.Column>
          <Text.B2>{priceAndPeriodFormat}</Text.B2>
          {!!charge.tiersMode && <TiersTooltip charge={charge} showPriceByMonth={showPriceByMonth} />}
        </GridFlex.Column>
        {(override || charge.isOverridePrice) && <ChargeOverrideIndication />}
      </GridFlex.Row>
      <ChargeEditButton
        charge={charge}
        disabled={!supportedPriceOverride}
        hovering={hovering}
        onClick={() => setIsInEditMode(true)}
      />
    </GridFlex.RowSpaceBetween>
  );
}
