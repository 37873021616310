import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  StripeSubscriptionsQuery,
  StripeSubscriptionsQueryVariables,
  StripeSubscriptionSearchResultFragment,
  StripeSubscriptionSearchInput,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { RootState, AppDispatch } from '../../../redux/store';

const SEARCH_STRIPE_SUBSCRIPTIONS = gql`
  query StripeSubscriptions($input: StripeSubscriptionSearchInput!) {
    stripeSubscriptions(input: $input) {
      ...StripeSubscriptionSearchResultFragment
    }
  }
  fragment StripeSubscriptionSearchResultFragment on StripeSubscriptionSearchResult {
    subscriptions {
      id
    }
    totalCount
    nextPage
  }
`;

export type SearchStripeSubscriptionsProps = {
  nextPage?: string;
};

async function searchStripeSubscriptions(
  searchParams: SearchStripeSubscriptionsProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<StripeSubscriptionSearchResultFragment> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const input: StripeSubscriptionSearchInput = {
        environmentId: accountReducer.currentEnvironmentId,
      };
      if (searchParams.nextPage) {
        input.nextPage = searchParams.nextPage;
      }

      const response = await apolloClient.query<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>({
        query: SEARCH_STRIPE_SUBSCRIPTIONS,
        fetchPolicy: 'network-only',
        variables: {
          input,
        },
      });
      return response.data.stripeSubscriptions;
    },
    {
      failureMessageHandler: () => t('integrations.api.failSearchSubscriptions'),
    },
    dispatch,
  );
}

export { searchStripeSubscriptions };
