import { gql } from '@apollo/client';

export const FEATURE_LIST_FRAGMENT = gql`
  fragment FeatureListFragment on Feature {
    displayName
    id
    refId
    createdAt
    updatedAt
    description
    featureStatus
    environmentId
    featureType
    meterType
    featureUnits
    featureUnitsPlural
    additionalMetaData
    hasMeter
  }
`;
