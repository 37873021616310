import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HookDataFragment } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchHooksAction } from '../../../webhooks/hooksSlice';

type UseSlackIntegrationResult = [isLoading: boolean, slackIntegration?: HookDataFragment];

export function useSlackIntegration(): UseSlackIntegrationResult {
  const dispatch = useAppDispatch();
  const { slackIntegration: slackIntegrationEnabled } = useFlags<FeatureFlags>();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const hooks: HookDataFragment[] = useSelector((state: RootState) => state.hooksReducer.hooks);
  const slackIntegration = hooks.find(
    (hook) => slackIntegrationEnabled && hook.endpoint.startsWith('https://hooks.slack.com'),
  );
  const isLoading = useSelector((state: RootState) => state.hooksReducer.isLoading);
  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchHooksAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);
  return [isLoading || !currentEnvironmentId, slackIntegration];
}
