import { Text, Grid, LocalDate } from '@stigg-components';
import { PromotionalEntitlementPeriod } from '@stigg-types/apiTypes';

const PERIOD_FORMAT = {
  [PromotionalEntitlementPeriod.OneWeek]: '1 week',
  [PromotionalEntitlementPeriod.OneMonth]: '1 month',
  [PromotionalEntitlementPeriod.SixMonth]: '6 months',
  [PromotionalEntitlementPeriod.OneYear]: '1 year',
  [PromotionalEntitlementPeriod.Lifetime]: 'Lifetime',
  [PromotionalEntitlementPeriod.Custom]: 'Custom',
};

export function PromotionalEntitlementPeriodBox({
  period,
  endDate,
}: {
  period: PromotionalEntitlementPeriod;
  endDate?: Date;
}) {
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Text.B2>{PERIOD_FORMAT[period]}</Text.B2>
      </Grid>
      {endDate && (
        <Grid item>
          <Text.Sub2>
            Expires on: <LocalDate date={endDate} />
          </Text.Sub2>
        </Grid>
      )}
    </Grid>
  );
}
