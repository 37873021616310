import { useCallback, useEffect, useState } from 'react';
import { PlanFragment, AddonFragment, PackageStatus } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../redux/store';
import { setNavbarControls as setNavbarControlsStore } from '../application/applicationSlice';

export type NavbarControls = {
  editDeleteControls?: null | {
    aPackage: PlanFragment | AddonFragment; // package is a reserved word so adding a prefix: ;
    title: 'plan' | 'add-on';
    showDraftAlert?: boolean;
  };
};

export function useToggleNavbarControls(): {
  setNavbarControls: (newControls: NavbarControls) => void;
  removeNavbarControls: (controlKey: keyof NavbarControls) => void;
} {
  const [controls, setControls] = useState<NavbarControls | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (controls === null) return;
    const { editDeleteControls } = controls;

    if (editDeleteControls) {
      const { aPackage } = editDeleteControls;
      const draftTotalChanges = aPackage?.draftDetails?.changes?.totalChanges;
      const showDraftAlert =
        draftTotalChanges && draftTotalChanges > 0 && aPackage.isLatest && aPackage.status === PackageStatus.Published;

      dispatch(
        setNavbarControlsStore({
          editDeleteControls: {
            ...editDeleteControls,
            showDraftAlert: !!showDraftAlert,
          },
        }),
      );
    }
  }, [controls, dispatch]);

  const removeNavbarControls = useCallback(
    (controlsKeys: keyof NavbarControls | Array<keyof NavbarControls>) => {
      for (const controlKey of typeof controlsKeys === 'string' ? [controlsKeys] : controlsKeys) {
        switch (controlKey) {
          case 'editDeleteControls':
            dispatch(setNavbarControlsStore({ editDeleteControls: null }));
            break;
          default:
        }
      }
    },
    [dispatch],
  );

  const setNavbarControls = (newControls: NavbarControls) => {
    setControls({ ...controls, ...newControls });
  };

  return { setNavbarControls, removeNavbarControls };
}
