import { useCallback, useEffect, useState } from 'react';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import isEqualWith from 'lodash/isEqualWith';
import isString from 'lodash/isString';
import { DeepPartial } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { WidgetIdentifier } from '../useWidgetsData';
import {
  fetchCheckoutConfigurationAction,
  fetchCustomerPortalConfigurationAction,
  fetchPaywallConfigurationAction,
  setWidgetConfigurationAction,
} from '../widgetsSlice';
import { RootState, useAppDispatch } from '../../../redux/store';
import { defaultPaywallTheme, PaywallThemeConfiguration } from '../configurations/paywall';
import { CustomerPortalThemeConfiguration, defaultCustomerPortalTheme } from '../configurations/customerPortal';
import { CheckoutWidgetConfiguration, defaultCheckoutWidgetConfiguration } from '../configurations/checkout';
import { mapTypography } from '../utils/typography';

export type WidgetConfig = {
  onSave: () => void;
  configuration: PaywallThemeConfiguration | CustomerPortalThemeConfiguration | CheckoutWidgetConfiguration;
  onThemeChanged: (
    theme:
      | DeepPartial<PaywallThemeConfiguration>
      | DeepPartial<CustomerPortalThemeConfiguration>
      | DeepPartial<CheckoutWidgetConfiguration>,
  ) => void;
  isLoadingSetConfig: boolean;
  isLoading: boolean;
  hasChanges: boolean;
};

function stringComparison(objValue: any, othValue: any) {
  if (isString(objValue) && isString(othValue)) {
    return objValue.toLowerCase() === othValue.toLowerCase();
  }
  return undefined;
}

const COLOR_CHANGE_DEBOUNCE = 300;

export function useWidgetConfiguration(widgetIdentifier?: WidgetIdentifier): WidgetConfig | null {
  const dispatch = useAppDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoadingSetConfig = useSelector((root: RootState) => root.widgetsReducer.isLoadingSetConfig);
  const isLoading = useSelector((root: RootState) => root.widgetsReducer.isLoading);
  const apiPaywallConfiguration = useSelector((root: RootState) => root.widgetsReducer.paywallConfiguration);
  const apiCustomerPortalConfiguration = useSelector(
    (root: RootState) => root.widgetsReducer.customerPortalConfiguration,
  );
  const apiCheckoutWidgetConfiguration = useSelector((root: RootState) => root.widgetsReducer.checkoutConfiguration);

  const [paywallConfiguration, setPaywallConfiguration] = useState<{
    initial: PaywallThemeConfiguration;
    state: PaywallThemeConfiguration;
  }>({
    initial: defaultPaywallTheme,
    state: defaultPaywallTheme,
  });

  const [customerPortalConfiguration, setCustomerPortalConfiguration] = useState<{
    initial: CustomerPortalThemeConfiguration;
    state: CustomerPortalThemeConfiguration;
  }>({
    initial: defaultCustomerPortalTheme,
    state: defaultCustomerPortalTheme,
  });

  const [checkoutConfiguration, setCheckoutConfiguration] = useState<{
    initial: CheckoutWidgetConfiguration;
    state: CheckoutWidgetConfiguration;
  }>({
    initial: defaultCheckoutWidgetConfiguration,
    state: defaultCheckoutWidgetConfiguration,
  });

  useEffect(() => {
    if (environmentId) {
      if (widgetIdentifier === WidgetIdentifier.Paywall) {
        void dispatch(
          fetchPaywallConfigurationAction({
            environmentId,
          }),
        );
      } else if (widgetIdentifier === WidgetIdentifier.CustomerPortal) {
        void dispatch(
          fetchCustomerPortalConfigurationAction({
            environmentId,
          }),
        );
      } else if (widgetIdentifier === WidgetIdentifier.Checkout) {
        void dispatch(fetchCheckoutConfigurationAction({ environmentId }));
      }
    }
  }, [dispatch, environmentId, widgetIdentifier]);

  useEffect(() => {
    if (apiPaywallConfiguration) {
      const { palette, typography, layout, customCss } = apiPaywallConfiguration;
      const mappedConfig: DeepPartial<PaywallThemeConfiguration> = {
        palette: {
          primary: palette?.primary || undefined,
          backgroundPaper: palette?.backgroundColor || undefined,
          outlinedBorder: palette?.borderColor || undefined,
          backgroundHighlight: palette?.currentPlanBackground || undefined,
          text: {
            primary: palette?.textColor || undefined,
          },
        },
        typography: mapTypography(typography),
        layout: {
          alignment: layout?.alignment || undefined,
          planMargin: layout?.planMargin || undefined,
          planPadding: layout?.planPadding || undefined,
          planWidth: layout?.planWidth || undefined,
        },
        css: customCss || '',
      };
      setPaywallConfiguration((currentState) => ({
        initial: merge(currentState.initial, mappedConfig),
        state: merge(currentState.state, mappedConfig),
      }));
    }
  }, [apiPaywallConfiguration]);

  useEffect(() => {
    if (apiCustomerPortalConfiguration) {
      const { palette, typography, customCss } = apiCustomerPortalConfiguration;
      const mappedConfig: DeepPartial<CustomerPortalThemeConfiguration> = {
        palette: palette
          ? {
              primary: palette.primary || undefined,
              backgroundPaper: palette.paywallBackgroundColor || undefined,
              customerPortalBackground: palette.backgroundColor || undefined,
              outlinedBorder: palette.borderColor || undefined,
              backgroundHighlight: palette.currentPlanBackground || undefined,
              iconsColor: palette.iconsColor || undefined,
              text: {
                primary: palette.textColor || undefined,
              },
            }
          : undefined,
        typography: mapTypography(typography),
        css: customCss || '',
      };
      setCustomerPortalConfiguration((currentState) => ({
        initial: merge(currentState.initial, mappedConfig),
        state: merge(currentState.state, mappedConfig),
      }));
    }
  }, [apiCustomerPortalConfiguration]);

  useEffect(() => {
    if (apiCheckoutWidgetConfiguration) {
      const { palette, typography, customCss } = apiCheckoutWidgetConfiguration;
      const mappedConfig: DeepPartial<CheckoutWidgetConfiguration> = {
        palette: palette
          ? {
              primary: palette.primary || undefined,
              backgroundColor: palette.backgroundColor || undefined,
              borderColor: palette.borderColor || undefined,
              textColor: palette.textColor || undefined,
              summaryBackgroundColor: palette.summaryBackgroundColor || undefined,
            }
          : undefined,
        typography: mapTypography(typography),
        css: customCss || '',
        content: {
          collectPhoneNumber: apiCheckoutWidgetConfiguration.content?.collectPhoneNumber || undefined,
        },
      };
      setCheckoutConfiguration((currentState) => ({
        initial: merge(currentState.initial, mappedConfig),
        state: merge(currentState.state, mappedConfig),
      }));
    }
  }, [apiCheckoutWidgetConfiguration]);

  // Paywall
  const onThemeChanged = useCallback((theme: DeepPartial<PaywallThemeConfiguration>) => {
    setPaywallConfiguration((currentTheme) => merge({}, currentTheme, { state: theme }));
  }, []);
  const debouncedThemeChange = debounce(onThemeChanged, COLOR_CHANGE_DEBOUNCE);

  // Customer Portal
  const onCustomerPortalThemeChanged = useCallback((theme: DeepPartial<CustomerPortalThemeConfiguration>) => {
    setCustomerPortalConfiguration((currentTheme) => merge({}, currentTheme, { state: theme }));
  }, []);
  const debouncedCustomerPortalThemeChange = debounce(onCustomerPortalThemeChanged, COLOR_CHANGE_DEBOUNCE);

  // Checkout
  const onCheckoutWidgetChange = useCallback((theme: DeepPartial<CheckoutWidgetConfiguration>) => {
    setCheckoutConfiguration((currentTheme) => merge({}, currentTheme, { state: theme }));
  }, []);
  const debounceCheckoutWidgetConfiguration = debounce(onCheckoutWidgetChange, COLOR_CHANGE_DEBOUNCE);

  switch (widgetIdentifier) {
    case WidgetIdentifier.Paywall: {
      return {
        onSave: () => {
          const {
            state: { palette, typography, layout, css },
          } = paywallConfiguration;

          void dispatch(
            setWidgetConfigurationAction({
              paywallConfiguration: {
                palette: {
                  primary: palette?.primary,
                  backgroundColor: palette?.backgroundPaper,
                  borderColor: palette?.outlinedBorder,
                  currentPlanBackground: palette?.backgroundHighlight,
                  textColor: palette?.text?.primary,
                },
                typography: mapTypography(typography),
                layout: {
                  alignment: layout.alignment,
                  planMargin: layout.planMargin,
                  planPadding: layout.planPadding,
                  planWidth: layout.planWidth,
                },
                customCss: css,
              },
            }),
          );

          // Setting the initial state to be the current state so we are not detecting changes
          setPaywallConfiguration((currentState) => ({
            initial: currentState.state,
            state: currentState.state,
          }));
        },
        configuration: paywallConfiguration.state,
        onThemeChanged: debouncedThemeChange,
        isLoadingSetConfig,
        isLoading,
        hasChanges: !isEqualWith(paywallConfiguration.initial, paywallConfiguration.state, stringComparison),
      };
    }
    case WidgetIdentifier.CustomerPortal: {
      return {
        onSave: () => {
          const {
            state: { palette, typography, css },
          } = customerPortalConfiguration;

          void dispatch(
            setWidgetConfigurationAction({
              customerPortalConfiguration: {
                palette: {
                  iconsColor: palette.iconsColor,
                  backgroundColor: palette.customerPortalBackground,
                  borderColor: palette.outlinedBorder,
                  currentPlanBackground: palette.backgroundHighlight,
                  paywallBackgroundColor: palette.backgroundPaper,
                  primary: palette.primary,
                  textColor: palette.text.primary,
                },
                typography: mapTypography(typography),
                customCss: css,
              },
            }),
          );

          // Setting the initial state to be the current state so we are not detecting changes
          setCustomerPortalConfiguration((currentState) => ({
            initial: currentState.state,
            state: currentState.state,
          }));
        },
        configuration: customerPortalConfiguration.state,
        onThemeChanged: debouncedCustomerPortalThemeChange,
        isLoadingSetConfig,
        isLoading,
        hasChanges: !isEqualWith(
          customerPortalConfiguration.initial,
          customerPortalConfiguration.state,
          stringComparison,
        ),
      };
    }
    case WidgetIdentifier.Checkout: {
      return {
        onSave: () => {
          const {
            state: { palette, typography, css, content },
          } = checkoutConfiguration;

          void dispatch(
            setWidgetConfigurationAction({
              checkoutConfiguration: {
                palette: {
                  primary: palette.primary,
                  textColor: palette.textColor,
                  backgroundColor: palette.backgroundColor,
                  borderColor: palette.borderColor,
                  summaryBackgroundColor: palette.summaryBackgroundColor,
                },
                typography: mapTypography(typography),
                customCss: css,
                content,
              },
            }),
          );

          // Setting the initial state to be the current state, so we are not detecting changes
          setCheckoutConfiguration((currentState) => ({
            initial: currentState.state,
            state: currentState.state,
          }));
        },
        configuration: checkoutConfiguration.state,
        onThemeChanged: debounceCheckoutWidgetConfiguration,
        isLoadingSetConfig,
        isLoading,
        hasChanges: !isEqualWith(checkoutConfiguration.initial, checkoutConfiguration.state, stringComparison),
      };
    }
    default: {
      return null;
    }
  }
}
