import { Checkbox, TableHead as MuiTableHead, Text } from '@stigg-components';
import { isNil } from 'lodash';
import { HeadCell, TableHeadComponentProps } from '../Table.types';
import * as S from '../Table.style';
import { LAST_CELL_WIDTH } from '.';
import { SELECTION_COLUMN_WIDTH } from '../Table.style';

export const TableHead = <TEntity, TCell extends keyof TEntity>({
  headCells,
  collapsableComponentOptions,
  headerRowColor,
  enableRowSelection,
  hideHeaderSelection,
  isAllRowsSelected,
  isAnyRowSelected,
  handleSelectAllClick,
  disableBoldHeader,
  isDraggingEnabled,
  headerHeight,
}: TableHeadComponentProps<TEntity, TCell>) => {
  return (
    <MuiTableHead>
      <S.TableRow $isHeaderComponent $rowColor={headerRowColor} $rowHeight={headerHeight}>
        {enableRowSelection && (
          <S.TableCell align="left" width={SELECTION_COLUMN_WIDTH}>
            {!hideHeaderSelection && (
              <Checkbox
                label=""
                color="primary"
                indeterminate={isAnyRowSelected && !isAllRowsSelected}
                checked={isAllRowsSelected}
                onChange={handleSelectAllClick}
                ariaLabel="select all rows"
              />
            )}
          </S.TableCell>
        )}
        {isDraggingEnabled && <S.TableDragHandleCell align="left" />}
        {headCells &&
          headCells.map((headCell: HeadCell<TEntity, TCell>) =>
            isNil(headCell.visible) || headCell.visible ? (
              <S.TableCell
                width={headCell.width}
                sx={{ minWidth: headCell.minWidth, whiteSpace: headCell.noWrap ? 'nowrap' : undefined }}
                $disablePadding={headCell.disablePadding}
                key={headCell.id}
                align={headCell.alignment}>
                <Text.B2 $medium={!disableBoldHeader}>{headCell.label}</Text.B2>
              </S.TableCell>
            ) : null,
          )}
        {collapsableComponentOptions && <S.TableCell key="collapsable" width={LAST_CELL_WIDTH} />}
      </S.TableRow>
    </MuiTableHead>
  );
};
