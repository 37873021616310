import { gql } from '@apollo/client';
import { t } from 'i18next';
import { ArchiveCustomerMutation, ArchiveCustomerMutationVariables, ErrorCode } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { navigateTo } from '../../navigation/actions';
import { appRoutes } from '../../navigation/useNavigation';

const ARCHIVE_CUSTOMER = gql`
  mutation ArchiveCustomer($input: ArchiveCustomerInput!) {
    archiveCustomer(input: $input) {
      customerId
    }
  }
`;

async function archiveCustomerByRefId(
  customerId: string,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<{ id: string } | undefined | null> {
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<ArchiveCustomerMutation, ArchiveCustomerMutationVariables>({
        mutation: ARCHIVE_CUSTOMER,
        variables: { input: { customerId, environmentId: accountReducer.currentEnvironmentId } },
      });

      dispatch(navigateTo(appRoutes.customersPage()));

      return { id: customerId };
    },
    {
      successMessage: t('customers.api.successDelete'),
      failureMessageHandler: () => t('customers.api.failDelete'),
      expectedErrorsMessage: {
        [ErrorCode.CannotDeleteCustomerError]: t('customers.api.canNotDeleteCustomer'),
      },
    },
    dispatch,
  );
}

export { archiveCustomerByRefId };
