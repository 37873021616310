import { useState } from 'react';
import { PageInfo, CursorPaging } from '@stigg-types/apiTypes';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../modules/common/pagination';

export const useReactQueryPagination = (
  pageInfo: PageInfo | undefined,
  setCursor: (paging: CursorPaging) => void,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
) => {
  const [lastVisitedPage, setLastVisitedPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const pageChangeFunc = (_: any, nextPage: number) => {
    const newCursor: CursorPaging =
      nextPage > pageNumber
        ? {
            after: pageInfo?.endCursor,
            first: pageSize,
          }
        : { before: pageInfo?.startCursor, last: pageSize };
    setCursor(newCursor);
    setLastVisitedPage(pageNumber);
    setPageNumber(nextPage);
  };

  return {
    pageNumber,
    lastVisitedPage,
    pageChangeFunc,
  };
};
