import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@tanstack/react-query';
import { ApolloQueryResult } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { applyEnvironmentFilter, executeOperationSafely } from '@stigg-common';
import { t } from 'i18next';
import {
  CouponFilter,
  CouponSortFields,
  CouponStatus,
  CursorPaging,
  FetchCouponsQuery,
  FetchCouponsQueryVariables,
  SortDirection,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { RootState } from '../../../redux/store';
import { FETCH_COUPONS } from './fetchCoupons';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../common/pagination';

const transformCouponsResult = (result: ApolloQueryResult<FetchCouponsQuery>) => ({
  coupons: result.data.coupons.edges.map((edge) => edge.node),
  totalCount: result.data.coupons.totalCount,
});

export const useGetCoupons = (search?: string, paging?: CursorPaging) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  const filter: CouponFilter = {
    environmentId: { eq: environmentId },
    status: { eq: CouponStatus.Active },
  };

  if (!isEmpty(search)) {
    filter.or = [{ refId: { iLike: `%${search}%` } }, { name: { iLike: `%${search}%` } }].map((searchFilter) =>
      applyEnvironmentFilter<CouponFilter>(searchFilter, environmentId),
    );
  }

  if (!paging) {
    paging = { first: DEFAULT_TABLE_PAGE_SIZE };
  }

  return useQuery({
    queryKey: ['coupons', filter, paging],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.query<FetchCouponsQuery, FetchCouponsQueryVariables>({
            query: FETCH_COUPONS,
            fetchPolicy: 'network-only',
            variables: {
              paging,
              sorting: { field: CouponSortFields.CreatedAt, direction: SortDirection.Desc },
              filter,
            },
          }),
        { failureMessageHandler: () => t('coupons.errors.couponsFetchFailed') },
        dispatch,
      ),
    select: transformCouponsResult,
    retry: 0, // we automatically retry in apollo client
  });
};
