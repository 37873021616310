import partition from 'lodash/partition';
import React, { useContext, useState, useMemo } from 'react';
import { BillingModel, ProductListItemFragment } from '@stigg-types/apiTypes';
import { StripeCustomerListItem } from '../importCustomers/wizardSteps/SelectCustomersStep';
import { StripeProductListItem } from '../wizardSteps/ImportProductsStep';

export type ImportProductsData = {
  totalCount: number;
  nextPage?: string | null;
  products: StripeProductListItem[];
  searchProductsResults: StripeProductListItem[];
  searchBlacklistProducts: StripeProductListItem[];
  searchWhitelistProducts: StripeProductListItem[];
  headerSelectionChecked: boolean;
  usageBasedProductPresent: boolean;
  taskId?: string;
};

export type ImportCustomersData = {
  totalCount: number;
  nextPage?: string | null;
  customers: StripeCustomerListItem[];
  searchCustomersResults: StripeCustomerListItem[];
  searchBlacklistCustomers: StripeCustomerListItem[];
  searchWhitelistCustomers: StripeCustomerListItem[];
  headerSelectionChecked: boolean;
  taskId?: string;
};

type ImportStripeContextValue = {
  featureUnitNameHasError: boolean;
  setFeatureUnitNameHasError: React.Dispatch<React.SetStateAction<boolean>>;
  featureUnitName: string;
  setFeatureUnitName: React.Dispatch<React.SetStateAction<string>>;
  billingModel: BillingModel;
  setBillingModel: React.Dispatch<React.SetStateAction<BillingModel>>;
  productsData: ImportProductsData;
  setProductsData: React.Dispatch<React.SetStateAction<ImportProductsData>>;
  addonIds: string[];
  setAddonIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedProduct?: ProductListItemFragment | null;
  setSelectedProduct?: React.Dispatch<React.SetStateAction<ProductListItemFragment | null>>;
  customersData: ImportCustomersData;
  setCustomersData: React.Dispatch<React.SetStateAction<ImportCustomersData>>;
  activeSubscriptionsCount: number;
  setActiveSubscriptionsCount: React.Dispatch<React.SetStateAction<number>>;
};

export const StripeImportContext = React.createContext<ImportStripeContextValue | null>(null);

export function useStripeWizardImportContext() {
  const [productsData, setProductsData] = useState<ImportProductsData>({
    totalCount: 0,
    products: [],
    searchProductsResults: [],
    searchBlacklistProducts: [],
    searchWhitelistProducts: [],
    headerSelectionChecked: true,
    usageBasedProductPresent: false,
  });
  const [customersData, setCustomersData] = useState<ImportCustomersData>({
    totalCount: 0,
    customers: [],
    searchCustomersResults: [],
    searchBlacklistCustomers: [],
    searchWhitelistCustomers: [],
    headerSelectionChecked: true,
  });
  const [billingModel, setBillingModel] = useState<BillingModel>(BillingModel.FlatFee);
  const [featureUnitName, setFeatureUnitName] = useState('');
  const [featureUnitNameHasError, setFeatureUnitNameHasError] = useState(false);
  const [activeSubscriptionsCount, setActiveSubscriptionsCount] = useState<number>(0);
  const [addonIds, setAddonIds] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductListItemFragment | null>(null);
  const importContext: ImportStripeContextValue = {
    productsData,
    setProductsData,
    addonIds,
    setAddonIds,
    selectedProduct,
    setSelectedProduct,
    customersData,
    setCustomersData,
    activeSubscriptionsCount,
    setActiveSubscriptionsCount,
    billingModel,
    setBillingModel,
    featureUnitName,
    setFeatureUnitName,
    featureUnitNameHasError,
    setFeatureUnitNameHasError,
  };

  return importContext;
}

export function useImportContext() {
  const importContext = useContext(StripeImportContext);
  if (!importContext) {
    throw new Error('There is no provider of Stripe import context available');
  }
  const { products, searchBlacklistProducts, searchWhitelistProducts } = importContext.productsData;
  const { customers, searchBlacklistCustomers, searchWhitelistCustomers } = importContext.customersData;
  const [selectedProducts, unSelectedProducts] = useMemo(
    () => partition(products, (product) => product.isSelected),
    [products],
  );
  const [selectedCustomers, unSelectedCustomers] = useMemo(
    () => partition(customers, (product) => product.isSelected),
    [customers],
  );

  const unseenBlackProducts = searchBlacklistProducts.filter(
    (blackProduct) => !products.some((product) => product.id === blackProduct.id),
  );
  const unseenWhiteProducts = searchWhitelistProducts.filter(
    (whiteProduct) => !products.some((product) => product.id === whiteProduct.id),
  );
  const unseenBlackCustomers = searchBlacklistCustomers.filter(
    (blackCustomer) => !customers.some((customer) => customer.id === blackCustomer.id),
  );
  const unseenWhiteCustomers = searchWhitelistCustomers.filter(
    (whiteCustomer) => !customers.some((customer) => customer.id === whiteCustomer.id),
  );

  const calculatedActiveSubscriptionsCount = useMemo(() => {
    const [selectedCustomers, unSelectedCustomers] = partition(customers, (customer) => customer.isSelected);
    const allSelectedCustomers = [...selectedCustomers, ...unseenWhiteCustomers];
    const allUnselectedCustomers = [...unSelectedCustomers, ...unseenBlackCustomers];
    if (importContext.customersData.headerSelectionChecked) {
      const unselectedSubscriptionsCount = allUnselectedCustomers.reduce(
        (acc, customer) => customer.subscriptionsCount + acc,
        0,
      );
      return importContext.activeSubscriptionsCount - unselectedSubscriptionsCount;
    }

    return allSelectedCustomers.reduce((acc, customer) => customer.subscriptionsCount + acc, 0);
  }, [
    importContext.customersData,
    customers,
    importContext.activeSubscriptionsCount,
    unseenWhiteCustomers,
    unseenBlackCustomers,
  ]);

  return {
    ...importContext,
    activeSubscriptionsCount: calculatedActiveSubscriptionsCount,
    selectedProducts: [...selectedProducts, ...unseenWhiteProducts],
    unSelectedProducts: [...unSelectedProducts, ...unseenBlackProducts],
    selectedCustomers: [...selectedCustomers, ...unseenWhiteCustomers],
    unSelectedCustomers: [...unSelectedCustomers, ...unseenBlackCustomers],
    unseenBlackProducts,
    unseenWhiteProducts,
  };
}
