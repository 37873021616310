import { createSlice } from '@reduxjs/toolkit';
import { HookDataFragment, TestHookDataFragment } from '@stigg-types/apiTypes';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import { fetchHooks, fetchTestHookEventData, sendTestHookEvent } from './queries';
import { createHook } from './mutations/createHook';
import { updateHook } from './mutations/updateHook';
import { deleteHookById } from './mutations/deleteHookById';
import { fetchHookById } from './queries/fetchHookById';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface HooksState {
  isLoading: boolean;
  hooks: HookDataFragment[];
  isLoadingHook: boolean;
  hook?: HookDataFragment | null;
  testHookData?: TestHookDataFragment | null;
  isLoadingTestHookData: boolean;
}

const initialState: HooksState = {
  isLoading: false,
  isLoadingHook: false,
  isLoadingTestHookData: false,
  hooks: [],
};

const sendTestHookEventAction = createAppAsyncThunk('sendTestHookEvent', sendTestHookEvent);
const fetchTestHookEventDataAction = createAppAsyncThunk('fetchTestHookEventData', fetchTestHookEventData);
const fetchHooksAction = createAppAsyncThunk('fetchHooks', fetchHooks);
const createHookAction = createAppAsyncThunk('createHook', createHook);
const updateHookAction = createAppAsyncThunk('updateHook', updateHook);
const deleteHookByIdAction = createAppAsyncThunk('deleteHookById', deleteHookById);
const fetchHookByIdAction = createAppAsyncThunk('fetchHookById', fetchHookById);

export const hooksSlice = createSlice({
  name: 'hooks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHooksAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHooksAction.fulfilled, (state, action) => {
        state.hooks = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchHooksAction.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(createHookAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHookAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createHookAction.rejected, (state) => {
        state.isLoading = false;
      });
    builder.addCase(updateHookAction.fulfilled, (state, { payload }) => {
      state.hook = merge(state.hook, payload);
      if (state?.hook?.eventLogTypes) {
        state.hook.eventLogTypes = payload?.eventLogTypes || [];
        state.hook.configuration = payload?.configuration || {};
      }

      state.hooks = state.hooks.map((hook: HookDataFragment) => {
        if (hook.id === payload?.id) {
          hook = merge(hook, payload);
          hook.eventLogTypes = payload.eventLogTypes || [];
          hook.configuration = payload.configuration || {};
        }
        return hook;
      });
    });

    builder.addCase(deleteHookByIdAction.fulfilled, (state, { payload }) => {
      remove(state.hooks, (hook) => hook.id === payload?.id);
    });

    builder
      .addCase(fetchHookByIdAction.pending, (state) => {
        state.isLoadingHook = true;
      })
      .addCase(fetchHookByIdAction.fulfilled, (state, { payload }) => {
        state.hook = payload;
        state.isLoadingHook = false;
      })
      .addCase(fetchHookByIdAction.rejected, (state) => {
        state.isLoadingHook = false;
      });

    builder
      .addCase(fetchTestHookEventDataAction.pending, (state) => {
        state.isLoadingTestHookData = true;
      })
      .addCase(fetchTestHookEventDataAction.fulfilled, (state, { payload }) => {
        state.testHookData = payload;
        state.isLoadingTestHookData = false;
      })
      .addCase(fetchTestHookEventDataAction.rejected, (state) => {
        state.isLoadingTestHookData = false;
      });
  },
});

export {
  fetchHooksAction,
  createHookAction,
  updateHookAction,
  deleteHookByIdAction,
  fetchHookByIdAction,
  sendTestHookEventAction,
  fetchTestHookEventDataAction,
};

export default hooksSlice.reducer;
