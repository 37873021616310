import { useBooleanEntitlement } from '../../../doggo/hooks/useBooleanEntitlement';
import { StiggFeature } from '../../../doggo/StiggFeature';
import NoEventLogAccess from '../NoEventLogAccess';
import EventLogTable from './EventLogTable';

export type EventLogProps = {
  entityId?: string;
  parentEntityId?: string;
  columns?: EventLogColumns[];
};

export enum EventLogColumns {
  Timestamp,
  EventName,
  Entity,
  Actor,
}

export const DEFAULT_COLUMNS = [EventLogColumns.Timestamp, EventLogColumns.EventName, EventLogColumns.Actor];

export default function EventLog({ entityId, parentEntityId, columns = DEFAULT_COLUMNS }: EventLogProps) {
  const eventLogEntitlement = useBooleanEntitlement(StiggFeature.EVENT_LOG);

  if (!eventLogEntitlement.hasAccess) {
    return <NoEventLogAccess columns={columns} />;
  }

  return <EventLogTable entityId={entityId} parentEntityId={parentEntityId} columns={columns} />;
}
