import { GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { ImportCustomersSummary } from './ImportCustomersSummary';

export function ReviewAndImport() {
  return (
    <GridFlex.Column>
      <Text.H3 mb={1}>{t('integrations.reviewAndImportCustomersStepHeader')}</Text.H3>
      <Text.B2 color="secondary" mb={12}>
        {t('integrations.importCustomersReviewSubtitle')}
      </Text.B2>

      <ImportCustomersSummary />
    </GridFlex.Column>
  );
}
